import type { StoreState } from '~/reducers';
import { communicationModuleStoreStateSelector } from '~/selectors';

import type { ConversationsSummaryWidgetStoreState } from './components/ConversationsSummaryWidget';
import type { ConversationWidgetsStoreState } from './components/ConversationWidgets/reducers';
import type { SendMessageDialogStoreState } from './components/SendMessageDialog/reducers';
import type { ConversationsRootStoreState } from './reducers';

export const conversationsRootStoreStateSelector = (s: StoreState): ConversationsRootStoreState =>
    communicationModuleStoreStateSelector(s).conversationsRoot;

export const conversationsSummaryWidgetStoreStateSelector = (s: StoreState): ConversationsSummaryWidgetStoreState =>
    communicationModuleStoreStateSelector(s).conversationsSummaryWidget;

export const conversationWidgetsStoreStateSelector = (s: StoreState): ConversationWidgetsStoreState =>
    communicationModuleStoreStateSelector(s).conversationWidgets;

export const sendMessageDialogStoreStateSelector = (s: StoreState): SendMessageDialogStoreState =>
    communicationModuleStoreStateSelector(s).sendMessageDialog;
