import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ControlInnerProps, ControlProps } from './component';
import { ControlComponent } from './component';
import { ControlStyleRules } from './styles';

export const Control = compose<ControlInnerProps, ControlProps>(
    setDisplayName('Control'),
    withStyles(ControlStyleRules)
)(ControlComponent);
