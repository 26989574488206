import type { FC } from 'react';

import type { StringListFormatterProps } from './models';
import { StyledChip } from './styles';

const StringListFormatter: FC<StringListFormatterProps> = (props) => {
    const { value } = props;
    return (
        <span>
            {value?.map((strValue: string) => (
                <StyledChip data-testid={strValue} key={strValue} label={strValue} size="small" />
            ))}
        </span>
    );
};

export { StringListFormatter };
