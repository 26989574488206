import { useTranslation } from 'react-i18next';

import { useDisplayPreferences } from '~/common/hooks';
import { AssetAvatar } from '~/components/AssetAvatar';
import { DriverIcon } from '~/components/Icons';
import { SceneDetailsPaneHeader } from '~/components/SceneDetailsPane';
import { isUndefined } from '~/libs/utility';
import { formatDriverName } from '~/services/Formatters';

import type { HeaderProps } from '../../../models';

const HeaderComponent: React.FC<HeaderProps> = (props) => {
    const { driverStatus } = props;

    const { t } = useTranslation();
    const displayPreferences = useDisplayPreferences();

    const primaryIdentifier = !isUndefined(driverStatus.driver)
        ? formatDriverName(driverStatus.driver, displayPreferences.driverDisplayFormat)
        : '';

    return (
        <SceneDetailsPaneHeader
            avatar={<AssetAvatar icon={<DriverIcon />} />}
            primaryIdentifier={primaryIdentifier}
            secondaryIdentifier={t('driver')}
        />
    );
};

HeaderComponent.displayName = 'Header';
export default HeaderComponent;
