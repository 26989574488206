import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';

import type { ScheduleEditorInnerProps, ScheduleEditorProps } from './component';
import { ScheduleEditorComponent } from './component';
import { mapDispatchProps, mapStateToProps } from './redux';
import { ScheduleEditorFormStyleRules } from './styles';

export const ScheduleEditor = compose<ScheduleEditorInnerProps, ScheduleEditorProps>(
    setDisplayName('ScheduleEditor'),
    withTranslation(),
    withStyles(ScheduleEditorFormStyleRules),
    ensureSettings([
        SettingsKey.TACHOGRAPH_DATA_BLOCK,
        SettingsKey.RTDS_PRIORITY,
        SettingsKey.RTDS_SCHEDULE_FREQUENCY,
        SettingsKey.RTDS_WEEK_DAY_TYPE,
        SettingsKey.RTDS_ORDINAL_TYPE,
    ]),
    connect(
        createSelector(
            settingDataSelector(SettingsKey.TACHOGRAPH_DATA_BLOCK),
            settingDataSelector(SettingsKey.RTDS_PRIORITY),
            settingDataSelector(SettingsKey.RTDS_SCHEDULE_FREQUENCY),
            settingDataSelector(SettingsKey.RTDS_WEEK_DAY_TYPE),
            settingDataSelector(SettingsKey.RTDS_ORDINAL_TYPE),
            mapStateToProps
        ),
        mapDispatchProps
    )
)(ScheduleEditorComponent);
