import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ContextMenuClassKey = 'tooltip';

export const ContextMenuStyleRules: StyleRulesCallback<Theme, {}, ContextMenuClassKey> = () =>
    createStyles({
        tooltip: {
            '&[aria-disabled="true"]': {
                pointerEvents: 'initial',
                cursor: 'default',
            },
            display: 'flex',
        },
    });
