import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressFormatter, CoordinatesFormatter } from '~/components/Formatters';
import { AddressFormat } from '~/services/Formatters';

import type { GeoPositionAddressProps } from './models';
import { Container, Typography } from './styles';

const GeoPositionAddress: FC<GeoPositionAddressProps> = (props) => {
    const { address, position } = props;

    const { t } = useTranslation();

    return (
        <Container data-testid="geoposition-address">
            {address && (
                <>
                    <Typography variant="subtitle2" data-testid="first-line">
                        <AddressFormatter value={address} options={{ format: AddressFormat.FirstLine }} />
                    </Typography>
                    <Typography variant="body2" data-testid="second-line">
                        <AddressFormatter value={address} options={{ format: AddressFormat.SecondLine }} />
                    </Typography>
                </>
            )}

            <Typography variant="body2" color="textSecondary" data-testid="coordinates">
                <CoordinatesFormatter value={position} t={t} />
            </Typography>
        </Container>
    );
};

GeoPositionAddress.displayName = 'GeoPositionAddress';
export { GeoPositionAddress };
