import { createCancelableAction } from '~/common';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { VehicleEventsPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

interface RetrieveVehicleEventsArgs {
    vehicleId: number;
    startDate: Date;
    stopDate: Date;
}

export const retrieveVehicleEventsAction = createCancelableAction<VehicleEventsPayload, RetrieveVehicleEventsArgs>(
    ActionTypePrefixes.VEHICLEEVENT,
    ({ vehicleId, startDate, stopDate }, signal) =>
        retryableRequest(() => ApiClient.getVehicleEvents(vehicleId, startDate, stopDate, signal))
);
