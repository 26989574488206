import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DefaultHeaderInnerProps, DefaultHeaderProps } from './component';
import { DefaultHeaderComponent } from './component';
import { DefaultHeaderStyleRules } from './styles';

export const DefaultHeader = compose<DefaultHeaderInnerProps, DefaultHeaderProps>(
    setDisplayName('DefaultHeader'),
    withTranslation(),
    withStyles(DefaultHeaderStyleRules)
)(DefaultHeaderComponent);
