import { createAction } from 'redux-actions';

import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { DriversPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

export const retrieveDriversExecutor = (): Promise<DriversPayload> => retryableRequest(() => ApiClient.getDrivers());

export const retrieveDriversAction = createAction<Promise<DriversPayload>>(
    ActionTypePrefixes.DRIVERS,
    retrieveDriversExecutor
);
