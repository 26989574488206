import classNames from 'classnames';
import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';

import { TemperatureFormatter } from '../Temperature';

import type { TemperatureSensorFormatterInnerProps } from './models';

export const TemperatureSensorFormatterComponent: FC<TemperatureSensorFormatterInnerProps> = (props) => {
    const { t, unitSystem, classes, sensorNumber, value } = props;

    const currentTemperature = value?.temperatureSensors[sensorNumber]?.value;

    const maxTemperature = value?.maxTemperature;

    const minTemperature = value?.minTemperature;

    const isHigh =
        !isUndefined(currentTemperature) && !isUndefined(maxTemperature) && currentTemperature > maxTemperature;

    const isLow =
        !isUndefined(currentTemperature) && !isUndefined(minTemperature) && currentTemperature < minTemperature;

    if (isHigh || isLow) {
        const dataIdSuffix = isHigh ? 'high' : 'low';

        const className = classNames(classes.root, { [classes.high]: isHigh, [classes.low]: isLow });

        return (
            <span className={className} data-id={`temperature-field:${dataIdSuffix}`}>
                <TemperatureFormatter value={currentTemperature} unitSystem={unitSystem} t={t} />
            </span>
        );
    } else {
        return <TemperatureFormatter value={currentTemperature} unitSystem={unitSystem} t={t} />;
    }
};
