import { createAction } from 'redux-actions';

import { ActionTypeKeys } from './actionTypes';

export const updateEtherealAction = createAction(
    ActionTypeKeys.ETHEREAL_UPDATE,
    (key: string, value: object) => ({
        key,
        value,
    }),
    (key: string) => key
);
