import { createCancelableAction } from '~/common';
import type { AssetType } from '~/services/ApiClient';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { TrailerEventsPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

interface RetrieveTrailerEventsArgs {
    assetId: number;
    assetType: AssetType;
    startDate: Date;
    stopDate: Date;
}

export const retrieveTrailerEventsAction = createCancelableAction<TrailerEventsPayload, RetrieveTrailerEventsArgs>(
    ActionTypePrefixes.TRAILEREVENT,
    ({ assetId, assetType, startDate, stopDate }, signal) =>
        retryableRequest(() => ApiClient.getTrailerEvents(assetId, assetType, startDate, stopDate, signal))
);
