import type { StaticDataStoreState } from '~/common';

import { ACTION_TYPE_KEYS } from './actionTypes';
import type { ActionTypes, TachoLincStatusState } from './models';
import { resolveTachoLincStatus } from './reducers.resolveTachoLincStatus';

const initialState: TachoLincStatusState = {
    pending: false,
    error: false,
    items: [],
};

export const tachoLincStatusSceneReducer = (
    state = initialState,
    action: ActionTypes,
    staticDataState: StaticDataStoreState
): TachoLincStatusState => {
    switch (action.type) {
        case ACTION_TYPE_KEYS.LIST_PENDING:
            return { ...state, pending: true, error: false };
        case ACTION_TYPE_KEYS.LIST_FULFILLED:
            return {
                items: (action.payload?.items || []).map((status) => resolveTachoLincStatus(status, staticDataState)),
                pending: false,
                error: false,
            };
        case ACTION_TYPE_KEYS.LIST_REJECTED:
            return { ...state, error: true, pending: false };
        case ACTION_TYPE_KEYS.RESET:
            return { items: [], error: false, pending: false };
        default:
            return state;
    }
};
