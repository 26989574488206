import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { MenuBarInnerProps, MenuBarProps } from './component';
import { MenuBarComponent } from './component';
import { MenuBarStyleRules } from './styles';

export const MenuBar = compose<MenuBarInnerProps, MenuBarProps>(
    setDisplayName('MenuBar'),
    withStyles(MenuBarStyleRules)
)(MenuBarComponent);
