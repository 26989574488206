import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import { formatTime } from '~/services/Formatters';

export interface TimeFormatterProps {
    value?: Date;
}

export interface TimeFormatterInnerProps extends TimeFormatterProps {}

const TimeFormatter: React.FC<TimeFormatterInnerProps> = ({ value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatTime(value)}</>;
};
TimeFormatter.displayName = 'TimeFormatter';

export { TimeFormatter };
