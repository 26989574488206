import { Divider } from '@mui/material';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BannerType, TopBanner } from '~/components/Dialogs';
import type { SendMessageOptions, WorkflowMessageBody } from '~/services/ApiClient';
import {
    OptimisticOutgoingConversationMessage,
    OptimisticOutgoingConversationMessageStatus,
} from '~/services/ApiClient';

import { SendWorkflowMessageForm } from '../../../../../SendWorkflowMessageForm';

import type { InternalState, WorkflowMessageSendSubpageInnerProps } from './models';

export const WorkflowMessageSendSubpageComponent: FC<WorkflowMessageSendSubpageInnerProps> = (props) => {
    const {
        sendWorkflowMessage,
        shouldClose,
        onSendOptionsWarning,
        onClosed,
        onCloseRejected,
        messages,
        discardMessage,
        t,
    } = props;

    const [state, setState] = useState<InternalState>({});

    const handleSend = useCallback(
        (messageBody: WorkflowMessageBody, options: SendMessageOptions) => {
            const sendingMessage = sendWorkflowMessage(messageBody, options);
            setState((s) => ({ ...s, sendingMessage }));
        },
        [sendWorkflowMessage]
    );

    // Create effect which makes sure the last version of the optimistic message is available in the state
    useEffect(() => {
        const currentSendingMessage = messages.find(
            (m) =>
                m.value instanceof OptimisticOutgoingConversationMessage &&
                m.value.correlationId === state.sendingMessage?.correlationId
        )?.value as OptimisticOutgoingConversationMessage;
        if (currentSendingMessage && currentSendingMessage !== state.sendingMessage) {
            setState((s) => ({ ...s, sendingMessage: currentSendingMessage }));
        }
    }, [state.sendingMessage, setState, messages]);

    // Create effect which makes sure the last version of the optimistic message is available in the state
    useEffect(() => {
        const currentSendingMessage = messages.find(
            (m) =>
                m.value instanceof OptimisticOutgoingConversationMessage &&
                m.value.correlationId === state.sendingMessage?.correlationId
        )?.value as OptimisticOutgoingConversationMessage;
        if (currentSendingMessage && currentSendingMessage !== state.sendingMessage) {
            setState((s) => ({ ...s, sendingMessage: currentSendingMessage }));
        }
    }, [messages, state.sendingMessage, setState]);

    useEffect(() => {
        if (
            state.sendingMessage &&
            state.sendingMessage.status !== OptimisticOutgoingConversationMessageStatus.Sending &&
            state.sendingMessage.status !== OptimisticOutgoingConversationMessageStatus.Pending
        ) {
            if (!state.messageDiscarded) {
                discardMessage(state.sendingMessage);
            }
            setState((s) => ({ ...s, showWarnings: true, messageDiscarded: true }));
        }
    }, [setState, onClosed, state.sendingMessage, state.messageDiscarded, discardMessage]);

    const sendWorkflowWarning = useMemo(() => {
        const dismissWarnings = () => setState((s) => ({ ...s, showWarnings: false }));
        const { title, dataId } =
            state.sendingMessage?.status === OptimisticOutgoingConversationMessageStatus.ValidationFailure
                ? {
                      title: t('message-has-invalid-content'),
                      dataId: 'validation-errors',
                  }
                : state.sendingMessage?.status === OptimisticOutgoingConversationMessageStatus.GeneralFailure
                  ? {
                        title: t('message-could-not-be-processed'),
                        dataId: 'send-failed',
                    }
                  : { title: undefined, dataId: undefined };

        return state.showWarnings && title && dataId ? (
            <>
                <TopBanner title={title} dataId={dataId} onDismiss={dismissWarnings} type={BannerType.Warning} />
                <Divider />
            </>
        ) : undefined;
    }, [state.sendingMessage?.status, state.showWarnings, t]);

    return (
        <>
            {sendWorkflowWarning}
            <SendWorkflowMessageForm
                onClose={onClosed}
                onSend={handleSend}
                onSendOptionsWarning={onSendOptionsWarning}
                shouldClose={shouldClose}
                onCloseRejected={onCloseRejected}
                messageStatus={state.sendingMessage?.status}
            />
        </>
    );
};
