import type { ComponentType, FC, ReactNode } from 'react';
import { createContext } from 'react';

interface DetailsPaneContextProps {
    openSubpage: (Subpage: ReactNode) => void;
    close: () => void;
    goBack?: () => void;
}

interface InjectedDetailsPaneContextProps {
    detailsPaneContext: DetailsPaneContextProps;
}

interface DetailsPaneSectionsProps {
    dragHandleElement?: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

const DetailsPaneContext = createContext<DetailsPaneContextProps>({
    openSubpage: () => {
        throw new Error('Not implemented');
    },
    close: () => {
        throw new Error('Not implemented');
    },
});
DetailsPaneContext.displayName = 'DetailsPaneContext';

const withDetailsPaneContext =
    () =>
    <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof InjectedDetailsPaneContextProps>> =>
    (props) => {
        return (
            <DetailsPaneContext.Consumer>
                {(context) => <Component {...(props as P)} detailsPaneContext={context} />}
            </DetailsPaneContext.Consumer>
        );
    };

export type { DetailsPaneContextProps, DetailsPaneSectionsProps, InjectedDetailsPaneContextProps };
export { DetailsPaneContext, withDetailsPaneContext };
