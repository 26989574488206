import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { PositionInputProps } from './component';

export type PositionInputClassKey = 'markerIcon' | 'markerIconDisabled';

export const PositionInputStyleRules: StyleRulesCallback<Theme, PositionInputProps, PositionInputClassKey> = (
    theme: Theme
) =>
    createStyles({
        markerIcon: {
            cursor: 'pointer',
        },
        markerIconDisabled: {
            pointerEvents: 'none',
            color: theme.palette.text.disabled,
        },
    });
