import { Tooltip } from '@mui/material';
import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { AssetType } from '~/services/ApiClient';
import { formatAssetTypeTitle } from '~/services/Formatters';

import { AssetTypeIconFormatter } from './components/AssetAvatarIcon';

export interface AssetTypeFormatterProps {
    t: SingleTFunction;
    value: AssetType;
}

export interface AssetTypeFormatterInnerProps extends AssetTypeFormatterProps {}

const AssetTypeFormatter: React.FunctionComponent<AssetTypeFormatterInnerProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }
    return (
        <Tooltip title={formatAssetTypeTitle(t, value)}>
            <span data-id={`asset-type:${value}`}>
                <AssetTypeIconFormatter value={value} />
            </span>
        </Tooltip>
    );
};
AssetTypeFormatter.displayName = 'AssetTypeFormatter';

export { AssetTypeFormatter };
