import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
    flex: {
        display: 'flex',
    },
    activityAvatar: {
        width: 22,
        height: 22,
        fontSize: 14,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        alignSelf: 'flex-end',
        marginLeft: theme.spacing(-1),
    },
    disabled: {
        opacity: 0.38,
    },
    driver: {
        display: 'flex',
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    driverAvatar: {
        width: 24,
        height: 24,
        fontSize: 18,
        color: theme.functionalItemsColors.assetType.driver.value,
        backgroundColor: theme.functionalItemsColors.assetType.driver.contrast,
        border: `1px solid ${theme.palette.text.secondary}`,
    },
    driverIcon: {
        fontSize: 14,
    },
}));
