import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { ActionTypes as CommunicationMessagesActionTypes, CommunicationStoreState } from '~/data/communication';
import { ActionTypeKeys as CommunicationMessagesActionTypeKeys, mergeMessagesUpdate } from '~/data/communication';
import { uniq } from '~/libs/utility';
import type { ActionTypes as ConversationsActionTypes, ConversationsRootStoreState } from '~/modules/Communication';
import { ActionTypeKeys as ConversationsActionTypeKeys, resolveConversationMessage } from '~/modules/Communication';
import { MessagesUpdate, WorkflowFormDefinitionStatus, createApiModel } from '~/services/ApiClient';

import { applyMessagesUpdate } from './reducers.applyMessagesUpdate';
import { resolveCommunicationEntries } from './reducers.resolveCommunicationEntries';

export const defaultStoreState: CommunicationStoreState = {
    dynamicCommunication: {
        communicationEntries: [],
        messages: {
            data: [],
            pending: false,
            fulfilled: false,
            rejected: false,
        },
        pendingMessageRequests: [],
        failedMessageRequests: [],
    },
};

type CombinedActionTypes = CommunicationMessagesActionTypes | ConversationsActionTypes;
const CombinedAction = { ...CommunicationMessagesActionTypeKeys, ...ConversationsActionTypeKeys };

export const communicationReducer = (
    state: CommunicationStoreState = defaultStoreState,
    action: CombinedActionTypes,
    conversationsRoot: ConversationsRootStoreState,
    staticDataStoreReducer: StaticDataStoreState,
    settings: SettingsStoreState
): CommunicationStoreState => {
    switch (action.type) {
        case CombinedAction.MESSAGES_QUERY_PENDING:
            return {
                ...state,
                dynamicCommunication: {
                    ...state.dynamicCommunication,
                    latestRequestParams: action.meta,
                    messages: {
                        ...state.dynamicCommunication.messages,
                        pending: true,
                    },
                },
            };

        case CombinedAction.MESSAGES_QUERY_REJECTED: {
            const isLatestResponse = state.dynamicCommunication.latestRequestParams === action.meta;
            if (!isLatestResponse) {
                return state;
            }
            return {
                ...state,
                dynamicCommunication: {
                    ...state.dynamicCommunication,
                    communicationEntries: [],
                    messages: {
                        data: [],
                        pending: false,
                        fulfilled: false,
                        rejected: true,
                    },
                },
            };
        }

        case CombinedAction.MESSAGES_QUERY_FULFILLED: {
            const isLatestResponse = state.dynamicCommunication.latestRequestParams === action.meta;
            if (!isLatestResponse) {
                return state;
            }

            const resolvedCommunicationEntries = resolveCommunicationEntries(
                action.payload.items,
                conversationsRoot,
                staticDataStoreReducer,
                settings
            );

            return {
                ...state,
                dynamicCommunication: {
                    ...state.dynamicCommunication,
                    messages: {
                        data: action.payload.items,
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                    communicationEntries: state.pendingUpdates
                        ? applyMessagesUpdate(
                              resolvedCommunicationEntries,
                              state.pendingUpdates,
                              conversationsRoot,
                              staticDataStoreReducer,
                              settings
                          )
                        : resolvedCommunicationEntries,
                },
                pendingUpdates: undefined,
            };
        }

        case CombinedAction.MESSAGES_GET_PENDING: {
            const pendingMessageRequests = uniq([
                ...state.dynamicCommunication.pendingMessageRequests,
                action.meta.messageId,
            ]);
            const failedMessageRequests = state.dynamicCommunication.failedMessageRequests.filter(
                (it) => it !== action.meta.messageId
            );

            return {
                ...state,
                dynamicCommunication: {
                    ...state.dynamicCommunication,
                    pendingMessageRequests,
                    failedMessageRequests,
                },
            };
        }

        case CombinedAction.MESSAGES_GET_REJECTED: {
            const pendingMessageRequests = state.dynamicCommunication.pendingMessageRequests.filter(
                (it) => it !== action.meta.messageId
            );

            const failedMessageRequests = uniq([
                ...state.dynamicCommunication.failedMessageRequests,
                action.meta.messageId,
            ]);

            return {
                ...state,
                dynamicCommunication: {
                    ...state.dynamicCommunication,
                    pendingMessageRequests,
                    failedMessageRequests,
                },
            };
        }

        case CombinedAction.MESSAGES_GET_FULFILLED: {
            const pendingMessageRequests = state.dynamicCommunication.pendingMessageRequests.filter(
                (it) => it !== action.meta.messageId
            );

            const failedMessageRequests = state.dynamicCommunication.failedMessageRequests.filter(
                (it) => it !== action.meta.messageId
            );

            let pendingUpdate: MessagesUpdate;

            let communicationEntries = [...state.dynamicCommunication.communicationEntries];
            if (action.payload?.item) {
                if (action.payload.item.isDeleted && !action.meta.includeDeleted) {
                    pendingUpdate = createApiModel(MessagesUpdate, {
                        updatedMessages: [],
                        deletedMessages: [action.payload.item.id],
                    });
                } else {
                    pendingUpdate = createApiModel(MessagesUpdate, {
                        updatedMessages: [action.payload.item],
                        deletedMessages: [],
                    });
                }
            } else {
                // if the user doesn't have the rights to see the deleted message, he will get 404 which will result in an empty payload
                // thus that message should be deleted
                pendingUpdate = createApiModel(MessagesUpdate, {
                    updatedMessages: [],
                    deletedMessages: [action.meta.messageId],
                });
            }

            pendingUpdate = state.pendingUpdates
                ? mergeMessagesUpdate(state.pendingUpdates, pendingUpdate)
                : pendingUpdate;

            communicationEntries = applyMessagesUpdate(
                communicationEntries,
                pendingUpdate,
                conversationsRoot,
                staticDataStoreReducer,
                settings
            );

            return {
                ...state,
                dynamicCommunication: {
                    ...state.dynamicCommunication,
                    communicationEntries,
                    pendingMessageRequests,
                    failedMessageRequests,
                },
            };
        }

        case CombinedAction.MESSAGES_CLEARFAILEDREQUESTS:
            return {
                ...state,
                dynamicCommunication: {
                    ...state.dynamicCommunication,
                    failedMessageRequests: [],
                },
            };

        case CombinedAction.MESSAGES_UPDATE: {
            return {
                ...state,
                pendingUpdates: state.pendingUpdates
                    ? mergeMessagesUpdate(state.pendingUpdates, action.payload)
                    : action.payload,
            };
        }

        case CombinedAction.COMMUNICATION_CLEAR_DATA:
            return {
                ...defaultStoreState,
                dateTimeRange: state.dateTimeRange,
            };

        case CombinedAction.COMMUNICATION_CLEAR_PENDING: {
            return {
                ...state,
                pendingUpdates: undefined,
            };
        }

        case CombinedAction.COMMUNICATION_APPLY_PENDING: {
            if (!state.dynamicCommunication.messages.fulfilled || !state.pendingUpdates) {
                return state;
            }

            return {
                ...state,
                dynamicCommunication: {
                    ...state.dynamicCommunication,
                    communicationEntries: applyMessagesUpdate(
                        state.dynamicCommunication.communicationEntries,
                        state.pendingUpdates,
                        conversationsRoot,
                        staticDataStoreReducer,
                        settings
                    ),
                },
                pendingUpdates: undefined,
            };
        }

        case CombinedAction.COMMUNICATION_CHANGE_DATETIMERANGE:
            return {
                ...state,
                dateTimeRange: action.payload,
            };

        case CombinedAction.WORKFLOWFORMDEFINITIONS_LATEST_FULFILLED:
        case CombinedAction.WORKFLOWFORMDEFINITION_FULFILLED:
        case CombinedAction.WORKFLOWFORMDEFINITION_PENDING:
        case CombinedAction.WORKFLOWFORMDEFINITION_REJECTED: {
            if (!conversationsRoot.workflowFormDefinitions.fulfilled) {
                return state;
            }
            return {
                ...state,
                dynamicCommunication: {
                    ...state.dynamicCommunication,
                    communicationEntries: state.dynamicCommunication.communicationEntries.map((communicationEntry) => {
                        if (
                            communicationEntry.message.body.workflowFormDefinitionStatus ===
                                WorkflowFormDefinitionStatus.REQUIRED ||
                            communicationEntry.message.body.workflowFormDefinitionStatus ===
                                WorkflowFormDefinitionStatus.PENDING
                        ) {
                            const updatedMessage = resolveConversationMessage(
                                communicationEntry.message.value,
                                conversationsRoot.workflowFormDefinitions.data
                            );

                            if (
                                updatedMessage.body.workflowFormDefinitionStatus ===
                                communicationEntry.message.body.workflowFormDefinitionStatus
                            ) {
                                return communicationEntry;
                            }

                            return {
                                ...communicationEntry,
                                message: updatedMessage,
                            };
                        }
                        return communicationEntry;
                    }),
                },
            };
        }

        default:
            return state;
    }
};
