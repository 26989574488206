import classNames from 'classnames';
import type { FC } from 'react';
import { Popup } from 'react-leaflet';

import type { StyledPopupProps } from './models';
import { useStyles } from './styles';

const StyledPopup: FC<StyledPopupProps> = (props) => {
    const { forwardedRef, children, className, ...restProps } = props;
    const classes = useStyles();

    return (
        <Popup ref={forwardedRef} className={classNames(classes.popup, className)} {...restProps}>
            {children}
        </Popup>
    );
};

StyledPopup.displayName = 'StyledPopup';
export default StyledPopup;
