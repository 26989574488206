import { memoizeOne } from '~/services/Memoize';

import { CompanySection } from './components/CompanySection';
import { GeneralSection } from './components/GeneralSection';
import { HeaderSection } from './components/HeaderSection';
import { IssuedSection } from './components/IssuedSection';
import { ValiditySection } from './components/ValiditySection';
import { SectionName } from './constants';
import type { GetSectionArgs } from './models';

const getSections = ({ collapsedSections, toggleCollapsedState, openCompanyCardEditDialog, entry }: GetSectionArgs) => {
    return [
        {
            name: SectionName.HEADER,
            content: () => <HeaderSection entry={entry} openCompanyCardEditDialog={openCompanyCardEditDialog} />,
        },
        {
            name: SectionName.GENERAL,
            content: (dragHandleElement: JSX.Element) => (
                <GeneralSection
                    adminCompanyCard={entry}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.GENERAL)}
                    toggleCollapsed={toggleCollapsedState(SectionName.GENERAL)}
                />
            ),
        },
        {
            name: SectionName.VALIDITY,
            content: (dragHandleElement: JSX.Element) => (
                <ValiditySection
                    adminCompanyCard={entry}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.VALIDITY)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VALIDITY)}
                />
            ),
        },
        {
            name: SectionName.ISSUED,
            content: (dragHandleElement: JSX.Element) => (
                <IssuedSection
                    adminCompanyCard={entry}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.ISSUED)}
                    toggleCollapsed={toggleCollapsedState(SectionName.ISSUED)}
                />
            ),
        },
        {
            name: SectionName.COMPANY,
            content: (dragHandleElement: JSX.Element) => (
                <CompanySection
                    adminCompanyCard={entry}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.COMPANY)}
                    toggleCollapsed={toggleCollapsedState(SectionName.COMPANY)}
                />
            ),
        },
    ];
};

export const getSectionsMemoized = memoizeOne(getSections);
