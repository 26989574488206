import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';

export type ReorderableSectionsClassKey = 'droppable' | 'draggable' | 'dragged' | 'hidden' | 'hiddenSection';

export const ReorderableSectionsStyleRules: StyleRulesCallback<Theme, {}, ReorderableSectionsClassKey> = (
    theme: Theme
) => {
    return {
        droppable: {
            backgroundColor: theme.palette.divider,
        },
        draggable: {
            backgroundColor: theme.palette.background.paper,
        },
        dragged: {
            boxShadow: theme.shadows[2],
        },
        hidden: {
            visibility: 'hidden',
        },
        hiddenSection: {
            display: 'none',
        },
    };
};
