import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type MenuBarClassKey = 'menuContainer' | 'icon';

export const MenuBarStyleRules: StyleRulesCallback<Theme, {}, MenuBarClassKey> = (theme: Theme) => {
    return createStyles({
        menuContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            textAlign: 'center',
            width: theme.custom.menu.width,
            paddingTop: theme.spacing(),
            height: '100vh',
            backgroundColor: theme.menuBar.background,
        },
        icon: {
            marginBottom: 10,
            color: theme.menuBar.iconColor,
        },
    });
};
