import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type StopPositionGroupClassKey = 'splitRow' | 'leftColumn' | 'rightColumn' | 'nowrap';

export const StopPositionGroupStyleRules: StyleRulesCallback<Theme, {}, StopPositionGroupClassKey> = () =>
    createStyles({
        splitRow: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        },
        leftColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        rightColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        nowrap: {
            whiteSpace: 'nowrap',
        },
    });
