import { CircularProgress, Typography } from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { ConfirmationDialog } from '~/components/Dialogs';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { deleteRtdsScheduleAction } from '~/data/rtdsschedules';
import { reportError } from '~/reporting';

import { constructRtdsScheduleUrl } from '../../constructRtdsScheduleUrl';

import { useStyles } from './styles';

export interface DeleteRtdsScheduleDialogProps {
    onClose: () => void;
    onDelete: () => void;
    scheduleId: number;
}

export interface DeleteRtdsScheduleDialogInnerProps extends DeleteRtdsScheduleDialogProps, InjectedTranslationProps {}

export const DeleteRtdsScheduleDialogComponent: FC<DeleteRtdsScheduleDialogInnerProps> = (props) => {
    const { t, scheduleId, onClose, onDelete } = props;

    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [status, setStatus] = useState({ isLoading: false, error: false });

    const deleteSchedule = () => {
        setStatus({ isLoading: true, error: false });

        dispatch(deleteRtdsScheduleAction(scheduleId))
            .then(() => {
                setStatus({ isLoading: false, error: false });
                onClose();
                onDelete();
                history.push(constructRtdsScheduleUrl());
            })
            .catch((error) => {
                reportError(error);
                setStatus({ isLoading: false, error: true });
            });
    };

    return (
        <ConfirmationDialog
            title={t('delete-rtds-schedule-action')}
            open
            onConfirm={deleteSchedule}
            onCancel={onClose}
            confirmationActionText={t('delete-rtds-schedule-action')}
            dataId="delete-rtds-schedule-dialog"
            isDisabled={status?.isLoading}
        >
            <>
                <Typography>{t('delete-rtds-schedule-dialog-text')}</Typography>
                {status.error && (
                    <Typography variant="caption" color="error" data-id="delete-error-message">
                        {t('generic-error-message')}
                    </Typography>
                )}
                {status.isLoading && (
                    <div className={classes.messageContainer}>
                        <CircularProgress size={30} className={classes.spinner} />
                        <Typography variant="subtitle2" data-id="delete-progress-message" className={classes.message}>
                            {t('delete-schedule-progress-message')}
                        </Typography>
                    </div>
                )}
            </>
        </ConfirmationDialog>
    );
};
