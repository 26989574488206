import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { HeaderSectionInnerProps, HeaderSectionProps } from './component';
import { HeaderSectionComponent } from './component';

export const HeaderSection = compose<HeaderSectionInnerProps, HeaderSectionProps>(
    setDisplayName('HeaderSection'),
    withDisplayPreferences(),
    withTranslation()
)(HeaderSectionComponent);
