import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationsSummaryWidgetClassKey =
    | 'content'
    | 'listContainer'
    | 'scrollToTopFab'
    | 'scrollToTopFabVisible'
    | 'actionButton';

export const widgetWidth = 264;
export const widgetHeight = 465;

export const ConversationsSummaryWidgetStyleRules: StyleRulesCallback<Theme, {}, ConversationsSummaryWidgetClassKey> = (
    theme: Theme
) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            '@global .ReactVirtualized__Grid__innerScrollContainer': {
                marginBottom: '48px !important',
            },
        },
        listContainer: {
            position: 'relative',
            overflow: 'hidden',
            flexGrow: 1,
        },
        scrollToTopFab: {
            width: 24,
            height: 24,
            minHeight: 24,
            position: 'absolute',
            left: 'calc(50% - 12px)',
            bottom: -50,
            transition: '250ms',
        },
        scrollToTopFabVisible: {
            bottom: theme.spacing(1.5),
        },
        actionButton: {
            width: 24,
            height: 24,
            fontSize: 14,
            padding: 0,
            marginLeft: theme.spacing(0.5),
        },
    });
