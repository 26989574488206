import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { TemperatureStatusIndicatorInnerProps, TemperatureStatusIndicatorOuterProps } from './component';
import { TemperatureStatusIndicatorComponent } from './component';
import { TemperatureStatusIndicatorStyleRules } from './styles';

export const TemperatureStatusIndicator = compose<
    TemperatureStatusIndicatorInnerProps,
    TemperatureStatusIndicatorOuterProps
>(
    setDisplayName('TemperatureStatusIndicator'),
    withStyles(TemperatureStatusIndicatorStyleRules)
)(TemperatureStatusIndicatorComponent);
