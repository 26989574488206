import type { Context } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { difference, intersection, isEmpty, isUndefined, omit } from '../utility';

import { ALL } from './consts';
import type {
    AssetSelectorMethods,
    AssetSelectorProviderInternalSetter,
    AssetSelectorProviderProps,
    AssetSelectorProviderState,
    BaseItem,
    GenericItem,
    GenericOnHandlersArgs,
    OnCheckArgs,
} from './models';
import { buildDictionary, getAllParentsIds, getChildrenIds, safeFilterTreeByName, updateTreeNodes } from './utils';

const AssetSelectorState = createContext<AssetSelectorProviderState<unknown> | undefined>(undefined);
const AssetSelectorSetter = createContext<AssetSelectorProviderInternalSetter | undefined>(undefined);

const AssetSelectorProvider = <T extends BaseItem<T>>(props: AssetSelectorProviderProps<T>) => {
    const {
        children,
        expandedKeys,
        items,
        singleSelectionMode,
        searchingItemsName,
        checkablePredicate,
        checkedKeys,
        excludeHeaderTabs = false,
        ...restProps
    } = props;

    const getUpdatedTreeNodes = useCallback(
        (previousItems: GenericItem<T>[], nextCheckedIds: { [key: string | symbol]: string | number }) =>
            updateTreeNodes(checkablePredicate, previousItems, nextCheckedIds),
        [checkablePredicate]
    );

    const getChildrenIdsWithPredicate = useCallback(
        (sourceItems) =>
            getChildrenIds(sourceItems, (item) => (checkablePredicate ? checkablePredicate(item as T) : false)),
        [checkablePredicate]
    );

    const initialCheckableItems = useMemo(
        () => getChildrenIdsWithPredicate(items),
        [getChildrenIdsWithPredicate, items]
    );

    const nextCheckedIds = useMemo(
        () => (isUndefined(checkedKeys) ? buildDictionary(initialCheckableItems) : buildDictionary(checkedKeys)),
        [checkedKeys, initialCheckableItems]
    );

    const { updatedTree, newCheckedKeys } = getUpdatedTreeNodes(items, nextCheckedIds);
    const hideExpandAllButton = items.every((item) => !item.children?.length);

    const [methods] = useState<AssetSelectorMethods<T>>(restProps);
    const [state, setState] = useState<AssetSelectorProviderState<T>>({
        items: updatedTree,
        query: '',
        searchingItemsName,
        singleSelectionMode,
        checkedKeys: newCheckedKeys,
        expandedAll: expandedKeys?.length > 0,
        expandedKeys: isEmpty(expandedKeys) ? {} : buildDictionary(expandedKeys),
        searchEnabled: false,
        selectedAll: isUndefined(checkedKeys) ? true : checkedKeys.length === items.length,
        selectedCheckableCount: intersection(Object.values(initialCheckableItems), Object.values(newCheckedKeys))
            .length,
        totalItems: initialCheckableItems.length,
        excludeHeaderTabs,
        hideExpandAllButton,
        checkableItemsCount: 0,
    });

    useEffect(() => {
        const allIds = getChildrenIdsWithPredicate(items);

        if (isUndefined(checkedKeys)) {
            restProps.onCheck(Object.values(state.checkedKeys));
        }

        setState((prev) => {
            const currentIds = getChildrenIdsWithPredicate(prev.items);
            const missingIds = difference(currentIds, allIds);
            const newCheckKeys = omit(prev.checkedKeys, missingIds);
            const newExpandKeys = omit(prev.expandedKeys, missingIds);

            if (!isEmpty(missingIds)) {
                restProps.onCheck(Object.values(newCheckKeys));
            }

            const { updatedTree: newItemsUpdatedTree, newCheckedKeys: newItemsNewCheckedKeys } = getUpdatedTreeNodes(
                safeFilterTreeByName(props.items, prev.query),
                newCheckKeys
            );

            const checkableItems = getChildrenIdsWithPredicate(newItemsUpdatedTree);

            return {
                ...prev,
                items: newItemsUpdatedTree,
                selectedAll: Object.keys(newCheckKeys).length === allIds.length,
                selectedCheckableCount: intersection(
                    Object.values(initialCheckableItems),
                    Object.values(newCheckedKeys)
                ).length,
                totalItems: allIds.length,
                checkedKeys: prev.query ? newCheckKeys : newItemsNewCheckedKeys,
                expandedKeys: newExpandKeys,
                checkableItemsCount: checkableItems.length,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const onClick = (args: GenericOnHandlersArgs) => {
        if (!singleSelectionMode) {
            return;
        }

        setState((prev) => {
            const hashValues = buildDictionary([args.id as string]);
            const newCheckIds = args.id ? hashValues : {};
            methods.onCheck([args.id as string]);

            return { ...prev, checkedKeys: newCheckIds };
        });
    };

    const handleCheck = (args: OnCheckArgs<T>) => {
        const { id, isLeaf, value, source = [] } = args;

        if (id === ALL) {
            setState((prev) => {
                const ids = getChildrenIdsWithPredicate(prev.items);
                const hashValues = buildDictionary(ids);
                const newCheckIds = value ? hashValues : {};
                methods.onCheck(Object.values(newCheckIds));

                return {
                    ...prev,
                    selectedAll: value,
                    checkedKeys: newCheckIds,
                    selectedCheckableCount: intersection(Object.values(ids), Object.values(newCheckIds)).length,
                };
            });

            return;
        }

        if (isLeaf) {
            setState((prev) => {
                const allIds = getChildrenIdsWithPredicate(prev.items);
                const newCheckIds = value ? { ...prev.checkedKeys, [id]: id } : omit(prev.checkedKeys, [id]);
                const { updatedTree: leafOnCheckUpdatedTree, newCheckedKeys: leafOnCheckNewCheckedKeys } =
                    getUpdatedTreeNodes(prev.items, newCheckIds);

                const actualCheckedKeys = prev.query ? newCheckIds : leafOnCheckNewCheckedKeys;

                methods.onCheck(Object.values(actualCheckedKeys));

                const selectedCheckableCount = intersection(
                    Object.values(allIds),
                    Object.values(actualCheckedKeys)
                ).length;

                return {
                    ...prev,
                    items: leafOnCheckUpdatedTree,
                    selectedAll: selectedCheckableCount >= allIds.length,
                    selectedCheckableCount,
                    checkedKeys: actualCheckedKeys,
                    checkableItemsCount: allIds.length,
                };
            });

            return;
        }

        setState((prev) => {
            const allIds = getChildrenIdsWithPredicate(prev.items);
            const ids = [id, ...getChildrenIdsWithPredicate(source)];
            const hashValues = buildDictionary(ids);
            const newCheckIds = value ? { ...prev.checkedKeys, ...hashValues } : omit(prev.checkedKeys, ids);

            const { updatedTree: defaultOnCheckUpdatedTree, newCheckedKeys: defaultOnCheckNewCheckedKeys } =
                getUpdatedTreeNodes(prev.items, newCheckIds);

            const actualCheckedKeys = prev.query ? newCheckIds : defaultOnCheckNewCheckedKeys;
            methods.onCheck(Object.values(actualCheckedKeys));

            const selectedCheckableCount = intersection(Object.values(allIds), Object.values(actualCheckedKeys)).length;

            return {
                ...prev,
                items: defaultOnCheckUpdatedTree,
                selectedAll: selectedCheckableCount >= allIds.length,
                selectedCheckableCount,
                checkedKeys: actualCheckedKeys,
                checkableItemsCount: allIds.length,
            };
        });
    };

    const handleCollapse = (args: GenericOnHandlersArgs) => {
        const { id, value } = args;

        if (id === ALL) {
            setState((prev) => {
                const ids = value ? getAllParentsIds(prev.items) : [];
                const hashValue = buildDictionary(ids);
                methods.onCollapse(Object.values(hashValue));

                return { ...prev, expandedAll: value, expandedKeys: hashValue };
            });

            return;
        }

        setState((prev) => {
            const hasValues = value ? { ...prev.expandedKeys, [id]: id } : omit(prev.expandedKeys, [id]);
            methods.onCollapse(Object.values(hasValues));

            return {
                ...prev,
                expandedAll: Object.values(hasValues).length > 0,
                expandedKeys: hasValues,
            };
        });
    };

    const handleSearch = (query: string) => {
        const nextItems = !query ? items : safeFilterTreeByName(items, query);
        const { updatedTree: handleSearchUpdatedTree } = getUpdatedTreeNodes(nextItems, nextCheckedIds);

        const allCheckableIds = getChildrenIdsWithPredicate(!query ? props.items : handleSearchUpdatedTree);

        setState((prev) => ({
            ...prev,
            query,
            items: handleSearchUpdatedTree,
            checkableItemsCount: allCheckableIds.length,
        }));
    };

    const toggleSearch = (isOpen: boolean) => {
        const { updatedTree: toggleSearchUpdatedTree, newCheckedKeys: toggleSearchNewCheckedKeys } =
            getUpdatedTreeNodes(updatedTree, state.checkedKeys as { [key: string]: string | number });

        const allCheckableIds = getChildrenIdsWithPredicate(updatedTree);

        const selectedCheckableCount = intersection(
            Object.values(allCheckableIds),
            Object.values(toggleSearchNewCheckedKeys)
        ).length;

        setState((prev) => ({
            ...prev,
            ...(!isOpen ? { checkedKeys: toggleSearchNewCheckedKeys } : {}),
            query: '',
            selectedCheckableCount,
            items: toggleSearchUpdatedTree,
            searchEnabled: isOpen,
        }));
    };

    const memoizedMethods = useMemo<AssetSelectorProviderInternalSetter>(
        () => ({
            onCheck: handleCheck,
            onCollapse: handleCollapse,
            changeSearchQuery: handleSearch,
            toggleSearch,
            onClick,
            checkablePredicate,
            onContextMenu: methods.onContextMenu,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [methods, items]
    );

    return (
        <AssetSelectorState.Provider value={state}>
            <AssetSelectorSetter.Provider value={memoizedMethods}>{children}</AssetSelectorSetter.Provider>
        </AssetSelectorState.Provider>
    );
};

const useAssetSelectorState = <T,>() => {
    const context = useContext(AssetSelectorState as Context<AssetSelectorProviderState<T> | undefined>);

    if (context === undefined) {
        throw new Error('useAssetSelectorState can only be used inside UserProvider');
    }

    return context;
};

const useAssetSelectorSetter = () => {
    const context = useContext(AssetSelectorSetter);

    if (context === undefined) {
        throw new Error('useAssetSelectorSetter can only be used inside UserProvider');
    }

    return context;
};

const useAssetSelector = <T,>(): [AssetSelectorProviderState<T>, AssetSelectorProviderInternalSetter<T>] => [
    useAssetSelectorState<T>(),
    useAssetSelectorSetter(),
];

AssetSelectorProvider.displayName = 'AssetSelectorProvider';

export { AssetSelectorProvider, useAssetSelectorState, useAssetSelectorSetter, useAssetSelector };
