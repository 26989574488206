import { Divider } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import type { ListRowProps, ScrollParams } from 'react-virtualized';
import { List } from 'react-virtualized';

import type { AutoSizerInjectedProps } from '~/components/AutoSizer';
import type { DisplayUserPreferencesVehicleDisplayFormat } from '~/services/ApiClient';

import type { ConversationSummaryEntry } from '../../conversationSummaryEntry';

import { ConversationSummary } from './components/ConversationSummary';
import type { ConversationsSummaryWidgetListClassKey } from './styles';

export interface ConversationsSummaryWidgetListProps {
    items: ConversationSummaryEntry[];
    scrollTop: number;
    onScroll: (params: ScrollParams) => void;
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat;
    openConversation: (vehicleId: number) => void;
    retrieveWorkflowFormDefinition: (formId: number) => void;
}

export interface ConversationsSummaryWidgetListInnerProps
    extends ConversationsSummaryWidgetListProps,
        AutoSizerInjectedProps,
        WithStyles<ConversationsSummaryWidgetListClassKey> {}

export const ConversationsSummaryWidgetListComponent: React.FC<ConversationsSummaryWidgetListInnerProps> = ({
    classes,
    size,
    items,
    scrollTop,
    onScroll,
    vehicleDisplayFormat,
    openConversation,
    retrieveWorkflowFormDefinition,
}) => {
    const renderRow = ({ index, style }: ListRowProps) => {
        const conversationSummaryEntry = items[index];
        const shouldShowDivider = index !== items.length - 1;

        return (
            <div key={conversationSummaryEntry.vehicle.id} style={style}>
                <ConversationSummary
                    conversationSummaryEntry={conversationSummaryEntry}
                    vehicleDisplayFormat={vehicleDisplayFormat}
                    openConversation={openConversation}
                    retrieveWorkflowFormDefinition={retrieveWorkflowFormDefinition}
                />
                {shouldShowDivider && <Divider className={classes.listItemDivider} />}
            </div>
        );
    };

    return (
        <div data-id="conversation-summaries-list">
            <List
                className={classes.list}
                height={size.height}
                width={size.width}
                rowHeight={56}
                rowRenderer={renderRow}
                rowCount={items.length}
                scrollTop={scrollTop}
                onScroll={onScroll}
            />
        </div>
    );
};
