import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

import type { CustomIconInnerProps } from '~/components/Icons';

const PaperGearIcon: FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" sx={{ fill: 'transparent' }}>
            <path d="M12 3v4a1 1 0 0 0 1 1h4" />
            <path d="M11.5 21h-6.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v2m0" />

            <g transform="scale(0.7) translate(12.5, 12.5)">
                <SvgIcon
                    {...props}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    sx={{ fill: 'transparent' }}
                >
                    <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                    <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" fill="currentColor" />
                </SvgIcon>
            </g>
        </SvgIcon>
    );
};

PaperGearIcon.displayName = 'PaperGearIcon';
export { PaperGearIcon };
