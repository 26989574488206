import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type PositionGroupClassKey =
    | 'avatar'
    | 'avatarSmaller'
    | 'parkAvatar'
    | 'parkItem'
    | 'clickableItem'
    | 'loaderContainer'
    | 'messageText'
    | 'toggleButton'
    | 'positionTimelineItem';

export const PositionGroupStyleRules: StyleRulesCallback<Theme, {}, PositionGroupClassKey> = (theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: '#797979',
            width: 32,
            height: 32,
            fontSize: 20,
        },
        avatarSmaller: {
            backgroundColor: '#797979',
            width: 22,
            height: 22,
            fontSize: 14,
        },
        parkAvatar: {
            backgroundColor: '#0652dd',
            width: 32,
            height: 32,
            fontSize: 20,
        },
        clickableItem: {
            cursor: 'pointer',
        },
        parkItem: {
            backgroundColor: theme.palette.background.default,
        },
        messageText: {
            display: 'flex',
            justifyContent: 'center',
            fontStyle: 'italic',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
        },
        loaderContainer: {
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(2),
        },
        toggleButton: {
            cursor: 'pointer',
        },
        positionTimelineItem: {
            borderStyle: 'none',
        },
    });
