import type { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export type DutySectionClassKey = 'simpleValue';

export const useStyles = makeStyles<Theme, {}, DutySectionClassKey>((theme: Theme) =>
    createStyles({
        simpleValue: {
            marginRight: theme.spacing(0.5),
        },
    })
);
