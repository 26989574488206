import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import * as React from 'react';
import type { Subtract } from 'utility-types';

export interface DrivingIconProps extends Subtract<SvgIconProps, { viewBox?: string }> {}

export interface DrivingIconInnerProps extends DrivingIconProps {}

const DrivingIcon: React.SFC<DrivingIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M11.41,2h1.17l.16,0A9.36,9.36,0,0,1,17.1,3.41,9.81,9.81,0,0,1,22,11.58a9.23,9.23,0,0,1-1.16,5.11,9.84,9.84,0,0,1-7.08,5.15c-.38.07-.77.11-1.16.16H11.41a.8.8,0,0,0-.15,0,9.41,9.41,0,0,1-3.95-1.15,9.81,9.81,0,0,1-5.15-7.08c-.08-.38-.11-.77-.16-1.15V11.41a.9.9,0,0,0,0-.16A9.5,9.5,0,0,1,3.17,7.31a9.87,9.87,0,0,1,7.09-5.15C10.64,2.09,11,2.05,11.41,2ZM19,9.49a7.43,7.43,0,0,0-14,0Zm-8.3,9.85a.09.09,0,0,0,0-.07,8.37,8.37,0,0,0-1.34-4.42,6.11,6.11,0,0,0-3.57-2.67C5.4,12.07,5,12,4.52,12A7.44,7.44,0,0,0,10.72,19.34ZM19.48,12c-.23,0-.45,0-.67.05A5.85,5.85,0,0,0,15,14.31a7.78,7.78,0,0,0-1.55,3.39c-.1.54-.15,1.09-.22,1.64A7.43,7.43,0,0,0,19.48,12ZM12,10.75A1.25,1.25,0,1,0,13.25,12,1.26,1.26,0,0,0,12,10.75Z" />
        </SvgIcon>
    );
};
DrivingIcon.displayName = 'DrivingIcon';

export { DrivingIcon };
