import type { TFunction } from 'i18next';

import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
} from '~/services/ApiClient';
import type { AddressFormatOptions } from '~/services/Formatters';
import {
    AddressFormat,
    formatAddress,
    formatDriverName,
    formatVehicleCategory,
    formatVehicleName,
} from '~/services/Formatters';

const firstLineOptions: AddressFormatOptions = { format: AddressFormat.FirstLine };
const secondLineOptions: AddressFormatOptions = { format: AddressFormat.SecondLine };

export const getVehicleSearchText = (
    entry: MonitoringVehicleEntry,
    vehicleNameDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat,
    driverNameDisplayFormat: DisplayUserPreferencesDriverDisplayFormat,
    t: TFunction
): string => {
    const { vehicle, vehicleStatus, vehicleDriverHoursStatus } = entry;
    const driverName = vehicleDriverHoursStatus?.driverHoursStatus?.driver;
    const coDriverName = vehicleDriverHoursStatus?.coDriverHoursStatus?.driver;

    const name = formatVehicleName(vehicle, vehicleNameDisplayFormat);
    const category = formatVehicleCategory(t, vehicle.category);
    const connection = entry.trailerConnection ? t('connected') : t('not-connected');
    const formattedDriverName = driverName ? formatDriverName(driverName, driverNameDisplayFormat) : undefined;
    const formattedCoDriverName = coDriverName ? formatDriverName(coDriverName, driverNameDisplayFormat) : undefined;
    const address = vehicleStatus?.address ? formatAddress(vehicleStatus?.address, firstLineOptions) : undefined;
    const addressSecondLine = vehicleStatus?.address
        ? formatAddress(vehicleStatus?.address, secondLineOptions)
        : undefined;

    return `${name}|${category}|${connection}|${formattedDriverName}|${formattedCoDriverName}|${address}|${addressSecondLine}`.toLowerCase();
};
