import { Status as RetryStatus } from '~/components/RetryAction';
import { Status as SaveStatus } from '~/services/SaveUserPreferences';

export const mapRetryStatus = (saveStatus: SaveStatus, prevSaveStatus?: SaveStatus): RetryStatus => {
    if (saveStatus === SaveStatus.FAILEDTOSAVE) {
        return RetryStatus.FAILED;
    }

    if (saveStatus === SaveStatus.SAVING && prevSaveStatus === SaveStatus.FAILEDTOSAVE) {
        return RetryStatus.RETRYING;
    }

    return RetryStatus.SUCCEEDED;
};
