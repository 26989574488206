import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        height: `calc(100% - ${theme.spacing(12)})`,
        left: '50%',
        maxHeight: '600px',
        maxWidth: '700px',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        zIndex: 1,
        '& .slick-list': {
            borderRadius: theme.shape.borderRadius * 3.5,
            backgroundColor: theme.palette.grey[500],
            position: 'relative',
            display: 'block',
            overflow: 'hidden',
            margin: 0,
            padding: 0,
        },
        '& .slick-arrow': {
            borderRadius: '100%',
            height: theme.spacing(6),
            width: theme.spacing(6),
            backgroundColor: theme.palette.grey[300],
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& .slick-prev': { left: theme.spacing(-12), '&:before': { display: 'none' } },
        '& .slick-next': { right: theme.spacing(-12), '&:before': { display: 'none' } },
        '& .slick-dots button': {
            '&:before': { color: 'white !important', fontSize: theme.typography.fontSize / 1.75 },
        },
        '& .slick-dots li': { margin: 0 },
    },
    textContainer: {
        margin: theme.spacing(0, 'auto'),
        maxWidth: '80%',
        textAlign: 'center',
        paddingTop: theme.spacing(4),
        color: 'white',
        height: theme.spacing(27),
    },
    title: {
        overflow: 'hidden',
        fontSize: theme.typography.fontSize * 2,
        fontWeight: (theme.typography.fontWeightRegular as number) * 1.75,
        lineHeight: theme.spacing(4),
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1.5),
        textOverflow: 'ellipsis',
    },
    subtitle: {
        margin: 0,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: theme.spacing(2.25),
    },
    arrow: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        position: 'absolute',
        top: `calc((100% - ${theme.spacing(12)}) / 2 + ${theme.spacing(3)})`,
    },
    arrowLeft: { left: theme.spacing(-12) },
    arrowRight: { right: theme.spacing(-12) },
    arrowIcon: { color: theme.palette.grey[700] },
    backdrop: { zIndex: 1 },
    imgContainer: { height: `calc(100% - ${theme.spacing(27)})` },
    dismiss: { position: 'absolute', bottom: theme.spacing(), left: '50%', transform: 'translateX(-50%)' },
    newsletter: { marginTop: theme.spacing() },
}));

export { useStyles };
