import { createAction } from 'redux-actions';

import type { PositionStatus } from '~/services/ApiClient';
import { retryableRequest } from '~/services/ApiClient';

import type { PositionStatusesMeta } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

const retrievePositionStatuses = (
    retrievePositions: (startDate: Date, stopDate: Date) => Promise<PositionStatus[]>,
    _positionGroupId: number,
    startDate: Date,
    stopDate: Date
) => {
    return retryableRequest(() => retrievePositions(startDate, stopDate));
};

export const retrievePositionStatusesAction = createAction<
    Promise<PositionStatus[]>,
    PositionStatusesMeta,
    (startDate: Date, stopDate: Date) => Promise<PositionStatus[]>,
    number,
    Date,
    Date
>(ActionTypePrefixes.POSITIONSTATUSES, retrievePositionStatuses, (_, positionGroupId, startDate, stopDate) => ({
    positionGroupId,
    startDate,
    stopDate,
}));
