import { Close } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import type { StyledComponentProps, WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledIconButton } from '~/components/TitledIconButton';

import type { FilterSheetClassKey } from './styles';

export interface FilterSheetProps {
    children: JSX.Element;
    onClose: () => void;
}

export interface FilterSheetOuterProps extends FilterSheetProps, StyledComponentProps<FilterSheetClassKey> {}

export interface FilterSheetInnerProps
    extends FilterSheetProps,
        InjectedTranslationProps,
        WithStyles<FilterSheetClassKey> {}

export const FilterSheetComponent: React.FC<FilterSheetInnerProps> = ({ t, classes, children, onClose }) => {
    return (
        <div className={classes.root} data-id="filter">
            <div className={classes.header}>
                <Typography className={classes.title} variant="h6" color="textPrimary">
                    {t('filters')}
                </Typography>

                <TitledIconButton title={t('close')} className={classes.close} onClick={onClose} data-id="close">
                    <Close />
                </TitledIconButton>
            </div>

            <Divider />

            <div className={classes.content}>{children}</div>
        </div>
    );
};
