import * as React from 'react';

import { EBS } from '../../../../../EBS';

import type { EbsSectionContentInnerProps } from './model';

export const EbsSectionContentComponent: React.FC<EbsSectionContentInnerProps> = ({ ebs, displayPreferences }) => {
    const { axleLoadSum: axleWeight, milage: distance, dateTime, trailerWeight } = ebs;

    return (
        <EBS
            unitSystem={displayPreferences.unitSystem}
            axleWeight={axleWeight}
            mileage={distance}
            trailerWeight={trailerWeight}
            timeStamp={dateTime}
        />
    );
};
