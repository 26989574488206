import type { DriverActivitiesUpdate, DriverActivity } from '~/services/ApiClient';

export const filterUpdatedDriverActivities = (
    toBeFiltered: DriverActivitiesUpdate,
    forComparison: DriverActivitiesUpdate
): DriverActivity[] => {
    return toBeFiltered.updatedActivities.filter((activityUpdate) => {
        const activityUpdateForComparison = forComparison.updatedActivities.find(({ id }) => id === activityUpdate.id);

        return (
            !forComparison.deletedActivities.includes(activityUpdate.id) &&
            !(activityUpdateForComparison && activityUpdateForComparison.revision > activityUpdate.revision)
        );
    });
};
