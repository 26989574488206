import { gdfToDate, kmphToMps } from '@fv/converters';

import type { FeatureDetails, RawFeatureAttribute } from '../Xserver';

import type { TrafficIncidentAttributes } from './incidentData';
import { RawTrafficIncidentDetailsAttributesKeys } from './incidentData';

const getAttributeKey = (rawAttribute: RawFeatureAttribute): string => {
    switch (rawAttribute.key) {
        case RawTrafficIncidentDetailsAttributesKeys.MESSAGE_TEXT: {
            return 'messageText';
        }

        case RawTrafficIncidentDetailsAttributesKeys.MESSAGE_LANGUAGE: {
            return 'messageLanguage';
        }

        default:
            return rawAttribute.key;
    }
};

const getAttributeValue = (rawAttribute: RawFeatureAttribute) => {
    switch (rawAttribute.key) {
        case RawTrafficIncidentDetailsAttributesKeys.CATEGORY: {
            return +rawAttribute.value;
        }

        case RawTrafficIncidentDetailsAttributesKeys.DELAY: {
            return +rawAttribute.value;
        }

        case RawTrafficIncidentDetailsAttributesKeys.TIME_DOMAIN: {
            return gdfToDate(rawAttribute.value);
        }

        case RawTrafficIncidentDetailsAttributesKeys.ABSOLUTE_SPEED: {
            return kmphToMps(+rawAttribute.value);
        }

        case RawTrafficIncidentDetailsAttributesKeys.MESSAGE_TEXT: {
            return rawAttribute.value;
        }

        case RawTrafficIncidentDetailsAttributesKeys.MESSAGE_LANGUAGE: {
            return rawAttribute.value;
        }

        case RawTrafficIncidentDetailsAttributesKeys.LENGTH: {
            return +rawAttribute.value;
        }

        case RawTrafficIncidentDetailsAttributesKeys.INCIDENT_ID: {
            return rawAttribute.value;
        }

        default:
            return null;
    }
};

export const getAttributesFromIncidentDetails = (trafficIncidentDetails: FeatureDetails): TrafficIncidentAttributes =>
    trafficIncidentDetails.attributes.reduce(
        (attributesObject: TrafficIncidentAttributes, rawAttribute: RawFeatureAttribute) => {
            // eslint-disable-next-line no-param-reassign
            attributesObject[getAttributeKey(rawAttribute)] = getAttributeValue(rawAttribute);
            return attributesObject;
        },
        { category: -1 }
    );
