import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Paper } from '@mui/material';
import type { FC } from 'react';

import { CloseOnClickTooltip } from '~/components/CloseOnClickTooltip';

import type { LeftPaneHandleProps } from './models';
import { useStyles } from './styles';

const LeftPaneHandle: FC<LeftPaneHandleProps> = (props) => {
    const { isOpen, onClick, title } = props;

    const classes = useStyles();

    const togglePaneButtonContext = isOpen
        ? { icon: <ChevronLeft />, state: 'on' }
        : { icon: <ChevronRight />, state: 'off' };

    return (
        <CloseOnClickTooltip title={title}>
            <Paper
                square
                elevation={5}
                className={classes.paneHandler}
                data-id={`left-pane-toggle-button:${togglePaneButtonContext.state}`}
                onClick={onClick}
            >
                {togglePaneButtonContext.icon}
            </Paper>
        </CloseOnClickTooltip>
    );
};

LeftPaneHandle.displayName = 'LeftPaneHandle';
export default LeftPaneHandle;
