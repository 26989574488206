import { useSelector } from 'react-redux';

import { featureFlagsSelector } from '~/selectors';

import type { Feature } from '../models';

const useFeatureFlags = () => {
    const state = useSelector(featureFlagsSelector);

    return {
        isEnabled: (feature: Feature) => {
            return state.enabledFeatures.includes(feature);
        },
    };
};

export { useFeatureFlags };
