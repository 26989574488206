import type { MonitoringVehicleEntry } from '~/data/monitoring';
import { parseOptionalDuration } from '~/services/Parsers';

export const getDoubleMannedAvailableDailyDrivingTimeValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.doubleManned &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.doubleManned.availableDailyDrive
    );

export const getDoubleMannedAvailableWeeklyDrivingTimeValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.doubleManned &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.doubleManned.availableWeeklyDrive
    );

export const getDoubleMannedAvailableBiWeeklyDrivingTimeValue = (
    monitoringVehicleEntry: MonitoringVehicleEntry
): moment.Duration | undefined =>
    parseOptionalDuration(
        monitoringVehicleEntry.vehicleDriverHoursStatus &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.doubleManned &&
            monitoringVehicleEntry.vehicleDriverHoursStatus.doubleManned.availableBiWeeklyDrive
    );
