import { Button } from '@mui/material';
import React from 'react';
import { Redirect } from 'react-router-dom';

import type { InjectedUserDataProps } from '~/components/AuthenticationData';
import { CustomerRemoteAutocomplete } from '~/components/CustomerRemoteAutocomplete';
import { HeaderTemplate } from '~/components/HeaderTemplate';
import { useTranslation } from '~/components/LanguageSelector';
import { PageTemplate } from '~/components/PageTemplate';
import type { Option } from '~/components/RemoteAutocomplete';
import { SettingItemHeader, SettingsGroup } from '~/components/SettingsForm';
import { UserRemoteAutocomplete } from '~/components/UserRemoteAutocomplete';
import type { Customer } from '~/services/ApiClient';

import { useStyles } from './styles';

export interface ImpersonationProps {}

export interface StateProps {
    impersonateSecurable: boolean;
}

export interface DispatchProps {
    impersonateUser: (customer: string, user: string) => void;
}

export interface ImpersonationInnerProps extends ImpersonationProps, StateProps, DispatchProps, InjectedUserDataProps {}

export const ImpersonationComponent: React.FC<ImpersonationInnerProps> = ({
    impersonateSecurable,
    cid,
    userName,
    impersonateUser,
}) => {
    const [customer, setCustomer] = React.useState<Option<Customer>>();
    const [user, setUser] = React.useState<Option<undefined>>();

    const { t } = useTranslation();
    const classes = useStyles();

    const onCustomerChangeCallback = React.useCallback(
        (selectedCustomer: Option<Customer>) => {
            if (selectedCustomer.data.id !== customer?.data.id) {
                setCustomer(selectedCustomer);
                setUser(undefined);
            }
        },
        [customer]
    );

    const onUserChangeCallback = React.useCallback(
        (selectedUser: Option<undefined>) => {
            if (selectedUser.value !== user?.value) {
                setUser(selectedUser);
            }
        },
        [user]
    );

    const customerSelector = (
        <SettingItemHeader
            title={t('customer')}
            description={t('impersonationform-customer-description')}
            action={<CustomerRemoteAutocomplete selectedCustomer={customer} onChange={onCustomerChangeCallback} />}
            classes={{ action: classes.formSelector }}
        />
    );

    const userSelectorDisabled = !customer;

    const userSelector = (
        <SettingItemHeader
            title={t('user')}
            description={t('impersonationform-user-description')}
            isDisabled={userSelectorDisabled}
            action={
                <UserRemoteAutocomplete
                    selectedUser={user}
                    customerId={customer?.data.id}
                    disabled={userSelectorDisabled}
                    onChange={onUserChangeCallback}
                />
            }
            classes={{ action: classes.formSelector }}
        />
    );

    const impersonateButtonDisabled = !customer || !user || (customer.data.id === cid && user.value === userName);

    const callImpersonateUser = React.useCallback(() => {
        if (customer && user) {
            impersonateUser(customer.value, user.value);
        }
    }, [customer, impersonateUser, user]);

    const content = (
        <>
            <SettingsGroup title={t('impersonation')}>
                {customerSelector}
                {userSelector}
            </SettingsGroup>
            <Button
                variant="contained"
                color="secondary"
                className={classes.impersonateButton}
                disabled={impersonateButtonDisabled}
                onClick={callImpersonateUser}
                data-id="impersonate-action"
            >
                {t('impersonate')}
            </Button>
        </>
    );

    const header = <HeaderTemplate title={t('impersonation')} />;

    return impersonateSecurable ? (
        <PageTemplate header={header}>
            <div className={classes.impersonationForm} data-id="impersonation-form">
                {content}
            </div>
        </PageTemplate>
    ) : (
        <Redirect to="/" />
    );
};
