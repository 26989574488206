import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { SelectSettingItemInnerProps, SelectSettingItemProps } from './component';
import { SelectSettingItemComponent } from './component';
import { SelectSettingItemStyleRules } from './styles';

export * from './model';

export const SelectSettingItem = compose<SelectSettingItemInnerProps, SelectSettingItemProps>(
    setDisplayName('SelectSettingItem'),
    withStyles(SelectSettingItemStyleRules)
)(SelectSettingItemComponent);
