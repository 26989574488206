import type { FC } from 'react';
import { lazy } from 'react';
import { compose } from 'react-recompose';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { withMenu } from '~/components/Navigation';
import { requiredDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { ensureUserPreferences } from '~/components/UserPreferences';

import { requiredCompanyCardAdministrationDetailsPaneUserPreferences } from './components/DetailsPane';

const CompanyCardsAdministration = lazy(() => import('./component'));

const CompanyCardsAdministrationScene: FC = () => {
    const securablesSelector = settingDataSelector(SettingsKey.SECURABLES);
    const { assetAdministration, services } = useSelector(securablesSelector);

    if (!assetAdministration.companyCards.isAllowed) {
        return <Redirect to="/unauthorized" />;
    }
    return <CompanyCardsAdministration servicesSecurables={services} />;
};

export default compose(
    withMenu,
    ensureUserPreferences([
        requiredCompanyCardAdministrationDetailsPaneUserPreferences,
        requiredDetailsPaneUserPreferences,
    ])
)(CompanyCardsAdministrationScene);
