import { actionGenerator } from '~/common/utils';

const MODULE_PREFIX = 'ADMINISTRATION GROUP';

const ACTIONS_KEYS = {
    LIST: `[${MODULE_PREFIX}] LIST`,
    RESET: `[${MODULE_PREFIX}] RESET`,
} as const;

const ACTIONS = { ...actionGenerator(ACTIONS_KEYS, MODULE_PREFIX) } as const;

export { ACTIONS, ACTIONS_KEYS };
