import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DailyOverviewSectionClassKey = 'root';

export const DailyOverviewSectionClassStyleRules: StyleRulesCallback<Theme, {}, DailyOverviewSectionClassKey> = (
    theme
) =>
    createStyles({
        root: {
            padding: `0 ${theme.spacing()}`,
            '& li:nth-child(odd)': {
                backgroundColor: theme.palette.background.default,
            },
        },
    });
