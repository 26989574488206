import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Action } from 'redux';
import type { ActionFunction0 } from 'redux-actions';

import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { SettingsDetails } from '~/components/EnsureSettings/settings';
import { settingsDetails } from '~/components/EnsureSettings/settings';
import { retrieveAssetGroupsAction } from '~/data/assetgroups';
import { retrieveContainersAction } from '~/data/containers';
import { retrieveDepotsAction } from '~/data/depots';
import { retrieveDevicesAction } from '~/data/devices';
import { retrieveDriversAction } from '~/data/drivers';
import { retrieveSettingAction } from '~/data/settings';
import { retrieveTrailersAction } from '~/data/trailers';
import { retrieveVehiclesAction } from '~/data/vehicles';
import { retrieveVehicleStatusAction } from '~/data/vehiclestatus';
import { getI18n } from '~/i18n';
import { isEqual } from '~/libs/utility';
import type { StoreState } from '~/reducers';

import { StaticDataType } from '../models';
import type { StaticDataStoreState } from '../reducers';

const StaticDataMap = {
    [StaticDataType.ASSETGROUPS]: retrieveAssetGroupsAction,
    [StaticDataType.DEPOTS]: retrieveDepotsAction,
    [StaticDataType.VEHICLES]: retrieveVehiclesAction,
    [StaticDataType.TRAILERS]: retrieveTrailersAction,
    [StaticDataType.CONTAINERS]: retrieveContainersAction,
    [StaticDataType.DRIVERS]: retrieveDriversAction,
    [StaticDataType.DEVICES]: retrieveDevicesAction,
    [StaticDataType.VEHICLESTATUS]: retrieveVehicleStatusAction,
};

interface UseEnsureDataProps {
    settings?: (keyof SettingsDetails)[];
    staticData?: StaticDataType[];
    // lastAction & clearActions will be refactored and typed properly when redux toolkit were implemented.
    lastActions?: ActionFunction0<Action>[];
    clearActions?: ActionFunction0<Action>[];
}

interface UseEnsureState {
    settingState: SettingsStoreState;
    staticDataState: StaticDataStoreState;
}

const useEnsureData = (props: UseEnsureDataProps) => {
    const { settings = [], staticData = [], lastActions = [], clearActions = [] } = props;

    const { settingState, staticDataState } = useSelector<StoreState, UseEnsureState>(
        (rootState) => ({ settingState: rootState.settings, staticDataState: rootState.staticData }),
        isEqual
    );
    const dispatch = useDispatch();
    const [status, setStatus] = useState({ isFetching: true, error: false, isInitialLoad: true });
    const { t } = getI18n();

    const reloadSettings = useCallback(
        (forceReload?: boolean) => {
            return settings.map((settingKey: keyof SettingsDetails) => {
                const { pending, fulfilled } = settingState[settingKey];

                if ((!pending && !fulfilled) || forceReload) {
                    const settingAction = settingsDetails[settingKey].retrieveDataFactory({ t, i18n: getI18n() });
                    return dispatch(retrieveSettingAction(settingKey, settingAction));
                }

                return Promise.resolve({});
            });
        },
        [dispatch, settings, settingState, t]
    );

    const reloadStaticData = useCallback(
        (forceReload?: boolean) => {
            return staticData.map((key) => {
                const { pending, fulfilled } = staticDataState[key];

                if ((!pending && !fulfilled) || forceReload) {
                    return dispatch<Promise<unknown>>(StaticDataMap[key]());
                }

                return Promise.resolve({});
            });
        },
        [dispatch, staticData, staticDataState]
    );

    useEffect(() => {
        setStatus((prevState) => ({ ...prevState, isFetching: true }));

        Promise.all([...reloadSettings(), ...reloadStaticData()])
            .then(() => Promise.all(lastActions.map((action) => dispatch(action()))))
            .then(() => setStatus({ isFetching: false, error: false, isInitialLoad: false }))
            .catch(() => setStatus({ isFetching: false, error: true, isInitialLoad: true }));

        return () => {
            clearActions.map((action) => dispatch(action()));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reloadData = async () => {
        if (status.isFetching || status.isInitialLoad) {
            // data is already being loading or initial load has not completed (e.g. failed), ignoring this request
            return;
        }

        setStatus((prevState) => ({ ...prevState, isFetching: true }));

        try {
            await Promise.all(lastActions.map((action) => dispatch(action())));
            setStatus({ isFetching: false, error: false, isInitialLoad: false });
        } catch {
            setStatus({ isFetching: false, error: true, isInitialLoad: false });
        }
    };

    return { ...status, reloadSettings, reloadStaticData, reloadData };
};

export { useEnsureData };
