import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatLatitude } from '~/services/Formatters';

export interface LatitudeFormatterProps {
    t: SingleTFunction;
    value?: number;
}

export interface LatitudeFormatterInnerProps extends LatitudeFormatterProps {}

const LatitudeFormatter: React.FunctionComponent<LatitudeFormatterInnerProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatLatitude(t, value)}</>;
};
LatitudeFormatter.displayName = 'LatitudeFormatter';

export { LatitudeFormatter };
