import * as React from 'react';

import type { SectionDefinition } from '~/components/Sections';
import { memoizeOne } from '~/services/Memoize';

import { DimensionsSection } from './components/DimensionsSection';
import { GeneralSection } from './components/GeneralSection';
import { HeaderSection } from './components/HeaderSection';
import { SpeedLimitsSection } from './components/SpeedLimitsSection';
import { TypeSection } from './components/TypeSection';
import { SectionName } from './constants';
import type { GetSectionsArgs } from './models';

export const getSections = ({
    collapsedSections,
    toggleCollapsedState,
    entry,
    openEditorDialog,
}: GetSectionsArgs): SectionDefinition[] => {
    return [
        {
            name: SectionName.VEHICLETYPESADMINISTRATION_HEADER,
            content: () => <HeaderSection adminVehicleType={entry} openEditorDialog={openEditorDialog} />,
        },
        {
            name: SectionName.VEHICLETYPESADMINISTRATION_GENERAL,
            content: (dragHandleElement: JSX.Element) => (
                <GeneralSection
                    adminVehicleType={entry}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.VEHICLETYPESADMINISTRATION_GENERAL)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VEHICLETYPESADMINISTRATION_GENERAL)}
                />
            ),
        },
        {
            name: SectionName.VEHICLETYPESADMINISTRATION_DIMENSIONS,
            content: (dragHandleElement: JSX.Element) => (
                <DimensionsSection
                    adminVehicleType={entry}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.VEHICLETYPESADMINISTRATION_DIMENSIONS)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VEHICLETYPESADMINISTRATION_DIMENSIONS)}
                />
            ),
        },
        {
            name: SectionName.VEHICLETYPESADMINISTRATION_TYPE,
            content: (dragHandleElement: JSX.Element) => (
                <TypeSection
                    adminVehicleType={entry}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.VEHICLETYPESADMINISTRATION_TYPE)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VEHICLETYPESADMINISTRATION_TYPE)}
                />
            ),
        },
        {
            name: SectionName.VEHICLETYPESADMINISTRATION_SPEEDLIMITS,
            content: (dragHandleElement: JSX.Element) => (
                <SpeedLimitsSection
                    adminVehicleType={entry}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.VEHICLETYPESADMINISTRATION_SPEEDLIMITS)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VEHICLETYPESADMINISTRATION_SPEEDLIMITS)}
                />
            ),
        },
    ];
};

export const getSectionsMemoized = memoizeOne(getSections);
