import { compose, setDisplayName } from 'react-recompose';

import { withSceneAnalytics } from '~/components/Analytics';

import type {
    VehicleTypeAdministrationDetailsPaneInnerProps,
    VehicleTypeAdministrationDetailsPaneProps,
} from './component';
import { VehicleTypeAdministrationDetailsPaneComponent } from './component';
import {
    VEHICLETYPESADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY,
    defaultVehicleTypesAdministrationDetailsPaneUserPreferences,
    requiredVehicleTypesAdministrationDetailsPaneUserPreferences,
} from './preferences';

export {
    defaultVehicleTypesAdministrationDetailsPaneUserPreferences,
    requiredVehicleTypesAdministrationDetailsPaneUserPreferences,
    VEHICLETYPESADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY,
};

export const VehicleTypeAdministrationDetailsPane = compose<
    VehicleTypeAdministrationDetailsPaneInnerProps,
    VehicleTypeAdministrationDetailsPaneProps
>(
    setDisplayName('VehicleTypeAdministrationDetailsPane'),
    withSceneAnalytics('vehicletypeadministrationdetailspane')
)(VehicleTypeAdministrationDetailsPaneComponent);
