import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

import { CheckIcon, CloseIcon } from '~/components/Icons';
import { isUndefined } from '~/libs/utility';

export interface BooleanIconFormatterProps {
    value?: boolean;
}

export const BooleanIconFormatter: React.FC<BooleanIconFormatterProps> = ({ value }) => {
    const { t } = useTranslation();

    if (isUndefined(value)) {
        return null;
    }

    if (value) {
        return (
            <Tooltip title={t('boolean-icon-enabled')}>
                <span data-id="boolean-icon-enabled" data-testid="boolean-icon-enabled">
                    <CheckIcon />
                </span>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={t('boolean-icon-disabled')}>
                <span data-id="boolean-icon-disabled" data-testid="boolean-icon-disabled">
                    <CloseIcon />
                </span>
            </Tooltip>
        );
    }
};
