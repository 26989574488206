import type { LocationDescriptor } from 'history';
import * as React from 'react';
import type { RouteComponentProps, StaticContext } from 'react-router';
import { Redirect } from 'react-router';

import { LogoutReason } from '~/components/AuthenticationData';
import { AuthTemplate } from '~/components/AuthTemplate';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';

export interface LoginStateProps {
    isAuthenticated: boolean;
}

export interface LoginLocationState {
    from?: LocationDescriptor;
    reason?: LogoutReason;
}

export interface LoginFormProps {
    defaultValidationMessage?: string;
}

export interface LoginProps extends RouteComponentProps<{}, StaticContext, LoginLocationState | undefined> {}

export interface LoginInnerProps extends LoginProps, LoginStateProps, InjectedTranslationProps {}

export const LoginComponentFactory: (
    LoginForm: React.ComponentType<LoginFormProps>
) => React.FunctionComponent<LoginInnerProps> = (LoginForm: React.ComponentType<LoginFormProps>) => {
    const LoginComponent: React.SFC<LoginInnerProps> = (props) => {
        const from = props.location.state?.from ?? { pathname: '/' };
        const { isAuthenticated, t } = props;

        const defaultValidationMessage = React.useMemo(() => {
            switch (props.location.state?.reason) {
                case LogoutReason.TokenExpired:
                    return t('token-expired');
                default:
                    return undefined;
            }
        }, [t, props.location.state?.reason]);

        if (isAuthenticated) {
            return <Redirect to={from} />;
        }

        return (
            <AuthTemplate>
                <LoginForm defaultValidationMessage={defaultValidationMessage} />
            </AuthTemplate>
        );
    };
    return LoginComponent;
};
