import type * as React from 'react';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';

import { withTranslation } from '~/components/LanguageSelector';
import { ensureUserPreferences } from '~/components/UserPreferences';
import { memoize } from '~/libs/utility';

import type { TextMessageDetailsInnerProps, TextMessageDetailsProps } from './component';
import { TextMessageDetailsComponentFactory } from './component';
import { requiredMessageDetailsUserPreferencesFactory } from './preferences';
import { mapDispatchToPropsFactory } from './redux';

export const TextMessageDetailsFactory = (userPreferencesKey: string): React.ComponentType<TextMessageDetailsProps> =>
    compose<TextMessageDetailsInnerProps, TextMessageDetailsProps>(
        setDisplayName('TextMessageDetails'),
        ensureUserPreferences([requiredMessageDetailsUserPreferencesFactory(userPreferencesKey)]),
        connect(undefined, mapDispatchToPropsFactory(userPreferencesKey)),
        withTranslation()
    )(TextMessageDetailsComponentFactory(userPreferencesKey));

export const textMessageDetailsProvider = memoize(TextMessageDetailsFactory);
