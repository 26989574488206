import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import type {
    DisplayUserPreferencesVehicleDisplayFormat,
    NamedVehicleReference,
    PojoModel,
    ResolvedVehicle,
    Vehicle,
} from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';

export interface VehicleNameFormatterProps {
    value?: PojoModel<NamedVehicleReference> | PojoModel<Vehicle> | ResolvedVehicle;
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat;
}

export interface VehicleNameFormatterInnerProps extends VehicleNameFormatterProps {}

const VehicleNameFormatter: React.FunctionComponent<VehicleNameFormatterInnerProps> = ({
    value,
    vehicleDisplayNameFormat,
}) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatVehicleName(value, vehicleDisplayNameFormat)}</>;
};
VehicleNameFormatter.displayName = 'VehicleNameFormatter';

export { VehicleNameFormatter };
