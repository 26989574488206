import { useState } from 'react';

import type { ContextMenuPosition } from '~/components/ContextMenu';

interface UseContextMenuState<T> {
    position?: ContextMenuPosition;
    isOpen: boolean;
    currentClicked?: T;
    prevClicked?: T;
}

const useContextMenu = <T>() => {
    const [status, setStatus] = useState<UseContextMenuState<T>>({ isOpen: false });

    const handleRowClick = (event: MouseEvent, rowId: T) => {
        setStatus({
            isOpen: true,
            position: { clientX: event.clientX, clientY: event.clientY },
            currentClicked: rowId,
            prevClicked: rowId,
        });

        event.preventDefault();
        event.stopPropagation();
    };

    return {
        ...status,
        handleRowClick,
        handleClose: () => {
            setStatus(({ prevClicked }) => ({ isOpen: false, prevClicked }));
        },
        reset: () => {
            setStatus({ isOpen: false });
        },
    };
};

export { useContextMenu };
