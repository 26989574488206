import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import type { ContextMenuItem, ContextMenuPosition } from '~/components/ContextMenu';
import { ContextMenu } from '~/components/ContextMenu';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { StoreState } from '~/reducers';

import { DeleteRtdsScheduleDialog } from '../DeleteRtdsScheduleDialog';
import { ScheduleEditor } from '../ScheduleEditor';

import { reduxPropsSelector } from './redux';

export interface RtdsSchedulesContextMenuProps {
    scheduleId?: number;
    position?: ContextMenuPosition;
    onClose: () => void;
    refreshData: () => void;
}

export interface RtdsSchedulesContextMenuInnerProps extends RtdsSchedulesContextMenuProps, InjectedTranslationProps {}

export const RtdsSchedulesContextMenuComponent: FC<RtdsSchedulesContextMenuInnerProps> = (props) => {
    const { scheduleId, position, onClose, refreshData, t } = props;

    const { selectedSchedule } = useSelector((state: StoreState) => reduxPropsSelector(state, { scheduleId }));
    const [dialog, setDialog] = useState<ReactNode>();

    const getMenuItems = (): ContextMenuItem[] => {
        const closeDialog = () => {
            setDialog(undefined);
        };
        const menuItems = [
            {
                key: 'delete-rtds-schedule',
                title: t('delete-rtds-schedule-action'),
                icon: <CancelIcon />,
                onClick: () => {
                    if (scheduleId) {
                        setDialog(
                            <DeleteRtdsScheduleDialog
                                onClose={closeDialog}
                                scheduleId={scheduleId}
                                onDelete={refreshData}
                            />
                        );
                    }
                },
            },
        ];

        if (selectedSchedule) {
            menuItems.push(
                {
                    key: 'modify-rtds-schedule',
                    title: t('modify-action'),
                    icon: <EditIcon />,
                    onClick: () => {
                        setDialog(
                            <ScheduleEditor
                                isClone={false}
                                onClose={closeDialog}
                                schedule={selectedSchedule}
                                assetType={selectedSchedule.assetReference.type}
                                onSave={refreshData}
                            />
                        );
                    },
                },
                {
                    key: 'clone-rtds-schedule',
                    title: t('clone-rtds-schedule-action'),
                    icon: <FileCopyIcon />,
                    onClick: () => {
                        setDialog(
                            <ScheduleEditor
                                isClone
                                onClose={closeDialog}
                                schedule={selectedSchedule}
                                assetType={selectedSchedule.assetReference.type}
                                onSave={refreshData}
                            />
                        );
                    },
                }
            );
        }

        return menuItems;
    };

    return (
        <>
            <ContextMenu
                position={position}
                onClose={onClose}
                menuItems={getMenuItems()}
                dataId="rtds-schedules-context-menu"
            />
            {dialog}
        </>
    );
};
