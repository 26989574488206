import { combineReducers } from 'redux';

import type { OverlayMenuStoreState } from './components/OverlayMenu';
import { overlayMenuReducer } from './components/OverlayMenu';
import type { MenuRootStoreState } from './reducers';
import { menuRootReducer } from './reducers';

export interface MenuStoreState {
    menuRoot: MenuRootStoreState;
    overlayMenu: OverlayMenuStoreState;
}

export const menuReducer = combineReducers({
    menuRoot: menuRootReducer,
    overlayMenu: overlayMenuReducer,
});
