import React from 'react';

import type { Option } from '~/components/RemoteAutocomplete';
import { RemoteAutocompleteFactory } from '~/components/RemoteAutocomplete';
import type { NumericDictionary } from '~/libs/utility';
import type { User } from '~/services/ApiClient';

import { mapUsersToUserOptions } from './mapUsersToUserOptions';

export interface UserRemoteAutocompleteProps {
    selectedUser?: Option<undefined>;
    customerId?: number;
    disabled?: boolean;
    onChange: (value: Option<undefined>) => void;
}

export interface StateProps {
    users: NumericDictionary<User>;
    loadingUsers: boolean;
    retrieveUsersFulfilled: boolean;
}

export interface DispatchProps {
    getUsersForCustomer: (cid: number) => void;
}

export interface UserRemoteAutocompleteInnerProps extends UserRemoteAutocompleteProps, StateProps, DispatchProps {}

const UserRemoteAutocomplete = RemoteAutocompleteFactory<undefined>();

export const UserRemoteAutocompleteComponent: React.FC<UserRemoteAutocompleteInnerProps> = ({
    selectedUser,
    customerId,
    disabled,
    onChange,
    users,
    loadingUsers,
    retrieveUsersFulfilled,
    getUsersForCustomer,
}) => {
    const [localSelectedUser, setLocalSelectedUser] = React.useState<Option<undefined> | undefined | null>(
        selectedUser
    );
    const [prevCustomerId, setPrevCustomerId] = React.useState(customerId);

    // If the customer ID has changed retrieve the users for the new customer and clear the current selection
    if (customerId && prevCustomerId !== customerId) {
        getUsersForCustomer(customerId);
        setLocalSelectedUser(null);
        setPrevCustomerId(customerId);
    }

    const userOptions = React.useMemo(() => (Object.keys(users).length ? mapUsersToUserOptions(users) : []), [users]);

    const onChangeCallback = React.useCallback(
        (user: Option<undefined>) => {
            if (user.value !== localSelectedUser?.value) {
                setLocalSelectedUser(user);
                onChange(user);
            }
        },
        [localSelectedUser, onChange]
    );

    const getUsersForCustomerCallback = React.useCallback(() => {
        if (customerId) {
            getUsersForCustomer(customerId);
        }
    }, [customerId, getUsersForCustomer]);

    return (
        <UserRemoteAutocomplete
            selectedOption={localSelectedUser}
            options={userOptions}
            isLoading={loadingUsers}
            loadSucceeded={retrieveUsersFulfilled}
            isDisabled={disabled}
            retrieveOptions={getUsersForCustomerCallback}
            onChange={onChangeCallback}
            dataId="username-autocomplete"
        />
    );
};
