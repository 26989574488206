import type { UnitSystem } from '@fv/converters';
import { autoConvertDistance, convertDistance, getDistanceUnit } from '@fv/converters';

import { isUndefined } from '../../utility';

interface FormatDistanceArgs {
    value?: number;
    precision?: number;
    unitSystem: UnitSystem;
    autoFormat?: boolean;
}

const formatDistance = (args: FormatDistanceArgs) => {
    const { value, autoFormat, precision, unitSystem } = args;
    if (isUndefined(value)) {
        return undefined;
    }

    if (autoFormat) {
        const { value: converted, unit } = autoConvertDistance({ value, unitSystem });
        return `${converted} ${unit}`;
    }

    const converted = convertDistance({ value, precision, unitSystem });
    const unit = getDistanceUnit(unitSystem);

    return `${converted} ${unit}`;
};

export { formatDistance };
export type { FormatDistanceArgs };
