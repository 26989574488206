import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { MetadataSectionInnerProps, MetadataSectionProps } from './component';
import { MetadataSectionComponent } from './component';

export const MetadataSection = compose<MetadataSectionInnerProps, MetadataSectionProps>(
    setDisplayName('MetadataSection'),
    withTranslation()
)(MetadataSectionComponent);
