import type { NumericDictionary } from '~/libs/utility';
import type { Classification, DoorStatus, ResolvedDoorStatus } from '~/services/ApiClient';

export const doorStatusResolver = (
    { init, toJSON, ...doorStatusData }: DoorStatus,
    doorStatus: NumericDictionary<Classification>
): ResolvedDoorStatus => ({
    ...doorStatusData,
    status: doorStatusData.status.map((status) => doorStatus[status]),
});
