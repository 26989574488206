import { ListItem } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EbsIcon } from '~/components/Icons';
import { SpeedOdometerIcon } from '~/components/Icons/SpeedOdometerIcon';
import { WeightIcon } from '~/components/Icons/WeightIcon';

import { Distance } from './Distance';
import type { EBSProps } from './model';
import { Avatar, TimeListItem } from './styles';
import { Time } from './Time';
import { Weight } from './Weight';

const EBS: FC<EBSProps> = (props) => {
    const { axleWeight, mileage, timeStamp, trailerWeight, unitSystem } = props;
    const { t } = useTranslation();
    const notAvailable = t('not-available');

    return (
        <div data-testid="ebs">
            <ListItem data-testid="axle-load">
                <Weight
                    weight={axleWeight}
                    unitSystem={unitSystem}
                    avatar={
                        <Avatar>
                            <EbsIcon color="primary" />
                        </Avatar>
                    }
                    title={t('axle-load')}
                    notAvailable={notAvailable}
                />
            </ListItem>
            <ListItem data-testid="trailer-weight">
                <Weight
                    weight={trailerWeight}
                    unitSystem={unitSystem}
                    avatar={
                        <Avatar>
                            <WeightIcon color="primary" />
                        </Avatar>
                    }
                    title={t('trailer-weight')}
                    notAvailable={notAvailable}
                />
            </ListItem>
            <ListItem data-testid="mileage">
                <Distance
                    distance={mileage}
                    notAvailable={notAvailable}
                    unitSystem={unitSystem}
                    avatar={
                        <Avatar>
                            <SpeedOdometerIcon color="primary" sx={{ stroke: '#63BC47', strokeWidth: '180px' }} />
                        </Avatar>
                    }
                    title={t('ebs-mileage-details-pane')}
                />
            </ListItem>
            <TimeListItem data-testid="time">
                <Time time={timeStamp} notAvailable={notAvailable} />
            </TimeListItem>
        </div>
    );
};

EBS.displayName = 'EBS';

export { EBS };
