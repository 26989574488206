import memoizeOne from 'memoize-one';
import type { Duration } from 'moment';

import type { SectionDefinition } from '~/components/Sections';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type { LoginStatus, ViolationField } from '~/services/ApiClient';
import { DriverRole } from '~/services/ApiClient';

import { DailyOverviewSection } from './components/DailyOverviewSection';
import { DriveSection } from './components/DriveSection';
import { DutySection } from './components/DutySection';
import { GeneralSection } from './components/GeneralSection';
import { LaborSection } from './components/LaborSection';
import { RestSection } from './components/RestSection';
import { SectionName } from './constants';
import {
    getActivityStartDateTimeValue,
    getActivityValue,
    getAvailableBiWeeklyDriveValue,
    getAvailableDailyDriveValue,
    getAvailableWeeklyDriveValue,
    getContinuousDriveValue,
    getContinuousLabourLongValue,
    getContinuousLabourShortValue,
    getContinuousWorkWaitValue,
    getCurrentActivityDurationValue,
    getDailyDriveValue,
    getDailyDutyValue,
    getDailyRestValue,
    getDriveBreakValue,
    getExtendedDayDriveCountValue,
    getLabourLongBreakValue,
    getLabourShortBreakValue,
    getLoginStatusValue,
    getMonthlyDutyValue,
    getMonthlyEffectivityPercentageValue,
    getNightLabourValue,
    getOperationalWeekDurationValue,
    getReducedDayRestCountValue,
    getShiftDurationValue,
    getSplitDailyRestValue,
    getSubActivityValue,
    getTotalWeeklyRestCompensationValue,
    getWeekLabourAverageValue,
    getWeekLabourValue,
    getWeeklyDriveValue,
    getWeeklyDutyValue,
    getWeeklyRestCompensationValue,
    getWeeklyRestDueDateTimeValue,
} from './getCellValue';

export const getSections = (
    collapsedSections: SectionName[],
    toggleCollapsedState: (sectionName: SectionName) => () => void,
    entry: MonitoringVehicleEntry,
    activeRole: DriverRole
): SectionDefinition[] => {
    const vehicleId = entry.vehicle.id;

    const hoursStatus =
        activeRole === DriverRole.DRIVER
            ? entry.vehicleDriverHoursStatus && entry.vehicleDriverHoursStatus.driverHoursStatus
            : entry.vehicleDriverHoursStatus && entry.vehicleDriverHoursStatus.coDriverHoursStatus;

    const driverId = hoursStatus?.driver.id;

    const activity = getActivityValue(entry, activeRole);
    const subActivity = getSubActivityValue(entry, activeRole);

    let activityStartDateTime: Date | undefined;
    let shiftDuration: ViolationField<Duration> | undefined;
    let availableDailyDrive: Duration | undefined;
    let availableWeeklyDrive: Duration | undefined;
    let availableBiWeeklyDrive: Duration | undefined;
    let dailyDuty: ViolationField<Duration> | undefined;
    let weeklyDuty: ViolationField<Duration> | undefined;
    let monthlyDuty: ViolationField<Duration> | undefined;
    let monthlyEffectivityPercentage: number | undefined;
    let currentActivityDuration: Duration | undefined;
    let continuousWorkWait: ViolationField<Duration> | undefined;
    let operationalWeekDuration: ViolationField<Duration> | undefined;
    let loginStatus: LoginStatus | undefined;
    let continuousDrive: ViolationField<Duration> | undefined;
    let driveBreak: Duration | undefined;
    let dailyDrive: ViolationField<Duration> | undefined;
    let weeklyDrive: ViolationField<Duration> | undefined;
    let extendedDayDriveCount: ViolationField<number> | undefined;
    let continuousLabourShort: ViolationField<Duration> | undefined;
    let labourShortBreak: Duration | undefined;
    let continuousLabourLong: ViolationField<Duration> | undefined;
    let labourLongBreak: Duration | undefined;
    let nightLabour: ViolationField<Duration> | undefined;
    let weekLabour: ViolationField<Duration> | undefined;
    let weekLabourAverage: ViolationField<Duration> | undefined;
    let splitDailyRest: Duration | undefined;
    let reducedDayRestCount: ViolationField<number> | undefined;
    let dailyRest: Duration | undefined;
    let weeklyRestCompensation: Duration | undefined;
    let totalWeeklyRestCompensation: Duration | undefined;
    let weeklyRestDueDateTime: Date | undefined;

    if (hoursStatus) {
        activityStartDateTime = getActivityStartDateTimeValue(hoursStatus);
        shiftDuration = getShiftDurationValue(hoursStatus);
        availableDailyDrive = getAvailableDailyDriveValue(hoursStatus);
        availableWeeklyDrive = getAvailableWeeklyDriveValue(hoursStatus);
        availableBiWeeklyDrive = getAvailableBiWeeklyDriveValue(hoursStatus);
        dailyDuty = getDailyDutyValue(hoursStatus);
        weeklyDuty = getWeeklyDutyValue(hoursStatus);
        monthlyDuty = getMonthlyDutyValue(hoursStatus);
        monthlyEffectivityPercentage = getMonthlyEffectivityPercentageValue(hoursStatus);
        currentActivityDuration = getCurrentActivityDurationValue(hoursStatus);
        continuousWorkWait = getContinuousWorkWaitValue(hoursStatus);
        operationalWeekDuration = getOperationalWeekDurationValue(hoursStatus);
        loginStatus = getLoginStatusValue(hoursStatus);
        continuousDrive = getContinuousDriveValue(hoursStatus);
        driveBreak = getDriveBreakValue(hoursStatus);
        dailyDrive = getDailyDriveValue(hoursStatus);
        weeklyDrive = getWeeklyDriveValue(hoursStatus);
        extendedDayDriveCount = getExtendedDayDriveCountValue(hoursStatus);
        continuousLabourShort = getContinuousLabourShortValue(hoursStatus);
        labourShortBreak = getLabourShortBreakValue(hoursStatus);
        continuousLabourLong = getContinuousLabourLongValue(hoursStatus);
        labourLongBreak = getLabourLongBreakValue(hoursStatus);
        nightLabour = getNightLabourValue(hoursStatus);
        weekLabour = getWeekLabourValue(hoursStatus);
        weekLabourAverage = getWeekLabourAverageValue(hoursStatus);
        splitDailyRest = getSplitDailyRestValue(hoursStatus);
        reducedDayRestCount = getReducedDayRestCountValue(hoursStatus);
        dailyRest = getDailyRestValue(hoursStatus);
        weeklyRestCompensation = getWeeklyRestCompensationValue(hoursStatus);
        totalWeeklyRestCompensation = getTotalWeeklyRestCompensationValue(hoursStatus);
        weeklyRestDueDateTime = getWeeklyRestDueDateTimeValue(hoursStatus);
    }

    return [
        {
            name: SectionName.GENERAL,
            content: (dragHandleElement: JSX.Element) => (
                <GeneralSection
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.GENERAL)}
                    toggleCollapsed={toggleCollapsedState(SectionName.GENERAL)}
                    activity={activity}
                    subActivity={subActivity}
                    activityStartDateTime={activityStartDateTime}
                    shiftDuration={shiftDuration}
                    currentActivityDuration={currentActivityDuration}
                    continuousWorkWait={continuousWorkWait}
                    operationalWeekDuration={operationalWeekDuration}
                    loginStatus={loginStatus}
                />
            ),
        },
        {
            name: SectionName.DRIVE,
            content: (dragHandleElement: JSX.Element) => (
                <DriveSection
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.DRIVE)}
                    toggleCollapsed={toggleCollapsedState(SectionName.DRIVE)}
                    availableDailyDrive={availableDailyDrive}
                    availableWeeklyDrive={availableWeeklyDrive}
                    availableBiWeeklyDrive={availableBiWeeklyDrive}
                    continuousDrive={continuousDrive}
                    driveBreak={driveBreak}
                    dailyDrive={dailyDrive}
                    weeklyDrive={weeklyDrive}
                    extendedDayDriveCount={extendedDayDriveCount}
                />
            ),
        },
        {
            name: SectionName.LABOR,
            content: (dragHandleElement: JSX.Element) => (
                <LaborSection
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.LABOR)}
                    toggleCollapsed={toggleCollapsedState(SectionName.LABOR)}
                    continuousLabourShort={continuousLabourShort}
                    labourShortBreak={labourShortBreak}
                    continuousLabourLong={continuousLabourLong}
                    labourLongBreak={labourLongBreak}
                    nightLabour={nightLabour}
                    weekLabour={weekLabour}
                    weekLabourAverage={weekLabourAverage}
                />
            ),
        },
        {
            name: SectionName.DUTY,
            content: (dragHandleElement: JSX.Element) => (
                <DutySection
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.DUTY)}
                    toggleCollapsed={toggleCollapsedState(SectionName.DUTY)}
                    dailyDuty={dailyDuty}
                    weeklyDuty={weeklyDuty}
                    monthlyDuty={monthlyDuty}
                    monthlyEffectivityPercentage={monthlyEffectivityPercentage}
                />
            ),
        },
        {
            name: SectionName.REST,
            content: (dragHandleElement: JSX.Element) => (
                <RestSection
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.REST)}
                    toggleCollapsed={toggleCollapsedState(SectionName.REST)}
                    splitDailyRest={splitDailyRest}
                    reducedDayRestCount={reducedDayRestCount}
                    dailyRest={dailyRest}
                    weeklyRestCompensation={weeklyRestCompensation}
                    totalWeeklyRestCompensation={totalWeeklyRestCompensation}
                    weeklyRestDueDateTime={weeklyRestDueDateTime}
                />
            ),
        },
        {
            name: SectionName.DAILY_OVERVIEW,
            content: (dragHandleElement: JSX.Element) => (
                <DailyOverviewSection
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.DAILY_OVERVIEW)}
                    toggleCollapsed={toggleCollapsedState(SectionName.DAILY_OVERVIEW)}
                    vehicleId={vehicleId}
                    driverId={driverId}
                    driverHours={hoursStatus}
                />
            ),
        },
    ];
};

export const getSectionsMemoized = memoizeOne(getSections);
