import type { ResolvedMessage } from '~/services/ApiClient';

import type { ListItem } from './listItem';

/**
 * Accepts buildListItem method as argument to be able to
 * memoize it per conversation.
 */
export const buildListItems = (
    conversationMessagesSortedDesc: ResolvedMessage[],
    buildListItem: (message: ResolvedMessage) => (prevMessage?: ResolvedMessage) => ListItem
): ListItem[] => {
    const conversationMessagesSortedAsc = conversationMessagesSortedDesc.slice().reverse();

    return conversationMessagesSortedAsc.map((message, index) =>
        buildListItem(message)(conversationMessagesSortedAsc[index - 1])
    );
};
