import { compose, setDisplayName } from 'react-recompose';
import { withRouter } from 'react-router';

import type { CustomLinkInnerProps, CustomLinkProps } from './component';
import { CustomLinkComponent } from './component';

export const CustomLink = compose<CustomLinkInnerProps, CustomLinkProps>(
    setDisplayName('CustomLink'),
    withRouter
)(CustomLinkComponent);
