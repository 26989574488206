import type { Reducer } from 'redux';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export interface SceneListStoreState {
    searchQuery?: string;
}

export interface SceneListRootStoreState {
    [key: string]: SceneListStoreState;
}

const defaultStoreState: SceneListRootStoreState = {};
const listDefault: SceneListStoreState = {};

const handleForList = (
    state: SceneListRootStoreState,
    key: string,
    listReducer: (s: SceneListStoreState) => SceneListStoreState
) => ({
    ...state,
    [key]: listReducer(state[key] ?? listDefault),
});

export const sceneListReducer: Reducer<SceneListRootStoreState> = (
    state: SceneListRootStoreState = defaultStoreState,
    action: ActionTypes
): SceneListRootStoreState => {
    switch (action.type) {
        case ActionTypeKeys.SCENELIST_CHANGESEARCHQUERY:
            return handleForList(state, action.meta, (s) => ({ ...s, searchQuery: action.payload }));
        case ActionTypeKeys.SCENELIST_CLEARSEARCHQUERY:
            return handleForList(state, action.meta, (s) => ({ ...s, searchQuery: undefined }));
        default:
            return state;
    }
};
