import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type BooleanInputClassKey = 'label' | 'changedField';

export const BooleanInputStyleRules: StyleRulesCallback<Theme, {}, BooleanInputClassKey> = (theme: Theme) =>
    createStyles({
        label: {
            fontSize: 14,
            flex: '0 0 auto',
            marginRight: theme.spacing(10),
        },
        changedField: {
            border: `2px solid ${theme.palette.warning.main}`,
            paddingBottom: theme.spacing(),
        },
    });
