import type { ResolvedConversation } from '~/services/ApiClient';
import { OptimisticOutgoingConversationMessage } from '~/services/ApiClient';

export const removeOptimisticConversationMessage = (
    conversation: ResolvedConversation,
    messageCorrelationId: string
): ResolvedConversation => ({
    ...conversation,
    messages: conversation.messages.filter((message) => {
        return !(
            message.value instanceof OptimisticOutgoingConversationMessage &&
            message.value.correlationId === messageCorrelationId
        );
    }),
});
