import { Clear, Search as SearchIcon } from '@mui/icons-material';
import { Input, InputAdornment, Paper, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DebouncedFunc } from '~/libs/utility';
import { debounce } from '~/libs/utility';

import { useStyles } from './styles';

export interface SearchProps {
    defaultSearchQuery?: string;
    onSearchQueryChange: (searchQuery: string) => void;
    clearSearchQuery: () => void;
    classes?: ReturnType<typeof useStyles>;
}

const Search: FC<SearchProps> = (props) => {
    const { clearSearchQuery, defaultSearchQuery, onSearchQueryChange } = props;

    const { t } = useTranslation();
    const classes = useStyles({ classes: props.classes });
    const debounceRef = useRef<DebouncedFunc<(searchQuery?: string) => void>>();
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (debounceRef.current) {
            debounceRef.current.cancel();
        }

        debounceRef.current = debounce((searchQuery?: string) => {
            if (searchQuery) {
                onSearchQueryChange(searchQuery);
            } else {
                clearSearchQuery();
            }
        }, 500);

        return () => debounceRef.current && debounceRef.current.flush();
    }, [onSearchQueryChange, clearSearchQuery]);

    const [currentSearchQuery, setCurrentSearchQuery] = useState(defaultSearchQuery || '');

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setCurrentSearchQuery(e.target.value);
            if (debounceRef.current) {
                debounceRef.current(e.target.value);
            }
        },
        [setCurrentSearchQuery]
    );

    const handlePaperClick = useCallback(() => inputRef.current && inputRef.current.focus(), []);

    const clearClick = useCallback(() => {
        if (debounceRef.current) {
            debounceRef.current.cancel();
        }

        clearSearchQuery();
        setCurrentSearchQuery('');
    }, [clearSearchQuery, setCurrentSearchQuery]);

    const startAdornment = (
        <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
        </InputAdornment>
    );

    const endAdornment = currentSearchQuery && (
        <InputAdornment position="end">
            <Tooltip title={t('clear-search')}>
                <Clear className={classes.closeIcon} onClick={clearClick} data-id="clear-search" />
            </Tooltip>
        </InputAdornment>
    );

    const inputClasses = {
        root: classes.input,
        input: classNames({ [classes.inputPlaceholder]: !currentSearchQuery }),
    };

    return (
        <Paper elevation={0} className={classes.root} onClick={handlePaperClick}>
            <Typography variant="body2" component="div" className={classes.typography}>
                <Input
                    inputRef={inputRef}
                    classes={inputClasses}
                    fullWidth
                    disableUnderline
                    value={currentSearchQuery}
                    onChange={handleChange}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment}
                    placeholder={t('type-to-search')}
                    data-id="search-input"
                />
            </Typography>
        </Paper>
    );
};

Search.displayName = 'Search';
export default Search;
