import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import type { CheckboxWorkflowFieldInnerProps, CheckboxWorkflowFieldProps } from './component';
import { CheckboxWorkflowFieldComponent } from './component';
import { CheckboxWorkflowFieldStyleRules } from './styles';

export const CheckboxWorkflowField = compose<CheckboxWorkflowFieldInnerProps, CheckboxWorkflowFieldProps>(
    setDisplayName('CheckboxWorkflowField'),
    withStyles(CheckboxWorkflowFieldStyleRules)
)(CheckboxWorkflowFieldComponent);
