import type { StyledComponentProps, WithStyles } from '@mui/styles';
import type { ComponentType, RefObject, UIEvent } from 'react';
import { Component, createRef } from 'react';

import type { AutoSizerInjectedProps } from '~/components/AutoSizer';
import { debounce } from '~/libs/utility';

import type { ReverseInfiniteScrollClassKey } from './styles';

export interface ItemAnchor {
    offset: number;
    identifier: number | string;
}

export interface ScrollPosition {
    itemAnchor?: ItemAnchor;
    stickToBottom: boolean;
}

export interface ReverseInfiniteScrollProps<T> {
    hasMore: boolean;
    scrollPosition?: ScrollPosition;
    items: T[];
    loading: boolean;
    threshold: number;
    dataId: string;
    statusIndicator?: JSX.Element;
    itemRenderer: (item: T, index: number) => JSX.Element;
    getItemId: (item: T) => number | string;
    loadMore: () => void;
    onScrollPositionChanged: (scrollPosition: ScrollPosition) => void;
}

export interface ReverseInfiniteScrollOuterProps<T>
    extends ReverseInfiniteScrollProps<T>,
        StyledComponentProps<ReverseInfiniteScrollClassKey> {
    scrollContainerRef?: RefObject<HTMLDivElement>;
}

export interface ReverseInfiniteScrollInnerProps<T>
    extends ReverseInfiniteScrollProps<T>,
        AutoSizerInjectedProps,
        WithStyles<ReverseInfiniteScrollClassKey> {
    scrollContainerRef?: RefObject<HTMLDivElement>;
}

export const ReverseInfiniteScrollComponentFactory = <T,>(): ComponentType<ReverseInfiniteScrollInnerProps<T>> =>
    class extends Component<ReverseInfiniteScrollInnerProps<T>> {
        private itemsContainerRef = createRef<HTMLDivElement>();
        private scrollContainerRef = this.props.scrollContainerRef ?? createRef<HTMLDivElement>();

        private lastItemAnchor = this.props.scrollPosition && this.props.scrollPosition.itemAnchor;

        private lastScrollContainerScrollTop = 0;
        private lastScrollContainerScrollHeight = 0;

        private updateScrollPositionItemAnchorDebounced = debounce(this.updateScrollPositionItemAnchor, 150);

        public componentDidMount() {
            this.setInitialScrollPosition();
            this.updateScrollContainerScrollHeight();
        }

        public componentDidUpdate(prevProps: Readonly<ReverseInfiniteScrollInnerProps<T>>) {
            this.updateScrollPosition(prevProps);
            this.updateScrollContainerScrollHeight();
        }

        public componentWillUnmount() {
            this.updateScrollPositionItemAnchorDebounced.flush();
        }

        public render() {
            const { size, classes, items, statusIndicator, itemRenderer, dataId } = this.props;

            return (
                <div
                    ref={this.scrollContainerRef}
                    style={size}
                    className={classes.root}
                    onScroll={this.handleScroll}
                    data-id={dataId}
                >
                    <div className={classes.pusher} />
                    <div className={classes.content}>
                        {statusIndicator && <div className={classes.statusIndicator}>{statusIndicator}</div>}
                        <div ref={this.itemsContainerRef} className={classes.itemsContainer}>
                            {items.map((item, index) => itemRenderer(item, index))}
                        </div>
                    </div>
                </div>
            );
        }

        private setInitialScrollPosition() {
            const { scrollPosition } = this.props;

            if (!scrollPosition || scrollPosition.stickToBottom) {
                this.scrollToBottom();
            } else if (scrollPosition.itemAnchor) {
                this.scrollToItemAnchor(scrollPosition.itemAnchor);
            }
        }

        private scrollToBottom() {
            if (this.scrollContainerRef.current) {
                this.scrollContainerRef.current.scrollTop = this.scrollContainerRef.current.scrollHeight;
            }
        }

        private scrollToItemAnchor(itemAnchor: ItemAnchor) {
            if (!this.scrollContainerRef.current || !this.itemsContainerRef.current) {
                return;
            }

            const targetItemIndex = this.props.items.findIndex(
                (item) => this.props.getItemId(item) === itemAnchor.identifier
            );

            if (targetItemIndex > -1) {
                const targetItemBoundingClientRect =
                    this.itemsContainerRef.current.children[targetItemIndex].getBoundingClientRect();

                this.scrollContainerRef.current.scrollTop =
                    this.scrollContainerRef.current.scrollTop +
                    targetItemBoundingClientRect.top -
                    this.scrollContainerRef.current.getBoundingClientRect().top -
                    itemAnchor.offset;
            }
        }

        private updateScrollContainerScrollHeight() {
            if (this.scrollContainerRef.current) {
                this.lastScrollContainerScrollHeight = this.scrollContainerRef.current.scrollHeight;
            }
        }

        private updateScrollPosition(prevProps: Readonly<ReverseInfiniteScrollInnerProps<T>>) {
            const { scrollPosition, loading } = this.props;

            if (!scrollPosition || scrollPosition.stickToBottom) {
                this.scrollToBottom();
            } else if (scrollPosition.itemAnchor && scrollPosition.itemAnchor !== this.lastItemAnchor) {
                this.scrollToItemAnchor(scrollPosition.itemAnchor);
            } else if (prevProps.loading && !loading) {
                this.updateScrollTopAfterLoadingMore();
            }
        }

        private updateScrollTopAfterLoadingMore() {
            if (this.scrollContainerRef.current) {
                this.scrollContainerRef.current.scrollTop =
                    this.scrollContainerRef.current.scrollHeight -
                    this.lastScrollContainerScrollHeight +
                    this.lastScrollContainerScrollTop;
            }
        }

        private handleScroll = ({ currentTarget }: UIEvent<HTMLDivElement>) => {
            this.handleScrollPositionChanged(currentTarget);
            this.handleLoadMore(currentTarget.scrollTop);

            this.lastScrollContainerScrollTop = currentTarget.scrollTop;
        };

        private handleScrollPositionChanged(scrollContainer: HTMLDivElement) {
            const { scrollPosition, onScrollPositionChanged } = this.props;

            const nextStickToBottom = this.isStickToBottom(scrollContainer);

            if (!scrollPosition || scrollPosition.stickToBottom !== nextStickToBottom) {
                onScrollPositionChanged({ ...scrollPosition, stickToBottom: nextStickToBottom });
            }

            this.updateScrollPositionItemAnchorDebounced();
        }

        private isStickToBottom({ offsetHeight, scrollTop, scrollHeight }: HTMLDivElement) {
            /**
             * scrollTop can be float, so this requires some tolerance.
             * https://stackoverflow.com/a/32283147
             */
            return scrollHeight - (offsetHeight + scrollTop) < 1;
        }

        private handleLoadMore(scrollTop: number) {
            const { loading, hasMore, threshold, loadMore } = this.props;

            if (!loading && hasMore && scrollTop < threshold) {
                loadMore();
            }
        }

        private updateScrollPositionItemAnchor() {
            if (!this.props.scrollPosition || this.props.scrollPosition.stickToBottom) {
                return;
            }

            if (this.scrollContainerRef.current && this.itemsContainerRef.current) {
                const scrollContainerBoundingClientRect = this.scrollContainerRef.current.getBoundingClientRect();

                Array.from(this.itemsContainerRef.current.children).some((item, index) => {
                    const itemBoundingClientRect = item.getBoundingClientRect();

                    if (
                        itemBoundingClientRect.top + itemBoundingClientRect.height >=
                        scrollContainerBoundingClientRect.top
                    ) {
                        this.lastItemAnchor = {
                            offset: itemBoundingClientRect.top - scrollContainerBoundingClientRect.top,
                            identifier: this.props.getItemId(this.props.items[index]),
                        };

                        this.props.onScrollPositionChanged({
                            itemAnchor: this.lastItemAnchor,
                            stickToBottom: false,
                        });

                        return true;
                    }

                    return false;
                });
            }
        }
    };
