import type { ActionMeta } from '~/types';

export enum ActionTypeKeys {
    ETHEREAL_UPDATE = 'ETHEREAL_UPDATE',
}

export interface EtherealStateUpdatePayload {
    key: string;
    value: object;
}

export type ActionTypes = ActionMeta<ActionTypeKeys.ETHEREAL_UPDATE, EtherealStateUpdatePayload, string>;
