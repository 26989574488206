import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TemperatureSensorClassKey = 'root' | 'high' | 'low';

export const TemperatureSensorStyleRules: StyleRulesCallback<Theme, {}, TemperatureSensorClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
            paddingTop: theme.spacing(0.25),
            paddingBottom: theme.spacing(0.25),
            marginRight: theme.spacing(-0.5),
            borderRadius: 3,
        },
        high: {
            backgroundColor: theme.functionalItemsColors.temperature.high.value,
            color: theme.palette.common.white,
        },
        low: {
            backgroundColor: theme.functionalItemsColors.temperature.low.value,
            color: theme.palette.common.white,
        },
    });
