import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { SendingMessagesDisabledInnerProps, SendingMessagesDisabledProps } from './component';
import { SendingMessagesDisabledComponent } from './component';
import { SendingMessagesDisabledStyleRules } from './styles';

export const SendingMessagesDisabled = compose<SendingMessagesDisabledInnerProps, SendingMessagesDisabledProps>(
    setDisplayName('SendingMessagesDisabled'),
    withTranslation(),
    withStyles(SendingMessagesDisabledStyleRules)
)(SendingMessagesDisabledComponent);
