import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DoorsSectionClassKey = 'doorStatuses';

export const DoorsSectionStyleRules: StyleRulesCallback<Theme, {}, DoorsSectionClassKey> = (theme: Theme) =>
    createStyles({
        doorStatuses: {
            display: 'flex',
            '&>*': {
                marginLeft: theme.spacing(1),
            },
        },
    });
