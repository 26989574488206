import type { Option } from '~/components/RemoteAutocomplete';
import type { NumericDictionary } from '~/libs/utility';
import type { Customer, CustomerSecurables } from '~/services/ApiClient';
import { compareFactory, stringComparer } from '~/services/Sorting';

import { getCustomerOption } from './getCustomerOption';

const customerComparer = compareFactory((u: Customer) => u.name, stringComparer);

export const mapCustomersToCustomerOptions = (
    customersHash: NumericDictionary<Customer>,
    customerSecurablesHash: NumericDictionary<CustomerSecurables>
): Array<Option<Customer>> => {
    const customerArray: Customer[] = Object.keys(customersHash).map((key) => customersHash[key]);

    return customerArray
        .sort(customerComparer)
        .map((customer) => getCustomerOption(customer, customerSecurablesHash[customer.id]));
};
