import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ControlClassKey = 'input';

export const ControlStyleRules: StyleRulesCallback<Theme, {}, ControlClassKey> = () =>
    createStyles({
        input: {
            display: 'flex',
            padding: 0,
            height: 'auto',
        },
    });
