import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { VehicleFilteringInnerProps, VehicleFilteringProps } from './component';
import { VehicleFilteringComponent } from './component';
import { VehicleFilteringStyleRules } from './styles';

export type { VehicleFilter } from './component';

export const VehicleFiltering = compose<VehicleFilteringInnerProps, VehicleFilteringProps>(
    setDisplayName('VehicleFiltering'),
    withTranslation(),
    withStyles(VehicleFilteringStyleRules)
)(VehicleFilteringComponent);
