import * as React from 'react';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification, formatDeviceType, formatVehicleCategory } from '~/services/Formatters';

import { SectionName } from '../../constants';
import type { CommonSectionProps } from '../../models';

export interface GeneralSectionInnerProps extends CommonSectionProps, InjectedTranslationProps {}

export const GeneralSectionComponent: React.FunctionComponent<GeneralSectionInnerProps> = ({
    adminVehicle,
    t,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
}) => {
    const keyValueItems: KeyValueItem[] = [
        {
            valueDataId: 'uaid',
            label: t('uaid'),
            value: adminVehicle.uaid ? <span>{adminVehicle.uaid}</span> : undefined,
        },
        {
            valueDataId: 'vin',
            label: t('vin'),
            value: adminVehicle.vin ? <span>{adminVehicle.vin}</span> : undefined,
        },
        {
            valueDataId: 'vrn',
            label: t('vrn'),
            value: adminVehicle.vrn ? <span>{adminVehicle.vrn}</span> : undefined,
        },
        {
            valueDataId: 'alias',
            label: t('alias'),
            value:
                adminVehicle.settings && adminVehicle.settings.alias ? (
                    <span>{adminVehicle.settings.alias}</span>
                ) : undefined,
        },
        {
            valueDataId: 'unit-id',
            label: t('unit-id'),
            value: adminVehicle.unitId ? <span>{adminVehicle.unitId}</span> : undefined,
        },
        {
            valueDataId: 'msisdn',
            label: t('msisdn'),
            value:
                adminVehicle.device && adminVehicle.device.msisdn ? (
                    <span>{adminVehicle.device.msisdn}</span>
                ) : undefined,
        },
        {
            valueDataId: 'device-type',
            label: t('device-type'),
            value: adminVehicle.deviceType && <span>{formatDeviceType(t, adminVehicle.deviceType.key)}</span>,
        },
        {
            valueDataId: 'vehicle-category',
            label: t('vehicle-category'),
            value: adminVehicle.category && <span>{formatVehicleCategory(t, adminVehicle.category)}</span>,
        },
        {
            valueDataId: 'network-id',
            label: t('network-id'),
            value:
                adminVehicle.device && adminVehicle.device.networkId ? (
                    <span>{formatClassification(t, ClassificationType.Network, adminVehicle.device.networkId)}</span>
                ) : undefined,
        },
        {
            valueDataId: 'tag',
            label: t('tag'),
            value: adminVehicle.tag ? <span>{adminVehicle.tag}</span> : undefined,
        },
        {
            valueDataId: 'depot',
            label: t('depot'),
            value: adminVehicle.depot && <span>{adminVehicle.depot.name}</span>,
        },
        {
            valueDataId: 'vehicle-type',
            label: t('vehicle-type'),
            value: adminVehicle.vehicleType && <span>{adminVehicle.vehicleType.name}</span>,
        },
        {
            valueDataId: 'billing-group',
            label: t('billing-group'),
            value: adminVehicle.device && adminVehicle.device.billingGroup && (
                <span>
                    {formatClassification(t, ClassificationType.BillingGroup, adminVehicle.device.billingGroup)}
                </span>
            ),
        },
        {
            valueDataId: 'billing-status',
            label: t('billing-status'),
            value: adminVehicle.device && adminVehicle.device.billingStatus && (
                <span>
                    {formatClassification(t, ClassificationType.BillingStatus, adminVehicle.device.billingStatus)}
                </span>
            ),
        },
        {
            valueDataId: 'company-card-id',
            label: t('company-card-id'),
            value: adminVehicle.companyCardId ? <span>{adminVehicle.companyCardId}</span> : undefined,
        },
    ];

    return (
        <ReorderableSection
            dataId="vehicle-administration-general-section"
            title={t(SectionName.VEHICLEADMINISTRATIONGENERAL)}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="general-list" />
        </ReorderableSection>
    );
};
