import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ConversationSummaryFirstLineInnerProps, ConversationSummaryFirstLineProps } from './component';
import { ConversationSummaryFirstLineComponent } from './component';
import { ConversationSummaryFirstLineStyleRules } from './styles';

export const ConversationSummaryFirstLine = compose<
    ConversationSummaryFirstLineInnerProps,
    ConversationSummaryFirstLineProps
>(
    setDisplayName('ConversationSummaryFirstLine'),
    withTranslation(),
    withStyles(ConversationSummaryFirstLineStyleRules)
)(ConversationSummaryFirstLineComponent);
