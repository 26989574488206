import { TableCell, Tooltip } from '@mui/material';
import classnames from 'classnames';
import type { FC, RefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { TableBodyCellProps } from './models';
import { useStyles } from './styles';

const TableBodyCell: FC<TableBodyCellProps> = (props) => {
    const { text, valueDataId, ...restProps } = props;
    const isEllipsisActive = useCallback((e: RefObject<HTMLDivElement>): boolean => {
        return e.current !== null && e.current.offsetWidth < e.current.scrollWidth;
    }, []);

    const ref = useRef<HTMLDivElement>(null);

    const classes = useStyles();

    const ellipsisActive = useMemo(() => {
        return (
            <Tooltip title={text}>
                <div ref={ref} className={classes.cellText}>
                    {text}
                </div>
            </Tooltip>
        );
    }, [classes.cellText, text]);

    const ellipsisInactive = useMemo(() => {
        return (
            <div ref={ref} className={classes.cellText}>
                {text}
            </div>
        );
    }, [classes.cellText, text]);

    const [innerComponent, setInnerComponent] = useState<JSX.Element>(ellipsisInactive);

    useEffect(() => {
        if (ref !== null && ref.current !== null && isEllipsisActive(ref)) {
            setInnerComponent(ellipsisActive);
        } else {
            setInnerComponent(ellipsisInactive);
        }
    }, [ellipsisActive, ellipsisInactive, isEllipsisActive]);

    return (
        <TableCell {...restProps} className={classnames(classes.cell, restProps.className)} data-id={valueDataId}>
            {innerComponent}
        </TableCell>
    );
};

TableBodyCell.displayName = 'TableBodyCell';
export { TableBodyCell };
