import loadable from '@loadable/component';

const Document = loadable(() => import('react-pdf'), {
    resolveComponent: (module) => {
        // eslint-disable-next-line no-param-reassign
        module.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${module.pdfjs.version}/pdf.worker.js`;
        return module.Document;
    },
});

Document.displayName = 'Document';
export default Document;
