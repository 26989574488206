import { List } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDisplayPreferences } from '~/common/hooks';
import { VehicleNameFormatter } from '~/components/Formatters';
import { ReorderableSection } from '~/components/Sections';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import type { ResolvedVehicle } from '~/services/ApiClient';

import type { SectionProps } from '../../../models';

import { Vehicle } from './components/Vehicle';
import { useStyles } from './styles';

const VehicleSection: FC<SectionProps> = (props) => {
    const { dragHandleElement, toggleCollapsed, driverStatus, isCollapsed } = props;
    const { vehicle } = props.driverStatus;
    const { t } = useTranslation();
    const displayPreferences = useDisplayPreferences();
    const classes = useStyles();

    return (
        <ReorderableSection
            dataId="vehicle"
            title={t('vehicle')}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            rightHeaderElement={
                <TitledTruncatedText>
                    <span
                        className={classNames({
                            [classes.disabled]: !vehicle,
                            [classes.notConnected]: !vehicle,
                        })}
                        data-id="vehicle-name"
                    >
                        {vehicle ? (
                            <VehicleNameFormatter
                                value={vehicle as ResolvedVehicle}
                                vehicleDisplayNameFormat={displayPreferences.vehicleDisplayFormat}
                            />
                        ) : (
                            t('not-connected')
                        )}
                    </span>
                </TitledTruncatedText>
            }
        >
            <List disablePadding>
                <Vehicle driverStatus={driverStatus} />
            </List>
        </ReorderableSection>
    );
};

VehicleSection.displayName = 'VehicleSection';
export default VehicleSection;
