import type { GridColumnDefinition } from '~/components/Grid';
import { filterDataSourceBySearchQuery } from '~/components/Grid';
import { memoizeOne } from '~/services/Memoize';

import type { HistoryTrailerEntry } from '../../../../models';

export const filterHistoryDataSourceMemoized = memoizeOne(
    (
        searchQuery: string | undefined,
        dataSource: HistoryTrailerEntry[],
        columnDefinitions: Array<GridColumnDefinition<HistoryTrailerEntry>>,
        visibleColumns: string[]
    ) => filterDataSourceBySearchQuery(searchQuery, dataSource, columnDefinitions, visibleColumns)
);
