import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ReverseInfiniteScrollClassKey = 'root' | 'pusher' | 'content' | 'statusIndicator' | 'itemsContainer';

export const ReverseInfiniteScrollStyleRules: StyleRulesCallback<Theme, {}, ReverseInfiniteScrollClassKey> = () =>
    createStyles({
        root: {
            position: 'relative',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
        },
        pusher: {
            flexGrow: 1,
        },
        content: {},
        statusIndicator: {},
        itemsContainer: {},
    });
