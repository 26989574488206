import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { compose, setDisplayName, wrapDisplayName } from 'react-recompose';

import type { TimelineItemForwardRefProps, TimelineItemInnerProps, TimelineItemOuterProps } from './component';
import { TimelineItemComponent } from './component';
import { TimelineItemStyleRules } from './styles';

export { splitTimelineSegmentType } from './splitTimelineSegmentType';
export type { TimelineSegmentType } from './component';
export type { TimelineItemClassKey } from './styles';

const BaseTimelineItem = compose<TimelineItemInnerProps, TimelineItemOuterProps & TimelineItemForwardRefProps>(
    setDisplayName('TimelineItem'),
    withStyles(TimelineItemStyleRules)
)(TimelineItemComponent);

export const TimelineItem = setDisplayName(wrapDisplayName(BaseTimelineItem, 'ForwardRef'))(
    React.forwardRef<HTMLLIElement, Exclude<TimelineItemOuterProps, TimelineItemForwardRefProps>>((props, ref) =>
        React.createElement(BaseTimelineItem, { ...props, forwardedRef: ref })
    )
);
