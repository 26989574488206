import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import classNames from 'classnames';
import type { FC } from 'react';

import {
    ClockIcon,
    DeleteIcon,
    DoubleCheckIcon,
    IncomingReadIcon,
    IncomingUnreadIcon,
    OutgoingScheduledIcon,
} from '~/components/Icons';
import { MessageStatus } from '~/services/ApiClient';

import type { IconFormatterProps } from './models';
import { useStyles } from './styles';

const IconFormatter: FC<IconFormatterProps> = (props) => {
    const { value, iconProps } = props;

    const classes = useStyles();

    switch (value) {
        case MessageStatus.Received:
            return <IncomingUnreadIcon {...iconProps} />;
        case MessageStatus.Deleted:
            return <DeleteIcon {...iconProps} />;
        case MessageStatus.IncomingRead:
            return <IncomingReadIcon {...iconProps} />;
        case MessageStatus.Pending:
            return <Check {...iconProps} />;
        case MessageStatus.Scheduled:
            return <OutgoingScheduledIcon {...iconProps} />;
        case MessageStatus.Sending:
            return <ClockIcon {...iconProps} />;
        case MessageStatus.Failed:
        case MessageStatus.GeneralFailure:
        case MessageStatus.ValidationFailure:
            return <Close {...iconProps} className={classNames(iconProps?.className, classes.failedIcon)} />;
        case MessageStatus.Delivered:
            return <DoubleCheckIcon {...iconProps} />;
        case MessageStatus.OutgoingRead:
            return <DoubleCheckIcon {...iconProps} className={classNames(iconProps?.className, classes.readIcon)} />;
        default: {
            return null;
        }
    }
};

IconFormatter.displayName = 'IconFormatter';
export default IconFormatter;
