import type { AssetReference, AssetType } from '~/services/ApiClient';

import type { CustomSceneUserPreferencesType } from './models';

export enum SceneAssetSelectorViewMode {
    ASSETS = 1,
    DEPOTS = 2,
    GROUPS = 3,
}

export interface SceneAssetSelectorUserPreferences {
    viewMode: SceneAssetSelectorViewMode;
    selectedAssetIds?: AssetReference[];
    expandedGroupIds: number[];
    expandedDepotIds: number[];
    collapsedAssetTypes: AssetType[];
    selectedParentGroupId?: number;
}

export const defaultSceneAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences = {
    viewMode: SceneAssetSelectorViewMode.ASSETS,
    selectedAssetIds: undefined,
    expandedGroupIds: [],
    expandedDepotIds: [],
    collapsedAssetTypes: [],
};

export const requiredSceneAssetSelectorUserPreferencesFactory = <T>(
    preferencesKey: string,
    defaultState?: T,
    processResponse?: (values: T) => T
): CustomSceneUserPreferencesType<T> => ({
    key: preferencesKey,
    defaultState: defaultState ?? defaultSceneAssetSelectorUserPreferences,
    ...(processResponse && { processResponse }),
});
