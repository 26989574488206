import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    tooltip: { '&.apexcharts-tooltip': { zIndex: 1000 } },
    noData: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(15),
        minHeight: theme.custom.dialogContent.minHeight,
    },
}));
