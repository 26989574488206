import { InputAdornment, TextField } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import {
    KEY_0,
    KEY_9,
    KEY_BACK_SPACE,
    KEY_DASH,
    KEY_DELETE,
    KEY_DOWN,
    KEY_LEFT,
    KEY_NUMPAD0,
    KEY_NUMPAD9,
    KEY_RIGHT,
    KEY_TAB,
    KEY_UP,
} from 'keycode-js';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNaN, isNil, isUndefined } from '~/libs/utility';
import type { NumericFieldWorkflowFormElement } from '~/services/ApiClient';

import type { NumericWorkflowFieldClassKey } from './styles';

export interface NumericWorkflowFieldProps {
    field: NumericFieldWorkflowFormElement;
    label?: string;
    dataId: string;
    disabled?: boolean;
}

export interface NumericWorkflowFieldInnerProps
    extends NumericWorkflowFieldProps,
        InjectedTranslationProps,
        WithStyles<NumericWorkflowFieldClassKey> {}

export const NumericWorkflowFieldComponent: React.FC<NumericWorkflowFieldInnerProps> = ({
    field,
    label,
    dataId,
    disabled,
    t,
    classes,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { id, isRequired, maxValue, minValue, isReadOnly, decimals, displayFormat } = field;
    const error = errors[field.id];
    const adornment = displayFormat && displayFormat.trim().split(' ').join('').slice(3);

    const requiredMessage = t('wf-field-error-required');
    const numericInputClassName = classes[`${field.horizontalAlignment}Alignment`];

    const rules = React.useMemo(() => {
        return {
            required: { value: isRequired, message: requiredMessage },
            min: !isUndefined(minValue)
                ? {
                      value: minValue,
                      message: isUndefined(maxValue)
                          ? t('wf-field-error-min-value', { minValue })
                          : t('wf-field-error-range', { maxValue, minValue }),
                  }
                : undefined,
            max: !isUndefined(maxValue)
                ? {
                      value: maxValue,
                      message: isUndefined(minValue)
                          ? t('wf-field-error-max-value', { maxValue })
                          : t('wf-field-error-range', { maxValue, minValue }),
                  }
                : undefined,
        };
    }, [t, isRequired, maxValue, minValue, requiredMessage]);

    const countDecimals = (value: number) => {
        if (value && Math.floor(value) !== value) {
            return value.toString().split('.')[1].length || 0;
        }
        return 0;
    };

    const onChangeHandler = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onChange: (...event: unknown[]) => void) => {
            const { value } = e.currentTarget;
            const numberValue = parseFloat(value);
            if (isNil(numberValue) || isNaN(numberValue)) {
                onChange(undefined);
                return;
            }
            const inputDecimals = countDecimals(numberValue);
            if (inputDecimals > decimals) {
                e.preventDefault();
                return;
            }
            onChange(numberValue);
        },
        [decimals]
    );

    const onKeyDownHandler = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        const keyDown = event.keyCode;
        const KEY_FIREFOX_DASH = 173;

        const isNumpad = keyDown >= KEY_NUMPAD0 && keyDown <= KEY_NUMPAD9;

        if (
            keyDown === KEY_DASH ||
            keyDown === KEY_FIREFOX_DASH ||
            keyDown === KEY_BACK_SPACE ||
            keyDown === KEY_DELETE ||
            keyDown === KEY_TAB ||
            keyDown === KEY_UP ||
            keyDown === KEY_DOWN ||
            keyDown === KEY_LEFT ||
            keyDown === KEY_RIGHT
        ) {
            return;
        }

        if (!isNumpad && (keyDown < KEY_0 || keyDown > KEY_9)) {
            event.preventDefault();
        }
    }, []);

    return (
        <Controller
            name={`${id}`}
            render={({ field: fieldFormProps }) => (
                <TextField
                    {...fieldFormProps}
                    value={isNil(fieldFormProps.value) ? '' : fieldFormProps.value}
                    type="number"
                    fullWidth
                    data-id={dataId}
                    label={label}
                    size="small"
                    disabled={disabled}
                    InputProps={{
                        readOnly: isReadOnly,
                        classes: {
                            input: numericInputClassName,
                        },
                        endAdornment: adornment && <InputAdornment position="end">{adornment}</InputAdornment>,
                    }}
                    variant="outlined"
                    onChange={(e) => onChangeHandler(e, fieldFormProps.onChange)}
                    onKeyDown={onKeyDownHandler}
                    required={isRequired}
                    error={!!error}
                    helperText={error ? error.message : isRequired ? requiredMessage : undefined}
                />
            )}
            control={control}
            rules={rules}
        />
    );
};
