import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import { AutocompleteDropdownInputComponent } from './component';
import type { AutocompleteDropdownInputInnerProps, AutocompleteDropdownInputProps } from './models';
import { AutocompleteDropdownInputStyleRules } from './styles';

export const AutocompleteDropdownInput = compose<AutocompleteDropdownInputInnerProps, AutocompleteDropdownInputProps>(
    setDisplayName('AutocompleteDropdownInput'),
    withStyles(AutocompleteDropdownInputStyleRules),
    withTranslation()
)(AutocompleteDropdownInputComponent);
