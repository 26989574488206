import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type UserAvatarClassKey = 'avatar';

export const UserAvatarStyleRules: StyleRulesCallback<Theme, {}, UserAvatarClassKey> = (theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
    });
