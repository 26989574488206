import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type SectionContentMessageClassKey = 'root' | 'message';

export const useStyles = makeStyles<Theme, {}, SectionContentMessageClassKey>((theme: Theme) => ({
    root: {
        height: 135,
        backgroundColor: theme.palette.background.default,
    },
    message: {
        fontStyle: 'italic',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
