import type { Filter } from '@devexpress/dx-react-grid';
import { IntegratedFiltering } from '@devexpress/dx-react-grid';
import moment from 'moment';

import { isUndefined } from '~/libs/utility';
import { compareStrings } from '~/services/Sorting';

import { nullFilterValue } from './constants';
import type { FilterDateTimeValue, FilterDurationValue, GridColumnDefinition } from './models';
import { GridFilterOperation } from './models';

const defaultFilterEqulas = (filterValue: unknown, cellValue: unknown): boolean => {
    return filterValue === cellValue;
};

export const createFilteringColumnExtensions = <T>(
    columnDefinitions: Array<GridColumnDefinition<T>>,
    visibleColumns: string[]
): IntegratedFiltering.ColumnExtension[] => {
    return columnDefinitions.map((c) => ({
        columnName: c.name,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        predicate: (value: any, filter: Filter, row: T): boolean => {
            if (isUndefined(filter.value) || !visibleColumns.find((vc) => vc === c.name)) {
                return true;
            }

            if (c.cellFiltering) {
                const selectedFilterValues: unknown[] = filter.value ? JSON.parse(filter.value) : [];

                const cellValue: unknown | undefined =
                    c.getFilterValue && !isUndefined(value) ? c.getFilterValue(value) : value;

                if (selectedFilterValues.length > 0) {
                    const filterFn = c.cellFiltering.valueEquals ?? defaultFilterEqulas;
                    return selectedFilterValues.some((filterValue) => filterFn(filterValue, cellValue));
                } else {
                    return true;
                }
            }

            if (c.dataType === 'date') {
                const dateFilterValue: FilterDateTimeValue | undefined = JSON.parse(filter.value);

                if (!dateFilterValue) {
                    return true;
                }

                const min = moment(dateFilterValue.min).toDate();
                const max = moment(dateFilterValue.max).toDate();

                const filterValue: Date | undefined =
                    c.getFilterValue && !isUndefined(value) ? c.getFilterValue(value) : value;

                const convertedValue = moment.isDate(filterValue) ? filterValue : undefined;

                switch (filter.operation) {
                    case GridFilterOperation.YearEqual:
                    case GridFilterOperation.MonthEqual:
                        return !!convertedValue && convertedValue >= min && convertedValue < max;
                    case GridFilterOperation.Before:
                        return !!convertedValue && convertedValue < min;
                    case GridFilterOperation.BeforeOrEqual:
                        return !!convertedValue && convertedValue < max;
                    case GridFilterOperation.After:
                        return !!convertedValue && convertedValue >= max;
                    case GridFilterOperation.AfterOrEqual:
                        return !!convertedValue && convertedValue >= min;
                    default:
                        return false;
                }
            } else if (c.dataType === 'number') {
                const filterValue = parseFloat(filter.value);
                const rowValue = c.getFilterValue && !isUndefined(value) ? c.getFilterValue(value) : value;
                const convertedValue = typeof rowValue === 'number' ? rowValue : undefined;

                switch (filter.operation) {
                    case GridFilterOperation.Equal:
                        return convertedValue === filterValue;
                    case GridFilterOperation.NotEqual:
                        return convertedValue !== filterValue;
                    case GridFilterOperation.LessThan:
                        return !isUndefined(convertedValue) && convertedValue < filterValue;
                    case GridFilterOperation.LessThanOrEqual:
                        return !isUndefined(convertedValue) && convertedValue <= filterValue;
                    case GridFilterOperation.GreaterThan:
                        return !isUndefined(convertedValue) && convertedValue > filterValue;
                    case GridFilterOperation.GreaterThanOrEqual:
                        return !isUndefined(convertedValue) && convertedValue >= filterValue;
                    default:
                        return false;
                }
            } else if (c.dataType === 'boolean') {
                let selectedFilterValues: unknown[] = filter.value ? JSON.parse(filter.value) : [];

                selectedFilterValues = selectedFilterValues.map((it) => (it === nullFilterValue ? undefined : it));

                if (selectedFilterValues.length > 0) {
                    return selectedFilterValues.includes(value);
                } else {
                    return true;
                }
            } else if (c.dataType === 'string') {
                let valueAsString: string | undefined;
                if (!isUndefined(c.valueTextFormatter)) {
                    valueAsString = c.valueTextFormatter(value);
                } else {
                    valueAsString = value;
                }
                if (filter.operation === GridFilterOperation.Equal) {
                    return compareStrings(valueAsString, filter.value) === 0;
                } else if (filter.operation === GridFilterOperation.NotEqual) {
                    return compareStrings(valueAsString, filter.value) !== 0;
                }
                return IntegratedFiltering.defaultPredicate(valueAsString, filter, row);
            } else if (c.dataType === 'duration') {
                const durationFilterValue: FilterDurationValue | undefined = JSON.parse(filter.value);

                if (!durationFilterValue) {
                    return true;
                }

                const min = moment.duration(durationFilterValue.min);
                const max = moment.duration(durationFilterValue.max);

                const filterValue = c.getFilterValue && !isUndefined(value) ? c.getFilterValue(value) : value;

                const convertedValue =
                    typeof filterValue === 'string'
                        ? moment.duration(filterValue as string)
                        : moment.isDuration(filterValue)
                          ? filterValue
                          : undefined;

                switch (filter.operation) {
                    case GridFilterOperation.Equal:
                        return !!convertedValue && convertedValue >= min && convertedValue < max;
                    case GridFilterOperation.NotEqual:
                        return !convertedValue || convertedValue < min || convertedValue >= max;
                    case GridFilterOperation.LessThan:
                        return !!convertedValue && convertedValue < min;
                    case GridFilterOperation.LessThanOrEqual:
                        return !!convertedValue && convertedValue < max;
                    case GridFilterOperation.GreaterThan:
                        return !!convertedValue && convertedValue >= max;
                    case GridFilterOperation.GreaterThanOrEqual:
                        return !!convertedValue && convertedValue >= min;
                    default:
                        return false;
                }
            }

            return IntegratedFiltering.defaultPredicate(value, filter, row);
        },
    }));
};
