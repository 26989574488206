import { Typography } from '@mui/material';
import type { StyledComponentProps, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { forwardRef } from 'react';

import type { BubbleClassKey } from './styles';

export enum BubbleType {
    LeftTailed = 'left-tailed',
    RightTailed = 'right-tailed',
    NonTailed = 'non-tailed',
}

export interface BubbleProps extends StyledComponentProps<BubbleClassKey> {
    type: BubbleType;
    title?: JSX.Element;
    content: JSX.Element;
    footer?: JSX.Element;
    fullWidthFooter?: boolean;
}

export interface BubbleInnerProps extends Omit<BubbleProps, 'classes'>, WithStyles<BubbleClassKey> {}

const BubbleComponent = forwardRef<HTMLDivElement, BubbleInnerProps>(
    ({ classes, type, title, content, footer }, ref) => {
        const rootClassName = classNames(classes.root, classes.surface, {
            [classes.leftTailed]: type === BubbleType.LeftTailed,
            [classes.rightTailed]: type === BubbleType.RightTailed,
            [classes.nonTailed]: type === BubbleType.NonTailed,
        });

        const tailElement = type !== BubbleType.NonTailed && (
            <div className={classes.tailContainer}>
                <div className={classNames(classes.tail, classes.surface)} />
            </div>
        );

        const titleElement = title && (
            <Typography className={classes.title} variant="caption">
                {title}
            </Typography>
        );

        const footerElement = footer && (
            <Typography className={classes.footer} variant="caption" color="textSecondary">
                {footer}
            </Typography>
        );

        return (
            <div className={rootClassName} ref={ref}>
                {tailElement}
                {titleElement}
                <div className={classes.body}>
                    <Typography className={classes.content} variant="caption" color="textPrimary">
                        {content}
                    </Typography>
                    {footerElement}
                </div>
            </div>
        );
    }
);

BubbleComponent.displayName = 'Bubble';
export { BubbleComponent };
