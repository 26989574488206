import type { SelectChangeEvent } from '@mui/material';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    InputLabel,
    OutlinedInput,
    RadioGroup,
    Select,
    Tooltip,
} from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC, FocusEvent } from 'react';
import { useCallback, useMemo } from 'react';
import type { ControllerProps, ControllerRenderProps } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import { getValueByPath } from '~/common';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNil, isUndefined } from '~/libs/utility';
import { EnumControlType } from '~/services/ApiClient';

import type { BaseProps } from '../model';

import type { OptionsInputClassKey } from './styles';

export interface OptionsInputProps extends BaseProps<unknown> {
    values: JSX.Element[];
    controlType: EnumControlType;
    selectedValueParser?: (value: unknown) => unknown;
    idIsString?: boolean;
}

export interface OptionsInputInnerProps
    extends OptionsInputProps,
        InjectedTranslationProps,
        WithStyles<OptionsInputClassKey> {}

export const OptionsInputComponent: FC<OptionsInputInnerProps> = (props) => {
    const {
        values,
        disabled,
        defaultValue,
        fieldName,
        controlType,
        className,
        label,
        dataId,
        required,
        hasError,
        errorText,
        validationRules,
        markValueChange,
        classes,
        selectedValueParser,
        onValueChanged,
        onBlurChanged,
        disabledReason,
        idIsString,
        t,
    } = props;

    const { control, formState } = useFormContext();
    const fieldError = formState.errors[fieldName];
    const error = !!fieldError || hasError;
    const isDirtyField = Boolean(getValueByPath(formState.dirtyFields, fieldName));
    const requiredMessage = t('wf-field-error-required');
    const helperText = hasError && !isUndefined(errorText) ? errorText : fieldError ? fieldError.message : undefined;
    const rules = useMemo(
        () => ({ required: { value: required ?? false, message: requiredMessage }, ...validationRules }),
        [required, requiredMessage, validationRules]
    );

    const notifyChange = useCallback(
        (value: unknown, callbackFn?: (v: unknown | undefined) => void) => {
            if (!callbackFn) {
                return;
            }

            if (isNil(value)) {
                callbackFn(undefined);
            } else {
                const parsedValue =
                    !idIsString && selectedValueParser ? selectedValueParser(value as string) : (value as string);
                callbackFn(parsedValue);
            }
        },
        [idIsString, selectedValueParser]
    );

    const onChangeHandler = useCallback(
        (event: SelectChangeEvent<unknown>, field: ControllerRenderProps) => {
            notifyChange(event.target.value, (value) => {
                field.onChange(value);
                if (onValueChanged) {
                    onValueChanged(value);
                }
            });
        },
        [onValueChanged, notifyChange]
    );

    const onBlurHandler = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (event: FocusEvent<any>, field: ControllerRenderProps) => {
            notifyChange(event.target.value, (value) => {
                if (onBlurChanged) {
                    onBlurChanged(value);
                } else {
                    field.onBlur();
                }
            });
        },
        [onBlurChanged, notifyChange]
    );

    const getHelperText = useCallback(
        (value?: string) =>
            !disabled &&
            (error || helperText) && (
                <FormHelperText error={error}>
                    {error ? helperText : isNil(value) ? t('wf-field-error-please-select-an-option') : undefined}
                </FormHelperText>
            ),
        [disabled, error, helperText, t]
    );

    const dropDownRender: ControllerProps['render'] = ({ field }) => (
        <FormControl variant="outlined" size="small" data-id={dataId} className={className}>
            {!isUndefined(label) && (
                <InputLabel id={`select-field:${dataId}`} error={error}>
                    {required ? `${label} *` : label}
                </InputLabel>
            )}

            <Select
                {...field}
                data-id={`select-field:${dataId}`}
                data-testid={dataId}
                disabled={disabled}
                error={error}
                onBlur={(e) => onBlurHandler(e, field)}
                onChange={(e) => onChangeHandler(e, field)}
                value={field.value}
                variant="outlined"
                input={
                    <OutlinedInput
                        label={required ? `${label} *` : label}
                        classes={{
                            root: classes.root,
                            ...(markValueChange &&
                                !error &&
                                isDirtyField && { notchedOutline: classes.notchedOutline }),
                            disabled: classes.disabled,
                        }}
                    />
                }
            >
                {values}
            </Select>
            {getHelperText(field.value)}
        </FormControl>
    );

    const radioButtonsRender: ControllerProps['render'] = ({ field }) => (
        <FormControl
            variant="outlined"
            size="small"
            data-id={dataId}
            className={className}
            disabled={disabled}
            error={error}
        >
            {!isUndefined(label) && (
                <FormLabel error={error} component="legend">
                    {label}
                </FormLabel>
            )}
            <RadioGroup
                {...field}
                data-id={`radio-button:${dataId}`}
                value={field.value}
                onChange={(e) => onChangeHandler(e, field)}
                onBlur={(e) => onBlurHandler(e, field)}
                data-testid={dataId}
            >
                {values}
            </RadioGroup>
            {getHelperText(field.value)}
        </FormControl>
    );

    const baseComponent = () => {
        if (controlType === EnumControlType.Dropdown) {
            return (
                <Controller
                    name={fieldName}
                    defaultValue={defaultValue}
                    control={control}
                    rules={rules}
                    render={dropDownRender}
                />
            );
        } else if (controlType === EnumControlType.RadioButtons) {
            return (
                <Controller
                    name={fieldName}
                    defaultValue={defaultValue}
                    control={control}
                    rules={rules}
                    render={radioButtonsRender}
                />
            );
        } else {
            throw Error(`ControlType '${controlType}' is not supported.`);
        }
    };

    return disabledReason && disabled ? (
        <Tooltip title={disabledReason} data-id={`input-tooltip-${dataId}`} placement="right">
            <span data-id={`tooltip-span-:${dataId}`} className={className}>
                {baseComponent()}
            </span>
        </Tooltip>
    ) : (
        baseComponent()
    );
};
