import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoizeOne } from '~/services/Memoize';

import { AssetType } from '../ApiClient';

const formatDriverMemoized = memoizeOne((t: SingleTFunction) => t('driver'));
const formatVehicleMemoized = memoizeOne((t: SingleTFunction) => t('vehicle'));
const formatTrailerMemoized = memoizeOne((t: SingleTFunction) => t('trailer'));
const formatContainerMemoized = memoizeOne((t: SingleTFunction) => t('container'));
const formatUnknownMemoized = memoizeOne((t: SingleTFunction) => t('asset-type-unknown'));

export const formatAssetTypeTitle = (t: SingleTFunction, assetType?: AssetType): string => {
    switch (assetType) {
        case AssetType.Driver:
            return formatDriverMemoized(t);
        case AssetType.Trailer:
            return formatTrailerMemoized(t);
        case AssetType.Vehicle:
            return formatVehicleMemoized(t);
        case AssetType.Container:
            return formatContainerMemoized(t);
        default:
            return formatUnknownMemoized(t);
    }
};

export const formatAssetTypeTitles = (t: SingleTFunction, assetTypes: AssetType[]): string => {
    return assetTypes.map((a) => formatAssetTypeTitle(t, a)).join(', ');
};
