import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withAppVersion } from '~/components/AppVersion';

import type { AuthTemplateInnerProps, AuthTemplateProps } from './component';
import { AuthTemplateComponent } from './component';
import { AuthTemplateStyleRules } from './styles';

export const AuthTemplate = compose<AuthTemplateInnerProps, AuthTemplateProps>(
    setDisplayName('AuthTemplate'),
    withStyles(AuthTemplateStyleRules),
    withAppVersion()
)(AuthTemplateComponent);
