import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import { TrailerComponent } from './component';
import type { TrailerInnerProps, TrailerProps } from './model';
import { TrailerStyleRules } from './styles';

export const Trailer = compose<TrailerInnerProps, TrailerProps>(
    setDisplayName('Trailer'),
    withTranslation(),
    withStyles(TrailerStyleRules)
)(TrailerComponent);
