import type { ActionMeta } from 'redux-actions';

import { updateSettingAction } from '~/data/settings';

import type { SettingDetails, SettingsDataType, SettingsDetails } from '../settings';
import { settingsDetails } from '../settings';

export const updateSettingsActionCreator = <K extends keyof SettingsDetails>(
    key: K,
    item: SettingsDataType[K]
): ActionMeta<Promise<unknown>, string> => {
    const settingDetails = settingsDetails[key] as SettingDetails<K>;
    if (!settingDetails.updateData) {
        throw new Error('Readonly settings cannot be updated');
    }

    const cb = settingDetails.updateData;
    return updateSettingAction(key, () => cb(item).then(() => item));
};
