import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const GroupGridIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M3 3.9v4h18v-4H3z M9 19.7h12v-2H9V19.7z M9 15.7h12v-2H9V15.7z M9 11.7h12v-2H9V11.7z" />
        </SvgIcon>
    );
};
GroupGridIcon.displayName = 'GroupGridIcon';

export { GroupGridIcon };
