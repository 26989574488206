import * as React from 'react';
import type { Subtract } from 'utility-types';

import { CheckIcon } from '~/components/Icons';
import type { CustomIconProps } from '~/components/Icons/customIconProps';

export interface IconFormatterProps {
    iconProps?: Subtract<CustomIconProps, { ref?: unknown }>;
}

export interface IconFormatterInnerProps extends IconFormatterProps {}

const IconFormatter: React.SFC<IconFormatterInnerProps> = ({ iconProps }) => {
    return <CheckIcon {...iconProps} />;
};
IconFormatter.displayName = 'IconFormatter';

export { IconFormatter };
