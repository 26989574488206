import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import backgroundImg from './assets/fv-login-graphics.jpg';

export type AuthTemplateClassKey = 'root' | 'container' | 'gridItem' | 'header' | 'logo' | 'paper' | 'language';

export const AuthTemplateStyleRules: StyleRulesCallback<Theme, {}, AuthTemplateClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: "'Roboto', sans-serif",
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: '100vw',
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            minHeight: '100%',
            minWidth: '500px',
        },
        gridItem: {
            margin: theme.spacing(3),
        },
        header: {
            color: theme.palette.text.disabled,
            textAlign: 'right',
            margin: theme.spacing(3, 5),
        },
        logo: {
            width: '100%',
        },
        language: {
            margin: theme.spacing(3, 5),
            textAlign: 'center',
        },
        paper: {
            width: 380,
            float: 'left',
        },
    });
