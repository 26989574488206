import { decomposeColor, hslToRgb, recomposeColor } from '@mui/material';

import { isUndefined } from '~/libs/utility';

// TODO; use ~/Services/styles version when FVNXT-4578 is integrated
export const alphaColorToRgb = (backgroundColor: string, color: string): string => {
    let colorValues = decomposeColor(color);

    const alpha = colorValues.values[3];
    // No alpha-channel, no need to convert
    if (isUndefined(alpha)) {
        return color;
    }

    let backgroundValues = decomposeColor(backgroundColor);

    if (!isUndefined(backgroundValues.values[3])) {
        throw new Error('background cannot have alpha-channel');
    }

    if (backgroundValues.type === 'hsl') {
        backgroundValues = decomposeColor(hslToRgb(backgroundColor));
    }

    if (colorValues.type === 'hsla') {
        colorValues = decomposeColor(hslToRgb(color));
    }

    return recomposeColor({
        type: 'rgb',
        values: [
            (1 - alpha) * backgroundValues.values[0] + alpha * colorValues.values[0],
            (1 - alpha) * backgroundValues.values[1] + alpha * colorValues.values[1],
            (1 - alpha) * backgroundValues.values[2] + alpha * colorValues.values[2],
        ],
    });
};
