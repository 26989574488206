import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';

import { withUserData } from '~/components/AuthenticationData';
import { withTranslation } from '~/components/LanguageSelector';

import { WorkflowMessageSendSubpageComponent } from './component';
import type { WorkflowMessageSendSubpageInnerProps, WorkflowMessageSendSubpageOuterProps } from './models';
import { mapDispatchToProps } from './redux';
import { WorkflowMessageSendSubpageStyleRules } from './styles';

export const WorkflowMessageSendSubpage = compose<
    WorkflowMessageSendSubpageInnerProps,
    WorkflowMessageSendSubpageOuterProps
>(
    setDisplayName('WorkflowMessageSendSubpage'),
    withTranslation(),
    withUserData(),
    withStyles(WorkflowMessageSendSubpageStyleRules),
    connect(undefined, mapDispatchToProps)
)(WorkflowMessageSendSubpageComponent);
