import { decomposeColor, hslToRgb, recomposeColor } from '@mui/material';

import { isUndefined } from '~/libs/utility';

/**
 * Get a RGB solid color from a transparent color taking the background into account. If the input color doesn't have
 * an alpha channel the original color will be returned
 *
 * @param rgbaColor A valid CSS color with alpha channel. E.g. 'rgba()' or 'hsla()'
 * @param bgColorRgb A valid solid CSS color (without alpha). E.g. 'rgb()', '#rgb', '#rrggbb' or 'hsl()'
 * @return The solid RGB color equivalent in 'rgb()' format
 */
export const transparentColorToSolidRgb = (rgbaColor: string, bgColorRgb: string): string => {
    const decomposedColor = decomposeColor(rgbaColor);
    const [r, g, b, a] =
        decomposedColor.type === 'rgba' ? decomposedColor.values : decomposeColor(hslToRgb(rgbaColor)).values;
    const decomposedBgColor = decomposeColor(bgColorRgb);
    const [bgR, bgG, bgB] =
        decomposedBgColor.type === 'rgb' ? decomposedBgColor.values : decomposeColor(hslToRgb(bgColorRgb)).values;

    if (decomposedBgColor.type === 'rgba' || decomposedBgColor.type === 'hsla') {
        throw new Error('Background color cannot have an alpha channel');
    }

    if (isUndefined(a)) {
        return rgbaColor;
    }

    const invertedAlpha = 1 - a;
    const newRed = Math.round((a * (r / 255) + invertedAlpha * (bgR / 255)) * 255);
    const newGreen = Math.round((a * (g / 255) + invertedAlpha * (bgG / 255)) * 255);
    const newBlue = Math.round((a * (b / 255) + invertedAlpha * (bgB / 255)) * 255);

    return recomposeColor({ type: 'rgb', values: [newRed, newGreen, newBlue] });
};
