import { isEmpty } from '../utility';

import type { BaseItem } from './models';

const getChildrenIds = <T extends BaseItem<T>>(source: T[]): (string | number)[] => {
    return source.reduce((acc: (string | number)[], { id, children }) => {
        acc.push(id);

        if (children && !isEmpty(children)) {
            return [...acc, ...getChildrenIds(children)];
        }

        return acc;
    }, []) as (string | number)[];
};

export { getChildrenIds };
