import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type GroupingPaneContainerClassKey = 'container';

export const GroupingPaneContainerStyleRules: StyleRulesCallback<Theme, {}, GroupingPaneContainerClassKey> = (
    theme: Theme
) =>
    createStyles({
        container: {
            marginTop: theme.spacing(1.25),
        },
    });
