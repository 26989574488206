import type { NumericDictionary } from '~/libs/utility';
import { mapValues, uniq, uniqBy } from '~/libs/utility';
import type { VehicleConnection } from '~/services/ApiClient';
import { VehicleConnectionsUpdate, createApiModel } from '~/services/ApiClient';

import type { FilterAssetProps, MonitoringAssetType } from './models';

export const applyVehicleConnectionsUpdate = (
    vehicleConnections: VehicleConnection[],
    vehicleConnectionsUpdate: VehicleConnectionsUpdate
): VehicleConnection[] => {
    return [
        ...vehicleConnections.filter((currentVehicleConnection) => {
            const updatedVehicleConnection = vehicleConnectionsUpdate.updatedVehicleConnections.find(
                ({ vehicle }) => vehicle.id === currentVehicleConnection.vehicle.id
            );

            return (
                !vehicleConnectionsUpdate.deletedVehicleConnections.includes(currentVehicleConnection.vehicle.id) &&
                !(updatedVehicleConnection && updatedVehicleConnection.revision > currentVehicleConnection.revision)
            );
        }),
        ...vehicleConnectionsUpdate.updatedVehicleConnections.filter((updatedVehicleConnection) => {
            const currentVehicleConnection = vehicleConnections.find(
                ({ vehicle }) => vehicle.id === updatedVehicleConnection.vehicle.id
            );

            return (
                !currentVehicleConnection ||
                (currentVehicleConnection && updatedVehicleConnection.revision > currentVehicleConnection.revision)
            );
        }),
    ];
};

export const filterDeletedVehicleConnections = (
    toBeFiltered: VehicleConnectionsUpdate,
    forComparison: VehicleConnectionsUpdate
): number[] =>
    toBeFiltered.deletedVehicleConnections.filter((deletedVehicleConnectionId) => {
        return !forComparison.updatedVehicleConnections.some(({ vehicle }) => {
            return vehicle.id === deletedVehicleConnectionId;
        });
    });

export const filterUpdatedVehicleConnections = (
    toBeFiltered: VehicleConnectionsUpdate,
    forComparison: VehicleConnectionsUpdate
): VehicleConnection[] => {
    return toBeFiltered.updatedVehicleConnections.filter((update) => {
        const updateForComparison = forComparison.updatedVehicleConnections.find(
            ({ vehicle }) => vehicle.id === update.vehicle.id
        );

        return (
            !forComparison.deletedVehicleConnections.includes(update.vehicle.id) &&
            !(updateForComparison && updateForComparison.revision > update.revision)
        );
    });
};

export const mergeDeletedVehicleConnections = (
    pendingVehicleConnectionsUpdate: VehicleConnectionsUpdate,
    nextVehicleConnectionsUpdate: VehicleConnectionsUpdate
): number[] =>
    uniq([
        ...filterDeletedVehicleConnections(pendingVehicleConnectionsUpdate, nextVehicleConnectionsUpdate),
        ...filterDeletedVehicleConnections(nextVehicleConnectionsUpdate, pendingVehicleConnectionsUpdate),
    ]);

export const mergeRevisionData = <T extends { revision: number }>(
    items: NumericDictionary<T>,
    pendingItems: NumericDictionary<T>
): NumericDictionary<T> => ({
    ...items,
    ...mapValues(pendingItems, (pendingItem, key) => {
        const currentItem = items[key];
        if (currentItem && currentItem.revision >= pendingItem.revision) {
            return currentItem;
        }

        return pendingItem;
    }),
});

export const mergeUpdatedVehicleConnections = (
    pendingVehicleConnectionsUpdate: VehicleConnectionsUpdate,
    nextVehicleConnectionsUpdate: VehicleConnectionsUpdate
): VehicleConnection[] =>
    uniqBy(
        [
            ...filterUpdatedVehicleConnections(pendingVehicleConnectionsUpdate, nextVehicleConnectionsUpdate),
            ...filterUpdatedVehicleConnections(nextVehicleConnectionsUpdate, pendingVehicleConnectionsUpdate),
        ],
        'vehicle.id'
    );

export const mergeVehicleConnectionsUpdates = (
    pendingVehicleConnectionsUpdate: VehicleConnectionsUpdate,
    nextVehicleConnectionsUpdate: VehicleConnectionsUpdate
): VehicleConnectionsUpdate =>
    createApiModel(VehicleConnectionsUpdate, {
        updatedVehicleConnections: mergeUpdatedVehicleConnections(
            pendingVehicleConnectionsUpdate,
            nextVehicleConnectionsUpdate
        ),
        deletedVehicleConnections: mergeDeletedVehicleConnections(
            pendingVehicleConnectionsUpdate,
            nextVehicleConnectionsUpdate
        ),
    });

export const filterAssets = <T extends MonitoringAssetType>(props: FilterAssetProps<T>): T[] => {
    const { assetType, assets, selectedAssets } = props;

    if (!selectedAssets) {
        return assets;
    }

    const selectedAssetIds = selectedAssets
        ?.filter((asset) => asset?.type === assetType)
        .map((selectedAsset) => selectedAsset.id);

    return assets.filter((asset) => {
        if ('driver' in asset) {
            return selectedAssetIds.includes(asset.driver);
        }

        return selectedAssetIds.includes(asset.id);
    });
};
