import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { TachographDataBlock } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';

export const getSelectedTachoDataIds = (
    assetType: AssetType,
    tachographDataBlocksSettings: NumericDictionary<TachographDataBlock>,
    tachoDataBlocks?: NumericDictionary<boolean>
): number[] => {
    switch (assetType) {
        case AssetType.Driver: {
            const tachographDataBlocks: TachographDataBlock[] = Object.values(tachographDataBlocksSettings);

            return tachographDataBlocks
                .filter((tachoDataBlock) => tachoDataBlock.assetType === AssetType.Driver)
                .map((tachoDataBlock) => tachoDataBlock.id);
        }
        case AssetType.Vehicle:
            if (isUndefined(tachoDataBlocks)) {
                return [];
            } else {
                const tachoDataBlockIds: number[] = Object.keys(tachoDataBlocks).map(Number);

                return tachoDataBlockIds.filter((id) => tachoDataBlocks[id] === true);
            }
        default:
            throw new Error('Unsupported asset type');
    }
};
