import { createContext } from 'react';

import type { Disposable } from '~/listeners';

export interface ComponentSubscription {}

export interface ComponentSubscriptions {
    [componentId: string]: ComponentSubscription;
}

export interface ResourceSubsriptionState {
    components: ComponentSubscriptions;
    resourceSubscriptionDisposable: Disposable;
    onResourceUnsubscribed: () => void;
}

export interface SubscriptionsState {
    [resourceType: string]: ResourceSubsriptionState;
}

export const defaultStoreState: SubscriptionsState = {};

export interface SubscriptionsContext {
    state: SubscriptionsState;
}

export const defaultContext: SubscriptionsContext = { state: {} };

export const SubscriptionContext = createContext<SubscriptionsContext>(defaultContext);
