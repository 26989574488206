import { parseDMS } from 'mt-geo';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { Position, createApiModel } from '~/services/ApiClient';
import { formatCardinal } from '~/services/Formatters';

const invalidPosition = createApiModel(Position, { latitude: Number.NaN, longitude: Number.NaN });

export const parseCoordinates = (t: SingleTFunction, value: string): Position => {
    const north = formatCardinal(t, 'N')[0];
    const south = formatCardinal(t, 'S')[0];
    const east = formatCardinal(t, 'E')[0];
    const west = formatCardinal(t, 'W')[0];

    const regex = new RegExp(
        `^[\\d]{2}\u00B0[0-5][\\d]\u2032[0-5][\\d]\u2033 (${north}|${south}) [0-1][\\d]{2}\u00B0[0-5][\\d]\u2032[0-5][\\d]\u2033 (${east}|${west})$`,
        'i'
    );

    if (!regex.test(value)) {
        return invalidPosition;
    }

    const latCardinalMapping = { [north.toLowerCase()]: 'N', [south.toLowerCase()]: 'S' };
    const longCardinalMapping = { [east.toLowerCase()]: 'E', [west.toLowerCase()]: 'W' };
    const latitude = parseDMS(
        value
            .substring(0, 11)
            .replace(new RegExp(`(${north}|${south})`, 'i'), (match) => latCardinalMapping[match.toLowerCase()])
    );
    const longitude = parseDMS(
        value
            .substring(12, 24)
            .replace(new RegExp(`(${east}|${west})`, 'i'), (match) => longCardinalMapping[match.toLowerCase()])
    );

    if (
        Number.isNaN(latitude) ||
        Number.isNaN(longitude) ||
        latitude < -90 ||
        latitude > 90 ||
        longitude < -180 ||
        longitude > 180
    ) {
        return invalidPosition;
    }

    return createApiModel(Position, { latitude, longitude });
};

export const isValidPosition = (position: Position): boolean => {
    return !Number.isNaN(position.latitude) && !Number.isNaN(position.longitude);
};
