import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import PositionGroupRouteComponent from './component';
import type { PositionGroupRouteInnerProps, PositionGroupRouteOuterProps } from './models';
import { PositionGroupRouteStyleRules } from './styles';

export type { PositionGroupRouteClassKey } from './styles';

export const PositionGroupRoute = compose<PositionGroupRouteInnerProps, PositionGroupRouteOuterProps>(
    setDisplayName('PositionGroupRoute'),
    withStyles(PositionGroupRouteStyleRules)
)(PositionGroupRouteComponent);
