import { createAction } from 'redux-actions';

import { sortBy } from '~/libs/utility';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import { ActionTypePrefixes } from './actionTypes';

export const retrieveAssetGroupsExecutor = () => {
    return retryableRequest(async () => {
        const { items } = await ApiClient.getAssetGroups();
        return sortBy(items, (assetGroup) => assetGroup.name.toLocaleLowerCase());
    });
};

export const retrieveAssetGroupsAction = createAction(ActionTypePrefixes.ASSETGROUPS, retrieveAssetGroupsExecutor);
