import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { PopupWidgetProps } from './component';
import { PopupWidgetMinimizedMode } from './component';

export type PopupWidgetClassKey =
    | 'root'
    | 'container'
    | 'popup'
    | 'header'
    | 'titleAndActions'
    | 'title'
    | 'actions'
    | 'content'
    | 'contents';

export const PopupWidgetStyleRules: StyleRulesCallback<Theme, PopupWidgetProps, PopupWidgetClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        container: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
        },
        popup: {
            display: 'flex',
            flexDirection: 'column',
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
        },
        header: {
            display: 'flex',
            padding: theme.spacing(),
            lineHeight: '24px',
            alignItems: 'center',
        },
        titleAndActions: {
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: 12,
            cursor: 'pointer',
        },
        title: {
            display: 'flex',
            overflow: 'hidden',
        },
        actions: {
            display: 'flex',
            marginLeft: theme.spacing(),
            cursor: 'default',
        },
        content: {
            display: 'flex',
            flexGrow: 1,
            height: 0,
            position: 'relative',
        },
        contents: {
            display: ({ isMinimized, minimizedMode }) =>
                isMinimized && minimizedMode === PopupWidgetMinimizedMode.HIDDEN ? 'none' : 'contents',
        },
    });
