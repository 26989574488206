import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const TachOnlineIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M11.79 1.72c12.89.5 16.1 16.41 2.35 19.91-1.05-.05 3-1.38 3-1.59a5.13 5.13 0 0 0 1.29-8.4C13.7 7.35 2.88 7.94 1.8 15.2c0 .49.05.95-.36.94-1-1.38.28-3.77.66-4.72s-.5-1.06-.34-1.85C3.4 4.25 6.59 2.26 11.79 1.72Zm-.71 2.2C5.79 4.6 2.53 8.36 3.86 8.65c1.64-.52 2.6-1.72 4.63-2C13.1 5.53 18.58 7.73 20.06 11c2.49 3.23.94-7.37-9-7Zm-.66 7.29c9.13.28 9.31 7.58 2.18 9.5C5.92 21.9.74 16.46 5.08 12.94A10.2 10.2 0 0 1 10.42 11.21Z"
                transform="translate(0 0)"
            />
        </SvgIcon>
    );
};
TachOnlineIcon.displayName = 'TachOnlineIcon';

export { TachOnlineIcon };
