import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined, memoize } from '~/libs/utility';
import type { DriverActivityType } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { formatDriverActivity } from './formatDriverActivity';

const formatHistoryDriverEventInternal = (t: SingleTFunction, v?: DriverActivityType) => {
    if (isUndefined(v)) {
        return t(`history-driver-event`);
    }
    return formatDriverActivity(t, v);
};

const formatHistoryDriverEventMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((v?: DriverActivityType) => formatHistoryDriverEventInternal(t, v))
);

export const formatHistoryDriverEvent = (t: SingleTFunction, v?: DriverActivityType): string =>
    formatHistoryDriverEventMemoized(t)(v);
