import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ViewBarActionsClassKey = 'buttons';

export const ViewBarActionsStyleRules: StyleRulesCallback<Theme, {}, ViewBarActionsClassKey> = () =>
    createStyles({
        buttons: {
            flexBasis: '33%',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    });
