import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { Classification, ResolvedVehicleType, VehicleType } from '~/services/ApiClient';

export const resolveAdminVehicleTypes = (
    vehicleTypes: VehicleType[],
    vehicleTypeCategoriesDictionary: NumericDictionary<Classification>,
    axlesDictionary: NumericDictionary<Classification>,
    emissionClassDictionary: NumericDictionary<Classification>
): ResolvedVehicleType[] => {
    return vehicleTypes.map((vehicleType) => {
        const vehicleTypeCategory = !isUndefined(vehicleType.type)
            ? vehicleTypeCategoriesDictionary[vehicleType.type]
            : undefined;
        const axles = !isUndefined(vehicleType.axles) ? axlesDictionary[vehicleType.axles] : undefined;
        const emissionClass = !isUndefined(vehicleType.emissionClass)
            ? emissionClassDictionary[vehicleType.emissionClass]
            : undefined;

        return {
            ...vehicleType,
            type: vehicleTypeCategory,
            axles,
            emissionClass,
        };
    });
};
