import withStyles from '@mui/styles/withStyles';
import { memo } from 'react';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ConversationWidgetHeaderInnerProps, ConversationWidgetHeaderProps } from './component';
import { ConversationWidgetHeaderComponent } from './component';
import { ConversationWidgetHeaderStyleRules } from './styles';

export const ConversationWidgetHeader = compose<ConversationWidgetHeaderInnerProps, ConversationWidgetHeaderProps>(
    setDisplayName('ConversationWidgetHeader'),
    withStyles(ConversationWidgetHeaderStyleRules),
    withTranslation(),
    memo
)(ConversationWidgetHeaderComponent);
