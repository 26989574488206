import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import type { InhibitorIconFormatterInnerProps, InhibitorIconFormatterProps } from './component';
import { InhibitorIconFormatterComponent } from './component';
import { InhibitorIconFormatterStyleRules } from './styles';

export const InhibitorIconFormatter = compose<InhibitorIconFormatterInnerProps, InhibitorIconFormatterProps>(
    setDisplayName('InhibitorIconFormatter'),
    withStyles(InhibitorIconFormatterStyleRules)
)(InhibitorIconFormatterComponent);
