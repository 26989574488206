enum SectionName {
    GENERAL = 'general',
    DRIVE = 'drive',
    LABOR = 'labor',
    DUTY = 'duty',
    REST = 'rest',
    DAILY_OVERVIEW = 'daily-overview',
}

export { SectionName };
