import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TemperatureSensorClassKey =
    | 'root'
    | 'icon'
    | 'primaryText'
    | 'secondaryText'
    | 'value'
    | 'temperatureTooLow'
    | 'temperatureTooHigh';

export const TemperatureSensorStyleRules: StyleRulesCallback<Theme, {}, TemperatureSensorClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0, 0.5),
        },
        icon: {
            width: 14,
            height: 14,
        },
        primaryText: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
        },
        value: {
            flexGrow: 1,
            textAlign: 'right',
        },
        secondaryText: {
            width: '100%',
            textAlign: 'right',
        },
        temperatureTooLow: {
            backgroundColor: theme.functionalItemsColors.temperature.low.value,
            '& $primaryText': {
                color: theme.functionalItemsColors.temperature.low.contrast,
            },
            '& $secondaryText': {
                color: theme.functionalItemsColors.temperature.low.contrast,
                opacity: 0.54,
            },
        },
        temperatureTooHigh: {
            backgroundColor: theme.functionalItemsColors.temperature.high.value,
            '& $primaryText': {
                color: theme.functionalItemsColors.temperature.high.contrast,
            },
            '& $secondaryText': {
                color: theme.functionalItemsColors.temperature.high.contrast,
                opacity: 0.54,
            },
        },
    });
