import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withTranslation } from '~/components/LanguageSelector';

import type { PositionGroupInnerProps, PositionGroupProps } from './component';
import { PositionGroupComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { positionGroupSelector } from './selectors';
import { PositionGroupStyleRules } from './styles';

export { positionGroupReducer } from './reducers';

export const PositionGroup = compose<PositionGroupInnerProps, PositionGroupProps>(
    setDisplayName('PositionGroup'),
    connect(createSelector(positionGroupSelector, mapStateToProps), mapDispatchToProps),
    withTranslation(),
    withStyles(PositionGroupStyleRules)
)(PositionGroupComponent);
