import { difference } from '~/libs/utility';
import type { ResolvedConversation } from '~/services/ApiClient';
import { IncomingMessage, IncomingMessageStatus } from '~/services/ApiClient';

export const filterPendingAllMessagesStatusUpdates = (
    conversation: ResolvedConversation,
    allUnreadMessagesThatAreBeingMarkedAsRead: number[]
): number[] => {
    const readMessages = conversation.messages.reduce((accumulator: number[], { value: message }) => {
        const messageToBeMarkedAsRead = allUnreadMessagesThatAreBeingMarkedAsRead.includes(message.id);
        if (!messageToBeMarkedAsRead) {
            return accumulator;
        }

        if (message instanceof IncomingMessage && message.status === IncomingMessageStatus.Read) {
            accumulator.push(message.id);
        }
        return accumulator;
    }, []);

    return difference(allUnreadMessagesThatAreBeingMarkedAsRead, readMessages);
};
