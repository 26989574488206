import { createAction } from 'redux-actions';

import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { TrailersPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

const retrieveTrailersExecutor = (): Promise<TrailersPayload> => retryableRequest(() => ApiClient.getTrailers());

export const retrieveTrailersAction = createAction<Promise<TrailersPayload>>(
    ActionTypePrefixes.TRAILERS,
    retrieveTrailersExecutor
);
