import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { NumericInputInnerProps, NumericInputProps } from './component';
import { NumericInputComponent } from './component';
import { NumericInputStyleRules } from './styles';

export type { NumericInputProps } from './component';

export const NumericInput = compose<NumericInputInnerProps, NumericInputProps>(
    setDisplayName('NumericInput'),
    withStyles(NumericInputStyleRules)
)(NumericInputComponent);
