import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './constants';

export const VEHICLE_ADMINISTRATION_USERPREFERENCES_KEY = 'vehicle-administration-grid-vehicle-state';

export const defaultVehicleAdministrationListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.UAID,
        ColumnName.VIN,
        ColumnName.VRN,
        ColumnName.ALIAS,
        ColumnName.UNITID,
        ColumnName.MSISDN,
        ColumnName.DEVICETYPE,
        ColumnName.VEHICLECATEGORY,
        ColumnName.NETWORKID,
        ColumnName.TAG,
        ColumnName.DEPOT,
        ColumnName.VEHICLETYPE,
        ColumnName.BILLINGGROUP,
        ColumnName.BILLINGSTATUS,
        ColumnName.COMPANYCARDID,
        ColumnName.TACHOGRAPH,
        ColumnName.TACHOPROTOCOL,
        ColumnName.TACHOGENERATION,
        ColumnName.ONEMINUTERULEENABLED,
        ColumnName.ALERTSANDPANIC,
        ColumnName.DRIVERCARDS,
        ColumnName.DRIVERCOACH,
        ColumnName.DRIVERHOURS,
        ColumnName.DSE,
        ColumnName.FREQUENTPOSITIONING,
        ColumnName.FUELSITEADVICE,
        ColumnName.KPI2,
        ColumnName.LANDMARKS,
        ColumnName.RTDS,
        ColumnName.TEXTMESSAGING,
        ColumnName.TRAILERS,
        ColumnName.WORKFLOW,
        ColumnName.GUIDEDNAVIGATION,
        ColumnName.NAVIGATION,
        ColumnName.IMAGECAPTURING,
        ColumnName.TIRECONTROL,
        ColumnName.APPSTORE,
        ColumnName.TRUCKIDENTIFICATION,
        ColumnName.TRAILERCONTROLSERVICE,
        ColumnName.STARTINHIBITOR,
        ColumnName.TELLTALESSERVICE,
        ColumnName.J1939DATASERVICE,
        ColumnName.NAVIGATIONETA,
        ColumnName.PRIMARY_FUEL_TANK_SIZE,
        ColumnName.PRIMARY_FUEL_TANK_TYPE,
        ColumnName.SECONDARY_FUEL_TANK_SIZE,
        ColumnName.SECONDARY_FUEL_TANK_TYPE,
        ColumnName.AD_BLUE_TANK_SIZE,
    ],
    visibleColumns: [
        ColumnName.VIN,
        ColumnName.VRN,
        ColumnName.ALIAS,
        ColumnName.UNITID,
        ColumnName.MSISDN,
        ColumnName.DEVICETYPE,
        ColumnName.VEHICLECATEGORY,
        ColumnName.NETWORKID,
        ColumnName.TAG,
        ColumnName.DEPOT,
        ColumnName.VEHICLETYPE,
        ColumnName.BILLINGGROUP,
        ColumnName.BILLINGSTATUS,
        ColumnName.COMPANYCARDID,
        ColumnName.TACHOGRAPH,
        ColumnName.TACHOPROTOCOL,
        ColumnName.TACHOGENERATION,
        ColumnName.ONEMINUTERULEENABLED,
        ColumnName.ALERTSANDPANIC,
        ColumnName.DRIVERCARDS,
        ColumnName.DRIVERCOACH,
        ColumnName.DRIVERHOURS,
        ColumnName.DSE,
        ColumnName.FREQUENTPOSITIONING,
        ColumnName.FUELSITEADVICE,
        ColumnName.KPI2,
        ColumnName.LANDMARKS,
        ColumnName.RTDS,
        ColumnName.TEXTMESSAGING,
        ColumnName.TRAILERS,
        ColumnName.WORKFLOW,
        ColumnName.GUIDEDNAVIGATION,
        ColumnName.NAVIGATION,
        ColumnName.IMAGECAPTURING,
        ColumnName.TIRECONTROL,
        ColumnName.APPSTORE,
        ColumnName.TRUCKIDENTIFICATION,
        ColumnName.TRAILERCONTROLSERVICE,
        ColumnName.STARTINHIBITOR,
        ColumnName.TELLTALESSERVICE,
        ColumnName.J1939DATASERVICE,
        ColumnName.NAVIGATIONETA,
        ColumnName.PRIMARY_FUEL_TANK_SIZE,
        ColumnName.PRIMARY_FUEL_TANK_TYPE,
        ColumnName.SECONDARY_FUEL_TANK_SIZE,
        ColumnName.SECONDARY_FUEL_TANK_TYPE,
        ColumnName.AD_BLUE_TANK_SIZE,
    ],
    columnWidths: [
        { columnName: ColumnName.UAID, width: 120 },
        { columnName: ColumnName.VIN, width: 150 },
        { columnName: ColumnName.VRN, width: 150 },
        { columnName: ColumnName.ALIAS, width: 150 },
        { columnName: ColumnName.UNITID, width: 150 },
        { columnName: ColumnName.MSISDN, width: 150 },
        { columnName: ColumnName.DEVICETYPE, width: 150 },
        { columnName: ColumnName.VEHICLECATEGORY, width: 150 },
        { columnName: ColumnName.NETWORKID, width: 150 },
        { columnName: ColumnName.TAG, width: 150 },
        { columnName: ColumnName.DEPOT, width: 150 },
        { columnName: ColumnName.VEHICLETYPE, width: 150 },
        { columnName: ColumnName.BILLINGGROUP, width: 150 },
        { columnName: ColumnName.BILLINGSTATUS, width: 150 },
        { columnName: ColumnName.COMPANYCARDID, width: 150 },
        { columnName: ColumnName.TACHOGRAPH, width: 150 },
        { columnName: ColumnName.TACHOPROTOCOL, width: 150 },
        { columnName: ColumnName.TACHOGENERATION, width: 150 },
        { columnName: ColumnName.ONEMINUTERULEENABLED, width: 150 },
        { columnName: ColumnName.ALERTSANDPANIC, width: 150 },
        { columnName: ColumnName.DRIVERCARDS, width: 150 },
        { columnName: ColumnName.DRIVERCOACH, width: 150 },
        { columnName: ColumnName.DRIVERHOURS, width: 150 },
        { columnName: ColumnName.DSE, width: 150 },
        { columnName: ColumnName.FREQUENTPOSITIONING, width: 150 },
        { columnName: ColumnName.FUELSITEADVICE, width: 150 },
        { columnName: ColumnName.KPI2, width: 150 },
        { columnName: ColumnName.LANDMARKS, width: 150 },
        { columnName: ColumnName.RTDS, width: 150 },
        { columnName: ColumnName.TEXTMESSAGING, width: 150 },
        { columnName: ColumnName.TRAILERS, width: 150 },
        { columnName: ColumnName.WORKFLOW, width: 150 },
        { columnName: ColumnName.GUIDEDNAVIGATION, width: 150 },
        { columnName: ColumnName.NAVIGATION, width: 150 },
        { columnName: ColumnName.IMAGECAPTURING, width: 150 },
        { columnName: ColumnName.TIRECONTROL, width: 150 },
        { columnName: ColumnName.APPSTORE, width: 150 },
        { columnName: ColumnName.TRUCKIDENTIFICATION, width: 150 },
        { columnName: ColumnName.TRAILERCONTROLSERVICE, width: 150 },
        { columnName: ColumnName.STARTINHIBITOR, width: 150 },
        { columnName: ColumnName.TELLTALESSERVICE, width: 150 },
        { columnName: ColumnName.J1939DATASERVICE, width: 150 },
        { columnName: ColumnName.NAVIGATIONETA, width: 150 },
        { columnName: ColumnName.PRIMARY_FUEL_TANK_SIZE, width: 200 },
        { columnName: ColumnName.PRIMARY_FUEL_TANK_TYPE, width: 200 },
        { columnName: ColumnName.SECONDARY_FUEL_TANK_SIZE, width: 200 },
        { columnName: ColumnName.SECONDARY_FUEL_TANK_TYPE, width: 220 },
        { columnName: ColumnName.AD_BLUE_TANK_SIZE, width: 150 },
    ],
    sorting: [{ columnName: ColumnName.VIN, direction: GridSortingDirection.DESC }],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: false,
    filteringEnabled: false,
    filters: [],
};
