import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { ComponentIdentifier, InjectedSubscriptionProviderProps } from '~/components/SignalRSubscriptionProvider';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import type { DriverActivity, DriverActivityType, DriverStatusNotification } from '~/services/ApiClient';
import { DriverActivitiesNotification } from '~/services/ApiClient';

import { ActivitiesGraph } from './components/ActivitiesGraph';
import { DriverActivitiesReportDialog } from './components/DriverActivitiesReportDialog';
import { generateDriverActivitiesEntries } from './generateDriverActivitiesEntries';
import { mergeDriverSubactivities } from './mergeDriverSubactivities';

export interface DriverActivityGraphProps extends ComponentIdentifier {
    vehicleId?: number;
    driverId?: number;
}

export interface StateProps {
    driverActivities: NumericDictionary<RetrievableData<NumericDictionary<DriverActivity> | undefined>>;
    driverActivityTypes: NumericDictionary<DriverActivityType>;
}

export interface DispatchProps {
    getDriverActivities: (driverId: number) => void;
    updateDriverActivities: (
        driverId: number,
        notification: DriverActivitiesNotification | DriverStatusNotification
    ) => void;
    clearDriverActivities: (driverId: number) => void;
}

export interface DriverActivityGraphInnerProps
    extends DriverActivityGraphProps,
        StateProps,
        DispatchProps,
        InjectedTranslationProps,
        InjectedSubscriptionProviderProps {}

export const DriverActivityGraphComponent: React.FC<DriverActivityGraphInnerProps> = (
    props: DriverActivityGraphInnerProps
) => {
    const {
        driverId,
        vehicleId,
        getDriverActivities,
        updateDriverActivities,
        clearDriverActivities,
        subscribe,
        unsubscribe,
    } = props;
    const [dialog, setDialog] = React.useState<React.ReactNode>();

    React.useEffect(() => {
        const resourceType = !isUndefined(vehicleId)
            ? `vehicle/${vehicleId}/driver/${driverId}/activities`
            : `drivers/${driverId}/activities`;

        // componentDidMount && componentDidUpdate
        if (driverId) {
            subscribe(
                resourceType,
                DriverActivitiesNotification.fromJS,
                (notification: DriverActivitiesNotification) => {
                    updateDriverActivities(driverId, notification);
                },
                () => {
                    getDriverActivities(driverId);
                },
                () => {
                    clearDriverActivities(driverId);
                }
            );
        }

        return () => {
            // componentWillUnmount
            if (driverId) {
                unsubscribe(resourceType);
            }
        };
    }, [
        driverId,
        vehicleId,
        subscribe,
        unsubscribe,
        getDriverActivities,
        updateDriverActivities,
        clearDriverActivities,
    ]);

    if (driverId) {
        const driverActivities = props.driverActivities[driverId];

        if (driverActivities) {
            const activityEntries = driverActivities.data
                ? generateDriverActivitiesEntries(
                      mergeDriverSubactivities(driverActivities.data),
                      props.driverActivityTypes
                  )
                : undefined;

            return (
                <>
                    <ActivitiesGraph
                        loading={driverActivities.pending}
                        failedToLoad={driverActivities.rejected}
                        activities={activityEntries}
                        openReportDialog={() =>
                            setDialog(
                                <DriverActivitiesReportDialog
                                    driverId={driverId}
                                    onClose={() => setDialog(undefined)}
                                    vehicleId={vehicleId}
                                />
                            )
                        }
                    />
                    {dialog}
                </>
            );
        } else {
            return <ActivitiesGraph loading failedToLoad={false} activities={[]} />;
        }
    } else {
        return <ActivitiesGraph loading={false} failedToLoad={false} activities={[]} />;
    }
};
