import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useBulkCheckbox } from '../../hooks';

import type { HeaderProps } from './models';
import { useStyles } from './styles';

const Header = <TId,>(props: HeaderProps<TId>) => {
    const { title, searchText, onSearchTextChange, options, selected, toggleSelected, equalityComparer } = props;

    const classes = useStyles();
    const visibleValues = options.map((option) => option.id);
    const { toggleBulk, bulkIndeterminate, bulkChecked } = useBulkCheckbox({
        items: visibleValues,
        equalityComparer,
        selected,
        toggleSelected,
    });

    return (
        <ListItem component="div" dense className={classes.root} data-testid="search-bar">
            <ListItemIcon className={classes.iconContainer} data-testid="select-all">
                <Checkbox
                    className={classes.checkbox}
                    disableRipple
                    checked={bulkChecked}
                    indeterminate={bulkIndeterminate}
                    onClick={toggleBulk}
                />
            </ListItemIcon>

            <ListItemText
                secondaryTypographyProps={{ component: 'span' }}
                primary={title}
                secondary={
                    <Input
                        startAdornment={<Search data-testid="search-icon" />}
                        endAdornment={
                            searchText && (
                                <Clear
                                    onClick={() => onSearchTextChange('')}
                                    className={classes.clearSearchText}
                                    data-testid="clear-icon"
                                />
                            )
                        }
                        fullWidth
                        margin="dense"
                        value={searchText}
                        onChange={(evt) => onSearchTextChange(evt.target.value)}
                        inputProps={{ 'data-testid': `search-input` }}
                    />
                }
            />
        </ListItem>
    );
};

Header.displayName = 'Header';
export { Header };
