import { Paper, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { AdaptiveDateFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import type { DaySeparatorClassKey } from './styles';

export interface DaySeparatorProps {
    value: Date;
}

export interface DaySeparatorInnerProps
    extends DaySeparatorProps,
        InjectedTranslationProps,
        WithStyles<DaySeparatorClassKey> {}

export const DaySeparatorComponent: React.FC<DaySeparatorInnerProps> = ({ t, classes, value }) => {
    return (
        <Paper className={classes.root} data-id="day-separator">
            <Typography variant="caption" color="textSecondary" className={classes.typography}>
                <AdaptiveDateFormatter value={value} t={t} />
            </Typography>
        </Paper>
    );
};
