import type { SingleTFunction } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import { keyBy } from '~/libs/utility';
import type { Classification, DerivedClassification } from '~/services/ApiClient';
import { DerivedClassificationType } from '~/services/ApiClient';
import { formatDerivedClassificationDisplayName } from '~/services/Formatters';

import type { DeriveVehicleTypeAxlesComboArgs } from './models';

export const deriveVehicleTypeAxlesCombo = (args: DeriveVehicleTypeAxlesComboArgs): DerivedClassification => {
    const { axles, t, vehicleTypeCategory } = args;

    const derivedKey = `${vehicleTypeCategory.key}-${axles.key}`;
    return {
        id: vehicleTypeCategory.id * 10 + axles.id,
        key: derivedKey,
        displayName: formatDerivedClassificationDisplayName(
            t,
            DerivedClassificationType.VehicleTypeAxlesCombo,
            derivedKey
        ),
    };
};

export const vehicleTypeAxlesComboDerivedClassificationValueProvider = (
    t: SingleTFunction,
    vehicleTypeCategories: NumericDictionary<Classification>,
    axles: NumericDictionary<Classification>
): NumericDictionary<DerivedClassification> => {
    const vehicleTypeCategoriesHashed = keyBy(Object.values(vehicleTypeCategories), 'key');
    const axlesHashed = keyBy(Object.values(axles), 'key');

    const values = [
        deriveVehicleTypeAxlesCombo({
            t,
            vehicleTypeCategory: vehicleTypeCategoriesHashed.Truck,
            axles: axlesHashed['3Axles'],
        }),
        deriveVehicleTypeAxlesCombo({
            t,
            vehicleTypeCategory: vehicleTypeCategoriesHashed.Truck,
            axles: axlesHashed['4Axles'],
        }),
        deriveVehicleTypeAxlesCombo({
            t,
            vehicleTypeCategory: vehicleTypeCategoriesHashed.Truck,
            axles: axlesHashed['5Axles'],
        }),
        deriveVehicleTypeAxlesCombo({
            t,
            vehicleTypeCategory: vehicleTypeCategoriesHashed.Van,
            axles: axlesHashed['2Axles'],
        }),
        deriveVehicleTypeAxlesCombo({
            t,
            vehicleTypeCategory: vehicleTypeCategoriesHashed.Car,
            axles: axlesHashed['2Axles'],
        }),
    ];

    return keyBy(values, 'id');
};
