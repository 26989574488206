import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const OpenDoorIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 661 784.01">
            {/* tslint:disable:max-line-length */}
            <path d="M0 76V577H510V76ZM235 360a15 15 0 0 1-15 15h-3a15 15 0 0 1-15-15V336a15 15 0 0 1 15-15h3a15 15 0 0 1 15 15ZM490 537.34A19.67 19.67 0 0 1 470.33 557H271.67A19.67 19.67 0 0 1 252 537.34V115.68A19.67 19.67 0 0 1 271.67 96H470.33c10.86 0 19.67 6.81 19.67 17.67ZM510 86 645.72 1.53A10 10 0 0 1 661 10V599.64A10 10 0 0 1 647.52 609L510 558ZM476.33 784H446.67A25.67 25.67 0 0 1 421 758.34V665h81v93.33A25.67 25.67 0 0 1 476.33 784Zm-413 0H33.67A25.67 25.67 0 0 1 8 758.34V665H89v93.33A25.67 25.67 0 0 1 63.33 784ZM510 601v64H421c0-17.33-18.5-17.33-18.5 0v38h-295V665h0c0-17.33-18.5-17.33-18.5 0H0V601Z" />
        </SvgIcon>
    );
};
OpenDoorIcon.displayName = 'OpenDoorIcon';

export { OpenDoorIcon };
