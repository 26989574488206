import type { Reducer } from 'redux';

import type { NumericDictionary } from '~/libs/utility';
import { keyBy } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import type { User } from '~/services/ApiClient';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export interface UserAutocompleteStoreState {
    users: RetrievableData<NumericDictionary<User>>;
}

export const defaultStoreState: UserAutocompleteStoreState = {
    users: {
        data: {},
        pending: false,
        fulfilled: false,
        rejected: false,
    },
};

export const userAutocompleteReducer: Reducer<UserAutocompleteStoreState> = (
    state: UserAutocompleteStoreState = defaultStoreState,
    action: ActionTypes
): UserAutocompleteStoreState => {
    switch (action.type) {
        case ActionTypeKeys.CUSTOMERUSERS_PENDING:
            return {
                ...state,
                users: {
                    ...state.users,
                    pending: true,
                },
            };
        case ActionTypeKeys.CUSTOMERUSERS_FULFILLED:
            return {
                ...state,
                users: {
                    data: keyBy(action.payload.items, 'id'),
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        case ActionTypeKeys.CUSTOMERUSERS_REJECTED:
            return {
                ...state,
                users: {
                    ...defaultStoreState.users,
                    rejected: true,
                },
            };

        default:
            return state;
    }
};
