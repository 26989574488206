import type { SvgIconProps } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import { Redirect } from 'react-router';

import type { DisplayUserPreferences, InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { ContainerIcon, TrailerIcon, TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneRoutes } from '~/routes';
import type {
    PositionStatusesResponse,
    ResolvedContainer,
    ResolvedTrailer,
    ResolvedVehicle,
} from '~/services/ApiClient';
import { ApiClient, AssetType } from '~/services/ApiClient';
import { formatContainerName, formatTrailerName, formatVehicleName } from '~/services/Formatters';

import { HistorySubpage } from '../HistorySubpage';

import type { AssetHistorySubpageClassKey } from './styles';

export interface AssetHistorySubpageProps<TAsset> {
    asset: TAsset;
}

export interface StateProps {
    authorized: boolean;
}

export interface AssetHistorySubpageInnerProps<TAsset>
    extends AssetHistorySubpageProps<TAsset>,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps,
        WithStyles<AssetHistorySubpageClassKey>,
        StateProps {}

export interface AssetDataType {
    [AssetType.Vehicle]: ResolvedVehicle;
    [AssetType.Trailer]: ResolvedTrailer;
    [AssetType.Container]: ResolvedContainer;
}

export interface AssetTypeDetail<T extends keyof AssetDataType> {
    formatAssetName: (asset: AssetDataType[T], displayPreferences: DisplayUserPreferences) => string;
    AssetIcon: React.ComponentType<SvgIconProps>;
    getAssetPositions: (assetId: number, startDate: Date, stopDate: Date) => Promise<PositionStatusesResponse>;
}

export type AssetTypeDetails = {
    [K in keyof AssetDataType]: AssetTypeDetail<K>;
};

const details: AssetTypeDetails = {
    [AssetType.Vehicle]: {
        AssetIcon: TruckIcon,
        formatAssetName: (vehicle, displayPreferences) =>
            formatVehicleName(vehicle, displayPreferences.vehicleDisplayFormat),
        getAssetPositions: ApiClient.getVehiclePositions.bind(ApiClient),
    },
    [AssetType.Trailer]: {
        AssetIcon: TrailerIcon,
        formatAssetName: (trailer, displayPreferences) =>
            formatTrailerName(trailer, displayPreferences.trailerDisplayFormat),
        getAssetPositions: ApiClient.getTrailerPositions.bind(ApiClient),
    },
    [AssetType.Container]: {
        AssetIcon: ContainerIcon,
        formatAssetName: (container, _displayPreferences) => formatContainerName(container),
        getAssetPositions: ApiClient.getContainerPositions.bind(ApiClient),
    },
};

export const AssetHistorySubpageComponentFactory = <T extends keyof AssetDataType>(
    assetType: T
): React.ComponentType<AssetHistorySubpageInnerProps<AssetDataType[T]>> => {
    const { getAssetPositions, AssetIcon, formatAssetName } = details[assetType] as AssetTypeDetail<T>;

    const AssetHistorySubpageComponent = ({
        asset,
        displayPreferences,
        t,
        classes,
        authorized,
    }: AssetHistorySubpageInnerProps<AssetDataType[T]>) => {
        const retrievePositionGroups = React.useCallback(
            async (startDate: Date, stopDate: Date, includePositions: boolean) =>
                (await ApiClient.getAssetPositionGroups(assetType, asset.id, startDate, stopDate, includePositions))
                    .items,
            [asset.id]
        );
        const retrievePositions = React.useCallback(
            async (startDate: Date, stopDate: Date) => (await getAssetPositions(asset.id, startDate, stopDate)).items,
            [asset.id]
        );
        const title = t('recent-asset-history', {
            assetName: formatAssetName(asset, displayPreferences),
        });
        const timelineClasses = React.useMemo(
            () => ({
                timelineSegment: classes.timeline,
                timelineIconContainer: classes.timelineIcon,
            }),
            [classes]
        );
        if (authorized) {
            return (
                <HistorySubpage
                    AssetIcon={AssetIcon}
                    title={title}
                    retrievePositionGroups={retrievePositionGroups}
                    retrievePositions={retrievePositions}
                    timelineClasses={timelineClasses}
                />
            );
        } else {
            return <Redirect to={SceneRoutes.UNAUTHORIZED} />;
        }
    };
    return AssetHistorySubpageComponent;
};
