import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';
import { getDimensionsUnitSystemUtils } from '~/services/Formatters';

import type { DimensionsFormatterInnerProps } from './model';

const DimensionsFormatter: FC<DimensionsFormatterInnerProps> = ({ t, unitSystem, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{getDimensionsUnitSystemUtils(t, unitSystem).formatter(value)}</>;
};

export { DimensionsFormatter };
