import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { AdminCompanyCard } from '~/services/ApiClient';

import type { ResolvedAdminCompanyCard } from './models';

export const resolveAdminCompanyCard = (
    card: AdminCompanyCard,
    settings: SettingsStoreState
): ResolvedAdminCompanyCard => {
    const countryCode = Object.values(settings.countries.data).find(
        ({ nationNumCode }) => nationNumCode === card.memberState
    )?.code;
    return {
        ...card,
        countryCode,
    };
};
