import { createAction } from 'redux-actions';

import type { VehicleStatus } from '~/services/ApiClient';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { VehicleStatusPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const retrieveVehiclesStatusExecutor = (): Promise<VehicleStatusPayload> => {
    return retryableRequest(() => ApiClient.getVehicleStatus());
};

export const retrieveVehicleStatusAction = createAction(
    ActionTypePrefixes.VEHICLESTATUS,
    retrieveVehiclesStatusExecutor
);

export const updateVehicleStatusAction = createAction<VehicleStatus>(ActionTypeKeys.VEHICLESTATUS_UPDATE);

export const getVehicleStatusesForPeriod = createAction(
    ActionTypePrefixes.VEHICLESTATUS_FOR_PERIOD,
    (vehicleId: number, startDate: Date, stopDate: Date) =>
        ApiClient.getVehicleStatusesForPeriod(vehicleId, startDate, stopDate)
);
