import { withTranslation } from 'react-i18next';
import { compose, defaultProps } from 'react-recompose';

import {
    AddressFormatter,
    AltitudeFormatter,
    DeviceTypeFormatter,
    DirectionFormatter,
    DistanceFormatter,
    FuelLevelFormatter,
    IgnitionStatusFormatter,
    LatitudeFormatter,
    LongitudeFormatter,
    NearestCityFormatter,
    SpeedFormatter,
} from '~/components/Formatters';
import type { GridColumnDefinition } from '~/components/Grid';
import { classificationFilterEquals, createDateTimeColumn, getClassificationOptions } from '~/components/Grid';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import type {
    Address,
    Classification,
    ClassificationAbstraction,
    DeviceType,
    DisplayUserPreferencesUnitSystem,
    DisplayUserPreferencesVehicleDisplayFormat,
    NearestCity,
} from '~/services/ApiClient';
import { degreeToCardinal, exportConvertorFactory } from '~/services/Convertors';
import {
    exportFormatterFactory,
    formatAddress,
    formatDeviceType,
    formatDirection,
    formatIgnitionStatus,
    formatIgnitionStatusTitle,
    formatLatitude,
    formatLongitude,
    formatNearestCity,
    getAltitudeUnitSystemUtils,
    getDistanceUnitSystemUtils,
    getFuelLevelUnitSystemUtils,
    getSpeedUnitSystemUtils,
    valueTextFormatterFactory,
} from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { memoizeOne } from '~/services/Memoize';
import { compareBooleans, compareFactory, compareNumbers, compareStrings, stringComparer } from '~/services/Sorting';

import type { HistoryVehicleEntry } from '../../../../models';

import { ColumnName } from './constants';
import {
    getAddressValue,
    getAltitudeValue,
    getCityValue,
    getCountryCodeValue,
    getFuelLevelValue,
    getHeadingValue,
    getIgnitionStatusValue,
    getLatitudeValue,
    getLongitudeValue,
    getMsisdnValue,
    getNearestCityValue,
    getOdometerValue,
    getPostalCodeValue,
    getSpeedValue,
    getStatusDateValue,
    getTotalFuelUsageValue,
} from './getCellValue.event';
import { getDeviceTypeValue, getVehicleValue } from './getCellValue.vehicle';

export const getRowId = (row: HistoryVehicleEntry): number => row.vehicleEvent.eventPosId;

export const getColumns = (
    t: SingleTFunction,
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat,
    unitSystem: DisplayUserPreferencesUnitSystem,
    deviceTypes: NumericDictionary<Classification>
): Array<GridColumnDefinition<HistoryVehicleEntry>> => {
    const withUnitSystem = defaultProps({ unitSystem });

    const altitudeUtils = getAltitudeUnitSystemUtils(t, unitSystem);
    const distanceUtils = getDistanceUnitSystemUtils(t, unitSystem);
    const fuelLevelUtils = getFuelLevelUnitSystemUtils(t, unitSystem);
    const speedUtils = getSpeedUnitSystemUtils(t, unitSystem);

    const deviceTypeClassificationFactory = (value: DeviceType): ClassificationAbstraction => {
        return {
            id: value.id,
            key: value.key,
            displayName: formatDeviceType(t, value.key),
        };
    };

    const vehicleColumns: Array<GridColumnDefinition<HistoryVehicleEntry>> = [
        {
            dataType: 'string',
            name: ColumnName.MSISDN,
            title: t('msisdn'),
            groupTitle: t('general'),
            getCellValue: getMsisdnValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.DEVICETYPE,
            title: t('device-type'),
            groupTitle: t('general'),
            getCellValue: getDeviceTypeValue,
            exportValueFormatter: exportFormatterFactory((v: string) => formatDeviceType(t, v)),
            valueFormatterComponent: withTranslation()(DeviceTypeFormatter),
            compare: compareFactory((v: string) => formatDeviceType(t, v), stringComparer),
            valueTextFormatter: valueTextFormatterFactory((v: string) => formatDeviceType(t, v)),

            cellFiltering: {
                options: getClassificationOptions(Object.values(deviceTypes), deviceTypeClassificationFactory),
                valueEquals: classificationFilterEquals,
            },
        },
        createDateTimeColumn(ColumnName.DATETIME, t('date-time'), t('general'), getStatusDateValue),
        {
            dataType: 'string',
            name: ColumnName.VEHICLE,
            title: t('vehicle'),
            groupTitle: t('general'),
            getCellValue: getVehicleValue(vehicleDisplayNameFormat),
            compare: compareStrings,
        },
        {
            dataType: 'boolean',
            name: ColumnName.IGNITIONSTATUS,
            title: t('ignition-status'),
            groupTitle: t('vehicle-status'),
            getCellValue: getIgnitionStatusValue,
            exportValueFormatter: exportFormatterFactory((v: boolean) => formatIgnitionStatus(t, v)),
            valueFormatterComponent: withTranslation()(IgnitionStatusFormatter),
            compare: compareBooleans,
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatIgnitionStatusTitle(t, v)),
        },
        {
            dataType: 'number',
            name: ColumnName.SPEED,
            title: t('speed'),
            groupTitle: t('location'),
            getCellValue: getSpeedValue,
            getFilterValue: (v: number) => speedUtils.converter(v, { precision: 0 }),
            exportValueFormatter: exportConvertorFactory((v: number) => speedUtils.converter(v, { precision: 0 })),
            excelCellFormat: `0 "${speedUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(SpeedFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => speedUtils.formatter(v, { precision: 0 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => speedUtils.formatter(v, { precision: 0 })),
            align: 'right',
        },
        {
            dataType: 'string',
            name: ColumnName.HEADING,
            title: t('heading'),
            groupTitle: t('location'),
            getCellValue: getHeadingValue,
            exportValueFormatter: exportFormatterFactory((v: number) => formatDirection(t, v)),
            valueFormatterComponent: DirectionFormatter,
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory(degreeToCardinal),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatDirection(t, v)),
        },
        {
            dataType: 'number',
            name: ColumnName.ODOMETER,
            title: t('odometer'),
            groupTitle: t('vehicle-status'),
            getCellValue: getOdometerValue,
            getFilterValue: (v: number) => distanceUtils.converter(v, { precision: 0 }),
            exportValueFormatter: exportConvertorFactory((v: number) => distanceUtils.converter(v, { precision: 0 })),
            excelCellFormat: `0 "${distanceUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DistanceFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            align: 'right',
        },
        {
            dataType: 'string',
            name: ColumnName.LATITUDE,
            title: t('latitude'),
            groupTitle: t('location'),
            getCellValue: getLatitudeValue,
            exportValueFormatter: exportFormatterFactory((v: number) => formatLatitude(t, v)),
            valueFormatterComponent: withTranslation()(LatitudeFormatter),
            compare: compareNumbers,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLatitude(t, v)),
        },
        {
            dataType: 'string',
            name: ColumnName.LONGITUDE,
            title: t('longitude'),
            groupTitle: t('location'),
            getCellValue: getLongitudeValue,
            exportValueFormatter: exportFormatterFactory((v: number) => formatLongitude(t, v)),
            valueFormatterComponent: withTranslation()(LongitudeFormatter),
            compare: compareStrings,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLongitude(t, v)),
        },
        {
            dataType: 'string',
            name: ColumnName.CITY,
            title: t('city'),
            groupTitle: t('location'),
            getCellValue: getCityValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.ADDRESS,
            title: t('address'),
            groupTitle: t('location'),
            getCellValue: getAddressValue,
            exportValueFormatter: exportFormatterFactory(formatAddress),
            valueFormatterComponent: AddressFormatter,
            compare: compareFactory(formatAddress, stringComparer),
            groupingCriteria: groupingCriteriaFactory(formatAddress),
            valueTextFormatter: valueTextFormatterFactory((v: Address) => formatAddress(v)),
        },
        {
            dataType: 'string',
            name: ColumnName.POSTALCODE,
            title: t('postal-code'),
            groupTitle: t('location'),
            getCellValue: getPostalCodeValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.COUNTRYCODE,
            title: t('country-code'),
            groupTitle: t('location'),
            getCellValue: getCountryCodeValue,
            compare: compareStrings,
        },
        {
            dataType: 'number',
            name: ColumnName.ALTITUDE,
            title: t('altitude'),
            groupTitle: t('location'),
            getCellValue: getAltitudeValue,
            getFilterValue: (v: number) => altitudeUtils.converter(v, { precision: 1 }),
            exportValueFormatter: exportConvertorFactory((v: number) => altitudeUtils.converter(v, { precision: 1 })),
            excelCellFormat: `0.0 "${altitudeUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(AltitudeFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => altitudeUtils.formatter(v, { precision: 1 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => altitudeUtils.formatter(v, { precision: 1 })),
            align: 'right',
        },
        {
            dataType: 'number',
            name: ColumnName.TOTALFUELUSAGE,
            title: t('total-fuel-usage'),
            groupTitle: t('vehicle-status'),
            getCellValue: getTotalFuelUsageValue,
            getFilterValue: (v: number) => fuelLevelUtils.converter(v, { precision: 1 }),
            exportValueFormatter: exportConvertorFactory((v: number) => fuelLevelUtils.converter(v, { precision: 1 })),
            excelCellFormat: `0.0 "${fuelLevelUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(FuelLevelFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => fuelLevelUtils.formatter(v, { precision: 1 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => fuelLevelUtils.formatter(v, { precision: 1 })),
            align: 'right',
        },
        {
            dataType: 'number',
            name: ColumnName.FUELLEVEL,
            title: t('fuel-level'),
            groupTitle: t('vehicle-status'),
            getCellValue: getFuelLevelValue,
            getFilterValue: (v: number) => fuelLevelUtils.converter(v, { precision: 1 }),
            exportValueFormatter: exportConvertorFactory((v: number) => fuelLevelUtils.converter(v, { precision: 1 })),
            excelCellFormat: `0.0 "${fuelLevelUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(FuelLevelFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => fuelLevelUtils.formatter(v, { precision: 1 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => fuelLevelUtils.formatter(v, { precision: 1 })),
            align: 'right',
        },
        {
            dataType: 'string',
            name: ColumnName.NEARESTCITY,
            title: t('nearest-city'),
            groupTitle: t('location'),
            getCellValue: getNearestCityValue,
            exportValueFormatter: exportFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            valueFormatterComponent: compose(withUnitSystem)(NearestCityFormatter),
            compare: compareFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v), stringComparer),
            groupingCriteria: groupingCriteriaFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            valueTextFormatter: valueTextFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
        },
    ];

    return vehicleColumns;
};

export const getColumnsMemoized = memoizeOne(getColumns);
