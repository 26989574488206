import moment from 'moment';

export interface Interval {
    start: Date;
    stop?: Date;
}

export const getActivitySurfaceStyles = (
    graphWidth: number,
    graphDurationInSeconds: number,
    graphEnd: Date,
    interval: Interval
): { left: number; width: number } => {
    const graphStart = moment(graphEnd).subtract(graphDurationInSeconds, 'seconds');
    const activityStart = moment.max([moment(interval.start), graphStart]);
    const activityStop = interval.stop ? moment.min([moment(interval.stop), moment(graphEnd)]) : moment(graphEnd);
    const activityDuration = activityStop.diff(activityStart, 'seconds');

    const graphDurationBeforeActivity = graphDurationInSeconds - moment(graphEnd).diff(activityStart, 'seconds');

    return {
        left: (graphDurationBeforeActivity * graphWidth) / graphDurationInSeconds,
        width: (activityDuration * graphWidth) / graphDurationInSeconds,
    };
};
