import { createAction } from 'redux-actions';

import { ActionTypeKeys } from './actionTypes';

export const changeSearchQueryAction = createAction(
    ActionTypeKeys.CONVERSATIONSSUMMARY_CHANGESEARCHQUERY,
    (searchQuery: string) => searchQuery
);

export const clearSearchQueryAction = createAction(ActionTypeKeys.CONVERSATIONSSUMMARY_CLEARSEARCHQUERY);
