import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';

import { withUserData } from '~/components/AuthenticationData';
import { withTranslation } from '~/components/LanguageSelector';

import { SendMessageBoxComponent } from './component';
import type { SendMessageBoxInnerProps, SendMessageBoxProps } from './models';
import { mapDispatchToProps } from './redux';
import { SendMessageBoxStyleRules } from './styles';

export { buildOptimisticOutgoingConversationMessage } from './buildOptimisticOutgoingConversationMessage';
export const SendMessageBox = compose<SendMessageBoxInnerProps, SendMessageBoxProps>(
    setDisplayName('SendMessageBox'),
    withStyles(SendMessageBoxStyleRules),
    withTranslation(),
    withUserData(),
    connect(undefined, mapDispatchToProps)
)(SendMessageBoxComponent);
