import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    tooltip: {
        '&[aria-disabled="true"]': {
            pointerEvents: 'initial',
            cursor: 'default',
        },
        display: 'flex',
    },
}));
