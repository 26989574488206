import { isUndefined } from '~/libs/utility';
import type { RetrievableHashedData } from '~/reducers';
import type { AssetReference, Vehicle } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

export const getSelectedAuthorizedVehicles = memoizeOne(
    (vehicles: RetrievableHashedData<Vehicle>, selectedAssetIds?: AssetReference[]) => {
        let authorizedVehicleIds: number[] | undefined;

        if (vehicles.fulfilled && vehicles.data?.hash) {
            const selectedVehiclesIds = selectedAssetIds
                ?.filter((selectedAsset) => selectedAsset.type === AssetType.Vehicle)
                .map((selectedAsset) => selectedAsset.id);
            authorizedVehicleIds = selectedVehiclesIds?.filter((vehicleId) => {
                const authorizedVehicle = vehicles.data.hash[vehicleId];
                return !isUndefined(authorizedVehicle);
            });
        }

        return authorizedVehicleIds;
    }
);
