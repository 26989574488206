import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { SummarySettingItemInnerProps, SummarySettingItemProps } from './component';
import { SummarySettingItemComponent } from './component';
import { SummarySettingItemStyleRules } from './styles';

export const SummarySettingItem = compose<SummarySettingItemInnerProps, SummarySettingItemProps>(
    setDisplayName('SummarySettingItem'),
    withStyles(SummarySettingItemStyleRules)
)(SummarySettingItemComponent);
