import type { RegisterOptions } from 'react-hook-form';

export interface BaseProps<T> {
    fieldName: string;
    dataId: string;
    label?: string;
    className?: string;
    defaultValue?: T;
    onValueChanged?: (value: T) => void;
    onBlurChanged?: (value: T) => void;
    markValueChange?: boolean;
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    disabledReason?: string;
    hasError?: boolean;
    errorText?: string;
    validationRules?: RegisterOptions;
}

export enum BooleanControlType {
    CheckboxControl = 'checkbox',
    ToggleButton = 'toggle-button',
}

export enum DateTimeControlType {
    DateTime = 'date-time',
    Date = 'date',
}

export const noneValue = -1;
