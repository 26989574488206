import type { MonitoringContainerEntry } from '~/data/monitoring';
import { sortBy } from '~/libs/utility';
import type { Address, Classification, NearestCity } from '~/services/ApiClient';
import { ContainerTemperatureStatusKeys } from '~/services/ApiClient';

export const getEventTypeValue = (monitoringContainerEntry: MonitoringContainerEntry): Classification | undefined => {
    return monitoringContainerEntry.status?.lastEvent.eventType;
};

export const getEventDateValue = (monitoringContainerEntry: MonitoringContainerEntry): Date | undefined => {
    return monitoringContainerEntry.status?.lastEvent.dateTime;
};

export const getCityValue = (entry: MonitoringContainerEntry): string | undefined =>
    entry.status?.location?.address?.city;

export const getAddressValue = (entry: MonitoringContainerEntry): Address | undefined =>
    entry.status?.location?.address;

export const getPostalCodeValue = (entry: MonitoringContainerEntry): string | undefined =>
    entry.status?.location?.address?.postalCode;

export const getCountryCodeValue = (entry: MonitoringContainerEntry): string | undefined =>
    entry.status?.location?.address?.countryCode;

export const getNearestCityValue = (entry: MonitoringContainerEntry): NearestCity | undefined =>
    entry.status?.location?.nearestCity;

export const getAltitudeValue = (entry: MonitoringContainerEntry): number | undefined =>
    entry.status?.location?.position.altitude;

export const getLatitudeValue = (entry: MonitoringContainerEntry): number | undefined =>
    entry.status?.location?.position.latitude;

export const getLongitudeValue = (entry: MonitoringContainerEntry): number | undefined =>
    entry.status?.location?.position.longitude;

export const getBarrelFillLevelValue = (monitoringContainerEntry: MonitoringContainerEntry): number | undefined => {
    return monitoringContainerEntry.status?.content?.barrelFillLevel;
};

export const getBarrelPressureValue = (monitoringContainerEntry: MonitoringContainerEntry): number | undefined => {
    return monitoringContainerEntry.status?.content?.barrelPressure;
};

export const getIsHeatingSystemOnValue = (monitoringContainerEntry: MonitoringContainerEntry): boolean | undefined => {
    return monitoringContainerEntry.status?.heatingSystem?.isOn;
};

export const getHeatingSystemTankLevelValue = (
    monitoringContainerEntry: MonitoringContainerEntry
): number | undefined => {
    return monitoringContainerEntry.status?.heatingSystem?.tankLevel;
};

export const getProductDensityValue = (monitoringContainerEntry: MonitoringContainerEntry): number | undefined => {
    return monitoringContainerEntry.status?.content?.productDensity;
};

export const getOdometerValue = (monitoringContainerEntry: MonitoringContainerEntry): number | undefined => {
    return monitoringContainerEntry.status?.odometer;
};

export const getFirstContentTemperatureValue = (entry: MonitoringContainerEntry): number | undefined =>
    sortBy(entry.status?.temperatures.filter((t) => t.type.key === ContainerTemperatureStatusKeys.CONTENT), 'id')[0]
        ?.value;
