import type { Dispatch } from 'redux';

import { SettingsKey } from '~/components/EnsureSettings';
import type { DetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { retrieveSettingAction } from '~/data/settings';
import { reportError } from '~/reporting';
import type { ResolvedVehicleType, Securables, VehicleTypesAdministrationSecurables } from '~/services/ApiClient';
import { ApiClient } from '~/services/ApiClient';

import type { Subpage } from './models';
import type { AdminVehicleTypesStoreState } from './reducers';

export interface UrlParams {
    vehicleTypeId: string;
    subpage?: Subpage;
}

export interface StateProps {
    resolvedVehicleTypes: ResolvedVehicleType[];
    vehicleTypesAdministrationAuthorizations: VehicleTypesAdministrationSecurables;
    loading: boolean;
    contentLoading: boolean;
    rejected: boolean;
    keepSubpageOpen: boolean;
}

export interface DispatchProps {
    getAllVehicleTypes: () => void;
}

export interface VehicleTypeAdministrationReduxProps extends DispatchProps, StateProps {}

export const mapStateToProps = (
    securables: Securables,
    vehicleTypesAdministrationStoreState: AdminVehicleTypesStoreState,
    detailsPaneUserPreferences: DetailsPaneUserPreferences
): StateProps => {
    const { adminVehicleTypes: resolvedVehicleTypes } = vehicleTypesAdministrationStoreState;
    return {
        resolvedVehicleTypes: resolvedVehicleTypes.data.array,
        vehicleTypesAdministrationAuthorizations: securables.assetAdministration.vehicleTypes,
        loading: !resolvedVehicleTypes.fulfilled,
        contentLoading: resolvedVehicleTypes.pending,
        rejected: resolvedVehicleTypes.rejected,
        keepSubpageOpen: detailsPaneUserPreferences.keepSubPageOpen,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getAllVehicleTypes: () => {
        dispatch(retrieveSettingAction(SettingsKey.VEHICLETYPES, () => ApiClient.getVehicleTypes())).catch(reportError);
    },
});

export const mergeProps = (
    { ...restStateProps }: StateProps,
    { ...restDispatchProps }: DispatchProps
): VehicleTypeAdministrationReduxProps => ({ ...restStateProps, ...restDispatchProps });
