import type { AnyAction, Store } from 'redux';
import type { Persistor } from 'redux-persist';
import { persistStore } from 'redux-persist';

export const flushAndOverridePersistor = async (
    prevPersistor: Persistor,
    reduxState: Store<unknown, AnyAction>,
    callback: (persistor: Persistor) => void
): Promise<void> => {
    await prevPersistor.flush();
    const nextPersistor = persistStore(reduxState);
    callback(nextPersistor);
};
