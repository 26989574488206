import { SvgIcon as _SvgIcon, Typography as _Typography } from '@mui/material';
import { styled } from '@mui/styles';

const Typography = styled(_Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
    lineHeight: '14px',
}));

const SvgIcon = styled(_SvgIcon)(({ theme }) => ({
    fontSize: 14,
    marginRight: theme.spacing(0.5),
}));

const Delimiter = styled('span')(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
}));

export { Typography, SvgIcon, Delimiter };
