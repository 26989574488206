import { Avatar, ListItem, styled } from '@mui/material';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
    alignSelf: 'center',
    backgroundColor: theme.palette.background.default,
    overflow: 'visible',
}));

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
});

const TimeListItem = styled(ListItem)({
    paddingTop: 0,
});

export { StyledAvatar as Avatar, Container, TimeListItem };
