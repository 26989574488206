import type { Dispatch } from 'redux';

import { updateUserPreferencesAction } from '~/data/userpreferences';

import type { DispatchProps } from './component';
import { HISTORY_DETAILSPANE_VEHICLE_USERPREFERENCES_KEY } from './preferences';

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    changeMiniMapZoomLevel: (zoomLevel: number) => {
        dispatch(
            updateUserPreferencesAction(HISTORY_DETAILSPANE_VEHICLE_USERPREFERENCES_KEY, {
                miniMapZoomLevel: zoomLevel,
            })
        );
    },
});
