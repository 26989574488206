import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type LocationSectionActionButtonsClassKeys = 'iconMargin' | 'copyCoordinatesButton';

export const useStyles = makeStyles<Theme, {}, LocationSectionActionButtonsClassKeys>((theme) => ({
    iconMargin: {
        marginRight: theme.spacing(),
    },
    copyCoordinatesButton: {
        marginLeft: theme.spacing(-1),
    },
}));
