import { Chat } from '@mui/icons-material';
import type { FC } from 'react';

import { ClipboardIcon } from '~/components/Icons';
import { MessageBodyType } from '~/services/ApiClient';

import type { IconFormatterProps } from './models';

const IconFormatter: FC<IconFormatterProps> = (props) => {
    const { value, iconProps } = props;

    switch (value) {
        case MessageBodyType.Workflow: {
            return <ClipboardIcon {...iconProps} />;
        }

        case MessageBodyType.Text: {
            return <Chat {...iconProps} />;
        }

        default: {
            return null;
        }
    }
};

IconFormatter.displayName = 'IconFormatter';
export default IconFormatter;
