import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { Classification } from '~/services/ApiClient';
import { AssetConnectionStatus, ClassificationType, TrailerType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import type { TrailerConnectionFilteringClassKey } from './styles';

export interface TrailerConnectionFilter {
    trailerType?: TrailerType;
    trailerManufacturer?: Classification;
    connectionStatus?: AssetConnectionStatus;
}

export interface TrailerConnectionFilteringProps {
    filter?: TrailerConnectionFilter;
    trailerManufacturers: Classification[];
    onFilterStateChange: (filter: TrailerConnectionFilter) => void;
}

export interface TrailerConnectionFilteringInnerProps
    extends TrailerConnectionFilteringProps,
        InjectedTranslationProps,
        WithStyles<TrailerConnectionFilteringClassKey> {}

export const ALL_OPTION = 'all';

export const TrailerConnectionFilteringComponent: React.FC<TrailerConnectionFilteringInnerProps> = ({
    t,
    trailerManufacturers,
    onFilterStateChange,
    classes,
    filter,
}) => {
    const trailerManufacturerElements = trailerManufacturers.map((manufacturer: Classification) => (
        <FormControlLabel
            key={`manufacturer:${manufacturer.id}`}
            value={manufacturer.id}
            control={<Radio />}
            label={formatClassification(t, ClassificationType.TrailerManufacturer, manufacturer)}
            data-id={`trailer-manufacturer-option:${manufacturer.id}`}
        />
    ));

    const handleTrailerTypeChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedTrailerType =
                event.target.value === ALL_OPTION ? undefined : (event.target.value as TrailerType);

            onFilterStateChange({
                trailerType: selectedTrailerType,
                trailerManufacturer: filter?.trailerManufacturer,
                connectionStatus: filter?.connectionStatus,
            });
        },
        [onFilterStateChange, filter]
    );

    const handleTrailerManufacturerChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedTrailerManufacturer = trailerManufacturers.find(
                (manufacturer: Classification) => manufacturer.id === +event.target.value
            );

            onFilterStateChange({
                trailerType: filter?.trailerType,
                trailerManufacturer: selectedTrailerManufacturer,
                connectionStatus: filter?.connectionStatus,
            });
        },
        [onFilterStateChange, filter, trailerManufacturers]
    );

    const handleConnectionStatusChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedConnectionStatus =
                event.target.value === ALL_OPTION ? undefined : (event.target.value as AssetConnectionStatus);

            onFilterStateChange({
                trailerType: filter?.trailerType,
                trailerManufacturer: filter?.trailerManufacturer,
                connectionStatus: selectedConnectionStatus,
            });
        },
        [onFilterStateChange, filter]
    );

    return (
        <div className={classes.root}>
            <FormControl variant="standard" component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.label} data-id="trailer-type-radio-group-title">
                    {t('trailer-type').toUpperCase()}
                </FormLabel>
                <RadioGroup
                    name="trailer-type"
                    value={filter?.trailerType || ALL_OPTION}
                    onChange={handleTrailerTypeChange}
                    data-id="trailer-type-radio-group"
                >
                    <FormControlLabel
                        key={ALL_OPTION}
                        value={ALL_OPTION}
                        control={<Radio />}
                        label={t(`${ALL_OPTION}`)}
                        data-id={`trailer-type-option:${ALL_OPTION}`}
                    />
                    <FormControlLabel
                        key={TrailerType.TRAILER}
                        value={TrailerType.TRAILER}
                        control={<Radio />}
                        label={t(`${TrailerType.TRAILER}`)}
                        data-id={`trailer-type-option:${TrailerType.TRAILER}`}
                    />
                    <FormControlLabel
                        key={TrailerType.REEFER}
                        value={TrailerType.REEFER}
                        control={<Radio />}
                        label={t(`${TrailerType.REEFER}`)}
                        data-id={`trailer-type-option:${TrailerType.REEFER}`}
                    />
                </RadioGroup>
            </FormControl>

            <FormControl variant="standard" component="fieldset" className={classes.formControl}>
                <FormLabel
                    component="legend"
                    className={classes.label}
                    data-id="trailer-manufacturer-radio-group-title"
                >
                    {t('trailer-manufacturer').toUpperCase()}
                </FormLabel>
                <RadioGroup
                    name="trailer-manufacturer"
                    value={isUndefined(filter?.trailerManufacturer?.id) ? -1 : filter?.trailerManufacturer?.id}
                    onChange={handleTrailerManufacturerChange}
                    data-id="trailer-manufacturer-radio-group"
                >
                    <FormControlLabel
                        key={ALL_OPTION}
                        value={-1}
                        control={<Radio />}
                        label={t(`${ALL_OPTION}`)}
                        data-id={`trailer-manufacturer-option:${ALL_OPTION}`}
                    />
                    {trailerManufacturerElements}
                </RadioGroup>
            </FormControl>

            <FormControl variant="standard" component="fieldset">
                <FormLabel component="legend" className={classes.label} data-id="connection-status-radio-group-title">
                    {t('connection-status').toUpperCase()}
                </FormLabel>
                <RadioGroup
                    name="connection-status"
                    value={filter?.connectionStatus || ALL_OPTION}
                    onChange={handleConnectionStatusChange}
                    data-id="connection-status-radio-group"
                >
                    <FormControlLabel
                        key={ALL_OPTION}
                        value={ALL_OPTION}
                        control={<Radio />}
                        label={t(`${ALL_OPTION}`)}
                        data-id={`connection-status-option:${ALL_OPTION}`}
                    />
                    <FormControlLabel
                        key={AssetConnectionStatus.CONNECTED}
                        value={AssetConnectionStatus.CONNECTED}
                        control={<Radio />}
                        label={t(`${AssetConnectionStatus.CONNECTED}`)}
                        data-id={`connection-status-option:${AssetConnectionStatus.CONNECTED}`}
                    />
                    <FormControlLabel
                        key={AssetConnectionStatus.NOT_CONNECTED}
                        value={AssetConnectionStatus.NOT_CONNECTED}
                        control={<Radio />}
                        label={t(`${AssetConnectionStatus.NOT_CONNECTED}`)}
                        data-id={`connection-status-option:${AssetConnectionStatus.NOT_CONNECTED}`}
                    />
                </RadioGroup>
            </FormControl>
        </div>
    );
};
