import type * as React from 'react';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import type { GridColumnDefinition } from '~/components/Grid';
import { withTranslation } from '~/components/LanguageSelector';
import { sceneListStateSelector } from '~/selectors';

import type { SceneListUserPreferences } from '../../preferences';

import { SceneListViewHeaderComponentFactory } from './component';
import type { SceneListViewHeaderInnerProps, SceneListViewHeaderProps } from './models';
import { mapDispatchToPropsFactory, mapStateToPropsFactory } from './redux';

export type { SceneListViewHeaderProps } from './models';

export const SceneListViewHeaderFactory = <T>(
    preferencesKey: string,
    defaultListUserPreferencesState: SceneListUserPreferences,
    filterDataSourceMemoized: (
        searchQuery: string | undefined,
        dataSource: T[],
        columnDefinitions: Array<GridColumnDefinition<T>>,
        visibleColumns: string[]
    ) => T[],
    excelFileName: string
): React.ComponentType<SceneListViewHeaderProps<T>> =>
    compose<SceneListViewHeaderInnerProps<T>, SceneListViewHeaderProps<T>>(
        setDisplayName('SceneListViewHeader'),
        withTranslation(),
        connect(
            createSelector(sceneListStateSelector, mapStateToPropsFactory(preferencesKey)),
            mapDispatchToPropsFactory(preferencesKey)
        )
    )(
        SceneListViewHeaderComponentFactory<T>(
            preferencesKey,
            defaultListUserPreferencesState,
            filterDataSourceMemoized,
            excelFileName
        )
    );
