import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const TimediscWebIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 14C10.89 14 10 13.1 10 12C10 10.89 10.89 10 12 10C13.11 10 14 10.89 14 12A2 2 0 0 1 12 14M12 4A8 8 0 0 0 4 12A8 8 0 0 0 12 20A8 8 0 0 0 20 12A8 8 0 0 0 12 4Z" />
        </SvgIcon>
    );
};
TimediscWebIcon.displayName = 'TimediscWebIcon';

export { TimediscWebIcon };
