import type { DriverResponse } from '~/services/ApiClient';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    DRIVERS = 'DRIVERS',
}

export enum ActionTypeKeys {
    DRIVERS_PENDING = 'DRIVERS_PENDING',
    DRIVERS_FULFILLED = 'DRIVERS_FULFILLED',
    DRIVERS_REJECTED = 'DRIVERS_REJECTED',
}

export type DriversPayload = Readonly<DriverResponse>;

export type ActionTypes =
    | Pending<ActionTypeKeys.DRIVERS_PENDING>
    | Fulfilled<ActionTypeKeys.DRIVERS_FULFILLED, DriversPayload>
    | Rejected<ActionTypeKeys.DRIVERS_REJECTED>;
