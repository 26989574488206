import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
} from '~/services/ApiClient';
import { formatDriverName, formatVehicleName } from '~/services/Formatters';

import type { TachoFileEntry } from '../../models';

export const getVehicleDisplayNameFactory = (
    format: DisplayUserPreferencesVehicleDisplayFormat
): ((entry: TachoFileEntry) => string | undefined) => {
    return (entry) => entry.file.sendVehicle?.vehicle && formatVehicleName(entry.file.sendVehicle?.vehicle, format);
};

export const getDriverDisplayNameFactory = (
    format: DisplayUserPreferencesDriverDisplayFormat
): ((entry: TachoFileEntry) => string | undefined) => {
    return (entry) => entry.file.metadata.driver && formatDriverName(entry.file.metadata.driver, format);
};

export const getUploadDetailsValue = (entry: TachoFileEntry): string | undefined => {
    return entry.file.rmsStatus?.remoteId ?? entry.file.rmsStatus?.uploadDetails;
};
