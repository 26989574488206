import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { getDensityUnitSystemUtils } from '~/services/Formatters';

export interface DensityFormatterProps {
    t: SingleTFunction;
    value?: number;
}

export interface DensityFormatterInnerProps extends DensityFormatterProps {}

const DensityFormatter: React.FunctionComponent<DensityFormatterInnerProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{getDensityUnitSystemUtils(t).formatter(value)}</>;
};
DensityFormatter.displayName = 'DensityFormatter';

export { DensityFormatter };
