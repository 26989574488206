import { CircularProgress, Typography } from '@mui/material';
import * as React from 'react';

import { ChangelogEntityType, ChangelogSubpage } from '~/common/components/ChangelogsSubpage';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsSubpageHeader } from '~/components/SceneDetailsPane';
import { getRtdsScheduleChangelogAttributeDefinitions } from '~/scenes/RtdsSchedules/getRtdsScheduleChangelogAttributeDefinitions';
import type { ResolvedRtdsScheduleChangelogChanges } from '~/services/ApiClient';

import type { RtdsScheduleChangelogsRedux } from './redux';
import { useStyles } from './styles';

export interface RtdsScheduleChangelogsSubpageProps {
    scheduleId: number;
}

export interface RtdsScheduleChangelogsSubpageInnerProps
    extends RtdsScheduleChangelogsSubpageProps,
        InjectedTranslationProps,
        RtdsScheduleChangelogsRedux {}

export const RtdsScheduleChangelogsSubpageComponent: React.FC<RtdsScheduleChangelogsSubpageInnerProps> = ({
    getRtdsScheduleChangelogs,
    loading,
    rejected,
    t,
    scheduleId,
    changelogsEntries,
    previousScheduleId,
}) => {
    const ChangelogSubpageComponent = React.useMemo(() => ChangelogSubpage<ResolvedRtdsScheduleChangelogChanges>(), []);
    const classes = useStyles();

    React.useEffect(() => {
        getRtdsScheduleChangelogs(scheduleId);
    }, [getRtdsScheduleChangelogs, scheduleId]);

    const header = (
        <SceneDetailsSubpageHeader
            title={t('changelog-schedule-subpage-title', {
                scheduleId,
            })}
        />
    );

    if (loading || previousScheduleId !== scheduleId) {
        return (
            <>
                {header}
                <div className={classes.loaderContainer} data-id="loading-data">
                    <CircularProgress size={32} />
                </div>
            </>
        );
    }

    if (rejected) {
        return (
            <>
                {header}
                <Typography className={classes.messageText} variant="caption" data-id="loading-failed">
                    {t('failed-to-retrieve-data')}
                </Typography>
            </>
        );
    }

    return (
        <ChangelogSubpageComponent
            changelogSubpageTitle={t('changelog-schedule-subpage-title', {
                scheduleId,
            })}
            entityType={ChangelogEntityType.RTDS_SCHEDULE}
            changelogs={changelogsEntries}
            attributeDefinitions={getRtdsScheduleChangelogAttributeDefinitions(t)}
        />
    );
};
