import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type RequestResetPasswordClassKeys = 'root' | 'backLink';

export const useStyles = makeStyles<Theme, {}, RequestResetPasswordClassKeys>((theme: Theme) => ({
    root: {
        margin: theme.spacing(3, 5),
    },
    backLink: {
        margin: theme.spacing(3, 0, 0),
        display: 'block',
        textAlign: 'center',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));
