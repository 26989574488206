import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';

import { graphWidth } from '../../constants';

import type { TicksClassKey } from './styles';

export interface TicksProps {
    currentTime: Date;
}

export interface TicksInnerProps extends TicksProps, WithStyles<TicksClassKey> {}

export const TicksComponent: React.FC<TicksInnerProps> = ({ currentTime, classes }) => {
    const startDate = moment(currentTime).subtract(1, 'day').toDate();
    const ticks: JSX.Element[] = [];
    const graphStartDateMinute = startDate.getMinutes();
    const minutesUntilNextHour = (60 - graphStartDateMinute) % 60;
    const tickMinuteDelta = minutesUntilNextHour * (graphWidth / 24 / 60);
    const numberOfTicks = graphStartDateMinute === 0 ? 25 : 24;

    for (let tick = 0; tick < numberOfTicks; tick++) {
        const tickHour =
            graphStartDateMinute !== 0 ? (startDate.getHours() + 1 + tick) % 24 : (startDate.getHours() + tick) % 24;

        const tickHourNumberToShow =
            tickHour % 3 !== 0
                ? null
                : tickHour.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

        const tickClasses = classNames(
            classes.tick,
            { [classes.number]: tickHourNumberToShow },
            { [classes.noon]: tickHour === 12 },
            { [classes.midnight]: tickHour === 0 }
        );

        const tickNumberClasses = classNames(
            classes.tickLabel,
            { [classes.noon]: tickHour === 12 },
            { [classes.midnight]: tickHour === 0 }
        );

        const numberColor: 'textPrimary' | 'textSecondary' =
            tickHour === 0 || tickHour === 12 ? 'textPrimary' : 'textSecondary';

        const tickNumber = tickHourNumberToShow && (
            <Typography variant="caption" color={numberColor} className={tickNumberClasses}>
                {tickHourNumberToShow}
            </Typography>
        );

        const currentTick = (
            <div key={tick} className={tickClasses} style={{ left: (graphWidth / 24) * tick + tickMinuteDelta }}>
                {tickNumber}
            </div>
        );

        ticks.push(currentTick);
    }

    return <div className={classes.root}>{ticks}</div>;
};
