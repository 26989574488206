import { Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { AssetAvatar } from '~/components/AssetAvatar';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { Classification } from '~/services/ApiClient';
import { ClassificationType, TrailerDoorStatusKeys } from '~/services/ApiClient';
import { formatClassifications } from '~/services/Formatters';

import { PrimaryDoorStatusFormatter } from './components/PrimaryDoorStatus';
import { SecondaryDoorStatusFormatter } from './components/SecondaryDoorStatus';
import type { DoorStatusClassKey } from './styles';

export interface DoorStatusFormatterProps {
    t: SingleTFunction;
    value?: Classification[];
    withAvatar?: boolean;
}

export interface DoorStatusFormatterInnerProps extends DoorStatusFormatterProps, WithStyles<DoorStatusClassKey> {}

export const DoorStatusFormatterComponent: React.SFC<DoorStatusFormatterInnerProps> = ({
    value,
    classes,
    t,
    withAvatar,
}) => {
    if (!value || !value.every((x) => x)) {
        return null;
    }

    const title = formatClassifications(t, ClassificationType.DoorStatus, value);
    const keys = value.map((x) => x.key);
    const showBadge = keys.includes(TrailerDoorStatusKeys.LOCKED) || keys.includes(TrailerDoorStatusKeys.SEALED);
    if (withAvatar) {
        return (
            <AssetAvatar
                icon={<PrimaryDoorStatusFormatter value={value} />}
                badge={showBadge ? <SecondaryDoorStatusFormatter value={value} withAvatar /> : undefined}
                classes={{ avatar: classes.avatar, badge: classes.badge }}
                tooltip={title}
            />
        );
    } else {
        return (
            <Tooltip title={title}>
                <span data-id={`door-status:${value.keys}`} className={classes.flex}>
                    <PrimaryDoorStatusFormatter value={value} />
                    <SecondaryDoorStatusFormatter value={value} />
                </span>
            </Tooltip>
        );
    }
};
