import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const TrafficInfoOffIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="none" d="M0 0H24V24H0ZM0 0H24V24H0ZM0 0H24V24H0ZM0 0H24V24H0Z" />
            <path d="M20.44 20.16l-8.6-8.59h0L9.69 9.42h0l-1-1h0L5.8 5.53h0l-1-1h0L3.27 3 2 4.27 4.12 6.39 2.93 9.84v6.91a.86.86 0 0 0 .86.87h.86a.87.87 0 0 0 .87-.87V13.22A1.3 1.3 0 0 1 6 10.7H6L6.62 9h-2l.52-1.54L8 10.27l-.75 2.16v6.92a.86.86 0 0 0 .86.86H9a.87.87 0 0 0 .86-.86v-.87h6.37L19.73 22 21 20.73l-.57-.57ZM10.27 15.89a1.3 1.3 0 1 1 1.3-1.3A1.3 1.3 0 0 1 10.27 15.89ZM9 11.57l.08-.24.24.24Z" />
            <path d="M15.1 5.09 15 4.65a1.32 1.32 0 0 0-1.23-.86H6.6l1.3 1.3Z" />
            <path d="M19.28 7.25a1.31 1.31 0 0 0-1.23-.87H10.27a1.29 1.29 0 0 0-.79.28l1 1h7.56l1.3 3.89h-5l2.49 2.49a1.3 1.3 0 1 1 1.71 1.71l2.49 2.49V12.43Z" />
        </SvgIcon>
    );
};
TrafficInfoOffIcon.displayName = 'TrafficInfoOffIcon';

export { TrafficInfoOffIcon };
