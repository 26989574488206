import { TableGroupRow } from '@devexpress/dx-react-grid-material-ui';
import { styled } from '@mui/material/styles';

const minColumnWidth = 52;

const TableGroupRowCell = styled(TableGroupRow.Cell)({
    '&.TableGroupCell-cell': {
        paddingBottom: 0,
        paddingTop: 0,
    },
});
const TableGroupRowSummaryCell = styled(TableGroupRow.SummaryCell)(() => ({ paddingBottom: 0, paddingTop: 0 }));

export { TableGroupRowCell, TableGroupRowSummaryCell, minColumnWidth };
