import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ExpandCollapseControlsProps } from './models';

const ExpandCollapseControls: FC<ExpandCollapseControlsProps> = (props) => {
    const { t } = useTranslation();
    const expandAllTitle = t('expand-all');
    const collapseAllTitle = t('collapse-all');
    return (
        <>
            <Tooltip title={expandAllTitle}>
                <span>
                    <IconButton
                        disabled={props.isExpandAllButtonDisabled}
                        onClick={props.onExpandAllButtonClick}
                        aria-label={expandAllTitle}
                        data-testid="expand-collapse-controls-expand-all-button"
                    >
                        <UnfoldMore />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={collapseAllTitle}>
                <span>
                    <IconButton
                        disabled={props.isCollapseAllButtonDisabled}
                        onClick={props.onCollapseAllButtonClick}
                        aria-label={collapseAllTitle}
                        data-testid="expand-collapse-controls-collapse-all-button"
                    >
                        <UnfoldLess />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
};

ExpandCollapseControls.displayName = 'ExpandCollapseControls';
export { ExpandCollapseControls };
