import Map from '@mui/icons-material/Map';
import ZoomOutMap from '@mui/icons-material/ZoomOutMap';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC } from 'react';

import { IconDivider } from '~/components/IconDivider';
import { HybridMapIcon, SatelliteIcon, TrafficInfoOnIcon, TruckAttributesOnIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { InjectedMapSharedProps } from '~/components/MapSharedProps';
import { MapViewMode } from '~/components/MapSharedProps';
import { TitledIconButton } from '~/components/TitledIconButton';

import type { MapActionsClassKey } from './styles';

export interface MapActionsProps {}

export interface DispatchProps {
    changeViewModeToHybrid: () => void;
    changeViewModeToMap: () => void;
    changeViewModeToSatellite: () => void;
    showAllSelectedAssets: () => void;
}

export interface MapActionsInnerProps
    extends DispatchProps,
        InjectedMapSharedProps,
        InjectedTranslationProps,
        WithStyles<MapActionsClassKey> {}

export const MapActionsComponent: FC<MapActionsInnerProps> = (props) => {
    const {
        t,
        classes,
        mapViewMode,
        mapLayersSecurables,
        changeViewModeToMap,
        changeViewModeToSatellite,
        changeViewModeToHybrid,
        showAllSelectedAssets,
        toggleTrafficIncidentsOverlayEnabled,
        toggleTruckAttributesOverlayEnabled,
        truckAttributesOverlayEnabled,
        trafficIncidentsOverlayEnabled,
    } = props;

    const trafficIconContext = trafficIncidentsOverlayEnabled
        ? { tooltip: t('hide-traffic-information'), state: 'on' }
        : { tooltip: t('show-traffic-information'), state: 'off' };

    const truckIconContext = truckAttributesOverlayEnabled
        ? { tooltip: t('hide-truck-limitations'), state: 'on' }
        : { tooltip: t('show-truck-limitations'), state: 'off' };

    const changeViewModeControls = mapLayersSecurables.satelliteAllowed && (
        <>
            <TitledIconButton
                className={classNames({ [classes.selected]: mapViewMode === MapViewMode.MAP })}
                title={t('map')}
                placement="bottom-end"
                color="inherit"
                onClick={changeViewModeToMap}
                data-id="map-view:map"
                area-selected={`${mapViewMode === MapViewMode.MAP}`}
            >
                <Map />
            </TitledIconButton>

            <TitledIconButton
                className={classNames({ [classes.selected]: mapViewMode === MapViewMode.HYBRID })}
                title={t('hybrid')}
                placement="bottom-end"
                color="inherit"
                onClick={changeViewModeToHybrid}
                data-id="map-view:hybrid"
                area-selected={`${mapViewMode === MapViewMode.HYBRID}`}
            >
                <HybridMapIcon />
            </TitledIconButton>

            <TitledIconButton
                className={classNames({ [classes.selected]: mapViewMode === MapViewMode.SATELLITE })}
                title={t('satellite')}
                placement="bottom-end"
                color="inherit"
                onClick={changeViewModeToSatellite}
                data-id="map-view:satellite"
                area-selected={`${mapViewMode === MapViewMode.SATELLITE}`}
            >
                <SatelliteIcon />
            </TitledIconButton>
        </>
    );

    const toggleTrafficIncidentsOverlayControl = mapLayersSecurables.trafficIncidentsAllowed && (
        <TitledIconButton
            className={classNames({ [classes.selected]: trafficIncidentsOverlayEnabled })}
            title={trafficIconContext.tooltip}
            placement="bottom-end"
            color="inherit"
            onClick={toggleTrafficIncidentsOverlayEnabled}
            data-id={`traffic-incidents:${trafficIconContext.state}`}
            area-selected={trafficIncidentsOverlayEnabled}
        >
            <TrafficInfoOnIcon />
        </TitledIconButton>
    );

    const toggleTruckAttributesOverlayControl = mapLayersSecurables.truckAttributesAllowed && (
        <TitledIconButton
            className={classNames({ [classes.selected]: truckAttributesOverlayEnabled })}
            title={truckIconContext.tooltip}
            placement="bottom-end"
            color="inherit"
            onClick={toggleTruckAttributesOverlayEnabled}
            data-id={`truck-attributes:${truckIconContext.state}`}
            area-selected={truckAttributesOverlayEnabled}
        >
            <TruckAttributesOnIcon />
        </TitledIconButton>
    );

    const toggleExtraLayersControls = (toggleTrafficIncidentsOverlayControl || toggleTruckAttributesOverlayControl) && (
        <div className={classNames({ [classes.toggleExtraLayersControlsLeftMargin]: changeViewModeControls })}>
            {toggleTrafficIncidentsOverlayControl}
            {toggleTruckAttributesOverlayControl}
        </div>
    );

    return (
        <>
            <TitledIconButton
                title={t('show-all-selected-assets')}
                placement="bottom-end"
                color="inherit"
                onClick={showAllSelectedAssets}
                data-id="show-all-selected-assets"
            >
                <ZoomOutMap />
            </TitledIconButton>

            <IconDivider />

            {changeViewModeControls}
            {toggleExtraLayersControls}
        </>
    );
};
