import type { Action } from 'redux';

import type { ConversationsSummaryWidgetStoreState } from './components/ConversationsSummaryWidget/reducers';
import { conversationsSummaryWidgetReducer } from './components/ConversationsSummaryWidget/reducers';
import type { ConversationWidgetsStoreState } from './components/ConversationWidgets/reducers';
import { conversationWidgetsReducer } from './components/ConversationWidgets/reducers';
import type { SendMessageDialogStoreState } from './components/SendMessageDialog';
import { sendMessageDialogReducer } from './components/SendMessageDialog';
import type { ConversationsRootStoreState } from './reducers';
import { conversationsRootReducer } from './reducers';

export interface CommunicationModuleStoreState {
    conversationsRoot: ConversationsRootStoreState;
    conversationWidgets: ConversationWidgetsStoreState;
    conversationsSummaryWidget: ConversationsSummaryWidgetStoreState;
    sendMessageDialog: SendMessageDialogStoreState;
}

export const communicationModuleReducer = (
    state: CommunicationModuleStoreState | undefined,
    action: Action
): CommunicationModuleStoreState => {
    const conversationsRoot = conversationsRootReducer(state?.conversationsRoot, action);
    const conversationWidgets = conversationWidgetsReducer(state?.conversationWidgets, action, conversationsRoot);
    const conversationsSummaryWidget = conversationsSummaryWidgetReducer(
        state?.conversationsSummaryWidget,
        action,
        conversationsRoot
    );
    const sendMessageDialog = sendMessageDialogReducer(state?.sendMessageDialog, action);

    return {
        conversationsRoot,
        conversationWidgets,
        conversationsSummaryWidget,
        sendMessageDialog,
    };
};
