import { Divider } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { IconDividerClassKey } from './styles';

export interface IconDividerProps {}

export interface IconDividerInnerProps extends IconDividerProps, WithStyles<IconDividerClassKey> {}

export const IconDividerComponent: React.FunctionComponent<IconDividerInnerProps> = ({ classes }) => {
    return <Divider classes={classes} />;
};
