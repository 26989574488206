import type { FC } from 'react';

import { getI18n } from '~/i18n';

import { DateTimeFormatter } from '../Formatters';

import type { ElapsedTimeProps } from './models';
import { Delimiter, SvgIcon, Typography } from './styles';
import { formatDistanceToNowStrict } from './utils';

const ElapsedTime: FC<ElapsedTimeProps> = (props) => {
    const { since, icon } = props;
    const { language } = getI18n();

    return (
        <Typography variant="caption" color="textSecondary" data-testid="elapsed-time-component">
            <SvgIcon data-testid="icon">{icon}</SvgIcon>
            <span data-testid="formatted-date-time">
                <DateTimeFormatter value={since} />
            </span>
            <Delimiter>•</Delimiter>
            <span data-testid="age">{formatDistanceToNowStrict(language, since)}</span>
        </Typography>
    );
};

export { ElapsedTime };
