import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { PositionMarkerInnerProps, PositionMarkerProps } from './component';
import { PositionMarkerComponent } from './component';
import { PositionMarkerStyleRules } from './styles';

export const PositionMarker = compose<PositionMarkerInnerProps, PositionMarkerProps>(
    setDisplayName('PositionMarker'),
    withStyles(PositionMarkerStyleRules, { withTheme: true })
)(PositionMarkerComponent);
