import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';

import type { Feature } from '../../models';
import { withFeatureFlags } from '../withFeatureFlags';

import { withFeatureFlagInterceptorHoc } from './component';

export const withFeatureFlagInterceptor = <WrappedProps extends {}>(
    feature: Feature,
    disabledBehavior?: JSX.Element
): ComponentEnhancer<WrappedProps, WrappedProps> =>
    compose<WrappedProps, WrappedProps>(withFeatureFlags(), withFeatureFlagInterceptorHoc(feature, disabledBehavior));
