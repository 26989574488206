import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withSceneAnalytics } from '~/components/Analytics';
import { protectedComponentHoc } from '~/components/AuthenticationData';
import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';
import { withMenu } from '~/components/Navigation';
import { requiredSceneAssetSelectorUserPreferencesFactory } from '~/components/SceneAssetSelector';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { staticDataStoreStateSelector } from '~/selectors';

import { TachoFilesComponent } from './component';
import {
    TACHOFILES_ASSETSELECTOR_USERPREFERENCES_KEY,
    TACHOFILES_USERPREFERENCES_KEY,
    requiredTachoFilesUserPreferences,
} from './preferences';
import { mapDispatchProps, mapStateToProps, mergeProps } from './redux';
import { tachoFilesStateSelector } from './selectors';
import { TachoFilesStyleRules } from './styles';

export * from './storestate';

export const TachoFiles = compose(
    setDisplayName('TachoFiles'),
    protectedComponentHoc,
    withMenu,
    withTranslation(),
    withStyles(TachoFilesStyleRules),
    ensureSettings([SettingsKey.SECURABLES, SettingsKey.TACHOGRAPH_DATA_BLOCK, SettingsKey.TACHO_DATA]),
    ensureUserPreferences([
        requiredSceneAssetSelectorUserPreferencesFactory(TACHOFILES_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredTachoFilesUserPreferences,
    ]),
    connect(
        createSelector(
            settingDataSelector(SettingsKey.SECURABLES),
            settingDataSelector(SettingsKey.TACHO_DATA),
            tachoFilesStateSelector,
            staticDataStoreStateSelector,
            keyDataStateSelectorFactory(TACHOFILES_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(TACHOFILES_ASSETSELECTOR_USERPREFERENCES_KEY),
            mapStateToProps
        ),
        mapDispatchProps,
        mergeProps
    ),
    withSceneAnalytics('tacho-files')
)(TachoFilesComponent);
