import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { IgnitionOffIcon, IgnitionOnIcon } from '~/components/Icons';

import type { IgnitionStatusBadgeClassKey } from './styles';

export interface IgnitionStatusBadgeProps {
    ignitionStatus: boolean;
}

export interface IgnitionStatusBadgeInnerProps
    extends IgnitionStatusBadgeProps,
        WithStyles<IgnitionStatusBadgeClassKey> {}

export const IgnitionStatusBadgeComponent: React.FC<IgnitionStatusBadgeInnerProps> = ({ classes, ignitionStatus }) => {
    const ignitionStatusIconClass = classNames(classes.ignitionStatusIcon, {
        [classes.ignitionStatusIconOff]: !ignitionStatus,
        [classes.ignitionStatusIconOn]: ignitionStatus,
    });

    const ignitionStatusBadge = ignitionStatus ? (
        <IgnitionOnIcon className={ignitionStatusIconClass} />
    ) : (
        <IgnitionOffIcon className={ignitionStatusIconClass} />
    );

    return <span className={classes.ignitionStatus}>{ignitionStatusBadge}</span>;
};
