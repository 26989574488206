import { maxNumberOfMessagesToDisplay } from '~/data/monitoring';
import { orderBy } from '~/libs/utility';
import type { ResolvedConversationUpdate, ResolvedMessage } from '~/services/ApiClient';

export const applyConversationUpdate = (
    latestMessages: ResolvedMessage[],
    conversationUpdate: ResolvedConversationUpdate
): ResolvedMessage[] => {
    return orderBy(
        [
            ...latestMessages.filter((conversationMessage) => {
                const updatedMessage = conversationUpdate.updatedMessages.find(
                    ({ value: { id } }) => id === conversationMessage.value.id
                );

                return !(updatedMessage && updatedMessage.value.revision > conversationMessage.value.revision);
            }),
            ...conversationUpdate.updatedMessages.filter((updatedMessage) => {
                const conversationMessage = latestMessages.find(({ value: { id } }) => id === updatedMessage.value.id);

                return !conversationMessage || conversationMessage.value.revision < updatedMessage.value.revision;
            }),
        ],
        ['value.dateTime', 'value.id'],
        ['desc', 'desc']
    ).slice(0, maxNumberOfMessagesToDisplay);
};
