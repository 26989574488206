import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';

import type { AssetDataType, AssetHistorySubpageInnerProps, AssetHistorySubpageProps } from './component';
import { AssetHistorySubpageComponentFactory } from './component';
import { mapStateToProps } from './redux';
import { assetHistorySubpageStyleRulesFactory } from './styles';

export const AssetHistorySubpageFactory = <T extends keyof AssetDataType>(
    assetType: T
): React.ComponentType<AssetHistorySubpageProps<AssetDataType[T]>> =>
    compose<AssetHistorySubpageInnerProps<AssetDataType[T]>, AssetHistorySubpageProps<AssetDataType[T]>>(
        setDisplayName('AssetHistorySubpage'),
        withDisplayPreferences(),
        withTranslation(),
        withStyles(assetHistorySubpageStyleRulesFactory(assetType)),
        connect(createSelector(settingDataSelector(SettingsKey.SECURABLES), mapStateToProps))
    )(AssetHistorySubpageComponentFactory(assetType));
