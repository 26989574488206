import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';

import type { LocationSectionInnerProps, LocationSectionProps } from './component';
import { LocationSectionComponent } from './component';

export const LocationSection = compose<LocationSectionInnerProps, LocationSectionProps>(
    setDisplayName('LocationSection'),
    withDisplayPreferences()
)(LocationSectionComponent);
