/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
import { difference } from '~/libs/utility';
import { AssetGroup, createApiModel } from '~/services/ApiClient';

export const filterAssetGroups = (
    assetGroups: AssetGroup[],
    predicate: (assetGroup: AssetGroup) => boolean
): AssetGroup[] => {
    const applied = assetGroups.filter(predicate).map((assetGroup) => applyAssetGroupFilter(assetGroup, predicate));

    if (difference(assetGroups, applied).length === 0) {
        return assetGroups;
    }

    return applied;
};

const applyAssetGroupFilter = (assetGroup: AssetGroup, predicate: (assetGroup: AssetGroup) => boolean): AssetGroup => {
    const nestedGroups = assetGroup.assetGroups || [];
    if (!nestedGroups.length) {
        return assetGroup;
    }

    const filteredNestedGroups = filterAssetGroups(nestedGroups, predicate);
    if (filteredNestedGroups === assetGroup.assetGroups) {
        return assetGroup;
    }

    return createApiModel(AssetGroup, { ...assetGroup, assetGroups: filteredNestedGroups });
};
