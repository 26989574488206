import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { floorCurrentTimestampStateSelector } from '~/components/SynchronizationClock';

import type { ActivitiesGraphInnerProps, ActivitiesGraphProps, ActivitiesGraphStateProps } from './component';
import { ActivitiesGraphComponent } from './component';
import { updateGraphIntervalMilliseconds } from './constants';
import { mapStateToProps } from './redux';
import { ActivitiesGraphStyleRules } from './styles';

export const ActivitiesGraph = compose<ActivitiesGraphInnerProps, ActivitiesGraphProps>(
    setDisplayName('ActivitiesGraph'),
    connect<ActivitiesGraphStateProps>(
        createSelector(floorCurrentTimestampStateSelector(updateGraphIntervalMilliseconds), mapStateToProps)
    ),
    withStyles(ActivitiesGraphStyleRules)
)(ActivitiesGraphComponent);
