import type { TFunction } from 'react-i18next';

interface Classification {
    id: number;
    key: string;
    displayName?: string;
}

interface translateClassificationArgs<T, U extends Classification> {
    t: TFunction;
    classificationKey: T;
    classification: U;
}

const translateClassification = <T, U extends Classification>(args: translateClassificationArgs<T, U>) => {
    const { classification, classificationKey, t } = args;

    if (classification.displayName) {
        return classification.displayName;
    }

    return t(`classifications:${classificationKey}-${classification.key.toLocaleLowerCase()}`);
};

export { translateClassification };

export type { Classification };
