import type { NumericDictionary } from '~/libs/utility';
import type {
    Conversation,
    ResolvedConversation,
    WorkflowFormDefinition,
    WorkflowFormDefinitionStatus,
} from '~/services/ApiClient';

import { resolveConversationMessage } from '../../reducers.resolveConversationMessage';

export const resolveConversation = (
    conversation: Conversation,
    workflowFormDefinitions: NumericDictionary<WorkflowFormDefinition | WorkflowFormDefinitionStatus> | undefined
): ResolvedConversation => ({
    ...conversation,
    messages: conversation.messages.map((message) => resolveConversationMessage(message, workflowFormDefinitions)),
});
