import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { StaffHeaderInfoInnerProps, StaffHeaderInfoProps } from './component';
import { StaffHeaderInfoComponent } from './component';
import { StaffHeaderInfoStyleRules } from './styles';

export const StaffHeaderInfo = compose<StaffHeaderInfoInnerProps, StaffHeaderInfoProps>(
    setDisplayName('StaffHeaderInfo'),
    withTranslation(),
    withStyles(StaffHeaderInfoStyleRules)
)(StaffHeaderInfoComponent);
