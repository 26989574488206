import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type MovementPositionGroupClassKey = 'splitRow' | 'primaryMoving' | 'distance' | 'iconMargin' | 'flex' | 'in';

export const MovementPositionGroupStyleRules: StyleRulesCallback<Theme, {}, MovementPositionGroupClassKey> = (
    theme: Theme
) =>
    createStyles({
        splitRow: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
        },
        primaryMoving: {
            display: 'flex',
            alignItems: 'center',
            margin: theme.spacing(1, 0),
            '& >*': {
                marginRight: theme.spacing(0.5),
            },
        },
        distance: {
            fontWeight: theme.typography.fontWeightBold,
        },
        iconMargin: {
            marginLeft: theme.spacing(),
        },
        flex: {
            display: 'flex',
            alignItems: 'center',
        },
        in: {
            marginRight: theme.spacing(0.5),
        },
    });
