import FilterList from '@mui/icons-material/FilterList';
import UngroupGridIcon from '@mui/icons-material/FormatAlignJustify';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import { ColumnSelector } from '~/components/ColumnSelector';
import { IconDivider } from '~/components/IconDivider';
import { ExportIcon, FilterOffIcon, GroupGridIcon, PinColumnIcon, UnpinColumnIcon } from '~/components/Icons';
import { TitledIconButton } from '~/components/TitledIconButton';

import { buildColumnGroups } from './buildColumnGroups';
import type { ViewBarActionsInnerProps } from './models';

export const ViewBarActionsComponentFactory: <T>() => FC<ViewBarActionsInnerProps<T>> = <T,>() => {
    const Component = ({
        classes,
        columns,
        disableActions,
        exportIsDisabled,
        exportToExcel,
        filteredDataSource,
        filteringEnabled,
        gridActionsDisabled,
        groupingEnabled,
        hideColumns,
        onFilteredDataSourceChange,
        onShowColumns,
        pinEnabled,
        showColumns,
        t,
        toggleFilteringEnabled,
        toggleGroupingEnabled,
        togglePinEnabled,
        visibleColumns,
    }: ViewBarActionsInnerProps<T>) => {
        useEffect(() => {
            if (onFilteredDataSourceChange) {
                onFilteredDataSourceChange(filteredDataSource);
            }
        }, [filteredDataSource, onFilteredDataSourceChange]);
        const columnGroups = useMemo(() => buildColumnGroups(columns), [columns]);

        const pinningContext = pinEnabled
            ? {
                  title: t('unfreeze-first-column'),
                  icon: <UnpinColumnIcon />,
                  state: 'on',
              }
            : {
                  title: t('freeze-first-column'),
                  icon: <PinColumnIcon />,
                  state: 'off',
              };

        const groupingContext = groupingEnabled
            ? {
                  title: t('ungroup-columns'),
                  icon: <UngroupGridIcon />,
                  state: 'on',
              }
            : {
                  title: t('group-by-column'),
                  icon: <GroupGridIcon />,
                  state: 'off',
              };

        const gridFilteringContext = filteringEnabled
            ? {
                  title: t('filter-off'),
                  icon: <FilterOffIcon />,
                  state: 'on',
              }
            : {
                  title: t('filter'),
                  icon: <FilterList />,
                  state: 'off',
              };

        const viewBarActions = (
            <div className={classes.buttons}>
                <TitledIconButton
                    title={exportIsDisabled ? t('nothing-to-export') : t('export-to-excel')}
                    disabled={gridActionsDisabled || exportIsDisabled || disableActions?.export}
                    placement="bottom-end"
                    color="inherit"
                    onClick={exportToExcel}
                    data-id="export-to-excel"
                >
                    <ExportIcon />
                </TitledIconButton>

                <IconDivider />

                <ColumnSelector
                    columnGroups={columnGroups}
                    deselectColumns={hideColumns}
                    selectColumns={(addedColumns) => {
                        if (onShowColumns) {
                            onShowColumns(addedColumns);
                        }
                        showColumns(addedColumns);
                    }}
                    selectedColumns={visibleColumns}
                    disabledButton={gridActionsDisabled}
                />

                <TitledIconButton
                    title={gridFilteringContext.title}
                    color="inherit"
                    disabled={gridActionsDisabled || disableActions?.filter}
                    onClick={toggleFilteringEnabled}
                    data-id={`toggle-filtering:${gridFilteringContext.state}`}
                >
                    {gridFilteringContext.icon}
                </TitledIconButton>

                <TitledIconButton
                    title={pinningContext.title}
                    placement="bottom-end"
                    color="inherit"
                    disabled={gridActionsDisabled || disableActions?.pinned}
                    onClick={togglePinEnabled}
                    data-id={`toggle-pinning:${pinningContext.state}`}
                >
                    {pinningContext.icon}
                </TitledIconButton>

                <TitledIconButton
                    title={groupingContext.title}
                    placement="bottom-end"
                    color="inherit"
                    disabled={gridActionsDisabled || disableActions?.grouping}
                    onClick={toggleGroupingEnabled}
                    data-id={`toggle-grouping:${groupingContext.state}`}
                >
                    {groupingContext.icon}
                </TitledIconButton>
            </div>
        );

        return viewBarActions;
    };
    return Component;
};
