import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import type { ReorderableSectionInnerProps, ReorderableSectionProps } from './component';
import { ReorderableSectionComponent } from './component';
import { ReorderableSectionStyleRules } from './styles';

export const ReorderableSection: React.ComponentType<ReorderableSectionProps> = compose<
    ReorderableSectionInnerProps,
    ReorderableSectionProps
>(
    setDisplayName('ReorderableSection'),
    withStyles(ReorderableSectionStyleRules, { withTheme: true })
)(ReorderableSectionComponent);
