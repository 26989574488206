import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export interface MenuRootStoreState {
    isOverlayMenuOpen: boolean;
}

const defaultStoreState: MenuRootStoreState = {
    isOverlayMenuOpen: false,
};

export const menuRootReducer = (
    state: MenuRootStoreState = defaultStoreState,
    action: ActionTypes
): MenuRootStoreState => {
    switch (action.type) {
        case ActionTypeKeys.OVERLAYMENU_OPEN:
            return {
                ...state,
                isOverlayMenuOpen: true,
            };

        case ActionTypeKeys.OVERLAYMENU_CLOSE:
            return {
                ...state,
                isOverlayMenuOpen: false,
            };

        default:
            return state;
    }
};
