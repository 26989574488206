import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LabeledIndicatorClassKey =
    | 'root'
    | 'badge'
    | 'primaryText'
    | 'secondaryText'
    | 'title'
    | 'warning'
    | 'error'
    | 'success'
    | 'disabled'
    | 'flex';

export const LabeledIndicatorStyleRules: StyleRulesCallback<Theme, {}, LabeledIndicatorClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0.5, 0.5),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        badge: {
            display: 'inline-block',
            marginRight: theme.spacing(0.5),
            '& > *': {
                width: 22,
                height: 22,
            },
        },
        title: {
            fontSize: 12,
        },
        primaryText: {
            width: '100%',
            textAlign: 'right',
            fontSize: 12,
        },
        secondaryText: {
            width: '100%',
            textAlign: 'right',
            fontSize: 10,
            minHeight: 16,
        },
        warning: {
            backgroundColor: theme.palette.warning2.main,
            '& $primaryText': {
                color: theme.palette.common.white,
                opacity: 0.87,
            },
            '& $title, $secondaryText': {
                color: theme.palette.common.white,
                opacity: 0.54,
            },
        },
        error: {
            backgroundColor: theme.palette.error.main,
            '& $primaryText': {
                color: theme.palette.common.white,
                opacity: 0.87,
            },
            '& $title, $secondaryText': {
                color: theme.palette.common.white,
                opacity: 0.54,
            },
        },
        success: {
            backgroundColor: theme.palette.success.main,
            '& $primaryText': {
                color: theme.palette.common.white,
                opacity: 0.87,
            },
            '& $title, $secondaryText': {
                color: theme.palette.common.white,
                opacity: 0.54,
            },
        },
        disabled: {
            backgroundColor: theme.palette.background.paper,
            '& $primaryText': {
                color: theme.palette.text.disabled,
                opacity: 0.87,
            },
            '& $title, $secondaryText': {
                color: theme.palette.text.disabled,
                opacity: 0.87,
            },
        },
        flex: {
            display: 'flex',
        },
    });
