import { getValueFromHash } from '~/common';
import type { SettingsDataType, SettingsKey } from '~/components/EnsureSettings';
import type { TrailerCompartmentChangelogChanges } from '~/services/ApiClient';

import type { ResolvedTrailerCompartmentChangelogChanges } from './models';

export const resolveTrailerCompartmentChangelogChanges = (
    compartment: TrailerCompartmentChangelogChanges,
    trailerBandwidthLinked: SettingsDataType[SettingsKey.TRAILER_BANDWIDTH_LINKED]
): ResolvedTrailerCompartmentChangelogChanges => {
    return {
        ...compartment,
        bandwidthLinked: getValueFromHash(trailerBandwidthLinked, compartment.bandwidthLinked),
    };
};
