import { KEY_BACK_SPACE, KEY_DASH, KEY_DELETE, KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_TAB, KEY_UP } from 'keycode-js';

const KEY_FIREFOX_DASH = 173;

const ALLOWED_KEYS = [
    KEY_DASH,
    KEY_FIREFOX_DASH,
    KEY_BACK_SPACE,
    KEY_DELETE,
    KEY_TAB,
    KEY_UP,
    KEY_DOWN,
    KEY_LEFT,
    KEY_RIGHT,
];

export { ALLOWED_KEYS };
