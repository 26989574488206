import { compose, setDisplayName } from 'react-recompose';

import { ensureUserPreferences } from '~/components/UserPreferences';

import type { NavigationSectionInnerProps, NavigationSectionProps } from './component';
import { NavigationSectionComponent } from './component';
import type { EtaDisplayOption, NavigationSectionUserPreferences } from './preferences';
import {
    EtaDisplayOptionsLiterals,
    NAVIGATIONSECTION_PREFERENCES_KEY,
    defaultNavigationSectionUserPreferences,
    requiredNavigationSectionUserPreferences,
} from './preferences';

export {
    EtaDisplayOptionsLiterals,
    requiredNavigationSectionUserPreferences,
    defaultNavigationSectionUserPreferences,
    NAVIGATIONSECTION_PREFERENCES_KEY,
};

export type { EtaDisplayOption, NavigationSectionUserPreferences };

export const NavigationSection = compose<NavigationSectionInnerProps, NavigationSectionProps>(
    setDisplayName('NavigationSection'),
    ensureUserPreferences([requiredNavigationSectionUserPreferences], false)
)(NavigationSectionComponent);
