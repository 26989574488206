import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { userPreferencesStateSelector } from '~/selectors';
import type { NonEmptyArray } from '~/types';

import type { DispatchProps, RequiredUserPreferences, StateProps } from './component';
import { ensureUserPreferencesHoc } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export type { RequiredUserPreferences } from './component';

export const ensureUserPreferences = (
    requiredUserPreferences: NonEmptyArray<RequiredUserPreferences>,
    showLoaderWhileRetrievingForFirstTime: boolean = true
): ComponentEnhancer<unknown, unknown> =>
    compose(
        connect<StateProps, DispatchProps>(
            createSelector(userPreferencesStateSelector, mapStateToProps),
            mapDispatchToProps
        ),
        ensureUserPreferencesHoc(requiredUserPreferences, showLoaderWhileRetrievingForFirstTime)
    );
