import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type InhibitorIconFormatterClassKey = 'iconSize' | 'inhibitorDisabled' | 'inhibitorEnabled';

export const InhibitorIconFormatterStyleRules: StyleRulesCallback<Theme, {}, InhibitorIconFormatterClassKey> = (
    theme: Theme
) =>
    createStyles({
        iconSize: {
            width: 24,
            height: 24,

            '& svg': {
                width: 20,
                height: 20,
            },
        },
        inhibitorDisabled: {
            color: theme.palette.success.main,
            backgroundColor: theme.palette.background.default,
        },
        inhibitorEnabled: {
            color: theme.palette.error.main,
            backgroundColor: theme.palette.background.default,
        },
    });
