import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '~/components/Icons';

const ChangelogIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 34 34">
            <path d="M3,5A1,1,0,0,1,4,4H7V5H5A1,1,0,0,0,4,6V28a1,1,0,0,0,1,1h6V27H6V7H22v4h2V6a1,1,0,0,0-1-1H21V4h3a1,1,0,0,1,1,1v7h2V5a3,3,0,0,0-3-3H23a2,2,0,0,0-2-2H7A2,2,0,0,0,5,2H4A3,3,0,0,0,1,5V31a1,1,0,0,0,1,1H12V30H3ZM9,2H19V5H9Z" />
            <path d="M22,14a9,9,0,1,0,9,9A9,9,0,0,0,22,14Zm0,16a7,7,0,1,1,7-7A7,7,0,0,1,22,30Z" />
            <path d="M23,19H21v4a1,1,0,0,0,.29.71l3,3,1.41-1.41L23,22.59Z" />
        </SvgIcon>
    );
};
ChangelogIcon.displayName = 'ChangelogIcon';

export { ChangelogIcon };
