import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type DateTimeEditorClassKey = 'toolbarIcon' | 'clear' | 'input';

export const useStyles = makeStyles<Theme, {}, DateTimeEditorClassKey>((theme) => ({
    input: { fontSize: 14 },
    toolbarIcon: { color: theme.palette.primary.contrastText },
    clear: { cursor: 'pointer', color: theme.palette.text.secondary },
}));
