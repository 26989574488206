const dataLayerKey = 'dataLayer';

export function gtag(..._args: unknown[]): void {
    // eslint-disable-next-line prefer-rest-params
    window[dataLayerKey].push(arguments);
}

export const install = (trackingId: string, configOptions?: unknown): void => {
    const scriptId = 'fv-gtag';

    if (document.getElementById(scriptId)) {
        return;
    }

    const { head } = document;
    const script = document.createElement('script');
    script.id = scriptId;
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    head.insertBefore(script, head.firstChild);

    window[dataLayerKey] = window[dataLayerKey] || [];

    gtag('js', new Date());
    gtag('config', trackingId, configOptions);
};
