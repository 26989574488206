import { CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { ConfirmationDialog } from '~/components/Dialogs';
import { useTranslation } from '~/components/LanguageSelector';
import { deleteAdminVehicleTypeAction, deleteAdminVehicleTypeResetAction } from '~/data/vehicletypeadministration';
import { reportError } from '~/reporting';

import { constructVehicleTypesAdministrationUrl } from '../../constructVehicleTypesAdministrationUrl';

import type { DeleteVehicleTypeDialogProps } from './models';
import { useStyles } from './styles';

const DeleteVehicleTypeDialogComponent: React.FC<DeleteVehicleTypeDialogProps> = (props) => {
    const { onCancel, id, name, onRefreshData } = props;
    const { push } = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [status, setStatus] = useState({ isLoading: false, error: false });

    const deleteVehicleType = () => {
        if (!id) {
            return;
        }

        setStatus({ isLoading: true, error: false });

        dispatch(deleteAdminVehicleTypeAction(id))
            .then(() => {
                setStatus({ isLoading: false, error: false });
                onCancel();
                onRefreshData();
                dispatch(deleteAdminVehicleTypeResetAction());
                push(constructVehicleTypesAdministrationUrl());
            })
            .catch((error) => {
                reportError(error);
                setStatus({ isLoading: false, error: true });
            });
    };

    return (
        <ConfirmationDialog
            title={t('delete')}
            confirmationActionText={t('delete')}
            dataId="delete-vehicletype-dialog"
            open
            onConfirm={deleteVehicleType}
            onCancel={onCancel}
            isDisabled={status?.isLoading}
        >
            <>
                <Typography>{t('delete-vehicletype-dialog-prompt', { name })}</Typography>
                {status.error && (
                    <Typography variant="caption" color="error" data-id="delete-error-message">
                        {t('generic-error-message')}
                    </Typography>
                )}
                {status.isLoading && (
                    <div className={classes.messageContainer}>
                        <CircularProgress size={30} className={classes.spinner} />
                        <Typography variant="subtitle2" data-id="delete-progress-message" className={classes.message}>
                            {t('delete-vehicletype-progress-message')}
                        </Typography>
                    </div>
                )}
            </>
        </ConfirmationDialog>
    );
};

DeleteVehicleTypeDialogComponent.displayName = 'DeleteVehicleTypeDialog';
export default DeleteVehicleTypeDialogComponent;
