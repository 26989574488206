import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import type { ValueProps } from './models';
import { useStyles } from './styles';

const Value = <TId,>(props: ValueProps<TId>) => {
    const { selected, testId, toggleSelected, option, toggleCollapse, isCollapsed, hasChildren } = props;
    const { id, icon, displayName } = option;

    const classes = useStyles();

    return (
        <>
            <ListItemIcon className={classes.iconContainer} data-testid={`option:${testId}:iconContainer`}>
                {hasChildren && isCollapsed !== undefined && toggleCollapse && (
                    <IconButton
                        onClick={() => toggleCollapse(id)}
                        disableRipple
                        className={classes.expand}
                        data-testid={`option:${testId}:expand`}
                    >
                        {isCollapsed ? (
                            <ChevronRight data-testid="chevron-icon" />
                        ) : (
                            <ExpandMore data-testid="expand-icon" />
                        )}
                    </IconButton>
                )}
                <Checkbox
                    onClick={toggleSelected}
                    className={classes.checkbox}
                    disableRipple
                    checked={selected}
                    data-testid={`option:${testId}:checkbox`}
                />
            </ListItemIcon>

            <ListItemIcon className={classes.iconContainer} data-testid={`option:${testId}:icon`}>
                {icon}
            </ListItemIcon>

            <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                data-testid={`option:${testId}:title`}
                className={classes.text}
            >
                {displayName}
            </ListItemText>
        </>
    );
};

Value.displayName = 'Value';
export { Value };
