import type { StaticDataStoreState } from '~/common';
import { getValueByPath } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import { AssetType } from '~/services/ApiClient';

import type { AssignedVehicle, DevicesAdministrationReadApp, ResolvedDevicesAdministrationReadApp } from './models';
import { transformDeviceHardware } from './utils';

interface ResolveAdministrationDeviceReadArgs {
    device: DevicesAdministrationReadApp;
    settingsState: SettingsStoreState;
    staticDataState: StaticDataStoreState;
}

export const resolveAdministrationDeviceRead = ({
    device,
    settingsState,
    staticDataState,
}: ResolveAdministrationDeviceReadArgs): ResolvedDevicesAdministrationReadApp => {
    const assignedVehicle: AssignedVehicle | undefined =
        device.assignedAsset?.type === AssetType.Vehicle
            ? getValueByPath(staticDataState, `vehicles.data.hash[${device.assignedAsset.id}]`)
            : undefined;

    return {
        ...device,
        deviceHardware: transformDeviceHardware(device?.deviceHardware),
        billingGroup: getValueByPath(settingsState, `billingGroup.data[${device?.billingGroup}]`),
        billingStatus: getValueByPath(settingsState, `billingStatus.data[${device?.billingStatus}]`),
        networkId: getValueByPath(settingsState, `network.data[${device?.networkId}]`),
        type: getValueByPath(settingsState, `deviceTypes.data[${device?.type}]`),
        vehicleCategory: getValueByPath(settingsState, `vehicleCategories.data[${assignedVehicle?.category}]`),
        activationStatus: getValueByPath(settingsState, `activationStatus.data[${device?.billingStatus}]`),
        navigation: getValueByPath(settingsState, `navigationType.data[${device.navigation}]`),
        assignedVehicle,
        assignedAssetRef: device.assignedAsset,
    };
};
