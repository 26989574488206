import React from 'react';
import { wrapDisplayName } from 'react-recompose';

import type { Language } from '~/data/language';

export interface EnsureCurrentLanguageDispatchProps {
    retrieveCurrentLanguage: () => void;
}

export interface EnsureCurrentLanguageStateProps {
    currentLanguage?: Language;
}

export interface EnsureCurrentLanguageExtendedProps
    extends EnsureCurrentLanguageStateProps,
        EnsureCurrentLanguageDispatchProps {}

export interface EnsureCurrentLanguageInnerProps extends EnsureCurrentLanguageExtendedProps {}

export const EnsureCurrentLanguageHoc = <P extends {}>(
    WrappedComponent: React.ComponentType<P>
): React.ComponentType<P & EnsureCurrentLanguageExtendedProps> => {
    type HocProps = P;

    type HocInnerProps = HocProps & EnsureCurrentLanguageExtendedProps;

    return class extends React.Component<HocInnerProps> {
        public static displayName = wrapDisplayName(WrappedComponent, 'EnsureCurrentLanguage');

        public componentDidMount() {
            if (!this.props.currentLanguage) {
                this.props.retrieveCurrentLanguage();
            }
        }

        public componentDidUpdate(prevProps: HocInnerProps) {
            if (!this.props.currentLanguage && prevProps.currentLanguage) {
                this.props.retrieveCurrentLanguage();
            }
        }

        public render() {
            const { currentLanguage, retrieveCurrentLanguage, ...restProps } = this.props;

            return <WrappedComponent {...(restProps as P)} />;
        }
    };
};
