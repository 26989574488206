import type { AssetGroup } from '~/services/ApiClient';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    ASSETGROUPS = 'ASSETGROUPS',
}

export enum ActionTypeKeys {
    ASSETGROUPS_PENDING = 'ASSETGROUPS_PENDING',
    ASSETGROUPS_FULFILLED = 'ASSETGROUPS_FULFILLED',
    ASSETGROUPS_REJECTED = 'ASSETGROUPS_REJECTED',
}

export type ActionTypes =
    | Pending<ActionTypeKeys.ASSETGROUPS_PENDING>
    | Fulfilled<ActionTypeKeys.ASSETGROUPS_FULFILLED, AssetGroup[]>
    | Rejected<ActionTypeKeys.ASSETGROUPS_REJECTED>;
