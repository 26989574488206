import { keyBy, mapValues, sortBy } from '~/libs/utility';

import type { ChartSeries, TimeSeriesChartDefinition } from '../../../../models';

import type { DataEntry } from './models';

export const mapDataEntries = <T>(chart: TimeSeriesChartDefinition<T>, items: T[]): DataEntry[] => {
    const valueAccessors = mapValues(keyBy(chart.series, 'name'), (s: ChartSeries<T>) => s.getValue);
    valueAccessors.dt = (item: T) => chart.getItemDate(item).getTime();

    const mapped = items.map((item) => mapValues(valueAccessors, (a) => a(item)) as DataEntry);

    return sortBy(mapped, 'dt');
};
