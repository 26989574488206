import type { ActionMeta } from '~/types';

export enum ActionTypeKeys {
    SCENELIST_CHANGESEARCHQUERY = 'SCENELIST_CHANGESEARCHQUERY',
    SCENELIST_CLEARSEARCHQUERY = 'SCENELIST_CLEARSEARCHQUERY',
}

export type ActionTypes =
    | ActionMeta<ActionTypeKeys.SCENELIST_CHANGESEARCHQUERY, string, string>
    | ActionMeta<ActionTypeKeys.SCENELIST_CLEARSEARCHQUERY, undefined, string>;
