import type { Dispatch } from 'redux';

import { openUnseenCarouselAction } from '~/components/IntroCarousel';
import type { Credentials } from '~/data/authentication';
import { loginAction } from '~/data/authentication';
import { reportError } from '~/reporting';

import type { LoginStoreState } from '../../reducers';

import type { CustomerCareLoginFormDispatchProps, CustomerCareLoginFormStateProps } from './component';

export const mapStateToProps = (state: LoginStoreState): CustomerCareLoginFormStateProps => {
    return {
        authenticating: state.loading,
        authenticationError: state.authenticationFailure,
        passwordExpired: state.passwordExpired,
        requestFailed: state.requestFailed,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): CustomerCareLoginFormDispatchProps => {
    return {
        authenticate: (credentials: Credentials) => {
            dispatch(loginAction(credentials)).catch(reportError);
            dispatch(openUnseenCarouselAction());
        },
    };
};
