import type { NumericDictionary } from '~/libs/utility';
import type {
    Classification,
    DeviceType,
    Driver,
    DriverActivityType,
    DriverEvent,
    DriverEventType,
    DriverSubActivityType,
    Trailer,
    TrailerEvent,
    Vehicle,
    VehicleCategory,
    VehicleEvent,
    VehicleType,
} from '~/services/ApiClient';

import type { HistoryAssetEntry } from './models';
import { HistoryEventType } from './models';
import { buildHistoryDriverEntry } from './redux.buildHistoryDriverEntry';
import { buildHistoryTrailerEntry } from './redux.buildHistoryTrailerEntry';
import { buildHistoryVehicleEntry } from './redux.buildHistoryVehicleEntry';

export const buildSelectedHistoryEntry = (
    perspective: HistoryEventType,
    selectedEntryId: number | undefined,
    vehiclesHash: NumericDictionary<Vehicle>,
    trailersHash: NumericDictionary<Trailer>,
    driversHash: NumericDictionary<Driver>,
    vehicleEvents: VehicleEvent[],
    trailerEvents: TrailerEvent[],
    driverEvents: DriverEvent[],
    deviceTypes: NumericDictionary<DeviceType>,
    vehicleCategories: NumericDictionary<VehicleCategory>,
    vehicleTypes: NumericDictionary<VehicleType>,
    reeferStatus: NumericDictionary<Classification>,
    reeferAlarm: NumericDictionary<Classification>,
    reeferOperationMode: NumericDictionary<Classification>,
    reeferPowerMode: NumericDictionary<Classification>,
    reeferSpeed: NumericDictionary<Classification>,
    hookedStatus: NumericDictionary<Classification>,
    compartmentStatus: NumericDictionary<Classification>,
    doorStatus: NumericDictionary<Classification>,
    trailerBatteryStatus: NumericDictionary<Classification>,
    trailerEventType: NumericDictionary<Classification>,
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>,
    driverEventTypes: NumericDictionary<DriverEventType>
): HistoryAssetEntry | undefined => {
    if (!selectedEntryId) {
        return undefined;
    }

    switch (perspective) {
        case HistoryEventType.VEHICLE: {
            const selectedVehicleEvent = vehicleEvents.find((event) => event.eventPosId === selectedEntryId);
            return (
                selectedVehicleEvent &&
                buildHistoryVehicleEntry(
                    vehiclesHash,
                    selectedVehicleEvent,
                    deviceTypes,
                    vehicleCategories,
                    vehicleTypes
                )
            );
        }
        case HistoryEventType.TRAILER: {
            const selectedVehicleEvent = trailerEvents.find((event) => event.trailerEventId === selectedEntryId);
            return (
                selectedVehicleEvent &&
                buildHistoryTrailerEntry(
                    trailersHash,
                    selectedVehicleEvent,
                    deviceTypes,
                    reeferStatus,
                    reeferAlarm,
                    reeferOperationMode,
                    reeferPowerMode,
                    reeferSpeed,
                    hookedStatus,
                    compartmentStatus,
                    doorStatus,
                    trailerBatteryStatus,
                    trailerEventType
                )
            );
        }
        case HistoryEventType.DRIVER: {
            const selectedVehicleEvent = driverEvents.find((event) => event.eventId === selectedEntryId);
            return (
                selectedVehicleEvent &&
                buildHistoryDriverEntry(
                    driversHash,
                    selectedVehicleEvent,
                    driverActivityTypes,
                    driverSubActivityTypes,
                    driverEventTypes
                )
            );
        }
        default:
            throw Error(`Unknown perspective: ${perspective}`);
    }
};
