import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationHeaderClassKey = 'root' | 'paneTitle';

export const ConversationHeaderStyleRules: StyleRulesCallback<Theme, {}, ConversationHeaderClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
        },
        paneTitle: {
            marginRight: theme.spacing(),
            lineHeight: 'unset',
        },
    });
