import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const DisconnectIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            {/* tslint:disable:max-line-length */}
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M5 2c0-0.6-0.4-1-1-1S3 1.4 3 2l0 2H1v6h6V4H5L5 2 M9 18c0 1.3 0.8 2.4 2 2.8V23h2v-2.2c1.2-0.4 2-1.5 2-2.8v-2H9V18 M1 18
    c0 1.3 0.8 2.4 2 2.8V23h2v-2.2c1.2-0.4 2-1.5 2-2.8v-2H1V18 M21 4l0-2.1c0-0.6-0.4-1-1-1s-1 0.4-1 1L19 4h-2v6h6V4H21 M13 1.9
    c0-0.6-0.4-1-1-1s-1 0.4-1 1L11 4H9v6h6V4h-2L13 1.9 M17 18c0 1.3 0.8 2.4 2 2.8V23h2v-2.2c1.2-0.4 2-1.5 2-2.8v-2h-6V18z"
            />
        </SvgIcon>
    );
};
DisconnectIcon.displayName = 'DisconnectIcon';

export { DisconnectIcon };
