import { Fade, Link, Snackbar, SnackbarContent } from '@mui/material';
import type { SnackbarOrigin } from '@mui/material/Snackbar';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';

import { useTranslation } from '~/components/LanguageSelector';

import { useStyles } from './styles';

export interface NotFoundPageSnackbarProps {}

export interface NotFoundPageSnackbarInnerProps extends NotFoundPageSnackbarProps {}

const NotFoundPageSnackbar: React.FunctionComponent<NotFoundPageSnackbarInnerProps> = () => {
    const [open, setOpen] = React.useState(true);
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const anchorOrigin: SnackbarOrigin = { vertical: 'top', horizontal: 'center' };
    const transitionDuration = { appear: 400, exit: 2000 };
    const notFoundPageMessage = t('invalid-page-message');

    const messageLink = (
        <Link href="/" className={classes.link} underline="always">
            {t('go-to-landing-page')}
        </Link>
    );

    const autoHideDuration = Math.min(Math.max(notFoundPageMessage.length * 50, 2000), 7000);

    const handleClose = React.useCallback(() => {
        setOpen(false);
        history.push('/');
    }, [history]);

    const notFoundPageElement = <Trans i18nKey="invalid-page-message">{messageLink}</Trans>;
    return (
        <Snackbar
            key="NotFoundPageSnackbar"
            data-id="not-found-page-snackbar"
            open={open}
            anchorOrigin={anchorOrigin}
            TransitionComponent={Fade}
            autoHideDuration={autoHideDuration}
            transitionDuration={transitionDuration}
            onClose={handleClose}
        >
            <SnackbarContent data-id="snackbar-content" className={classes.content} message={notFoundPageElement} />
        </Snackbar>
    );
};
NotFoundPageSnackbar.displayName = 'NotFoundPageSnackbar';

export { NotFoundPageSnackbar };
