import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const ClosedDoorIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            {/* tslint:disable:max-line-length */}
            <path d="M4.2 2.3v15.3h15.6V2.3H4.2z M11.4 11c0 0.3-0.2 0.5-0.5 0.5h-0.1c-0.3 0-0.5-0.2-0.5-0.5v-0.7c0-0.3 0.2-0.5 0.5-0.5h0.1 c0.3 0 0.5 0.2 0.5 0.5V11z M13.6 11c0 0.3-0.2 0.5-0.5 0.5h-0.1c-0.3 0-0.5-0.2-0.5-0.5v-0.7c0-0.3 0.2-0.5 0.5-0.5h0.1 c0.3 0 0.5 0.2 0.5 0.5V11z" />
            <path d="M4.2 20.4h0.2v2.9c0 0.4 0.4 0.8 0.8 0.8h0.9c0.4 0 0.8-0.4 0.8-0.8v-2.9c0-0.5 0.6-0.5 0.6 0v1.2h9v-1.2 c0-0.5 0.6-0.5 0.6 0v2.9c0 0.4 0.4 0.8 0.8 0.8h0.9c0.4 0 0.8-0.4 0.8-0.8v-2.9h0.2v-2H4.2V20.4z" />
        </SvgIcon>
    );
};
ClosedDoorIcon.displayName = 'ClosedDoorIcon';

export { ClosedDoorIcon };
