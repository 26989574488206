import type { AnyAction, Middleware } from 'redux';
import { createAction } from 'redux-actions';
import type { AsyncAction } from 'redux-promise-middleware';

class CanceledException extends Error {
    static isCanceledException(obj: unknown): obj is CanceledException {
        return obj instanceof CanceledException;
    }
}

/**
 * This action creator will create an AsyncAction, and will make sure the previous action created by this creator will be canceled using the AbortController.
 * This prevents the action from running multiple times in parallel.
 *
 * When using in conjunction with redux-promise-middleware:
 * When the action gets canceled, it will reject the promise and dispatch the _REJECTED action (can be prevented by applying the cancelableActionMiddleware).
 * Regarding the order of dispatched actions, the new _PENDING action is dispatched BEFORE the _REJECTED of the previous action.
 */
const createCancelableAction = <TPayload, TArg1>(
    actionType: string,
    payloadCreator: (args: TArg1, signal: AbortSignal) => Promise<TPayload>
) => {
    let controller: AbortController;
    return createAction<Promise<TPayload>, TArg1>(actionType, async (args) => {
        controller?.abort();
        controller = new AbortController();

        const currentController = controller;
        try {
            return await payloadCreator(args, controller.signal);
        } catch (err) {
            if (currentController.signal.aborted) {
                throw new CanceledException('ActionCanceled');
            }

            throw err;
        }
    });
};

type CancelableActionType = AnyAction | AsyncAction;

/**
 * This redux middleware prevents canceled actions (caused by createCancelableAction) to bubble to the reducer
 */
const cancelableActionMiddleware: Middleware = () => (next) => (action: CancelableActionType) => {
    if ('payload' in action && CanceledException.isCanceledException(action.payload)) {
        // Discarding action as it's cancelled
        return undefined;
    } else {
        return next(action);
    }
};

export { createCancelableAction, CanceledException, cancelableActionMiddleware };
