import { isUndefined } from '~/libs/utility';

import { getCarousel } from './carousel';

export interface IntroCarousel {
    revision: number;
    slides: IntroCarouselSlide[];
    autoPlaySpeed?: number;
}

export interface IntroCarouselSlide {
    title: string;
    subtitle: string;
    imageSource: string;
    customUrl?: string;
}

export const retrieveLatestIntroCarousel = async (language: string): Promise<IntroCarousel> => {
    const carousel = getCarousel();
    const defaultCarousel = carousel.fallback;
    const specificCarousel = carousel.languages[language];

    return {
        revision: carousel.revision,
        autoPlaySpeed: carousel.autoPlaySpeed,
        slides: Object.keys(defaultCarousel.slides).map((slideKey) => {
            const specificSlide = specificCarousel && specificCarousel.slides[slideKey];
            const fallbackSlide = defaultCarousel.slides[slideKey];

            return {
                title: specificSlide && !isUndefined(specificSlide.title) ? specificSlide.title : fallbackSlide.title,
                subtitle:
                    specificSlide && !isUndefined(specificSlide.subtitle)
                        ? specificSlide.subtitle
                        : fallbackSlide.subtitle,
                imageSource: carousel.assets[slideKey],
                customUrl: carousel.customUrl?.[slideKey],
            };
        }),
    };
};
