import { isUndefined } from '~/libs/utility';
import type { Depot } from '~/services/ApiClient';

export const getVehicleDepot = (vehicleId: number, depots: Depot[]): string | undefined => {
    const vehicleDepot = depots.find((depot) => {
        if (isUndefined(depot.vehicleIds)) {
            return false;
        }

        return depot.vehicleIds.includes(vehicleId);
    });

    return vehicleDepot && vehicleDepot.name;
};
