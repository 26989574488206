import React, { useEffect } from 'react';

import { setScreen } from '~/services/Analytics';

export const withScreenAnalytics =
    <BaseProps extends {}>(screen: string | ((p: BaseProps) => string)) =>
    (BaseComponent: React.ComponentType<BaseProps>): React.ComponentType<BaseProps> => {
        const TheBaseComponent = BaseComponent as React.ComponentType<{}>;

        const Hoc = (props: BaseProps) => {
            const screenName = typeof screen === 'function' ? screen(props) : screen;
            useEffect(() => {
                setScreen(screenName);
            }, [screenName]);

            return <TheBaseComponent {...props} />;
        };
        Hoc.displayName = `withScreenAnalytics(${BaseComponent.name})`;

        return Hoc;
    };
