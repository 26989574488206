import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type FilterSheetClassKey = 'root' | 'header' | 'title' | 'close' | 'content';

export const FilterSheetStyleRules: StyleRulesCallback<Theme, {}, FilterSheetClassKey> = (theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(0.5),
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
        },
        close: {
            color: theme.palette.text.secondary,
        },
        content: {
            overflow: 'auto',
            padding: theme.spacing(1.5, 2),
        },
    });
