/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ensureAuthorizationSelector } from '~/components/EnsureAuthorization/selectors';
import { useLanguageCode } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { get } from '~/libs/utility';
import { translatedMessagesStoreStateSelector } from '~/modules/Communication/components/ConversationWidgets/components/ConversationWidget/selectors';
import type { TranslatedMessageState } from '~/modules/Communication/components/ConversationWidgets/reducers';
import { translateConversationMessageAction } from '~/modules/Communication/data/actions';
import type { ResolvedMessage } from '~/services/ApiClient';

import { isTranslatableMessageType } from '../../utils';
import { ConversationMessage } from '../ConversationMessage';

import { Frame } from './styles';

export interface MessageDetailsSectionProps {
    message: ResolvedMessage;
    title: string;
    dataId: string;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
    hideCollapsing: boolean;
    headerOverContent: boolean;
}

export interface MessageDetailsSectionInnerProps extends MessageDetailsSectionProps {}

const MessageDetailsSectionComponent: React.FC<MessageDetailsSectionInnerProps> = ({
    dataId,
    message,
    title,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
    hideCollapsing,
    headerOverContent,
}) => {
    const translatedMessages = useSelector(translatedMessagesStoreStateSelector);
    const { securables } = useSelector(ensureAuthorizationSelector);
    const currentLanguageCode = useLanguageCode();
    const dispatch = useDispatch();
    const { vehicleId, id: messageId } = message.value;
    const translatedMessage = get(translatedMessages, `${vehicleId}.${messageId}`) as unknown as TranslatedMessageState;

    useEffect(() => {
        if (isTranslatableMessageType(message) && !translatedMessage && securables.autotranslate.isAllowed) {
            dispatch(translateConversationMessageAction(message, currentLanguageCode));
        }
    }, [messageId]);

    return (
        <ReorderableSection
            title={title}
            dataId={dataId}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            hideCollapsing={hideCollapsing}
            headerOverContent={headerOverContent}
        >
            <Frame>
                <ConversationMessage
                    message={message}
                    firstMessageOfGroup
                    canUpdateReadStatus={false}
                    updatingReadStatus={false}
                    hideDropdownMenu
                    canDeleteMessages={false}
                    updatingDeleteStatus={false}
                    translatedMessageValue={translatedMessage}
                />
            </Frame>
        </ReorderableSection>
    );
};

MessageDetailsSectionComponent.displayName = 'MessageDetailsSection';
export { MessageDetailsSectionComponent };
