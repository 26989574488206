import saveAs from 'file-saver';
import JSZip from 'jszip';

import type { DocumentViewerFileWithContent } from './models';

export const saveFiles = async (files: DocumentViewerFileWithContent[], zipFileName?: string): Promise<void> => {
    if (files.length === 0) {
        return;
    }

    const zip = new JSZip();

    files.forEach((file) => {
        zip.file(file.file.filename, file.contents, { base64: true });
    });

    const zipContent = await zip.generateAsync({ type: 'blob' });

    saveAs(zipContent, zipFileName);
};
