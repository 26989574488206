import makeStyles from '@mui/styles/makeStyles';

export type ChangelogDateEntityClassKey = keyof ReturnType<typeof useStyles>;

export const useStyles = makeStyles((theme) => ({
    date: {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.grey.A100,
        borderCollapse: 'collapse',
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));
