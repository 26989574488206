import { omit } from '~/libs/utility';
import type { BaseAction } from '~/types';

import { actions } from './actionTypes';
import type { ActionTypes, NotificationsState } from './models';

const initialState: NotificationsState = {};

const notificationReducer = (state = initialState, action: ActionTypes | BaseAction<''>): NotificationsState => {
    switch (action.type) {
        case actions.PENDING:
            return { ...state, [action.payload.id]: action.payload };

        case actions.REJECTED:
            return { ...state, [action.payload.id]: { ...action.payload, form: state[action.payload.id].form } };

        case actions.CLEAR: {
            return omit(state, action.payload.id);
        }

        default:
            return state;
    }
};

export { notificationReducer };
