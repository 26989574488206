import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { InhibitorActionDialogInnerProps, InhibitorActionDialogProps } from './component';
import { InhibitorActionDialogComponent } from './component';

export { InhibitorAction } from './component';

export const InhibitorActionDialog = compose<InhibitorActionDialogInnerProps, InhibitorActionDialogProps>(
    setDisplayName('InhibitorActionDialog'),
    withDisplayPreferences(),
    withTranslation()
)(InhibitorActionDialogComponent);
