const directions = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
    'N',
];

export const degreeToCardinal = (direction: number): string => {
    const directionIndex = Math.round((direction % 360) / 22.5);
    return directions[directionIndex];
};
