import type { FC } from 'react';

import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { ResolvedDriverStatus } from '~/data/monitoring';

import { DriverSubpageHeader } from './components/Header';
import { getSectionsMemoized } from './components/Sections/sections';
import type { SectionName } from './consts';
import type { DriverSubpageProps } from './models';
import type { DriverPerspectiveSubpageUserPreferences } from './preferences';
import { DRIVERSUBPAGE_PREFERENCES_KEY } from './preferences';

const DetailsPaneComponent = SceneDetailsPaneFactory<
    ResolvedDriverStatus,
    DriverPerspectiveSubpageUserPreferences,
    SectionName
>(DRIVERSUBPAGE_PREFERENCES_KEY);

const DriverSubpage: FC<DriverSubpageProps> = (props) => {
    const { driverStatus } = props;

    const header = () => <DriverSubpageHeader driverStatus={driverStatus as ResolvedDriverStatus} />;

    const getSections = (
        userPreferences: DriverPerspectiveSubpageUserPreferences,
        toggleCollapsed: (sectionName: SectionName) => () => void
    ) => getSectionsMemoized({ collapsedSections: userPreferences.collapsedSections, driverStatus, toggleCollapsed });

    return (
        <DetailsPaneComponent dataId="driver-subpage" entry={driverStatus} getSections={getSections} Header={header} />
    );
};

DriverSubpage.displayName = 'DriverSubpage';
export default DriverSubpage;
