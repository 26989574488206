import { Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import moment from 'moment';
import * as React from 'react';

import { AddressFormatter, DateTimeFormatter, DurationFormatter, TimeFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { PositionGroup } from '~/services/ApiClient';
import { AddressFormat } from '~/services/Formatters';

import type { StopPositionGroupClassKey } from './styles';

export interface StopPositionGroupProps {
    positionGroup: PositionGroup;
    toggleElement?: React.ReactNode;
}

export interface StopPositionGroupInnerProps
    extends StopPositionGroupProps,
        InjectedTranslationProps,
        WithStyles<StopPositionGroupClassKey> {}

export const StopPositionGroupComponent: React.FC<StopPositionGroupInnerProps> = ({
    classes,
    positionGroup,
    toggleElement,
}) => {
    const valuePrefix = !positionGroup.start ? '> ' : '';

    const primaryLeft = (
        <span data-id="address-line">
            <AddressFormatter
                value={positionGroup.current?.address ?? positionGroup.stop?.address}
                options={{ format: AddressFormat.FirstLine }}
            />
        </span>
    );
    const secondaryLeft = (
        <span data-id="address-secondary-line">
            <AddressFormatter
                value={positionGroup.current?.address ?? positionGroup.stop?.address}
                options={{ format: AddressFormat.SecondLine }}
            />
        </span>
    );

    const periodTimeTitle = (
        <>
            <DateTimeFormatter value={positionGroup.start?.dateTime} />
            {' - '}
            <DateTimeFormatter value={positionGroup.stop?.dateTime ?? positionGroup.current?.dateTime} />
        </>
    );

    const primaryRight = (
        <Tooltip title={periodTimeTitle}>
            <span data-id="period" className={classes.nowrap}>
                <TimeFormatter value={positionGroup.start?.dateTime} />
                {' - '}
                <TimeFormatter value={positionGroup.stop?.dateTime ?? positionGroup.current?.dateTime} />
            </span>
        </Tooltip>
    );
    const secondaryRight = (
        <span data-id="duration" className={classes.nowrap}>
            {valuePrefix}
            <DurationFormatter value={moment.duration(positionGroup.duration)} />
        </span>
    );

    return (
        <>
            <div className={classes.splitRow}>
                <Typography variant="body2" component="div" className={classes.leftColumn}>
                    {primaryLeft}
                    <Typography variant="caption" color="textSecondary">
                        {secondaryLeft}
                    </Typography>
                </Typography>
                <Typography variant="caption" className={classes.rightColumn}>
                    {primaryRight}
                    <Typography variant="caption" color="textSecondary">
                        {secondaryRight}
                    </Typography>
                </Typography>
            </div>
            {toggleElement && (
                <Typography color="textSecondary" className={classes.splitRow}>
                    <span />
                    {toggleElement}
                </Typography>
            )}
        </>
    );
};
