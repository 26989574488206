import MoreVert from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import type { FC, MouseEventHandler } from 'react';
import { useRef, useState } from 'react';

import { useIntersectionObserver } from '../../hooks';
import { ContextMenu } from '../ContextMenu';

import type { ActionButtonGroupProps } from './models';
import { useStyles } from './styles';

const ActionButtonGroup: FC<ActionButtonGroupProps> = (props) => {
    const { items, testId, disablePadding } = props;

    const classes = useStyles();
    const [menuAnchor, setMenuAnchor] = useState<Element>();
    const containerRef = useRef<Element>(null);
    const { visibleItems, hiddenItems, register } = useIntersectionObserver({ root: containerRef, threshold: 1 });

    const handleMenuIconClick: MouseEventHandler = (event) => {
        setMenuAnchor(event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    };

    const buttons = items.map((item) => {
        const button = (
            <Button
                color="secondary"
                disabled={item.disabled}
                startIcon={item.icon}
                data-testid={item.key}
                onClick={item.onClick}
                key={`${item.key}:button`}
                ref={register(item.key)}
                className={classNames({ [classes.hidden]: !visibleItems.includes(item.key) })}
            >
                {item.title}
            </Button>
        );

        return item.tooltip ? (
            <Tooltip title={item.tooltip} placement="bottom" data-id={`${item.key}:tooltip`} key={item.key}>
                <span>{button}</span>
            </Tooltip>
        ) : (
            button
        );
    });
    const contextMenuItems = items.filter((item) => hiddenItems.includes(item.key));

    return (
        <>
            <Box padding={disablePadding ? 0 : 1} data-testid={testId} display="flex">
                <Box overflow="hidden" display="flex" ref={containerRef} flex={1}>
                    {buttons}
                </Box>
                {hiddenItems.length > 0 && (
                    <IconButton data-testid="more-action-buttons" onClick={handleMenuIconClick} size="small">
                        <MoreVert />
                    </IconButton>
                )}
            </Box>
            {menuAnchor && (
                <ContextMenu items={contextMenuItems} onClose={() => setMenuAnchor(undefined)} anchorEl={menuAnchor} />
            )}
        </>
    );
};

ActionButtonGroup.displayName = 'ActionButtonGroup';
export { ActionButtonGroup };
