import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';
import { useCallback, useState } from 'react';

import type { SensitiveDataClassKeys } from './styles';

export interface SensitiveDataProps {
    value: string;
}

export interface SensitiveDataInnerProps extends SensitiveDataProps, WithStyles<SensitiveDataClassKeys> {}

export const SensitiveDataComponent: FC<SensitiveDataInnerProps> = ({ value, classes }) => {
    const asterisks = '******';
    const [visible, setVisible] = useState(false);
    const shownItem = visible ? value : asterisks;
    const changeVisibility = useCallback(() => {
        setVisible(!visible);
    }, [setVisible, visible]);
    const renderVisible = (
        <VisibilityOff
            fontSize="inherit"
            className={classes.visibilityIcon}
            data-id="visible-off-value"
            onClick={changeVisibility}
        />
    );

    const renderHidden = (
        <Visibility
            fontSize="inherit"
            className={classes.visibilityIcon}
            data-id="visible-value"
            onClick={changeVisibility}
        />
    );

    return (
        <div className={classes.positioning}>
            <span data-id="value">{shownItem}</span>
            {visible ? renderVisible : renderHidden}
        </div>
    );
};
