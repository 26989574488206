import type { NumericDictionary } from '~/libs/utility';
import type {
    Driver,
    DriverActivityType,
    DriverEvent,
    DriverEventType,
    DriverSubActivityType,
} from '~/services/ApiClient';

import type { HistoryDriverEntry } from './models';
import { driverEventResolver } from './redux.driverEventResolver';

export const buildHistoryDriverEntry = (
    driversHash: NumericDictionary<Driver>,
    driverEvent: DriverEvent,
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>,
    driverEventTypes: NumericDictionary<DriverEventType>
): HistoryDriverEntry => {
    const driver = driverEvent.driver ? driversHash[driverEvent.driver.id] : undefined;

    return {
        driver,
        driverEvent: driverEventResolver(driverEvent, driverActivityTypes, driverSubActivityTypes, driverEventTypes),
    };
};
