import type { FC } from 'react';
import { useCallback } from 'react';

import { useTranslation } from '~/components/LanguageSelector';
import PasswordStrengthBar from '~/libs/react-password-strength-bar';

import type { PasswordStrengthIndicatorProps } from './models';
import { PasswordScore } from './models';

const PasswordStrengthIndicator: FC<PasswordStrengthIndicatorProps> = (props) => {
    const { password, onChangeScore, minLength, className } = props;

    const { t } = useTranslation();
    const onChangeScoreCallback = useCallback(
        (numberScore: number) => {
            // Override [TooShort] score given to passwords long enough made of same-character repetition or number sequences with [Weak]
            if (password && minLength && numberScore === PasswordScore.TooShort && password.length >= minLength) {
                onChangeScore(PasswordScore.Weak);
            }
            // Override [TooShort] score when there's no minimum length with [Weak]
            else if (password && !minLength && numberScore === PasswordScore.TooShort) {
                onChangeScore(PasswordScore.Weak);
            } else {
                onChangeScore(numberScore);
            }
        },
        [password, minLength, onChangeScore]
    );

    const scoreWords = [
        t('password-score-word-very-weak'),
        t('password-score-word-weak'),
        t('password-score-word-ok'),
        t('password-score-word-good'),
        t('password-score-word-strong'),
    ];

    return (
        <PasswordStrengthBar
            password={password as string}
            minLength={minLength ?? 0}
            onChangeScore={onChangeScoreCallback}
            shortScoreWord={t('password-too-short')}
            scoreWords={scoreWords}
            className={className}
        />
    );
};

PasswordStrengthIndicator.displayName = 'PasswordStrengthIndicator';
export default PasswordStrengthIndicator;
