import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { RetryLoadInnerProps, RetryLoadProps } from './component';
import { RetryLoadComponent } from './component';
import { RetryLoadStyleRules } from './styles';

export const RetryLoad = compose<RetryLoadInnerProps, RetryLoadProps>(
    setDisplayName('RetryLoad'),
    withStyles(RetryLoadStyleRules)
)(RetryLoadComponent);
