import { Dialog as MUIDialog } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import type { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import type { DialogProps } from './models';

const Dialog: FC<DialogProps> = (props) => {
    const { title, confirmationActionText, onClose, onConfirm, children, isDisabled, testId = 'dialog' } = props;

    const { t } = useTranslation();

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <MUIDialog open onClick={handleClick} data-testid={testId}>
            <DialogTitle data-testid={`${testId}-title`}>{title}</DialogTitle>
            <DialogContent data-testid={`${testId}-content`}>{children}</DialogContent>
            <DialogActions data-testid={`${testId}-footer`}>
                <Button onClick={onClose} color="secondary" data-testid={`${testId}-cancel`}>
                    {t('cancel')}
                </Button>
                <Button onClick={onConfirm} color="secondary" data-testid={`${testId}-confirm`} disabled={isDisabled}>
                    {confirmationActionText || t('ok')}
                </Button>
            </DialogActions>
        </MUIDialog>
    );
};

Dialog.displayName = 'Dialog';
export { Dialog };
