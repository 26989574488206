import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DriveSectionInnerProps, DriveSectionProps } from './component';
import { DriveSectionComponent } from './component';

export const DriveSection = compose<DriveSectionInnerProps, DriveSectionProps>(
    setDisplayName('DriveSection'),
    withTranslation()
)(DriveSectionComponent);
