import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ConversationWorkflowBodyInnerProps, ConversationWorkflowBodyProps } from './component';
import { ConversationWorkflowBodyComponent } from './component';
import { ConversationWorkflowBodyStyleRules } from './styles';

export const ConversationWorkflowBody = compose<ConversationWorkflowBodyInnerProps, ConversationWorkflowBodyProps>(
    setDisplayName('ConversationWorkflowBody'),
    withStyles(ConversationWorkflowBodyStyleRules),
    withTranslation()
)(ConversationWorkflowBodyComponent);
