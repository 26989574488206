import { createAction } from 'redux-actions';

import { createCancelableAction } from '~/common';
import type { AssetType, DriverEventFieldValuesResponse } from '~/services/ApiClient';
import { ApiClient, resolveWhenStatusCodeIn, retryableRequest } from '~/services/ApiClient';

import type { DriverEventFieldValueMeta, DriverEventsPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

interface RetrieveDriverEventsArgs {
    assetId: number;
    assetType: AssetType;
    startDate: Date;
    stopDate: Date;
}

export const retrieveDriverEventsAction = createCancelableAction<DriverEventsPayload, RetrieveDriverEventsArgs>(
    ActionTypePrefixes.DRIVEREVENT,
    ({ assetId, assetType, startDate, stopDate }, signal) =>
        retryableRequest(() => ApiClient.getDriverEvents(assetId, assetType, startDate, stopDate, signal))
);

export const retrieveDriverEventFieldValuesExecutor = (eventId: number): Promise<DriverEventFieldValuesResponse> =>
    retryableRequest(() => ApiClient.getDriverEventFieldValues(eventId).catch(resolveWhenStatusCodeIn(404)));

export const retrieveDriverEventFieldValuesAction = createAction<
    Promise<DriverEventFieldValuesResponse>,
    DriverEventFieldValueMeta,
    number,
    number | undefined,
    number | undefined
>(
    ActionTypePrefixes.DRIVEREVENTFIELDVALUES,
    retrieveDriverEventFieldValuesExecutor,
    (eventId: number, eventTypeId?: number, subActivityTypeId?: number) => ({
        eventId,
        eventTypeId,
        subActivityTypeId,
    })
);
