import { Header, List } from './components';
import { TreeProvider } from './context';
import type { TreeProps } from './models';
import { Container } from './styles';

const Tree = <T,>(props: TreeProps<T>) => {
    const { width, ...rest } = props;

    const { readOnly = false } = rest.state;
    return (
        <TreeProvider {...rest}>
            <Container width={width}>
                {!readOnly && <Header />}
                <List />
            </Container>
        </TreeProvider>
    );
};

Tree.displayName = 'Tree';
export { Tree };
