import { logEvent } from '~/services/Analytics';

import { ColumnName } from '../../constants';

const rtdsColumnsToLog = [ColumnName.MSISDN, ColumnName.DRIVERID];

export const logEventWhenEnablingColumns = (columns: ColumnName[]): void => {
    columns.forEach((c) => {
        if (rtdsColumnsToLog.includes(c)) {
            logEvent('rtds-sessions', `enable-${c}-column`, `Enabling ${c.replace(/-/g, ' ')} column`);
        }
    });
};
