import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const OdometerIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M0 0H24V24H0Z" fill="none" />
            <path d="M22.15 18.1H22V5.9h.14ZM20 9.49v5a.84.84 0 0 1-.84.84H16.65a.84.84 0 0 1-.81-.82v-5a.84.84 0 0 1 .82-.84h2.53A.84.84 0 0 1 20 9.49Zm-1.68.83h-.84v3.36h.84Zm-7.62 3.36h.84V10.32h-.84Zm-6 0h.84V10.32H4.68ZM14.09 18h8.07v.1H1.84V5.9h20.3V6H14.06ZM7.2 9.49a.84.84 0 0 0-.84-.84H3.84A.83.83 0 0 0 3 9.48H3v5a.84.84 0 0 0 .8.86H6.36a.84.84 0 0 0 .84-.84h0Zm6 0a.84.84 0 0 0-.84-.84H9.84A.83.83 0 0 0 9 9.48H9v5a.84.84 0 0 0 .8.86h2.55a.84.84 0 0 0 .84-.84h0Z" />
        </SvgIcon>
    );
};
OdometerIcon.displayName = 'OdometerIcon';

export { OdometerIcon };
