import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { Option } from '~/components/RemoteAutocomplete';
import type { Customer } from '~/services/ApiClient';

import type { CustomerOptionChildClassKey } from './styles';

export interface CustomerOptionChildProps {
    option: Option<Customer>;
}

export interface CustomerOptionChildInnerProps
    extends CustomerOptionChildProps,
        WithStyles<CustomerOptionChildClassKey> {}

export const CustomerOptionChildComponent: React.FC<CustomerOptionChildInnerProps> = (props) => {
    return (
        <div className={props.classes.root}>
            <span data-id="customer-name">{props.children}</span>
            <span data-id="customer-id">{props.option.data.id}</span>
        </div>
    );
};
