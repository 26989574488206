import { Tooltip } from '@mui/material';
import type { TooltipProps } from '@mui/material/Tooltip';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { useRef, useState } from 'react';

import type { TitledTruncatedTextClassKey } from './styles';

export interface TitledTruncatedTextProps {
    tooltipProps?: Pick<TooltipProps, Exclude<keyof TooltipProps, 'children' | 'title'>>;
    title?: React.ReactNode;
    className?: string;
    children: React.ReactNode;
}

export interface TitledTruncatedTextInnerProps
    extends TitledTruncatedTextProps,
        WithStyles<TitledTruncatedTextClassKey> {}

export const TitledTruncatedTextComponent: React.FC<TitledTruncatedTextInnerProps> = (props) => {
    const { classes, tooltipProps, title, className, children } = props;

    const ref = useRef<HTMLSpanElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        if (ref.current && !(ref.current.scrollWidth > ref.current.offsetWidth)) {
            return;
        }

        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Tooltip
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            {...tooltipProps}
            title={title || children || ''}
        >
            <span ref={ref} className={classNames(classes.text, className)}>
                {children}
            </span>
        </Tooltip>
    );
};
