import type { Dispatch } from 'redux';
import { v1 as uuidv1 } from 'uuid';

import type { InjectedUserDataProps } from '~/components/AuthenticationData';
import { reportError } from '~/reporting';
import type { SendMessageOptions, TextMessageBody } from '~/services/ApiClient';

import { changeConversationMessageTextFocusAction, sendConversationMessageAction } from '../../../../../../data';

import { buildOptimisticOutgoingConversationMessage } from './buildOptimisticOutgoingConversationMessage';
import type { SendMessageBoxProps } from './models';

export interface DispatchProps {
    sendMessage: (messageBody: TextMessageBody, options: SendMessageOptions) => void;
    clearInputFocus: () => void;
}

export interface SendMessageBoxReduxOwnProps extends SendMessageBoxProps, InjectedUserDataProps {}

export const mapDispatchToProps = (
    dispatch: Dispatch,
    { vehicleId, userName }: SendMessageBoxReduxOwnProps
): DispatchProps => ({
    sendMessage: (messageBody: TextMessageBody, options: SendMessageOptions) => {
        dispatch(
            sendConversationMessageAction(
                vehicleId,
                buildOptimisticOutgoingConversationMessage(uuidv1(), messageBody, userName, vehicleId),
                options
            )
        ).catch(reportError);
    },
    clearInputFocus: () => {
        dispatch(changeConversationMessageTextFocusAction(vehicleId, false));
    },
});
