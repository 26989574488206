import type { StoreState } from '~/reducers';
import { rtdsSessionsSceneStateSelector } from '~/selectors';

import type { RtdsSessionEntry } from './models';
import type { DynamicRtdsSessionsStoreState, RtdsSessionsStoreState } from './reducers';

export const rtdsSessionsStateSelector = (s: StoreState): RtdsSessionsStoreState =>
    rtdsSessionsSceneStateSelector(s).rtdsSessionsRoot;

export const dynamicRtdsSessionsStateSelector = (s: StoreState): DynamicRtdsSessionsStoreState => {
    return rtdsSessionsSceneStateSelector(s).rtdsSessionsRoot.dynamicRtdsSessions;
};

export const rtdsSessionEntriesSelector = (s: StoreState): RtdsSessionEntry[] => {
    return rtdsSessionsStateSelector(s).dynamicRtdsSessions.rtdsSessionsEntries;
};
