import type { DriverActivitiesReportStoreState, DriverActivityStoreState } from '~/data/monitoring';
import type { StoreState } from '~/reducers';

import { driverActivitiesStateSelector } from '../../selectors';

export const staffSectionStateSelector = (s: StoreState): DriverActivityStoreState =>
    driverActivitiesStateSelector(s).staffSection;

export const driverActivitiesReportStateSelector = (s: StoreState): DriverActivitiesReportStoreState =>
    driverActivitiesStateSelector(s).driverActivitiesReport;
