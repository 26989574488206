import { Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { AddressFormatter, CoordinatesFormatter, DateTimeFormatter, TimeFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { PositionStatus } from '~/services/ApiClient';
import { AddressFormat } from '~/services/Formatters';

import type { PositionStatusContentClassKey } from './styles';

export interface PositionStatusContentProps {
    positionStatus: PositionStatus;
}

export interface PositionStatusContentInnerProps
    extends PositionStatusContentProps,
        InjectedTranslationProps,
        WithStyles<PositionStatusContentClassKey> {}

export const PositionStatusContentComponent: React.FC<PositionStatusContentInnerProps> = ({
    classes,
    positionStatus,
    t,
}) => {
    const getAddressLine = () => {
        if (isUndefined(positionStatus.address)) {
            return (
                <CoordinatesFormatter
                    t={t}
                    value={positionStatus.position}
                    undefinedValueMessage={t('not-available')}
                />
            );
        }

        return (
            <span data-id="address-line">
                <AddressFormatter value={positionStatus.address} options={{ format: AddressFormat.FirstLine }} />
            </span>
        );
    };
    const timeWithTooltip = (
        <Tooltip title={<DateTimeFormatter value={positionStatus.dateTime} />}>
            <span data-id="current-date">
                <TimeFormatter value={positionStatus.dateTime} />
            </span>
        </Tooltip>
    );
    const addressSecondLine = (
        <span data-id="address-secondary-line">
            <AddressFormatter value={positionStatus.address} options={{ format: AddressFormat.SecondLine }} />
        </span>
    );

    const positionStatusContent = (
        <span data-id="position-status-content">
            <Typography variant="body2" className={classes.splitRow}>
                {getAddressLine()}
                <Typography variant="caption">{timeWithTooltip}</Typography>
            </Typography>
            <Typography variant="caption" color="textSecondary" className={classes.splitRow}>
                {addressSecondLine}
            </Typography>
        </span>
    );

    return positionStatusContent;
};
