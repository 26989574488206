import { orderBy } from '~/libs/utility';
import type { AssetReference, AssetType, Depot } from '~/services/ApiClient';

import type { AssetReferenceHash, AssetReferenceWithDisplayName, DepotNode } from '../models';
import { NodeTypes } from '../models';

import { getSingleDepotAssetIds } from './depots';
import { assetIdsToNodes } from './mapasset';
import { depotHasCheckedChildren, depotIsIndeterminate } from './predicates';

export const depotsToNodes = (
    assetTypes: AssetType[],
    depots: Depot[] | undefined,
    assetIds: AssetReferenceHash<AssetReference>,
    assetsHash: AssetReferenceHash<AssetReferenceWithDisplayName>,
    expandedAllDepots: boolean,
    expandedDepotIds: number[],
    selectedAssetIds: AssetReferenceHash<AssetReference>
): DepotNode[] => {
    if (depots === undefined) {
        return [];
    }
    const selectedAllAssets = !selectedAssetIds;

    return orderBy(
        depots.reduce((acc: DepotNode[], depot): DepotNode[] => {
            const depotNodes = assetIdsToNodes(
                assetTypes,
                getSingleDepotAssetIds(depot, assetIds),
                assetsHash,
                selectedAssetIds,
                undefined,
                depot.id
            );

            const expanded = expandedAllDepots || expandedDepotIds.includes(depot.id);

            const hasCheckedChildren = depotHasCheckedChildren(selectedAllAssets, depotNodes);

            const indeterminate = depotIsIndeterminate(selectedAllAssets, hasCheckedChildren, depotNodes);

            const hasAssetChildren = !!depotNodes.length;
            const checked = hasAssetChildren && hasCheckedChildren && !indeterminate;

            acc.push({
                id: depot.id,
                type: NodeTypes.DEPOT,
                name: depot.name,
                state: { expanded, checked, disabled: false, indeterminate, hasAssetChildren },
                children: [...depotNodes],
            });
            return acc;
        }, []),
        'name'
    );
};
