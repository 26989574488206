import type { SingleTFunction } from '~/components/LanguageSelector';
import type { DerivedClassificationType } from '~/services/ApiClient';

export const formatDerivedClassificationDisplayName = (
    t: SingleTFunction,
    derivedClassificationType: DerivedClassificationType,
    classificationKey: string
) => {
    if (!classificationKey) {
        return '';
    }

    return t(`classifications:${derivedClassificationType}-${classificationKey.toLocaleLowerCase()}`);
};
