import type { Duration } from 'moment';

import { isUndefined } from '~/libs/utility';
import type { ResolvedCompartmentStatus, ViolationField } from '~/services/ApiClient';
import { ViolationType as ApiContractViolationType } from '~/services/ApiClient';

import type { ViolationType } from '../Formatters/Duration/violations';
import { isViolation } from '../Formatters/Duration/violations';

enum DurationViolationGroupValue {
    NO_VALUE = 'NO_VALUE',
    NORMAL = 'NORMAL',
    WARNINGS = 'WARNINGS',
    VIOLATIONS = 'VIOLATIONS',
}

enum TemperatureSensorGroupValue {
    NO_VALUE = 'NO_VALUE',
    NORMAL = 'NORMAL',
    LOW = 'LOW',
    HIGH = 'HIGH',
}

export const violationGroupingCriteriaForDuration = (
    value?: Duration,
    threshold?: Duration,
    violationType?: ViolationType
): string => {
    if (isUndefined(value)) {
        return DurationViolationGroupValue.NO_VALUE;
    }

    if (!isUndefined(threshold) && !isUndefined(violationType) && isViolation(value, threshold, violationType)) {
        return DurationViolationGroupValue.VIOLATIONS;
    }

    return DurationViolationGroupValue.NORMAL;
};

export const violationGroupingCriteriaForViolationField =
    <T>() =>
    (value?: ViolationField<T>): string => {
        const currentValue = value?.value;

        if (isUndefined(value) || isUndefined(currentValue)) {
            return DurationViolationGroupValue.NO_VALUE;
        }

        if (value.violationType === ApiContractViolationType.Warning) {
            return DurationViolationGroupValue.WARNINGS;
        }

        if (value.violationType === ApiContractViolationType.Violation) {
            return DurationViolationGroupValue.VIOLATIONS;
        }

        return DurationViolationGroupValue.NORMAL;
    };

export const temperatureSensorGroupingCriteria = (value?: ResolvedCompartmentStatus, sensorNumber?: number): string => {
    const currentTemperature = value?.temperatureSensors[sensorNumber ?? '']?.value;

    if (isUndefined(currentTemperature)) {
        return TemperatureSensorGroupValue.NO_VALUE;
    }

    const maxTemperature = value?.maxTemperature;

    const minTemperature = value?.minTemperature;

    if (!isUndefined(maxTemperature) && currentTemperature > maxTemperature) {
        return TemperatureSensorGroupValue.HIGH;
    }

    if (!isUndefined(minTemperature) && currentTemperature < minTemperature) {
        return TemperatureSensorGroupValue.LOW;
    }

    return TemperatureSensorGroupValue.NORMAL;
};
