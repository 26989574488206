import type { GenericItemTree } from '@fv/components';
import cloneDeep from 'lodash/cloneDeep';

import type { Securable } from '~/services/ApiClient';

export function filterTreeByName<T extends GenericItemTree<Securable[]>[]>(
    tree: T,
    name: string = ''
): GenericItemTree<Securable[]>[] {
    const lowerCasedName = name.toLowerCase();
    const stack = cloneDeep(tree);
    const result = [];

    while (stack.length > 0) {
        const node = stack.pop();

        if (node && node.name !== undefined && node.name.toLowerCase().startsWith(lowerCasedName)) {
            result.push(node);
        }

        if (node && node.children?.length) {
            stack.push(...node.children);
        }
    }

    return result;
}
