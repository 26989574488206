import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { ContainerTemperaturesSectionInnerProps } from './component';

export type ContainerTemperaturesSectionClassKey = 'temperatureContainer';

export const ContainerTemperaturesSectionStyleRules: StyleRulesCallback<
    Theme,
    ContainerTemperaturesSectionInnerProps,
    ContainerTemperaturesSectionClassKey
> = () =>
    createStyles({
        temperatureContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
    });
