import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    inputAdornedEnd: { paddingRight: theme.spacing(1) },
    paper: { margin: theme.spacing(1, 0) },
    popperRef: { position: 'absolute', left: 18, zIndex: 99 },
    readOnlyRoot: { borderRadius: 4 },
    readOnlyUnderlineDisabled: { '&:before': { borderBottom: 0 } },
    messageIconInfo: { fontSize: 24, color: theme.palette.text.secondary, cursor: 'pointer' },
    readOnlyUnderline: {
        '&:before': { border: 'none' },
        '&:after': { border: 'none' },
        '&:hover:before': { border: 'none' },
        '&:disabled:before': { border: 'none' },
    },
}));

export { useStyles };
