import withStyles from '@mui/styles/withStyles';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withUserData } from '~/components/AuthenticationData';
import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { withFeatureFlags } from '~/components/FeatureFlags';
import { withTranslation } from '~/components/LanguageSelector';

import type { MenuInnerProps, MenuProps } from './component';
import { withMenuHoc } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { menuRootStateSelector } from './selectors';
import { MenuStyleRules } from './styles';

export * from './storestate';

/**
 * This HOC has its own injected props. To avoid
 * any collision with wrapped component own props
 * make sure you use it before withTranslation and
 * withStyles HOC and it doesn't require next props:
 * `openIntroCarousel`, `openMenuAction`,
 * `closeMenuAction`, `isOverlayMenuOpen`.
 */
export const withMenu = compose<MenuInnerProps, MenuProps>(
    withTranslation(),
    withFeatureFlags(),
    withStyles(MenuStyleRules, { withTheme: true }),
    ensureSettings([SettingsKey.SECURABLES]),
    withUserData(),
    connect(
        createSelector(menuRootStateSelector, settingDataSelector(SettingsKey.SECURABLES), mapStateToProps),
        mapDispatchToProps
    ),
    withMenuHoc
);
