import type { Dispatch } from 'redux';

import { retrieveUserPreferencesAction } from '~/data/userpreferences';
import { reportError } from '~/reporting';

import type { UserPreferencesState } from '../../reducers';

import type { DispatchProps, StateProps } from './component';

export const mapStateToProps = (userPreferencesState: UserPreferencesState): StateProps => ({
    userPreferencesState,
});

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        retrieveUserPreferences: (keys: string[]) => {
            dispatch(retrieveUserPreferencesAction(keys)).catch(reportError);
        },
    };
};
