import type { Classification } from '@fv/translations';

import type { ClassificationAbstraction } from '~/services/ApiClient';

interface Values {
    id: number;
    key: string;
    name: string;
}

interface MapEntryToClassification<T extends Values> {
    values: T[];
    displayName?: ((key: Classification) => string) | string;
}

const mapEntryToClassification = <T extends Values>(args: MapEntryToClassification<T>): ClassificationAbstraction[] => {
    const { values, displayName = 'name' } = args;

    return values.map((value) => ({
        id: value.id,
        key: value.key,
        displayName: typeof displayName === 'function' ? displayName(value) : value[displayName],
    }));
};

export { mapEntryToClassification };
