import type { TilesTypes } from '@fv/components';
import type { Dispatch } from 'redux';

import { updateUserPreferencesAction } from '~/data/userpreferences';
import { memoizeOne } from '~/services/Memoize';

import type { MapLayersSecurables, Securables } from '../../services/ApiClient/generated';
import type { DisplayUserPreferences } from '../DisplayPreferences';
import type { LanguageStoreState } from '../LanguageSelector/components/LanguageSelector';

import { getMapLayersProps } from './layers';
import type { MapProfile } from './mapProfile';
import type { MapViewMode } from './mapViewMode';
import type { MapUserPreferences } from './preferences';
import { MAP_USERPREFERENCES_KEY } from './preferences';

export interface MapStateProps {
    mapViewMode: MapViewMode;
    truckAttributesOverlayEnabled: boolean;
    trafficIncidentsOverlayEnabled: boolean;
    markerClusterRadius: number;
    followSelectedAsset: boolean;
    mapLayersProps: TilesTypes[];
    mapLayersSecurables: MapLayersSecurables;
}

export interface MapDispatchProps {
    changeMapViewMode: (mapViewMode: MapViewMode) => void;
    changeFollowSelectedAsset: (followSelectedAsset: boolean) => void;
    changeTruckAttributesOverlayEnabled: (truckAttributesOverlayEnabled: boolean) => void;
    changeTrafficIncidentsOverlayEnabled: (trafficIncidentsOverlayEnabled: boolean) => void;
}

export interface MapReduxProps extends MapStateProps {
    changeMapViewMode: (mapViewMode: MapViewMode) => void;
    changeFollowSelectedAsset: (followSelectedAsset: boolean) => void;
    toggleTruckAttributesOverlayEnabled: () => void;
    toggleTrafficIncidentsOverlayEnabled: () => void;
}

export const mapStateToProps = (
    timestamp: number,
    {
        viewMode: mapViewMode,
        truckAttributesOverlayEnabled,
        trafficIncidentsOverlayEnabled,
        followSelectedAsset,
        markerClusterRadius,
    }: MapUserPreferences,
    securables: Securables,
    languageState: LanguageStoreState,
    displayPreferences: DisplayUserPreferences,
    mapProfile: MapProfile
): MapStateProps => {
    return {
        mapViewMode,
        mapLayersSecurables: securables.mapLayers,
        truckAttributesOverlayEnabled,
        trafficIncidentsOverlayEnabled,
        followSelectedAsset,
        markerClusterRadius,
        mapLayersProps: getMapLayersProps(
            timestamp,
            mapViewMode,
            truckAttributesOverlayEnabled,
            trafficIncidentsOverlayEnabled,
            securables.mapLayers,
            mapProfile,
            displayPreferences.unitSystem,
            languageState.currentLanguage && languageState.currentLanguage.code
        ),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): MapDispatchProps => ({
    changeMapViewMode: (viewMode: MapViewMode) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { viewMode }));
    },
    changeFollowSelectedAsset: (followSelectedAsset: boolean) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { followSelectedAsset }));
    },
    changeTruckAttributesOverlayEnabled: (truckAttributesOverlayEnabled: boolean) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { truckAttributesOverlayEnabled }));
    },
    changeTrafficIncidentsOverlayEnabled: (trafficIncidentsOverlayEnabled: boolean) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { trafficIncidentsOverlayEnabled }));
    },
});

const toggleTruckAttributesOverlayEnabledMemoized = memoizeOne(
    (
        truckAttributesOverlayEnabled: boolean | undefined,
        changeTruckAttributesOverlayEnabled: (truckAttributesOverlayEnabled: boolean) => void
    ) =>
        () => {
            changeTruckAttributesOverlayEnabled(!truckAttributesOverlayEnabled);
        }
);
const toggleTrafficIncidentsOverlayEnabledMemoized = memoizeOne(
    (
        trafficIncidentsOverlayEnabled: boolean | undefined,
        changeTrafficIncidentsOverlayEnabled: (trafficIncidentsOverlayEnabled: boolean) => void
    ) =>
        () => {
            changeTrafficIncidentsOverlayEnabled(!trafficIncidentsOverlayEnabled);
        }
);

export const mergeProps = (
    stateProps: MapStateProps,
    {
        changeTruckAttributesOverlayEnabled,
        changeTrafficIncidentsOverlayEnabled,
        ...restDispatchProps
    }: MapDispatchProps,
    ownProps: object
): MapReduxProps => ({
    ...stateProps,
    ...restDispatchProps,
    ...ownProps,
    toggleTruckAttributesOverlayEnabled: toggleTruckAttributesOverlayEnabledMemoized(
        stateProps.truckAttributesOverlayEnabled,
        changeTruckAttributesOverlayEnabled
    ),
    toggleTrafficIncidentsOverlayEnabled: toggleTrafficIncidentsOverlayEnabledMemoized(
        stateProps.trafficIncidentsOverlayEnabled,
        changeTrafficIncidentsOverlayEnabled
    ),
});
