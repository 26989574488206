import type { ensureAuthorizationSelector } from '~/components/EnsureAuthorization/selectors';
import type { LanguageStoreState } from '~/components/LanguageSelector';

export const mapStateToProps = (
    authorizationState: ReturnType<typeof ensureAuthorizationSelector>,
    languageStoreState: LanguageStoreState
) => ({
    securables: authorizationState.securables,
    currentLanguageCode: languageStoreState.currentLanguage?.code,
});
