import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { DateTimeWorkflowFieldProps } from './component';

export type DateTimeWorkflowFieldClassKey = 'toolbarIcon' | 'input';

export const DateTimeWorkflowFieldStyleRules: StyleRulesCallback<
    Theme,
    DateTimeWorkflowFieldProps,
    DateTimeWorkflowFieldClassKey
> = (theme: Theme) =>
    createStyles({ toolbarIcon: { color: theme.palette.primary.contrastText }, input: { width: '100%' } });
