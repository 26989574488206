import { getValueFromHash } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';
import type { AdminDevice, ResolvedAdminDevice } from '~/services/ApiClient';

export const resolveAdminDevice = (device: AdminDevice, settings: SettingsStoreState): ResolvedAdminDevice => {
    const networkId = settings.network.data[device.networkId];
    const billingGroup = getValueFromHash(settings.billingGroup.data, device.billingGroup);
    const billingStatus = getValueFromHash(settings.billingStatus.data, device.billingStatus);

    return {
        ...device,
        networkId,
        billingGroup,
        billingStatus,
    };
};
