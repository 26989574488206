import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { AnimatedTableRowInnerProps, AnimatedTableRowProps } from './component';
import { AnimatedTableRowComponent } from './component';
import { AnimatedTableRowStyleRules } from './styles';

export const AnimatedTableRow = compose<AnimatedTableRowInnerProps, AnimatedTableRowProps>(
    setDisplayName('AnimatedTableRow'),
    withStyles(AnimatedTableRowStyleRules, { withTheme: true })
)(AnimatedTableRowComponent);
