import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { TrailerEntryRowInnerProps, TrailerEntryRowProps } from './component';
import { TrailerEntryRowComponent } from './component';
import { TrailerEntryRowStyleRules } from './styles';

export const TrailerEntryRow = compose<TrailerEntryRowInnerProps, TrailerEntryRowProps>(
    setDisplayName('TrailerEntryRow'),
    withTranslation(),
    withDisplayPreferences(),
    withStyles(TrailerEntryRowStyleRules)
)(TrailerEntryRowComponent);
