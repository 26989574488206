import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { SettingItemHeaderInnerProps, SettingItemHeaderOuterProps } from './component';
import { SettingItemHeaderComponent } from './component';
import { SettingItemHeaderStyleRules } from './styles';

export type { SettingItemHeaderProps } from './component';

export const SettingItemHeader = compose<SettingItemHeaderInnerProps, SettingItemHeaderOuterProps>(
    setDisplayName('SettingItemHeader'),
    withStyles(SettingItemHeaderStyleRules)
)(SettingItemHeaderComponent);
