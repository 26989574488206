import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type RtdsSessionsClassKeys = 'root' | 'messageText' | 'loader' | 'backDrop';

export const RtdsSessionsStyleRules: StyleRulesCallback<Theme, {}, RtdsSessionsClassKeys> = (theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
        },
        messageText: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
        },
        loader: {
            color: theme.palette.primary.main,
        },
        backDrop: {
            position: 'absolute',
            zIndex: theme.zIndex.drawer,
            color: '#fff',
        },
    });
