import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { FilterSheetInnerProps, FilterSheetOuterProps } from './component';
import { FilterSheetComponent } from './component';
import { FilterSheetStyleRules } from './styles';

export const FilterSheet = compose<FilterSheetInnerProps, FilterSheetOuterProps>(
    setDisplayName('FilterSheet'),
    withTranslation(),
    withStyles(FilterSheetStyleRules)
)(FilterSheetComponent);
