import type { ActionTypes as AuthenticationActionTypes } from '~/data/authentication';
import { ActionTypeKeys as AuthenticationActionTypeKeys } from '~/data/authentication';

export interface AuthenticateStoreState {
    loading: boolean;
    requestFailed: boolean;
}

const defaultStoreState: AuthenticateStoreState = {
    loading: false,
    requestFailed: false,
};

export const authenticateReducer = (
    state: AuthenticateStoreState = defaultStoreState,
    action: AuthenticationActionTypes
): AuthenticateStoreState => {
    switch (action.type) {
        case AuthenticationActionTypeKeys.AUTHENTICATION_PENDING:
            return {
                ...state,
                loading: true,
                requestFailed: false,
            };
        case AuthenticationActionTypeKeys.AUTHENTICATION_FULFILLED:
            return {
                ...state,
                loading: false,
            };
        case AuthenticationActionTypeKeys.AUTHENTICATION_REJECTED:
            return { ...state, requestFailed: action.error, loading: false };
        default:
            return state;
    }
};
