import type { UnitSystem } from '@fv/converters';
import { convertSpeed, getSpeedUnit } from '@fv/converters';

import { isUndefined } from '../../utility';

interface FormatSpeedArgs {
    value?: number;
    precision?: number;
    unitSystem: UnitSystem;
}

const formatSpeed = (args: FormatSpeedArgs) => {
    const { value, precision, unitSystem } = args;
    if (isUndefined(value)) {
        return undefined;
    }

    const converted = convertSpeed({ value, precision, unitSystem });
    const unit = getSpeedUnit(unitSystem);

    return `${converted} ${unit}`;
};

export { formatSpeed };
