export interface PasswordStrengthIndicatorProps {
    password?: string;
    onChangeScore: (newScore: PasswordScore) => void;
    minLength?: number;
    className?: string;
}

export enum PasswordScore {
    TooShort,
    Weak,
    Okay,
    Good,
    Strong,
}
