import * as React from 'react';
import { compose, setDisplayName, wrapDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';
import { ensureUserPreferences } from '~/components/UserPreferences';

import { requiredConversationUserPreferences } from '../../preferences';

import type {
    SendTextMessageFormElement,
    SendTextMessageFormForwardRefProps,
    SendTextMessageFormInnerProps,
    SendTextMessageFormProps,
} from './component';
import { SendTextMessageFormComponent } from './component';

const BaseSendTextMessageForm = compose<
    SendTextMessageFormInnerProps,
    SendTextMessageFormProps & SendTextMessageFormForwardRefProps
>(
    setDisplayName('SendTextMessageForm'),
    withTranslation(),
    ensureUserPreferences([requiredConversationUserPreferences])
)(SendTextMessageFormComponent);

export type { SendTextMessageFormElement } from './component';

export const SendTextMessageForm = setDisplayName(wrapDisplayName(BaseSendTextMessageForm, 'ForwardRef'))(
    React.forwardRef<SendTextMessageFormElement, SendTextMessageFormProps>((props, ref) =>
        React.createElement(BaseSendTextMessageForm, { ...props, forwardedRef: ref })
    )
);
