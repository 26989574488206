import { Grid } from '@devexpress/dx-react-grid-material-ui';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { GridRootClassKey } from './styles';

export interface GridRootProps {
    dataId?: string;
}

export interface GridRootInnerProps extends GridRootProps, WithStyles<GridRootClassKey> {}

export const GridRootComponent: React.FC<GridRootInnerProps> = ({ classes, dataId, ...restProps }) => {
    return <Grid.Root {...restProps} className={classes.root} data-id={dataId} />;
};
