import { compose, setDisplayName } from 'react-recompose';
import { withRouter } from 'react-router';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';
import { withDetailsPaneContext } from '~/components/SceneDetailsPane';

import type { DriverInfoSectionInnerProps, DriverInfoSectionProps } from './component';
import { DriverInfoSectionComponent } from './component';

export const DriverInfoSection = compose<DriverInfoSectionInnerProps, DriverInfoSectionProps>(
    setDisplayName('DriverInfoSection'),
    withTranslation(),
    withRouter,
    withDisplayPreferences(),
    withDetailsPaneContext()
)(DriverInfoSectionComponent);
