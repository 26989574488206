import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type { Classification, DisplayUserPreferencesVehicleDisplayFormat } from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';

export const getVehicleValue = (vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat) => {
    return (monitoringVehicleEntry: MonitoringVehicleEntry): string => {
        return formatVehicleName(monitoringVehicleEntry.vehicle, vehicleDisplayNameFormat);
    };
};

export const getMsisdnValue = (monitoringVehicleEntry: MonitoringVehicleEntry): string | undefined =>
    monitoringVehicleEntry.vehicle.device?.msisdn;

export const getInhibitorStatusValue = (entry: MonitoringVehicleEntry): Classification | undefined =>
    entry.inhibitorStatus?.inhibitorStatus;
