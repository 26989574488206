import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DriverSummaryInnerProps, DriverSummaryProps } from './component';
import { DriverSummaryComponent } from './component';
import { DriverSummaryStyleRules } from './styles';

export * from './models';

export const DriverSummary = compose<DriverSummaryInnerProps, DriverSummaryProps>(
    setDisplayName('DriverSummary'),
    withTranslation(),
    withStyles(DriverSummaryStyleRules)
)(DriverSummaryComponent);
