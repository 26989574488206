import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const DRIVERSUBPAGE_PREFERENCES_KEY = 'driver-subpage-state';

export interface DriverSubpageUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {}

export const defaultDriverSubpageUserPreferences: DriverSubpageUserPreferences = {
    sectionOrder: [
        SectionName.DAILY_OVERVIEW,
        SectionName.GENERAL,
        SectionName.DRIVE,
        SectionName.LABOR,
        SectionName.DUTY,
        SectionName.REST,
    ],
    collapsedSections: [],
};

export const processResponse = (preferences: Partial<DriverSubpageUserPreferences>): DriverSubpageUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultDriverSubpageUserPreferences);
};

export const requiredDriverSubpageUserPreferences = {
    key: DRIVERSUBPAGE_PREFERENCES_KEY,
    defaultState: defaultDriverSubpageUserPreferences,
    processResponse,
};
