import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { SignalRConnectionMonitorInnerProps, SignalRConnectionMonitorProps } from './component';
import { SignalRConnectionMonitorComponent } from './component';

export const SignalRConnectionMonitor = compose<SignalRConnectionMonitorInnerProps, SignalRConnectionMonitorProps>(
    setDisplayName('SignalRConnectionMonitor'),
    withTranslation()
)(SignalRConnectionMonitorComponent);
