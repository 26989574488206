import memoizeOne from 'memoize-one';

import type { SectionDefinition } from '~/components/Sections';

import { ChangelogDetails } from './components/ChangelogDetails';
import { SectionName } from './constants';
import type { GetSectionsArgs } from './models';

export const getSections = <T,>({
    attributeDefinitions,
    entityType,
    changelogs,
}: GetSectionsArgs<T>): SectionDefinition[] => {
    return [
        {
            name: SectionName.CHANGELOG,
            content: () => (
                <ChangelogDetails
                    entityType={entityType}
                    attributeDefinitions={attributeDefinitions}
                    changelogs={changelogs}
                />
            ),
        },
    ];
};

export const getSectionsMemoized = memoizeOne(getSections);
