import { toLon } from 'mt-geo';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

import { formatCardinal } from './formatCardinal';

const formatLongitudeInternal = (t: SingleTFunction, longitude: number) => {
    const lonString = toLon(longitude);
    const lonCardinal = lonString.slice(-1);
    return `${lonString.substring(0, lonString.length - 1)} ${formatCardinal(t, lonCardinal)}`;
};

const formatLongitudeNoCardinalTranslationInternal = (longitude: number) => {
    const lonString = toLon(longitude);
    const lonCardinal = lonString.slice(-1);
    return `${lonString.substring(0, lonString.length - 1)} ${lonCardinal}`;
};

const formatLongitudeMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((longitude: number) => formatLongitudeInternal(t, longitude))
);

const formatLongitudeNoCardinalTranslationMemoized = memoizeOne(() =>
    memoize((longitude: number) => formatLongitudeNoCardinalTranslationInternal(longitude))
);

export const formatLongitude = (t: SingleTFunction, longitude: number): string => formatLongitudeMemoized(t)(longitude);

export const formatLongitudeNoCardinalTranslation = (longitude: number): string =>
    formatLongitudeNoCardinalTranslationMemoized()(longitude);
