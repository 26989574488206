import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import type {
    DisplayUserPreferencesTrailerDisplayFormat,
    NamedTrailerReference,
    PojoModel,
    Trailer,
} from '~/services/ApiClient';
import { formatTrailerName } from '~/services/Formatters';

export interface TrailerNameFormatterProps {
    value?: PojoModel<Trailer> | PojoModel<NamedTrailerReference>;
    trailerDisplayNameFormat: DisplayUserPreferencesTrailerDisplayFormat;
}

export interface TrailerNameFormatterInnerProps extends TrailerNameFormatterProps {}

const TrailerNameFormatter: React.FunctionComponent<TrailerNameFormatterInnerProps> = ({
    value,
    trailerDisplayNameFormat,
}) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatTrailerName(value, trailerDisplayNameFormat)}</>;
};
TrailerNameFormatter.displayName = 'TrailerNameFormatter';

export { TrailerNameFormatter };
