import type { AssetReference, AssetType } from '~/services/ApiClient';

import type { AssetDataSet, AssetReferenceHash, AssetReferenceWithDisplayName, AssetTypeNode } from '../models';
import { NodeTypes } from '../models';

import { assetIdsToNodes } from './mapasset';
import { mapAssetTypeNodeState } from './mapassettypestate';

export const assetTypesToNodes = (
    assetDataSets: AssetDataSet[],
    assetIds: AssetReferenceHash<AssetReference>,
    assetsHash: AssetReferenceHash<AssetReferenceWithDisplayName>,
    collapsedAssetTypes: AssetType[],
    selectedAssetIds: AssetReferenceHash<AssetReference>
): AssetTypeNode[] => {
    return assetDataSets.map((assetDataSet) => {
        const children = assetIdsToNodes(
            [assetDataSet.type],
            assetIds,
            assetsHash,
            selectedAssetIds,
            undefined,
            undefined
        );

        const state = mapAssetTypeNodeState(assetDataSet.type, collapsedAssetTypes, children);

        return {
            id: assetDataSet.type,
            name: assetDataSet.displayName,
            children,
            type: NodeTypes.ASSET_TYPE,
            state,
        };
    });
};
