import Assignment from '@mui/icons-material/Assignment';
import Chat from '@mui/icons-material/Chat';
import VerticalAlignTop from '@mui/icons-material/VerticalAlignTop';
import { Divider, Fab } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { ReactNode, SyntheticEvent } from 'react';
import { Component } from 'react';
import type { OnScrollParams } from 'react-virtualized';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { BooleanSettingItem } from '~/components/MiniSettingsPanelComponent';
import { BooleanFieldType, MiniSettingsPanel, SettingItemType } from '~/components/MiniSettingsPanelComponent';
import { PopupWidget } from '~/components/PopupWidget';
import { TitledIconButton } from '~/components/TitledIconButton';
import type { Disposable } from '~/listeners';
import { reportError } from '~/reporting';
import type {
    ConversationSummary as ConversationSummaryModel,
    DisplayUserPreferencesVehicleDisplayFormat,
} from '~/services/ApiClient';
import { ConversationSummaryNotification, MessageBodyType, ResourceFilter, createApiModel } from '~/services/ApiClient';
import { subscribeToNotificationsAndRetrieveTheData } from '~/services/SignalR';

import type { InjectedUserMessagingProfilesProps } from '../EnsureUserMessagingProfiles';
import { SendMessageDialog } from '../SendMessageDialog';

import { ConversationHeader } from './components/ConversationHeader';
import { ConversationsSummaryWidgetList } from './components/ConversationsSummaryWidgetList';
import { EmptyStateMessage } from './components/EmptyStateMessage';
import { ConversationSummarySearchBar } from './components/SearchBar';
import type { ConversationSummaryEntry } from './conversationSummaryEntry';
import type { ConversationsSummaryWidgetClassKey } from './styles';
import { widgetHeight, widgetWidth } from './styles';

export interface ConversationsSummaryWidgetProps {}

export interface ConversationsSummaryWidgetReduxProps {
    conversationSummaryEntries: ConversationSummaryEntry[];
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat;
    isMinimized: boolean;
    totalUnreadTextMessageCount: number;
    totalUnreadWorkflowMessageCount: number;
    hasVehiclesAvailable: boolean;
    hasSearchableConversations: boolean;
    searchQuery?: string;
    getConversations: (includeDeleted: boolean) => void;
    updateConversations: (conversation: ConversationSummaryModel) => void;
    openConversation: (vehicleId: number) => void;
    toggleMinimizedState: () => void;
    loading: boolean;
    changeSearchQuery: (searchQuery: string) => void;
    toggleShowOnlyUnreadConversationsState: () => void;
    clearSearchQuery: () => void;
    sendWorkflowMessageAllowed: boolean;
    sendTextMessagesAllowed: boolean;
    showOnlyUnreadConversations: boolean;
    retrieveLatestWorkflowFormDefinitions: () => void;
    retrieveWorkflowFormDefinition: (formId: number) => void;
    showDeletedMessages: boolean;
}

export interface ConversationsSummaryWidgetInnerProps
    extends ConversationsSummaryWidgetProps,
        ConversationsSummaryWidgetReduxProps,
        InjectedTranslationProps,
        InjectedUserMessagingProfilesProps,
        WithStyles<ConversationsSummaryWidgetClassKey> {}

export interface ConversationsSummaryWidgetState {
    scrollTop: number;
    newMessagedialog: ReactNode;
}

export class ConversationsSummaryWidgetComponent extends Component<
    ConversationsSummaryWidgetInnerProps,
    ConversationsSummaryWidgetState
> {
    private disposableHandlers: Disposable[] = [];

    constructor(props: ConversationsSummaryWidgetInnerProps) {
        super(props);
        this.state = { scrollTop: 0, newMessagedialog: undefined };
    }

    public componentDidMount(): void {
        this.props.retrieveLatestWorkflowFormDefinitions();
        this.subscribeForConversationProfile();
    }

    public componentDidUpdate(prevProps: ConversationsSummaryWidgetInnerProps): void {
        if (
            this.props.searchQuery &&
            prevProps.conversationSummaryEntries.length &&
            !this.props.conversationSummaryEntries.length &&
            this.state.scrollTop
        ) {
            this.setState({ scrollTop: 0 });
        }

        if (prevProps.userConversationProfileId !== this.props.userConversationProfileId) {
            this.disposableHandlers.forEach(({ dispose }) => dispose());
            this.disposableHandlers = [];
            this.subscribeForConversationProfile();
        }
    }

    public componentWillUnmount(): void {
        this.disposableHandlers.forEach(({ dispose }) => dispose());
    }

    public render(): JSX.Element {
        const renderActions = (
            <>
                <TitledIconButton
                    className={this.props.classes.actionButton}
                    onClick={this.handleOpenNewTextMessageDialog}
                    data-id="open-new-text-message-dialog"
                    disabled={!this.props.sendTextMessagesAllowed}
                    title={
                        this.props.sendTextMessagesAllowed
                            ? this.props.t('new-text-message')
                            : this.props.t('sending-text-disabled')
                    }
                    placement="left"
                >
                    <Chat fontSize="inherit" />
                </TitledIconButton>
                <TitledIconButton
                    className={this.props.classes.actionButton}
                    onClick={this.handleOpenNewWorkflowMessageDialog}
                    data-id="open-new-workflow-message-dialog"
                    disabled={!this.props.sendWorkflowMessageAllowed}
                    title={
                        this.props.sendWorkflowMessageAllowed
                            ? this.props.t('new-workflow-message')
                            : this.props.t('sending-workflow-disabled')
                    }
                    placement="left"
                >
                    <Assignment fontSize="inherit" />
                </TitledIconButton>
            </>
        );

        return (
            <>
                <PopupWidget
                    title={this.getPopupTitle()}
                    actions={renderActions}
                    footer={this.getFooter()}
                    content={this.getPopupContent()}
                    width={widgetWidth}
                    height={widgetHeight}
                    isMinimized={this.props.isMinimized}
                    toggleMinimize={this.props.toggleMinimizedState}
                    dataId="conversations-summary-widget"
                />
                {this.state.newMessagedialog}
            </>
        );
    }

    private subscribeForConversationProfile = () => {
        subscribeToNotificationsAndRetrieveTheData(
            'conversationsummaries',
            ConversationSummaryNotification.fromJS,
            (notification: ConversationSummaryNotification) => {
                this.props.updateConversations(notification.data);
            },
            () => {
                this.props.getConversations(this.props.showDeletedMessages);
            },
            createApiModel(ResourceFilter, {
                conversationProfileId: this.props.userConversationProfileId,
                includeDeletedMessages: this.props.showDeletedMessages,
            })
        ).then((disposable: Disposable) => {
            this.disposableHandlers.push(disposable);
        }, reportError);
    };

    private handleOpenNewTextMessageDialog = (event: SyntheticEvent) => {
        event.stopPropagation();
        this.setState({
            newMessagedialog: (
                <SendMessageDialog
                    vehicleId={undefined}
                    onClose={this.closeDialog}
                    messageBodyType={MessageBodyType.Text}
                />
            ),
        });
    };

    private handleOpenNewWorkflowMessageDialog = (event: SyntheticEvent) => {
        event.stopPropagation();
        this.setState({
            newMessagedialog: (
                <SendMessageDialog
                    vehicleId={undefined}
                    onClose={this.closeDialog}
                    messageBodyType={MessageBodyType.Workflow}
                />
            ),
        });
    };

    private closeDialog = () =>
        this.setState({
            newMessagedialog: undefined,
        });

    private getFooter(): JSX.Element | undefined {
        if (!this.props.hasVehiclesAvailable) {
            return undefined;
        }

        const filterUnreadMessagesSetting: BooleanSettingItem = {
            key: 'show-only-unread-conversations',
            onValueChange: () => this.props.toggleShowOnlyUnreadConversationsState(),
            settingType: SettingItemType.Boolean,
            title: this.props.t('show-only-unread-conversations'),
            type: BooleanFieldType.ToggleSwitch,
            value: this.props.showOnlyUnreadConversations,
        };

        return <MiniSettingsPanel settings={[filterUnreadMessagesSetting]} />;
    }

    private getPopupTitle(): JSX.Element {
        const { t, totalUnreadTextMessageCount, totalUnreadWorkflowMessageCount } = this.props;
        return (
            <ConversationHeader
                title={t('messaging')}
                unreadTextMessageCount={totalUnreadTextMessageCount}
                unreadWorkflowMessageCount={totalUnreadWorkflowMessageCount}
            />
        );
    }

    private getPopupContent(): JSX.Element {
        const {
            t,
            classes,
            loading,
            hasVehiclesAvailable,
            hasSearchableConversations,
            conversationSummaryEntries,
            vehicleDisplayFormat,
            searchQuery,
        } = this.props;

        if (loading) {
            return <div />;
        }

        if (!hasVehiclesAvailable) {
            return (
                <EmptyStateMessage
                    dataId="no-conversation-vehicles-available"
                    title={t('no-conversation-vehicles-available')}
                    description={t('no-conversation-vehicles-available-description')}
                />
            );
        }

        if (!hasSearchableConversations) {
            return (
                <EmptyStateMessage
                    dataId="no-matching-conversations"
                    title={t('no-matching-conversations')}
                    description={t('no-matching-conversations-description')}
                />
            );
        }

        const scrollToTopFabClasses = classNames(classes.scrollToTopFab, {
            [classes.scrollToTopFabVisible]: this.state.scrollTop > 0,
        });

        const content =
            searchQuery && !conversationSummaryEntries.length ? (
                <EmptyStateMessage
                    dataId="no-results-message"
                    title={t('no-match')}
                    description={t('no-results', { term: searchQuery })}
                />
            ) : (
                <div className={classes.listContainer}>
                    <ConversationsSummaryWidgetList
                        items={conversationSummaryEntries}
                        scrollTop={this.state.scrollTop}
                        onScroll={this.handleScroll}
                        vehicleDisplayFormat={vehicleDisplayFormat}
                        openConversation={this.props.openConversation}
                        retrieveWorkflowFormDefinition={this.props.retrieveWorkflowFormDefinition}
                    />

                    <Fab
                        className={scrollToTopFabClasses}
                        color="secondary"
                        onClick={this.handleBackToTop}
                        data-id="scroll-to-top"
                    >
                        <VerticalAlignTop fontSize="inherit" />
                    </Fab>
                </div>
            );

        return (
            <div className={classes.content}>
                <ConversationSummarySearchBar
                    changeSearchQuery={this.props.changeSearchQuery}
                    clearSearchQuery={this.props.clearSearchQuery}
                    defaultSearchQuery={searchQuery}
                />
                <Divider />
                {content}
            </div>
        );
    }

    private handleBackToTop = () => {
        this.setState({ scrollTop: 0 });
    };

    private handleScroll = ({ scrollTop }: OnScrollParams) => {
        this.setState({ scrollTop });
    };
}
