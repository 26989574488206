import type { Dispatch } from 'redux';

import type { JwtStoreState } from '~/components/AuthenticationData';
import { logoutAction } from '~/data/authentication';
import { reportError } from '~/reporting';
import { saveUserPreferencesService } from '~/services/SaveUserPreferences';

import type { AuthenticationStoreState } from '../../reducers';

import type { LogoutDispatchProps, LogoutStateProps } from './component';

export function mapStateToProps(state: AuthenticationStoreState, jwtStoreState: JwtStoreState): LogoutStateProps {
    return {
        isAuthenticated: jwtStoreState.isAuthenticated,
        loginType: state.loginType,
    };
}

export const mapDispatchToLogoutProps = (dispatch: Dispatch): LogoutDispatchProps => ({
    logout: async () => {
        await saveUserPreferencesService.saveAndClearAll();
        await dispatch(logoutAction()).catch(reportError);
    },
});
