import { getValueFromHash } from '~/common';
import { isEmpty } from '~/libs/utility';
import { AssetType } from '~/services/ApiClient';

import type { AddAssetNameArgs, ResolvedAlerts, ResolvedAlertsWithAssetName } from './models';

const addAssetNamesToAlerts = (args: AddAssetNameArgs): ResolvedAlertsWithAssetName[] => {
    const { alerts, vehicles, trailers, drivers, containers } = args;

    if (isEmpty(alerts)) {
        return [];
    }

    const ASSETS_MAP = {
        [AssetType.Vehicle]: vehicles,
        [AssetType.Trailer]: trailers,
        [AssetType.Driver]: drivers,
        [AssetType.Container]: containers,
    };

    return alerts.reduce((acc: ResolvedAlertsWithAssetName[], alert) => {
        if (!alert || !alert.alertAsset) {
            return acc;
        }

        acc.push({
            ...alert,
            assetName: getValueFromHash(ASSETS_MAP[alert.alertAsset.type], alert.alertAsset.id)?.alias,
        });

        return acc;
    }, []);
};

const getComparedCache = (cache: ResolvedAlerts[], payload: ResolvedAlerts) => {
    const alertIndex = cache.findIndex((item) => item.id === payload.id);
    const result = [...cache];

    if (alertIndex !== -1) {
        result.splice(alertIndex, 1, payload);
        return result;
    }
    return [...cache, payload];
};

const getUpdatedAlerts = (state: ResolvedAlerts[], itemsToUpdate: ResolvedAlerts[]): ResolvedAlerts[] => {
    const updatedItems = state.reduce((accumulator: ResolvedAlerts[], item) => {
        const itemToUpdate = itemsToUpdate.find((updatedItem) => updatedItem.id === item.id);

        accumulator.push(itemToUpdate ?? item);

        return accumulator;
    }, []);

    const itemsToAdd = itemsToUpdate.reduce((accumulator: ResolvedAlerts[], item) => {
        const itemToAdd = !state.some((stateItem) => stateItem.id === item.id);

        if (itemToAdd) {
            accumulator.push(item);
        }
        return accumulator;
    }, []);

    return [...updatedItems, ...itemsToAdd];
};

export { addAssetNamesToAlerts, getComparedCache, getUpdatedAlerts };
