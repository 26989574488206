import { formatFuel, formatVolumeLiquid } from '@fv/components';
import type { FuelValue } from '@fv/converters';
import { convertFuel, convertVolumeLiquid, getFuelUnit, getVolumeLiquidUnit } from '@fv/converters';
import type { Duration as MomentDuration } from 'moment';
import moment from 'moment';
import type { FC } from 'react';

import { isNumber, isUndefined } from '~/libs/utility';
import { durationToDays, exportConvertorFactory } from '~/services/Convertors';
import { exportFormatterFactory, formatDate, valueTextFormatterFactory } from '~/services/Formatters';
import { formatDuration } from '~/services/Formatters/formatDuration';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareDateObjects, compareFactory, compareNumbers, compareStrings } from '~/services/Sorting';

import type { DefaultOptions, TypeMap } from './models';

const DurationFormatter: FC<{ value: MomentDuration }> = ({ value }) => {
    if (isUndefined(value)) {
        return null;
    }

    if (isNumber(value)) {
        return <>{formatDuration(moment.duration(value))}</>;
    }

    return <>{formatDuration(value as unknown as number | MomentDuration)}</>;
};

const DateFormatter: FC<{ value: Date }> = ({ value }) => {
    if (!value) {
        return null;
    }

    return <>{formatDate(value)}</>;
};

const DEFAULT_OPTIONS: DefaultOptions = {
    string: () => ({ dataType: 'string', compare: compareStrings }),
    duration: ({ t }) => ({
        align: 'right',
        dataType: 'duration',
        excelCellFormat: `[h]"${t('unit-hour')}" mm"${t('unit-minute')}"`,
        compare: compareNumbers,
        exportValueFormatter: exportFormatterFactory(durationToDays),
        valueFormatterComponent: DurationFormatter,
    }),
    date: () => ({
        dataType: 'date',
        compare: compareDateObjects,
        excelCellFormat: 'm/d/yy',
        valueFormatterComponent: DateFormatter,
    }),
    fuel: ({ unitSystem, precision }) => {
        const FuelFormatter: FC<{ value: TypeMap['fuel'] }> = ({ value }) => {
            if (!value) {
                return null;
            }
            return <>{formatFuel({ unitSystem, value, precision })}</>;
        };

        return {
            dataType: 'number',
            align: 'right',
            getFilterValue: (value) => value && convertFuel({ unitSystem, value, precision }),
            exportValueFormatter: exportConvertorFactory((value) => convertFuel({ unitSystem, value, precision })),
            excelCellFormat: (value) => (value ? `0.0 "${getFuelUnit(unitSystem, value.type)}"` : '0.0'),
            valueFormatterComponent: FuelFormatter,
            compare: compareFactory(
                (value: FuelValue) => convertFuel({ unitSystem, value, precision }),
                compareNumbers
            ),
            groupingCriteria: groupingCriteriaFactory((value) => formatFuel({ unitSystem, value, precision })),
            valueTextFormatter: valueTextFormatterFactory((value) => formatFuel({ unitSystem, value, precision })),
        };
    },
    volume_liquid: ({ unitSystem, precision }) => {
        const VolumeLiquidFormatter: FC<{ value: number }> = ({ value }) => {
            if (isUndefined(value)) {
                return null;
            }
            return <>{formatVolumeLiquid({ unitSystem, value, precision })}</>;
        };

        return {
            dataType: 'number',
            align: 'right',
            getFilterValue: (value) =>
                isUndefined(value) ? undefined : convertVolumeLiquid({ unitSystem, value, precision }),
            exportValueFormatter: exportConvertorFactory((value) =>
                convertVolumeLiquid({ unitSystem, value, precision })
            ),
            excelCellFormat: `0.0 "${getVolumeLiquidUnit(unitSystem)}"`,
            valueFormatterComponent: VolumeLiquidFormatter,
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((value) => formatVolumeLiquid({ unitSystem, value, precision })),
            valueTextFormatter: valueTextFormatterFactory((value) =>
                formatVolumeLiquid({ unitSystem, value, precision })
            ),
        };
    },
};

export { DEFAULT_OPTIONS };
