import type { Action, BaseAction } from '~/types';

export enum ActionTypeKeys {
    CONVERSATIONSSUMMARY_CHANGESEARCHQUERY = 'CONVERSATIONSSUMMARY_CHANGESEARCHQUERY',
    CONVERSATIONSSUMMARY_CLEARSEARCHQUERY = 'CONVERSATIONSSUMMARY_CLEARSEARCHQUERY',
}

export type ActionTypes =
    | Action<ActionTypeKeys.CONVERSATIONSSUMMARY_CHANGESEARCHQUERY, string>
    | BaseAction<ActionTypeKeys.CONVERSATIONSSUMMARY_CLEARSEARCHQUERY>;
