import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import type { FC, ReactElement } from 'react';
import { forwardRef, useState } from 'react';
import type { Components, GroupContent, GroupItemContent } from 'react-virtuoso';
import { GroupedVirtuoso } from 'react-virtuoso';

import { isUndefined } from '../../utility';

import { Footer, Group, Header, Value } from './components';
import { testIdPrefix } from './consts';
import { useMultiSelectList } from './hooks';
import type { MultiSelectListProps } from './models';
import { useStyles } from './styles';
import { getAllChildrenItems } from './utils';

const MuiComponents: Components<unknown, unknown> = {
    List: forwardRef((props, listRef) => {
        const classes = useStyles({ indentLevel: 0 });
        return <List dense component="div" ref={listRef} {...props} className={classes.list} />;
    }),

    Item: (props) => {
        const element = props.children as ReactElement | undefined;
        const { indentLevel = 0 } = element ? element.props.option : { indentLevel: 0 };
        const { hasChildren = false } = element ? element.props : { hasChildren: false };
        const modifiedIndentLevel = hasChildren ? indentLevel - 1 : indentLevel;
        const classes = useStyles({ indentLevel: modifiedIndentLevel });

        return <ListItem component="div" {...props} className={classes.item} />;
    },

    Group: (props) => {
        const classes = useStyles({ indentLevel: 0 });
        return <ListItem component="div" {...props} className={classes.group} />;
    },
};

const MultiSelectList = <TId,>(props: MultiSelectListProps<TId>): ReturnType<FC<MultiSelectListProps<TId>>> => {
    const { title, testId, value, onChange, options, equalityComparer } = props;

    const [searchText, setSearchText] = useState('');
    const {
        isCollapsed,
        toggleCollapsed,
        toggleCollapsedSubGroup,
        isCollapsedSubGroup,
        isSelected,
        toggleSelected,
        groups,
        groupCounts,
        items,
        filteredItems,
        allItems,
    } = useMultiSelectList({
        value,
        onChange,
        options,
        equalityComparer,
        searchText,
    });

    const groupContent: GroupContent<unknown> = (groupIndex: number) => (
        <Group
            testId={groups[groupIndex].groupName.toLowerCase()}
            group={groups[groupIndex]}
            selected={value}
            toggleSelected={toggleSelected}
            equalityComparer={equalityComparer}
            collapsed={isCollapsed(groups[groupIndex])}
            toggleCollapsed={() => toggleCollapsed(groups[groupIndex])}
        />
    );

    const itemContent: GroupItemContent<unknown, unknown> = (index) => {
        const option = items[index];

        if (isUndefined(option)) {
            return null;
        }

        const { id, hasChildren, testId: optionTestId } = option;
        const childrenOptions: TId[] = [];
        const isOptionSelected = isSelected(id);
        const shouldSelectChildrenOptions = hasChildren ? !isOptionSelected : undefined;

        if (hasChildren) {
            getAllChildrenItems(id, items).map((x) => childrenOptions.push(x.id));
        }

        return (
            <Value
                testId={optionTestId}
                option={option}
                selected={isOptionSelected}
                hasChildren={hasChildren}
                toggleSelected={() => toggleSelected([id, ...childrenOptions], shouldSelectChildrenOptions)}
                toggleCollapse={(_id) => toggleCollapsedSubGroup(_id)}
                isCollapsed={isCollapsedSubGroup(id)}
            />
        );
    };

    return (
        <Box data-testid={`${testIdPrefix}:${testId}`} display="flex" height="100%" flexDirection="column">
            <Header
                equalityComparer={equalityComparer}
                onSearchTextChange={setSearchText}
                options={filteredItems}
                searchText={searchText}
                selected={value}
                title={title}
                toggleSelected={toggleSelected}
            />

            <GroupedVirtuoso
                components={MuiComponents}
                groupContent={groupContent}
                groupCounts={groupCounts}
                itemContent={itemContent}
            />

            <Footer selected={value} options={allItems} />
        </Box>
    );
};

MultiSelectList.displayName = 'MultiSelectList';
export { MultiSelectList };
