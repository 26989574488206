import type { Action } from 'redux';

import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';

import type { RtdsSessionsStoreState } from './reducers';
import { rtdsSessionsReducer } from './reducers';

export interface RtdsSessionsSceneStoreState {
    rtdsSessionsRoot: RtdsSessionsStoreState;
}

export const rtdsSessionsSceneReducer = (
    state: RtdsSessionsSceneStoreState | undefined,
    action: Action,
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): RtdsSessionsSceneStoreState => {
    const rtdsSessionsRoot = rtdsSessionsReducer(
        state?.rtdsSessionsRoot,
        action,
        staticDataStoreState,
        settingsStoreState
    );
    return { rtdsSessionsRoot };
};
