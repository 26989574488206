import memoizeOne from 'memoize-one';
import { withTranslation } from 'react-i18next';

import { IgnitionStatusFormatter } from '~/components/Formatters';
import { AssetTypeFormatter } from '~/components/Formatters/AssetType';
import type { GridColumnDefinition } from '~/components/Grid';
import {
    createClassificationsColumn,
    createDateTimeColumn,
    createFormattedColumn,
    createStringColumn,
    filterDataSourceBySearchQuery,
} from '~/components/Grid';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
    ResolvedRtdsSessionError,
} from '~/services/ApiClient';
import { AssetType, ClassificationType, RtdsSessionStatus } from '~/services/ApiClient';
import {
    exportFormatterFactory,
    formatAssetTypeTitle,
    formatIgnitionStatus,
    formatIgnitionStatusTitle,
    valueTextFormatterFactory,
} from '~/services/Formatters';
import { formatRtdsSessionError } from '~/services/Formatters/formatRtdsSessionError';
import { formatRtdsSessionStatus } from '~/services/Formatters/formatRtdsSessionStatus';
import { compareBooleans, compareNumbers, compareStrings } from '~/services/Sorting';

import type { RtdsSessionEntry } from '../../models';

import { ColumnName } from './constants';
import {
    getAssetTypeValue,
    getDriverDisplayNameFactory,
    getErrorValue,
    getIgnitionStatusValue,
    getStatusValue,
    getVehicleDisplayNameFactory,
} from './getCellValue';

export const filterDataSourceMemoized = memoizeOne(
    (
        searchQuery: string | undefined,
        dataSource: RtdsSessionEntry[],
        columnDefinitions: Array<GridColumnDefinition<RtdsSessionEntry>>,
        visibleColumns: string[]
    ) => filterDataSourceBySearchQuery(searchQuery, dataSource, columnDefinitions, visibleColumns)
);

export const getColumns = (
    t: SingleTFunction,
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat,
    driverDisplayNameFormat: DisplayUserPreferencesDriverDisplayFormat
): Array<GridColumnDefinition<RtdsSessionEntry>> => {
    const columns: Array<GridColumnDefinition<RtdsSessionEntry>> = [
        {
            dataType: 'string',
            name: ColumnName.TYPE,
            title: t('rtds-asset-type'),
            groupTitle: t('session-related'),
            getCellValue: getAssetTypeValue,
            exportValueFormatter: exportFormatterFactory((v: AssetType) => formatAssetTypeTitle(t, v)),
            valueFormatterComponent: withTranslation()(AssetTypeFormatter),
            compare: compareStrings,
            valueTextFormatter: valueTextFormatterFactory((v: AssetType) => formatAssetTypeTitle(t, v)),
            cellFiltering: {
                options: Object.values(AssetType).filter(
                    (assetType) => assetType === AssetType.Vehicle || assetType === AssetType.Driver
                ),
            },
        },
        createStringColumn(
            ColumnName.DRIVER,
            t('driver'),
            t('driver-related'),
            getDriverDisplayNameFactory(driverDisplayNameFormat)
        ),
        createStringColumn(
            ColumnName.VEHICLE,
            t('vehicle'),
            t('vehicle-related'),
            getVehicleDisplayNameFactory(vehicleDisplayNameFormat)
        ),
        {
            dataType: 'boolean',
            name: ColumnName.IGNITIONSTATUS,
            title: t('ignition-status'),
            groupTitle: t('vehicle-status'),
            getCellValue: getIgnitionStatusValue,
            exportValueFormatter: exportFormatterFactory((v: boolean) => formatIgnitionStatus(t, v)),
            valueFormatterComponent: withTranslation()(IgnitionStatusFormatter),
            compare: compareBooleans,
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatIgnitionStatusTitle(t, v)),
        },
        createFormattedColumn(
            ColumnName.STATUS,
            t('status'),
            t('session-related'),
            (e: RtdsSessionEntry) => getStatusValue(e),
            (status: RtdsSessionStatus) => formatRtdsSessionStatus(t, status),
            {
                cellFiltering: { options: Object.values(RtdsSessionStatus) },
            }
        ),
        createDateTimeColumn(
            ColumnName.SCHEDULED,
            t('session-scheduled'),
            t('session-related'),
            (e) => e.session.scheduledAt
        ),
        createDateTimeColumn(
            ColumnName.EXPIRATION,
            t('session-expiration'),
            t('session-related'),
            (e) => e.session.expirationAt
        ),
        createDateTimeColumn(ColumnName.QUEUED, t('session-queued'), t('session-related'), (e) => e.session.queuedAt),
        createDateTimeColumn(
            ColumnName.STARTED,
            t('session-started'),
            t('session-related'),
            (e) => e.session.startedAt
        ),
        createDateTimeColumn(
            ColumnName.AUTHENTICATED,
            t('session-authenticated'),
            t('session-related'),
            (e) => e.session.authenticatedAt
        ),
        createDateTimeColumn(
            ColumnName.DOWNLOADED,
            t('session-downloaded'),
            t('session-related'),
            (e) => e.session.downloadedAt
        ),
        createDateTimeColumn(
            ColumnName.RECEIVED,
            t('session-received'),
            t('session-related'),
            (e) => e.session.receivedAt
        ),
        createDateTimeColumn(ColumnName.FAILED, t('session-failed'), t('session-related'), (e) => e.session.failedAt),
        createDateTimeColumn(
            ColumnName.LASTCHANGE,
            t('session-last-change'),
            t('session-related'),
            (e) => e.session.statusChangedAt
        ),
        createFormattedColumn(
            ColumnName.ERROR,
            t('session-error'),
            t('session-related'),
            (e: RtdsSessionEntry) => getErrorValue(e),
            (e: ResolvedRtdsSessionError) => formatRtdsSessionError(t, e)
        ),
        {
            dataType: 'number',
            name: ColumnName.FREQUENCY,
            title: t('session-frequency'),
            groupTitle: t('session-related'),
            getCellValue: (e) => e.session.frequency,
            compare: compareNumbers,
        },
        {
            dataType: 'number',
            name: ColumnName.RECURRENCE,
            title: t('session-recurrence'),
            groupTitle: t('session-related'),
            getCellValue: (e) => e.session.recurrence,
            compare: compareNumbers,
        },
        createClassificationsColumn(
            t,
            ColumnName.REQUESTEDTACHODATA,
            t('session-requested-tacho-data'),
            t('session-related'),
            (e) => e.session.dataBlocks,
            ClassificationType.TachographDataBlocks
        ),
        {
            dataType: 'number',
            name: ColumnName.SCHEDULEID,
            title: t('session-scheduleid'),
            groupTitle: t('session-related'),
            getCellValue: (e) => e.session.scheduleId?.id,
            compare: compareNumbers,
        },
        {
            dataType: 'number',
            name: ColumnName.INSTANCE,
            title: t('session-instance'),
            groupTitle: t('session-related'),
            getCellValue: (e) => e.session.instance,
            compare: compareNumbers,
        },
        createStringColumn(
            ColumnName.MSISDN,
            t('msisdn'),
            t('vehicle-related'),
            (e) => e.session.vehicle?.device?.msisdn
        ),
        createStringColumn(
            ColumnName.DRIVERID,
            t('driver-id'),
            t('driver-related'),
            getDriverDisplayNameFactory(driverDisplayNameFormat)
        ),
    ];

    return columns;
};

export const getColumnsMemoized = memoizeOne(getColumns);
