export const NAVIGATIONSECTION_PREFERENCES_KEY = 'navigation-section';

export const EtaDisplayOptionsLiterals = ['navigation', 'driver-hours'] as const;
export type EtaDisplayOption = (typeof EtaDisplayOptionsLiterals)[number];

export interface NavigationSectionUserPreferences {
    preferredEtaWhenCollapsed: EtaDisplayOption;
}

export const defaultNavigationSectionUserPreferences: NavigationSectionUserPreferences = {
    preferredEtaWhenCollapsed: 'driver-hours',
};

export const requiredNavigationSectionUserPreferences = {
    key: NAVIGATIONSECTION_PREFERENCES_KEY,
    defaultState: defaultNavigationSectionUserPreferences,
};
