import type { Reducer } from 'redux';

import type { ActionTypes } from '~/data/communication';
import { ActionTypeKeys } from '~/data/communication';
import type { MessageBody } from '~/services/ApiClient';
import { OptimisticOutgoingConversationMessageStatus, ValidationFailedResponse } from '~/services/ApiClient';

export interface SendMessageDialogStoreState {
    messageStatus?: OptimisticOutgoingConversationMessageStatus;
    messageBody?: MessageBody;
}

export const defaultStoreState: SendMessageDialogStoreState = {
    messageStatus: undefined,
    messageBody: undefined,
};

export const sendMessageDialogReducer: Reducer = (
    state: SendMessageDialogStoreState = defaultStoreState,
    action: ActionTypes
): SendMessageDialogStoreState => {
    switch (action.type) {
        case ActionTypeKeys.MESSAGES_SEND_PENDING:
            return {
                ...state,
                messageStatus: OptimisticOutgoingConversationMessageStatus.Sending,
                messageBody: action.meta.messageBody,
            };
        case ActionTypeKeys.MESSAGES_SEND_FULFILLED:
            return {
                ...state,
                messageStatus: OptimisticOutgoingConversationMessageStatus.Pending,
                messageBody: action.meta.messageBody,
            };
        case ActionTypeKeys.MESSAGES_SEND_REJECTED:
            return {
                ...state,
                messageStatus:
                    action.payload instanceof ValidationFailedResponse
                        ? OptimisticOutgoingConversationMessageStatus.ValidationFailure
                        : OptimisticOutgoingConversationMessageStatus.GeneralFailure,
                messageBody: action.meta.messageBody,
            };
        default:
            return state;
    }
};
