import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { IgnitionIconFormatterInnerProps, IgnitionIconFormatterProps } from './component';
import { IgnitionIconFormatterComponent } from './component';
import { IgnitionIconFormatterStyleRules } from './styles';

export const IgnitionIconFormatter = compose<IgnitionIconFormatterInnerProps, IgnitionIconFormatterProps>(
    setDisplayName('IgnitionIconFormatter'),
    withStyles(IgnitionIconFormatterStyleRules)
)(IgnitionIconFormatterComponent);
