import { createAction } from 'redux-actions';

import type { PojoModel } from '~/services/ApiClient';
import {
    ApiClient,
    QueryRoleRequest,
    QueryRolesRequest,
    Role,
    UpdatePermissionRequest,
    UpdateRoleRequest,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import { USER_ROLES_ACTION_TYPE } from './actionTypes';
import type { GetUserRolesArgs, GetUsersPermissionsArgs } from './models';

export const retrieveUserRoles = createAction(USER_ROLES_ACTION_TYPE.POST, ({ includeSystemRoles }: GetUserRolesArgs) =>
    retryableRequest(() =>
        ApiClient.queryRoles(
            createApiModel(QueryRolesRequest, {
                includeSystemRoles,
            })
        )
    )
);

export const retrieveUserPermissions = createAction(
    USER_ROLES_ACTION_TYPE.POST_QUERY_ROLE,
    ({ roleName }: GetUsersPermissionsArgs) =>
        retryableRequest(() => ApiClient.queryRole(createApiModel(QueryRoleRequest, { roleName })))
);

export const createNewRole = createAction(USER_ROLES_ACTION_TYPE.CREATE, (role: PojoModel<Role>) =>
    retryableRequest(() => {
        const { name, isAdministrator = false, description, isEnabled = false, roleId = '' } = role;

        const roleInstance = new Role();
        roleInstance.init({
            name,
            description,
            isEnabled,
            isAdministrator,
            roleId,
        });

        const updateRoleRequest = new UpdateRoleRequest();
        updateRoleRequest.init({ role: roleInstance });

        return ApiClient.addRole(updateRoleRequest);
    })
);

export const updateRole = createAction(USER_ROLES_ACTION_TYPE.UPDATE_ROLE, (role: PojoModel<Role>) => {
    return retryableRequest(() => {
        return ApiClient.updateRole(
            createApiModel(UpdateRoleRequest, {
                role: createApiModel(Role, role),
            })
        );
    });
});

export const updateUserPermission = createAction(
    USER_ROLES_ACTION_TYPE.UPDATE_PERMISSION,
    (roleName: string, path: string) => {
        return retryableRequest(() => {
            return ApiClient.updatePermission(createApiModel(UpdatePermissionRequest, { roleName, path }));
        });
    }
);
