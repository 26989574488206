import _NavigationIcon from '@mui/icons-material/Navigation';
import { styled } from '@mui/material';

import {
    AvailableIcon as _AvailableIcon,
    DrivingIcon as _DrivingIcon,
    RestingIcon as _RestingIcon,
    WorkingIcon as _WorkingIcon,
} from '~/components/DriverActivity';

const commonStyles = {
    height: '100%',
    width: '100%',
};

interface NavigationIconProps {
    courseOverGround: number;
}

const NavigationIcon = styled(_NavigationIcon, {
    shouldForwardProp: (propName) => propName !== 'courseOverGround',
})<NavigationIconProps>(({ courseOverGround }) => ({
    ...commonStyles,
    transform: `rotate(${courseOverGround}deg)`,
}));

const DrivingIcon = styled(_DrivingIcon)(commonStyles);
const WorkingIcon = styled(_WorkingIcon)(commonStyles);
const AvailableIcon = styled(_AvailableIcon)(commonStyles);
const RestingIcon = styled(_RestingIcon)(commonStyles);

export { DrivingIcon, NavigationIcon, WorkingIcon, AvailableIcon, RestingIcon };
