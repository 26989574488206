import type { SingleTFunction } from '~/components/LanguageSelector';
import { isNil, isObject } from '~/libs/utility';
import type { Classification, ClassificationAbstraction, ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { nullFilterValue } from './constants';

const castValueAsClassification = (value: unknown): ClassificationAbstraction | undefined => {
    const classification = isObject(value) ? (value as ClassificationAbstraction) : undefined;
    return classification;
};

const castValueAsClassifications = (value: unknown): ClassificationAbstraction[] | undefined => {
    const classification = isObject(value) ? (value as ClassificationAbstraction[]) : undefined;
    return classification;
};
export const classificationFactory =
    (t: SingleTFunction, classificationType: ClassificationType) =>
    (value: Classification): ClassificationAbstraction => {
        return {
            id: value.id,
            key: value.key,
            displayName: formatClassification(t, classificationType, value),
        };
    };

export const classificationsFilterEquals = (filterValue: unknown, cellValue: unknown): boolean => {
    if (filterValue === nullFilterValue) {
        if (isNil(cellValue)) {
            return true;
        } else {
            return false;
        }
    }

    const filterValueClassification = castValueAsClassification(filterValue);
    const cellValueClassifications = castValueAsClassifications(cellValue);

    if (filterValueClassification !== undefined && cellValueClassifications !== undefined) {
        return cellValueClassifications.some(
            (cellValueClassification) => filterValueClassification.id === cellValueClassification.id
        );
    }

    return false;
};

export const classificationFilterEquals = (filterValue: unknown, cellValue: unknown): boolean => {
    if (filterValue === nullFilterValue) {
        if (isNil(cellValue)) {
            return true;
        } else {
            return false;
        }
    }

    const filterValueClassification = castValueAsClassification(filterValue);
    const cellValueClassification = castValueAsClassification(cellValue);

    if (filterValueClassification && cellValueClassification) {
        return filterValueClassification.id === cellValueClassification.id;
    } else if (filterValueClassification && typeof cellValue === 'string') {
        return filterValueClassification.key === (cellValue as string);
    }

    return filterValue === cellValue;
};

export const getClassificationOptions = <T>(
    values: T[],
    factory: (value: T) => ClassificationAbstraction,
    hasNullFilterValue: boolean = true
): (ClassificationAbstraction | string)[] => {
    if (values) {
        const output: (ClassificationAbstraction | string)[] = values.map((value) => factory(value as T));
        if (hasNullFilterValue) {
            output.push(nullFilterValue);
        }
        return output;
    }

    return [];
};
