import type { Component } from 'react';
import { compose } from 'react-recompose';

import { withAutoSizer } from '~/components/AutoSizer';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { requiredMapUserPreferences, withMapSharedProps } from '~/components/MapSharedProps';
import { ensureUserPreferences } from '~/components/UserPreferences';

import { SceneMapViewComponent } from './component';
import type { SceneMapViewInnerProps, SceneMapViewProps } from './models';

export type { SceneMapViewProps } from './models';

export const SceneMapView: new <T>(props: SceneMapViewProps<T>) => Component<SceneMapViewProps<T>, {}> = compose<
    SceneMapViewInnerProps<never>,
    SceneMapViewProps<never>
>(
    withAutoSizer,
    withDisplayPreferences(),
    ensureUserPreferences([requiredMapUserPreferences]),
    withMapSharedProps()
)(SceneMapViewComponent);
