import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { LatestMessagesSectionContentInnerProps, LatestMessagesSectionContentProps } from './component';
import { LatestMessagesSectionContentComponent } from './component';
import { LatestMessagesSectionContentStyleRules } from './styles';

export const LatestMessagesSectionContent = compose<
    LatestMessagesSectionContentInnerProps,
    LatestMessagesSectionContentProps
>(
    setDisplayName('LatestMessagesSectionContent'),
    withTranslation(),
    withStyles(LatestMessagesSectionContentStyleRules)
)(LatestMessagesSectionContentComponent);
