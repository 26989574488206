import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type {
    UnresolvedConversationWorkflowBodyInnerProps,
    UnresolvedConversationWorkflowBodyProps,
} from './component';
import { UnresolvedConversationWorkflowBodyComponent } from './component';

export const UnresolvedConversationWorkflowBody = compose<
    UnresolvedConversationWorkflowBodyInnerProps,
    UnresolvedConversationWorkflowBodyProps
>(
    setDisplayName('UnresolvedConversationWorkflowBody'),
    withTranslation()
)(UnresolvedConversationWorkflowBodyComponent);
