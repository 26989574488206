import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

import type { AssetReference } from '~/common';
import { SettingsKey } from '~/components/EnsureSettings';
import type { SceneAssetSelectorUserPreferences } from '~/components/SceneAssetSelector';
import type { UserPreferencesKeyState } from '~/components/UserPreferences';
import { isEqual } from '~/libs/utility';
import type { RetrievableHashedData, StoreState } from '~/reducers';
import { settingsStoreStateSelector, staticDataStoreStateSelector, userPreferencesStateSelector } from '~/selectors';
import type { ResolvedAdminDriverRead } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';

import { ADMINDRIVER_ASSETSELECTOR_USERPREFERENCES_KEY } from './consts';
import type { AdminDriverStoreState } from './models';
import { parseDriverAdminChangelogsEntries } from './utils';

const driverAdministrationStateSelector = (state: StoreState): AdminDriverStoreState => state.driverAdministrationScene;

const driverAdministrationChangelogStateSelector = createSelector(
    [driverAdministrationStateSelector, staticDataStoreStateSelector],
    (adminDriver, staticData) => ({
        ...adminDriver.changelogs,
        data: parseDriverAdminChangelogsEntries({ changelogs: adminDriver?.changelogs?.data, staticData }),
    })
);

const retrieveDriverSelector = (state: StoreState, driverId: number) => {
    const { adminDrivers } = driverAdministrationStateSelector(state);
    return adminDrivers?.data?.hash[driverId];
};

const getSelectedAdminDrivers = (
    drivers: RetrievableHashedData<ResolvedAdminDriverRead>,
    selectedAssetIds?: AssetReference[]
) => {
    if (!drivers.fulfilled || !drivers.data?.hash) {
        return [];
    }

    if (!selectedAssetIds) {
        return drivers.data.array;
    }

    return selectedAssetIds
        .filter((selectedAsset) => selectedAsset.type === AssetType.Driver)
        .map((selectedAsset) => drivers.data.hash[selectedAsset.id])
        .filter(Boolean) as ResolvedAdminDriverRead[];
};

const deepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
const driversAdministrationSceneStateSelectortate = deepEqualSelector(
    driverAdministrationStateSelector,
    settingsStoreStateSelector,
    userPreferencesStateSelector,
    (driverAdminState, settingsState, userPreferencesState) => {
        const { actualData: assetPrefActual } = userPreferencesState[
            ADMINDRIVER_ASSETSELECTOR_USERPREFERENCES_KEY
        ] as UserPreferencesKeyState<SceneAssetSelectorUserPreferences>;

        const items = getSelectedAdminDrivers(driverAdminState.adminDrivers, assetPrefActual?.selectedAssetIds);

        return {
            driverAdministrationSecurables: settingsState[SettingsKey.SECURABLES].data.assetAdministration.drivers,
            items,
        };
    }
);

export {
    driverAdministrationStateSelector,
    driverAdministrationChangelogStateSelector,
    retrieveDriverSelector,
    driversAdministrationSceneStateSelectortate,
};
