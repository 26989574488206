import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, ensureSettings } from '~/components/EnsureSettings';
import { settingsStoreStateSelector, staticDataStoreStateSelector } from '~/selectors';

import { driverActivitiesReportStateSelector } from '../../selectors';

import type { DriverActivitiesReportDialogInnerProps, DriverActivitiesReportDialogProps, OwnProps } from './component';
import { DriverActivitiesReportDialogComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export { driverActivitiesReportStoreReducer } from './reducers';

export const DriverActivitiesReportDialog = compose<
    DriverActivitiesReportDialogInnerProps,
    DriverActivitiesReportDialogProps
>(
    setDisplayName('DriverActivitiesReportDialog '),
    withDisplayPreferences(),
    ensureSettings(
        [SettingsKey.DRIVERACTIVITYTYPES, SettingsKey.DRIVERSUBACTIVITYTYPES, SettingsKey.DRIVEREVENTTYPES],
        false
    ),
    connect(
        createSelector(
            staticDataStoreStateSelector,
            settingsStoreStateSelector,
            driverActivitiesReportStateSelector,
            (_: never, ownProps: OwnProps) => ownProps,
            mapStateToProps
        ),
        mapDispatchToProps
    )
)(DriverActivitiesReportDialogComponent);
