import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type NumericWorkflowFieldClassKey = 'leftAlignment' | 'rightAlignment' | 'centerAlignment';

export const NumericWorkflowFieldStyleRules: StyleRulesCallback<{}, {}, NumericWorkflowFieldClassKey> = () =>
    createStyles({
        leftAlignment: {
            textAlign: 'left',
        },
        rightAlignment: {
            textAlign: 'right',
        },
        centerAlignment: {
            textAlign: 'center',
        },
    });
