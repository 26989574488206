import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoizeOne } from '~/services/Memoize';

const formatOnMemoized = memoizeOne((t: SingleTFunction) => t('ignition-status-on'));
const formatOffMemoized = memoizeOne((t: SingleTFunction) => t('ignition-status-off'));
const formatNotAvailableMemoized = memoizeOne((t: SingleTFunction) => t('ignition-status-unknown'));

export const formatIgnitionStatusTitle = (t: SingleTFunction, ignitionStatus?: boolean): string => {
    return ignitionStatus === true
        ? formatOnMemoized(t)
        : ignitionStatus === false
          ? formatOffMemoized(t)
          : formatNotAvailableMemoized(t);
};
