import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withTranslation } from '~/components/LanguageSelector';

import { historyStateSelector } from '../../../../selectors';

import type { PositionGroupsContainerInnerProps, PositionGroupsContainerProps } from './component';
import { PositionGroupsContainerComponent } from './component';
import { mapDispatchProps, mapStateToProps } from './redux';
import { PositionGroupsContainerStyleRules } from './styles';

export type { TimelineItemClassKey } from './components/TimelineItem';

export const PositionGroupsContainer = compose<PositionGroupsContainerInnerProps, PositionGroupsContainerProps>(
    setDisplayName('PositionGroupsContainer'),
    withTranslation(),
    withStyles(PositionGroupsContainerStyleRules),
    connect(createSelector(historyStateSelector, mapStateToProps), mapDispatchProps)
)(PositionGroupsContainerComponent);
