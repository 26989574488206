import type { ComponentType, FC } from 'react';
import { useCallback, useContext } from 'react';

import { omit } from '~/libs/utility';
import type { Notification } from '~/services/ApiClient';
import { subscribeToNotificationsAndRetrieveTheData } from '~/services/SignalR';

import type { SubscriptionsContext } from './context';
import { SubscriptionContext } from './context';

export interface ComponentIdentifier {
    componentId: string;
}

export interface InjectedSubscriptionProviderProps {
    subscribe<T extends Notification>(
        resourceType: string,
        parse: (data: unknown) => T,
        handler: (notification: T) => void,
        retrieveData: () => void,
        onUnsubscribed: () => void
    ): void;
    unsubscribe(resourceType: string): void;
}

export type ExtendedPropType = ComponentIdentifier & InjectedSubscriptionProviderProps;

export const subscriptionProviderHoc = <PropType extends ComponentIdentifier>(
    WrappedComponent: ComponentType<PropType>
): FC<PropType & InjectedSubscriptionProviderProps> => {
    return (props: ExtendedPropType) => {
        const subscriptionContext = useContext<SubscriptionsContext>(SubscriptionContext);

        const { componentId } = { ...props };

        const subscribe = useCallback(
            async <T extends Notification>(
                resourceType: string,
                parse: (data: unknown) => T,
                handler: (notification: T) => void,
                retrieveData: () => void,
                onUnsubscribed: () => void
            ): Promise<void> => {
                let isFirstSubscription = false;

                if (!subscriptionContext.state[resourceType]) {
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    const emptyFunction = () => {};

                    subscriptionContext.state[resourceType] = {
                        components: {},
                        resourceSubscriptionDisposable: {
                            dispose: emptyFunction,
                        },

                        onResourceUnsubscribed: emptyFunction,
                    };

                    isFirstSubscription = true;
                }

                subscriptionContext.state[resourceType].components[componentId] = {};

                if (isFirstSubscription) {
                    const disposable = await subscribeToNotificationsAndRetrieveTheData(
                        resourceType,
                        parse,
                        handler,
                        retrieveData
                    );
                    subscriptionContext.state[resourceType].resourceSubscriptionDisposable = disposable;
                    subscriptionContext.state[resourceType].onResourceUnsubscribed = onUnsubscribed;
                }
            },
            [componentId, subscriptionContext]
        );

        const unsubscribe = useCallback(
            (resourceType: string) => {
                if (!subscriptionContext.state[resourceType]?.components?.[componentId]) {
                    return;
                }

                let isLastSubscription = false;

                subscriptionContext.state[resourceType].components = omit(
                    subscriptionContext.state[resourceType].components,
                    [componentId]
                );

                if (Object.keys(subscriptionContext.state[resourceType].components).length === 0) {
                    isLastSubscription = true;
                }

                if (isLastSubscription) {
                    subscriptionContext.state[resourceType].resourceSubscriptionDisposable.dispose();
                    subscriptionContext.state[resourceType].onResourceUnsubscribed();
                    subscriptionContext.state = omit(subscriptionContext.state, [resourceType]);
                }
            },
            [componentId, subscriptionContext]
        );

        return <WrappedComponent {...(props as unknown as PropType)} subscribe={subscribe} unsubscribe={unsubscribe} />;
    };
};
