import { createAction } from 'redux-actions';

import type { TachoLincStatus, TachoLincStatusResponse } from '~/services/ApiClient';
import {
    ApiClient,
    ApiException,
    ResetCardSlot,
    ServerResultStatus,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import { TACHOLINC_STATUS_ACTION_TYPE } from './actionTypes';

export const retrieveTachoLincStatus = createAction<Promise<TachoLincStatusResponse>>(
    TACHOLINC_STATUS_ACTION_TYPE.GET,
    () => retryableRequest(() => ApiClient.getTachoLincStatus())
);

const resetCardSlotExecutor = async (item: TachoLincStatus): Promise<ServerResultStatus> => {
    try {
        await ApiClient.resetCardslot(createApiModel(ResetCardSlot, { item }));
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

export const resetCardSlotAction = createAction(TACHOLINC_STATUS_ACTION_TYPE.RESET, resetCardSlotExecutor);
