import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { CommonSectionProps } from '../../models';

import type { FuelSectionInnerProps } from './component';
import { FuelSectionComponent } from './component';

export const FuelSection = compose<FuelSectionInnerProps, CommonSectionProps>(
    setDisplayName('FuelSection'),
    withTranslation()
)(FuelSectionComponent);
