import { createAction } from 'redux-actions';

import { ApiClient, ApiException, ServerResultStatus, retryableRequest } from '~/services/ApiClient';

import { ACTION_KEYS } from './actionTypes';

export const retrieveAlertDefinitions = createAction(ACTION_KEYS.GET_ALERTS, () =>
    retryableRequest(() => ApiClient.queryDefinitions())
);

export const retrieveAlertSummary = createAction(ACTION_KEYS.GET_ALERT_SUMMARY, (alertId: number) => {
    return retryableRequest(() => ApiClient.getDefinition(alertId));
});

export const deleteAlertSummary = createAction(ACTION_KEYS.DELETE_ALERT, (alertId: number) => {
    return retryableRequest(() => ApiClient.deleteAlertDefinition(String(alertId)));
});

export const queryAssetGroupsAdminList = createAction(ACTION_KEYS.GET_ASSET_GROUPS, async () => {
    try {
        const { items } = await ApiClient.getAdminAssetGroups();

        return items;
    } catch (error) {
        if (ApiException.isApiException(error) && error.status === 400) {
            throw ServerResultStatus.BADREQUEST;
        }

        throw ServerResultStatus.SERVERERROR;
    }
});
