import { Divider, Paper } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { PopupWidgetClassKey } from './styles';

export enum PopupWidgetMinimizedMode {
    HIDDEN = 'hidden',
    NORENDER = 'no-render',
}

export interface PopupWidgetProps {
    title: JSX.Element;
    actions?: JSX.Element;
    content: JSX.Element;
    footer?: JSX.Element;
    isMinimized: boolean;
    minimizedMode?: PopupWidgetMinimizedMode;
    width: number;
    height: number;
    toggleMinimize: () => void;
    dataId: string;
}

export interface PopupWidgetInnerProps extends PopupWidgetProps, WithStyles<PopupWidgetClassKey> {}

export const PopupWidgetComponent: React.FC<PopupWidgetInnerProps> = ({
    classes,
    isMinimized,
    minimizedMode,
    actions,
    title,
    footer,
    content,
    width,
    height,
    toggleMinimize,
    dataId,
}) => {
    const popupStyle = isMinimized ? undefined : { height };

    const contentElement = (
        <>
            <Divider />
            <div className={classes.content} data-id="popup-widget-content">
                {content}
            </div>
        </>
    );

    const footerElement = footer && (
        <>
            <Divider />
            <div data-id="popup-widget-footer">{footer}</div>
        </>
    );

    const renderContent = !isMinimized || minimizedMode === PopupWidgetMinimizedMode.HIDDEN;
    const contentAndFooter = renderContent && (
        <div className={classes.contents}>
            {contentElement}
            {footerElement}
        </div>
    );

    return (
        <div style={{ width }} className={classes.root} data-id={dataId}>
            <div className={classes.container}>
                <Paper className={classes.popup} style={popupStyle} elevation={3}>
                    <div className={classes.header} data-id="popup-widget-header">
                        <div className={classes.titleAndActions} onClick={toggleMinimize}>
                            <div className={classes.title}>{title}</div>
                            {actions && <div className={classes.actions}>{actions}</div>}
                        </div>
                    </div>
                    {contentAndFooter}
                </Paper>
            </div>
        </div>
    );
};
