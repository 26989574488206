import type { Duration } from 'moment';
import moment from 'moment';
import * as React from 'react';

import { DurationFormatter, ViolationFieldFormatterFactory, ViolationType } from '~/components/Formatters';
import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import type { ViolationField } from '~/services/ApiClient';
import { formatDriverSubpageListItemLabel, formatDuration } from '~/services/Formatters';

import { SectionName } from '../../constants';

export interface LaborSectionProps {
    dragHandleElement: JSX.Element;
    toggleCollapsed: () => void;
    isCollapsed: boolean;
    continuousLabourShort?: ViolationField<Duration>;
    labourShortBreak?: Duration;
    continuousLabourLong?: ViolationField<Duration>;
    labourLongBreak?: Duration;
    nightLabour?: ViolationField<Duration>;
    weekLabour?: ViolationField<Duration>;
    weekLabourAverage?: ViolationField<Duration>;
}

export interface LaborSectionInnerProps extends LaborSectionProps, InjectedTranslationProps {}

export const LaborSectionComponent: React.FC<LaborSectionInnerProps> = ({
    t,
    dragHandleElement,
    toggleCollapsed,
    isCollapsed,
    continuousLabourShort,
    labourShortBreak,
    continuousLabourLong,
    labourLongBreak,
    nightLabour,
    weekLabour,
    weekLabourAverage,
}) => {
    const ViolationDurationFormatter = React.useMemo(
        () => ViolationFieldFormatterFactory((v: Duration) => formatDuration(v)),
        []
    );

    const keyValueItems: KeyValueItem[] = [
        {
            valueDataId: 'continuous-labor-short',
            label: formatDriverSubpageListItemLabel(t, 'labor-continuous-labor-short'),
            value: continuousLabourShort ? (
                <ViolationDurationFormatter
                    t={t}
                    fieldName={formatDriverSubpageListItemLabel(t, 'labor-continuous-labor-short')}
                    value={continuousLabourShort}
                />
            ) : undefined,
        },
        {
            valueDataId: 'short-labor-break',
            label: formatDriverSubpageListItemLabel(t, 'labor-short-labor-break'),
            value: labourShortBreak ? (
                <DurationFormatter
                    threshold={moment.duration()}
                    violationType={ViolationType.ABOVE}
                    value={labourShortBreak}
                />
            ) : undefined,
        },
        {
            valueDataId: 'continuous-labor-long',
            label: formatDriverSubpageListItemLabel(t, 'labor-continuous-labor-long'),
            value: continuousLabourLong ? (
                <ViolationDurationFormatter
                    t={t}
                    fieldName={formatDriverSubpageListItemLabel(t, 'labor-continuous-labor-long')}
                    value={continuousLabourLong}
                />
            ) : undefined,
        },
        {
            valueDataId: 'long-labor-break',
            label: formatDriverSubpageListItemLabel(t, 'labor-long-labor-break'),
            value: labourLongBreak ? (
                <DurationFormatter
                    threshold={moment.duration()}
                    violationType={ViolationType.ABOVE}
                    value={labourLongBreak}
                />
            ) : undefined,
        },
        {
            valueDataId: 'night-labor',
            label: formatDriverSubpageListItemLabel(t, 'labor-night-labor'),
            value: nightLabour ? (
                <ViolationDurationFormatter
                    t={t}
                    fieldName={formatDriverSubpageListItemLabel(t, 'labor-night-labor')}
                    value={nightLabour}
                />
            ) : undefined,
        },
        {
            valueDataId: 'weekly-labor',
            label: formatDriverSubpageListItemLabel(t, 'labor-weekly-labor'),
            value: weekLabour ? (
                <ViolationDurationFormatter
                    t={t}
                    fieldName={formatDriverSubpageListItemLabel(t, 'labor-weekly-labor')}
                    value={weekLabour}
                />
            ) : undefined,
        },
        {
            valueDataId: 'average-weekly-labor',
            label: formatDriverSubpageListItemLabel(t, 'labor-average-weekly-labor'),
            value: weekLabourAverage ? (
                <ViolationDurationFormatter
                    t={t}
                    fieldName={formatDriverSubpageListItemLabel(t, 'labor-average-weekly-labor')}
                    value={weekLabourAverage}
                />
            ) : undefined,
        },
    ];

    return (
        <ReorderableSection
            title={t(`driver-subpage-${SectionName.LABOR}`)}
            dataId={SectionName.LABOR}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            dragHandleElement={dragHandleElement}
        >
            <KeyValueList list={keyValueItems} listDataId="property-list" />
        </ReorderableSection>
    );
};
