import type { SingleTFunction } from '~/components/LanguageSelector';
import type { AssetGroup, AssetRecipient, AssetType } from '~/services/ApiClient';

import { extractGroups } from './extractGroups';

export const mapGroupOptions = (
    assetGroups: AssetGroup[],
    t: SingleTFunction,
    assetType: AssetType,
    assetPredicate?: (assetId: number) => boolean
): AssetRecipient[] => {
    const groupsCombined: AssetRecipient[] = [];

    assetGroups.forEach((group) => {
        extractGroups(group, groupsCombined, t, assetType, assetPredicate);
    });

    return groupsCombined;
};
