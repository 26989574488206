import { Component, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { InactivityMonitor, withScreenAnalytics } from '~/components/Analytics';
import { LandingPage } from '~/components/Navigation';
import { NotAuthorizedPageSnackbar } from '~/components/NotAuthorizedPageSnackbar';
import { ReloadPage } from '~/components/ReloadPage';
import { SaveUserPreferencesStatusMonitor } from '~/components/SaveUserPreferencesMonitor';
import { SignalRConnectionMonitor } from '~/components/SignalRConnectionMonitor';
import { SynchronizationClock } from '~/components/SynchronizationClock';
import { HandleUserPreferencesStateUpdate, LoadUserPreferencesStatusMonitor } from '~/components/UserPreferences';
import { CustomerCareLogin, EndImpersonation, Logout, SingleSignOn, UserLogin } from '~/scenes/Authentication';
import { ResetPassword } from '~/scenes/ResetPassword';
import { saveUserPreferencesService } from '~/services/SaveUserPreferences';
import { NotificationsClient } from '~/services/SignalR';

import { GeozoneNotifications } from './components/GeozoneNotifications';
import { SceneLoader } from './components/SceneLoader';
import { DecoratedMainApp } from './MainApp';

const DecoratedSingleSignOn = withScreenAnalytics('singlesignon')(SingleSignOn);
const DecoratedUserLogin = withScreenAnalytics('userlogin')(UserLogin);
const DecoratedCustomerCareLogin = withScreenAnalytics('customercarelogin')(CustomerCareLogin);
const DecoratedLogout = withScreenAnalytics('logout')(Logout);
const DecoratedEndImpersonation = withScreenAnalytics('endimpersonation')(EndImpersonation);

const FeatureFlags = lazy(() => import('~/scenes/FeatureFlags'));

export interface AppInnerProps {}

export interface AppState {
    hasError: boolean;
}

export class App extends Component<AppInnerProps, AppState> {
    public constructor(props: AppInnerProps) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    public componentDidCatch(): void {
        this.setState({ hasError: true });
    }

    public render(): JSX.Element {
        if (this.state.hasError) {
            return <ReloadPage handleReload={this.reloadPage} />;
        }
        const resetPasswordPaths = ['/reset-password', `/reset-password/:token=[A-Za-z0-9\\-]+`];

        return (
            <>
                <SynchronizationClock />

                <Suspense fallback={<SceneLoader />}>
                    <Switch>
                        <Route exact path="/" component={LandingPage} />

                        <Route path="/login" component={DecoratedUserLogin} />
                        <Route path="/customer-care" component={DecoratedCustomerCareLogin} />
                        <Route path="/logout" component={DecoratedLogout} />
                        <Route path="/end-impersonation" component={DecoratedEndImpersonation} />
                        <Route path="/single-sign-on" component={DecoratedSingleSignOn} />
                        <Route path="/feature-flags" component={FeatureFlags} />
                        <Route path="/unauthorized" component={NotAuthorizedPageSnackbar} />
                        <Route path={resetPasswordPaths} component={ResetPassword} />

                        <Route component={DecoratedMainApp} />
                    </Switch>
                </Suspense>

                <SignalRConnectionMonitor signalRConnection={NotificationsClient} />
                <HandleUserPreferencesStateUpdate />
                <LoadUserPreferencesStatusMonitor />
                <SaveUserPreferencesStatusMonitor savePreferencesService={saveUserPreferencesService} />
                <InactivityMonitor />
                <GeozoneNotifications />
            </>
        );
    }

    private reloadPage = () => window.location.reload();
}
