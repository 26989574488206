import { booleanToNumber } from './booleanToNumber';
import { baseComparer, defaultComparer, formattedComparer, stringComparer } from './comparers';
import { dateToTimestamp } from './dateToTimestamp';

export { defaultComparer, stringComparer };

export const compareFactory = <T, C>(
    format: (a: T) => C,
    comparer: (a: C, b: C) => number
): ((a: T, b: T) => number) => {
    return baseComparer(formattedComparer(format, comparer));
};

export const compareNumbers = baseComparer(defaultComparer);
export const compareStrings = baseComparer(stringComparer);
export const compareBooleans = compareFactory(booleanToNumber, defaultComparer);
export const compareDateObjects = compareFactory(dateToTimestamp, defaultComparer);
