import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ToggleSettingItemClassKeys = 'action';

export const ToggleSettingItemStyleRules: StyleRulesCallback<Theme, {}, ToggleSettingItemClassKeys> = (theme) =>
    createStyles({
        action: {
            marginRight: theme.spacing(-1),
        },
    });
