import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { GroupingPaneContainerInnerProps, GroupingPaneContainerProps } from './component';
import { GroupingPaneContainerComponent } from './component';
import { GroupingPaneContainerStyleRules } from './styles';

export const GroupingPaneContainer = compose<GroupingPaneContainerInnerProps, GroupingPaneContainerProps>(
    setDisplayName('GroupingPaneContainer'),
    withStyles(GroupingPaneContainerStyleRules)
)(GroupingPaneContainerComponent);
