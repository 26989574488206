export enum ColumnName {
    DOWNLOADDATE = 'download-date',
    VEHICLE = 'vehicle',
    VEHICLEALIAS = 'vehicle-alias',
    MSISDN = 'msisdn',
    DRIVER = 'driver',
    FIRSTNAME = 'first-name',
    LASTNAME = 'last-name',
    SOURCE = 'source',
    NATIONALPHACODE = 'nation-alpha-code',
    COUNTRY = 'country',
    UPLOADSTATUS = 'upload-status',
    DETAILS = 'details',
    UPLOADEVENTDATE = 'upload-event-date',
    UPLOADEVENTDETAILS = 'upload-event-details',
    EXPORTSTATUS = 'export-status',
    EXPORTDATE = 'export-date',
}
