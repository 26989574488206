import type { TilesTypes } from '@fv/components';

import type { DisplayUserPreferencesUnitSystem, MapLayersSecurables } from '~/services/ApiClient';

import { getBaseMapLayersProps } from './layers.base';
import { getExtraMapLayersProps } from './layers.extra';
import type { MapProfile } from './mapProfile';
import type { MapViewMode } from './mapViewMode';

export const getMapLayersProps = (
    timestamp: number,
    mapViewMode: MapViewMode,
    truckAttributesOverlayEnabled: boolean,
    trafficIncidentsOverlayEnabled: boolean,
    mapLayersSecurables: MapLayersSecurables,
    mapProfile: MapProfile,
    unitSystem: DisplayUserPreferencesUnitSystem,
    language?: string
): TilesTypes[] => [
    ...getBaseMapLayersProps({
        truckAttributesOverlayEnabled,
        trafficIncidentsOverlayEnabled,
        language,
        mapViewMode,
        mapProfile,
        unitSystem,
        mapLayersSecurables,
    }),
    ...getExtraMapLayersProps({
        timestamp,
        truckAttributesOverlayEnabled,
        trafficIncidentsOverlayEnabled,
        mapLayersSecurables,
        mapProfile,
        unitSystem,
        language,
    }),
];
