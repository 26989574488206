import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './constants';

export const HISTORY_LIST_DRIVER_USERPREFERENCES_KEY = 'history-grid-driver-state';

export const defaultDriverListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.DATETIME,
        ColumnName.DRIVERID,
        ColumnName.DRIVER,
        ColumnName.MSISDN,
        ColumnName.DRIVERACTIVITY,
        ColumnName.SUBACTIVITY,
        ColumnName.DISTANCE,
        ColumnName.DURATION,
        ColumnName.CARDSTATUS,
        ColumnName.VEHICLE,
        ColumnName.CODRIVERID,
        ColumnName.CODRIVER,
        ColumnName.ADDRESS,
        ColumnName.CITY,
        ColumnName.POSTALCODE,
        ColumnName.COUNTRYCODE,
        ColumnName.STARTODOMETER,
        ColumnName.ENDODOMETER,
        ColumnName.NEARESTCITY,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.ISCORRECTION,
    ],
    visibleColumns: [
        ColumnName.VEHICLE,
        ColumnName.DATETIME,
        ColumnName.MSISDN,
        ColumnName.ADDRESS,
        ColumnName.CITY,
        ColumnName.POSTALCODE,
        ColumnName.COUNTRYCODE,
        ColumnName.STARTODOMETER,
        ColumnName.ENDODOMETER,
        ColumnName.NEARESTCITY,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.DRIVERID,
        ColumnName.CODRIVERID,
        ColumnName.DRIVERACTIVITY,
        ColumnName.ISCORRECTION,
    ],
    columnWidths: [
        { columnName: ColumnName.DRIVER, width: 175 },
        { columnName: ColumnName.DATETIME, width: 150 },
        { columnName: ColumnName.SUBACTIVITY, width: 75 },
        { columnName: ColumnName.DURATION, width: 100 },
        { columnName: ColumnName.CARDSTATUS, width: 100 },
        { columnName: ColumnName.CODRIVER, width: 175 },
        { columnName: ColumnName.VEHICLE, width: 200 },
        { columnName: ColumnName.MSISDN, width: 150 },
        { columnName: ColumnName.ADDRESS, width: 200 },
        { columnName: ColumnName.CITY, width: 175 },
        { columnName: ColumnName.POSTALCODE, width: 100 },
        { columnName: ColumnName.COUNTRYCODE, width: 90 },
        { columnName: ColumnName.STARTODOMETER, width: 100 },
        { columnName: ColumnName.ENDODOMETER, width: 100 },
        { columnName: ColumnName.NEARESTCITY, width: 250 },
        { columnName: ColumnName.LATITUDE, width: 100 },
        { columnName: ColumnName.LONGITUDE, width: 100 },
        { columnName: ColumnName.DRIVERID, width: 90 },
        { columnName: ColumnName.CODRIVERID, width: 90 },
        { columnName: ColumnName.DRIVERACTIVITY, width: 75 },
        { columnName: ColumnName.DISTANCE, width: 100 },
        { columnName: ColumnName.ISCORRECTION, width: 75 },
    ],
    sorting: [{ columnName: ColumnName.DATETIME, direction: GridSortingDirection.DESC }],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: true,
    filters: [],
    filteringEnabled: true,
};
