import type { ClassNameMap, StyledComponentProps, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { PositionStatusContent } from '~/components/PositionStatusContent';
import type { Position, PositionStatus } from '~/services/ApiClient';

import type { TimelineItemClassKey, TimelineSegmentType } from '../TimelineItem';
import { TimelineItem } from '../TimelineItem';

import type { IndividualPositionClassKey } from './styles';

export interface IndividualPositionProps {
    positionStatus: PositionStatus;
    segmentType: TimelineSegmentType;
    dataId: string;
    highlightPosition: (position?: Position) => void;
    panToPosition: () => void;
    timelineClasses?: Partial<ClassNameMap<TimelineItemClassKey>>;
}

export interface IndividualPositionOuterProps
    extends IndividualPositionProps,
        StyledComponentProps<TimelineItemClassKey> {}

export interface IndividualPositionInnerProps extends IndividualPositionProps, WithStyles<IndividualPositionClassKey> {}

export const IndividualPositionComponent: React.FC<IndividualPositionInnerProps> = ({
    positionStatus,
    classes,
    dataId,
    segmentType,
    highlightPosition,
    panToPosition,
    timelineClasses,
}) => {
    const mouseEnterHandler = React.useCallback(() => {
        highlightPosition(positionStatus.position);
    }, [highlightPosition, positionStatus.position]);

    const mergedTimelineItemClasses = {
        ...timelineClasses,
        root: classNames(timelineClasses?.root, classes.root),
    };

    return (
        <TimelineItem
            ListItemProps={{ onMouseEnter: mouseEnterHandler, onClick: panToPosition }}
            segmentType={segmentType}
            segmentDotSize="small"
            classes={mergedTimelineItemClasses}
            dataId={dataId}
        >
            <PositionStatusContent positionStatus={positionStatus} />
        </TimelineItem>
    );
};
