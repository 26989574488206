import type { Dispatch } from 'redux';

import { createRtdsSchedulesAction, updateRtdsScheduleAction } from '~/data/rtdsschedules';
import type { NumericDictionary } from '~/libs/utility';
import type { Classification, RtdsSchedule, TachographDataBlock } from '~/services/ApiClient';

export interface ScheduleEditorReduxProps extends Omit<DispatchProps, ''>, StateProps {}
export interface StateProps {
    tachoDataBlocks: NumericDictionary<TachographDataBlock>;
    priorityTypes: NumericDictionary<Classification>;
    frequencyTypes: NumericDictionary<Classification>;
    weekDayTypes: NumericDictionary<Classification>;
    ordinalTypes: NumericDictionary<Classification>;
}

export const mapStateToProps = (
    tachographDataBlock: NumericDictionary<TachographDataBlock>,
    rtdsPriority: NumericDictionary<Classification>,
    rtdsFrequency: NumericDictionary<Classification>,
    rtdsWeekDayTypes: NumericDictionary<Classification>,
    rtdsOrdinalTypes: NumericDictionary<Classification>
): StateProps => {
    return {
        tachoDataBlocks: tachographDataBlock,
        priorityTypes: rtdsPriority,
        frequencyTypes: rtdsFrequency,
        weekDayTypes: rtdsWeekDayTypes,
        ordinalTypes: rtdsOrdinalTypes,
    };
};

export interface DispatchProps {
    createRtdsSchedules: (items: RtdsSchedule[]) => void;
    updateRtdsSchedule: (scheduleId: number, item: RtdsSchedule) => void;
}

export const mapDispatchProps = (dispatch: Dispatch): DispatchProps => ({
    createRtdsSchedules: (items: RtdsSchedule[]) => {
        dispatch(createRtdsSchedulesAction(items));
    },
    updateRtdsSchedule: (scheduleId: number, item: RtdsSchedule) => {
        dispatch(updateRtdsScheduleAction(scheduleId, item));
    },
});

export const mergeProps = (
    { tachoDataBlocks, ...restStateProps }: StateProps,
    { createRtdsSchedules, updateRtdsSchedule, ...restDispatchProps }: DispatchProps
): ScheduleEditorReduxProps => {
    return {
        ...restStateProps,
        ...restDispatchProps,
        createRtdsSchedules,
        updateRtdsSchedule,
        tachoDataBlocks,
    };
};
