import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ContainerTemperatureSensorInnerProps, ContainerTemperatureSensorProps } from './component';
import { ContainerTemperatureSensorComponent } from './component';
import { ContainerTemperatureSensorStyleRules } from './styles';

export const ContainerTemperatureSensor = compose<
    ContainerTemperatureSensorInnerProps,
    ContainerTemperatureSensorProps
>(
    setDisplayName('Container'),
    withStyles(ContainerTemperatureSensorStyleRules),
    withTranslation()
)(ContainerTemperatureSensorComponent);
