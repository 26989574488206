import type { ApiModel, PojoModel } from './models';

export const createApiModel = <T extends ApiModel>(Ctor: new () => T, data: PojoModel<T>): T => {
    const instance = new Ctor();

    if (data) {
        Object.assign(instance, data);
    }

    return instance;
};
