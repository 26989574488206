import type { CalculateUnderlyingDriverIdsAndSubgroupsArgs, CalculationResult } from './models';

export const calculateUnderlyingDriverIdsAndSubgroups = ({
    assetGroup,
    result,
    predicate,
}: CalculateUnderlyingDriverIdsAndSubgroupsArgs): CalculationResult => {
    let sumOfSubgroups = result.subgroups;
    let concatenatedDrivers = assetGroup.driverIds ? result.driverIds.concat(assetGroup.driverIds) : result.driverIds;
    if (assetGroup.assetGroups) {
        assetGroup.assetGroups.forEach((group) => {
            const recursion = calculateUnderlyingDriverIdsAndSubgroups({
                assetGroup: group,
                result: { driverIds: concatenatedDrivers, subgroups: sumOfSubgroups, vehicleIds: [] },
            });
            concatenatedDrivers = recursion.driverIds;
            sumOfSubgroups = recursion.subgroups + 1;
        });
    }

    const enabledAssetIds = predicate ? concatenatedDrivers.filter(predicate) : concatenatedDrivers;

    return {
        vehicleIds: [],
        driverIds: concatenatedDrivers,
        subgroups: sumOfSubgroups,
        enabledAssetIds,
    };
};
