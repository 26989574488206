import { Button, FormControl, TextField } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { ButtonProgress } from '~/components/ButtonProgress';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { Severity, ValidationBox } from '~/components/ValidationBox';
import type { Credentials } from '~/data/authentication';

import type { LoginFormClassKey } from './styles';

export interface CustomerCareLoginFormStateProps {
    requestFailed: boolean;
    authenticationError: boolean;
    passwordExpired: boolean;
    authenticating: boolean;
}

export interface CustomerCareLoginFormDispatchProps {
    authenticate: (credentials: Credentials) => void;
}

export interface CustomerCareLoginFormProps {
    defaultValidationMessage?: string;
}

export interface CustomerCareLoginFormInnerProps
    extends CustomerCareLoginFormStateProps,
        CustomerCareLoginFormDispatchProps,
        CustomerCareLoginFormProps,
        InjectedTranslationProps,
        WithStyles<LoginFormClassKey> {}

export interface CustomerCareLoginFormState {
    username: string;
    customerCareUsername: string;
    customerCarePassword: string;
    customer: string;
}

export class CustomerCareLoginFormComponent extends React.Component<
    CustomerCareLoginFormInnerProps,
    CustomerCareLoginFormState
> {
    constructor(props: CustomerCareLoginFormInnerProps) {
        super(props);

        this.state = {
            username: '',
            customer: '',
            customerCareUsername: '',
            customerCarePassword: '',
        };
    }

    public render(): JSX.Element {
        const {
            t,
            classes,
            authenticationError,
            passwordExpired,
            authenticating,
            requestFailed,
            defaultValidationMessage,
        } = this.props;

        const validationMessageText = requestFailed
            ? t('not-able-to-process-request')
            : passwordExpired
              ? t('password-expired')
              : authenticationError
                ? t('invalid-credentials')
                : defaultValidationMessage;

        return (
            <form
                method="post"
                data-id="customer-care-login-form"
                className={classes.form}
                onSubmit={this.handleSubmit}
                autoComplete="on"
            >
                {validationMessageText && <ValidationBox message={validationMessageText} severity={Severity.Error} />}
                <div className={classes.fields}>
                    <TextField
                        variant="standard"
                        label={t('customer')}
                        data-id="customer"
                        autoFocus
                        required
                        fullWidth
                        margin="normal"
                        value={this.state.customer}
                        onChange={this.handleChange('customer')}
                    />
                    <TextField
                        variant="standard"
                        label={t('username')}
                        data-id="username"
                        required
                        fullWidth
                        margin="normal"
                        value={this.state.username}
                        onChange={this.handleChange('username')}
                    />
                    <TextField
                        variant="standard"
                        label={t('customer-care-username')}
                        data-id="customer-care-username"
                        required
                        autoComplete="username"
                        fullWidth
                        margin="normal"
                        value={this.state.customerCareUsername}
                        onChange={this.handleChange('customerCareUsername')}
                    />
                    <TextField
                        variant="standard"
                        label={t('customer-care-password')}
                        data-id="customer-care-password"
                        required
                        autoComplete="current-password"
                        fullWidth
                        margin="normal"
                        value={this.state.customerCarePassword}
                        onChange={this.handleChange('customerCarePassword')}
                        type="password"
                    />
                    <FormControl variant="standard" fullWidth margin="normal">
                        <Button
                            color="secondary"
                            data-id="sign-in"
                            type="submit"
                            variant="contained"
                            disabled={!this.canLogin || authenticating}
                        >
                            {t('sign-in')}
                        </Button>
                        <ButtonProgress showProgress={authenticating} />
                    </FormControl>
                </div>
            </form>
        );
    }

    private get canLogin(): boolean {
        return !!(
            this.state.username &&
            this.state.customerCareUsername &&
            this.state.customer &&
            this.state.customerCarePassword
        );
    }

    private handleChange(key: keyof CustomerCareLoginFormState): (e: React.ChangeEvent<HTMLInputElement>) => void {
        return (e) => {
            const obj = {};
            obj[key] = e.target.value;
            this.setState(obj);
        };
    }

    private handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const credentials = {
            username: this.state.username,
            customer: this.state.customer,
            customerCareUsername: this.state.customerCareUsername,
            password: this.state.customerCarePassword,
        };
        this.props.authenticate(credentials);
    };
}
