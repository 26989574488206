export enum ColumnName {
    SCHEDULEID = 'scheduled-id',
    TYPE = 'type',
    STARTDATE = 'start-date',
    FREQUENCY = 'frequency',
    DESCRIPTION = 'description',
    NEXTRUN = 'next-run',
    INSTANCE = 'instance',
    REQUESTEDTACHODATA = 'requested-tacho-data',
    DEVICETYPE = 'device-type',
    DEVICEENABLED = 'device-enabled',
    RESPONSEENABLED = 'response-enabled',
    ASSET = 'asset',
}
