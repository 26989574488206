import { createSelectorCreator, defaultMemoize } from 'reselect';

import { formatToSelect, getValueByPath } from '~/common';
import { SettingsKey } from '~/components/EnsureSettings';
import type { SceneAssetSelectorUserPreferences } from '~/components/SceneAssetSelector';
import type { UserPreferencesKeyState } from '~/components/UserPreferences';
import { isEmpty, isEqual, isNil, keyBy } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { settingsStoreStateSelector, staticDataStoreStateSelector, userPreferencesStateSelector } from '~/selectors';
import { AssetType } from '~/services/ApiClient';

import { TRAILERADMIN_ASSETSELECTOR_USERPREFERENCES_KEY } from './constants';
import type {
    DropdownSelector,
    FilterGridSceneByAssetProps,
    GetTrailerById,
    ResolvedTrailersAdministration,
    ResolvedTrailersAdministrationCompartmentStatus,
    ResolverDevicesProps,
    TrailersAdministrationState,
} from './models';

// this should be extracted to a common folder, will be removed when devices administration get merged with master
const deepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
const trailersAdministrationSceneState = (state: StoreState): TrailersAdministrationState =>
    state.trailersAdministrationScene;

const resolverTrailers = ({ trailersAdministration, staticData, settings }: ResolverDevicesProps) =>
    (trailersAdministration?.list?.items || []).map(
        (trailer): ResolvedTrailersAdministration => ({
            ...trailer,
            depot: getValueByPath(staticData, `depots.data.hash[${trailer?.depotId}]`),
            batteryType: getValueByPath(settings, `trailerBatteryType.data[${trailer?.batteryType}]`),
            trailerManufacturer: getValueByPath(settings, `trailerManufacturers.data[${trailer?.manufacturer}]`),
            reeferManufacturer: getValueByPath(settings, `reeferManufacturers.data[${trailer?.reeferManufacturer}]`),
            ebsManufacturer: getValueByPath(settings, `ebsManufacturer.data[${trailer?.ebsManufacturer}]`),
            tpmsManufacturer: getValueByPath(settings, `tpmsManufacturer.data[${trailer?.tpmsManufacturer}]`),
            reeferBatteryType: getValueByPath(settings, `reeferBatteryType.data[${trailer?.reeferBatteryType}]`),
            reeferDataInterface: getValueByPath(settings, `reeferDataInterface.data[${trailer?.reeferDataInterface}]`),
            settings: {
                ...trailer?.settings,
                monitoring: getValueByPath(settings, `trailerMonitoring.data[${trailer?.settings?.monitoring}]`),
                offlineAlarm: getValueByPath(settings, `trailerOfflineAlarm.data[${trailer?.settings?.offlineAlarm}]`),
                compartments: (trailer?.settings?.compartments || []).map(
                    (compartment): ResolvedTrailersAdministrationCompartmentStatus => ({
                        ...compartment,
                        bandwidthLinked: getValueByPath(
                            settings,
                            `trailerBandwidthLinked.data[${compartment?.bandwidthLinked}]`
                        ),
                    })
                ),
            },
        })
    );

const filterGridSceneByAssetSelector = <T>({ items, type, selectedAssetIds }: FilterGridSceneByAssetProps<T>): T[] => {
    if (isEmpty(items)) {
        return [];
    }

    if (isNil(selectedAssetIds)) {
        return items;
    }

    const itemsByKey = keyBy(items, 'id');

    const filteredByType = selectedAssetIds.reduce(
        (acc, selectedAsset) => (selectedAsset.type === type ? [...acc, selectedAsset.id] : acc),
        []
    );

    return filteredByType.reduce((acc, id) => (itemsByKey[id] ? [...acc, itemsByKey[id]] : acc), []);
};

const getTrailersAdministrationState = deepEqualSelector(
    trailersAdministrationSceneState,
    staticDataStoreStateSelector,
    settingsStoreStateSelector,
    userPreferencesStateSelector,
    (trailersAdministration, staticData, settings, preferences) => {
        const { actualData: assetPrefActual } = preferences[
            TRAILERADMIN_ASSETSELECTOR_USERPREFERENCES_KEY
        ] as UserPreferencesKeyState<SceneAssetSelectorUserPreferences>;

        const items = filterGridSceneByAssetSelector({
            items: resolverTrailers({ trailersAdministration, settings, staticData }),
            type: AssetType.Trailer,
            selectedAssetIds: assetPrefActual?.selectedAssetIds,
        });
        return {
            items,
            trailerAdministrationSecurables: settings[SettingsKey.SECURABLES].data.assetAdministration.trailers,
        };
    }
);

const getTrailerById =
    (selected?: number) =>
    (state: StoreState): GetTrailerById => {
        const { list, crud } = trailersAdministrationSceneState(state);
        return {
            items: (list.items || [])
                .filter(({ id }) => id !== selected)
                .reduce((acc, { trailerNumber }) => (isNil(trailerNumber) ? acc : [...acc, trailerNumber]), []),
            selected: (list?.items || []).find(({ id }) => id === selected),
            ...crud,
        };
    };

const getDefaultSettings = (state: StoreState) => {
    const { defaultSettings } = trailersAdministrationSceneState(state);
    return { defaultSettings };
};

const dropDownValues = (state: StoreState): DropdownSelector => {
    const options = { displayName: 'key' };

    const { depots } = staticDataStoreStateSelector(state);
    const {
        ebsManufacturer,
        reeferBatteryType,
        reeferDataInterface,
        reeferManufacturers,
        tpmsManufacturer,
        trailerBatteryType,
        trailerManufacturers,
        trailerMonitoring,
        trailerOfflineAlarm,
        trailerBandwidthLinked,
    } = settingsStoreStateSelector(state);

    return {
        depots: formatToSelect({ source: depots.data.array, options: { displayName: 'name', key: 'id' } }),
        bandwidthLinked: formatToSelect({ source: trailerBandwidthLinked.data, options }),
        ebsManufacturer: formatToSelect({ source: ebsManufacturer.data, options }),
        reeferBatteryType: formatToSelect({ source: reeferBatteryType.data, options }),
        reeferDataInterface: formatToSelect({ source: reeferDataInterface.data, options }),
        reeferManufacturers: formatToSelect({ source: reeferManufacturers.data, options }),
        tpmsManufacturer: formatToSelect({ source: tpmsManufacturer.data, options }),
        batteryType: formatToSelect({ source: trailerBatteryType.data, options }),
        manufacturers: formatToSelect({ source: trailerManufacturers.data, options }),
        monitoring: formatToSelect({ source: trailerMonitoring.data, options }),
        offlineAlarm: formatToSelect({ source: trailerOfflineAlarm.data, options }),
    };
};

export {
    dropDownValues,
    trailersAdministrationSceneState,
    getTrailersAdministrationState,
    getTrailerById,
    getDefaultSettings,
};
