import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import type { DateTimeSettingInnerProps, DateTimeSettingProps } from './component';
import { DateTimeSettingComponent } from './component';
import { DateTimeSettingStyleRules } from './styles';

export const DateTimeSetting = compose<DateTimeSettingInnerProps, DateTimeSettingProps>(
    setDisplayName('DateTimeSetting'),
    withStyles(DateTimeSettingStyleRules)
)(DateTimeSettingComponent);
