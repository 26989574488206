import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        flexGrow: 0,
        paddingLeft: theme.spacing(2 /* original padding */ + 3 /* expand/collapse spacing */),
        gap: theme.spacing(),
    },
    iconContainer: {
        minWidth: theme.spacing(3),
    },
    icon: {
        padding: 0,
    },
    checkbox: {
        padding: 0,
    },
    clearSearchText: {
        cursor: 'pointer',
    },
}));

export { useStyles };
