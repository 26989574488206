import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withSceneAnalytics } from '~/components/Analytics';
import { keyDataStateSelectorFactory as etherealStateSelector } from '~/components/EtherealState';
import { withFeatureFlags } from '~/components/FeatureFlags';
import { withTranslation } from '~/components/LanguageSelector';
import { MONITORING_DATETIMERANGEPICKER_USERPREFERENCES_KEY } from '~/data/monitoring';

import { MONITORING_DATETIMERANGE } from '../../../../consts';

import type { HistorySubpageInnerProps, HistorySubpageProps } from './component';
import { HistorySubpageComponent } from './component';
import { mapDispatchProps, mapStateToProps } from './redux';
import { historyStateSelector } from './selectors';
import { HistorySubpageStyleRules } from './styles';

export { requiredHistorySubpageUserPreferences } from './preferences';
export { historySubpageReducer } from './storestate';

export const HistorySubpage = compose<HistorySubpageInnerProps, HistorySubpageProps>(
    setDisplayName('HistorySubpage'),
    withSceneAnalytics('historysubpage'),
    connect(
        createSelector(
            historyStateSelector,
            etherealStateSelector(MONITORING_DATETIMERANGEPICKER_USERPREFERENCES_KEY, MONITORING_DATETIMERANGE),
            mapStateToProps
        ),
        mapDispatchProps
    ),
    withStyles(HistorySubpageStyleRules),
    withTranslation(),
    withFeatureFlags()
)(HistorySubpageComponent);
