import { isValid } from 'date-fns';

import { isNil } from '~/libs/utility';

const cleanUpValues = <T, S>(values: T): S | undefined => {
    if (isNil(values) || !values) {
        return {} as S;
    }

    return Object.entries(values).reduce((acc, [key, value]) => {
        if (!isValid(value) && !Array.isArray(value) && typeof value === 'object') {
            return { ...acc, [key]: cleanUpValues(value) };
        }

        return { ...acc, [key]: !Number.isFinite(value) && (isNil(value) || !value) ? undefined : value };
    }, {} as S);
};

export { cleanUpValues };
