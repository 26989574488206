import { CircularProgress, Paper } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { LoadingIndicatorClassKey } from './styles';
import { circularProgressSize } from './styles';

export interface LoadingIndicatorProps {}

export interface LoadingIndicatorInnerProps extends LoadingIndicatorProps, WithStyles<LoadingIndicatorClassKey> {}

export const LoadingIndicatorComponent: React.FC<LoadingIndicatorInnerProps> = ({ classes }) => {
    return (
        <Paper className={classes.root} data-id="loading-indicator">
            <CircularProgress size={circularProgressSize} />
        </Paper>
    );
};
