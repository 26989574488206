import { styled } from '@mui/material/styles';
import { Virtuoso as _Virtuoso } from 'react-virtuoso';

const Virtuoso = styled(_Virtuoso)({
    'div[data-viewport-type="element"]': {
        // add dynamic width for the first child
        width: 'auto',
    },
});

const EmptyPlaceholderContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
}));

export { Virtuoso, EmptyPlaceholderContainer };
