import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import * as React from 'react';

export const SortLabelComponent: React.FC<TableHeaderRow.SortLabelProps> = (props) => {
    const dataIdSortAttribute = props.direction
        ? {
              'data-id': `sorted:${props.direction}`,
          }
        : undefined;
    return <TableHeaderRow.SortLabel {...props} {...dataIdSortAttribute} />;
};
