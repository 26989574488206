import { createAction } from 'redux-actions';

import { logEvent } from '~/services/Analytics';
import type {
    ConversationNotification,
    ConversationProfile,
    ConversationProfilesResponse,
    ConversationSummary,
    CreatedEntityResponse,
    MarkAllMessagesAsReadResponse,
    OptimisticOutgoingConversationMessage,
    ResolvedMessage,
    TranslateMessageResponse,
} from '~/services/ApiClient';
import {
    ApiClient,
    ApiException,
    IncomingMessage,
    MarkAllMessagesAsReadRequest,
    SaveConversationProfileRequest,
    SendConversationMessageRequest,
    SendMessageOptions,
    TextMessageBody,
    TranslateMessageRequest,
    ValidationFailedResponse,
    WorkflowMessageBody,
    createApiModel,
    resolveWhenStatusCodeIn,
    retryableRequest,
} from '~/services/ApiClient';

import type {
    ConversationMessagesPayload,
    ConversationPayload,
    ConversationProfileActionEditTypeMeta,
    ConversationProfileActionTypeMeta,
    ConversationsPayload,
    DiscardConversationMessageMeta,
    MessageActionTypeMeta,
    SendConversationMessageMeta,
} from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const retrieveConversationsExecutor = (
    conversationProfileId: number,
    showDeletedMessagesInConversations: boolean
): Promise<ConversationsPayload> => {
    return retryableRequest(() =>
        ApiClient.getConversations(conversationProfileId, showDeletedMessagesInConversations)
    );
};

export const retrieveConversationsAction = createAction(
    ActionTypePrefixes.CONVERSATIONS,
    retrieveConversationsExecutor
);

export const updateConversationsAction = createAction<ConversationSummary>(ActionTypeKeys.CONVERSATIONS_UPDATE);

export const applyPendingUpdatesAction = createAction(ActionTypeKeys.CONVERSATIONS_APPLY_PENDING);

export const retrieveConversationExecutor = (
    vehicleId: number,
    conversationProfileId: number,
    includeDeleted: boolean
): Promise<ConversationPayload> => {
    return retryableRequest(() => ApiClient.getConversation(vehicleId, conversationProfileId, includeDeleted)).then(
        (res) => {
            const messagesCharLength = res.item.messages.reduce((acc, message) => {
                if (message instanceof IncomingMessage && message.body instanceof TextMessageBody) {
                    return acc + message.body.message.length;
                }

                return acc;
            }, 0);

            logEvent('messaging', `incoming-text-messages-loaded`, `Client has loaded incoming text messages`, {
                value: messagesCharLength,
            });
            return res;
        }
    );
};

export const sendConversationMessageExecutor = async (
    vehicleId: number,
    optimisticMessage: OptimisticOutgoingConversationMessage,
    options: SendMessageOptions
): Promise<void> => {
    let success = false;
    try {
        try {
            await retryableRequest(() =>
                ApiClient.sendConversationMessage(
                    optimisticMessage.correlationId,
                    vehicleId,
                    createApiModel(SendConversationMessageRequest, {
                        body: optimisticMessage.body,
                        options: createApiModel(SendMessageOptions, options),
                    })
                )
            );
        } catch (e) {
            if (e instanceof ApiException && e.status === 400 && e.result instanceof ValidationFailedResponse) {
                throw e.result;
            }
            throw e;
        }

        success = true;
    } finally {
        if (optimisticMessage.body instanceof WorkflowMessageBody) {
            logEvent('messaging', 'send-workflow', 'Send workflow', { value: success ? 1 : 0 });
        } else {
            logEvent('messaging', 'send-message', 'Send message', { value: success ? 1 : 0 });
        }
    }
};

export const markConversationMessageAsReadActionExecutor = (vehicleId: number, messageId: number): Promise<void> => {
    return retryableRequest(() => ApiClient.markAsRead(vehicleId, messageId));
};

export const markConversationMessageAsUnreadActionExecutor = (vehicleId: number, messageId: number): Promise<void> => {
    return retryableRequest(() => ApiClient.markAsUnread(vehicleId, messageId));
};

export const markAllConversationMessageAsReadActionExecutor = (
    vehicleId: number,
    conversationProfileId: number,
    upToAndIncludingMessageId: number
): Promise<MarkAllMessagesAsReadResponse> => {
    return retryableRequest(() =>
        ApiClient.markAllMessagesAsRead(
            vehicleId,
            conversationProfileId,
            createApiModel(MarkAllMessagesAsReadRequest, { upToAndIncludingMessageId })
        )
    );
};

export const markConversationMessageAsReadAction = createAction(
    ActionTypePrefixes.CONVERSATION_MARKMESSAGEASREAD,
    markConversationMessageAsReadActionExecutor,
    (vehicleId: number, messageId: number): MessageActionTypeMeta => ({ vehicleId, messageId })
);

export const markConversationMessageAsUnreadAction = createAction(
    ActionTypePrefixes.CONVERSATION_MARKMESSAGEASUNREAD,
    markConversationMessageAsUnreadActionExecutor,
    (vehicleId: number, messageId: number): MessageActionTypeMeta => ({ vehicleId, messageId })
);

export const deleteConversationMessageActionExecutor = (vehicleId: number, messageId: number): Promise<void> => {
    return retryableRequest(() => ApiClient.deleteConversationMessage(vehicleId, messageId));
};

export const undeleteConversationMessageActionExecutor = (vehicleId: number, messageId: number): Promise<void> => {
    return retryableRequest(() => ApiClient.undeleteConversationMessage(vehicleId, messageId));
};

export const deleteConversationMessageAction = createAction(
    ActionTypePrefixes.CONVERSATION_DELETEMESSAGE,
    deleteConversationMessageActionExecutor,
    (vehicleId: number, messageId: number): MessageActionTypeMeta => ({ vehicleId, messageId })
);

export const undeleteConversationMessageAction = createAction(
    ActionTypePrefixes.CONVERSATION_UNDELETEMESSAGE,
    undeleteConversationMessageActionExecutor,
    (vehicleId: number, messageId: number): MessageActionTypeMeta => ({ vehicleId, messageId })
);

export const markAllConversationMessagesAsReadAction = createAction<
    Promise<MarkAllMessagesAsReadResponse>,
    number,
    number,
    number,
    number
>(
    ActionTypePrefixes.CONVERSATION_MARKALLMESSAGESASREAD,
    markAllConversationMessageAsReadActionExecutor,
    (vehicleId: number) => vehicleId
);

export const retrieveConversationAction = createAction(
    ActionTypePrefixes.CONVERSATION,
    retrieveConversationExecutor,
    (vehicleId: number) => vehicleId
);

export const updateConversationAction = createAction(
    ActionTypeKeys.CONVERSATION_UPDATE,
    (conversationNotification: ConversationNotification) => conversationNotification,
    ({ data: { vehicleId } }: ConversationNotification) => vehicleId
);

export const clearConversationAction = createAction(
    ActionTypeKeys.CONVERSATION_CLEAR,
    undefined,
    (vehicleId: number) => vehicleId
);

export const applyPendingUpdateAction = createAction(
    ActionTypeKeys.CONVERSATION_APPLY_PENDING,
    undefined,
    (vehicleId: number) => vehicleId
);

export const sendConversationMessageAction = createAction(
    ActionTypePrefixes.CONVERSATIONMESSAGE_SEND,
    sendConversationMessageExecutor,
    (vehicleId: number, optimisticMessage: OptimisticOutgoingConversationMessage): SendConversationMessageMeta => ({
        vehicleId,
        optimisticMessage,
    })
);

export const clearSendMessageErrorAction = createAction(
    ActionTypeKeys.CONVERSATIONMESSAGETEXT_CLEARSENDERROR,
    undefined,
    (vehicleId: number) => vehicleId
);

export const changeConversationMessageTextFocusAction = createAction(
    ActionTypeKeys.CONVERSATIONMESSAGETEXT_CHANGEFOCUS,
    (_: number, shouldFocusInput: boolean) => shouldFocusInput,
    (vehicleId: number) => vehicleId
);

export const openConversationAction = createAction(
    ActionTypeKeys.CONVERSATION_OPEN,
    (_: number, eventTrigger: string) => {
        logEvent('messaging', 'open-conversation', 'Open conversation', { eventTrigger });
        return undefined;
    },
    (vehicleId: number) => vehicleId
);

export const openConversationSucceededAction = createAction(
    ActionTypeKeys.CONVERSATION_OPEN_SUCCEEDED,
    undefined,
    (vehicleId: number) => vehicleId
);

export const openConversationRejectedAction = createAction(
    ActionTypeKeys.CONVERSATION_OPEN_REJECTED,
    undefined,
    (vehicleId: number) => vehicleId
);

export const retrieveConversationMessagesExecutor = (
    vehicleId: number,
    conversationProfileId: number,
    count: number,
    afterCursor: string,
    includeDeleted: boolean
): Promise<ConversationMessagesPayload> => {
    return retryableRequest(() =>
        ApiClient.getConversationMessages(
            vehicleId,
            count,
            undefined,
            afterCursor,
            conversationProfileId,
            includeDeleted
        ).then((res) => {
            const messagesCharLength = res.items.reduce((acc, message) => {
                if (message instanceof IncomingMessage && message.body instanceof TextMessageBody) {
                    return acc + message.body.message.length;
                }

                return acc;
            }, 0);

            logEvent('messaging', `incoming-text-messages-loaded`, `Client has loaded incoming text messages`, {
                value: messagesCharLength,
            });
            return res;
        })
    );
};

export const retrieveConversationMessagesAction = createAction(
    ActionTypePrefixes.CONVERSATIONMESSAGES,
    retrieveConversationMessagesExecutor,
    (vehicleId: number) => vehicleId
);

export const discardConversationMessageAction = createAction(
    ActionTypeKeys.CONVERSATIONMESSAGE_DISCARD,
    undefined,
    (vehicleId: number, message: OptimisticOutgoingConversationMessage): DiscardConversationMessageMeta => ({
        vehicleId,
        message,
    })
);

export const retrieveLatestWorkflowFormDefinitionsAction = createAction(
    ActionTypePrefixes.WORKFLOWFORMDEFINITIONS_LATEST,
    (lng: string) => retryableRequest(() => ApiClient.getLatestWorkflowFormDefinitions(lng))
);

export const retrieveWorkflowFormDefinitionAction = createAction(
    ActionTypePrefixes.WORKFLOWFORMDEFINITION,
    (formId: number, lng: string) => retryableRequest(() => ApiClient.getWorkflowFormDefinition(formId, lng)),
    (formId: number) => formId
);

export const retrieveConversationProfilesExecutor = (): Promise<ConversationProfilesResponse> =>
    retryableRequest(() => ApiClient.getConversationProfiles());

export const createConversationProfileExecutor = (item: ConversationProfile): Promise<CreatedEntityResponse> => {
    return retryableRequest(() =>
        ApiClient.createConversationProfile(createApiModel(SaveConversationProfileRequest, { item }))
    );
};

export const updateConversationProfileExecutor = (id: number, item: ConversationProfile): Promise<void> => {
    return retryableRequest(() =>
        ApiClient.updateConversationProfile(id, createApiModel(SaveConversationProfileRequest, { item }))
    );
};

export const deleteConversationProfileExecutor = (id: number): Promise<void> => {
    return retryableRequest(() => ApiClient.deleteConversationProfile(id).catch(resolveWhenStatusCodeIn(404)));
};

export const translateConversationMessageExecutor = (
    message: ResolvedMessage,
    targetLanguage: string
): Promise<TranslateMessageResponse> => {
    return retryableRequest(() =>
        ApiClient.translateMessage(
            createApiModel(TranslateMessageRequest, {
                message: (message.body.value as TextMessageBody).message,
                targetLanguage,
            })
        ).catch(resolveWhenStatusCodeIn(404))
    );
};

export const retrieveConversationProfilesAction = createAction(
    ActionTypePrefixes.CONVERSATIONPROFILES,
    retrieveConversationProfilesExecutor
);

export const createConversationProfileAction = createAction(
    ActionTypePrefixes.CONVERSATIONPROFILE_CREATE,
    createConversationProfileExecutor,
    (item: ConversationProfile): ConversationProfileActionTypeMeta => ({ item })
);

export const updateConversationProfileAction = createAction(
    ActionTypePrefixes.CONVERSATIONPROFILE_UPDATE,
    updateConversationProfileExecutor,
    (id: number, item: ConversationProfile): ConversationProfileActionEditTypeMeta => ({ id, item })
);

export const deleteConversationProfileAction = createAction<Promise<unknown>, number>(
    ActionTypePrefixes.CONVERSATIONPROFILE_DELETE,
    deleteConversationProfileExecutor,
    (id: number) => id
);

export const translateConversationMessageAction = createAction(
    ActionTypePrefixes.CONVERSATIONMESSAGE_TRANSLATE,
    translateConversationMessageExecutor,
    (message: ResolvedMessage, _targetLanguage: string) => ({
        vehicleId: message.value.vehicleId,
        messageId: message.value.id,
    })
);
