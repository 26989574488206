import type { HistoryVehicleEntry } from './models';

export const HistoryVehicleEquals = (rowA?: HistoryVehicleEntry, rowB?: HistoryVehicleEntry): boolean => {
    if (!rowA || !rowB) {
        return false;
    }

    const vehicleEqual = rowA.vehicleEvent.eventPosId === rowB.vehicleEvent.eventPosId;

    return vehicleEqual;
};
