import { Backdrop, CircularProgress, debounce } from '@mui/material';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router';

import { RefreshSceneBar, RefreshSceneBarState } from '~/common';
import { AskForPageReload } from '~/components/AskForPageReload';
import { PageTemplate } from '~/components/PageTemplate';
import type { DetailsPaneContextProps } from '~/components/SceneDetailsPane';
import { DetailsPaneContext } from '~/components/SceneDetailsPane';
import { SceneLoader } from '~/components/SceneLoader';
import { isUndefined } from '~/libs/utility';
import { Subpage } from '~/scenes/VehicleAdministration';
import type { ResolvedVehicleType } from '~/services/ApiClient';
import { EditorState } from '~/services/ApiClient';

import type { ContextMenuActions } from './components/ContextMenu';
import { CONTEXT_MENU_ACTIONS, VehicleTypeAdministrationContextMenu } from './components/ContextMenu';
import { DeleteVehicleTypeDialog } from './components/DeleteVehicleTypeDialog';
import { VehicleTypeAdministrationDetailsPane } from './components/DetailsPane';
import { VehicleTypesAdministrationsChangelogSubpage } from './components/DetailsPane/components/VehicleTypesAdministrationChangelogsSubpage';
import { VehicleTypesAdministrationListView, VehicleTypesAdministrationListViewHeader } from './components/List';
import { VehicleTypeEditor } from './components/VehicleTypeEditor';
import { constructVehicleTypesAdministrationUrl } from './constructVehicleTypesAdministrationUrl';
import type { VehicleTypesAdministrationComponentState, VehicleTypesAdministrationInnerProps } from './models';
import type { UrlParams } from './redux';
import { useStyles } from './styles';

export const VehicleTypesAdministrationComponent: React.FC<VehicleTypesAdministrationInnerProps> = ({
    resolvedVehicleTypes,
    vehicleTypesAdministrationAuthorizations,
    getAllVehicleTypes,
    loading,
    contentLoading,
    rejected,
    keepSubpageOpen,
}) => {
    const { vehicleTypeId, subpage } = useParams<UrlParams>();
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [editorDialog, setEditorDialog] = useState<React.ReactNode>(undefined);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [state, setState] = useState<VehicleTypesAdministrationComponentState>({
        triggerDataRefresh: {
            initialLoad: true,
            showBanner: false,
            triggered: false,
        },
    });

    const selectedEntry = useCallback(
        (id: number) => {
            if (isUndefined(id)) {
                return undefined;
            }

            return resolvedVehicleTypes.find((entry) => entry.id === id);
        },
        [resolvedVehicleTypes]
    );

    const onCloseRefreshSceneBar = React.useCallback(() => {
        setState({
            ...state,
            triggerDataRefresh: {
                showBanner: false,
                triggered: state.triggerDataRefresh.triggered,
                initialLoad: false,
            },
        });
    }, [state]);

    const handleRefreshData = React.useCallback(() => {
        setState({
            ...state,
            triggerDataRefresh: {
                triggered: !state.triggerDataRefresh.triggered,
                showBanner: true,
                initialLoad: false,
            },
        });
    }, [state]);

    const closeContextMenu = React.useCallback(() => {
        setState({
            ...state,
            prevVehicleType: state?.currentVehicleType,
            currentVehicleType: undefined,
            contextMenu: undefined,
        });
    }, [state]);

    const editorOnCloseHandler = React.useCallback(
        (isCancelled: boolean): void => {
            if (!isCancelled) {
                handleRefreshData();
            }
            setEditorDialog(undefined);
            closeContextMenu();
        },
        [closeContextMenu, handleRefreshData]
    );

    const openEditorDialog = React.useCallback(
        (operationMode: EditorState, entry?: ResolvedVehicleType): void => {
            setEditorDialog(
                <VehicleTypeEditor operationMode={operationMode} vehicleType={entry} onClose={editorOnCloseHandler} />
            );
        },
        [editorOnCloseHandler]
    );

    const onRowContextMenu = React.useCallback(
        (event: MouseEvent, rowId: number) => {
            setState({
                ...state,
                contextMenu: { clientX: event.clientX, clientY: event.clientY },
                currentVehicleType: selectedEntry(rowId),
                prevVehicleType: selectedEntry(rowId),
            });

            event.preventDefault();
            event.stopPropagation();
        },
        [selectedEntry, state]
    );

    const dispatchGetAllVehicleTypesDebounced = React.useMemo(
        () =>
            debounce(() => {
                getAllVehicleTypes();
            }, 500),
        [getAllVehicleTypes]
    );

    React.useEffect(() => {
        dispatchGetAllVehicleTypesDebounced();
    }, [dispatchGetAllVehicleTypesDebounced, state.triggerDataRefresh.triggered]);

    const selectVehicleTypeGridRow = React.useCallback(
        (selectedAdminVehicleTypeId: number) => {
            history.push(
                constructVehicleTypesAdministrationUrl(
                    selectedAdminVehicleTypeId,
                    keepSubpageOpen ? subpage : undefined
                )
            );
        },
        [history, keepSubpageOpen, subpage]
    );

    const closeDetailsPane = React.useCallback(() => {
        history.push(constructVehicleTypesAdministrationUrl());
    }, [history]);

    const getSubpageContext = React.useMemo(() => {
        const subpageContext: DetailsPaneContextProps = {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            openSubpage: () => {},
            close: () => {
                history.push(constructVehicleTypesAdministrationUrl(undefined, undefined));
            },
            goBack: () => {
                history.push(constructVehicleTypesAdministrationUrl(+vehicleTypeId, undefined));
            },
        };
        return subpageContext;
    }, [history, vehicleTypeId]);

    const openChangelogsSubpage = React.useCallback(
        (id: number) => {
            const changelogsSubpageUrl = constructVehicleTypesAdministrationUrl(id, Subpage.CHANGELOGS);
            history.push(changelogsSubpageUrl);
        },
        [history]
    );

    const closeDeleteDialog = () => {
        if (!vehicleTypeId) {
            history.push(constructVehicleTypesAdministrationUrl());
        }

        setDeleteDialogOpen(false);
    };

    const handleClickContextMenu = (action: ContextMenuActions) => {
        if (!state?.prevVehicleType) {
            return;
        }

        switch (action) {
            case CONTEXT_MENU_ACTIONS.DELETE:
                setDeleteDialogOpen(true);
                break;
            case CONTEXT_MENU_ACTIONS.MODIFY:
                openEditorDialog(EditorState.MODIFY, state?.prevVehicleType);
                break;
            case CONTEXT_MENU_ACTIONS.CHANGELOG:
                openChangelogsSubpage(state?.prevVehicleType?.id);
                break;

            default:
                break;
        }
    };

    const detailsPane = React.useMemo((): JSX.Element | undefined => {
        const entry = selectedEntry(+vehicleTypeId);

        if (subpage === Subpage.CHANGELOGS) {
            return (
                <DetailsPaneContext.Provider value={getSubpageContext}>
                    <VehicleTypesAdministrationsChangelogSubpage vehicleTypeId={+vehicleTypeId} />
                </DetailsPaneContext.Provider>
            );
        }

        return (
            entry && (
                <VehicleTypeAdministrationDetailsPane
                    entry={entry}
                    openEditorDialog={openEditorDialog}
                    onClose={closeDetailsPane}
                />
            )
        );
    }, [vehicleTypeId, subpage, getSubpageContext, selectedEntry, openEditorDialog, closeDetailsPane]);

    const refreshDataBanner = () => {
        if (
            (contentLoading || rejected) &&
            !state.triggerDataRefresh.initialLoad &&
            state.triggerDataRefresh.showBanner
        ) {
            return (
                <RefreshSceneBar
                    disableClose={contentLoading}
                    onClose={onCloseRefreshSceneBar}
                    state={rejected ? RefreshSceneBarState.ERROR : RefreshSceneBarState.REFRESHING}
                    stateAction={rejected ? handleRefreshData : undefined}
                />
            );
        }

        return undefined;
    };

    if (!vehicleTypesAdministrationAuthorizations.isAllowed) {
        return <Redirect to="/unauthorized" />;
    }

    if (rejected && state.triggerDataRefresh.initialLoad) {
        return <AskForPageReload />;
    }

    if (loading && !rejected) {
        return <SceneLoader />;
    }

    return (
        <div className={classes.root} data-id="vehicle-types-administration">
            <PageTemplate
                header={
                    <VehicleTypesAdministrationListViewHeader
                        title={t('vehicle-types-admin-module')}
                        dataSource={resolvedVehicleTypes}
                        onRefreshData={handleRefreshData}
                        contentLoading={contentLoading}
                        openEditorDialog={openEditorDialog}
                    />
                }
                topPaneElement={refreshDataBanner()}
                rightPaneElement={detailsPane}
            >
                <Backdrop
                    open={contentLoading && !!state.triggerDataRefresh.initialLoad}
                    className={classes.backDrop}
                    data-id="back-drop"
                >
                    <CircularProgress size={120} className={classes.loader} data-id="backdrop-loader" />
                </Backdrop>

                <VehicleTypesAdministrationListView
                    dataSource={resolvedVehicleTypes}
                    selectRow={selectVehicleTypeGridRow}
                    selectedAssetId={+vehicleTypeId}
                    onRowContextMenu={onRowContextMenu}
                    highlightedAssetId={state?.currentVehicleType?.id}
                />
            </PageTemplate>

            {state?.contextMenu && (
                <VehicleTypeAdministrationContextMenu
                    position={state?.contextMenu}
                    onClose={closeContextMenu}
                    onClick={handleClickContextMenu}
                />
            )}

            {deleteDialogOpen && (
                <DeleteVehicleTypeDialog
                    id={state?.prevVehicleType?.id}
                    name={state?.prevVehicleType?.name}
                    onCancel={closeDeleteDialog}
                    onRefreshData={handleRefreshData}
                />
            )}

            {editorDialog}
        </div>
    );
};
