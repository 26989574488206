import type { TimelineSegmentType } from './component';

export const splitTimelineSegmentType = (
    original: TimelineSegmentType
): { start: TimelineSegmentType; middle: TimelineSegmentType; stop: TimelineSegmentType } => {
    switch (original) {
        case 'begin':
            return { start: 'begin', middle: 'continuous', stop: 'continuous' };
        case 'continuous':
            return { start: 'continuous', middle: 'continuous', stop: 'continuous' };
        case 'end':
            return { start: 'continuous', middle: 'continuous', stop: 'end' };
        case 'hidden':
            return { start: 'hidden', middle: 'hidden', stop: 'hidden' };
        case 'point':
            return { start: 'begin', middle: 'continuous', stop: 'end' };
        default:
            throw new Error(`Unknown timeline segment type: ${original}`);
    }
};
