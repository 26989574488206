import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { SettingsKey, ensureSettings } from '~/components/EnsureSettings';

import { vehicleAdministrationChangelogsSelector } from '../../../../selectors';

import VehicleAdministrationChangelogsSubpageComponent from './component';
import type {
    VehicleAdministrationChangelogsSubpageInnerProps,
    VehicleAdministrationChangelogsSubpageProps,
} from './models';
import { mapDispatchProps, mapStateToProps } from './redux';

export { vehicleChangelogsReducer as vehicleAdministrationChangelogsReducer } from './reducers';

export type { VehicleAdministrationChangelogsStoreState } from './reducers';

export const VehicleAdministrationChangelogsSubpage = compose<
    VehicleAdministrationChangelogsSubpageInnerProps,
    VehicleAdministrationChangelogsSubpageProps
>(
    setDisplayName('VehicleAdministrationChangelogsSubpage'),
    ensureSettings([
        SettingsKey.DEVICETYPES,
        SettingsKey.VEHICLETYPES,
        SettingsKey.VEHICLECATEGORIES,
        SettingsKey.TACHOGRAPH,
        SettingsKey.TACHO_PROTOCOL,
        SettingsKey.TACHO_GENERATION,
        SettingsKey.WORKFLOW_TYPE,
        SettingsKey.IMAGE_CAPTURING_TYPE,
        SettingsKey.NAVIGATION_TYPE,
        SettingsKey.INHIBITOR_SERVICE_TYPE,
        SettingsKey.TELL_TALE_SERVICE_TYPE,
        SettingsKey.FUEL_TANK_TYPE,
    ]),
    connect(createSelector(vehicleAdministrationChangelogsSelector, mapStateToProps), mapDispatchProps)
)(VehicleAdministrationChangelogsSubpageComponent);
