export enum ColumnName {
    TYPE = 'type',
    MSISDN = 'msisdn',
    DRIVERID = 'driver-id',
    DRIVER = 'driver',
    VEHICLE = 'vehicle',
    IGNITIONSTATUS = 'ignition-status',
    STATUS = 'status',
    SCHEDULED = 'scheduled',
    EXPIRATION = 'expiration',
    QUEUED = 'queued',
    STARTED = 'started',
    AUTHENTICATED = 'authenticated',
    DOWNLOADED = 'downloaded',
    RECEIVED = 'received',
    FAILED = 'failed',
    LASTCHANGE = 'last-change',
    ERROR = 'error',
    FREQUENCY = 'frequency',
    RECURRENCE = 'recurrence',
    REQUESTEDTACHODATA = 'requested-tacho-data',
    SCHEDULEID = 'scheduled-id',
    INSTANCE = 'instance',
}
