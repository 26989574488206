import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { TemperatureSensorInnerProps, TemperatureSensorProps } from './component';
import { TemperatureSensorComponent } from './component';
import { TemperatureSensorStyleRules } from './styles';

export const TemperatureSensor = compose<TemperatureSensorInnerProps, TemperatureSensorProps>(
    setDisplayName('TemperatureSensor'),
    withStyles(TemperatureSensorStyleRules),
    withTranslation()
)(TemperatureSensorComponent);
