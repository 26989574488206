import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ConversationSummarySecondLineInnerProps, ConversationSummarySecondLineProps } from './component';
import { ConversationSummarySecondLineComponent } from './component';
import { ConversationSummarySecondLineStyleRules } from './styles';

export const ConversationSummarySecondLine = compose<
    ConversationSummarySecondLineInnerProps,
    ConversationSummarySecondLineProps
>(
    setDisplayName('ConversationSummarySecondLine'),
    withTranslation(),
    withStyles(ConversationSummarySecondLineStyleRules)
)(ConversationSummarySecondLineComponent);
