import type { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import classNames from 'classnames';
import React from 'react';

import { nullFilterValue } from '../../constants';
import type { GridColumnDefinition } from '../../models';

import { DateTimeEditor } from './components/DateTimeEditor';
import { DurationEditor } from './components/DurationEditor';
import { FilterDefaultCell } from './components/FilterDefaultCell';
import { FilterOptionsCell } from './components/FilterOptionsCell';
import {
    dateFilterOperations,
    defaultFilterOperations,
    durationFilterOperations,
    numberFilterOperations,
} from './constants';
import { useStyles } from './styles';

export interface FilterCellInnerProps extends TableFilterRow.CellProps {
    className?: string;
}

export const createFilterCellFactory: <T>(
    columnDefinitions: Array<GridColumnDefinition<T>>
) => React.FunctionComponent<FilterCellInnerProps> =
    (columnDefinitions) =>
    ({ className, ...props }: FilterCellInnerProps) => {
        const classes = useStyles();

        const cellClassName = classNames(className, { [classes.filtered]: props.filter });
        const columnDefinition = columnDefinitions.find((x) => x.name === props.column.name);

        const columnName = columnDefinition ? `column-filter:${columnDefinition.name}` : 'column-filter';
        const dataId = props.filter ? columnName.concat(':', 'highlighted') : columnName;

        if (columnDefinition?.cellFiltering) {
            return (
                <FilterOptionsCell
                    {...props}
                    data-id={dataId}
                    columnName={columnDefinition?.name}
                    options={columnDefinition.cellFiltering.options}
                    renderOptions={columnDefinition.cellFiltering.renderOptions}
                    valueTextFormatter={columnDefinition.valueTextFormatter}
                    className={cellClassName}
                />
            );
        }

        switch (columnDefinition?.dataType) {
            case 'boolean': {
                const booleanOptionValues = React.useMemo(() => {
                    return [true, false, nullFilterValue];
                }, []);

                return (
                    <FilterOptionsCell
                        {...props}
                        data-id={dataId}
                        columnName={columnDefinition?.name}
                        options={booleanOptionValues}
                        valueTextFormatter={columnDefinition.valueTextFormatter}
                        className={cellClassName}
                        hasSelectAll={false}
                    />
                );
            }

            case 'duration':
                return (
                    <FilterDefaultCell
                        {...props}
                        data-id={dataId}
                        dataId={dataId}
                        availableFilterOperations={durationFilterOperations}
                        editorComponent={DurationEditor}
                        align={columnDefinition.align}
                        classes={{ root: cellClassName }}
                    />
                );

            case 'number':
                return (
                    <FilterDefaultCell
                        {...props}
                        data-id={dataId}
                        availableFilterOperations={numberFilterOperations}
                        align={columnDefinition.align}
                        classes={{ root: cellClassName }}
                    />
                );

            case 'date':
                return (
                    <FilterDefaultCell
                        {...props}
                        data-id={dataId}
                        dataId={dataId}
                        availableFilterOperations={dateFilterOperations}
                        editorComponent={DateTimeEditor}
                        align={columnDefinition.align}
                        classes={{ root: cellClassName }}
                    />
                );

            default:
                return (
                    <FilterDefaultCell
                        {...props}
                        data-id={dataId}
                        availableFilterOperations={defaultFilterOperations}
                        align={columnDefinition?.align}
                        classes={{ root: cellClassName }}
                    />
                );
        }
    };
