import { Avatar, Divider, Paper, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import type { AutocompleteRenderGroupParams } from '@mui/material/Autocomplete';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import type { PaperProps } from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import type { WithStyles } from '@mui/styles';
import type { HTMLAttributes } from 'react';
import React from 'react';
import type { ListChildComponentProps, VariableSizeListProps } from 'react-window';
import { VariableSizeList } from 'react-window';

import type { AutoSizerInjectedProps } from '~/components/AutoSizer';
import { withAutoSizer } from '~/components/AutoSizer';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { AssetDepotIcon, AssetGroupIcon, CloseIcon, DriverIcon, TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector/';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type {
    AssetGroup,
    AssetRecipient,
    Depot,
    Driver,
    Vehicle,
    VehicleCategory,
    VehicleType,
} from '~/services/ApiClient';
import { AssetRecipientType, AssetType } from '~/services/ApiClient';

import { mapDepotOptions } from './mapDepotOptions';
import { mapDriverOptions } from './mapDriverOptions';
import { mapGroupOptions } from './mapGroupOptions';
import { mapVehicleOptions } from './mapVehicleOptions';
import type { RecipientsListClassKey } from './styles';

export interface RecipientsListProps {
    className?: string;
    setSelectedRecipients: (newValue: AssetRecipient[]) => void;
    selectedRecipients: AssetRecipient[];
    onOpenChange?: (value: boolean) => void;
    placeholder?: string;
    assetPredicate?: (assetId: number) => boolean;
    assetType: AssetType;
    displayName: string;
}

export interface RecipientListReduxProps {
    depots: Depot[];
    assetGroups: AssetGroup[];
    vehicles: Vehicle[];
    drivers: Driver[];
    loading: boolean;
    rejected: boolean;
    vehicleTypes: NumericDictionary<VehicleType>;
    vehicleCategories: NumericDictionary<VehicleCategory>;
}
export interface RecipientsListInnerProps
    extends RecipientsListProps,
        RecipientListReduxProps,
        InjectedTranslationProps,
        WithStyles<RecipientsListClassKey>,
        InjectedDisplayPreferencesProps {}

const RecipientListPaperComponent: React.FC<PaperProps> = (props) => <Paper {...props} elevation={4} />;

const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const OuterElementContext = React.useMemo(() => React.createContext({}), []);
    const OuterElementType = React.useMemo(
        () =>
            // eslint-disable-next-line react/no-unstable-nested-components
            React.forwardRef<HTMLDivElement>((elProps, elRef) => {
                const outerProps = React.useContext(OuterElementContext);
                return <div ref={elRef} {...elProps} {...outerProps} data-id="recipient-inner-list" />;
            }),
        [OuterElementContext]
    );

    const VariableSizeListWrapper: React.ComponentType<VariableSizeListProps> = React.useMemo(
        () =>
            withAutoSizer(({ size, ...restProps }: VariableSizeListProps & AutoSizerInjectedProps) => {
                return <VariableSizeList {...restProps} height={size.height} width={size.width} />;
            }),
        []
    );

    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const itemCount = itemData.length;

    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemSize = smUp ? 56 : 72;
    const getChildSize = (child: React.ReactNode) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 32;
        }

        return itemSize;
    };

    const renderRow = React.useCallback((childProps: ListChildComponentProps) => {
        const { data, index, style } = childProps;
        const el = data[index];
        return React.cloneElement(el, {
            style: {
                ...style,
            },
            // The pointerEvents:none has been removed from options to support <Tooltip /> inside options.
            // To prevent disabled options to be selected, remove the onClick handler
            // see https://github.com/mui-org/material-ui/issues/19254 for related issue
            onClick: el.props['aria-disabled'] ? undefined : el.props.onClick,
        });
    }, []);

    return (
        <div ref={ref} style={{ height: '40vh' }}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeListWrapper
                    itemData={itemData}
                    outerElementType={OuterElementType}
                    width={0}
                    height={0}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeListWrapper>
            </OuterElementContext.Provider>
        </div>
    );
});

export const RecipientsListComponent: React.FunctionComponent<RecipientsListInnerProps> = ({
    setSelectedRecipients,
    vehicles,
    drivers,
    depots,
    assetGroups,
    selectedRecipients,
    vehicleCategories,
    vehicleTypes,
    onOpenChange,
    t,
    classes,
    className,
    placeholder,
    displayPreferences,
    assetType,
    displayName,
    assetPredicate,
}) => {
    const vehicleOptions = React.useMemo(() => {
        return (
            assetType === AssetType.Vehicle &&
            mapVehicleOptions(
                vehicles,
                vehicleTypes,
                displayPreferences.vehicleDisplayFormat,
                vehicleCategories,
                t,
                assetPredicate
            )
        );
    }, [
        displayPreferences.vehicleDisplayFormat,
        assetType,
        t,
        vehicleCategories,
        assetPredicate,
        vehicleTypes,
        vehicles,
    ]);

    const driverOptions = React.useMemo(() => {
        return (
            assetType === AssetType.Driver &&
            mapDriverOptions(drivers, displayPreferences.driverDisplayFormat, assetPredicate)
        );
    }, [assetPredicate, displayPreferences.driverDisplayFormat, drivers, assetType]);

    const groupOptions = React.useMemo(() => {
        return mapGroupOptions(assetGroups, t, assetType, assetPredicate);
    }, [assetGroups, t, assetType, assetPredicate]);

    const depotOptions = React.useMemo(() => {
        return mapDepotOptions({ depots, t, assetType, assetPredicate });
    }, [depots, t, assetType, assetPredicate]);

    const allOptions = React.useMemo(() => {
        return [...(vehicleOptions || []), ...(driverOptions || []), ...groupOptions, ...depotOptions];
    }, [depotOptions, groupOptions, vehicleOptions, driverOptions]);

    const getId = (classParam: string, option: AssetRecipient) =>
        `${classParam}:${option.type}:${option.name}`.toLowerCase().replace(/ /g, '');

    const filterOptions = createFilterOptions({
        stringify: (option: AssetRecipient) => {
            return option.name;
        },
    });

    const getIcon = React.useCallback((type: AssetRecipientType) => {
        let icon;
        switch (type) {
            case AssetRecipientType.VEHICLE:
                icon = <TruckIcon data-id="truck-group-icon" />;
                break;
            case AssetRecipientType.DRIVER:
                icon = <DriverIcon data-id="driver-group-icon" />;
                break;
            case AssetRecipientType.GROUP:
                icon = <AssetGroupIcon data-id="asset-group-icon" />;
                break;
            case AssetRecipientType.DEPOT:
                icon = <AssetDepotIcon data-id="depot-group-icon" />;
                break;
            default:
                throw new Error('Unsupported value provided');
        }
        return icon;
    }, []);

    const getGroupTitle = React.useCallback(
        (type: AssetRecipientType) => {
            switch (type) {
                case AssetRecipientType.VEHICLE:
                    return t('vehicles');
                case AssetRecipientType.DRIVER:
                    return t('drivers');
                case AssetRecipientType.GROUP:
                    return t('groups');
                case AssetRecipientType.DEPOT:
                    return t('depots');
                default:
                    throw new Error('Unsupported value provided');
            }
        },
        [t]
    );

    const secondLine = React.useCallback(
        (option: AssetRecipient) => {
            const secondPart = !isUndefined(option.secondaryTextSecond) && (
                <>
                    <span className={classes.delimiter} data-id="delimiter">
                        •
                    </span>
                    <span data-id="recipient-type2">{option.secondaryTextSecond}</span>
                </>
            );
            const thirdPart = !isUndefined(option.secondaryTextThird) && (
                <>
                    <span className={classes.delimiter} data-id="delimiter">
                        •
                    </span>
                    <span data-id="recipient-type3">{option.secondaryTextThird}</span>
                </>
            );
            return (
                <div className={classes.secondaryTextPlaceholder}>
                    <span data-id="recipient-type1">{option.secondaryTextFirst}</span>
                    {secondPart}
                    {thirdPart}
                </div>
            );
        },
        [classes]
    );

    const optionLabel = React.useCallback(
        (option: AssetRecipient) =>
            option.type === AssetRecipientType.VEHICLE ? option.name : `${option.name} (${option.ids.length})`,
        []
    );
    const optionDisabled = React.useCallback((option: AssetRecipient) => {
        return (
            option.ids.length === 0 ||
            (option.enabledAssetIds?.length === 0 &&
                (option.type === AssetRecipientType.GROUP || option.type === AssetRecipientType.DEPOT))
        );
    }, []);

    const renderGroup = React.useCallback(
        (params: AutocompleteRenderGroupParams) => [
            <ListSubheader className={classes.groupName} data-id="recipient-list-group-header">
                <Typography
                    variant="button"
                    color="textSecondary"
                    key={params.key}
                    component="div"
                    data-id={`recipient-group:${params.group}`}
                >
                    {getGroupTitle(params.group as AssetRecipientType)}
                </Typography>
            </ListSubheader>,
            params.children,
        ],
        [classes.groupName, getGroupTitle]
    );

    const renderOption = React.useCallback(
        (liProps: HTMLAttributes<HTMLLIElement>, option: AssetRecipient) => {
            let tooltip = '';
            if (option.ids?.length === 0) {
                tooltip = t('cannot-add-empty-entry-to-recipients', {
                    entryType: t(option.type),
                    displayName,
                });
            } else if (option.enabledAssetIds?.length === 0) {
                tooltip = t('cannot-add-without-eligible-assets', {
                    entryType: t(option.type),
                    displayName,
                });
            }

            return (
                <li {...liProps}>
                    <Tooltip title={tooltip}>
                        <div className={classes.flexColumn} data-id={getId('recipient-column', option)}>
                            <Avatar className={classes.avatar} data-id="recipient-avatar">
                                {getIcon(option.type)}
                            </Avatar>
                            <ListItemText
                                data-id="recipient-column"
                                primary={<span data-id="name">{option.name}</span>}
                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                secondary={secondLine(option)}
                                secondaryTypographyProps={{ variant: 'caption' }}
                            />
                            <Divider className={classes.listItemDivider} />
                        </div>
                    </Tooltip>
                </li>
            );
        },
        [classes.flexColumn, classes.avatar, classes.listItemDivider, getIcon, secondLine, t, displayName]
    );

    const chipsLabel = React.useCallback(
        (option: AssetRecipient) => (
            <div className={classes.flex}>
                <span className={classes.chipsIcon} data-id="chip-icon">
                    {getIcon(option.type)}
                </span>
                <Tooltip title={optionLabel(option)}>
                    <span className={classes.chipsValue} data-id="chip-value">
                        {optionLabel(option)}
                    </span>
                </Tooltip>
            </div>
        ),
        [classes, getIcon, optionLabel]
    );

    const autocompleteClasses = {
        option: classes.option,
        input: classes.input,
    };
    const renderTags = React.useCallback(
        (tagValue, getTagProps) => {
            return tagValue.map((option: AssetRecipient, index: number) => (
                <Chip
                    size="small"
                    label={chipsLabel(option)}
                    {...getTagProps({ index })}
                    data-id={getId('chip-container', option)}
                    deleteIcon={<CloseIcon className={classes.chipCloseIcon} data-id="chip-close-icon" />}
                />
            ));
        },
        [chipsLabel, classes]
    );

    const handleChange = React.useCallback(
        (_event: React.ChangeEvent, newValue: AssetRecipient[]) => {
            return setSelectedRecipients(newValue);
        },
        [setSelectedRecipients]
    );

    const groupByType = React.useCallback((option: AssetRecipient) => option.type, []);

    const handleOpen = React.useCallback(() => {
        if (onOpenChange) {
            onOpenChange(true);
        }
    }, [onOpenChange]);

    const handleClosed = React.useCallback(() => {
        if (onOpenChange) {
            onOpenChange(false);
        }
    }, [onOpenChange]);

    const renderInput = React.useCallback(
        (params) => {
            const inputProps = {
                ...params?.inputProps,
                onBlur: (event: unknown) => {
                    // Delay onBlur of the input.
                    // Blur can cause the size of the input element to be changes, which clicking elements below this, impossible (as the item below cursor changes).
                    window.setTimeout(() => params?.inputProps?.onBlur(event), 0);
                },
            };

            return (
                <TextField
                    {...params}
                    inputProps={inputProps}
                    data-id="recipient-input"
                    variant="standard"
                    placeholder={placeholder || t('type-a-recipient-or-recipients')}
                    fullWidth
                    InputProps={{ ...params?.InputProps, disableUnderline: true }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
        },
        [placeholder, t]
    );

    return (
        <Autocomplete
            className={className}
            fullWidth
            disableListWrap
            multiple
            data-id="recipient-list"
            limitTags={6}
            onOpen={handleOpen}
            onClose={handleClosed}
            options={allOptions}
            value={selectedRecipients}
            PaperComponent={RecipientListPaperComponent}
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            classes={autocompleteClasses}
            size="small"
            groupBy={groupByType}
            filterOptions={filterOptions}
            getOptionLabel={optionLabel}
            getOptionDisabled={optionDisabled}
            renderGroup={renderGroup}
            renderTags={renderTags}
            renderOption={renderOption}
            noOptionsText={t('no-options')}
            filterSelectedOptions
            renderInput={renderInput}
            onChange={handleChange}
            clearIcon={<CloseIcon data-id="close-icon" />}
        />
    );
};
