import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import type { PositionMiniMapSectionInnerProps, PositionMiniMapSectionProps } from './component';
import { PositionMiniMapSectionComponent } from './component';
import { PositionMiniMapSectionStyleRules } from './styles';

export type { PositionMiniMapSectionProps } from './component';

export const PositionMiniMapSection = compose<PositionMiniMapSectionInnerProps, PositionMiniMapSectionProps>(
    setDisplayName('PositionMiniMapSection'),
    withStyles(PositionMiniMapSectionStyleRules, { withTheme: true })
)(PositionMiniMapSectionComponent);
