import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';

import { vehicleAdministrationStateSelector } from '../../selectors';

import type { VehicleEditorDialogInnerProps, VehicleEditorDialogProps } from './component';
import { VehicleEditorDialogComponent } from './component';
import { mapDispatchProps, mapStateToProps } from './redux';
import { VehicleEditorDialogFormStyleRules } from './styles';

export const VehicleEditorDialog = compose<VehicleEditorDialogInnerProps, VehicleEditorDialogProps>(
    setDisplayName('VehicleEditorDialog'),
    withDisplayPreferences(),
    withTranslation(),
    ensureSettings([
        SettingsKey.VEHICLECATEGORIES,
        SettingsKey.VEHICLETYPES,
        SettingsKey.TACHOGRAPH,
        SettingsKey.TACHO_PROTOCOL,
        SettingsKey.TACHO_GENERATION,
        SettingsKey.WORKFLOW_TYPE,
        SettingsKey.IMAGE_CAPTURING_TYPE,
        SettingsKey.NAVIGATION_TYPE,
        SettingsKey.INHIBITOR_SERVICE_TYPE,
        SettingsKey.TELL_TALE_SERVICE_TYPE,
        SettingsKey.COMPANY_CARDS,
        SettingsKey.DEVICETYPES,
        SettingsKey.FUEL_TANK_TYPE,
    ]),
    withStyles(VehicleEditorDialogFormStyleRules),
    connect(
        createSelector(
            vehicleAdministrationStateSelector,
            settingDataSelector(SettingsKey.VEHICLECATEGORIES),
            settingDataSelector(SettingsKey.VEHICLETYPES),
            settingDataSelector(SettingsKey.TACHOGRAPH),
            settingDataSelector(SettingsKey.TACHO_PROTOCOL),
            settingDataSelector(SettingsKey.TACHO_GENERATION),
            settingDataSelector(SettingsKey.WORKFLOW_TYPE),
            settingDataSelector(SettingsKey.NAVIGATION_TYPE),
            settingDataSelector(SettingsKey.IMAGE_CAPTURING_TYPE),
            settingDataSelector(SettingsKey.INHIBITOR_SERVICE_TYPE),
            settingDataSelector(SettingsKey.TELL_TALE_SERVICE_TYPE),
            settingDataSelector(SettingsKey.COMPANY_CARDS),
            settingDataSelector(SettingsKey.DEVICETYPES),
            settingDataSelector(SettingsKey.FUEL_TANK_TYPE),
            mapStateToProps
        ),
        mapDispatchProps
    )
)(VehicleEditorDialogComponent);
