import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import type { AddressPopupInnerProps, AddressPopupProps } from './component';
import { AddressPopupComponent } from './component';
import { AddressPopupStyleRules } from './styles';

export const AddressPopup = compose<AddressPopupInnerProps, AddressPopupProps>(
    setDisplayName('AddressPopup'),
    withStyles(AddressPopupStyleRules)
)(AddressPopupComponent);
