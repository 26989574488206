import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { ResolvedDriverStatus } from '~/data/monitoring';
import {
    DRIVERSTATUS_DETAILSPANE_USERPREFERENCES_KEY,
    MonitoringPerspective,
    MonitoringViewMode,
} from '~/data/monitoring';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import { MONITORING_PATH_STRUCTURE } from '~/scenes/Monitoring/consts';

import { HeaderSection } from './components/Sections/Header';
import { getSectionsMemoized } from './components/Sections/sections';
import { SectionName } from './consts';
import type { DriverStatusDetailsPaneProps } from './models';
import type { DriverStatusDetailsPaneUserPreferences } from './preferences';

const DetailsPaneComponent = SceneDetailsPaneFactory<
    ResolvedDriverStatus,
    DriverStatusDetailsPaneUserPreferences,
    SectionName
>(DRIVERSTATUS_DETAILSPANE_USERPREFERENCES_KEY);

const DriverStatusDetailsPaneComponent: FC<DriverStatusDetailsPaneProps> = (props) => {
    const { driverStatus } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const getSections = (
        userPreferences: DriverStatusDetailsPaneUserPreferences,
        toggleCollapsed: (sectionName: SectionName) => () => void
    ) =>
        getSectionsMemoized({
            driverStatus,
            collapsedSections: userPreferences.collapsedSections,
            toggleCollapsed,
            zoomLevel: userPreferences.miniMapZoomLevel,
            changeMiniMapZoomLevel: (zoomLevel) =>
                dispatch(
                    updateUserPreferencesAction(DRIVERSTATUS_DETAILSPANE_USERPREFERENCES_KEY, {
                        miniMapZoomLevel: zoomLevel,
                    })
                ),
        });

    const handleClose = () => {
        history.push(
            generatePath(MONITORING_PATH_STRUCTURE, {
                viewMode: MonitoringViewMode.LIST,
                perspective: MonitoringPerspective.DRIVER,
            })
        );
    };

    return (
        <DetailsPaneComponent
            entry={driverStatus}
            getSections={getSections}
            dataId="driverstatus-detailspane"
            HeaderReactNode={<HeaderSection driverStatus={driverStatus} />}
            alwaysFirstSection={SectionName.DRIVERSTATUS_HEADER}
            onClose={handleClose}
        />
    );
};

DriverStatusDetailsPaneComponent.displayName = 'DriverStatusDetailsPane';
export default DriverStatusDetailsPaneComponent;
