import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type TooltipClassKey = 'root' | 'valueRow' | 'value' | 'label';

export const useChartTooltipStyles = makeStyles<Theme, {}, TooltipClassKey>((theme) => ({
    root: {
        padding: theme.spacing(),
        '&>*:not(:last-child)': {
            marginBottom: theme.spacing(0.5),
        },
    },
    valueRow: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        margin: theme.spacing(0, 1),
        flexGrow: 1,
    },
    value: {
        fontWeight: theme.typography.fontWeightBold,
    },
}));
