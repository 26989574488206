import Chat from '@mui/icons-material/Chat';
import { Button } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { ResolvedMessage } from '~/services/ApiClient';

import { DecideContent } from './components';
import type { LatestMessagesSectionContentClassKey } from './styles';

export interface LatestMessagesSectionContentProps {
    latestMessagesSortedDesc: ResolvedMessage[];
    loadingMessages: boolean;
    deletedMessageIds?: number[];
    failedToLoadMessages: boolean;
    getLatestMessages: () => void;
    openConversation: () => void;
}

export interface LatestMessagesSectionContentInnerProps
    extends LatestMessagesSectionContentProps,
        WithStyles<LatestMessagesSectionContentClassKey>,
        InjectedTranslationProps {}

export const LatestMessagesSectionContentComponent: FC<LatestMessagesSectionContentInnerProps> = (props) => {
    const { t, classes, openConversation } = props;

    return (
        <div className={classes.root}>
            <div className={classes.content} data-id="latest-messages-list">
                <DecideContent {...props} />
            </div>

            <Button
                className={classes.openConversationButton}
                color="secondary"
                data-id="open-conversation"
                onClick={openConversation}
            >
                <Chat className={classes.leftButtonAdornment} />
                {t('open-conversation')}
            </Button>
        </div>
    );
};
