import type { FC } from 'react';
import { useMemo } from 'react';

import { themeSpacingToNumber } from '~/common/utils';
import type { GridColumnDefinition, GridRowId } from '~/components/Grid';
import { GridFactory, applyColumnPreferences, minColumnWidth } from '~/components/Grid';

import type { CreateAdjustedColumnsWidthArgs, SceneListViewInnerProps } from './models';
import { firstColumnPaddingLeft } from './styles';

const createPinnedColumns = <T,>(
    pinEnabled: boolean | undefined,
    orderedVisibleColumns: Array<GridColumnDefinition<T>>
) => (pinEnabled && orderedVisibleColumns.length > 0 ? [orderedVisibleColumns[0].name] : []);

const createAdjustedColumnsWidth = <T,>({
    columnWidths,
    orderedVisibleColumns,
    groupBy,
    theme,
}: CreateAdjustedColumnsWidthArgs<T>) => {
    if (groupBy.length > 0 || orderedVisibleColumns.length < 1) {
        return columnWidths;
    }
    const columnPaddingLeft = themeSpacingToNumber(theme.spacing());
    const minWidth = firstColumnPaddingLeft + minColumnWidth - columnPaddingLeft;

    return columnWidths.map((column) => {
        if (column.columnName === orderedVisibleColumns[0].name && Number(column.width) < minWidth) {
            return { ...column, width: minWidth };
        }
        return column;
    });
};

export const SceneListViewComponentFactory = <T extends object>(
    getRowId: (row: T) => GridRowId,
    entryEquals: (rowA?: T, rowB?: T) => boolean,
    filterDataSourceMemoized: (
        searchQuery: string | undefined,
        dataSource: T[],
        columnDefinitions: Array<GridColumnDefinition<T>>,
        visibleColumns: string[]
    ) => T[]
): FC<SceneListViewInnerProps<T>> => {
    const Grid = GridFactory<T>();

    const SceneListViewComponent: FC<SceneListViewInnerProps<T>> = ({
        changeColumnOrder,
        changeColumnWidths,
        changeFilters,
        changeGroupBy,
        changeSorting,
        classes,
        columnOrder,
        columns,
        columnWidths,
        currentSelection,
        dataSource,
        filteringEnabled,
        filters,
        getCellClassName,
        gridDataId,
        gridRef,
        enableMultiselection = false,
        groupBy,
        groupingEnabled,
        hideToolBar,
        highlightedAssetId,
        onRowContextMenu,
        pinEnabled,
        RowDetail,
        searchQuery,
        selectedAssetId,
        selectRow,
        multiSelection,
        sorting,
        summaryItems,
        t,
        theme,
        visibleColumns,
    }) => {
        const orderedVisibleColumns = useMemo(
            () => applyColumnPreferences(columns, visibleColumns, columnOrder),
            [columns, visibleColumns, columnOrder]
        );

        const pinnedColumns = useMemo(
            () => createPinnedColumns(pinEnabled, orderedVisibleColumns),
            [pinEnabled, orderedVisibleColumns]
        );

        const adjustedColumnsWidth = useMemo(
            () => createAdjustedColumnsWidth({ columnWidths, orderedVisibleColumns, groupBy, theme }),
            [columnWidths, orderedVisibleColumns, groupBy, theme]
        );

        const filteredDataSource = filterDataSourceMemoized(searchQuery, dataSource, columns, visibleColumns);

        return (
            <div className={classes.root}>
                <Grid
                    selectedRowId={selectedAssetId}
                    selectRow={selectRow}
                    onRowContextMenu={onRowContextMenu}
                    dataSource={filteredDataSource}
                    columnDefinitions={columns}
                    getRowId={getRowId}
                    rowEquals={entryEquals}
                    columnOrder={columnOrder}
                    changeColumnOrder={changeColumnOrder}
                    visibleColumns={visibleColumns}
                    sorting={sorting}
                    changeSorting={changeSorting}
                    groupBy={groupBy}
                    groupingEnabled={groupingEnabled}
                    changeGroupBy={changeGroupBy}
                    filteringEnabled={filteringEnabled}
                    filters={filters}
                    changeFilters={changeFilters}
                    columnWidths={adjustedColumnsWidth}
                    changeColumnWidths={changeColumnWidths}
                    pinnedColumns={pinnedColumns}
                    noDataMessage={searchQuery && t('no-results', { context: 'term', term: searchQuery })}
                    gridDataId={gridDataId}
                    getCellClassName={getCellClassName}
                    highlightedRowId={highlightedAssetId}
                    ref={gridRef}
                    enableMultiselection={enableMultiselection}
                    hideToolBar={hideToolBar}
                    summaryItems={summaryItems}
                    currentSelection={currentSelection}
                    multiSelection={multiSelection}
                    RowDetail={RowDetail}
                />
            </div>
        );
    };
    return SceneListViewComponent;
};
