import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { AssetType } from '~/services/ApiClient';

export type AssetHistorySubpageClassKey = 'timeline' | 'timelineIcon';

export const assetHistorySubpageStyleRulesFactory =
    (assetType: AssetType): StyleRulesCallback<Theme, {}, AssetHistorySubpageClassKey> =>
    (theme: Theme) =>
        createStyles({
            timeline: {
                backgroundColor: theme.functionalItemsColors.assetType[assetType].value,
            },
            timelineIcon: {
                color: theme.functionalItemsColors.assetType[assetType].contrast,
            },
        });
