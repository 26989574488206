import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type {
    DriverActivityType,
    DriverSubActivityType,
    LoginStatus,
    NumberViolationField,
    ResolvedDriverHoursStatus,
    ViolationField,
} from '~/services/ApiClient';
import { DriverRole, DurationViolationField, ViolationType, createApiModel } from '~/services/ApiClient';
import { parseOptionalDuration, parseOptionalDurationViolationField } from '~/services/Parsers';

export const getActivityValue = (
    entry: MonitoringVehicleEntry,
    activeRole: DriverRole
): DriverActivityType | undefined =>
    activeRole === DriverRole.DRIVER
        ? entry.vehicleDriverHoursStatus?.driverHoursStatus?.activity
        : entry.vehicleDriverHoursStatus?.coDriverHoursStatus?.activity;

export const getSubActivityValue = (
    entry: MonitoringVehicleEntry,
    activeRole: DriverRole
): DriverSubActivityType | undefined =>
    activeRole === DriverRole.DRIVER
        ? entry.vehicleDriverHoursStatus?.driverHoursStatus?.subActivity
        : entry.vehicleDriverHoursStatus?.coDriverHoursStatus?.subActivity;

export const getActivityStartDateTimeValue = (hoursStatus: ResolvedDriverHoursStatus): Date | undefined =>
    hoursStatus.activityStartDateTime;

export const getShiftDurationValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.shiftDuration);

export const getAvailableDailyDriveValue = (hoursStatus: ResolvedDriverHoursStatus): moment.Duration | undefined =>
    parseOptionalDuration(hoursStatus.availableDailyDrive);

export const getAvailableWeeklyDriveValue = (hoursStatus: ResolvedDriverHoursStatus): moment.Duration | undefined =>
    parseOptionalDuration(hoursStatus.availableWeeklyDrive);

export const getAvailableBiWeeklyDriveValue = (hoursStatus: ResolvedDriverHoursStatus): moment.Duration | undefined =>
    parseOptionalDuration(hoursStatus.availableBiWeeklyDrive);

export const getDailyDutyValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.dailyDuty);

export const getWeeklyDutyValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.weeklyDuty);

export const getMonthlyDutyValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.monthlyDuty);

export const getMonthlyEffectivityPercentageValue = (hoursStatus: ResolvedDriverHoursStatus): number | undefined =>
    hoursStatus.monthlyEffectivityPercentage;

export const getCurrentActivityDurationValue = (hoursStatus: ResolvedDriverHoursStatus): moment.Duration | undefined =>
    parseOptionalDuration(hoursStatus.currentActivityDuration);

export const getContinuousWorkWaitValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.continuousWorkWait);

export const getOperationalWeekDurationValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined =>
    parseOptionalDurationViolationField(hoursStatus.operationalWeekDuration);

export const getLoginStatusValue = (hoursStatus: ResolvedDriverHoursStatus): LoginStatus | undefined =>
    hoursStatus.loginStatus;

export const getContinuousDriveValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.continuousDrive);

export const getDriveBreakValue = (hoursStatus: ResolvedDriverHoursStatus): moment.Duration | undefined =>
    parseOptionalDuration(hoursStatus.driveBreak);

export const getDailyDriveValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.dailyDrive);

export const getWeeklyDriveValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.weeklyDrive);

export const getExtendedDayDriveCountValue = (
    hoursStatus: ResolvedDriverHoursStatus
): NumberViolationField | undefined => hoursStatus.extendedDayDriveCount;

export const getContinuousLabourShortValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined =>
    parseOptionalDurationViolationField(hoursStatus.continuousLabourShort);

export const getLabourShortBreakValue = (hoursStatus: ResolvedDriverHoursStatus): moment.Duration | undefined =>
    parseOptionalDuration(hoursStatus.labourShortBreak);

export const getContinuousLabourLongValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.continuousLabourLong);

export const getLabourLongBreakValue = (hoursStatus: ResolvedDriverHoursStatus): moment.Duration | undefined =>
    parseOptionalDuration(hoursStatus.labourLongBreak);

export const getNightLabourValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.nightLabour);

export const getWeekLabourValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.weekLabour);

export const getWeekLabourAverageValue = (
    hoursStatus: ResolvedDriverHoursStatus
): ViolationField<moment.Duration> | undefined => parseOptionalDurationViolationField(hoursStatus.weekLabourAverage);

export const getSplitDailyRestValue = (hoursStatus: ResolvedDriverHoursStatus): moment.Duration | undefined =>
    parseOptionalDuration(hoursStatus.splitDailyRest);

export const getReducedDayRestCountValue = (hoursStatus: ResolvedDriverHoursStatus): NumberViolationField | undefined =>
    hoursStatus.reducedDayRestCount;

export const getDailyRestValue = (hoursStatus: ResolvedDriverHoursStatus): moment.Duration | undefined =>
    parseOptionalDuration(hoursStatus.dailyRest);

export const getWeeklyRestCompensationValue = (hoursStatus: ResolvedDriverHoursStatus): moment.Duration | undefined =>
    parseOptionalDuration(hoursStatus.weeklyRestCompensation);

export const getTotalWeeklyRestCompensationValue = (
    hoursStatus: ResolvedDriverHoursStatus
): moment.Duration | undefined => parseOptionalDuration(hoursStatus.totalWeeklyRestCompensation);

export const getWeeklyRestDueDateTimeValue = (hoursStatus: ResolvedDriverHoursStatus): Date | undefined =>
    hoursStatus.weeklyRestDueDateTime;

// when BE ready take it from resolved hours and remove imports
export const getContinuousDriveRemainingValue = (): ViolationField<moment.Duration> | undefined =>
    parseOptionalDurationViolationField(
        createApiModel(DurationViolationField, {
            threshold: 'PT1H6M',
            value: 'PT20M',
            violationType: ViolationType.Violation,
            violation: 'PT1H6M',
        })
    );

export const getDriveBreakDueValue = (): moment.Duration | undefined => parseOptionalDuration('PT20H');

export const isLastShift = (): boolean | undefined => false;
