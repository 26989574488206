import type { DriverEvent, DriverEventFieldValue } from '~/services/ApiClient';
import type { Fulfilled, FulfilledMeta, Pending, PendingMeta, Rejected, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    DRIVEREVENT = 'DRIVEREVENT',
    DRIVEREVENTFIELDVALUES = 'DRIVEREVENTFIELDVALUES',
}

export enum ActionTypeKeys {
    DRIVEREVENT_PENDING = 'DRIVEREVENT_PENDING',
    DRIVEREVENT_FULFILLED = 'DRIVEREVENT_FULFILLED',
    DRIVEREVENT_REJECTED = 'DRIVEREVENT_REJECTED',
    DRIVEREVENTFIELDVALUES_PENDING = 'DRIVEREVENTFIELDVALUES_PENDING',
    DRIVEREVENTFIELDVALUES_FULFILLED = 'DRIVEREVENTFIELDVALUES_FULFILLED',
    DRIVEREVENTFIELDVALUES_REJECTED = 'DRIVEREVENTFIELDVALUES_REJECTED',
}

export interface DriverEventsPayload {
    readonly items: DriverEvent[];
}

export interface DriverEventFieldValuePayload {
    readonly items: DriverEventFieldValue[];
}

export type ActionTypes =
    | Pending<ActionTypeKeys.DRIVEREVENT_PENDING>
    | Fulfilled<ActionTypeKeys.DRIVEREVENT_FULFILLED, DriverEventsPayload>
    | Rejected<ActionTypeKeys.DRIVEREVENT_REJECTED>
    | PendingMeta<ActionTypeKeys.DRIVEREVENTFIELDVALUES_PENDING, DriverEventFieldValueMeta>
    | FulfilledMeta<
          ActionTypeKeys.DRIVEREVENTFIELDVALUES_FULFILLED,
          DriverEventFieldValuePayload,
          DriverEventFieldValueMeta
      >
    | RejectedMeta<ActionTypeKeys.DRIVEREVENTFIELDVALUES_REJECTED, undefined, DriverEventFieldValueMeta>;

export interface DriverEventFieldValueMeta {
    eventId: number;
    eventTypeId?: number;
    subActivityTypeId?: number;
}
