import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgress, IconButton, Paper, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import moment from 'moment';
import type { ChangeEvent, RefObject } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { DropDownMenu } from '~/components/DropDownMenu';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import { logEvent } from '~/services/Analytics';
import type { ChartPeriod } from '~/services/ApiClient';
import { DurationFormat, FormatLength, formatDuration } from '~/services/Formatters';

import type { ChartState, TimeSeriesChartDefinition } from '../../models';

import { ChartLegend } from './components/ChartLegend';
import { TimeSeriesChartFactory } from './components/TimeSeriesChart';
import { exportTimeSeriesChartToExcel } from './exportTimeSeriesChartToExcel';
import type { TimeSeriesDialogContentClassKey } from './styles';

export interface TimeSeriesDialogContentProps<T> {
    charts: TimeSeriesChartDefinition<T>[];
    activeChart: TimeSeriesChartDefinition<T>;
    onActiveChartChanged: (activeChart: TimeSeriesChartDefinition<T>) => void;
    getExportFilename: () => string;
    data: RetrievableData<T[]>;
    downloadImage: () => void;
    viewPeriod: ChartPeriod;
    chartPeriod: ChartPeriod;
    onViewPeriodChanged: (viewPeriod: ChartPeriod) => void;
    chartRef?: RefObject<HTMLDivElement>;
}

export interface TimeSeriesDialogContentInnerProps<T>
    extends TimeSeriesDialogContentProps<T>,
        InjectedTranslationProps,
        WithStyles<TimeSeriesDialogContentClassKey> {}

export const TimeSeriesDialogContentComponent = <T,>({
    classes,
    charts,
    activeChart,
    onActiveChartChanged,
    data: { pending: loading, data: items, rejected },
    t,
    getExportFilename,
    downloadImage,
    chartRef,
    onViewPeriodChanged,
    chartPeriod,
    viewPeriod,
}: TimeSeriesDialogContentInnerProps<T>): JSX.Element => {
    const TimeSeriesChart = useMemo(() => TimeSeriesChartFactory<T>(), []);
    const [chartStates, setChartStates] = useState<NumericDictionary<ChartState>>({});
    const chartDuration: moment.Duration = useMemo(
        () => moment.duration(moment(chartPeriod.stopDate).diff(moment(chartPeriod.startDate))),
        [chartPeriod]
    );

    const durationFormatOptions = useMemo(() => {
        return {
            durationFormat: DurationFormat.Hour,
            durationFormatLength: FormatLength.Standard,
        };
    }, []);

    const changeChart = useCallback(
        (_: ChangeEvent<{}>, value: TimeSeriesChartDefinition<T>) => {
            onActiveChartChanged(value);
        },
        [onActiveChartChanged]
    );

    const tabPanel = useMemo(() => {
        if (charts.length <= 1) {
            return undefined;
        }

        const tabs = charts.map((chart) => (
            <Tab key={chart.name} label={chart.title} value={chart} data-id={`chart-tab:${chart.name}`} />
        ));

        return (
            <Paper square className={classes.tabsPaper} elevation={3}>
                <Tabs orientation="vertical" value={activeChart} onChange={changeChart} data-id="compartment-tabs">
                    {tabs}
                </Tabs>
            </Paper>
        );
    }, [charts, classes, activeChart, changeChart]);

    const currentItems = useMemo(() => {
        return activeChart.filterData(items);
    }, [items, activeChart]);

    const currentCompartmentState = useMemo(
        () => chartStates[activeChart.name] ?? { visibleSeries: activeChart.series.map((s) => s.name) },
        [activeChart, chartStates]
    );

    const setCurrentCompartmentState = useCallback(
        (compartmentState: ChartState) => {
            setChartStates({ ...chartStates, [activeChart.name]: compartmentState });
        },
        [activeChart.name, chartStates, setChartStates]
    );

    const exportItems = useCallback(() => {
        return currentItems.filter((item) => {
            const dt = activeChart.getItemDate(item);
            return dt >= viewPeriod.startDate && dt <= viewPeriod.stopDate;
        });
    }, [currentItems, activeChart, viewPeriod]);

    const exportToExcelClick = useCallback(() => {
        logEvent('temperatures-graph', 'export-to-excel', 'Export to Excel');
        exportTimeSeriesChartToExcel(
            activeChart,
            currentCompartmentState,
            exportItems(),
            getExportFilename(),
            activeChart.title,
            t
        );
    }, [getExportFilename, activeChart, currentCompartmentState, exportItems, t]);

    const exportToImageClick = useCallback(() => {
        logEvent('temperatures-graph', 'export-to-image', 'Export to Image');
        downloadImage();
    }, [downloadImage]);

    const content = useMemo(() => {
        if (loading) {
            return (
                <div className={classes.loaderContainer} data-id="loading-data">
                    <CircularProgress size={32} />
                </div>
            );
        }

        if (rejected) {
            return (
                <Typography variant="body2" className={classes.messageText} data-id="loading-graph-failed">
                    {t('failed-to-retrieve-data')}
                </Typography>
            );
        }

        if (!currentItems.length) {
            return (
                <Typography variant="body2" className={classes.messageText} data-id="no-graph-data">
                    {t('no-historic-data-available', {
                        period: formatDuration(chartDuration, durationFormatOptions),
                    })}
                </Typography>
            );
        }

        return (
            <>
                <div className={classes.chart} data-id="temperatures-graph">
                    <TimeSeriesChart
                        key={activeChart.name}
                        data={currentItems}
                        chart={activeChart}
                        chartState={currentCompartmentState}
                        viewPeriod={viewPeriod}
                        onViewPeriodChange={onViewPeriodChanged}
                    />
                </div>
                <ChartLegend
                    series={activeChart.series}
                    chartState={currentCompartmentState}
                    onChange={setCurrentCompartmentState}
                />
            </>
        );
    }, [
        TimeSeriesChart,
        t,
        classes,
        loading,
        rejected,
        currentItems,
        activeChart,
        currentCompartmentState,
        setCurrentCompartmentState,
        chartDuration,
        viewPeriod,
        onViewPeriodChanged,
        durationFormatOptions,
    ]);

    if (charts.length === 0) {
        return (
            <Typography variant="body2" className={classes.messageText} data-id="no-charts">
                {t('no-charts')}
            </Typography>
        );
    }

    const exportToExcelTitle = {
        id: 'excel',
        element: (
            <Typography variant="body2" data-id="export-to-excel" onClick={exportToExcelClick}>
                {t('export-to-excel')}
            </Typography>
        ),
    };

    const exportToImageTitle = {
        id: 'image',
        element: (
            <Typography variant="body2" data-id="export-to-image" onClick={exportToImageClick}>
                {t('export-to-image')}
            </Typography>
        ),
    };

    const connectButton = (
        <Tooltip title={t('export')} data-id="export-button-tooltip">
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                data-id="export-button-icon"
                size="large"
            >
                <MoreVertIcon />
            </IconButton>
        </Tooltip>
    );

    return (
        <>
            {tabPanel}
            <div className={classes.content}>
                <div className={classes.contentHeader}>
                    <Typography variant="subtitle1" data-id="graph-title" className={classes.contentTitle}>
                        {activeChart.title}
                    </Typography>
                    <DropDownMenu
                        chartRef={chartRef}
                        menuOptions={[exportToExcelTitle, exportToImageTitle]}
                        menuButton={connectButton}
                    />
                </div>
                {content}
            </div>
        </>
    );
};
