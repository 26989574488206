import { Drawer } from '@mui/material';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { Component } from 'react';

import { LeftPaneHandle } from './components/LeftPaneHandle';
import type { PageTemplateInnerProps, PageTemplateState } from './models';
import { leftPaneWidth, rightPaneWidth } from './styles';
import { WidgetsViewMode } from './widgetsViewMode';

export class PageTemplateComponent extends Component<PageTemplateInnerProps, PageTemplateState> {
    public static getDerivedStateFromProps(
        props: PageTemplateInnerProps,
        state: PageTemplateState
    ): PageTemplateState | null {
        if (
            (props.leftPaneElement && props.leftPaneElement !== state.leftPaneElement) ||
            (props.rightPaneElement && props.rightPaneElement !== state.rightPaneElement) ||
            (props.topPaneElement && props.topPaneElement !== state.topPaneElement)
        ) {
            return {
                leftPaneElement: props.leftPaneElement || state.leftPaneElement,
                rightPaneElement: props.rightPaneElement || state.rightPaneElement,
                topPaneElement: props.topPaneElement || state.topPaneElement,
            };
        }

        return null;
    }

    private clearLeftPaneStateTimeout?: number;
    private clearRightPaneStateTimeout?: number;
    private clearTopPaneStateTimeout?: number;

    constructor(props: PageTemplateInnerProps) {
        super(props);

        this.state = {
            leftPaneElement: props.leftPaneElement,
            rightPaneElement: props.rightPaneElement,
            topPaneElement: props.topPaneElement,
        };
    }

    public componentDidUpdate(prevProps: PageTemplateInnerProps): void {
        if (this.props.leftPaneElement && !prevProps.leftPaneElement) {
            window.clearTimeout(this.clearLeftPaneStateTimeout);
        }

        if (this.props.rightPaneElement && !prevProps.rightPaneElement) {
            window.clearTimeout(this.clearRightPaneStateTimeout);
        }

        if (this.props.topPaneElement && !prevProps.topPaneElement) {
            window.clearTimeout(this.clearTopPaneStateTimeout);
        }

        if (!this.props.leftPaneElement && prevProps.leftPaneElement) {
            this.clearLeftPaneStateTimeout = window.setTimeout(() => {
                this.setState({ leftPaneElement: undefined });
            }, this.props.theme.transitions.duration.leavingScreen);
        }

        if (!this.props.rightPaneElement && prevProps.rightPaneElement) {
            this.clearRightPaneStateTimeout = window.setTimeout(() => {
                this.setState({ rightPaneElement: undefined });
            }, this.props.theme.transitions.duration.leavingScreen);
        }

        if (!this.props.topPaneElement && prevProps.topPaneElement) {
            this.clearTopPaneStateTimeout = window.setTimeout(() => {
                this.setState({ topPaneElement: undefined });
            }, this.props.theme.transitions.duration.leavingScreen);
        }
    }

    public render(): ReactNode {
        const {
            classes,
            header,
            leftPaneElement,
            rightPaneElement,
            toggleLeftPaneVisibility,
            children,
            leftPaneToggleTitle,
            widgetsViewMode,
            widgets,
        } = this.props;

        const leftPaneIsOpened = !!this.props.leftPaneElement;
        const rightPaneIsOpened = !!this.props.rightPaneElement;
        const topPaneIsOpened = !!this.props.topPaneElement;

        const computedLeftPaneWidth = leftPaneIsOpened ? leftPaneWidth : 0;
        const computedRightPaneWidth = rightPaneIsOpened ? rightPaneWidth : 0;
        const contentWidth = `calc(100% - ${computedLeftPaneWidth + computedRightPaneWidth}px)`;
        const contentStyle = {
            marginLeft: computedLeftPaneWidth,
            width: contentWidth,
        };

        const leftPaneClasses = {
            paper: classNames(classes.panePaper, classes.leftPanePaper, {
                [classes.openedPanePaper]: !!leftPaneElement,
                [classes.leftPanePaperClosed]: !leftPaneElement,
            }),
        };
        const topPaneClasses = {
            paper: classNames(classes.panePaper, classes.topPanePaper, {
                [classes.openedPanePaper]: !!topPaneIsOpened,
                [classes.topPanePaperClosed]: !topPaneIsOpened,
            }),
        };

        const rightPaneClasses = {
            paper: classNames(classes.panePaper, classes.rightPanePaper, {
                [classes.openedPanePaper]: rightPaneIsOpened,
            }),
        };

        const renderLeftPaneHandle = toggleLeftPaneVisibility && leftPaneToggleTitle && (
            <div className={classes.leftPaneHandle}>
                <LeftPaneHandle
                    title={leftPaneToggleTitle}
                    isOpen={leftPaneIsOpened}
                    onClick={toggleLeftPaneVisibility}
                />
            </div>
        );

        const widgetsClassName =
            widgetsViewMode &&
            classNames(classes.widgets, {
                [classes.dockedWidgets]: widgetsViewMode === WidgetsViewMode.DOCKED,
                [classes.floatingWidgets]: widgetsViewMode === WidgetsViewMode.FLOATING,
            });

        const renderWidgets = widgets && widgetsViewMode && (
            <div className={classes.widgetsContainer}>
                <div className={widgetsClassName} data-id="widgets">
                    {widgets}
                </div>
            </div>
        );

        return (
            <div className={classes.root}>
                {header}
                <div className={classes.body}>
                    <Drawer
                        PaperProps={{ elevation: leftPaneIsOpened ? 5 : 0 }}
                        classes={leftPaneClasses}
                        open={leftPaneIsOpened}
                        variant="persistent"
                        anchor="left"
                    >
                        {this.state.leftPaneElement}
                        {renderLeftPaneHandle}
                    </Drawer>
                    <div className={classes.mainView} style={contentStyle}>
                        <Drawer classes={topPaneClasses} open={topPaneIsOpened} variant="persistent" anchor="top">
                            {this.state.topPaneElement}
                        </Drawer>
                        <div className={classes.content} data-id="page-content">
                            {children}
                        </div>
                        {renderWidgets}
                    </div>
                    <Drawer classes={rightPaneClasses} open={!!rightPaneElement} variant="persistent" anchor="right">
                        {this.state.rightPaneElement}
                    </Drawer>
                </div>
            </div>
        );
    }
}
