import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type WorkflowMessageSendSubpageClassKey =
    | 'root'
    | 'workflowDefinitionSelect'
    | 'messageFields'
    | 'sendFab'
    | 'sendIcon'
    | 'sendFabDisabled'
    | 'circularProgress'
    | 'form'
    | 'formMessage'
    | 'workflowDefinitionSelectOption';

export const WorkflowMessageSendSubpageStyleRules: StyleRulesCallback<Theme, {}, WorkflowMessageSendSubpageClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            height: '100%',
            backgroundColor: theme.palette.common.white,
        },
        workflowDefinitionSelect: {
            margin: theme.spacing(2),
        },
        messageFields: {
            marginBottom: theme.spacing(4),
        },
        sendFab: {
            width: 32,
            height: 32,
            minHeight: 32,
            margin: theme.spacing(0, 2, 1, 0),
            position: 'absolute',
            right: 0,
            bottom: 0,
            zIndex: 99,
        },
        sendFabDisabled: {
            backgroundColor: theme.palette.background.paper,
            width: 32,
            height: 32,
            minHeight: 32,
            margin: theme.spacing(0, 2, 1, 0),
            position: 'absolute',
            right: 0,
            bottom: 0,
            borderRadius: '50%',
        },
        sendIcon: {
            fontSize: theme.typography.pxToRem(14),
        },
        circularProgress: {
            position: 'absolute',
        },
        form: {
            flexGrow: 1,
        },
        formMessage: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            fontStyle: 'italic',
            display: 'flex',
            height: '100%',
            justifyContent: 'space-around',
        },
        workflowDefinitionSelectOption: {
            '&[aria-disabled="true"]': {
                pointerEvents: 'initial',
                cursor: 'default',
            },
            display: 'flex',
        },
    });
