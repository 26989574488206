import { escapeRegExp } from '~/libs/utility';

export const filterBySearchQuery = <T>(
    dataSource: T[],
    textValuesBuilder: (entry: T) => Array<string | undefined>,
    searchQuery: string
): T[] => {
    const regex = new RegExp(escapeRegExp(searchQuery), 'i');
    return dataSource.filter((entry) => {
        return textValuesBuilder(entry).some((textVal) => {
            return textVal ? regex.test(textVal.toString()) : false;
        });
    });
};
