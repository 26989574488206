import { Box, Checkbox, FormControl, FormHelperText, Tooltip } from '@mui/material';
import type { FC } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NumericInput } from '~/components/InputFields';
import { SettingsValueSource } from '~/services/ApiClient';

import type { NumericInputSettingsFieldProps } from './models';
import { useStyles } from './styles';

const NumericInputSettingsField: FC<NumericInputSettingsFieldProps> = (props) => {
    const { fieldDataId, disabled, disabledReason, defaultSettingValue, settingsSourceFieldName, ...restInputProps } =
        props;

    const classes = useStyles();
    const { t } = useTranslation();
    const {
        field: { ref, value: settingsValueSource, onChange: onSettingsValueSourceChange, ...fieldProps },
        fieldState: { error },
    } = useController({ name: settingsSourceFieldName });
    const { field } = useController({ name: restInputProps.fieldName });

    const onSettingsSourceCheckboxChanged = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        onSettingsValueSourceChange(checked ? SettingsValueSource.Default : SettingsValueSource.Asset);
        if (checked) {
            field.onChange(defaultSettingValue);
        }
    };

    return (
        <FormControl error={Boolean(error)} className={classes.root}>
            <Box className={classes.fieldContainer}>
                <NumericInput
                    {...restInputProps}
                    className={classes.inputField}
                    dataId={`vehicle-editor-field-${fieldDataId}`}
                    label={t(`vehicle-editor-field-${fieldDataId}`)}
                    disabled={disabled || settingsValueSource === SettingsValueSource.Default}
                    disabledReason={disabled ? disabledReason : t('default-service-value')}
                    markValueChange
                    leftAlignment
                />

                <Tooltip
                    title={
                        disabled
                            ? disabledReason
                            : settingsValueSource === SettingsValueSource.Default
                              ? t('switch-to-no-default')
                              : t('switch-to-default')
                    }
                    data-id={`disabled-tooltip-${fieldDataId}`}
                    placement="right"
                >
                    <span>
                        <Checkbox
                            {...fieldProps}
                            data-testId={`${fieldDataId}-default`}
                            data-id={`${fieldDataId}-default`}
                            checked={settingsValueSource === SettingsValueSource.Default}
                            onChange={onSettingsSourceCheckboxChanged}
                            disabled={disabled}
                            inputRef={ref}
                        />
                    </span>
                </Tooltip>
            </Box>
            {error && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
    );
};

NumericInputSettingsField.displayName = 'NumericInputSettingsField';
export { NumericInputSettingsField };
