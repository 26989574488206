import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DateTimeWorkflowFieldInnerProps, DateTimeWorkflowFieldProps } from './component';
import { DateTimeWorkflowFieldComponent } from './component';
import { DateTimeWorkflowFieldStyleRules } from './styles';

export const DateTimeWorkflowField = compose<DateTimeWorkflowFieldInnerProps, DateTimeWorkflowFieldProps>(
    setDisplayName('DateTimeWorkflowField'),
    withStyles(DateTimeWorkflowFieldStyleRules),
    withTranslation()
)(DateTimeWorkflowFieldComponent);
