import type { TilesTypes } from '@fv/components';
import { SHAPE_TYPES } from '@fv/components';

import type { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';
import type { MapMode } from '~/services/MapGatewayClient';

import { MapLayer } from './layers.constants';

export const defaultLayerProps = { subdomains: '1234', minZoom: 3, maxZoom: 19 } as const;

interface GetMapLayerPropsArgs {
    layer: string;
    url: string;
    unitSystem: DisplayUserPreferencesUnitSystem;
    zIndex?: number;
}

interface GetMapGLLayerPropsArgs extends Omit<GetMapLayerPropsArgs, 'url' | 'layer'> {
    mapMode: MapMode;
    includeTruckAttributes?: boolean;
    includeTrafficIncidents?: boolean;
    language?: string;
}

export const getMapLayerProps = (args: GetMapLayerPropsArgs): TilesTypes => {
    const { layer, url, ...overrideProps } = args;

    const shape =
        url.indexOf('/renderMap') !== -1 || url.indexOf('contentType=JSON') !== -1
            ? SHAPE_TYPES.XSERVER
            : SHAPE_TYPES.COMMON;

    return {
        ...defaultLayerProps,
        key: layer,
        shape,
        url,
        zIndex: Object.keys(MapLayer).findIndex((key) => layer === key),
        ...overrideProps,
    };
};

export const getMapGLLayerProps = (args: GetMapGLLayerPropsArgs): TilesTypes => {
    const { mapMode, ...overrideProps } = args;

    return {
        ...defaultLayerProps,
        key: mapMode,
        mapMode,
        url: '',
        shape: SHAPE_TYPES.MAPLIBREGL,
        zIndex: 1,
        ...overrideProps,
    };
};
