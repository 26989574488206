import { createAction } from 'redux-actions';

import type { GetActiveAlertCountResponse } from '~/services/ApiClient';
import {
    AcknowledgeAlertRequest,
    ApiClient,
    QueryAlertsRequest,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import { ACTION_KEYS } from './actionTypes';
import type { AcknowledgeAlertArgs, ResolvedAlerts, RetrieveAlertsArgs } from './models';

export const retrieveAlerts = createAction(
    ACTION_KEYS.POST,
    ({ vehicleIds, startDate, stopDate }: RetrieveAlertsArgs) => {
        return retryableRequest(() =>
            ApiClient.queryAlerts(
                createApiModel(QueryAlertsRequest, {
                    vehicleIds,
                    startDate,
                    stopDate,
                })
            )
        );
    }
);

export const acknowledgeAlert = createAction(
    ACTION_KEYS.ACKNOWLEDGE,
    ({ alertId, acknowledgeReason }: AcknowledgeAlertArgs) => {
        return retryableRequest(() =>
            ApiClient.acknowledgeAlert(
                createApiModel(AcknowledgeAlertRequest, {
                    alertId,
                    acknowledgeReason,
                })
            )
        );
    }
);

export const getActiveAlertCount = createAction<Promise<GetActiveAlertCountResponse>>(ACTION_KEYS.GET_ACTIVE, () =>
    retryableRequest(() => ApiClient.getActiveAlertCount())
);

export const changeActiveAlertCount = createAction<number>(ACTION_KEYS.CHANGE_ACTIVE_COUNT);

export const clearAlertsData = createAction(ACTION_KEYS.CLEAR_ALERTS_DATA);

export const applyUpdates = createAction(ACTION_KEYS.APPLY_UPDATE);
export const saveMessagesIntoCache = createAction<ResolvedAlerts>(ACTION_KEYS.SAVE_INTO_CACHE);

export const clearAlertsCache = createAction(ACTION_KEYS.CLEAR_CACHE);
