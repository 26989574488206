import type { NumericDictionary } from '~/libs/utility';
import { keyBy, mapValues } from '~/libs/utility';
import type { AssetReference, Depot } from '~/services/ApiClient';

import type { AssetReferenceHash } from '../models';

import { assetTypeIdsMap } from './createUnassignedCategories';
import { assetReferenceHashMerge } from './hashutils';

export const getDepotIds = (depots?: Depot[]): number[] => {
    if (depots === undefined) {
        return [];
    }

    return depots.reduce((a, b: Depot) => [...a, b.id], []);
};

export const getSingleDepotAssetIds = (
    depot: Depot,
    assetIds: AssetReferenceHash<AssetReference>
): AssetReferenceHash<AssetReference> => {
    return mapValues(assetIds, (assetTypeDict, assetType) => {
        const ids = depot[assetTypeIdsMap[assetType]] as unknown as number[] | undefined;
        if (!ids?.length) {
            return {};
        }
        return keyBy(ids.map((id) => assetTypeDict?.[id]).filter(Boolean), 'id') as NumericDictionary<AssetReference>;
    });
};

export const getDepotAssetIds = (
    depots: Depot[] | undefined,
    assetIds: AssetReferenceHash<AssetReference>
): AssetReferenceHash<AssetReference> => {
    if (depots === undefined) {
        return {};
    }

    const ids = depots.map((d) => getSingleDepotAssetIds(d, assetIds));

    return assetReferenceHashMerge(...ids);
};

export const getDepotById = (depots: Depot[] | undefined, depotId: number): Depot | null => {
    if (depots === undefined) {
        return null;
    }

    return depots.find((d) => d.id === depotId) || null;
};
