import type { SingleTFunction } from '~/components/LanguageSelector';

type FormatVoltageArgs = {
    t: SingleTFunction;
    value: number;
    precision?: number;
};

export const formatVoltage = ({ t, value, precision = 2 }: FormatVoltageArgs): string => {
    const formattedValue = `${value.toFixed(precision)} ${t('unit-voltage')}`;
    return formattedValue;
};
