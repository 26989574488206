import type { GridColumnDefinition } from '~/components/Grid';
import { filterDataSourceBySearchQuery } from '~/components/Grid';
import type { MonitoringContainerEntry } from '~/data/monitoring';
import { memoizeOne } from '~/services/Memoize';

export const filterMonitoringDataSourceMemoized = memoizeOne(
    (
        searchQuery: string | undefined,
        dataSource: MonitoringContainerEntry[],
        columnDefinitions: Array<GridColumnDefinition<MonitoringContainerEntry>>,
        visibleColumns: string[]
    ) => filterDataSourceBySearchQuery(searchQuery, dataSource, columnDefinitions, visibleColumns)
);
