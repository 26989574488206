import type { NumericDictionary } from '~/libs/utility';
import type {
    Device,
    DeviceType,
    DisplayUserPreferencesVehicleDisplayFormat,
    SettingsValueSource,
    Vehicle,
} from '~/services/ApiClient';

export type FieldNames = keyof FieldValues;

export const SourceFiledNameSuffix = 'SettingsValueSource';

export const toggleServiceFields = [
    'alertsAndPanicEnabled',
    'driverCardsEnabled',
    'driverCoachEnabled',
    'driverHoursEnabled',
    'dseEnabled',
    'frequentPositioningEnabled',
    'fuelSiteAdviceEnabled',
    'kpi2Enabled',
    'landmarksEnabled',
    'rtdsEnabled',
    'textMessagingEnabled',
    'trailersEnabled',
    'guidedNavigationEnabled',
    'tireControlEnabled',
    'appStoreEnabled',
    'truckIdentificationEnabled',
    'trailerControlServiceEnabled',
    'j1939DataServiceEnabled',
    'navigationEtaEnabled',
    'oneMinuteRuleEnabled',
];
export const dropdownServiceFields = [
    'tachograph',
    'tachoProtocol',
    'workflowType',
    'navigationType',
    'imageCapturingType',
    'inhibitorServiceType',
    'tellTaleServiceType',
];

export interface FieldValues {
    id?: number;
    vin?: string;
    vrn?: string;
    unitId?: string;
    alias?: string;
    device: number | null;
    tag?: string;
    companyCardId?: string | null;
    depot?: number | null;
    vehicleType?: number | null;
    vehicleCategory: number;
    alertsAndPanicEnabled?: boolean;
    driverCardsEnabled?: boolean;
    driverCoachEnabled?: boolean;
    driverHoursEnabled?: boolean;
    dseEnabled?: boolean;
    frequentPositioningEnabled?: boolean;
    fuelSiteAdviceEnabled?: boolean;
    kpi2Enabled?: boolean;
    landmarksEnabled?: boolean;
    rtdsEnabled?: boolean;
    textMessagingEnabled?: boolean;
    trailersEnabled?: boolean;
    guidedNavigationEnabled?: boolean;
    tireControlEnabled?: boolean;
    appStoreEnabled?: boolean;
    truckIdentificationEnabled?: boolean;
    trailerControlServiceEnabled?: boolean;
    j1939DataServiceEnabled?: boolean;
    navigationEtaEnabled?: boolean;
    tachograph?: number;
    tachoProtocol?: number;
    tachoGeneration?: number;
    oneMinuteRuleEnabled?: boolean;
    workflowType?: number;
    navigationType?: number;
    imageCapturingType?: number;
    inhibitorServiceType?: number;
    tellTaleServiceType?: number;
    alertsAndPanicEnabledSettingsValueSource?: SettingsValueSource;
    driverCardsEnabledSettingsValueSource?: SettingsValueSource;
    driverCoachEnabledSettingsValueSource?: SettingsValueSource;
    driverHoursEnabledSettingsValueSource?: SettingsValueSource;
    dseEnabledSettingsValueSource?: SettingsValueSource;
    frequentPositioningEnabledSettingsValueSource?: SettingsValueSource;
    fuelSiteAdviceEnabledSettingsValueSource?: SettingsValueSource;
    kpi2EnabledSettingsValueSource?: SettingsValueSource;
    landmarksEnabledSettingsValueSource?: SettingsValueSource;
    rtdsEnabledSettingsValueSource?: SettingsValueSource;
    textMessagingEnabledSettingsValueSource?: SettingsValueSource;
    trailersEnabledSettingsValueSource?: SettingsValueSource;
    guidedNavigationEnabledSettingsValueSource?: SettingsValueSource;
    tireControlEnabledSettingsValueSource?: SettingsValueSource;
    appStoreEnabledSettingsValueSource?: SettingsValueSource;
    truckIdentificationEnabledSettingsValueSource?: SettingsValueSource;
    trailerControlServiceEnabledSettingsValueSource?: SettingsValueSource;
    j1939DataServiceEnabledSettingsValueSource?: SettingsValueSource;
    navigationEtaEnabledSettingsValueSource?: SettingsValueSource;
    tachographSettingsValueSource?: SettingsValueSource;
    tachoProtocolSettingsValueSource?: SettingsValueSource;
    tachoGenerationSettingsValueSource?: SettingsValueSource;
    oneMinuteRuleEnabledSettingsValueSource?: SettingsValueSource;
    workflowTypeSettingsValueSource?: SettingsValueSource;
    navigationTypeSettingsValueSource?: SettingsValueSource;
    imageCapturingTypeSettingsValueSource?: SettingsValueSource;
    inhibitorServiceTypeSettingsValueSource?: SettingsValueSource;
    tellTaleServiceTypeSettingsValueSource?: SettingsValueSource;
    adBlueTankSize?: number;
    adBlueTankSizeValueSource?: SettingsValueSource;
    primaryFuelTankSize?: number;
    primaryFuelTankSizeValueSource?: SettingsValueSource;
    primaryFuelTankType?: number;
    primaryFuelTankTypeValueSource?: SettingsValueSource;
    secondaryFuelTankSize?: number;
    secondaryFuelTankSizeValueSource?: SettingsValueSource;
    secondaryFuelTankType?: number;
    secondaryFuelTankTypeValueSource?: SettingsValueSource;
}

export interface DefaultSettingsValues {
    alertsAndPanicEnabled: boolean;
    driverCardsEnabled: boolean;
    driverCoachEnabled: boolean;
    driverHoursEnabled: boolean;
    dseEnabled: boolean;
    frequentPositioningEnabled: boolean;
    fuelSiteAdviceEnabled: boolean;
    kpi2Enabled: boolean;
    landmarksEnabled: boolean;
    rtdsEnabled: boolean;
    textMessagingEnabled: boolean;
    trailersEnabled: boolean;
    guidedNavigationEnabled: boolean;
    tireControlEnabled: boolean;
    appStoreEnabled: boolean;
    truckIdentificationEnabled: boolean;
    trailerControlServiceEnabled: boolean;
    j1939DataServiceEnabled: boolean;
    navigationEtaEnabled: boolean;
    tachograph: number;
    tachoProtocol: number;
    tachoGeneration: number;
    oneMinuteRuleEnabled: boolean;
    workflowType: number;
    navigationType: number;
    imageCapturingType: number;
    inhibitorServiceType: number;
    tellTaleServiceType: number;
    primaryFuelTankSize: number;
    secondaryFuelTankSize: number;
    adBlueTankSize: number;
    primaryFuelTankType: number;
    secondaryFuelTankType: number;
}

export interface MapMulticolumnClassificationProps {
    devices: Device[];
    vehicles: Vehicle[];
    vehicleDisplayPreferencesFormat: DisplayUserPreferencesVehicleDisplayFormat;
    devicesTypes: NumericDictionary<DeviceType>;
}
