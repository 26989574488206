import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type {
    Classification,
    DisplayUserPreferencesTrailerDisplayFormat,
    ResolvedCompartmentStatus,
} from '~/services/ApiClient';
import { formatTrailerName } from '~/services/Formatters';

export const getTrailerDisplayNameFactory =
    (displayNameFormat: DisplayUserPreferencesTrailerDisplayFormat) =>
    (entry: MonitoringVehicleEntry): string | undefined => {
        return entry.trailerConnection
            ? formatTrailerName(entry.trailerConnection.trailerReference, displayNameFormat)
            : undefined;
    };

export const getTrailerNumberValue = (entry: MonitoringVehicleEntry): number | undefined =>
    entry.trailerConnection?.trailer?.trailerNumber;

export const getTrailerUnitIdValue = (entry: MonitoringVehicleEntry): string | undefined =>
    entry.trailerConnection?.trailer?.unitId;

export const getEventTypeValue = (entry: MonitoringVehicleEntry): Classification | undefined =>
    entry.trailerConnection?.trailerStatus?.lastEvent.trailerEventType;

export const getReeferStatusValue = (entry: MonitoringVehicleEntry): Classification | undefined =>
    entry.trailerConnection?.trailerStatus?.reefer?.status;

export const getTrailerWeightValue = (entry: MonitoringVehicleEntry): number | undefined =>
    entry.trailerConnection?.trailerStatus?.ebs?.trailerWeight;

export const getEBSAxleLoadValue = (entry: MonitoringVehicleEntry): number | undefined =>
    entry.trailerConnection?.trailerStatus?.ebs?.axleLoadSum;

export const getEBSMileageValue = (entry: MonitoringVehicleEntry): number | undefined =>
    entry.trailerConnection?.trailerStatus?.ebs?.milage;

export const getEBSTimeStampValue = (entry: MonitoringVehicleEntry): Date | undefined =>
    entry.trailerConnection?.trailerStatus?.ebs?.dateTime;

export const getTrailerLastEventValue = (entry: MonitoringVehicleEntry): Date | undefined =>
    entry.trailerConnection?.trailerStatus?.lastEvent.dateTime;

export const getCompartmentNStatusValue = (compartmentNumber: number) => {
    return (entry: MonitoringVehicleEntry): Classification | undefined =>
        entry.trailerConnection?.trailerStatus?.compartments[compartmentNumber]?.status;
};

export const getCompartmentNStatusManufacturerValue = (compartmentNumber: number) => {
    return (entry: MonitoringVehicleEntry): string | undefined =>
        entry.trailerConnection?.trailerStatus?.compartments[compartmentNumber]?.statusManufacturer;
};

export const getCompartmentNLastEventValue = (compartmentNumber: number) => {
    return (entry: MonitoringVehicleEntry): Date | undefined =>
        entry.trailerConnection?.trailerStatus?.compartments[compartmentNumber]?.dateTime;
};

export const getCompartmentNSupplyTemperatureValue = (compartmentNumber: number) => {
    return (entry: MonitoringVehicleEntry): number | undefined =>
        entry.trailerConnection?.trailerStatus?.compartments[compartmentNumber]?.supplyTemperature;
};

export const getCompartmentNReturnTemperatureValue = (compartmentNumber: number) => {
    return (entry: MonitoringVehicleEntry): number | undefined =>
        entry.trailerConnection?.trailerStatus?.compartments[compartmentNumber]?.returnTemperature;
};

export const getCompartmentNSetpointTemperatureValue = (compartmentNumber: number) => {
    return (entry: MonitoringVehicleEntry): number | undefined =>
        entry.trailerConnection?.trailerStatus?.compartments[compartmentNumber]?.setpointTemperature;
};

export const getDoorNSensorValue = (doorNumber: number) => {
    return (entry: MonitoringVehicleEntry): Classification[] | undefined =>
        entry.trailerConnection?.trailerStatus?.doors[doorNumber]?.status;
};

export const getCompartmentN = (compartmentNumber: number) => {
    return (entry: MonitoringVehicleEntry): ResolvedCompartmentStatus | undefined =>
        entry.trailerConnection?.trailerStatus?.compartments[compartmentNumber];
};
