import { Drawer } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import memoizeOne from 'memoize-one';
import * as React from 'react';
import { wrapDisplayName } from 'react-recompose';
import type { Dispatch } from 'redux';

import type { InjectedUserDataProps } from '~/components/AuthenticationData';
import type { InjectedFeatureFlagsProps } from '~/components/FeatureFlags';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { Securables } from '~/services/ApiClient';

import { getExternalLinkItems, getMiscellaneousMenuItems, getModuleMenuItems } from '../../moduleMenuItems';

import { MenuBar } from './components/MenuBar';
import { OverlayMenu } from './components/OverlayMenu';
import type { MenuStyleRules } from './styles';

export interface MenuProps {}

export interface DispatchProps {
    dispatch: Dispatch;
    openMenuAction: () => void;
    closeMenuAction: () => void;
}

export interface StateProps {
    isOverlayMenuOpen: boolean;
    securables: Securables;
}

export interface MenuInnerProps
    extends MenuProps,
        InjectedTranslationProps,
        InjectedUserDataProps,
        DispatchProps,
        StateProps,
        WithStyles<typeof MenuStyleRules, true>,
        InjectedFeatureFlagsProps {}

export const withMenuHoc = <WrappedProps extends object>(
    WrappedComponent: React.ComponentType<WrappedProps>
): React.ComponentType<WrappedProps & MenuInnerProps> => {
    return class extends React.Component<WrappedProps & MenuInnerProps> {
        public static displayName = wrapDisplayName(WrappedComponent, 'WithMenu');

        private getModuleMenuItemsMemoized = memoizeOne(getModuleMenuItems);
        private getMiscellaneousMenuItemsMemoized = memoizeOne(getMiscellaneousMenuItems);
        private getExternalLinkItemsMemoized = memoizeOne(getExternalLinkItems);

        public render() {
            const {
                t,
                securables,
                theme,
                i18n,
                classes,
                isOverlayMenuOpen,
                dispatch,
                openMenuAction,
                closeMenuAction,
                featureFlags,
                cid,
                userIsImpersonated,
                userName,
                userFullName,
                customerCareUserName,
                customerName,
                ...restProps
            } = this.props;

            const items = this.getModuleMenuItemsMemoized({ t, securables, featureFlags });
            const externalLinkItems = this.getExternalLinkItemsMemoized({ securables, partner: theme.partner, cid });
            const miscellaneousMenuItems = this.getMiscellaneousMenuItemsMemoized({
                t,
                securables,
                dispatch,
                featureFlags,
            });

            const menuBarItems = [...items, ...externalLinkItems, ...miscellaneousMenuItems];

            return (
                <div className={classes.root}>
                    <div className={classes.menu} data-id="app-menu">
                        <MenuBar items={menuBarItems} onMenuClick={openMenuAction} />
                        <Drawer open={isOverlayMenuOpen} onClose={closeMenuAction} disableRestoreFocus>
                            <OverlayMenu
                                moduleMenuItems={items}
                                externalLinksItems={externalLinkItems}
                                miscellaneousMenuItems={miscellaneousMenuItems}
                                onClose={closeMenuAction}
                            />
                        </Drawer>
                    </div>

                    <div className={classes.content}>
                        <WrappedComponent {...(restProps as WrappedProps)} />
                    </div>
                </div>
            );
        }
    };
};
