import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '292px',
        height: '222px',
        display: 'flex',
        flexDirection: 'column',
    },
    mapContainer: {
        flex: 1,
        position: 'relative',
    },
    addressContainer: {
        height: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
    },
    closeButton: {
        fontSize: 14,
    },
    mapActionButtons: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 1,
    },
    map: {
        zIndex: 0,
        position: 'relative',
        width: '100%',
        height: '100%',
        '& .leaflet-right .leaflet-control': {
            marginRight: theme.spacing(2),
        },
        '& .leaflet-bottom .leaflet-control': {
            marginBottom: theme.spacing(2),
        },
    },
    positionIcon: {
        fontSize: 50,
        filter: 'drop-shadow( 2px 0px 2px rgba(0, 0, 0, .4))',
    },
}));
