import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';

interface ExpandableContentProps {
    expanded: boolean;
}

const FooterButton = styled(Button)(() => ({
    width: '100%',
    fontSize: 'smaller',
}));

const ExpandableContent = styled('div')<ExpandableContentProps>(({ expanded }) => ({
    filter: expanded ? 'none' : 'opacity(0.3)',
    transitionDuration: '0.2',
}));

const ExpandableContainer = styled(Collapse)(({ theme }) => ({
    padding: theme?.spacing(2),
    paddingBottom: 0,
    borderRadius: `${theme?.spacing()} ${theme?.spacing()} 0 0`,
    border: '1px solid white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderBottom: 'none',
}));

const FooterContainer = styled('div')(({ theme }) => ({
    border: '1px solid white',
    borderTop: 'none',
    borderRadius: `0 0 ${theme.spacing()} ${theme.spacing()}`,
    textAlign: 'center',
    background: 'linear-gradient(0deg, rgb(255 255 255) 0%, rgb(255 1 1 / 0%) 90%, rgba(2, 0, 36, 0) 100%)',
}));

export { ExpandableContainer, ExpandableContent, FooterButton, FooterContainer };
