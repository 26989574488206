import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const FilterOffIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M10 16h4v2H10Zm8-3V11H14.81l2 2Zm3-5V6H9.82l2 2ZM2.78 1.51h0L1.5 2.77 4.73 6H3V8H6.73l3 3H6v2h5.73l9.5 9.5 1.27-1.27Z" />
        </SvgIcon>
    );
};
FilterOffIcon.displayName = 'FilterOffIcon';

export { FilterOffIcon };
