import type { Dispatch } from 'redux';

import type { TokenRequest } from '~/data/authentication';
import { tokenExpiredAction, verifyTokenAction } from '~/data/authentication';
import { reportError } from '~/reporting';

import { LogoutReason } from '../../models';
import type { JwtStoreState } from '../../reducers';
import { isAuthenticated } from '../../reducers';

import type { DispatchProps, StateProps } from './component';

export const mapStateToProps = (state: JwtStoreState): StateProps => {
    return {
        isAuthenticated: !state.tokenExpired && isAuthenticated(state),
        jwt: state.jwt,
        decodedJwt: state.decodedJwt,
        impersonatorJwt: state.impersonatorJwt,
        decodedImpersonatorJwt: state.decodedImpersonatorJwt,
        verified: state.verified,
        logoutReason: state.tokenExpired ? LogoutReason.TokenExpired : undefined,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    verifyToken: (request: TokenRequest) => dispatch(verifyTokenAction(request)).catch(reportError),
    tokenExpired: () => dispatch(tokenExpiredAction()),
});
