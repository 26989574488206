import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withSceneAnalytics, withScreenAnalytics } from '~/components/Analytics';
import { protectedComponentHoc } from '~/components/AuthenticationData';
import { requiredDisplayUserPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { withMenu } from '~/components/Navigation';
import { requiredSceneAssetSelectorUserPreferencesFactory } from '~/components/SceneAssetSelector';
import { requiredDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';

import VehicleAdministrationComponent from './component';
import { requiredVehicleAdministrationDetailsPaneUserPreferences } from './components/DetailsPane';
import { ADMINVEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY, requiredAdminVehicleUserPreferences } from './preferences';
import { mapStateToProps } from './redux';
import { vehicleAdministrationStateSelector } from './selectors';

export * from './storestate';
export * from './models';

export const VehicleAdministration = compose(
    setDisplayName('VehicleAdministration'),
    protectedComponentHoc,
    withMenu,
    withSceneAnalytics('vehicleadministration'),
    withScreenAnalytics('vehicleadministration'),
    ensureUserPreferences([
        requiredSceneAssetSelectorUserPreferencesFactory(ADMINVEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredDisplayUserPreferences,
        requiredAdminVehicleUserPreferences,
        requiredVehicleAdministrationDetailsPaneUserPreferences,
        requiredDetailsPaneUserPreferences,
    ]),
    connect(
        createSelector(
            settingDataSelector(SettingsKey.SECURABLES),
            vehicleAdministrationStateSelector,
            keyDataStateSelectorFactory(ADMINVEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY),
            mapStateToProps
        )
    )
)(VehicleAdministrationComponent);
