import { compose, setDisplayName } from 'react-recompose';
import { withRouter } from 'react-router';

import type { CustomNavLinkInnerProps, CustomNavLinkProps } from './component';
import { CustomNavLinkComponent } from './component';

export const CustomNavLink = compose<CustomNavLinkInnerProps, CustomNavLinkProps>(
    setDisplayName('CustomNavLink'),
    withRouter
)(CustomNavLinkComponent);
