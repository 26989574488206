import Close from '@mui/icons-material/Close';
import { Avatar, CardHeader, Paper } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';
import { useContext } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledIconButton } from '~/components/TitledIconButton';

import { DetailsPaneContext } from '../../context';

import type { SceneDetailsPaneHeaderClassKey } from './styles';

export interface SceneDetailsPaneHeaderProps {
    avatar: JSX.Element;
    primaryIdentifier: string;
    secondaryIdentifier: string;
    className?: string;
}

export interface SceneDetailsPaneHeaderInnerProps
    extends SceneDetailsPaneHeaderProps,
        WithStyles<SceneDetailsPaneHeaderClassKey>,
        InjectedTranslationProps {}

export const SceneDetailsPaneHeaderComponent: FC<SceneDetailsPaneHeaderInnerProps> = (props) => {
    const { t, classes, avatar, primaryIdentifier, secondaryIdentifier, className } = props;

    const { close } = useContext(DetailsPaneContext);

    return (
        <Paper square className={className}>
            <CardHeader
                classes={{ root: classes.root }}
                avatar={<Avatar className={classes.avatar}>{avatar}</Avatar>}
                title={<div data-id="primary-identifier">{primaryIdentifier}</div>}
                titleTypographyProps={{ color: 'inherit' }}
                subheader={<div data-id="secondary-identifier">{secondaryIdentifier}</div>}
                subheaderTypographyProps={{ color: 'inherit', className: classes.secondaryIdentifier }}
                action={
                    <TitledIconButton
                        title={t('close')}
                        onClick={close}
                        className={classes.action}
                        data-id="close-icon"
                    >
                        <Close />
                    </TitledIconButton>
                }
                data-id="asset-details-header"
            />
        </Paper>
    );
};
