import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const TruckIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M20.32 7.4h-.91V2.75A.78.78 0 0 0 18.63 2H5.36a.78.78 0 0 0-.77.78V7.4H3.65a.19.19 0 0 0-.19.19v2.54a.19.19 0 0 0 .19.2H4.6v8.8a.77.77 0 0 0 .77.78h0a.28.28 0 0 0 0 .13v.62a.32.32 0 0 0 .33.32H8.32a.32.32 0 0 0 .32-.32V20a.51.51 0 0 0 0-.13H15.5a.27.27 0 0 0 0 .13v.62a.32.32 0 0 0 .32.32h2.65a.32.32 0 0 0 .33-.32V20a.33.33 0 0 0 0-.14.78.78 0 0 0 .68-.77V10.32h.91a.19.19 0 0 0 .19-.19V7.59A.2.2 0 0 0 20.32 7.4Zm-13 10.76a.33.33 0 0 1-.33.33H5.83a.32.32 0 0 1-.32-.33V17a.32.32 0 0 1 .32-.33H7a.33.33 0 0 1 .33.33Zm7.84 0a.32.32 0 0 1-.32.33H9.33A.32.32 0 0 1 9 18.16V16.08a.32.32 0 0 1 .32-.32h5.54a.32.32 0 0 1 .32.32Zm3.3 0a.32.32 0 0 1-.32.33H17a.32.32 0 0 1-.32-.33V17a.32.32 0 0 1 .32-.33h1.19a.32.32 0 0 1 .32.33Zm0-7.21a.38.38 0 0 1-.34.41H5.85A.38.38 0 0 1 5.52 11V5.89a.38.38 0 0 1 .33-.41h12.3a.38.38 0 0 1 .34.41Z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </SvgIcon>
    );
};
TruckIcon.displayName = 'TruckIcon';

export { TruckIcon };
