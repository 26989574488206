import type { NumericDictionary } from '~/libs/utility';
import type { MessageBody, ResolvedMessageBody } from '~/services/ApiClient';
import {
    MessageBodyType,
    TextMessageBody,
    UnresolvedWorkflowMessageBody,
    WorkflowFormDefinition,
    WorkflowFormDefinitionStatus,
    WorkflowMessageBody,
} from '~/services/ApiClient';

export const resolveConversationMessageBody = (
    body: MessageBody,
    workflowFormDefinitions: NumericDictionary<WorkflowFormDefinition | WorkflowFormDefinitionStatus> | undefined
): ResolvedMessageBody => {
    let type: MessageBodyType;
    if (body instanceof WorkflowMessageBody) {
        type = MessageBodyType.Workflow;
    } else if (body instanceof TextMessageBody) {
        type = MessageBodyType.Text;
    } else if (body instanceof UnresolvedWorkflowMessageBody) {
        type = MessageBodyType.Workflow;
    } else {
        throw new Error('Unsupported message body type');
    }

    const isWorkflow = body instanceof WorkflowMessageBody;
    const workflowFormDefinitionOrStatus =
        workflowFormDefinitions && isWorkflow
            ? workflowFormDefinitions[(body as WorkflowMessageBody).formDefinitionId]
            : undefined;

    return {
        value: body,
        type,
        workflowFormDefinition:
            workflowFormDefinitionOrStatus instanceof WorkflowFormDefinition
                ? workflowFormDefinitionOrStatus
                : undefined,
        workflowFormDefinitionStatus: !isWorkflow
            ? undefined
            : workflowFormDefinitionOrStatus instanceof WorkflowFormDefinition
              ? WorkflowFormDefinitionStatus.RESOLVED
              : workflowFormDefinitionOrStatus ?? WorkflowFormDefinitionStatus.REQUIRED,
    };
};
