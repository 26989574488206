import DateRange from '@mui/icons-material/DateRange';
import Schedule from '@mui/icons-material/Schedule';
import TextField from '@mui/material/TextField';
import type { WithStyles } from '@mui/styles';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker/DesktopDateTimePicker';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { DateTimeSettingItem } from '../../models';

import type { DateTimeSettingClassKey } from './styles';

export interface DateTimeSettingProps {
    setting: DateTimeSettingItem;
    size?: 'medium' | 'small';
    margin?: 'dense' | 'none';
    className?: string;
}

export interface DateTimeSettingInnerProps extends DateTimeSettingProps, WithStyles<DateTimeSettingClassKey> {}

export const DateTimeSettingComponent: FC<DateTimeSettingInnerProps> = (props) => {
    const { setting, classes, size, margin, className } = props;

    const {
        key,
        value,
        isRequired,
        minDate,
        maxDate,
        disablePastDatesInPicker,
        disableFutureDatesInPicker,
        errorText,
        onValueChange,
    } = setting;

    const [inputValue, setInputValue] = useState<Moment | undefined>(value !== undefined ? moment(value) : undefined);

    useEffect(() => {
        setInputValue(value !== undefined ? moment(value) : undefined);
    }, [value]);

    const helpers = useMemo(() => {
        const timeFormat = moment.localeData().longDateFormat('LT');
        return {
            dateFormat: moment.localeData().longDateFormat('L'),
            timeFormat,
            ampm: timeFormat.toLowerCase().includes('a'),
        };
    }, []);

    const validateInput = useCallback(
        (input?: Moment) =>
            (!isRequired && !input) ||
            (input &&
                input.isValid() &&
                (!minDate || input.isSameOrAfter(minDate)) &&
                (!maxDate || input.isSameOrBefore(maxDate))),
        [isRequired, minDate, maxDate]
    );

    const hasError = !validateInput(inputValue);

    const onBlur = useCallback(() => {
        if (hasError) {
            setInputValue(moment(value));
        }
    }, [hasError, value]);

    const onChangeCallback = useCallback(
        (input: Moment) => {
            setInputValue(input);

            if (validateInput(input)) {
                onValueChange(input?.toDate());
            }
        },
        [validateInput, onValueChange]
    );

    return (
        <DesktopDateTimePicker
            className={className}
            inputFormat={`${helpers.dateFormat} ${helpers.timeFormat}`}
            value={inputValue}
            InputProps={{
                ...(margin && { margin }),
                error: hasError,
                onBlur,
                size,
                value: inputValue === undefined ? ' ' : undefined,
                required: isRequired,
            }}
            onChange={onChangeCallback}
            disablePast={disablePastDatesInPicker}
            disableFuture={disableFutureDatesInPicker}
            renderInput={(inputProps) => (
                <TextField
                    {...inputProps}
                    variant="outlined"
                    data-id={key}
                    helperText={hasError ? errorText : undefined}
                />
            )}
            ampm={helpers.ampm}
            timeIcon={<Schedule className={classes.toolbarIcon} />}
            dateRangeIcon={<DateRange className={classes.toolbarIcon} />}
        />
    );
};
