import type { Duration } from 'moment';
import moment from 'moment';
import * as React from 'react';

import { DateTimeFormatter, DurationFormatter, ViolationType } from '~/components/Formatters';
import { ViolationFieldFormatterFactory } from '~/components/Formatters/ViolationField';
import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';
import type { DriverActivityType, DriverSubActivityType, LoginStatus, ViolationField } from '~/services/ApiClient';
import {
    formatDriverActivity,
    formatDriverHoursLoginStatus,
    formatDriverSubpageListItemLabel,
    formatDuration,
} from '~/services/Formatters';

import { SectionName } from '../../constants';

import { useStyles } from './styles';

export interface GeneralSectionProps {
    dragHandleElement: JSX.Element;
    toggleCollapsed: () => void;
    isCollapsed: boolean;
    activity?: DriverActivityType;
    subActivity?: DriverSubActivityType;
    activityStartDateTime?: Date;
    shiftDuration?: ViolationField<Duration>;
    currentActivityDuration?: Duration;
    continuousWorkWait?: ViolationField<Duration>;
    operationalWeekDuration?: ViolationField<Duration>;
    loginStatus?: LoginStatus;
}

export interface GeneralSectionInnerProps extends GeneralSectionProps, InjectedTranslationProps {}

export const GeneralSectionComponent: React.FC<GeneralSectionInnerProps> = ({
    t,
    dragHandleElement,
    toggleCollapsed,
    isCollapsed,
    activity,
    subActivity,
    activityStartDateTime,
    shiftDuration,
    currentActivityDuration,
    continuousWorkWait,
    operationalWeekDuration,
    loginStatus,
}) => {
    const classes = useStyles();

    const ViolationDurationFormatter = React.useMemo(
        () => ViolationFieldFormatterFactory((v: Duration) => formatDuration(v)),
        []
    );

    const keyValueItems: KeyValueItem[] = [
        {
            valueDataId: 'last-activity',
            label: formatDriverSubpageListItemLabel(t, 'general-last-activity'),
            value: activity && (
                <span data-id="last-activity" className={classes.simpleValue}>
                    {formatDriverActivity(t, activity)}
                </span>
            ),
        },
        {
            valueDataId: 'last-subactivity',
            label: formatDriverSubpageListItemLabel(t, 'general-last-sub-activity'),
            value: subActivity && (
                <span data-id="last-subactivity" className={classes.simpleValue}>
                    {subActivity.name}
                </span>
            ),
        },
        {
            valueDataId: 'start-time',
            label: formatDriverSubpageListItemLabel(t, 'general-date-time'),
            value: activityStartDateTime ? (
                <span data-id="start-time" className={classes.simpleValue}>
                    <DateTimeFormatter value={activityStartDateTime} />
                </span>
            ) : undefined,
        },
        {
            valueDataId: 'duration',
            label: formatDriverSubpageListItemLabel(t, 'general-duration'),
            value: currentActivityDuration ? (
                <DurationFormatter
                    threshold={moment.duration()}
                    violationType={ViolationType.ABOVE}
                    value={currentActivityDuration}
                />
            ) : undefined,
        },
        {
            valueDataId: 'current-shift-duration',
            label: formatDriverSubpageListItemLabel(t, 'general-current-shift'),
            value: shiftDuration ? (
                <ViolationDurationFormatter
                    t={t}
                    fieldName={formatDriverSubpageListItemLabel(t, 'general-current-shift')}
                    value={shiftDuration}
                />
            ) : undefined,
        },
        {
            valueDataId: 'continuous-work-available',
            label: formatDriverSubpageListItemLabel(t, 'general-continuous-work-available'),
            value: continuousWorkWait ? (
                <ViolationDurationFormatter
                    t={t}
                    fieldName={formatDriverSubpageListItemLabel(t, 'general-continuous-work-available')}
                    value={continuousWorkWait}
                />
            ) : undefined,
        },
        {
            valueDataId: 'operational-week-duration',
            label: formatDriverSubpageListItemLabel(t, 'general-operational-week-duration'),
            value: continuousWorkWait ? (
                <ViolationDurationFormatter
                    t={t}
                    fieldName={formatDriverSubpageListItemLabel(t, 'general-operational-week-duration')}
                    value={operationalWeekDuration}
                />
            ) : undefined,
        },
        {
            valueDataId: 'login-manual-status',
            label: formatDriverSubpageListItemLabel(t, 'general-login-manual-status'),
            value: !isUndefined(loginStatus) ? (
                <span data-id="login-manual-status" className={classes.simpleValue}>
                    {formatDriverHoursLoginStatus(t, loginStatus)}
                </span>
            ) : undefined,
        },
    ];

    return (
        <ReorderableSection
            title={t(`driver-subpage-${SectionName.GENERAL}`)}
            dataId={SectionName.GENERAL}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            dragHandleElement={dragHandleElement}
        >
            <KeyValueList list={keyValueItems} listDataId="property-list" />
        </ReorderableSection>
    );
};
