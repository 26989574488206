import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ConversationHeaderInnerProps, ConversationHeaderProps } from './component';
import { ConversationHeaderComponent } from './component';
import { ConversationHeaderStyleRules } from './styles';

export const ConversationHeader = compose<ConversationHeaderInnerProps, ConversationHeaderProps>(
    setDisplayName('ConversationHeader'),
    withStyles(ConversationHeaderStyleRules)
)(ConversationHeaderComponent);
