import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SectionListItemClassKey = 'root' | 'typography' | 'label' | 'value' | 'icon';

export const SectionListItemClassStyleRules: StyleRulesCallback<Theme, {}, SectionListItemClassKey> = (theme) =>
    createStyles({
        /**
         * Remove padding top/bottom from list item and
         * move it to label & value to be able to include
         * a value element that has more than 14px height
         * without increasing the list item height.
         */
        root: {
            padding: `0 ${theme.spacing()}`,
            overflow: 'hidden',
        },
        typography: {
            lineHeight: '14px',
        },
        label: {
            paddingTop: theme.spacing(0.75),
            paddingBottom: theme.spacing(0.75),
            marginRight: theme.spacing(),
            flexGrow: 1,
        },
        value: {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            textAlign: 'right',
        },
        icon: {
            display: 'inline-flex',
        },
    });
