import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';

import { vehicleTypeAdministrationStateSelector } from '../../selectors';

import type { VehicleTypeEditorInnerProps, VehicleTypeEditorProps } from './component';
import { VehicleTypeEditorComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export const VehicleTypeEditor = compose<VehicleTypeEditorInnerProps, VehicleTypeEditorProps>(
    setDisplayName('VehicleTypeEditor'),
    ensureSettings([SettingsKey.VEHICLE_TYPE_CATEGORIES, SettingsKey.AXLES, SettingsKey.EMISSION_CLASS]),
    connect(
        createSelector(
            settingDataSelector(SettingsKey.VEHICLE_TYPE_CATEGORIES),
            settingDataSelector(SettingsKey.AXLES),
            settingDataSelector(SettingsKey.EMISSION_CLASS),
            vehicleTypeAdministrationStateSelector,
            (_: never, ownProps: VehicleTypeEditorProps) => ownProps,
            mapStateToProps
        ),
        mapDispatchToProps
    )
)(VehicleTypeEditorComponent);
