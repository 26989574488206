import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DurationInputInnerProps, DurationInputProps } from './component';
import { DurationInputComponent } from './component';

export * from './utility';
export const DurationInput = compose<DurationInputInnerProps, DurationInputProps>(
    setDisplayName('DurationInput'),
    withTranslation()
)(DurationInputComponent);
