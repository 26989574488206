import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type {
    GetMessageResponse,
    MessagesUpdate,
    QueryMessagesRequest,
    QueryMessagesResponse,
    ValidationFailedResponse,
} from '~/services/ApiClient';
import type { Action, BaseAction, FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

import type { SendMessageMeta } from './models';

export interface MessageActionTypeMeta {
    messageId: number;
    includeDeleted?: boolean;
}
export interface MessageActionBulkTypeMeta {
    messageIds: number[];
    includeDeleted?: boolean;
}

export enum ActionTypePrefixes {
    MESSAGES_QUERY = 'MESSAGES_QUERY',
    MESSAGES_GET = 'MESSAGES_GET',
    MESSAGES_MARKMESSAGEASREAD = 'MESSAGES_MARKMESSAGEASREAD',
    MESSAGES_MARKMESSAGEASUNREAD = 'MESSAGES_MARKMESSAGEASUNREAD',
    MESSAGES_DELETEMESSAGE = 'MESSAGES_DELETEMESSAGE',
    MESSAGES_UNDELETEMESSAGE = 'MESSAGES_UNDELETEMESSAGE',
    MESSAGES_SEND = 'MESSAGES_SEND',
}

export enum ActionTypeKeys {
    MESSAGES_QUERY_PENDING = 'MESSAGES_QUERY_PENDING',
    MESSAGES_QUERY_FULFILLED = 'MESSAGES_QUERY_FULFILLED',
    MESSAGES_QUERY_REJECTED = 'MESSAGES_QUERY_REJECTED',
    MESSAGES_UPDATE = 'MESSAGES_UPDATE',
    MESSAGES_GET_PENDING = 'MESSAGES_GET_PENDING',
    MESSAGES_GET_FULFILLED = 'MESSAGES_GET_FULFILLED',
    MESSAGES_GET_REJECTED = 'MESSAGES_GET_REJECTED',
    MESSAGES_CLEARFAILEDREQUESTS = 'MESSAGES_CLEARFAILEDREQUESTS',
    MESSAGES_MARKMESSAGEASREAD_PENDING = 'MESSAGES_MARKMESSAGEASREAD_PENDING',
    MESSAGES_MARKMESSAGEASREAD_FULFILLED = 'MESSAGES_MARKMESSAGEASREAD_FULFILLED',
    MESSAGES_MARKMESSAGEASREAD_REJECTED = 'MESSAGES_MARKMESSAGEASREAD_REJECTED',
    MESSAGES_MARKMESSAGEASUNREAD_PENDING = 'MESSAGES_MARKMESSAGEASUNREAD_PENDING',
    MESSAGES_MARKMESSAGEASUNREAD_FULFILLED = 'MESSAGES_MARKMESSAGEASUNREAD_FULFILLED',
    MESSAGES_MARKMESSAGEASUNREAD_REJECTED = 'MESSAGES_MARKMESSAGEASUNREAD_REJECTED',
    MESSAGES_DELETEMESSAGE_PENDING = 'MESSAGES_DELETEMESSAGE_PENDING',
    MESSAGES_DELETEMESSAGE_FULFILLED = 'MESSAGES_DELETEMESSAGE_FULFILLED',
    MESSAGES_DELETEMESSAGE_REJECTED = 'MESSAGES_DELETEMESSAGE_REJECTED',
    MESSAGES_UNDELETEMESSAGE_PENDING = 'MESSAGES_UNDELETEMESSAGE_PENDING',
    MESSAGES_UNDELETEMESSAGE_FULFILLED = 'MESSAGES_UNDELETEMESSAGE_FULFILLED',
    MESSAGES_UNDELETEMESSAGE_REJECTED = 'MESSAGES_UNDELETEMESSAGE_REJECTED',
    MESSAGES_SEND_PENDING = 'MESSAGES_SEND_PENDING',
    MESSAGES_SEND_FULFILLED = 'MESSAGES_SEND_FULFILLED',
    MESSAGES_SEND_REJECTED = 'MESSAGES_SEND_REJECTED',
    COMMUNICATION_CLEAR_DATA = 'COMMUNICATION_CLEAR_DATA',
    COMMUNICATION_CHANGE_DATETIMERANGE = 'COMMUNICATION_CHANGE_DATETIMERANGE',
    COMMUNICATION_CLEAR_PENDING = 'COMMUNICATION_CLEAR_PENDING',
    COMMUNICATION_APPLY_PENDING = 'COMMUNICATION_APPLY_PENDING',
}

export type ActionTypes =
    | PendingMeta<ActionTypeKeys.MESSAGES_QUERY_PENDING, QueryMessagesRequest>
    | FulfilledMeta<ActionTypeKeys.MESSAGES_QUERY_FULFILLED, Readonly<QueryMessagesResponse>, QueryMessagesRequest>
    | RejectedMeta<ActionTypeKeys.MESSAGES_QUERY_REJECTED, undefined, QueryMessagesRequest>
    | Action<ActionTypeKeys.MESSAGES_UPDATE, MessagesUpdate>
    | PendingMeta<ActionTypeKeys.MESSAGES_GET_PENDING, MessageActionTypeMeta>
    | FulfilledMeta<
          ActionTypeKeys.MESSAGES_GET_FULFILLED,
          Readonly<GetMessageResponse | undefined>,
          MessageActionTypeMeta
      >
    | RejectedMeta<ActionTypeKeys.MESSAGES_GET_REJECTED, undefined, MessageActionTypeMeta>
    | BaseAction<ActionTypeKeys.MESSAGES_CLEARFAILEDREQUESTS>
    | PendingMeta<ActionTypeKeys.MESSAGES_MARKMESSAGEASREAD_PENDING, MessageActionTypeMeta>
    | FulfilledMeta<ActionTypeKeys.MESSAGES_MARKMESSAGEASREAD_FULFILLED, undefined, MessageActionTypeMeta>
    | RejectedMeta<ActionTypeKeys.MESSAGES_MARKMESSAGEASREAD_REJECTED, undefined, MessageActionTypeMeta>
    | PendingMeta<ActionTypeKeys.MESSAGES_MARKMESSAGEASUNREAD_PENDING, MessageActionTypeMeta>
    | FulfilledMeta<ActionTypeKeys.MESSAGES_MARKMESSAGEASUNREAD_FULFILLED, undefined, MessageActionTypeMeta>
    | RejectedMeta<ActionTypeKeys.MESSAGES_MARKMESSAGEASUNREAD_REJECTED, undefined, MessageActionTypeMeta>
    | PendingMeta<ActionTypeKeys.MESSAGES_DELETEMESSAGE_PENDING, MessageActionTypeMeta>
    | FulfilledMeta<ActionTypeKeys.MESSAGES_DELETEMESSAGE_FULFILLED, undefined, MessageActionTypeMeta>
    | RejectedMeta<ActionTypeKeys.MESSAGES_DELETEMESSAGE_REJECTED, undefined, MessageActionTypeMeta>
    | PendingMeta<ActionTypeKeys.MESSAGES_UNDELETEMESSAGE_PENDING, MessageActionTypeMeta>
    | FulfilledMeta<ActionTypeKeys.MESSAGES_UNDELETEMESSAGE_FULFILLED, undefined, MessageActionTypeMeta>
    | RejectedMeta<ActionTypeKeys.MESSAGES_UNDELETEMESSAGE_REJECTED, undefined, MessageActionTypeMeta>
    | PendingMeta<ActionTypeKeys.MESSAGES_SEND_PENDING, SendMessageMeta>
    | FulfilledMeta<ActionTypeKeys.MESSAGES_SEND_FULFILLED, undefined, SendMessageMeta>
    | RejectedMeta<ActionTypeKeys.MESSAGES_SEND_REJECTED, ValidationFailedResponse | unknown, SendMessageMeta>
    | BaseAction<ActionTypeKeys.COMMUNICATION_CLEAR_DATA>
    | Action<ActionTypeKeys.COMMUNICATION_CHANGE_DATETIMERANGE, DateTimeRange>
    | BaseAction<ActionTypeKeys.COMMUNICATION_CLEAR_PENDING>
    | BaseAction<ActionTypeKeys.COMMUNICATION_APPLY_PENDING>;
