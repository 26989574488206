import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { TrafficIncidentsInfoBoxInnerProps, TrafficIncidentsInfoBoxProps } from './component';
import { TrafficIncidentsInfoBoxComponent } from './component';
import { TrafficIncidentsInfoBoxStyleRules } from './styles';

export const TrafficIncidentsInfoBox = compose<TrafficIncidentsInfoBoxInnerProps, TrafficIncidentsInfoBoxProps>(
    setDisplayName('TrafficIncidentsInfoBox'),
    withStyles(TrafficIncidentsInfoBoxStyleRules)
)(TrafficIncidentsInfoBoxComponent);
