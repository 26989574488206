import type { ResolvedVehicleType } from '~/services/ApiClient';

export const getRowId = (entry: ResolvedVehicleType): number => entry.id;

export const VehicleTypesAdministrationEquals = (rowA?: ResolvedVehicleType, rowB?: ResolvedVehicleType): boolean => {
    if (!rowA || !rowB) {
        return false;
    }

    return rowA.id === rowB.id;
};
