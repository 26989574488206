import type { HistoryMapStoreState } from '~/data/monitoring';
import { defaultHistoryMapStoreState } from '~/data/monitoring';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export const historyMapReducer = (
    state: HistoryMapStoreState = defaultHistoryMapStoreState,
    action: ActionTypes
): HistoryMapStoreState => {
    switch (action.type) {
        case ActionTypeKeys.HISTORYMAP_CHANGEVIEWPORT:
            return {
                ...state,
                viewport: action.payload,
                panToPositionGroup: false,
                flyToPositionGroup: false,
                panToPosition: false,
            };
        case ActionTypeKeys.HISTORYMAP_PANTOPOSITIONGROUP:
            return {
                ...state,
                panToPositionGroup: true,
            };

        case ActionTypeKeys.HISTORYMAP_FLYTOPOSITIONGROUP:
            return {
                ...state,
                flyToPositionGroup: true,
            };
        case ActionTypeKeys.HISTORYMAP_PANTOPOSITION:
            return {
                ...state,
                panToPosition: true,
            };
        default:
            return state;
    }
};
