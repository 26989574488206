import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { GridColumnDefinition } from '../../models';

import type { TableHeaderRowTitleInnerProps, TableHeaderRowTitleProps } from './component';
import { createTableHeaderRowTitleComponent } from './component';
import { TableHeaderRowTitleStyleRules } from './styles';

export const createTableHeaderRowTitle = <T>(
    columnDefinitions: Array<GridColumnDefinition<T>>
): React.ComponentType<TableHeaderRowTitleProps<T>> =>
    compose<TableHeaderRowTitleInnerProps<T>, TableHeaderRowTitleProps<T>>(
        setDisplayName('TableHeaderRowTitle'),
        withStyles(TableHeaderRowTitleStyleRules)
    )(createTableHeaderRowTitleComponent<T>(columnDefinitions));
