import { IntegratedFiltering } from '@devexpress/dx-react-grid';

import { keyBy } from '~/libs/utility';

import { createFilteringColumnExtensions } from './createFilteringColumnExtensions';
import { getRawCellValue } from './getRawCellValue';
import type { GridColumnDefinition, GridFilter } from './models';

export const filterDataSourceByFilter = <T>(
    dataSource: T[],
    filters: GridFilter[],
    columns: Array<GridColumnDefinition<T>>,
    visibleColumns: string[],
    filteringEnabled: boolean
): T[] => {
    if (!filteringEnabled || !filters.length || !visibleColumns.length || !dataSource.length) {
        return dataSource;
    }

    const extensions = createFilteringColumnExtensions(columns, visibleColumns);
    const columnsByName = keyBy(columns, 'name');
    const extensionsByName = keyBy(extensions, 'columnName');

    const filterPredicates = filters
        .filter(({ columnName }) => {
            return columnsByName[columnName];
        })
        .map((filter) => {
            const extension = extensionsByName[filter.columnName];
            const column = columnsByName[filter.columnName];

            const valueAccessor = getRawCellValue.bind(undefined, column);
            const predicate = extension?.predicate ?? IntegratedFiltering.defaultPredicate;
            return (row: T) => predicate(valueAccessor(row), filter, row);
        });

    return dataSource.filter((row) => filterPredicates.every((p) => p(row)));
};
