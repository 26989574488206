export const DETAILSPANE_USERPREFERENCES_KEY = 'detailspane-state';

export interface DetailsPaneUserPreferences {
    keepSubPageOpen: boolean;
}

export const defaultDetailsPaneUserPreferences: DetailsPaneUserPreferences = {
    keepSubPageOpen: false,
};

export const requiredDetailsPaneUserPreferences = {
    key: DETAILSPANE_USERPREFERENCES_KEY,
    defaultState: defaultDetailsPaneUserPreferences,
};
