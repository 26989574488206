import * as React from 'react';

import { Status } from '~/components/RetryAction';

import type { UserPreferencesState } from '../../reducers';

import { RetryRetrieveSnackbar } from './components/RetryRetrieveSnackbar';

export interface StateProps {
    userPreferencesState: UserPreferencesState;
}

export interface DispatchProps {
    retrieveUserPreferences: (keys: string[]) => void;
}

export interface LoadUserPreferencesStatusMonitorProps {}

export interface LoadUserPreferencesStatusMonitorInnerProps
    extends LoadUserPreferencesStatusMonitorProps,
        StateProps,
        DispatchProps {}

export class LoadUserPreferencesStatusMonitorComponent extends React.Component<LoadUserPreferencesStatusMonitorInnerProps> {
    public render(): React.ReactNode {
        return (
            <RetryRetrieveSnackbar
                status={this.getRetryRetrieveStatus()}
                onRetry={this.retryRetrieveFailedUserPreferences}
            />
        );
    }

    private getRetryRetrieveStatus(): Status {
        const userPreferencesStateKeys = Object.keys(this.props.userPreferencesState);

        if (userPreferencesStateKeys.some(this.isRetryingToRetrieve)) {
            return Status.RETRYING;
        }

        if (userPreferencesStateKeys.some(this.shouldRetryRetrieve)) {
            return Status.FAILED;
        }

        return Status.SUCCEEDED;
    }

    private retryRetrieveFailedUserPreferences = (): void => {
        const shouldRetryRetrieveKeys = Object.keys(this.props.userPreferencesState).filter(this.shouldRetryRetrieve);
        if (shouldRetryRetrieveKeys.length > 0) {
            this.props.retrieveUserPreferences(shouldRetryRetrieveKeys);
        }
    };

    private shouldRetryRetrieve = (key: string): boolean => {
        const userPreferencesKeyState = this.props.userPreferencesState[key];

        return (
            userPreferencesKeyState &&
            userPreferencesKeyState.rejected &&
            !(userPreferencesKeyState.pending || userPreferencesKeyState.updated)
        );
    };

    private isRetryingToRetrieve = (key: string): boolean => {
        const userPreferencesKeyState = this.props.userPreferencesState[key];

        return (
            userPreferencesKeyState &&
            userPreferencesKeyState.rejected &&
            userPreferencesKeyState.pending &&
            !userPreferencesKeyState.updated
        );
    };
}
