const ACTION_TYPE_PREFIXES = {
    LIST: 'DEVICE_ADMINISTRATION_LIST',
} as const;

const ACTION_TYPE_KEYS = {
    LIST_PENDING: `${ACTION_TYPE_PREFIXES.LIST}_PENDING`,
    LIST_FULFILLED: `${ACTION_TYPE_PREFIXES.LIST}_FULFILLED`,
    LIST_REJECTED: `${ACTION_TYPE_PREFIXES.LIST}_REJECTED`,
} as const;

export { ACTION_TYPE_PREFIXES, ACTION_TYPE_KEYS };
