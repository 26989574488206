import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { protectedComponentStoreStateSelector } from '~/selectors';

import type { StateProps } from './component';
import { protectedComponent } from './component';
import { mapDispatchToProps, mapStateToProps as protectedComponentMapStatToProps } from './redux';

export const protectedComponentHoc = compose(
    connect<StateProps>(
        createSelector(protectedComponentStoreStateSelector, protectedComponentMapStatToProps),
        mapDispatchToProps
    ),
    protectedComponent
);
