import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { SelectionDetailsInnerProps, SelectionDetailsProps } from './component';
import { SelectionDetailsComponent } from './component';

export const SelectionDetails = compose<SelectionDetailsInnerProps, SelectionDetailsProps>(
    setDisplayName('SelectionDetails'),
    withTranslation()
)(SelectionDetailsComponent);
