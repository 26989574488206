import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const ArrowRightIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M10 17l5-5-5-5v10z" />
            <path d="M0 24V0h24v24H0z" fill="none" />
        </SvgIcon>
    );
};
ArrowRightIcon.displayName = 'ArrowRightIcon';

export { ArrowRightIcon };
