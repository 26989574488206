import withStyles from '@mui/styles/withStyles';
import type { ComponentType } from 'react';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import type { GridColumnDefinition } from '~/components/Grid';
import { withTranslation } from '~/components/LanguageSelector';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { sceneListStateSelector } from '~/selectors';

import type { SceneListUserPreferences } from '../../../../preferences';
import { processResponseFactory } from '../../../../preferences';

import { ViewBarActionsComponentFactory } from './component';
import type { ViewBarActionsInnerProps, ViewBarActionsProps } from './models';
import { mapDispatchToPropsFactory, mapStateToPropsFactory, mergePropsFactory } from './redux';
import { ViewBarActionsStyleRules } from './styles';

export type { ViewBarActionsProps } from './models';

export const ViewBarActionsFactory = <T>(
    preferencesKey: string,
    defaultListUserPreferencesState: SceneListUserPreferences,
    filterDataSourceMemoized: (
        searchQuery: string | undefined,
        dataSource: T[],
        columnDefinitions: Array<GridColumnDefinition<T>>,
        visibleColumns: string[]
    ) => T[],
    excelFileName: string
): ComponentType<ViewBarActionsProps<T>> =>
    compose<ViewBarActionsInnerProps<T>, ViewBarActionsProps<T>>(
        setDisplayName('ViewBarActions'),
        ensureUserPreferences(
            [
                {
                    key: preferencesKey,
                    defaultState: defaultListUserPreferencesState,
                    processResponse: processResponseFactory(defaultListUserPreferencesState),
                },
            ],
            false
        ),
        withTranslation(),
        withStyles(ViewBarActionsStyleRules),
        connect(
            createSelector(
                keyDataStateSelectorFactory(preferencesKey),
                sceneListStateSelector,
                mapStateToPropsFactory(preferencesKey)
            ),
            mapDispatchToPropsFactory(preferencesKey),
            mergePropsFactory<T>(filterDataSourceMemoized, excelFileName)
        )
    )(ViewBarActionsComponentFactory<T>());
