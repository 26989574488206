import { createSelector } from 'reselect';

import type { StoreState } from '~/reducers';
import type { ResolvedRtdsSchedule } from '~/services/ApiClient';

import type { RtdsSchedulesEntry } from '../../models';
import { rtdsScheduleEntriesSelector } from '../../selectors';

export interface RtdsScheduleContextMenuReduxProps {
    scheduleId?: number;
}

export interface RtdsScheduleContextMenuReduxState {
    selectedSchedule: ResolvedRtdsSchedule | undefined;
}

export const mapStateToProps = (
    rtdsSchedulesEntries: RtdsSchedulesEntry[],
    ownProps: RtdsScheduleContextMenuReduxProps
): RtdsScheduleContextMenuReduxState => {
    const scheduleEntry = rtdsSchedulesEntries.find((it) => it.schedule.id?.id === ownProps.scheduleId);

    return {
        selectedSchedule: scheduleEntry?.schedule,
    };
};

export const reduxPropsSelector: (
    storeState: StoreState,
    ownProps: RtdsScheduleContextMenuReduxProps
) => RtdsScheduleContextMenuReduxState = createSelector(
    rtdsScheduleEntriesSelector,
    (_: never, ownProps: RtdsScheduleContextMenuReduxProps) => ownProps,
    mapStateToProps
);
