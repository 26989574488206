import type { TableFilterRow } from '@devexpress/dx-react-grid';
import { compose, setDisplayName } from 'react-recompose';

import type { GridColumnDefinition } from '../../models';

import type { FilterCellInnerProps } from './component';
import { createFilterCellFactory } from './component';

export const FilterCellFactory = <T>(
    columnDefinitions: Array<GridColumnDefinition<T>>
): React.ComponentType<TableFilterRow.CellProps> =>
    compose<FilterCellInnerProps, TableFilterRow.CellProps>(setDisplayName('FilterCell'))(
        createFilterCellFactory<T>(columnDefinitions)
    );
