import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { LabeledIndicatorInnerProps, LabeledIndicatorProps } from './component';
import { LabeledIndicatorComponent } from './component';
import { LabeledIndicatorStyleRules } from './styles';

export const LabeledIndicator = compose<LabeledIndicatorInnerProps, LabeledIndicatorProps>(
    setDisplayName('LabeledIndicator'),
    withStyles(LabeledIndicatorStyleRules)
)(LabeledIndicatorComponent);
