import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import type { DoorStatusFormatterInnerProps, DoorStatusFormatterProps } from './component';
import { DoorStatusFormatterComponent } from './component';
import { DoorStatusStyleRules } from './styles';

export const DoorStatusFormatter = compose<DoorStatusFormatterInnerProps, DoorStatusFormatterProps>(
    setDisplayName('DoorStatusFormatter'),
    withStyles(DoorStatusStyleRules)
)(DoorStatusFormatterComponent);
