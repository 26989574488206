import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { AttachmentsSectionContentInnerProps, AttachmentsSectionContentProps } from './component';
import { AttachmentsSectionContentComponent } from './component';

export const AttachmentsSectionContent = compose<AttachmentsSectionContentInnerProps, AttachmentsSectionContentProps>(
    setDisplayName('AttachmentsSectionContent'),
    withTranslation()
)(AttachmentsSectionContentComponent);
