import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ColumnSelectorFooterInnerProps, ColumnSelectorFooterProps } from './component';
import { ColumnSelectorFooterComponent } from './component';
import { ColumnSelectorFooterStyleRules } from './styles';

export const ColumnSelectorFooter = compose<ColumnSelectorFooterInnerProps, ColumnSelectorFooterProps>(
    setDisplayName('ColumnSelectorFooter'),
    withStyles(ColumnSelectorFooterStyleRules),
    withTranslation()
)(ColumnSelectorFooterComponent);
