import { Marker } from '@fv/components';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC } from 'react';
import { useMemo } from 'react';
import { withProps } from 'react-recompose';

import type { MonitoringTrailerEntry } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesTrailerDisplayFormat } from '~/services/ApiClient';

import { TrailerMarkerIcon } from './components/TrailerMarkerIcon';
import { TrailerMarkerNameFormatter } from './components/TrailerMarkerNameFormatter';
import type { TrailerMarkerClassKey } from './styles';

export interface TrailerMarkerProps {
    animationDuration: number;
    entry: MonitoringTrailerEntry;
    selected: boolean;
    trailerDisplayNameFormat?: DisplayUserPreferencesTrailerDisplayFormat;
    updateAnimationVisible: boolean;
}

export interface TrailerMarkerInnerProps extends TrailerMarkerProps, WithStyles<TrailerMarkerClassKey> {}

export const TrailerMarkerComponent: FC<TrailerMarkerInnerProps> = (props) => {
    const { entry, trailerDisplayNameFormat, classes, ...rest } = props;

    const NameFormatterComponent = useMemo(
        () => trailerDisplayNameFormat && withProps({ trailerDisplayNameFormat })(TrailerMarkerNameFormatter),
        [trailerDisplayNameFormat]
    );

    const driverActivity = entry.vehicleConnection?.vehicleDriverHoursStatus?.driverHoursStatus?.activity?.key;
    const overriddenMarkerClasses = useMemo(() => {
        return {
            iconBox: classNames({
                [classes[`driverActivity${driverActivity}BackgroundColor`]]: !isUndefined(driverActivity),
                [classes.trailerBackgroundColor]: isUndefined(driverActivity),
            }),
            highlight: classNames({
                [classes[`driverActivity${driverActivity}BorderColor`]]: !isUndefined(driverActivity),
                [classes.trailerBorderColor]: isUndefined(driverActivity),
            }),
            selected: classNames({
                [classes[`driverActivity${driverActivity}BorderColor`]]: !isUndefined(driverActivity),
                [classes.trailerBorderColor]: isUndefined(driverActivity),
            }),
            assetName: classes.assetName,
        };
    }, [classes, driverActivity]);

    return (
        <Marker<MonitoringTrailerEntry>
            {...rest}
            classes={overriddenMarkerClasses}
            dataId={`trailer-marker:${entry.trailer.id}`}
            entry={entry}
            Icon={TrailerMarkerIcon}
            NameFormatter={NameFormatterComponent}
        />
    );
};
