import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import { formatDate } from '~/services/Formatters';

import { SectionName } from '../../constants';

import type { ValiditySectionProps } from './models';

const ValiditySection: FC<ValiditySectionProps> = (props) => {
    const { adminCompanyCard, dragHandleElement, isCollapsed, toggleCollapsed } = props;
    const { t } = useTranslation();

    const keyValueItems: KeyValueItem[] = [
        {
            valueDataId: 'company-card-valid-from',
            label: t('company-card-valid-from'),
            value: adminCompanyCard.validFrom ? <span> {formatDate(adminCompanyCard.validFrom)}</span> : undefined,
        },
        {
            valueDataId: 'company-card-expiration',
            label: t('company-card-expiration'),
            value: adminCompanyCard.expiration ? <span> {formatDate(adminCompanyCard.expiration)}</span> : undefined,
        },
    ];
    return (
        <ReorderableSection
            dataId="company-card-administration-validity-section"
            title={t(SectionName.VALIDITY)}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="validity-list" />
        </ReorderableSection>
    );
};
ValiditySection.displayName = 'ValiditySection';
export default ValiditySection;
