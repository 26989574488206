import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withFeatureFlags } from '~/components/FeatureFlags';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';

import { conversationWidgetsStoreStateSelector } from '../../selectors';
import { ensureUserMessagingProfiles } from '../EnsureUserMessagingProfiles';

import type { ConversationWidgetsInnerProps, ConversationWidgetsProps } from './component';
import { ConversationWidgetsComponent } from './component';
import { CONVERSATIONWIDGETS_USERPREFERENCES_KEY, requiredConversationWidgetsUserPreferences } from './preferences';
import { mapDispatchToProps, mapStateToProps } from './redux';

export { requiredConversationWidgetsUserPreferences, CONVERSATIONWIDGETS_USERPREFERENCES_KEY } from './preferences';
export type { ConversationWidgetsUserPreferences } from './preferences';

export const ConversationWidgets = compose<ConversationWidgetsInnerProps, ConversationWidgetsProps>(
    setDisplayName('ConversationWidgets'),
    ensureUserPreferences([requiredConversationWidgetsUserPreferences], false),
    withFeatureFlags(),
    ensureUserMessagingProfiles(),
    connect(
        createSelector(
            conversationWidgetsStoreStateSelector,
            keyDataStateSelectorFactory(CONVERSATIONWIDGETS_USERPREFERENCES_KEY),
            mapStateToProps
        ),
        mapDispatchToProps
    )
)(ConversationWidgetsComponent);
