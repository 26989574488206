import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { TrailerHeaderInnerProps, TrailerHeaderProps } from './component';
import { TrailerHeaderComponent } from './component';

export const TrailerHeader = compose<TrailerHeaderInnerProps, TrailerHeaderProps>(
    setDisplayName('TrailerHeader'),
    withDisplayPreferences(),
    withTranslation()
)(TrailerHeaderComponent);
