import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ColumnSelectorClassKey = 'popoverPaper' | 'groupList' | 'message';

export const ColumnSelectorStyleRules: StyleRulesCallback<Theme, {}, ColumnSelectorClassKey> = (theme: Theme) =>
    createStyles({
        popoverPaper: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: `calc(100% - ${theme.spacing(12)})`,
        },
        groupList: {
            overflow: 'auto',
        },
        message: {
            padding: theme.spacing(2, 2, 3),
        },
    });
