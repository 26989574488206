import { Grid, ListItem } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import type { OptionProps } from './models';

const Option: React.FC<OptionProps> = (props) => {
    const { isMulticolumn, classes, option } = props;
    const options = (Array.isArray(option?.values) && option.values) || [];
    const brandedClass = classes?.brandedRow ?? '';

    return isMulticolumn ? (
        <Grid
            container
            className={classNames(classes?.optionCellRow, { [brandedClass]: option.branded })}
            key={`autocomplete-dropdown-input-list-${option.key}`}
        >
            {options.map((e: string) => (
                <Grid item xs={4} className={classes?.optionItemText} key={`autocomplete-dropdown-input-list-${e}`}>
                    {e}
                </Grid>
            ))}
        </Grid>
    ) : (
        <ListItem
            key={`autocomplete-dropdown-input-list-${option.key}`}
            className={classNames(classes?.optionCellRow, { [brandedClass]: option.branded })}
        >
            <span>{option.displayName}</span>
        </ListItem>
    );
};

export default Option;
