import 'react-toastify/dist/ReactToastify.css';

import { useTheme } from '@mui/material';
import type { FC } from 'react';

import type { NotificationProps } from './models';
import { ToastContainer } from './styles';

const Notification: FC<NotificationProps> = (props) => {
    const { autoClose = 3000, position = 'bottom-left', limit = 4 } = props;

    const muiTheme = useTheme();

    return (
        <ToastContainer
            autoClose={autoClose}
            draggable={false}
            limit={limit}
            muiTheme={muiTheme}
            newestOnTop={false}
            pauseOnFocusLoss={false}
            pauseOnHover={false}
            position={position}
        />
    );
};

Notification.displayName = 'Notification';
export { Notification };
