import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Button, List, ListItem, Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { ChangelogIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { constructRtdsScheduleUrl } from '~/scenes/RtdsSchedules/constructRtdsScheduleUrl';
import type { RtdsSchedulesEntry } from '~/scenes/RtdsSchedules/models';
import { Subpage } from '~/scenes/RtdsSchedules/models';
import type { ResolvedRtdsSchedule } from '~/services/ApiClient';
import { AssetType, ClassificationType } from '~/services/ApiClient';
import { formatClassification, formatDateTimeHourMinuteSecond, formatTachoDatablocks } from '~/services/Formatters';

import { useStyles } from './styles';

export interface RtdsScheduleDetailsProps {
    entry: RtdsSchedulesEntry;
    openScheduleEditor: (assetType: AssetType, isClone: boolean, schedule?: ResolvedRtdsSchedule) => void;
}

export interface RtdsScheduleDetailsInnerProps extends RtdsScheduleDetailsProps, InjectedTranslationProps {}

export const RtdsScheduleDetailsComponent: FC<RtdsScheduleDetailsInnerProps> = (props) => {
    const { t, entry, openScheduleEditor } = props;

    const classes = useStyles();
    const history = useHistory();
    const openChangelogsSubpage = useCallback(() => {
        const changelogsSubpageUrl = constructRtdsScheduleUrl(entry.schedule.id.id, Subpage.CHANGELOGS);
        history.push(changelogsSubpageUrl);
    }, [entry.schedule.id.id, history]);

    const scheduleActionButtons = (
        <ListItem className={classes.actionButtons}>
            <Tooltip title={t('modify-rtds-schedule-tooltip')}>
                <Button
                    color="secondary"
                    data-id="modify-rtds-schedule"
                    onClick={() => openScheduleEditor(entry.schedule.assetReference.type, false, entry.schedule)}
                >
                    <EditIcon className={classes.iconMargin} />
                    {t('modify-action')}
                </Button>
            </Tooltip>
            <Tooltip title={t('clone-rtds-schedule-tooltip')}>
                <Button
                    color="secondary"
                    data-id="clone-rtds-schedule"
                    onClick={() => openScheduleEditor(entry.schedule.assetReference?.type, true, entry.schedule)}
                >
                    <FileCopyIcon className={classes.iconMargin} />
                    {t('clone-rtds-schedule-action')}
                </Button>
            </Tooltip>
            <Tooltip title={t('open-rtdsschedules-changelogs-subpage-tooltip')}>
                <Button color="secondary" data-id="open-changelogs-subpage" onClick={openChangelogsSubpage}>
                    <ChangelogIcon className={classes.iconMargin} />
                    {t('open-changelogs-subpage-action')}
                </Button>
            </Tooltip>
        </ListItem>
    );

    const startDate = (
        <ListItem className={classes.item}>
            <Typography variant="caption" color="textSecondary">
                {t('schedule-start-date')}
            </Typography>
            <Typography variant="body2" color="textPrimary" data-id="start-date">
                {formatDateTimeHourMinuteSecond(entry.schedule.startDate)}
            </Typography>
        </ListItem>
    );

    const frequency = entry.schedule.scheduleFrequency && (
        <ListItem className={classes.item}>
            <Typography variant="caption" color="textSecondary">
                {t('schedule-frequency')}
            </Typography>
            <Typography variant="body2" color="textPrimary" data-id="frequency">
                {formatClassification(t, ClassificationType.RtdsScheduleFrequency, entry.schedule.scheduleFrequency)}
            </Typography>
        </ListItem>
    );

    const priority = entry.schedule.schedulePriority && (
        <ListItem className={classes.item}>
            <Typography variant="caption" color="textSecondary">
                {t('rtds-schedule-priority')}
            </Typography>
            <Typography variant="body2" color="textPrimary" data-id="priority">
                {formatClassification(t, ClassificationType.RtdsPriority, entry.schedule.schedulePriority)}
            </Typography>
        </ListItem>
    );

    const requestedTachoData = entry.schedule.dataBlocks && entry.schedule.type === AssetType.Vehicle && (
        <ListItem className={classes.item}>
            <Typography variant="caption" color="textSecondary">
                {t('schedule-requested-tacho-data')}
            </Typography>
            <Typography variant="body2" color="textPrimary" data-id="requested-tacho-data">
                {formatTachoDatablocks(t, entry.schedule)}
            </Typography>
        </ListItem>
    );

    return (
        <List disablePadding data-id="schedule-details">
            {startDate}
            {frequency}
            {priority}
            {requestedTachoData}
            {scheduleActionButtons}
        </List>
    );
};
