import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { MessageStatusFormatter, adaptiveDateTimeFormatterFactory } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import type { ResolvedMessage } from '~/services/ApiClient';
import { MessageDirection, MessageStatus, OutgoingMessage } from '~/services/ApiClient';
import { FormatNewLineToBr, formatMessagePrimaryAttribute } from '~/services/Formatters';

import type { LatestMessageClassKey } from './styles';

export interface LatestMessageProps {
    value: ResolvedMessage;
    isDeleted: boolean;
}

export interface LatestMessageInnerProps
    extends LatestMessageProps,
        WithStyles<LatestMessageClassKey>,
        InjectedTranslationProps {}

export const LatestMessageComponent: React.FC<LatestMessageInnerProps> = ({ classes, t, value, isDeleted }) => {
    const rootClasses = classNames(classes.root, {
        [classes.incomingMessageBackground]: value.direction === MessageDirection.Incoming,
    });
    const messageClasses = classNames(classes.message, {
        [classes.deleted]: isDeleted,
        [classes.unreadMessage]: value.status === MessageStatus.Received,
    });

    const authorElement = value.value instanceof OutgoingMessage && (
        <Typography variant="caption" className={classes.author} data-id="latest-message-author">
            {value.value.author}
        </Typography>
    );

    const AdaptiveDateTimeFormatter = adaptiveDateTimeFormatterFactory();

    const deletedMessage = (
        <div className={rootClasses} data-id={`latest-message:${value.value.id}`}>
            <div className={classes.statusIndicator}>
                <MessageStatusFormatter value={value.status} t={t} iconProps={{ fontSize: 'inherit' }} />
            </div>
            <Typography variant="caption" className={messageClasses} data-id="latest-message-content">
                <TitledTruncatedText title={t('message-was-deleted')}>{t('message-was-deleted')}</TitledTruncatedText>
            </Typography>
            <Typography variant="caption" color="textSecondary" data-id="latest-message-timestamp">
                <AdaptiveDateTimeFormatter value={value.value.dateTime} t={t} />
            </Typography>
        </div>
    );

    const messageText = formatMessagePrimaryAttribute(t, value.body);
    const message = (
        <div className={rootClasses} data-id={`latest-message:${value.value.id}`}>
            <div className={classes.statusIndicator}>
                <MessageStatusFormatter value={value.status} t={t} iconProps={{ fontSize: 'inherit' }} />
            </div>
            {authorElement}
            <Typography variant="caption" className={messageClasses} data-id="latest-message-content">
                <TitledTruncatedText title={messageText ? <FormatNewLineToBr text={messageText} /> : undefined}>
                    {messageText}
                </TitledTruncatedText>
            </Typography>
            <Typography variant="caption" color="textSecondary" data-id="latest-message-timestamp">
                <AdaptiveDateTimeFormatter value={value.value.dateTime} t={t} />
            </Typography>
        </div>
    );

    return isDeleted ? deletedMessage : message;
};
