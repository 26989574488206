import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { VehicleInnerProps, VehicleProps } from './component';
import { VehicleComponent } from './component';
import { VehicleStyleRules } from './styles';

export const Vehicle = compose<VehicleInnerProps, VehicleProps>(
    setDisplayName('Vehicle'),
    withTranslation(),
    withStyles(VehicleStyleRules)
)(VehicleComponent);
