import type { SearchResult } from '@fv/components/Map';
import { SearchBar } from '@fv/components/Map';
import { bounds, point } from 'leaflet';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { SearchLocationsResult } from '~/data/geozonesAdministration';
import type { Address, Location } from '~/services/MapGatewayClient';
import { SearchLocationRequest, SearchLocationResultOptions, getMapGatewayClient } from '~/services/MapGatewayClient';

const SearchLocationBar: FC = () => {
    const { t } = useTranslation();

    const handleSearch = (text: string) => {
        const query = new SearchLocationRequest();
        const resultOptions = new SearchLocationResultOptions();
        resultOptions.init({ maxNumberOfResults: 5 });
        query.init({ text, resultOptions });
        return getMapGatewayClient()
            .searchLocation(query)
            .then((response) => {
                return (response.items || []).map((location): SearchResult<Location> => {
                    const {
                        position: { coordinates, bbox },
                        address,
                    } = location;
                    const { city, countryCode, postalCode, county, street, houseNumber } = address as Address;

                    const label = [street, houseNumber, city, county, countryCode, postalCode]
                        .filter(Boolean)
                        .join(', ');

                    return {
                        label,
                        bounds:
                            bbox?.length === 4
                                ? bounds([point([bbox[0], bbox[1]]), point([bbox[2], bbox[3]])])
                                : bounds([point(coordinates[0], coordinates[1])]),
                        x: coordinates[0],
                        y: coordinates[1],
                        raw: location,
                    };
                });
            });
    };

    return (
        <SearchBar<SearchLocationsResult | Location>
            onSearch={handleSearch}
            notFoundMessage={t('error.location-not-found')}
        />
    );
};

SearchLocationBar.displayName = 'SearchLocationbar';
export { SearchLocationBar };
