import type { DisplayUserPreferencesVehicleDisplayFormat } from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';

import type { HistoryVehicleEntry } from '../../../../models';

export const getVehicleValue = (vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat) => {
    return (historyVehicle: HistoryVehicleEntry): string | undefined => {
        return historyVehicle.vehicle ? formatVehicleName(historyVehicle.vehicle, vehicleDisplayNameFormat) : undefined;
    };
};

export const getDeviceTypeValue = (historyVehicle: HistoryVehicleEntry): string | undefined =>
    historyVehicle.vehicle?.deviceType?.key;
