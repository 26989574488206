import { createSelector } from 'reselect';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { StoreState } from '~/reducers';
import type { Securables, TachoDataSettings, TachoFileType } from '~/services/ApiClient';

export interface TachoFileContextMenuReduxProps {
    fileId?: number;
    fileType?: TachoFileType;
}

export interface TachoFileContextMenuReduxState {
    rmsEnabled: boolean;
}

export const mapStateToProps = (
    securables: Securables,
    tachoDataSettings: TachoDataSettings
): TachoFileContextMenuReduxState => {
    return {
        rmsEnabled: securables.tachoFile.rms && tachoDataSettings.rmsEnabled,
    };
};

export const reduxPropsSelector: (storeState: StoreState) => TachoFileContextMenuReduxState = createSelector(
    settingDataSelector(SettingsKey.SECURABLES),
    settingDataSelector(SettingsKey.TACHO_DATA),
    mapStateToProps
);
