import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { CompartmentSubSectionContentInnerProps, CompartmentSubSectionContentProps } from './component';
import { CompartmentSubSectionContentComponent } from './component';
import { CompartmentSubSectionContentStyleRules } from './styles';

export const CompartmentSubSectionContent = compose<
    CompartmentSubSectionContentInnerProps,
    CompartmentSubSectionContentProps
>(
    setDisplayName('CompartmentSubSectionContent'),
    withTranslation(),
    withStyles(CompartmentSubSectionContentStyleRules),
    withDisplayPreferences()
)(CompartmentSubSectionContentComponent);
