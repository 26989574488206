import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        minWidth: theme.spacing(3),
    },
    checkbox: {
        paddingLeft: theme.spacing(0.5),
    },
    expand: {
        padding: theme.spacing(0.5),
    },
    text: {
        marginLeft: theme.spacing(),
    },
}));

export { useStyles };
