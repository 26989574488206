import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type NoOptionsMessageClassKey = 'root';

export const NoOptionsMessageStyleRules: StyleRulesCallback<Theme, {}, NoOptionsMessageClassKey> = (theme: Theme) =>
    createStyles({
        root: { padding: theme.spacing(1, 2), fontStyle: 'italic' },
    });
