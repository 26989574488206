import type { Dispatch } from 'redux';
import type { Persistor } from 'redux-persist';

import type { PersistStoreDispatchProps, PersistStoreStateProps } from './component';
import { overridePersistorAction } from './data';
import type { PersistStoreState } from './reducers';

export const mapStateToProps = (state: PersistStoreState): PersistStoreStateProps => {
    return {
        persistor: state.persistor,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): PersistStoreDispatchProps => ({
    overridePersistor: (persistor: Persistor) => dispatch(overridePersistorAction(persistor)),
});
