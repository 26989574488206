import { compose, setDisplayName } from 'react-recompose';

import { withScreenAnalytics } from '~/components/Analytics';

import type { ResetPasswordInnerProps, ResetPasswordProps } from './component';
import { ResetPasswordComponent } from './component';

export const ResetPassword = compose<ResetPasswordInnerProps, ResetPasswordProps>(
    setDisplayName('ResetPassword'),
    withScreenAnalytics('resetpassword')
)(ResetPasswordComponent);
