import type { Duration } from 'moment';
import { defaultProps } from 'react-recompose';

import type { DurationFormatterProps, ViolationType } from '~/components/Formatters';
import { DurationFormatter } from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { ViolationType as ViolationTypeApi } from '~/services/ApiClient';
import { durationToDays } from '~/services/Convertors';
import type { DurationFormatOptions } from '~/services/Formatters';
import { exportFormatterFactory, formatDuration, valueTextFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareNumbers } from '~/services/Sorting';

import { isViolation } from '../Formatters/Duration/violations';
import { ViolationGroupFormatter } from '../Formatters/ViolationGroup';
import type { ViolationGroupFormatterProps } from '../Formatters/ViolationGroup';

import type { CreateColumnOptions, GridColumnDefinition } from './models';
import { violationGroupingCriteriaForDuration } from './violationGroupCriteria';

export interface CreateDurationColumnOptions extends CreateColumnOptions {
    excelCellFormat?: string;
    threshold?: Duration;
    violationType?: ViolationType;
    formatOptions?: DurationFormatOptions;
}

export const createDurationColumn = <T>(
    t: SingleTFunction,
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => Duration | undefined,
    { threshold, formatOptions, violationType, ...restOptions }: CreateDurationColumnOptions = {}
): GridColumnDefinition<T> => {
    const defaultDurationFormatterProps: Partial<DurationFormatterProps> = { options: formatOptions };
    const violationDurationFormatterProps: Partial<DurationFormatterProps> = {
        ...defaultDurationFormatterProps,
        threshold,
        violationType,
    };

    const ValueFormatterComponent =
        isUndefined(threshold) || isUndefined(violationType)
            ? defaultProps(defaultDurationFormatterProps)(DurationFormatter)
            : defaultProps(violationDurationFormatterProps)(DurationFormatter);

    const getViolationType = (value: Duration): ViolationTypeApi => {
        if (!isUndefined(threshold) && !isUndefined(violationType) && isViolation(value, threshold, violationType)) {
            return ViolationTypeApi.Violation;
        }
        return ViolationTypeApi.None;
    };

    const violationGroupFormatterProps: Partial<ViolationGroupFormatterProps<Duration>> = {
        t,
        getViolationType,
    };
    const ViolationGroupFormatterComponent =
        defaultProps(violationGroupFormatterProps)(ViolationGroupFormatter<Duration>());

    const violationGroupingCriteriaForDurationWrapper = (value?: Duration) => {
        return violationGroupingCriteriaForDuration(value, threshold, violationType);
    };

    const align = restOptions.align ?? 'right';

    return {
        ...restOptions,
        excelCellFormat: `[h]"${t('unit-hour')}" mm"${t('unit-minute')}"`,
        dataType: 'duration',
        name,
        title,
        groupTitle,
        getCellValue,
        align,
        compare: compareNumbers,
        exportValueFormatter: exportFormatterFactory(durationToDays),
        valueFormatterComponent: ValueFormatterComponent,
        valueTextFormatter: valueTextFormatterFactory((v: Duration) =>
            formatDuration(durationToDays(v), formatOptions)
        ),
        formatUndefinedValue: !isUndefined(violationType),
        groupValueFormatterComponent: !isUndefined(violationType) ? ViolationGroupFormatterComponent : undefined,
        groupingCriteria: !isUndefined(violationType)
            ? groupingCriteriaFactory(violationGroupingCriteriaForDurationWrapper)
            : undefined,
    };
};
