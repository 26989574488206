import type { InhibitorStatus } from '~/services/ApiClient';
import type { Action, Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    INHIBITORSTATUS = 'INHIBITORSTATUS',
}

export enum ActionTypeKeys {
    INHIBITORSTATUS_PENDING = 'INHIBITORSTATUS_PENDING',
    INHIBITORSTATUS_FULFILLED = 'INHIBITORSTATUS_FULFILLED',
    INHIBITORSTATUS_REJECTED = 'INHIBITORSTATUS_REJECTED',
    INHIBITORSTATUS_UPDATE = 'INHIBITORSTATUS_UPDATE',
}

export interface InhibitorStatusPayload {
    readonly items: InhibitorStatus[];
}

export type ActionTypes =
    | Pending<ActionTypeKeys.INHIBITORSTATUS_PENDING>
    | Fulfilled<ActionTypeKeys.INHIBITORSTATUS_FULFILLED, InhibitorStatusPayload>
    | Rejected<ActionTypeKeys.INHIBITORSTATUS_REJECTED>
    | Action<ActionTypeKeys.INHIBITORSTATUS_UPDATE, InhibitorStatus>;
