import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { PositionInputInnerProps, PositionInputProps } from './component';
import { PositionInputComponent } from './component';
import { PositionInputStyleRules } from './styles';

export const PositionInput = compose<PositionInputInnerProps, PositionInputProps>(
    setDisplayName('PositionInput'),
    withStyles(PositionInputStyleRules),
    withTranslation()
)(PositionInputComponent);
