import * as React from 'react';

import { DetailsPaneHeader } from '~/components/DetailsPaneHeader';

import { DetailsPaneContext } from '../../context';

export interface SceneDetailsSubpageHeaderProps {
    title: string;
}

const SceneDetailsSubpageHeader: React.FC<SceneDetailsSubpageHeaderProps> = ({ title }) => {
    const { close, goBack } = React.useContext(DetailsPaneContext);

    return <DetailsPaneHeader title={title} onBack={goBack} onClose={close} />;
};

SceneDetailsSubpageHeader.displayName = 'SceneDetailsSubpageHeader';
export { SceneDetailsSubpageHeader };
