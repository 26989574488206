import { subtract, toUnit } from 'duration-fns';
import type { HumanizerOptions } from 'humanize-duration';

import { durationHumanizer } from '../utils';

import { LIMIT, VIOLATION_TYPE } from './consts';
import type { FormatDurationArgs, IsViolationArgs } from './models';

const formatDuration = (args: FormatDurationArgs) => {
    const { value, options } = args;
    const formatOptions: HumanizerOptions = { units: ['h', 'm'], ...options };

    return durationHumanizer(toUnit(value, 'milliseconds'), formatOptions);
};

const isViolation = (args: IsViolationArgs) => {
    const { value, threshold, violationType } = args;

    const difference = toUnit(subtract(value, threshold), 'seconds');

    switch (violationType) {
        case VIOLATION_TYPE.ABOVE:
            return difference < LIMIT;
        case VIOLATION_TYPE.BELOW:
            return difference > LIMIT;
        default:
            return false;
    }
};

export { formatDuration, isViolation };
