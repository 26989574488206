import { Paper, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { ColorVariants } from '~/services/Styling';

import type { LabeledIndicatorClassKey } from './styles';

export interface LabeledIndicatorProps {
    title: string;
    titleKeepCapitalization?: boolean;
    primaryText: React.ReactNode;
    secondaryText?: string | React.ReactNode;
    badge?: React.ReactNode;
    variant?: ColorVariants;
    dataId?: string;
}

export interface LabeledIndicatorInnerProps extends LabeledIndicatorProps, WithStyles<LabeledIndicatorClassKey> {}

export const LabeledIndicatorComponent: React.FC<LabeledIndicatorInnerProps> = ({
    classes,
    title,
    titleKeepCapitalization,
    primaryText,
    secondaryText,
    badge,
    variant,
    dataId,
}) => {
    const paperClassName = classNames(classes.root, {
        [classes.warning]: variant === ColorVariants.Warning,
        [classes.error]: variant === ColorVariants.Error,
        [classes.success]: variant === ColorVariants.Success,
        [classes.disabled]: variant === ColorVariants.Disabled,
    });

    const formattedBadge = badge && (
        <span data-id="badge" className={classes.badge}>
            {badge}
        </span>
    );
    const formattedTitle = (
        <span className={classes.title} data-id="title">
            {titleKeepCapitalization ? title : title.toUpperCase()}
        </span>
    );

    const firstLine = (
        <Typography variant="subtitle2" color="textSecondary" className={classes.flex}>
            {formattedBadge}
            {formattedTitle}
        </Typography>
    );
    const secondLine = (
        <Typography variant="subtitle2" className={classes.primaryText} color="textPrimary" data-id="primary-value">
            {primaryText}
        </Typography>
    );
    const thirdLine = (
        <Typography variant="caption" className={classes.secondaryText} color="textSecondary" data-id="secondary-value">
            {secondaryText}
        </Typography>
    );

    return (
        <Paper elevation={3} className={paperClassName} data-id={dataId}>
            {firstLine}
            {secondLine}
            {thirdLine}
        </Paper>
    );
};
