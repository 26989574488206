import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const MarkAllAsReadIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M3.7 8.81l1.42.88 1.6-1L3.7 6.8l6.8-4.25L17.3 6.8h0L19 7.88V6.8a1.68 1.68 0 0 0-.82-1.45L10.5.54 2.82 5.35A1.68 1.68 0 0 0 2 6.8v8.5A1.7 1.7 0 0 0 3.7 17h.51V15.3H3.7Z" />
            <path d="M21.19 10l-7.68-4.8L5.84 10A1.7 1.7 0 0 0 5 11.48V20a1.7 1.7 0 0 0 1.7 1.7h13.6A1.7 1.7 0 0 0 22 20v-8.5A1.71 1.71 0 0 0 21.19 10Zm-.88 10H6.71v-6.5l6.8 4.25 6.8-4.25Zm-6.8-4.25-6.8-4.25 6.8-4.25 6.8 4.25Z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </SvgIcon>
    );
};
MarkAllAsReadIcon.displayName = 'MarkAllAsReadIcon';

export { MarkAllAsReadIcon };
