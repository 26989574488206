import type { FC } from 'react';
import { memo } from 'react';
import { Circle, Polygon, TileLayer } from 'react-leaflet';

import { isEqual } from '../../../utility';
import { SHAPE_TYPES } from '../../consts';
import { MaplibreTileLayer } from '../MaplibreGLTileLayer';
import { XServerTileLayer } from '../XServerTileLayer';

import type { TilesProps } from './models';

const Tiles: FC<TilesProps> = memo((props) => {
    const { tiles } = props;

    return (
        <>
            {tiles.map((tile) => {
                switch (tile.shape) {
                    case SHAPE_TYPES.CIRCLE:
                        return <Circle key={tile.id} {...tile} />;
                    case SHAPE_TYPES.POLYGON:
                        return <Polygon key={tile.id} {...tile} />;
                    case SHAPE_TYPES.COMMON:
                        return <TileLayer {...tile} />;
                    case SHAPE_TYPES.XSERVER:
                        return <XServerTileLayer {...tile} />;
                    case SHAPE_TYPES.MAPLIBREGL:
                        return <MaplibreTileLayer {...tile} />;
                    default:
                        return null;
                }
            })}
        </>
    );
}, isEqual);

Tiles.displayName = 'Tiles';
export { Tiles };
