import CancelIcon from '@mui/icons-material/Cancel';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import type { ContextMenuItem, ContextMenuPosition } from '~/components/ContextMenu';
import { ContextMenu } from '~/components/ContextMenu';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNil } from '~/libs/utility';
import type { StoreState } from '~/reducers';

import { AbortRtdsSessionDialog } from '../AbortRtdsSessionDialog';

import { reduxPropsSelector } from './redux';

export interface RtdsSessionContextMenuProps {
    sessionId?: number;
    position?: ContextMenuPosition;
    onClose: () => void;
    onAbort: () => void;
}

export interface RtdsSessionContextMenuInnerProps extends RtdsSessionContextMenuProps, InjectedTranslationProps {}

export const RtdsSessionContextMenuComponent: FC<RtdsSessionContextMenuInnerProps> = (props) => {
    const { sessionId, position, onClose, onAbort, t } = props;

    const reduxProps = useSelector((state: StoreState) => reduxPropsSelector(state, { sessionId }));
    const [dialog, setDialog] = useState<ReactNode>();

    const getMenuItems = (): ContextMenuItem[] => {
        if (isNil(sessionId)) {
            return [];
        }

        const closeDialog = () => setDialog(undefined);

        return [
            {
                key: 'abort-rtds-session',
                title: t('abort-rtds-session'),
                icon: <CancelIcon />,
                onClick: () => {
                    setDialog(<AbortRtdsSessionDialog onClose={closeDialog} sessionId={sessionId} onAbort={onAbort} />);
                },
                hidden: !reduxProps.sessionCanBeAborted,
            },
        ];
    };

    return (
        <>
            <ContextMenu
                position={position}
                onClose={onClose}
                menuItems={getMenuItems()}
                dataId="rtds-session-context-menu"
            />
            {dialog}
        </>
    );
};
