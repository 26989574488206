import type { RetrievableData, StoreState } from '~/reducers';
import type { PositionStatus } from '~/services/ApiClient';

import { positionGroupsStoreStateSelector } from '../../../../selectors';

import type { PositionGroupProps } from './component';
import { defaultPositionGroupState } from './reducers';

export const positionGroupSelector = (
    s: StoreState,
    ownProps: PositionGroupProps
): RetrievableData<PositionStatus[]> => {
    return positionGroupsStoreStateSelector(s).items[ownProps.positionGroup.id] ?? defaultPositionGroupState;
};
