import { Button, Divider, FormControlLabel, MenuItem, Radio, Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { RecipientsList } from '~/common';
import { WidgetDialog } from '~/components/Dialogs';
import { DriverIcon, TruckIcon } from '~/components/Icons';
import {
    BooleanControlType,
    BooleanInput,
    DateTimeControlType,
    DateTimeInput,
    NumericInput,
    OptionsInput,
} from '~/components/InputFields';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined, uniq } from '~/libs/utility';
import type { AssetRecipient, Classification, ResolvedRtdsSchedule, TachographDataBlock } from '~/services/ApiClient';
import { AssetType, ClassificationType, EnumControlType, TachoDataBlockTypes } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { dataToSubmittedSchedule } from './dataToSubmittedSchedules';
import { mapRecipientIdsToSubmittedSchedules } from './mapRecipientIdsToSubmittedSchedules';
import { FrequencyTypes, MonthlyRadioButtonsTypes, OrdinalTypes, PriorityTypes, WeekDayTypes } from './models';
import type { ScheduleEditorReduxProps } from './redux';
import type { ScheduleEditorFormClassKey } from './styles';

export interface Schedule {
    startDate: Date;
    frequency: number;
    priority: number;
    tachographDataBlocks: number[];
    lastActivities: number;
}

export interface FieldValues {
    assetType: number;
    assetDestination: number[];
    dropdownFrequency: number;
    dropdownPriority: number;
    dropdownWeekDays: number;
    dropdownMonthOrdinalType: number;
    frequencyDay: number;
    frequencyWeek: number;
    frequencyMonthForMonthsOption: number;
    frequencyMonthForDaysOption: number;
    frequencyMonthNumDays: number;
    lastActivityDays: number;
    startDate: Date;
    tachoDataBlocks?: NumericDictionary<boolean>;
    radioButtonsFrequencyMonth: number;
    requestedDays: number;
}

export interface ScheduleEditorProps {
    isClone: boolean;
    onClose: () => void;
    assetType: AssetType;
    schedule?: ResolvedRtdsSchedule;
    onSave: () => void;
}

export interface ScheduleEditorInnerProps
    extends ScheduleEditorReduxProps,
        ScheduleEditorProps,
        InjectedTranslationProps,
        WithStyles<ScheduleEditorFormClassKey> {}

export const ScheduleEditorComponent: React.FC<ScheduleEditorInnerProps> = ({
    t,
    classes,
    isClone,
    schedule,
    onClose,
    tachoDataBlocks,
    priorityTypes,
    frequencyTypes,
    ordinalTypes,
    weekDayTypes,
    createRtdsSchedules,
    updateRtdsSchedule,
    assetType,
    onSave,
}) => {
    const [frequencyType, setFrequencyType] = React.useState<FrequencyTypes>(
        schedule?.scheduleFrequency.id || FrequencyTypes.Once
    );

    const [selectedDayRadioButon, setSelectedDayRadioButon] = React.useState<boolean>(
        !isUndefined(schedule?.resolvedDescription.day)
    );

    const [recipientIds, setRecipientIds] = React.useState<number[]>([]);

    const [selectedRecipients, setRecipients] = React.useState<AssetRecipient[]>([]);

    const [showLastActivitiesField, setShowLastActivitiesField] = React.useState(
        isUndefined(schedule) ? true : schedule?.tachographDataBlocks.includes(TachoDataBlockTypes.ActivityData)
    );

    const calculateTachoDataBlocksInitialValues = React.useCallback(() => {
        if (assetType === AssetType.Driver) {
            return undefined;
        }

        return Object.values(tachoDataBlocks).reduce((acc, tachographDataBlock) => {
            acc[tachographDataBlock.id] = schedule
                ? schedule.tachographDataBlocks.includes(tachographDataBlock.id)
                : true;
            return acc;
        }, {});
    }, [assetType, schedule, tachoDataBlocks]);

    const defaultValues = {
        dropdownFrequency: frequencyType,
        dropdownPriority: schedule?.schedulePriority.id || PriorityTypes.Normal,
        startDate: moment(schedule?.startDate || new Date())
            .startOf('minutes')
            .toDate(),
        lastActivityDays: schedule?.requestedDays || 90,
        tachoDataBlocks: calculateTachoDataBlocksInitialValues(),
        frequencyMonthForDaysOption: schedule?.recurrence || 1,
        frequencyMonthForMonthsOption: schedule?.recurrence || 1,
        frequencyMonthNumDays: schedule?.resolvedDescription.day || 1,
        frequencyDay: schedule?.recurrence || 1,
        frequencyWeek: schedule?.recurrence || 1,
        dropdownWeekDays: schedule?.resolvedDescription.weekdayType?.id || WeekDayTypes.Monday,
        dropdownMonthOrdinalType: schedule?.resolvedDescription.ordinalType?.id || OrdinalTypes.First,
        radioButtonsFrequencyMonth: !isUndefined(schedule?.resolvedDescription.day)
            ? MonthlyRadioButtonsTypes.Day
            : MonthlyRadioButtonsTypes.Month,
        assetDestination: [],
    };
    const methods = useForm<FieldValues>({
        mode: 'all',
        defaultValues,
        shouldUnregister: true,
    });

    const {
        setValue,
        formState: { errors, isValid, dirtyFields },
    } = methods;

    const onTachoDataBlockValueChanged = React.useCallback(
        (checkedId: number) => {
            if (checkedId === TachoDataBlockTypes.ActivityData) {
                setShowLastActivitiesField(!showLastActivitiesField);
            }
        },
        [showLastActivitiesField]
    );

    const selectedValueParser = React.useCallback((value: unknown): number | undefined => {
        const parseValue = parseInt(value as string, 10);
        return Number.isNaN(parseValue) ? undefined : parseValue;
    }, []);

    const getDefaultCheckedValue = (tachoId: number) => {
        return schedule?.tachographDataBlocks.includes(tachoId) ?? true;
    };

    const radioMonthValues = [
        { id: 1, text: t('rtds-frequency-month-custom') },
        { id: 2, text: t('rtds-frequency-day-custom') },
    ];

    const onSubmit = React.useCallback(
        (data: FieldValues) => {
            if (!schedule || isClone) {
                const submittedSchedules = mapRecipientIdsToSubmittedSchedules(
                    data,
                    recipientIds,
                    assetType,
                    tachoDataBlocks
                );
                createRtdsSchedules(submittedSchedules);
            } else {
                const editedSchedule = dataToSubmittedSchedule(data, schedule, tachoDataBlocks);
                updateRtdsSchedule(schedule.id.id, editedSchedule);
            }
            onClose();
            onSave();
        },
        [
            assetType,
            createRtdsSchedules,
            isClone,
            onClose,
            onSave,
            recipientIds,
            schedule,
            tachoDataBlocks,
            updateRtdsSchedule,
        ]
    );

    const resetFieldWithDefaultFallback = React.useCallback(
        (fieldName: keyof FieldValues, defaultValue: number | Date) => {
            if (errors[fieldName]) {
                setValue(fieldName, defaultValue, { shouldValidate: true, shouldDirty: true });
            }
        },
        [errors, setValue]
    );

    const frequencyListValues = Object.values(frequencyTypes).map((dropdownValue: Classification) => {
        return (
            <MenuItem key={dropdownValue.id} value={dropdownValue.id}>
                {formatClassification(t, ClassificationType.RtdsScheduleFrequency, dropdownValue)}
            </MenuItem>
        );
    });

    const odrinalListValues = Object.values(ordinalTypes).map((dropdownValue: Classification) => {
        return (
            <MenuItem key={dropdownValue.id} value={dropdownValue.id}>
                {formatClassification(t, ClassificationType.RtdsOrdinalType, dropdownValue)}
            </MenuItem>
        );
    });

    const weekDayListValues = Object.values(weekDayTypes).map((dropdownValue: Classification) => {
        return (
            <MenuItem key={dropdownValue.id} value={dropdownValue.id}>
                {formatClassification(t, ClassificationType.RtdsWeekDayType, dropdownValue)}
            </MenuItem>
        );
    });

    const radioButtonCustomMonthListValues = Object.values(radioMonthValues).map((radioValue) => {
        return (
            <FormControlLabel
                data-id={`radio-button-frequency-monthly:${radioValue.id}`}
                className={classes.radioButtonsMarginBottom}
                key={radioValue.id}
                value={radioValue.id}
                control={<Radio className={classes.elementRecurrenceMonthlyRadioButtonText} />}
                label={radioValue.text}
            />
        );
    });

    const priorityListValues = Object.values(priorityTypes).map((dropdownValue: Classification) => {
        return (
            <MenuItem key={dropdownValue.id} value={dropdownValue.id}>
                {formatClassification(t, ClassificationType.RtdsPriority, dropdownValue)}
            </MenuItem>
        );
    });

    const lastActivitiesNumericField = showLastActivitiesField && (
        <NumericInput
            className={classes.lastActivities}
            dataId="requested-days-last-activities"
            minValue={0}
            maxValue={380}
            fieldName="lastActivityDays"
            adornment={t('schedule-requested-days-field-adornment')}
            required
            onBlurChanged={() => resetFieldWithDefaultFallback('lastActivityDays', defaultValues.lastActivityDays)}
        />
    );

    const tachoDataBlockCheckboxes = Object.values(tachoDataBlocks)
        .filter((tachoDataBlockValue: TachographDataBlock) => tachoDataBlockValue.assetType === assetType)
        .map((tachoDataBlockValue: TachographDataBlock) => {
            return (
                <div className={classes.itemCheckbox} key={tachoDataBlockValue.id}>
                    <BooleanInput
                        dataId={`checkbox-tachodatablocks:${tachoDataBlockValue.id}`}
                        fieldName={`tachoDataBlocks[${tachoDataBlockValue.id}]`}
                        controlType={BooleanControlType.CheckboxControl}
                        label={formatClassification(t, ClassificationType.TachographDataBlocks, tachoDataBlockValue)}
                        onValueChanged={() => onTachoDataBlockValueChanged(tachoDataBlockValue.id)}
                        defaultValue={getDefaultCheckedValue(tachoDataBlockValue.id)}
                        required
                    />
                    {tachoDataBlockValue.id === TachoDataBlockTypes.ActivityData
                        ? lastActivitiesNumericField
                        : undefined}
                </div>
            );
        });
    const DialogHeaderIcon = assetType === AssetType.Driver ? DriverIcon : TruckIcon;
    const headerIcon = <DialogHeaderIcon data-id="dialog-header-icon" />;

    const tachoDataFields =
        assetType === AssetType.Vehicle || (schedule && schedule?.type === AssetType.Vehicle) ? (
            <div data-id="checkboxes-field-request-tacho-data" className={classes.element}>
                <Typography data-id="schedule-editor-requested-tacho-data">
                    {t('schedule-requested-tacho-data')}
                </Typography>
                <div data-id="checkboxes-input-request-tacho-data" className={classes.checkboxContainer}>
                    {tachoDataBlockCheckboxes}
                </div>
            </div>
        ) : undefined;

    const changeRecursState = React.useCallback(
        (frequencyValue: number) => {
            setValue('dropdownPriority', PriorityTypes.Normal, { shouldDirty: true });
            switch (frequencyValue) {
                case FrequencyTypes.Once:
                    setFrequencyType(FrequencyTypes.Once);
                    break;
                case FrequencyTypes.Daily:
                    setFrequencyType(FrequencyTypes.Daily);
                    break;
                case FrequencyTypes.Weekly:
                    setFrequencyType(FrequencyTypes.Weekly);
                    break;
                case FrequencyTypes.Monthly:
                    setFrequencyType(FrequencyTypes.Monthly);
                    break;
                default:
                    break;
            }
        },
        [setValue]
    );

    const editorTitle = () => {
        if (isClone) {
            return t(`rtds-schedule-clone-${assetType}`);
        }
        if (!schedule) {
            return t(`rtds-schedule-create-${assetType}`);
        }
        return t(`rtds-schedule-modify-${assetType}`);
    };

    const buttonName = () => {
        if (isUndefined(schedule)) {
            return t('create');
        }
        if (isClone) {
            return t('create');
        } else {
            return t('save');
        }
    };

    const title = editorTitle();

    const recipientListIsValid = () => {
        if (schedule && !isClone) {
            return true;
        }
        if (isUndefined(schedule) || isClone) {
            return recipientIds.length > 0;
        }
        return false;
    };

    const formIsValid = isValid && recipientListIsValid();

    const editMode = !isClone && schedule;

    const formHasChanges = !!Object.keys(dirtyFields).length;

    const disableWhenNoChanges = editMode && !formHasChanges;

    const onClickCallbackHeaderButtons = React.useCallback(() => {
        setRecipientIds([]);
        setRecipients([]);
        onClose();
    }, [onClose]);

    const submitButton = (
        <Button
            data-id="save"
            disabled={!formIsValid || disableWhenNoChanges}
            variant="contained"
            color="secondary"
            type="submit"
            form="schedule-editor-form"
        >
            {buttonName()}
        </Button>
    );

    const actionButtons = (
        <>
            <Button color="secondary" onClick={onClickCallbackHeaderButtons} data-id="cancel">
                {t('cancel')}
            </Button>
            {!editMode ? (
                submitButton
            ) : (
                <Tooltip
                    title={editMode && formHasChanges ? t('save-changes') : t('no-changes-to-save')}
                    data-id="save-tooltip"
                >
                    <span>{submitButton}</span>
                </Tooltip>
            )}
        </>
    );

    const dropdownFields = React.useMemo(() => {
        return (
            <div data-id="frequency-daily-container" className={classes.recurrenceMonthlyFieldsContainer}>
                <OptionsInput
                    className={classNames(classes.elementEnumerationRecurrence, classes.recurrenceItem)}
                    controlType={EnumControlType.Dropdown}
                    dataId="dropdown-field-ordinal-type"
                    values={odrinalListValues}
                    disabled={selectedDayRadioButon}
                    fieldName="dropdownMonthOrdinalType"
                    selectedValueParser={selectedValueParser}
                    required
                />
                <OptionsInput
                    className={classNames(classes.elementEnumerationRecurrence, classes.recurrenceItem)}
                    controlType={EnumControlType.Dropdown}
                    dataId="dropdown-field-week-day-type"
                    values={weekDayListValues}
                    disabled={selectedDayRadioButon}
                    fieldName="dropdownWeekDays"
                    selectedValueParser={selectedValueParser}
                    required
                />
                <Typography data-id="recurs-of-every-monthly" className={classes.recurrenceItem}>
                    {t('rtds-schedule-recurs-of-every')}
                </Typography>
                <NumericInput
                    className={classNames(classes.elementNumericRecurrence, classes.recurrenceItem)}
                    dataId="requested-months-frequency-month-months"
                    minValue={1}
                    maxValue={380}
                    fieldName="frequencyMonthForMonthsOption"
                    disabled={selectedDayRadioButon}
                    required
                    onBlurChanged={() =>
                        resetFieldWithDefaultFallback(
                            'frequencyMonthForMonthsOption',
                            defaultValues.frequencyMonthForMonthsOption
                        )
                    }
                />
                <Typography data-id="recurs-of-every-monthly-adornment" className={classes.recurrenceItem}>
                    {t('schedule-requested-months-field-adornment')}
                </Typography>
            </div>
        );
    }, [
        classes.recurrenceMonthlyFieldsContainer,
        classes.elementEnumerationRecurrence,
        classes.recurrenceItem,
        classes.elementNumericRecurrence,
        odrinalListValues,
        selectedDayRadioButon,
        selectedValueParser,
        weekDayListValues,
        t,
        resetFieldWithDefaultFallback,
        defaultValues.frequencyMonthForMonthsOption,
    ]);

    const numericInputField = React.useMemo(() => {
        return (
            <div data-id="frequency-monthly-container" className={classes.recurrenceMonthlyFieldsContainer}>
                <NumericInput
                    className={classNames(classes.elementNumericRecurrence, classes.recurrenceItem)}
                    dataId="requested-days-frequency-month-days"
                    minValue={1}
                    maxValue={31}
                    fieldName="frequencyMonthNumDays"
                    disabled={!selectedDayRadioButon}
                    required
                    onBlurChanged={() =>
                        resetFieldWithDefaultFallback('frequencyMonthNumDays', defaultValues.frequencyMonthNumDays)
                    }
                />
                <Typography data-id="frequency-monthly-numeric-input-field" className={classes.recurrenceItem}>
                    {t('rtds-schedule-recurs-of-every')}
                </Typography>
                <NumericInput
                    className={classNames(classes.elementNumericRecurrence, classes.recurrenceItem)}
                    dataId="requested-months-frequency-month-days"
                    minValue={1}
                    maxValue={380}
                    fieldName="frequencyMonthForDaysOption"
                    disabled={!selectedDayRadioButon}
                    required
                    onBlurChanged={() =>
                        resetFieldWithDefaultFallback(
                            'frequencyMonthForDaysOption',
                            defaultValues.frequencyMonthForDaysOption
                        )
                    }
                />
                <Typography data-id="frequency-monthly-days-adornment" className={classes.elementRecurrenceMonthlyText}>
                    {t('schedule-requested-months-field-adornment')}
                </Typography>
            </div>
        );
    }, [
        classes.elementNumericRecurrence,
        classes.elementRecurrenceMonthlyText,
        classes.recurrenceItem,
        classes.recurrenceMonthlyFieldsContainer,
        defaultValues.frequencyMonthForDaysOption,
        defaultValues.frequencyMonthNumDays,
        resetFieldWithDefaultFallback,
        selectedDayRadioButon,
        t,
    ]);

    const setSelectedRecipients = (recipients: AssetRecipient[]): void => {
        const flattenedIds = recipients
            .map(({ ids }) => ids)
            .reduce((accumulator, value) => {
                accumulator.push(...value);
                return accumulator;
            }, []);
        const withoutDuplicates = uniq(flattenedIds);
        setRecipientIds(withoutDuplicates);
        setRecipients(recipients);
    };

    const recipientList = (isUndefined(schedule) || isClone) && (
        <>
            <RecipientsList
                key="schedule-editor-recipient-list"
                setSelectedRecipients={setSelectedRecipients}
                selectedRecipients={selectedRecipients}
                className={classes.recipientsListPadding}
                placeholder={t(`schedule-editor-recipient-list-${assetType}`)}
                assetType={assetType}
                displayName={t('rtds-schedule')}
            />
            <Divider />
            <Typography
                data-id="required-recipient-list-field"
                color="error"
                variant="caption"
                className={classes.recipientsListPadding}
            >
                {t('rtds-schedule-editor-required')}
            </Typography>
        </>
    );

    const frequencyField = React.useMemo(
        () => (
            <OptionsInput
                className={classes.element}
                label={t('schedule-frequency')}
                controlType={EnumControlType.Dropdown}
                dataId="dropdown-field-frequency"
                values={frequencyListValues}
                disabled={false}
                onValueChanged={changeRecursState}
                selectedValueParser={selectedValueParser}
                fieldName="dropdownFrequency"
                required
            />
        ),
        [changeRecursState, classes.element, frequencyListValues, selectedValueParser, t]
    );

    const priorityField = React.useMemo(
        () => (
            <OptionsInput
                className={classes.element}
                label={t('rtds-schedule-priority')}
                controlType={EnumControlType.Dropdown}
                dataId="dropdown-field-priority"
                values={priorityListValues}
                fieldName="dropdownPriority"
                disabled={
                    frequencyType === FrequencyTypes.Daily ||
                    frequencyType === FrequencyTypes.Weekly ||
                    frequencyType === FrequencyTypes.Monthly
                }
                selectedValueParser={selectedValueParser}
                required
            />
        ),
        [classes.element, frequencyType, priorityListValues, selectedValueParser, t]
    );

    const startDateField = React.useMemo(
        () => (
            <DateTimeInput
                fieldName="startDate"
                className={classes.element}
                defaultValue={schedule?.startDate}
                dataId="start-date-input-field"
                label={t('schedule-start-date')}
                required
                onBlurChanged={() => resetFieldWithDefaultFallback('startDate', defaultValues.startDate)}
                controlType={DateTimeControlType.DateTime}
            />
        ),
        [classes.element, defaultValues.startDate, resetFieldWithDefaultFallback, schedule?.startDate, t]
    );

    const frequencyDailyField = React.useMemo(
        () =>
            frequencyType === FrequencyTypes.Daily && (
                <div data-id="frequency-daily-container" className={classes.recurrenceDailyWeeklyContainer}>
                    <Typography data-id="recurrence-daily" className={classes.recurrenceItem}>
                        {t('schdule-editor-recurrence-every')}
                    </Typography>
                    <NumericInput
                        className={classNames(classes.elementNumericRecurrence, classes.recurrenceItem)}
                        dataId="numeric-field-recurs-days"
                        minValue={1}
                        maxValue={31}
                        fieldName="frequencyDay"
                        required
                        onBlurChanged={() => resetFieldWithDefaultFallback('frequencyDay', defaultValues.frequencyDay)}
                    />
                    <Typography data-id="recurrence-daily-adornment" className={classes.recurrenceItem}>
                        {t('schedule-requested-days-field-adornment')}
                    </Typography>
                </div>
            ),
        [
            classes.elementNumericRecurrence,
            classes.recurrenceDailyWeeklyContainer,
            classes.recurrenceItem,
            frequencyType,
            defaultValues.frequencyDay,
            resetFieldWithDefaultFallback,
            t,
        ]
    );

    const frequencyWeeklyField = React.useMemo(
        () =>
            frequencyType === FrequencyTypes.Weekly && (
                <div data-id="frequency-weekly-container" className={classes.recurrenceDailyWeeklyContainer}>
                    <Typography data-id="schdule-editor-recurrence-weekly" className={classes.recurrenceItem}>
                        {t('schdule-editor-recurrence-every')}
                    </Typography>
                    <NumericInput
                        className={classNames(classes.elementNumericRecurrence, classes.recurrenceItem)}
                        dataId="numeric-field-recurs-weeks"
                        minValue={1}
                        maxValue={31}
                        fieldName="frequencyWeek"
                        required
                        onBlurChanged={() =>
                            resetFieldWithDefaultFallback('frequencyWeek', defaultValues.frequencyWeek)
                        }
                    />
                    <Typography data-id="schdule-editor-recurrence-weekly-adornment" className={classes.recurrenceItem}>
                        {t('schedule-requested-weeks-field-adornment')}
                    </Typography>
                    <OptionsInput
                        className={classNames(classes.elementEnumerationRecurrence, classes.recurrenceItem)}
                        controlType={EnumControlType.Dropdown}
                        dataId="requested-week-day"
                        values={weekDayListValues}
                        fieldName="dropdownWeekDays"
                        selectedValueParser={selectedValueParser}
                        required
                    />
                </div>
            ),
        [
            frequencyType,
            classes.recurrenceDailyWeeklyContainer,
            classes.recurrenceItem,
            classes.elementNumericRecurrence,
            classes.elementEnumerationRecurrence,
            t,
            weekDayListValues,
            selectedValueParser,
            resetFieldWithDefaultFallback,
            defaultValues.frequencyWeek,
        ]
    );

    const resetWithDefaultValue = (fieldNameOrNames: keyof FieldValues | (keyof FieldValues)[]) => {
        if (Array.isArray(fieldNameOrNames)) {
            fieldNameOrNames.forEach((fieldName) =>
                setValue(fieldName, defaultValues[fieldName], { shouldDirty: true })
            );
        } else {
            setValue(fieldNameOrNames, defaultValues[fieldNameOrNames], { shouldDirty: true });
        }
    };

    const setSelectedDayRadioButtonAndResetNumField = (option: unknown) => {
        const recurrenceExpressionIsMonthly = option === MonthlyRadioButtonsTypes.Month;

        setSelectedDayRadioButon(!recurrenceExpressionIsMonthly);
        if (recurrenceExpressionIsMonthly) {
            resetWithDefaultValue(['frequencyMonthNumDays', 'frequencyMonthForDaysOption']);
        } else {
            resetWithDefaultValue(['dropdownMonthOrdinalType', 'dropdownWeekDays', 'frequencyMonthForMonthsOption']);
        }
    };

    const frequencyMonthlyField = frequencyType === FrequencyTypes.Monthly && (
        <div data-id="frequency-weekly-container" className={classes.recurrenceMonthlyContainer}>
            <OptionsInput
                className={classNames(classes.elementEnumerationRecurrence, classes.recurrenceItem)}
                controlType={EnumControlType.RadioButtons}
                onValueChanged={setSelectedDayRadioButtonAndResetNumField}
                dataId="radio-field-every-month-custom"
                values={radioButtonCustomMonthListValues}
                fieldName="radioButtonsFrequencyMonth"
                selectedValueParser={selectedValueParser}
                required
            />
            <div data-id="frequency-weekly-container" className={classes.radioFieldContainer}>
                {dropdownFields}
                {numericInputField}
            </div>
        </div>
    );

    return (
        <div data-id="editor-root" className={classes.root}>
            <WidgetDialog
                testId="schedule-editor-dialog"
                open
                title={title}
                dialogActions={actionButtons}
                headerActions={[headerIcon]}
            >
                <>
                    {recipientList}
                    <FormProvider {...methods}>
                        <form
                            onSubmit={methods.handleSubmit(onSubmit)}
                            data-id="schedule-editor-form"
                            id="schedule-editor-form"
                        >
                            <div data-id="editor-body" className={classes.body}>
                                {frequencyField}
                                {frequencyDailyField}
                                {frequencyWeeklyField}
                                {frequencyMonthlyField}
                                {priorityField}
                                {startDateField}
                                {tachoDataFields}
                            </div>
                        </form>
                    </FormProvider>
                </>
            </WidgetDialog>
        </div>
    );
};
