import memoizeOne from 'memoize-one';
import { withTranslation } from 'react-i18next';

import { CountryFormatter } from '~/components/Formatters';
import type { GridColumnDefinition } from '~/components/Grid';
import {
    createClassificationsColumn,
    createDateTimeColumn,
    createFormattedColumn,
    createStringColumn,
    filterDataSourceBySearchQuery,
} from '~/components/Grid';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
    DriverCardFileMetadata,
} from '~/services/ApiClient';
import { ClassificationType, ExportStatusType, RmsStatusType, TachoFileType } from '~/services/ApiClient';
import { formatCountry, formatRmsStatus, formatTachoExportStatus, formatTachoFileType } from '~/services/Formatters';

import type { TachoFileEntry } from '../../models';

import { ColumnName } from './constants';
import { getDriverDisplayNameFactory, getUploadDetailsValue, getVehicleDisplayNameFactory } from './getCellValue';

export const filterDataSourceMemoized = memoizeOne(
    (
        searchQuery: string | undefined,
        dataSource: TachoFileEntry[],
        columnDefinitions: Array<GridColumnDefinition<TachoFileEntry>>,
        visibleColumns: string[]
    ) => filterDataSourceBySearchQuery(searchQuery, dataSource, columnDefinitions, visibleColumns)
);

export const getColumns = (
    t: SingleTFunction,
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat,
    driverDisplayNameFormat: DisplayUserPreferencesDriverDisplayFormat,
    canViewRms: boolean
): Array<GridColumnDefinition<TachoFileEntry>> => {
    const columns: Array<GridColumnDefinition<TachoFileEntry>> = [
        createDateTimeColumn(
            ColumnName.DOWNLOADDATE,
            t('download-date'),
            t('download-related'),
            (e) => e.file.downloadDateTime
        ),
        createStringColumn(
            ColumnName.VEHICLE,
            t('vehicle'),
            t('vehicle-related'),
            getVehicleDisplayNameFactory(vehicleDisplayNameFormat)
        ),
        createStringColumn(
            ColumnName.VEHICLEALIAS,
            t('vehicle-alias'),
            t('vehicle-related'),
            (e) => e.file.sendVehicle?.vehicle?.alias
        ),
        createStringColumn(ColumnName.MSISDN, t('msisdn'), t('vehicle-related'), (e) => e.file.sendVehicle?.msisdn),
        createStringColumn(
            ColumnName.DRIVER,
            t('driver'),
            t('driver-related'),
            getDriverDisplayNameFactory(driverDisplayNameFormat)
        ),
        createStringColumn(
            ColumnName.FIRSTNAME,
            t('first-name'),
            t('tachograph-card-related'),
            (e) => (e.file.metadata.value as DriverCardFileMetadata)?.firstName
        ),
        createStringColumn(
            ColumnName.LASTNAME,
            t('last-name'),
            t('tachograph-card-related'),
            (e) => (e.file.metadata.value as DriverCardFileMetadata)?.lastName
        ),
        createFormattedColumn(
            ColumnName.SOURCE,
            t('source'),
            t('download-related'),
            (e) => e.file.id.type,
            (v) => formatTachoFileType(t, v),
            {
                cellFiltering: { options: Object.values(TachoFileType) },
            }
        ),
        createStringColumn(
            ColumnName.NATIONALPHACODE,
            t('nation-alpha-code'),
            t('tachograph-card-related'),
            (e) => e.file.metadata.value.nationAlphaCode
        ),
        createFormattedColumn(
            ColumnName.COUNTRY,
            t('country'),
            t('tachograph-card-related'),
            (e) => e.file.metadata.value.countryCode,
            (v: string) => formatCountry(t, v),
            { valueFormatterComponent: withTranslation()(CountryFormatter) }
        ),
        createClassificationsColumn(
            t,
            ColumnName.DETAILS,
            t('details'),
            t('download-related'),
            (e) => e.file.metadata.dataBlocks,
            ClassificationType.TachographDataBlocks
        ),
        createFormattedColumn(
            ColumnName.EXPORTSTATUS,
            t('export-status'),
            t('export-related'),
            (e) => e.file.exportStatus.type,
            (v) => formatTachoExportStatus(t, v),
            {
                cellFiltering: { options: Object.values(ExportStatusType) },
            }
        ),
        createDateTimeColumn(
            ColumnName.EXPORTDATE,
            t('export-date'),
            t('export-related'),
            (e) => e.file.exportStatus.dateTime
        ),
    ];

    if (canViewRms) {
        columns.push(
            createFormattedColumn(
                ColumnName.UPLOADSTATUS,
                t('upload-status'),
                t('upload-related'),
                (e) => e.file.rmsStatus?.type,
                (v) => formatRmsStatus(t, v),
                {
                    cellFiltering: { options: Object.values(RmsStatusType) },
                }
            ),
            createDateTimeColumn(
                ColumnName.UPLOADEVENTDATE,
                t('upload-event-date'),
                t('upload-related'),
                (e) => e.file.rmsStatus?.dateTime
            ),
            createStringColumn(
                ColumnName.UPLOADEVENTDETAILS,
                t('upload-event-details'),
                t('upload-related'),
                getUploadDetailsValue
            )
        );
    }

    return columns;
};

export const getColumnsMemoized = memoizeOne(getColumns);
