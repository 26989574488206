import type { Table } from '@devexpress/dx-react-grid';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import * as React from 'react';

import type { VirtualTableCellClassKey } from './styles';

export interface VirtualTableCellProps extends Table.DataCellProps, React.HTMLAttributes<HTMLTableCellElement> {
    getCellClassName?: (row: unknown, columnName: string, value: unknown) => string | undefined;
    customRenderer?: (children: ReactNode) => ReactNode;
}

export interface VirtualTableCellInnerProps extends VirtualTableCellProps, WithStyles<VirtualTableCellClassKey> {}

export const VirtualTableCellComponent: React.FunctionComponent<VirtualTableCellInnerProps> = ({
    classes,
    getCellClassName,
    customRenderer,
    children,
    ...restProps
}) => {
    return (
        <VirtualTable.Cell
            {...restProps}
            className={classNames(
                restProps.className,
                classes.cell,
                getCellClassName && getCellClassName(restProps.row, restProps.column.name, restProps.value)
            )}
            data-id={restProps.column.name}
        >
            {customRenderer ? customRenderer(children) : children}
        </VirtualTable.Cell>
    );
};
