import type { Dispatch } from 'redux';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListRootStoreState } from '~/components/SceneList';

import { changeSearchQueryAction, clearSearchQueryAction } from '../../data';

import type { SceneListViewHeaderProps } from './models';

export interface OwnProps<T> extends SceneListViewHeaderProps<T>, InjectedTranslationProps {}

export interface StateProps {
    searchQuery?: string;
}

export interface DispatchProps {
    changeSearchQuery: (searchQuery: string) => void;
    clearSearchQuery: () => void;
}

export const mapStateToPropsFactory =
    (preferencesKey: string) =>
    (sceneListStoreState: SceneListRootStoreState): StateProps => ({
        searchQuery: sceneListStoreState[preferencesKey]?.searchQuery,
    });

export const mapDispatchToPropsFactory =
    (preferencesKey: string) =>
    (dispatch: Dispatch): DispatchProps => ({
        changeSearchQuery: (searchQuery: string) => {
            dispatch(changeSearchQueryAction(preferencesKey, searchQuery));
        },
        clearSearchQuery: () => {
            dispatch(clearSearchQueryAction(preferencesKey));
        },
    });
