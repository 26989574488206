import * as React from 'react';

import { updateInterval } from './constants';

export interface SynchronizationClockProps {}

export interface SynchronizationClockDispatchProps {
    updateSynchronizationClock: () => void;
}

export interface SynchronizationClockInnerProps extends SynchronizationClockProps, SynchronizationClockDispatchProps {}

export class SynchronizationClockComponent extends React.Component<SynchronizationClockInnerProps> {
    private intervalHandle?: number;

    public componentDidMount(): void {
        this.intervalHandle = window.setInterval(() => {
            this.props.updateSynchronizationClock();
        }, updateInterval);
    }

    public componentWillUnmount(): void {
        window.clearInterval(this.intervalHandle);
    }

    public render() {
        return null;
    }
}
