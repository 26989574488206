import 'leaflet-draw/dist/leaflet.draw.css';
import type { DrawEvents, FeatureGroup as FeatureGroupRef, Polygon } from 'leaflet';
import { GeoJSON as LGeoJSON } from 'leaflet';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { FeatureGroup, useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import { DEFAULT_SHAPE_OPTIONS } from './consts';
import type { EditableLayerProps } from './models';

const EditableLayer: FC<EditableLayerProps> = (props) => {
    const { onChange, shape, maxPoints = 10 } = props;

    const mapContext = useMap();
    const layer = useRef<Polygon | undefined>();
    const editLayerRef = useRef<FeatureGroupRef | null>(null);

    const handleEdit = (args: DrawEvents.Edited) => {
        args.layers.eachLayer((_layer) => {
            onChange(_layer as Polygon);
        });
    };

    const handleCreate = (args: DrawEvents.Created) => {
        if (layer.current && editLayerRef.current?.hasLayer(layer.current)) {
            editLayerRef.current.removeLayer(layer.current);
        }

        layer.current = args.layer as Polygon;
        onChange(layer.current);
    };

    const handleDelete = (args: DrawEvents.Deleted) => {
        args.layers.eachLayer((_layer) => {
            _layer.remove();
            layer.current = undefined;
        });

        onChange(undefined);
    };

    useEffect(() => {
        if (!shape) {
            return;
        }

        const fieldLayer = new LGeoJSON(shape.toGeoJSON());

        fieldLayer.eachLayer((_layer) => {
            editLayerRef.current?.addLayer(_layer);
            layer.current = _layer as Polygon;

            setTimeout(() => {
                mapContext.fitBounds((layer.current as Polygon).getBounds());
            }, 500);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FeatureGroup ref={editLayerRef}>
            <EditControl
                position="topright"
                onEdited={handleEdit}
                onCreated={handleCreate}
                onDeleted={handleDelete}
                draw={{
                    circle: false,
                    circlemarker: false,
                    marker: false,
                    polyline: false,
                    rectangle: false,
                    polygon: { maxPoints, shapeOptions: { ...DEFAULT_SHAPE_OPTIONS } },
                }}
            />
        </FeatureGroup>
    );
};

EditableLayer.displayName = 'EditableLayer';
export { EditableLayer };
