import type { ActionTypes } from '~/data/resetpassword';
import { ActionTypeKeys } from '~/data/resetpassword';
import { ServerResultStatus } from '~/services/ApiClient';

export interface ResetPasswordStoreState {
    lastServerResultStatus: ServerResultStatus | undefined;
    verifyTokenServerResultStatus: ServerResultStatus | undefined;
}

const defaultStoreState: ResetPasswordStoreState = {
    lastServerResultStatus: undefined,
    verifyTokenServerResultStatus: undefined,
};

export const resetPasswordReducer = (
    state: ResetPasswordStoreState = defaultStoreState,
    action: ActionTypes
): ResetPasswordStoreState => {
    switch (action.type) {
        case ActionTypeKeys.REQUESTPASSWORDRESET_PENDING:
        case ActionTypeKeys.RESETPASSWORD_PENDING:
            return { ...state, lastServerResultStatus: ServerResultStatus.PENDING };
        case ActionTypeKeys.REQUESTPASSWORDRESET_FULFILLED:
        case ActionTypeKeys.RESETPASSWORD_FULFILLED:
            return { ...state, lastServerResultStatus: action.payload };
        case ActionTypeKeys.REQUESTPASSWORDRESET_REJECTED:
        case ActionTypeKeys.RESETPASSWORD_REJECTED:
            return { ...state, lastServerResultStatus: ServerResultStatus.SERVERERROR };
        case ActionTypeKeys.VALIDATERESETPASSWORD_PENDING:
            return { ...state, verifyTokenServerResultStatus: ServerResultStatus.PENDING };
        case ActionTypeKeys.VALIDATERESETPASSWORD_FULFILLED:
            return { ...state, verifyTokenServerResultStatus: action.payload };
        case ActionTypeKeys.VALIDATERESETPASSWORD_REJECTED:
            return {
                ...state,
                verifyTokenServerResultStatus: ServerResultStatus.SERVERERROR,
            };
        case ActionTypeKeys.RESETPASSWORD_CLEAR_DATA:
            return { ...defaultStoreState };
        default:
            return state;
    }
};
