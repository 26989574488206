import withStyles from '@mui/styles/withStyles';
import type * as React from 'react';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';
import type { MonitoringAssetEntry } from '~/data/monitoring/models';

import type { SelectEntryDialogInnerProps, SelectEntryDialogProps } from './component';
import { SelectEntryDialogComponentFactory } from './component';
import { SelectEntryDialogStyleRules } from './styles';

export type { EntryRowType, FilteringComponentType } from './component';

export const SelectEntryDialogFactory = <E extends MonitoringAssetEntry, F>(
    getEntryIdentifier: (entry: E) => number | string,
    canSelectEntry: (entry: E) => boolean
): React.ComponentType<SelectEntryDialogProps<E, F>> =>
    compose<SelectEntryDialogInnerProps<E, F>, SelectEntryDialogProps<E, F>>(
        setDisplayName('SelectEntryDialog'),
        withTranslation(),
        withStyles(SelectEntryDialogStyleRules)
    )(SelectEntryDialogComponentFactory<E, F>(getEntryIdentifier, canSelectEntry));
