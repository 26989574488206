import { uniqBy } from '~/libs/utility';
import type { ConversationUpdate, Message } from '~/services/ApiClient';

import { filterUpdatedMessages } from './filterUpdatedMessages';

export const mergeUpdatedMessages = (
    pendingConversationUpdate: ConversationUpdate,
    nextConversationUpdate: ConversationUpdate
): Message[] =>
    uniqBy(
        [
            ...filterUpdatedMessages(pendingConversationUpdate, nextConversationUpdate),
            ...filterUpdatedMessages(nextConversationUpdate, pendingConversationUpdate),
        ],
        'id'
    );
