import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    profileSection: { backgroundColor: 'unset' },
    header: { padding: theme.spacing(), backgroundColor: theme.palette.background.default },
    infoBox: { alignItems: 'center', display: 'flex', flexDirection: 'column', padding: theme.spacing(1, 1, 2) },
    avatar: {
        marginBottom: theme.spacing(),
        width: 64,
        height: 64,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.text.secondary}`,
    },
}));
