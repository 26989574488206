import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { AssetAvatarInnerProps, AssetAvatarProps } from './component';
import { AssetAvatarComponent } from './component';
import { AssetAvatarStyleRules } from './styles';

export const AssetAvatar = compose<AssetAvatarInnerProps, AssetAvatarProps>(
    setDisplayName('AssetAvatar'),
    withTranslation(),
    withStyles(AssetAvatarStyleRules)
)(AssetAvatarComponent);
