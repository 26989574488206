import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledIndicatorGroupContainer } from '~/components/Indicators';
import { ReorderableSection } from '~/components/Sections';
import { DriverActivityGraph } from '~/scenes/Monitoring/components/DetailsPane/components/Vehicles/components/DriverActivityGraph';

import { SectionName } from '../../../consts';
import type { SectionProps } from '../models';

import { ContinuousDriving } from './components/ContinuousDriving';

const DailyOverviewSection: FC<SectionProps> = (props) => {
    const { dragHandleElement, toggleCollapsed, driverStatus, isCollapsed } = props;
    const { t } = useTranslation();

    return (
        <ReorderableSection
            title={t(`driver-subpage-${SectionName.DAILY_OVERVIEW}`)}
            dataId={SectionName.DAILY_OVERVIEW}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            dragHandleElement={dragHandleElement}
        >
            <DriverActivityGraph
                driverId={driverStatus.driver?.id}
                componentId="daily-overview-section-driver-activity-graph"
            />
            <LabeledIndicatorGroupContainer dataId="daily-overview-indicator-group-container">
                <ContinuousDriving {...props} />
            </LabeledIndicatorGroupContainer>
        </ReorderableSection>
    );
};

DailyOverviewSection.displayName = 'DailyOverviewSection';
export default DailyOverviewSection;
