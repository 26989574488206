import { humanizer } from 'humanize-duration';
import { getI18n } from 'react-i18next';

/* eslint-disable no-restricted-imports */
import deDe from '../../locales/duration.de-de.json';
import enGb from '../../locales/duration.en-gb.json';
import esEs from '../../locales/duration.es-es.json';
import frFr from '../../locales/duration.fr-fr.json';
import huHu from '../../locales/duration.hu-hu.json';
import itIt from '../../locales/duration.it-it.json';
import nlNl from '../../locales/duration.nl-nl.json';
import plPl from '../../locales/duration.pl-pl.json';
import ptBr from '../../locales/duration.pt-br.json';

import type { EnhancedOptions } from './models';

const customHumanizer = humanizer({
    language: 'short-en-GB',
    fallbacks: ['short-en-GB'],
    languages: {
        'short-en-GB': {
            y: (count: number) => (count > 1 ? enGb.yy : enGb.y),
            mo: (count: number) => (count > 1 ? enGb.MM : enGb.M),
            w: (count: number) => (count > 1 ? enGb.ww : enGb.w),
            d: (count: number) => (count > 1 ? enGb.dd : enGb.d),
            h: (count: number) => (count > 1 ? enGb.hh : enGb.h),
            m: (count: number) => (count > 1 ? enGb.mm : enGb.m),
            s: (count: number) => (count > 1 ? enGb.ss : enGb.s),
            ms: (count: number) => (count > 1 ? enGb.SS : enGb.S),
        },
        'short-nl-NL': {
            y: (count: number) => (count > 1 ? nlNl.yy : nlNl.y),
            mo: (count: number) => (count > 1 ? nlNl.MM : nlNl.M),
            w: (count: number) => (count > 1 ? nlNl.ww : nlNl.w),
            d: (count: number) => (count > 1 ? nlNl.dd : nlNl.d),
            h: () => nlNl.hh,
            m: (count: number) => (count > 1 ? nlNl.mm : nlNl.m),
            s: () => nlNl.ss,
            ms: () => nlNl.SS,
        },
        'short-de-DE': {
            y: () => deDe.yy,
            mo: () => deDe.MM,
            w: () => deDe.ww,
            d: () => deDe.dd,
            h: () => deDe.hh,
            m: () => deDe.mm,
            s: () => deDe.ss,
            ms: () => deDe.SS,
        },
        'short-fr-FR': {
            y: (count: number) => (count > 1 ? frFr.yy : frFr.y),
            mo: (count: number) => (count > 1 ? frFr.MM : frFr.M),
            w: (count: number) => (count > 1 ? frFr.ww : frFr.w),
            d: (count: number) => (count > 1 ? frFr.dd : frFr.d),
            h: () => frFr.hh,
            m: () => frFr.mm,
            s: () => frFr.ss,
            ms: (count: number) => (count > 1 ? frFr.SS : frFr.S),
        },
        'short-es-ES': {
            y: (count: number) => (count > 1 ? esEs.yy : esEs.y),
            mo: (count: number) => (count > 1 ? esEs.MM : esEs.M),
            w: (count: number) => (count > 1 ? esEs.ww : esEs.w),
            d: (count: number) => (count > 1 ? esEs.dd : esEs.d),
            h: (count: number) => (count > 1 ? esEs.hh : esEs.h),
            m: (count: number) => (count > 1 ? esEs.mm : esEs.m),
            s: (count: number) => (count > 1 ? esEs.ss : esEs.s),
            ms: (count: number) => (count > 1 ? esEs.SS : esEs.S),
        },
        'short-pl-PL': {
            y: (count: number) => (count > 1 ? plPl.yy : plPl.y),
            mo: (count: number) => (count > 1 ? plPl.MM : plPl.M),
            w: (count: number) => (count > 1 ? plPl.ww : plPl.w),
            d: (count: number) => (count > 1 ? plPl.dd : plPl.d),
            h: (count: number) => (count > 1 ? plPl.hh : plPl.h),
            m: (count: number) => (count > 1 ? plPl.mm : plPl.m),
            s: () => plPl.ss,
            ms: (count: number) => (count > 1 ? plPl.SS : plPl.S),
        },
        'short-it-IT': {
            y: (count: number) => (count > 1 ? itIt.yy : itIt.y),
            mo: () => itIt.MM,
            w: () => itIt.ww,
            d: () => itIt.dd,
            h: (count: number) => (count > 1 ? itIt.hh : itIt.h),
            m: () => itIt.mm,
            s: () => itIt.ss,
            ms: () => itIt.SS,
        },
        'short-pt-BR': {
            y: (count: number) => (count > 1 ? ptBr.yy : ptBr.y),
            mo: (count: number) => (count > 1 ? ptBr.MM : ptBr.M),
            w: (count: number) => (count > 1 ? ptBr.ww : ptBr.w),
            d: (count: number) => (count > 1 ? ptBr.dd : ptBr.d),
            h: (count: number) => (count > 1 ? ptBr.hh : ptBr.h),
            m: (count: number) => (count > 1 ? ptBr.mm : ptBr.m),
            s: (count: number) => (count > 1 ? ptBr.ss : ptBr.s),
            ms: (count: number) => (count > 1 ? ptBr.SS : ptBr.S),
        },
        'short-hu-HU': {
            y: (count: number) => (count > 1 ? huHu.yy : huHu.y),
            mo: (count: number) => (count > 1 ? huHu.MM : huHu.M),
            w: (count: number) => (count > 1 ? huHu.ww : huHu.w),
            d: (count: number) => (count > 1 ? huHu.dd : huHu.d),
            h: (count: number) => (count > 1 ? huHu.hh : huHu.h),
            m: (count: number) => (count > 1 ? huHu.mm : huHu.m),
            s: (count: number) => (count > 1 ? huHu.ss : huHu.s),
            ms: (count: number) => (count > 1 ? huHu.SS : huHu.S),
        },
    },
});

const durationHumanizer = (ms: number, options: EnhancedOptions) => {
    const { short, ...restOpts } = { short: true, ...options };
    const { language } = getI18n();

    return customHumanizer(ms, {
        maxDecimalPoints: 0,
        language: short ? `short-${language}` : language.split('-')[0],
        ...restOpts,
    });
};

export { durationHumanizer };
