import type { Dispatch } from 'react';

import { intersectionWith } from '../../../utility';

interface UseBulkCheckboxArgs<T> {
    items: T[];
    selected: T[];
    toggleSelected: Dispatch<T[]>;
    equalityComparer: (a: T, b: T) => boolean;
}

const useBulkCheckbox = <T>(args: UseBulkCheckboxArgs<T>) => {
    const { items, selected, toggleSelected, equalityComparer } = args;

    const selectedVisibleValues = intersectionWith(items, selected, equalityComparer);
    const allVisibleChecked = selectedVisibleValues.length > 0 && selectedVisibleValues.length === items.length;
    const partialVisibleChecked = selectedVisibleValues.length > 0 && !allVisibleChecked;
    const toggleAllVisible = () => {
        if (allVisibleChecked || partialVisibleChecked) {
            // Unselect all visible items from current selection
            toggleSelected(selectedVisibleValues);
        } else {
            toggleSelected(items);
        }
    };

    return {
        bulkChecked: allVisibleChecked,
        bulkIndeterminate: partialVisibleChecked,
        toggleBulk: toggleAllVisible,
    };
};

export { useBulkCheckbox };
export type { UseBulkCheckboxArgs };
