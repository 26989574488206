import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type DocumentViewerShellClassKey =
    | 'dialogPaper'
    | 'toolbar'
    | 'filename'
    | 'fab'
    | 'fabDisabled'
    | 'menuIcon'
    | 'menuButton';

export const useStyles = makeStyles<Theme, {}, DocumentViewerShellClassKey>((theme) => ({
    dialogPaper: {
        backgroundColor: 'unset',
        position: 'absolute',
        left: 0,
        top: 64,
        right: 0,
        bottom: 0,
        margin: 0,
        maxHeight: 'unset',
        maxWidth: 'unset',
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(),
    },
    filename: {
        margin: theme.spacing(),
    },
    fab: {
        margin: theme.spacing(),
    },
    fabDisabled: {
        '&::before': {
            content: '""',
            backgroundColor: theme.palette.background.default,
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderRadius: '50%',
            zIndex: -1,
        },
    },
    menuButton: {
        textTransform: 'none',
    },
    menuIcon: {
        marginRight: theme.spacing(),
        minWidth: 'unset',
    },
}));
