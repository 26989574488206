import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const EbsIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M0 0H24V24H0Z" fill="none" />
            <path d="M15 20A1 1 0 0 0 14 19H13V17H17A2 2 0 0 0 19 15V5A2 2 0 0 0 17 3H7A2 2 0 0 0 5 5V15A2 2 0 0 0 7 17H11V19H10A1 1 0 0 0 9 20H2V22H9A1 1 0 0 0 10 23H14A1 1 0 0 0 15 22H22V20H15M7 15V5H17V15H7M12 14L16 10H13V6H11V10H8L12 14Z" />
        </SvgIcon>
    );
};
EbsIcon.displayName = 'EbsIcon';

export { EbsIcon };
