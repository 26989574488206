import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationSummarySearchBarClassKey = 'root' | 'input' | 'icon';

export const ConversationSummarySearchBarStyleRules: StyleRulesCallback<
    Theme,
    {},
    ConversationSummarySearchBarClassKey
> = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(),
        },
        input: {
            backgroundColor: theme.palette.background.paper,
            height: 32,
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.divider}`,
            color: theme.palette.text.primary,
        },
        icon: {
            width: 14,
            height: 14,
            margin: 5,
            color: theme.palette.text.secondary,
            cursor: 'pointer',
        },
    });
