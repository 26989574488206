import DragHandle from '@mui/icons-material/DragHandle';
import { Tooltip } from '@mui/material';
import type { FC } from 'react';
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

export interface DragHandleProps {
    dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

export interface DragHandleInnerProps extends DragHandleProps, InjectedTranslationProps {}

export const DragHandleComponent: FC<DragHandleInnerProps> = ({ t, dragHandleProps }) => (
    <Tooltip title={t('change-order')}>
        <span {...dragHandleProps} data-id="drag-handle" style={{ height: '24px' }}>
            <DragHandle />
        </span>
    </Tooltip>
);
