import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DriverSubpageHeaderInnerProps, DriverSubpageHeaderProps } from './component';
import { DriverSubpageHeaderComponent } from './component';
import { DriverSubpageHeaderStyleRules } from './styles';

export const DriverSubpageHeader = compose<DriverSubpageHeaderInnerProps, DriverSubpageHeaderProps>(
    setDisplayName('DriverSubpageHeader'),
    withTranslation(),
    withStyles(DriverSubpageHeaderStyleRules)
)(DriverSubpageHeaderComponent);
