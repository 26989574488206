import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { RtdsScheduleDetailsInnerProps, RtdsScheduleDetailsProps } from './component';
import { RtdsScheduleDetailsComponent } from './component';

export type { RtdsScheduleDetailsProps } from './component';

export const RtdsScheduleDetails = compose<RtdsScheduleDetailsInnerProps, RtdsScheduleDetailsProps>(
    setDisplayName('RtdsScheduleDetails'),
    withTranslation()
)(RtdsScheduleDetailsComponent);
