import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type {
    DriverActivityType,
    DriverEvent,
    DriverEventType,
    DriverSubActivityType,
    ResolvedDriverEvent,
} from '~/services/ApiClient';

import { completedActivityResolver } from './redux.completedActivityResolver';

export const driverEventResolver = (
    { init, toJSON, ...restProps }: DriverEvent,
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>,
    driverEventTypes: NumericDictionary<DriverEventType>
): ResolvedDriverEvent => {
    return {
        ...restProps,
        eventType: !isUndefined(restProps.eventType) ? driverEventTypes[restProps.eventType] : undefined,
        completedActivity: completedActivityResolver(
            restProps.completedActivity,
            driverActivityTypes,
            driverSubActivityTypes
        ),
    };
};
