import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { userPreferencesStateSelector } from '~/selectors';

import type {
    DispatchProps,
    LoadUserPreferencesStatusMonitorInnerProps,
    LoadUserPreferencesStatusMonitorProps,
    StateProps,
} from './component';
import { LoadUserPreferencesStatusMonitorComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export const LoadUserPreferencesStatusMonitor = compose<
    LoadUserPreferencesStatusMonitorInnerProps,
    LoadUserPreferencesStatusMonitorProps
>(
    setDisplayName('LoadUserPreferencesStatusMonitor'),
    connect<StateProps, DispatchProps>(
        createSelector(userPreferencesStateSelector, mapStateToProps),
        mapDispatchToProps
    )
)(LoadUserPreferencesStatusMonitorComponent);
