import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TrailerConnectionFilteringClassKey = 'root' | 'formControl' | 'label';

export const trailerConnectionFilteringStyleRules: StyleRulesCallback<Theme, {}, TrailerConnectionFilteringClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        formControl: {
            marginBottom: theme.spacing(2),
        },
        label: {
            marginBottom: theme.spacing(2),
        },
    });
