import type { TilesTypes } from '@fv/components';

import type { DisplayUserPreferencesUnitSystem, MapLayersSecurables } from '~/services/ApiClient';

import { getMapLayerProps } from './layer';
import type { PtvMapProfile } from './layers.ptv';
import { PtvLayer, getPtvLayerUrl } from './layers.ptv';
import type { MapProfile } from './mapProfile';

interface GetExtraMapLayersPropsArgs {
    timestamp: number;
    truckAttributesOverlayEnabled: boolean;
    trafficIncidentsOverlayEnabled: boolean;
    mapLayersSecurables: MapLayersSecurables;
    mapProfile: MapProfile;
    unitSystem: DisplayUserPreferencesUnitSystem;
    language?: string;
}

export const getExtraMapLayersProps = (args: GetExtraMapLayersPropsArgs): TilesTypes[] => {
    const {
        timestamp,
        truckAttributesOverlayEnabled,
        trafficIncidentsOverlayEnabled,
        mapLayersSecurables,
        mapProfile,
        unitSystem,
        language,
    } = args;

    const extraMapLayersProps: TilesTypes[] = [];
    const commonLayerProps = { timestamp, isJson: true, language, profile: mapProfile as unknown as PtvMapProfile };

    if (
        truckAttributesOverlayEnabled &&
        mapLayersSecurables.truckAttributesAllowed &&
        trafficIncidentsOverlayEnabled &&
        mapLayersSecurables.trafficIncidentsAllowed
    ) {
        return [
            getMapLayerProps({
                layer: `${PtvLayer.TRUCKATTRIBUTES}_${PtvLayer.TRAFFICINCIDENTS}`,
                url: getPtvLayerUrl({
                    ...commonLayerProps,
                    layers: [PtvLayer.TRUCKATTRIBUTES, PtvLayer.TRAFFICINCIDENTS],
                }),
                unitSystem,
                zIndex: 3,
            }),
        ];
    }

    if (truckAttributesOverlayEnabled && mapLayersSecurables.truckAttributesAllowed) {
        extraMapLayersProps.push(
            getMapLayerProps({
                layer: PtvLayer.TRUCKATTRIBUTES,
                url: getPtvLayerUrl({ ...commonLayerProps, layers: [PtvLayer.TRUCKATTRIBUTES] }),
                unitSystem,
                zIndex: 3,
            })
        );
    }

    if (trafficIncidentsOverlayEnabled && mapLayersSecurables.trafficIncidentsAllowed) {
        extraMapLayersProps.push(
            getMapLayerProps({
                layer: PtvLayer.TRAFFICINCIDENTS,
                url: getPtvLayerUrl({ ...commonLayerProps, layers: [PtvLayer.TRAFFICINCIDENTS] }),
                unitSystem,
                zIndex: 4,
            })
        );
    }

    return extraMapLayersProps;
};
