export const gdfToDate = (gdfString: string): Date | undefined => {
    const gdfDate = gdfString.match(/y([0-9]{4})M([0-9]{1,2})d([0-9]{1,2})h([0-9]{1,2})m([0-9]{1,2})s([0-9]{1,2})/);

    if (gdfDate) {
        const year = parseInt(gdfDate[1], 10);
        const month = parseInt(gdfDate[2], 10);
        const date = parseInt(gdfDate[3], 10);
        const hours = parseInt(gdfDate[4], 10);
        const minutes = parseInt(gdfDate[5], 10);
        const seconds = parseInt(gdfDate[6], 10);

        return new Date(Date.UTC(year, month - 1, date, hours, minutes, seconds));
    }

    return undefined;
};
