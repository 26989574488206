import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const UnknownIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            {/* tslint:disable:max-line-length */}
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M9.81 16a4.9 4.9 0 0 1 .51-2.49 8.33 8.33 0 0 1 2-1.93A6.72 6.72 0 0 0 13.67 10a3.33 3.33 0 0 0 .52-1.83 2.51 2.51 0 0 0-.59-1.76 2.22 2.22 0 0 0-1.69-.63 2.27 2.27 0 0 0-1.53.54A2 2 0 0 0 9.74 7.9H6.28V7.83A4.21 4.21 0 0 1 7.8 4.24 6.43 6.43 0 0 1 11.91 3a6.2 6.2 0 0 1 4.28 1.38 4.79 4.79 0 0 1 1.56 3.76A5 5 0 0 1 16.84 11a8.2 8.2 0 0 1-2.27 2.2 3 3 0 0 0-1 1.15A4.53 4.53 0 0 0 13.36 16Zm3.57 5H9.8V18h3.58Z" />
        </SvgIcon>
    );
};
UnknownIcon.displayName = 'UnknownIcon';

export { UnknownIcon };
