import FilterList from '@mui/icons-material/FilterList';
import { Checkbox, Divider, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { ComponentType, FC, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { Search } from '~/components/Search';
import { TitledIconButton } from '~/components/TitledIconButton';

import { WidgetDialog } from '../WidgetDialog';

import { FilterSheet } from './components/FilterSheet';
import { SelectionDetails } from './components/SelectionDetails';
import type { SelectEntryDialogClassKey } from './styles';

export type FilteringComponentType<T> = ComponentType<{ filter?: T; onFilterStateChange: (filter: T) => void }>;

export type EntryRowType<T> = ComponentType<{ entry: T }>;

export interface SelectEntryDialogProps<E, F = undefined> {
    entries: E[];
    title: ReactNode;
    defaultSearchQuery?: string;
    defaultFilter?: F;
    filterEntries: (entries: E[], searchQuery?: string, filter?: F) => E[];
    FilteringComponent?: FilteringComponentType<F>;
    EntryRow: EntryRowType<E>;
    actionButtons: JSX.Element;
    selectedEntry?: E;
    onEntrySelect: (entry?: E) => void;
    open: boolean;
    onClose: () => void;
    entryTypeSingular: string;
    entryTypePlural: string;
    dataId: string;
}

export interface SelectEntryDialogInnerProps<E, F>
    extends SelectEntryDialogProps<E, F>,
        InjectedTranslationProps,
        WithStyles<SelectEntryDialogClassKey> {}

export const SelectEntryDialogComponentFactory = <E extends {}, F>(
    getEntryIdentifier: (entry: E) => number | string,
    canSelectEntry: (entry: E) => boolean
): ComponentType<SelectEntryDialogInnerProps<E, F>> => {
    const SelectEntryDialogComponent: FC<SelectEntryDialogInnerProps<E, F>> = ({
        t,
        classes,
        entries,
        title,
        defaultSearchQuery,
        defaultFilter,
        filterEntries,
        FilteringComponent,
        EntryRow,
        actionButtons,
        selectedEntry,
        onEntrySelect,
        open,
        onClose,
        entryTypeSingular,
        entryTypePlural,
        dataId,
    }) => {
        const [filter, setFilter] = useState<F | undefined>(defaultFilter);
        const [searchQuery, setSearchQuery] = useState<string | undefined>(defaultSearchQuery);
        const [filterIsOpened, setFilterIsOpened] = useState<boolean>(false);

        const handleSearchQueryChange = useCallback((sq: string) => setSearchQuery(sq), [setSearchQuery]);
        const handleSearchQueryClear = useCallback(() => setSearchQuery(undefined), [setSearchQuery]);
        const handleFilterChange = useCallback((f: F) => setFilter(f), [setFilter]);
        const handleFilterClose = useCallback(() => setFilterIsOpened(false), [setFilterIsOpened]);
        const handleFilterToggle = useCallback(
            () => setFilterIsOpened(!filterIsOpened),
            [setFilterIsOpened, filterIsOpened]
        );

        const visibleEntries = useMemo(() => {
            if (searchQuery || filter) {
                return filterEntries(entries, searchQuery, filter);
            }

            return entries;
        }, [filterEntries, entries, searchQuery, filter]);

        const searchBarOverriddenClasses = useMemo(
            () => ({
                root: classes.search,
                searchIcon: classes.searchIcon,
                closeIcon: classes.closeIcon,
                input: classes.searchInput,
            }),
            [classes]
        );

        const toggleFilterElement = FilteringComponent && (
            <TitledIconButton
                key="toggle-filter"
                title={t(filterIsOpened ? 'hide-filter' : 'show-filter')}
                color="inherit"
                onClick={handleFilterToggle}
                data-id="toggle-filter"
            >
                <FilterList />
            </TitledIconButton>
        );

        const selectedEntryIdentifier = selectedEntry && getEntryIdentifier(selectedEntry);

        const entriesList = useMemo(() => {
            return visibleEntries.map((entry) => {
                const entryIdentifier = getEntryIdentifier(entry);
                const entryIsSelected = entryIdentifier === selectedEntryIdentifier;
                const entryIsSelectable = canSelectEntry(entry);

                const handleToggleEntrySelect = () => {
                    if (entryIsSelectable) {
                        onEntrySelect(entryIsSelected ? undefined : entry);
                    }
                };

                const itemClasses = classNames(classes.item, {
                    [classes.selectedItem]: entryIsSelected,
                    [classes.disabledItem]: !entryIsSelectable,
                });

                const entryRowContainerClasses = classNames({
                    [classes.disabled]: !entryIsSelectable,
                });

                return (
                    <div
                        key={entryIdentifier}
                        className={itemClasses}
                        onClick={handleToggleEntrySelect}
                        data-id={`item-${entryIdentifier}`}
                    >
                        <Checkbox
                            className={classes.itemInput}
                            checked={entryIsSelected}
                            disabled={!entryIsSelectable}
                            data-id="checkbox"
                        />

                        <div className={entryRowContainerClasses}>
                            <EntryRow entry={entry} />
                        </div>
                    </div>
                );
            });
        }, [EntryRow, visibleEntries, selectedEntryIdentifier, onEntrySelect, classes]);

        const filterSheetElement = useMemo(() => {
            return (
                filterIsOpened &&
                FilteringComponent && (
                    <FilterSheet onClose={handleFilterClose} classes={{ root: classes.filter }}>
                        <FilteringComponent filter={filter} onFilterStateChange={handleFilterChange} />
                    </FilterSheet>
                )
            );
        }, [classes, filterIsOpened, FilteringComponent, handleFilterClose, filter, handleFilterChange]);

        const selectionDetailsElement = (
            <Typography data-id="selected-entries" variant="caption">
                <SelectionDetails
                    entryType={entries.length === 1 ? entryTypeSingular : entryTypePlural}
                    numberOfSelectedEntries={selectedEntry ? 1 : 0}
                    numberOfMatchingEntries={searchQuery || filter ? visibleEntries.length : undefined}
                    numberOfEntries={entries.length}
                />
            </Typography>
        );

        return (
            <WidgetDialog
                open={open}
                onClose={onClose}
                title={title}
                headerActions={[toggleFilterElement]}
                leftFooterAdornment={selectionDetailsElement}
                dialogActions={actionButtons}
                testId={dataId}
            >
                <div className={classes.body}>
                    <div className={classes.content}>
                        <Search
                            classes={searchBarOverriddenClasses}
                            onSearchQueryChange={handleSearchQueryChange}
                            clearSearchQuery={handleSearchQueryClear}
                        />
                        <Divider />
                        <div className={classes.items}>{entriesList}</div>
                    </div>
                    {filterSheetElement}
                </div>
            </WidgetDialog>
        );
    };

    return SelectEntryDialogComponent;
};
