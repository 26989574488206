import type { Dispatch } from 'redux';

import type { DisplayUserPreferences } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneAssetSelectorUserPreferences } from '~/components/SceneAssetSelector';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import type { NumericDictionary } from '~/libs/utility';
import { throttle } from '~/libs/utility';
import { reportError } from '~/reporting';
import type {
    ConversationSummary,
    DisplayUserPreferencesVehicleDisplayFormat,
    ResolvedConversationSummary,
    Securables,
    Vehicle,
    VehicleMessagingCapabilities,
} from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import {
    applyPendingUpdatesAction,
    openConversationAction,
    retrieveConversationsAction,
    retrieveLatestWorkflowFormDefinitionsAction,
    retrieveWorkflowFormDefinitionAction,
    updateConversationsAction,
} from '../../data';
import type { ConversationUserPreferences } from '../../preferences';
import type { InjectedUserMessagingProfilesProps } from '../EnsureUserMessagingProfiles';

import type { ConversationsSummaryWidgetProps, ConversationsSummaryWidgetReduxProps } from './component';
import type { ConversationSummaryEntry } from './conversationSummaryEntry';
import { changeSearchQueryAction, clearSearchQueryAction } from './data';
import { filterConversationSummaryEntries } from './filterConversationSummaryEntries';
import { filterConversationSummaryForSelectedVehicle } from './filterConversationSummaryForSelectedVehicle';
import { filterOnlyUnreadConversations } from './filterOnlyUnreadConversations';
import type { ConversationsSummaryWidgetUserPreferences } from './preferences';
import { CONVERSATIONSSUMMARYWIDGET_USERPREFERENCES_KEY } from './preferences';
import type { ConversationsSummaryWidgetStoreState } from './reducers';
import { buildConversationSummaryEntries } from './redux.buildConversationSummaryEntries';

export const autoUpdateThrottleTimeout = 2000;

const buildConversationSummaryEntriesMemoized = memoizeOne(buildConversationSummaryEntries);
const filterConversationSummaryEntriesMemoized = memoizeOne(filterConversationSummaryEntries);
const filterConversationSummaryForSelectedVehicleMemoized = memoizeOne(filterConversationSummaryForSelectedVehicle);
const filterOnlyUnreadConversationsMemoized = memoizeOne(filterOnlyUnreadConversations);

export interface ConversationSummaryWidgetStateProps {
    conversationSummaryEntries: ConversationSummaryEntry[];
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat;
    isMinimized: boolean;
    totalUnreadTextMessageCount: number;
    totalUnreadWorkflowMessageCount: number;
    hasVehiclesAvailable: boolean;
    hasSearchableConversations: boolean;
    loading: boolean;
    searchQuery?: string;
    sendTextMessagesAllowed: boolean;
    showOnlyUnreadConversations: boolean;
    sendWorkflowMessageAllowed: boolean;
    showDeletedMessages: boolean;
}

export interface ConversationSummaryWidgetDispatchProps {
    getConversations: (includeDeleted: boolean) => void;
    updateConversations: (conversation: ConversationSummary) => void;
    changeMinimizedState: (isMinimized: boolean) => void;
    openConversation: (vehicleId: number) => void;
    changeSearchQuery: (searchQuery: string) => void;
    clearSearchQuery: () => void;
    retrieveLatestWorkflowFormDefinitions: () => void;
    retrieveWorkflowFormDefinition: (formId: number) => void;
    changeShowOnlyUnreadConversations: (value: boolean) => void;
}

export const mapStateToProps = (
    vehiclesHash: NumericDictionary<Vehicle>,
    conversationsWidgetState: ConversationsSummaryWidgetStoreState,
    conversationSummaryWidgetPreferences: ConversationsSummaryWidgetUserPreferences,
    predefinedUserPreferences: DisplayUserPreferences,
    assetSelectorPreferences: SceneAssetSelectorUserPreferences,
    conversationUserPreferences: ConversationUserPreferences,
    securables: Securables,
    vehicleMessagingCapabilities: NumericDictionary<VehicleMessagingCapabilities>
): ConversationSummaryWidgetStateProps => {
    let conversationSummaryList: ResolvedConversationSummary[] = Object.values(
        conversationsWidgetState.conversations.data
    );
    const hasVehiclesAvailable = !!conversationSummaryList.length;

    if (conversationSummaryWidgetPreferences.showOnlySelectedVehicles) {
        conversationSummaryList = filterConversationSummaryForSelectedVehicleMemoized(
            conversationSummaryList,
            assetSelectorPreferences.selectedAssetIds
        );
    }

    if (conversationSummaryWidgetPreferences.showOnlyUnreadConversations) {
        conversationSummaryList = filterOnlyUnreadConversationsMemoized(conversationSummaryList);
    }

    const totalUnreadTextMessageCount = conversationSummaryList.reduce((a, b) => a + b.unreadTextMessageCount, 0);
    const totalUnreadWorkflowMessageCount = conversationSummaryList.reduce(
        (a, b) => a + b.unreadWorkflowMessageCount,
        0
    );

    const { vehicleDisplayFormat } = predefinedUserPreferences;

    const conversationSummaryEntries = buildConversationSummaryEntriesMemoized(
        conversationSummaryList,
        vehiclesHash,
        vehicleMessagingCapabilities,
        vehicleDisplayFormat
    );

    const { searchQuery } = conversationsWidgetState;
    const filteredConversationSummaryEntries = filterConversationSummaryEntriesMemoized(
        searchQuery,
        conversationSummaryEntries,
        vehicleDisplayFormat
    );

    return {
        conversationSummaryEntries: filteredConversationSummaryEntries,
        vehicleDisplayFormat,
        isMinimized: conversationSummaryWidgetPreferences.isMinimized,
        totalUnreadTextMessageCount,
        totalUnreadWorkflowMessageCount,
        hasVehiclesAvailable,
        hasSearchableConversations: !!conversationSummaryList.length,
        loading: !conversationsWidgetState.conversations.fulfilled,
        searchQuery,
        sendTextMessagesAllowed: securables.messaging.sendTextMessage,
        showOnlyUnreadConversations: conversationSummaryWidgetPreferences.showOnlyUnreadConversations,
        showDeletedMessages:
            securables.messaging.viewDeletedMessages && conversationUserPreferences.showDeletedMessagesInConversations,
        sendWorkflowMessageAllowed: securables.messaging.sendWorkflowMessage,
    };
};

export interface ConversationSummaryWidgetReduxOwnProps
    extends ConversationsSummaryWidgetProps,
        InjectedUserMessagingProfilesProps,
        InjectedTranslationProps {}

export const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: ConversationSummaryWidgetReduxOwnProps
): ConversationSummaryWidgetDispatchProps => {
    const refreshThrottled = throttle(() => dispatch(applyPendingUpdatesAction()), autoUpdateThrottleTimeout);
    return {
        getConversations: (includeDeleted: boolean) => {
            dispatch(retrieveConversationsAction(ownProps.userConversationProfileId, includeDeleted)).catch(
                reportError
            );
        },
        updateConversations: (conversation: ConversationSummary) => {
            dispatch(updateConversationsAction(conversation));
            refreshThrottled();
        },
        changeMinimizedState: (isMinimized: boolean) => {
            dispatch(
                updateUserPreferencesAction(CONVERSATIONSSUMMARYWIDGET_USERPREFERENCES_KEY, {
                    isMinimized,
                })
            );
        },
        openConversation: (vehicleId?: number) => {
            dispatch(openConversationAction(vehicleId));
        },
        changeSearchQuery: (searchQuery: string) => {
            dispatch(changeSearchQueryAction(searchQuery));
        },
        clearSearchQuery: () => {
            dispatch(clearSearchQueryAction());
        },
        retrieveLatestWorkflowFormDefinitions: () => {
            dispatch(retrieveLatestWorkflowFormDefinitionsAction(ownProps.i18n.language));
        },
        retrieveWorkflowFormDefinition: (formId: number) => {
            dispatch(retrieveWorkflowFormDefinitionAction(formId, ownProps.i18n.language));
        },
        changeShowOnlyUnreadConversations: (showOnlyUnreadConversations: boolean) => {
            dispatch(
                updateUserPreferencesAction(CONVERSATIONSSUMMARYWIDGET_USERPREFERENCES_KEY, {
                    showOnlyUnreadConversations,
                })
            );
        },
    };
};

const toggleMinimizedStateMemoized = memoizeOne(
    (isMinimized: boolean, changeMinimizedState: (isMinimized: boolean) => void) => () => {
        changeMinimizedState(!isMinimized);
    }
);

const toggleShowOnlyUnreadConversationsStateMemoized = memoizeOne(
    (
        showOnlyUnreadConversations: boolean,
        changeShowOnlyUnreadConversations: (showOnlyUnreadConversations: boolean) => void
    ) =>
        () => {
            changeShowOnlyUnreadConversations(!showOnlyUnreadConversations);
        }
);

export const mergeProps = (
    stateProps: ConversationSummaryWidgetStateProps,
    {
        changeMinimizedState,
        changeShowOnlyUnreadConversations,
        ...restDispatchProps
    }: ConversationSummaryWidgetDispatchProps,
    ownProps: object
): ConversationsSummaryWidgetReduxProps => {
    return {
        ...stateProps,
        ...ownProps,
        ...restDispatchProps,
        toggleMinimizedState: toggleMinimizedStateMemoized(stateProps.isMinimized, changeMinimizedState),
        toggleShowOnlyUnreadConversationsState: toggleShowOnlyUnreadConversationsStateMemoized(
            stateProps.showOnlyUnreadConversations,
            changeShowOnlyUnreadConversations
        ),
    };
};
