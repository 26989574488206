import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { WorkflowMessageContentProps } from './models';

export type WorkflowMessageContentClassKey =
    | 'messageBox'
    | 'messageIcon'
    | 'messageText'
    | 'messageIconInfo'
    | 'quoted'
    | 'messageTitlePrimary'
    | 'messageTitleSecondary'
    | 'twoLineTextWrap'
    | 'iconTopAlignmentSecondaryText';

export const WorkflowMessageContentStyleRules: StyleRulesCallback<
    Theme,
    WorkflowMessageContentProps,
    WorkflowMessageContentClassKey
> = (theme: Theme) =>
    createStyles({
        messageBox: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.action.hover,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(0.5, 1),
            cursor: ({ onClick }) => (!onClick ? 'auto' : 'pointer'),
        },
        iconTopAlignmentSecondaryText: {
            alignSelf: 'self-start',
            margin: theme.spacing(1, 0),
        },
        messageIcon: {
            fontSize: 24,
            color: ({ isFailed, isDeleted }) =>
                isDeleted
                    ? alpha(theme.palette.text.secondary, 0.38)
                    : isFailed
                      ? theme.palette.error.main
                      : theme.palette.text.secondary,
        },
        messageIconInfo: {
            fontSize: 24,
            color: theme.palette.text.secondary,
        },
        messageText: {
            display: 'grid',
            margin: '0 10px',
        },
        twoLineTextWrap: {
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
        },
        messageTitlePrimary: {
            color: ({ isFailed, isDeleted }) =>
                isDeleted
                    ? alpha(theme.palette.text.primary, 0.38)
                    : isFailed
                      ? theme.palette.error.main
                      : theme.palette.text.primary,
            textDecoration: ({ isDeleted }) => (isDeleted ? 'line-through' : 'none'),
        },
        messageTitleSecondary: {
            textDecoration: ({ isDeleted }) => (isDeleted ? 'line-through' : 'none'),
            color: ({ isFailed, isMissingFormDefinition, isDeleted }) =>
                isDeleted
                    ? alpha(theme.palette.text.secondary, 0.38)
                    : isFailed || isMissingFormDefinition
                      ? alpha(theme.palette.error.main, 0.54)
                      : theme.palette.text.secondary,
        },
        quoted: {
            borderLeft: `4px solid ${theme.palette.primary.main}`,
            marginBottom: theme.spacing(0.25),
        },
    });
