/* eslint-disable import/no-duplicates */
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import hu from 'date-fns/locale/hu';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import pl from 'date-fns/locale/pl';
import ptBR from 'date-fns/locale/pt-BR';

const LOCALES_MAPPER = {
    'en-GB': enGB,
    'nl-NL': nl,
    'de-DE': de,
    'fr-FR': fr,
    'hu-HU': hu,
    'es-ES': es,
    'pl-PL': pl,
    'it-IT': it,
    'pt-BR': ptBR,
};

const TIME_PICKER_ERROR_MESSAGES = {
    invalidDate: 'date-time-field-invalid-time',
    minTime: 'date-time-picker-invalid-endTime',
    maxTime: 'date-time-picker-invalid-startTime',
    invalidTime: 'date-time-picker-invalid-time-interval',
};

export { LOCALES_MAPPER, TIME_PICKER_ERROR_MESSAGES };
