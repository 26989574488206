import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ModuleViewBarActionsClassKey = 'selected';

export const ModuleViewBarActionsStyleRules: StyleRulesCallback<Theme, {}, ModuleViewBarActionsClassKey> = (
    theme: Theme
) => {
    return createStyles({
        selected: {
            '&:hover': {
                backgroundColor: theme.palette.action.selectedBackground,
            },
            backgroundColor: theme.palette.action.selectedBackground,
        },
    });
};
