import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import { alphaColorToRgb } from './utils';

export type TableFixedColumnsCellClassKey = 'highlighted';

export const TableFixedColumnsCellStyleRules: StyleRulesCallback<Theme, {}, TableFixedColumnsCellClassKey> = (
    theme: Theme
) =>
    createStyles({
        highlighted: {
            backgroundColor: alphaColorToRgb(theme.palette.background.paper, theme.palette.action.hover),
        },
    });
