import { List } from '@mui/material';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection, SectionContentMessage } from '~/components/Sections';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type { Disposable } from '~/listeners';
import type { InjectedUserMessagingProfilesProps } from '~/modules/Communication';
import { reportError } from '~/reporting';
import type { ResolvedMessage } from '~/services/ApiClient';
import { ConversationNotification, ResourceFilter, createApiModel } from '~/services/ApiClient';
import { subscribeToNotificationsAndRetrieveTheData } from '~/services/SignalR';

import { LatestMessagesSectionContent } from './components/LatestMessagesSectionContent';

export interface LatestMessagesSectionProps {
    entry: MonitoringVehicleEntry;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

export interface StateProps {
    latestMessages: ResolvedMessage[];
    deletedMessageIds?: number[];
    loadingMessages: boolean;
    failedToLoadMessages: boolean;
    canViewMessages: boolean;
    dataWasNotRetrieved: boolean;
}

export interface DispatchProps {
    getLatestMessages: () => void;
    updateLatestMessages: (conversation: ConversationNotification) => void;
    openConversation: () => void;
    clearLatestMessages: () => void;
}

export interface LatestMessagesSectionInnerProps
    extends LatestMessagesSectionProps,
        StateProps,
        DispatchProps,
        InjectedUserMessagingProfilesProps,
        InjectedTranslationProps {}

export class LatestMessagesSectionComponent extends React.Component<
    LatestMessagesSectionInnerProps,
    LatestMessagesSectionProps
> {
    private disposableHandlers: Disposable[] = [];

    public async componentDidMount(): Promise<void> {
        if (!this.props.isCollapsed) {
            this.retrieveLatestMessagesAndSubscribeToConversation();
        }
    }

    public componentDidUpdate(prevProps: LatestMessagesSectionInnerProps): void {
        if (this.props.dataWasNotRetrieved && prevProps.isCollapsed && !this.props.isCollapsed) {
            this.retrieveLatestMessagesAndSubscribeToConversation();
        }

        if (prevProps.entry.vehicle.id !== this.props.entry.vehicle.id) {
            this.disposableHandlers.forEach(({ dispose }) => dispose());
            this.disposableHandlers = [];
            this.props.clearLatestMessages();
            if (!this.props.isCollapsed) {
                this.retrieveLatestMessagesAndSubscribeToConversation();
            }
        }
    }

    public componentWillUnmount(): void {
        this.disposableHandlers.forEach(({ dispose }) => dispose());
        this.props.clearLatestMessages();
    }

    public render(): JSX.Element {
        const { t } = this.props;

        const notAuthorizedToSeeMessagesElement = (
            <SectionContentMessage dataId="not-authorized-to-see-messages">
                {t('see-messages-for-vehicle-not-authorized')}
            </SectionContentMessage>
        );

        const sectionContent = this.props.canViewMessages ? (
            <LatestMessagesSectionContent
                latestMessagesSortedDesc={this.props.latestMessages}
                deletedMessageIds={this.props.deletedMessageIds}
                loadingMessages={this.props.loadingMessages}
                failedToLoadMessages={this.props.failedToLoadMessages}
                getLatestMessages={this.props.getLatestMessages}
                openConversation={this.props.openConversation}
            />
        ) : (
            notAuthorizedToSeeMessagesElement
        );

        return (
            <ReorderableSection
                dataId="last-messages"
                title={t('last-messages')}
                dragHandleElement={this.props.dragHandleElement}
                isCollapsed={this.props.isCollapsed}
                toggleCollapsed={this.props.toggleCollapsed}
            >
                <List disablePadding>{sectionContent}</List>
            </ReorderableSection>
        );
    }

    private retrieveLatestMessagesAndSubscribeToConversation = () => {
        if (!this.props.canViewMessages) {
            return;
        }

        subscribeToNotificationsAndRetrieveTheData(
            `conversations/${this.props.entry.vehicle.id}`,
            ConversationNotification.fromJS,
            (notification: ConversationNotification) => {
                this.props.updateLatestMessages(notification);
            },
            () => {
                this.props.getLatestMessages();
            },
            createApiModel(ResourceFilter, { conversationProfileId: this.props.defaultConversationProfileId })
        ).then((disposable: Disposable) => {
            this.disposableHandlers.push(disposable);
        }, reportError);
    };
}
