import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';
import { settingsStoreStateSelector, staticDataStoreStateSelector } from '~/selectors';

import type { RecipientsListInnerProps, RecipientsListProps } from './component';
import { RecipientsListComponent } from './component';
import { mapStateToProps } from './redux';
import { RecipientsListStyleRules } from './styles';

export const RecipientsList = compose<RecipientsListInnerProps, RecipientsListProps>(
    setDisplayName('RecipientsList'),
    withTranslation(),
    connect(createSelector(staticDataStoreStateSelector, settingsStoreStateSelector, mapStateToProps)),
    withStyles(RecipientsListStyleRules),
    withDisplayPreferences()
)(RecipientsListComponent);
