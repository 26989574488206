import { kmphToMps, mpsToMph } from '@fv/converters';

import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { DerivedClassification, ResolvedVehicleType } from '~/services/ApiClient';
import { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';

import { mapVehicleTypeAxlesCombo } from './formDataMappers';
import type { FieldValues } from './models';
import { defaultFieldValues } from './models';

const kmphToMph = (speed: number): number => mpsToMph(kmphToMps(speed));

export const mergeDefaultValues = (
    typeAxlesDerivedClassifications: NumericDictionary<DerivedClassification>,
    unitSystem: DisplayUserPreferencesUnitSystem,
    vehicleTypeEntry?: ResolvedVehicleType
): FieldValues => {
    const defaultValues = defaultFieldValues(typeAxlesDerivedClassifications);

    const speedLimitMotorway = vehicleTypeEntry?.speedLimit?.motorway;
    const speedLimitRoad = vehicleTypeEntry?.speedLimit?.road;
    const speedLimitCity = vehicleTypeEntry?.speedLimit?.city;
    let convertedSpeedLimitMotorway: number | undefined;
    let convertedSpeedLimitRoad: number | undefined;
    let convertedSpeedLimitCity: number | undefined;

    const convertedWeight =
        !isUndefined(vehicleTypeEntry) && !isUndefined(vehicleTypeEntry?.weight)
            ? vehicleTypeEntry.weight / 1000.0
            : defaultValues.weight;
    const derivedTypeId =
        !isUndefined(vehicleTypeEntry?.type) && !isUndefined(vehicleTypeEntry?.axles)
            ? (mapVehicleTypeAxlesCombo(
                  typeAxlesDerivedClassifications,
                  vehicleTypeEntry?.type?.id,
                  vehicleTypeEntry?.axles?.id
              ).id as number)
            : defaultValues.type;

    switch (unitSystem) {
        case DisplayUserPreferencesUnitSystem.Metric:
            convertedSpeedLimitMotorway = !isUndefined(speedLimitMotorway)
                ? speedLimitMotorway
                : defaultValues.speedLimitMotorway;
            convertedSpeedLimitRoad = !isUndefined(speedLimitRoad) ? speedLimitRoad : defaultValues.speedLimitRoad;
            convertedSpeedLimitCity = !isUndefined(speedLimitCity) ? speedLimitCity : defaultValues.speedLimitCity;
            break;
        case DisplayUserPreferencesUnitSystem.Imperial:
            convertedSpeedLimitMotorway = !isUndefined(speedLimitMotorway)
                ? kmphToMph(speedLimitMotorway)
                : kmphToMph(defaultValues.speedLimitMotorway ?? 0);
            convertedSpeedLimitRoad = !isUndefined(speedLimitRoad)
                ? kmphToMph(speedLimitRoad)
                : kmphToMph(defaultValues.speedLimitRoad ?? 0);
            convertedSpeedLimitCity = !isUndefined(speedLimitCity)
                ? kmphToMph(speedLimitCity)
                : kmphToMph(defaultValues.speedLimitCity ?? 0);
            break;
        default:
            throw new Error('Unknown unit system');
    }

    return {
        name: vehicleTypeEntry?.name ?? defaultValues.name,
        description: vehicleTypeEntry?.description ?? defaultValues.description,
        height: vehicleTypeEntry?.dimensions?.height ?? defaultValues.height,
        width: vehicleTypeEntry?.dimensions?.width ?? defaultValues.width,
        length: vehicleTypeEntry?.dimensions?.length ?? defaultValues.length,
        weight: Number(convertedWeight?.toFixed(2)),
        type: derivedTypeId,
        emissionClass: vehicleTypeEntry?.emissionClass?.id ?? defaultValues.emissionClass,
        hazardousGoods: vehicleTypeEntry?.hazardousGoods ?? defaultValues.hazardousGoods,
        speedLimitMotorway: Number(convertedSpeedLimitMotorway?.toFixed(0)),
        speedLimitRoad: Number(convertedSpeedLimitRoad?.toFixed(0)),
        speedLimitCity: Number(convertedSpeedLimitCity?.toFixed(0)),
    };
};
