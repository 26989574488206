import { kmphToMps, mpsToKmph, mpsToMph } from '@fv/converters';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import type { CommonSpeedProps, UnitSystemFormatterOptions, UnitSystemUtils } from './unitSystemUtils';

const formatterSpeed = ({ value, unit, transform, precision = 0 }: CommonSpeedProps) => {
    if (isUndefined(value)) {
        return '';
    }
    const mpsValue = kmphToMps(value);

    return `${transform(mpsValue).toFixed(precision)} ${unit}`;
};

const transformSpeed = ({ value, transform, precision = 0 }: CommonSpeedProps) => {
    if (isUndefined(value)) {
        return undefined;
    }

    const mpsValue = kmphToMps(value);

    return Number(transform(mpsValue).toFixed(precision));
};

const getImperialUtils = (t: SingleTFunction) => {
    const unit = t('unit-mph');

    return {
        unit,
        formatter: (value: number, options?: UnitSystemFormatterOptions) =>
            formatterSpeed({ value, unit, precision: options?.precision, transform: mpsToMph }),
        converter: (value: number, options?: UnitSystemFormatterOptions) =>
            transformSpeed({ value, precision: options?.precision, transform: mpsToMph }),
    };
};

const getMetricUtils = (t: SingleTFunction) => {
    const unit = t('unit-kmph');

    return {
        unit,
        formatter: (value: number, options?: UnitSystemFormatterOptions) =>
            formatterSpeed({ value, unit, precision: options?.precision, transform: mpsToKmph }),
        converter: (value: number, options?: UnitSystemFormatterOptions) =>
            transformSpeed({ value, precision: options?.precision, transform: mpsToKmph }),
    };
};

export const getSpeedUnitSystemUtils = memoizeOne(
    (t: SingleTFunction, unitSystem: DisplayUserPreferencesUnitSystem): UnitSystemUtils => {
        switch (unitSystem) {
            case DisplayUserPreferencesUnitSystem.Imperial:
                return getImperialUtils(t);
            case DisplayUserPreferencesUnitSystem.Metric:
                return getMetricUtils(t);
            default:
                throw new Error('Unknown unit system.');
        }
    }
);
