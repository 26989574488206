import type { Dispatch } from 'redux';

import { panToAssetAction } from '~/components/SceneMap';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import type { Securables } from '~/services/ApiClient';

import type { DispatchProps, StateProps } from './models';
import { DETAILSPANE_VEHICLE_USERPREFERENCES_KEY } from './preferences';

export const mapStateToProps = (securables: Securables): StateProps => ({ securables });

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    changeMiniMapZoomLevel: (zoomLevel: number) => {
        dispatch(updateUserPreferencesAction(DETAILSPANE_VEHICLE_USERPREFERENCES_KEY, { miniMapZoomLevel: zoomLevel }));
    },
    changeCollapsedTemperatureCompartments: (collapsedTemperatureCompartments: number[]) => {
        dispatch(
            updateUserPreferencesAction(DETAILSPANE_VEHICLE_USERPREFERENCES_KEY, { collapsedTemperatureCompartments })
        );
    },
    panToVehicle: () => {
        dispatch(
            panToAssetAction(
                'mapping',
                'locate-from-vehicle-details',
                'Locate on map from vehicle details',
                'location-section'
            )
        );
    },
});
