import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { VirtualTableCellInnerProps, VirtualTableCellProps } from './component';
import { VirtualTableCellComponent } from './component';
import { VirtualTableCellStyleRules } from './styles';

export const VirtualTableCell = compose<VirtualTableCellInnerProps, VirtualTableCellProps>(
    setDisplayName('VirtualTableCell'),
    withStyles(VirtualTableCellStyleRules)
)(VirtualTableCellComponent);
