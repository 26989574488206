import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { SectionListItemInnerProps, SectionListItemProps } from './component';
import { SectionListItemComponent } from './component';
import { SectionListItemClassStyleRules } from './styles';

export const SectionListItem = compose<SectionListItemInnerProps, SectionListItemProps>(
    setDisplayName('SectionListItem'),
    withStyles(SectionListItemClassStyleRules)
)(SectionListItemComponent);
