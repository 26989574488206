import type { NumericDictionary } from '~/libs/utility';
import type { DeviceType, Vehicle, VehicleCategory, VehicleEvent, VehicleType } from '~/services/ApiClient';

import type { HistoryVehicleEntry } from './models';
import { buildHistoryVehicleEntry } from './redux.buildHistoryVehicleEntry';

export const buildHistoryVehicleEntries = (
    vehiclesHash: NumericDictionary<Vehicle>,
    vehiclesEvents: VehicleEvent[],
    deviceTypes: NumericDictionary<DeviceType>,
    vehicleCategories: NumericDictionary<VehicleCategory>,
    vehicleTypes: NumericDictionary<VehicleType>
): HistoryVehicleEntry[] =>
    vehiclesEvents.map((vehicleEvent: VehicleEvent) =>
        buildHistoryVehicleEntry(vehiclesHash, vehicleEvent, deviceTypes, vehicleCategories, vehicleTypes)
    );
