import { CircularProgress } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

import { MarkAllAsReadIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledIconButton } from '~/components/TitledIconButton';

import { ConfirmMarkAllMessagesAsReadDialog } from '../ConfirmMarkAllMessagesAsRead';

import type { MarkAllMessagesAsReadButtonClassKey } from './styles';
import { spinnerSize } from './styles';

export interface MarkAllMessagesAsReadButtonProps {
    unreadMessageCount: number;
    markMessagesAsReadAllowed: boolean;
    warnOnMarkAllMessagesAsRead: boolean;
    markingAllMessagesAsRead: boolean;
    markAllMessagesAsRead: (dontWarnMeAnymore?: boolean) => void;
}

export interface MarkAllMessagesAsReadButtonInnerProps
    extends MarkAllMessagesAsReadButtonProps,
        WithStyles<MarkAllMessagesAsReadButtonClassKey>,
        InjectedTranslationProps {}

export interface MarkAllMessagesAsReadButtonState {
    showMarkAllMessagesAsReadConfirmationDialog: boolean;
}

export class MarkAllMessagesAsReadButtonComponent extends React.Component<
    MarkAllMessagesAsReadButtonInnerProps,
    MarkAllMessagesAsReadButtonState
> {
    constructor(props: MarkAllMessagesAsReadButtonInnerProps) {
        super(props);
        this.state = { showMarkAllMessagesAsReadConfirmationDialog: false };
    }

    public render(): JSX.Element {
        const { unreadMessageCount, markingAllMessagesAsRead, t } = this.props;

        const icon = markingAllMessagesAsRead ? (
            <CircularProgress size={spinnerSize} data-id="marking-all-messages-as-read-spinner" />
        ) : (
            <MarkAllAsReadIcon fontSize="inherit" />
        );

        // rendered like this instead of using open prop
        // in order to solve tooltip not disappearing after cancel or confirm issue
        const markAllMessagesAsReadConfirmationDialog = this.state.showMarkAllMessagesAsReadConfirmationDialog && (
            <ConfirmMarkAllMessagesAsReadDialog
                unreadMessagesCount={unreadMessageCount}
                onMarkAllMessagesAsRead={this.handleMarkAllMessagesAsReadConfirmation}
                onCancel={this.handleMarkAllMessagesAsReadCancellation}
                open
            />
        );

        const isDisabled = !this.props.markMessagesAsReadAllowed || this.props.unreadMessageCount === 0;

        return (
            <div onClick={this.handleRootClick} className={classNames({ [this.props.classes.disabled]: isDisabled })}>
                <TitledIconButton
                    disabled={isDisabled}
                    className={this.props.classes.root}
                    title={t(this.getTooltipTranslationKey())}
                    onClick={!this.props.markingAllMessagesAsRead ? this.handleClick : undefined}
                    data-id="mark-all-messages-as-read"
                    closeTooltipOnClick
                >
                    {icon}
                </TitledIconButton>
                {markAllMessagesAsReadConfirmationDialog}
            </div>
        );
    }

    private handleRootClick = (event: React.SyntheticEvent) => {
        event.stopPropagation();
    };

    private handleClick = (event: React.SyntheticEvent) => {
        event.stopPropagation();

        if (this.props.warnOnMarkAllMessagesAsRead) {
            this.setState({ showMarkAllMessagesAsReadConfirmationDialog: true });
        } else {
            this.props.markAllMessagesAsRead();
        }
    };

    private getTooltipTranslationKey = (): string => {
        if (!this.props.markMessagesAsReadAllowed) {
            return 'change-read-status-not-authorized';
        }

        if (this.props.unreadMessageCount === 0) {
            return 'no-messages-to-mark-as-read';
        }

        if (!this.props.markingAllMessagesAsRead) {
            return 'mark-all-messages-as-read';
        }

        if (this.props.markingAllMessagesAsRead) {
            return 'changing-read-status';
        }

        return '';
    };

    private handleMarkAllMessagesAsReadConfirmation = (dontWarnMeAnymore?: boolean) => {
        this.props.markAllMessagesAsRead(dontWarnMeAnymore);
        this.setState({ showMarkAllMessagesAsReadConfirmationDialog: false });
    };

    private handleMarkAllMessagesAsReadCancellation = () => {
        this.setState({ showMarkAllMessagesAsReadConfirmationDialog: false });
    };
}
