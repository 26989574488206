import Tooltip from '@mui/material/Tooltip';
import type { LocationDescriptor } from 'history';
import { bindHover, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import Menu from 'material-ui-popup-state/HoverMenu';
import type { FC } from 'react';
import { useMemo } from 'react';
import { matchPath, useLocation, useRouteMatch } from 'react-router-dom';

import { flatten, isUndefined } from '~/libs/utility';

import type { MenuItem } from '../../../../../../models';

import { ItemIconContainer, MenuItemTitle, NestedMenuContainer, StyledLink, StyledNavLink } from './styles';

const getNestedPaths = (menuItem: MenuItem): LocationDescriptor[] => {
    if (menuItem.nestedItems) {
        return flatten(menuItem.nestedItems.map((x) => x.to ?? getNestedPaths(x)));
    }

    return [];
};

const isCurrentPath = (nestedPaths: LocationDescriptor[], pathname: string): boolean => {
    return nestedPaths.some((x) =>
        matchPath(pathname, {
            path: x.toString(),
            exact: false,
            strict: false,
        })
    );
};

export interface MenuBarItemProps {
    menuItem: MenuItem;
    parentMenuItem?: MenuItem;
}

const MenuBarItem: FC<MenuBarItemProps> = (props) => {
    const { menuItem, parentMenuItem } = props;

    const { pathname } = useLocation();
    const match = useRouteMatch({ path: menuItem.to?.toString(), strict: true, sensitive: true });
    const popupState = usePopupState({ popupId: 'demoMenu', variant: 'popover' });
    const hasNestedItems = useMemo(() => !isUndefined(menuItem.nestedItems), [menuItem.nestedItems]);

    const itemTitle = parentMenuItem ? undefined : menuItem.title;

    const linkContent = (() => (
        <>
            <ItemIconContainer isActive={!!match || isCurrentPath(getNestedPaths(menuItem), pathname)}>
                {menuItem.icon}
            </ItemIconContainer>
            {parentMenuItem && <MenuItemTitle>{menuItem.title}</MenuItemTitle>}
        </>
    ))();

    return (
        <>
            <Tooltip open={hasNestedItems ? false : undefined} title={itemTitle} placement="right">
                {menuItem.href && !menuItem.nestedItems ? (
                    <StyledLink
                        target="_blank"
                        rel="noopener"
                        href={menuItem.href}
                        color="inherit"
                        data-testid={`module-button:${menuItem.key}`}
                        onClick={() => {
                            menuItem.onClick?.();
                        }}
                        parentMenuItem={!!parentMenuItem}
                    >
                        {linkContent}
                    </StyledLink>
                ) : (
                    <StyledNavLink
                        {...(menuItem.nestedItems && bindHover(popupState))}
                        color="inherit"
                        data-testid={`module-button:${menuItem.key}`}
                        to={menuItem.to ?? '#'}
                        parentMenuItem={!!parentMenuItem}
                        hasChildren={!!menuItem.nestedItems?.length}
                    >
                        {linkContent}
                    </StyledNavLink>
                )}
            </Tooltip>

            {menuItem.nestedItems && (
                <Menu
                    {...bindMenu(popupState)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    MenuListProps={{ disablePadding: true }}
                >
                    <div>
                        {menuItem.nestedItems.map((nestedItem) => (
                            <NestedMenuContainer key={`nested-menu:${nestedItem.key}`} onClick={popupState.close}>
                                <MenuBarItem menuItem={nestedItem} parentMenuItem={menuItem} />
                            </NestedMenuContainer>
                        ))}
                    </div>
                </Menu>
            )}
        </>
    );
};

MenuBarItem.displayName = 'MenuBarItem';
export { MenuBarItem };
