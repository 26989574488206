import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    list: { padding: 0, margin: 0 },
    item: {
        margin: 0,
        height: theme.spacing(5),
        paddingLeft: (props: { indentLevel: number }) => theme.spacing(1.5 + (props.indentLevel + 1) * 4),
    },
    group: {
        backgroundColor: theme.palette.background.paper,
        margin: 0,
        gap: theme.spacing(),
        height: theme.spacing(5),
    },
}));

export { useStyles };
