import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type {
    ConnectedTrailerTemperaturesSectionInnerProps,
    ConnectedTrailerTemperaturesSectionProps,
} from './component';
import { ConnectedTrailerTemperaturesSectionComponent } from './component';

export const ConnectedTrailerTemperaturesSection = compose<
    ConnectedTrailerTemperaturesSectionInnerProps,
    ConnectedTrailerTemperaturesSectionProps
>(
    setDisplayName('ConnectedTrailerTemperaturesSection'),
    withTranslation()
)(ConnectedTrailerTemperaturesSectionComponent);
