import { isUndefined } from '~/libs/utility';

export const getUserNameInitials = (userName: string, userFullName?: string): string => {
    if (isUndefined(userFullName) || userFullName.trim() === '') {
        return userName.trim().charAt(0).toUpperCase();
    }

    const names = userFullName.trim().replace(/  +/g, ' ').split(' ');

    if (names.length === 1) {
        return names[0].charAt(0).toUpperCase();
    }

    return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`.toUpperCase();
};
