import * as storage from 'localforage';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import type { PersistConfig } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import { createSelector } from 'reselect';

import { withTranslation } from '~/components/LanguageSelector';
import { protectedComponentStoreStateSelector } from '~/selectors';

import type { LoginInnerProps, LoginProps, LoginStateProps } from './component';
import { LoginComponentFactory } from './component';
import { CustomerCareLoginForm } from './components/CustomerCareLoginForm';
import { UserLoginForm } from './components/UserLoginForm';
import type { LoginStoreState } from './reducers';
import { loginReducer as reducer } from './reducers';
import { mapStateToProps } from './redux';

const stateToPropsMapper = createSelector(protectedComponentStoreStateSelector, mapStateToProps);

export type { LoginStoreState } from './reducers';

export const UserLogin = compose<LoginInnerProps, LoginProps>(
    setDisplayName('UserLogin'),
    connect<LoginStateProps>(stateToPropsMapper),
    withTranslation()
)(LoginComponentFactory(UserLoginForm));

export const CustomerCareLogin = compose<LoginInnerProps, LoginProps>(
    setDisplayName('CustomerCareLogin'),
    connect<LoginStateProps>(stateToPropsMapper),
    withTranslation()
)(LoginComponentFactory(CustomerCareLoginForm));

const config: PersistConfig<LoginStoreState> = {
    key: 'identity',
    storage,
    whitelist: ['identity'],
};

export const loginReducer = persistReducer(config, reducer);
