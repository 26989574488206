import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { TrailerMarkerInnerProps, TrailerMarkerProps } from './component';
import { TrailerMarkerComponent } from './component';
import { TrailerMarkerStyleRules } from './styles';

export type { TrailerMarkerProps } from './component';

export const TrailerMarker = compose<TrailerMarkerInnerProps, TrailerMarkerProps>(
    setDisplayName('TrailerMarker'),
    withStyles(TrailerMarkerStyleRules)
)(TrailerMarkerComponent);
