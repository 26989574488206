import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ActivitiesBarInnerProps, ActivitiesBarProps } from './component';
import { ActivitiesBarComponent } from './component';
import { ActivitiesBarStyleRules } from './styles';

export const ActivitiesBar = compose<ActivitiesBarInnerProps, ActivitiesBarProps>(
    setDisplayName('ActivitiesBar'),
    withStyles(ActivitiesBarStyleRules)
)(ActivitiesBarComponent);
