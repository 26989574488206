import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import { TrailerInfoBox as _TrailerInfoBox } from './component';
import type { TrailerInfoBoxInnerProps, TrailerInfoBoxProps } from './models';
import { InfoBoxStyleRules } from './styles';

export const TrailerInfoBox = compose<TrailerInfoBoxInnerProps, TrailerInfoBoxProps>(
    setDisplayName('InfoBox'),
    withStyles(InfoBoxStyleRules),
    withTranslation()
)(_TrailerInfoBox);
