import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SelectSettingItemClassKeys = 'action' | 'selectedDisabled';

export const SelectSettingItemStyleRules: StyleRulesCallback<Theme, {}, SelectSettingItemClassKeys> = (theme) =>
    createStyles({
        action: {
            width: 200,
        },
        selectedDisabled: {
            color: theme.palette.text.disabled,
        },
    });
