import { createAction } from 'redux-actions';

import type { Identity } from '~/data/authentication';

import type { SaveIdentityPayload } from './actionTypes';
import { ActionTypeKeys } from './actionTypes';

function saveIdentityExecutor(identity: Identity | undefined): SaveIdentityPayload {
    return { identity };
}

export const saveIdentityAction = createAction(ActionTypeKeys.SAVEIDENTITY, saveIdentityExecutor);
