import { Tooltip } from '@mui/material';
import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import type { Classification } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { IconFormatter } from './components/Icon';

export interface CompartmentStatusFormatterProps {
    t: SingleTFunction;
    value?: Classification;
}

export interface CompartmentStatusFormatterInnerProps extends CompartmentStatusFormatterProps {}

const CompartmentStatusFormatter: React.FunctionComponent<CompartmentStatusFormatterInnerProps> = ({ value, t }) => {
    return value ? (
        <Tooltip title={formatClassification(t, ClassificationType.CompartmentStatus, value)}>
            <span data-id={`compartment-status:${value.key}`}>
                <IconFormatter value={value.key} />
            </span>
        </Tooltip>
    ) : null;
};
CompartmentStatusFormatter.displayName = 'CompartmentStatusFormatter';

export { CompartmentStatusFormatter };
