import Assignment from '@mui/icons-material/Assignment';
import { Component, createRef } from 'react';

import { TitledIconButton } from '~/components/TitledIconButton';
import type { SendMessageOptions, TextMessageBody } from '~/services/ApiClient';

import type { SendTextMessageFormElement } from '../../../../../SendTextMessageForm';
import { SendTextMessageForm } from '../../../../../SendTextMessageForm';

import type { SendMessageBoxInnerProps } from './models';
import { maxInputRows } from './styles';

export class SendMessageBoxComponent extends Component<SendMessageBoxInnerProps> {
    private textMessageFormRef = createRef<SendTextMessageFormElement>();

    public componentDidMount(): void {
        this.handleShowFocusInput();
    }

    public componentDidUpdate(): void {
        this.handleShowFocusInput();
    }

    public render(): JSX.Element {
        const {
            classes,
            t,
            disabled,
            messageText,
            changeMessage,
            sendWorkflowMessagesAllowed,
            closeConversation,
            onCloseRejected,
            shouldClose,
        } = this.props;

        const tooltippedSendWorkflowMessageFab =
            sendWorkflowMessagesAllowed && this.props.supportWorkflowMessaging ? (
                <TitledIconButton
                    title={t('open-send-workflow-message-page')}
                    className={classes.openWorkflowFab}
                    onClick={this.handleOpenSendWorkflowMessageForm}
                    disabled={!sendWorkflowMessagesAllowed}
                    data-id="send-workflow-message"
                >
                    <Assignment fontSize="inherit" className={classes.openWorkflowSendIcon} />
                </TitledIconButton>
            ) : undefined;

        return (
            <SendTextMessageForm
                ref={this.textMessageFormRef}
                messageText={messageText}
                onMessageChange={changeMessage}
                onSend={this.handleSendMessage}
                onSendOptionsWarning={this.handleOnSendOptionsWarning}
                disabledSendFab={tooltippedSendWorkflowMessageFab}
                disabled={disabled}
                maxInputRows={maxInputRows}
                onClose={closeConversation}
                onCloseRejected={onCloseRejected}
                shouldClose={shouldClose}
                disabledTyping={!this.props.supportTextMessaging}
            />
        );
    }

    private handleShowFocusInput = () => {
        if (this.props.shouldFocusInput) {
            if (this.textMessageFormRef.current) {
                this.textMessageFormRef.current.focus();
            }
            this.props.clearInputFocus();
        }
    };

    private handleOpenSendWorkflowMessageForm = () => {
        if (this.props.openSendWorkflowMessageForm) {
            this.props.openSendWorkflowMessageForm();
        }
    };

    private handleSendMessage = (messageBody: TextMessageBody, options: SendMessageOptions) => {
        this.props.sendMessage(messageBody, options);
        this.props.changeMessage('');

        if (this.textMessageFormRef.current) {
            this.textMessageFormRef.current.focus();
        }
    };

    private handleOnSendOptionsWarning = (messageText?: string, canDismiss?: boolean) => {
        this.props.onSendOptionsWarning(messageText, canDismiss);
    };
}
