import { styled } from '@mui/material';
import _Backdrop from '@mui/material/Backdrop';
import _CircularProgress from '@mui/material/CircularProgress';

const MessageContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
}));

const Backdrop = styled(_Backdrop)(({ theme }) => ({
    position: 'absolute',
    zIndex: theme.zIndex.drawer,
    color: '#fff',
}));

const CircularProgress = styled(_CircularProgress)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

export { MessageContainer, Backdrop, CircularProgress };
