import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ConversationSummarySearchBarInnerProps, ConversationSummarySearchBarProps } from './component';
import { ConversationSummarySearchBarComponent } from './component';
import { ConversationSummarySearchBarStyleRules } from './styles';

export const ConversationSummarySearchBar = compose<
    ConversationSummarySearchBarInnerProps,
    ConversationSummarySearchBarProps
>(
    setDisplayName('ConversationSummarySearchBar'),
    withStyles(ConversationSummarySearchBarStyleRules),
    withTranslation()
)(ConversationSummarySearchBarComponent);
