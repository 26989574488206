import { saveAs } from 'file-saver';
import * as React from 'react';

import type { Dictionary } from '~/libs/utility';
import { reportError } from '~/reporting';
import { FileTypeKeys } from '~/services/ApiClient';

import { DefaultFileViewer } from './components/DefaultFileViewer';
import { DocumentViewerShell } from './components/DocumentViewerShell';
import { ImageViewer } from './components/ImageViewer';
import PdfViewer from './components/PdfViewer';
import { useFileCache } from './hooks';
import type {
    DocumentViewerFile,
    DocumentViewerFileWithContent,
    DocumentViewerPaging,
    DocumentViewerRotation,
    DocumentViewerScaling,
    SingleDocumentViewerElement,
    SingleDocumentViewerProps,
} from './models';
import { DocumentViewerState } from './models';
import { saveFiles } from './saveFiles';

export interface DocumentViewerProps {
    defaultFile: DocumentViewerFile;
    files: DocumentViewerFile[];
    onClose: () => void;
    downloadFiles: (files: DocumentViewerFile[]) => Promise<DocumentViewerFileWithContent[]>;
    saveAllFilename?: string;
}

export interface DocumentViewerInnerProps extends DocumentViewerProps {}

const viewers: Dictionary<React.ComponentType<SingleDocumentViewerProps>> = {
    [FileTypeKeys.PDF]: PdfViewer,
    [FileTypeKeys.PNG]: ImageViewer,
    [FileTypeKeys.JPG]: ImageViewer,
};

export const DocumentViewerComponent: React.FC<DocumentViewerInnerProps> = ({
    files,
    defaultFile,
    onClose,
    downloadFiles,
    saveAllFilename,
}) => {
    const [viewerRef, setViewerRef] = React.useState<SingleDocumentViewerElement | null>(null);

    const fileCache = useFileCache(downloadFiles);
    const [currentFileContents, setCurrentFileContents] = React.useState<Blob | undefined>();
    const [viewerState, setViewerState] = React.useState<DocumentViewerState>(DocumentViewerState.Loading);
    const [file, setFile] = React.useState<DocumentViewerFile>(defaultFile);

    const [rotation, setRotation] = React.useState<DocumentViewerRotation | undefined>();
    const [scaling, setScaling] = React.useState<DocumentViewerScaling | undefined>();
    const [paging, setPaging] = React.useState<DocumentViewerPaging | undefined>();

    React.useEffect(() => {
        (async () => {
            try {
                setViewerState(DocumentViewerState.Loading);
                const contents = await fileCache.getSingle(file);
                if (contents) {
                    setCurrentFileContents(contents.contents);
                } else {
                    setViewerState(DocumentViewerState.NoPreview);
                }
            } catch {
                setViewerState(DocumentViewerState.NoPreview);
            }
        })();
    }, [file, downloadFiles, fileCache]);

    const changeFile = (newFile: DocumentViewerFile) => {
        if (file !== newFile) {
            setFile(newFile);
            setCurrentFileContents(undefined);
            setRotation(undefined);
            setScaling(undefined);
            setPaging(undefined);
            setViewerState(DocumentViewerState.Loading);
        }
    };
    const downloadFile = currentFileContents && (() => saveAs(currentFileContents, file.filename));

    const downloadAll = async () => {
        try {
            await saveFiles(await fileCache.getMultiple(files), saveAllFilename);
        } catch (ex) {
            reportError(ex);
        }
    };

    const Viewer = viewers[file.type.key] ?? DefaultFileViewer;

    return (
        <DocumentViewerShell
            downloadFile={downloadFile}
            onClose={onClose}
            currentFile={file}
            files={files}
            state={viewerState}
            onCurrentFileChange={changeFile}
            downloadAllFiles={downloadAll}
            printFile={viewerRef?.print}
            rotation={rotation}
            paging={paging}
            scaling={scaling}
        >
            <Viewer
                ref={setViewerRef}
                key={file.filename}
                data={currentFileContents}
                state={viewerState}
                onStateChange={setViewerState}
                onPagingChange={setPaging}
                onRotationChange={setRotation}
                onScalingChange={setScaling}
            />
        </DocumentViewerShell>
    );
};
