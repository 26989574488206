import moment from 'moment';

import type { Cursor } from './components/ZoomPopup';
import { graphWidth, numberOfSecondsInAPixel } from './constants';

export const getCursorInterval = (currentTime: Date, cursor: number): Cursor => {
    const cursorDate = moment(currentTime).subtract((graphWidth - cursor) * numberOfSecondsInAPixel, 'seconds');

    return {
        start: cursorDate
            .clone()
            .subtract(numberOfSecondsInAPixel / 2, 'seconds')
            .toDate(),
        stop: cursorDate
            .clone()
            .add(numberOfSecondsInAPixel / 2, 'seconds')
            .toDate(),
    };
};
