import { CanceledException } from './common/utils/cancelableAction';

export const reportError = (error: unknown) => {
    if (error instanceof CanceledException) {
        // Prevent logging canceled actions in the console
        return;
    }

    // eslint-disable-next-line no-console
    console.error(error);
};
