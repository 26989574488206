import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import { lighten, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

interface StyledLinkProps {
    parentMenuItem?: boolean;
    hasChildren?: boolean;
}

interface ItemIconContainerProps {
    isActive?: boolean;
}

const styledLinkPropsNames: Array<keyof StyledLinkProps> = ['parentMenuItem', 'hasChildren'];

const NestedMenuContainer = styled(MenuItem)(({ theme }) => ({
    width: 'auto',
    backgroundColor: theme.menuBar.background,
    marginLeft: -7,
    overflow: 'hidden',
    '&:hover': {
        backgroundColor: lighten(theme.menuBar.background, 0.1),
    },
}));

const StyledNavLink = styled(NavLink, {
    shouldForwardProp: (propName) => !styledLinkPropsNames.includes(propName as keyof StyledLinkProps),
})<StyledLinkProps>(({ theme, parentMenuItem, hasChildren }) => ({
    alignItems: 'start',
    color: theme.menuBar.iconColor,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: `${parentMenuItem ? theme.spacing(-1) : theme.spacing(1)}`,
    padding: `${parentMenuItem ? theme.spacing(1, 1.25) : theme.spacing(1.25)}`,
    textDecoration: 'none',

    position: 'relative',
    '&::after': {
        width: 200,
        height: 40,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '-100%',
        opacity: 0,
        margin: 'auto',
        zIndex: 1000,
        display: `${hasChildren ? 'block' : 'none'}`,
        transform: 'perspective(110px) rotateY(272deg)',
    },
    '&:hover': {
        textDecoration: 'none',
        '&::after': {
            content: '" "',
        },
    },
}));

const StyledLink = styled(Link, {
    shouldForwardProp: (propName) => !styledLinkPropsNames.includes(propName as keyof StyledLinkProps),
})<StyledLinkProps>(({ theme, parentMenuItem }) => ({
    alignItems: 'start',
    color: theme.menuBar.iconColor,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: `${parentMenuItem ? theme.spacing(-1) : theme.spacing(1)}`,
    padding: `${parentMenuItem ? theme.spacing(1, 1.25) : theme.spacing(1.25)}`,
    textDecoration: 'none',
}));

const MenuItemTitle = styled(Typography)({
    marginLeft: 10,
});

const ItemIconContainer = styled('span', {
    shouldForwardProp: (propName) => propName !== 'isActive',
})<ItemIconContainerProps>(({ theme, isActive }) => ({
    position: 'relative',
    color: theme.menuBar.activeIconColor,
    '&::after': {
        borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.secondary.main}`,
        bottom: 0,
        content: '" "',
        left: -2,
        position: 'absolute',
        display: `${isActive ? 'inherit' : 'none'}`,
        width: theme.spacing(3.5),
    },
}));

export { NestedMenuContainer, StyledNavLink, MenuItemTitle, ItemIconContainer, StyledLink };
