import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import * as React from 'react';
import type { Subtract } from 'utility-types';

export interface WorkingIconProps extends Subtract<SvgIconProps, { viewBox?: string }> {}

export interface WorkingIconInnerProps extends WorkingIconProps {}

const WorkingIcon: React.SFC<WorkingIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M20.52,7.2a.57.57,0,0,0-.08-.13L17.09,3.72a.32.32,0,0,0-.46,0l-1,.95a.34.34,0,0,0,0,.46L17.34,6.8,12.17,12,6.83,6.63l1.5-1.5a.34.34,0,0,0,0-.46l-1-.95a.32.32,0,0,0-.46,0L3.56,7.07a.57.57,0,0,0-.08.13L2.59,10a.33.33,0,0,0,.54.33L5.42,8l5.34,5.34L5.17,19l1.41,1.41,5.59-5.59,5.59,5.59L19.17,19l-5.59-5.59,5.17-5.17,2.12,2.12a.33.33,0,0,0,.54-.33Z" />
        </SvgIcon>
    );
};
WorkingIcon.displayName = 'WorkingIcon';

export { WorkingIcon };
