import * as React from 'react';

import type { Classification } from '~/services/ApiClient';

import { IconFormatter } from './components/Icon';

export interface SecondaryDoorStatusFormatterProps {
    value: Classification[];
    withAvatar?: boolean;
}

export interface SecondaryDoorStatusFormatterInnerProps extends SecondaryDoorStatusFormatterProps {}

const SecondaryDoorStatusFormatter: React.SFC<SecondaryDoorStatusFormatterInnerProps> = ({ value, withAvatar }) => {
    const keys = value.map((x) => x.key);
    return <IconFormatter value={keys} withAvatar={withAvatar} />;
};
SecondaryDoorStatusFormatter.displayName = 'SecondaryDoorStatusFormatter';

export { SecondaryDoorStatusFormatter };
