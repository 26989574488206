import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { FuelInformationInnerProps, FuelInformationProps } from './component';
import { FuelInformationComponent } from './component';
import { FuelInformationStyleRules } from './styles';

export const FuelInformation = compose<FuelInformationInnerProps, FuelInformationProps>(
    setDisplayName('FuelInformation'),
    withStyles(FuelInformationStyleRules),
    withTranslation()
)(FuelInformationComponent);
