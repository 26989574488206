import type { SingleTFunction } from '~/components/LanguageSelector';
import type { AssetGroup, AssetRecipient } from '~/services/ApiClient';
import { AssetRecipientType, AssetType } from '~/services/ApiClient';

import { calculateUnderlyingDriverIdsAndSubgroups } from './calculateUnderlyingDriverIdsAndSubgroups';
import { calculateUnderlyingVehicleIdsAndSubgroups } from './calculateUnderlyingVehicleIdsAndSubgroups';

export const extractGroups = (
    group: AssetGroup,
    groupsCombined: AssetRecipient[],
    t: SingleTFunction,
    assetType: AssetType,
    assetPredicate?: (vehicleId: number) => boolean
): void => {
    switch (assetType) {
        case AssetType.Vehicle: {
            const vehiclesFlat = calculateUnderlyingVehicleIdsAndSubgroups({
                assetGroup: group,
                result: { vehicleIds: [], subgroups: 0, driverIds: [] },
                predicate: assetPredicate,
            });
            const vehiclesGroupToAdd = {
                name: group.name,
                ids: vehiclesFlat.vehicleIds,
                type: AssetRecipientType.GROUP,
                enabledAssetIds: vehiclesFlat.enabledAssetIds,
                secondaryTextFirst: t('number-of-vehicles', { numberOfVehicles: vehiclesFlat.vehicleIds.length }),
                secondaryTextSecond: t('number-of-assets-eligibles', {
                    numberOfAssets: vehiclesFlat.enabledAssetIds?.length || 0,
                    assetType: t('vehicle'),
                }),
                secondaryTextThird: t('number-of-subgroups', { numberOfSubGroups: vehiclesFlat.subgroups }),
            };
            groupsCombined.push(vehiclesGroupToAdd);
            break;
        }
        case AssetType.Driver: {
            const driversFlat = calculateUnderlyingDriverIdsAndSubgroups({
                assetGroup: group,
                result: { driverIds: [], subgroups: 0, vehicleIds: [] },
                predicate: assetPredicate,
            });
            const driversGroupToAdd = {
                name: group.name,
                ids: driversFlat.driverIds,
                type: AssetRecipientType.GROUP,
                enabledAssetIds: driversFlat.enabledAssetIds,
                secondaryTextFirst: t('number-of-drivers', { numberOfDrivers: driversFlat.driverIds.length }),
                secondaryTextSecond: t('number-of-assets-eligibles', {
                    numberOfAssets: driversFlat.enabledAssetIds?.length || 0,
                    assetType: t('driver'),
                }),
                secondaryTextThird: t('number-of-subgroups', { numberOfSubGroups: driversFlat.subgroups }),
            };
            groupsCombined.push(driversGroupToAdd);
            break;
        }
        default:
            throw Error('Unkown asset type');
    }

    if (group.assetGroups) {
        group.assetGroups.forEach((nested) => {
            extractGroups(nested, groupsCombined, t, assetType, assetPredicate);
        });
    }
};
