import type { PositionGroupsStoreState } from '~/data/monitoring';
import type { RetrievableData } from '~/reducers';
import type { PositionStatus } from '~/services/ApiClient';

import type { ActionTypes } from './data/actionTypes';
import { ActionTypeKeys } from './data/actionTypes';

export const defaultPositionGroupState: RetrievableData<PositionStatus[]> = {
    data: [],
    fulfilled: false,
    rejected: false,
    pending: false,
};

const defaultPositionGroupsStoreState: PositionGroupsStoreState = {
    items: [],
};

export const positionGroupReducer = (
    state: PositionGroupsStoreState = defaultPositionGroupsStoreState,
    action: ActionTypes
): PositionGroupsStoreState => {
    switch (action.type) {
        case ActionTypeKeys.POSITIONSTATUSES_PENDING: {
            const actualPositionStatusesState = state.items[action.meta.positionGroupId];
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.meta.positionGroupId]: {
                        ...defaultPositionGroupState,
                        ...actualPositionStatusesState,
                        pending: true,
                    },
                },
            };
        }

        case ActionTypeKeys.POSITIONSTATUSES_FULFILLED: {
            const actualPositionStatusesState = state.items[action.meta.positionGroupId];

            if (!actualPositionStatusesState) {
                return state;
            }

            return {
                ...state,
                items: {
                    ...state.items,
                    [action.meta.positionGroupId]: {
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                        data: action.payload,
                    },
                },
            };
        }

        case ActionTypeKeys.POSITIONSTATUSES_REJECTED: {
            const actualPositionStatusesState = state.items[action.meta.positionGroupId];
            if (!actualPositionStatusesState) {
                return state;
            }
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.meta.positionGroupId]: {
                        fulfilled: false,
                        pending: false,
                        rejected: true,
                        data: [],
                    },
                },
            };
        }
        default:
            return state;
    }
};
