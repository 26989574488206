import { isUndefined } from '@fv/components/utility';

import { ROOT_NODE_DEFAULT_ID } from './consts';
import type { GenericItem } from './models';

const buildDictionary = (values: (string | number)[]) => {
    return values.reduce(
        (acc, item) => {
            acc[String(item)] = item;
            return acc;
        },
        {} as { [key: string | symbol]: string | number }
    );
};

const innerGetChildrenIds = <T extends GenericItem<T>>(
    source: T[],
    accumulator: Set<string | number>,
    predicate: (item: { id: string | number }) => boolean
): Set<string | number> => {
    return source.reduce((acc: Set<string | number>, item) => {
        const { id, children } = item;
        if ((!children || children.length === 0) && (predicate ? predicate(item) : true)) {
            acc.add(id);
        }

        if (children) {
            return innerGetChildrenIds(children, acc, predicate);
        }

        return acc;
    }, accumulator);
};

const innerGetAllParentsIds = <T extends GenericItem<T>>(
    source: T[],
    accumulator: Set<string | number>
): Set<string | number> => {
    return source.reduce((acc: Set<string | number>, item) => {
        const { id, children } = item;

        if (children) {
            acc.add(id);
            return innerGetAllParentsIds(children, acc);
        }

        return acc;
    }, accumulator);
};

const getAllParentsIds = <T extends GenericItem<T>>(source: T[]): (string | number)[] => {
    const allIdsSet = innerGetAllParentsIds(source, new Set());
    return Array.from(allIdsSet);
};

const getChildrenIds = <T extends GenericItem<T>>(
    source: T[],
    predicate: (item: { id: string | number }) => boolean = () => false
): (string | number)[] => {
    const childrenIdsSet = innerGetChildrenIds(source, new Set(), predicate);
    return Array.from(childrenIdsSet);
};

/**
 * Creates a filtered version of a provided tree without mutating an original one
 * @param tree Asset tree
 * @param query search query, will be used for the 'name' field, comparison will be executed by String.includes()
 */
const innerSafeFilterTreeByName = <T extends GenericItem<T>>(tree: GenericItem<T>[], query: string = '') => {
    const filteredTree: GenericItem<T>[] = [];
    tree.forEach((item) => {
        if (item.children?.length) {
            const filteredChildren = innerSafeFilterTreeByName(item.children, query);
            if (filteredChildren?.length) {
                filteredTree.push({ ...item, children: filteredChildren });
                return undefined; // exit condition
            }
        }
        if (item.name.toLowerCase().includes(query.toLowerCase())) {
            filteredTree.push({ ...item });
        }
    });

    return filteredTree;
};

/**
 * Updates the nodes with checked and indeterminate status recursively, adds parentId to the node
 * @param checkablePredicate - a callback to verify whether item is checkable (item: GenericItem) => boolean
 * @param tree a tree structure to udpate
 * @param parentId a parent node id
 * @param checkedKeys a dictionary of checked keys
 */
const innerUpdateTreeNodes = <T extends GenericItem<T>>(
    checkablePredicate: (item: GenericItem<T>) => boolean,
    tree: GenericItem<T>[],
    parentId: number | string,
    checkedKeys: { [key: string | symbol]: string | number }
) => {
    const currentLevelUpdatedTree: GenericItem<T>[] = [];
    let innerCheckedKeys: { [key: string | symbol]: string | number } = {};
    tree.forEach((item) => {
        if (item.children?.length) {
            const { updatedTree, newCheckedKeys } = innerUpdateTreeNodes(
                checkablePredicate,
                item.children,
                item.id,
                checkedKeys
            );

            innerCheckedKeys = Object.assign(innerCheckedKeys, newCheckedKeys);

            const someOfTheUpdatedChildrenAreChecked = updatedTree.some(
                (node) => !isUndefined(checkedKeys[node.id]) || node.checkedState?.isChecked
            );

            const everyOfTheUpdatedChildrenAreChecked = updatedTree.every((node) => {
                return (
                    (!isUndefined(checkedKeys[node.id]) || node.checkedState?.isChecked) &&
                    !node.checkedState?.isIndeterminate // if indeterminate, then not every child node is selected
                );
            });

            const isChecked = someOfTheUpdatedChildrenAreChecked;
            const isIndeterminate = !everyOfTheUpdatedChildrenAreChecked && isChecked;

            if (isChecked && checkablePredicate(item)) {
                innerCheckedKeys[String(item.id)] = item.id;
            }

            currentLevelUpdatedTree.push({
                ...(item.parentId ? item : { ...item, parentId }),
                children: updatedTree,
                checkedState: { isChecked, isIndeterminate },
            });
        } else {
            // leaf
            const isChecked = !isUndefined(checkedKeys[item.id]);

            if (isChecked && checkablePredicate(item)) {
                innerCheckedKeys[String(item.id)] = item.id;
            }

            currentLevelUpdatedTree.push({
                ...(item.parentId ? item : { ...item, parentId }),
                checkedState: { isChecked, isIndeterminate: false },
            });
        }
    });

    return { updatedTree: currentLevelUpdatedTree, newCheckedKeys: innerCheckedKeys };
};

const safeFilterTreeByName = <T extends GenericItem<T>>(tree: GenericItem<T>[], query: string = '') => {
    return innerSafeFilterTreeByName(tree, query);
};

const updateTreeNodes = <T extends GenericItem<T>>(
    // eslint-disable-next-line default-param-last
    checkablePredicate: (item: Partial<GenericItem<T>>) => boolean = () => true,
    tree: GenericItem<T>[],
    checkedKeys: { [key: string | symbol]: string | number }
) => {
    return innerUpdateTreeNodes(checkablePredicate, tree, ROOT_NODE_DEFAULT_ID, { ...checkedKeys });
};

export { buildDictionary, getAllParentsIds, getChildrenIds, safeFilterTreeByName, updateTreeNodes };
