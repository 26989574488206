import type { GridColumnDefinition } from './models';

export function getRawCellValue<T>(
    columnDefinition: GridColumnDefinition<T>,
    entry: T
): string | number | Date | undefined {
    return typeof columnDefinition.getCellValue === 'function'
        ? columnDefinition.getCellValue(entry)
        : entry[columnDefinition.name];
}
