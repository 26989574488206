import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const SatelliteIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M20.73 15.91 15.8 13.09a.52.52 0 0 0-.63.08l-.63.63L13.72 13l2.41-2.41L13.36 7.79 11 10.2l-.75-.74.63-.63a.52.52 0 0 0 .08-.63L8.09 3.27a.52.52 0 0 0-.69-.22l-4.1 2A.53.53 0 0 0 3 5.44a.5.5 0 0 0 .15.46l4.92 4.93a.54.54 0 0 0 .38.15.51.51 0 0 0 .37-.15l.63-.63.84.85a2 2 0 0 0-.06 1.41 2.88 2.88 0 0 0-1.42-.36A3 3 0 0 0 6.7 13a.51.51 0 0 0 0 .74L8.07 15.1l-.35.35a.53.53 0 0 0 0 .74.52.52 0 0 0 .38.16.5.5 0 0 0 .37-.16l.35-.35 1.38 1.38a.5.5 0 0 0 .37.15.51.51 0 0 0 .37-.15 3 3 0 0 0 .52-3.55 2 2 0 0 0 .64.11 2 2 0 0 0 .77-.16l.93.92-.63.63a.54.54 0 0 0 0 .75l4.93 4.93a.53.53 0 0 0 .37.15h.09A.53.53 0 0 0 19 20.7l2-4.1A.53.53 0 0 0 20.73 15.91Z" />
            <path d="M8.1 19.86a4.05 4.05 0 0 1-4-4 .52.52 0 0 0-.52-.53.53.53 0 0 0-.53.53 5.11 5.11 0 0 0 5.1 5.1.53.53 0 0 0 0-1.06Z" />
            <path d="M8.1 17.58a1.76 1.76 0 0 1-1.76-1.76.53.53 0 0 0-.53-.53.52.52 0 0 0-.52.53A2.81 2.81 0 0 0 8.1 18.63a.52.52 0 0 0 .52-.52A.52.52 0 0 0 8.1 17.58Z" />
            <path d="M17.16 9.53a2 2 0 0 0 0-2.74h0l0 0a1.93 1.93 0 0 0-2.74 0L14.11 7l2.77 2.77Z" />
            <path d="M0 0H24V24H0Z" fill="none" />
        </SvgIcon>
    );
};
SatelliteIcon.displayName = 'SatelliteIcon';

export { SatelliteIcon };
