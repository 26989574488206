import type { LatLngBoundsExpression, LatLngTuple } from 'leaflet';
import { LatLngBounds } from 'leaflet';

import type { Position } from '~/common';
import { getConfig } from '~/services/Config';

import { ensureBoundsArea } from './ensureBoundsArea';

export const getAllBounds = <T>(
    getAssetPosition: (entry: T) => Position | undefined,
    entries: T[]
): LatLngBoundsExpression => {
    const latlng = entries.reduce((accumulator: LatLngTuple[], entry) => {
        const position = getAssetPosition(entry);
        if (position) {
            accumulator.push([position.latitude, position.longitude]);
        }

        return accumulator;
    }, []);

    return latlng.length ? ensureBoundsArea(new LatLngBounds(latlng)) : getConfig().defaultMapBounds;
};
