import { createAction } from 'redux-actions';

import { retryableRequest } from '~/services/ApiClient';

import { ActionTypePrefixes } from './actionTypes';

export const retrieveSettingAction = createAction<Promise<unknown>, string, string, () => Promise<unknown>>(
    ActionTypePrefixes.SETTINGS,
    (_: string, retrieveData: () => Promise<unknown>) => retryableRequest(() => retrieveData()),
    (key: string) => key
);

export const updateSettingAction = createAction<Promise<unknown>, string, string, () => Promise<unknown>>(
    ActionTypePrefixes.SETTINGS_UPDATE,
    (_: string, updateData: () => Promise<unknown>) => retryableRequest(() => updateData()),
    (key: string) => key
);
