import type { AssetGroup, AssetReference, AssetType, Depot } from '~/services/ApiClient';

import type { AssetDataSet, AssetReferenceHash, AssetReferenceWithDisplayName, SupportedNode } from '../models';
import { SceneAssetSelectorViewMode } from '../preferences';

import { assetIdsToNodes } from './mapasset';
import { assetTypesToNodes } from './mapassettype';
import { depotsToNodes } from './mapdepot';
import { assetGroupsToNodes } from './mapgroup';

export const generateAssetTree = (
    viewMode: SceneAssetSelectorViewMode,
    assetDataSets: AssetDataSet[],
    assetGroups: AssetGroup[],
    depots: Depot[],
    assetIds: AssetReferenceHash<AssetReference>,
    assetsHash: AssetReferenceHash<AssetReferenceWithDisplayName>,
    expandedDepotIds: number[],
    expandedGroupIds: number[],
    collapsedAssetTypes: AssetType[],
    selectedAssetIds: AssetReferenceHash<AssetReference>,
    selectedParentGroupId: number | undefined,
    expandedAllGroups: boolean,
    expandedAllDepots: boolean
): SupportedNode[] => {
    const assetTypes = assetDataSets.map((dataSet) => dataSet.type);

    switch (viewMode) {
        case SceneAssetSelectorViewMode.ASSETS:
            if (assetTypes.length > 1) {
                return assetTypesToNodes(assetDataSets, assetIds, assetsHash, collapsedAssetTypes, selectedAssetIds);
            }

            return assetIdsToNodes(assetTypes, assetIds, assetsHash, selectedAssetIds, undefined, undefined);
        case SceneAssetSelectorViewMode.DEPOTS:
            return depotsToNodes(
                assetTypes,
                depots,
                assetIds,
                assetsHash,
                expandedAllDepots,
                expandedDepotIds,
                selectedAssetIds
            );
        case SceneAssetSelectorViewMode.GROUPS:
            return assetGroupsToNodes(
                assetTypes,
                assetGroups,
                assetIds,
                assetsHash,
                expandedAllGroups,
                expandedGroupIds,
                selectedAssetIds,
                selectedParentGroupId
            );
        default:
            throw new Error(`Unknown viewMode '${viewMode}'`);
    }
};
