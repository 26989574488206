import type { AssetType, RouteStatus, RouteStatusesResponse } from '~/services/ApiClient';
import type { Action, FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    ROUTESTATUS = 'ROUTESTATUS',
}

export enum ActionTypeKeys {
    ROUTESTATUS_PENDING = 'ROUTESTATUS_PENDING',
    ROUTESTATUS_FULFILLED = 'ROUTESTATUS_FULFILLED',
    ROUTESTATUS_REJECTED = 'ROUTESTATUS_REJECTED',
    ROUTESTATUS_UPDATE = 'ROUTESTATUS_UPDATE',
}

export type RouteStatusesPayload = Readonly<RouteStatusesResponse>;

export type ActionTypes =
    | PendingMeta<ActionTypeKeys.ROUTESTATUS_PENDING, AssetType>
    | FulfilledMeta<ActionTypeKeys.ROUTESTATUS_FULFILLED, RouteStatusesPayload, AssetType>
    | RejectedMeta<ActionTypeKeys.ROUTESTATUS_REJECTED, undefined, AssetType>
    | Action<ActionTypeKeys.ROUTESTATUS_UPDATE, RouteStatus>;
