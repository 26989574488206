import type { FC } from 'react';

import { useDisplayPreferences } from '~/common/hooks';
import { SceneMapView } from '~/components/SceneMap';
import type { MonitoringContainerEntry } from '~/data/monitoring';
import { monitoringContainerEntryEquals } from '~/scenes/Monitoring/utils';

import { MONITORING_MAP_CONTAINER_STATE_KEY } from './consts';
import { createFilter } from './createFilter';
import type { ContainerMapViewProps } from './models';

const getRowId = (entry: MonitoringContainerEntry) => entry.container.id;
const getLocation = (entry: MonitoringContainerEntry) => entry.status?.location?.position;

const ContainerMapView: FC<ContainerMapViewProps> = (props) => {
    const displayPreferences = useDisplayPreferences();

    return (
        <SceneMapView
            mapKey={MONITORING_MAP_CONTAINER_STATE_KEY}
            dataId="monitoring-map-container"
            filterDataSource={createFilter(displayPreferences)}
            entryEquals={monitoringContainerEntryEquals}
            getAssetIdentifier={getRowId}
            getAssetPosition={getLocation}
            showTooltip={false}
            {...props}
        />
    );
};
ContainerMapView.displayName = 'ContainerMapView';

export { ContainerMapView };
