import ArrowBack from '@mui/icons-material/ArrowBack';
import Close from '@mui/icons-material/Close';
import { Paper, Typography } from '@mui/material';
import type { FC } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useDisplayPreferences } from '~/common/hooks';
import { DriverNameFormatter } from '~/components/Formatters';
import { DetailsPaneContext } from '~/components/SceneDetailsPane';
import { TitledIconButton } from '~/components/TitledIconButton';
import { isUndefined } from '~/libs/utility';
import type { DriverActivityType, DriverName, ResolvedVehicle } from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';

import { DriverAvatar } from './components/DriverAvatar';
import type { DriverSubpageHeaderProps } from './models';
import { useStyles } from './styles';

const DriverSubpageHeader: FC<DriverSubpageHeaderProps> = (props) => {
    const { activity, driver, vehicle } = props.driverStatus;
    const { close, goBack } = useContext(DetailsPaneContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const displayPreferences = useDisplayPreferences();

    const vehicleName = !isUndefined(vehicle as ResolvedVehicle)
        ? formatVehicleName(vehicle as ResolvedVehicle, displayPreferences.vehicleDisplayFormat)
        : undefined;

    return (
        <Paper square className={classes.root} data-id="driver-subpage-header">
            <div className={classes.actionButtons}>
                <TitledIconButton onClick={goBack} data-id="back-button">
                    <ArrowBack />
                </TitledIconButton>

                <TitledIconButton onClick={close} data-id="close-button">
                    <Close />
                </TitledIconButton>
            </div>

            <div className={classes.avatarIcons}>
                <DriverAvatar activity={activity as DriverActivityType} />
            </div>

            <Typography className={classes.driverName} variant="body2" color="textPrimary" data-id="driver-name">
                {!isUndefined(driver) ? (
                    <DriverNameFormatter
                        value={driver as DriverName}
                        displayNameFormat={displayPreferences.driverDisplayFormat}
                    />
                ) : (
                    t('not-logged-in')
                )}
            </Typography>

            <Typography
                className={classes.vehicleInfo}
                variant="body2"
                color="textSecondary"
                data-id="driver-role-and-vehicle"
            >
                {!isUndefined(vehicle) ? `${t('driver-of-vehicle', { vehicle: vehicleName })} ` : undefined}
            </Typography>
        </Paper>
    );
};

DriverSubpageHeader.displayName = 'DriverSubpageHeader';
export default DriverSubpageHeader;
