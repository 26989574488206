import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { SettingItemHeaderOuterProps } from './component';

export type SettingItemHeaderClassKeys = 'root' | 'icon' | 'body' | 'title' | 'description' | 'action' | 'disabled';

export const SettingItemHeaderStyleRules: StyleRulesCallback<
    Theme,
    SettingItemHeaderOuterProps,
    SettingItemHeaderClassKeys
> = (theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            padding: ({ disableGutters }) => theme.spacing(2, disableGutters ? 0 : 2),
        },
        icon: {
            width: 24,
            height: 24,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
        body: {
            flexGrow: 1,
            marginRight: theme.spacing(2),
        },
        title: {},
        description: {},
        action: {
            display: 'flex',
            color: theme.palette.text.secondary,
        },
        disabled: {
            opacity: theme.palette.action.disabledOpacity,
        },
    });
