import type { VehicleEvent } from '~/services/ApiClient';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    VEHICLEEVENT = 'VEHICLEEVENT',
}

export enum ActionTypeKeys {
    VEHICLEEVENT_PENDING = 'VEHICLEEVENT_PENDING',
    VEHICLEEVENT_FULFILLED = 'VEHICLEEVENT_FULFILLED',
    VEHICLEEVENT_REJECTED = 'VEHICLEEVENT_REJECTED',
}

export interface VehicleEventsPayload {
    readonly items: VehicleEvent[];
}

export type ActionTypes =
    | Pending<ActionTypeKeys.VEHICLEEVENT_PENDING>
    | Fulfilled<ActionTypeKeys.VEHICLEEVENT_FULFILLED, VehicleEventsPayload>
    | Rejected<ActionTypeKeys.VEHICLEEVENT_REJECTED>;
