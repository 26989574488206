import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import type { RequiredUserPreferences } from '~/components/UserPreferences';

import { SectionName } from './constants';

export interface TextMessageDetailsUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapZoomLevel: number;
}

export const defaultTextMessageDetailsUserPreferences: TextMessageDetailsUserPreferences = {
    miniMapZoomLevel: 12,
    sectionOrder: [SectionName.MESSAGEINFO, SectionName.MINIMAP, SectionName.LOCATION, SectionName.METADATA],
    collapsedSections: [],
};

export const requiredMessageDetailsUserPreferencesFactory = (key: string): RequiredUserPreferences => ({
    key,
    defaultState: defaultTextMessageDetailsUserPreferences,
});
