import type { Dispatch } from 'redux';

import { retrieveSettingAction } from '~/data/settings';
import { reportError } from '~/reporting';

import type { EnsureSettingsDispatchProps, EnsureSettingsStateProps } from './component';
import type { SettingsStoreState } from './reducers';

export const mapStateToProps = (settingsState: SettingsStoreState): EnsureSettingsStateProps => ({ settingsState });

export const mapDispatchToProps = (dispatch: Dispatch): EnsureSettingsDispatchProps => ({
    retrieveSetting: (key: string, retrieveData: () => Promise<unknown>) => {
        dispatch(retrieveSettingAction(key, retrieveData)).catch(reportError);
    },
});
