import { combineReducers } from 'redux';

import type { HistoryMapStoreState, MonitoringStoreState, VehicleDetailsPaneStoreState } from '~/data/monitoring';
import { monitoringReducer } from '~/data/monitoring';

import { vehicleDetailsPaneReducer } from './components/DetailsPane';
import { historyMapReducer } from './components/Map';

export interface MonitoringSceneStoreState {
    monitoring: MonitoringStoreState;
    vehicleDetailsPane: VehicleDetailsPaneStoreState;
    historyMap: HistoryMapStoreState;
}

export const monitoringSceneReducer = combineReducers({
    monitoring: monitoringReducer,
    vehicleDetailsPane: vehicleDetailsPaneReducer,
    historyMap: historyMapReducer,
});
