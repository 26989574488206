import type { FC } from 'react';

import { DefaultHeader, SearchHeader } from './components';
import type { SearchingBoxProps } from './models';

const SearchingBox: FC<SearchingBoxProps> = (props) => {
    const {
        changeSearchQuery,
        closeSearch,
        defaultSearchQuery,
        expandedAll,
        hideBulkSelectActions,
        openSearch,
        partiallySelected,
        searchEnabled,
        searchingItemsName,
        selectedAll,
        onToggleSelectAll,
        handleCollapse,
    } = props;

    return (
        <div data-testid="asset-selector-header" data-id="searching-box">
            {searchEnabled ? (
                <SearchHeader
                    changeSearchQuery={changeSearchQuery}
                    defaultSearchQuery={defaultSearchQuery}
                    closeSearch={closeSearch}
                />
            ) : (
                <DefaultHeader
                    searchingItemsName={searchingItemsName}
                    openSearch={openSearch}
                    onToggleSelectAll={onToggleSelectAll}
                    partiallySelected={partiallySelected}
                    selectedAll={selectedAll}
                    expandedAll={expandedAll}
                    hideSelectAllCheckbox={hideBulkSelectActions}
                    handleCollapse={handleCollapse}
                />
            )}
        </div>
    );
};

SearchingBox.displayName = 'SearchingBox';
export { SearchingBox };
