import type { StoreState } from '~/reducers';
import { monitoringSceneStateSelector } from '~/selectors';

import type { HistoryMapStoreState, VehicleDetailsPaneStoreState } from './models';
import type { DynamicMonitoringStoreState } from './reducers';

export const dynamicMonitoringStateSelector = (s: StoreState): DynamicMonitoringStoreState => {
    return monitoringSceneStateSelector(s).monitoring.dynamicMonitoring;
};

export const vehicleDetailsPaneStateSelector = (s: StoreState): VehicleDetailsPaneStoreState => {
    return monitoringSceneStateSelector(s).vehicleDetailsPane;
};

export const historyMapStateSelector = (s: StoreState): HistoryMapStoreState => {
    return monitoringSceneStateSelector(s).historyMap;
};
