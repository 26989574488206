import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';

import type { SynchronizationClockInnerProps, SynchronizationClockProps } from './component';
import { SynchronizationClockComponent } from './component';
import { mapDispatchToProps } from './redux';

export * from './reducers';
export * from './selectors';

export const SynchronizationClock = compose<SynchronizationClockInnerProps, SynchronizationClockProps>(
    setDisplayName('SynchronizationClock'),
    connect(undefined, mapDispatchToProps)
)(SynchronizationClockComponent);
