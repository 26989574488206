import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type HeaderSectionClassKey = 'vehicleIcon';

export const useStyles = makeStyles<Theme, {}, HeaderSectionClassKey>((theme) => ({
    vehicleIcon: {
        color: theme.functionalItemsColors.assetType.vehicle.value,
    },
}));
