import type { StaticDataStoreState } from '~/common';
import { deepArrayFinder, getValueByPath, isAdministratorUser } from '~/common';
import type { AssetGroup } from '~/services/ApiClient';

import type { UserRolesState } from '../userRoles';

import { ROLES } from './consts';
import type { ResolvedAdminUsersRead, UsersAdministrationRead } from './models';

interface ResolveAdministrationUserReadArgs {
    users?: UsersAdministrationRead[];
    staticDataState: StaticDataStoreState;
    userRolesState: UserRolesState;
}

export const resolveAdministrationUserRead = ({
    users,
    staticDataState,
    userRolesState,
}: ResolveAdministrationUserReadArgs): ResolvedAdminUsersRead[] => {
    return (users || []).map((user) => {
        const assetGroups: AssetGroup[] = [];
        const isAdmin = userRolesState.items.some((role) => role.isAdministrator && user.roles?.includes(role.name));

        user.assetGroups?.forEach((assetGroupId) => {
            const asset = deepArrayFinder({
                source: staticDataState.assetGroups.data,
                key: 'id',
                value: assetGroupId,
                where: 'assetGroups',
            });

            if (asset) {
                assetGroups.push(asset);
            }
        });

        return {
            ...user,
            depot: getValueByPath(staticDataState, `depots.data.hash[${user?.depotId}]`),
            assetGroups,
            isAdmin,
            isAdminUser: isAdministratorUser(user.username),
            isPortalAdmin: !!user?.roles?.find((role) => role.toLowerCase() === ROLES.PORTAL_ADMIN),
        };
    });
};
