import { LinearProgress } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';

import { DriverActivityKeys } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import type { DriverActivityEntry } from '../../../../models';
import { ActivitySurface } from '../../../ActivitySurface';
import { graphDuration, graphWidth } from '../../constants';
import { filterActivitiesThatAreInInterval } from '../../filterActivitiesThatAreInInterval';
import { getActivitySurfaceStyles } from '../../getActivitySurfaceStyles';

import type { ActivitiesBarClassKey } from './styles';

export interface ActivitiesBarProps {
    activities?: DriverActivityEntry[];
    currentTime: Date;
    loading: boolean;
    failedToLoad: boolean;
}

export interface ActivitiesBarInnerProps extends ActivitiesBarProps, WithStyles<ActivitiesBarClassKey> {}

const filterActivities = (currentTime: Date, activities: DriverActivityEntry[]) =>
    filterActivitiesThatAreInInterval(
        {
            start: moment(currentTime).subtract(1, 'day').toDate(),
            stop: currentTime,
        },
        activities
    );

export const ActivitiesBarComponent: React.FC<ActivitiesBarInnerProps> = ({
    activities,
    currentTime,
    loading,
    failedToLoad,
    classes,
}) => {
    const filterActivitiesMemoized = React.useMemo(() => memoizeOne(filterActivities), []);

    if (loading || failedToLoad) {
        return (
            <div data-id="activities-loading">
                <LinearProgress className={classes.loadingView} />
            </div>
        );
    }

    if (!activities) {
        return null;
    }

    const filteredActivities = filterActivitiesMemoized(currentTime, activities);
    const activitiesGraph = filteredActivities.map((activity: DriverActivityEntry, index: number) => {
        const activitySurfaceStyles = getActivitySurfaceStyles(graphWidth, graphDuration, currentTime, {
            start: activity.startDate,
            stop: activity.stopDate,
        });

        const activitySurfaceClasses = classNames(
            classes.activitySurface,
            { [classes.activitySurfaceLeftCornersRounded]: index === 0 },
            { [classes.activitySurfaceRightCornersRounded]: index === filteredActivities.length - 1 }
        );

        return (
            <ActivitySurface
                key={activity.id}
                className={activitySurfaceClasses}
                activity={DriverActivityKeys[activity.activityType.key.toUpperCase()]}
                isActive={!activity.stopDate}
                style={activitySurfaceStyles}
                dataId={`activity-segment:${activity.activityType.key.toLowerCase()}:${activity.id}`}
            />
        );
    });

    return (
        <div className={classes.root} data-id="activities-graph">
            {activitiesGraph}
        </div>
    );
};
