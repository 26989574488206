import type { Dispatch } from 'redux';

import type { GridColumnInfo, GridFilter, GridSorting } from '~/components/Grid';
import { updateUserPreferencesAction } from '~/data/userpreferences';

import type { SceneListUserPreferences } from '../../preferences';
import type { SceneListRootStoreState } from '../../reducers';

import type { SceneListViewDispatchProps, SceneListViewStateProps } from './models';

export const mapStateToPropsFactory =
    (preferencesKey: string) =>
    (
        sceneListUserPreferences: SceneListUserPreferences,
        sceneListStoreState: SceneListRootStoreState
    ): SceneListViewStateProps => {
        return {
            ...sceneListUserPreferences,
            searchQuery: sceneListStoreState[preferencesKey]?.searchQuery,
        };
    };

export const mapDispatchToPropsFactory =
    (preferencesKey: string) =>
    (dispatch: Dispatch): SceneListViewDispatchProps => ({
        changeColumnOrder: (columnOrder: string[]) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { columnOrder }));
        },
        changeVisibleColumns: (visibleColumns: string[]) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { visibleColumns }));
        },
        changeSorting: (sorting: GridSorting[]) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { sorting }));
        },
        changeGroupBy: (groupBy: string[]) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { groupBy }));
        },
        changeColumnWidths: (columnWidths: GridColumnInfo[]) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { columnWidths }));
        },
        changeFilters: (filters: GridFilter[]) => {
            dispatch(updateUserPreferencesAction(preferencesKey, { filters }));
        },
    });
