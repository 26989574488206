import DateRange from '@mui/icons-material/DateRange';
import Schedule from '@mui/icons-material/Schedule';
import TextField from '@mui/material/TextField';
import type { WithStyles } from '@mui/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import type { Moment } from 'moment';
import moment from 'moment';
import type { ComponentProps, FC } from 'react';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNil } from '~/libs/utility';
import type { DateTimeFieldWorkflowFormElement } from '~/services/ApiClient';
import { DateTimeDisplayPrecision } from '~/services/ApiClient';

import type { DateTimeWorkflowFieldClassKey } from './styles';

export interface DateTimeWorkflowFieldProps {
    field: DateTimeFieldWorkflowFormElement;
    label?: string;
    dataId: string;
    disabled?: boolean;
}

export interface DateTimeWorkflowFieldInnerProps
    extends DateTimeWorkflowFieldProps,
        WithStyles<DateTimeWorkflowFieldClassKey>,
        InjectedTranslationProps {}

export const DateTimeWorkflowFieldComponent: FC<DateTimeWorkflowFieldInnerProps> = (props) => {
    const { field, label, dataId, disabled, classes, t } = props;

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const helpers = useMemo(() => {
        const timeFormat = moment.localeData().longDateFormat('LT');
        return {
            dateFormat: moment.localeData().longDateFormat('L'),
            timeFormat,
            ampm: timeFormat.toLowerCase().includes('a'),

            includeDate:
                field.displayPrecision === DateTimeDisplayPrecision.DateTime ||
                field.displayPrecision === DateTimeDisplayPrecision.Date,
            includeTime:
                field.displayPrecision === DateTimeDisplayPrecision.DateTime ||
                field.displayPrecision === DateTimeDisplayPrecision.Time,

            prepareValueForEdit: (v: Date | null) => {
                return v ? moment.utc(v).local(field.timezoneIndependent) : null;
            },
            prepareValueForChange: (v: Moment | null) => {
                return v?.clone().utc(field.timezoneIndependent).toDate();
            },
        };
    }, [field]);

    const controllerProps = {
        defaultValue: null,
        name: `${field.id}`,
        control,
        rules: {
            required: field.isRequired,
            validate: {
                'invalid-date': (value: unknown) =>
                    value instanceof Date ? !Number.isNaN(value.valueOf()) : isNil(value),
            },
        },
    };

    const error = errors[controllerProps.name];
    const helperText = error
        ? error.message || t(`wf-field-error-${error.type}`)
        : field.isRequired
          ? t('wf-field-error-required')
          : undefined;

    const baseProps: Partial<ComponentProps<typeof DesktopDatePicker>> = {
        disabled,
        label,
        InputProps: {
            error: !!error,
            fullWidth: true,
            readOnly: field.isReadOnly,
            required: field.isRequired,
            size: 'small',
        },
    };

    if (helpers.includeDate === helpers.includeTime) {
        return (
            <Controller
                {...controllerProps}
                render={({ field: fieldFormProps }) => (
                    <DesktopDateTimePicker
                        {...fieldFormProps}
                        {...baseProps}
                        className={classes.input}
                        ampm={helpers.ampm}
                        inputFormat={`${helpers.dateFormat} ${helpers.timeFormat}`}
                        onChange={(value: Moment | null) =>
                            fieldFormProps.onChange(helpers.prepareValueForChange(value))
                        }
                        renderInput={(inputProps) => (
                            <TextField data-id={dataId} helperText={helperText} {...inputProps} />
                        )}
                        value={helpers.prepareValueForEdit(fieldFormProps.value)}
                        timeIcon={<Schedule className={classes.toolbarIcon} />}
                        dateRangeIcon={<DateRange className={classes.toolbarIcon} />}
                    />
                )}
            />
        );
    } else if (helpers.includeDate) {
        return (
            <Controller
                {...controllerProps}
                render={({ field: fieldFormProps }) => (
                    <DesktopDatePicker
                        {...fieldFormProps}
                        {...baseProps}
                        inputFormat={helpers.dateFormat}
                        onChange={(value: Moment | null) =>
                            fieldFormProps.onChange(helpers.prepareValueForChange(value))
                        }
                        renderInput={(inputProps) => (
                            <TextField data-id={dataId} helperText={helperText} {...inputProps} />
                        )}
                        value={helpers.prepareValueForEdit(fieldFormProps.value)}
                    />
                )}
            />
        );
    } else {
        return (
            <Controller
                {...controllerProps}
                render={({ field: fieldFormProps }) => (
                    <DesktopDateTimePicker
                        {...fieldFormProps}
                        {...baseProps}
                        ampm={helpers.ampm}
                        inputFormat={helpers.timeFormat}
                        onChange={(value: Moment | null) =>
                            fieldFormProps.onChange(helpers.prepareValueForChange(value))
                        }
                        renderInput={(inputProps) => (
                            <TextField data-id={dataId} helperText={helperText} {...inputProps} />
                        )}
                        value={helpers.prepareValueForEdit(fieldFormProps.value)}
                    />
                )}
            />
        );
    }
};
