import type { ActionTypes as AssetGroupsActionTypes } from '~/data/assetgroups';
import { ActionTypeKeys as AssetGroupsActionTypeKeys } from '~/data/assetgroups';
import type { ActionTypes as ContainersActionTypes } from '~/data/containers';
import { ActionTypeKeys as ContainersActionTypeKeys } from '~/data/containers';
import type { ActionTypes as DepotsActionTypes } from '~/data/depots';
import { ActionTypeKeys as DepotsActionTypeKeys } from '~/data/depots';
import type { ActionTypes as DevicesActionTypes } from '~/data/devices';
import { ActionTypeKeys as DevicesActionTypeKeys } from '~/data/devices';
import type { ActionTypes as DriversActionTypes } from '~/data/drivers';
import { ActionTypeKeys as DriversActionTypeKeys } from '~/data/drivers';
import type { ActionTypes as DriverStatusActionTypes } from '~/data/monitoring/actionTypes';
import { ActionTypeKeys as DriverStatusActionTypeKeys } from '~/data/monitoring/actionTypes';
import type { ActionTypes as TrailersActionTypes } from '~/data/trailers';
import { ActionTypeKeys as TrailersActionTypeKeys } from '~/data/trailers';
import type { ActionTypes as VehiclesActionTypes } from '~/data/vehicles';
import { ActionTypeKeys as VehiclesActionTypeKeys } from '~/data/vehicles';
import type { ActionTypes as VehicleStatusActionTypes } from '~/data/vehiclestatus';
import { ActionTypeKeys as VehicleStatusActionTypeKeys } from '~/data/vehiclestatus';
import { keyBy } from '~/libs/utility';
import type { RetrievableData, RetrievableHashedData } from '~/reducers';
import type {
    AssetGroup,
    Container,
    Depot,
    Device,
    Driver,
    DriverStatus,
    Trailer,
    Vehicle,
    VehicleStatus,
} from '~/services/ApiClient';

export interface StaticDataStoreState {
    assetGroups: RetrievableData<AssetGroup[]>;
    depots: RetrievableHashedData<Depot>;
    vehicles: RetrievableHashedData<Vehicle>;
    trailers: RetrievableHashedData<Trailer>;
    containers: RetrievableHashedData<Container>;
    drivers: RetrievableHashedData<Driver>;
    devices: RetrievableHashedData<Device>;
    vehicleStatus: RetrievableHashedData<VehicleStatus>;
    driverStatus: RetrievableHashedData<DriverStatus>;
}

const defaultHashedData = {
    data: { array: [], hash: {} },
    pending: false,
    fulfilled: false,
    rejected: false,
};

export const defaultStoreState: StaticDataStoreState = {
    assetGroups: {
        data: [],
        pending: false,
        fulfilled: false,
        rejected: false,
    },
    depots: defaultHashedData,
    vehicles: defaultHashedData,
    trailers: defaultHashedData,
    containers: defaultHashedData,
    drivers: defaultHashedData,
    devices: defaultHashedData,
    vehicleStatus: defaultHashedData,
    driverStatus: defaultHashedData,
};

export type StaticDataActionTypes =
    | AssetGroupsActionTypes
    | DepotsActionTypes
    | VehiclesActionTypes
    | TrailersActionTypes
    | ContainersActionTypes
    | DriversActionTypes
    | DevicesActionTypes
    | VehicleStatusActionTypes
    | DriverStatusActionTypes;

export const staticDataReducer = (
    state: StaticDataStoreState = defaultStoreState,
    action: StaticDataActionTypes
): StaticDataStoreState => {
    switch (action.type) {
        case AssetGroupsActionTypeKeys.ASSETGROUPS_PENDING:
            return {
                ...state,
                assetGroups: { ...state.assetGroups, pending: true },
            };
        case AssetGroupsActionTypeKeys.ASSETGROUPS_FULFILLED:
            return {
                ...state,
                assetGroups: {
                    data: action.payload,
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        case AssetGroupsActionTypeKeys.ASSETGROUPS_REJECTED:
            return {
                ...state,
                assetGroups: {
                    data: [],
                    pending: false,
                    fulfilled: false,
                    rejected: true,
                },
            };

        case DepotsActionTypeKeys.DEPOTS_PENDING:
            return { ...state, depots: { ...state.depots, pending: true } };
        case DepotsActionTypeKeys.DEPOTS_FULFILLED:
            return {
                ...state,
                depots: {
                    data: { array: action.payload, hash: keyBy(action.payload, 'id') },
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        case DepotsActionTypeKeys.DEPOTS_REJECTED:
            return {
                ...state,
                depots: { data: { array: [], hash: {} }, pending: false, fulfilled: false, rejected: true },
            };

        case VehiclesActionTypeKeys.VEHICLES_PENDING:
            return { ...state, vehicles: { ...state.vehicles, pending: true } };
        case VehiclesActionTypeKeys.VEHICLES_FULFILLED:
            return {
                ...state,
                vehicles: {
                    data: { array: action.payload.items, hash: keyBy(action.payload.items, 'id') },
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        case VehiclesActionTypeKeys.VEHICLES_REJECTED:
            return {
                ...state,
                vehicles: { data: { array: [], hash: {} }, pending: false, fulfilled: false, rejected: true },
            };

        case TrailersActionTypeKeys.TRAILERS_PENDING:
            return { ...state, trailers: { ...state.trailers, pending: true } };
        case TrailersActionTypeKeys.TRAILERS_FULFILLED:
            return {
                ...state,
                trailers: {
                    data: { array: action.payload.items, hash: keyBy(action.payload.items, 'id') },
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        case TrailersActionTypeKeys.TRAILERS_REJECTED:
            return {
                ...state,
                trailers: { data: { array: [], hash: {} }, pending: false, fulfilled: false, rejected: true },
            };

        case ContainersActionTypeKeys.CONTAINERS_PENDING:
            return {
                ...state,
                containers: { ...state.containers, pending: true },
            };
        case ContainersActionTypeKeys.CONTAINERS_FULFILLED:
            return {
                ...state,
                containers: {
                    data: { array: action.payload.items, hash: keyBy(action.payload.items, 'id') },
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        case ContainersActionTypeKeys.CONTAINERS_REJECTED:
            return {
                ...state,
                containers: { data: { array: [], hash: {} }, pending: false, fulfilled: false, rejected: true },
            };

        case DriversActionTypeKeys.DRIVERS_PENDING:
            return { ...state, drivers: { ...state.drivers, pending: true } };
        case DriversActionTypeKeys.DRIVERS_FULFILLED:
            return {
                ...state,
                drivers: {
                    data: { array: action.payload.items, hash: keyBy(action.payload.items, 'id') },
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        case DriversActionTypeKeys.DRIVERS_REJECTED:
            return {
                ...state,
                drivers: { data: { array: [], hash: {} }, pending: false, fulfilled: false, rejected: true },
            };

        case DevicesActionTypeKeys.DEVICES_PENDING:
            return {
                ...state,
                devices: { ...state.devices, pending: true },
            };
        case DevicesActionTypeKeys.DEVICES_FULFILLED:
            return {
                ...state,
                devices: {
                    data: { array: action.payload.items, hash: keyBy(action.payload.items, 'id') },
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        case DevicesActionTypeKeys.DEVICES_REJECTED:
            return {
                ...state,
                devices: { data: { array: [], hash: {} }, pending: false, fulfilled: false, rejected: true },
            };

        case VehicleStatusActionTypeKeys.VEHICLESTATUS_PENDING:
            return { ...state, vehicleStatus: { ...state.vehicleStatus, pending: true } };
        case VehicleStatusActionTypeKeys.VEHICLESTATUS_FULFILLED:
            return {
                ...state,
                vehicleStatus: {
                    data: { array: action.payload.items, hash: keyBy(action.payload.items, 'vehicleId') },
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        case VehicleStatusActionTypeKeys.VEHICLESTATUS_REJECTED:
            return {
                ...state,
                vehicleStatus: { data: { array: [], hash: {} }, pending: false, fulfilled: false, rejected: true },
            };

        case DriverStatusActionTypeKeys.MONITORING_DRIVERSTATUS_PENDING:
            return { ...state, driverStatus: { ...state.driverStatus, pending: true } };
        case DriverStatusActionTypeKeys.MONITORING_DRIVERSTATUS_REJECTED:
            return { ...state, driverStatus: { ...defaultHashedData, rejected: true } };
        case DriverStatusActionTypeKeys.MONITORING_DRIVERSTATUS_FULFILLED:
            return {
                ...state,
                driverStatus: {
                    data: { array: action.payload.items, hash: keyBy(action.payload.items, 'driver') },
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };

        default:
            return state;
    }
};
