import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { ResolvedDriverEventFieldValue } from '~/services/ApiClient/models';

export enum DriverEventSource {
    DriverEvent = 'driver-event',
    DriverSubActivity = 'driver-sub-activity',
}

export interface DriverEventFieldsSectionProps {
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
    driverEventId: number;
    driverEventTypeId?: number;
    driverEventSource?: DriverEventSource;
}

export interface StateProps {
    fields: ResolvedDriverEventFieldValue[];
    loadingFields: boolean;
    failedToLoadFields: boolean;
}

export interface DispatchProps {
    loadEventFields: () => void;
}

export interface DriverEventFieldsSectionInnerProps
    extends DriverEventFieldsSectionProps,
        StateProps,
        DispatchProps,
        InjectedTranslationProps {}
