import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ContainerHeaderInnerProps, ContainerHeaderProps } from './component';
import { ContainerHeaderComponent } from './component';

export const ContainerHeader = compose<ContainerHeaderInnerProps, ContainerHeaderProps>(
    setDisplayName('ContainerHeader'),
    withTranslation()
)(ContainerHeaderComponent);
