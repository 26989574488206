import withStyles from '@mui/styles/withStyles';
import { memo } from 'react';
import { compose, setDisplayName } from 'react-recompose';

import type { PopupWidgetInnerProps, PopupWidgetProps } from './component';
import { PopupWidgetComponent } from './component';
import { PopupWidgetStyleRules } from './styles';

export { PopupWidgetMinimizedMode } from './component';

export const PopupWidget = compose<PopupWidgetInnerProps, PopupWidgetProps>(
    setDisplayName('PopupWidget'),
    withStyles(PopupWidgetStyleRules),
    memo
)(PopupWidgetComponent);
