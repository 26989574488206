import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { TachoFileContextMenuInnerProps, TachoFileContextMenuProps } from './component';
import { TachoFileContextMenuComponent } from './component';

export const TachoFileContextMenu = compose<TachoFileContextMenuInnerProps, TachoFileContextMenuProps>(
    setDisplayName('TachoFileContextMenu'),
    withTranslation()
)(TachoFileContextMenuComponent);
