import { vehicleDetailsPaneStateSelector } from '~/data/monitoring';
import type { RetrievableData, StoreState } from '~/reducers';
import type { ResolvedMessage } from '~/services/ApiClient';

import type { LatestMessagesSectionProps } from './component';

export const latestMessagesStateSelector = (
    s: StoreState,
    { entry: { vehicle } }: LatestMessagesSectionProps
): RetrievableData<ResolvedMessage[] | undefined> | undefined => {
    return vehicleDetailsPaneStateSelector(s).latestMessagesSection.latestMessages[vehicle.id];
};

export const deletedMessagesStateSelector = (
    s: StoreState,
    { entry: { vehicle } }: LatestMessagesSectionProps
): number[] | undefined => {
    return vehicleDetailsPaneStateSelector(s).latestMessagesSection.deletedMessageIds[vehicle.id];
};
