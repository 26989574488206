import * as React from 'react';

import type { ResolvedMessageBody } from '~/services/ApiClient';
import { UnresolvedWorkflowMessageBody } from '~/services/ApiClient';

import { ConversationWorkflowBody } from './components/ConversationWorkflowBody';
import { UnresolvedConversationWorkflowBody } from './components/UnresolvedConversationWorkflowBody';

export interface WorkflowBodyContentProps {
    messageBody: ResolvedMessageBody;
    showEmptyFields: boolean;
}

export interface WorkflowBodyContentInnerProps extends WorkflowBodyContentProps {}

const WorkflowBodyContent: React.FC<WorkflowBodyContentInnerProps> = ({
    messageBody,
    showEmptyFields,
}: WorkflowBodyContentProps) => {
    if (messageBody.value instanceof UnresolvedWorkflowMessageBody) {
        return <UnresolvedConversationWorkflowBody messageBody={messageBody.value} showEmptyFields={showEmptyFields} />;
    }

    return <ConversationWorkflowBody messageBody={messageBody} readOnly showEmptyFields={showEmptyFields} />;
};
WorkflowBodyContent.displayName = 'WorkflowBodyContent';

export { WorkflowBodyContent };
