import * as React from 'react';

import { isEmpty } from '~/libs/utility';
import { predefinedUserPreferences } from '~/services/PredefinedUserPreferences';
import type { PreferencesDictionary, SharedKeyPreferences } from '~/services/SaveUserPreferences';
import { saveUserPreferencesService } from '~/services/SaveUserPreferences';

import type { UserPreferencesState } from '../../reducers';

export interface StateProps {
    userPreferencesState: UserPreferencesState;
}

export interface DispatchProps {
    retrieveUserPreferences: (keys: string[]) => void;
}

export interface HandleUserPreferencesStateUpdateProps {}

export interface HandleUserPreferencesStateUpdateInnerProps
    extends HandleUserPreferencesStateUpdateProps,
        StateProps,
        DispatchProps {}

export class HandleUserPreferencesStateUpdateComponent extends React.Component<HandleUserPreferencesStateUpdateInnerProps> {
    public componentDidUpdate(): void {
        this.retrieveRequiredUserPreferences();
        this.queueUserPreferencesForSaving();
    }

    public render(): React.ReactNode {
        return null;
    }

    private retrieveRequiredUserPreferences(): void {
        const shouldBeRetrievedKeys = Object.keys(this.props.userPreferencesState).filter(this.shouldBeRetrieved);
        if (shouldBeRetrievedKeys.length > 0) {
            this.props.retrieveUserPreferences(shouldBeRetrievedKeys);
        }
    }

    private shouldBeRetrieved = (key: string): boolean => {
        const userPreferencesKeyState = this.props.userPreferencesState[key];

        return (
            !userPreferencesKeyState.pending &&
            !userPreferencesKeyState.fulfilled &&
            !userPreferencesKeyState.rejected &&
            !userPreferencesKeyState.updated
        );
    };

    private queueUserPreferencesForSaving(): void {
        const userPreferencesToBeUpdated = Object.keys(this.props.userPreferencesState)
            .filter((key) => !predefinedUserPreferences[key])
            .reduce(
                (accumulator: PreferencesDictionary<SharedKeyPreferences>, key) => ({
                    ...accumulator,
                    [key]: {
                        data: this.props.userPreferencesState[key].actualData,
                        toBeSaved: this.props.userPreferencesState[key].updated,
                    },
                }),
                {}
            );

        if (!isEmpty(userPreferencesToBeUpdated)) {
            saveUserPreferencesService.update(userPreferencesToBeUpdated);
        }
    }
}
