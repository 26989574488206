import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationWidgetListClassKey =
    | 'statusIndicator'
    | 'listItemsContainer'
    | 'listItem'
    | 'groupedListItem'
    | 'daySeparator'
    | 'centeredContent';

export const ConversationWidgetListStyleRules: StyleRulesCallback<Theme, {}, ConversationWidgetListClassKey> = (
    theme: Theme
) =>
    createStyles({
        statusIndicator: {
            margin: theme.spacing(1.5, 0),
        },
        listItemsContainer: {
            margin: theme.spacing(1.5, 0),
        },
        listItem: {
            margin: theme.spacing(1.5, 1, 0),
        },
        groupedListItem: {
            marginTop: theme.spacing(0.5),
        },
        daySeparator: {
            marginBottom: theme.spacing(1.5),
        },
        centeredContent: {
            display: 'flex',
            justifyContent: 'center',
        },
    });
