import type { Dispatch } from 'redux';

import { updateUserPreferencesAction } from '~/data/userpreferences';

import {
    changeConversationMessageTextFocusAction,
    openConversationRejectedAction,
    openConversationSucceededAction,
} from '../../data';

import type { ConversationWidgetStateProps, ConversationWidgetsDispatchProps } from './component';
import type { ConversationWidgetsUserPreferences } from './preferences';
import { CONVERSATIONWIDGETS_USERPREFERENCES_KEY } from './preferences';
import type { ConversationWidgetsStoreState } from './reducers';

export const mapStateToProps = (
    conversationWidgetState: ConversationWidgetsStoreState,
    conversationWidgetsUserPreferences: ConversationWidgetsUserPreferences
): ConversationWidgetStateProps => {
    return {
        shouldOpenConversationForVehicleId: conversationWidgetState.shouldOpenConversationForVehicleId,
        openedConversations: conversationWidgetsUserPreferences.openedConversations,
        shouldOpenConversation: conversationWidgetState.shouldOpenConversation,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): ConversationWidgetsDispatchProps => {
    return {
        openConversation: (vehicleId: number) => {
            dispatch(
                updateUserPreferencesAction(CONVERSATIONWIDGETS_USERPREFERENCES_KEY, {
                    openedConversations: [{ vehicleId, isMinimized: false }],
                })
            );
            dispatch(changeConversationMessageTextFocusAction(vehicleId, true));
        },
        closeConversation: () => {
            dispatch(updateUserPreferencesAction(CONVERSATIONWIDGETS_USERPREFERENCES_KEY, { openedConversations: [] }));
        },
        openConversationSucceeded: () => {
            dispatch(openConversationSucceededAction());
        },
        openConversationRejected: () => {
            dispatch(openConversationRejectedAction());
        },
    };
};
