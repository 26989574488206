import makeStyles from '@mui/styles/makeStyles';

export type RefreshSceneBarClassKey = keyof ReturnType<typeof useStyles>;

export const useStyles = makeStyles((theme) => ({
    link: {
        marginLeft: theme.spacing(0.5),
        textDecoration: 'underline',
        fontWeight: 500,
        color: theme.palette.info.dark,
        cursor: 'pointer',
    },
}));
