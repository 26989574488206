import type { TFunction } from 'i18next';

import type { MonitoringTrailerEntry } from '~/data/monitoring';
import type { DisplayUserPreferencesTrailerDisplayFormat } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification, formatTrailerName } from '~/services/Formatters';

export const getTrailerSearchText = (
    entry: MonitoringTrailerEntry,
    trailerNameDisplayFormat: DisplayUserPreferencesTrailerDisplayFormat,
    t: TFunction
): string => {
    const { trailer } = entry;

    const name = formatTrailerName(trailer, trailerNameDisplayFormat);
    const type = trailer.reeferManufacturer ? t('reefer') : t('trailer');
    const manufacturer = formatClassification(t, ClassificationType.TrailerManufacturer, trailer.trailerManufacturer);
    const connection = entry.vehicleConnection ? t('connected') : t('not-connected');

    return `${name}|${type}|${manufacturer}|${connection}`.toLowerCase();
};
