import { memo } from 'react';
import { compose, setDisplayName } from 'react-recompose';

import type { DocumentViewerInnerProps, DocumentViewerProps } from './component';
import { DocumentViewerComponent } from './component';

export type { DocumentViewerFile, DocumentViewerFileWithContent } from './models';

export const DocumentViewer = compose<DocumentViewerInnerProps, DocumentViewerProps>(
    setDisplayName('DocumentViewer'),
    memo
)(DocumentViewerComponent);
