import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './constants';

export const VEHICLE_TYPES_ADMINISTRATION_USERPREFERENCES_KEY = 'vehicle-types-administration-grid-state';

export const defaultVehicleTypesAdministrationListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.NAME,
        ColumnName.DESCRIPTION,
        ColumnName.HEIGHT,
        ColumnName.WIDTH,
        ColumnName.LENGTH,
        ColumnName.WEIGHT,
        ColumnName.TYPE,
        ColumnName.AXLES,
        ColumnName.EMISSIONCLASS,
        ColumnName.HAZARDOUSGOODS,
        ColumnName.SPEEDLIMITMOTORWAY,
        ColumnName.SPEEDLIMITROAD,
        ColumnName.SPEEDLIMITCITY,
    ],
    visibleColumns: [
        ColumnName.NAME,
        ColumnName.DESCRIPTION,
        ColumnName.HEIGHT,
        ColumnName.WIDTH,
        ColumnName.LENGTH,
        ColumnName.WEIGHT,
        ColumnName.TYPE,
        ColumnName.AXLES,
        ColumnName.EMISSIONCLASS,
        ColumnName.HAZARDOUSGOODS,
        ColumnName.SPEEDLIMITMOTORWAY,
        ColumnName.SPEEDLIMITROAD,
        ColumnName.SPEEDLIMITCITY,
    ],
    columnWidths: [
        { columnName: ColumnName.NAME, width: 150 },
        { columnName: ColumnName.DESCRIPTION, width: 150 },
        { columnName: ColumnName.HEIGHT, width: 150 },
        { columnName: ColumnName.WIDTH, width: 150 },
        { columnName: ColumnName.LENGTH, width: 150 },
        { columnName: ColumnName.WEIGHT, width: 150 },
        { columnName: ColumnName.TYPE, width: 150 },
        { columnName: ColumnName.AXLES, width: 150 },
        { columnName: ColumnName.EMISSIONCLASS, width: 150 },
        { columnName: ColumnName.HAZARDOUSGOODS, width: 150 },
        { columnName: ColumnName.SPEEDLIMITMOTORWAY, width: 150 },
        { columnName: ColumnName.SPEEDLIMITROAD, width: 150 },
        { columnName: ColumnName.SPEEDLIMITCITY, width: 150 },
    ],
    sorting: [{ columnName: ColumnName.NAME, direction: GridSortingDirection.DESC }],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: false,
    filteringEnabled: false,
    filters: [],
};
