import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { StaticDataProvider } from '~/common/models';
import { StaticDataType } from '~/common/models';
import { retrieveAssetGroupsAction } from '~/data/assetgroups';
import { retrieveContainersAction } from '~/data/containers';
import { retrieveDepotsAction } from '~/data/depots';
import { retrieveDevicesAction } from '~/data/devices';
import { retrieveDriversAction } from '~/data/drivers';
import { retrieveDriverStatusAction } from '~/data/monitoring/actions';
import { retrieveTrailersAction } from '~/data/trailers';
import { retrieveVehiclesAction } from '~/data/vehicles';
import { retrieveVehicleStatusAction } from '~/data/vehiclestatus';
import type { StoreState } from '~/reducers';
import { reportError } from '~/reporting';
import { staticDataStoreStateSelector } from '~/selectors';

import type { StaticDataStoreState } from '../reducers';

import * as utils from './useStaticDataProvider.utils';

export const useStaticDataProvider = (): StaticDataProvider => {
    const dispatch = useDispatch();
    const staticDataState = useSelector((store: StoreState) => staticDataStoreStateSelector(store));

    const staticDataStateRef = React.useRef<StaticDataStoreState>(staticDataState);
    staticDataStateRef.current = staticDataState;

    return React.useMemo(() => {
        const isLoaded = (type: StaticDataType) => {
            return utils.isStaticDataLoaded(type, staticDataStateRef.current);
        };

        const retrieveAssetGroups = (forceLoad?: boolean) => {
            if (!isLoaded(StaticDataType.ASSETGROUPS) || forceLoad) {
                dispatch(retrieveAssetGroupsAction()).catch(reportError);
            }
        };

        const retrieveDepots = (forceLoad?: boolean) => {
            if (!isLoaded(StaticDataType.DEPOTS) || forceLoad) {
                dispatch(retrieveDepotsAction()).catch(reportError);
            }
        };

        const retrieveVehicles = (forceLoad?: boolean) => {
            if (!isLoaded(StaticDataType.VEHICLES) || forceLoad) {
                dispatch(retrieveVehiclesAction()).catch(reportError);
            }
        };

        const retrieveTrailers = (forceLoad?: boolean) => {
            if (!isLoaded(StaticDataType.TRAILERS) || forceLoad) {
                dispatch(retrieveTrailersAction()).catch(reportError);
            }
        };

        const retrieveContainers = (forceLoad?: boolean) => {
            if (!isLoaded(StaticDataType.CONTAINERS) || forceLoad) {
                dispatch(retrieveContainersAction()).catch(reportError);
            }
        };

        const retrieveDrivers = (forceLoad?: boolean) => {
            if (!isLoaded(StaticDataType.DRIVERS) || forceLoad) {
                dispatch(retrieveDriversAction()).catch(reportError);
            }
        };

        const retrieveDevices = (forceLoad?: boolean) => {
            if (!isLoaded(StaticDataType.DEVICES) || forceLoad) {
                dispatch(retrieveDevicesAction()).catch(reportError);
            }
        };

        const retrieveVehicleStatus = (forceLoad?: boolean) => {
            if (!isLoaded(StaticDataType.VEHICLESTATUS) || forceLoad) {
                dispatch(retrieveVehicleStatusAction()).catch(reportError);
            }
        };

        const retrieveDriverStatus = (forceLoad?: boolean) => {
            if (!isLoaded(StaticDataType.DRIVERSTATUS) || forceLoad) {
                dispatch(retrieveDriverStatusAction()).catch(reportError);
            }
        };

        return {
            isLoaded,
            retrieveAssetGroups,
            retrieveContainers,
            retrieveDepots,
            retrieveDevices,
            retrieveDrivers,
            retrieveDriverStatus,
            retrieveTrailers,
            retrieveVehicles,
            retrieveVehicleStatus,
        };
    }, [dispatch]);
};
