import type { FieldValues } from './component';
import { MonthlyRadioButtonsTypes } from './models';

export const getRecurrenceValue = (data: FieldValues): number => {
    if (data.frequencyDay) {
        return data.frequencyDay;
    }
    if (data.frequencyWeek) {
        return data.frequencyWeek;
    }
    if (data.radioButtonsFrequencyMonth === MonthlyRadioButtonsTypes.Month) {
        return data.frequencyMonthForMonthsOption;
    }
    if (data.radioButtonsFrequencyMonth === MonthlyRadioButtonsTypes.Day) {
        return data.frequencyMonthForDaysOption;
    }

    return 1;
};
