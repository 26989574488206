import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ColumnSelectorInnerProps, ColumnSelectorProps } from './component';
import { ColumnSelectorComponent } from './component';
import { ColumnSelectorStyleRules } from './styles';

export * from './models';

export const ColumnSelector = compose<ColumnSelectorInnerProps, ColumnSelectorProps>(
    setDisplayName('ColumnSelector'),
    withTranslation(),
    withStyles(ColumnSelectorStyleRules),
    React.memo
)(ColumnSelectorComponent);
