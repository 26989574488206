import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { LaborSectionInnerProps, LaborSectionProps } from './component';
import { LaborSectionComponent } from './component';

export const LaborSection = compose<LaborSectionInnerProps, LaborSectionProps>(
    setDisplayName('LaborSection'),
    withTranslation()
)(LaborSectionComponent);
