import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { VehicleHeaderInnerProps, VehicleHeaderProps } from './component';
import { VehicleHeaderComponent } from './component';
import { VehicleHeaderStyleRules } from './styles';

export const VehicleHeader = compose<VehicleHeaderInnerProps, VehicleHeaderProps>(
    setDisplayName('VehicleHeader'),
    withDisplayPreferences(),
    withTranslation(),
    withStyles(VehicleHeaderStyleRules)
)(VehicleHeaderComponent);
