export const formatBytes = (bytes: number, decimals: number = 2): string => {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;

    const dm = decimals < 0 ? 0 : decimals;

    const sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const formatted: string = `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;

    return formatted;
};
