import makeStyles from '@mui/styles/makeStyles';

import type { SceneBarTemplateProps } from './component';
import { SceneBarState } from './models';

export const useStyles = makeStyles((theme) => ({
    wrapper: (props: SceneBarTemplateProps) => ({
        width: '100%',
        height: theme.spacing(3.5),
        display: 'flex',
        backgroundColor:
            props.state === SceneBarState.WARNING
                ? theme.palette.warning.light
                : props.state === SceneBarState.NORMAL
                  ? theme.palette.background.paper
                  : theme.palette.error.light,
        padding: theme.spacing(0.5, 2),
        justifyContent: 'space-between',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
        },
    }),
    closeIcon: (props: SceneBarTemplateProps) => ({
        fontSize: 12,
        color: props.state === SceneBarState.ERROR ? theme.palette.background.paper : theme.palette.text.primary,
    }),
    warningIcon: {
        color: theme.palette.warning.dark,
        fontSize: 12,
    },
    errorIcon: {
        color: theme.palette.background.paper,
        fontSize: 12,
    },
    message: (props: SceneBarTemplateProps) => ({
        color: props.state === SceneBarState.ERROR ? theme.palette.background.paper : theme.palette.text.primary,
        marginLeft: theme.spacing(1),
    }),
}));
