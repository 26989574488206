import { Checkbox, FormControl, FormHelperText, Tooltip } from '@mui/material';
import type { FC } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ClassificationsInput } from '~/components/InputFields';
import type { ClassificationsInputProps } from '~/components/InputFields';
import { SettingsValueSource } from '~/services/ApiClient';

import type { BaseSettingsProps } from '../model';

import { useStyles } from './styles';

export interface ClassificationSettingsFieldProps
    extends Omit<ClassificationsInputProps, 'disabled' | 'disabledReason'>,
        BaseSettingsProps<number> {}

export interface ClassificationSettingsFieldInnerProps extends ClassificationSettingsFieldProps {}

const ClassificationSettingsFieldComponent: FC<ClassificationSettingsFieldInnerProps> = (props) => {
    const {
        defaultSettingValue,
        disabled,
        disabledReason,
        settingsSourceFieldName,
        settingsSourceRules,
        ...restInputProps
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const {
        field: { ref, value: settingsValueSource, onChange: onSettingsValueSourceChange, ...fieldProps },
        fieldState: { error },
    } = useController({
        name: settingsSourceFieldName,
        rules: settingsSourceRules,
    });
    const { field } = useController({ name: restInputProps.fieldName });

    const onSettingsSourceCheckboxChanged = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        onSettingsValueSourceChange(checked ? SettingsValueSource.Default : SettingsValueSource.Asset);
        if (checked) {
            field.onChange(defaultSettingValue);
        }
    };

    return (
        <FormControl error={!!error} className={classes.root}>
            <div className={classes.fieldContainer}>
                <ClassificationsInput
                    {...restInputProps}
                    disabled={disabled || settingsValueSource === SettingsValueSource.Default}
                    disabledReason={disabled ? disabledReason : t('default-service-value')}
                />
                <Tooltip
                    title={
                        disabled
                            ? disabledReason
                            : settingsValueSource === SettingsValueSource.Default
                              ? t('switch-to-no-default')
                              : t('switch-to-default')
                    }
                    data-id={`disabled-tooltip-${restInputProps.dataId}`}
                    placement="right"
                >
                    <span>
                        <Checkbox
                            {...fieldProps}
                            data-id={`${restInputProps.dataId}-default`}
                            className={classes.defaultCheckbox}
                            checked={settingsValueSource === SettingsValueSource.Default}
                            onChange={onSettingsSourceCheckboxChanged}
                            disabled={disabled}
                            inputRef={ref}
                        />
                    </span>
                </Tooltip>
            </div>
            {error && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
    );
};

ClassificationSettingsFieldComponent.displayName = 'ClassificationField';
export default ClassificationSettingsFieldComponent;
