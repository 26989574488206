import { Toolbar } from '@devexpress/dx-react-grid-material-ui';
import classnames from 'classnames';
import type { FC } from 'react';

import { useStyles } from './styles';

export interface ToolbarRootInnerProps extends Toolbar.RootProps {
    isHidden?: boolean;
}

const ToolbarRootComponent: FC<ToolbarRootInnerProps> = (props) => {
    const { isHidden = false, ...restProps } = props;

    const classes = useStyles();

    return (
        <Toolbar.Root
            {...restProps}
            className={classnames(classes.toolbar, { [classes.hidden]: isHidden })}
            data-id="toolbar-root"
        />
    );
};

ToolbarRootComponent.displayName = 'ToolbarRoot';
export { ToolbarRootComponent };
