import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type {
    RtdsSchedulesBooleanStatesFormatterProps,
    RtdsSchedulesBooleanStatesFormatterPropsInnerProps,
} from './component';
import { RtdsSchedulesBooleanStatesFormatterComponent } from './component';

export const RtdsSchedulesBooleanStatesFormatter = compose<
    RtdsSchedulesBooleanStatesFormatterPropsInnerProps,
    RtdsSchedulesBooleanStatesFormatterProps
>(
    setDisplayName('BooleanStatesFormatter'),
    withTranslation()
)(RtdsSchedulesBooleanStatesFormatterComponent);
