import type { SelectChangeEvent } from '@mui/material';
import {
    Box,
    CircularProgress,
    Divider,
    Fab,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import type { StyledComponentProps, WithStyles } from '@mui/styles';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ConfirmationDialog } from '~/components/Dialogs';
import { SendIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { useUserPreferences } from '~/components/UserPreferences';
import type { NumericDictionary } from '~/libs/utility';
import { groupBy, isUndefined, keyBy, map } from '~/libs/utility';
import { logEvent } from '~/services/Analytics';
import type {
    OptimisticOutgoingConversationMessage,
    ResolvedMessageBody,
    WorkflowFormDefinition,
} from '~/services/ApiClient';
import {
    BooleanWorkflowFieldValue,
    DateTimeWorkflowFieldValue,
    DurationWorkflowFieldValue,
    EnumWorkflowFieldValue,
    NumericWorkflowFieldValue,
    OptimisticOutgoingConversationMessageStatus,
    PositionWorkflowFieldValue,
    RawWorkflowFieldValue,
    SendMessageOptions,
    TextWorkflowFieldValue,
    WorkflowMessageBody,
    createApiModel,
} from '~/services/ApiClient';
import { compareFactory, stringComparer } from '~/services/Sorting';

import type { FieldValueDictionary } from '../../models';
import type { ConversationUserPreferences } from '../../preferences';
import { CONVERSATION_USERPREFERENCES_KEY } from '../../preferences';
import { SendMessageFooter } from '../SendMessageFooter';
import { ConversationWorkflowBody } from '../WorkflowBodyContent';

import { buildWorkflowConversationMessageBody } from './buildWorkflowConversationMessageBody';
import type { SendWorkflowMessageFormDispatchProps, SendWorkflowMessageFormStateProps } from './redux';
import type { SendWorkflowMessageFormClassKey } from './styles';

export interface SendWorkflowMessageFormProps {
    onClose: () => void;
    onSend: (messageBody: WorkflowMessageBody, options: SendMessageOptions) => void;
    onSendOptionsWarning: (messageText?: string, canDismiss?: boolean) => void;
    shouldClose: boolean;
    onCloseRejected: () => void;
    messageStatus?: OptimisticOutgoingConversationMessageStatus;
    sendButtonDisabled?: boolean;
}

export interface SendWorkflowMessageFormOuterProps
    extends SendWorkflowMessageFormProps,
        StyledComponentProps<SendWorkflowMessageFormClassKey> {}

export interface InternalState {
    resolvedMessageBody?: ResolvedMessageBody;
    selectedWorkflowDefinitionId: number;
    showWarnings: boolean;
    sendingMessage?: OptimisticOutgoingConversationMessage;
}

export interface SendWorkflowMessageFormInnerProps
    extends SendWorkflowMessageFormProps,
        SendWorkflowMessageFormDispatchProps,
        SendWorkflowMessageFormStateProps,
        InjectedTranslationProps,
        WithStyles<SendWorkflowMessageFormClassKey> {}

export const defaultInternalState = {
    selectedWorkflowDefinitionId: -1,
    resolvedMessageBody: undefined,
    showWarnings: true,
};

const buildFiledValueDictionary = (body?: WorkflowMessageBody) => {
    const output: FieldValueDictionary = {};

    if (!body) {
        return output;
    }

    body.fieldValues.forEach((field) => {
        output[field.fieldId] = null;

        if (field instanceof RawWorkflowFieldValue && !isUndefined(field.value)) {
            output[field.fieldId] = field.value;
        } else if (field instanceof TextWorkflowFieldValue && !isUndefined(field.value)) {
            output[field.fieldId] = field.value;
        } else if (field instanceof NumericWorkflowFieldValue && !isUndefined(field.value)) {
            output[field.fieldId] = field.value;
        } else if (field instanceof BooleanWorkflowFieldValue && !isUndefined(field.value)) {
            output[field.fieldId] = field.value;
        } else if (field instanceof DateTimeWorkflowFieldValue && !isUndefined(field.value)) {
            output[field.fieldId] = field.value;
        } else if (field instanceof DurationWorkflowFieldValue && !isUndefined(field.value)) {
            output[field.fieldId] = field.value;
        } else if (field instanceof EnumWorkflowFieldValue && !isUndefined(field.optionId)) {
            output[field.fieldId] = field.optionId;
        } else if (field instanceof PositionWorkflowFieldValue && !isUndefined(field.value)) {
            output[field.fieldId] = field.value;
        }
    });

    return output;
};

const mapFieldValues = (body: WorkflowMessageBody, fieldValues: FieldValueDictionary): WorkflowMessageBody => {
    if (!body || !fieldValues) {
        return {} as WorkflowMessageBody;
    }

    const newFieldValues = body.fieldValues.map((field) => {
        const fieldValue = fieldValues[field.fieldId];

        if (field instanceof RawWorkflowFieldValue) {
            const newValue = new RawWorkflowFieldValue();
            newValue.init({ ...field, value: fieldValue });

            return newValue.toJSON();
        } else if (field instanceof TextWorkflowFieldValue) {
            const newValue = new TextWorkflowFieldValue();
            newValue.init({ ...field, value: fieldValue });

            return newValue.toJSON();
        } else if (field instanceof NumericWorkflowFieldValue) {
            const newValue = new NumericWorkflowFieldValue();
            newValue.init({ ...field, value: fieldValue });

            return newValue.toJSON();
        } else if (field instanceof BooleanWorkflowFieldValue) {
            const newValue = new BooleanWorkflowFieldValue();
            newValue.init({ ...field, value: fieldValue });

            return newValue.toJSON();
        } else if (field instanceof DateTimeWorkflowFieldValue) {
            const newValue = new DateTimeWorkflowFieldValue();
            newValue.init({ ...field, value: fieldValue });

            return newValue.toJSON();
        } else if (field instanceof DurationWorkflowFieldValue) {
            const newValue = new DurationWorkflowFieldValue();
            newValue.init({ ...field, value: fieldValue });

            return newValue.toJSON();
        } else if (field instanceof EnumWorkflowFieldValue) {
            const newValue = new EnumWorkflowFieldValue();
            newValue.init({ ...field, optionId: fieldValue });

            return newValue.toJSON();
        } else if (field instanceof PositionWorkflowFieldValue) {
            const newValue = new PositionWorkflowFieldValue();
            newValue.init({ ...field, value: fieldValue });

            return newValue.toJSON();
        }

        return { ...field, value: fieldValue };
    });

    const newWorkflowMessageBody = new WorkflowMessageBody();
    newWorkflowMessageBody.init({ formDefinitionId: body.formDefinitionId, fieldValues: newFieldValues });

    return newWorkflowMessageBody;
};

export const SendWorkflowMessageFormComponent: React.FC<SendWorkflowMessageFormInnerProps> = (props) => {
    const {
        onClose,
        onSend,
        onSendOptionsWarning,
        shouldClose,
        onCloseRejected,
        sendButtonDisabled,
        classes,
        t,
        outgoingWorkflowFormDefinitions,
        shouldFetchWorkflowFormDefinitions,
        retrieveLatestWorkflowFormDefinitions,
        messageStatus,
    } = props;

    const methods = useForm<FieldValueDictionary>({
        mode: 'all',
        defaultValues: {},
        shouldUnregister: true,
    });

    const [conversationUserPreferences, setConversationUserPreferences] =
        useUserPreferences<ConversationUserPreferences>(CONVERSATION_USERPREFERENCES_KEY);

    const [state, setState] = React.useState<InternalState>(defaultInternalState);

    const [sendMessageOptions, setSendMessageOptions] = React.useState(
        createApiModel(SendMessageOptions, { ttl: conversationUserPreferences.ttl })
    );

    React.useEffect(() => {
        if (shouldFetchWorkflowFormDefinitions) {
            retrieveLatestWorkflowFormDefinitions();
        }
    }, [retrieveLatestWorkflowFormDefinitions, shouldFetchWorkflowFormDefinitions]);

    const workflowFormDefinitionDictionary: NumericDictionary<WorkflowFormDefinition> = React.useMemo(
        () => keyBy(outgoingWorkflowFormDefinitions, 'id'),
        [outgoingWorkflowFormDefinitions]
    );

    const changeWorkflowFormDefinitionCallback = React.useCallback(
        (event: SelectChangeEvent<number>) => {
            let workflowMessageBody: ResolvedMessageBody | undefined;
            let fieldValues: FieldValueDictionary = {};

            const workflowFormDefinitionId = event?.target.value as number;

            if (workflowFormDefinitionId) {
                const workflowFormDefinition = workflowFormDefinitionDictionary[workflowFormDefinitionId];

                if (workflowFormDefinition?.containsMctElements) {
                    event.preventDefault();
                    return;
                }

                workflowMessageBody = buildWorkflowConversationMessageBody(workflowFormDefinition);
                fieldValues = buildFiledValueDictionary(workflowMessageBody?.value as WorkflowMessageBody);
            }

            methods.reset(fieldValues);

            setState((s) => {
                return {
                    ...s,
                    selectedWorkflowDefinitionId: workflowFormDefinitionId,
                    resolvedMessageBody: workflowMessageBody,
                    showWarnings: false,
                };
            });
        },
        [setState, workflowFormDefinitionDictionary, methods]
    );

    const sendOptionsAreDefault = React.useCallback(
        (sendOptions: SendMessageOptions) =>
            conversationUserPreferences.ttl === sendOptions.ttl && !sendOptions.sendAfter,
        [conversationUserPreferences]
    );

    const resetSendOptions = React.useCallback(
        (keepLastTTL: boolean) => {
            setSendMessageOptions(
                createApiModel(SendMessageOptions, {
                    ttl: keepLastTTL ? sendMessageOptions.ttl : conversationUserPreferences.ttl,
                })
            );
        },
        [conversationUserPreferences.ttl, sendMessageOptions.ttl]
    );

    const submitMessageCallback = React.useCallback(
        (fieldValues: FieldValueDictionary) => {
            const workflowMessageBody = state?.resolvedMessageBody?.value as WorkflowMessageBody;

            onSend(mapFieldValues(workflowMessageBody, fieldValues), sendMessageOptions);

            const rememberThisTTL =
                !sendOptionsAreDefault(sendMessageOptions) && conversationUserPreferences.rememberLastTtl;

            if (rememberThisTTL) {
                setConversationUserPreferences({ ttl: sendMessageOptions.ttl });
            }

            resetSendOptions(rememberThisTTL);
        },
        [
            state,
            onSend,
            sendMessageOptions,
            resetSendOptions,
            sendOptionsAreDefault,
            conversationUserPreferences.rememberLastTtl,
            setConversationUserPreferences,
        ]
    );

    const onSendOptionsChangeCallback = React.useCallback((options) => {
        setSendMessageOptions(options);
    }, []);

    const onSendOptionsWarningCallback = React.useCallback(
        (messageText?: string, canDismiss?: boolean) => {
            onSendOptionsWarning(messageText, canDismiss);
        },
        [onSendOptionsWarning]
    );

    const selectWorkflowDefinitionLabel = t('select-workflow-definition-label');

    const isSending = messageStatus === OptimisticOutgoingConversationMessageStatus.Sending;
    const { isDirty } = methods.formState;

    const showConfirmationOnClose = !isSending && isDirty;

    React.useEffect(() => {
        if (
            (shouldClose && !showConfirmationOnClose) ||
            messageStatus === OptimisticOutgoingConversationMessageStatus.Pending
        ) {
            onClose();
        }
    }, [shouldClose, showConfirmationOnClose, onClose, messageStatus]);

    const formContent =
        state.selectedWorkflowDefinitionId === -1 ? (
            <Typography className={classes.formMessage} variant="caption" color="textSecondary">
                {t('select-workflow-definition-description')}
            </Typography>
        ) : (
            <div className={classes.messageFields} data-id="workflow-message-fields">
                {state.resolvedMessageBody && (
                    <ConversationWorkflowBody
                        messageBody={state.resolvedMessageBody}
                        readOnly={false}
                        disabled={isSending}
                    />
                )}
            </div>
        );

    const renderWorkflowDefinitionMenuItems = React.useMemo(() => {
        const filterDuplicatesAndSort = (
            workflowFormDefinitionsToFilter: WorkflowFormDefinition[]
        ): WorkflowFormDefinition[] => {
            // group definitions by formNumber
            const definitionGroups = groupBy(workflowFormDefinitionsToFilter, 'number');

            // return Array of groups filtered from duplicates (with highest version number)
            const filteredFromDuplicates = map(definitionGroups, (groupByName: WorkflowFormDefinition[]) => {
                // return filtered group with highest version number
                return groupByName.reduce((prev: WorkflowFormDefinition, current: WorkflowFormDefinition) => {
                    // returns object with highest version number (latest)
                    return prev.version > current.version ? prev : current;
                });
            });

            // sort wf by name using defComparer created with stringComparer
            const defComparer = compareFactory((def: WorkflowFormDefinition) => def.name, stringComparer);
            return filteredFromDuplicates.sort(defComparer);
        };

        return filterDuplicatesAndSort(outgoingWorkflowFormDefinitions).map((item) => {
            return (
                <MenuItem
                    key={item.id}
                    value={item.id}
                    className={classes.workflowDefinitionSelectOption}
                    data-id={`option:${item.id}`}
                    disabled={item.containsMctElements}
                >
                    <Tooltip title={item.containsMctElements ? t('workflow-definition-mct-disabled') : ''}>
                        <Box flex={1}>{item.name}</Box>
                    </Tooltip>
                </MenuItem>
            );
        });
    }, [classes.workflowDefinitionSelectOption, outgoingWorkflowFormDefinitions, t]);

    React.useEffect(() => {
        if (outgoingWorkflowFormDefinitions.find((d) => d.containsMctElements)) {
            logEvent('errors', 'workflow-definition-mct', 'MCT fields in workflow definition');
        }
    }, [outgoingWorkflowFormDefinitions]);

    const formIsValid = methods.formState.isValid;

    return (
        <>
            <ConfirmationDialog
                dataId="confirm-close-send-workflow-form"
                title={t('discard-draft')}
                open={shouldClose && showConfirmationOnClose}
                confirmationActionText={t('discard')}
                onConfirm={onClose}
                onCancel={onCloseRejected}
            >
                <Typography>{t('confirm-discard-draft')}</Typography>
            </ConfirmationDialog>

            <div data-id="send-workflow-message-form" className={classes.root}>
                <FormControl
                    data-id="select-workflow-definition"
                    variant="outlined"
                    size="small"
                    className={classes.workflowDefinitionSelect}
                    disabled={isSending}
                >
                    <InputLabel id="select-workflow-definition-label">{selectWorkflowDefinitionLabel}</InputLabel>
                    <Select
                        variant="outlined"
                        labelId="select-workflow-definition-label"
                        onChange={changeWorkflowFormDefinitionCallback}
                        label={selectWorkflowDefinitionLabel}
                        value={state.selectedWorkflowDefinitionId}
                    >
                        <MenuItem value={-1}>
                            <em>{t('select-workflow-definition-placeholder')}</em>
                        </MenuItem>
                        {renderWorkflowDefinitionMenuItems}
                    </Select>
                </FormControl>

                <Divider />

                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(submitMessageCallback)}
                        data-id="send-wf-form"
                        className={classes.form}
                    >
                        {formContent}
                        {state.selectedWorkflowDefinitionId !== -1 && (
                            <Fab
                                color="secondary"
                                classes={{
                                    disabled: classes.sendFabDisabled,
                                }}
                                className={classes.sendFab}
                                type="submit"
                                disabled={
                                    sendButtonDisabled ||
                                    state.selectedWorkflowDefinitionId === -1 ||
                                    !formIsValid ||
                                    isSending
                                }
                                data-id="send-workflow-message"
                            >
                                {isSending && <CircularProgress size="100%" className={classes.circularProgress} />}
                                <SendIcon className={classes.sendIcon} />
                            </Fab>
                        )}
                    </form>
                </FormProvider>
            </div>
            <Divider />
            <div className={classes.footer}>
                <SendMessageFooter
                    options={sendMessageOptions}
                    preferences={conversationUserPreferences}
                    onOptionsChange={onSendOptionsChangeCallback}
                    onWarning={onSendOptionsWarningCallback}
                />
            </div>
        </>
    );
};
