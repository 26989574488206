import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { SearchHeaderInnerProps, SearchHeaderProps } from './component';
import { SearchHeaderComponent } from './component';
import { SearchHeaderStyleRules } from './styles';

export const SearchHeader = compose<SearchHeaderInnerProps, SearchHeaderProps>(
    setDisplayName('SearchHeader'),
    withTranslation(),
    withStyles(SearchHeaderStyleRules)
)(SearchHeaderComponent);
