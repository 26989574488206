export enum MapProfile {
    PTV_DEFAULT = 'gravelpit',
    PTV_SILICA = 'silica',
}

export const mapProfileSelector = (mapProfile: MapProfile): (() => MapProfile) => {
    return () => {
        return mapProfile;
    };
};
