import { compose, setDisplayName } from 'react-recompose';

import type { SingleSignOnInnerProps, SingleSignOnProps } from './component';
import { SingleSignOnComponent } from './component';
import { authenticateReducer } from './components/Authenticate';
import type { AuthenticateStoreState } from './components/Authenticate/reducers';

export const SingleSignOn = compose<SingleSignOnInnerProps, SingleSignOnProps>(setDisplayName('SingleSignOn'))(
    SingleSignOnComponent
);

export const singleSignOnReducer = authenticateReducer;

export type SingleSignOnStoreState = AuthenticateStoreState;
