import type { WithStyles } from '@mui/styles';
import moment from 'moment';
import * as React from 'react';

import { DriverActivityKeys } from '~/services/ApiClient';

import type { DriverActivityEntry } from '../../../../../../models';
import { ActivitySurface } from '../../../../../ActivitySurface';
import { filterActivitiesThatAreInInterval } from '../../../../filterActivitiesThatAreInInterval';
import { getActivitySurfaceStyles } from '../../../../getActivitySurfaceStyles';
import { graphDurationInSeconds } from '../../constants';
import type { Cursor } from '../../models';
import { zoomPopupWidth } from '../../styles';

import type { ActivitiesBarClassKey } from './styles';

export interface ActivitiesBarProps {
    activityEntries?: DriverActivityEntry[];
    currentTime: Date;
    cursor: Cursor;
}

export interface ActivitiesBarInnerProps extends ActivitiesBarProps, WithStyles<ActivitiesBarClassKey> {}

const filterActivities = (graphEnd: Date, activities: DriverActivityEntry[]) =>
    filterActivitiesThatAreInInterval(
        {
            start: moment(graphEnd).subtract(graphDurationInSeconds, 'seconds').toDate(),
            stop: graphEnd,
        },
        activities
    );

export const ActivitiesBarComponent: React.FC<ActivitiesBarInnerProps> = ({
    activityEntries,
    currentTime,
    cursor,
    classes,
}) => {
    const cursorDurationInSeconds = moment(cursor.stop).diff(moment(cursor.start), 'seconds');
    const graphEnd: Date = moment(cursor.start)
        .add(graphDurationInSeconds / 2 + cursorDurationInSeconds / 2, 'seconds')
        .toDate();

    const activitySurfaces =
        activityEntries &&
        filterActivities(graphEnd, activityEntries).map((entry) => {
            const activitySurfaceStyles = getActivitySurfaceStyles(zoomPopupWidth, graphDurationInSeconds, graphEnd, {
                start: entry.startDate,
                stop: entry.stopDate || currentTime,
            });

            return (
                <ActivitySurface
                    key={entry.id}
                    className={classes.activitySurface}
                    activity={DriverActivityKeys[entry.activityType.key.toUpperCase()]}
                    isActive={!entry.stopDate}
                    style={activitySurfaceStyles}
                />
            );
        });

    const cursorStyles = getActivitySurfaceStyles(zoomPopupWidth, graphDurationInSeconds, graphEnd, cursor);
    const cursorElement = <div className={classes.cursor} style={cursorStyles} />;

    return (
        <div className={classes.root}>
            {activitySurfaces}
            {cursorElement}
        </div>
    );
};
