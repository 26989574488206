import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const DoubleCheckIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M0.41 13.41L6 19 L7.41 17.58L1.83 12M22.24 5.58L11.66 16.17L7.5 12L6.07 13.41L11.66 19L23.66 7M18 7L16.59 5.58L10.24 11.93L11.66 13.34L18 7Z" />
            <path fill="none" d="M0 0h18v18H0z" />
        </SvgIcon>
    );
};
DoubleCheckIcon.displayName = 'DoubleCheckIcon';

export { DoubleCheckIcon };
