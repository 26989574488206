import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { CustomerOptionChildInnerProps, CustomerOptionChildProps } from './component';
import { CustomerOptionChildComponent } from './component';
import { CustomerOptionChildStyleRules } from './styles';

export const CustomerOptionChild = compose<CustomerOptionChildInnerProps, CustomerOptionChildProps>(
    setDisplayName('CustomerOptionChild'),
    withStyles(CustomerOptionChildStyleRules)
)(CustomerOptionChildComponent);
