import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DoorInnerProps, DoorProps } from './component';
import { DoorComponent } from './component';
import { DoorStyleRules } from './styles';

export const Door = compose<DoorInnerProps, DoorProps>(
    setDisplayName('Door'),
    withStyles(DoorStyleRules),
    withTranslation()
)(DoorComponent);
