import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { Action, BaseAction } from '~/types';

export enum ActionTypeKeys {
    HISTORY_CLEAR_DATA = 'HISTORY_CLEAR_DATA',
    HISTORY_CHANGE_DATETIMERANGE = 'HISTORY_CHANGE_DATETIMERANGE',
}

export type ActionTypes =
    | BaseAction<ActionTypeKeys.HISTORY_CLEAR_DATA>
    | Action<ActionTypeKeys.HISTORY_CHANGE_DATETIMERANGE, DateTimeRange>;
