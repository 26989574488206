import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { introCarouselStoreStateSelector } from '~/selectors';

import { withUserData } from '../AuthenticationData';

import IntroCarouselComponent from './component';
import type { IntroCarouselInnerProps } from './models';
import { INTROCAROUSEL_USERPREFERENCES_KEY, requiredIntroCarouselUserPreferences } from './preferences';
import { introCarouselReducer as reducer } from './reducers';
import { mapDispatchToProps, mapStateToProps, mergeProps } from './redux';

export * from './data';

export type { IntroCarouselStoreState } from './reducers';
export const introCarouselReducer = reducer;
export const IntroCarousel = compose<IntroCarouselInnerProps, {}>(
    ensureUserPreferences([requiredIntroCarouselUserPreferences], false),
    withUserData(),
    connect(
        createSelector(
            introCarouselStoreStateSelector,
            keyDataStateSelectorFactory(INTROCAROUSEL_USERPREFERENCES_KEY),
            mapStateToProps
        ),
        mapDispatchToProps,
        mergeProps
    )
)(IntroCarouselComponent);
