import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import { FilterOptionsCellComponent } from './component';
import type { FilterOptionsCellInnerProps, FilterOptionsCellProps } from './models';
import { FilterOptionsCellStyleRules } from './styles';

export const FilterOptionsCell = compose<FilterOptionsCellInnerProps, FilterOptionsCellProps>(
    setDisplayName('FilterOptionsCell'),
    withTranslation(),
    withStyles(FilterOptionsCellStyleRules)
)(FilterOptionsCellComponent);
