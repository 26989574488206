import { createSelector } from 'reselect';

import { isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';

import type { AlertDefinitionsState } from './models';

export const AlertDefinitionsSceneStoreState = (state: StoreState): AlertDefinitionsState =>
    state.alertDefinitionsScene;

interface AlertDefinitionEditorStateSelectorArgs {
    alertDefinitionId?: number;
}

export const getAlertDefinitionEditorState = createSelector(
    AlertDefinitionsSceneStoreState,
    (_: StoreState, args: AlertDefinitionEditorStateSelectorArgs) => args,
    (state, { alertDefinitionId }) => ({
        alertDefinition: isUndefined(alertDefinitionId) ? undefined : state.item,
    })
);

export const createAlertDefinitionEditorState = (args: AlertDefinitionEditorStateSelectorArgs) => (state: StoreState) =>
    getAlertDefinitionEditorState(state, args);
