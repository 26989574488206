import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import type { LoginStatus } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

const formatDriverHoursLoginStatusInternal = (t: SingleTFunction, value: LoginStatus): string =>
    t(`login-status-${value}`);

const formatDriverHoursLoginStatusMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((value: LoginStatus): string => formatDriverHoursLoginStatusInternal(t, value))
);

export const formatDriverHoursLoginStatus = (t: SingleTFunction, value: LoginStatus): string =>
    formatDriverHoursLoginStatusMemoized(t)(value);
