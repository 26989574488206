import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { FC, MouseEvent } from 'react';
import { useCallback, useState } from 'react';

import type { DropDownMenuProps } from './models';

const DropDownMenu: FC<DropDownMenuProps> = (props) => {
    const { menuButton, menuOptions, chartRef } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    return (
        <div>
            <div onClick={handleClick} data-id="menu-button" ref={chartRef}>
                {menuButton}
            </div>
            <Menu
                id="drop-down-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {menuOptions.map((option) => (
                    <MenuItem key={option.id} onClick={handleClose} data-id={`menu-item-${option.id}`}>
                        {option.element}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

DropDownMenu.displayName = 'DropDownMenu';
export default DropDownMenu;
