import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const HISTORYSUBPAGE_PREFERENCES_KEY = 'history-subpage-state';

export interface HistorySubpageUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {}

export const defaultHistorySubpageUserPreferences: HistorySubpageUserPreferences = {
    sectionOrder: [SectionName.SUMMARY, SectionName.TIMELINE],
    collapsedSections: [],
};

export const processResponse = (preferences: Partial<HistorySubpageUserPreferences>): HistorySubpageUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultHistorySubpageUserPreferences);
};

export const requiredHistorySubpageUserPreferences = {
    key: HISTORYSUBPAGE_PREFERENCES_KEY,
    defaultState: defaultHistorySubpageUserPreferences,
    processResponse,
};
