import { EditNotifications } from '@mui/icons-material';
import Assessment from '@mui/icons-material/Assessment';
import Business from '@mui/icons-material/Business';
import Chat from '@mui/icons-material/Chat';
import DesktopWindows from '@mui/icons-material/DesktopWindowsOutlined';
import History from '@mui/icons-material/History';
import HomeWork from '@mui/icons-material/HomeWork';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import Person from '@mui/icons-material/Person';
import Settings from '@mui/icons-material/Settings';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ViewCarousel from '@mui/icons-material/ViewCarousel';
import type { Partner } from '@mui/material/styles';

import type { FeatureFlagsProp } from '~/components/FeatureFlags';
import { Feature } from '~/components/FeatureFlags';
import {
    AdministrationIcon,
    CalendarIcon,
    DeviceIcon,
    DriverIcon,
    FolderIcon,
    GeozoneIcon,
    PaperGearIcon,
    ReportIcon,
    RtdsSchedulesIcon,
    RtdsSessionsIcon,
    SupportAgentIcon,
    TachOnlineIcon,
    TachoLincDownloaderIcon,
    TachographIcon,
    TimediscWebIcon,
    TrailerIcon,
    TruckIcon,
    VehicleTypesIcon,
    VideoLincIcon,
} from '~/components/Icons';
import { openIntroCarouselAction } from '~/components/IntroCarousel';
import type { SingleTFunction } from '~/components/LanguageSelector';
import { isEmpty } from '~/libs/utility';
import { SceneRoutes } from '~/routes';
import { logEvent } from '~/services/Analytics';
import type { HistorySecurables, Securables } from '~/services/ApiClient';
import { getConfig } from '~/services/Config';

import { AlertsIconCounter } from './components/AlertsIconCounter';
import type { MenuItem, MiscellaneousMenuItemsProps, Params } from './models';

const getHistoryMenuItems = (
    securables: HistorySecurables,
    t: SingleTFunction,
    featureFlags: FeatureFlagsProp
): MenuItem[] => {
    const menuItems: MenuItem[] = [];

    if (securables.vehicles) {
        menuItems.push({
            key: 'vehicles',
            icon: <TruckIcon />,
            to: SceneRoutes.HISTORY_VEHICLE,
            title: t('historic-positions'),
        });
    }

    if (securables.trailers) {
        menuItems.push({
            key: 'trailers',
            icon: <TrailerIcon />,
            to: SceneRoutes.HISTORY_TRAILER,
            title: t('trailer-events'),
        });
    }

    if (securables.drivers) {
        menuItems.push({
            key: 'drivers',
            icon: <DriverIcon />,
            to: SceneRoutes.HISTORY_DRIVER,
            title: t('driver-activities'),
        });
    }

    if (featureFlags.isEnabled(Feature.AssetNearBy)) {
        menuItems.push({
            key: 'asset-near-by',
            icon: <TravelExploreIcon />,
            to: SceneRoutes.ASSET_NEAR_BY,
            title: t('asset-near-by'),
        });
    }

    menuItems.push({
        key: 'trip-report',
        icon: <CalendarIcon />,
        to: SceneRoutes.TRIP_REPORT,
        title: t('trip-report.title'),
    });

    menuItems.push({
        key: 'driver-activities-report',
        icon: <ReportIcon />,
        to: SceneRoutes.DRIVER_ACTIVITIES_REPORT,
        title: t('driver-activities-report.title'),
    });

    return menuItems;
};
export const getModuleMenuItems = ({ t, securables, featureFlags }: Params): MenuItem[] => {
    const items: MenuItem[] = [];

    if (securables.impersonation.impersonate) {
        items.push({
            key: 'impersonation',
            icon: <SupportAgentIcon />,
            to: SceneRoutes.IMPERSONATION,
            title: t('impersonation'),
        });
    }

    if (
        securables.monitoring.vehicles ||
        securables.monitoring.trailers ||
        securables.monitoring.containers ||
        securables.monitoring.driverStatus
    ) {
        items.push({
            key: 'monitoring',
            icon: <DesktopWindows />,
            to: SceneRoutes.MONITORING,
            title: t('monitoring'),
        });
    }

    if (securables.history.vehicles || securables.history.trailers || securables.history.drivers) {
        items.push({
            key: 'history',
            icon: <History />,
            title: t('history'),
            nestedItems: getHistoryMenuItems(securables.history, t, featureFlags),
        });
    }

    if (securables.messaging.view) {
        items.push({
            key: 'communication',
            icon: <Chat />,
            to: SceneRoutes.COMMUNICATION,
            title: t('communication'),
        });
    }

    if (securables.alertManager.isAllowed) {
        items.push({
            key: 'alerts',
            icon: <AlertsIconCounter />,
            to: SceneRoutes.ALERTS,
            title: t('alerts.title'),
        });
    }

    if (
        securables.tachoFile.driverCardFiles ||
        securables.tachoFile.tachographFiles ||
        securables.rtds.sessions ||
        securables.rtds.schedules ||
        securables.rtds.tachoLinc
    ) {
        const rtdsItem: MenuItem = {
            key: 'rtds-modules',
            icon: <TachographIcon />,
            title: t('rtds-modules'),
            nestedItems: [],
        };

        if (securables.tachoFile.driverCardFiles || securables.tachoFile.tachographFiles) {
            rtdsItem.nestedItems?.push({
                key: 'tacho-files',
                icon: <TachographIcon />,
                to: SceneRoutes.TACHOFILES,
                title: t('tacho-files'),
            });
        }

        if (securables.rtds.tachoLinc) {
            rtdsItem.nestedItems?.push({
                key: 'tacholinc-status',
                icon: <HomeWork />,
                to: SceneRoutes.TACHOLINCSTATUS,
                title: t('tacholinc-status'),
            });
        }

        if (securables.rtds.sessions) {
            rtdsItem.nestedItems?.push({
                key: 'rtds-sessions',
                icon: <RtdsSessionsIcon />,
                to: SceneRoutes.RTDSSESSIONS,
                title: t('rtds-sessions'),
            });
        }

        if (securables.rtds.schedules) {
            rtdsItem.nestedItems?.push({
                key: 'rtds-schedules',
                icon: <RtdsSchedulesIcon />,
                to: SceneRoutes.RTDSSCHEDULES,
                title: t('rtds-schedules'),
            });
        }

        if (securables.rtds.tachoLinc) {
            rtdsItem.nestedItems?.push({
                key: 'download-tacholinc',
                icon: <TachoLincDownloaderIcon />,
                title: t('rtds-tacho-linc-download'),
                href: getConfig().tachoLinkDownloadUrl,
                hasExternalLinkIcon: true,
                openInNewWindow: true,
                onClick: () => logEvent('tachoLincDownload', 'download-tachoLinc', 'Download TachoLinc'),
            });
        }

        items.push(rtdsItem);
    }
    return items;
};

interface ExternalLinkItemsArgs {
    securables: Securables;
    partner: Partner;
    cid: number;
}

export const getExternalLinkItems = ({ securables, partner, cid }: ExternalLinkItemsArgs): MenuItem[] => {
    const items = [
        ...(securables.externalSystem.dashboard
            ? [
                  {
                      key: 'analytics-platform',
                      href: getConfig().sisenseUrl,
                      icon: <Assessment />,
                      title: partner.analyticsSolutionName,
                      hasExternalLinkIcon: true,
                      openInNewWindow: true,
                      onClick: () =>
                          logEvent(
                              'reporting',
                              'launch-reporting',
                              `Launch reporting module '${partner.analyticsSolutionName}'`
                          ),
                  },
              ]
            : []),
        ...(securables.externalSystem.timediscWeb
            ? [
                  {
                      key: 'timedisc-web',
                      href: getConfig().timediscWebUrl,
                      icon: <TimediscWebIcon />,
                      title: 'TimediscWEB',
                      hasExternalLinkIcon: true,
                      openInNewWindow: true,
                      onClick: () => logEvent('timediscWeb', 'launch-timediscWeb', 'Launch TimediscWeb'),
                  },
              ]
            : []),
        ...(securables.externalSystem.videoLinc
            ? [
                  {
                      key: 'video-linc-app',
                      href: getConfig().videoLincUrl,
                      icon: <VideoLincIcon />,
                      title: 'VideoLinc',
                      hasExternalLinkIcon: true,
                      openInNewWindow: true,
                      onClick: () => logEvent('videolinc', 'launch-videolinc', 'Launch VideoLinc'),
                  },
              ]
            : []),
        // HACK: There is a temporary trial, this code should be removed, or prettiefied to remain working after november 2024
        // Yes, month is correct, in javascript 0-11 (jan-dec)
        ...(cid === 5694 && Date.now() < Date.UTC(2024, 10, 1)
            ? [
                  {
                      key: 'video-linc-streamax',
                      href: 'https://eur-ftcloud.ifleetvision.com/ftv/ft/realtime-monitoring',
                      icon: <VideoLincIcon />,
                      title: 'VideoLinc',
                      hasExternalLinkIcon: true,
                      openInNewWindow: true,
                      onClick: () => logEvent('videolinc', 'launch-videolinc-streamax', 'Launch VideoLinc Streamax'),
                  },
              ]
            : []),
        ...(securables.externalSystem.tachOnline
            ? [
                  {
                      key: 'tach-online-app',
                      href: getConfig().tachOnlineUrl,
                      icon: <TachOnlineIcon />,
                      title: 'TachOnline',
                      hasExternalLinkIcon: true,
                      openInNewWindow: true,
                      onClick: () => logEvent('tachonline', 'launch-tachonline', 'Launch TachOnline'),
                  },
              ]
            : []),
    ];

    return items;
};

export const getMiscellaneousMenuItems = (props: MiscellaneousMenuItemsProps): MenuItem[] => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t, dispatch, securables, featureFlags } = props;
    const menuItems: MenuItem[] = [];

    const administrationItem: MenuItem = {
        key: 'administration',
        icon: <AdministrationIcon />,
        title: t('administration'),
        nestedItems: [],
    };
    if (securables.assetAdministration.vehicles.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'vehicle-admin',
            icon: <TruckIcon />,
            to: SceneRoutes.ADMIN_VEHICLES,
            title: t('vehicle-admin-module'),
        });
    }
    if (securables.assetAdministration.trailers?.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'trailers-admin',
            icon: <TrailerIcon />,
            to: SceneRoutes.ADMIN_TRAILERS,
            title: t('trailers-admin-module'),
        });
    }

    if (securables.assetAdministration.devices.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'devices-admin',
            icon: <DeviceIcon />,
            to: SceneRoutes.ADMIN_DEVICES,
            title: t('device-admin-module'),
        });
    }

    if (securables.assetAdministration.drivers.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'driver-admin',
            icon: <DriverIcon />,
            to: SceneRoutes.ADMIN_DRIVERS,
            title: t('driver-admin-module'),
        });
    }
    if (securables.assetAdministration.vehicleTypes.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'vehicle-types-admin',
            icon: <VehicleTypesIcon />,
            to: SceneRoutes.ADMIN_VEHICLE_TYPES,
            title: t('vehicle-types-admin-module'),
        });
    }
    if (securables.assetAdministration.companyCards.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'company-card-admin',
            icon: <TachographIcon />,
            to: SceneRoutes.ADMIN_COMPANY_CARD,
            title: t('company-card-admin-module'),
        });
    }
    if (securables.securityAdministration.users.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'user-admin',
            icon: <Person />,
            to: SceneRoutes.ADMIN_USERS,
            title: t('user-admin.title'),
        });
    }
    if (securables.securityAdministration.userRolesAndPermissions.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'user-roles-permissions',
            icon: <ManageAccounts />,
            to: SceneRoutes.USER_ROLES_PERMISSIONS,
            title: t('user-roles.permissions-module'),
        });
    }
    if (securables.assetAdministration.assetGroups.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'group-admin',
            icon: <FolderIcon />,
            to: SceneRoutes.ADMIN_GROUPS,
            title: t('asset-group-admin.title'),
        });
    }

    if (securables.services.geozones && securables.geozonesAdministration.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'geozone-admin',
            icon: <GeozoneIcon />,
            to: SceneRoutes.GEOZONE_ADMINISTRATION,
            title: t('geozone-admin.title'),
        });
    }

    if (securables.formsAdministration.alertManagerDefinitions.isAllowed) {
        administrationItem.nestedItems?.push({
            key: 'alert-definitions',
            icon: <EditNotifications />,
            to: SceneRoutes.ALERT_DEFINITIONS,
            title: t('alert-definitions.module'),
        });
    }

    if (!isEmpty(administrationItem.nestedItems)) {
        menuItems.push(administrationItem);
    }

    const settingsMenuItem: MenuItem = {
        key: 'settings',
        icon: <Settings />,
        title: t('settings'),
        nestedItems: [
            {
                key: 'user',
                icon: <Person />,
                title: t('user-settings.title'),
                to: SceneRoutes.SETTINGS_USER,
            },
        ],
    };

    if (securables.customerSettings.manageSettings) {
        settingsMenuItem.nestedItems?.push({
            key: 'customer',
            icon: <Business />,
            title: t('customer-settings.title'),
            to: SceneRoutes.SETTINGS_CUSTOMER,
        });
    }

    if (securables.driverCoachReportConfiguration.isAllowed) {
        settingsMenuItem.nestedItems?.push({
            key: 'report-settings',
            icon: <PaperGearIcon />,
            title: t('settings-profiles.title'),
            to: SceneRoutes.SETTINGS_REPORTS,
        });
    }

    menuItems.push(settingsMenuItem);

    menuItems.push({
        key: 'introduction',
        icon: <ViewCarousel />,
        onClick: () => dispatch(openIntroCarouselAction()),
        title: t('introduction'),
        hideInMenuBar: true,
    });

    return menuItems;
};
