import GpsFixed from '@mui/icons-material/GpsFixed';
import { Divider, Fab, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { ReactNode, SyntheticEvent } from 'react';
import { Component } from 'react';

import { BannerType, TopBanner } from '~/components/Dialogs';
import { ConfirmationDialog } from '~/components/Dialogs/ConfirmationDialog';
import { CloseIcon, ExpandMoreIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { PopupWidget, PopupWidgetMinimizedMode } from '~/components/PopupWidget';
import type { ScrollPosition } from '~/components/ReverseInfiniteScroll';
import { TitledIconButton } from '~/components/TitledIconButton';
import type { NumericDictionary } from '~/libs/utility';
import { memoize } from '~/libs/utility';
import type { Disposable } from '~/listeners';
import { SendingMessageDisabledReason } from '~/modules/Communication/models';
import { reportError } from '~/reporting';
import { logEvent } from '~/services/Analytics';
import type {
    DisplayUserPreferencesVehicleDisplayFormat,
    OptimisticOutgoingConversationMessage,
    ResolvedMessage,
    TextMessageBody,
    Vehicle,
} from '~/services/ApiClient';
import { ConversationNotification, ResourceFilter, createApiModel } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';
import { subscribeToNotificationsAndRetrieveTheData } from '~/services/SignalR';

import { MessageDetails, MessageDetailsType } from '../../../MessageDetails';
import type { PendingMessageStatusUpdate, TranslatedMessagesState } from '../../reducers';

import { buildListItem } from './buildListItem';
import { buildListItems } from './buildListItems';
import { ConversationWidgetHeader } from './components/ConversationWidgetHeader';
import { ConversationWidgetList } from './components/ConversationWidgetList';
import { MarkAllMessagesAsReadButton } from './components/MarkAllMessagesAsReadButton';
import { SendingMessagesDisabled } from './components/SendingMessagesDisabled';
import { SendMessageBox } from './components/SendMessageBox/index';
import { WorkflowMessageSendSubpage } from './components/WorkflowMessageSendSubpage';
import { sendingSomeMessage } from './sendingSomeMessage';
import type { ConversationWidgetClassKey } from './styles';
import { widgetHeight, widgetWidth } from './styles';

export interface ConversationWidgetProps {
    vehicleId: number;
    userConversationProfileId: number;
    isMinimized: boolean;
    shouldClose: boolean;
    onClosed: () => void;
    onCloseRejected: () => void;
    onLocate?: (vehicleId: number) => void;
    canLocate?: (vehicleId: number) => boolean;
}

export interface ConversationWidgetReduxProps {
    vehicle?: Vehicle;
    unreadTextMessageCount: number;
    unreadWorkflowMessageCount: number;
    messagesSortedDesc: ResolvedMessage[];
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat;
    sendTextMessagesAllowed: boolean;
    sendWorkflowMessagesAllowed: boolean;
    canDeleteMessages: boolean;
    markMessagesAsReadAllowed: boolean;
    includeDeletedMessages: boolean;
    shouldFocusInput: boolean;
    failedToSendMessage: boolean;
    warnOnMarkAllMessagesAsRead: boolean;
    markingAllMessagesAsRead: boolean;
    pendingMessagesStatusUpdates: NumericDictionary<PendingMessageStatusUpdate>;
    loadingConversation: boolean;
    failedToLoadConversation: boolean;
    loadingOlderMessages: boolean;
    failedToLoadOlderMessages: boolean;
    noOlderMessagesToDisplay: boolean;
    textMessagingEnabled: boolean;
    workflowMessagingEnabled: boolean;
    supportTextMessaging: boolean;
    supportWorkflowMessaging: boolean;
    getConversation: () => void;
    updateConversation: (conversationNotification: ConversationNotification) => void;
    clearConversation: () => void;
    markIncomingMessageAsRead: (messageId: number) => void;
    markIncomingMessageAsUnread: (messageId: number) => void;
    deleteMessage: (messageId: number) => void;
    undeleteMessage: (messageId: number) => void;
    markAllMessagesAsRead: (dontWarnMeAnymore?: boolean) => void;
    toggleMinimizedState: () => void;
    clearSendMessageError: () => void;
    loadOlderMessages: () => void;
    retrySendMessage: (message: OptimisticOutgoingConversationMessage) => void;
    discardMessage: (message: OptimisticOutgoingConversationMessage) => void;
    focusMessageText: () => void;
    retrieveWorkflowFormDefinition: (formId: number) => void;
    translateConversationMessage: (message: ResolvedMessage, targetLanguage: string) => void;
    translatedMessages: TranslatedMessagesState;
}

export interface ConversationWidgetInnerProps
    extends ConversationWidgetProps,
        ConversationWidgetReduxProps,
        WithStyles<ConversationWidgetClassKey>,
        InjectedTranslationProps {}

export interface ConversationWidgetState {
    scrollPosition?: ScrollPosition;
    showCloseConversationConfirmationDialog: boolean;
    messageText: string;
    messageDetailsOpen: boolean;
    sendWorkflowFormOpen: boolean;
    sendWorkflowFormShouldClose: boolean;
    scrollToAttachments: boolean;
    selectedMessage?: ResolvedMessage;
    shouldClose: boolean;
    showSendOptionsWarning: boolean;
    sendOptionsWarningText: string;
    sendOptionsWarningDismissible: boolean;
}

interface GetDisabledReasonArgs {
    sendingAllowed: boolean;
    vehicleMessagingServiceAllowed: boolean;
    deviceSupportsMessaging: boolean;
}
export const getDisabledReason = ({
    sendingAllowed,
    vehicleMessagingServiceAllowed,
    deviceSupportsMessaging,
}: GetDisabledReasonArgs): SendingMessageDisabledReason => {
    if (!sendingAllowed) {
        return SendingMessageDisabledReason.NotAllowed;
    } else if (!vehicleMessagingServiceAllowed) {
        return SendingMessageDisabledReason.VehicleMesagingServiceDisabled;
    } else if (!deviceSupportsMessaging) {
        return SendingMessageDisabledReason.NotSupportedByDevice;
    }
    throw Error('Cannot get disabled reason when sendingAllowed, serviceEnabled and supportMessaging are all true');
};

export class ConversationWidgetComponent extends Component<ConversationWidgetInnerProps, ConversationWidgetState> {
    private disposableHandlers: Disposable[] = [];
    private buildListItemMemoized = memoize(buildListItem);
    private buildListItemsMemoized = memoizeOne((conversationMessagesSortedDesc: ResolvedMessage[]) =>
        buildListItems(conversationMessagesSortedDesc, this.buildListItemMemoized)
    );

    private sendingSomeMessageMemoized = memoize(sendingSomeMessage);
    private confirmationDialogChildrenMemoized = memoize((confirmationDialogQuestion) => (
        <Typography>{confirmationDialogQuestion}</Typography>
    ));

    constructor(props: ConversationWidgetInnerProps) {
        super(props);
        this.state = {
            showCloseConversationConfirmationDialog: false,
            messageText: '',
            messageDetailsOpen: false,
            sendWorkflowFormOpen: false,
            sendWorkflowFormShouldClose: false,
            scrollToAttachments: false,
            selectedMessage: undefined,
            shouldClose: false,
            showSendOptionsWarning: false,
            sendOptionsWarningText: '',
            sendOptionsWarningDismissible: true,
        };
    }

    public componentDidMount(): void {
        window.addEventListener('beforeunload', this.beforeUnloadHandler);
        this.subscribeForConversations();
    }

    public componentDidUpdate(prevProps: ConversationWidgetInnerProps): void {
        if (this.props.shouldClose || this.state.shouldClose) {
            this.handleCloseConversation();
        }

        if (
            prevProps.userConversationProfileId !== this.props.userConversationProfileId ||
            prevProps.includeDeletedMessages !== this.props.includeDeletedMessages
        ) {
            this.disposableHandlers.forEach(({ dispose }) => dispose());
            this.disposableHandlers = [];
            this.subscribeForConversations();
        }
    }

    public componentWillUnmount(): void {
        this.disposableHandlers.forEach(({ dispose }) => dispose());
        this.props.clearConversation();
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    }

    public render(): ReactNode {
        const {
            t,
            classes,
            vehicleId,
            isMinimized,
            messagesSortedDesc,
            loadingConversation,
            failedToLoadConversation,
            loadingOlderMessages,
            failedToLoadOlderMessages,
            noOlderMessagesToDisplay,
            getConversation,
            loadOlderMessages,
            toggleMinimizedState,
            translateConversationMessage,
            translatedMessages,
            sendTextMessagesAllowed,
            sendWorkflowMessagesAllowed,
            failedToSendMessage,
            shouldFocusInput,
            supportTextMessaging,
            supportWorkflowMessaging,
            textMessagingEnabled,
            workflowMessagingEnabled,
        } = this.props;

        const {
            showCloseConversationConfirmationDialog,
            messageText,
            messageDetailsOpen,
            sendWorkflowFormOpen,
            selectedMessage,
            scrollToAttachments,
            showSendOptionsWarning,
            sendOptionsWarningText,
        } = this.state;

        if (!this.props.vehicle) {
            // Render blank widget when no vehicle is resolved, the widget will automatically be closed didMount
            return null;
        }

        const messageSubpageIsOpened = (messageDetailsOpen || sendWorkflowFormOpen) && !isMinimized;

        const renderHeader = (
            <ConversationWidgetHeader
                messageDetailsSubpageIsOpened={messageSubpageIsOpened}
                failedToSendMessage={failedToSendMessage}
                unreadTextMessageCount={this.props.unreadTextMessageCount}
                unreadWorkflowMessageCount={this.props.unreadWorkflowMessageCount}
                vehicle={this.props.vehicle}
                vehicleDisplayNameFormat={this.props.vehicleDisplayFormat}
                title={sendWorkflowFormOpen ? t('sending-workflow') : undefined}
                onGoBack={messageSubpageIsOpened ? this.closeMessageSubpage : undefined}
            />
        );

        const markAllAsReadButton =
            messageDetailsOpen || sendWorkflowFormOpen ? undefined : (
                <MarkAllMessagesAsReadButton
                    markMessagesAsReadAllowed={this.props.markMessagesAsReadAllowed}
                    unreadMessageCount={this.props.unreadTextMessageCount + this.props.unreadWorkflowMessageCount}
                    warnOnMarkAllMessagesAsRead={this.props.warnOnMarkAllMessagesAsRead}
                    markingAllMessagesAsRead={this.props.markingAllMessagesAsRead}
                    markAllMessagesAsRead={this.props.markAllMessagesAsRead}
                />
            );

        const canLocate = this.locationAvailable();

        const locateOnMapAction = (
            <span onClick={this.stopPropagation}>
                <TitledIconButton
                    data-id="locate-from-conversation"
                    className={classes.actionButton}
                    title={canLocate ? t('locate') : t('current-position-not-available')}
                    onClick={this.handleLocate}
                    disabled={!canLocate}
                >
                    <GpsFixed fontSize="inherit" />
                </TitledIconButton>
            </span>
        );

        const closeAction = (
            <TitledIconButton
                className={classes.actionButton}
                onClick={this.handleCloseConversationClick}
                data-id="conversation-close"
                title={t('close')}
            >
                <CloseIcon fontSize="inherit" />
            </TitledIconButton>
        );

        const renderActions = (
            <>
                {markAllAsReadButton}
                {locateOnMapAction}
                {closeAction}
            </>
        );

        const scrollToBottomFabClasses = classNames(classes.scrollToBottomFab, {
            [classes.scrollToBottomFabVisible]: this.state.scrollPosition && !this.state.scrollPosition.stickToBottom,
        });

        const sendOptionsWarningBanner = showSendOptionsWarning && (
            <>
                <TopBanner
                    title={sendOptionsWarningText}
                    onDismiss={this.state.sendOptionsWarningDismissible ? this.dismissSendOptionsWarning : undefined}
                    dataId="send-options-changed-warning"
                    type={BannerType.Warning}
                />
                <Divider />
            </>
        );

        const failedToSendMessageWarning = failedToSendMessage && (
            <>
                <TopBanner
                    title={t('failed-to-send-some-messages')}
                    onDismiss={this.props.clearSendMessageError}
                    dataId="message-failed"
                    type={BannerType.Warning}
                />
                <Divider />
            </>
        );

        const listClassName = classNames(classes.listContainer, {
            [classes.none]: messageDetailsOpen,
        });

        const renderContent = (
            <div className={classes.content}>
                {sendOptionsWarningBanner}
                {failedToSendMessageWarning}
                {messageDetailsOpen && selectedMessage ? (
                    <MessageDetails
                        message={selectedMessage}
                        type={MessageDetailsType.Widget}
                        scrollToAttachments={scrollToAttachments}
                    />
                ) : sendWorkflowFormOpen ? (
                    <WorkflowMessageSendSubpage
                        vehicleId={vehicleId}
                        messages={messagesSortedDesc}
                        shouldClose={this.state.sendWorkflowFormShouldClose}
                        onSendOptionsWarning={this.handleSendOptionsWarning}
                        onClosed={this.handleSendWorkflowSubpageClosed}
                        onCloseRejected={this.handleSendWorkflowSubpageCloseRejected}
                    />
                ) : (
                    <div className={listClassName}>
                        <ConversationWidgetList
                            items={this.buildListItemsMemoized(messagesSortedDesc)}
                            scrollPosition={this.state.scrollPosition}
                            loadingConversation={loadingConversation}
                            failedToLoadConversation={failedToLoadConversation}
                            loadingOlderMessages={loadingOlderMessages}
                            failedToLoadOlderMessages={failedToLoadOlderMessages}
                            noOlderMessagesToDisplay={noOlderMessagesToDisplay}
                            getConversation={getConversation}
                            loadOlderMessages={loadOlderMessages}
                            onScrollPositionChanged={this.handleScrollPositionChanged}
                            canDeleteMessages={this.props.canDeleteMessages}
                            canUpdateReadStatus={this.props.markMessagesAsReadAllowed}
                            markIncomingMessageAsRead={this.props.markIncomingMessageAsRead}
                            markIncomingMessageAsUnread={this.props.markIncomingMessageAsUnread}
                            deleteMessage={this.props.deleteMessage}
                            undeleteMessage={this.props.undeleteMessage}
                            pendingMessagesStatusUpdates={this.props.pendingMessagesStatusUpdates}
                            retrySendMessage={this.props.retrySendMessage}
                            discardMessage={this.props.discardMessage}
                            copyMessageToDraft={this.handleCopyMessageToDraft}
                            openMessageDetails={this.openMessageDetails}
                            retrieveWorkflowFormDefinition={this.props.retrieveWorkflowFormDefinition}
                            translateConversationMessage={translateConversationMessage}
                            translatedConversationMessages={translatedMessages[vehicleId]}
                        />

                        <Fab
                            className={scrollToBottomFabClasses}
                            onClick={this.scrollToBottom}
                            data-id="scroll-to-bottom"
                        >
                            <ExpandMoreIcon fontSize="inherit" />
                        </Fab>
                    </div>
                )}
            </div>
        );

        const generalSendingMessageAllowed =
            (sendTextMessagesAllowed && textMessagingEnabled && supportTextMessaging) ||
            (sendWorkflowMessagesAllowed && workflowMessagingEnabled && supportWorkflowMessaging);

        const sendMessageBox = generalSendingMessageAllowed ? (
            <SendMessageBox
                vehicleId={vehicleId}
                messageText={messageText}
                shouldFocusInput={shouldFocusInput}
                closeConversation={this.closeConversation}
                disabled={loadingConversation || failedToLoadConversation}
                sendWorkflowMessagesAllowed={sendWorkflowMessagesAllowed}
                openSendWorkflowMessageForm={this.openSendWorkflowMessageForm}
                changeMessage={this.changeMessage}
                onSendOptionsWarning={this.handleSendOptionsWarning}
                onCloseRejected={this.handleConfirmationDialogCancel}
                shouldClose={this.state.shouldClose}
                supportTextMessaging={textMessagingEnabled && supportTextMessaging}
                supportWorkflowMessaging={workflowMessagingEnabled && supportWorkflowMessaging}
            />
        ) : (
            <SendingMessagesDisabled
                textMessageDisabledReason={getDisabledReason({
                    sendingAllowed: sendTextMessagesAllowed,
                    vehicleMessagingServiceAllowed: textMessagingEnabled,
                    deviceSupportsMessaging: supportTextMessaging,
                })}
                workflowMessageDisabledReason={getDisabledReason({
                    sendingAllowed: sendWorkflowMessagesAllowed,
                    vehicleMessagingServiceAllowed: workflowMessagingEnabled,
                    deviceSupportsMessaging: supportWorkflowMessaging,
                })}
            />
        );

        const footer = messageDetailsOpen || sendWorkflowFormOpen ? undefined : sendMessageBox;
        const confirmationDialogTitle = messageText ? t('discard-draft') : t('close-conversation');

        const confirmationDialogQuestion = messageText
            ? t('confirm-discard-draft')
            : t('confirm-losing-of-sending-in-progress-message');

        const confirmationDialogConfirmationActionText = messageText ? t('discard') : t('close');

        return (
            <>
                <PopupWidget
                    title={renderHeader}
                    actions={renderActions}
                    content={renderContent}
                    minimizedMode={
                        sendWorkflowFormOpen ? PopupWidgetMinimizedMode.HIDDEN : PopupWidgetMinimizedMode.NORENDER
                    }
                    footer={footer}
                    width={widgetWidth}
                    height={widgetHeight}
                    isMinimized={isMinimized}
                    toggleMinimize={toggleMinimizedState}
                    dataId={`conversation-widget:${vehicleId}`}
                />
                <ConfirmationDialog
                    dataId={`confirm-close-conversation-widget-dialog:${vehicleId}`}
                    title={confirmationDialogTitle}
                    open={showCloseConversationConfirmationDialog}
                    confirmationActionText={confirmationDialogConfirmationActionText}
                    onConfirm={this.handleConfirmationDialogConfirmationAction}
                    onCancel={this.handleConfirmationDialogCancel}
                >
                    {this.confirmationDialogChildrenMemoized(confirmationDialogQuestion)}
                </ConfirmationDialog>
            </>
        );
    }

    private subscribeForConversations = () => {
        if (this.props.vehicle) {
            subscribeToNotificationsAndRetrieveTheData(
                `conversations/${this.props.vehicleId}`,
                ConversationNotification.fromJS,
                (notification: ConversationNotification) => {
                    this.props.updateConversation(notification);
                },
                () => {
                    this.props.getConversation();
                },
                createApiModel(ResourceFilter, {
                    conversationProfileId: this.props.userConversationProfileId,
                    includeDeletedMessages: this.props.includeDeletedMessages,
                })
            ).then((disposable: Disposable) => {
                this.disposableHandlers.push(disposable);
            }, reportError);
        } else {
            // specific scenario when conversation was open and with reload trying to open it again, but meanwhile permission for vehicle revoked
            this.props.onClosed();
        }
    };

    private changeMessage = (messageText: string): void => {
        this.setState({ messageText });
    };

    private beforeUnloadHandler = (event: WindowEventMap['beforeunload']) => {
        if (this.sendingSomeMessageMemoized(this.props.messagesSortedDesc)) {
            // Cancel the event as stated by the standard
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = this.props.t('confirm-losing-of-sending-in-progress-message');
        }
    };

    private handleConfirmationDialogCancel = () => {
        this.setState({ showCloseConversationConfirmationDialog: false, shouldClose: false });

        this.props.onCloseRejected();
    };

    private handleConfirmationDialogConfirmationAction = () => {
        const shouldShowLosingOfSendingInProgressMessagesConfirmationDialog =
            this.state.messageText && this.sendingSomeMessageMemoized(this.props.messagesSortedDesc);
        if (shouldShowLosingOfSendingInProgressMessagesConfirmationDialog) {
            this.setState({ messageText: '' });
        } else {
            this.setState({ showCloseConversationConfirmationDialog: false });
            this.props.onClosed();
        }
    };

    private handleSendOptionsWarning = (messageText?: string, canDismiss?: boolean) => {
        this.setState({
            showSendOptionsWarning: !!messageText,
            sendOptionsWarningText: messageText ?? '',
            sendOptionsWarningDismissible: !!canDismiss,
        });
    };

    private dismissSendOptionsWarning = () => {
        this.setState({ showSendOptionsWarning: false });
    };

    private handleSendWorkflowSubpageClosed = () => {
        this.setState({ sendWorkflowFormOpen: false, sendWorkflowFormShouldClose: false });
    };

    private handleSendWorkflowSubpageCloseRejected = () => {
        this.setState({ sendWorkflowFormShouldClose: false, shouldClose: false });
        if (this.props.shouldClose) {
            this.props.onCloseRejected();
        }
    };

    private stopPropagation = (event: SyntheticEvent) => {
        event.stopPropagation();
    };

    private closeConversation = () => {
        this.setState({ shouldClose: true });
    };

    private handleCloseConversationClick = (event: SyntheticEvent) => {
        event.stopPropagation();
        this.closeConversation();
    };

    private openMessageDetails = (message: ResolvedMessage, scrollToAttachment: boolean) => {
        this.setState({
            messageDetailsOpen: true,
            selectedMessage: message,
            scrollToAttachments: scrollToAttachment,
        });
        logEvent('messaging', 'open-workflow-details', 'Open workflow details');
    };

    private openSendWorkflowMessageForm = () => {
        this.setState({
            sendWorkflowFormOpen: true,
        });
        logEvent('messaging', 'open-send-workflow-form', 'Open send workflow form');
    };

    private closeMessageSubpage = () => {
        if (this.state.sendWorkflowFormOpen) {
            this.setState({ sendWorkflowFormShouldClose: true });
        } else {
            this.setState({
                messageDetailsOpen: false,
                selectedMessage: undefined,
            });
        }
    };

    private handleCloseConversation = () => {
        if (this.state.sendWorkflowFormOpen) {
            if (!this.state.sendWorkflowFormShouldClose) {
                this.setState({ sendWorkflowFormShouldClose: true });
            }
        } else if (this.state.messageText || this.sendingSomeMessageMemoized(this.props.messagesSortedDesc)) {
            if (!this.state.showCloseConversationConfirmationDialog) {
                this.setState({ showCloseConversationConfirmationDialog: true });
            }
        } else {
            this.props.onClosed();
        }
    };

    private locationAvailable = () => this.props.canLocate && this.props.canLocate(this.props.vehicleId);

    private handleLocate = () => {
        if (this.props.onLocate) {
            this.props.onLocate(this.props.vehicleId);
        }
    };

    private scrollToBottom = () => {
        this.setState({ scrollPosition: { stickToBottom: true } });
    };

    private handleScrollPositionChanged = (scrollPosition: ScrollPosition) => {
        this.setState({ scrollPosition });
    };

    private handleCopyMessageToDraft = (messageBody: TextMessageBody) => {
        this.setState({ messageText: messageBody.message });
        this.props.focusMessageText();
    };
}
