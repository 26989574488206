import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import type { StoreState } from '~/reducers';
import { languageStateSelector } from '~/selectors';

import { languageReducer } from '../../reducers';

import LanguageSelectorComponent from './component';
import type { DispatchProps, LanguageSelectorProps, StateProps } from './models';
import { mapDispatchToProps, mapStateToProps } from './redux';

export type { LanguageStoreState } from '../../reducers';

export const LanguageSelector = connect<StateProps, DispatchProps, LanguageSelectorProps, StoreState>(
    createSelector(languageStateSelector, mapStateToProps),
    mapDispatchToProps
)(LanguageSelectorComponent);

export const languageComponentReducer = languageReducer;
