import ArrowBack from '@mui/icons-material/ArrowBack';
import Close from '@mui/icons-material/Close';
import { Paper, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TitledIconButton } from '~/components/TitledIconButton';

import type { DetailsPaneHeaderProps } from './models';
import { useStyles } from './styles';

const DetailsPaneHeader: FC<DetailsPaneHeaderProps> = (props) => {
    const { onClose, onBack, title } = props;

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Paper square className={classes.root}>
            {onBack && (
                <TitledIconButton title={t('back')} onClick={onBack} data-id="back-icon">
                    <ArrowBack />
                </TitledIconButton>
            )}

            <Typography variant="subtitle2" className={classes.title} data-id="title">
                {title}
            </Typography>

            {onClose && (
                <TitledIconButton title={t('close')} onClick={onClose} data-id="close-icon">
                    <Close />
                </TitledIconButton>
            )}
        </Paper>
    );
};

DetailsPaneHeader.displayName = 'DetailsPaneHeader';
export default DetailsPaneHeader;
