import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesUnitSystem, NearestCity } from '~/services/ApiClient';
import { formatNearestCity } from '~/services/Formatters';

export interface NearestCityFormatterProps {
    unitSystem: DisplayUserPreferencesUnitSystem;
    value?: NearestCity;
}

const NearestCityFormatter: React.FunctionComponent<NearestCityFormatterProps> = ({ unitSystem, value }) => {
    const { t } = useTranslation();
    if (isUndefined(value)) {
        return null;
    }

    return <div data-testid="nearest-city-formatter-component">{formatNearestCity(t, unitSystem, value)}</div>;
};
NearestCityFormatter.displayName = 'NearestCityFormatter';

export { NearestCityFormatter };
