import type { Theme } from '@mui/material';
import { lighten } from '@mui/material/styles';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LatestMessageClassKey =
    | 'root'
    | 'incomingMessageBackground'
    | 'statusIndicator'
    | 'author'
    | 'message'
    | 'deleted'
    | 'unreadMessage';

export const LatestMessageStyleRules: StyleRulesCallback<Theme, {}, LatestMessageClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            background: theme.palette.background.paper,
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(),
        },
        incomingMessageBackground: {
            backgroundColor: lighten(theme.functionalItemsColors.assetType.vehicle.value, 0.92),
        },
        deleted: {
            fontStyle: 'italic',
        },
        statusIndicator: {
            display: 'inline-flex',
            fontSize: 14,
            marginRight: theme.spacing(0.25),
            color: theme.palette.text.secondary,
        },
        author: {
            marginRight: theme.spacing(0.25),
            marginLeft: theme.spacing(0.25),
            color: theme.functionalItemsColors.assetType.user.value,
        },
        message: {
            flexGrow: 1,
            width: 0,
            marginLeft: theme.spacing(0.25),
            marginRight: theme.spacing(0.75),
        },
        unreadMessage: {
            fontWeight: 600,
        },
    });
