import type { FC } from 'react';

import { SectionListItem } from '~/components/SectionListItem';

import type { KeyValueItem, KeyValueListInnerProps } from './models';
import { List } from './styles';

const KeyValueList: FC<KeyValueListInnerProps> = (props) => {
    const { list, listDataId } = props;

    const listItems = list.map((item: KeyValueItem) => {
        return (
            <SectionListItem
                key={item.valueDataId}
                valueDataId={item.valueDataId}
                label={item.label}
                value={item.value}
                isIconValue={item.isIconValue}
            />
        );
    });

    return <List data-id={listDataId}>{listItems}</List>;
};

KeyValueList.displayName = 'KeyValueList';
export { KeyValueList };
