import * as React from 'react';
import { wrapDisplayName } from 'react-recompose';

import type { Feature } from '../../models';
import type { InjectedFeatureFlagsProps } from '../withFeatureFlags';

export const withFeatureFlagInterceptorHoc =
    (feature: Feature, disabledBehavior?: JSX.Element) =>
    <BaseProps extends {}>(
        BaseComponent: React.ComponentType<BaseProps>
    ): React.FC<BaseProps & InjectedFeatureFlagsProps> => {
        const Hoc: React.FC<BaseProps & InjectedFeatureFlagsProps> = (props: BaseProps & InjectedFeatureFlagsProps) => {
            const { featureFlags, ...restProps } = props;

            const enabled = featureFlags.isEnabled(feature);

            if (!enabled) {
                return disabledBehavior ?? null;
            }

            return <BaseComponent {...(restProps as unknown as BaseProps)} />;
        };
        Hoc.displayName = wrapDisplayName(BaseComponent, 'withFeatureFlagInterceptor');

        return Hoc;
    };
