import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const MessageContent = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'italic',
    minHeight: theme.custom.dialogContent.minHeight,
    margin: theme.spacing(2),
}));

const TitleWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '350px',
    fontSize: 16,
});

const GraphWrapper = styled(Box)(({ theme }) => ({
    overflow: 'hidden',
    margin: theme.spacing(2),
    minHeight: theme.custom.dialogContent.minHeight,
}));

const StyledHeader = styled(Typography)({ flexGrow: 1, alignSelf: 'center' });

export { MessageContent, TitleWrapper, GraphWrapper, StyledHeader };
