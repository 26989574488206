import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { RetryLoadLatestMessagesInnerProps, RetryLoadLatestMessagesProps } from './component';
import { RetryLoadLatestMessagesComponent } from './component';
import { RetryLoadLatestMessagesStyleRules } from './styles';

export const RetryLoadLatestMessages = compose<RetryLoadLatestMessagesInnerProps, RetryLoadLatestMessagesProps>(
    setDisplayName('RetryLoadLatestMessages'),
    withTranslation(),
    withStyles(RetryLoadLatestMessagesStyleRules)
)(RetryLoadLatestMessagesComponent);
