import type { ResolvedMessage } from '~/services/ApiClient';
import {
    OptimisticOutgoingConversationMessage,
    OptimisticOutgoingConversationMessageStatus,
} from '~/services/ApiClient';

export const sendingSomeMessage = (messages: ResolvedMessage[]): boolean =>
    messages.some(
        (message) =>
            message.value instanceof OptimisticOutgoingConversationMessage &&
            message.value.status === OptimisticOutgoingConversationMessageStatus.Sending
    );
