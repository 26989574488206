import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type {
    TrailerManualConnectionStatusFormatterInnerProps,
    TrailerManualConnectionStatusFormatterProps,
} from './component';
import { TrailerManualConnectionStatusFormatterComponent } from './component';
import { IconFormatter } from './components/Icon';

export const TrailerManualConnectionStatusIconFormatter = IconFormatter;

export const TrailerManualConnectionStatusFormatter = compose<
    TrailerManualConnectionStatusFormatterInnerProps,
    TrailerManualConnectionStatusFormatterProps
>(
    setDisplayName('TrailerManualConnectionStatusFormatter'),
    withTranslation()
)(TrailerManualConnectionStatusFormatterComponent);
