import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC, ReactNode } from 'react';

import { TitledTruncatedText } from '~/components/TitledTruncatedText';

import type { SectionListItemClassKey } from './styles';

export interface SectionListItemProps {
    label: string;
    value?: ReactNode;
    className?: string;
    valueDataId: string;
    isIconValue?: boolean;
}

export interface SectionListItemInnerProps extends SectionListItemProps, WithStyles<SectionListItemClassKey> {}

export const SectionListItemComponent: FC<SectionListItemInnerProps> = (props) => {
    const { classes, className, label, value, valueDataId, isIconValue } = props;

    const valueClassName = classNames(classes.typography, {
        [classes.value]: !isIconValue,
        [classes.icon]: isIconValue,
    });

    return (
        <ListItem className={classNames(classes.root, className)}>
            <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                className={classNames(classes.typography, classes.label)}
            >
                <TitledTruncatedText>{label}</TitledTruncatedText>
            </Typography>

            <Typography
                component="div"
                variant="caption"
                color="textPrimary"
                className={valueClassName}
                data-id={valueDataId}
                data-testid={valueDataId}
            >
                {value || '-'}
            </Typography>
        </ListItem>
    );
};
