import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { SendMessageFooterInnerProps, SendMessageFooterProps } from './component';
import { SendMessageFooterComponent } from './component';
import { SendMessageFooterStyleRules } from './styles';

export const SendMessageFooter = compose<SendMessageFooterInnerProps, SendMessageFooterProps>(
    setDisplayName('SendMessageFooter'),
    withStyles(SendMessageFooterStyleRules),
    withTranslation()
)(SendMessageFooterComponent);
