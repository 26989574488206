import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ChartLegendInnerProps, ChartLegendProps } from './component';
import { ChartLegendComponent } from './component';
import { ChartLegendStyleRules } from './styles';

export const ChartLegend = compose<ChartLegendInnerProps, ChartLegendProps>(
    setDisplayName('ChartLegend'),
    withStyles(ChartLegendStyleRules)
)(ChartLegendComponent);
