import type { WithStyles } from '@mui/styles';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import type { AssetGroup, AssetReference, AssetType, Depot } from '~/services/ApiClient';

import type { RequiredUserPreferences } from '../UserPreferences';

import type { SceneAssetSelectorUserPreferences, SceneAssetSelectorViewMode } from './preferences';
import type { SceneAssetSelectorStyleRules } from './styles';

export interface Asset {
    id: number;
}

export interface AssetReferenceWithDisplayName {
    reference: AssetReference;
    displayName: string;
}

export type AssetReferenceHash<T> = {
    [keys in AssetType]?: NumericDictionary<T>;
};

export interface AssetDataSet {
    type: AssetType;
    displayName: string;
    assets: AssetReferenceWithDisplayName[];
    icon: JSX.Element;
}

interface BasicNode {
    id: string | number;
    name: string;
}

export interface Node extends BasicNode {
    children?: BasicNode[];
}

export type SupportedNode = (AssetNode | GroupNode | DepotNode | AssetTypeNode) & {
    icon?: JSX.Element;
    isExpandable?: boolean;
    hideCheckbox?: boolean;
};

export enum NodeTypes {
    GROUP = 'GROUP',
    ASSET = 'ASSET',
    DEPOT = 'DEPOT',
    ASSET_TYPE = 'ASSET_TYPE',
}

export interface AssetNodeState {
    checked: boolean;
}

export interface AssetNode extends Node {
    id: string;
    name: string;
    assetReference: AssetReference;
    type: NodeTypes.ASSET;
    state: AssetNodeState;
    parentGroupId?: number;
}

export interface GroupNodeState {
    expanded: boolean;
    checked: boolean;
    indeterminate: boolean;
    hasAssetChildren: boolean;
    disabled: boolean;
}

export interface GroupNode extends Node {
    id: number;
    name: string;
    type: NodeTypes.GROUP;
    state: GroupNodeState;
    parentGroupId?: number;
}

export interface DepotNodeState {
    expanded: boolean;
    checked: boolean;
    indeterminate: boolean;
    hasAssetChildren: boolean;
    disabled: boolean;
}

export interface DepotNode extends Node {
    id: number;
    name: string;
    type: NodeTypes.DEPOT;
    state: DepotNodeState;
}

export interface AssetTypeNodeState {
    expanded: boolean;
    checked: boolean;
    indeterminate: boolean;
    hasAssetChildren: boolean;
    assetsCount: number;
    selectedAssetsCount: number;
}

export interface AssetTypeNode extends Node {
    id: AssetType;
    name: string;
    type: NodeTypes.ASSET_TYPE;
    state: AssetTypeNodeState;
}

export type CustomSceneUserPreferencesType<T> = Omit<RequiredUserPreferences, 'defaultState'> & {
    defaultState: T | SceneAssetSelectorUserPreferences;
};

export interface SceneAssetSelectorDerivedProps {
    viewMode: SceneAssetSelectorViewMode;
    changeViewMode: (viewMode: SceneAssetSelectorViewMode) => void;

    assetTree: SupportedNode[];
    setSelectedAssets: (assetIds: AssetReference[], parentGroupId?: number) => void;
    setExpandedGroups: (groupIds: number[]) => void;
    setExpandedDepots: (depotIds: number[]) => void;
    setSelectedGroups: (groupIds: number[]) => void;
    setSelectedDepots: (depotIds: number[]) => void;
    setCollapsedAssetTypes: (collapsedAssetTypesIds: AssetType[]) => void;

    removeUnauthorizedSelectedAssets: () => void;
    removeUnauthorizedExpandedDepots: () => void;
    removeUnauthorizedExpandedGroups: () => void;
}

export interface SceneAssetSelectorProps {
    className?: string;
    assetGroups: AssetGroup[];
    depots: Depot[];
    assetDataSets: AssetDataSet[];
    singleSelectionMode?: boolean;

    onSelectedAssetIdsChange?: (selectedAssetIds: AssetReference[]) => void;
    // Use selectedAssetIds to use the asset selector in a controlled way
    selectedAssetIds?: AssetReference[];
    onAssetContextMenu?: (event: MouseEvent, assetId: AssetReference) => void;

    preferencesKey: string;
}

export interface SceneAssetSelectorInnerProps
    extends SceneAssetSelectorProps,
        InjectedTranslationProps,
        WithStyles<typeof SceneAssetSelectorStyleRules, true> {}
