import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Checkbox, Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { isUndefined } from '~/libs/utility';

import type { ColumnSelectorGroup } from '../../models';

import type { ColumnGroupClassKey } from './styles';

export interface ColumnGroupProps {
    columnGroup: ColumnSelectorGroup;
    selectedColumns: string[];
    selectColumns: (columns: string[]) => void;
    deselectColumns: (columns: string[]) => void;
    groupIsExpanded: boolean;
    toggleGroupExpand?: (expanded: boolean) => void;
}

export interface ColumnGroupInnerProps extends ColumnGroupProps, WithStyles<ColumnGroupClassKey> {}

export const ColumnGroupComponent: React.FC<ColumnGroupInnerProps> = ({
    columnGroup,
    selectedColumns,
    deselectColumns,
    selectColumns,
    classes,
    groupIsExpanded,
    toggleGroupExpand,
}) => {
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => setExpanded(groupIsExpanded), [groupIsExpanded, setExpanded]);

    const toggleExpand = React.useCallback(() => {
        setExpanded(!expanded);
        if (!isUndefined(toggleGroupExpand)) {
            toggleGroupExpand(!expanded);
        }
    }, [expanded, setExpanded, toggleGroupExpand]);

    const groupSelected = React.useMemo(() => {
        return columnGroup.columns.every((c) => selectedColumns.includes(c.name));
    }, [columnGroup, selectedColumns]);

    const partialSelected = React.useMemo(() => {
        return !groupSelected && columnGroup.columns.some((c) => selectedColumns.includes(c.name));
    }, [groupSelected, columnGroup, selectedColumns]);

    const toggleGroup = React.useCallback(() => {
        const groupColumnNames = columnGroup.columns.map((c) => c.name);
        if (groupSelected || partialSelected) {
            deselectColumns(groupColumnNames);
        } else {
            selectColumns(groupColumnNames);
        }
    }, [columnGroup, partialSelected, groupSelected, selectColumns, deselectColumns]);

    const columns = React.useMemo(() => {
        return columnGroup.columns.map((column) => {
            const selected = selectedColumns.includes(column.name);
            const toggleColumn = selected ? () => deselectColumns([column.name]) : () => selectColumns([column.name]);

            return (
                <ListItem key={column.name} className={classes.listItem} data-id={`column:${column.name}`}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <Checkbox
                            className={classes.columnCheckbox}
                            checked={selected}
                            onClick={toggleColumn}
                            data-id="column-checkbox"
                        />
                    </ListItemIcon>
                    <ListItemText data-id="title">{column.title}</ListItemText>
                </ListItem>
            );
        });
    }, [classes, columnGroup, selectedColumns, selectColumns, deselectColumns]);

    const toggleIcon = expanded ? <ExpandMore /> : <ChevronRight />;
    const toggleDataId = expanded ? 'collapse' : 'expand';
    const collapsableId = `column-group-items:${columnGroup.title}`;

    return (
        <>
            <ListItem
                className={classes.listItem}
                data-id={`column-group:${columnGroup.title}`}
                aria-owns={collapsableId}
            >
                <ListItemIcon
                    className={classNames(classes.listItemIcon, classes.toggleButton)}
                    onClick={toggleExpand}
                    data-id={toggleDataId}
                >
                    {toggleIcon}
                </ListItemIcon>
                <ListItemIcon className={classes.listItemIcon}>
                    <Checkbox
                        className={classes.groupCheckbox}
                        indeterminate={partialSelected}
                        checked={groupSelected}
                        onClick={toggleGroup}
                        data-id="group-checkbox"
                    />
                </ListItemIcon>
                <ListItemText data-id="title">{columnGroup.title}</ListItemText>
            </ListItem>
            <Collapse in={expanded}>
                <List dense disablePadding id={collapsableId}>
                    {columns}
                </List>
            </Collapse>
        </>
    );
};
