import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TrailerEntryRowClassKey = 'root' | 'avatar' | 'content' | 'primaryText' | 'delimiter';

export const TrailerEntryRowStyleRules: StyleRulesCallback<Theme, {}, TrailerEntryRowClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
            alignItems: 'start',
        },
        avatar: {
            color: theme.functionalItemsColors.assetType.trailer.value,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.text.secondary}`,
            marginRight: theme.spacing(2),
        },
        content: {
            margin: 0,
        },
        primaryText: {
            fontWeight: theme.typography.fontWeightMedium,
        },
        delimiter: {
            margin: theme.spacing(0, 0.5),
        },
    });
