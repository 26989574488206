import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ActivityDetailsListItemInnerProps, ActivityDetailsListItemProps } from './component';
import { ActivityDetailsListItemComponent } from './component';
import { ActivityDetailsListItemStyleRules } from './styles';

export const ActivityDetailsListItem = compose<ActivityDetailsListItemInnerProps, ActivityDetailsListItemProps>(
    setDisplayName('ActivityDetailsListItem'),
    withStyles(ActivityDetailsListItemStyleRules),
    withTranslation()
)(ActivityDetailsListItemComponent);
