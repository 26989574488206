import { Tooltip } from '@mui/material';
import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import type { Classification } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { InhibitorIconFormatter } from './components/InhibitorAvatarIcon';

export interface InhibitorStatusFormatterProps {
    t: SingleTFunction;
    value: Classification;
}

export interface InhibitorStatusFormatterInnerProps extends InhibitorStatusFormatterProps {}

const InhibitorStatusFormatter: React.FunctionComponent<InhibitorStatusFormatterInnerProps> = ({ t, value }) => {
    return value ? (
        <Tooltip title={formatClassification(t, ClassificationType.InhibitorStatus, value)}>
            <span data-id={`inhibitor-status:${value.key}`}>
                <InhibitorIconFormatter value={value} />
            </span>
        </Tooltip>
    ) : null;
};
InhibitorStatusFormatter.displayName = 'InhibitorStatusFormatter';

export { InhibitorStatusFormatter };
