import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { isEmpty, isUndefined } from '../../../utility';
import type { BaseItem } from '../../models';

import type { ItemContentProps } from './models';
import { Container, ExpandLess, ExpandMore, TreeRowContainer, TreeRowItem } from './styles';

const ItemContent = <T extends BaseItem<T>>(props: ItemContentProps<T>) => {
    const {
        checkedKeys,
        expandedKeys,
        index,
        isDisabled,
        isRoot,
        item,
        onCheck,
        onToggleCollapse,
        parentId,
        type,
        withExpand = false,
    } = props;

    const hasChildren = !isEmpty(item.children);

    return (
        <Container
            data-testid={`asset-tree-node:${type}-${item.id}`}
            isRoot={isRoot}
            key={`list-item-${index}-${item.id}`}
            {...(!isUndefined(parentId) && { 'data-parent-id': parentId })}
        >
            <TreeRowContainer>
                {withExpand &&
                    (isUndefined(expandedKeys[item.id]) ? (
                        <ExpandMore
                            isDisable={!hasChildren}
                            data-testid={`expand-more-${item.id}`}
                            onClick={(event) => {
                                if (hasChildren) {
                                    onToggleCollapse({ value: item, isCollapsed: true, event });
                                }
                            }}
                        />
                    ) : (
                        <ExpandLess
                            isDisable={!hasChildren}
                            data-testid={`expand-less-${item.id}`}
                            onClick={(event) => {
                                if (hasChildren) {
                                    onToggleCollapse({ value: item, isCollapsed: false, event });
                                }
                            }}
                        />
                    ))}

                <TreeRowItem order={3}>
                    <Switch
                        data-testid={`checkbox-input-${item.id}`}
                        checked={!isUndefined(checkedKeys[item.id])}
                        disabled={isDisabled}
                        onChange={(event, isChecked) => onCheck({ isChecked, value: item, event })}
                    />
                </TreeRowItem>

                <TreeRowItem order={2} flexGrow={1}>
                    <Typography variant="body2" color="textPrimary">
                        {item.name}
                    </Typography>
                </TreeRowItem>
            </TreeRowContainer>

            {item.children?.map((child, innerIndex) => (
                <Collapse
                    data-testid={`item-content-collapsible-${child.id}`}
                    key={`item-content-collapsible-${child.id}`}
                    in={!isUndefined(expandedKeys[item.id])}
                >
                    <ItemContent
                        checkedKeys={checkedKeys}
                        expandedKeys={expandedKeys}
                        index={innerIndex}
                        isDisabled={isDisabled}
                        isRoot={false}
                        item={child}
                        onCheck={onCheck}
                        onToggleCollapse={onToggleCollapse}
                        parentId={parentId}
                        type={type}
                        withExpand={withExpand}
                    />
                </Collapse>
            ))}
        </Container>
    );
};

ItemContent.displayName = 'ItemContent';
export { ItemContent };
