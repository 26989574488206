import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { TimeSeriesDialogContentInnerProps, TimeSeriesDialogContentProps } from './component';
import { TimeSeriesDialogContentComponent } from './component';
import { TimeSeriesDialogContentStyleRules } from './styles';

export const TimeSeriesDialogContentFactory = <T>(): React.ComponentType<TimeSeriesDialogContentProps<T>> =>
    compose<TimeSeriesDialogContentInnerProps<T>, TimeSeriesDialogContentProps<T>>(
        setDisplayName('TimeSeriesDialogContent'),
        withTranslation(),
        withStyles(TimeSeriesDialogContentStyleRules)
    )(TimeSeriesDialogContentComponent);
