import moment from 'moment';

import type { TicksResult } from './models';

type CalculateTicksArgs = {
    start: Date;
    stop: Date;
    maxTickCount: number;
};

const intervals = [
    moment.duration(5, 'minute'),
    moment.duration(15, 'minute'),
    moment.duration(30, 'minute'),
    moment.duration(1, 'hour'),
    moment.duration(3, 'hour'),
    moment.duration(6, 'hour'),
    moment.duration(12, 'hour'),
    moment.duration(1, 'day'),
];
export const calculateTicks = ({ stop, maxTickCount, start }: CalculateTicksArgs): TicksResult => {
    const min = moment(start);
    const max = moment(stop);
    const cursor = moment(min);

    // Determine the interval
    const duration = max.diff(min);
    const perfectInterval = moment.duration(duration / maxTickCount);
    const interval = intervals.find((i) => i > perfectInterval) ?? intervals[intervals.length - 1];

    // Calculate if it is a different day
    const diffDays = moment.duration(duration).asDays() > 1;

    // Poor mans ceil of min-date to interval
    cursor.startOf('day');
    while (cursor < min) {
        cursor.add(interval);
    }

    // Build the actual ticks
    const result: Date[] = [];
    while (cursor <= max) {
        result.push(cursor.toDate());
        cursor.add(interval);
    }

    return {
        diffDays,
        ticks: result,
    };
};
