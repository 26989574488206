import type {
    AdminVehicleRead,
    GetVehicleSettingsResponse,
    ServerResultStatus,
    VehicleChangelogsResponse,
    VehicleSettingsRead,
} from '~/services/ApiClient';
import type { BaseAction, Fulfilled, FulfilledMeta, Pending, PendingMeta, Rejected, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    ADMINVEHICLEGET = 'ADMINVEHICLEGET',
    ADMINVEHICLEUPDATE = 'ADMINVEHICLEUPDATE',
    ADMINVEHICLECREATE = 'ADMINVEHICLECREATE',
    ADMINVEHICLECHANGELOGS_GET = 'ADMINVEHICLECHANGELOGS_GET',
    ADMINVEHICLEDEFAULTSETTINGS_GET = 'ADMINVEHICLEDEFAULTSETTINGS_GET',
    ADMINVEHICLESETTINGS_GET = 'ADMINVEHICLESETTINGS_GET',
    ADMINVEHICLE_DELETE = 'ADMINVEHICLE_DELETE',
}

export enum ActionTypeKeys {
    ADMINVEHICLEGET_PENDING = 'ADMINVEHICLEGET_PENDING',
    ADMINVEHICLEGET_FULFILLED = 'ADMINVEHICLEGET_FULFILLED',
    ADMINVEHICLEGET_REJECTED = 'ADMINVEHICLEGET_REJECTED',
    ADMINVEHICLECREATE_PENDING = 'ADMINVEHICLECREATE_PENDING',
    ADMINVEHICLECREATE_FULFILLED = 'ADMINVEHICLECREATE_FULFILLED',
    ADMINVEHICLECREATE_REJECTED = 'ADMINVEHICLECREATE_REJECTED',
    ADMINVEHICLEUPDATE_PENDING = 'ADMINVEHICLEUPDATE_PENDING',
    ADMINVEHICLEUPDATE_FULFILLED = 'ADMINVEHICLEUPDATE_FULFILLED',
    ADMINVEHICLEUPDATE_REJECTED = 'ADMINVEHICLEUPDATE_REJECTED',
    ADMINVEHICLEUPDATE_RESET = 'ADMINVEHICLEUPDATE_RESET',
    ADMINVEHICLECHANGELOGS_GET_PENDING = 'ADMINVEHICLECHANGELOGS_GET_PENDING',
    ADMINVEHICLECHANGELOGS_GET_FULFILLED = 'ADMINVEHICLECHANGELOGS_GET_FULFILLED',
    ADMINVEHICLECHANGELOGS_GET_REJECTED = 'ADMINVEHICLECHANGELOGS_GET_REJECTED',
    ADMINVEHICLEDEFAULTSETTINGS_GET_PENDING = 'ADMINVEHICLEDEFAULTSETTINGS_GET_PENDING',
    ADMINVEHICLEDEFAULTSETTINGS_GET_FULFILLED = 'ADMINVEHICLEDEFAULTSETTINGS_GET_FULFILLED',
    ADMINVEHICLEDEFAULTSETTINGS_GET_REJECTED = 'ADMINVEHICLEDEFAULTSETTINGS_GET_REJECTED',
    ADMINVEHICLESETTINGS_GET_PENDING = 'ADMINVEHICLESETTINGS_GET_PENDING',
    ADMINVEHICLESETTINGS_GET_FULFILLED = 'ADMINVEHICLESETTINGS_GET_FULFILLED',
    ADMINVEHICLESETTINGS_GET_REJECTED = 'ADMINVEHICLESETTINGS_GET_REJECTED',
    ADMINVEHICLE_DELETE_PENDING = 'ADMINVEHICLE_DELETE_PENDING',
    ADMINVEHICLE_DELETE_FULFILLED = 'ADMINVEHICLE_DELETE_FULFILLED',
    ADMINVEHICLE_DELETE_REJECTED = 'ADMINVEHICLE_DELETE_REJECTED',
}

export interface AdminVehicleGetPayload {
    readonly items: AdminVehicleRead[];
}
export interface AdminVehicleSettingsGetPayload {
    readonly item: VehicleSettingsRead;
}
export type ActionTypes =
    | Pending<ActionTypeKeys.ADMINVEHICLEGET_PENDING>
    | Fulfilled<ActionTypeKeys.ADMINVEHICLEGET_FULFILLED, AdminVehicleGetPayload>
    | Rejected<ActionTypeKeys.ADMINVEHICLEGET_REJECTED>
    | Pending<ActionTypeKeys.ADMINVEHICLEUPDATE_PENDING>
    | Fulfilled<ActionTypeKeys.ADMINVEHICLEUPDATE_FULFILLED, ServerResultStatus>
    | Rejected<ActionTypeKeys.ADMINVEHICLEUPDATE_REJECTED>
    | Pending<ActionTypeKeys.ADMINVEHICLECREATE_PENDING>
    | Fulfilled<ActionTypeKeys.ADMINVEHICLECREATE_FULFILLED, ServerResultStatus>
    | Rejected<ActionTypeKeys.ADMINVEHICLECREATE_REJECTED>
    | BaseAction<ActionTypeKeys.ADMINVEHICLEUPDATE_RESET>
    | PendingMeta<ActionTypeKeys.ADMINVEHICLECHANGELOGS_GET_PENDING, number>
    | FulfilledMeta<ActionTypeKeys.ADMINVEHICLECHANGELOGS_GET_FULFILLED, Readonly<VehicleChangelogsResponse>, number>
    | RejectedMeta<ActionTypeKeys.ADMINVEHICLECHANGELOGS_GET_REJECTED, undefined, number>
    | Pending<ActionTypeKeys.ADMINVEHICLEDEFAULTSETTINGS_GET_PENDING>
    | Fulfilled<ActionTypeKeys.ADMINVEHICLEDEFAULTSETTINGS_GET_FULFILLED, AdminVehicleSettingsGetPayload>
    | Rejected<ActionTypeKeys.ADMINVEHICLEDEFAULTSETTINGS_GET_REJECTED>
    | PendingMeta<ActionTypeKeys.ADMINVEHICLESETTINGS_GET_PENDING, number>
    | FulfilledMeta<ActionTypeKeys.ADMINVEHICLESETTINGS_GET_FULFILLED, Readonly<GetVehicleSettingsResponse>, number>
    | RejectedMeta<ActionTypeKeys.ADMINVEHICLESETTINGS_GET_REJECTED, undefined, number>
    | Pending<ActionTypeKeys.ADMINVEHICLE_DELETE_PENDING>
    | Fulfilled<ActionTypeKeys.ADMINVEHICLE_DELETE_FULFILLED, ServerResultStatus>
    | Rejected<ActionTypeKeys.ADMINVEHICLE_DELETE_REJECTED>;
