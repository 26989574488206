import type { NotificationSubscription, ResourceFilter } from '~/services/ApiClient';

export interface Subscription {
    parse: (data: unknown) => unknown;
    handler: (data: unknown) => void;
    renewSubscriptionTimeout?: number;
    locked?: boolean;
    notificationSubscription?: NotificationSubscription;
    resourceType: string;
    resourceFilter?: ResourceFilter;
}

export enum ConnectionStatus {
    CONNECTED = 'CONNECTED',
    DISCONNECTING = 'DISCONNECTING',
    DISCONNECTED = 'DISCONNECTED',
    TRYINGTORECONNECT = 'TRYINGTORECONNECT',
    CONNECTIONLOST = 'CONNECTIONLOST',
}

export type ConnectionStatusChangedHandler = (
    connectionStatus: ConnectionStatus,
    prevConnectionStatus: ConnectionStatus
) => void;
