import { Button, FormControl, TextField, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { ButtonProgress } from '~/components/ButtonProgress';
import { useTranslation } from '~/components/LanguageSelector';
import { Severity, ValidationBox } from '~/components/ValidationBox';
import { ServerResultStatus } from '~/services/ApiClient';

import { ResetPasswordNotice } from './components/ResetPasswordNotice';
import { useStyles } from './styles';

export interface RequestResetPasswordProps {}

export interface RequestResetPasswordStateProps {
    resetPasswordResetStatus?: ServerResultStatus;
}

export interface RequestResetPasswordDispatchProps {
    requestResetPassword: (customer: string, username: string) => void;
    clearData: () => void;
}

export interface RequestResetPasswordInnerProps
    extends RequestResetPasswordProps,
        RequestResetPasswordStateProps,
        RequestResetPasswordDispatchProps {}

export const RequestResetPasswordComponent: React.FC<RequestResetPasswordInnerProps> = ({
    resetPasswordResetStatus,
    requestResetPassword,
    clearData,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [customer, setCustomer] = React.useState('');
    const [username, setUsername] = React.useState('');

    React.useEffect(() => {
        clearData();
    }, [clearData]);

    const handleSubmit = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            requestResetPassword(customer, username);
        },
        [customer, requestResetPassword, username]
    );

    const onCustomerChangeCallback = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer(e.target.value);
    }, []);
    const onUsernameChangeCallback = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    }, []);

    const getContent = React.useCallback(() => {
        if (resetPasswordResetStatus === ServerResultStatus.ACCEPTED) {
            return <ResetPasswordNotice />;
        } else {
            const formIsValid = customer && username;
            const showProgress = resetPasswordResetStatus === ServerResultStatus.PENDING;

            return (
                <>
                    {resetPasswordResetStatus === ServerResultStatus.SERVERERROR && (
                        <ValidationBox message={t('not-able-to-process-request')} severity={Severity.Error} />
                    )}

                    <div className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            {t('reset-password')}
                        </Typography>
                        <br />
                        <Typography variant="body2">{t('request-reset-password-first-paragraph')}</Typography>
                        <br />
                        <Typography variant="body2">{t('request-reset-password-second-paragraph')}</Typography>
                        <form data-id="request-reset-password-form" method="post" onSubmit={handleSubmit}>
                            <TextField
                                variant="standard"
                                label={t('customer')}
                                data-id="customer"
                                required
                                autoComplete="organization"
                                fullWidth
                                margin="normal"
                                value={customer}
                                onChange={onCustomerChangeCallback}
                            />
                            <TextField
                                variant="standard"
                                label={t('username')}
                                data-id="username"
                                required
                                autoComplete="username"
                                fullWidth
                                margin="normal"
                                value={username}
                                onChange={onUsernameChangeCallback}
                            />
                            <FormControl variant="standard" fullWidth margin="normal">
                                <Button
                                    color="secondary"
                                    data-id="request-reset-password"
                                    type="submit"
                                    variant="contained"
                                    disabled={!formIsValid || showProgress}
                                >
                                    {t('reset-my-password')}
                                </Button>
                                <ButtonProgress showProgress={showProgress} />
                            </FormControl>
                        </form>
                        <Link to="/" data-id="back-link" className={classes.backLink}>
                            {t('reset-password-link-back-to-login')}
                        </Link>
                    </div>
                </>
            );
        }
    }, [
        t,
        resetPasswordResetStatus,
        customer,
        username,
        classes.root,
        classes.backLink,
        handleSubmit,
        onCustomerChangeCallback,
        onUsernameChangeCallback,
    ]);

    return getContent();
};
