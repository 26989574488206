import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { SummarySectionInnerProps, SummarySectionProps } from './component';
import { SummarySectionComponent } from './component';

export const SummarySection = compose<SummarySectionInnerProps, SummarySectionProps>(
    setDisplayName('SummarySection'),
    withDisplayPreferences(),
    withTranslation()
)(SummarySectionComponent);
