import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { IndividualPositionInnerProps, IndividualPositionOuterProps } from './component';
import { IndividualPositionComponent } from './component';
import { IndividualPositionStyleRules } from './styles';

export const IndividualPosition = compose<IndividualPositionInnerProps, IndividualPositionOuterProps>(
    setDisplayName('IndividualPosition'),
    withStyles(IndividualPositionStyleRules)
)(IndividualPositionComponent);
