import { TextField } from '@mui/material';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { FieldWorkflowFormElement, WorkflowFieldValue } from '~/services/ApiClient';
import {
    BooleanFieldWorkflowFormElement,
    BooleanWorkflowFieldValue,
    DateTimeFieldWorkflowFormElement,
    DateTimeWorkflowFieldValue,
    DurationFieldWorkflowFormElement,
    DurationWorkflowFieldValue,
    EnumFieldWorkflowFormElement,
    EnumWorkflowFieldValue,
    NumericFieldWorkflowFormElement,
    NumericWorkflowFieldValue,
    PositionFieldWorkflowFormElement,
    PositionWorkflowFieldValue,
    RawFieldWorkflowFormElement,
    RawWorkflowFieldValue,
    TextFieldWorkflowFormElement,
    TextWorkflowFieldValue,
} from '~/services/ApiClient';

import { CheckboxWorkflowField } from './components/CheckboxWorkflowField';
import { DateTimeWorkflowField } from './components/DateTimeWorkflowField';
import { DurationWorkflowField } from './components/DurationWorkflowField';
import { EnumerationWorkflowField } from './components/EnumerationWorkflowField';
import { NumericWorkflowField } from './components/NumericWorkflowField';
import { PositionWorkflowField } from './components/PositionWorkflowField';
import { TextWorkflowField } from './components/TextWorkflowField';

export interface WorkflowEditFieldProps {
    field: FieldWorkflowFormElement;
    getWorkflowBodyRef?: () => HTMLDivElement | null;
    value?: WorkflowFieldValue;
    label?: string;
    disabled?: boolean;
    dataId: string;
}

export interface WorkflowEditFieldInnerProps extends WorkflowEditFieldProps, InjectedTranslationProps {}

// eslint-disable-next-line @typescript-eslint/ban-types
const valueTypeMapping = new Map<Function, Function>([
    [RawFieldWorkflowFormElement, RawWorkflowFieldValue],
    [TextFieldWorkflowFormElement, TextWorkflowFieldValue],
    [PositionFieldWorkflowFormElement, PositionWorkflowFieldValue],
    [NumericFieldWorkflowFormElement, NumericWorkflowFieldValue],
    [BooleanFieldWorkflowFormElement, BooleanWorkflowFieldValue],
    [EnumFieldWorkflowFormElement, EnumWorkflowFieldValue],
    [DateTimeFieldWorkflowFormElement, DateTimeWorkflowFieldValue],
    [DurationFieldWorkflowFormElement, DurationWorkflowFieldValue],
]);

export const WorkflowEditFieldComponent: React.FC<WorkflowEditFieldInnerProps> = ({
    field,
    value,
    label,
    dataId,
    disabled,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const expectedValueType = valueTypeMapping.get(field.constructor);
    if (value && expectedValueType) {
        if (!(value instanceof expectedValueType)) {
            throw new Error(`Expected value type '${expectedValueType.name}', but got '${value.constructor.name}'`);
        }
    }

    if (field instanceof RawFieldWorkflowFormElement) {
        return (
            <Controller
                defaultValue=""
                name={`${field.id}`}
                render={({ field: fieldFormProps }) => (
                    <TextField
                        {...fieldFormProps}
                        disabled={disabled}
                        fullWidth
                        data-id={dataId}
                        label={label}
                        variant="outlined"
                        error={!!errors[field.id]}
                        required={field.isRequired}
                    />
                )}
                control={control}
                rules={{ required: field.isRequired }}
            />
        );
    }

    if (field instanceof TextFieldWorkflowFormElement) {
        return <TextWorkflowField field={field} dataId={dataId} label={label} disabled={disabled} />;
    }

    if (field instanceof PositionFieldWorkflowFormElement) {
        return <PositionWorkflowField field={field} label={label} dataId={dataId} disabled={disabled} />;
    }

    if (field instanceof NumericFieldWorkflowFormElement) {
        return <NumericWorkflowField field={field} dataId={dataId} label={label} disabled={disabled} />;
    }

    if (field instanceof EnumFieldWorkflowFormElement) {
        return <EnumerationWorkflowField field={field} label={label} dataId={dataId} disabled={disabled} />;
    }

    if (field instanceof BooleanFieldWorkflowFormElement) {
        return <CheckboxWorkflowField field={field} label={label} dataId={dataId} disabled={disabled} />;
    }

    if (field instanceof DateTimeFieldWorkflowFormElement) {
        return <DateTimeWorkflowField field={field} label={label} dataId={dataId} disabled={disabled} />;
    }

    if (field instanceof DurationFieldWorkflowFormElement) {
        return <DurationWorkflowField field={field} dataId={dataId} label={label} disabled={disabled} />;
    }

    throw new Error(`Unhandled form element of type '${field.constructor.name}'`);
};
