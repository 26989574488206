import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { TrailerConnectionFilteringInnerProps, TrailerConnectionFilteringProps } from './component';
import { TrailerConnectionFilteringComponent } from './component';
import { trailerConnectionFilteringStyleRules } from './styles';

export type { TrailerConnectionFilter } from './component';

export const TrailerConnectionFiltering = compose<
    TrailerConnectionFilteringInnerProps,
    TrailerConnectionFilteringProps
>(
    setDisplayName('TrailerConnectionFiltering'),
    withTranslation(),
    withStyles(trailerConnectionFilteringStyleRules)
)(TrailerConnectionFilteringComponent);
