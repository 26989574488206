import { withTranslation } from 'react-i18next';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';

import type { RtdsSessionsListViewInnerProps, RtdsSessionsListViewProps } from './component';
import { RtdsSessionsListViewComponent } from './component';

export const RtdsSessionsListView = compose<RtdsSessionsListViewInnerProps, RtdsSessionsListViewProps>(
    setDisplayName('RtdsSessionsListView'),
    withDisplayPreferences(),
    withTranslation()
)(RtdsSessionsListViewComponent);
