import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ensureAuthorizationSelector } from '~/components/EnsureAuthorization/selectors';
import { changeActiveAlertCount, getActiveAlertCount } from '~/data/alerts';
import { throttle } from '~/libs/utility';
import type { Disposable } from '~/listeners';
import { AlertCountNotification } from '~/services/ApiClient';
import { subscribeToNotificationsAndRetrieveTheData } from '~/services/SignalR';

export const ActiveCountAutoUpdate = () => {
    const signalrSubscription = useRef<Promise<Disposable>>();
    const signalrSubscribing = useRef<boolean>(false);
    const dispatch = useDispatch();
    const { securables } = useSelector(ensureAuthorizationSelector);

    useEffect(() => {
        if (!securables.alertManager.isAllowed) {
            return;
        }

        signalrSubscribing.current = true;

        if (signalrSubscription.current) {
            signalrSubscription.current.then((s) => s.dispose());
        }

        const refreshThrottled = throttle((count: number) => {
            dispatch(changeActiveAlertCount(count));
        });

        signalrSubscription.current = subscribeToNotificationsAndRetrieveTheData<AlertCountNotification>(
            'alertcount',
            AlertCountNotification.fromJS,
            (notification) => {
                refreshThrottled(notification.count);
            },
            () => {
                dispatch(getActiveAlertCount());
            }
        );

        signalrSubscription.current.then(() => {
            signalrSubscribing.current = false;
        });

        return () => {
            if (signalrSubscription.current) {
                signalrSubscription.current.then((s) => s.dispose());
                signalrSubscription.current = undefined;
            }
        };
    }, [dispatch, securables.alertManager.isAllowed]);

    return null;
};
