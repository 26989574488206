import type { RouteComponentProps } from 'react-router';
import type { Dispatch } from 'redux';

import type { StaticDataStoreState } from '~/common';
import { getSelectedAuthorizedDrivers } from '~/common/utils/driverAssetAuthorized';
import { getSelectedAuthorizedVehicles } from '~/common/utils/vehicleAssetAuthorized';
import type { SceneAssetSelectorUserPreferences } from '~/components/SceneAssetSelector';
import type { DetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { queryRtdsSchedulesAction } from '~/data/rtdsschedules';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import type { Securables } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { clearDataAction } from './data';
import type { RtdsSchedulesEntry, Subpage } from './models';
import type { RtdsSchedulesUserPreferences } from './preferences';
import { RTDSSCHEDULES_USERPREFERENCES_KEY } from './preferences';
import type { DynamicRtdsScheduleStoreState } from './reducers';

export interface RtdsSchedulesReduxProps
    extends Omit<DispatchProps, 'changeLeftPaneVisibility'>,
        StateProps,
        MergedProps {}

export interface MergedProps {
    toggleLeftPaneVisibility: () => void;
}

export interface UrlParams {
    scheduleId?: string;
    subpage?: Subpage;
}
export interface DispatchProps {
    clearData: () => void;
    getRtdsSchedules: (vehicleIds: number[] | undefined, driverIds: number[] | undefined) => void;
    changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => void;
}
export interface StateProps {
    authorized: boolean;
    contentLoading: boolean;
    rtdsSchedulesEntries: RtdsSchedulesEntry[];
    leftPaneIsOpen: boolean;
    selectedVehicleIds?: number[];
    selectedDriverIds?: number[];
    keepSubpageOpen: boolean;
    loading: boolean;
    rejected: boolean;
    selectedScheduleId?: number;
    subpage?: Subpage;
}

export const mapStateToProps = (
    securables: Securables,
    staticDataStoreState: StaticDataStoreState,
    rtdsScheduleUserPreferences: RtdsSchedulesUserPreferences,
    rtdsSchedulesAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences,
    detailsPaneUserPreferences: DetailsPaneUserPreferences,
    dynamicRtdsSchedulesState: DynamicRtdsScheduleStoreState
): StateProps => {
    const { vehicles, drivers } = staticDataStoreState;

    return {
        authorized: securables.rtds.schedules,
        rtdsSchedulesEntries: dynamicRtdsSchedulesState.rtdsSchedulesEntries,
        loading: !vehicles.fulfilled || !drivers.fulfilled || !dynamicRtdsSchedulesState.rtdsSchedules.fulfilled,
        rejected: vehicles.rejected || drivers.rejected || dynamicRtdsSchedulesState.rtdsSchedules.rejected,
        contentLoading: dynamicRtdsSchedulesState.rtdsSchedules.pending,
        leftPaneIsOpen: rtdsScheduleUserPreferences.leftPaneIsOpen,
        selectedVehicleIds: getSelectedAuthorizedVehicles(
            vehicles,
            rtdsSchedulesAssetSelectorUserPreferences.selectedAssetIds
        ),
        selectedDriverIds: getSelectedAuthorizedDrivers(
            drivers,
            rtdsSchedulesAssetSelectorUserPreferences.selectedAssetIds
        ),
        keepSubpageOpen: detailsPaneUserPreferences.keepSubPageOpen,
    };
};

export const mapDispatchProps = (dispatch: Dispatch): DispatchProps => ({
    clearData: () => {
        dispatch(clearDataAction());
    },
    changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => {
        dispatch(updateUserPreferencesAction(RTDSSCHEDULES_USERPREFERENCES_KEY, { leftPaneIsOpen }));
    },
    getRtdsSchedules: (vehicleIds: number[] | undefined, driverIds: number[] | undefined) => {
        dispatch(queryRtdsSchedulesAction(vehicleIds, driverIds));
    },
});

const toggleLeftPaneVisibilityMemoized = memoizeOne(
    (changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => void, leftPaneIsOpen: boolean) => () => {
        changeLeftPaneVisibility(!leftPaneIsOpen);
    }
);

export const mergeProps = (
    { leftPaneIsOpen, selectedVehicleIds, selectedDriverIds, ...restStateProps }: StateProps,
    { changeLeftPaneVisibility, ...restDispatchProps }: DispatchProps,
    { match: { params }, ...restOwnProps }: RouteComponentProps<UrlParams>
): RtdsSchedulesReduxProps => {
    const leftPaneForceOpen =
        selectedVehicleIds?.length === 0 && selectedDriverIds?.length === 0 ? false : leftPaneIsOpen;
    const selectedScheduleId = params.scheduleId ? Number(params.scheduleId) : undefined;
    const { subpage } = params;
    return {
        ...restStateProps,
        ...restDispatchProps,
        ...restOwnProps,
        toggleLeftPaneVisibility: toggleLeftPaneVisibilityMemoized(changeLeftPaneVisibility, leftPaneForceOpen),
        leftPaneIsOpen: leftPaneForceOpen ? true : leftPaneIsOpen,
        selectedDriverIds,
        selectedVehicleIds,
        selectedScheduleId,
        subpage,
    };
};
