import type { StoreState } from '~/reducers';
import { vehicleAdministrationSceneStateSelector } from '~/selectors';

import type { VehicleAdministrationChangelogsStoreState } from './components/DetailsPane/components/VehicleAdministrationChangelogsSubpage';
import type { AdminVehicleStoreState } from './reducers';

export const vehicleAdministrationStateSelector = (s: StoreState): AdminVehicleStoreState =>
    vehicleAdministrationSceneStateSelector(s).adminVehicleStoreState;

export const vehicleAdministrationChangelogsSelector = (s: StoreState): VehicleAdministrationChangelogsStoreState => {
    return vehicleAdministrationSceneStateSelector(s).vehicleAdministrationChangelogsRoot;
};
