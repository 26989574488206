import type { TilesTypes } from '@fv/components';
import type { MaplibreGLTile } from '@fv/components/Map/models';
import { useMaplibreStyle } from '@fv/hooks/useMaplibreStyle';
import type { MaplibreGL } from 'leaflet';
import { maplibreGL } from 'leaflet';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import '@maplibre/maplibre-gl-leaflet';

const MaplibreTileLayer: FC<TilesTypes> = (props) => {
    const mapReference = useMap();

    const { mapMode, includeTrafficIncidents, includeTruckAttributes, language } = props as MaplibreGLTile;
    const maplibreStyle = useMaplibreStyle({ mapMode, includeTrafficIncidents, includeTruckAttributes, language });

    useEffect(() => {
        let layer: MaplibreGL;

        if (maplibreStyle) {
            layer = maplibreGL({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                style: maplibreStyle,
            });
            mapReference.addLayer(layer);
        }

        return () => {
            if (layer) {
                mapReference.removeLayer(layer);
            }
        };
    }, [mapReference, maplibreStyle]);

    return <span />;
};

export { MaplibreTileLayer };
