import { Component } from 'react';

import { isEqual } from '../../../utility';
import { JSXMarker } from '../JSXMarker';

import type { AnimatedMarkerInnerProps, AnimatedMarkerState } from './models';

class AnimatedMarker<T> extends Component<AnimatedMarkerInnerProps<T>, AnimatedMarkerState> {
    private timeoutHandle?: number;
    static displayName: 'AnimatedMarker';

    constructor(props: AnimatedMarkerInnerProps<T>) {
        super(props);
        this.state = {
            isAnimated: false,
        };
    }

    public shouldComponentUpdate(nextProps: AnimatedMarkerInnerProps<T>, nextState: AnimatedMarkerState) {
        const { entryEquals, entry } = this.props;
        const entryEqual = entryEquals(entry, nextProps.entry);

        return (
            // no current animation, an update arrives
            (!this.state.isAnimated && !entryEqual) ||
            // no update, but animation state changes - must be updated in the ui
            (entryEqual && this.state.isAnimated !== nextState.isAnimated) ||
            !isEqual(this.props.position, nextProps.position)
        );
    }

    public componentDidUpdate(prevProps: AnimatedMarkerInnerProps<T>) {
        const { updateAnimationDuration, entry, entryEquals } = this.props;
        if (!entryEquals(entry, prevProps.entry) && !this.state.isAnimated) {
            this.setState((prevState) => {
                if (prevState.isAnimated) {
                    return null;
                }

                this.timeoutHandle = window.setTimeout(() => {
                    this.setState(() => {
                        this.timeoutHandle = undefined;
                        return { isAnimated: false };
                    });
                }, updateAnimationDuration);

                return { isAnimated: true };
            });
        }
    }

    public componentWillUnmount() {
        if (this.timeoutHandle) {
            window.clearTimeout(this.timeoutHandle);
        }
    }

    public render() {
        const { children, updateAnimationDuration, ...childProps } = this.props;
        const { isAnimated } = this.state;

        if (!children) {
            return null;
        }

        return (
            <JSXMarker {...childProps}>
                {children({ animating: isAnimated, duration: updateAnimationDuration })}
            </JSXMarker>
        );
    }
}

export { AnimatedMarker };
