import { escapeRegExp } from '~/libs/utility';
import type { DisplayUserPreferencesVehicleDisplayFormat } from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';

import type { ConversationSummaryEntry } from './conversationSummaryEntry';

export const filterConversationSummaryEntries = (
    searchQuery: string | undefined,
    entries: ConversationSummaryEntry[],
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat
): ConversationSummaryEntry[] => {
    if (!searchQuery || !entries.length) {
        return entries;
    }

    const regex = new RegExp(escapeRegExp(searchQuery), 'i');

    return entries.filter((entry) => {
        const entryTextVal = formatVehicleName(entry.vehicle, vehicleDisplayNameFormat);

        return regex.test(entryTextVal);
    });
};
