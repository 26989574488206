import type { SettingsStoreState } from '~/components/EnsureSettings';
import { isUndefined } from '~/libs/utility';
import type { ResolvedRtdsScheduleChangelogChanges, RtdsScheduleChangelogChanges } from '~/services/ApiClient';

export const getResolvedRtdsScheduleChangelogChanges = (
    settingsStoreState: SettingsStoreState,
    changes?: RtdsScheduleChangelogChanges
): ResolvedRtdsScheduleChangelogChanges => {
    const weekDay = changes?.weekDay;
    const frequency = changes?.frequency;
    const priority = changes?.priority;

    return {
        weekDay: isUndefined(weekDay) ? undefined : settingsStoreState.rtdsWeekDayType.data[weekDay],
        frequency: isUndefined(frequency) ? undefined : settingsStoreState.rtdsScheduleFrequency.data[frequency],
        priority: isUndefined(priority) ? undefined : settingsStoreState.rtdsPriority.data[priority],
        tachoBlocks: changes?.tachographDataBlocks
            ? changes?.tachographDataBlocks.map(
                  (dataBlockId) => settingsStoreState.tachographDataBlock.data[dataBlockId]
              )
            : undefined,
        startDate: changes?.startDate,
        recurrence: changes?.recurrence,
        day: changes?.day,
        requestedDays: changes?.requestedDays,
    };
};
