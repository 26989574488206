import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './constants';

export const RTDSSCHEDULES_LIST_USERPREFERENCES_KEY = 'rtds-schedule-grid-state';

export const defaultRtdsSchedulesListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.SCHEDULEID,
        ColumnName.TYPE,
        ColumnName.ASSET,
        ColumnName.STARTDATE,
        ColumnName.FREQUENCY,
        ColumnName.DESCRIPTION,
        ColumnName.NEXTRUN,
        ColumnName.INSTANCE,
        ColumnName.REQUESTEDTACHODATA,
        ColumnName.DEVICETYPE,
        ColumnName.DEVICEENABLED,
        ColumnName.RESPONSEENABLED,
    ],
    visibleColumns: [
        ColumnName.SCHEDULEID,
        ColumnName.TYPE,
        ColumnName.STARTDATE,
        ColumnName.FREQUENCY,
        ColumnName.DESCRIPTION,
        ColumnName.NEXTRUN,
        ColumnName.INSTANCE,
        ColumnName.REQUESTEDTACHODATA,
        ColumnName.DEVICETYPE,
        ColumnName.DEVICEENABLED,
        ColumnName.RESPONSEENABLED,
        ColumnName.ASSET,
    ],
    columnWidths: [
        { columnName: ColumnName.SCHEDULEID, width: 150 },
        { columnName: ColumnName.TYPE, width: 150 },
        { columnName: ColumnName.STARTDATE, width: 150 },
        { columnName: ColumnName.FREQUENCY, width: 150 },
        { columnName: ColumnName.DESCRIPTION, width: 300 },
        { columnName: ColumnName.NEXTRUN, width: 150 },
        { columnName: ColumnName.INSTANCE, width: 100 },
        { columnName: ColumnName.REQUESTEDTACHODATA, width: 200 },
        { columnName: ColumnName.DEVICETYPE, width: 150 },
        { columnName: ColumnName.DEVICEENABLED, width: 250 },
        { columnName: ColumnName.RESPONSEENABLED, width: 150 },
        { columnName: ColumnName.ASSET, width: 150 },
    ],
    sorting: [{ columnName: ColumnName.ASSET, direction: GridSortingDirection.ASC }],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: false,
    filteringEnabled: false,
    filters: [],
};
