import { actionGenerator } from '~/common';

const MODULE_PREFIX = 'REPORT SETTINGS';

const ACTIONS_TYPE = {
    LIST: `[${MODULE_PREFIX}] LIST`,
    TEMPLATE: `[${MODULE_PREFIX}] TEMPLATE`,
};

const actions = actionGenerator(ACTIONS_TYPE, MODULE_PREFIX);

export { actions, ACTIONS_TYPE };
