import type { Duration } from 'moment';
import moment from 'moment';

import type { FilterDurationValue } from '../../../../models';

const floorDuration = (value: Duration) => {
    return moment.duration(Math.floor(value.asMinutes()), 'minutes');
};

export const createFilterValue = (value: Duration | undefined): FilterDurationValue | undefined => {
    if (!value) {
        return undefined;
    }
    const startDuration = floorDuration(value);
    const min = startDuration.toISOString();
    const max = startDuration.clone().add(1, 'minutes').toISOString();

    return { min, max };
};

export const getInputValue = (value: FilterDurationValue | undefined): Duration | undefined => {
    if (!value) {
        return undefined;
    }

    return floorDuration(moment.duration(value.min));
};
