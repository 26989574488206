import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SendingMessagesDisabledClassKey = 'root';

export const SendingMessagesDisabledStyleRules: StyleRulesCallback<Theme, {}, SendingMessagesDisabledClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            background: theme.palette.warning.light,
            fontStyle: 'italic',
            padding: theme.spacing(1, 2),
        },
    });
