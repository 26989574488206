import { defaultProps } from 'react-recompose';

import { ClassificationsFormatter } from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { Classification, ClassificationType } from '~/services/ApiClient';
import { exportFormatterFactory, formatClassifications, valueTextFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareFactory, stringComparer } from '~/services/Sorting';

import {
    classificationFactory,
    classificationsFilterEquals,
    getClassificationOptions,
} from './classifications.utility';
import type { CreateColumnOptions, GridColumnDefinition } from './models';

export interface CreateClassificationsColumnOptions extends CreateColumnOptions {
    valueFormatterComponent?: React.ComponentType<{ value?: unknown }>;
    classifications?: Classification[];
}

export const createClassificationsColumn = <T>(
    t: SingleTFunction,
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => Classification[] | undefined,
    classificationKey: ClassificationType,
    options?: CreateClassificationsColumnOptions
): GridColumnDefinition<T> => ({
    valueFormatterComponent: defaultProps({ t, classificationKey })(ClassificationsFormatter),
    ...options,
    dataType: 'string',
    name,
    title,
    groupTitle,
    getCellValue,
    exportValueFormatter: exportFormatterFactory((v: Classification[]) =>
        formatClassifications(t, classificationKey, v)
    ),
    compare: compareFactory((v: Classification[]) => formatClassifications(t, classificationKey, v), stringComparer),
    valueTextFormatter: valueTextFormatterFactory((v: Classification[]) =>
        formatClassifications(t, classificationKey, v)
    ),
    groupingCriteria: groupingCriteriaFactory((v: Classification[]) => formatClassifications(t, classificationKey, v)),

    cellFiltering: options?.classifications
        ? {
              options: getClassificationOptions<Classification>(
                  options.classifications,
                  classificationFactory(t, classificationKey)
              ),
              valueEquals: classificationsFilterEquals,
          }
        : undefined,
});
