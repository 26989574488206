import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { ActionTypes as RtdsSchedulesActionTypes } from '~/data/rtdsschedules';
import { ActionTypeKeys as RtdsSchedulesActionTypeKeys } from '~/data/rtdsschedules';
import type { RetrievableData } from '~/reducers';
import type { QueryRtdsSchedulesRequest, RtdsSchedule } from '~/services/ApiClient';

import type { ActionTypes as RtdsSchedulesItemsActionTypes } from './data';
import { ActionTypeKeys as RtdsSchedulesItemsActionTypeKeys } from './data';
import type { RtdsSchedulesEntry } from './models';
import { resolveRtdsSchedulesEntries } from './reducers.resolveRtdsSchedulesEntries';

export interface DynamicRtdsScheduleStoreState {
    rtdsSchedulesEntries: RtdsSchedulesEntry[];
    rtdsSchedules: RetrievableData<RtdsSchedule[]>;
}
export interface RtdsSchedulesStoreState {
    dynamicRtdsSchedules: DynamicRtdsScheduleStoreState;
    latestRequestParams?: QueryRtdsSchedulesRequest;
}

export const defaultStoreState: RtdsSchedulesStoreState = {
    dynamicRtdsSchedules: {
        rtdsSchedulesEntries: [],
        rtdsSchedules: {
            data: [],
            pending: false,
            fulfilled: false,
            rejected: false,
        },
    },
};

type ActionTypes = RtdsSchedulesActionTypes | RtdsSchedulesItemsActionTypes;

export const rtdsSchedulesReducer = (
    state: RtdsSchedulesStoreState = defaultStoreState,
    action: ActionTypes,
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): RtdsSchedulesStoreState => {
    switch (action.type) {
        case RtdsSchedulesItemsActionTypeKeys.RTDSSCHEDULES_CLEAR_DATA:
            return {
                ...defaultStoreState,
            };
        case RtdsSchedulesActionTypeKeys.RTDSSCHEDULES_QUERY_PENDING:
            return {
                ...state,
                latestRequestParams: action.meta,
                dynamicRtdsSchedules: {
                    ...state.dynamicRtdsSchedules,
                    rtdsSchedules: {
                        ...state.dynamicRtdsSchedules.rtdsSchedules,
                        pending: true,
                    },
                },
            };
        case RtdsSchedulesActionTypeKeys.RTDSSCHEDULES_QUERY_REJECTED: {
            const isLatestResponse = state.latestRequestParams === action.meta;
            if (!isLatestResponse) {
                return state;
            }
            return {
                ...state,
                dynamicRtdsSchedules: {
                    ...state.dynamicRtdsSchedules,
                    rtdsSchedulesEntries: [],
                    rtdsSchedules: {
                        data: [],
                        pending: false,
                        fulfilled: false,
                        rejected: true,
                    },
                },
            };
        }
        case RtdsSchedulesActionTypeKeys.RTDSSCHEDULES_QUERY_FULFILLED: {
            const isLatestResponse = state.latestRequestParams === action.meta;
            if (!isLatestResponse) {
                return state;
            }
            return {
                ...state,
                dynamicRtdsSchedules: {
                    ...state.dynamicRtdsSchedules,
                    rtdsSchedulesEntries: resolveRtdsSchedulesEntries(
                        action.payload.items,
                        staticDataStoreState,
                        settingsStoreState
                    ),
                    rtdsSchedules: {
                        data: action.payload.items,
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
            };
        }

        default:
            return state;
    }
};
