import makeStyles from '@mui/styles/makeStyles';

export type WidgetDialogClassKey =
    | 'dialogContent'
    | 'customContentStyle'
    | 'dialog'
    | 'title'
    | 'header'
    | 'headerActions'
    | 'dialogActions'
    | 'leftFooterAdornment'
    | 'headerAction'
    | 'footer';

const widgetDialogDimensions = {
    width: 1008,
    maxHeight: 700,
};

const useStyles = makeStyles((theme) => ({
    customContentStyle: { ...widgetDialogDimensions },
    dialog: { display: 'contents' },
    dialogActions: { padding: 0 },
    dialogContent: { padding: 0, display: 'flex', flexDirection: 'column' },
    footer: { display: 'flex', margin: theme.spacing(2, 3), justifyContent: 'flex-end' },
    header: {
        position: 'relative',
        padding: `6px 12px 6px ${theme.spacing(3)}`,
        display: 'flex',
        justifyContent: 'space-between',
        height: 56,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    headerAction: { margin: '0 4px' },
    headerActions: { display: 'flex', alignSelf: 'center' },
    leftFooterAdornment: { flexGrow: 1, alignSelf: 'center', color: theme.palette.text.secondary },
    title: { flexGrow: 1, alignSelf: 'center' },
}));

export { useStyles, widgetDialogDimensions };
