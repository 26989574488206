import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { ButtonProgress } from '~/components/ButtonProgress';
import type { InjectedFeatureFlagsProps } from '~/components/FeatureFlags';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { Severity, ValidationBox } from '~/components/ValidationBox';
import type { Credentials } from '~/data/authentication';
import { debounce } from '~/libs/utility';
import { AuthenticationRequest, createApiModel } from '~/services/ApiClient';

import { minimumScreenResolutionReference, minimunScreenResolution } from './constants';
import type { LoginFormClassKey } from './styles';

export interface UserLoginFormStateProps {
    requestFailed: boolean;
    authenticationError: boolean;
    passwordExpired: boolean;
    authenticating: boolean;
    customer?: string;
    username?: string;
}

export interface UserLoginFormDispatchProps {
    authenticate: (credentials: Credentials, rememberMe: boolean) => void;
}

export interface UserLoginFormProps {
    defaultValidationMessage?: string;
}

export interface UserLoginFormInnerProps
    extends UserLoginFormStateProps,
        UserLoginFormDispatchProps,
        UserLoginFormProps,
        InjectedTranslationProps,
        InjectedFeatureFlagsProps,
        WithStyles<LoginFormClassKey> {}

export interface UserLoginFormState {
    rememberMe: boolean;
    username: string;
    password: string;
    customer: string;
    isScreenBelowMinimumResolution?: boolean;
    hasLoginBeenSubmitted: boolean;
}

export class UserLoginFormComponent extends React.Component<UserLoginFormInnerProps, UserLoginFormState> {
    private handleResizeDebounced: () => void = debounce(() => {
        this.handleResize();
    }, 500);

    constructor(props: UserLoginFormInnerProps) {
        super(props);

        this.state = {
            username: this.props.username || '',
            customer: this.props.customer || '',
            password: '',
            rememberMe: !!this.props.username,
            hasLoginBeenSubmitted: false,
        };

        this.handleResize = this.handleResize.bind(this);
    }

    public componentDidMount(): void {
        window.addEventListener('resize', this.handleResizeDebounced);
        this.handleResize();
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResizeDebounced);
    }

    public render(): JSX.Element {
        const {
            t,
            classes,
            authenticationError,
            passwordExpired,
            authenticating,
            requestFailed,
            defaultValidationMessage,
        } = this.props;

        const rememberMeControl = (
            <Checkbox
                data-id="remember-me"
                checked={this.state.rememberMe}
                onChange={this.handleCheckboxChange('rememberMe')}
            />
        );

        const hasError = requestFailed || passwordExpired || authenticationError || defaultValidationMessage;
        const errorValidationText = requestFailed
            ? t('not-able-to-process-request')
            : passwordExpired
              ? t('password-expired')
              : authenticationError
                ? t('invalid-credentials')
                : defaultValidationMessage;
        const interpolationParams = {
            minimumScreenResolutionReference: `${minimumScreenResolutionReference.width}x${minimumScreenResolutionReference.height}`,
            conventionalName: minimumScreenResolutionReference.conventionalName,
        };
        const warningValidationText =
            this.state.isScreenBelowMinimumResolution && !this.state.hasLoginBeenSubmitted
                ? t('screen-size-below-minimum-resolution-message', interpolationParams)
                : undefined;
        const validationMessage = hasError ? errorValidationText : warningValidationText;
        const validationMessageSeverity = hasError ? Severity.Error : Severity.Warning;

        return (
            <form
                method="post"
                data-id="user-login-form"
                className={classes.form}
                onSubmit={this.handleSubmit}
                autoComplete="on"
            >
                {validationMessage && (
                    <ValidationBox message={validationMessage} severity={validationMessageSeverity} />
                )}
                <div className={classes.fields}>
                    <TextField
                        variant="standard"
                        label={t('customer')}
                        data-id="customer"
                        autoFocus={!this.state.rememberMe}
                        required
                        autoComplete="organization"
                        fullWidth
                        margin="normal"
                        value={this.state.customer}
                        onChange={this.handleChange('customer')}
                    />
                    <TextField
                        variant="standard"
                        label={t('username')}
                        data-id="username"
                        required
                        autoComplete="username"
                        fullWidth
                        margin="normal"
                        value={this.state.username}
                        onChange={this.handleChange('username')}
                    />
                    <TextField
                        variant="standard"
                        label={t('password')}
                        data-id="password"
                        autoFocus={this.state.rememberMe}
                        required
                        autoComplete="current-password"
                        fullWidth
                        margin="normal"
                        value={this.state.password}
                        onChange={this.handleChange('password')}
                        type="password"
                    />
                    <FormControl variant="standard" fullWidth margin="normal">
                        <FormControlLabel control={rememberMeControl} label={t('remember-me')} />
                    </FormControl>
                    <FormControl variant="standard" fullWidth margin="normal">
                        <Button
                            color="secondary"
                            data-id="sign-in"
                            type="submit"
                            variant="contained"
                            disabled={!this.canLogin || authenticating}
                        >
                            {t('sign-in')}
                        </Button>
                        <ButtonProgress showProgress={authenticating} />
                    </FormControl>

                    <Link to="/reset-password" data-id="reset-password" className={classes.resetPassword}>
                        {t('reset-password-link-title')}
                    </Link>
                </div>
            </form>
        );
    }

    private get canLogin(): boolean {
        return !!(this.state.username && this.state.customer && this.state.password);
    }

    private handleChange(key: keyof UserLoginFormState): (e: React.ChangeEvent<HTMLInputElement>) => void {
        return (e) => {
            const obj = {};
            obj[key] = e.target.value;
            this.setState(obj);
        };
    }

    private handleCheckboxChange(key: keyof UserLoginFormState): (e: React.ChangeEvent<HTMLInputElement>) => void {
        return (e) => {
            const obj = {};
            obj[key] = e.target.checked;
            this.setState(obj);
        };
    }

    private handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const credentials = createApiModel(AuthenticationRequest, {
            username: this.state.username,
            customer: this.state.customer,
            password: this.state.password,
        });
        this.props.authenticate(credentials, this.state.rememberMe);
        this.setState({ hasLoginBeenSubmitted: true });
    };

    private handleResize(): void {
        const isScreenBelowMinimumResolution =
            window.innerWidth < minimunScreenResolution.width || window.innerHeight < minimunScreenResolution.height;
        this.setState({ isScreenBelowMinimumResolution });
    }
}
