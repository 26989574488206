import { createAction } from 'redux-actions';

import type { DriverEventsResponse } from '~/services/ApiClient';
import { ApiClient, AssetType, retryableRequest } from '~/services/ApiClient';

import { ActionTypePrefixes } from './actionTypes';

export const retrieveDriverEventsForVehicleDriverExecutor = (
    vehicleId: number,
    driverId: number,
    startDate: Date,
    stopDate: Date
): Promise<DriverEventsResponse> => {
    return retryableRequest(() => ApiClient.getDriverEventsFromVehicleDriver(vehicleId, driverId, startDate, stopDate));
};

export const getDriverEventsFromVehicleDriverAction = createAction<
    Promise<DriverEventsResponse>,
    number,
    number,
    Date,
    Date
>(ActionTypePrefixes.DRIVERACTIVITIESREPORT_DRIVEREVENTS, retrieveDriverEventsForVehicleDriverExecutor);

export const retrieveDriverEventsExecutor = (
    driverId: number,
    startDate: Date,
    stopDate: Date
): Promise<DriverEventsResponse> => {
    return retryableRequest(() => ApiClient.getDriverEvents(driverId, AssetType.Driver, startDate, stopDate));
};

export const getDriverEventsAction = createAction<Promise<DriverEventsResponse>, number, Date, Date>(
    ActionTypePrefixes.DRIVERACTIVITIESREPORT_DRIVEREVENTS,
    retrieveDriverEventsExecutor
);
