import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LabeledIndicatorGroupClassKey =
    | 'root'
    | 'badge'
    | 'primaryText'
    | 'secondaryText'
    | 'title'
    | 'splitRow'
    | 'flex'
    | 'options'
    | 'option';

export const LabeledIndicatorGroupStyleRules: StyleRulesCallback<Theme, {}, LabeledIndicatorGroupClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0.5),
        },
        badge: {
            display: 'inline-block',
            marginRight: theme.spacing(0.5),
            '& > *': {
                width: 20,
                height: 20,
            },
            '& > * >*': {
                width: 20,
                height: 20,
            },
            '& > * >* >*': {
                width: 14,
                height: 14,
            },
        },
        title: {
            fontSize: 12,
            margin: 'auto',
            fontWeight: theme.typography.fontWeightMedium,
        },
        primaryText: {
            width: '100%',
            textAlign: 'right',
            fontSize: 12,
        },
        secondaryText: {
            width: '100%',
            textAlign: 'right',
            fontSize: 10,
        },
        splitRow: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: theme.spacing(),
            alignItems: 'flex-end',
        },
        flex: {
            display: 'flex',
        },
        options: {
            marginRight: theme.spacing(),
            marginBottom: theme.spacing(0.3),
            fontSize: 10,
            fontWeight: theme.typography.fontWeightMedium,
            '& > *:not(:last-child)': {
                borderRight: 'none',
            },
            '& > *:first-child': {
                borderTopLeftRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
            '& > *:last-child': {
                borderTopRightRadius: theme.shape.borderRadius,
                borderBottomRightRadius: theme.shape.borderRadius,
            },
        },
        option: {
            padding: theme.spacing(0.3),
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    });
