import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';

import type { NamedVehicleReference, ResolvedVehicle, VehicleCategory, VehicleType } from '../ApiClient';

export interface NamedVehicleReferenceResolverProps {
    namedVehicleReference: NamedVehicleReference;
    categories: NumericDictionary<VehicleCategory>;
    vehicleTypes: NumericDictionary<VehicleType>;
}

export const namedVehicleReferenceResolver = (props: NamedVehicleReferenceResolverProps): ResolvedVehicle => {
    const { namedVehicleReference, categories, vehicleTypes } = props;
    return {
        ...namedVehicleReference,
        category: categories[namedVehicleReference?.category ?? 0],
        vehicleType: !isUndefined(namedVehicleReference.type) ? vehicleTypes[namedVehicleReference.type] : undefined,
    };
};
