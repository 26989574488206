import { CircularProgress, Typography } from '@mui/material';
import type { ReactElement } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { SceneDetailsPaneFactory, SceneDetailsSubpageHeader } from '~/components/SceneDetailsPane';
import { isEmpty } from '~/libs/utility';
import { reportError } from '~/reporting';

import type { ChangelogEntry, ChangelogSubpageProps } from './models';
import { CHANGELOGSUBPAGE_PREFERENCES_KEY } from './preferences';
import { getSectionsMemoized } from './sections';
import { useStyles } from './styles';

interface ChangelogSubpageComponentState<T> {
    pending: boolean;
    rejected: boolean;
    changelogs: ChangelogEntry<T>[];
}

const ChangelogSubpage = <T,>({
    changelogSubpageTitle,
    changelogs,
    attributeDefinitions,
    entityType,
}: ChangelogSubpageProps<T>): ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();
    const DetailsPaneComponent = useMemo(
        () =>
            SceneDetailsPaneFactory<undefined, SceneDetailsPaneUserPreferences<''>, ''>(
                CHANGELOGSUBPAGE_PREFERENCES_KEY
            ),
        []
    );
    const [status, setStatus] = useState<ChangelogSubpageComponentState<T>>({
        pending: true,
        rejected: false,
        changelogs: [],
    });

    const getSections = useCallback(
        () => getSectionsMemoized({ entityType, changelogs: status.changelogs, attributeDefinitions }),
        [attributeDefinitions, status.changelogs, entityType]
    );

    useEffect(() => {
        Promise.resolve(changelogs)
            .then(
                (cl) => setStatus({ pending: false, rejected: false, changelogs: cl }),
                () => setStatus({ pending: false, rejected: true, changelogs: [] })
            )
            .catch(reportError);
    }, [changelogs]);

    const header = <SceneDetailsSubpageHeader title={changelogSubpageTitle} />;

    if (status.pending) {
        return (
            <>
                {header}
                <div className={classes.loaderContainer} data-id="loading-data">
                    <CircularProgress size={32} />
                </div>
            </>
        );
    }

    if (status.rejected) {
        return (
            <>
                {header}
                <Typography className={classes.messageText} variant="caption" data-id="loading-failed">
                    {t('failed-to-retrieve-data')}
                </Typography>
            </>
        );
    }

    if (isEmpty(status.changelogs)) {
        return (
            <div data-id="changelog-subpage">
                {header}
                <Typography className={classes.messageText} variant="caption" data-id="no-data">
                    {t('changelog-subpage-no-data')}
                </Typography>
            </div>
        );
    }

    return (
        <DetailsPaneComponent
            HeaderReactNode={header}
            entry={undefined}
            dataId="changelog-subpage"
            getSections={getSections}
        />
    );
};

ChangelogSubpage.displayName = 'ChangelogSubpage';
export { ChangelogSubpage };
