import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

const formatLanguageInternal = (t: SingleTFunction, code: string) => {
    return t(`language-${code}`);
};

const formatLanguageMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((code: string) => formatLanguageInternal(t, code))
);

export const formatLanguage = (t: SingleTFunction, code: string): string => formatLanguageMemoized(t)(code);
