import * as React from 'react';
import { wrapDisplayName } from 'react-recompose';
import type { Size } from 'react-virtualized';
import type { Subtract } from 'utility-types';

import { memoizeOne } from '~/services/Memoize';

import { AutoSizer as VirtualizedAutoSizer } from './components/AutoSizer';

export interface AutoSizerInjectedProps {
    size: Size;
}

export const withAutoSizer = <WrappedProps extends AutoSizerInjectedProps>(
    WrappedComponent: React.ComponentType<WrappedProps>
): React.ComponentType<Subtract<WrappedProps, AutoSizerInjectedProps>> => {
    type HocProps = Subtract<WrappedProps, AutoSizerInjectedProps>;

    return class AutoSizerComponent extends React.Component<HocProps> {
        public static displayName = wrapDisplayName(WrappedComponent, 'AutoSizer');

        private sizeMemoized = memoizeOne((height: number, width: number) => ({
            height,
            width,
        }));

        public render() {
            const adjustableComponent = ({ height, width }: Size): React.ReactNode => {
                if (height === 0 || width === 0) {
                    return null;
                }

                const size = this.sizeMemoized(height, width);

                return <WrappedComponent {...(this.props as WrappedProps)} size={size} />;
            };
            return <VirtualizedAutoSizer>{adjustableComponent}</VirtualizedAutoSizer>;
        }
    };
};
