import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ClassificationsInputInnerProps, ClassificationsInputProps } from './component';
import { ClassificationsInputComponent } from './component';

export type { ClassificationsInputProps } from './component';

export const ClassificationsInput = compose<ClassificationsInputInnerProps, ClassificationsInputProps>(
    setDisplayName('ClassificationsInput'),
    withTranslation()
)(ClassificationsInputComponent);
