import { createAction } from 'redux-actions';

import { AuthenticationApiClient, retryableRequest } from '~/services/ApiClient';

import type { CustomersPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

const retrieveCustomersExecutor = (): Promise<CustomersPayload> => {
    return retryableRequest(() => AuthenticationApiClient.getCustomers());
};

export const retrieveCustomersAction = createAction(ActionTypePrefixes.CUSTOMERS, retrieveCustomersExecutor);
