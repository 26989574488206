import type { ConversationUpdate, Message } from '~/services/ApiClient';

/**
 * Returns the updated messages that are actual
 * and were not deleted in a newer conversation update.
 */
export const filterUpdatedMessages = (
    toBeFiltered: ConversationUpdate,
    forComparison: ConversationUpdate
): Message[] => {
    return toBeFiltered.updatedMessages.filter((message) => {
        const messageForComparison = forComparison.updatedMessages.find(({ id }) => id === message.id);

        return (
            !(forComparison.revision > toBeFiltered.revision && forComparison.deletedMessages.includes(message.id)) &&
            !(messageForComparison && messageForComparison.revision > message.revision)
        );
    });
};
