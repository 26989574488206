import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { AttachmentComponentInnerProps, AttachmentComponentOuterProps } from './component';
import { AttachmentComponent } from './component';
import { AttachmentComponentStyleRules } from './styles';

export const Attachment = compose<AttachmentComponentInnerProps, AttachmentComponentOuterProps>(
    setDisplayName('AttachmentComponent'),
    withTranslation(),
    withStyles(AttachmentComponentStyleRules)
)(AttachmentComponent);
