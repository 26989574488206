import type { HistoryDriverEntry } from './models';

export const HistoryDriverEquals = (rowA?: HistoryDriverEntry, rowB?: HistoryDriverEntry): boolean => {
    if (!rowA || !rowB) {
        return false;
    }

    const driverEquals = rowA.driverEvent.eventId === rowB.driverEvent.eventId;
    return driverEquals;
};
