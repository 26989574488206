import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    messageText: {
        display: 'flex',
        justifyContent: 'center',
        fontStyle: 'italic',
        padding: theme.spacing(2),
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

export type ChangelogSubpageClassKey = keyof ReturnType<typeof useStyles>;
