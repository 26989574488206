import type { ActionTypes } from './data/actionTypes';
import { ActionTypeKeys } from './data/actionTypes';
import { Feature } from './models';

export interface FeatureFlagsStoreState {
    enabled: boolean;
    enabledFeatures: Feature[];
}

export const defaultStoreState: FeatureFlagsStoreState = {
    enabled: false,
    enabledFeatures: [],
};

export const featureFlagsReducer = (
    state: FeatureFlagsStoreState = defaultStoreState,
    action: ActionTypes
): FeatureFlagsStoreState => {
    switch (action.type) {
        case ActionTypeKeys.FEATURES_TOGGLE: {
            const enabledFeatures = state.enabledFeatures.includes(action.meta)
                ? state.enabledFeatures.filter((f) => f !== action.meta)
                : [...state.enabledFeatures, action.meta];

            return {
                ...state,
                enabledFeatures,
            };
        }

        case ActionTypeKeys.FEATURES_ENABLEALL: {
            return {
                ...state,
                enabledFeatures: Object.values(Feature),
            };
        }

        case ActionTypeKeys.FEATURES_DISABLEALL: {
            return {
                ...state,
                enabledFeatures: [],
            };
        }

        case ActionTypeKeys.FEATURES_RESETTODEFAULT: {
            return {
                enabled: state.enabled,
                enabledFeatures: defaultStoreState.enabledFeatures,
            };
        }

        case ActionTypeKeys.FEATURES_SETFEATUREFLAGS: {
            if (action.payload.enabled) {
                // Leave configured features untouched if already enabled
                if (state.enabled) {
                    return state;
                }

                // Enable all features when enabling
                return {
                    enabled: true,
                    enabledFeatures: Object.values(Feature),
                };
            }

            // Reset to default features, to prevent any stale configuration
            return {
                enabled: false,
                enabledFeatures: defaultStoreState.enabledFeatures,
            };
        }

        default:
            return state;
    }
};
