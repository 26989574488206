import type { PopoverProps } from '@mui/material';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import type { FC, MouseEvent, ReactNode } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { Message } from '~/services/ApiClient';
import {
    IncomingMessage,
    IncomingMessageStatus,
    OptimisticOutgoingConversationMessage,
    OptimisticOutgoingConversationMessageStatus,
    OutgoingMessage,
    OutgoingMessageStatus,
} from '~/services/ApiClient';

export interface MenuProps {
    conversationMessage: Message;
    anchorElement: PopoverProps['anchorEl'];
    open: boolean;
    canUpdateReadStatus: boolean;
    updatingReadStatus: boolean;
    canDeleteMessage: boolean;
    onInfo: () => void;
    onClose: () => void;
    onClickMarkAsRead: (e: MouseEvent<HTMLLIElement>) => void;
    onRetrySendMessage: () => void;
    onDiscardMessage: () => void;
    onDeleteMessage: () => void;
    onUndeleteMessage: () => void;
    onCopyMessageToDraft?: () => void;
}

export interface MenuInnerProps extends MenuProps, InjectedTranslationProps {}

export const MenuComponent: FC<MenuInnerProps> = (props) => {
    const {
        t,
        onInfo,
        onClose,
        onClickMarkAsRead,
        conversationMessage,
        open,
        anchorElement,
        canUpdateReadStatus,
        canDeleteMessage,
        updatingReadStatus,
        onRetrySendMessage,
        onDiscardMessage,
        onDeleteMessage,
        onUndeleteMessage,
        onCopyMessageToDraft,
    } = props;

    const getMenuItems = () => {
        const items: Array<ReactNode> = [
            <MenuItem key="info" onClick={onInfo} data-id="message-menu:info">
                {t('message-info')}
            </MenuItem>,
        ];

        if (
            conversationMessage instanceof OutgoingMessage ||
            conversationMessage instanceof OptimisticOutgoingConversationMessage
        ) {
            items.push(
                onCopyMessageToDraft && (
                    <MenuItem key="copy-to-draft" onClick={onCopyMessageToDraft} data-id="message-menu:copy-to-draft">
                        {t('copy-to-draft')}
                    </MenuItem>
                )
            );
        }

        if (
            conversationMessage instanceof OptimisticOutgoingConversationMessage &&
            (conversationMessage.status === OptimisticOutgoingConversationMessageStatus.GeneralFailure ||
                conversationMessage.status === OptimisticOutgoingConversationMessageStatus.ValidationFailure)
        ) {
            items.push(
                <MenuItem
                    key="retry-send-message"
                    onClick={onRetrySendMessage}
                    data-id="message-menu:retry-send-message"
                >
                    {t('retry')}
                </MenuItem>
            );
            items.push(
                <MenuItem key="discard-message" onClick={onDiscardMessage} data-id="message-menu:discard-message">
                    {t('discard')}
                </MenuItem>
            );
        }

        if (conversationMessage instanceof IncomingMessage) {
            const markAsReadTranslationKey =
                conversationMessage.status === IncomingMessageStatus.Read ? 'mark-as-unread' : 'mark-as-read';

            const markAsReadTooltipTranslationKey = !canUpdateReadStatus
                ? 'change-read-status-not-authorized'
                : updatingReadStatus
                  ? 'changing-read-status'
                  : '';

            const markAsReadDisabled = !canUpdateReadStatus || updatingReadStatus;

            items.push(
                <Tooltip key={markAsReadTranslationKey} title={t(markAsReadTooltipTranslationKey)}>
                    <div>
                        {/* needs wrapping in a div, otherwise won't show tooltip when disabled */}
                        <MenuItem
                            onClick={onClickMarkAsRead}
                            data-id={`message-menu:${markAsReadTranslationKey}`}
                            /**
                             * temp adding aria-disabled to handle disabled status in ui tests,
                             * it is a default prop material-ui v4 and should be removed once we upgrade to v4
                             */
                            aria-disabled={markAsReadDisabled}
                            disabled={markAsReadDisabled}
                        >
                            {t(markAsReadTranslationKey)}
                        </MenuItem>
                    </div>
                </Tooltip>
            );
        }

        if (
            canDeleteMessage &&
            (conversationMessage instanceof IncomingMessage || conversationMessage instanceof OutgoingMessage)
        ) {
            if (conversationMessage.isDeleted) {
                items.push(
                    <MenuItem
                        key="undelete-message"
                        onClick={onUndeleteMessage}
                        data-id="message-menu:undelete-message"
                    >
                        {t('undelete')}
                    </MenuItem>
                );
            } else if (
                !conversationMessage.isDeleted &&
                conversationMessage.status !== OutgoingMessageStatus.Scheduled
            ) {
                items.push(
                    <MenuItem key="delete-message" onClick={onDeleteMessage} data-id="message-menu:delete-message">
                        {t('delete')}
                    </MenuItem>
                );
            } else if (
                !conversationMessage.isDeleted &&
                conversationMessage.status === OutgoingMessageStatus.Scheduled
            ) {
                items.push(
                    <MenuItem
                        key="cancel-message"
                        onClick={onDeleteMessage}
                        data-id="message-menu:cancel-scheduled-message"
                    >
                        {t('cancel')}
                    </MenuItem>
                );
            }
        }

        return items;
    };

    return (
        <Menu
            id="message-menu"
            onClose={onClose}
            open={open}
            anchorEl={anchorElement}
            disableAutoFocusItem
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            {getMenuItems()}
        </Menu>
    );
};
