import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withAutoSizer } from '~/components/AutoSizer';
import { withTranslation } from '~/components/LanguageSelector';
import { MapProfile, requiredMapUserPreferences, withMapSharedProps } from '~/components/MapSharedProps';
import { ensureUserPreferences } from '~/components/UserPreferences';
import { historyMapStateSelector } from '~/data/monitoring';

import { historyStateSelector } from '../../../DetailsPane/components/HistorySubpage/selectors';

import { HistoryMapComponent } from './component';
import type { HistoryMapInnerProps, HistoryMapOutterProps } from './models';
import { mapDispatchProps, mapStateToProps } from './redux';
import { HistoryMapViewStyleRules } from './styles';

export { historyMapReducer } from './reducers';

export const HistoryMap = compose<HistoryMapInnerProps, HistoryMapOutterProps>(
    setDisplayName('HistoryMap'),
    withAutoSizer,
    withTranslation(),
    ensureUserPreferences([requiredMapUserPreferences]),
    withMapSharedProps(MapProfile.PTV_SILICA),
    withStyles(HistoryMapViewStyleRules),
    connect(createSelector(historyStateSelector, historyMapStateSelector, mapStateToProps), mapDispatchProps)
)(HistoryMapComponent);
