import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './constants';

export const HISTORY_LIST_VEHICLE_USERPREFERENCES_KEY = 'history-grid-vehicle-state';

export const defaultVehicleListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.DATETIME,
        ColumnName.VEHICLE,
        ColumnName.MSISDN,
        ColumnName.DEVICETYPE,
        ColumnName.IGNITIONSTATUS,
        ColumnName.SPEED,
        ColumnName.HEADING,
        ColumnName.ODOMETER,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.CITY,
        ColumnName.ADDRESS,
        ColumnName.POSTALCODE,
        ColumnName.COUNTRYCODE,
        ColumnName.ALTITUDE,
        ColumnName.TOTALFUELUSAGE,
        ColumnName.FUELLEVEL,
        ColumnName.NEARESTCITY,
    ],
    visibleColumns: [
        ColumnName.DATETIME,
        ColumnName.VEHICLE,
        ColumnName.IGNITIONSTATUS,
        ColumnName.SPEED,
        ColumnName.HEADING,
        ColumnName.ODOMETER,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.CITY,
        ColumnName.ADDRESS,
        ColumnName.POSTALCODE,
        ColumnName.COUNTRYCODE,
        ColumnName.ALTITUDE,
        ColumnName.TOTALFUELUSAGE,
        ColumnName.FUELLEVEL,
        ColumnName.NEARESTCITY,
    ],
    columnWidths: [
        { columnName: ColumnName.DATETIME, width: 125 },
        { columnName: ColumnName.VEHICLE, width: 200 },
        { columnName: ColumnName.IGNITIONSTATUS, width: 90 },
        { columnName: ColumnName.SPEED, width: 90 },
        { columnName: ColumnName.HEADING, width: 90 },
        { columnName: ColumnName.ODOMETER, width: 100 },
        { columnName: ColumnName.LATITUDE, width: 100 },
        { columnName: ColumnName.LONGITUDE, width: 100 },
        { columnName: ColumnName.CITY, width: 175 },
        { columnName: ColumnName.ADDRESS, width: 200 },
        { columnName: ColumnName.POSTALCODE, width: 100 },
        { columnName: ColumnName.COUNTRYCODE, width: 90 },
        { columnName: ColumnName.ALTITUDE, width: 90 },
        { columnName: ColumnName.TOTALFUELUSAGE, width: 90 },
        { columnName: ColumnName.FUELLEVEL, width: 90 },
        { columnName: ColumnName.NEARESTCITY, width: 90 },
        { columnName: ColumnName.DEVICETYPE, width: 125 },
        { columnName: ColumnName.MSISDN, width: 150 },
    ],
    sorting: [{ columnName: ColumnName.DATETIME, direction: GridSortingDirection.DESC }],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: true,
    filters: [],
    filteringEnabled: true,
};
