import { styled } from '@mui/material/styles';
import type { CSSProperties } from 'react';

type ContainerProps = {
    width?: CSSProperties['width'];
};

const Container = styled('div')<ContainerProps>(({ width }) => ({
    width: typeof width === 'number' ? `${width}px` : width ?? '300px',
}));

export { Container };
