import * as React from 'react';

import type { Classification } from '~/services/ApiClient';

import { IconFormatter } from './components/Icon';

export interface PrimaryDoorStatusFormatterProps {
    value?: Classification[];
}

export interface PrimaryDoorStatusFormatterInnerProps extends PrimaryDoorStatusFormatterProps {}

const PrimaryDoorStatusFormatter: React.FunctionComponent<PrimaryDoorStatusFormatterInnerProps> = ({ value }) => {
    return value ? <IconFormatter value={value.map((x) => x.key)} /> : null;
};
PrimaryDoorStatusFormatter.displayName = 'PrimaryDoorStatusFormatter';

export { PrimaryDoorStatusFormatter };
