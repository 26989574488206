import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SensitiveDataClassKeys = 'positioning' | 'visibilityIcon';

export const SensitiveDataStyleRules: StyleRulesCallback<Theme, {}, SensitiveDataClassKeys> = (theme: Theme) =>
    createStyles({
        positioning: {
            display: 'flex',
            alignItems: 'center',
        },
        visibilityIcon: {
            marginLeft: theme.spacing(0.5),
            cursor: 'pointer',
        },
    });
