import type { SectionDefinition } from '~/components/Sections';
import { memoizeOne } from '~/services/Memoize';

import { SectionName } from '../../consts';
import type { GetSectionProps } from '../../models';

import { LocationSection } from './Location';
import { MiniMapSection } from './Minimap';
import { StaffSection } from './Staff';
import { VehicleSection } from './Vehicle';

export const getSections = (props: GetSectionProps): SectionDefinition[] => {
    const { changeMiniMapZoomLevel, collapsedSections, toggleCollapsed, driverStatus, zoomLevel } = props;

    return [
        {
            name: SectionName.DRIVERSTATUS_MINIMAP,
            content: (dragHandleElement: JSX.Element) => (
                <MiniMapSection
                    driverStatus={driverStatus}
                    toggleCollapsed={toggleCollapsed(SectionName.DRIVERSTATUS_MINIMAP)}
                    zoomLevel={zoomLevel}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.DRIVERSTATUS_MINIMAP)}
                    changeZoomLevel={changeMiniMapZoomLevel}
                    lastLocation={driverStatus.location?.position}
                />
            ),
        },
        {
            name: SectionName.DRIVERSTATUS_LOCATION,
            content: (dragHandleElement: JSX.Element) => (
                <LocationSection
                    driverStatus={driverStatus}
                    toggleCollapsed={toggleCollapsed(SectionName.DRIVERSTATUS_LOCATION)}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.DRIVERSTATUS_LOCATION)}
                />
            ),
        },
        {
            name: SectionName.DRIVERSTATUS_STAFF,
            content: (dragHandleElement: JSX.Element) => (
                <StaffSection
                    driverStatus={driverStatus}
                    toggleCollapsed={toggleCollapsed(SectionName.DRIVERSTATUS_STAFF)}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.DRIVERSTATUS_STAFF)}
                />
            ),
        },
        {
            name: SectionName.DRIVERSTATUS_VEHICLE,
            content: (dragHandleElement: JSX.Element) => (
                <VehicleSection
                    driverStatus={driverStatus}
                    toggleCollapsed={toggleCollapsed(SectionName.DRIVERSTATUS_VEHICLE)}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.DRIVERSTATUS_VEHICLE)}
                />
            ),
        },
    ];
};

export const getSectionsMemoized = memoizeOne(getSections);
