import { Typography } from '@mui/material';
import * as React from 'react';

import { DateFormatter } from '~/components/Formatters';

import { useStyles } from './styles';

export interface ChangelogDateEntityProps {
    value: Date;
    groupedPosition: number;
}

export interface ChangelogDateEntityInnerProps extends ChangelogDateEntityProps {}

const ChangelogDateEntity: React.FC<ChangelogDateEntityInnerProps> = ({ value, groupedPosition }) => {
    const classes = useStyles();
    return (
        <Typography className={classes.date} variant="subtitle2" data-id={`date:${groupedPosition}`}>
            <DateFormatter value={value} format="PPPP" />
        </Typography>
    );
};
ChangelogDateEntity.displayName = 'ChangelogDateEntity';

export { ChangelogDateEntity };
