import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import type { FC } from 'react';

import type { CollapsibleContentProps } from './models';
import { useStyles } from './styles';

const CollapsibleContent: FC<CollapsibleContentProps> = (props) => {
    const { children, header, isCollapsed, toggleCollapsed, dataId, classes } = props;

    const ownClasses = useStyles();
    const overwrittenClasses = { ...ownClasses, classes };
    const ToggleIcon = isCollapsed ? ArrowRight : ArrowDropDown;

    return (
        <div data-id={dataId}>
            <div className={overwrittenClasses.header} onClick={toggleCollapsed} data-id="header">
                <ToggleIcon className={overwrittenClasses.toggleIcon} />
                {header}
            </div>
            {!isCollapsed && (
                <div className={overwrittenClasses.content} data-id="content">
                    {children}
                </div>
            )}
        </div>
    );
};

CollapsibleContent.displayName = 'CollapsibleContent';
export default CollapsibleContent;
