import { compose, setDisplayName, withProps } from 'react-recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import type { Omit } from 'utility-types';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { withTranslation } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps, SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory, SceneListViewHeaderFactory } from '~/components/SceneList';
import type { MonitoringTrailerEntry } from '~/data/monitoring';
import type { NumericDictionary } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import type { Classification } from '~/services/ApiClient/generated';

import { monitoringTrailerEntryEquals } from '../../../../utils';

import { filterMonitoringDataSourceMemoized } from './filter';
import { getColumnsMemoized, getRowId } from './grid';
import { MONITORING_LIST_TRAILERS_USERPREFERENCES_KEY, defaultTrailersListUserPreferences } from './preferences';

export * from './preferences';

export type TrailerListViewProps = Omit<SceneListViewProps<MonitoringTrailerEntry>, 'columns' | 'gridDataId'>;

const TrailerListViewComponent = SceneListViewFactory<MonitoringTrailerEntry>(
    MONITORING_LIST_TRAILERS_USERPREFERENCES_KEY,
    defaultTrailersListUserPreferences,
    getRowId,
    monitoringTrailerEntryEquals,
    filterMonitoringDataSourceMemoized
);

export type TrailerListViewHeaderProps = Omit<
    SceneListViewHeaderProps<MonitoringTrailerEntry>,
    'columns' | 'excelSheetTitle'
>;

const TrailerListViewHeaderComponent = SceneListViewHeaderFactory<MonitoringTrailerEntry>(
    MONITORING_LIST_TRAILERS_USERPREFERENCES_KEY,
    defaultTrailersListUserPreferences,
    filterMonitoringDataSourceMemoized,
    'monitoring'
);

interface ReduxProps {
    trailerEventTypes: NumericDictionary<Classification>;

    hookedStatuses: NumericDictionary<Classification>;

    doorStatuses: NumericDictionary<Classification>;

    batteryStatuses: NumericDictionary<Classification>;

    reeferStatuses: NumericDictionary<Classification>;

    compartmentStatuses: NumericDictionary<Classification>;
}

const reduxSelector = createStructuredSelector<StoreState, ReduxProps>({
    trailerEventTypes: settingDataSelector(SettingsKey.TRAILER_EVENT_TYPE),

    hookedStatuses: settingDataSelector(SettingsKey.HOOKED_STATUS),

    doorStatuses: settingDataSelector(SettingsKey.DOOR_STATUS),

    batteryStatuses: settingDataSelector(SettingsKey.TRAILER_BATTERY_STATUS),

    reeferStatuses: settingDataSelector(SettingsKey.REEFER_STATUS),

    compartmentStatuses: settingDataSelector(SettingsKey.COMPARTMENT_STATUS),
});

export const TrailerListView = compose<SceneListViewProps<MonitoringTrailerEntry>, TrailerListViewProps>(
    setDisplayName('TrailerListView'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps & ReduxProps) => ({
        columns: getColumnsMemoized({
            t: ownProps.t,
            trailerDisplayNameFormat: ownProps.displayPreferences.trailerDisplayFormat,
            vehicleDisplayNameFormat: ownProps.displayPreferences.vehicleDisplayFormat,
            unitSystem: ownProps.displayPreferences.unitSystem,
            trailerEventTypes: ownProps.trailerEventTypes,
            hookedStatuses: ownProps.hookedStatuses,
            doorStatuses: ownProps.doorStatuses,
            batteryStatuses: ownProps.batteryStatuses,
            reeferStatuses: ownProps.reeferStatuses,
            compartmentStatuses: ownProps.compartmentStatuses,
        }),
        gridDataId: 'monitoring-grid-trailer',
    }))
)(TrailerListViewComponent);

export const TrailerListViewHeader = compose<
    SceneListViewHeaderProps<MonitoringTrailerEntry>,
    TrailerListViewHeaderProps
>(
    setDisplayName('TrailerListViewHeader'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps & ReduxProps) => ({
        columns: getColumnsMemoized({
            t: ownProps.t,
            trailerDisplayNameFormat: ownProps.displayPreferences.trailerDisplayFormat,
            vehicleDisplayNameFormat: ownProps.displayPreferences.vehicleDisplayFormat,
            unitSystem: ownProps.displayPreferences.unitSystem,
            trailerEventTypes: ownProps.trailerEventTypes,
            hookedStatuses: ownProps.hookedStatuses,
            doorStatuses: ownProps.doorStatuses,
            batteryStatuses: ownProps.batteryStatuses,
            reeferStatuses: ownProps.reeferStatuses,
            compartmentStatuses: ownProps.compartmentStatuses,
        }),
        excelSheetTitle: ownProps.t('monitoring'),
    }))
)(TrailerListViewHeaderComponent);
