import VisibilityIcon from '@mui/icons-material/Visibility';
import { Divider, List, Popover, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { MouseEvent, ReactNode } from 'react';
import { PureComponent } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledIconButton } from '~/components/TitledIconButton';

import { SearchingBox } from '../Searching';

import { ColumnGroup } from './components/ColumnGroup';
import { ColumnSelectorFooter } from './components/ColumnSelectorFooter';
import type { ColumnSelectorGroup } from './models';
import type { ColumnSelectorClassKey } from './styles';
import * as utility from './utility';

export interface ColumnSelectorProps {
    columnGroups: ColumnSelectorGroup[];
    selectedColumns: string[];
    selectColumns: (columns: string[]) => void;
    deselectColumns: (columns: string[]) => void;
    disabledButton?: boolean;
}

export interface ColumnSelectorInnerProps
    extends ColumnSelectorProps,
        InjectedTranslationProps,
        WithStyles<ColumnSelectorClassKey> {}

export interface ColumnSelectorState {
    open: boolean;
    searchEnabled: boolean;
    expandedGroups: string[];
    matchedSelectedColumns: string[];
    searchQuery: string;
    visibleColumnGroups: ColumnSelectorGroup[];
    anchorElement?: HTMLElement;
}

enum SelectedFlag {
    None = 'None',
    Partially = 'Partially',
    All = 'All',
}

export class ColumnSelectorComponent extends PureComponent<ColumnSelectorInnerProps, ColumnSelectorState> {
    private allColumnNames: string[] = [];
    private matchedColumnNames: string[] = [];

    constructor(props: ColumnSelectorInnerProps) {
        super(props);

        this.allColumnNames = utility.getColumnNamesFromGroups(props.columnGroups);

        this.state = {
            open: false,
            searchEnabled: false,
            expandedGroups: [],
            searchQuery: '',
            visibleColumnGroups: [...props.columnGroups],
            matchedSelectedColumns: [...props.selectedColumns],
        };
    }

    public componentDidUpdate(_prevProps: ColumnSelectorInnerProps, prevState: ColumnSelectorState): void {
        if (this.state.searchQuery !== prevState.searchQuery) {
            // eslint-disable-next-line react/no-access-state-in-setstate
            const currentState = this.state;
            const filteredColumnGroups = utility.filterColumnGroups(currentState.searchQuery, this.props.columnGroups);
            this.matchedColumnNames = utility.getColumnNamesFromGroups(filteredColumnGroups);
            this.setState({
                visibleColumnGroups: filteredColumnGroups,
                expandedGroups: currentState.searchQuery === '' ? [] : filteredColumnGroups.map((it) => it.title),
                matchedSelectedColumns: this.matchedColumnNames.filter((it) => this.props.selectedColumns.includes(it)),
            });
        }
    }

    public render(): ReactNode {
        const { classes, t, selectedColumns } = this.props;

        const groups = this.state.visibleColumnGroups.map((columnGroup) => (
            <ColumnGroup
                key={columnGroup.title}
                columnGroup={columnGroup}
                selectedColumns={selectedColumns}
                selectColumns={this.selectColumns}
                deselectColumns={this.deselectColumns}
                groupIsExpanded={this.groupIsExpanded(columnGroup)}
                toggleGroupExpand={(expanded) => this.toggleGroupExpand(columnGroup, expanded)}
            />
        ));

        const selectedGroupColumnsCount = this.state.visibleColumnGroups.reduce((c, columnGroup) => {
            const groupCnt = columnGroup.columns.filter((it) => selectedColumns.includes(it.name)).length;
            return c + groupCnt;
        }, 0);

        const groupColumnCount = this.state.visibleColumnGroups.reduce(
            (c, columnGroup) => c + columnGroup.columns.length,
            0
        );

        const selectedFlag = this.getSelectedColumnsFlag();
        const items =
            this.state.searchQuery && !groups.length ? (
                <Typography align="center" data-id="column-selector-message" className={classes.message}>
                    {t('no-results', { term: this.state.searchQuery })}
                </Typography>
            ) : (
                <div className={classes.groupList}>
                    <List dense>{groups}</List>
                </div>
            );

        return (
            <>
                <TitledIconButton
                    title={t('change-visible-columns')}
                    disabled={this.props.disabledButton}
                    placement="bottom-end"
                    color="inherit"
                    onClick={this.handleClick}
                    aria-haspopup="true"
                    aria-owns={this.state.open ? 'column-selector-menu' : undefined}
                    data-id="column-selector"
                >
                    <VisibilityIcon />
                </TitledIconButton>

                <Popover
                    id="column-selector-menu"
                    onClose={this.handleClose}
                    open={this.state.open}
                    anchorEl={this.state.anchorElement}
                    disableRestoreFocus
                    classes={{ paper: classes.popoverPaper }}
                >
                    <SearchingBox
                        dataIdPrefix="column-selector"
                        searchingItemsName={t('columns')}
                        defaultSearchQuery={this.state.searchQuery}
                        changeSearchQuery={this.changeSearchQuery}
                        expandedAll={this.getAllExpanded()}
                        partiallyExpanded={this.isPartiallyExpanded()}
                        selectedAll={selectedFlag === SelectedFlag.All}
                        partiallySelected={selectedFlag === SelectedFlag.Partially}
                        selectAll={this.selectAllColumns}
                        deselectAll={this.deselectAllColumns}
                        selectAllMatchedItems={this.getSelectAllMatchedColumns()}
                        deselectAllMatchedItems={this.getDeselectAllMatchedColumns()}
                        searchEnabled={this.state.searchEnabled}
                        openSearch={this.openSearch}
                        closeSearch={this.closeSearch}
                        expandAll={this.expandAll}
                        collapseAll={this.collapseAll}
                    />

                    <Divider />
                    {items}
                    <Divider />

                    <ColumnSelectorFooter selectedCount={selectedGroupColumnsCount} totalNumber={groupColumnCount} />
                </Popover>
            </>
        );
    }

    private getSelectedColumnsFlag = (): SelectedFlag => {
        let selectedFlag = SelectedFlag.None;

        if (this.props.selectedColumns.length) {
            if (this.props.selectedColumns.length === this.allColumnNames.length) {
                selectedFlag = SelectedFlag.All;
            } else {
                selectedFlag = SelectedFlag.Partially;
            }
        }

        return selectedFlag;
    };

    private changeSearchQuery = (searchQuery: string) => {
        this.setState({ searchQuery });
    };

    private selectAllColumns = () => {
        this.props.selectColumns(this.allColumnNames);
    };

    private deselectAllColumns = () => {
        this.props.deselectColumns(this.allColumnNames);
    };

    private selectColumns = (columns: string[]) => {
        this.setState((prevState) => {
            return { matchedSelectedColumns: prevState.matchedSelectedColumns.concat(columns) };
        });
        this.props.selectColumns(columns);
    };

    private deselectColumns = (columns: string[]) => {
        this.setState((prevState) => {
            return {
                matchedSelectedColumns: prevState.matchedSelectedColumns.filter((it) => !columns.includes(it)),
            };
        });
        this.props.deselectColumns(columns);
    };

    private getSelectAllMatchedColumns = (): (() => void) | undefined => {
        return this.matchedColumnNames.length !== this.state.matchedSelectedColumns.length
            ? this.selectAllMatchedColumns
            : undefined;
    };

    private getDeselectAllMatchedColumns = (): (() => void) | undefined => {
        return this.state.matchedSelectedColumns.length > 0 ? this.deselectAllMatchedColumns : undefined;
    };

    private selectAllMatchedColumns = () => {
        this.setState({ matchedSelectedColumns: [...this.matchedColumnNames] });
        this.props.selectColumns(this.matchedColumnNames);
    };

    private deselectAllMatchedColumns = () => {
        this.setState({ matchedSelectedColumns: [] });
        this.props.deselectColumns(this.matchedColumnNames);
    };

    private openSearch = () => {
        this.setState({ searchEnabled: true });
    };

    private closeSearch = () => {
        this.setState({ searchQuery: '', searchEnabled: false });
    };

    private expandAll = () => {
        this.setState({ expandedGroups: this.props.columnGroups.map((it) => it.title) });
    };

    private collapseAll = () => {
        this.setState({ expandedGroups: [] });
    };

    private getAllExpanded = (): boolean => {
        return this.state.expandedGroups.length === this.props.columnGroups.length;
    };

    private groupIsExpanded = (group: ColumnSelectorGroup): boolean => {
        return this.state.expandedGroups.includes(group.title);
    };

    private toggleGroupExpand = (group: ColumnSelectorGroup, expanded: boolean) => {
        if (expanded) {
            this.setState((prevState) => {
                return { expandedGroups: [...prevState.expandedGroups, group.title] };
            });
        } else {
            this.setState((prevState) => {
                return { expandedGroups: prevState.expandedGroups.filter((it) => it !== group.title) };
            });
        }
    };

    private isPartiallyExpanded = (): boolean => {
        return this.state.expandedGroups.length > 0;
    };

    private handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        this.setState({ open: true, anchorElement: event.currentTarget });
    };

    private handleClose = (): void => {
        this.setState({ open: false });
    };
}
