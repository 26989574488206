import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { TextWorkflowFieldInnerProps, TextWorkflowFieldProps } from './component';
import { TextWorkflowFieldComponent } from './component';
import { TextWorkflowFieldStyleRules } from './styles';

export const TextWorkflowField = compose<TextWorkflowFieldInnerProps, TextWorkflowFieldProps>(
    setDisplayName('TextWorkflowField'),
    withStyles(TextWorkflowFieldStyleRules),
    withTranslation()
)(TextWorkflowFieldComponent);
