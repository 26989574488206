import type { TFunction } from 'i18next';

import type { MonitoringTrailerEntry } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesTrailerDisplayFormat } from '~/services/ApiClient';
import { AssetConnectionStatus, TrailerType } from '~/services/ApiClient';

import type { TrailerConnectionFilter } from './components/TrailerConnectionFiltering';
import { getTrailerSearchText } from './getTrailerSearchText';

export const filterTrailers = (
    entries: MonitoringTrailerEntry[],
    trailerNameDisplayFormat: DisplayUserPreferencesTrailerDisplayFormat,
    t: TFunction,
    searchQuery?: string,
    filter?: TrailerConnectionFilter
): MonitoringTrailerEntry[] => {
    if ((!filter || Object.values(filter).every((v) => isUndefined(v))) && !searchQuery) {
        return entries;
    }

    const searchQueryLowerCase = searchQuery?.toLowerCase();
    return entries.filter((entry: MonitoringTrailerEntry) => {
        const filterBySearchQuery = searchQueryLowerCase
            ? getTrailerSearchText(entry, trailerNameDisplayFormat, t).includes(searchQueryLowerCase)
            : true;

        const filterByTrailerTypeCondition = !filter?.trailerType
            ? true
            : filter.trailerType === TrailerType.TRAILER
              ? !entry.trailer.reeferManufacturer
              : entry.trailer.reeferManufacturer;

        const filterByTrailerManufacturerCondition = !filter?.trailerManufacturer
            ? true
            : entry.trailer.trailerManufacturer.id === filter.trailerManufacturer.id;

        const filterByConnectionStatusCondition = !filter?.connectionStatus
            ? true
            : filter.connectionStatus === AssetConnectionStatus.CONNECTED
              ? !isUndefined(entry.vehicleConnection)
              : isUndefined(entry.vehicleConnection);

        return (
            filterByTrailerTypeCondition &&
            filterByTrailerManufacturerCondition &&
            filterByConnectionStatusCondition &&
            filterBySearchQuery
        );
    });
};
