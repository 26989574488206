import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';
import { withSubscriptionProvider } from '~/components/SignalRSubscriptionProvider';

import type { DriverActivityGraphInnerProps, DriverActivityGraphProps } from './component';
import { DriverActivityGraphComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { staffSectionStateSelector } from './selectors';

export { driverActivitiesReducer } from './storestate';

export const DriverActivityGraph = compose<DriverActivityGraphInnerProps, DriverActivityGraphProps>(
    setDisplayName('DriverActivityGraph'),
    withTranslation(),
    withDisplayPreferences(),
    withSubscriptionProvider(),
    connect(
        createSelector(
            settingDataSelector(SettingsKey.DRIVERACTIVITYTYPES),
            staffSectionStateSelector,
            mapStateToProps
        ),
        mapDispatchToProps
    )
)(DriverActivityGraphComponent);
