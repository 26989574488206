import type { Duration } from 'moment';
import { duration as momentDuration } from 'moment';

import { memoize } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

import { DurationFormat, FormatLength } from './enums';

export interface DurationFormatOptions {
    durationFormat: DurationFormat;
    durationFormatLength: FormatLength;
}

const formatDurationInternal = (duration: Duration | number, options: DurationFormatOptions): string => {
    let format = '';
    switch (options.durationFormat) {
        case DurationFormat.HourMinute:
            format = options.durationFormatLength === FormatLength.Short ? 'h_ m_' : 'h __ m __';
            break;

        case DurationFormat.DayHourMinute:
            format = options.durationFormatLength === FormatLength.Short ? 'd_ h_ m_' : 'd __ h __ m __';
            break;

        case DurationFormat.Hour:
            format = options.durationFormatLength === FormatLength.Short ? 'h_' : 'h __';
            break;

        case DurationFormat.Minute:
            format = options.durationFormatLength === FormatLength.Short ? 'm _' : 'm __';
            break;

        default:
            throw new Error(`DurationFormat ${options.durationFormat} is not supported.`);
    }
    if (typeof duration !== 'number') {
        return duration.format(format);
    } else {
        const toDurationType = momentDuration(duration);
        return toDurationType.format(format);
    }
};

const formatDurationMemoized = memoizeOne((options: DurationFormatOptions) => {
    return memoize(
        (duration: Duration | number) => {
            return formatDurationInternal(duration, options);
        },
        (duration: Duration | number) => (typeof duration === 'number' ? duration : duration.asMilliseconds())
    );
});

export const formatDuration = (
    duration: Duration | number,
    options: DurationFormatOptions = {
        durationFormat: DurationFormat.HourMinute,
        durationFormatLength: FormatLength.Short,
    }
): string => {
    return formatDurationMemoized(options)(duration);
};

export const formatDurationMinutes = (
    duration: Duration,
    options: DurationFormatOptions = {
        durationFormat: DurationFormat.Minute,
        durationFormatLength: FormatLength.Short,
    }
): string => formatDurationMemoized(options)(duration);

export const durationISOStringToMinutes = (isoString?: string): number | undefined => {
    if (!isoString) {
        return undefined;
    }

    return momentDuration(isoString).asMinutes();
};

/**
 * @returns WORTH NOTING! Moment.Duration#toISOString() doesn't overflow 24+ hours to days. E.g 2190 minutes (1 day 12h 30min)
 * gets converted to "PT36H30M", not "P1DT12H30M"
 */
export const minutesNumberToDurationISOString = (minutes: number): string | undefined => {
    if (minutes < 0) {
        return undefined;
    }

    return momentDuration(minutes, 'minutes').toISOString();
};
