import Room from '@mui/icons-material/Room';
import type { InputBaseComponentProps } from '@mui/material';
import { TextField } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { ChangeEvent, FC, RefCallback } from 'react';
import { useCallback, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { Position } from '~/services/ApiClient';
import { formatCardinal, formatCoordinates } from '~/services/Formatters';
import { isValidPosition, parseCoordinates } from '~/services/Parsers';

import { PositionDialog } from '../PositionDialog';

import type { PositionInputClassKey } from './styles';

interface PositionMaskComponentProps extends InputBaseComponentProps {
    inputRef: RefCallback<HTMLInputElement>;
}

export interface PositionInputProps {
    position?: Position;
    onChange: (position: Position | undefined) => void;
    onBlur: () => void;
    name: string;
    dataId: string;
    readOnly: boolean;
    required: boolean;
    error: boolean;
    helperText: string;
    label?: string;
    disabled?: boolean;
}

export interface PositionInputInnerProps
    extends PositionInputProps,
        WithStyles<PositionInputClassKey>,
        InjectedTranslationProps {}

export const PositionInputComponent: FC<PositionInputInnerProps> = (props) => {
    const {
        onChange,
        onBlur,
        position,
        name,
        readOnly,
        required,
        dataId,
        classes,
        t,
        helperText,
        error,
        disabled,
        label,
    } = props;

    const [showPositionDialog, setShowPositionDialog] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(
        position && isValidPosition(position) ? formatCoordinates(t, position) : ''
    );

    const handlePositionDialog = useCallback(() => {
        setShowPositionDialog(!showPositionDialog);
    }, [showPositionDialog]);

    const positionDialog = showPositionDialog && (
        <PositionDialog
            open={showPositionDialog}
            onClose={handlePositionDialog}
            position={position && isValidPosition(position) ? position : undefined}
            setFieldValue={onChange}
        />
    );

    useEffect(() => {
        if (position && isValidPosition(position)) {
            setInputValue(formatCoordinates(t, position));
        }
    }, [t, setInputValue, position]);

    const PositionMaskComponent = useCallback(
        (inputProps: PositionMaskComponentProps) => {
            const north = formatCardinal(t, 'N')[0];
            const south = formatCardinal(t, 'S')[0];
            const east = formatCardinal(t, 'E')[0];
            const west = formatCardinal(t, 'W')[0];

            const mask = [
                /\d/,
                /\d/,
                '\u00B0',
                /[0-5]/,
                /\d/,
                '\u2032',
                /[0-5]/,
                /\d/,
                '\u2033',
                ' ',
                new RegExp(`(${north}|${south})`, 'i'),
                ' ',
                /[0-1]/,
                /\d/,
                /\d/,
                '\u00B0',
                /[0-5]/,
                /\d/,
                '\u2032',
                /[0-5]/,
                /\d/,
                '\u2033',
                ' ',
                new RegExp(`(${east}|${west})`, 'i'),
            ];

            return (
                <MaskedInput {...inputProps} mask={mask} placeholderChar="-" showMask={false} guide keepCharPositions />
            );
        },
        [t]
    );

    return (
        <>
            <TextField
                data-id={dataId}
                variant="outlined"
                size="small"
                name={name}
                fullWidth
                InputProps={{
                    readOnly,
                    inputComponent: PositionMaskComponent,
                    endAdornment: (
                        <Room
                            className={readOnly ? classes.markerIconDisabled : classes.markerIcon}
                            color="action"
                            onClick={handlePositionDialog}
                        />
                    ),
                }}
                label={label}
                required={required}
                error={error}
                helperText={helperText}
                value={inputValue}
                onBlur={onBlur}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const eventValue = e.target.value;
                    setInputValue(eventValue);
                    const changedPosition = eventValue ? parseCoordinates(t, eventValue) : undefined;
                    onChange(changedPosition);
                }}
                disabled={disabled}
            />
            {positionDialog}
        </>
    );
};
