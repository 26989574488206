import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { NoOptionsMessageInnerProps, NoOptionsMessageProps } from './component';
import { NoOptionsMessageComponent } from './component';
import { NoOptionsMessageStyleRules } from './styles';

export const NoOptionsMessage = compose<NoOptionsMessageInnerProps, NoOptionsMessageProps>(
    setDisplayName('NoOptionsMessage'),
    withStyles(NoOptionsMessageStyleRules)
)(NoOptionsMessageComponent);
