import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ColumnSelectorFooterClassKey = 'root';

export const ColumnSelectorFooterStyleRules: StyleRulesCallback<Theme, {}, ColumnSelectorFooterClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            padding: theme.spacing(1.5, 2),
        },
    });
