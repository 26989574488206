import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ColumnGroupInnerProps, ColumnGroupProps } from './component';
import { ColumnGroupComponent } from './component';
import { ColumnGroupStyleRules } from './styles';

export const ColumnGroup = compose<ColumnGroupInnerProps, ColumnGroupProps>(
    setDisplayName('ColumnGroup'),
    withStyles(ColumnGroupStyleRules)
)(ColumnGroupComponent);
