import type { NumericDictionary } from '~/libs/utility';
import { isUndefined, keyBy } from '~/libs/utility';
import type {
    Classification,
    CompartmentStatus,
    DeviceType,
    DoorStatus,
    ResolvedTrailerEvent,
    TrailerEvent,
} from '~/services/ApiClient';
import {
    compartmentStatusResolver,
    doorStatusResolver,
    reeferStatusResolver,
    trailerBatteryStatusResolver,
} from '~/services/ModelResolvers';

export const trailerEventResolver = (
    { init, toJSON, ...restProps }: TrailerEvent,
    deviceTypes: NumericDictionary<DeviceType>,
    reeferStatus: NumericDictionary<Classification>,
    reeferAlarm: NumericDictionary<Classification>,
    reeferOperationMode: NumericDictionary<Classification>,
    reeferPowerMode: NumericDictionary<Classification>,
    reeferSpeed: NumericDictionary<Classification>,
    hookedStatus: NumericDictionary<Classification>,
    compartmentStatus: NumericDictionary<Classification>,
    doorStatus: NumericDictionary<Classification>,
    trailerBatteryStatus: NumericDictionary<Classification>,
    trailerEventType: NumericDictionary<Classification>
): ResolvedTrailerEvent => ({
    ...restProps,
    deviceType: restProps.sendingDevice && deviceTypes[restProps.sendingDevice.type],
    compartments: keyBy(
        restProps.compartments.map((compartment: CompartmentStatus) =>
            compartmentStatusResolver(compartment, compartmentStatus)
        ),
        'compartmentNumber'
    ),
    doors: keyBy(
        restProps.doors.map((door: DoorStatus) => doorStatusResolver(door, doorStatus)),
        'doorNumber'
    ),
    reefer:
        restProps.reefer &&
        reeferStatusResolver(
            restProps.reefer,
            reeferStatus,
            reeferAlarm,
            reeferOperationMode,
            reeferPowerMode,
            reeferSpeed
        ),
    hooked: !isUndefined(restProps.hooked) ? hookedStatus[restProps.hooked] : undefined,
    battery: restProps.battery ? trailerBatteryStatusResolver(restProps.battery, trailerBatteryStatus) : undefined,
    eventType: trailerEventType[restProps.eventType],
});
