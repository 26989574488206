import type { TrailersResponse } from '~/services/ApiClient';
import type { Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    TRAILERS = 'TRAILERS',
}

export enum ActionTypeKeys {
    TRAILERS_PENDING = 'TRAILERS_PENDING',
    TRAILERS_FULFILLED = 'TRAILERS_FULFILLED',
    TRAILERS_REJECTED = 'TRAILERS_REJECTED',
}

export type TrailersPayload = Readonly<TrailersResponse>;

export type ActionTypes =
    | Pending<ActionTypeKeys.TRAILERS_PENDING>
    | Fulfilled<ActionTypeKeys.TRAILERS_FULFILLED, TrailersPayload>
    | Rejected<ActionTypeKeys.TRAILERS_REJECTED>;
