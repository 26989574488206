import { memo } from 'react';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ConfirmationDialogInnerProps, ConfirmationDialogProps } from './component';
import { ConfirmationDialogComponent } from './component';

export const ConfirmationDialog = compose<ConfirmationDialogInnerProps, ConfirmationDialogProps>(
    setDisplayName('ConfirmationDialog'),
    withTranslation(),
    memo
)(ConfirmationDialogComponent);
