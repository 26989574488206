import { compose, setDisplayName } from 'react-recompose';

import { withSceneAnalytics } from '~/components/Analytics';
import { withTranslation } from '~/components/LanguageSelector';

import type { RtdsScheduleDetailsPaneInnerProps, RtdsScheduleDetailsPaneProps } from './component';
import { RtdsScheduleDetailsPaneComponent } from './component';
import {
    RTDSSCHEDULE_DETAILSPANE_USERPREFERENCES_KEY,
    defaultRtdsScheduleDetailsPaneUserPreferences,
    requiredRtdsScheduleDetailsPaneUserPreferences,
} from './preferences';

export {
    defaultRtdsScheduleDetailsPaneUserPreferences,
    requiredRtdsScheduleDetailsPaneUserPreferences,
    RTDSSCHEDULE_DETAILSPANE_USERPREFERENCES_KEY,
};

export const RtdsScheduleDetailsPane = compose<RtdsScheduleDetailsPaneInnerProps, RtdsScheduleDetailsPaneProps>(
    setDisplayName('RtdsScheduleDetailsPane'),
    withSceneAnalytics('rtdsscheduledetailspane'),
    withTranslation()
)(RtdsScheduleDetailsPaneComponent);
