import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ConversationSummaryInnerProps, ConversationSummaryProps } from './component';
import { ConversationSummaryComponent } from './component';
import { ConversationSummaryStyleRules } from './styles';

export const ConversationSummary = compose<ConversationSummaryInnerProps, ConversationSummaryProps>(
    setDisplayName('ConversationSummary'),
    withStyles(ConversationSummaryStyleRules)
)(ConversationSummaryComponent);
