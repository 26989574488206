import { createAction } from 'redux-actions';

import { sortBy } from '~/libs/utility';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import { ActionTypePrefixes } from './actionTypes';

export const retrieveDepotsExecutor = () => {
    return retryableRequest(async () => {
        const { items } = await ApiClient.getDepots();
        return sortBy(items, (depot) => depot.name.toLocaleLowerCase());
    });
};

export const retrieveDepotsAction = createAction(ActionTypePrefixes.DEPOTS, retrieveDepotsExecutor);
