import type { VehicleConnectionsNotification, VehicleConnectionsResponse } from '~/services/ApiClient';
import type { Action, Fulfilled, FulfilledMeta, Pending, PendingMeta, Rejected, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    VEHICLECONNECTIONS = 'VEHICLECONNECTIONS',
    VEHICLECONNECTIONS_CONNECTTRAILER = 'VEHICLECONNECTIONS_CONNECTTRAILER',
    VEHICLECONNECTIONS_DISCONNECTTRAILER = 'VEHICLECONNECTIONS_DISCONNECTTRAILER',
}

export enum ActionTypeKeys {
    VEHICLECONNECTIONS_PENDING = 'VEHICLECONNECTIONS_PENDING',
    VEHICLECONNECTIONS_FULFILLED = 'VEHICLECONNECTIONS_FULFILLED',
    VEHICLECONNECTIONS_REJECTED = 'VEHICLECONNECTIONS_REJECTED',
    VEHICLECONNECTIONS_UPDATE = 'VEHICLECONNECTIONS_UPDATE',
    VEHICLECONNECTIONS_CONNECTTRAILER_PENDING = 'VEHICLECONNECTIONS_CONNECTTRAILER_PENDING',
    VEHICLECONNECTIONS_CONNECTTRAILER_FULFILLED = 'VEHICLECONNECTIONS_CONNECTTRAILER_FULFILLED',
    VEHICLECONNECTIONS_CONNECTTRAILER_REJECTED = 'VEHICLECONNECTIONS_CONNECTTRAILER_REJECTED',
    VEHICLECONNECTIONS_DISCONNECTTRAILER_PENDING = 'VEHICLECONNECTIONS_DISCONNECTTRAILER_PENDING',
    VEHICLECONNECTIONS_DISCONNECTTRAILER_FULFILLED = 'VEHICLECONNECTIONS_DISCONNECTTRAILER_FULFILLED',
    VEHICLECONNECTIONS_DISCONNECTTRAILER_REJECTED = 'VEHICLECONNECTIONS_DISCONNECTTRAILER_REJECTED',
}

export type VehicleConnectionsPayload = Readonly<VehicleConnectionsResponse>;

export type ActionTypes =
    | Pending<ActionTypeKeys.VEHICLECONNECTIONS_PENDING>
    | Fulfilled<ActionTypeKeys.VEHICLECONNECTIONS_FULFILLED, VehicleConnectionsPayload>
    | Rejected<ActionTypeKeys.VEHICLECONNECTIONS_REJECTED>
    | Action<ActionTypeKeys.VEHICLECONNECTIONS_UPDATE, VehicleConnectionsNotification>
    | PendingMeta<ActionTypeKeys.VEHICLECONNECTIONS_CONNECTTRAILER_PENDING, number>
    | FulfilledMeta<ActionTypeKeys.VEHICLECONNECTIONS_CONNECTTRAILER_FULFILLED, undefined, number>
    | RejectedMeta<ActionTypeKeys.VEHICLECONNECTIONS_CONNECTTRAILER_REJECTED, undefined, number>
    | PendingMeta<ActionTypeKeys.VEHICLECONNECTIONS_DISCONNECTTRAILER_PENDING, number>
    | FulfilledMeta<ActionTypeKeys.VEHICLECONNECTIONS_DISCONNECTTRAILER_FULFILLED, undefined, number>
    | RejectedMeta<ActionTypeKeys.VEHICLECONNECTIONS_DISCONNECTTRAILER_REJECTED, undefined, number>;
