import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type PositionStatusContentClassKey = 'splitRow';

export const PositionStatusContentStyleRules: StyleRulesCallback<Theme, {}, PositionStatusContentClassKey> = () =>
    createStyles({
        splitRow: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
        },
    });
