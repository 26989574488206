import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { userAutocompleteStateSelector } from '~/selectors';

import type { UserRemoteAutocompleteInnerProps, UserRemoteAutocompleteProps } from './component';
import { UserRemoteAutocompleteComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export type { UserRemoteAutocompleteProps } from './component';
export type { UserAutocompleteStoreState } from './reducers';
export { userAutocompleteReducer } from './reducers';

export const UserRemoteAutocomplete = compose<UserRemoteAutocompleteInnerProps, UserRemoteAutocompleteProps>(
    setDisplayName('UserRemoteAutocomplete'),
    connect(createSelector(userAutocompleteStateSelector, mapStateToProps), mapDispatchToProps)
)(UserRemoteAutocompleteComponent);
