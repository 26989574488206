import withStyles from '@mui/styles/withStyles';
import type { ComponentType } from 'react';
import { compose } from 'react-recompose';

import type { AnimatedMarkerProps } from '../AnimatedMarker';
import { AnimatedMarker } from '../AnimatedMarker';
import { JSXMarker } from '../JSXMarker';
import type { JSXMarkerProps } from '../JSXMarker/models';

import { withMarkerPopupHoc } from './component';
import type { MarkerPopupExtendedProps } from './models';
import { WithMarkerPopupStyleRules } from './styles';

export const withMarkerPopup = <T>(WrappedComponent: ComponentType<T>): ComponentType<T & MarkerPopupExtendedProps> =>
    compose<T, T & MarkerPopupExtendedProps>(
        withStyles(WithMarkerPopupStyleRules),
        withMarkerPopupHoc
    )(WrappedComponent);

export const MarkerWithPopup = withMarkerPopup<JSXMarkerProps>(JSXMarker);
export const AnimatedMarkerWithPopup = withMarkerPopup<AnimatedMarkerProps<unknown>>(AnimatedMarker);
