import { useTheme } from '@mui/material';
import type { ApexOptions } from 'apexcharts';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDisplayPreferences } from '~/common/hooks';
import ApexCharts from '~/libs/react-apexcharts';
import { getDistanceUnitSystemUtils, getFuelLevelUnitSystemUtils } from '~/services/Formatters';
import { renderToStaticThemedMarkup } from '~/services/React';

import { Tooltip } from './components';
import { TooltipSize } from './models';
import type { FuelLevelGraphProps } from './models';

const FuelLevelGraph: FC<FuelLevelGraphProps> = (props: FuelLevelGraphProps) => {
    const { data, tooltipSize = TooltipSize.Large } = props;
    const { t } = useTranslation();
    const { unitSystem } = useDisplayPreferences();
    const theme = useTheme();

    // Memoize the options to prevent unnecessary re-renders.
    const options: ApexOptions = useMemo(
        () => ({
            chart: {
                type: 'line',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: true,
                },
            },
            xaxis: {
                type: 'numeric',
                categories: data.map(({ odometer }) => odometer),
                title: {
                    text: t('odometer'),
                    style: theme.custom.font,
                },
                tickAmount: 10,
                labels: {
                    formatter: (value) => getDistanceUnitSystemUtils(t, unitSystem).formatter(Number(value)),
                },
            },
            yaxis: {
                title: {
                    text: t('fuel-level'),
                    style: theme.custom.font,
                },
                labels: {
                    formatter: (value) => getFuelLevelUnitSystemUtils(t, unitSystem).formatter(Number(value)),
                },
            },
            tooltip: {
                intersect: true,
                shared: false,
                followCursor: true,
                custom: ({ dataPointIndex }: { dataPointIndex: number }) =>
                    renderToStaticThemedMarkup(
                        theme,
                        <Tooltip size={tooltipSize} info={data[dataPointIndex]} unitSystem={unitSystem} />
                    ),
            },
            markers: {
                size: 5,
                hover: {
                    sizeOffset: 6,
                },
            },
            stroke: {
                curve: 'smooth',
            },
        }),
        [data, t, unitSystem, theme, tooltipSize]
    );

    // Map the data to the series format that ApexCharts expects.
    const series = useMemo(
        () => [
            {
                name: t('fuel-level'),
                data: data.map(({ odometer, fuelLevel }) => ({
                    x: odometer,
                    y: fuelLevel,
                })),
            },
        ],
        [t, data]
    );

    return <ApexCharts options={options} series={series} height="100%" type="line" />;
};

export { FuelLevelGraph };
