import type { Classification } from '~/services/ApiClient';

export interface DocumentViewerFile {
    filename: string;
    type: Classification;
}

export interface DocumentViewerFileWithContent {
    file: DocumentViewerFile;
    contents: Blob;
}

export enum DocumentViewerState {
    Loading = 'loading',
    Success = 'success',
    NoPreview = 'no-preview',
}

export interface DocumentViewerPaging {
    numberOfPages: number;
    pageNumber: number;
    onPageNumberChange: (pageNumber: number) => void;
}

export interface DocumentViewerRotation {
    value: number;
    applyRotation: (degrees: number) => void;
    setOriginalSize: (size: Size) => void;
    transformedSize?: Size;
}

export interface Size {
    width: number;
    height: number;
}

export enum DocumentViewerScaleMode {
    FitToScreen = 'fit-to-screen',
    FitToScreenWidth = 'fit-to-screen-width',
}

export type DocumentViewerScale = DocumentViewerScaleMode | number;

export interface DocumentViewerScaling {
    value: number;
    scale: DocumentViewerScale;
    zoomIn?: () => void;
    zoomOut?: () => void;
    fitToScreenWidth: () => void;
    fitToScreen: () => void;
    setScreenSize: (size: Size) => void;
    setContentSize: (size: Size) => void;
}

export interface SingleDocumentViewerProps extends React.RefAttributes<SingleDocumentViewerElement> {
    state: DocumentViewerState;
    data: Blob | undefined;
    onStateChange: (state: DocumentViewerState) => void;
    onScalingChange?: (scaling: DocumentViewerScaling | undefined) => void;
    onRotationChange?: (scaling: DocumentViewerRotation | undefined) => void;
    onPagingChange?: (scaling: DocumentViewerPaging | undefined) => void;
}

export interface SingleDocumentViewerElement {
    print?: () => void;
}
