import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateEtherealAction } from './data';
import { keyDataStateSelectorFactory } from './selectors';

export const useEtherealState = <T extends object>(defaultValue: T, key: string): [T, (value: Partial<T>) => void] => {
    const selector = useMemo(() => keyDataStateSelectorFactory<T>(key, defaultValue), [defaultValue, key]);
    const value = useSelector(selector);
    const dispatch = useDispatch();
    const setValue = useCallback((v: Partial<T>) => dispatch(updateEtherealAction(key, v)), [dispatch, key]);

    return [value, setValue];
};
