import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { languageStateSelector } from '~/selectors';

import { EnsureCurrentLanguageHoc as hoc } from './component';
import { mapDispatchProps, mapStateToProps } from './redux';

export const ensureCurrentLanguage = compose(
    setDisplayName('ensureCurrentLanguage'),
    connect(createSelector(languageStateSelector, mapStateToProps), mapDispatchProps),
    hoc
);
