import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type GridRootClassKey = 'root';

export const GridRootStyleRules: StyleRulesCallback<Theme, {}, GridRootClassKey> = () =>
    createStyles({
        root: {
            height: '100%',
            width: '100%',
        },
    });
