import type { AssetReference } from '~/services/ApiClient';

import type { AssetReferenceHash } from '../models';

export const selectedAssetSelector = (
    overrideSelectedAssets: AssetReference[] | undefined,
    selectedAssetIds: AssetReference[] | undefined,
    assetIdsHash: AssetReferenceHash<AssetReference>
): AssetReference[] | undefined => {
    const ids = overrideSelectedAssets ?? selectedAssetIds;

    // Make sure the selected ids are equal by reference
    return ids?.map((id) => assetIdsHash[id?.type]?.[id.id]).filter((id): id is AssetReference => !!id);
};
