import type { Reducer } from 'redux';
import type { Persistor } from 'redux-persist';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export interface PersistStoreState {
    persistor?: Persistor;
}

const defaultStoreState: PersistStoreState = {
    persistor: undefined,
};

export const persistStoreReducer: Reducer<PersistStoreState> = (
    state: PersistStoreState = defaultStoreState,
    action: ActionTypes
): PersistStoreState => {
    switch (action.type) {
        case ActionTypeKeys.OVERRIDEPERSISTOR:
            return { persistor: action.payload };
        default:
            return state;
    }
};
