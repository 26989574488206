import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LeftPaneClassKey = 'root' | 'header' | 'title' | 'content';

export const LeftPaneStyleRules: StyleRulesCallback<Theme, {}, LeftPaneClassKey> = (theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            backgroundColor: theme.palette.background.default,
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
        },
        content: {
            overflow: 'auto',
            padding: theme.spacing(1.5, 2),
        },
    });
