import { Tooltip } from '@mui/material';
import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatIgnitionStatusTitle } from '~/services/Formatters';

import { IgnitionIconFormatter } from './components/IgnitionAvatarIcon';

export interface IgnitionStatusFormatterProps {
    t: SingleTFunction;
    value?: boolean;
}

export interface IgnitionStatusFormatterInnerProps extends IgnitionStatusFormatterProps {}

const IgnitionStatusFormatter: React.FunctionComponent<IgnitionStatusFormatterInnerProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }
    const status = value ? 'on' : 'off';

    return (
        <Tooltip title={formatIgnitionStatusTitle(t, value)}>
            <span data-id={`ignition-status:${status}`}>
                <IgnitionIconFormatter value={value} />
            </span>
        </Tooltip>
    );
};
IgnitionStatusFormatter.displayName = 'IgnitionStatusFormatter';

export { IgnitionStatusFormatter };
