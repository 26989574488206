import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';

import { withTranslation } from '~/components/LanguageSelector';
import { requiredMapUserPreferences, withMapSharedProps } from '~/components/MapSharedProps';
import { ensureUserPreferences } from '~/components/UserPreferences';

import type { MapActionsInnerProps, MapActionsProps } from './component';
import { MapActionsComponent } from './component';
import { mapDispatchToProps } from './redux';
import { MapActionsStyleRules } from './styles';

export const MapActions = compose<MapActionsInnerProps, MapActionsProps>(
    setDisplayName('MapActions'),
    ensureUserPreferences([requiredMapUserPreferences], false),
    withMapSharedProps(),
    withTranslation(),
    withStyles(MapActionsStyleRules),
    connect(undefined, mapDispatchToProps)
)(MapActionsComponent);
