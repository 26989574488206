import type { NumericDictionary } from '~/libs/utility';
import type {
    Classification,
    DeviceType,
    Driver,
    DriverActivityType,
    DriverEvent,
    DriverEventType,
    DriverSubActivityType,
    Trailer,
    TrailerEvent,
    Vehicle,
    VehicleCategory,
    VehicleEvent,
    VehicleType,
} from '~/services/ApiClient';

import type { HistoryAssetEntry } from './models';
import { HistoryEventType } from './models';
import { buildHistoryDriverEntries } from './redux.buildHistoryDriverEntries';
import { buildHistoryTrailerEntries } from './redux.buildHistoryTrailerEntries';
import { buildHistoryVehicleEntries } from './redux.buildHistoryVehicleEntries';

export const buildHistoryAssetEntries = (
    historyEventType: HistoryEventType,
    vehicleEvents: VehicleEvent[],
    trailerEvents: TrailerEvent[],
    driverEvents: DriverEvent[],
    vehiclesHash: NumericDictionary<Vehicle>,
    trailersHash: NumericDictionary<Trailer>,
    driversHash: NumericDictionary<Driver>,
    deviceTypes: NumericDictionary<DeviceType>,
    vehicleCategories: NumericDictionary<VehicleCategory>,
    vehicleTypes: NumericDictionary<VehicleType>,
    reeferStatus: NumericDictionary<Classification>,
    reeferAlarm: NumericDictionary<Classification>,
    reeferOperationMode: NumericDictionary<Classification>,
    reeferPowerMode: NumericDictionary<Classification>,
    reeferSpeed: NumericDictionary<Classification>,
    hookedStatus: NumericDictionary<Classification>,
    compartmentStatus: NumericDictionary<Classification>,
    doorStatus: NumericDictionary<Classification>,
    trailerBatteryStatus: NumericDictionary<Classification>,
    trailerEventType: NumericDictionary<Classification>,
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>,
    driverEventTypes: NumericDictionary<DriverEventType>
): HistoryAssetEntry[] => {
    switch (historyEventType) {
        case HistoryEventType.VEHICLE: {
            return buildHistoryVehicleEntries(
                vehiclesHash,
                vehicleEvents,
                deviceTypes,
                vehicleCategories,
                vehicleTypes
            );
        }
        case HistoryEventType.TRAILER: {
            return buildHistoryTrailerEntries(
                trailersHash,
                trailerEvents,
                deviceTypes,
                reeferStatus,
                reeferAlarm,
                reeferOperationMode,
                reeferPowerMode,
                reeferSpeed,
                hookedStatus,
                compartmentStatus,
                doorStatus,
                trailerBatteryStatus,
                trailerEventType
            );
        }
        case HistoryEventType.DRIVER: {
            return buildHistoryDriverEntries(
                driversHash,
                driverEvents,
                driverActivityTypes,
                driverSubActivityTypes,
                driverEventTypes
            );
        }
        default:
            throw Error(`Unknown perspective: ${historyEventType}`);
    }
};
