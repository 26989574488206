import _Checkbox from '@mui/material/Checkbox';
import _Collapse from '@mui/material/Collapse';
import _FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import _Typography from '@mui/material/Typography';

interface ContainerProps {
    isLeaf: boolean;
    hideExpandAllButton?: boolean;
    indentLevel?: number;
}

const Container = styled('div')<ContainerProps>(({ theme, isLeaf, indentLevel = 0, hideExpandAllButton }) => ({
    paddingLeft: !hideExpandAllButton
        ? `calc(${isLeaf ? `${theme.spacing(4)} + ` : ''}(${indentLevel} * ${theme.spacing(4)}))`
        : theme.spacing(),
}));

interface ActionContainerProps {
    isSelected: boolean;
    isLeaf?: boolean;
    isDisabled?: boolean;
    singleSelectMode?: boolean;
}

const ActionContainer = styled('div')<ActionContainerProps>(
    ({ theme, isSelected, isLeaf, singleSelectMode, isDisabled }) => ({
        alignItems: 'center',
        backgroundColor: isSelected ? theme.palette.action.selected : 'initial',
        color: theme.palette.action.active,
        display: 'flex',
        flexGrow: 1,
        paddingRight: theme.spacing(),
        minHeight: theme.spacing(5),
        padding: isLeaf ? `0 ${theme.spacing()}` : 'initial',
        opacity: isDisabled ? theme.palette.action.disabledOpacity : 1,
        pointerEvents: isDisabled ? 'none' : 'initial',
        ':hover': {
            backgroundColor: singleSelectMode && isLeaf ? theme.palette.action.hover : 'initial',
            cursor: singleSelectMode && isLeaf ? 'pointer' : 'initial',
        },
    })
);

const Typography = styled(_Typography)({ paddingLeft: '10px', whiteSpace: 'nowrap' });
const Checkbox = styled(_Checkbox)({ ':hover': { backgroundColor: 'inherit' } });

const Collapse = styled(_Collapse)({
    '& .MuiCollapse-wrapperInner': { flexGrow: 1 },
    flexGrow: 1,
    margin: 0,
    width: '100%',
});

const LabelContainer = styled('div')(() => ({
    display: 'flex',
}));

const FormControlLabel = styled(_FormControlLabel)(({ theme }) => ({
    paddingLeft: theme.spacing(0.5),
}));

export { ActionContainer, Checkbox, Collapse, Container, Typography, LabelContainer, FormControlLabel };
