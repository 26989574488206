import { createAction } from 'redux-actions';

import type { QueryRtdsSessionsRequestRequestMode } from '~/services/ApiClient';
import { ApiClient, QueryRtdsSessionsRequest, createApiModel, retryableRequest } from '~/services/ApiClient';

import { ActionTypePrefixes } from './actionTypes';

export const queryRtdsSessionsAction = createAction(
    ActionTypePrefixes.RTDSSESSIONS_QUERY,
    (
        startDate: Date,
        stopDate: Date,
        requestMode: QueryRtdsSessionsRequestRequestMode,
        vehicleIds: number[] | undefined,
        driverIds: number[] | undefined
    ) =>
        retryableRequest(() =>
            ApiClient.queryRtdsSessions(
                createApiModel(QueryRtdsSessionsRequest, { startDate, stopDate, requestMode, vehicleIds, driverIds })
            )
        ),
    (
        startDate: Date,
        stopDate: Date,
        requestMode: QueryRtdsSessionsRequestRequestMode,
        vehicleIds: number[] | undefined,
        driverIds: number[] | undefined
    ) => createApiModel(QueryRtdsSessionsRequest, { startDate, stopDate, requestMode, vehicleIds, driverIds })
);
