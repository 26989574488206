import withStyles from '@mui/styles/withStyles';

import { BubbleComponent } from './component';
import { BubbleStyleRules } from './styles';

export { BubbleType } from './component';

const Bubble = withStyles(BubbleStyleRules)(BubbleComponent);

export { Bubble };
