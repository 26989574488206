import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DriverEventHasCorrectionFormatterInnerProps, DriverEventHasCorrectionFormatterProps } from './component';
import { DriverEventHasCorrectionFormatterComponent } from './component';
import { IconFormatter } from './components/Icon';

export const DriverEventHasCorrectionIconFormatter = IconFormatter;

export const DriverEventHasCorrectionFormatter = compose<
    DriverEventHasCorrectionFormatterInnerProps,
    DriverEventHasCorrectionFormatterProps
>(
    setDisplayName('DriverEventHasCorrectionFormatter'),
    withTranslation()
)(DriverEventHasCorrectionFormatterComponent);
