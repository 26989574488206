import type { JwtDecodedToken } from '~/data/authentication';
import type { StoreState } from '~/reducers';
import { protectedComponentStoreStateSelector } from '~/selectors';

export const protectedComponentDecodedTokenStateSelector = (s: StoreState): JwtDecodedToken | undefined => {
    return protectedComponentStoreStateSelector(s).decodedJwt;
};

export const impersonatorTokenStateSelector = (s: StoreState): string | undefined => {
    return protectedComponentStoreStateSelector(s).impersonatorJwt;
};
