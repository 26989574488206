import { createAction } from 'redux-actions';

import type { QueryTachoFilesResponse } from '~/services/ApiClient';
import { ApiClient, QueryTachoFilesRequest, createApiModel, retryableRequest } from '~/services/ApiClient';

import { ActionTypePrefixes } from './actionTypes';

export const queryTachoFilesAction = createAction<
    Promise<QueryTachoFilesResponse>,
    QueryTachoFilesRequest,
    Date,
    Date,
    number[] | undefined,
    number[] | undefined
>(
    ActionTypePrefixes.TACHOFILES_QUERY,
    (startDate: Date, stopDate: Date, vehicleIds: number[] | undefined, driverIds: number[] | undefined) =>
        retryableRequest(() =>
            ApiClient.queryTachoFiles(
                createApiModel(QueryTachoFilesRequest, { startDate, stopDate, vehicleIds, driverIds })
            )
        ),
    (startDate: Date, stopDate: Date, vehicleIds: number[] | undefined, driverIds: number[] | undefined) =>
        createApiModel(QueryTachoFilesRequest, { startDate, stopDate, vehicleIds, driverIds })
);
