import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { ContextMenuItem, ContextMenuPosition } from '~/components/ContextMenu';
import { ContextMenu } from '~/components/ContextMenu';
import { CompassIcon } from '~/components/Icons';
import { isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { copyCoordinatesToClipboard } from '~/services/Clipboard';

import type { HistoryEventType } from '../../models';

import { reduxPropsSelector } from './redux';

export interface HistoryEventContextMenuProps {
    eventId?: number;
    eventType?: HistoryEventType;
    position?: ContextMenuPosition;
    onClose: () => void;
}

export interface HistoryEventContextMenuInnerProps extends HistoryEventContextMenuProps {}

const HistoryEventContextMenu: FC<HistoryEventContextMenuInnerProps> = (props) => {
    const { eventId, eventType, position, onClose } = props;

    const { t } = useTranslation();
    const reduxProps = useSelector((state: StoreState) => reduxPropsSelector(state, { eventId, eventType }));

    const getMenuItems = (): ContextMenuItem[] => {
        const copyCoordinates = () => {
            if (!isUndefined(reduxProps.eventPosition)) {
                copyCoordinatesToClipboard(reduxProps.eventPosition);
            }
        };

        return [
            {
                key: 'copy-coordinates',
                title: t('copy-coordinates'),
                icon: <CompassIcon />,
                onClick: copyCoordinates,
                disabled: isUndefined(reduxProps.eventPosition),
            },
        ];
    };

    return (
        <ContextMenu
            menuItems={getMenuItems()}
            position={position}
            onClose={onClose}
            data-id="history-entry-context-menu"
        />
    );
};
HistoryEventContextMenu.displayName = 'HistoryEventContextMenu';

export { HistoryEventContextMenu };
