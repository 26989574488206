import { Avatar } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import type { Subtract } from 'utility-types';

import { LockIcon, ShieldIcon } from '~/components/Icons';
import type { CustomIconProps } from '~/components/Icons/customIconProps';
import { TrailerDoorStatusKeys } from '~/services/ApiClient';

import type { SecondaryDoorStatusClassKey } from './styles';

export interface IconFormatterProps {
    value: string[];
    withAvatar?: boolean;
    iconProps?: Subtract<CustomIconProps, { ref?: unknown }>;
}

export interface IconFormatterInnerProps extends IconFormatterProps, WithStyles<SecondaryDoorStatusClassKey> {}

export const IconFormatterComponent: React.SFC<IconFormatterInnerProps> = ({
    value,
    iconProps,
    classes,
    withAvatar,
}) => {
    const renderContent = (TheIcon: React.ComponentType<CustomIconProps>, id: string) => {
        const component = <TheIcon className={withAvatar ? classes.doorStatusBadge : ''} {...iconProps} />;
        return withAvatar ? (
            <Avatar data-id={`${id}-avatar`} className={classes.badgeBackground}>
                {component}
            </Avatar>
        ) : (
            component
        );
    };

    if (value.includes(TrailerDoorStatusKeys.SEALED)) {
        return renderContent(ShieldIcon, 'sealed');
    }

    if (value.includes(TrailerDoorStatusKeys.LOCKED)) {
        return renderContent(LockIcon, 'locked');
    }

    return null;
};
