import type { AssetReference } from '~/services/ApiClient';

import type { AssetNode, GroupNode } from '../models';

export const groupHasCheckedChildren = (
    selectedAllAssets: boolean,
    groupNodes: GroupNode[],
    assetNodes: AssetNode[]
): boolean => {
    if (selectedAllAssets) {
        return true;
    }

    return (
        assetNodes.some(({ state }) => state.checked) ||
        groupNodes.some(({ state }) => state.checked || state.indeterminate)
    );
};

export const groupIsIndeterminate = (
    selectedAllAssets: boolean,
    hasCheckedChildren: boolean,
    groupNodes: GroupNode[],
    assetNodes: AssetNode[]
): boolean => {
    if (selectedAllAssets || !hasCheckedChildren) {
        return false;
    }

    return assetNodes.some(({ state }) => !state.checked) || groupNodes.some(({ state }) => !state.checked);
};

export const groupHasAssetChildren = (assetNodes: AssetNode[], groupNodes: GroupNode[]): boolean =>
    !!assetNodes.length || groupNodes.some(({ state }) => state.hasAssetChildren);

export const areAllEntitiesExpanded = (allEntitiesIds: number[], expandedEntitiesIds: number[]): boolean =>
    allEntitiesIds.every((id) => expandedEntitiesIds.includes(id));

export const depotHasCheckedChildren = (selectedAllAssets: boolean, assetNodes: AssetNode[]): boolean => {
    if (selectedAllAssets) {
        return true;
    }

    return assetNodes.some(({ state }) => state.checked);
};

export const depotIsIndeterminate = (
    selectedAllAssets: boolean,
    hasCheckedChildren: boolean,
    assetNodes: AssetNode[]
): boolean => {
    if (selectedAllAssets || !hasCheckedChildren) {
        return false;
    }

    return assetNodes.some(({ state }) => !state.checked);
};

export const areAllAssetsSelected = (
    assetIds: AssetReference[],
    selectedAssetIds: AssetReference[] | undefined
): boolean => !selectedAssetIds || assetIds.every((id) => selectedAssetIds.includes(id));
