import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { featureFlagsReducer as localFeatureFlagsReducer } from './reducers';

export * from './components/withFeatureFlagInterceptor';
export * from './components/withFeatureFlags';
export * from './models';
export * from './data/actions';
export * from './hooks';
export type { FeatureFlagsStoreState } from './reducers';

export const featureFlagsReducer = persistReducer(
    {
        key: 'featureFlags',
        storage: storageSession,
        whitelist: ['enabledFeatures'],
    },
    localFeatureFlagsReducer
);
