import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { PositionGroupMarkerInnerProps, PositionGroupMarkerOuterProps } from './component';
import { PositionGroupMarkerComponent } from './component';
import { PositionGroupMarkerStyleRules } from './styles';

export type { PositionGroupMarkerClassKey } from './styles';

export const PositionGroupMarker = compose<PositionGroupMarkerInnerProps, PositionGroupMarkerOuterProps>(
    setDisplayName('PositionGroupMarker'),
    withStyles(PositionGroupMarkerStyleRules, { withTheme: true })
)(PositionGroupMarkerComponent);
