import type { AssetGroup, AssetReference } from '~/services/ApiClient';

import type { AssetReferenceHash } from '../models';

import { getOwnAssetGroupAssetIds } from './assetgroups';

export const ensureSelectedParentGroupId = (
    assetGroups: AssetGroup[],
    assetIds: AssetReferenceHash<AssetReference>,
    selectedAssetIds: AssetReference[],
    selectedParentGroupId: number | undefined,
    singleSelectionMode: boolean | undefined
): number | undefined => {
    if (singleSelectionMode && selectedAssetIds.length) {
        const [selectedAssetId] = selectedAssetIds;

        const flattenedAgs: AssetGroup[] = [];

        const h = (ags: AssetGroup[]) => {
            flattenedAgs.push(...ags);
            ags.forEach((ag) => {
                if (ag.assetGroups) {
                    h(ag.assetGroups);
                }
            });
        };
        h(assetGroups);

        const assetGroupsWithSelectedAsset = flattenedAgs.filter(
            (ag) => getOwnAssetGroupAssetIds(ag, assetIds)[selectedAssetId.type]?.[selectedAssetId.id]
        );
        if (assetGroupsWithSelectedAsset.find((ag) => ag.id === selectedParentGroupId)) {
            return selectedParentGroupId;
        } else {
            return assetGroupsWithSelectedAsset[0].id;
        }
    }

    return undefined;
};
