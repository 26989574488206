import { formatDistanceToNowStrict as _formatDistanceToNowStrict } from 'date-fns';

import { isUndefined } from '~/libs/utility';
import { getLocale } from '~/services/LocalProvider';

const formatDistanceToNowStrict = (language: string, dateTime?: Date) => {
    if (isUndefined(dateTime)) {
        return '-';
    }

    return _formatDistanceToNowStrict(dateTime, { addSuffix: true, locale: getLocale(language) });
};

export { formatDistanceToNowStrict };
