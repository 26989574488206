import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';

import type {
    VehicleTypesAdministrationListViewInnerProps,
    VehicleTypesAdministrationListViewProps,
} from './component';
import { VehicleTypesAdministrationListViewComponent } from './component';
import { mapStateToProps } from './redux';

export const VehicleTypesAdministrationListView = compose<
    VehicleTypesAdministrationListViewInnerProps,
    VehicleTypesAdministrationListViewProps
>(
    setDisplayName('VehicleTypesAdministrationListView'),
    withDisplayPreferences(),
    connect(
        createSelector(
            settingDataSelector(SettingsKey.VEHICLE_TYPE_CATEGORIES),
            settingDataSelector(SettingsKey.AXLES),
            settingDataSelector(SettingsKey.EMISSION_CLASS),
            mapStateToProps
        ),
        undefined
    )
)(VehicleTypesAdministrationListViewComponent);
