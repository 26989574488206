import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const SendIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </SvgIcon>
    );
};
SendIcon.displayName = 'SendIcon';

export { SendIcon };
