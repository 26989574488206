import { keyBy } from '~/libs/utility';

import { GridSortingDirection } from './component';
import { getRawCellValue } from './getRawCellValue';
import type { GridColumnDefinition, GridSorting } from './models';

export const sortDataSource = <T>(
    dataSource: T[],
    columnDefinitions: Array<GridColumnDefinition<T>>,
    sorting: GridSorting[]
): T[] => {
    const columnDefinitionsByKey = keyBy(columnDefinitions, 'name');

    if (sorting.some((s) => !columnDefinitionsByKey[s.columnName])) {
        return dataSource;
    }

    const sortMultiple = (a: T, b: T, nextSortOrder: number): number => {
        const sortColumn = sorting[nextSortOrder];
        const nextSortOrderNumber = nextSortOrder + 1;

        const columnDefinition = columnDefinitionsByKey[sortColumn.columnName];
        const compareModifier = sortColumn.direction === GridSortingDirection.ASC ? 1 : -1;

        const value =
            columnDefinition.compare(getRawCellValue(columnDefinition, a), getRawCellValue(columnDefinition, b)) *
            compareModifier;

        if (value === 0 && sorting.length > nextSortOrderNumber) {
            return sortMultiple(a, b, nextSortOrderNumber);
        }

        return value;
    };

    return [...dataSource].sort((a, b) => sortMultiple(a, b, 0));
};
