import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { RtdsSchedulesContextMenuInnerProps, RtdsSchedulesContextMenuProps } from './component';
import { RtdsSchedulesContextMenuComponent } from './component';

export const RtdsSchedulesContextMenu = compose<RtdsSchedulesContextMenuInnerProps, RtdsSchedulesContextMenuProps>(
    setDisplayName('RtdsScheduleContextMenu'),
    withTranslation()
)(RtdsSchedulesContextMenuComponent);
