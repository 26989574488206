import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { GeneralSectionInnerProps, GeneralSectionProps } from './component';
import { GeneralSectionComponent } from './component';

export const GeneralSection = compose<GeneralSectionInnerProps, GeneralSectionProps>(
    setDisplayName('GeneralSection'),
    withTranslation()
)(GeneralSectionComponent);
