import type { Reducer } from 'redux';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export interface SynchronizationClockState {
    timestamp: number;
}

const defaultStoreState: SynchronizationClockState = {
    timestamp: new Date().getTime(),
};

export const synchronizationClockReducer: Reducer<SynchronizationClockState> = (
    state: SynchronizationClockState = defaultStoreState,
    action: ActionTypes
): SynchronizationClockState => {
    switch (action.type) {
        case ActionTypeKeys.SYNCHRONIZATIONCLOCK_UPDATE:
            return {
                ...state,
                timestamp: action.payload,
            };

        default:
            return state;
    }
};
