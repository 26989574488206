import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export type TextBadgeClassKeys = 'root' | 'success' | 'warning' | 'error' | 'disabled';

export const useStyles = makeStyles<Theme, {}, TextBadgeClassKeys>((theme: Theme) => ({
    root: {
        borderRadius: 3,
        padding: theme.spacing(0.25, 0.5),
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
    },
    warning: {
        backgroundColor: theme.palette.warning2.main,
        color: theme.palette.common.white,
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
    },
    disabled: {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.text.disabled,
    },
}));
