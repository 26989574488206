import type { Action } from 'redux';

import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';

import type { VehicleTypeChangelogsStoreState } from './components/DetailsPane/components/VehicleTypesAdministrationChangelogsSubpage';
import { vehicleTypeChangelogsReducer } from './components/DetailsPane/components/VehicleTypesAdministrationChangelogsSubpage/reducers';
import type { AdminVehicleTypesStoreState } from './reducers';
import { adminVehicleTypeReducer } from './reducers';

export interface AdminVehicleTypeSceneStoreState {
    adminVehicleTypeStoreState: AdminVehicleTypesStoreState;
    vehicleTypeAdministrationChangelogsRoot: VehicleTypeChangelogsStoreState;
}

export const adminVehicleTypeSceneReducer = (
    state: AdminVehicleTypeSceneStoreState | undefined,
    action: Action,
    settingsStoreState: SettingsStoreState
): AdminVehicleTypeSceneStoreState => {
    const vehicleTypesAdministration = adminVehicleTypeReducer(
        state?.adminVehicleTypeStoreState,
        action,
        settingsStoreState
    );

    const vehicleTypesAdministrationChangelogsRoot = vehicleTypeChangelogsReducer(
        state?.vehicleTypeAdministrationChangelogsRoot,
        action,
        settingsStoreState
    );

    return {
        adminVehicleTypeStoreState: vehicleTypesAdministration,
        vehicleTypeAdministrationChangelogsRoot: vehicleTypesAdministrationChangelogsRoot,
    };
};
