import { compose, setDisplayName } from 'react-recompose';

import { protectedComponentHoc } from '~/components/AuthenticationData';
import { SettingsKey, ensureSettings } from '~/components/EnsureSettings';
import { withFeatureFlags } from '~/components/FeatureFlags';

import { LandingPageComponent } from './component';

export const LandingPage = compose(
    setDisplayName('LandingPage'),
    protectedComponentHoc,
    withFeatureFlags(),
    ensureSettings([SettingsKey.SECURABLES])
)(LandingPageComponent);
