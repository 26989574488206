import type { ChangelogEntry } from '~/common';
import { isNil, isUndefined } from '~/libs/utility';
import { AssetType } from '~/services/ApiClient';
import { formatContainerName, formatDriverName, formatTrailerName, formatVehicleName } from '~/services/Formatters';

import type {
    GetFormattedAssetProps,
    ParseDeviceAdminChangelogEntriesProps,
    ResolveDeviceChangelogChangesProps,
    ResolvedDeviceChangelogChanges,
} from './models';

const transformDeviceHardware = (version: number | undefined) => {
    const [integer, decimals] = !isNil(version) ? (version / 100).toFixed(2).split('.') : [undefined, undefined];

    return integer && decimals ? Number(`${integer}.${Math.ceil(+decimals)}`).toFixed(1) : undefined;
};

const getFormattedAsset = ({ id, type, staticData, displayUserPreferences }: GetFormattedAssetProps) => {
    const assetTypeMap = {
        [AssetType.Container]: staticData.containers.data.hash[id]
            ? formatContainerName(staticData.containers.data.hash[id])
            : undefined,
        [AssetType.Driver]: staticData.drivers.data.hash[id]
            ? formatDriverName(staticData.drivers.data.hash[id], displayUserPreferences.driverDisplayFormat)
            : undefined,
        [AssetType.Trailer]: staticData.trailers.data.hash[id]
            ? formatTrailerName(staticData.trailers.data.hash[id], displayUserPreferences.trailerDisplayFormat)
            : undefined,
        [AssetType.Vehicle]: staticData.vehicles.data.hash[id]
            ? formatVehicleName(staticData.vehicles.data.hash[id], displayUserPreferences.vehicleDisplayFormat)
            : undefined,
    };

    return assetTypeMap[type];
};

const resolveDeviceChangelogChanges = ({
    changes,
    staticData,
    settings,
    displayUserPreferences,
}: ResolveDeviceChangelogChangesProps): ResolvedDeviceChangelogChanges => {
    const networkId = changes?.networkId?.toString();
    const billingGroup = changes?.billingGroup?.toString();
    const billingStatus = changes?.billingStatus?.toString();
    const assetReference = changes?.assetReference;

    return {
        msisdn: changes?.msisdn,
        serialNumber: changes?.serialNumber,
        isEnabled: changes?.isEnabled,
        isResponseEnabled: changes?.isResponseEnabled,
        source: changes?.source,
        networkId: networkId ? settings.network.data[networkId] : undefined,
        iccid: changes?.iccid,
        billingGroup: billingGroup ? settings.billingGroup.data[billingGroup] : undefined,
        billingStatus: billingStatus ? settings.billingStatus.data[billingStatus] : undefined,
        prepaidUntil: changes?.prepaidUntil,
        assetReference: isUndefined(assetReference)
            ? undefined
            : getFormattedAsset({ ...assetReference, staticData, displayUserPreferences }),
    };
};

const parseDeviceAdminChangelogEntries = ({
    changelogs,
    staticData,
    settings,
    displayUserPreferences,
}: ParseDeviceAdminChangelogEntriesProps): ChangelogEntry<ResolvedDeviceChangelogChanges>[] =>
    changelogs.map(({ before, after, ...changelog }) => ({
        ...changelog,
        before: resolveDeviceChangelogChanges({ changes: before, staticData, settings, displayUserPreferences }),
        after: resolveDeviceChangelogChanges({ changes: after, staticData, settings, displayUserPreferences }),
    }));

export { transformDeviceHardware, getFormattedAsset, parseDeviceAdminChangelogEntries, resolveDeviceChangelogChanges };
