import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { LatestMessageInnerProps, LatestMessageProps } from './component';
import { LatestMessageComponent } from './component';
import { LatestMessageStyleRules } from './styles';

export const LatestMessage = compose<LatestMessageInnerProps, LatestMessageProps>(
    setDisplayName('LatestMessage'),
    withStyles(LatestMessageStyleRules),
    withTranslation()
)(LatestMessageComponent);
