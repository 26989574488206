import type { StyledComponentProps, WithStyles } from '@mui/styles';

import type { MiniSettingsPanelClassKeys } from './styles';

export interface SettingItem<T extends SettingItemType> {
    key: string;
    title: string;
    settingType: T;
}

export enum SettingItemType {
    Boolean = 'boolean',
    NumericMagnitude = 'numeric-magnitude',
    DateTime = 'datetime',
}

export enum BooleanFieldType {
    ToggleSwitch = 'toggle-switch',
    Checkbox = 'checkbox',
}

export interface BooleanSettingItem extends SettingItem<SettingItemType.Boolean> {
    key: string;
    title: string;
    value: boolean;
    onValueChange: () => void;
    type: BooleanFieldType;
}

export interface NumericMagnitudeSettingItem extends SettingItem<SettingItemType.NumericMagnitude> {
    value?: number;
    unit: string;
    isRequired?: boolean;
    onValueChange: (value?: number) => void;
    minValue?: number;
    maxValue?: number;
    errorText?: string;
}

export interface DateTimeSettingItem extends SettingItem<SettingItemType.DateTime> {
    value?: Date;
    isRequired?: boolean;
    onValueChange: (value?: Date) => void;
    minDate?: Date;
    maxDate?: Date;
    disablePastDatesInPicker?: boolean;
    disableFutureDatesInPicker?: boolean;
    errorText?: string;
}

export type CustomSettingItemTypes = BooleanSettingItem | NumericMagnitudeSettingItem | DateTimeSettingItem;

export interface MiniSettingsPanelProps {
    settings: CustomSettingItemTypes[];
    collapsable?: boolean;
}

export interface MiniSettingsPanelOuterProps
    extends MiniSettingsPanelProps,
        StyledComponentProps<MiniSettingsPanelClassKeys> {}

export interface MiniSettingsPanelInnerProps extends MiniSettingsPanelProps, WithStyles<MiniSettingsPanelClassKeys> {}
