import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import * as React from 'react';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { useTranslation } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import type { ResolvedVehicleType } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { SectionName } from '../../constants';

export interface TypeSectionProps {
    adminVehicleType: ResolvedVehicleType;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

export interface TypeSectionInnerProps extends TypeSectionProps {}

const TypeSection: React.FunctionComponent<TypeSectionInnerProps> = ({
    adminVehicleType,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
}) => {
    const { t } = useTranslation();
    const keyValueItems: KeyValueItem[] = [
        {
            valueDataId: 'type',
            label: t('type'),
            value: adminVehicleType.type ? (
                <span>{formatClassification(t, ClassificationType.VehicleTypeCategory, adminVehicleType.type)}</span>
            ) : undefined,
        },
        {
            valueDataId: 'axles',
            label: t('axles'),
            value: adminVehicleType.axles ? (
                <span>{formatClassification(t, ClassificationType.Axles, adminVehicleType.axles)}</span>
            ) : undefined,
        },
        {
            valueDataId: 'emission-class',
            label: t('emission-class'),
            value: adminVehicleType.emissionClass ? (
                <span>{formatClassification(t, ClassificationType.EmissionClass, adminVehicleType.emissionClass)}</span>
            ) : undefined,
        },
        {
            valueDataId: 'hazardous-goods',
            label: t('hazardous-goods'),
            value: adminVehicleType.hazardousGoods ? (
                <CheckIcon data-id="true" />
            ) : (
                <HighlightOffIcon data-id="false" />
            ),
        },
    ];

    return (
        <ReorderableSection
            dataId="vehicle-administration-type-section"
            title={t(SectionName.VEHICLETYPESADMINISTRATION_TYPE)}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="type-list" />
        </ReorderableSection>
    );
};
TypeSection.displayName = 'TypeSection';

export { TypeSection };
