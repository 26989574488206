import type { ComponentType } from 'react';

import type { Impersonator } from '~/common/models';
import type { ChangelogAction } from '~/services/ApiClient';

export interface ChangelogEntry<T> {
    dateTime: Date;
    username?: string;
    impersonator?: Impersonator;
    action: ChangelogAction;
    before: T;
    after: T;
    id: string;
}

export interface ChangelogAttributeDefinition<T, V = unknown> {
    title: string;
    valueFormatterComponent?: ComponentType<{ value?: unknown }>;
    getValue: (item: T) => V;
    equalityComparer?: (a: V, b: V) => boolean;
    RowComponent?: ComponentType<ChangelogChangesTableRowProps<T, V>>;
}

export interface ChangelogChangesTableRowProps<T, V> {
    changelog: ChangelogEntry<T>;
    attributeDefinition: ChangelogAttributeDefinition<T, V>;
}

export interface ChangelogSubpageProps<T> {
    changelogs: ChangelogEntry<T>[] | Promise<ChangelogEntry<T>[]>;
    attributeDefinitions: ChangelogAttributeDefinition<T>[];
    changelogSubpageTitle: string;
    entityType: ChangelogEntityType;
}

export interface DictionaryChangelogsDay<T> {
    [key: string]: ChangelogEntry<T>[];
}

export enum ChangelogEntityType {
    RTDS_SCHEDULE = 'rtds-schedule',
    VEHICLE_ADMIN = 'vehicle-admin',
    DRIVER_ADMIN = 'driver-admin',
    VEHICLE_TYPE_ADMIN = 'vehicle-type-admin',
    DEVICE_ADMIN = 'device-admin',
    TRAILER_ADMIN = 'trailer-admin',
    ASSET_GROUP_ADMIN = 'asset-group-admin',
    USER_ROLE = 'user-role',
}

export interface GetSectionsArgs<T> {
    entityType: ChangelogEntityType;
    changelogs: ChangelogEntry<T>[];
    attributeDefinitions: ChangelogAttributeDefinition<T>[];
}
