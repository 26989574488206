import { compose, setDisplayName } from 'react-recompose';

import { withSceneAnalytics } from '~/components/Analytics';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { TemperaturesGraphDialogInnerProps, TemperaturesGraphDialogProps } from './component';
import { TemperaturesGraphDialogComponent } from './component';

export const TemperaturesGraphDialog = compose<TemperaturesGraphDialogInnerProps, TemperaturesGraphDialogProps>(
    setDisplayName('TemperaturesGraphDialog'),
    withSceneAnalytics('temperaturesgraph'),
    withTranslation(),
    withDisplayPreferences()
)(TemperaturesGraphDialogComponent);
