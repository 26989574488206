import type {
    ConversationMessageCorrelationRef,
    ResolvedConversation,
    ResolvedConversationUpdate,
} from '~/services/ApiClient';

import { mergeConversationMessages } from './reducers.mergeConversationMessages';

export const applyConversationUpdate = (
    conversation: ResolvedConversation,
    conversationUpdate: ResolvedConversationUpdate,
    correlationRefs?: ConversationMessageCorrelationRef[]
): ResolvedConversation => {
    return {
        ...conversation,
        revision: Math.max(conversationUpdate.revision, conversation.revision),
        unreadTextMessageCount:
            conversationUpdate.revision > conversation.revision
                ? conversationUpdate.unreadTextMessageCount
                : conversation.unreadTextMessageCount,
        unreadWorkflowMessageCount:
            conversationUpdate.revision > conversation.revision
                ? conversationUpdate.unreadWorkflowMessageCount
                : conversation.unreadWorkflowMessageCount,
        messages: mergeConversationMessages(conversation, conversationUpdate, correlationRefs),
    };
};
