import { translateClassification } from '@fv/translations';
import type { TFunction } from 'react-i18next';

import { mapEntryToClassification } from '~/common/utils';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { StoreState } from '~/reducers';
import { ClassificationType } from '~/services/ApiClient';
import { stringComparer } from '~/services/Sorting';

const geozoneAdminSelector = (state: StoreState) => state.geozoneAdministrationScene;

interface EditorDialogSelectorArgs {
    t: TFunction;
    geozoneId?: string;
}

const editorDialogSelector =
    ({ t, geozoneId }: EditorDialogSelectorArgs) =>
    (state: StoreState) => ({
        geozoneNames: state.geozoneAdministrationScene.items
            .filter((item) => item.id !== geozoneId)
            .map((item) => item.name),
        classificationType: mapEntryToClassification({
            values: Object.values(settingDataSelector(SettingsKey.GEOZONE_TYPE)(state)),
            displayName: (value) => {
                return translateClassification({
                    classification: value,
                    classificationKey: ClassificationType.GeozoneType,
                    t,
                });
            },
        }).sort((a, b) => stringComparer(a.displayName, b.displayName)),
    });

const categoriesSelector = (state: StoreState) => state.geozoneAdministrationScene.categories;

export { geozoneAdminSelector, editorDialogSelector, categoriesSelector };
