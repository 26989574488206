import { createAction } from 'redux-actions';

import type { VehicleType } from '~/services/ApiClient';
import {
    ApiClient,
    ApiException,
    SaveAdminVehicleTypeRequest,
    ServerResultStatus,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const getVehicleTypeChangelogsAction = createAction(
    ActionTypePrefixes.ADMINVEHICLETYPECHANGELOGS_GET,
    (vehicleTypeId: number) => retryableRequest(() => ApiClient.getVehicleTypeChangelogs(vehicleTypeId)),
    (vehicleTypeId: number) => vehicleTypeId
);

const updateAdminVehicleTypeExecutor = async (vehicleType: VehicleType): Promise<ServerResultStatus> => {
    try {
        await ApiClient.updateAdminVehicleType(
            vehicleType.id,
            createApiModel(SaveAdminVehicleTypeRequest, { item: vehicleType })
        );
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

export const updateAdminVehicleTypeAction = createAction(
    ActionTypePrefixes.VEHICLETYPE_UPDATE,
    updateAdminVehicleTypeExecutor
);

export const createAdminVehicleTypeExecutor = async (item: VehicleType): Promise<ServerResultStatus> => {
    try {
        await ApiClient.createAdminVehicleType(createApiModel(SaveAdminVehicleTypeRequest, { item }));
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

export const updateAdminVehicleTypeResetAction = createAction(ActionTypeKeys.VEHICLETYPE_UPDATE_RESET);

const deleteAdminVehicleTypeExecutor = async (vehicleTypeId: number): Promise<ServerResultStatus> => {
    await ApiClient.deleteAdminVehicleType(vehicleTypeId);
    return ServerResultStatus.OK;
};

export const deleteAdminVehicleTypeAction = createAction(
    ActionTypePrefixes.VEHICLETYPE_DELETE,
    deleteAdminVehicleTypeExecutor
);

export const deleteAdminVehicleTypeResetAction = createAction(ActionTypeKeys.VEHICLETYPE_DELETE_RESET);
export const createAdminVehicleTypeAction = createAction(
    ActionTypePrefixes.VEHICLETYPE_CREATE,
    createAdminVehicleTypeExecutor
);
export const createAdminVehicleTypeResetAction = createAction(ActionTypeKeys.VEHICLETYPE_CREATE_RESET);
