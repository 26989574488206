import type { AssetGroup } from '~/services/ApiClient';

export const getVehicleAssetGroups = (selectedVehicleId: number, assetGroups: AssetGroup[]): string[] =>
    assetGroups.reduce((vehicleAssetGroups: string[], assetGroup) => {
        if (assetGroup.vehicleIds && assetGroup.vehicleIds.includes(selectedVehicleId)) {
            vehicleAssetGroups.push(assetGroup.name);
        }

        if (assetGroup.assetGroups) {
            vehicleAssetGroups.push(...getVehicleAssetGroups(selectedVehicleId, assetGroup.assetGroups));
        }

        return vehicleAssetGroups;
    }, []);
