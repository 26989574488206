import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { TitledTruncatedTextInnerProps, TitledTruncatedTextProps } from './component';
import { TitledTruncatedTextComponent } from './component';
import { TitledTruncatedTextStyleRules } from './styles';

export const TitledTruncatedText = compose<TitledTruncatedTextInnerProps, TitledTruncatedTextProps>(
    setDisplayName('TitledTruncatedText'),
    withStyles(TitledTruncatedTextStyleRules)
)(TitledTruncatedTextComponent);
