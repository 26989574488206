import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { persistStoreStateSelector } from '~/selectors';

import type {
    PersistStoreDispatchProps,
    PersistStoreInnerProps,
    PersistStoreProps,
    PersistStoreStateProps,
} from './component';
import { PersistStoreComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export * from './reducers';

export const PersistStore = compose<PersistStoreInnerProps, PersistStoreProps>(
    connect<PersistStoreStateProps, PersistStoreDispatchProps>(
        createSelector(persistStoreStateSelector, mapStateToProps),
        mapDispatchToProps
    )
)(PersistStoreComponent);
