import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withAppVersion } from '~/components/AppVersion';
import { withUserData } from '~/components/AuthenticationData';
import { withTranslation } from '~/components/LanguageSelector';

import { overlayMenuStateSelector } from '../../selectors';

import type { OverlayMenuInnerProps, OverlayMenuProps, StateProps } from './component';
import { OverlayMenuComponent } from './component';
import { mapStateToProps } from './redux';
import { OverlayMenuStyleRules } from './styles';

export * from './reducers';

export const OverlayMenu = compose<OverlayMenuInnerProps, OverlayMenuProps>(
    setDisplayName('OverlayMenu'),
    withUserData(),
    connect<StateProps>(createSelector(overlayMenuStateSelector, mapStateToProps)),
    withTranslation(),
    withStyles(OverlayMenuStyleRules, { withTheme: true }),
    withAppVersion()
)(OverlayMenuComponent);
