import type { DisplayUserPreferences } from '~/components/DisplayPreferences';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import { filterBySearchQuery } from '~/services/Filter';
import { formatDriverName, formatTrailerName, formatVehicleName } from '~/services/Formatters';

export const createFilter =
    (displayPreferences: DisplayUserPreferences) =>
    (vehicleEntries: MonitoringVehicleEntry[], searchQuery: string): MonitoringVehicleEntry[] => {
        const textAccessors = (entry: MonitoringVehicleEntry): Array<string | undefined> => {
            const trailer = entry.trailerConnection?.trailerReference;
            const driver = entry.vehicleDriverHoursStatus?.driverHoursStatus?.driver;
            const coDriver = entry.vehicleDriverHoursStatus?.coDriverHoursStatus?.driver;
            return [
                formatVehicleName(entry.vehicle, displayPreferences.vehicleDisplayFormat),
                trailer && formatTrailerName(trailer, displayPreferences.trailerDisplayFormat),
                driver && formatDriverName(driver, displayPreferences.driverDisplayFormat),
                coDriver && formatDriverName(coDriver, displayPreferences.driverDisplayFormat),
            ];
        };

        return filterBySearchQuery(vehicleEntries, textAccessors, searchQuery);
    };
