import { subDays } from 'date-fns';
import { createAction } from 'redux-actions';

import type { DriverActivitiesNotification, DriverStatusNotification } from '~/services/ApiClient';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { DriverActivitiesPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const retrieveDriverActivitiesExecutor = (
    vehicleId: number,
    driverId: number
): Promise<DriverActivitiesPayload> => {
    return retryableRequest(() => ApiClient.getDriverActivities(vehicleId, driverId));
};

export const retrieveDriverActivitiesAction = createAction<Promise<DriverActivitiesPayload>, number, number, number>(
    ActionTypePrefixes.DRIVERACTIVITIES,
    retrieveDriverActivitiesExecutor,
    (_: number, driverId: number) => driverId
);

export const retrieveDriverActivitiesByIdExecutor = (driverId: number): Promise<DriverActivitiesPayload> => {
    const now = new Date();
    return retryableRequest(() => ApiClient.getDriverActivitiesByDriverId(driverId, subDays(now, 2), now));
};

export const retrieveDriverActivitiesByIdAction = createAction<Promise<DriverActivitiesPayload>, number, number>(
    ActionTypePrefixes.DRIVERACTIVITIES,
    retrieveDriverActivitiesByIdExecutor,
    (driverId: number) => driverId
);

export const clearDriverActivitiesAction = createAction<undefined, number, number>(
    ActionTypeKeys.DRIVERACTIVITIES_CLEAR,
    () => undefined,
    (driverId: number) => driverId
);

export const updateDriverActivitiesAction = createAction<
    DriverActivitiesNotification | DriverStatusNotification,
    number,
    number,
    DriverActivitiesNotification | DriverStatusNotification
>(
    ActionTypeKeys.DRIVERACTIVITIES_UPDATE,
    (_: number, notification: DriverActivitiesNotification | DriverStatusNotification) => notification,
    (driverId: number) => driverId
);

export const applyDriverActivitiesUpdatesAction = createAction<undefined, number, number>(
    ActionTypeKeys.DRIVERACTIVITIES_APPLY_PENDING,
    () => undefined,
    (driverId: number) => driverId
);
