import RefreshIcon from '@mui/icons-material/Refresh';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { ModeToggleAllIcon, ModeToggleMostRecentIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps } from '~/components/SceneList';
import { SceneListViewHeaderFactory } from '~/components/SceneList';
import { TitledIconButton } from '~/components/TitledIconButton';
import { QueryRtdsSessionsRequestRequestMode } from '~/services/ApiClient';

import type { RtdsSessionEntry } from '../../../../models';
import { filterDataSourceMemoized, getColumnsMemoized } from '../../grid';
import { RTDSSESSIONS_LIST_USERPREFERENCES_KEY, defaultRtdsSessionsListUserPreferences } from '../../preferences';

import { logEventWhenEnablingColumns } from './logEventWhenEnablingColumns';

const HeaderComponent = SceneListViewHeaderFactory<RtdsSessionEntry>(
    RTDSSESSIONS_LIST_USERPREFERENCES_KEY,
    defaultRtdsSessionsListUserPreferences,
    filterDataSourceMemoized,
    'rtds-session'
);

export interface RtdsSessionsListViewHeaderProps
    extends Omit<SceneListViewHeaderProps<RtdsSessionEntry>, 'columns' | 'excelSheetTitle' | 'moduleBarActions'> {
    requestMode: QueryRtdsSessionsRequestRequestMode;
    contentLoading: boolean;
    onRefreshData: () => void;
    onChangeRequestMode: () => void;
}
export interface RtdsSessionsListViewHeaderInnerProps
    extends RtdsSessionsListViewHeaderProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

export const RtdsSessionsListViewHeaderComponent: FC<RtdsSessionsListViewHeaderInnerProps> = ({
    displayPreferences,
    t,
    i18n,
    requestMode,
    onChangeRequestMode,
    contentLoading,
    onRefreshData,
    ...restProps
}) => {
    const columns = getColumnsMemoized(
        t,
        displayPreferences.vehicleDisplayFormat,
        displayPreferences.driverDisplayFormat
    );

    const getModeIcon = useMemo(() => {
        if (requestMode === QueryRtdsSessionsRequestRequestMode.All) {
            return <ModeToggleAllIcon />;
        }

        return <ModeToggleMostRecentIcon />;
    }, [requestMode]);

    const modeToggleButton = useMemo(() => {
        return (
            <TitledIconButton
                title={
                    requestMode === QueryRtdsSessionsRequestRequestMode.All
                        ? t(`mode-toggle-session-most-recent`)
                        : t(`mode-toggle-session-all`)
                }
                placement="bottom-end"
                color="inherit"
                onClick={onChangeRequestMode}
                data-id="change-mode-toggle-session"
                disabled={restProps.gridActionsDisabled}
            >
                {getModeIcon}
            </TitledIconButton>
        );
    }, [getModeIcon, onChangeRequestMode, requestMode, restProps.gridActionsDisabled, t]);
    const refreshDataButton = useMemo(() => {
        return (
            <TitledIconButton
                title={t('refresh')}
                placement="bottom-end"
                color="inherit"
                onClick={onRefreshData}
                data-id="refresh-data-button"
                disabled={restProps.gridActionsDisabled || contentLoading}
            >
                <RefreshIcon />
            </TitledIconButton>
        );
    }, [contentLoading, onRefreshData, restProps.gridActionsDisabled, t]);
    const moduleBarActions = useMemo(
        () => (
            <>
                {modeToggleButton}
                {refreshDataButton}
            </>
        ),
        [modeToggleButton, refreshDataButton]
    );
    return (
        <HeaderComponent
            {...restProps}
            columns={columns}
            moduleBarActions={moduleBarActions}
            excelSheetTitle={t('rtds-sessions')}
            onShowColumns={logEventWhenEnablingColumns}
        />
    );
};
