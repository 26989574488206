import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';

import { withSceneAnalytics } from '~/components/Analytics';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { VehicleDetailsPaneInnerProps, VehicleDetailsPaneProps } from './component';
import { VehicleDetailsPaneComponent } from './component';
import type { HistoryVehicleDetailsPaneUserPreferences } from './preferences';
import {
    HISTORY_DETAILSPANE_VEHICLE_USERPREFERENCES_KEY,
    defaultHistoryVehicleDetailsPaneUserPreferences,
    requiredHistoryVehicleDetailsPaneUserPreferences,
} from './preferences';
import { mapDispatchToProps } from './redux';

export {
    defaultHistoryVehicleDetailsPaneUserPreferences,
    requiredHistoryVehicleDetailsPaneUserPreferences,
    HISTORY_DETAILSPANE_VEHICLE_USERPREFERENCES_KEY,
};

export type DefaultDetailsPaneUserPreferences = HistoryVehicleDetailsPaneUserPreferences;

export const VehicleDetailsPane = compose<VehicleDetailsPaneInnerProps, VehicleDetailsPaneProps>(
    setDisplayName('VehicleDetailsPane'),
    withSceneAnalytics('historyvehicledetailspane'),
    withTranslation(),
    withDisplayPreferences(),
    connect(undefined, mapDispatchToProps)
)(VehicleDetailsPaneComponent);
