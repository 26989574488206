import type { Dispatch } from 'redux';

import { initializeUserPreferencesStateAction } from '~/data/userpreferences';

import type { UserPreferencesState } from '../../reducers';

import type { DispatchProps, StateProps } from './component';

export const mapStateToProps = (userPreferencesState: UserPreferencesState): StateProps => ({
    userPreferencesState,
});

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        initializeUserPreferencesState: (
            key: string,
            value: object,
            processResponse?: (preferences: object) => object
        ) => {
            dispatch(initializeUserPreferencesStateAction(key, value, processResponse));
        },
    };
};
