import { Typography } from '@mui/material';
import type { StyledComponentProps, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import type { SettingItemHeaderClassKeys } from './styles';

export interface SettingItemHeaderProps {
    icon?: JSX.Element;
    title: React.ReactNode;
    description?: React.ReactNode;
    isDisabled?: boolean;
    action?: JSX.Element;
}

export interface SettingItemHeaderOuterProps
    extends SettingItemHeaderProps,
        StyledComponentProps<SettingItemHeaderClassKeys> {
    // If true, the left and right padding is removed.
    disableGutters?: boolean;
}

export interface SettingItemHeaderInnerProps extends SettingItemHeaderProps, WithStyles<SettingItemHeaderClassKeys> {}

export const SettingItemHeaderComponent: React.FC<SettingItemHeaderInnerProps> = (props) => {
    const { icon, title, description, action, isDisabled, classes } = props;

    const descriptionElement = description && (
        <Typography component="div" variant="body2" color="textSecondary" className={classes.description}>
            {description}
        </Typography>
    );

    const rootClassName = classNames(classes.root, {
        [classes.disabled]: isDisabled,
    });

    return (
        <div className={rootClassName}>
            {icon && <div className={classes.icon}>{icon}</div>}

            <div className={classes.body}>
                <Typography component="div" variant="body2" color="textPrimary" className={classes.title}>
                    {title}
                </Typography>

                {descriptionElement}
            </div>

            {action && <div className={classes.action}>{action}</div>}
        </div>
    );
};
