import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type HeaderInfoClassKey = 'reeferStatuses' | 'reeferPercentage';

export const HeaderInfoStyleRules: StyleRulesCallback<Theme, {}, HeaderInfoClassKey> = (theme: Theme) =>
    createStyles({
        reeferStatuses: {
            display: 'flex',
            height: '20px',
            '&>*': {
                marginLeft: theme.spacing(1),
            },
        },
        reeferPercentage: {
            marginTop: theme.spacing(0.125),
        },
    });
