import _ChevronRight from '@mui/icons-material/ChevronRight';
import _ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import _Switch from '@mui/material/Switch';
import _Typography from '@mui/material/Typography';

interface ContainerProps {
    paddingLeft?: number;
    isLeaf: boolean;
}

const Container = styled('div')<ContainerProps>(({ theme, isLeaf, paddingLeft }) => ({
    paddingLeft: !paddingLeft ? theme.spacing() : isLeaf ? theme.spacing(7.25) : theme.spacing(4.25),
    paddingTop: !paddingLeft ? '0px' : isLeaf ? '0px' : theme.spacing(0.5),
}));

const ActionContainer = styled('div')({ display: 'flex', alignItems: 'center' });
const ExpandMore = styled(_ChevronRight)({ ':hover': { cursor: 'pointer', backgroundColor: 'whitespace' } });
const ExpandLess = styled(_ExpandMore)({ ':hover': { cursor: 'pointer', backgroundColor: 'whitespace' } });
const Switch = styled(_Switch)({ marginLeft: 'auto', marginRight: 0 });
const Typography = styled(_Typography)({ whiteSpace: 'nowrap', paddingLeft: '10px', paddingBottom: '2px' });

export { ActionContainer, Container, Typography, ExpandMore, ExpandLess, Switch };
