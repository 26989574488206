import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { MiniSettingsPanelComponent } from './component';
import type { MiniSettingsPanelInnerProps, MiniSettingsPanelOuterProps } from './models';
import { MiniSettingsPanelStyleRules } from './styles';

export * from './models';

export const MiniSettingsPanel = compose<MiniSettingsPanelInnerProps, MiniSettingsPanelOuterProps>(
    setDisplayName('MiniSettingsPanel'),
    withStyles(MiniSettingsPanelStyleRules)
)(MiniSettingsPanelComponent);
