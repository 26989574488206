import { Avatar, Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { ElapsedTime } from '~/components/ElapsedTime';
import { ClassificationFormatter, CompartmentStatusFormatter, TemperatureFormatter } from '~/components/Formatters';
import { ClockIcon, ReturnIcon, SetpointIcon, SupplyIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { ResolvedCompartmentStatus } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';

import { TemperatureSensor } from './components/TemperatureSensor';
import type { CompartmentSubSectionContentClassKey } from './styles';

export interface CompartmentSubSectionContentProps {
    compartmentStatus?: ResolvedCompartmentStatus;
    onClick?: () => void;
}

export interface CompartmentSubSectionContentInnerProps
    extends CompartmentSubSectionContentProps,
        WithStyles<CompartmentSubSectionContentClassKey>,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

export const CompartmentSubSectionContentComponent: React.FC<CompartmentSubSectionContentInnerProps> = ({
    compartmentStatus,
    classes,
    onClick,
    t,
    displayPreferences,
}) => {
    const sensors = Object.values(compartmentStatus?.temperatureSensors || {}).map((s) => {
        return (
            <TemperatureSensor
                key={s.sensorNumber}
                temperatureSensor={s}
                minTemperature={compartmentStatus?.minTemperature}
                maxTemperature={compartmentStatus?.maxTemperature}
                unitSystem={displayPreferences.unitSystem}
                dataId={`temperature-${s.sensorNumber}`}
            />
        );
    });

    const formatOptionalTemperature = React.useCallback(
        (temperature?: number) => {
            if (isUndefined(temperature)) {
                return t('not-available');
            }

            return <TemperatureFormatter unitSystem={displayPreferences.unitSystem} value={temperature} t={t} />;
        },
        [displayPreferences.unitSystem, t]
    );

    const statusElement = compartmentStatus?.status && (
        <Avatar className={classes.avatar}>
            <CompartmentStatusFormatter value={compartmentStatus.status} t={t} />
        </Avatar>
    );
    const statusText = compartmentStatus?.status ? (
        <ClassificationFormatter
            classificationKey={ClassificationType.CompartmentStatus}
            value={compartmentStatus.status}
            t={t}
        />
    ) : (
        t('not-available')
    );

    const sensorsElement = sensors.length > 0 && (
        <div className={classNames(classes.sensorRow, classes.flexRow)} onClick={onClick}>
            {sensors}
        </div>
    );

    const timestamp = compartmentStatus ? (
        <ElapsedTime icon={ClockIcon} since={compartmentStatus.dateTime} />
    ) : (
        <span data-id="timestamp">{t('not-available')}</span>
    );

    return (
        <div className={classes.root}>
            <div className={classNames(classes.statusRow, classes.flexRow)} onClick={onClick}>
                {statusElement || <div className={classes.avatarPlaceholder} />}
                <Typography variant="body2" className={classes.compartmentStatus} data-id="compartment-status">
                    {statusText}
                </Typography>
                <div className={classes.reeferValues}>
                    <Tooltip title={t('setpoint-temperature')}>
                        <Typography
                            variant="subtitle1"
                            component="span"
                            className={classNames(classes.primaryReeferValue, classes.flexRow)}
                        >
                            <Typography color="textSecondary" component="span" className={classes.flexRow}>
                                <SetpointIcon className={classes.primaryInfoIcon} />
                            </Typography>
                            <span data-id="setpoint-temperature">
                                {formatOptionalTemperature(compartmentStatus?.setpointTemperature)}
                            </span>
                        </Typography>
                    </Tooltip>
                    <Typography variant="caption" component="span" color="textSecondary" className={classes.flexRow}>
                        <Tooltip title={t('return-temperature')}>
                            <span className={classes.flexRow}>
                                <ReturnIcon className={classes.infoIcon} />
                                <span data-id="return-temperature">
                                    {formatOptionalTemperature(compartmentStatus?.returnTemperature)}
                                </span>
                            </span>
                        </Tooltip>

                        <Tooltip title={t('supply-temperature')}>
                            <span className={classes.flexRow}>
                                <SupplyIcon className={classes.infoIcon} />
                                <span data-id="supply-temperature">
                                    {formatOptionalTemperature(compartmentStatus?.supplyTemperature)}
                                </span>
                            </span>
                        </Tooltip>
                    </Typography>
                </div>
            </div>
            {sensorsElement}
            {timestamp}
        </div>
    );
};
