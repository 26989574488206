import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { HeaderTemplateInnerProps, HeaderTemplateProps } from './component';
import { HeaderTemplateComponent } from './component';
import { HeaderTemplateStyleRules } from './styles';

export const HeaderTemplate = compose<HeaderTemplateInnerProps, HeaderTemplateProps>(
    setDisplayName('HeaderTemplate'),
    withStyles(HeaderTemplateStyleRules, { withTheme: true })
)(HeaderTemplateComponent);
