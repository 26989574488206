import type { ServerResultStatus, VehicleTypeChangelogsResponse } from '~/services/ApiClient';
import type { BaseAction, Fulfilled, FulfilledMeta, Pending, PendingMeta, Rejected, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    ADMINVEHICLETYPECHANGELOGS_GET = 'ADMINVEHICLETYPECHANGELOGS_GET',
    VEHICLETYPE_UPDATE = 'VEHICLETYPE_UPDATE',
    VEHICLETYPE_DELETE = 'VEHICLETYPE_DELETE',
    VEHICLETYPE_CREATE = 'VEHICLETYPE_CREATE',
}

export enum ActionTypeKeys {
    ADMINVEHICLETYPECHANGELOGS_GET_PENDING = 'ADMINVEHICLETYPECHANGELOGS_GET_PENDING',
    ADMINVEHICLETYPECHANGELOGS_GET_FULFILLED = 'ADMINVEHICLETYPECHANGELOGS_GET_FULFILLED',
    ADMINVEHICLETYPECHANGELOGS_GET_REJECTED = 'ADMINVEHICLETYPECHANGELOGS_GET_REJECTED',
    VEHICLETYPE_UPDATE_PENDING = 'VEHICLETYPE_UPDATE_PENDING',
    VEHICLETYPE_UPDATE_FULFILLED = 'VEHICLETYPE_UPDATE_FULFILLED',
    VEHICLETYPE_UPDATE_REJECTED = 'VEHICLETYPE_UPDATE_REJECTED',
    VEHICLETYPE_UPDATE_RESET = 'VEHICLETYPE_UPDATE_RESET',
    VEHICLETYPE_DELETE_PENDING = 'VEHICLETYPE_DELETE_PENDING',
    VEHICLETYPE_DELETE_FULFILLED = 'VEHICLETYPE_DELETE_FULFILLED',
    VEHICLETYPE_DELETE_REJECTED = 'VEHICLETYPE_DELETE_REJECTED',
    VEHICLETYPE_DELETE_RESET = 'VEHICLETYPE_DELETE_RESET',
    VEHICLETYPE_CREATE_FULFILLED = 'VEHICLETYPE_CREATE_FULFILLED',
    VEHICLETYPE_CREATE_REJECTED = 'VEHICLETYPE_CREATE_REJECTED',
    VEHICLETYPE_CREATE_PENDING = 'VEHICLETYPE_CREATE_PENDING',
    VEHICLETYPE_CREATE_RESET = 'VEHICLETYPE_CREATE_RESET',
}

export type ActionTypes =
    | PendingMeta<ActionTypeKeys.ADMINVEHICLETYPECHANGELOGS_GET_PENDING, number>
    | FulfilledMeta<
          ActionTypeKeys.ADMINVEHICLETYPECHANGELOGS_GET_FULFILLED,
          Readonly<VehicleTypeChangelogsResponse>,
          number
      >
    | RejectedMeta<ActionTypeKeys.ADMINVEHICLETYPECHANGELOGS_GET_REJECTED, undefined, number>
    | Pending<ActionTypeKeys.VEHICLETYPE_UPDATE_PENDING>
    | Fulfilled<ActionTypeKeys.VEHICLETYPE_UPDATE_FULFILLED, ServerResultStatus>
    | Rejected<ActionTypeKeys.VEHICLETYPE_UPDATE_REJECTED>
    | BaseAction<ActionTypeKeys.VEHICLETYPE_UPDATE_RESET>
    | Pending<ActionTypeKeys.VEHICLETYPE_DELETE_PENDING>
    | Fulfilled<ActionTypeKeys.VEHICLETYPE_DELETE_FULFILLED, ServerResultStatus>
    | Rejected<ActionTypeKeys.VEHICLETYPE_DELETE_REJECTED>
    | BaseAction<ActionTypeKeys.VEHICLETYPE_DELETE_RESET>
    | Pending<ActionTypeKeys.VEHICLETYPE_CREATE_PENDING>
    | Fulfilled<ActionTypeKeys.VEHICLETYPE_CREATE_FULFILLED, ServerResultStatus>
    | Rejected<ActionTypeKeys.VEHICLETYPE_CREATE_REJECTED>
    | BaseAction<ActionTypeKeys.VEHICLETYPE_CREATE_RESET>;
