import { escapeRegExp, keyBy } from '~/libs/utility';

import { getRawCellValue } from './getRawCellValue';
import type { GridColumnDefinition } from './models';

export const filterDataSourceBySearchQuery = <T>(
    searchQuery: string | undefined,
    dataSource: T[],
    columns: Array<GridColumnDefinition<T>>,
    visibleColumns: string[]
): T[] => {
    if (!searchQuery || !dataSource.length || !visibleColumns.length) {
        return dataSource;
    }

    const regex = new RegExp(escapeRegExp(searchQuery), 'i');
    const columnsByName = keyBy(columns, 'name');
    const columnTextAccessors = visibleColumns
        .filter((columnName) => {
            return columnsByName[columnName];
        })
        .map((columnName) => {
            const columnDefinition = columnsByName[columnName];
            const valueAccessor = getRawCellValue.bind(undefined, columnDefinition);
            const { valueTextFormatter } = columnDefinition;
            return valueTextFormatter ? (row: T) => valueTextFormatter(valueAccessor(row)) : valueAccessor;
        });

    return dataSource.filter((entry) => {
        return columnTextAccessors.some((columnTextAccessor) => {
            const columnTextVal = columnTextAccessor(entry);
            return columnTextVal ? regex.test(columnTextVal.toString()) : false;
        });
    });
};
