import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type {
    DisconnectTrailerFromVehicleDialogInnerProps,
    DisconnectTrailerFromVehicleDialogProps,
} from './component';
import { DisconnectTrailerFromVehicleDialogComponent } from './component';

export const DisconnectTrailerFromVehicleDialog = compose<
    DisconnectTrailerFromVehicleDialogInnerProps,
    DisconnectTrailerFromVehicleDialogProps
>(
    setDisplayName('DisconnectTrailerFromVehicleDialog'),
    withTranslation()
)(DisconnectTrailerFromVehicleDialogComponent);
