import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { SettingsKey, ensureSettings } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';

import { rtdsScheduleChangelogsSelector } from '../../../../selectors';

import type { RtdsScheduleChangelogsSubpageInnerProps, RtdsScheduleChangelogsSubpageProps } from './component';
import { RtdsScheduleChangelogsSubpageComponent } from './component';
import { mapDispatchProps, mapStateToProps } from './redux';

export { rtdsScheduleChangelogsReducer } from './reducers';

export type { RtdsScheduleChangelogsStoreState } from './reducers';

export const RtdsSchedulesChangelogSubpage = compose<
    RtdsScheduleChangelogsSubpageInnerProps,
    RtdsScheduleChangelogsSubpageProps
>(
    setDisplayName('RtdsScheduleChangelogSubpage'),
    withTranslation(),
    ensureSettings([
        SettingsKey.TACHOGRAPH_DATA_BLOCK,
        SettingsKey.RTDS_WEEK_DAY_TYPE,
        SettingsKey.RTDS_SCHEDULE_FREQUENCY,
        SettingsKey.RTDS_PRIORITY,
    ]),
    connect(createSelector(rtdsScheduleChangelogsSelector, mapStateToProps), mapDispatchProps)
)(RtdsScheduleChangelogsSubpageComponent);
