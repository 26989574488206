import Room from '@mui/icons-material/Room';
import { Avatar, Paper } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';

import { useReverseGeocodedAddress } from '~/common';
import { CloseIcon } from '~/components/Icons';
import { TitledIconButton } from '~/components/TitledIconButton';
import type { Position } from '~/services/ApiClient';

import { GeoPositionAddress } from '../../../../../GeoPositionAddress';

import type { AddressPopupClassKey } from './styles';

export interface AddressPopupProps {
    onClose: () => void;
    position: Position;
}

export interface AddressPopupInnerProps extends AddressPopupProps, WithStyles<AddressPopupClassKey> {}

export const AddressPopupComponent: FC<AddressPopupInnerProps> = (props) => {
    const { onClose, classes, position } = props;

    const { address } = useReverseGeocodedAddress(position);

    return (
        <Paper elevation={3} className={classes.paper} data-id="address-popup">
            <Avatar data-id="address-avatar" className={classes.avatar}>
                <Room color="primary" />
            </Avatar>

            <GeoPositionAddress address={address} position={position} />

            <TitledIconButton className={classes.closeButton} onClick={onClose} data-id="address-popup-close">
                <CloseIcon fontSize="inherit" />
            </TitledIconButton>
        </Paper>
    );
};
