import { UNIT_SYSTEMS } from '../consts';
import type { UnitSystem } from '../models';

import { litersToGallons } from './litersToGallons';

interface ConvertVolumeLiquidArgs {
    value: number;
    unitSystem: UnitSystem;
    precision?: number;
}

const convertVolumeLiquid = (args: ConvertVolumeLiquidArgs) => {
    const { value, unitSystem, precision = 0 } = args;
    switch (unitSystem) {
        case UNIT_SYSTEMS.METRIC:
            return Number(value.toFixed(precision));
        case UNIT_SYSTEMS.IMPERIAL:
            return Number(litersToGallons(value).toFixed(precision));
        default:
            throw new Error('Unknown unit system.');
    }
};

export { convertVolumeLiquid };
export type { ConvertVolumeLiquidArgs };
