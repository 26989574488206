import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConversationSummaryClassKey = 'root' | 'avatar' | 'body' | 'disabled';

export const ConversationSummaryStyleRules: StyleRulesCallback<Theme, {}, ConversationSummaryClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            padding: theme.spacing(),
            display: 'flex',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        },
        avatar: {
            color: theme.functionalItemsColors.assetType.vehicle.value,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.text.secondary}`,
            marginRight: theme.spacing(),
        },
        body: {
            flexGrow: 1,
        },
        disabled: {
            opacity: theme.palette.action.disabledOpacity,
        },
    });
