import continentalLogoUrl from '~/assets/continental-logo.svg';
import ivecoLogoUrl from '~/assets/iveco-logo.svg';

import { createAppTheme } from './styles/createAppTheme';

const defaultTheme = createAppTheme();

const featureFlaggedTheme = createAppTheme({
    functionalItemsColors: {
        driverActivity: {
            subtotal: { value: '#f39500', contrast: '#FFFFFF' },
            drive: { value: '#dc3e3e', contrast: '#FFFFFF' },
            work: { value: '#1b93e1', contrast: '#FFFFFF' },
            available: { value: '#ffc92a', contrast: '#FFFFFF' },
            rest: { value: '#35a119', contrast: '#FFFFFF' },
        },
    },
});

const continentalTheme = createAppTheme({
    partner: {
        logoUrl: continentalLogoUrl,
        name: 'Continental',
        url: 'https://www.continental.com',
        analyticsSolutionName: 'Astrata Dash',
    },
    palette: {
        primary: { main: '#FFA700' },
        secondary: { main: '#151821' },
    },
});

const ivecoTheme = createAppTheme({
    partner: {
        logoUrl: ivecoLogoUrl,
        name: 'Iveco',
        url: 'https://www.iveco.com',
        analyticsSolutionName: 'Astrata Dash',
    },
    palette: {
        primary: { main: '#003087' },
        secondary: { main: '#B1303B' },
    },
});

export const themes = {
    default: defaultTheme,
    featureFlaggedTheme,
    continental: continentalTheme,
    iveco: ivecoTheme,
};
