import type {
    AssetType,
    Classification,
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
    ResolvedRtdsSchedule,
} from '~/services/ApiClient';
import { Driver, Vehicle } from '~/services/ApiClient';
import { formatDriverName, formatVehicleName } from '~/services/Formatters';

import type { RtdsSchedulesEntry } from '../../models';

export const getDeviceTypeValue = (entry: RtdsSchedulesEntry): string | undefined => entry.schedule.deviceType?.key;

export const getRtdsDescription = (entry: RtdsSchedulesEntry): ResolvedRtdsSchedule => entry.schedule;

export const getRtdsFrequencyValue = (entry: RtdsSchedulesEntry): Classification | undefined =>
    entry.schedule.scheduleFrequency;

export const getAssetValue = (
    formatVehicle: DisplayUserPreferencesVehicleDisplayFormat,
    formatDriver: DisplayUserPreferencesDriverDisplayFormat,
    entry: RtdsSchedulesEntry
): string | undefined => {
    if (entry.schedule.asset instanceof Vehicle) {
        return entry.schedule.asset && formatVehicleName(entry.schedule.asset, formatVehicle);
    }

    if (entry.schedule.asset instanceof Driver) {
        return entry.schedule.asset && formatDriverName(entry.schedule.asset, formatDriver);
    }

    return undefined;
};
export const getResponseEnabledValue = (entry: RtdsSchedulesEntry): boolean | undefined => {
    if (entry.schedule.asset instanceof Vehicle) {
        return entry.schedule.asset?.device?.isResponseEnabled;
    }
    return undefined;
};

export const getDeviceEnabledValue = (entry: RtdsSchedulesEntry): boolean | undefined => {
    if (entry.schedule.asset instanceof Vehicle) {
        return entry.schedule.asset?.device?.isEnabled;
    }
    return undefined;
};

export const getAssetTypeValue = (entry: RtdsSchedulesEntry): AssetType | undefined => entry.schedule.type;
