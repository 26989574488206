import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DurationWorkflowFieldInnerProps, DurationWorkflowFieldProps } from './component';
import { DurationWorkflowFieldComponent } from './component';

export const DurationWorkflowField = compose<DurationWorkflowFieldInnerProps, DurationWorkflowFieldProps>(
    setDisplayName('DurationWorkflowField'),
    withTranslation()
)(DurationWorkflowFieldComponent);
