import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';
import { lighten } from '@mui/material/styles';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { ConversationMessageProps } from './component';

export type ConversationMessageClassKey =
    | 'root'
    | 'optimisticMessageTimestamp'
    | 'incomingMessage'
    | 'outgoingMessage'
    | 'footer'
    | 'statusIndicator'
    | 'incomingMessageBubbleSurface'
    | 'outgoingMessageBubbleSurface'
    | 'outgoingMessageBubbleTitle'
    | 'incomingMessageBubbleContentUnread'
    | 'outgoingMessageBubbleContentFailed'
    | 'messageMenuButton'
    | 'messageMenuButtonIcon'
    | 'messageMenuOpen'
    | 'incomingMessageMenuButton'
    | 'outgoingMessageMenuButton'
    | 'attachmentIcon'
    | 'attachment'
    | 'attachmentInfo'
    | 'timeAndStatus'
    | 'textMessageContent'
    | 'translationDetectedLanguage'
    | 'translationLoader'
    | 'translateButton';

const messageButtonHoverGradient = 'linear-gradient(90deg,hsla(94,78%,87%,0)';

export const ConversationMessageStyleRules: StyleRulesCallback<
    Theme,
    ConversationMessageProps,
    ConversationMessageClassKey
> = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            position: 'relative',
        },
        optimisticMessageTimestamp: {
            fontStyle: 'italic',
        },
        incomingMessage: {
            marginRight: theme.spacing(2),
        },
        outgoingMessage: {
            marginLeft: theme.spacing(2),
            justifyContent: 'flex-end',
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            height: 20,
            justifyContent: 'flex-end',
        },
        statusIndicator: {
            display: 'inline-flex',
            fontSize: 14,
            marginLeft: theme.spacing(0.5),
        },
        incomingMessageBubble: {
            marginRight: theme.spacing(3),
        },
        outgoingMessageBubble: {
            marginLeft: theme.spacing(3),
        },
        messageMenuButtonIcon: {
            float: 'right',
        },
        messageMenuButton: {
            opacity: 0,
            display: 'block',
            position: 'absolute',
            top: 4,
            right: 0,
            fontSize: 24,
            width: 48,
            color: theme.palette.text.secondary,
            transition: `${theme.transitions.duration.short}ms`,
            zIndex: 1,
            '&:hover': {
                right: 6,
                opacity: 1,
            },
        },
        incomingMessageMenuButton: {
            background: `${messageButtonHoverGradient} 0,${lighten(
                theme.functionalItemsColors.assetType.vehicle.value,
                0.92
            )} 50%)`,
        },
        incomingMessageBubbleSurface: {
            backgroundColor: lighten(theme.functionalItemsColors.assetType.vehicle.value, 0.92),
            '&:hover $incomingMessageMenuButton': {
                opacity: 1,
                right: 6,
            },
        },
        outgoingMessageMenuButton: {
            background: `${messageButtonHoverGradient} 0,${lighten(theme.palette.background.paper, 0.92)} 50%)`,
        },
        outgoingMessageBubbleSurface: {
            backgroundColor: lighten(theme.palette.background.paper, 0.92),
            '&:hover $outgoingMessageMenuButton': {
                opacity: 1,
                right: 6,
            },
        },
        messageMenuOpen: {
            opacity: 1,
            right: 6,
        },
        outgoingMessageBubbleTitle: {
            color: ({ message }) =>
                message.value.isDeleted
                    ? alpha(theme.functionalItemsColors.assetType.user.value, 0.38)
                    : theme.functionalItemsColors.assetType.user.value,
        },
        incomingMessageBubbleContentUnread: {
            fontWeight: 600,
        },
        outgoingMessageBubbleContentFailed: {
            color: theme.palette.error.main,
        },
        attachmentIcon: {
            fontSize: 14,
            marginRight: theme.spacing(0.5),
        },
        attachment: {
            marginRight: 'auto',
            display: 'flex',
            cursor: ({ openMessageDetails }) => (openMessageDetails ? 'pointer' : 'auto'),
        },
        attachmentInfo: {
            fontSize: 10,
        },
        timeAndStatus: {
            display: 'flex',
            alignItems: 'center',
        },
        textMessageContent: {
            textDecoration: ({ message }) => (message.value.isDeleted ? 'line-through' : 'none'),
            color: ({ message }) =>
                message.value.isDeleted ? alpha(theme.palette.text.primary, 0.38) : theme.palette.text.primary,
        },
        translationDetectedLanguage: {
            width: '100%',
            filter: 'opacity(0.5)',
            marginTop: theme.spacing(),
        },
        translationLoader: {
            borderRadius: theme.spacing(),
            overflow: 'hidden',
        },
        translateButton: {
            fontSize: 'smaller',
        },
    });
