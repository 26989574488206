import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import type { ContextMenuInnerProps, ContextMenuProps } from './component';
import { ContextMenuComponent } from './component';
import { ContextMenuStyleRules } from './style';

export type { ContextMenuItem, ContextMenuPosition } from './component';

/**
 * @deprecated This component is deprecated in favor of `@fv/components/Actions/ContextMenu`
 */
export const ContextMenu = compose<ContextMenuInnerProps, ContextMenuProps>(
    setDisplayName('ContextMenu'),
    withStyles(ContextMenuStyleRules)
)(ContextMenuComponent);
