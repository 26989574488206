import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import { keyBy } from '~/libs/utility';
import { ServerResultStatus } from '~/services/ApiClient';

import { Actions } from './actionTypes';
import type { ActionTypes, AdminDriverStoreState } from './models';
import { resolveAdminDrivers } from './utils';

export const defaultStoreState: AdminDriverStoreState = {
    adminDrivers: { data: { array: [], hash: {} }, pending: false, fulfilled: false, rejected: false },
    updateDriverServerResultStatus: undefined,
    deleteDriverServerResultStatus: undefined,
    changelogs: {
        data: [],
        pending: false,
        fulfilled: false,
        rejected: false,
    },
};

export const adminDriverReducer = (
    state: AdminDriverStoreState = defaultStoreState,
    action: ActionTypes,
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): AdminDriverStoreState => {
    switch (action.type) {
        case Actions.ADMINDRIVER_CLEAR_DATA:
            return { ...defaultStoreState };

        case Actions.ADMINDRIVERGET_PENDING:
            return { ...state, adminDrivers: { ...state.adminDrivers, pending: true } };
        case Actions.ADMINDRIVERGET_FULFILLED: {
            const resolvedDrivers = resolveAdminDrivers({
                adminDrivers: action.payload.items,
                staticDataStoreState,
                settingsStoreState,
            });
            return {
                ...state,
                adminDrivers: {
                    data: { array: resolvedDrivers, hash: keyBy(resolvedDrivers, 'driverId') },
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        }
        case Actions.ADMINDRIVERGET_REJECTED:
            return {
                ...state,
                adminDrivers: { data: { array: [], hash: {} }, pending: false, fulfilled: false, rejected: true },
            };

        case Actions.ADMINDRIVERUPDATE_PENDING:
            return { ...state, updateDriverServerResultStatus: ServerResultStatus.PENDING };
        case Actions.ADMINDRIVERUPDATE_FULFILLED:
            return { ...state, updateDriverServerResultStatus: action.payload };
        case Actions.ADMINDRIVERUPDATE_REJECTED:
            return { ...state, updateDriverServerResultStatus: ServerResultStatus.SERVERERROR };
        case Actions.ADMINDRIVERUPDATE_RESET:
            return { ...state, updateDriverServerResultStatus: undefined };

        case Actions.ADMINDRIVERCREATE_PENDING:
            return { ...state, updateDriverServerResultStatus: ServerResultStatus.PENDING };
        case Actions.ADMINDRIVERCREATE_FULFILLED:
            return { ...state, updateDriverServerResultStatus: action.payload };
        case Actions.ADMINDRIVERCREATE_REJECTED:
            return { ...state, updateDriverServerResultStatus: ServerResultStatus.SERVERERROR };

        case Actions.ADMINDRIVERCHANGELOGS_PENDING:
            return { ...state, changelogs: { ...state.changelogs, fulfilled: false, rejected: false, pending: true } };
        case Actions.ADMINDRIVERCHANGELOGS_FULFILLED:
            return {
                ...state,
                changelogs: {
                    ...state.changelogs,
                    data: action.payload?.items,
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
            };
        case Actions.ADMINDRIVERCHANGELOGS_REJECTED:
            return { ...state, changelogs: { ...state.changelogs, pending: false, rejected: true, fulfilled: false } };

        case Actions.ADMINDRIVERDELETE_RESET:
            return { ...state, updateDriverServerResultStatus: undefined };
        case Actions.ADMINDRIVERDELETE_PENDING:
            return { ...state, updateDriverServerResultStatus: ServerResultStatus.PENDING };
        case Actions.ADMINDRIVERDELETE_FULFILLED:
            return { ...state, updateDriverServerResultStatus: action.payload };
        case Actions.ADMINDRIVERDELETE_REJECTED:
            return { ...state, updateDriverServerResultStatus: ServerResultStatus.SERVERERROR };

        default:
            return state;
    }
};
