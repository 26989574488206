import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { SummarySectionContentInnerProps, SummarySectionContentProps } from './component';
import { SummarySectionContentComponent } from './component';
import { SummarySectionContentStyleRules } from './styles';

export const SummarySectionContent = compose<SummarySectionContentInnerProps, SummarySectionContentProps>(
    setDisplayName('SummarySectionContent'),
    withTranslation(),
    withDisplayPreferences(),
    withStyles(SummarySectionContentStyleRules)
)(SummarySectionContentComponent);
