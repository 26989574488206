import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type IgnitionIconFormatterClassKey = 'iconSize' | 'on' | 'off';

export const IgnitionIconFormatterStyleRules: StyleRulesCallback<Theme, {}, IgnitionIconFormatterClassKey> = (
    theme: Theme
) =>
    createStyles({
        iconSize: {
            width: 24,
            height: 24,

            '& svg': {
                width: 20,
                height: 20,
            },
        },
        on: {
            backgroundColor: theme.functionalItemsColors.ignition.on.value,
            color: theme.functionalItemsColors.ignition.on.contrast,
        },
        off: {
            backgroundColor: theme.functionalItemsColors.ignition.off.value,
            color: theme.functionalItemsColors.ignition.off.contrast,
        },
    });
