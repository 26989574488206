import type { FC } from 'react';
import { Fragment } from 'react';

export const FormatNewLineToBr: FC<{ text: string }> = (props) => {
    const { text } = props;

    return (
        <>
            {text.split('\n').map((line, index) => {
                const key = index;
                return (
                    <Fragment key={key}>
                        {index > 0 && <br />}
                        {line}
                    </Fragment>
                );
            })}
        </>
    );
};
