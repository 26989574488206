import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { MarkAllMessagesAsReadButtonInnerProps, MarkAllMessagesAsReadButtonProps } from './component';
import { MarkAllMessagesAsReadButtonComponent } from './component';
import { MarkAllMessagesAsReadButtonStyleRules } from './styles';

export const MarkAllMessagesAsReadButton = compose<
    MarkAllMessagesAsReadButtonInnerProps,
    MarkAllMessagesAsReadButtonProps
>(
    setDisplayName('MarkAllMessagesAsRead'),
    withTranslation(),
    withStyles(MarkAllMessagesAsReadButtonStyleRules)
)(MarkAllMessagesAsReadButtonComponent);
