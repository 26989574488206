import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const IncomingReadIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M12 15.36L4 10.36V18H20V10.36L12 15.36M4 8L12 13L20 8V8L12 3L4 8V8M22 8V18A2 2 0 0 1 20 20H4A2 2 0 0 1 2 18V8C2 7.27 2.39 6.64 2.97 6.29L12 0.64L21.03 6.29C21.61 6.64 22 7.27 22 8Z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </SvgIcon>
    );
};
IncomingReadIcon.displayName = 'IncomingReadIcon';

export { IncomingReadIcon };
