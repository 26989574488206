import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const PinColumnIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
                d="M20 2H4C2.9 2 2 2.9 2 4v16c0 1.1 0.9 2 2 2h16c1.1 0 2-0.9 2-2V4C22 2.9 21.1 2 20 2z M14 20h-4v-4h4V20z M14 14h-4v-4h4
	                V14z M14 8h-4V4h4V8z M20 20h-4v-4h4V20z M20 14h-4v-4h4V14z M20 8h-4V4h4V8z"
            />
        </SvgIcon>
    );
};
PinColumnIcon.displayName = 'PinColumnIcon';

export { PinColumnIcon };
