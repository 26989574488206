import React from 'react';

import type { GridVirtualTableElement } from '~/components/Grid';

import type { HistoryAssetEntry, HistoryDriverEntry, HistoryTrailerEntry, HistoryVehicleEntry } from '../../models';
import { HistoryEventType } from '../../models';

import { DriverListView } from './components/Drivers';
import { TrailerListView } from './components/Trailers';
import { VehicleListView } from './components/Vehicles';

export interface HistoryListProps {
    dataSource: HistoryAssetEntry[];
    historyEventType: HistoryEventType;
    selectRow?: (selectedEntryId: number) => void;
    selectedEntryId?: number;
    highlightedEntryId?: number;
    onRowContextMenu?: (event: MouseEvent, rowId: number) => void;
    gridRef?: React.Ref<GridVirtualTableElement>;
}

export interface HistoryListInnerProps extends HistoryListProps {}

const HistoryList: React.FC<HistoryListInnerProps> = ({
    dataSource,
    historyEventType,
    selectRow,
    selectedEntryId,
    highlightedEntryId,
    onRowContextMenu,
    gridRef,
}) => {
    switch (historyEventType) {
        case HistoryEventType.VEHICLE:
            return (
                <VehicleListView
                    dataSource={dataSource as HistoryVehicleEntry[]}
                    selectRow={selectRow}
                    selectedAssetId={selectedEntryId}
                    highlightedAssetId={highlightedEntryId}
                    onRowContextMenu={onRowContextMenu}
                />
            );
        case HistoryEventType.TRAILER:
            return (
                <TrailerListView
                    dataSource={dataSource as HistoryTrailerEntry[]}
                    selectRow={selectRow}
                    selectedAssetId={selectedEntryId}
                    highlightedAssetId={highlightedEntryId}
                    onRowContextMenu={onRowContextMenu}
                />
            );
        case HistoryEventType.DRIVER:
            return (
                <DriverListView
                    dataSource={dataSource as HistoryDriverEntry[]}
                    selectRow={selectRow}
                    selectedAssetId={selectedEntryId}
                    highlightedAssetId={highlightedEntryId}
                    onRowContextMenu={onRowContextMenu}
                    gridRef={gridRef}
                />
            );
        default:
            throw Error(`Unknown perspective: ${historyEventType}`);
    }
};
HistoryList.displayName = 'HistoryList';

export { HistoryList };
