import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { OverlayMenuItemInnerProps, OverlayMenuItemProps } from './component';
import { OverlayMenuItemComponent } from './component';

export const OverlayMenuItem = compose<OverlayMenuItemInnerProps, OverlayMenuItemProps>(
    setDisplayName('OverlayMenuItem'),
    withTranslation()
    // withStyles(OverlayMenuItemStyleRules, { withTheme: true })
)(OverlayMenuItemComponent);
