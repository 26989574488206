import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import type { NoticeProps } from 'react-select/src/components/Menu';

import type { Option } from '../../models';

import type { NoOptionsMessageClassKey } from './styles';

export interface NoOptionsMessageProps extends NoticeProps<Option, false> {}

export interface NoOptionsMessageInnerProps extends NoOptionsMessageProps, WithStyles<NoOptionsMessageClassKey> {}

export const NoOptionsMessageComponent: React.FC<NoOptionsMessageInnerProps> = ({ children, classes, innerProps }) => {
    return (
        <Typography color="textSecondary" className={classes.root} {...innerProps} data-id="no-options-message">
            {children}
        </Typography>
    );
};
