import moment from 'moment';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { FieldWorkflowFormElement, WorkflowFieldValue } from '~/services/ApiClient';
import {
    BooleanFieldWorkflowFormElement,
    BooleanWorkflowFieldValue,
    DateTimeDisplayPrecision,
    DateTimeFieldWorkflowFormElement,
    DateTimeWorkflowFieldValue,
    DurationFieldWorkflowFormElement,
    DurationWorkflowFieldValue,
    EnumFieldWorkflowFormElement,
    EnumWorkflowFieldValue,
    NumericFieldWorkflowFormElement,
    NumericWorkflowFieldValue,
    PositionFieldWorkflowFormElement,
    PositionWorkflowFieldValue,
    RawFieldWorkflowFormElement,
    RawWorkflowFieldValue,
    TextFieldWorkflowFormElement,
    TextWorkflowFieldValue,
} from '~/services/ApiClient';

import { DurationFormat, FormatLength } from './enums';
import { formatBoolean } from './formatBoolean';
import { formatCoordinates } from './formatCoordinates';
import { formatDate } from './formatDate';
import { formatDateTime } from './formatDateTime';
import { formatDuration } from './formatDuration';
import { formatTime } from './formatTime';

// eslint-disable-next-line @typescript-eslint/ban-types
const valueTypeMapping = new Map<Function, Function>([
    [RawFieldWorkflowFormElement, RawWorkflowFieldValue],
    [TextFieldWorkflowFormElement, TextWorkflowFieldValue],
    [PositionFieldWorkflowFormElement, PositionWorkflowFieldValue],
    [NumericFieldWorkflowFormElement, NumericWorkflowFieldValue],
    [BooleanFieldWorkflowFormElement, BooleanWorkflowFieldValue],
    [EnumFieldWorkflowFormElement, EnumWorkflowFieldValue],
    [DateTimeFieldWorkflowFormElement, DateTimeWorkflowFieldValue],
    [DurationFieldWorkflowFormElement, DurationWorkflowFieldValue],
]);

export const formatWorkflowFieldValue = (
    field: FieldWorkflowFormElement,
    t: SingleTFunction,
    value?: WorkflowFieldValue
): string | undefined => {
    const expectedValueType = valueTypeMapping.get(field.constructor);
    if (value && expectedValueType) {
        if (!(value instanceof expectedValueType)) {
            throw new Error(`Expected value type '${expectedValueType.name}', but got '${value.constructor.name}'`);
        }
    }

    if (field instanceof RawFieldWorkflowFormElement) {
        const rawValue = value as RawWorkflowFieldValue | undefined;
        return rawValue?.value;
    }

    if (field instanceof TextFieldWorkflowFormElement) {
        const textValue = value as TextWorkflowFieldValue | undefined;
        return textValue?.value;
    }

    if (field instanceof PositionFieldWorkflowFormElement) {
        const positionValue = value as PositionWorkflowFieldValue | undefined;
        return positionValue?.value ? formatCoordinates(t, positionValue?.value) : undefined;
    }

    if (field instanceof NumericFieldWorkflowFormElement) {
        const numericValue = value as NumericWorkflowFieldValue | undefined;
        const formattedValue = numericValue?.value?.toFixed(field.decimals) ?? '';

        const formattedValueWithUnit = (field.displayFormat ?? '{0}').replace('{0}', formattedValue);

        return isUndefined(numericValue?.value) ? undefined : formattedValueWithUnit;
    }

    if (field instanceof EnumFieldWorkflowFormElement) {
        const enumValue = value as EnumWorkflowFieldValue | undefined;
        const selectedValue = field.options.find((it) => it.id === enumValue?.optionId);

        return selectedValue?.text;
    }

    if (field instanceof BooleanFieldWorkflowFormElement) {
        const booelanValue = value as BooleanWorkflowFieldValue | undefined;
        if (booelanValue) {
            return isUndefined(booelanValue?.value) ? undefined : formatBoolean(t, booelanValue?.value);
        }
    }

    if (field instanceof DateTimeFieldWorkflowFormElement) {
        const dateTimeValue = value as DateTimeWorkflowFieldValue | undefined;
        let formatted;

        if (dateTimeValue?.value) {
            const includeDate =
                field.displayPrecision === DateTimeDisplayPrecision.DateTime ||
                field.displayPrecision === DateTimeDisplayPrecision.Date;
            const includeTime =
                field.displayPrecision === DateTimeDisplayPrecision.DateTime ||
                field.displayPrecision === DateTimeDisplayPrecision.Time;
            if (includeDate === includeTime) {
                formatted = formatDateTime(dateTimeValue?.value);
            } else if (includeDate) {
                formatted = formatDate(dateTimeValue?.value);
            } else {
                formatted = formatTime(dateTimeValue?.value);
            }
        }

        return formatted;
    }

    if (field instanceof DurationFieldWorkflowFormElement) {
        const durationField = value as DurationWorkflowFieldValue | undefined;

        const formattedDuration = formatDuration(moment.duration(durationField?.value), {
            durationFormat: DurationFormat.DayHourMinute,
            durationFormatLength: FormatLength.Short,
        });

        return formattedDuration;
    }

    throw new Error(`Unhandled form element of type '${field.constructor.name}'`);
};
