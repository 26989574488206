import { AppBar, Toolbar, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { TitledTruncatedText } from '../TitledTruncatedText';

import type { HeaderTemplateClassKey } from './styles';

export interface HeaderTemplateProps {
    centerEl?: JSX.Element;
    actions?: JSX.Element;
    filters?: JSX.Element;
    title: string;
}

export interface HeaderTemplateInnerProps extends HeaderTemplateProps, WithStyles<HeaderTemplateClassKey> {}

export const HeaderTemplateComponent: React.FunctionComponent<HeaderTemplateInnerProps> = ({
    title,
    centerEl,
    actions,
    filters,
    classes,
}) => {
    return (
        <AppBar position="sticky" className={classes.headerBar}>
            <Toolbar data-id="header-toolbar" className={classes.toolbar}>
                <div className={classes.header} data-id="header">
                    <div className={classes.leftWrapper} data-id="left-wrapper">
                        <Typography noWrap variant="h6" color="inherit" data-id="page-title">
                            <TitledTruncatedText>{title}</TitledTruncatedText>
                        </Typography>
                        <div className={classes.filters}>{filters}</div>
                    </div>
                    <div className={classes.centerWrapper} data-id="center-wrapper">
                        {centerEl}
                    </div>
                    <div className={classes.rightWrapper} data-id="right-wrapper">
                        {actions}
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};
