import { Divider, Typography } from '@mui/material';
import type { StyledComponentProps, WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import type { LeftPaneClassKey } from './styles';

export interface LeftPaneProps {
    children: JSX.Element;
    header: JSX.Element;
}

export interface LeftPaneOuterProps extends LeftPaneProps, StyledComponentProps<LeftPaneClassKey> {}

export interface LeftPaneInnerProps extends LeftPaneProps, InjectedTranslationProps, WithStyles<LeftPaneClassKey> {}

export const LeftPaneComponent: React.FC<LeftPaneInnerProps> = ({ classes, children, header }) => {
    return (
        <div className={classes.root} data-id="filter">
            <div className={classes.header}>
                <Typography className={classes.title} variant="h6" color="textPrimary">
                    {header}
                </Typography>
            </div>

            <Divider />

            <div className={classes.content}>{children}</div>
        </div>
    );
};
