import { parse } from 'qs';
import { Component } from 'react';

import { isUndefined } from '~/libs/utility';

import { locationReplace } from '../../locationReplace';

import type { LandingInnerProps, LandingSearchParams, SetQueryParameterArgs } from './models';

export class LandingComponent extends Component<LandingInnerProps> {
    public async componentDidMount(): Promise<void> {
        const params: LandingSearchParams = parse(this.props.location.search, { ignoreQueryPrefix: true }) || {};

        if (this.props.isAuthenticated) {
            this.redirect(params.redirectUrl || '/');
        } else {
            const enhancedLoginUrl =
                params.loginUrl &&
                this.setQueryParameter({
                    url: params.loginUrl,
                    paramName: 'redirectUrl',
                    paramValue: params.redirectUrl,
                });

            this.redirect(enhancedLoginUrl || '/login');
        }
    }

    public render() {
        return null;
    }

    private redirect(url: string) {
        if (this.isAbsoluteUrl(url)) {
            locationReplace(url);
        } else {
            this.props.history.replace(url);
        }
    }

    private setQueryParameter({ url, paramName, paramValue }: SetQueryParameterArgs) {
        if (!this.isAbsoluteUrl(url)) {
            return url;
        }

        const parsed = new URL(url);
        if (isUndefined(paramValue)) {
            parsed.searchParams.delete(paramName);
        } else {
            parsed.searchParams.set(paramName, paramValue);
        }

        return parsed.href;
    }

    private isAbsoluteUrl(url: string): boolean {
        return /^https?:\/\//.test(url);
    }
}
