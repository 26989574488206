import type { DisplayUserPreferences } from '~/components/DisplayPreferences';
import type { MonitoringTrailerEntry } from '~/data/monitoring';
import { filterBySearchQuery } from '~/services/Filter';
import { formatDriverName, formatTrailerName, formatVehicleName } from '~/services/Formatters';

export const createFilter =
    (displayPreferences: DisplayUserPreferences) =>
    (entries: MonitoringTrailerEntry[], searchQuery: string): MonitoringTrailerEntry[] => {
        const textAccessors = (entry: MonitoringTrailerEntry): Array<string | undefined> => {
            const vehicle = entry.vehicleConnection?.vehicleReference;
            const driver = entry.vehicleConnection?.vehicleDriverHoursStatus?.driverHoursStatus?.driver;
            const coDriver = entry.vehicleConnection?.vehicleDriverHoursStatus?.coDriverHoursStatus?.driver;
            return [
                formatTrailerName(entry.trailer, displayPreferences.trailerDisplayFormat),
                vehicle && formatVehicleName(vehicle, displayPreferences.vehicleDisplayFormat),
                driver && formatDriverName(driver, displayPreferences.driverDisplayFormat),
                coDriver && formatDriverName(coDriver, displayPreferences.driverDisplayFormat),
            ];
        };

        return filterBySearchQuery(entries, textAccessors, searchQuery);
    };
