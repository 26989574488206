import type { NumericDictionary } from '~/libs/utility';

import type {
    AdminDevice,
    AdminDriverRead,
    AdminVehicleEdit,
    AdminVehicleRead,
    Attachment,
    ButtonDriverLoginChangelogChanges,
    Classification,
    CompartmentStatus,
    CompletedActivity,
    Container,
    ContainerStatus,
    ContainerTemperatureStatus,
    Conversation,
    ConversationSummary,
    ConversationUpdate,
    Country,
    Depot,
    DeviceType,
    DoorStatus,
    Driver,
    DriverActivityType,
    DriverChangelog,
    DriverChangelogChanges,
    DriverEvent,
    DriverEventField,
    DriverEventFieldValue,
    DriverEventType,
    DriverHoursStatus,
    DriverSubActivityType,
    InhibitorStatus,
    LastContainerEvent,
    LastTrailerEvent,
    ManualDriverLoginChangelogChanges,
    Message,
    MessageBody,
    MessageDirection,
    NamedTrailerReference,
    NamedVehicleReference,
    ReeferStatus,
    RouteStatus,
    RtdsSchedule,
    RtdsScheduleChangelogChanges,
    RtdsSession,
    SendVehicle,
    SettingsIntegerValue,
    SmartCardDriverLogin,
    SmartCardDriverLoginChangelogChanges,
    SpeedLimit,
    TachoFile,
    TachoFileMetadata,
    TachographDataBlock,
    TemperatureSensorStatus,
    Trailer,
    TrailerBatteryStatus,
    TrailerConnection,
    TrailerEvent,
    TrailerStatus,
    TranslateMessageResponse,
    Vehicle,
    VehicleCategory,
    VehicleChangelogChanges,
    VehicleDriverHoursStatus,
    VehicleSettingsChangelogChanges,
    VehicleSettingsEdit,
    VehicleSettingsRead,
    VehicleStatus,
    VehicleType,
    VehicleTypeChangelogChanges,
    VehicleTypeDimensions,
    ViolationType,
    WorkflowFormDefinition,
} from './generated';

export interface ApiModel {
    toJSON: (_?: unknown) => unknown;
    init: (_: unknown) => void;
}

export type PojoModel<T extends ApiModel> = Omit<T, keyof ApiModel>;

type DeepPojoModelArray<T> = Array<DeepPojoModel<T>>;

type DeepPojoModelObject<T> = {
    [Key in keyof T]: DeepPojoModel<T[Key]>;
};

export type DeepPojoModel<T> = T extends Array<infer U>
    ? DeepPojoModelArray<U>
    : T extends Date
      ? T
      : T extends object
        ? DeepPojoModelObject<Omit<T, keyof ApiModel>>
        : T;

type DeepPartial<T> = T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

export interface ClassificationAbstraction {
    id: number | string;
    key: string;
    displayName: string;
    rowValues?: (string | undefined)[];
}

export type DerivedClassification = ClassificationAbstraction;

export enum DerivedClassificationType {
    VehicleTypeAxlesCombo = 'vehicle-type-axles-combo',
}

export enum ServerResultStatus {
    PENDING = 'Pending',
    BADREQUEST = 'BadRequest',
    SERVERERROR = 'ServerError',
    OK = 'Ok',
    ACCEPTED = 'Accepted',
}

export enum EditorState {
    NEW = 'New',
    CLONE = 'Clone',
    MODIFY = 'Modify',
}

export enum DriverRole {
    DRIVER = 'driver',
    CODRIVER = 'co-driver',
}

export enum DriverActivityKeys {
    DRIVE = 'Drive',
    WORK = 'Work',
    AVAILABLE = 'Available',
    REST = 'Rest',
}

export enum FileTypeKeys {
    BIN = 'Bin',
    TIF = 'Tif',
    PNG = 'Png',
    JPG = 'Jpg',
    GIF = 'Gif',
    XLS = 'Xls',
    DOC = 'Doc',
    PDF = 'Pdf',
    XML = 'Xml',
    CSV = 'Csv',
    RTF = 'Rtf',
    ZIP = 'Zip',
    NOTSUPPORTED = 'NotSupported',
    UNKNOWN = 'Unknown',
}

export enum CompartmentStatusKeys {
    UNKNOWN = 'Unknown',
    COOLING = 'Cooling',
    HEATING = 'Heating',
    DEFROST = 'Defrost',
    POWEROFF = 'PowerOff',
    PRETRIP = 'PreTrip',
    SHUTDOWN = 'Shutdown',
    MANUFACTURERSPECIFIC = 'ManufacturerSpecific',
}

export enum InhibitorStatusKeys {
    UNKNOWN = 'Unknown',
    ENABLED = 'Enabled',
    DISABLED = 'Disabled',
    DISABLEDBYPIN = 'DisabledbyPin',
    ENABLEDBYIBUTTON = 'EnabledByIButton',
    DISABLEDBYIBUTTON = 'DisabledByIButton',
}

export interface ViolationField<T> {
    value: T;
    threshold: T;
    violationType: ViolationType;
    violation?: T;
}

export enum TrailerDoorStatusKeys {
    OPEN = 'Open',
    CLOSED = 'Closed',
    LOCKED = 'Locked',
    SEALED = 'Sealed',
}

export enum ReeferStatusKeys {
    NORMAL = 'NormalOperation',
    WARNING = 'Warning',
    SHUTDOWN = 'Shutdown',
    OFF = 'Off',
}

export enum ReeferPowerModeKeys {
    DIESEL = 'Diesel',
    ELECTRIC = 'Electric',
}

export enum ContainerTemperatureStatusKeys {
    UNKNOWN = 'Unknown',
    SETPOINT = 'Setpoint',
    AMBIENT = 'Ambient',
    CONTENT = 'Content',
    HEATER = 'Heater',
}

export enum RouteStatusKeys {
    NOTNAVIGATING = 'NotNavigating',
    STARTNAVIGATING = 'StartNavigating',
    ARRIVEDATDESTINATION = 'ArrivedAtDestination',
    OUTOFCORRIDOR = 'OutOfCorridor',
    BACKINCORRIDOR = 'BackInCorridor',
}

type OmitFromGenerated<T extends ApiModel, K extends keyof T> = Omit<PojoModel<T>, K>;

export type Asset = Vehicle | Trailer | Driver;

export type ResolvedAsset = ResolvedVehicle | ResolvedTrailer | ResolvedDriver;

export interface ResolvedTrailer extends OmitFromGenerated<Trailer, 'trailerManufacturer' | 'reeferManufacturer'> {
    trailerManufacturer: Classification;
    reeferManufacturer?: Classification;
}

export interface ResolvedReeferStatus
    extends OmitFromGenerated<ReeferStatus, 'status' | 'alarms' | 'operationMode' | 'powerMode' | 'speed'> {
    status?: Classification;
    alarms?: Classification[];
    operationMode?: Classification;
    powerMode?: Classification;
    speed?: Classification;
}

export interface ResolvedLastTrailerEvent extends OmitFromGenerated<LastTrailerEvent, 'trailerEventType'> {
    trailerEventType: Classification;
}

export interface ResolvedTrailerBatteryStatus extends OmitFromGenerated<TrailerBatteryStatus, 'status'> {
    status?: Classification;
}

export interface ResolvedDoorStatus extends OmitFromGenerated<DoorStatus, 'status'> {
    status: Classification[];
}

export interface ResolvedCompartmentStatus
    extends OmitFromGenerated<CompartmentStatus, 'status' | 'temperatureSensors'> {
    status?: Classification;
    temperatureSensors: NumericDictionary<TemperatureSensorStatus>;
}

export interface ResolvedInhibitorStatus extends OmitFromGenerated<InhibitorStatus, 'status'> {
    inhibitorStatus: Classification;
}

export interface ResolvedTrailerStatus
    extends OmitFromGenerated<TrailerStatus, 'lastEvent' | 'doors' | 'compartments' | 'reefer' | 'hooked' | 'battery'> {
    lastEvent: ResolvedLastTrailerEvent;
    doors: NumericDictionary<ResolvedDoorStatus>;
    compartments: NumericDictionary<ResolvedCompartmentStatus>;
    reefer?: ResolvedReeferStatus;
    hooked?: Classification;
    battery?: ResolvedTrailerBatteryStatus;
}

export interface ResolvedTrailerConnection extends OmitFromGenerated<TrailerConnection, 'trailer'> {
    trailer?: ResolvedTrailer;
    trailerStatus?: ResolvedTrailerStatus;
    trailerReference: NamedTrailerReference;
}

export interface ResolvedVehicle extends OmitFromGenerated<Vehicle, 'category'> {
    deviceType?: DeviceType;
    category: VehicleCategory;
    vehicleType?: VehicleType;
}

export interface ResolvedVehicleDriverHoursStatus
    extends OmitFromGenerated<VehicleDriverHoursStatus, 'driverHoursStatus' | 'coDriverHoursStatus'> {
    driverHoursStatus?: ResolvedDriverHoursStatus;
    coDriverHoursStatus?: ResolvedDriverHoursStatus;
}

export interface ResolvedDriverHoursStatus extends OmitFromGenerated<DriverHoursStatus, 'activity' | 'subActivity'> {
    activity?: DriverActivityType;
    subActivity?: DriverSubActivityType;
}

export interface ResolvedDriver extends OmitFromGenerated<Driver, 'init' | 'toJSON'> {}

export enum TrailerType {
    TRAILER = 'trailer',
    REEFER = 'reefer',
}

export enum AssetConnectionStatus {
    CONNECTED = 'connected',
    NOT_CONNECTED = 'not-connected',
}

export interface ResolvedVehicleConnection {
    vehicle?: ResolvedVehicle;
    vehicleReference: NamedVehicleReference;
    isManuallyConnected: boolean;
    vehicleDriverHoursStatus?: ResolvedVehicleDriverHoursStatus;
    canManuallyDisconnect: boolean;
}

export enum AssetRecipientType {
    VEHICLE = 'vehicle',
    DRIVER = 'driver',
    GROUP = 'group',
    DEPOT = 'depot',
}

export interface AssetRecipient {
    name: string;
    ids: number[];
    type: AssetRecipientType;
    enabledAssetIds?: number[];
    secondaryTextFirst?: string;
    secondaryTextSecond?: string;
    secondaryTextThird?: string;
}

export interface ResolvedConversation extends OmitFromGenerated<Conversation, 'messages'> {
    messages: ResolvedMessage[];
}

export enum InhibitorStatusEnum {
    Unknown = 0,
    Enabled = 1,
    Disabled = 2,
    DisabledByPin = 3,
    EnabledByIButton = 4,
    DisabledByIButton = 5,
}

export enum MessageBodyType {
    Workflow = 'workflow',
    Text = 'text',
}

export enum MessageStatus {
    Received = 'received',
    IncomingRead = 'incoming-read',
    Pending = 'pending',
    Delivered = 'delivered',
    OutgoingRead = 'outgoing-read',
    Failed = 'failed',
    Sending = 'sending',
    Scheduled = 'scheduled',
    ValidationFailure = 'validation-failure',
    GeneralFailure = 'general-failure',
    Deleted = 'deleted',
}

export enum TachoDataBlockTypes {
    Overview = 1,
    EventsAndFaults = 2,
    DetailedSpeed = 4,
    TechnicalData = 8,
    DriverCard = 48,
    ActivityData = 64,
}

export enum GeozoneType {
    Checkpoint,
    NoEntryZone,
}

export interface ResolvedMessage {
    value: Message;
    body: ResolvedMessageBody;
    replyToMessage?: ResolvedMessage;
    direction: MessageDirection;
    status: MessageStatus;
}

export interface ResolvedMessageBody {
    value: MessageBody;
    workflowFormDefinition?: WorkflowFormDefinition;
    workflowFormDefinitionStatus?: WorkflowFormDefinitionStatus;
    type: MessageBodyType;
}

export interface ResolvedConversationSummary extends OmitFromGenerated<ConversationSummary, 'lastMessage'> {
    lastMessage?: ResolvedMessage | undefined;
}

export enum WorkflowFormDefinitionStatus {
    // Form definition is required, but not available in the store yet. Visually equivalent to pending
    REQUIRED = 'required',

    // Failed to load the form definition
    REJECTED = 'rejected',

    // Loading the form definition
    PENDING = 'pending',

    // Form definition is available
    RESOLVED = 'resolved',
}

export interface ResolvedConversationUpdate extends OmitFromGenerated<ConversationUpdate, 'updatedMessages'> {
    updatedMessages: ResolvedMessage[];
}

export interface ResolvedRouteStatus extends OmitFromGenerated<RouteStatus, 'status'> {
    status: Classification;
}

export interface ResolvedContainer extends OmitFromGenerated<Container, 'init' | 'toJSON'> {}

export interface ResolvedContainerStatus extends OmitFromGenerated<ContainerStatus, 'lastEvent' | 'temperatures'> {
    lastEvent: ResolvedLastContainerEvent;
    temperatures: ResolvedContainerTemperatureStatus[];
}

export interface ResolvedLastContainerEvent extends OmitFromGenerated<LastContainerEvent, 'eventType'> {
    eventType: Classification;
}

export interface ResolvedContainerTemperatureStatus extends OmitFromGenerated<ContainerTemperatureStatus, 'type'> {
    type: Classification;
}

export interface ResolvedAttachment extends OmitFromGenerated<Attachment, 'type'> {
    type: Classification;
}

export interface ResolvedTrailerEvent
    extends OmitFromGenerated<TrailerEvent, 'eventType' | 'doors' | 'compartments' | 'reefer' | 'hooked' | 'battery'> {
    deviceType?: DeviceType;
    eventType: Classification;
    doors: NumericDictionary<ResolvedDoorStatus>;
    compartments: NumericDictionary<ResolvedCompartmentStatus>;
    reefer?: ResolvedReeferStatus;
    hooked?: Classification;
    battery?: ResolvedTrailerBatteryStatus;
}

export interface ResolvedCompletedActivity
    extends OmitFromGenerated<CompletedActivity, 'activityType' | 'subActivityType'> {
    activityType: DriverActivityType;
    subActivityType?: DriverSubActivityType;
}

export interface ResolvedDriverEventFieldValue extends OmitFromGenerated<DriverEventFieldValue, 'fieldId'> {
    field: DriverEventField;
}

export interface ResolvedDriverEvent extends OmitFromGenerated<DriverEvent, 'eventType' | 'completedActivity'> {
    completedActivity?: ResolvedCompletedActivity;
    eventType?: DriverEventType;
}

export interface ResolvedRtdsSession extends OmitFromGenerated<RtdsSession, 'vehicle' | 'driver' | 'error'> {
    vehicle?: Vehicle;
    vehicleStatus?: VehicleStatus;
    driver?: Driver;
    dataBlocks?: TachographDataBlock[];
    error?: ResolvedRtdsSessionError;
}

export interface ResolvedRtdsSessionError {
    rtdsSessionErrorType?: Classification;
    tachographDataBlocks?: Classification[];
}

export interface ResolvedRtdsSchedule
    extends OmitFromGenerated<RtdsSchedule, 'frequency' | 'description' | 'priority'> {
    asset?: Asset;
    deviceType?: DeviceType;
    dataBlocks?: TachographDataBlock[];
    resolvedDescription: ResolvedRtdsScheduleDescription;
    scheduleFrequency: Classification;
    schedulePriority: Classification;
}

export interface ResolvedRtdsScheduleDescription {
    rtdsScheduleType?: Classification;
    weekdayType?: Classification;
    ordinalType?: Classification;
    day?: number;
}

export interface ResolvedTachoFile extends OmitFromGenerated<TachoFile, 'metadata' | 'sendVehicle'> {
    metadata: ResolvedTachoFileMetadata;
    sendVehicle?: ResolvedSendVehicle;
}

export interface ResolvedTachoFileMetadata {
    value: TachoFileMetadata;
    dataBlocks?: TachographDataBlock[];
    vehicle?: Vehicle;
    driver?: Driver;
}

export interface ResolvedSendVehicle extends OmitFromGenerated<SendVehicle, 'vehicle'> {
    vehicle?: Vehicle;
}
export interface ResolvedAdminVehicleRead
    extends OmitFromGenerated<AdminVehicleRead, 'category' | 'depot' | 'settings' | 'device'> {
    category: VehicleCategory;
    vehicleType?: VehicleType;
    deviceType?: DeviceType;
    depot?: Depot;
    settings?: ResolvedVehicleSettingsRead;
    device?: ResolvedAdminDevice;
}

export interface ResolvedVehicleType extends OmitFromGenerated<VehicleType, 'type' | 'axles' | 'emissionClass'> {
    type?: Classification;
    axles?: Classification;
    emissionClass?: Classification;
}

export interface ResolvedVehicleSettingsRead
    extends OmitFromGenerated<
        VehicleSettingsRead,
        | 'tachograph'
        | 'tachoProtocol'
        | 'tachoGeneration'
        | 'workflowType'
        | 'navigationType'
        | 'imageCapturingType'
        | 'inhibitorServiceType'
        | 'tellTaleServiceType'
        | 'primaryFuelTankType'
        | 'secondaryFuelTankType'
    > {
    tachograph: Classification;
    tachoProtocol: Classification;
    tachoGeneration: Classification;
    workflowType: Classification;
    navigationType: Classification;
    imageCapturingType: Classification;
    inhibitorServiceType: Classification;
    tellTaleServiceType: Classification;
    primaryFuelTankType: Classification;
    secondaryFuelTankType: Classification;
}

export interface ResolvedAdminVehicleEdit
    extends OmitFromGenerated<AdminVehicleEdit, 'category' | 'depot' | 'settings' | 'deviceId'> {
    category?: VehicleCategory;
    vehicleType?: VehicleType;
    deviceType?: DeviceType;
    depot?: Depot;
    settings?: ResolvedVehicleSettingsEdit;
    device?: ResolvedAdminDevice;
}

export interface ResolvedVehicleSettingsEdit
    extends OmitFromGenerated<
        VehicleSettingsEdit,
        | 'tachograph'
        | 'tachoProtocol'
        | 'tachoGeneration'
        | 'workflowType'
        | 'navigationType'
        | 'imageCapturingType'
        | 'inhibitorServiceType'
        | 'tellTaleServiceType'
    > {
    tachograph: ResolvedSettingsIntegerValue;
    tachoProtocol: ResolvedSettingsIntegerValue;
    tachoGeneration: ResolvedSettingsIntegerValue;
    workflowType: ResolvedSettingsIntegerValue;
    navigationType: ResolvedSettingsIntegerValue;
    imageCapturingType: ResolvedSettingsIntegerValue;
    inhibitorServiceType: ResolvedSettingsIntegerValue;
    tellTaleServiceType: ResolvedSettingsIntegerValue;
}

export interface ResolvedSettingsIntegerValue extends OmitFromGenerated<SettingsIntegerValue, 'value'> {
    value: Classification;
}

export interface ResolvedAdminDevice
    extends OmitFromGenerated<AdminDevice, 'networkId' | 'billingGroup' | 'billingStatus'> {
    networkId: Classification;
    billingGroup?: Classification;
    billingStatus?: Classification;
}

export interface ResolvedRtdsScheduleChangelogChanges
    extends OmitFromGenerated<
        RtdsScheduleChangelogChanges,
        'frequency' | 'tachographDataBlocks' | 'priority' | 'weekDay'
    > {
    frequency?: Classification;
    tachoBlocks?: TachographDataBlock[];
    priority?: Classification;
    weekDay?: Classification;
}

export interface ResolvedVehicleChangelogChanges
    extends OmitFromGenerated<VehicleChangelogChanges, 'category' | 'depot' | 'settings'> {
    category?: VehicleCategory;
    vehicleType?: VehicleType;
    depot?: Depot;
    settings?: ResolvedVehicleSettingsChangelogChanges;
}

export interface ResolvedVehicleSettingsChangelogChanges
    extends OmitFromGenerated<
        VehicleSettingsChangelogChanges,
        | 'tachograph'
        | 'tachoProtocol'
        | 'tachoGeneration'
        | 'workflowType'
        | 'navigationType'
        | 'imageCapturingType'
        | 'inhibitorServiceType'
        | 'tellTaleServiceType'
        | 'primaryFuelTankType'
        | 'secondaryFuelTankType'
    > {
    tachograph?: Classification;
    tachoProtocol?: Classification;
    tachoGeneration?: Classification;
    workflowType?: Classification;
    navigationType?: Classification;
    imageCapturingType?: Classification;
    inhibitorServiceType?: Classification;
    tellTaleServiceType?: Classification;
    primaryFuelTankType?: Classification;
    secondaryFuelTankType?: Classification;
}

export interface ResolvedAdminDriverRead
    extends OmitFromGenerated<AdminDriverRead, 'smartCardLogin' | 'residenceCountryCode' | 'employersCountryCode'> {
    depot?: PojoModel<Depot>;
    smartCardLogin?: ResolvedSmartCardDriverLogin;
    driverCountry?: Country;
    companyCountry?: Country;
}

export interface ResolvedSmartCardDriverLogin extends OmitFromGenerated<SmartCardDriverLogin, 'nationNumCode'> {
    country?: PojoModel<Country>;
}

export interface ResolvedSmartCardDriverLoginChangelogChanges
    extends OmitFromGenerated<SmartCardDriverLoginChangelogChanges, 'nationNumCode'> {
    country?: PojoModel<Country>;
}

export interface ResolvedDriverChangelogChanges
    extends OmitFromGenerated<DriverChangelogChanges, 'depotId' | 'smartCardLogin' | 'manualLogin' | 'buttonLogin'> {
    depot?: PojoModel<Depot>;
    smartCardLogin?: PojoModel<SmartCardDriverLoginChangelogChanges>;
    manualLogin?: PojoModel<ManualDriverLoginChangelogChanges>;
    buttonLogin?: PojoModel<ButtonDriverLoginChangelogChanges>;
}

export interface ResolvedDriverChangelog extends OmitFromGenerated<DriverChangelog, 'before' | 'after'> {
    before?: ResolvedDriverChangelogChanges;
    after?: ResolvedDriverChangelogChanges;
}

export interface ChartPeriod {
    startDate: Date;
    stopDate: Date;
}

export interface ResolvedVehicleTypeChangelogChanges
    extends OmitFromGenerated<
        VehicleTypeChangelogChanges,
        'dimensions' | 'type' | 'axles' | 'emissionClass' | 'speedLimit'
    > {
    dimensions?: VehicleTypeDimensions;
    type?: Classification;
    axles?: Classification;
    emissionClass?: Classification;
    speedLimit?: SpeedLimit;
}

export type { DeepPartial };

export type ResolvedTranslateMessageResponse = PojoModel<TranslateMessageResponse>;
