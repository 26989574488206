import type { Dispatch } from 'redux';

import { clearDataAction, requestPasswordResetAction } from '~/data/resetpassword';

import type { ResetPasswordStoreState } from '../../reducers';

import type { RequestResetPasswordDispatchProps, RequestResetPasswordStateProps } from './component';

export const mapStateToProps = (resetPasswordState: ResetPasswordStoreState): RequestResetPasswordStateProps => ({
    resetPasswordResetStatus: resetPasswordState.lastServerResultStatus,
});

export const mapDispatchToProps = (dispatch: Dispatch): RequestResetPasswordDispatchProps => ({
    requestResetPassword: (customer: string, username: string) => {
        dispatch(requestPasswordResetAction(username, customer));
    },
    clearData: () => {
        dispatch(clearDataAction());
    },
});
