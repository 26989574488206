export enum ColumnName {
    CONTAINER = 'container',
    EVENTTYPE = 'event-type',
    EVENTDATETIME = 'event-date-time',
    BARRELFILLLEVEL = 'barrel-fill-level',
    BARRELPRESSURE = 'barrel-pressure',
    HEATINGSYSTEMON = 'heating-system-on',
    HEATINGSYSTEMTANKLEVEL = 'heating-system-tank-level',
    PRODUCTDENSITY = 'product-density',
    ODOMETER = 'odometer',
    TEMPERATURE = 'temperature',

    CITY = 'city',
    ADDRESS = 'address',
    POSTALCODE = 'postal-code',
    COUNTRYCODE = 'country-code',
    NEARESTCITY = 'nearest-city',
    ALTITUDE = 'altitude',
    LATITUDE = 'latitude',
    LONGITUDE = 'longitude',
}
