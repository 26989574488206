import { Button } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CompassIcon } from '~/components/Icons';
import { isUndefined } from '~/libs/utility';
import { copyCoordinatesToClipboard } from '~/services/Clipboard';

import type { CopyCoordinatesProps } from './models';
import { useStyles } from './styles';

const CopyCoordinatesComponent: FC<CopyCoordinatesProps> = (props) => {
    const { position } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const copyCoordinates = () => {
        if (!isUndefined(position)) {
            copyCoordinatesToClipboard(position);
        }
    };

    return (
        <Button
            color="secondary"
            data-id="copy-coordinates"
            className={classes.copyCoordinatesButton}
            disabled={isUndefined(position)}
            onClick={copyCoordinates}
        >
            <CompassIcon className={classes.iconMargin} />
            {t('copy-coordinates')}
        </Button>
    );
};

CopyCoordinatesComponent.displayName = 'CopyCoordinates';
export default CopyCoordinatesComponent;
