import type { AuthenticationFailedResponse, AuthenticationResponse } from '~/services/ApiClient';
import type { BaseAction, Fulfilled, FulfilledMeta, Pending, PendingMeta, Rejected, RejectedMeta } from '~/types';

import type { JwtDecodedToken } from './actions';

export enum ActionTypePrefixes {
    AUTHENTICATION = 'AUTHENTICATION',
    LOGOUT = 'LOGOUT',
    VERIFYTOKEN = 'VERIFYTOKEN',
    IMPERSONATEUSER = 'IMPERSONATEUSER',
    IMPERSONATIONENDED = 'IMPERSONATIONENDED',
}

export enum LoginTypes {
    USER = 'USER',
    CUSTOMERCARE = 'CUSTOMERCARE',
}

export enum ActionTypeKeys {
    AUTHENTICATION_PENDING = 'AUTHENTICATION_PENDING',
    AUTHENTICATION_FULFILLED = 'AUTHENTICATION_FULFILLED',
    AUTHENTICATION_REJECTED = 'AUTHENTICATION_REJECTED',
    LOGOUT_PENDING = 'LOGOUT_PENDING',
    LOGOUT_FULFILLED = 'LOGOUT_FULFILLED',
    LOGOUT_REJECTED = 'LOGOUT_REJECTED',
    VERIFYTOKEN_PENDING = 'VERIFYTOKEN_PENDING',
    VERIFYTOKEN_FULFILLED = 'VERIFYTOKEN_FULFILLED',
    VERIFYTOKEN_REJECTED = 'VERIFYTOKEN_REJECTED',
    IMPERSONATEUSER_PENDING = 'IMPERSONATEUSER_PENDING',
    IMPERSONATEUSER_FULFILLED = 'IMPERSONATEUSER_FULFILLED',
    IMPERSONATEUSER_REJECTED = 'IMPERSONATEUSER_REJECTED',
    IMPERSONATIONENDED_PENDING = 'IMPERSONATIONENDED_PENDING',
    IMPERSONATIONENDED_FULFILLED = 'IMPERSONATIONENDED_FULFILLED',
    IMPERSONATIONENDED_REJECTED = 'IMPERSONATIONENDED_REJECTED',
    TOKENEXPIRED = 'TOKENEXPIRED',
}

export type AuthenticationSuccessPayload =
    | Readonly<AuthenticationResponse>
    | Partial<Readonly<AuthenticationFailedResponse>>;

export interface LogoutSuccessPayload {}
export interface EndImpersonationSuccessPayload {}

export interface VerifyTokenSuccessPayload {
    decodedJwt: JwtDecodedToken;
    decodedImpersonatorJwt?: JwtDecodedToken;
}

export interface ImpersonateUserPayload {
    readonly token: string;
    readonly decoded: JwtDecodedToken;
}
export type ActionTypes =
    // Authentication
    | PendingMeta<ActionTypeKeys.AUTHENTICATION_PENDING, LoginTypes>
    | RejectedMeta<ActionTypeKeys.AUTHENTICATION_REJECTED, undefined, LoginTypes>
    | FulfilledMeta<ActionTypeKeys.AUTHENTICATION_FULFILLED, AuthenticationSuccessPayload, LoginTypes>

    // Logout
    | Pending<ActionTypeKeys.LOGOUT_PENDING>
    | Rejected<ActionTypeKeys.LOGOUT_REJECTED>
    | Fulfilled<ActionTypeKeys.LOGOUT_FULFILLED, LogoutSuccessPayload>

    // Verify
    | Pending<ActionTypeKeys.VERIFYTOKEN_PENDING>
    | Fulfilled<ActionTypeKeys.VERIFYTOKEN_FULFILLED, VerifyTokenSuccessPayload>
    | Rejected<ActionTypeKeys.VERIFYTOKEN_REJECTED>

    // Impersonate User
    | Pending<ActionTypeKeys.IMPERSONATEUSER_PENDING>
    | Fulfilled<ActionTypeKeys.IMPERSONATEUSER_FULFILLED, ImpersonateUserPayload>
    | Rejected<ActionTypeKeys.IMPERSONATEUSER_REJECTED>

    // End Impersonation
    | Pending<ActionTypeKeys.IMPERSONATIONENDED_PENDING>
    | Fulfilled<ActionTypeKeys.IMPERSONATIONENDED_FULFILLED, EndImpersonationSuccessPayload>
    | Rejected<ActionTypeKeys.IMPERSONATIONENDED_REJECTED>

    // Token expiration
    | BaseAction<ActionTypeKeys.TOKENEXPIRED>;
