import type { DisplayUserPreferencesDriverDisplayFormat } from '~/services/ApiClient';
import { formatDriverName } from '~/services/Formatters';

import type { HistoryDriverEntry } from '../../../../models';

export const getDriverNameValue =
    (driverDisplayNameFormat: DisplayUserPreferencesDriverDisplayFormat) =>
    (entry: HistoryDriverEntry): string => {
        const { driver } = entry;

        if (!driver) {
            return '';
        }

        return formatDriverName(driver, driverDisplayNameFormat);
    };

export const getDriverIdValue = (entry: HistoryDriverEntry): number | undefined => entry.driverEvent.driver?.id;
