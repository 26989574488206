import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const HeatingIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            {/* tslint:disable:max-line-length */}
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M4.76 11.88a6.67 6.67 0 0 1-2-5.79l.1-.67H5.77l-.14.92a3.89 3.89 0 0 0 1 3.42l.11.11a6.61 6.61 0 0 1 2 5.78l-.1.67h-3l.17-.92a4 4 0 0 0-1-3.43ZM17.32 12a4 4 0 0 1 1.05 3.43l-.17.92h3l.09-.67a6.6 6.6 0 0 0-2-5.78l-.11-.11a3.89 3.89 0 0 1-1-3.42l.14-.92h-3l-.09.67a6.69 6.69 0 0 0 2 5.79Zm-6.23 0a4 4 0 0 1 1.05 3.43l-.17.92h3l.09-.67a6.6 6.6 0 0 0-2-5.78l-.11-.11a3.89 3.89 0 0 1-1-3.42L12 5.42H9.05L9 6.09a6.69 6.69 0 0 0 2 5.79ZM20 19.62H4a2 2 0 0 0-2 2V22H22v-.38A2 2 0 0 0 20 19.62Z" />
        </SvgIcon>
    );
};
HeatingIcon.displayName = 'HeatingIcon';

export { HeatingIcon };
