import type { ClassNameMap, Theme } from '@mui/material';
import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme, { classes?: ClassNameMap<string> }>((theme) => ({
    root: {
        padding: theme.spacing(0.5),
        cursor: 'text',
        backgroundColor: 'transparent',
        height: 40,
        position: 'relative',
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            margin: theme.spacing(-0.5),
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.black, 0.26),
            pointerEvents: 'none',
        },
    },
    input: {
        height: theme.spacing(4),
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 14,
        color: theme.palette.primary.contrastText,
        opacity: 0.87,
        '&:focus-within $searchIcon': {
            color: alpha(theme.palette.primary.contrastText, 1),
        },
    },
    closeIcon: {
        margin: theme.spacing(0.5),
        color: alpha(theme.palette.primary.contrastText, 0.87),
        '&:hover': {
            color: alpha(theme.palette.primary.contrastText, 1),
            cursor: 'pointer',
        },
    },
    searchIcon: {
        margin: theme.spacing(0.5),
        color: alpha(theme.palette.primary.contrastText, 0.54),
    },
    inputPlaceholder: {
        fontStyle: 'italic',
    },
    typography: {
        display: 'flex',
    },
}));
