import { compose, setDisplayName, withProps } from 'react-recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import type { Omit } from 'utility-types';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { withTranslation } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps, SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory, SceneListViewHeaderFactory } from '~/components/SceneList';
import type { StoreState } from '~/reducers';
import { HistoryDriverEquals } from '~/scenes/History/historyDriverEntryEquals';
import type { Securables } from '~/services/ApiClient';

import type { HistoryDriverEntry } from '../../../../models';

import { filterHistoryDataSourceMemoized } from './filter';
import { getColumnsMemoized, getRowId } from './grid';
import { HISTORY_LIST_DRIVER_USERPREFERENCES_KEY, defaultDriverListUserPreferences } from './preferences';

export type DriverListViewProps = Omit<SceneListViewProps<HistoryDriverEntry>, 'columns' | 'gridDataId'>;

export type DriverListViewHeaderProps = Omit<
    SceneListViewHeaderProps<HistoryDriverEntry>,
    'columns' | 'excelSheetTitle'
>;

const DriverListViewComponent = SceneListViewFactory<HistoryDriverEntry>(
    HISTORY_LIST_DRIVER_USERPREFERENCES_KEY,
    defaultDriverListUserPreferences,
    getRowId,
    HistoryDriverEquals,
    filterHistoryDataSourceMemoized
);

const DriverListViewHeaderComponents = SceneListViewHeaderFactory<HistoryDriverEntry>(
    HISTORY_LIST_DRIVER_USERPREFERENCES_KEY,
    defaultDriverListUserPreferences,
    filterHistoryDataSourceMemoized,
    'history'
);

interface ReduxProps {
    securables: Securables;
}

const reduxSelector = createStructuredSelector<StoreState, ReduxProps>({
    securables: settingDataSelector(SettingsKey.SECURABLES),
});

export const DriverListView = compose<SceneListViewProps<HistoryDriverEntry>, DriverListViewProps>(
    setDisplayName('DriverListView'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps & ReduxProps) => ({
        columns: getColumnsMemoized(
            ownProps.t,
            ownProps.displayPreferences?.driverDisplayFormat,
            ownProps.displayPreferences?.vehicleDisplayFormat,
            ownProps.displayPreferences.unitSystem
        ),
        gridDataId: 'history-grid-driver',
    }))
)(DriverListViewComponent);

export const DriverListViewHeader = compose<SceneListViewHeaderProps<HistoryDriverEntry>, DriverListViewHeaderProps>(
    setDisplayName('VehicleListViewHeader'),
    withDisplayPreferences(),
    withTranslation(),
    connect(reduxSelector),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps & ReduxProps) => ({
        columns: getColumnsMemoized(
            ownProps.t,
            ownProps.displayPreferences?.driverDisplayFormat,
            ownProps.displayPreferences?.vehicleDisplayFormat,
            ownProps.displayPreferences.unitSystem
        ),
        excelSheetTitle: ownProps.t('history'),
    }))
)(DriverListViewHeaderComponents);
