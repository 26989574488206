import type { NumericDictionary } from '~/libs/utility';
import { keyBy, pick } from '~/libs/utility';
import type { DriverActivitiesUpdate, DriverActivity } from '~/services/ApiClient';

export const applyDriverActivitiesUpdate = (
    driverActivities: NumericDictionary<DriverActivity>,
    driverActivitiesUpdate: DriverActivitiesUpdate
): NumericDictionary<DriverActivity> => ({
    ...pick(
        driverActivities,
        Object.keys(driverActivities)
            .filter((activityId) => {
                const currentActivity = driverActivities[activityId];
                const updatedDriverActivity = driverActivitiesUpdate.updatedActivities.find(
                    ({ id }) => id === currentActivity.id
                );
                const isDeleted = driverActivitiesUpdate.deletedActivities.includes(currentActivity.id);

                const isUpdated = updatedDriverActivity && updatedDriverActivity.revision > currentActivity.revision;

                return !isDeleted && !isUpdated;
            })
            .map((x) => Number(x))
    ),
    ...keyBy(
        driverActivitiesUpdate.updatedActivities.filter((updatedActivity) => {
            const driverActivity = driverActivities[updatedActivity.id];

            const isNew = !driverActivity;
            const isUpdated = driverActivity && driverActivity.revision < updatedActivity.revision;

            return isNew || isUpdated;
        }),
        'id'
    ),
});
