import * as React from 'react';
import type { Subtract } from 'utility-types';

import { ClosedDoorIcon, OpenDoorIcon } from '~/components/Icons';
import type { CustomIconProps } from '~/components/Icons/customIconProps';
import { TrailerDoorStatusKeys } from '~/services/ApiClient';

export interface IconFormatterProps {
    value: string[];
    iconProps?: Subtract<CustomIconProps, { ref?: unknown }>;
}

export interface IconFormatterInnerProps extends IconFormatterProps {}

const IconFormatter: React.SFC<IconFormatterInnerProps> = ({ value, iconProps }) => {
    if (value.includes(TrailerDoorStatusKeys.OPEN)) {
        return <OpenDoorIcon {...iconProps} />;
    } else {
        return <ClosedDoorIcon {...iconProps} />;
    }
};
IconFormatter.displayName = 'IconFormatter';

export { IconFormatter };
