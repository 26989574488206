import { Box, Tooltip } from '@mui/material';
import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { MessageDirection } from '~/services/ApiClient';
import { formatMessageSource } from '~/services/Formatters';

import { IconFormatter } from './components/Icon';

export interface MessageSourceFormatterProps {
    t: SingleTFunction;
    value?: MessageDirection;
}

export interface MessageSourceFormatterInnerProps extends MessageSourceFormatterProps {}

const MessageSourceFormatter: React.FunctionComponent<MessageSourceFormatterInnerProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return (
        <Tooltip title={formatMessageSource(t, value)}>
            <Box display="inline-flex" data-id={`message-body-type:${value}`}>
                <IconFormatter value={value} />
            </Box>
        </Tooltip>
    );
};
MessageSourceFormatter.displayName = 'MessageSourceFormatter';

export { MessageSourceFormatter };
