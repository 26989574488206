import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withStaticDataProvider } from '~/common';
import { withSceneAnalytics, withScreenAnalytics } from '~/components/Analytics';
import { protectedComponentHoc } from '~/components/AuthenticationData';
import { requiredDisplayUserPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, ensureSettings } from '~/components/EnsureSettings';
import { keyDataStateSelectorFactory as etherealStateSelector } from '~/components/EtherealState';
import { withTranslation } from '~/components/LanguageSelector';
import { withMenu } from '~/components/Navigation';
import { requiredSceneAssetSelectorUserPreferencesFactory } from '~/components/SceneAssetSelector';
import { DETAILSPANE_USERPREFERENCES_KEY, requiredDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';
import type { MonitoringReduxProps, MonitoringStateProps } from '~/data/monitoring';
import {
    MONITORING_CONTAINER_ASSETSELECTOR_USERPREFERENCES_KEY,
    MONITORING_DATETIMERANGEPICKER_USERPREFERENCES_KEY,
    MONITORING_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY,
    MONITORING_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY,
    MONITORING_USERPREFERENCES_KEY,
    MONITORING_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY,
    dynamicMonitoringStateSelector,
    mapDispatchToProps,
    mapStateToProps,
    mergeProps,
    requiredMonitoringUserPreferences,
} from '~/data/monitoring';
import { ensureUserMessagingProfiles } from '~/modules/Communication/components/EnsureUserMessagingProfiles';
import { sceneMapRootStateSelector, settingsStoreStateSelector, staticDataStoreStateSelector } from '~/selectors';

import { MonitoringComponent } from './component';
import {
    requiredContainerDetailsPaneUserPreferences,
    requiredDriverSubpageUserPreferences,
    requiredHistorySubpageUserPreferences,
    requiredTrailerDetailsPaneUserPreferences,
    requiredVehicleDetailsPaneUserPreferences,
} from './components/DetailsPane';
import { requiredDriverPerspectiveSubpageUserPreferences } from './components/DetailsPane/components/Drivers/components/Sections/Staff/components/Summary/components/DriverSubpage/preferences';
import { requiredDriverStatusDetailsPaneUserPreferences } from './components/DetailsPane/components/Drivers/preferences';
import { MONITORING_DATETIMERANGE } from './consts';
import type { MonitoringInnerProps, MonitoringProps } from './models';
import { WidgetsStyleRules } from './styles';

export * from './models';
export * from './storestate';

export const Monitoring = compose<MonitoringInnerProps, MonitoringProps>(
    setDisplayName('Monitoring'),
    protectedComponentHoc,
    withMenu,
    withStaticDataProvider(),
    ensureSettings([
        SettingsKey.SECURABLES,
        SettingsKey.DEVICETYPES,
        SettingsKey.VEHICLECATEGORIES,
        SettingsKey.VEHICLETYPES,
        SettingsKey.DRIVERACTIVITYTYPES,
        SettingsKey.DRIVERSUBACTIVITYTYPES,
        SettingsKey.TRAILER_MANUFACTURERS,
        SettingsKey.REEFER_MANUFACTURERS,
        SettingsKey.REEFER_STATUS,
        SettingsKey.REEFER_ALARM,
        SettingsKey.REEFER_OPERATION_MODE,
        SettingsKey.REEFER_POWER_MODE,
        SettingsKey.REEFER_SPEED,
        SettingsKey.COMPARTMENT_STATUS,
        SettingsKey.DOOR_STATUS,
        SettingsKey.TRAILER_BATTERY_STATUS,
        SettingsKey.TRAILER_EVENT_TYPE,
        SettingsKey.TPMS_MANUFACTURER,
        SettingsKey.EBS_MANUFACTURER,
        SettingsKey.TRAILER_BATTERY_TYPE,
        SettingsKey.REEFER_BATTERY_TYPE,
        SettingsKey.REEFER_DATA_INTERFACE,
        SettingsKey.ROUTESTATUSTYPES,
        SettingsKey.CONTAINER_EVENT_TYPE,
        SettingsKey.CONTAINER_TEMPERATURE_TYPE,
        SettingsKey.VEHICLE_MESSAGING_CAPABILITIES,
        SettingsKey.VEHICLE_INHIBITOR_CAPABILITIES,
        SettingsKey.INHIBITOR_STATUS,
    ]),
    ensureUserPreferences([
        requiredSceneAssetSelectorUserPreferencesFactory(MONITORING_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredSceneAssetSelectorUserPreferencesFactory(MONITORING_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredSceneAssetSelectorUserPreferencesFactory(MONITORING_CONTAINER_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredSceneAssetSelectorUserPreferencesFactory(MONITORING_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredDisplayUserPreferences,
        requiredVehicleDetailsPaneUserPreferences,
        requiredTrailerDetailsPaneUserPreferences,
        requiredContainerDetailsPaneUserPreferences,
        requiredDriverStatusDetailsPaneUserPreferences,
        requiredMonitoringUserPreferences,
        requiredDriverSubpageUserPreferences,
        requiredHistorySubpageUserPreferences,
        requiredDetailsPaneUserPreferences,
        requiredDriverPerspectiveSubpageUserPreferences,
    ]),
    ensureUserMessagingProfiles(),
    withTranslation(),
    withStyles(WidgetsStyleRules),
    connect<MonitoringStateProps>(
        createSelector(
            staticDataStoreStateSelector,
            dynamicMonitoringStateSelector,
            keyDataStateSelectorFactory(MONITORING_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(MONITORING_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(MONITORING_CONTAINER_ASSETSELECTOR_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(MONITORING_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(MONITORING_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(DETAILSPANE_USERPREFERENCES_KEY),
            settingsStoreStateSelector,
            sceneMapRootStateSelector,
            etherealStateSelector(MONITORING_DATETIMERANGEPICKER_USERPREFERENCES_KEY, MONITORING_DATETIMERANGE),
            mapStateToProps
        ),
        mapDispatchToProps,
        mergeProps
    ),
    withSceneAnalytics('monitoring'),
    withScreenAnalytics<MonitoringReduxProps>((p) => `monitoring/${p.viewMode}/${p.perspective}`)
)(MonitoringComponent);
