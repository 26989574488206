import type { CalendarSpec } from 'moment';

import type { SingleTFunction } from '~/components/LanguageSelector';

export const getAdaptiveDateCalendarSpec = (t: SingleTFunction): CalendarSpec => {
    return {
        lastDay: `[${t('yesterday')}]`,
        sameDay: `[${t('today')}]`,
        lastWeek: 'dddd',
        sameElse: 'L',
        nextDay: `[${t('tomorrow')}]`,
        nextWeek: 'L',
    };
};
