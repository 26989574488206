import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: { marginRight: theme.spacing(0.5) },
    violation: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        marginRight: theme.spacing(0),
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        borderRadius: 3,
    },
}));
