import type { NumericDictionary } from '~/libs/utility';
import { omit } from '~/libs/utility';
import type { ResolvedConversation } from '~/services/ApiClient';
import { IncomingMessage } from '~/services/ApiClient';

import type { PendingMessageStatusUpdate } from './reducers';

export const filterPendingMessageStatusUpdate = (
    conversation: ResolvedConversation,
    pendingMessageStatusUpdates: NumericDictionary<PendingMessageStatusUpdate>
): NumericDictionary<PendingMessageStatusUpdate> => {
    const updatedMessages = conversation.messages.reduce((accumulator, { value: message }) => {
        const pendingMessageStatusUpdate = pendingMessageStatusUpdates[message.id];
        if (!pendingMessageStatusUpdate) {
            return accumulator;
        }

        if (
            message instanceof IncomingMessage &&
            message.status === pendingMessageStatusUpdates[message.id].expectedStatus
        ) {
            accumulator.push(message.id);
        }

        if (message.isDeleted === pendingMessageStatusUpdates[message.id].expectedStatusDeleted) {
            accumulator.push(message.id);
        }

        return accumulator;
    }, [] as number[]);

    return omit(pendingMessageStatusUpdates, updatedMessages);
};
