import type { AssetReference } from '~/services/ApiClient';

export const removeAssetsSelection = (
    selectedAssetIds: AssetReference[],
    assetIdsToBeDeselected: AssetReference[]
): AssetReference[] => {
    return selectedAssetIds.filter((id) => !assetIdsToBeDeselected.includes(id));
};

export const selectedAssetIdsBasedOnMode = (
    selectedAssetIds: AssetReference[] | undefined,
    assetIds: AssetReference[],
    singleSelectionMode?: boolean
): AssetReference[] => {
    if (singleSelectionMode) {
        if (selectedAssetIds) {
            if (selectedAssetIds.length === 1) {
                return selectedAssetIds;
            }

            if (selectedAssetIds.length > 1) {
                return selectedAssetIds.splice(-1, 1);
            }
        } else {
            return [];
        }
    }

    return selectedAssetIds || assetIds;
};
