import { Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection, SectionContentMessage } from '~/components/Sections';
import type { ResolvedDoorStatus } from '~/services/ApiClient';
import { formatDoorStatus } from '~/services/Formatters';

import { Door } from './components/Door';
import type { DoorsSectionClassKey } from './styles';

export interface DoorsSectionProps {
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
    doors: ResolvedDoorStatus[];
}

export interface DoorsSectionInnerProps
    extends InjectedTranslationProps,
        DoorsSectionProps,
        WithStyles<DoorsSectionClassKey> {}

export const DoorsSectionComponent: React.FC<DoorsSectionInnerProps> = ({
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
    t,
    doors,
    classes,
}) => {
    const renderContent = React.useCallback(() => {
        if (!doors.length) {
            return <SectionContentMessage dataId="no-doors-messages">{t('no-doors')}</SectionContentMessage>;
        }
        return doors.map((door) => {
            return <Door key={door.doorNumber} status={door} />;
        });
    }, [t, doors]);

    const collapsedHeaderElement = React.useMemo(() => {
        const doorStatuses = doors.map((door, index) => (
            <div key={door.doorNumber}>
                <Tooltip title={t('nth-door', { doorNumber: door.doorNumber })}>
                    <span data-id={`door-tooltip-${door.doorNumber}`}>{formatDoorStatus(t, door.status)}</span>
                </Tooltip>
                {index === doors.length - 1 ? undefined : ', '}
            </div>
        ));

        return <span className={classes.doorStatuses}>{doorStatuses}</span>;
    }, [t, doors, classes]);

    return (
        <ReorderableSection
            dataId="doors"
            title={t('doors')}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            rightHeaderElement={collapsedHeaderElement}
        >
            {renderContent()}
        </ReorderableSection>
    );
};
