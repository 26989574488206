import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { UnreadMessageCountBadge } from '../../../UnreadMessageCountBadge';

import type { ConversationHeaderClassKey } from './styles';

export interface ConversationHeaderProps {
    title: string;
    unreadTextMessageCount: number;
    unreadWorkflowMessageCount: number;
}

export interface ConversationHeaderInnerProps extends ConversationHeaderProps, WithStyles<ConversationHeaderClassKey> {}

export const ConversationHeaderComponent: React.FunctionComponent<ConversationHeaderInnerProps> = ({
    title,
    unreadTextMessageCount,
    unreadWorkflowMessageCount,
    classes,
}) => {
    return (
        <div className={classes.root} data-id="conversation-header">
            <Typography variant="subtitle2" data-id="conversation-title" className={classes.paneTitle}>
                {title}
            </Typography>

            <UnreadMessageCountBadge
                unreadTextMessageCount={unreadTextMessageCount}
                unreadWorkflowMessageCount={unreadWorkflowMessageCount}
            />
        </div>
    );
};
