import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import * as React from 'react';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { SectionName } from '../../constants';
import type { CommonSectionProps } from '../../models';

export interface TachographSectionInnerProps extends CommonSectionProps, InjectedTranslationProps {}

export const TachographSectionComponent: React.FunctionComponent<TachographSectionInnerProps> = ({
    adminVehicle,
    t,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
}) => {
    const keyValueItems: KeyValueItem[] = [
        {
            valueDataId: 'tachograph',
            label: t('tachograph'),
            value: adminVehicle.settings && (
                <span>{formatClassification(t, ClassificationType.Tachograph, adminVehicle.settings.tachograph)}</span>
            ),
        },
        {
            valueDataId: 'tacho-protocol',
            label: t('tacho-protocol'),
            value: adminVehicle.settings && (
                <span>
                    {formatClassification(t, ClassificationType.TachoProtocol, adminVehicle.settings.tachoProtocol)}
                </span>
            ),
        },
        {
            valueDataId: 'tacho-generation',
            label: t('tacho-generation'),
            value: adminVehicle.settings && (
                <span>
                    {formatClassification(t, ClassificationType.TachoGeneration, adminVehicle.settings.tachoGeneration)}
                </span>
            ),
        },
        {
            valueDataId: 'one-minute-rule-enabled',
            label: t('one-minute-rule-enabled'),
            value:
                adminVehicle.settings &&
                (adminVehicle.settings.oneMinuteRuleEnabled ? (
                    <CheckIcon data-id="true" />
                ) : (
                    <HighlightOffIcon data-id="false" />
                )),
            isIconValue: true,
        },
    ];

    return (
        <ReorderableSection
            dataId="vehicle-administration-tachograph-section"
            title={t(SectionName.VEHICLEADMINISTRATIONTACHOGRAPH)}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="tachograph-list" />
        </ReorderableSection>
    );
};
