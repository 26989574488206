import { Button, CircularProgress, List, ListItem, Typography } from '@mui/material';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProfileSubpageShell } from '~/common';
import { BannerType, ConfirmationDialog, LeftPane, TopBanner, WidgetDialog } from '~/components/Dialogs';
import { CloseIcon, VehicleTypesIcon } from '~/components/Icons';
import { BooleanControlType, BooleanInput, NumericInput, TextInput } from '~/components/InputFields';
import { AutocompleteDropdownInput } from '~/components/InputFields/AutocompleteDropdownInput';
import { TitledIconButton } from '~/components/TitledIconButton';
import { useUserPreferences } from '~/components/UserPreferences';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { Classification, DisplayUserPreferences, ResolvedVehicleType, VehicleType } from '~/services/ApiClient';
import {
    ClassificationType,
    DisplayUserPreferencesUnitSystem,
    EditorState,
    ServerResultStatus,
} from '~/services/ApiClient';
import { vehicleTypeAxlesComboDerivedClassificationValueProvider } from '~/services/DerivedClassifications';
import { formatClassification } from '~/services/Formatters';
import { PredefinedUserPreferencesKeys } from '~/services/PredefinedUserPreferences';

import {
    mapEmissionClassToClassificationAbstraction,
    mapToVehicleType,
    mapVehicleTypeAxlesComboToClassificationAbstraction,
} from './formDataMappers';
import { mergeDefaultValues } from './mergeDefaultValues';
import type { FieldNames, FieldValues } from './models';
import { buttonSubmitTitle, editorTitle } from './models';
import { useStyles } from './styles';

export interface VehicleTypeEditorProps {
    operationMode: EditorState;
    vehicleType?: ResolvedVehicleType;
    onClose: (isCancelled: boolean) => void;
}

export interface VehicleTypeEditorReduxStateProps {
    uniqueVehicleTypeNames: string[];
    vehicleTypeCategories: NumericDictionary<Classification>;
    axles: NumericDictionary<Classification>;
    emissionClasses: NumericDictionary<Classification>;
    updateVehicleTypeServerResultStatus?: ServerResultStatus;
    createVehicleTypeServerResultStatus?: ServerResultStatus;
}

export interface VehicleTypeEditorReduxDispatchProps {
    updateAdminVehicleType: (vehicleType: VehicleType) => void;
    resetAdminVehicleTypeUpdate: () => void;
    createAdminVehicleType: (vehicleType: VehicleType) => void;
    resetAdminVehicleTypeCreate: () => void;
}

export interface VehicleTypeEditorReduxProps
    extends VehicleTypeEditorReduxStateProps,
        VehicleTypeEditorReduxDispatchProps {}

export interface VehicleTypeEditorInnerProps extends VehicleTypeEditorProps, VehicleTypeEditorReduxProps {}

export const VehicleTypeEditorComponent: React.FC<VehicleTypeEditorInnerProps> = ({
    operationMode,
    vehicleType,
    onClose,
    uniqueVehicleTypeNames,
    vehicleTypeCategories,
    axles,
    emissionClasses,
    updateVehicleTypeServerResultStatus,
    updateAdminVehicleType,
    resetAdminVehicleTypeUpdate,
    createAdminVehicleType,
    createVehicleTypeServerResultStatus,
    resetAdminVehicleTypeCreate,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [displayUserPreferences] = useUserPreferences<DisplayUserPreferences>(PredefinedUserPreferencesKeys.DISPLAY);

    const [topBanner, setTopBanner] = useState<ReactNode>(undefined);
    const [openDiscardChangesDialog, setOpenDiscardChangesDialog] = useState(false);

    const generalSectionRef = useRef<HTMLDivElement>(null);
    const dimensionsSectionRef = useRef<HTMLDivElement>(null);
    const typeSectionRef = useRef<HTMLDivElement>(null);
    const speedLimitsSectionRef = useRef<HTMLDivElement>(null);

    const vehicleTypeAxlesDerivedClassification = vehicleTypeAxlesComboDerivedClassificationValueProvider(
        t,
        vehicleTypeCategories,
        axles
    );
    const initialValues = mergeDefaultValues(
        vehicleTypeAxlesDerivedClassification,
        displayUserPreferences.unitSystem,
        vehicleType
    );
    const form = useForm<FieldValues>({
        mode: 'all',
        defaultValues: initialValues,
    });
    const { formState, setValue, handleSubmit } = form;

    const dirtyFields = Object.keys(formState.dirtyFields);
    const dirtyFieldsCount = dirtyFields.length;
    const formIsValid = formState.isValid;
    const requestIsPending =
        updateVehicleTypeServerResultStatus === ServerResultStatus.PENDING ||
        createVehicleTypeServerResultStatus === ServerResultStatus.PENDING;

    const resetFormState = useCallback(() => {
        switch (operationMode) {
            case EditorState.MODIFY:
                resetAdminVehicleTypeUpdate();
                break;
            case EditorState.NEW:
                resetAdminVehicleTypeCreate();
                break;
            default:
                throw Error(`Provided operation mode ${operationMode} is not supported`);
        }
    }, [operationMode, resetAdminVehicleTypeCreate, resetAdminVehicleTypeUpdate]);
    const closeForm = useCallback(
        (isCancelled: boolean) => {
            onClose(isCancelled);
            resetFormState();
        },
        [onClose, resetFormState]
    );
    const closeBanner = React.useCallback(() => {
        resetFormState();
        setTopBanner(undefined);
    }, [resetFormState]);
    const openBanner = React.useCallback(
        (bannerTitle: string, dataId: string, type: BannerType) => {
            setTopBanner(<TopBanner title={bannerTitle} dataId={dataId} onDismiss={closeBanner} type={type} />);
        },
        [closeBanner]
    );
    const scrollTo = (position: React.RefObject<HTMLDivElement>) => {
        if (position.current !== null) {
            position.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };
    const cancelUpdateHandler = () => {
        if (dirtyFieldsCount) {
            setOpenDiscardChangesDialog(true);
        } else {
            closeForm(true);
        }
    };
    const discardChangesCancelledHandler = () => {
        setOpenDiscardChangesDialog(false);
    };
    const fallbackToValidValue = (field: FieldNames) => {
        if (formState.errors[field]) {
            setValue(field, initialValues[field], { shouldValidate: true, shouldDirty: true });
        }
    };
    const submitForm = (data: FieldValues) => {
        const vehicleTypeMapped = mapToVehicleType({
            typeAxlesDerivedClassifications: vehicleTypeAxlesDerivedClassification,
            unitSystem: displayUserPreferences.unitSystem,
            data,
            originalId: vehicleType?.id,
        });

        switch (operationMode) {
            case EditorState.MODIFY:
                updateAdminVehicleType(vehicleTypeMapped);
                break;
            case EditorState.NEW:
                createAdminVehicleType(vehicleTypeMapped);
                break;
            default:
                throw Error(`Provided operation mode ${operationMode} is not supported`);
        }
    };

    useEffect(() => {
        let requestStatus;

        switch (operationMode) {
            case EditorState.MODIFY:
                requestStatus = updateVehicleTypeServerResultStatus;
                break;
            case EditorState.NEW:
                requestStatus = createVehicleTypeServerResultStatus;
                break;
            default:
                throw Error(`Provided operation mode ${operationMode} is not supported`);
        }
        if (isUndefined(requestStatus)) {
            form.trigger();
            if (!formIsValid) {
                openBanner(
                    t('topbanner-message-validation-errors'),
                    'vehicle-type-update-dialog-banner-form-validation-errors',
                    BannerType.Error
                );
            } else if (dirtyFieldsCount > 0) {
                openBanner(
                    t('topbanner-message-fields-changed', {
                        fieldCount: dirtyFieldsCount,
                    }),
                    'vehicle-update-dialog-banner-fields-changed',
                    BannerType.Warning
                );
            } else {
                closeBanner();
            }
        } else {
            switch (requestStatus) {
                case ServerResultStatus.PENDING:
                    setTopBanner(undefined);
                    break;
                case ServerResultStatus.OK:
                    closeForm(false);
                    break;
                case ServerResultStatus.BADREQUEST:
                    openBanner(
                        t('server-message-bad-request'),
                        'vehicle-type-update-dialog-banner-server-warning',
                        BannerType.Warning
                    );
                    break;
                case ServerResultStatus.SERVERERROR:
                    openBanner(
                        t('server-message-internal-error'),
                        'vehicle-type-update-dialog-banner-server-error',
                        BannerType.Error
                    );
                    break;
                default:
                    closeBanner();
            }
        }
    }, [
        closeBanner,
        closeForm,
        createVehicleTypeServerResultStatus,
        dirtyFieldsCount,
        form,
        formIsValid,
        openBanner,
        operationMode,
        t,
        updateVehicleTypeServerResultStatus,
    ]);

    const discardChangesDialog = (
        <ConfirmationDialog
            title={t('asset-editor-close-confirmation-title')}
            open={openDiscardChangesDialog}
            onConfirm={() => closeForm(true)}
            confirmationActionText={t('discard')}
            onCancel={discardChangesCancelledHandler}
            dataId="discard-changes-dialog"
        >
            <Typography>{t('asset-editor-close-confirmation-prompt')}</Typography>
        </ConfirmationDialog>
    );

    const closeIcon = (
        <TitledIconButton
            title={t('close')}
            disabled={requestIsPending}
            onClick={() => closeForm(true)}
            className={classes.closeIcon}
            data-id="dialog-header-close-icon"
        >
            <CloseIcon />
        </TitledIconButton>
    );

    const actionButtons = (
        <>
            <Button
                color="secondary"
                disabled={requestIsPending}
                onClick={cancelUpdateHandler}
                className={classes.actionButton}
                data-id="cancel-button"
            >
                {t('cancel')}
            </Button>
            <Button
                color="secondary"
                variant="contained"
                form="vehicle-type-editor-form"
                type="submit"
                disabled={!formIsValid || dirtyFieldsCount === 0 || requestIsPending}
                className={classes.actionButton}
                data-id="save-button"
            >
                {requestIsPending ? (
                    <CircularProgress size={30} className={classes.backdrop} />
                ) : (
                    t(buttonSubmitTitle[operationMode])
                )}
            </Button>
        </>
    );

    const getLeftPaneElement = () => {
        const leftPaneHeader = (
            <ProfileSubpageShell
                icon={<VehicleTypesIcon className={classes.vehicleTypeIcon} />}
                primaryTitle={vehicleType?.name ?? t('new-vehicle-type')}
                secondaryTitle={
                    vehicleType?.type
                        ? formatClassification(t, ClassificationType.VehicleTypeCategory, vehicleType.type)
                        : ''
                }
            />
        );

        return (
            <LeftPane
                header={leftPaneHeader}
                classes={{
                    root: classNames(classes.leftPane),
                }}
            >
                <List>
                    <ListItem className={classes.sectionsListItem}>
                        <Typography
                            color="secondary"
                            variant="button"
                            onClick={() => scrollTo(generalSectionRef)}
                            data-id="vehicle-type-editor-general-section-link"
                        >
                            {t('vehicle-type-editor-section-general')}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.sectionsListItem}>
                        <Typography
                            color="secondary"
                            variant="button"
                            onClick={() => scrollTo(dimensionsSectionRef)}
                            data-id="vehicle-type-editor-dimensions-section-link"
                        >
                            {t('vehicle-type-editor-section-dimensions')}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.sectionsListItem}>
                        <Typography
                            color="secondary"
                            variant="button"
                            onClick={() => scrollTo(typeSectionRef)}
                            data-id="vehicle-type-editor-type-section-link"
                        >
                            {t('vehicle-type-editor-section-type')}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.sectionsListItem}>
                        <Typography
                            color="secondary"
                            variant="button"
                            onClick={() => scrollTo(speedLimitsSectionRef)}
                            data-id="vehicle-type-editor-speedlimits-section-link"
                        >
                            {t('vehicle-type-editor-section-speedlimits')}
                        </Typography>
                    </ListItem>
                </List>
            </LeftPane>
        );
    };

    const getGeneralSectionFormFields = () => {
        const className = classNames(classes.baseInput, classes.textInput);

        const validateNameIsUnique = (name: string): boolean | string =>
            !uniqueVehicleTypeNames.includes(name) ? true : t('name-not-unique-field-error');

        const nameField = (
            <TextInput
                fieldName="name"
                label={t('name')}
                dataId="editor-field-name"
                validationRules={{
                    validate: {
                        'name-is-unique': validateNameIsUnique,
                    },
                }}
                required
                requiredText={t('name-should-be-populated-field-error')}
                maxLength={20}
                markValueChange
                className={className}
            />
        );

        const descriptionField = (
            <TextInput
                fieldName="description"
                label={t('description')}
                dataId="editor-field-description"
                maxLength={40}
                markValueChange
                className={className}
            />
        );

        return (
            <>
                {nameField}
                {descriptionField}
            </>
        );
    };

    const getDimensionsSectionFormFields = () => {
        const className = classNames(classes.baseInput, classes.dimensionsNumberInput);

        const heightField = (
            <NumericInput
                fieldName="height"
                label={t('height')}
                dataId="editor-field-height"
                minValue={0}
                maxValue={9.99}
                markValueChange
                onBlurChanged={() => fallbackToValidValue('height')}
                className={className}
                adornment={t('unit-m')}
                supportsDecimals
                decimalPrecision={2}
            />
        );

        const widthField = (
            <NumericInput
                fieldName="width"
                label={t('width')}
                dataId="editor-field-width"
                minValue={0}
                maxValue={9.99}
                markValueChange
                onBlurChanged={() => fallbackToValidValue('width')}
                className={className}
                adornment={t('unit-m')}
                supportsDecimals
                decimalPrecision={2}
            />
        );

        const lengthField = (
            <NumericInput
                fieldName="length"
                label={t('length')}
                dataId="editor-field-length"
                minValue={0}
                maxValue={30}
                markValueChange
                onBlurChanged={() => fallbackToValidValue('length')}
                className={className}
                adornment={t('unit-m')}
                supportsDecimals
                decimalPrecision={2}
            />
        );

        const weightField = (
            <NumericInput
                fieldName="weight"
                label={t('weight')}
                dataId="editor-field-weight"
                minValue={0}
                maxValue={84.2}
                markValueChange
                onBlurChanged={() => fallbackToValidValue('weight')}
                className={className}
                adornment={t('unit-ton')}
                supportsDecimals
                decimalPrecision={2}
            />
        );

        return (
            <>
                {heightField}
                {widthField}
                {lengthField}
                {weightField}
            </>
        );
    };

    const getTypeSectionFormFields = () => {
        const className = classNames(classes.baseInput, classes.selectInput);

        return (
            <>
                <div className={classes.select}>
                    <AutocompleteDropdownInput
                        className={className}
                        dataId="editor-field-type"
                        fieldName="type"
                        label={t('type')}
                        markValueChange
                        required
                        values={mapVehicleTypeAxlesComboToClassificationAbstraction(t, vehicleTypeCategories, axles)}
                    />

                    <AutocompleteDropdownInput
                        className={className}
                        dataId="editor-field-emission-class"
                        defaultValue={0}
                        fieldName="emissionClass"
                        label={t('emission-class')}
                        markValueChange
                        required
                        values={mapEmissionClassToClassificationAbstraction(t, emissionClasses)}
                    />
                </div>

                <div className={classes.fieldContainer}>
                    <Typography data-id="editor-field-hazardous-goods-label">{t('hazardous-goods')}</Typography>
                    <BooleanInput
                        controlType={BooleanControlType.ToggleButton}
                        fieldName="hazardousGoods"
                        dataId="editor-field-hazardous-goods"
                        required
                        markValueChange
                    />
                </div>
            </>
        );
    };

    const getSpeedLimitsSectionFormFields = () => {
        const className = classNames(classes.baseInput, classes.speedNumberInput);
        const speedUnitAdornment =
            displayUserPreferences.unitSystem === DisplayUserPreferencesUnitSystem.Metric
                ? t('unit-kmph')
                : t('unit-mph');

        const motorwaySpeedLimitField = (
            <NumericInput
                fieldName="speedLimitMotorway"
                label={t('speed-limit-motorway')}
                dataId="editor-field-speedlimit-motorway"
                minValue={0}
                maxValue={255}
                markValueChange
                onBlurChanged={() => fallbackToValidValue('speedLimitMotorway')}
                className={className}
                adornment={speedUnitAdornment}
            />
        );

        const roadSpeedLimitField = (
            <NumericInput
                fieldName="speedLimitRoad"
                label={t('speed-limit-road')}
                dataId="editor-field-speedlimit-road"
                minValue={0}
                maxValue={255}
                markValueChange
                onBlurChanged={() => fallbackToValidValue('speedLimitRoad')}
                className={className}
                adornment={speedUnitAdornment}
            />
        );

        const citySpeedLimitField = (
            <NumericInput
                fieldName="speedLimitCity"
                label={t('speed-limit-city')}
                dataId="editor-field-speedlimit-city"
                minValue={0}
                maxValue={255}
                markValueChange
                onBlurChanged={() => fallbackToValidValue('speedLimitCity')}
                className={className}
                adornment={speedUnitAdornment}
            />
        );

        return (
            <>
                {motorwaySpeedLimitField}
                {roadSpeedLimitField}
                {citySpeedLimitField}
            </>
        );
    };

    return (
        <>
            {discardChangesDialog}
            <div data-id="editor-root" className={classes.root}>
                <WidgetDialog
                    title={t(editorTitle[operationMode])}
                    open
                    headerActions={[closeIcon]}
                    dialogActions={actionButtons}
                    testId="vehicle-type-editor"
                >
                    <>
                        {topBanner}
                        <div className={classes.body}>
                            {getLeftPaneElement()}
                            <FormProvider {...form}>
                                <form
                                    id="vehicle-type-editor-form"
                                    onSubmit={handleSubmit(submitForm)}
                                    className={classNames(classes.form)}
                                    data-id="vehicle-type-editor-form"
                                >
                                    <div data-id="form-content">
                                        <Typography
                                            color="textSecondary"
                                            variant="subtitle1"
                                            className={classes.sectionTitle}
                                            ref={generalSectionRef}
                                            data-id="vehicle-type-editor-section-general"
                                        >
                                            {t('vehicle-type-editor-section-general')}
                                        </Typography>
                                        {getGeneralSectionFormFields()}
                                        <Typography
                                            color="textSecondary"
                                            variant="subtitle1"
                                            className={classes.sectionTitle}
                                            ref={dimensionsSectionRef}
                                            data-id="vehicle-type-editor-section-dimensions"
                                        >
                                            {t('vehicle-type-editor-section-dimensions')}
                                        </Typography>
                                        {getDimensionsSectionFormFields()}
                                        <Typography
                                            color="textSecondary"
                                            variant="subtitle1"
                                            className={classes.sectionTitle}
                                            ref={typeSectionRef}
                                            data-id="vehicle-type-editor-section-type"
                                        >
                                            {t('vehicle-type-editor-section-type')}
                                        </Typography>
                                        {getTypeSectionFormFields()}
                                        <Typography
                                            color="textSecondary"
                                            variant="subtitle1"
                                            className={classes.sectionTitle}
                                            ref={speedLimitsSectionRef}
                                            data-id="vehicle-type-editor-section-speedlimits"
                                        >
                                            {t('vehicle-type-editor-section-speedlimits')}
                                        </Typography>
                                        {getSpeedLimitsSectionFormFields()}
                                    </div>
                                </form>
                            </FormProvider>
                        </div>
                    </>
                </WidgetDialog>
            </div>
        </>
    );
};
