import { createAction } from 'redux-actions';

import type { DeviceChangelogResponse } from '~/services/ApiClient';
import { ApiClient, ApiException, ServerResultStatus, retryableRequest } from '~/services/ApiClient';

import { ACTION_TYPE_PREFIXES } from './actionTypes';

const getAdministrationDevices = createAction(ACTION_TYPE_PREFIXES.LIST, () =>
    retryableRequest(() => ApiClient.getAdministrationDevices())
);

const getDeviceAdministrationChangelogsExecutor = async (deviceId: number): Promise<DeviceChangelogResponse> => {
    try {
        const data = await ApiClient.getDeviceChangelogs(deviceId);
        return data;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            throw ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

export { getAdministrationDevices, getDeviceAdministrationChangelogsExecutor };
