import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type UnresolvedConversationWorkflowBodyClassKey =
    | 'body'
    | 'element'
    | 'readOnlyRoot'
    | 'readOnlyUnderline'
    | 'readOnlyUnderlineDisabled';

export const useStyles = makeStyles<Theme, {}, UnresolvedConversationWorkflowBodyClassKey>((theme) => ({
    body: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    element: {
        padding: theme.spacing(),
        flex: '1 1 33%',
    },
    readOnlyRoot: {
        borderRadius: 4,
    },
    readOnlyUnderline: {
        '&:before': { border: 'none' },
        '&:after': { border: 'none' },
        '&:hover:before': { border: 'none' },
        '&:disabled:before': { border: 'none' },
    },
    readOnlyUnderlineDisabled: {
        '&:before': { borderBottom: 0 },
    },
}));
