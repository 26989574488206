import type { ChangelogEntry } from '~/common';
import { cleanUpValues } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import { isUndefined } from '~/libs/utility';
import type {
    Country,
    DriverChangelogChanges,
    ResolvedAdminDriverRead,
    ResolvedDriverChangelogChanges,
    ResolvedSmartCardDriverLogin,
    SmartCardDriverLogin,
} from '~/services/ApiClient';

import type { NormalizedChangelogArgs, ParseDriverAdminChangelogsEntriesArgs, ResolveAdminDriversArgs } from './models';

const resolveSmartCardLogin = (
    card: SmartCardDriverLogin,
    settings: SettingsStoreState
): ResolvedSmartCardDriverLogin => {
    let driverCountry;
    Object.entries(settings.countries.data).forEach(([code, country]) => {
        if (country.nationNumCode === card.nationNumCode) {
            driverCountry = settings.countries.data[code];
        }
    });

    return {
        ...card,
        country: driverCountry,
    };
};

const resolveAdminDriverCountry = (countryCode: string, settings: SettingsStoreState): Country => {
    const country: Country = Object.values(settings.countries.data).find(({ code }) => code === countryCode);
    return country;
};

const resolveAdminDrivers = ({
    adminDrivers,
    settingsStoreState,
    staticDataStoreState,
}: ResolveAdminDriversArgs): ResolvedAdminDriverRead[] => {
    const resolvedAdminDrivers: ResolvedAdminDriverRead[] = adminDrivers.map((driver) => ({
        driverId: isUndefined(driver) ? 0 : driver.driverId,
        firstName: isUndefined(driver) ? undefined : driver.firstName,
        lastName: isUndefined(driver) ? undefined : driver.lastName,
        alias: isUndefined(driver) ? undefined : driver.alias,
        loginName: isUndefined(driver) ? undefined : driver.loginName,
        manualLogin: isUndefined(driver) ? undefined : driver.manualLogin,
        smartCardLogin: isUndefined(driver.smartCardLogin)
            ? undefined
            : resolveSmartCardLogin(driver.smartCardLogin, settingsStoreState),
        buttonLogin: isUndefined(driver) ? undefined : driver.buttonLogin,
        culture: isUndefined(driver) ? undefined : driver.culture,
        wTDStartDate: isUndefined(driver) ? undefined : driver.wTDStartDate,
        depotId: isUndefined(driver.depotId) ? undefined : driver.depotId,
        depot: isUndefined(driver.depotId) ? undefined : staticDataStoreState.depots.data.hash[driver.depotId],
        isLoggedIn: isUndefined(driver.isLoggedIn) ? false : driver.isLoggedIn,
        driverCountry: isUndefined(driver.residenceCountryCode)
            ? undefined
            : resolveAdminDriverCountry(driver.residenceCountryCode, settingsStoreState),
        companyCountry: isUndefined(driver.employersCountryCode)
            ? undefined
            : resolveAdminDriverCountry(driver.employersCountryCode, settingsStoreState),
        uaid: driver.uaid,
    }));

    return resolvedAdminDrivers;
};

const normalizedChangelog = ({ changes, staticData }: NormalizedChangelogArgs): ResolvedDriverChangelogChanges => {
    const cleanedChanges = cleanUpValues<DriverChangelogChanges | undefined, ResolvedDriverChangelogChanges>(changes);

    return {
        ...cleanedChanges,
        depot: staticData?.depots.data.hash[changes?.depotId as number],
    };
};

const parseDriverAdminChangelogsEntries = ({
    changelogs = [],
    staticData,
}: ParseDriverAdminChangelogsEntriesArgs): ChangelogEntry<ResolvedDriverChangelogChanges>[] =>
    changelogs.map(({ before, after, ...changelog }) => ({
        ...changelog,
        before: normalizedChangelog({ changes: before, staticData }),
        after: normalizedChangelog({ changes: after, staticData }),
    }));

export { resolveAdminDriverCountry, resolveSmartCardLogin, resolveAdminDrivers, parseDriverAdminChangelogsEntries };
