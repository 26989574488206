import type { i18n } from 'i18next';

import { getI18n } from '~/i18n';
import { memoizeOne } from '~/services/Memoize';

import type { SingleTFunction } from '../models';

import { useLanguageCode } from './useLanguageCode';

export interface Translation {
    i18n: i18n;
    t: SingleTFunction;
}

const getI18nMemoized = memoizeOne(getI18n);
const getFixedTMemoized = memoizeOne((lng: string | null, i18n: i18n): SingleTFunction => {
    // Wrapping the original TFunction, to a version which ALWAYS returns a string
    const namespace = i18n.options.defaultNS ? i18n.options.defaultNS : 'translation';
    const t = lng ? i18n.getFixedT(lng, namespace) : i18n.getFixedT(null, namespace);
    return (key, options) => t(key, options) || '';
});

export const useTranslation = (): Translation => {
    const languageCode = useLanguageCode();
    const i18n = getI18nMemoized();
    const t = getFixedTMemoized(languageCode ?? null, i18n);

    return { i18n, t };
};
