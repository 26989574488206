import moment from 'moment';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DurationInput, validateDurationValue } from '~/components/InputFields';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { DurationFieldWorkflowFormElement } from '~/services/ApiClient';

export interface DurationWorkflowFieldProps {
    field: DurationFieldWorkflowFormElement;
    label?: string;
    dataId: string;
    disabled?: boolean;
}

export interface DurationWorkflowFieldInnerProps extends DurationWorkflowFieldProps, InjectedTranslationProps {}

export const DurationWorkflowFieldComponent: React.FC<DurationWorkflowFieldInnerProps> = ({
    field,
    label,
    dataId,
    disabled,
    t,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const error = errors[field.id];
    const dayToken = React.useMemo(() => t('unit-day'), [t]);
    const minDuration = React.useMemo(() => {
        if (!field.minValue) {
            return undefined;
        }

        const durationValue = moment.duration(field.minValue);

        if (!durationValue || !durationValue.isValid()) {
            return undefined;
        }

        return durationValue;
    }, [field.minValue]);

    const maxDuration = React.useMemo(() => {
        if (!field.maxValue) {
            return undefined;
        }

        const durationValue = moment.duration(field.maxValue);

        if (!durationValue || !durationValue.isValid()) {
            return undefined;
        }

        return durationValue;
    }, [field.maxValue]);

    const rules = React.useMemo(() => {
        return {
            validate: {
                'duration-validation': (data: string) =>
                    validateDurationValue(data, dayToken, t, field.isRequired, minDuration, maxDuration),
            },
        };
    }, [field.isRequired, maxDuration, minDuration, dayToken, t]);

    return (
        <Controller
            name={`${field.id}`}
            defaultValue={null}
            control={control}
            rules={rules}
            render={({ field: fieldFormProps }) => {
                return (
                    <DurationInput
                        {...fieldFormProps}
                        fieldName={`${field.id}`}
                        dataId={dataId}
                        label={label}
                        hasError={!!error}
                        errorText={error?.message as unknown as string}
                        disabled={disabled}
                        readonly={field.isReadOnly}
                        required={field.isRequired}
                        defaultValue={fieldFormProps.value}
                        onValueChanged={(value) => fieldFormProps.onChange(value)}
                        onBlurChanged={(value) => fieldFormProps.onChange(value)}
                    />
                );
            }}
        />
    );
};
