import { createSelector } from 'reselect';

import { isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';

import type { RtdsSessionEntry } from '../../models';
import { rtdsSessionEntriesSelector } from '../../selectors';

export interface RtdsSessionContextMenuReduxProps {
    sessionId?: number;
}

export interface RtdsSessionContextMenuReduxState {
    sessionCanBeAborted: boolean | undefined;
}

export const mapStateToProps = (
    rtdsSessionsEntries: RtdsSessionEntry[],
    ownProps: RtdsSessionContextMenuReduxProps
): RtdsSessionContextMenuReduxState => {
    if (isUndefined(ownProps.sessionId)) {
        return {
            sessionCanBeAborted: false,
        };
    }

    const sessionEntry = rtdsSessionsEntries.find((it) => it.session.id.id === ownProps.sessionId);

    return {
        sessionCanBeAborted: sessionEntry?.session.canBeAborted,
    };
};

export const reduxPropsSelector: (
    storeState: StoreState,
    ownProps: RtdsSessionContextMenuReduxProps
) => RtdsSessionContextMenuReduxState = createSelector(
    rtdsSessionEntriesSelector,
    (_: never, ownProps: RtdsSessionContextMenuReduxProps) => ownProps,
    mapStateToProps
);
