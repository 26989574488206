import { Avatar } from '@mui/material';
import type { StyledComponentProps, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { CheckIcon, ExclamationIcon } from '~/components/Icons';
import { isUndefined } from '~/libs/utility';
import type { TemperatureSensorStatus } from '~/services/ApiClient';

import type { TemperatureStatusIndicatorClassKey } from './styles';

export interface TemperatureStatusIndicatorProps {
    temperatureSensors: TemperatureSensorStatus[];
    minTemperature?: number;
    maxTemperature?: number;
}

export interface TemperatureStatusIndicatorOuterProps
    extends TemperatureStatusIndicatorProps,
        StyledComponentProps<TemperatureStatusIndicatorClassKey> {}

export interface TemperatureStatusIndicatorInnerProps
    extends TemperatureStatusIndicatorProps,
        WithStyles<TemperatureStatusIndicatorClassKey> {}

export const TemperatureStatusIndicatorComponent: React.FC<TemperatureStatusIndicatorInnerProps> = ({
    temperatureSensors,
    minTemperature,
    maxTemperature,
    classes,
}) => {
    const shouldShowWarning =
        temperatureSensors &&
        temperatureSensors.some((temp) => {
            return (
                (!isUndefined(minTemperature) && temp.value < minTemperature) ||
                (!isUndefined(maxTemperature) && temp.value > maxTemperature)
            );
        });

    const displayIcon = shouldShowWarning ? <ExclamationIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />;

    const iconClass = classNames(classes.root, {
        [classes.warningIcon]: shouldShowWarning,
        [classes.okIcon]: !shouldShowWarning,
    });

    return <Avatar className={iconClass}>{displayIcon}</Avatar>;
};
