import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DaySeparatorClassKey = 'root' | 'typography';

export const DaySeparatorStyleRules: StyleRulesCallback<Theme, {}, DaySeparatorClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            display: 'inline-block',
            padding: theme.spacing(0.5, 1.5),
        },
        typography: {
            lineHeight: 1,
        },
    });
