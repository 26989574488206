import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { PositionDialogInnerProps, PositionDialogProps } from './component';
import { PositionDialogComponent } from './component';
import { PositionDialogStyleRules } from './styles';

export const PositionDialog = compose<PositionDialogInnerProps, PositionDialogProps>(
    setDisplayName('PositionDialog'),
    withStyles(PositionDialogStyleRules, { withTheme: true }),
    withTranslation()
)(PositionDialogComponent);
