import { Avatar } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { TruckIcon } from '~/components/Icons';
import type { DisplayUserPreferencesVehicleDisplayFormat } from '~/services/ApiClient';

import type { ConversationSummaryEntry } from '../../../../conversationSummaryEntry';

import { ConversationSummaryFirstLine } from './components/ConversationSummaryFirstLine';
import { ConversationSummarySecondLine } from './components/ConversationSummarySecondLine';
import type { ConversationSummaryClassKey } from './styles';

export interface ConversationSummaryProps {
    conversationSummaryEntry: ConversationSummaryEntry;
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat;
    openConversation: (vehicleId: number) => void;
    retrieveWorkflowFormDefinition: (formId: number) => void;
}

export interface ConversationSummaryInnerProps
    extends ConversationSummaryProps,
        WithStyles<ConversationSummaryClassKey> {}

export const ConversationSummaryComponent: React.FC<ConversationSummaryInnerProps> = ({
    classes,
    conversationSummaryEntry,
    vehicleDisplayFormat,
    openConversation,
    retrieveWorkflowFormDefinition,
}) => {
    const handleClick = React.useCallback(() => {
        openConversation(conversationSummaryEntry.vehicle.id);
    }, [openConversation, conversationSummaryEntry.vehicle.id]);

    const rootClassName = classNames(classes.root, {
        [classes.disabled]: !conversationSummaryEntry.capabilities.textMessagingEnabled,
    });

    return (
        <div
            className={rootClassName}
            onClick={handleClick}
            data-id={`conversation-summary:${conversationSummaryEntry.vehicle.id}`}
        >
            <Avatar className={classes.avatar} data-id="vehicle-avatar">
                <TruckIcon />
            </Avatar>

            <div className={classes.body}>
                <ConversationSummaryFirstLine
                    conversationSummaryEntry={conversationSummaryEntry}
                    vehicleDisplayFormat={vehicleDisplayFormat}
                />

                <ConversationSummarySecondLine
                    conversationSummary={conversationSummaryEntry.conversationSummary}
                    retrieveWorkflowFormDefinition={retrieveWorkflowFormDefinition}
                />
            </div>
        </div>
    );
};
