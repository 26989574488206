import { getI18n } from '~/i18n';

import { EDITOR_TITLE } from './constants';

const getEditorTitle = (title: string) => {
    const { t } = getI18n();

    if (!EDITOR_TITLE[title]) {
        throw Error(`Provided editor state ${title} is not supported.`);
    }

    return t(EDITOR_TITLE[title]);
};

export { getEditorTitle };
