export const exportFormatterFactory =
    <T, C>(formatter: (v: T) => C) =>
    (v?: T): C | undefined => {
        if (v === undefined) {
            return undefined;
        }

        return formatter(v);
    };

export const exportFormatterIncludingUndefinedFactory =
    <T, C>(formatter: (v: T | undefined) => C) =>
    (v?: T): C => {
        return formatter(v);
    };
