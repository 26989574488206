import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { CommunicationEntry } from '~/data/communication';
import type { NumericDictionary } from '~/libs/utility';
import { keyBy } from '~/libs/utility';
import type { ConversationsRootStoreState } from '~/modules/Communication';
import type { MessagesUpdate } from '~/services/ApiClient';

import { resolveCommunicationEntries } from './reducers.resolveCommunicationEntries';

export const applyMessagesUpdate = (
    communicationEntries: CommunicationEntry[],
    messagesUpdate: MessagesUpdate,
    conversationsRoot: ConversationsRootStoreState,
    staticDataStoreReducer: StaticDataStoreState,
    settings: SettingsStoreState
): CommunicationEntry[] => {
    const existingEntriesById: NumericDictionary<CommunicationEntry> = keyBy(communicationEntries, 'message.value.id');
    const deletedMessageIds = messagesUpdate.deletedMessages;
    const changedMessages = messagesUpdate.updatedMessages.filter(
        (updatedMessage) =>
            !deletedMessageIds.includes(updatedMessage.id) &&
            (!existingEntriesById[updatedMessage.id] ||
                existingEntriesById[updatedMessage.id].message.value.revision < updatedMessage.revision)
    );
    const changedMessageIds = changedMessages.map((message) => message.id);

    return [
        ...communicationEntries.filter((entry) => {
            const messageId = entry.message.value.id;
            const isDeleted = deletedMessageIds.includes(messageId);
            const isUpdated = changedMessageIds.includes(messageId);

            return !isDeleted && !isUpdated;
        }),
        ...(changedMessages.length
            ? resolveCommunicationEntries(changedMessages, conversationsRoot, staticDataStoreReducer, settings)
            : []),
    ];
};
