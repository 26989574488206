import Search from '@mui/icons-material/Search';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';

import type { DefaultHeaderClassKey } from './styles';

export interface DefaultHeaderProps {
    dataIdPrefix: string;
    searchingItemsName: string;
    expandAll?: () => void;
    collapseAll?: () => void;
    deselectAll?: () => void;
    selectAll?: () => void;
    openSearch: () => void;
    partiallySelected: boolean;
    selectedAll: boolean;
    partiallyExpanded: boolean;
    expandedAll: boolean;
    hideSelectAllCheckbox?: boolean;
}

export interface DefaultHeaderInnerProps
    extends DefaultHeaderProps,
        WithStyles<DefaultHeaderClassKey>,
        InjectedTranslationProps {}

export const DefaultHeaderComponent: React.SFC<DefaultHeaderInnerProps> = ({
    dataIdPrefix,
    searchingItemsName,
    classes,
    t,
    expandAll,
    collapseAll,
    deselectAll,
    selectAll,
    partiallySelected,
    selectedAll,
    partiallyExpanded,
    expandedAll,
    openSearch,
    hideSelectAllCheckbox,
}) => {
    const shouldCollapseAll = expandedAll || partiallyExpanded;
    const shouldClearSelection = selectedAll || partiallySelected;

    const handleToggleExpandAll = (): void => {
        if (shouldCollapseAll) {
            if (collapseAll) {
                collapseAll();
            }
        } else if (expandAll) {
            expandAll();
        }
    };

    const handleToggleSelectAll = (): void => {
        if (shouldClearSelection) {
            if (deselectAll) {
                deselectAll();
            }
        } else if (selectAll) {
            selectAll();
        }
    };

    const renderCollapseIcon = () => {
        if (!isUndefined(expandAll) && !isUndefined(collapseAll)) {
            return (
                <Tooltip title={shouldCollapseAll ? t('collapse-all') : t('expand-all')}>
                    <IconButton
                        onClick={handleToggleExpandAll}
                        data-id="expand-all"
                        data-expanded={expandedAll}
                        className={classes.toggleExpandAllButton}
                        disableRipple
                        size="large"
                    >
                        {shouldCollapseAll ? <UnfoldLess /> : <UnfoldMore />}
                    </IconButton>
                </Tooltip>
            );
        }

        return null;
    };

    return (
        <div className={classes.root} data-id={`${dataIdPrefix}-default-header`}>
            {renderCollapseIcon()}

            {hideSelectAllCheckbox ? null : (
                <Tooltip title={shouldClearSelection ? t('clear-selection') : t('select-all')}>
                    <Checkbox
                        className={classes.checkbox}
                        checked={selectedAll}
                        indeterminate={partiallySelected}
                        onChange={handleToggleSelectAll}
                        data-id="select-all"
                    />
                </Tooltip>
            )}

            <div className={classes.itemsCounter}>
                <Typography variant="subtitle1" color="textPrimary">
                    {searchingItemsName}
                </Typography>
            </div>
            <Search aria-label="search" className={classes.searchIcon} onClick={openSearch} data-id="search-icon" />
        </div>
    );
};
