import { colors } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type TotalCounterFooterClassKey = 'totalCounterFooter';

export const useStyles = makeStyles({
    totalCounterFooter: {
        minWidth: 200,
        maxWidth: 200,
        padding: 16,
        fontWeight: 700,
        color: colors.common.black,
        fontSize: 13,
        position: 'sticky',
        left: 0,
        bottom: 0,
    },
});
