import { uniqBy } from '~/libs/utility';
import type { DriverActivitiesUpdate, DriverActivity } from '~/services/ApiClient';

import { filterUpdatedDriverActivities } from './reducers.filterUpdatedDriverActivities';

export const mergeUpdatedDriverActivities = (
    pendingDriverActivitiesUpdates: DriverActivitiesUpdate,
    nextDriverActivityUpdate: DriverActivitiesUpdate
): DriverActivity[] =>
    uniqBy(
        [
            ...filterUpdatedDriverActivities(pendingDriverActivitiesUpdates, nextDriverActivityUpdate),
            ...filterUpdatedDriverActivities(nextDriverActivityUpdate, pendingDriverActivitiesUpdates),
        ],
        'id'
    );
