import type { NumericDictionary } from '~/libs/utility';
import { keyBy, mapValues } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import type { ConversationSummary, ResolvedConversationSummary } from '~/services/ApiClient';
import { WorkflowFormDefinitionStatus } from '~/services/ApiClient';

import type { ActionTypes } from '../../data';
import { ActionTypeKeys } from '../../data';
import type { ConversationsRootStoreState } from '../../reducers';
import { resolveConversationMessage } from '../../reducers.resolveConversationMessage';

import type { ActionTypes as SummaryActionTypes } from './data';
import { ActionTypeKeys as SummaryActionTypeKeys } from './data';
import { mergeConversationSummaryData } from './reducers.mergeConversationsData';
import { resolveConversationSummaryDictionary } from './reducers.resolveConversationSummary';

export interface ConversationsSummaryWidgetStoreState {
    conversations: RetrievableData<NumericDictionary<ResolvedConversationSummary>>;
    pendingConversationsUpdates: NumericDictionary<ConversationSummary>;
    searchQuery?: string;
}

const defaultStoreState: ConversationsSummaryWidgetStoreState = {
    conversations: {
        data: {},
        pending: false,
        fulfilled: false,
        rejected: false,
    },
    pendingConversationsUpdates: {},
    searchQuery: undefined,
};

export const conversationsSummaryWidgetReducer = (
    state: ConversationsSummaryWidgetStoreState = defaultStoreState,
    action: ActionTypes | SummaryActionTypes,
    conversationsRoot: ConversationsRootStoreState
): ConversationsSummaryWidgetStoreState => {
    switch (action.type) {
        case ActionTypeKeys.CONVERSATIONS_PENDING: {
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    pending: true,
                },
            };
        }

        case ActionTypeKeys.CONVERSATIONS_FULFILLED: {
            return {
                ...state,
                conversations: {
                    data: mergeConversationSummaryData(
                        resolveConversationSummaryDictionary(
                            state.pendingConversationsUpdates,
                            conversationsRoot.workflowFormDefinitions.data
                        ),
                        resolveConversationSummaryDictionary(
                            keyBy(action.payload.items, 'vehicleId'),
                            conversationsRoot.workflowFormDefinitions.data
                        )
                    ),
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                },
                pendingConversationsUpdates: {},
            };
        }

        case ActionTypeKeys.CONVERSATIONS_REJECTED:
            return {
                ...state,
                conversations: {
                    data: [],
                    pending: false,
                    fulfilled: false,
                    rejected: true,
                },
            };

        case ActionTypeKeys.CONVERSATIONS_UPDATE: {
            const pendingUpdate = state.pendingConversationsUpdates[action.payload.vehicleId];
            if (!pendingUpdate || action.payload.revision > pendingUpdate.revision) {
                return {
                    ...state,
                    pendingConversationsUpdates: {
                        ...state.pendingConversationsUpdates,
                        [action.payload.vehicleId]: action.payload,
                    },
                };
            }

            return state;
        }

        case ActionTypeKeys.CONVERSATIONS_APPLY_PENDING: {
            let nextState = { ...state };
            if (state.conversations.fulfilled && !state.conversations.pending) {
                nextState = {
                    ...nextState,
                    conversations: {
                        ...state.conversations,
                        data: mergeConversationSummaryData(
                            state.conversations.data,
                            resolveConversationSummaryDictionary(
                                state.pendingConversationsUpdates,
                                conversationsRoot.workflowFormDefinitions.data
                            )
                        ),
                    },
                    pendingConversationsUpdates: {},
                };
            }

            return nextState;
        }

        case SummaryActionTypeKeys.CONVERSATIONSSUMMARY_CHANGESEARCHQUERY:
            return { ...state, searchQuery: action.payload };

        case SummaryActionTypeKeys.CONVERSATIONSSUMMARY_CLEARSEARCHQUERY:
            return { ...state, searchQuery: undefined };

        case ActionTypeKeys.WORKFLOWFORMDEFINITIONS_LATEST_FULFILLED:
        case ActionTypeKeys.WORKFLOWFORMDEFINITION_PENDING:
        case ActionTypeKeys.WORKFLOWFORMDEFINITION_REJECTED:
        case ActionTypeKeys.WORKFLOWFORMDEFINITION_FULFILLED: {
            if (!conversationsRoot.workflowFormDefinitions.fulfilled) {
                return state;
            }

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    data: mapValues(state.conversations.data, (resolvedConversationSummary) => {
                        if (!resolvedConversationSummary.lastMessage) {
                            return resolvedConversationSummary;
                        }

                        if (
                            resolvedConversationSummary.lastMessage.body.workflowFormDefinitionStatus ===
                                WorkflowFormDefinitionStatus.REQUIRED ||
                            resolvedConversationSummary.lastMessage.body.workflowFormDefinitionStatus ===
                                WorkflowFormDefinitionStatus.PENDING
                        ) {
                            const updatedMessage = resolveConversationMessage(
                                resolvedConversationSummary.lastMessage.value,
                                conversationsRoot.workflowFormDefinitions.data
                            );

                            if (
                                updatedMessage.body.workflowFormDefinitionStatus ===
                                resolvedConversationSummary.lastMessage.body.workflowFormDefinitionStatus
                            ) {
                                return resolvedConversationSummary;
                            }

                            return {
                                ...resolvedConversationSummary,
                                lastMessage: updatedMessage,
                            };
                        }

                        return resolvedConversationSummary;
                    }),
                },
            };
        }

        default:
            return state;
    }
};
