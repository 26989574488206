import type { Dispatch } from 'redux';

import { updateUserPreferencesAction } from '~/data/userpreferences';

import type { TextMessageDetailsReduxProps } from './component';

export const mapDispatchToPropsFactory: (
    userPreferencesKey: string
) => (dispatch: Dispatch) => TextMessageDetailsReduxProps = (userPreferencesKey) => (dispatch) => ({
    changeMiniMapZoomLevel: (zoomLevel: number) => {
        dispatch(
            updateUserPreferencesAction(userPreferencesKey, {
                miniMapZoomLevel: zoomLevel,
            })
        );
    },
});
