import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { defaultDisplayUserPreferences } from '~/components/DisplayPreferences';
import { useTranslation } from '~/components/LanguageSelector';
import type { SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory } from '~/components/SceneList';
import type { ResolvedVehicleType } from '~/services/ApiClient';

import { VehicleTypesAdministrationEquals, getRowId } from '../../../../entryUtils';
import { filterDataSourceMemoized, getColumnsMemoized } from '../../grid';
import {
    VEHICLE_TYPES_ADMINISTRATION_USERPREFERENCES_KEY,
    defaultVehicleTypesAdministrationListUserPreferences,
} from '../../preferences';

import type { ReduxProps } from './redux';

export type VehicleTypesAdministrationListViewProps = Omit<
    SceneListViewProps<ResolvedVehicleType>,
    'columns' | 'gridDataId' | 'getCellClassName'
>;
export interface VehicleTypesAdministrationListViewInnerProps
    extends VehicleTypesAdministrationListViewProps,
        ReduxProps,
        InjectedDisplayPreferencesProps {}

const ListViewComponent = SceneListViewFactory<ResolvedVehicleType>(
    VEHICLE_TYPES_ADMINISTRATION_USERPREFERENCES_KEY,
    defaultVehicleTypesAdministrationListUserPreferences,
    getRowId,
    VehicleTypesAdministrationEquals,
    filterDataSourceMemoized
);

export const VehicleTypesAdministrationListViewComponent: React.FC<VehicleTypesAdministrationListViewInnerProps> = ({
    displayPreferences,
    vehicleTypeCategories,
    axles,
    emissionClasses,
    ...restProps
}) => {
    const { t } = useTranslation();
    const columns = getColumnsMemoized(
        t,
        vehicleTypeCategories,
        axles,
        emissionClasses,
        defaultDisplayUserPreferences.unitSystem
    );
    return <ListViewComponent columns={columns} gridDataId="vehicle-types-administration-grid" {...restProps} />;
};
