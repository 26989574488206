import type { SvgIconProps } from '@mui/material';
import { Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import moment from 'moment';
import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { DateTimeFormatter, DistanceFormatter, DurationFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { PositionStatusContent } from '~/components/PositionStatusContent';
import type { PositionGroup } from '~/services/ApiClient';

import type { MovementPositionGroupClassKey } from './styles';

export interface MovementPositionGroupProps {
    positionGroup: PositionGroup;
    toggleElement?: React.ReactNode;
    popup?: boolean;
    AssetIcon: React.ComponentType<SvgIconProps>;
}

export interface MovementPositionGroupInnerProps
    extends MovementPositionGroupProps,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps,
        WithStyles<MovementPositionGroupClassKey> {}

export const MovementPositionGroupComponent: React.FC<MovementPositionGroupInnerProps> = ({
    classes,
    t,
    displayPreferences,
    positionGroup,
    toggleElement,
    popup,
    AssetIcon,
}) => {
    const valuePrefix = !positionGroup.start ? '> ' : '';

    const left = (
        <span className={classes.primaryMoving}>
            <AssetIcon fontSize="inherit" data-id="asset" />
            <span>{t('moving')}</span>
            <span>•</span>
            <span className={classes.distance} data-id="distance">
                {valuePrefix}
                <DistanceFormatter
                    t={t}
                    unitSystem={displayPreferences.unitSystem}
                    value={positionGroup.gpsDistance}
                    autoFormat
                />
            </span>
        </span>
    );

    const durationTitle = (
        <>
            <DateTimeFormatter value={positionGroup.start?.dateTime} />
            {' - '}
            <DateTimeFormatter value={positionGroup.stop?.dateTime ?? positionGroup.current?.dateTime} />
        </>
    );

    const right = (
        <Tooltip title={durationTitle} data-id="duration">
            <span>
                {popup && <span className={classes.in}>{t('in')}</span>}
                {valuePrefix}
                <DurationFormatter value={moment.duration(positionGroup.duration)} />
            </span>
        </Tooltip>
    );

    const movement = (
        <Typography variant="body1" color="textSecondary" className={classes.splitRow} data-id="movement-content">
            {left}
            <Typography variant="caption" color="textSecondary" className={classes.flex}>
                {right}
                {toggleElement && <span className={classes.iconMargin}>{toggleElement}</span>}
            </Typography>
        </Typography>
    );

    const currentPosition =
        !popup && !positionGroup.stop && positionGroup.current ? (
            <PositionStatusContent positionStatus={positionGroup.current} />
        ) : undefined;

    return (
        <>
            {currentPosition}
            {movement}
        </>
    );
};
