import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { LabeledIndicatorGroupContainerClassKey } from './styles';

export interface LabeledIndicatorGroupContainerProps {
    dataId: string;
}

export interface LabeledIndicatorGroupContainerInnerProps
    extends LabeledIndicatorGroupContainerProps,
        WithStyles<LabeledIndicatorGroupContainerClassKey> {}

export const LabeledIndicatorGroupContainerComponent: React.FC<LabeledIndicatorGroupContainerInnerProps> = ({
    classes,
    dataId,
    children,
}) => {
    if (!children) {
        return null;
    }

    return (
        <div className={classes.root} data-id={dataId}>
            {children}
        </div>
    );
};
