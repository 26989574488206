import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { protectedComponentStoreStateSelector } from '~/selectors';

import { LandingComponent } from './component';
import type { LandingInnerProps, LandingProps } from './models';
import { mapStateToProps } from './redux';

const stateToPropsMapper = createSelector(protectedComponentStoreStateSelector, mapStateToProps);

export const Landing = compose<LandingInnerProps, LandingProps>(
    setDisplayName('Landing'),
    connect(stateToPropsMapper)
)(LandingComponent);
