import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './constants';

export const TACHOFILES_LIST_USERPREFERENCES_KEY = 'tachofiles-grid-state';

export const defaultTachoFilesListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.DOWNLOADDATE,
        ColumnName.VEHICLE,
        ColumnName.VEHICLEALIAS,
        ColumnName.MSISDN,
        ColumnName.DRIVER,
        ColumnName.FIRSTNAME,
        ColumnName.LASTNAME,
        ColumnName.SOURCE,
        ColumnName.NATIONALPHACODE,
        ColumnName.COUNTRY,
        ColumnName.UPLOADSTATUS,
        ColumnName.DETAILS,
        ColumnName.UPLOADEVENTDATE,
        ColumnName.UPLOADEVENTDETAILS,
        ColumnName.EXPORTSTATUS,
        ColumnName.EXPORTDATE,
    ],
    visibleColumns: [
        ColumnName.DOWNLOADDATE,
        ColumnName.VEHICLE,
        ColumnName.DRIVER,
        ColumnName.SOURCE,
        ColumnName.COUNTRY,
        ColumnName.UPLOADSTATUS,
        ColumnName.DETAILS,
        ColumnName.EXPORTSTATUS,
        ColumnName.EXPORTDATE,
    ],
    columnWidths: [
        { columnName: ColumnName.DOWNLOADDATE, width: 175 },
        { columnName: ColumnName.VEHICLE, width: 200 },
        { columnName: ColumnName.VEHICLEALIAS, width: 200 },
        { columnName: ColumnName.MSISDN, width: 150 },
        { columnName: ColumnName.DRIVER, width: 200 },
        { columnName: ColumnName.FIRSTNAME, width: 150 },
        { columnName: ColumnName.LASTNAME, width: 150 },
        { columnName: ColumnName.SOURCE, width: 100 },
        { columnName: ColumnName.NATIONALPHACODE, width: 90 },
        { columnName: ColumnName.COUNTRY, width: 150 },
        { columnName: ColumnName.UPLOADSTATUS, width: 100 },
        { columnName: ColumnName.DETAILS, width: 200 },
        { columnName: ColumnName.UPLOADEVENTDATE, width: 150 },
        { columnName: ColumnName.UPLOADEVENTDETAILS, width: 200 },
        { columnName: ColumnName.EXPORTSTATUS, width: 100 },
        { columnName: ColumnName.EXPORTDATE, width: 150 },
    ],
    sorting: [{ columnName: ColumnName.DOWNLOADDATE, direction: GridSortingDirection.DESC }],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: false,
    filters: [],
    filteringEnabled: false,
};
