import type {
    DriverActivitiesStoreState,
    HistorySubpageStoreState,
    VehicleStatusForPeriodStoreState,
} from '~/data/monitoring';
import { vehicleDetailsPaneStateSelector } from '~/data/monitoring';
import type { StoreState } from '~/reducers';

export const driverActivitiesStateSelector = (s: StoreState): DriverActivitiesStoreState =>
    vehicleDetailsPaneStateSelector(s).driverActivities;

export const historySubpageStateSelector = (s: StoreState): HistorySubpageStoreState =>
    vehicleDetailsPaneStateSelector(s).historySubpage;

export const vehicleStatusesForPeriodSelector = (s: StoreState): VehicleStatusForPeriodStoreState =>
    vehicleDetailsPaneStateSelector(s).vehicleStatuses;
