import { List } from '@mui/material';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import type { DictionaryChangelogsDay } from '../../models';

import { ChangelogDateEntity } from './components/ChangelogDateEntity';
import { ChangelogEntity } from './components/ChangelogEntity';
import { groupChangelogsByDay } from './groupChangelogsByDay';
import type { ChangelogDetailsProps } from './models';

const ChangelogDetails = <T,>({
    entityType,
    attributeDefinitions,
    changelogs,
}: ChangelogDetailsProps<T>): JSX.Element => {
    const groupedChangelogs: DictionaryChangelogsDay<T> = useMemo(() => groupChangelogsByDay(changelogs), [changelogs]);

    const content = useMemo(() => {
        let sections: ReactNode[] = [];
        Object.entries(groupedChangelogs).forEach(([key], index) => {
            const dayChangelogs = groupedChangelogs[key];
            sections.push(<ChangelogDateEntity key={key} value={new Date(key)} groupedPosition={index} />);
            sections = sections.concat(
                dayChangelogs.map((changelogGrouped, position) => (
                    <ChangelogEntity
                        key={`${key}:${changelogGrouped.action}:${changelogGrouped.dateTime}`}
                        entityType={entityType}
                        changelog={changelogGrouped}
                        attributeDefinitions={attributeDefinitions}
                        id={changelogGrouped.id}
                        groupedPosition={position}
                    />
                ))
            );
        });
        return sections;
    }, [attributeDefinitions, entityType, groupedChangelogs]);
    return (
        <List disablePadding data-id="changelog-details">
            {content}
        </List>
    );
};

ChangelogDetails.displayName = 'ChangelogDetails';
export { ChangelogDetails };
