import { GridFilterOperation } from '../../models';

export const numberFilterOperations = [
    GridFilterOperation.Equal,
    GridFilterOperation.NotEqual,
    GridFilterOperation.GreaterThan,
    GridFilterOperation.GreaterThanOrEqual,
    GridFilterOperation.LessThan,
    GridFilterOperation.LessThanOrEqual,
];
export const dateFilterOperations = [
    GridFilterOperation.YearEqual,
    GridFilterOperation.MonthEqual,
    GridFilterOperation.Before,
    GridFilterOperation.BeforeOrEqual,
    GridFilterOperation.After,
    GridFilterOperation.AfterOrEqual,
];
export const defaultFilterOperations = [
    GridFilterOperation.Contains,
    GridFilterOperation.NotContains,
    GridFilterOperation.StartsWith,
    GridFilterOperation.EndsWith,
    GridFilterOperation.Equal,
    GridFilterOperation.NotEqual,
];
export const durationFilterOperations = [
    GridFilterOperation.Equal,
    GridFilterOperation.NotEqual,
    GridFilterOperation.GreaterThan,
    GridFilterOperation.GreaterThanOrEqual,
    GridFilterOperation.LessThan,
    GridFilterOperation.LessThanOrEqual,
];
