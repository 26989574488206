import { Button } from '@mui/material';
import * as React from 'react';
import { withProps } from 'react-recompose';

import type { FilteringComponentType } from '~/components/Dialogs/SelectEntryDialogFactory';
import { SelectEntryDialogFactory } from '~/components/Dialogs/SelectEntryDialogFactory';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { MonitoringTrailerEntry } from '~/data/monitoring';
import type {
    Classification,
    NamedVehicleReference,
    PojoModel,
    ResolvedTrailerConnection,
    ResolvedVehicle,
    Vehicle,
} from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';

import { canConnectTrailer } from './canConnectTrailer';
import type { TrailerConnectionFilter } from './components/TrailerConnectionFiltering';
import { TrailerConnectionFiltering } from './components/TrailerConnectionFiltering';
import { TrailerEntryRow } from './components/TrailerEntryRow';
import { filterTrailers } from './filterTrailers';
import { getTrailerEntryId } from './getTrailerEntryId';

export interface StateProps {
    trailers: MonitoringTrailerEntry[];
    trailerManufacturers: Classification[];
}

export interface DispatchProps {
    connectTrailer: (trailerId: number) => void;
}

export interface ConnectTrailerDialogProps {
    vehicle: PojoModel<NamedVehicleReference> | PojoModel<Vehicle> | ResolvedVehicle;
    trailerConnection?: ResolvedTrailerConnection;
    onClose: () => void;
}

export interface ConnectTrailerDialogInnerProps
    extends StateProps,
        DispatchProps,
        ConnectTrailerDialogProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

const SelectEntryDialog = SelectEntryDialogFactory<MonitoringTrailerEntry, TrailerConnectionFilter>(
    getTrailerEntryId,
    canConnectTrailer
);

export const ConnectTrailerDialogComponent: React.FC<ConnectTrailerDialogInnerProps> = ({
    t,
    displayPreferences,
    vehicle,
    trailerConnection,
    onClose,
    trailers,
    trailerManufacturers,
    connectTrailer,
}) => {
    const [selectedTrailerEntry, setSelectedTrailerEntry] = React.useState<MonitoringTrailerEntry | undefined>();

    const selectableTrailers = React.useMemo(() => {
        return trailerConnection
            ? trailers.filter(({ trailer }) => {
                  return trailer.id !== trailerConnection?.trailerReference.id;
              })
            : trailers;
    }, [trailerConnection, trailers]);

    const vehicleName = formatVehicleName(vehicle, displayPreferences.vehicleDisplayFormat);
    const title = trailerConnection ? (
        <span data-id="dialog-title">{t('replace-trailer-dialog-title', { vehicleName })}</span>
    ) : (
        <span data-id="dialog-title">{t('connect-trailer-dialog-title', { vehicleName })}</span>
    );

    const FilteringComponent: FilteringComponentType<TrailerConnectionFilter> = React.useMemo(() => {
        return withProps({ trailerManufacturers })(TrailerConnectionFiltering);
    }, [trailerManufacturers]);

    const handleConnectClick = React.useCallback(() => {
        if (selectedTrailerEntry) {
            connectTrailer(selectedTrailerEntry.trailer.id);
            onClose();
        }
    }, [selectedTrailerEntry, connectTrailer, onClose]);

    const actionButtons = (
        <>
            <Button color="secondary" onClick={onClose} data-id="cancel">
                {t('cancel')}
            </Button>
            <Button
                disabled={!selectedTrailerEntry}
                variant="contained"
                color="secondary"
                onClick={handleConnectClick}
                data-id="connect"
            >
                {t('connect')}
            </Button>
        </>
    );

    const handleSelectEntry = React.useCallback(
        (e: MonitoringTrailerEntry) => {
            setSelectedTrailerEntry(e);
        },
        [setSelectedTrailerEntry]
    );

    const filterEntries = React.useCallback(
        (entries: MonitoringTrailerEntry[], searchQuery?: string, filter?: TrailerConnectionFilter) =>
            filterTrailers(entries, displayPreferences.trailerDisplayFormat, t, searchQuery, filter),
        [t, displayPreferences.trailerDisplayFormat]
    );

    return (
        <SelectEntryDialog
            entries={selectableTrailers}
            title={title}
            FilteringComponent={FilteringComponent}
            EntryRow={TrailerEntryRow}
            filterEntries={filterEntries}
            actionButtons={actionButtons}
            selectedEntry={selectedTrailerEntry}
            onEntrySelect={handleSelectEntry}
            open
            entryTypePlural={t('trailers')}
            entryTypeSingular={t('trailer')}
            dataId="connect-trailer-dialog"
            onClose={onClose}
        />
    );
};
