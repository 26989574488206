import type { StaticDataStoreState } from '~/common';
import { getValueFromHash } from '~/common';
import type { SettingsDataType, SettingsKey } from '~/components/EnsureSettings';
import type { TrailerChangelogChanges } from '~/services/ApiClient';

import type { ResolvedTrailerChangelogChanges } from './models';
import { resolveTrailerSettingsChangelogChanges } from './reducers.resolveTrailerSettingsChangelogChanges';

export const resolveTrailerChangelogChanges = (
    changes: TrailerChangelogChanges,
    trailerManufacturers: SettingsDataType[SettingsKey.TRAILER_MANUFACTURERS],
    trailerBatteryType: SettingsDataType[SettingsKey.TRAILER_BATTERY_TYPE],
    ebsManufacturer: SettingsDataType[SettingsKey.EBS_MANUFACTURER],
    reeferBatteryType: SettingsDataType[SettingsKey.REEFER_BATTERY_TYPE],
    reeferDataInterface: SettingsDataType[SettingsKey.REEFER_DATA_INTERFACE],
    reeferManufacturers: SettingsDataType[SettingsKey.REEFER_MANUFACTURERS],
    tpmsManufacturer: SettingsDataType[SettingsKey.TPMS_MANUFACTURER],
    trailerOfflineAlarm: SettingsDataType[SettingsKey.TRAILER_OFFLINE_ALARM],
    trailerMonitoring: SettingsDataType[SettingsKey.TRAILER_MONITORING],
    trailerBandwidthLinked: SettingsDataType[SettingsKey.TRAILER_BANDWIDTH_LINKED],
    depots: StaticDataStoreState['depots']['data']['hash']
): ResolvedTrailerChangelogChanges => {
    return {
        ...changes,
        depot: getValueFromHash(depots, changes.depotId),
        trailerManufacturer: getValueFromHash(trailerManufacturers, changes.trailerManufacturer),
        batteryType: getValueFromHash(trailerBatteryType, changes.batteryType),
        ebsManufacturer: getValueFromHash(ebsManufacturer, changes.ebsManufacturer),
        reeferBatteryType: getValueFromHash(reeferBatteryType, changes.reeferBatteryType),
        reeferDataInterface: getValueFromHash(reeferDataInterface, changes.reeferDataInterface),
        reeferManufacturer: getValueFromHash(reeferManufacturers, changes.reeferManufacturer),
        tpmsManufacturer: getValueFromHash(tpmsManufacturer, changes.tpmsManufacturer),
        settings: changes.settings
            ? resolveTrailerSettingsChangelogChanges(
                  changes.settings,
                  trailerOfflineAlarm,
                  trailerMonitoring,
                  trailerBandwidthLinked
              )
            : undefined,
    };
};
