import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import type { DeepPojoModel, DriverActivityType } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

const formatDriverActivityInternal = (t: SingleTFunction, v: DriverActivityType | DeepPojoModel<DriverActivityType>) =>
    t(`driver-activity-${v.key.toLocaleLowerCase()}`);

const formatDriverActivityMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((v: DriverActivityType | DeepPojoModel<DriverActivityType>) => formatDriverActivityInternal(t, v))
);

export const formatDriverActivity = (
    t: SingleTFunction,
    v: DriverActivityType | DeepPojoModel<DriverActivityType>
): string => formatDriverActivityMemoized(t)(v);
