import { isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { etherealStateSelector } from '~/selectors';

export const keyDataStateSelectorFactory =
    <T extends object>(key: string, defaultValue: T) =>
    (store: StoreState): T => {
        const value = etherealStateSelector(store)[key];

        if (isUndefined(value)) {
            return defaultValue;
        }

        return value.data as T;
    };
