import * as React from 'react';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { RemoteAutocompleteInnerProps, RemoteAutocompleteProps } from './component';
import { RemoteAutocompleteComponentFactory } from './component';
import type { Option } from './models';

export * from './models';

export const RemoteAutocompleteFactory: <T>(
    OptionChildComponent?: React.ComponentType<{ option: Option<T> }>
) => React.ComponentType<RemoteAutocompleteProps<T>> = <T>(
    OptionChildComponent?: React.ComponentType<{ option: Option<T> }>
) =>
    compose<RemoteAutocompleteInnerProps<T>, RemoteAutocompleteProps<T>>(
        setDisplayName('RemoteAutocomplete'),
        withTranslation(),
        React.memo
    )(RemoteAutocompleteComponentFactory<T>(OptionChildComponent));
