import type { Action } from 'redux';

import type { SettingsStoreState } from '~/components/EnsureSettings';

import type { HistoryStoreState } from './reducers';
import { historyReducer } from './reducers';

export interface HistorySceneStoreState {
    history: HistoryStoreState;
}

export const historySceneReducer = (
    state: HistorySceneStoreState | undefined,
    action: Action,
    settings: SettingsStoreState
): HistorySceneStoreState => {
    const history = historyReducer(state?.history, action, settings);

    return { history };
};
