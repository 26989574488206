import _Clear from '@mui/icons-material/Clear';
import _Search from '@mui/icons-material/Search';
import _Input from '@mui/material/Input';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.default,
}));

const SearchIcon = styled(_Search)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const ClearIcon = styled(_Clear)(({ theme }) => ({
    color: theme.palette.text.secondary,
    cursor: 'pointer',
}));

const Input = styled(_Input)(({ theme }) => ({
    height: '32px',
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5, 1),
}));

export { ClearIcon, Container, Input, SearchIcon };
