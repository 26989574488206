import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SendMessageFooterClassKey = 'summary' | 'collapseIcon';

export const SendMessageFooterStyleRules: StyleRulesCallback<Theme, {}, SendMessageFooterClassKey> = (theme: Theme) =>
    createStyles({
        summary: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.default,
        },
        collapseIcon: {
            cursor: 'pointer',
        },
    });
