import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import type { RequiredUserPreferences } from '~/components/UserPreferences';

import { SectionName } from './constants';

export interface MessageDetailsUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapZoomLevel: number;
}

export const defaultMessageDetailsUserPreferences: MessageDetailsUserPreferences = {
    miniMapZoomLevel: 12,
    sectionOrder: [
        SectionName.MESSAGEINFO,
        SectionName.MINIMAP,
        SectionName.LOCATION,
        SectionName.WORKFLOWBODY,
        SectionName.ATTACHMENTS,
        SectionName.METADATA,
    ],
    collapsedSections: [],
};

export const requiredMessageDetailsUserPreferencesFactory = (userPreferencesKey: string): RequiredUserPreferences => ({
    key: userPreferencesKey,
    defaultState: defaultMessageDetailsUserPreferences,
});
