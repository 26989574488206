export const CONVERSATION_USERPREFERENCES_KEY = 'conversation';

export interface ConversationUserPreferences {
    conversationProfileId?: number;
    showDeletedMessagesInConversations: boolean;
    showDeletedMessagesInCommunicationModule: boolean;
    ttl: string;
    rememberLastTtl: boolean;
}

export const defaultConversationUserPreferences: ConversationUserPreferences = {
    conversationProfileId: undefined,
    showDeletedMessagesInConversations: false,
    showDeletedMessagesInCommunicationModule: false,
    ttl: 'PT30M',
    rememberLastTtl: false,
};

export const requiredConversationUserPreferences = {
    key: CONVERSATION_USERPREFERENCES_KEY,
    defaultState: defaultConversationUserPreferences,
};
