import { Checkbox, Switch, Typography } from '@mui/material';
import * as React from 'react';

import { DateTimeSetting } from './components/DateTimeSetting';
import { NumericMagnitudeSetting } from './components/NumericMagnitudeSetting';
import type { MiniSettingsPanelInnerProps } from './models';
import { BooleanFieldType, SettingItemType } from './models';

export const MiniSettingsPanelComponent: React.FC<MiniSettingsPanelInnerProps> = (props) => {
    const { classes, settings } = props;

    const children = settings.map((item) => {
        let renderComponent;
        switch (item.settingType) {
            case SettingItemType.Boolean:
                switch (item.type) {
                    case BooleanFieldType.ToggleSwitch:
                        renderComponent = (
                            <Switch
                                checked={item.value}
                                onChange={() => item.onValueChange()}
                                data-id={item.key}
                                size="small"
                            />
                        );
                        break;
                    case BooleanFieldType.Checkbox:
                        renderComponent = (
                            <Checkbox
                                checked={item.value}
                                onChange={() => item.onValueChange()}
                                data-id={item.key}
                                size="small"
                            />
                        );
                        break;
                    default:
                        renderComponent = null;
                }
                break;
            case SettingItemType.NumericMagnitude:
                renderComponent = (
                    <NumericMagnitudeSetting setting={item} size="small" margin="dense" className={classes.setting} />
                );
                break;
            case SettingItemType.DateTime:
                renderComponent = (
                    <DateTimeSetting setting={item} size="small" margin="dense" className={classes.setting} />
                );
                break;
            default:
                throw new Error('unknown setting type for MiniSettingsPane');
        }

        const renderContent = (
            <>
                <Typography variant="caption">{item.title}</Typography>
                {renderComponent}
            </>
        );

        return (
            <div key={item.key} className={classes.settingWrapper}>
                {renderContent}
            </div>
        );
    });

    return <div className={classes.miniSettingsPanel}>{children}</div>;
};
