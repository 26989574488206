import { Tooltip } from '@mui/material';
import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { DriverActivityType } from '~/services/ApiClient';
import { formatDriverActivity } from '~/services/Formatters';

import { IconFormatter } from './components/Icon';

export interface DriverActivityFormatterProps {
    t: SingleTFunction;
    value?: DriverActivityType;
}

const DriverActivityFormatter: React.FunctionComponent<DriverActivityFormatterProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return (
        <Tooltip title={formatDriverActivity(t, value)}>
            <span data-id={`driver-activity:${value.key}`}>
                <IconFormatter value={value.key} />
            </span>
        </Tooltip>
    );
};
DriverActivityFormatter.displayName = 'DriverActivityFormatter';

export { DriverActivityFormatter };
