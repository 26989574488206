import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { ConversationWorkflowBodyProps } from './component';

export type ConversationWorkflowBodyClassKey = 'body' | 'group' | 'element';

export const ConversationWorkflowBodyStyleRules: StyleRulesCallback<
    Theme,
    ConversationWorkflowBodyProps,
    ConversationWorkflowBodyClassKey
> = (theme: Theme) =>
    createStyles({
        body: {
            backgroundColor: theme.palette.common.white,
            padding: theme.spacing(),
            flex: '1 1 auto',
            position: 'relative',
        },
        group: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        element: {
            padding: ({ readOnly }) => (readOnly ? theme.spacing() : theme.spacing(0.5)),
            flex: '1 1 33%',
        },
    });
