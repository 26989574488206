import { styled } from '@mui/material';

import { TrailerIcon as _TrailerIcon } from '~/components/Icons';

const TrailerIcon = styled(_TrailerIcon)({
    height: '100%',
    width: '100%',
});

export { TrailerIcon };
