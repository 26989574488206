import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { AssetContextMenuInnerProps, AssetContextMenuProps } from './component';
import { AssetContextMenuComponent } from './component';

export { AssetContextMenuType } from './component';

export const AssetContextMenu = compose<AssetContextMenuInnerProps, AssetContextMenuProps>(
    setDisplayName('AssetContextMenu'),
    withDisplayPreferences(),
    withTranslation()
)(AssetContextMenuComponent);
