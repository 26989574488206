import { uniq } from '~/libs/utility';
import type { ConversationUpdate } from '~/services/ApiClient';

import { filterDeletedMessages } from './filterDeletedMessages';

export const mergeDeletedMessages = (
    pendingConversationUpdate: ConversationUpdate,
    nextConversationUpdate: ConversationUpdate
): number[] =>
    uniq([
        ...filterDeletedMessages(pendingConversationUpdate, nextConversationUpdate),
        ...filterDeletedMessages(nextConversationUpdate, pendingConversationUpdate),
    ]);
