import withStyles from '@mui/styles/withStyles';
import type { ComponentType } from 'react';
import { compose, setDisplayName } from 'react-recompose';

import { withAutoSizer } from '~/components/AutoSizer';

import type { ReverseInfiniteScrollInnerProps, ReverseInfiniteScrollOuterProps } from './component';
import { ReverseInfiniteScrollComponentFactory } from './component';
import { ReverseInfiniteScrollStyleRules } from './styles';

export type { ScrollPosition } from './component';

export const ReverseInfiniteScrollFactory = <T>(): ComponentType<ReverseInfiniteScrollOuterProps<T>> =>
    compose<ReverseInfiniteScrollInnerProps<T>, ReverseInfiniteScrollOuterProps<T>>(
        setDisplayName('ReverseInfiniteScroll'),
        withAutoSizer,
        withStyles(ReverseInfiniteScrollStyleRules)
    )(ReverseInfiniteScrollComponentFactory<T>());
