import { useEffect } from 'react';

import { useDeepCompareMemoize } from './useDeepCompareMemoize';

type UseEffectParams = Parameters<typeof useEffect>;
type EffectCallback = UseEffectParams[0];
type DependencyList = UseEffectParams[1];
type UseEffectReturn = ReturnType<typeof useEffect>;

const isPrimitive = (val: unknown) => {
    return val == null || /^[sbn]/.test(typeof val);
};

const checkDeps = (deps: DependencyList) => {
    if (!deps || !deps.length) {
        throw new Error('useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.');
    }
    if (deps.every(isPrimitive)) {
        throw new Error(
            'useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.'
        );
    }
};

const useDeepCompareEffect = (callback: EffectCallback, dependencies: DependencyList): UseEffectReturn => {
    if (process.env.NODE_ENV !== 'production') {
        checkDeps(dependencies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(callback, useDeepCompareMemoize(dependencies));
};

export const useDeepCompareEffectNoCheck = (
    callback: EffectCallback,
    dependencies: DependencyList
): UseEffectReturn => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(callback, useDeepCompareMemoize(dependencies));
};

export default useDeepCompareEffect;
