import { createAction } from 'redux-actions';

import type { TrailerStatus } from '~/services/ApiClient';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { TrailerStatusPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const retrieveTrailerStatusExecutor = (): Promise<TrailerStatusPayload> => {
    return retryableRequest(() => ApiClient.getTrailerStatus());
};

export const retrieveTrailerStatusAction = createAction(
    ActionTypePrefixes.TRAILERSTATUS,
    retrieveTrailerStatusExecutor
);

export const updateTrailerStatusAction = createAction<TrailerStatus>(ActionTypeKeys.TRAILERSTATUS_UPDATE);
