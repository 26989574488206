import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ActivitiesBarClassKey =
    | 'root'
    | 'activitySurface'
    | 'activitySurfaceLeftCornersRounded'
    | 'activitySurfaceRightCornersRounded'
    | 'loadingView';

export const ActivitiesBarStyleRules: StyleRulesCallback<Theme, {}, ActivitiesBarClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            background: theme.palette.divider,
            height: 12,
            marginBottom: theme.spacing(0.25),
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        activitySurface: {
            position: 'absolute',
            bottom: 0,
        },
        activitySurfaceLeftCornersRounded: {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
        },
        activitySurfaceRightCornersRounded: {
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
        },
        loadingView: {
            marginBottom: theme.spacing(0.25),
            height: 12,
            borderRadius: theme.shape.borderRadius,
        },
    });
