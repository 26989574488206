import type { CalendarSpec } from 'moment';
import type React from 'react';
import { compose, setDisplayName } from 'react-recompose';

import type { SingleTFunction } from '../../LanguageSelector';

import type { AdaptiveDateTimeFormatterInnerProps, AdaptiveDateTimeFormatterProps } from './component';
import { createAdaptiveDateTimeComponent } from './component';
import { getAdaptiveDateCalendarSpec } from './getAdaptiveDateCalendarSpec';
import { getDefaultAdaptiveDateTimeCalendarSpec } from './getDefaultAdaptiveDateTimeCalendarSpec';

export const AdaptiveDateFormatter = compose<AdaptiveDateTimeFormatterInnerProps, AdaptiveDateTimeFormatterProps>(
    setDisplayName('AdaptiveDateFormatter')
)(createAdaptiveDateTimeComponent(getAdaptiveDateCalendarSpec));

export const adaptiveDateTimeFormatterFactory = (
    getAdaptiveCalendarSpec?: (t: SingleTFunction) => CalendarSpec
): React.ComponentClass<AdaptiveDateTimeFormatterProps> =>
    compose<AdaptiveDateTimeFormatterInnerProps, AdaptiveDateTimeFormatterProps>(
        setDisplayName('AdaptiveDateTimeFormatter')
    )(createAdaptiveDateTimeComponent(getAdaptiveCalendarSpec ?? getDefaultAdaptiveDateTimeCalendarSpec));
