import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const ContainerIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M23 7H1V17H23Zm-6.37 8.57H15.47V8.43h1.16ZM7.37 8.43H8.53v7.14H7.37Zm5.21 7.14H11.42V8.43h1.16ZM3.32 8.43H4.47v7.14H3.32Zm17.36 7.14H19.53V8.43h1.15Z" />
        </SvgIcon>
    );
};
ContainerIcon.displayName = 'ContainerIcon';

export { ContainerIcon };
