import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ReorderableSectionClassKeys =
    | 'header'
    | 'title'
    | 'body'
    | 'headerOverContent'
    | 'section'
    | 'collapseIcon'
    | 'noPaddingBottom'
    | 'rightHeaderElement'
    | 'hideCollapsing'
    | 'emptyMessage';

export const ReorderableSectionStyleRules: StyleRulesCallback<Theme, {}, ReorderableSectionClassKeys> = (
    theme: Theme
) =>
    createStyles({
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(),
            paddingBottom: theme.spacing(),
            color: theme.palette.text.secondary,

            '& svg:last-child': {
                margin: theme.spacing(0, 0.5),
            },
        },
        title: {
            flexGrow: 1,
            lineHeight: '24px',
            fontWeight: theme.typography.fontWeightMedium,
        },
        section: {
            position: 'relative',
        },
        body: {
            paddingTop: 0,
            paddingBottom: theme.spacing(2),
        },
        headerOverContent: {
            position: 'absolute',
            width: '100%',
            // Leaflet have set z-index 400 for canvas HTML element, we need 1 more to show the drag&drop buttons
            zIndex: 401,
            height: 40,
        },
        noPaddingBottom: {
            paddingBottom: 0,
        },
        collapseIcon: {
            marginRight: theme.spacing(0.5),
        },
        rightHeaderElement: {
            overflow: 'hidden',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(),
            color: theme.palette.text.primary,
            fontSize: theme.typography.body2.fontSize,
            fontFamily: theme.typography.body2.fontFamily,
            letterSpacing: theme.typography.body2.letterSpacing,
            fontWeight: theme.typography.body2.fontWeight,
            lineHeight: theme.typography.body2.lineHeight,
        },
        hideCollapsing: {
            paddingLeft: theme.spacing(3.5),
        },
        emptyMessage: {
            padding: `${theme.spacing()} ${theme.spacing(2)}`,
            color: theme.palette.grey[500],
        },
    });
