import type { Feature } from '../../models';
import type { FeatureFlagsStoreState } from '../../reducers';

export interface FeatureFlagsProp {
    isEnabled: (feature: Feature) => boolean;
}

export interface FeatureFlagsReduxProps {
    featureFlags: FeatureFlagsProp;
}

export const mapStateToProps = (state: FeatureFlagsStoreState): FeatureFlagsReduxProps => {
    return {
        featureFlags: {
            isEnabled: (feature: Feature) => {
                return state.enabledFeatures.includes(feature);
            },
        },
    };
};
