import type { NumericDictionary } from '~/libs/utility';
import { DriverActivity, createApiModel } from '~/services/ApiClient';

export const mergeDriverSubactivities = (activities: NumericDictionary<DriverActivity>): DriverActivity[] =>
    Object.keys(activities).reduce((acc: DriverActivity[], activityId) => {
        const currentActivity = activities[activityId];
        let lastActivity = acc.length && acc[acc.length - 1];
        if (
            lastActivity &&
            lastActivity.stopDate &&
            lastActivity.stopDate.getTime() === currentActivity.startDate.getTime() &&
            lastActivity.activityId === currentActivity.activityId
        ) {
            lastActivity = createApiModel(DriverActivity, { ...currentActivity, startDate: lastActivity.startDate });
            acc.pop();
            acc.push(lastActivity);
        } else {
            acc.push(currentActivity);
        }

        return acc;
    }, []);
