import Close from '@mui/icons-material/Close';
import { Divider } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RecipientsList, vehiclesHashSelector } from '~/common';
import { BannerType, TopBanner, WidgetDialog } from '~/components/Dialogs';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledIconButton } from '~/components/TitledIconButton';
import { isEmpty, isUndefined, uniq } from '~/libs/utility';
import type { AssetRecipient, MessageBody, SendMessageOptions } from '~/services/ApiClient';
import {
    AssetRecipientType,
    AssetType,
    MessageBodyType,
    OptimisticOutgoingConversationMessageStatus,
} from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';

import { SendTextMessageForm } from '../SendTextMessageForm';
import { SendWorkflowMessageForm } from '../SendWorkflowMessageForm';

import type { SendMessageDialogStateProps } from './redux';
import type { SendMessageDialogClassKey } from './styles';

export interface SendMessageDialogProps {
    vehicleId?: number;
    messageBodyType: MessageBodyType;
    onClose: () => void;
}

export interface SendMessageDialogDispatchProps {
    sendMessage: (recipients: number[], message: MessageBody, options: SendMessageOptions) => void;
}

export interface SendMessageDialogInnerProps
    extends SendMessageDialogProps,
        SendMessageDialogStateProps,
        SendMessageDialogDispatchProps,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps,
        WithStyles<SendMessageDialogClassKey> {}

export const SendMessageDialogComponent: FC<SendMessageDialogInnerProps> = (props) => {
    const {
        vehicleId,
        messageBodyType,
        onClose,
        t,
        displayPreferences,
        sendMessage,
        messageStatus,
        messageBody,
        classes,
        vehicleMessagingCapabilities,
    } = props;

    const vehicles = useSelector(vehiclesHashSelector);

    const textMessageTitle = isUndefined(vehicleId)
        ? t('new-message')
        : t('send-message-to', {
              vehicleName: formatVehicleName(vehicles[vehicleId], displayPreferences.vehicleDisplayFormat),
          });

    const workflowMessageTitle = isUndefined(vehicleId)
        ? t('new-workflow')
        : t('send-workflow-to', {
              vehicleName: formatVehicleName(vehicles[vehicleId], displayPreferences.vehicleDisplayFormat),
          });

    const title = messageBodyType === MessageBodyType.Text ? textMessageTitle : workflowMessageTitle;
    const [recipientIds, setRecipientIds] = useState<number[]>([]);
    const [selectedRecipients, setRecipients] = useState<AssetRecipient[]>([]);
    const [showRecipientWarning, setShowRecipientWarning] = useState<boolean>(false);
    const [showSendOptionsWarningBanner, setShowSendOptionsWarningBanner] = useState<boolean>(false);
    const [sendOptionsWarningText, setSendOptionsWarningText] = useState<string>('');
    const [sendOptionsWarningDismissible, setSendOptionsWarningDismissible] = useState<boolean>();
    const [showFailedMessageBanner, setShowFailedMessageBanner] = useState<boolean>(false);
    const [shouldCloseState, setShouldCloseState] = useState<boolean>(false);
    const [bodyState, setBodyState] = useState<MessageBody | undefined>(undefined);

    const handleClosed = useCallback(() => {
        setShouldCloseState(true);
    }, [setShouldCloseState]);

    const handleOnCloseRejected = useCallback(() => {
        setShouldCloseState(false);
    }, [setShouldCloseState]);

    const headerActions = [
        <TitledIconButton key="button-close" title={t('close')} onClick={handleClosed} data-id="close" color="inherit">
            <Close />
        </TitledIconButton>,
    ];

    const handleOnClose = useCallback(() => {
        onClose();
        setBodyState(undefined);
        setShowFailedMessageBanner(false);
    }, [onClose]);

    const handleSendMessage = useCallback(
        (message: MessageBody, options: SendMessageOptions) => {
            sendMessage(isUndefined(vehicleId) ? recipientIds : [vehicleId], message, options);
            setBodyState(message);
        },
        [recipientIds, sendMessage, vehicleId]
    );

    const handleSendOptionsWarning = useCallback((messageText?: string, canDismiss?: boolean) => {
        setShowSendOptionsWarningBanner(!!messageText);
        setSendOptionsWarningText(messageText ?? '');
        setSendOptionsWarningDismissible(canDismiss);
    }, []);

    const dismissSendOptionsWarning = useCallback(() => {
        setShowSendOptionsWarningBanner(false);
    }, []);

    const messageFailed =
        messageStatus === OptimisticOutgoingConversationMessageStatus.GeneralFailure ||
        messageStatus === OptimisticOutgoingConversationMessageStatus.ValidationFailure;

    useEffect(() => {
        setShowFailedMessageBanner(messageBody === bodyState ? messageFailed : false);
    }, [bodyState, messageBody, messageFailed]);

    const setSelectedRecipients = (recipients: AssetRecipient[]): void => {
        let selectedIds: number[] = [];

        recipients.forEach((recipient) => {
            if (recipient.type === AssetRecipientType.GROUP || recipient.type === AssetRecipientType.DEPOT) {
                selectedIds = selectedIds.concat(uniq(recipient.enabledAssetIds));
            } else {
                selectedIds = selectedIds.concat(recipient.ids);
            }
        });

        const withoutDuplicates = selectedIds.filter((value, index, array) => array.indexOf(value) === index);
        setRecipientIds(withoutDuplicates);
        setShowRecipientWarning(withoutDuplicates.length > 1);
        setRecipients(recipients);
    };

    const vehiclePredicate = useCallback(
        (vehicle: number) => {
            if (isEmpty(vehicleMessagingCapabilities[vehicle])) {
                return false;
            }

            switch (messageBodyType) {
                case MessageBodyType.Text:
                    return (
                        vehicleMessagingCapabilities[vehicle].textMessagingEnabled &&
                        vehicleMessagingCapabilities[vehicle].supportsTextMessages
                    );
                case MessageBodyType.Workflow:
                    return (
                        vehicleMessagingCapabilities[vehicle].workflowMessagingEnabled &&
                        vehicleMessagingCapabilities[vehicle].supportsWorkflowMessages
                    );
                default:
                    return false;
            }
        },
        [messageBodyType, vehicleMessagingCapabilities]
    );

    const recipientList = !vehicleId && (
        <>
            <RecipientsList
                setSelectedRecipients={setSelectedRecipients}
                selectedRecipients={selectedRecipients}
                className={classes.recipientsListPadding}
                assetPredicate={vehiclePredicate}
                assetType={AssetType.Vehicle}
                displayName={t('bulk-message')}
            />
            <Divider />
        </>
    );

    const params = {
        numberOfRecipients: selectedRecipients.length,
        numberOfVehicles: recipientIds.length,
    };

    const removeRecipientWarning = (): void => {
        setShowRecipientWarning(false);
    };

    const removeFailedMessageBanner = (): void => {
        setShowFailedMessageBanner(false);
    };

    const sendOptionsWarningBanner = showSendOptionsWarningBanner && (
        <>
            <TopBanner
                title={sendOptionsWarningText}
                onDismiss={sendOptionsWarningDismissible ? dismissSendOptionsWarning : undefined}
                dataId="send-options-changed"
                type={BannerType.Warning}
            />
            <Divider />
        </>
    );

    const sendMessageFailed = useMemo(() => {
        const { warningTitle, dataId } =
            messageStatus === OptimisticOutgoingConversationMessageStatus.ValidationFailure
                ? {
                      warningTitle: t('message-has-invalid-content'),
                      dataId: 'validation-errors',
                  }
                : messageStatus === OptimisticOutgoingConversationMessageStatus.GeneralFailure
                  ? {
                        warningTitle: t('message-could-not-be-processed'),
                        dataId: 'send-failed',
                    }
                  : { warningTitle: undefined, dataId: undefined };
        return showFailedMessageBanner && warningTitle && dataId ? (
            <>
                <TopBanner
                    title={warningTitle}
                    onDismiss={removeFailedMessageBanner}
                    dataId={dataId}
                    type={BannerType.Error}
                />
                <Divider />
            </>
        ) : undefined;
    }, [messageStatus, showFailedMessageBanner, t]);

    const recipientNumberWarning = showRecipientWarning && (
        <>
            <TopBanner
                title={t('recipient-number-warning', params)}
                onDismiss={removeRecipientWarning}
                dataId="recipient-warning"
                type={BannerType.Warning}
            />
            <Divider />
        </>
    );

    const noRecipient = isUndefined(vehicleId) && recipientIds.length === 0;

    const content =
        messageBodyType === MessageBodyType.Text ? (
            <SendTextMessageForm
                onSend={handleSendMessage}
                onSendOptionsWarning={handleSendOptionsWarning}
                onClose={handleOnClose}
                shouldClose={shouldCloseState}
                onCloseRejected={handleOnCloseRejected}
                disabled={noRecipient}
                messageStatus={messageBody === bodyState ? messageStatus : undefined}
                classes={{ sendMessageContainer: classes.sendMessageContainerPadding }}
            />
        ) : (
            <SendWorkflowMessageForm
                onSend={handleSendMessage}
                onSendOptionsWarning={handleSendOptionsWarning}
                onClose={handleOnClose}
                shouldClose={shouldCloseState}
                onCloseRejected={handleOnCloseRejected}
                classes={{
                    workflowDefinitionSelect: classes.workflowDefinitionSelectMargin,
                    messageFields: classes.messageFieldsMargin,
                }}
                sendButtonDisabled={noRecipient}
                messageStatus={messageBody === bodyState ? messageStatus : undefined}
            />
        );

    return (
        <WidgetDialog
            testId="send-message-dialog"
            open
            title={title}
            headerActions={headerActions}
            onClose={handleClosed}
        >
            <>
                {sendOptionsWarningBanner}
                {sendMessageFailed}
                {recipientNumberWarning}
                {recipientList}
                {content}
            </>
        </WidgetDialog>
    );
};
