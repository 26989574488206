import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { featureFlagsSelector } from '~/selectors';

import type { FeatureFlagsReduxProps } from './redux';
import { mapStateToProps } from './redux';

export type { FeatureFlagsReduxProps as InjectedFeatureFlagsProps, FeatureFlagsProp } from './redux';

export const withFeatureFlags = <WrappedProps extends FeatureFlagsReduxProps>(): ComponentEnhancer<
    WrappedProps,
    Exclude<WrappedProps, FeatureFlagsReduxProps>
> =>
    compose<WrappedProps, Exclude<WrappedProps, FeatureFlagsReduxProps>>(
        connect(createSelector(featureFlagsSelector, mapStateToProps))
    );
