import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DeleteRtdsScheduleDialogInnerProps, DeleteRtdsScheduleDialogProps } from './component';
import { DeleteRtdsScheduleDialogComponent } from './component';

export const DeleteRtdsScheduleDialog = compose<DeleteRtdsScheduleDialogInnerProps, DeleteRtdsScheduleDialogProps>(
    setDisplayName('DeleteRtdsScheduleDialog'),
    withTranslation()
)(DeleteRtdsScheduleDialogComponent);
