import { orderBy } from '~/libs/utility';
import type {
    ConversationMessageCorrelationRef,
    ResolvedConversation,
    ResolvedConversationUpdate,
    ResolvedMessage,
} from '~/services/ApiClient';
import { OptimisticOutgoingConversationMessage } from '~/services/ApiClient';

export const mergeConversationMessages = (
    conversation: ResolvedConversation,
    conversationUpdate: ResolvedConversationUpdate,
    correlationRefs?: ConversationMessageCorrelationRef[]
): ResolvedMessage[] => {
    const correlationRefsCorrelationIds = correlationRefs
        ? correlationRefs.map((correlationRef) => correlationRef.correlationId)
        : [];

    return orderBy(
        [
            ...conversation.messages.filter(({ value: conversationMessage }) => {
                const updatedMessage = conversationUpdate.updatedMessages.find(
                    ({ value: { id } }) => id === conversationMessage.id
                )?.value;

                const isDeleted =
                    conversationUpdate.revision > conversation.revision &&
                    conversationUpdate.deletedMessages.includes(conversationMessage.id);

                const isUpdated = updatedMessage && updatedMessage.revision > conversationMessage.revision;
                const isReplacedWithActualMessage =
                    conversationMessage instanceof OptimisticOutgoingConversationMessage &&
                    correlationRefsCorrelationIds.includes(conversationMessage.correlationId);

                return !isDeleted && !isUpdated && !isReplacedWithActualMessage;
            }),
            ...conversationUpdate.updatedMessages.filter(({ value: updatedMessage }) => {
                if (
                    conversation.messages.length &&
                    updatedMessage.dateTime < conversation.messages[conversation.messages.length - 1].value.dateTime
                ) {
                    return false;
                }

                const conversationMessage = conversation.messages.find(({ value: { id } }) => id === updatedMessage.id)
                    ?.value;

                const isNew = !conversationMessage;
                const isUpdated = conversationMessage && conversationMessage.revision < updatedMessage.revision;

                return isNew || isUpdated;
            }),
        ],
        ['value.dateTime', 'value.id'],
        ['desc', 'desc']
    );
};
