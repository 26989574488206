import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { ConversationSummarySecondLineProps } from './component';

export type ConversationSummarySecondLineClassKey =
    | 'root'
    | 'statusIndicator'
    | 'message'
    | 'messageFailed'
    | 'messageUnread'
    | 'noMessageSentYet'
    | 'unreadMessageCount'
    | 'author'
    | 'messageBody';

export const ConversationSummarySecondLineStyleRules: StyleRulesCallback<
    Theme,
    ConversationSummarySecondLineProps,
    ConversationSummarySecondLineClassKey
> = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            height: 20,
        },
        statusIndicator: {
            display: 'inline-flex',
            fontSize: 14,
            marginRight: theme.spacing(0.5),
            color: theme.palette.text.secondary,
        },
        message: {
            flexGrow: 1,
            width: 0,
        },
        messageFailed: {
            color: theme.palette.error.main,
        },
        messageUnread: {
            fontWeight: 600,
        },
        noMessageSentYet: {
            fontStyle: 'italic',
        },
        unreadMessageCount: {
            marginLeft: theme.spacing(),
        },
        author: {
            color: ({ conversationSummary }) =>
                conversationSummary.lastMessage?.value.isDeleted
                    ? alpha(theme.palette.text.primary, 0.38)
                    : theme.palette.text.primary,
        },
        messageBody: {
            color: ({ conversationSummary }) =>
                conversationSummary.lastMessage?.value.isDeleted
                    ? alpha(theme.palette.text.primary, 0.38)
                    : theme.palette.text.primary,
            textDecoration: ({ conversationSummary }) =>
                conversationSummary.lastMessage?.value.isDeleted ? 'line-through' : 'none',
        },
    });
