import { Table } from '@devexpress/dx-react-grid-material-ui';
import type { FC } from 'react';

import { useStyles } from './styles';

export interface NoDataCellProps extends Table.NoDataCellProps {
    width: number;
}

const NoDataCell: FC<NoDataCellProps> = (props) => {
    const { width, ...restProps } = props;

    const classes = useStyles({ width });

    return <Table.NoDataCell {...restProps} className={classes.text} data-id="no-data" />;
};

NoDataCell.displayName = 'NoDataCell';
export default NoDataCell;
