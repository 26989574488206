import * as React from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SceneAssetSelectorViewMode } from '../../preferences';

import type { AssetSelectorTabsProps } from './models';
import { Paper, Tab, TabContainer } from './styles';

const AssetSelectorTabs: FC<AssetSelectorTabsProps> = (props: AssetSelectorTabsProps) => {
    const { assetName, viewMode, changeViewMode } = props;
    const { t } = useTranslation();

    const handleChangeEvent = (_: React.ChangeEvent, value: SceneAssetSelectorViewMode) => {
        if (value !== viewMode) {
            changeViewMode(value);
        }
    };

    return (
        <Paper square>
            <TabContainer
                variant="fullWidth"
                value={viewMode}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeEvent}
                data-testid="asset-selector-tabs"
            >
                <Tab
                    label={assetName}
                    value={SceneAssetSelectorViewMode.ASSETS}
                    data-testid="asset-selector-tab:assets"
                />
                <Tab
                    label={t('depots')}
                    value={SceneAssetSelectorViewMode.DEPOTS}
                    data-testid="asset-selector-tab:depots"
                />
                <Tab
                    label={t('groups')}
                    value={SceneAssetSelectorViewMode.GROUPS}
                    data-testid="asset-selector-tab:groups"
                />
            </TabContainer>
        </Paper>
    );
};

AssetSelectorTabs.displayName = 'AssetSelectorTabs';
export { AssetSelectorTabs };
