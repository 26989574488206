import * as React from 'react';
import type { AnyAction, Store } from 'redux';
import type { Persistor } from 'redux-persist';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { SceneLoader } from '~/components/SceneLoader';
import { reportError } from '~/reporting';

import { flushAndOverridePersistor } from './flushAndOverridePersistor';

export interface PersistStoreStateProps {
    persistor?: Persistor;
}

export interface PersistStoreDispatchProps {
    overridePersistor: (persistor: Persistor) => void;
}

export interface PersistStoreProps {
    reduxStore: Store<unknown, AnyAction>;
}

export interface PersistStoreInnerProps extends PersistStoreProps, PersistStoreStateProps, PersistStoreDispatchProps {}

export class PersistStoreComponent extends React.Component<PersistStoreInnerProps> {
    public componentDidMount(): void {
        if (!this.props.persistor) {
            const persistor = persistStore(this.props.reduxStore);
            this.props.overridePersistor(persistor);
        }
    }

    public componentDidUpdate(prevProps: PersistStoreInnerProps): void {
        if (prevProps.reduxStore !== this.props.reduxStore) {
            throw new Error('Property store is not supposed to be changed.');
        }

        if (prevProps.persistor && !this.props.persistor) {
            flushAndOverridePersistor(prevProps.persistor, this.props.reduxStore, this.props.overridePersistor).catch(
                reportError
            );
        }
    }

    public render(): React.ReactNode {
        if (!this.props.persistor) {
            return <SceneLoader />;
        }

        return (
            <PersistGate loading={<SceneLoader />} persistor={this.props.persistor}>
                {this.props.children}
            </PersistGate>
        );
    }
}
