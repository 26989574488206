import type { ConversationUpdate } from '~/services/ApiClient';

/**
 * Returns the deleted messages that were
 * not added back in a newer conversation update.
 */
export const filterDeletedMessages = (
    toBeFiltered: ConversationUpdate,
    forComparison: ConversationUpdate
): number[] => {
    if (forComparison.revision > toBeFiltered.revision) {
        return toBeFiltered.deletedMessages.filter(
            (deletedMessageId) => !forComparison.updatedMessages.some(({ id }) => id === deletedMessageId)
        );
    }

    return toBeFiltered.deletedMessages;
};
