import type { ResolvedConversation } from '~/services/ApiClient';
import {
    OptimisticOutgoingConversationMessage,
    OptimisticOutgoingConversationMessageStatus,
} from '~/services/ApiClient';

/**
 * merges non failed optimistic messages into conversation
 */

export const mergeOptimisticMessagesIntoConversation = (
    conversationState: ResolvedConversation | undefined,
    conversationResponse: ResolvedConversation
): ResolvedConversation => {
    if (!conversationState) {
        return conversationResponse;
    }

    const filteredOptimisticOutgoingMessages = conversationState.messages.filter(
        ({ value }) =>
            value instanceof OptimisticOutgoingConversationMessage &&
            value.status !== OptimisticOutgoingConversationMessageStatus.Pending
    );

    const conversation = {
        ...conversationResponse,
        messages: [...filteredOptimisticOutgoingMessages, ...conversationResponse.messages],
    };

    return conversation;
};
