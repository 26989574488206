import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import type { DriverActivityKeys } from '~/services/ApiClient';

import type { ActivitySurfaceClassKey } from './styles';

export interface ActivitySurfaceProps {
    isActive: boolean;
    activity: DriverActivityKeys;
    className: string;
    style?: React.CSSProperties;
    dataId?: string;
}

export interface ActivitySurfaceInnerProps extends ActivitySurfaceProps, WithStyles<ActivitySurfaceClassKey> {}

export const ActivitySurfaceComponent: React.FC<ActivitySurfaceInnerProps> = ({
    activity,
    classes,
    className,
    style,
    isActive,
    dataId,
}) => {
    const backgroundClasses = classNames(classes.root, className, classes[`activity${activity}`], {
        [classes.active]: isActive,
    });
    return <span className={backgroundClasses} style={style} data-id={dataId} />;
};
