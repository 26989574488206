import type { FC } from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatBoolean } from '~/services/Formatters';

export interface BooleanFormatterProps {
    t: SingleTFunction;
    value?: boolean;
}

const BooleanFormatter: FC<BooleanFormatterProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatBoolean(t, value)}</>;
};

BooleanFormatter.displayName = 'BooleanFormatter';
export default BooleanFormatter;
