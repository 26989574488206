import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BannerType } from './consts';
import type { TopBannerProps } from './models';
import { useStyles } from './styles';

const TopBanner: FC<TopBannerProps> = (props) => {
    const { title, dataId, onDismiss, type } = props;

    const { t } = useTranslation();
    const classes = useStyles({ type });

    return (
        <div className={classes.root} data-testid={`top-banner-disclaimer:${dataId}`}>
            <span className={classes.messageContainer} data-id="content">
                {type === BannerType.Warning ? (
                    <WarningIcon className={classes.icon} />
                ) : (
                    <ErrorIcon className={classes.icon} />
                )}

                <Typography align="center" variant="caption" color="textSecondary" className={classes.message}>
                    {title}
                </Typography>
            </span>
            {onDismiss && (
                <Button
                    disableFocusRipple
                    disableRipple
                    onClick={onDismiss}
                    className={classes.button}
                    data-testid="close"
                >
                    {t('dismiss')}
                </Button>
            )}
        </div>
    );
};

TopBanner.displayName = 'TopBanner';
export default TopBanner;
