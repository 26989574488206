import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SendMessageBoxClassKey =
    | 'root'
    | 'sendMessageContainer'
    | 'input'
    | 'sendFab'
    | 'sendIcon'
    | 'openWorkflowFab'
    | 'openWorkflowSendIcon'
    | 'characterCountContainer'
    | 'characterCount'
    | 'none';

export const maxInputRows = 2;

export const SendMessageBoxStyleRules: StyleRulesCallback<Theme, {}, SendMessageBoxClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        sendMessageContainer: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: theme.spacing(),
            paddingBottom: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingLeft: theme.spacing(2),
        },
        input: {
            minHeight: 32,
            paddingTop: theme.spacing(),
            paddingBottom: theme.spacing(),
            marginRight: theme.spacing(),
            flexGrow: 1,
            color: theme.palette.text.primary,
            border: 'none',
            '&::placeholder': {
                color: theme.palette.text.secondary,
                fontStyle: 'italic',
            },
            resize: 'none',
            outline: 'none',
            fontFamily: theme.typography.fontFamily,
        },
        sendFab: {
            width: 32,
            height: 32,
            minHeight: 32,
            fontSize: theme.typography.pxToRem(14),
        },
        sendIcon: {
            fontSize: theme.typography.pxToRem(14),
        },
        openWorkflowFab: {
            width: 32,
            height: 32,
            minHeight: 32,
            backgroundColor: theme.palette.common.white,
            color: theme.palette.grey[500],
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        openWorkflowSendIcon: {
            width: 22,
            height: 22,
        },
        characterCountContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            background: theme.palette.background.default,
            paddingRight: theme.spacing(),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
        },
        characterCount: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(11),
        },
        none: {
            display: 'none',
        },
    });
