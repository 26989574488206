import { isUndefined } from '~/libs/utility';
import type { DriverEventFieldValue, ResolvedDriverEventFieldValue } from '~/services/ApiClient';
import { DriverEventField, createApiModel } from '~/services/ApiClient';

const getDriverEventField = (fieldId: number, eventFields?: DriverEventField[]): DriverEventField => {
    const driverEventField = eventFields?.find((field) => fieldId === field.id);
    if (isUndefined(driverEventField)) {
        return createApiModel(DriverEventField, { id: fieldId, name: fieldId.toString() });
    }
    return driverEventField;
};

export const eventFieldValueResolver = (
    driverEventFieldValues?: DriverEventFieldValue[],
    driverEventFields?: DriverEventField[]
): ResolvedDriverEventFieldValue[] => {
    return driverEventFieldValues
        ? driverEventFieldValues.map((it) => ({
              field: getDriverEventField(it.fieldId, !isUndefined(driverEventFields) ? driverEventFields : undefined),
              value: it.value,
          }))
        : [];
};
