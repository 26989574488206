import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type EnumerationWorkflowFieldClassKey = 'label';

export const EnumerationWorkflowFieldStyleRules: StyleRulesCallback<Theme, {}, EnumerationWorkflowFieldClassKey> = () =>
    createStyles({
        label: {
            fontSize: 14,
        },
    });
