import type { Address } from '~/common';

import { AddressFormat } from './enums';

export interface AddressFormatOptions {
    format: AddressFormat;
}

export const formatAddress = (
    address: Address,
    options: AddressFormatOptions = { format: AddressFormat.FirstLine }
): string => {
    switch (options.format) {
        case AddressFormat.Full: {
            const first = [address.street, address.houseNumber].filter(Boolean).join(' ');
            const second = [address.postalCode, address.city, address.countryCode].filter(Boolean).join(' ');
            return first && second ? `${first}, ${second}` : first ?? second;
        }
        case AddressFormat.SecondLine:
            return [address.postalCode, address.city, address.countryCode].filter(Boolean).join(' ');
        case AddressFormat.FirstLine:
            return [address.street, address.houseNumber].filter(Boolean).join(' ');
        default:
            throw new Error('Unknown address format');
    }
};
