import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { NoOlderMessagesToDisplayInnerProps, NoOlderMessagesToDisplayProps } from './component';
import { NoOlderMessagesToDisplayComponent } from './component';
import { NoOlderMessagesToDisplayStyleRules } from './styles';

export const NoOlderMessagesToDisplay = compose<NoOlderMessagesToDisplayInnerProps, NoOlderMessagesToDisplayProps>(
    setDisplayName('NoOlderMessagesToDisplay'),
    withTranslation(),
    withStyles(NoOlderMessagesToDisplayStyleRules)
)(NoOlderMessagesToDisplayComponent);
