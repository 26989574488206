import type { Dispatch } from 'redux';

import type { SettingsStoreState } from '~/components/EnsureSettings';
import { sendMessageAction } from '~/data/communication';
import type { NumericDictionary } from '~/libs/utility';
import { reportError } from '~/reporting';
import type {
    MessageBody,
    OptimisticOutgoingConversationMessageStatus,
    SendMessageOptions,
    VehicleMessagingCapabilities,
} from '~/services/ApiClient';

import type { SendMessageDialogDispatchProps } from './component';
import type { SendMessageDialogStoreState } from './reducers';

export interface SendMessageDialogStateProps {
    messageStatus?: OptimisticOutgoingConversationMessageStatus;
    messageBody?: MessageBody;
    vehicleMessagingCapabilities: NumericDictionary<VehicleMessagingCapabilities>;
}

export const mapStateToProps = (
    store: SendMessageDialogStoreState,
    settingsState: SettingsStoreState
): SendMessageDialogStateProps => ({
    messageStatus: store.messageStatus,
    messageBody: store.messageBody,
    vehicleMessagingCapabilities: settingsState.vehicleMessagingCapabilities.data,
});

export const mapDispatchToProps = (dispatch: Dispatch): SendMessageDialogDispatchProps => ({
    sendMessage: (recipients: number[], message: MessageBody, options: SendMessageOptions) => {
        dispatch(sendMessageAction(recipients, message, options)).catch(reportError);
    },
});
