import { useDisplayPreferences } from '~/common/hooks';
import type { MonitoringTrailerEntry, MonitoringVehicleEntry } from '~/data/monitoring';

import { MAP_KEYS } from '../consts';

import { TrailerInfoBox, VehicleInfoBox } from './components';
import type { AssetMarkerPopupProps } from './models';

const AssetMarkerPopup = <T,>(props: AssetMarkerPopupProps<T>) => {
    const { mapKey, entry } = props;

    const { trailerDisplayFormat, driverDisplayFormat, vehicleDisplayFormat } = useDisplayPreferences();

    switch (mapKey) {
        case MAP_KEYS.trailer:
            return (
                <TrailerInfoBox
                    vehicleDisplayFormat={vehicleDisplayFormat}
                    driverDisplayNameFormat={driverDisplayFormat}
                    entry={entry as MonitoringTrailerEntry}
                />
            );

        case MAP_KEYS.vehicle:
            return (
                <VehicleInfoBox
                    driverDisplayNameFormat={driverDisplayFormat}
                    trailerDisplayFormat={trailerDisplayFormat}
                    entry={entry as MonitoringVehicleEntry}
                />
            );

        default:
            return null;
    }
};

AssetMarkerPopup.displayName = 'AssetMarkerPopup';
export { AssetMarkerPopup };
