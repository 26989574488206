import type { SceneAssetSelectorUserPreferences } from '~/components/SceneAssetSelector';
import type { RetrievableHashedData } from '~/reducers';
import type {
    AssetReference,
    ResolvedAdminVehicleRead,
    Securables,
    ServicesSecurables,
    VehicleAdministrationSecurables,
} from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import type { AdminVehicleStoreState } from './reducers';

export interface VehicleAdministrationReduxProps extends StateProps {}

export interface StateProps {
    vehicleAdministrationAuthorizations: VehicleAdministrationSecurables;
    servicesSecurables: ServicesSecurables;
    dataSource: ResolvedAdminVehicleRead[];
}

export const getSelectedAdminVehicles = memoizeOne(
    (vehicles: RetrievableHashedData<ResolvedAdminVehicleRead>, selectedAssetIds?: AssetReference[]) => {
        if (!vehicles.fulfilled || !vehicles.data?.hash) {
            return [];
        }

        if (!selectedAssetIds) {
            return vehicles.data.array;
        }

        const selectedVehiclesIds = selectedAssetIds
            .filter((selectedAsset) => selectedAsset.type === AssetType.Vehicle)
            .map((selectedAsset) => selectedAsset.id);

        const selectedVehicles = selectedVehiclesIds
            .filter((vehicleId) => vehicles.data.hash[vehicleId])
            .map((vehicleId) => vehicles.data.hash[vehicleId]);

        return selectedVehicles;
    }
);

export const mapStateToProps = (
    securables: Securables,
    vehicleAdministrationStoreState: AdminVehicleStoreState,
    vehicleAdministrationAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences
): StateProps => {
    const { adminVehicles } = vehicleAdministrationStoreState;

    return {
        vehicleAdministrationAuthorizations: securables.assetAdministration.vehicles,
        servicesSecurables: securables.services,
        dataSource: getSelectedAdminVehicles(
            adminVehicles,
            vehicleAdministrationAssetSelectorUserPreferences.selectedAssetIds
        ),
    };
};
