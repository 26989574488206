import { createAction } from 'redux-actions';

import type { ConversationNotification } from '~/services/ApiClient';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { ConversationMessagesPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const retrieveLatestMessagesActionExecutor = (
    vehicleId: number,
    count: number,
    conversationProfileId: number,
    includeDeleted?: boolean
): Promise<ConversationMessagesPayload> => {
    return retryableRequest(() =>
        ApiClient.getConversationMessages(vehicleId, count, undefined, undefined, conversationProfileId, includeDeleted)
    );
};

export const retrieveLatestMessagesAction = createAction<
    Promise<ConversationMessagesPayload>,
    number,
    number,
    number,
    number
>(ActionTypePrefixes.LATESTMESSAGES, retrieveLatestMessagesActionExecutor, (vehicleId: number) => vehicleId);

export const updateLatestMessagesAction = createAction<ConversationNotification, number, ConversationNotification>(
    ActionTypeKeys.LATESTMESSAGES_UPDATE,
    (conversationNotification: ConversationNotification) => conversationNotification,
    ({ data: { vehicleId } }: ConversationNotification) => vehicleId
);

export const applyPendingUpdatesAction = createAction<undefined, number, number>(
    ActionTypeKeys.LATESTMESSAGES_APPLY_PENDING,
    () => undefined,
    (vehicleId: number) => vehicleId
);

export const clearLatestConversationMessagesAction = createAction<undefined, number, number>(
    ActionTypeKeys.LATESTMESSAGES_CLEAR,
    () => undefined,
    (vehicleId: number) => vehicleId
);
