import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { Classification, ReeferStatus, ResolvedReeferStatus } from '~/services/ApiClient';

export const reeferStatusResolver = (
    { init, toJSON, ...reeferStatusData }: ReeferStatus,
    reeferStatus: NumericDictionary<Classification>,
    reeferAlarm: NumericDictionary<Classification>,
    reeferOperationMode: NumericDictionary<Classification>,
    reeferPowerMode: NumericDictionary<Classification>,
    reeferSpeed: NumericDictionary<Classification>
): ResolvedReeferStatus => {
    const { status, alarms, operationMode, powerMode, speed } = reeferStatusData;

    const resolvedAlarms = alarms ? alarms.map((alarm) => reeferAlarm[alarm]) : undefined;

    return {
        ...reeferStatusData,
        status: !isUndefined(status) ? reeferStatus[status] : undefined,
        alarms: resolvedAlarms,
        operationMode: !isUndefined(operationMode) ? reeferOperationMode[operationMode] : undefined,
        powerMode: !isUndefined(powerMode) ? reeferPowerMode[powerMode] : undefined,
        speed: !isUndefined(speed) ? reeferSpeed[speed] : undefined,
    };
};
