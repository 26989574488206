import { createAction } from 'redux-actions';

import type { VehicleConnectionsNotification } from '~/services/ApiClient';
import {
    ApiClient,
    ConnectTrailerRequest,
    DisconnectTrailerRequest,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import type { VehicleConnectionsPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

const retrieveVehicleConnectionsExecutor = (): Promise<VehicleConnectionsPayload> =>
    retryableRequest(() => ApiClient.getVehicleConnections());

const connectTrailerExecutor = (vehicleId: number, trailerId: number): Promise<void> =>
    retryableRequest(() => ApiClient.connectTrailer(vehicleId, createApiModel(ConnectTrailerRequest, { trailerId })));

const disconnectTrailerExecutor = (vehicleId: number, trailerId: number): Promise<void> =>
    retryableRequest(() =>
        ApiClient.disconnectTrailer(vehicleId, createApiModel(DisconnectTrailerRequest, { trailerId }))
    );

export const retrieveVehicleConnectionsAction = createAction<Promise<VehicleConnectionsPayload>>(
    ActionTypePrefixes.VEHICLECONNECTIONS,
    retrieveVehicleConnectionsExecutor
);

export const connectTrailerAction = createAction<Promise<void>, number, number, number>(
    ActionTypePrefixes.VEHICLECONNECTIONS_CONNECTTRAILER,
    connectTrailerExecutor,
    (_: number, trailerId: number): number => trailerId
);

export const disconnectTrailerAction = createAction<Promise<void>, number, number, number>(
    ActionTypePrefixes.VEHICLECONNECTIONS_DISCONNECTTRAILER,
    disconnectTrailerExecutor,
    (_: number, trailerId: number): number => trailerId
);

export const updateVehicleConnectionsAction = createAction(
    ActionTypeKeys.VEHICLECONNECTIONS_UPDATE,
    (notification: VehicleConnectionsNotification) => notification
);
