import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '../Dialog';

import type { ConfirmDialogProps } from './models';
import { MessageContainer, Spinner, Text } from './styles';

const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
    const { onClose, onSuccess, onConfirm, progressText, title, text, actionText, testId } = props;

    const [status, setStatus] = useState({ isLoading: false, error: false });
    const { t } = useTranslation();

    const handleConfirm = () => {
        setStatus({ isLoading: true, error: false });

        onConfirm()
            .then(() => {
                setStatus({ isLoading: false, error: false });
                onSuccess();
            })
            .catch(() => {
                setStatus({ isLoading: false, error: true });
            });
    };

    return (
        <Dialog
            confirmationActionText={actionText}
            isDisabled={status?.isLoading}
            onConfirm={handleConfirm}
            onClose={onClose}
            title={title}
            data-testid={`${testId}-confirm-dialog`}
        >
            <>
                <Typography data-testid={`${testId}-confirm-dialog-text`}>{text}</Typography>
                {status.error && (
                    <Typography variant="caption" color="error" data-testid={`${testId}-confirm-dialog-error-message`}>
                        {t('generic-error-message')}
                    </Typography>
                )}

                {status.isLoading && (
                    <MessageContainer>
                        <Spinner size={30} />
                        <Text variant="subtitle2" data-testid={`${testId}-confirm-dialog-progress-message`}>
                            {progressText}
                        </Text>
                    </MessageContainer>
                )}
            </>
        </Dialog>
    );
};

ConfirmDialog.displayName = 'ConfirmDialog';
export { ConfirmDialog };
