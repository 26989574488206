import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { SceneDetailsPaneHeaderInnerProps, SceneDetailsPaneHeaderProps } from './component';
import { SceneDetailsPaneHeaderComponent } from './component';
import { SceneDetailsPaneHeaderStyleRules } from './styles';

export const SceneDetailsPaneHeader = compose<SceneDetailsPaneHeaderInnerProps, SceneDetailsPaneHeaderProps>(
    setDisplayName('SceneDetailsPaneHeader'),
    withTranslation(),
    withStyles(SceneDetailsPaneHeaderStyleRules)
)(SceneDetailsPaneHeaderComponent);
