import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type CountryFormatterClassKey = 'flag';

export const CountryFormatterStyleRules: StyleRulesCallback<Theme, {}, CountryFormatterClassKey> = (theme: Theme) =>
    createStyles({
        flag: {
            marginRight: theme.spacing(),
        },
    });
