import * as React from 'react';
import { Redirect, generatePath } from 'react-router-dom';

import { AssetSelector } from '~/common';
import { AskForPageReload } from '~/components/AskForPageReload';
import { DateTimeRangePicker } from '~/components/DateTimeRangePicker';
import { PageTemplate, WidgetsViewMode } from '~/components/PageTemplate';
import type { DetailsPaneContextProps } from '~/components/SceneDetailsPane';
import { DetailsPaneContext } from '~/components/SceneDetailsPane';
import { SceneLoader } from '~/components/SceneLoader';
import { SceneMapViewHeader } from '~/components/SceneMap';
import type {
    MonitoringContainerEntry,
    MonitoringDriverEntry,
    MonitoringState,
    MonitoringTrailerEntry,
    MonitoringVehicleEntry,
} from '~/data/monitoring';
import {
    AssetSubpage,
    MONITORING_CONTAINER_ASSETSELECTOR_USERPREFERENCES_KEY,
    MONITORING_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY,
    MONITORING_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY,
    MONITORING_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY,
    MonitoringPerspective,
    MonitoringViewMode,
} from '~/data/monitoring';
import { isUndefined, memoize } from '~/libs/utility';
import type { Disposable } from '~/listeners';
import { ConversationWidgets, ConversationsSummaryWidgetFactory } from '~/modules/Communication';
import { reportError } from '~/reporting';
import { SceneRoutes } from '~/routes';
import type { AssetReference } from '~/services/ApiClient';
import {
    AssetType,
    ContainerStatusNotification,
    DriverHoursByVehicleNotification,
    DriverRole,
    DriverStatusNotification,
    InhibitorStatusNotification,
    RouteStatusNotification,
    TrailerStatusNotification,
    VehicleConnectionsNotification,
    VehicleStatusNotification,
} from '~/services/ApiClient';
import { subscribeToNotificationsAndRetrieveTheData } from '~/services/SignalR';

import { AssetContextMenu, AssetContextMenuType } from './components/AssetContextMenu';
import { ContainerDetailsPane, TrailerDetailsPane, VehicleDetailsPane } from './components/DetailsPane';
import { ContainerHistorySubpage } from './components/DetailsPane/components/Containers';
import { DriverStatusDetailsPane } from './components/DetailsPane/components/Drivers';
import { DriverSubpage as DriverPerspectiveDriverSubpage } from './components/DetailsPane/components/Drivers/components/Sections/Staff/components/Summary/components/DriverSubpage';
import { TrailerHistorySubpage } from './components/DetailsPane/components/Trailers';
import { VehicleHistorySubpage } from './components/DetailsPane/components/Vehicles';
import { DriverSubpage } from './components/DetailsPane/components/Vehicles/components/DriverSubpage';
import {
    ContainerListView,
    ContainerListViewHeader,
    DriverListView,
    DriverListViewHeader,
    TrailerListView,
    TrailerListViewHeader,
    VehicleListView,
    VehicleListViewHeader,
} from './components/List';
import {
    ContainerHistoryMap,
    ContainerMapView,
    MONITORING_MAP_CONTAINER_STATE_KEY,
    MONITORING_MAP_TRAILER_STATE_KEY,
    MONITORING_MAP_VEHICLE_STATE_KEY,
    TrailerHistoryMap,
    TrailerMapView,
    VehicleHistoryMap,
    VehicleMapView,
} from './components/Map';
import { ModuleBarActions } from './components/ModuleBarActions';
import { MONITORING_DATETIMERANGE, MONITORING_PATH_STRUCTURE } from './consts';
import type { MonitoringInnerProps, OnSelectProps } from './models';

const ConversationsSummaryWidget = ConversationsSummaryWidgetFactory(
    MONITORING_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY
);

export class MonitoringComponent extends React.Component<MonitoringInnerProps, MonitoringState> {
    private disposableHandlers: Disposable[] = [];

    private buildAssetSelectorContextMenuHandlerMemoized = memoize(
        (menuType: AssetContextMenuType, perspective: MonitoringPerspective) =>
            (evt: MouseEvent, assetReference: AssetReference) => {
                this.openContextMenu(evt, menuType, perspective as unknown as AssetType, assetReference.id);
            },
        (menuType, perspective) => `${menuType}-${perspective}`
    );

    private buildContextMenuHandlerMemoized = memoize(
        (menuType: AssetContextMenuType, perspective: MonitoringPerspective) => (evt: MouseEvent, assetId: number) => {
            this.openContextMenu(evt, menuType, perspective as unknown as AssetType, assetId);
        },
        (menuType, perspective) => `${menuType}-${perspective}`
    );

    public constructor(props: MonitoringInnerProps) {
        super(props);
        this.state = {};
    }

    public async componentDidMount(): Promise<void> {
        this.props.staticDataProvider.retrieveAssetGroups();
        this.props.staticDataProvider.retrieveDepots();
        this.props.staticDataProvider.retrieveVehicles();
        this.props.staticDataProvider.retrieveDrivers();

        if (this.props.supportedPerspectives.includes(MonitoringPerspective.TRAILER)) {
            this.props.staticDataProvider.retrieveTrailers();

            await subscribeToNotificationsAndRetrieveTheData(
                'trailerstatus',
                TrailerStatusNotification.fromJS,
                (notification: TrailerStatusNotification) => {
                    this.props.updateTrailerStatus(notification.data);
                },
                () => {
                    this.props.getTrailerStatus();
                }
            ).then((disposable: Disposable) => {
                this.disposableHandlers.push(disposable);
            }, reportError);
        }

        if (this.props.canViewRouting) {
            await subscribeToNotificationsAndRetrieveTheData(
                'routestatus',
                RouteStatusNotification.fromJS,
                (notification: RouteStatusNotification) => {
                    this.props.updateRouteStatus(notification.data);
                },
                () => {
                    this.props.getVehicleRouteStatuses();
                }
            ).then((disposable: Disposable) => {
                this.disposableHandlers.push(disposable);
            }, reportError);
        }

        if (this.props.supportedPerspectives.includes(MonitoringPerspective.CONTAINER)) {
            this.props.staticDataProvider.retrieveContainers();

            await subscribeToNotificationsAndRetrieveTheData(
                'containerstatus',
                ContainerStatusNotification.fromJS,
                (notification: ContainerStatusNotification) => {
                    this.props.updateContainerStatus(notification.data);
                },
                () => {
                    this.props.getContainerStatus();
                }
            ).then((disposable: Disposable) => {
                this.disposableHandlers.push(disposable);
            }, reportError);
        }

        if (this.props.supportedPerspectives.includes(MonitoringPerspective.VEHICLE)) {
            await subscribeToNotificationsAndRetrieveTheData(
                'vehiclestatus',
                VehicleStatusNotification.fromJS,
                (notification: VehicleStatusNotification) => {
                    this.props.updateVehicleStatus(notification.data);
                },
                () => {
                    this.props.getVehicleStatus();
                }
            ).then((disposable: Disposable) => {
                this.disposableHandlers.push(disposable);
            }, reportError);
        }

        await subscribeToNotificationsAndRetrieveTheData(
            'vehicleconnections',
            VehicleConnectionsNotification.fromJS,
            (notification: VehicleConnectionsNotification) => {
                this.props.updateVehicleConnections(notification);
            },
            () => {
                this.props.getVehicleConnections();
            }
        ).then((disposable: Disposable) => {
            this.disposableHandlers.push(disposable);
        }, reportError);

        if (this.props.canStartInhibitor) {
            await subscribeToNotificationsAndRetrieveTheData(
                'inhibitorstatus',
                InhibitorStatusNotification.fromJS,
                (notification: InhibitorStatusNotification) => {
                    this.props.updateInhibitorStatus(notification.data);
                },
                () => {
                    this.props.getInhibitorStatuses();
                }
            ).then((disposable: Disposable) => {
                this.disposableHandlers.push(disposable);
            }, reportError);
        }

        if (this.props.supportedPerspectives.includes(MonitoringPerspective.DRIVER)) {
            this.props.staticDataProvider.retrieveDriverStatus();

            if (this.props.supportedPerspectives.includes(MonitoringPerspective.VEHICLE)) {
                await subscribeToNotificationsAndRetrieveTheData(
                    'driverhours/byvehicle',
                    DriverHoursByVehicleNotification.fromJS,
                    (notification: DriverHoursByVehicleNotification) => {
                        this.props.updateVehicleDriverHoursStatus(notification.data);
                    },
                    () => {
                        this.props.getVehicleDriverHoursStatus();
                    }
                ).then((disposable: Disposable) => {
                    this.disposableHandlers.push(disposable);
                }, reportError);
            }

            await subscribeToNotificationsAndRetrieveTheData(
                'driverstatus',
                DriverStatusNotification.fromJS,
                (notification: DriverStatusNotification) => {
                    this.props.updateDriverStatus(notification.data);
                },
                () => {
                    this.props.getDriverStatuses();
                }
            ).then((disposable: Disposable) => {
                this.disposableHandlers.push(disposable);
            }, reportError);
        }
    }

    public componentWillUnmount(): void {
        this.disposableHandlers.forEach(({ dispose }) => dispose());
        this.props.clearData();
    }

    public render(): React.ReactNode {
        const {
            t,
            rejected,
            loading,
            leftPaneIsOpen,
            toggleLeftPaneVisibility,
            perspective,
            supportedPerspectives,
            classes,
        } = this.props;

        if (!supportedPerspectives.includes(perspective)) {
            return <Redirect to={SceneRoutes.UNAUTHORIZED} />;
        }

        if (rejected) {
            return <AskForPageReload />;
        }

        if (loading) {
            return <SceneLoader />;
        }

        const leftPaneTitle = t((leftPaneIsOpen && 'collapse-asset-selector') || 'expand-asset-selector');
        return (
            <div className={classes.root} data-id="monitoring">
                <PageTemplate
                    header={this.getHeaderBar()}
                    leftPaneElement={this.getLeftPane()}
                    rightPaneElement={this.getRightPane()}
                    toggleLeftPaneVisibility={toggleLeftPaneVisibility}
                    leftPaneToggleTitle={leftPaneTitle}
                    widgetsViewMode={this.getWidgetViewMode()}
                    widgets={this.getWidgets()}
                >
                    {this.getViewContent()}
                </PageTemplate>
                <AssetContextMenu
                    {...this.state.assetContextMenu}
                    onClose={this.closeContextMenu}
                    viewMode={this.props.viewMode}
                />
            </div>
        );
    }

    private getPerspectiveButtons(): JSX.Element {
        return (
            <ModuleBarActions
                supportedPerspectives={this.props.supportedPerspectives}
                viewMode={this.props.viewMode}
                perspective={this.props.perspective}
                subpage={this.props.subpage}
                selectedAssetId={this.props.selectedAssetId}
                canViewMap={this.props.canViewMap}
            />
        );
    }

    private getHeaderBar(): JSX.Element {
        const { dateTimeRange, changeDateTimeRange, selectedAssetId, isHistoryMode } = this.props;
        const filter =
            selectedAssetId && isHistoryMode && dateTimeRange ? (
                <DateTimeRangePicker
                    value={dateTimeRange}
                    onChange={changeDateTimeRange}
                    maxDays={MONITORING_DATETIMERANGE.maxDays}
                    minDate={MONITORING_DATETIMERANGE.minDate}
                    maxDate={MONITORING_DATETIMERANGE.endDate}
                />
            ) : undefined;

        switch (this.props.viewMode) {
            case MonitoringViewMode.MAP:
                switch (this.props.perspective) {
                    case MonitoringPerspective.VEHICLE:
                        return (
                            <SceneMapViewHeader
                                mapKey={MONITORING_MAP_VEHICLE_STATE_KEY}
                                moduleBarActions={this.getPerspectiveButtons()}
                                title={this.props.t('monitoring')}
                                filters={filter}
                            />
                        );
                    case MonitoringPerspective.TRAILER:
                        return (
                            <SceneMapViewHeader
                                mapKey={MONITORING_MAP_TRAILER_STATE_KEY}
                                moduleBarActions={this.getPerspectiveButtons()}
                                title={this.props.t('monitoring')}
                                filters={filter}
                            />
                        );
                    case MonitoringPerspective.CONTAINER:
                        return (
                            <SceneMapViewHeader
                                mapKey={MONITORING_MAP_CONTAINER_STATE_KEY}
                                moduleBarActions={this.getPerspectiveButtons()}
                                title={this.props.t('monitoring')}
                                filters={filter}
                            />
                        );
                    default:
                        throw Error(`Unknown perspective: ${this.props.perspective}`);
                }

            case MonitoringViewMode.LIST:
                switch (this.props.perspective) {
                    case MonitoringPerspective.VEHICLE:
                        return (
                            <VehicleListViewHeader
                                dataSource={this.props.dataSource as MonitoringVehicleEntry[]}
                                perspectiveButtons={this.getPerspectiveButtons()}
                                title={this.props.t('monitoring')}
                                gridActionsDisabled={!this.props.dataSource.length}
                                filters={filter}
                            />
                        );
                    case MonitoringPerspective.TRAILER:
                        return (
                            <TrailerListViewHeader
                                dataSource={this.props.dataSource as MonitoringTrailerEntry[]}
                                perspectiveButtons={this.getPerspectiveButtons()}
                                title={this.props.t('monitoring')}
                                gridActionsDisabled={!this.props.dataSource.length}
                                filters={filter}
                            />
                        );
                    case MonitoringPerspective.CONTAINER:
                        return (
                            <ContainerListViewHeader
                                dataSource={this.props.dataSource as MonitoringContainerEntry[]}
                                perspectiveButtons={this.getPerspectiveButtons()}
                                title={this.props.t('monitoring')}
                                gridActionsDisabled={!this.props.dataSource.length}
                                filters={filter}
                            />
                        );
                    case MonitoringPerspective.DRIVER:
                        return (
                            <DriverListViewHeader
                                dataSource={this.props.dataSource as MonitoringDriverEntry[]}
                                perspectiveButtons={this.getPerspectiveButtons()}
                                title={this.props.t('monitoring')}
                                gridActionsDisabled={!this.props.dataSource.length}
                                filters={filter}
                            />
                        );
                    default:
                        throw Error(`Unknown perspective: ${this.props.perspective}`);
                }

            default:
                throw Error(`Unknown viewMode: ${this.props.viewMode}`);
        }
    }

    private getLeftPane(): JSX.Element | undefined {
        if (!this.props.leftPaneIsOpen) {
            return undefined;
        }

        const contextMenuHandler = this.buildAssetSelectorContextMenuHandlerMemoized(
            AssetContextMenuType.AssetSelector,
            this.props.perspective
        );
        switch (this.props.perspective) {
            case MonitoringPerspective.VEHICLE:
                return (
                    <AssetSelector
                        assetTypes={[AssetType.Vehicle]}
                        preferencesKey={MONITORING_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY}
                        onAssetContextMenu={contextMenuHandler}
                    />
                );
            case MonitoringPerspective.TRAILER:
                return (
                    <AssetSelector
                        preferencesKey={MONITORING_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY}
                        assetTypes={[AssetType.Trailer]}
                        onAssetContextMenu={contextMenuHandler}
                    />
                );
            case MonitoringPerspective.CONTAINER:
                return (
                    <AssetSelector
                        assetTypes={[AssetType.Container]}
                        preferencesKey={MONITORING_CONTAINER_ASSETSELECTOR_USERPREFERENCES_KEY}
                        onAssetContextMenu={contextMenuHandler}
                    />
                );
            case MonitoringPerspective.DRIVER:
                return (
                    <AssetSelector
                        assetTypes={[AssetType.Driver]}
                        preferencesKey={MONITORING_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY}
                    />
                );
            default:
                return undefined;
        }
    }

    private getSubpageContext(): DetailsPaneContextProps {
        return {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            openSubpage: () => {},
            close: () => {
                this.props.history.push(
                    generatePath(MONITORING_PATH_STRUCTURE, {
                        viewMode: this.props.viewMode,
                        perspective: this.props.perspective,
                    })
                );
            },
            goBack: () => {
                this.props.history.push(
                    generatePath(MONITORING_PATH_STRUCTURE, {
                        viewMode: this.props.viewMode,
                        perspective: this.props.perspective,
                        id: this.props.selectedAssetId,
                    })
                );
            },
        };
    }

    private getRightPane(): JSX.Element | undefined {
        if (!this.props.selectedAsset) {
            return undefined;
        }

        const subpageContext = this.getSubpageContext();

        switch (this.props.perspective) {
            case MonitoringPerspective.VEHICLE: {
                const vehicleEntry = this.props.selectedAsset as MonitoringVehicleEntry;
                switch (this.props.subpage) {
                    case AssetSubpage.DRIVER:
                        if (this.props.supportedPerspectives.includes(MonitoringPerspective.DRIVER)) {
                            return (
                                <DetailsPaneContext.Provider value={subpageContext}>
                                    <DriverSubpage
                                        vehicle={vehicleEntry.vehicle}
                                        defaultActiveRole={DriverRole.DRIVER}
                                    />
                                </DetailsPaneContext.Provider>
                            );
                        }

                        return <Redirect to={SceneRoutes.UNAUTHORIZED} />;

                    case AssetSubpage.CODRIVER:
                        if (this.props.supportedPerspectives.includes(MonitoringPerspective.DRIVER)) {
                            return (
                                <DetailsPaneContext.Provider value={subpageContext}>
                                    <DriverSubpage
                                        vehicle={vehicleEntry.vehicle}
                                        defaultActiveRole={DriverRole.CODRIVER}
                                    />
                                </DetailsPaneContext.Provider>
                            );
                        }

                        return <Redirect to={SceneRoutes.UNAUTHORIZED} />;

                    case AssetSubpage.HISTORY:
                        return (
                            <DetailsPaneContext.Provider value={subpageContext}>
                                <VehicleHistorySubpage asset={vehicleEntry.vehicle} />
                            </DetailsPaneContext.Provider>
                        );
                    default:
                        return (
                            <VehicleDetailsPane
                                key={this.props.selectedAssetId}
                                entry={vehicleEntry}
                                viewMode={this.props.viewMode}
                                depots={this.props.depots}
                                assetGroups={this.props.assetGroups}
                            />
                        );
                }
            }
            case MonitoringPerspective.TRAILER: {
                const trailerEntry = this.props.selectedAsset as MonitoringTrailerEntry;
                switch (this.props.subpage) {
                    case AssetSubpage.HISTORY:
                        return (
                            <DetailsPaneContext.Provider value={subpageContext}>
                                <TrailerHistorySubpage asset={trailerEntry.trailer} />
                            </DetailsPaneContext.Provider>
                        );
                    default:
                        return (
                            <TrailerDetailsPane
                                key={this.props.selectedAssetId}
                                entry={trailerEntry}
                                viewMode={this.props.viewMode}
                            />
                        );
                }
            }
            case MonitoringPerspective.CONTAINER: {
                const containerEntry = this.props.selectedAsset as MonitoringContainerEntry;
                switch (this.props.subpage) {
                    case AssetSubpage.HISTORY:
                        return (
                            <DetailsPaneContext.Provider value={subpageContext}>
                                <ContainerHistorySubpage asset={containerEntry.container} />
                            </DetailsPaneContext.Provider>
                        );
                    default:
                        return (
                            <ContainerDetailsPane
                                key={this.props.selectedAssetId}
                                entry={containerEntry}
                                viewMode={this.props.viewMode}
                            />
                        );
                }
            }
            case MonitoringPerspective.DRIVER: {
                const driverEntry = this.props.selectedAsset as MonitoringDriverEntry;
                switch (this.props.subpage) {
                    case AssetSubpage.DRIVER:
                        return (
                            driverEntry.driver && (
                                <DetailsPaneContext.Provider value={subpageContext}>
                                    <DriverPerspectiveDriverSubpage driverStatus={driverEntry.driver} />
                                </DetailsPaneContext.Provider>
                            )
                        );
                    default:
                        return driverEntry.driver && <DriverStatusDetailsPane driverStatus={driverEntry.driver} />;
                }
            }

            default:
                return undefined;
        }
    }

    private noSelectedAsset(assetType: string): JSX.Element {
        return (
            <div className={this.props.classes.messageText} data-id="no-selection">
                {this.props.t('no-assets-selected-in-asset-selector', { assetType: this.props.t(assetType) })}
            </div>
        );
    }

    private getViewContent(): JSX.Element {
        switch (this.props.viewMode) {
            case MonitoringViewMode.MAP: {
                if (this.props.isHistoryMode && this.props.selectedAsset) {
                    switch (this.props.perspective) {
                        case MonitoringPerspective.VEHICLE:
                            return (
                                <VehicleHistoryMap
                                    asset={(this.props.selectedAsset as MonitoringVehicleEntry).vehicle}
                                />
                            );
                        case MonitoringPerspective.TRAILER:
                            return (
                                <TrailerHistoryMap
                                    asset={(this.props.selectedAsset as MonitoringTrailerEntry).trailer}
                                />
                            );
                        case MonitoringPerspective.CONTAINER:
                            return (
                                <ContainerHistoryMap
                                    asset={(this.props.selectedAsset as MonitoringContainerEntry).container}
                                />
                            );
                        default:
                            break;
                    }
                }

                const assetContextMenuHandler = this.buildContextMenuHandlerMemoized(
                    AssetContextMenuType.Map,
                    this.props.perspective
                );

                switch (this.props.perspective) {
                    case MonitoringPerspective.VEHICLE:
                        if (this.props.canViewMap) {
                            return (
                                <VehicleMapView
                                    dataSource={this.props.dataSource as MonitoringVehicleEntry[]}
                                    selectedAsset={this.props.selectedAsset as MonitoringVehicleEntry}
                                    onAssetSelect={({ vehicle }) =>
                                        this.onSelectHandler({
                                            id: vehicle.id,
                                            perspective: MonitoringPerspective.VEHICLE,
                                            viewMode: MonitoringViewMode.MAP,
                                        })
                                    }
                                    onAssetContextMenu={assetContextMenuHandler}
                                />
                            );
                        }
                        return <Redirect to={SceneRoutes.UNAUTHORIZED} />;

                    case MonitoringPerspective.TRAILER:
                        if (this.props.canViewMap) {
                            return (
                                <TrailerMapView
                                    dataSource={this.props.dataSource as MonitoringTrailerEntry[]}
                                    selectedAsset={this.props.selectedAsset as MonitoringTrailerEntry}
                                    onAssetSelect={({ trailer }) =>
                                        this.onSelectHandler({
                                            id: trailer.id,
                                            perspective: MonitoringPerspective.TRAILER,
                                            viewMode: MonitoringViewMode.MAP,
                                        })
                                    }
                                    onAssetContextMenu={assetContextMenuHandler}
                                />
                            );
                        }
                        return <Redirect to={SceneRoutes.UNAUTHORIZED} />;
                    case MonitoringPerspective.CONTAINER:
                        if (this.props.canViewMap) {
                            return (
                                <ContainerMapView
                                    dataSource={this.props.dataSource as MonitoringContainerEntry[]}
                                    selectedAsset={this.props.selectedAsset as MonitoringContainerEntry}
                                    onAssetSelect={({ container }) =>
                                        this.onSelectHandler({
                                            id: container.id,
                                            perspective: MonitoringPerspective.CONTAINER,
                                            viewMode: MonitoringViewMode.MAP,
                                        })
                                    }
                                    onAssetContextMenu={assetContextMenuHandler}
                                />
                            );
                        }
                        return <Redirect to={SceneRoutes.UNAUTHORIZED} />;
                    default:
                        throw Error(`Unknown perspective: ${this.props.perspective}`);
                }
            }
            case MonitoringViewMode.LIST: {
                const contextMenuHandler = this.buildContextMenuHandlerMemoized(
                    AssetContextMenuType.Grid,
                    this.props.perspective
                );
                const highlightedAssetId =
                    this.state.assetContextMenu?.menuType === AssetContextMenuType.Grid
                        ? this.state.assetContextMenu?.assetId
                        : undefined;

                switch (this.props.perspective) {
                    case MonitoringPerspective.VEHICLE:
                        if (!this.props.dataSource.length) {
                            return this.noSelectedAsset('vehicles');
                        }

                        return (
                            <VehicleListView
                                dataSource={this.props.dataSource as MonitoringVehicleEntry[]}
                                selectedAssetId={this.props.selectedAssetId}
                                selectRow={(id) =>
                                    this.onSelectHandler({
                                        id,
                                        perspective: MonitoringPerspective.VEHICLE,
                                        viewMode: MonitoringViewMode.LIST,
                                    })
                                }
                                onRowContextMenu={contextMenuHandler}
                                highlightedAssetId={highlightedAssetId}
                            />
                        );
                    case MonitoringPerspective.TRAILER:
                        if (!this.props.dataSource.length) {
                            return this.noSelectedAsset('trailers');
                        }
                        return (
                            <TrailerListView
                                dataSource={this.props.dataSource as MonitoringTrailerEntry[]}
                                selectedAssetId={this.props.selectedAssetId}
                                selectRow={(id) =>
                                    this.onSelectHandler({
                                        id,
                                        perspective: MonitoringPerspective.TRAILER,
                                        viewMode: MonitoringViewMode.LIST,
                                    })
                                }
                                onRowContextMenu={contextMenuHandler}
                                highlightedAssetId={highlightedAssetId}
                            />
                        );
                    case MonitoringPerspective.CONTAINER:
                        if (!this.props.dataSource.length) {
                            return this.noSelectedAsset('containers');
                        }
                        return (
                            <ContainerListView
                                dataSource={this.props.dataSource as MonitoringContainerEntry[]}
                                selectedAssetId={this.props.selectedAssetId}
                                selectRow={(id) =>
                                    this.onSelectHandler({
                                        id,
                                        perspective: MonitoringPerspective.CONTAINER,
                                        viewMode: MonitoringViewMode.LIST,
                                    })
                                }
                                onRowContextMenu={contextMenuHandler}
                                highlightedAssetId={highlightedAssetId}
                            />
                        );
                    case MonitoringPerspective.DRIVER:
                        if (!this.props.dataSource.length) {
                            return this.noSelectedAsset('drivers');
                        }
                        return (
                            <DriverListView
                                dataSource={this.props.dataSource as MonitoringDriverEntry[]}
                                selectedAssetId={this.props.selectedAssetId}
                                selectRow={(id) =>
                                    this.onSelectHandler({
                                        id,
                                        perspective: MonitoringPerspective.DRIVER,
                                        viewMode: MonitoringViewMode.LIST,
                                    })
                                }
                                highlightedAssetId={highlightedAssetId}
                            />
                        );
                    default:
                        throw Error(`Unknown perspective: ${this.props.perspective}`);
                }
            }
            default:
                throw Error(`Unknown viewMode: ${this.props.viewMode}`);
        }
    }

    private getWidgetViewMode(): WidgetsViewMode {
        return this.props.viewMode === MonitoringViewMode.LIST ? WidgetsViewMode.DOCKED : WidgetsViewMode.FLOATING;
    }

    private getWidgets(): JSX.Element | undefined {
        if (!this.props.canViewMessages) {
            return undefined;
        }

        return (
            <span className={this.props.classes.widgets}>
                <div className={this.props.classes.conversationWidgets}>
                    <ConversationWidgets onLocate={this.onLocateFromWidget} canLocate={this.canLocate} />
                </div>
                <ConversationsSummaryWidget />
            </span>
        );
    }

    private canLocate = (vehicleId: number) => {
        return !isUndefined(this.props.vehicleStatuses[vehicleId]?.position);
    };

    private onLocateFromWidget = (vehicleId: number) => {
        if (!this.canLocate(vehicleId)) {
            return;
        }
        this.props.locateOnMap();
        const path = generatePath(MONITORING_PATH_STRUCTURE, {
            viewMode: MonitoringViewMode.MAP,
            perspective: MonitoringPerspective.VEHICLE,
            id: vehicleId,
        });
        if (this.props.history.location.pathname !== path) {
            this.props.history.push(path);
        }
    };

    private getSubpageUrlParam = (): AssetSubpage | undefined => {
        return this.props.keepSubPageOpen ? this.props.subpage : undefined;
    };

    private onSelectHandler = ({ id, perspective, viewMode }: OnSelectProps) => {
        this.props.history.push(
            generatePath(MONITORING_PATH_STRUCTURE, {
                viewMode,
                perspective,
                id,
                subpage: this.getSubpageUrlParam(),
            })
        );
    };

    private openContextMenu = (
        evt: MouseEvent,
        menuType: AssetContextMenuType,
        assetType: AssetType,
        assetId: number
    ) => {
        this.setState({
            assetContextMenu: {
                position: { clientX: evt.clientX, clientY: evt.clientY },
                menuType,
                assetType,
                assetId,
            },
        });
        evt.preventDefault();
        evt.stopPropagation();
    };

    private closeContextMenu = () => this.setState({ assetContextMenu: undefined });
}
