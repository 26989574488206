import { compose, setDisplayName } from 'react-recompose';

import { withSceneAnalytics } from '~/components/Analytics';
import { withTranslation } from '~/components/LanguageSelector';

import type { VehicleAdministrationDetailsPaneInnerProps, VehicleAdministrationDetailsPaneProps } from './component';
import { VehicleAdministrationDetailsPaneComponent } from './component';
import {
    VEHICLEADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY,
    defaultVehicleAdministrationDetailsPaneUserPreferences,
    requiredVehicleAdministrationDetailsPaneUserPreferences,
} from './preferences';

export {
    defaultVehicleAdministrationDetailsPaneUserPreferences,
    requiredVehicleAdministrationDetailsPaneUserPreferences,
    VEHICLEADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY,
};

export const VehicleAdministrationDetailsPane = compose<
    VehicleAdministrationDetailsPaneInnerProps,
    VehicleAdministrationDetailsPaneProps
>(
    setDisplayName('defaultVehicleAdministrationDetailsPaneUserPreferencesDetailsPane'),
    withSceneAnalytics('vehicleadministrationdetailspane'),
    withTranslation()
)(VehicleAdministrationDetailsPaneComponent);
