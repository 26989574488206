import type {
    AssetType,
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
    ResolvedRtdsSessionError,
    RtdsSessionStatus,
} from '~/services/ApiClient';
import { formatDriverName, formatVehicleName } from '~/services/Formatters';

import type { RtdsSessionEntry } from '../../models';

export const getVehicleDisplayNameFactory =
    (format: DisplayUserPreferencesVehicleDisplayFormat): ((entry: RtdsSessionEntry) => string | undefined) =>
    (entry) =>
        entry.session.vehicle && formatVehicleName(entry.session.vehicle, format);

export const getDriverDisplayNameFactory =
    (format: DisplayUserPreferencesDriverDisplayFormat): ((entry: RtdsSessionEntry) => string | undefined) =>
    (entry) =>
        entry.session.driver && formatDriverName(entry.session.driver, format);

export const getStatusValue = (entry: RtdsSessionEntry): RtdsSessionStatus | undefined => entry.session.status;

export const getErrorValue = (entry: RtdsSessionEntry): ResolvedRtdsSessionError | undefined => entry.session.error;

export const getIgnitionStatusValue = (entry: RtdsSessionEntry): boolean | undefined =>
    entry.session.vehicleStatus?.ignitionStatus;

export const getAssetTypeValue = (entry: RtdsSessionEntry): AssetType | undefined => entry.session.type;
