import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type VehicleSectionClassKey = 'disabled' | 'notConnected' | 'buttonIcon' | 'button' | 'buttons';

export const VehicleSectionStyleRules: StyleRulesCallback<Theme, {}, VehicleSectionClassKey> = (theme) =>
    createStyles({
        disabled: {
            opacity: 0.38,
        },
        notConnected: {
            fontStyle: 'italic',
        },
        buttonIcon: {
            marginRight: theme.spacing(),
        },
        button: {
            marginRight: theme.spacing(),
        },
        buttons: {
            display: 'flex',
        },
    });
