import type { Dispatch } from 'redux';

import type { LanguageStoreState } from '~/components/LanguageSelector';
import { retrieveCurrentLanguageAction } from '~/data/language';

import type { EnsureCurrentLanguageDispatchProps, EnsureCurrentLanguageStateProps } from './component';

export function mapStateToProps(state: LanguageStoreState): EnsureCurrentLanguageStateProps {
    return {
        currentLanguage: state.currentLanguage,
    };
}

export function mapDispatchProps(dispatch: Dispatch): EnsureCurrentLanguageDispatchProps {
    return {
        retrieveCurrentLanguage: () => dispatch(retrieveCurrentLanguageAction()),
    };
}
