import * as React from 'react';
import type { Subtract } from 'utility-types';

import {
    CoolingIcon,
    DefrostIcon,
    HeatingIcon,
    ManufacturerIcon,
    PowerOffIcon,
    PreTripIcon,
    ShutDownIcon,
    UnknownIcon,
} from '~/components/Icons';
import type { CustomIconProps } from '~/components/Icons/customIconProps';
import { CompartmentStatusKeys } from '~/services/ApiClient';

export interface IconFormatterProps {
    value: string;
    iconProps?: Subtract<CustomIconProps, { ref?: unknown }>;
}

export interface IconFormatterInnerProps extends IconFormatterProps {}

const IconFormatter: React.FC<IconFormatterInnerProps> = ({ value, iconProps }) => {
    switch (value) {
        case CompartmentStatusKeys.UNKNOWN: {
            return <UnknownIcon {...iconProps} />;
        }
        case CompartmentStatusKeys.COOLING: {
            return <CoolingIcon {...iconProps} />;
        }

        case CompartmentStatusKeys.HEATING: {
            return <HeatingIcon {...iconProps} />;
        }

        case CompartmentStatusKeys.DEFROST: {
            return <DefrostIcon {...iconProps} />;
        }

        case CompartmentStatusKeys.POWEROFF: {
            return <PowerOffIcon {...iconProps} />;
        }

        case CompartmentStatusKeys.PRETRIP: {
            return <PreTripIcon {...iconProps} />;
        }

        case CompartmentStatusKeys.SHUTDOWN: {
            return <ShutDownIcon {...iconProps} />;
        }

        case CompartmentStatusKeys.MANUFACTURERSPECIFIC: {
            return <ManufacturerIcon {...iconProps} />;
        }

        default: {
            return null;
        }
    }
};
IconFormatter.displayName = 'IconFormatter';

export { IconFormatter };
