import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type { Address, NearestCity, Position } from '~/services/ApiClient';

export const getAddressValue = (monitoringVehicleEntry: MonitoringVehicleEntry): Address | undefined =>
    monitoringVehicleEntry.vehicleStatus && monitoringVehicleEntry.vehicleStatus?.address;

export const getAltitudeValue = (monitoringVehicleEntry: MonitoringVehicleEntry): number | undefined =>
    monitoringVehicleEntry.vehicleStatus &&
    monitoringVehicleEntry.vehicleStatus?.position &&
    monitoringVehicleEntry.vehicleStatus?.position.altitude;

export const getCityValue = (monitoringVehicleEntry: MonitoringVehicleEntry): string | undefined =>
    monitoringVehicleEntry.vehicleStatus &&
    monitoringVehicleEntry.vehicleStatus?.address &&
    monitoringVehicleEntry.vehicleStatus?.address.city;

export const getHeadingValue = (monitoringVehicleEntry: MonitoringVehicleEntry): number | undefined =>
    monitoringVehicleEntry.vehicleStatus && monitoringVehicleEntry.vehicleStatus?.courseOverGround;

export const getCountryCodeValue = (monitoringVehicleEntry: MonitoringVehicleEntry): string | undefined =>
    monitoringVehicleEntry.vehicleStatus &&
    monitoringVehicleEntry.vehicleStatus?.address &&
    monitoringVehicleEntry.vehicleStatus?.address.countryCode;

export const getLastUpdateValue = (monitoringVehicleEntry: MonitoringVehicleEntry): Date | undefined =>
    monitoringVehicleEntry.vehicleStatus && monitoringVehicleEntry.vehicleStatus?.datetime;

export const getFuelLevelValue = (monitoringVehicleEntry: MonitoringVehicleEntry): number | undefined =>
    monitoringVehicleEntry.vehicleStatus && monitoringVehicleEntry.vehicleStatus?.fuelLevel;

export const getIgnitionStatusValue = (monitoringVehicleEntry: MonitoringVehicleEntry): boolean | undefined =>
    monitoringVehicleEntry.vehicleStatus && monitoringVehicleEntry.vehicleStatus?.ignitionStatus;

export const getLatitudeValue = (monitoringVehicleEntry: MonitoringVehicleEntry): number | undefined =>
    monitoringVehicleEntry.vehicleStatus &&
    monitoringVehicleEntry.vehicleStatus?.position &&
    monitoringVehicleEntry.vehicleStatus?.position.latitude;

export const getLongitudeValue = (monitoringVehicleEntry: MonitoringVehicleEntry): number | undefined =>
    monitoringVehicleEntry.vehicleStatus &&
    monitoringVehicleEntry.vehicleStatus?.position &&
    monitoringVehicleEntry.vehicleStatus?.position.longitude;

export const getNearestCityValue = (monitoringVehicleEntry: MonitoringVehicleEntry): NearestCity | undefined =>
    monitoringVehicleEntry.vehicleStatus && monitoringVehicleEntry.vehicleStatus?.nearestCity;

export const getOdometerValue = (monitoringVehicleEntry: MonitoringVehicleEntry): number | undefined =>
    monitoringVehicleEntry.vehicleStatus && monitoringVehicleEntry.vehicleStatus?.odometer;

export const getPostalCodeValue = (monitoringVehicleEntry: MonitoringVehicleEntry): string | undefined =>
    monitoringVehicleEntry.vehicleStatus &&
    monitoringVehicleEntry.vehicleStatus?.address &&
    monitoringVehicleEntry.vehicleStatus?.address.postalCode;

export const getPositionValue = (monitoringVehicleEntry: MonitoringVehicleEntry): Position | undefined =>
    monitoringVehicleEntry.vehicleStatus && monitoringVehicleEntry.vehicleStatus?.position;

export const getSpeedValue = (monitoringVehicleEntry: MonitoringVehicleEntry): number | undefined =>
    monitoringVehicleEntry.vehicleStatus && monitoringVehicleEntry.vehicleStatus?.speedOverGround;
