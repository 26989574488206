import { Tooltip } from '@mui/material';
import * as React from 'react';

import { FileIcon } from '~/components/Icons';
import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { DriverActivityType } from '~/services/ApiClient';

import { DriverActivityFormatter } from '../DriverActivity';

export interface HistoryDriverEventFormatterProps {
    t: SingleTFunction;
    value?: DriverActivityType;
}

export interface HistoryDriverEventFormatterInnerProps extends HistoryDriverEventFormatterProps {}

const HistoryDriverEventFormatter: React.FunctionComponent<HistoryDriverEventFormatterInnerProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return (
            <Tooltip title={t(`history-driver-event`)}>
                <span data-id="history-driver-event">
                    <FileIcon />
                </span>
            </Tooltip>
        );
    }

    return <DriverActivityFormatter t={t} value={value} />;
};
HistoryDriverEventFormatter.displayName = 'HistoryDriverEventFormatter';

export { HistoryDriverEventFormatter };
