import { createAction } from 'redux-actions';

import { logEvent } from '~/services/Analytics';

import { ActionTypeKeys } from './actionTypes';

export const changeSearchQueryAction = createAction<string, string, string, string>(
    ActionTypeKeys.SCENELIST_CHANGESEARCHQUERY,
    (_: string, searchQuery: string) => {
        logEvent('grid-view', 'search', `Search for '${searchQuery}'`);
        return searchQuery;
    },
    (key: string) => key
);

export const clearSearchQueryAction = createAction<undefined, string, string>(
    ActionTypeKeys.SCENELIST_CLEARSEARCHQUERY,
    () => undefined,
    (key: string) => key
);
