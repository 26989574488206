import { createAction } from 'redux-actions';

import { retryableRequest } from '~/services/ApiClient';

import type { TimeSeriesDialogMeta } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

const retrieveTimeSeriesDialogData = (
    startDate: Date,
    stopDate: Date,
    getData: (startDate: Date, stopDate: Date) => Promise<unknown[]>
) => {
    return retryableRequest(() => getData(startDate, stopDate));
};

export const retrieveTimeSeriesDialogDataAction = createAction<
    Promise<unknown[]>,
    TimeSeriesDialogMeta,
    Date,
    Date,
    (startDate: Date, stopDate: Date) => Promise<unknown[]>
>(ActionTypePrefixes.TIMESERIESDIALOG, retrieveTimeSeriesDialogData, (startDate: Date, stopDate: Date) => ({
    startDate,
    stopDate,
}));

export const clearTimeSeriesDialogDataAction = createAction(ActionTypeKeys.TIMESERIESDIALOG_CLEAR);
