import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ChartLegendClassKey = 'legendLine';

export const ChartLegendStyleRules: StyleRulesCallback<Theme, {}, ChartLegendClassKey> = () =>
    createStyles({
        legendLine: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            whiteSpace: 'nowrap',
        },
    });
