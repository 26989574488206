import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { TrailersSectionInnerProps, TrailersSectionProps } from './component';
import { TrailersSectionComponent } from './component';
import { TrailersSectionStyleRules } from './styles';

export const TrailersSection = compose<TrailersSectionInnerProps, TrailersSectionProps>(
    setDisplayName('TrailersSection'),
    withTranslation(),
    withStyles(TrailersSectionStyleRules),
    withDisplayPreferences()
)(TrailersSectionComponent);
