import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import differenceWith from 'lodash/differenceWith';
import escapeRegExp from 'lodash/escapeRegExp';
import flattenDeep from 'lodash/flattenDeep';
import intersection from 'lodash/intersection';
import intersectionWith from 'lodash/intersectionWith';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import union from 'lodash/union';
import xor from 'lodash/xor';
import xorWith from 'lodash/xorWith';

const themeSpacingToNumber = (value: string) => Number(value.replace('px', ''));

export type { Dictionary, NumericDictionary } from 'lodash';

export {
    cloneDeep,
    debounce,
    difference,
    differenceWith,
    escapeRegExp,
    flattenDeep,
    intersectionWith,
    isEmpty,
    isEqual,
    isNil,
    isUndefined,
    keyBy,
    merge,
    omit,
    themeSpacingToNumber,
    union,
    xor,
    xorWith,
    intersection,
};
