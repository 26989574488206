import type { DisplayUserPreferences } from '~/components/DisplayPreferences';
import type { MonitoringContainerEntry } from '~/data/monitoring';
import { filterBySearchQuery } from '~/services/Filter';
import { formatContainerName } from '~/services/Formatters';

export const createFilter =
    (_displayPreferences: DisplayUserPreferences) =>
    (entries: MonitoringContainerEntry[], searchQuery: string): MonitoringContainerEntry[] => {
        const textAccessors = (entry: MonitoringContainerEntry): Array<string | undefined> => {
            return [formatContainerName(entry.container)];
        };

        return filterBySearchQuery(entries, textAccessors, searchQuery);
    };
