import { nullFilterValue, selectAllValue } from '~/components/Grid/constants';
import { compareFactory, stringComparer } from '~/services/Sorting';

import type { BuildAndSortOptionsArgs, ObjectOption } from './models';

const buildAndSortOptions = (args: BuildAndSortOptionsArgs) => {
    const { hasSelectAll, options, formatValue } = args;

    const withoutUnknown = options.filter((value) => value !== nullFilterValue) as ObjectOption[];

    const selectAllOptions = hasSelectAll && options.length > 0 ? [selectAllValue] : [];
    const unknownOptions = options.length !== withoutUnknown.length ? [nullFilterValue] : [];

    const sortedOptions = withoutUnknown.sort(compareFactory(formatValue, stringComparer));

    return [...selectAllOptions, ...unknownOptions, ...sortedOptions];
};

export { buildAndSortOptions };
