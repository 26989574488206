import GetApp from '@mui/icons-material/GetApp';
import * as React from 'react';
import { useSelector } from 'react-redux';

import type { ContextMenuItem, ContextMenuPosition } from '~/components/ContextMenu';
import { ContextMenu } from '~/components/ContextMenu';
import { UploadIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNil } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import type { TachoFileType } from '~/services/ApiClient';
import {
    ApiClient,
    TachoFileBatchRequest,
    TachoFileReference,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import { zipFiles } from '../../zipUtils';

import { reduxPropsSelector } from './redux';

export interface TachoFileContextMenuProps {
    fileId?: number;
    fileType?: TachoFileType;
    position?: ContextMenuPosition;
    onClose: () => void;
    onUpload: () => void;
}

export interface TachoFileContextMenuInnerProps extends TachoFileContextMenuProps, InjectedTranslationProps {}

export const TachoFileContextMenuComponent: React.FC<TachoFileContextMenuInnerProps> = ({
    fileId,
    fileType,
    position,
    onClose,
    onUpload,
    t,
}) => {
    const reduxProps = useSelector((state: StoreState) => reduxPropsSelector(state));
    const reuploadTachoFile = React.useCallback(
        (id: number, type: TachoFileType) => {
            retryableRequest(() =>
                ApiClient.uploadTachoFiles(
                    createApiModel(TachoFileBatchRequest, {
                        tachoFileIds: [createApiModel(TachoFileReference, { id, type })],
                    })
                )
            );
            onUpload();
        },
        [onUpload]
    );

    const exportTachoFile = React.useCallback(
        async (id: number, type: TachoFileType) => {
            const response = await retryableRequest(() =>
                ApiClient.downloadTachoFiles(
                    createApiModel(TachoFileBatchRequest, {
                        tachoFileIds: [createApiModel(TachoFileReference, { id, type })],
                    })
                )
            );

            zipFiles({ t, response, fileType: type });
        },
        [t]
    );

    const getMenuItems = (): ContextMenuItem[] => {
        if (isNil(fileId) || isNil(fileType)) {
            return [];
        }

        return [
            {
                key: 'upload-tacho-file',
                title: t('upload-tacho-file'),
                icon: <UploadIcon />,
                onClick: () => reuploadTachoFile(fileId, fileType),
                hidden: !reduxProps.rmsEnabled,
            },
            {
                key: 'export-tacho-file',
                title: t('export-tacho-file'),
                icon: <GetApp />,
                onClick: () => exportTachoFile(fileId, fileType),
            },
        ];
    };

    return (
        <ContextMenu
            position={position}
            onClose={onClose}
            menuItems={getMenuItems()}
            dataId="tacho-file-context-menu"
        />
    );
};
