import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type InfoBoxClassKey =
    | 'driverAvatar'
    | 'trailerAvatar'
    | 'icon'
    | 'infoBoxWrapper'
    | 'infoText'
    | 'notLoggedIn'
    | 'notAuthorized';

export const InfoBoxStyleRules: StyleRulesCallback<Theme, {}, InfoBoxClassKey> = (theme: Theme) =>
    createStyles({
        driverAvatar: {
            color: theme.functionalItemsColors.assetType.driver.value,
            backgroundColor: theme.functionalItemsColors.assetType.driver.contrast,
            border: `1px solid ${theme.palette.text.secondary}`,
            width: 24,
            height: 24,
        },
        trailerAvatar: {
            color: theme.functionalItemsColors.assetType.trailer.value,
            backgroundColor: theme.functionalItemsColors.assetType.trailer.contrast,
            border: `1px solid ${theme.palette.text.secondary}`,
            width: 24,
            height: 24,
        },
        icon: {
            width: 14,
            height: 14,
        },
        infoBoxWrapper: {
            height: theme.spacing(3),
            padding: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            display: 'flex',
            alignItems: 'center',
            '&:first-child': {
                marginTop: theme.spacing(0.5),
            },
        },
        infoText: {
            marginLeft: theme.spacing(),
        },
        notLoggedIn: {
            opacity: 0.38,
            fontStyle: 'italic',
        },
        notAuthorized: {
            opacity: 0.38,
        },
    });
