import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
    iconMargin: {
        marginRight: theme.spacing(),
    },
    copyCoordinatesButton: {
        marginLeft: theme.spacing(-1),
    },
}));
