import type { Duration } from 'moment';

import type {
    Address,
    Classification,
    DisplayUserPreferencesVehicleDisplayFormat,
    NearestCity,
    ResolvedCompartmentStatus,
} from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';
import { parseOptionalDuration } from '~/services/Parsers';

import type { HistoryTrailerEntry } from '../../../../models';

export const getIsManuallyConnectedValue = (entry: HistoryTrailerEntry): boolean | undefined =>
    entry.trailerEvent?.isManual;

export const getTrailerEventDateTimeValue = (entry: HistoryTrailerEntry): Date | undefined =>
    entry.trailerEvent?.eventDateTime;

export const getEventTypeValue = (entry: HistoryTrailerEntry): Classification | undefined =>
    entry.trailerEvent?.eventType;

export const getCityValue = (entry: HistoryTrailerEntry): string | undefined =>
    entry.trailerEvent?.location?.address?.city;

export const getAddressValue = (entry: HistoryTrailerEntry): Address | undefined =>
    entry.trailerEvent?.location?.address;

export const getPostalCodeValue = (entry: HistoryTrailerEntry): string | undefined =>
    entry.trailerEvent?.location?.address?.postalCode;

export const getCountryCodeValue = (entry: HistoryTrailerEntry): string | undefined =>
    entry.trailerEvent?.location?.address?.countryCode;

export const getNearestCityValue = (entry: HistoryTrailerEntry): NearestCity | undefined =>
    entry.trailerEvent?.location?.nearestCity;

export const getLatitudeValue = (entry: HistoryTrailerEntry): number | undefined =>
    entry.trailerEvent?.location?.position?.latitude;

export const getLongitudeValue = (entry: HistoryTrailerEntry): number | undefined =>
    entry.trailerEvent?.location?.position?.longitude;

export const getDoorNSensorValue = (doorNumber: number) => {
    return (entry: HistoryTrailerEntry): Classification[] | undefined => entry.trailerEvent?.doors[doorNumber]?.status;
};

export const getDoorNSealCodeValue = (doorNumber: number) => {
    return (entry: HistoryTrailerEntry): string | undefined => entry.trailerEvent?.doors[doorNumber]?.sealCode;
};

export const getDoorNTimestampValue = (doorNumber: number) => {
    return (entry: HistoryTrailerEntry): Date | undefined => entry.trailerEvent?.doors[doorNumber]?.dateTime;
};

export const getBatteryVoltageTrailerValue = (entry: HistoryTrailerEntry): number | undefined =>
    entry.trailerEvent?.battery?.voltage;

export const getBatteryLevelTrailerValue = (entry: HistoryTrailerEntry): number | undefined =>
    entry.trailerEvent?.battery?.level;

export const getBatteryStatusTrailerValue = (entry: HistoryTrailerEntry): Classification | undefined =>
    entry.trailerEvent?.battery?.status;

export const getBatteryVoltageReeferValue = (entry: HistoryTrailerEntry): number | undefined =>
    entry.trailerEvent?.reefer?.batteryVoltage;

export const getFuelLevelReeferValue = (entry: HistoryTrailerEntry): number | undefined =>
    entry.trailerEvent?.reefer?.fuelLevel;

export const getTotalEngineHoursReeferValue = (entry: HistoryTrailerEntry): Duration | undefined =>
    parseOptionalDuration(entry.trailerEvent?.reefer?.totalAmountOfEngineHours, 'hours');

export const getReeferAlarmsValue = (entry: HistoryTrailerEntry): Classification[] | undefined =>
    entry.trailerEvent?.reefer?.alarms;

export const getReeferAlarmsManufacturerValue = (entry: HistoryTrailerEntry): string | undefined =>
    entry.trailerEvent?.reefer?.alarmManufacturer;

export const getReeferOperationModeValue = (entry: HistoryTrailerEntry): Classification | undefined =>
    entry.trailerEvent?.reefer?.operationMode;

export const getReeferPowerModeValue = (entry: HistoryTrailerEntry): Classification | undefined =>
    entry.trailerEvent?.reefer?.powerMode;

export const getReeferSpeedValue = (entry: HistoryTrailerEntry): Classification | undefined =>
    entry.trailerEvent?.reefer?.speed;

export const getReeferStatusValue = (entry: HistoryTrailerEntry): Classification | undefined =>
    entry.trailerEvent?.reefer?.status;

export const getHookedValue = (entry: HistoryTrailerEntry): Classification | undefined => entry.trailerEvent?.hooked;

export const getEbsTimestampValue = (entry: HistoryTrailerEntry): Date | undefined => entry.trailerEvent?.ebs?.dateTime;

export const getEbsMileageValue = (entry: HistoryTrailerEntry): number | undefined => entry.trailerEvent?.ebs?.milage;

export const getAxleLoadValue = (entry: HistoryTrailerEntry): number | undefined =>
    entry.trailerEvent?.ebs?.axleLoadSum;

export const getDeviceTypeValue = (entry: HistoryTrailerEntry): string | undefined =>
    entry.trailerEvent?.deviceType?.key;

export const getDeviceMsisdnValue = (entry: HistoryTrailerEntry): string | undefined =>
    entry.trailerEvent?.sendingDevice?.msisdn;

export const getCompartmentNStatusValue = (compartmentNumber: number) => {
    return (entry: HistoryTrailerEntry): Classification | undefined =>
        entry.trailerEvent?.compartments[compartmentNumber]?.status;
};

export const getCompartmentNStatusManufacturerValue = (compartmentNumber: number) => {
    return (entry: HistoryTrailerEntry): string | undefined =>
        entry.trailerEvent?.compartments[compartmentNumber]?.statusManufacturer;
};

export const getCompartmentNSupplyTemperatureValue = (compartmentNumber: number) => {
    return (entry: HistoryTrailerEntry): number | undefined =>
        entry.trailerEvent?.compartments[compartmentNumber]?.supplyTemperature;
};

export const getCompartmentNReturnTemperatureValue = (compartmentNumber: number) => {
    return (entry: HistoryTrailerEntry): number | undefined =>
        entry.trailerEvent?.compartments[compartmentNumber]?.returnTemperature;
};

export const getCompartmentNSetpointTemperatureValue = (compartmentNumber: number) => {
    return (entry: HistoryTrailerEntry): number | undefined =>
        entry.trailerEvent?.compartments[compartmentNumber]?.setpointTemperature;
};

export const getCompartmentN = (compartmentNumber: number) => {
    return (entry: HistoryTrailerEntry): ResolvedCompartmentStatus =>
        entry.trailerEvent?.compartments[compartmentNumber];
};

export const getCompartmentNMinBandWithTempValue = (compartmentNumber: number) => {
    return (entry: HistoryTrailerEntry): number | undefined =>
        entry.trailerEvent?.compartments[compartmentNumber]?.minTemperature;
};

export const getCompartmentNMaxBandWithTempValue = (compartmentNumber: number) => {
    return (entry: HistoryTrailerEntry): number | undefined =>
        entry.trailerEvent?.compartments[compartmentNumber]?.maxTemperature;
};

export const getVehicleDisplayNameFactory =
    (vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat) =>
    (entry: HistoryTrailerEntry): string | undefined =>
        entry.trailerEvent?.vehicle && formatVehicleName(entry.trailerEvent.vehicle, vehicleDisplayNameFormat);
