import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import * as React from 'react';
import type { Subtract } from 'utility-types';

export interface RestingIconProps extends Subtract<SvgIconProps, { viewBox?: string }> {}

export interface RestingIconInnerProps extends RestingIconProps {}

const RestingIcon: React.SFC<RestingIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M11,13.5H3v-9H1v15H3v-3H21v3h2V13.56" />
        </SvgIcon>
    );
};
RestingIcon.displayName = 'RestingIcon';

export { RestingIcon };
