import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { RtdsSession } from '~/services/ApiClient';

import type { RtdsSessionEntry } from './models';

export const resolveRtdsSessionsEntries = (
    rtdsSessions: RtdsSession[],
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): RtdsSessionEntry[] =>
    rtdsSessions.map((rtdsSession) => {
        const { vehicles, vehicleStatus, drivers } = staticDataStoreState;
        const resolvedRtdsSession = {
            ...rtdsSession,
            vehicle: rtdsSession.vehicle && vehicles.data.hash[rtdsSession.vehicle.id],
            vehicleStatus: rtdsSession.vehicle && vehicleStatus.data.hash[rtdsSession.vehicle.id],
            driver: rtdsSession.driver && drivers.data.hash[rtdsSession.driver.id],
            dataBlocks: rtdsSession.tachographDataBlocks.map(
                (dataBlockId) => settingsStoreState.tachographDataBlock.data[dataBlockId]
            ),
            error: {
                rtdsSessionErrorType:
                    rtdsSession.error &&
                    settingsStoreState.rtdsSessionErrorType.data[rtdsSession.error?.rtdsSessionErrorType],
                tachographDataBlocks: rtdsSession.error?.tachographDataBlocks.map(
                    (dataBlockId) => settingsStoreState.tachographDataBlock.data[dataBlockId]
                ),
            },
        };

        return { session: resolvedRtdsSession };
    });
