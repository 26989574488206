import { UNIT_SYSTEMS } from '../consts';
import type { UnitSystem } from '../models';

import { mpsToKmph } from './mpsToKmph';
import { mpsToMph } from './mpsToMph';

interface ConvertSpeedArgs {
    value: number;
    unitSystem: UnitSystem;
    precision?: number;
}

const convertSpeed = (args: ConvertSpeedArgs) => {
    const { value, unitSystem, precision = 0 } = args;
    switch (unitSystem) {
        case UNIT_SYSTEMS.METRIC:
            return mpsToKmph(value).toFixed(precision);
        case UNIT_SYSTEMS.IMPERIAL:
            return mpsToMph(value).toFixed(precision);
        default:
            throw new Error('Unknown unit system.');
    }
};

export { convertSpeed };
export type { ConvertSpeedArgs };
