import type { Dispatch } from 'redux';

import { updateUserPreferencesAction } from '~/data/userpreferences';
import type { NumericDictionary } from '~/libs/utility';
import type { Classification, Message, ResolvedAttachment } from '~/services/ApiClient';
import { IncomingMessage } from '~/services/ApiClient';

import type { WorkflowMessageDetailsDispatchProps, WorkflowMessageDetailsStateProps } from './component';

export const mapStateToProps = (
    attachmentTypes: NumericDictionary<Classification>,
    message: Message
): WorkflowMessageDetailsStateProps => {
    let messageResolvedAttachments: ResolvedAttachment[] = [];

    if (message instanceof IncomingMessage && message.attachments) {
        messageResolvedAttachments = message.attachments.map((it) => {
            const attachmentType = attachmentTypes[it.type];
            const resolvedAttachment: ResolvedAttachment = { ...it, type: attachmentType };
            return resolvedAttachment;
        });
    }

    return { messageResolvedAttachments };
};

export const mapDispatchToPropsFactory: (
    userPreferencesKey: string
) => (dispatch: Dispatch) => WorkflowMessageDetailsDispatchProps = (userPreferencesKey) => (dispatch) => ({
    changeMiniMapZoomLevel: (zoomLevel: number) => {
        dispatch(
            updateUserPreferencesAction(userPreferencesKey, {
                miniMapZoomLevel: zoomLevel,
            })
        );
    },
});
