import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { RtdsSessionsListViewHeaderInnerProps, RtdsSessionsListViewHeaderProps } from './component';
import { RtdsSessionsListViewHeaderComponent } from './component';

export const RtdsSessionsListViewHeader = compose<
    RtdsSessionsListViewHeaderInnerProps,
    RtdsSessionsListViewHeaderProps
>(
    setDisplayName('RtdsSessionsListViewHeader'),
    withDisplayPreferences(),
    withTranslation()
)(RtdsSessionsListViewHeaderComponent);
