import _Search from '@mui/icons-material/Search';
import _Checkbox from '@mui/material/Checkbox';
import _IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    margin: theme.spacing(1.5, 0),
}));

const Title = styled('div')(({ theme }) => ({
    flexGrow: 1,
    marginLeft: theme.spacing(),
}));

const Search = styled(_Search)(({ theme }) => ({
    margin: theme.spacing(),
    color: theme.palette.text.secondary,
    cursor: 'pointer',
}));

const IconButton = styled(_IconButton)(({ theme }) => ({
    width: theme.spacing(3),
    ':hover': { backgroundColor: 'transparent' },
}));

const Checkbox = styled(_Checkbox)({ ':hover': { backgroundColor: 'inherit' } });

export { Container, IconButton, Search, Title, Checkbox };
