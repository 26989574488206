import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { StoreState } from '~/reducers';
import { historySceneStateSelector } from '~/selectors';

import type { DynamicHistoryStoreState, HistoryStoreState } from './reducers';

export const historyStateSelector = (s: StoreState): HistoryStoreState => {
    return historySceneStateSelector(s).history;
};

export const dynamicHistoryStateSelector = (s: StoreState): DynamicHistoryStoreState => {
    return historyStateSelector(s).dynamicHistory;
};

export const historyDateTimeRangeStateSelector = (s: StoreState): DateTimeRange | undefined =>
    historyStateSelector(s).dateTimeRange;
