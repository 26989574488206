import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { ContainerMarkerComponent } from './component';
import type { ContainerMarkerInnerProps, ContainerMarkerProps } from './models';
import { ContainerMarkerStyleRules } from './styles';

export const ContainerMarker = compose<ContainerMarkerInnerProps, ContainerMarkerProps>(
    setDisplayName('ContainerMarker'),
    withStyles(ContainerMarkerStyleRules)
)(ContainerMarkerComponent);
