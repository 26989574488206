import Assignment from '@mui/icons-material/Assignment';
import AssignmentReturn from '@mui/icons-material/AssignmentReturn';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';

import type { WorkflowMessageContentInnerProps } from './models';

export const WorkflowMessageContentComponent: FC<WorkflowMessageContentInnerProps> = (props) => {
    const {
        classes,
        messageTitle,
        messageTitleSecondary,
        isOutgoing,
        isFailed,
        isMissingFormDefinition,
        isQuoted,
        onClick,
    } = props;

    const messageBoxClasses = classNames(classes.messageBox, {
        [classes.quoted]: isQuoted,
    });
    const dataId = isQuoted ? 'quoted-wf-message-content' : 'wf-message-click-content';
    return (
        <div data-id={dataId} className={messageBoxClasses} onClick={onClick}>
            {isOutgoing ? (
                <Assignment
                    fontSize="inherit"
                    className={classNames(classes.messageIcon, classes.iconTopAlignmentSecondaryText)}
                    data-id="avatar:outgoing"
                />
            ) : (
                <AssignmentReturn
                    fontSize="inherit"
                    className={classNames(classes.messageIcon, classes.iconTopAlignmentSecondaryText)}
                    data-id="avatar:incoming"
                />
            )}
            <div className={classes.messageText} data-id="title">
                <Typography
                    className={classNames(classes.messageTitlePrimary, classes.twoLineTextWrap)}
                    variant="caption"
                    color={isFailed ? 'error' : 'textPrimary'}
                    data-id="primary-title"
                >
                    {messageTitle}
                </Typography>
                {messageTitleSecondary && (
                    <Typography
                        className={classNames(classes.messageTitleSecondary, classes.twoLineTextWrap)}
                        variant="caption"
                        color={isMissingFormDefinition ? 'error' : 'textSecondary'}
                        data-id="secondary-title"
                    >
                        {messageTitleSecondary}
                    </Typography>
                )}
            </div>
            {onClick && (
                <InfoOutlined
                    fontSize="inherit"
                    className={classNames(classes.messageIconInfo, classes.iconTopAlignmentSecondaryText)}
                    data-id="info-icon"
                />
            )}
        </div>
    );
};
