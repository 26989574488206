import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type EbsSectionClassKey = 'collapsedHeaderWrapper' | 'weight';

export const EbsSectionStyleRules: StyleRulesCallback<Theme, {}, EbsSectionClassKey> = (theme: Theme) =>
    createStyles({
        collapsedHeaderWrapper: {
            display: 'flex',
        },
        weight: {
            marginLeft: theme.spacing(),
        },
    });
