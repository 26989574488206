import { Snackbar } from '@mui/material';
import type { SnackbarOrigin } from '@mui/material/Snackbar';
import * as React from 'react';

import { RetrySnackbar } from '~/components/RetrySnackbar';

export enum Status {
    FAILED = 'FAILED',
    SUCCEEDED = 'SUCCEEDED',
    RETRYING = 'RETRYING',
}

export interface RetryActionProps {
    status: Status;
    retryMessage: string;
    successMessage: string;
    anchorOrigin: SnackbarOrigin;
    onRetry: () => void;
    dataIdPrefix: string;
}

export interface RetryActionInnerProps extends RetryActionProps {}

export interface RetryActionState {
    isSuccessSnackbarOpen: boolean;
}

export class RetryActionComponent extends React.Component<RetryActionInnerProps, RetryActionState> {
    constructor(props: RetryActionProps) {
        super(props);

        this.state = {
            isSuccessSnackbarOpen: false,
        };
    }

    public componentDidUpdate(prevProps: RetryActionProps): void {
        if (this.props.status === Status.SUCCEEDED && prevProps.status !== Status.SUCCEEDED) {
            this.setState({
                isSuccessSnackbarOpen: true,
            });
        }
    }

    public render(): React.ReactNode {
        const { status, retryMessage, successMessage, anchorOrigin, onRetry, dataIdPrefix } = this.props;

        switch (status) {
            case Status.FAILED: {
                const snackbarProps = {
                    open: true,
                    anchorOrigin,
                    message: retryMessage,
                    'data-id': `${dataIdPrefix}-retry-snackbar`,
                };

                return <RetrySnackbar snackbarProps={snackbarProps} onRetry={onRetry} />;
            }
            case Status.SUCCEEDED: {
                return (
                    <Snackbar
                        autoHideDuration={3000}
                        open={this.state.isSuccessSnackbarOpen}
                        message={successMessage}
                        anchorOrigin={anchorOrigin}
                        onClose={this.closeSuccessSnackbar}
                        data-id={`${dataIdPrefix}-success-snackbar`}
                    />
                );
            }

            case Status.RETRYING: {
                return null;
            }

            default:
                throw new Error(`Unknown status '${status}'`);
        }
    }

    private closeSuccessSnackbar = () => {
        this.setState({ isSuccessSnackbarOpen: false });
    };
}
