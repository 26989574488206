import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const HybridMapIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M15.8 14.1a5.73 5.73 0 0 1-4.2-1.5V18L7.3 19.5V11l2.8-1c-.1-.3-.1-.6-.2-.9V8.6l-2.6.9L3.5 8H3.4c-.2 0-.4.1-.4.3V19.2a.27.27 0 0 0 .3.3l4 1.5 4.3-1.5L15.5 21h.1a.43.43 0 0 0 .4-.4V14C16 14.1 15.9 14.1 15.8 14.1Z" />
            <path d="M14.8 11.89a2.69 2.69 0 0 1-2.7-2.7.38.38 0 1 0-.75 0 3.44 3.44 0 0 0 3.45 3.45.35.35 0 0 0 .38-.37A.41.41 0 0 0 14.8 11.89Z" />
            <path d="M14.8 10.39a1.18 1.18 0 0 1-1.2-1.2.38.38 0 1 0-.75 0 1.85 1.85 0 0 0 1.88 1.87.35.35 0 0 0 .37-.37C15.18 10.54 15 10.39 14.8 10.39Z" />
            <path d="M14.79 8.85 15 8.66 14.31 8a.35.35 0 0 1-.1-.2c0-.09 0-.09.1-.19A1.62 1.62 0 0 1 16 7.41c0-.1-.1-.19-.1-.29a.54.54 0 0 1 .1-.38l-.48-.49-.29.29a.29.29 0 0 1-.39 0L12.67 4.33a.36.36 0 0 1-.09-.19A.32.32 0 0 1 12.67 4L14.6 3c.19 0 .28 0 .38.1l1.25 2.31c.1.09 0 .19 0 .29L15.94 6l.39.38 1.15-1.06 1.25 1.25L17.58 7.7l.38.38.29-.29a.19.19 0 0 1 .29 0L20.85 9c.09.1.19.2.09.29l-1 1.93c0 .09-.09.09-.19.09s-.19 0-.19-.09L17.29 9a.35.35 0 0 1-.1-.19c0-.1 0-.1.1-.2l.29-.19L17.19 8a.87.87 0 0 1-.67 0 .92.92 0 0 1 .19.67 1.48 1.48 0 0 1-.38 1 .29.29 0 0 1-.39 0L15.27 9l-.19.19c-.16.12-.3.1-.36 0A.26.26 0 0 1 14.79 8.85Z" />
            <path d="M0 0H24V24H0Z" fill="none" />
        </SvgIcon>
    );
};
HybridMapIcon.displayName = 'HybridMapIcon';

export { HybridMapIcon };
