import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoizeOne } from '~/services/Memoize';

import { Classification, ClassificationType, TrailerDoorStatusKeys, createApiModel } from '../ApiClient';

import { formatClassification } from './formatClassification';

const formatOpenMemoized = memoizeOne((t: SingleTFunction) =>
    formatClassification(
        t,
        ClassificationType.DoorStatus,
        createApiModel(Classification, { id: 0, key: TrailerDoorStatusKeys.OPEN })
    )
);
const formatClosedMemoized = memoizeOne((t: SingleTFunction) =>
    formatClassification(
        t,
        ClassificationType.DoorStatus,
        createApiModel(Classification, { id: 0, key: TrailerDoorStatusKeys.CLOSED })
    )
);
const formatLockedMemoized = memoizeOne((t: SingleTFunction) =>
    formatClassification(
        t,
        ClassificationType.DoorStatus,
        createApiModel(Classification, { id: 0, key: TrailerDoorStatusKeys.LOCKED })
    )
);

export const formatDoorStatus = (t: SingleTFunction, status: Classification[]): string => {
    const statusIsOpen = status.find((x) => x.key === TrailerDoorStatusKeys.OPEN);
    const statusIsClosed = status.find((x) => x.key === TrailerDoorStatusKeys.CLOSED) && status.length === 1;

    return statusIsOpen ? formatOpenMemoized(t) : statusIsClosed ? formatClosedMemoized(t) : formatLockedMemoized(t);
};
