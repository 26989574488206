import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { AutocompleteDropdownInputProps } from './models';

export type AutocompleteDropdownInputClassKey =
    | 'autoCompleteContainer'
    | 'optionCellRow'
    | 'optionItemText'
    | 'listbox'
    | 'headerBox'
    | 'headerText'
    | 'brandedRow'
    | 'changedNotchedOutline'
    | 'focusChangedNotchedOutline'
    | 'outlinedInput';

export const AutocompleteDropdownInputStyleRules: StyleRulesCallback<
    Theme,
    AutocompleteDropdownInputProps,
    AutocompleteDropdownInputClassKey
> = (theme: Theme) =>
    createStyles({
        autoCompleteContainer: {
            position: 'relative',
            marginBottom: ({ disablePadding }) => (disablePadding ? 0 : 24),
        },
        optionCellRow: {
            justifyContent: 'space-around',
            maxWidth: '100%',
            width: '100%',
        },
        optionItemText: {
            textAlign: 'left',
            padding: theme.spacing(0, 1),
        },
        listbox: {
            '& li': {
                padding: theme.spacing(0.5, 0.5),
            },
            width: '90%',
            zIndex: theme.zIndex.modal,
        },
        headerBox: {
            padding: theme.spacing(1, 0.5),
        },
        headerText: {
            alignItems: 'center',
            padding: theme.spacing(0, 1),
        },
        brandedRow: {
            color: theme.palette.grey[500],
        },
        changedNotchedOutline: {
            borderWidth: '1px',
            borderColor: `${theme.palette.warning.main} !important`,
        },
        focusChangedNotchedOutline: {
            borderColor: `${theme.palette.primary.main} !important`,
        },
        outlinedInput: {
            '&$focusChangedNotchedOutline $changedNotchedOutline': {
                borderColor: `${theme.palette.primary.main} !important`,
            },
            '&:hover:not($disabled):not($focused):not($error) $changedNotchedOutline': {
                borderColor: `${theme.palette.warning.dark} !important`,
            },
        },
    });
