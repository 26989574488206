import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { SaveUserPreferencesStatusMonitorInnerProps, SaveUserPreferencesStatusMonitorProps } from './component';
import { SaveUserPreferencesStatusMonitorComponent } from './component';

export const SaveUserPreferencesStatusMonitor = compose<
    SaveUserPreferencesStatusMonitorInnerProps,
    SaveUserPreferencesStatusMonitorProps
>(
    setDisplayName('SaveUserPreferencesStatusMonitor'),
    withTranslation()
)(SaveUserPreferencesStatusMonitorComponent);
