import { Checkbox, FormControlLabel, FormHelperText, Switch, Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';

import type { BaseProps } from '../model';
import { BooleanControlType } from '../model';

import type { BooleanInputClassKey } from './styles';

export interface BooleanInputProps extends BaseProps<boolean> {
    controlType: BooleanControlType;
}

export interface BooleanInputInnerProps
    extends BooleanInputProps,
        InjectedTranslationProps,
        WithStyles<BooleanInputClassKey> {}

export const BooleanInputComponent: React.FC<BooleanInputInnerProps> = (props) => {
    const {
        label,
        fieldName,
        controlType,
        dataId,
        disabled,
        hasError,
        errorText,
        onValueChanged,
        onBlurChanged,
        markValueChange,
        className,
        classes,
        defaultValue,
        validationRules,
        disabledReason,
    } = props;

    const { control, formState } = useFormContext();
    const fieldError = formState.errors[fieldName];
    const error = !!fieldError || hasError;
    const isDirtyField = formState.dirtyFields[fieldName];
    const inputClasses = classNames(className, { [classes.changedField]: markValueChange && !error && isDirtyField });
    const helperText = hasError && !isUndefined(errorText) ? errorText : fieldError ? fieldError.message : undefined;
    const rules = React.useMemo(() => ({ ...validationRules }), [validationRules]);

    const getHelperText = React.useCallback(() => {
        return error && helperText ? (
            <FormHelperText error={error}>{error ? helperText : undefined}</FormHelperText>
        ) : null;
    }, [error, helperText]);

    const notifyChange = React.useCallback((value: boolean, callbackFn?: (v: boolean) => void) => {
        if (callbackFn) {
            callbackFn(value);
        }
    }, []);

    const onChangeHandler = React.useCallback(
        (checked: boolean, field: ControllerRenderProps) => {
            notifyChange(checked, (value) => {
                field.onChange(value);
                if (onValueChanged) {
                    onValueChanged(value);
                }
            });
        },
        [onValueChanged, notifyChange]
    );

    const onBlurHandler = React.useCallback(
        (checked: boolean, field: ControllerRenderProps) => {
            notifyChange(checked, (value) => {
                if (onBlurChanged) {
                    onBlurChanged(value);
                } else {
                    field.onBlur();
                }
            });
        },
        [onBlurChanged, notifyChange]
    );

    const baseComponent = () => {
        if (controlType === BooleanControlType.CheckboxControl) {
            return (
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={defaultValue}
                    rules={rules}
                    render={({ field }) => (
                        <FormControlLabel
                            label={label}
                            labelPlacement="end"
                            classes={{ label: classes.label }}
                            control={
                                <>
                                    <Checkbox
                                        {...field}
                                        data-id={dataId}
                                        onChange={(_, checked) => onChangeHandler(checked, field)}
                                        onBlur={() => onBlurHandler(field.value, field)}
                                        className={inputClasses}
                                        disabled={disabled}
                                        checked={field.value}
                                        inputRef={field.ref}
                                    />
                                    {getHelperText()}
                                </>
                            }
                        />
                    )}
                />
            );
        } else if (controlType === BooleanControlType.ToggleButton) {
            return (
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={defaultValue}
                    rules={rules}
                    render={({ field }) => {
                        const switchControl = (
                            <>
                                <Switch
                                    className={classNames(inputClasses)}
                                    checked={field.value}
                                    disabled={disabled}
                                    onChange={(_, checked) => onChangeHandler(checked, field)}
                                    onBlur={() => onBlurHandler(field.value, field)}
                                    data-id={dataId}
                                />
                                {getHelperText()}
                            </>
                        );

                        return label ? (
                            <FormControlLabel
                                label={label}
                                labelPlacement="end"
                                classes={{ label: classes.label }}
                                control={switchControl}
                            />
                        ) : (
                            switchControl
                        );
                    }}
                />
            );
        } else {
            throw Error(`ControlType '${controlType}' is not supported.`);
        }
    };

    return disabledReason && disabled ? (
        <Tooltip title={disabledReason} data-id={`input-tooltip-${dataId}`} placement="right">
            <span data-id={`tooltip-span-:${dataId}`} className={className}>
                {baseComponent()}
            </span>
        </Tooltip>
    ) : (
        baseComponent()
    );
};
