import type { StoreState } from '~/reducers';
import { authenticationSceneStateSelector } from '~/selectors';

import type { LoginStoreState } from './components/Login';
import type { SingleSignOnStoreState } from './components/SingleSignOn';
import type { AuthenticationStoreState } from './reducers';

export const loginStoreStateSelector = (s: StoreState): LoginStoreState => {
    return authenticationSceneStateSelector(s).login;
};

export const authenticationStoreStateSelector = (s: StoreState): AuthenticationStoreState => {
    return authenticationSceneStateSelector(s).authentication;
};

export const singleSignOnStoreStateSelector = (s: StoreState): SingleSignOnStoreState => {
    return authenticationSceneStateSelector(s).singleSignOn;
};
