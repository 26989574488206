import { createAction } from 'redux-actions';

import { logEvent } from '~/services/Analytics';
import {
    ApiClient,
    ApiException,
    RequestResetPasswordRequest,
    ResetPasswordRequest,
    ServerResultStatus,
    ValidateResetPasswordRequest,
    createApiModel,
} from '~/services/ApiClient';

import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const requestPasswordResetExecutor = async (
    username: string,
    customerName: string
): Promise<ServerResultStatus> => {
    try {
        await ApiClient.requestResetPassword(createApiModel(RequestResetPasswordRequest, { username, customerName }));
        return ServerResultStatus.ACCEPTED;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    } finally {
        logEvent(
            'authentication',
            'reset-password-request',
            `Reset password request by user ${username} from customer ${customerName}`
        );
    }
};

export const resetPasswordExecutor = async (secretToken: string, password: string): Promise<ServerResultStatus> => {
    try {
        await ApiClient.resetPassword(createApiModel(ResetPasswordRequest, { secretToken, password }));
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

export const validateResetPasswordExecutor = async (secretToken: string): Promise<ServerResultStatus> => {
    try {
        await ApiClient.validateResetPassword(createApiModel(ValidateResetPasswordRequest, { secretToken }));
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

export const requestPasswordResetAction = createAction(
    ActionTypePrefixes.REQUESTPASSWORDRESET,
    requestPasswordResetExecutor
);

export const resetPasswordAction = createAction(ActionTypePrefixes.RESETPASSWORD, resetPasswordExecutor);

export const validateResetPasswordAction = createAction(
    ActionTypePrefixes.VALIDATERESETPASSWORD,
    validateResetPasswordExecutor
);

export const clearDataAction = createAction(ActionTypeKeys.RESETPASSWORD_CLEAR_DATA);
