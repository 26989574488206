import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { PageTemplateComponent } from './component';
import type { PageTemplateInnerProps, PageTemplateProps } from './models';
import { PageTemplateStyleRules } from './styles';

export { WidgetsViewMode } from './widgetsViewMode';

export const PageTemplate = compose<PageTemplateInnerProps, PageTemplateProps>(
    setDisplayName('PageTemplate'),
    withStyles(PageTemplateStyleRules, { withTheme: true })
)(PageTemplateComponent);
