import { actionGenerator } from '~/common';

const MODULE_PREFIX = 'ALERTS';

const ACTION_KEYS = {
    POST: `[${MODULE_PREFIX}] POST`,
    DELETE: `[${MODULE_PREFIX}] DELETE`,
    CLEAR_ALERTS_DATA: `[${MODULE_PREFIX}] CLEAR_ALERTS_DATA`,
    SAVE_INTO_CACHE: `[${MODULE_PREFIX}] SAVE_INTO_CACHE`,
    CLEAR_CACHE: `[${MODULE_PREFIX}] CLEAR_CACHE`,
    APPLY_UPDATE: `[${MODULE_PREFIX}] APPLY_UPDATE`,
    CHANGE_ACTIVE_COUNT: `[${MODULE_PREFIX}] CHANGE_ACTIVE_COUNT`,
    GET_ACTIVE: `[${MODULE_PREFIX}] GET_ACTIVE`,
    ACKNOWLEDGE: `[${MODULE_PREFIX}] ACKNOWLEDGE`,
} as const;

const ACTIONS = { ...actionGenerator(ACTION_KEYS, MODULE_PREFIX) } as const;

export { ACTIONS, ACTION_KEYS };
