import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import image from './assets/empty-road.png';
import type { EmptyStateMessageClassKey } from './styles';

export interface EmptyStateMessageProps {
    dataId?: string;
    title: React.ReactNode;
    description: React.ReactNode;
}

export interface EmptyStateMessageInnerProps extends EmptyStateMessageProps, WithStyles<EmptyStateMessageClassKey> {}

export const EmptyStateMessageComponent: React.FunctionComponent<EmptyStateMessageInnerProps> = ({
    classes,
    dataId,
    title,
    description,
}) => {
    return (
        <div className={classes.root} data-id={dataId}>
            <img className={classes.image} src={image} alt="Empty road" />
            <Typography align="center" variant="subtitle1" className={classes.title}>
                {title}
            </Typography>
            <Typography align="center" variant="caption" color="textSecondary">
                {description}
            </Typography>
        </div>
    );
};
