import { isUndefined } from '~/libs/utility';

export const groupingCriteriaFactory =
    <T>(modifier: (value: T) => number | string) =>
    (value?: T): { key: string | number; value?: React.ReactNode } => ({
        key: isUndefined(value) ? 'undefined' : modifier(value),
        value,
    });

export const groupingCriteriaIncludingUndefinedFactory =
    <T>(modifier: (value: T | undefined) => number | string) =>
    (value?: T): { key: string | number; value?: React.ReactNode } => ({
        key: modifier(value),
        value,
    });
