import { Paper, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import type { NoOlderMessagesToDisplayClassKey } from './styles';

export interface NoOlderMessagesToDisplayProps {}

export interface NoOlderMessagesToDisplayInnerProps
    extends NoOlderMessagesToDisplayProps,
        InjectedTranslationProps,
        WithStyles<NoOlderMessagesToDisplayClassKey> {}

export const NoOlderMessagesToDisplayComponent: React.FC<NoOlderMessagesToDisplayInnerProps> = ({ t, classes }) => {
    return (
        <Paper className={classes.noOlderMessagesToDisplay}>
            <Typography variant="caption" color="textSecondary">
                <span data-id="no-messages">{t('no-older-messages')}</span>
            </Typography>
        </Paper>
    );
};
