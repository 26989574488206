import type { StaticDataStoreState } from '~/common';
import type { JwtStoreState } from '~/components/AuthenticationData';
import type { CustomerAutocompleteStoreState } from '~/components/CustomerRemoteAutocomplete';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { EtherealState } from '~/components/EtherealState';
import type { FeatureFlagsStoreState } from '~/components/FeatureFlags';
import type { IntroCarouselStoreState } from '~/components/IntroCarousel';
import type { LanguageStoreState } from '~/components/LanguageSelector';
import type { MenuStoreState } from '~/components/Navigation';
import type { PersistStoreState } from '~/components/PersistStore';
import type { SceneListRootStoreState } from '~/components/SceneList';
import type { SceneMapRootStoreState } from '~/components/SceneMap';
import type { SynchronizationClockState } from '~/components/SynchronizationClock';
import type { TimeSeriesDialogStoreState } from '~/components/TimeSeriesDialog';
import type { UserPreferencesState } from '~/components/UserPreferences';
import type { UserAutocompleteStoreState } from '~/components/UserRemoteAutocomplete';
import type { CommunicationModuleStoreState } from '~/modules/Communication';
import type { StoreState } from '~/reducers';
import type { AuthenticationSceneStoreState } from '~/scenes/Authentication';
import type { HistorySceneStoreState } from '~/scenes/History';
import type { MonitoringSceneStoreState } from '~/scenes/Monitoring';
import type { ResetPasswordStoreState } from '~/scenes/ResetPassword/reducers';
import type { RtdsSchedulesSceneStoreState } from '~/scenes/RtdsSchedules';
import type { RtdsSessionsSceneStoreState } from '~/scenes/RtdsSessions';
import type { TachoFilesSceneStoreState } from '~/scenes/TachoFiles';
import type { AdminVehicleSceneStoreState } from '~/scenes/VehicleAdministration';

import type { AdminVehicleTypeSceneStoreState } from './scenes/VehicleTypesAdministration/storestate';

export const persistStoreStateSelector = (state: StoreState): PersistStoreState => state.persistStore;

export const protectedComponentStoreStateSelector = (state: StoreState): JwtStoreState => state.protectedComponent;

export const authenticationSceneStateSelector = (state: StoreState): AuthenticationSceneStoreState =>
    state.authenticationScene;

export const languageStateSelector = (state: StoreState): LanguageStoreState => state.language;

export const settingsStoreStateSelector = (state: StoreState): SettingsStoreState => state.settings;

export const staticDataStoreStateSelector = (state: StoreState): StaticDataStoreState => state.staticData;

export const monitoringSceneStateSelector = (state: StoreState): MonitoringSceneStoreState => state.monitoringScene;

export const sceneListStateSelector = (state: StoreState): SceneListRootStoreState => state.sceneList;

export const sceneMapRootStateSelector = (state: StoreState): SceneMapRootStoreState => state.sceneMap;

export const timeSeriesDialogStateSelector = (state: StoreState): TimeSeriesDialogStoreState => state.timeSeriesDialog;

export const synchronizationClockStateSelector = (state: StoreState): SynchronizationClockState =>
    state.synchronizationClock;

export const userPreferencesStateSelector = (state: StoreState): UserPreferencesState => state.userPreferences;

export const etherealStateSelector = (state: StoreState): EtherealState => state.ethereal;

export const introCarouselStoreStateSelector = (state: StoreState): IntroCarouselStoreState => state.introCarousel;

export const communicationModuleStoreStateSelector = (state: StoreState): CommunicationModuleStoreState =>
    state.communicationModule;

export const menuStoreStateSelector = (state: StoreState): MenuStoreState => state.menu;

export const featureFlagsSelector = (state: StoreState): FeatureFlagsStoreState => state.featureFlags;

export const historySceneStateSelector = (state: StoreState): HistorySceneStoreState => state.historyScene;

export const tachoFilesSceneStateSelector = (state: StoreState): TachoFilesSceneStoreState => state.tachoFilesScene;

export const rtdsSessionsSceneStateSelector = (state: StoreState): RtdsSessionsSceneStoreState =>
    state.rtdsSessionsScene;

export const rtdsSchedulesSceneStateSelector = (state: StoreState): RtdsSchedulesSceneStoreState =>
    state.rtdsSchedulesScene;

export const vehicleAdministrationSceneStateSelector = (state: StoreState): AdminVehicleSceneStoreState =>
    state.vehicleAdministrationScene;

export const customerAutocompleteStateSelector = (state: StoreState): CustomerAutocompleteStoreState =>
    state.customerAutocomplete;

export const userAutocompleteStateSelector = (state: StoreState): UserAutocompleteStoreState => state.userAutocomplete;

export const resetPasswordSceneStateSelector = (state: StoreState): ResetPasswordStoreState => state.resetPasswordScene;

export const vehicleTypesAdministrationSceneStateSelector = (state: StoreState): AdminVehicleTypeSceneStoreState =>
    state.vehicleTypesAdministrationScene;
