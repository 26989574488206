import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { LabeledIndicatorGroupContainerInnerProps, LabeledIndicatorGroupContainerProps } from './component';
import { LabeledIndicatorGroupContainerComponent } from './component';
import { LabeledIndicatorGroupContainerStyleRules } from './styles';

export const LabeledIndicatorGroupContainer = compose<
    LabeledIndicatorGroupContainerInnerProps,
    LabeledIndicatorGroupContainerProps
>(
    setDisplayName('LabeledIndicatorGroupContainer'),
    withStyles(LabeledIndicatorGroupContainerStyleRules)
)(LabeledIndicatorGroupContainerComponent);
