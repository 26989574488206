import { withTranslation } from 'react-i18next';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';

import type { RtdsSchedulesListViewInnerProps, RtdsSchedulesListViewProps } from './component';
import { RtdsSchedulesListViewComponent } from './component';

export const RtdsSchedulesListView = compose<RtdsSchedulesListViewInnerProps, RtdsSchedulesListViewProps>(
    setDisplayName('RtdsSchedulesListView'),
    withDisplayPreferences(),
    withTranslation()
)(RtdsSchedulesListViewComponent);
