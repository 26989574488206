import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type ResetPasswordFormClassKeys = 'root' | 'button' | 'link' | 'formField' | 'loader';

export const useStyles = makeStyles<Theme, {}, ResetPasswordFormClassKeys>((theme: Theme) => ({
    root: {
        margin: theme.spacing(3, 5),
    },
    button: {
        marginTop: theme.spacing(5),
    },
    link: {
        color: theme.palette.primary.main,
    },
    formField: {
        marginTop: theme.spacing(1),
    },
    loader: {
        marginLeft: '40%',
        marginBottom: theme.spacing(5),
    },
}));
