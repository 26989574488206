import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateUserPreferencesAction } from '~/data/userpreferences';

import { keyDataStateSelectorFactory } from '../selectors';

export const useUserPreferences = <T extends object>(key: string): [T, (value: Partial<T>) => void] => {
    const selector = React.useMemo(() => keyDataStateSelectorFactory<T>(key), [key]);
    const value = useSelector(selector);
    const dispatch = useDispatch();
    const setValue = React.useCallback(
        (v: Partial<T>) => dispatch(updateUserPreferencesAction(key, v)),
        [key, dispatch]
    );

    return [value, setValue];
};
