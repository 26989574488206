import { sortBy } from '~/libs/utility';

import type { GridColumnDefinition } from './models';

export const applyColumnPreferences = <T>(
    columnDefinitions: Array<GridColumnDefinition<T>>,
    visibleColumns: string[],
    columnOrder: string[]
): Array<GridColumnDefinition<T>> => {
    const availableColumns = columnDefinitions.filter(({ name }) => visibleColumns.includes(name));
    return sortBy(availableColumns, ({ name }) => columnOrder.indexOf(name));
};
