import promiseRetry from 'promise-retry';

import { getConfig } from '~/services/Config';

import { retryableErrorResponseFactory } from './retryableErrorResponseFactory';

export const retryableRequest = <T>(
    fn: () => Promise<T>,
    retries = getConfig().maxRetriesPerFailedRequest
): Promise<T> => {
    return promiseRetry<T>(retryableErrorResponseFactory<T>(fn), {
        retries,
    });
};
