import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const SpeedOdometerIcon: FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path d="M2350 4774 c-346 -42 -597 -115 -871 -255 -610 -310 -1062 -880 -1229 -1549 -51 -205 -65 -322 -65 -570 0 -165 5 -256 18 -340 77 -505 294 -949 636 -1303 82 -85 98 -97 128 -97 42 0 73 30 73 71 0 25 -17 49 -90 128 -334 361 -529 766 -596 1236 -23 162 -23 449 0 610 72 501 302 950 665 1297 346 332 752 531 1231 604 157 25 462 25 620 1 416 -64 799 -233 1105 -489 435 -363 710 -855 791 -1413 23 -160 23 -448 0 -610 -67 -470 -270 -887 -597 -1231 -49 -50 -91 -104 -95 -119 -10 -40 23 -79 69 -83 44 -4 80 26 215 183 309 359 501 796 563 1280 16 132 14 448 -5 590 -141 1046 -952 1875 -1996 2040 -101 16 -488 29 -570 19z" />
                <path d="M2510 4500 c-11 -11 -22 -30 -25 -42 -3 -13 -5 -150 -3 -304 3 -261 4 -284 22 -303 26 -29 86 -29 112 0 18 19 19 42 22 303 2 154 0 291 -3 304 -14 57 -85 82 -125 42z" />
                <path d="M1060 3900 c-12 -12 -20 -33 -20 -53 0 -29 22 -55 213 -245 190 -189 217 -212 245 -212 41 0 82 38 82 77 1 23 -35 63 -216 241 -195 191 -220 212 -250 212 -21 0 -42 -8 -54 -20z" />
                <path d="M3762 3707 c-188 -188 -212 -216 -212 -244 0 -41 33 -73 74 -73 26 0 60 29 243 213 189 189 213 216 213 245 0 42 -30 72 -73 72 -29 0 -55 -23 -245 -213z" />
                <path d="M3506 2946 l-669 -324 -36 35 c-20 19 -58 47 -86 62 -45 23 -61 26 -155 26 -96 0 -110 -2 -158 -28 -158 -83 -233 -252 -183 -415 38 -128 133 -216 265 -249 162 -40 330 46 404 205 23 48 27 71 26 144 l0 87 76 35 c41 19 197 94 345 166 149 73 410 199 580 282 171 82 318 157 327 166 9 10 20 29 23 44 6 22 1 33 -23 58 -17 16 -39 30 -49 30 -10 0 -319 -146 -687 -324z m-868 -361 c79 -34 122 -101 122 -193 0 -81 -39 -143 -113 -177 -66 -31 -108 -31 -174 0 -156 72 -152 300 7 369 45 20 111 20 158 1z" />
                <path d="M485 2468 c-50 -29 -58 -72 -21 -115 l24 -28 302 0 302 0 19 24 c24 30 24 72 0 102 l-19 24 -294 2 c-210 2 -299 0 -313 -9z" />
                <path d="M4015 2455 c-43 -42 -28 -103 29 -125 38 -14 543 -13 585 1 57 20 71 92 25 128 -26 20 -38 21 -321 21 l-294 0 -24 -25z" />
                <path d="M3603 1412 c-30 -4 -53 -39 -53 -77 0 -27 28 -59 213 -243 182 -181 217 -212 243 -212 47 0 74 25 74 68 0 35 -12 49 -212 248 -117 116 -220 213 -228 216 -8 2 -25 2 -37 0z" />
                <path d="M1252 1197 c-187 -187 -212 -216 -212 -244 0 -35 38 -73 72 -73 39 0 77 32 271 228 214 216 222 228 172 277 -16 17 -35 25 -57 25 -30 0 -55 -22 -246 -213z" />
                <path d="M1945 958 c-44 -24 -45 -30 -45 -303 0 -249 1 -262 20 -283 11 -12 31 -24 43 -27 12 -3 291 -4 619 -3 l597 3 23 23 c22 22 23 28 26 266 3 270 -3 307 -53 326 -38 14 -1204 13 -1230 -2z m1125 -308 l0 -160 -510 0 -510 0 0 160 0 160 510 0 510 0 0 -160z" />
            </g>
        </SvgIcon>
    );
};

export { SpeedOdometerIcon };
