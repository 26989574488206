import type { Dispatch } from 'redux';

import type { ChangelogEntry } from '~/common';
import { getVehicleTypeChangelogsAction } from '~/data/vehicletypeadministration';
import { reportError } from '~/reporting';
import type { ResolvedVehicleTypeChangelogChanges } from '~/services/ApiClient';

import type { VehicleTypeChangelogsStoreState } from './reducers';

export interface VehicleTypeChangelogsStateProps {
    loading: boolean;
    rejected: boolean;
    changelogsEntries: ChangelogEntry<ResolvedVehicleTypeChangelogChanges>[];
    previousVehicleTypeId?: number;
}

export interface VehicleTypeChangelogsDispatchProps {
    getVehicleTypeChangelogs: (vehicleTypeId: number) => void;
}

export interface VehicleTypeChangelogsRedux
    extends VehicleTypeChangelogsStateProps,
        VehicleTypeChangelogsDispatchProps {}

export const mapStateToProps = (state: VehicleTypeChangelogsStoreState): VehicleTypeChangelogsStateProps => ({
    loading: state.vehicleTypeChangelogs.pending,
    rejected: state.vehicleTypeChangelogs.rejected,
    changelogsEntries: state.vehicleTypeChangelogsEntries,
    previousVehicleTypeId: state.previousSourceVehicleType,
});

export const mapDispatchProps = (dispatch: Dispatch): VehicleTypeChangelogsDispatchProps => ({
    getVehicleTypeChangelogs: (vehicleTypeId: number) => {
        dispatch(getVehicleTypeChangelogsAction(vehicleTypeId)).catch(reportError);
    },
});
