import type { NumericDictionary } from '~/libs/utility';
import { orderBy } from '~/libs/utility';
import type {
    DisplayUserPreferencesVehicleDisplayFormat,
    ResolvedConversationSummary,
    VehicleMessagingCapabilities,
} from '~/services/ApiClient';
import { Vehicle, createApiModel } from '~/services/ApiClient';
import { formatVehicleName } from '~/services/Formatters';

import type { ConversationSummaryEntry } from './conversationSummaryEntry';

export const buildConversationSummaryEntries = (
    conversationSummaryList: ResolvedConversationSummary[],
    vehiclesHash: NumericDictionary<Vehicle>,
    vehicleMessagingCapabilities: NumericDictionary<VehicleMessagingCapabilities>,
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat
): ConversationSummaryEntry[] => {
    const conversationSummaryEntries = conversationSummaryList.map((conversationSummary) => {
        return {
            conversationSummary,
            vehicle:
                vehiclesHash[conversationSummary.vehicleId] ||
                createApiModel(Vehicle, { id: conversationSummary.vehicleId, category: 0 }),
            capabilities: vehicleMessagingCapabilities[conversationSummary.vehicleId],
        };
    });

    return orderBy(
        conversationSummaryEntries,
        [
            (a: ConversationSummaryEntry) => {
                if (!a.conversationSummary.lastMessage?.value) {
                    return '';
                }

                return a.conversationSummary.lastMessage.value.dateTime;
            },
            (a: ConversationSummaryEntry) => {
                return formatVehicleName(a.vehicle, vehicleDisplayFormat);
            },
        ],
        ['desc', 'asc']
    );
};
