import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type AttachmentComponentClassKey = 'root' | 'fileType' | 'filename' | 'fileDateTime' | 'fileTypeAndName';

export const AttachmentComponentStyleRules: StyleRulesCallback<Theme, {}, AttachmentComponentClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            display: 'flex',
            cursor: 'pointer',
            background: theme.palette.background.paper,
            padding: theme.spacing(0.5, 1),
        },
        fileTypeAndName: {
            flexGrow: 1,
            display: 'flex',
            overflow: 'hidden',
        },
        fileType: {
            margin: theme.spacing(0, 0.25),
        },
        filename: {
            flexGrow: 1,
            margin: theme.spacing(0, 0.25),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        fileDateTime: {
            textAlign: 'right',
            whiteSpace: 'nowrap',
            margin: theme.spacing(0, 0.25, 0, 0.75),
        },
    });
