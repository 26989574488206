import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';
import type { DeepPojoModel, DisplayUserPreferencesDriverDisplayFormat, DriverName } from '~/services/ApiClient';
import { formatDriverName } from '~/services/Formatters';

export interface DriverNameFormatterProps {
    value?: DriverName | DeepPojoModel<DriverName>;
    displayNameFormat: DisplayUserPreferencesDriverDisplayFormat;
}

export interface DriverNameFormatterInnerProps extends DriverNameFormatterProps {}

const DriverNameFormatter: FC<DriverNameFormatterInnerProps> = ({ value, displayNameFormat }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatDriverName(value, displayNameFormat)}</>;
};
DriverNameFormatter.displayName = 'DriverNameFormatter';

export { DriverNameFormatter };
