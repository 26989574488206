import type { Duration } from 'moment';
import { withTranslation } from 'react-i18next';
import { defaultProps } from 'react-recompose';

import { ViolationFieldFormatterFactory } from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { ViolationField } from '~/services/ApiClient';
import { durationToDays } from '~/services/Convertors';
import { exportFormatterFactory, formatDuration, valueTextFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareFactory, compareNumbers } from '~/services/Sorting';

import { ViolationFieldGroupFormatter } from '../Formatters/ViolationFieldGroup';

import type { CreateColumnOptions, GridColumnDefinition } from './models';
import { violationGroupingCriteriaForViolationField } from './violationGroupCriteria';

export const createDurationWithViolationColumn = <T>(
    t: SingleTFunction,
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => ViolationField<Duration> | undefined,
    options: CreateColumnOptions
): GridColumnDefinition<T> => {
    const DurationViolationFieldFormatterComponent = ViolationFieldFormatterFactory((v: Duration) => formatDuration(v));

    return {
        ...options,
        dataType: 'duration',
        name,
        title,
        groupTitle,
        getCellValue,
        align: 'right',
        excelCellFormat: `[h]"${t('unit-hour')}" mm"${t('unit-minute')}"`,
        exportValueFormatter: exportFormatterFactory((durationViolationField: ViolationField<Duration>) =>
            durationToDays(durationViolationField.value)
        ),
        compare: compareFactory((v: ViolationField<Duration>) => v.value.asMilliseconds(), compareNumbers),
        valueFormatterComponent: defaultProps({ t, fieldName: title })(DurationViolationFieldFormatterComponent),
        valueTextFormatter: valueTextFormatterFactory((v: ViolationField<Duration>) => formatDuration(v.value)),
        getFilterValue: (v: ViolationField<Duration>) => v.value,
        formatUndefinedValue: true,
        groupValueFormatterComponent: withTranslation()(ViolationFieldGroupFormatter<Duration>()),
        groupingCriteria: groupingCriteriaFactory(violationGroupingCriteriaForViolationField<Duration>()),
    };
};
