import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';

import type { LocationSectionActionButtonsInnerProps, LocationSectionActionButtonsProps } from './component';
import { LocationSectionActionButtonsComponent } from './component';
import { mapStateToProps } from './redux';
import { LocationSectionActionButtonsStyleRules } from './styles';

export const LocationSectionActionButtons = compose<
    LocationSectionActionButtonsInnerProps,
    LocationSectionActionButtonsProps
>(
    setDisplayName('LocationSectionActionButtons'),
    withStyles(LocationSectionActionButtonsStyleRules),
    withTranslation(),
    connect(createSelector(settingDataSelector(SettingsKey.SECURABLES), mapStateToProps))
)(LocationSectionActionButtonsComponent);
