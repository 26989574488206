import { mpsToKmph } from '@fv/converters';

import type { SingleTFunction } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { ClassificationAbstraction, DerivedClassification } from '~/services/ApiClient';
import {
    Classification,
    ClassificationType,
    DisplayUserPreferencesUnitSystem,
    SpeedLimit,
    VehicleType,
    VehicleTypeDimensions,
    createApiModel,
} from '~/services/ApiClient';
import { mphToMps } from '~/services/Convertors';
import { vehicleTypeAxlesComboDerivedClassificationValueProvider } from '~/services/DerivedClassifications';
import { formatClassification } from '~/services/Formatters';

import type { FieldValues } from './models';

export const mapVehicleTypeAxlesCombo = (
    typeAxlesDerivedClassifications: NumericDictionary<DerivedClassification>,
    vehicleTypeCategoryId?: number,
    axlesId?: number
): DerivedClassification => {
    if (isUndefined(vehicleTypeCategoryId) || isUndefined(axlesId)) {
        throw new Error('Cannot map empty classification IDs');
    }

    const derivedId = vehicleTypeCategoryId * 10 + axlesId;
    const derivedClassification = typeAxlesDerivedClassifications[derivedId];

    if (isUndefined(derivedClassification)) {
        throw new Error(`Cannot map derived classification id ${derivedId}`);
    }

    return derivedClassification;
};

interface InverseMapVehicleTypeAxlesComboArgs {
    typeAxlesDerivedClassifications: NumericDictionary<DerivedClassification>;
    typeAxlesDerivedClassificationId?: number;
}

export const inverseMapVehicleTypeAxlesCombo = (
    args: InverseMapVehicleTypeAxlesComboArgs
): [Classification, Classification] => {
    const { typeAxlesDerivedClassifications, typeAxlesDerivedClassificationId } = args;

    if (
        isUndefined(typeAxlesDerivedClassificationId) ||
        isUndefined(typeAxlesDerivedClassifications[typeAxlesDerivedClassificationId])
    ) {
        throw new Error(`Unknown derived classification id ${typeAxlesDerivedClassificationId}`);
    }

    const derivedClassificationKey = typeAxlesDerivedClassifications[typeAxlesDerivedClassificationId].key;

    return [
        createApiModel(Classification, {
            id: Math.floor(typeAxlesDerivedClassificationId / 10),
            key: derivedClassificationKey.split('-')[0],
        }),
        createApiModel(Classification, {
            id: typeAxlesDerivedClassificationId % 10,
            key: derivedClassificationKey.split('-')[1],
        }),
    ];
};

interface MapToVehicleTypeArgs {
    typeAxlesDerivedClassifications: NumericDictionary<DerivedClassification>;
    unitSystem: DisplayUserPreferencesUnitSystem;
    data: FieldValues;
    originalId?: number;
}

export const mapToVehicleType = (args: MapToVehicleTypeArgs): VehicleType => {
    const { data, typeAxlesDerivedClassifications, unitSystem, originalId } = args;

    let convertedSpeedLimitMotorway: number | undefined;
    let convertedSpeedLimitRoad: number | undefined;
    let convertedSpeedLimitCity: number | undefined;

    const convertedWeight = !isUndefined(data?.weight) ? data.weight * 1000 : undefined;
    const [typeClassification, axlesClassification] = inverseMapVehicleTypeAxlesCombo({
        typeAxlesDerivedClassifications,
        typeAxlesDerivedClassificationId: data.type,
    });

    switch (unitSystem) {
        case DisplayUserPreferencesUnitSystem.Metric:
            convertedSpeedLimitMotorway = data?.speedLimitMotorway;
            convertedSpeedLimitRoad = data?.speedLimitRoad;
            convertedSpeedLimitCity = data?.speedLimitCity;
            break;
        case DisplayUserPreferencesUnitSystem.Imperial:
            convertedSpeedLimitMotorway = !isUndefined(data?.speedLimitMotorway)
                ? mpsToKmph(mphToMps(data.speedLimitMotorway))
                : undefined;
            convertedSpeedLimitRoad = !isUndefined(data?.speedLimitRoad)
                ? mpsToKmph(mphToMps(data.speedLimitRoad))
                : undefined;
            convertedSpeedLimitCity = !isUndefined(data?.speedLimitCity)
                ? mpsToKmph(mphToMps(data.speedLimitCity))
                : undefined;
            break;
        default:
            throw new Error('Unknown unit system');
    }

    return createApiModel(VehicleType, {
        id: originalId ?? 0,
        name: data.name ?? '',
        description: data.description,
        dimensions: createApiModel(VehicleTypeDimensions, {
            height: data.height,
            width: data.width,
            length: data.length,
        }),
        weight: Number(convertedWeight?.toFixed(1)),
        type: typeClassification.id,
        axles: axlesClassification.id,
        emissionClass: data.emissionClass,
        hazardousGoods: data.hazardousGoods,
        speedLimit: createApiModel(SpeedLimit, {
            motorway: Number(convertedSpeedLimitMotorway?.toFixed(0)),
            road: Number(convertedSpeedLimitRoad?.toFixed(0)),
            city: Number(convertedSpeedLimitCity?.toFixed(0)),
        }),
    });
};

export const mapEmissionClassToClassificationAbstraction = (
    t: SingleTFunction,
    values: NumericDictionary<Classification>
): ClassificationAbstraction[] => {
    return Object.values(values).map((it: Classification) => ({
        id: it.id,
        key: it.key,
        displayName: formatClassification(t, ClassificationType.EmissionClass, it),
    }));
};

export const mapVehicleTypeAxlesComboToClassificationAbstraction = (
    t: SingleTFunction,
    vehicleTypeCategories: NumericDictionary<Classification>,
    axles: NumericDictionary<Classification>
): ClassificationAbstraction[] => {
    return Object.values(vehicleTypeAxlesComboDerivedClassificationValueProvider(t, vehicleTypeCategories, axles));
};
