import { createAction } from 'redux-actions';

import { changeLanguage, retrieveAllLanguages, retrieveCurrentLanguage } from '~/services/Language';

import type { ChangeLanguagePayload, CurrentLanguagePayload, Language, LanguagesPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

export const retrieveLanguagesAction = createAction(
    ActionTypePrefixes.LANGUAGES,
    async (): Promise<LanguagesPayload> => ({
        languages: await retrieveAllLanguages(),
    })
);

export const retrieveCurrentLanguageAction = createAction(
    ActionTypePrefixes.CURRENTLANGUAGE,
    async (): Promise<CurrentLanguagePayload> => ({
        language: await retrieveCurrentLanguage(),
    })
);

export const changeLanguageAction = createAction(
    ActionTypePrefixes.CHANGELANGUAGE,
    async (language: Language): Promise<ChangeLanguagePayload> => {
        await changeLanguage(language);
        return { language };
    }
);
