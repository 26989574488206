import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { MovementPositionGroupInnerProps, MovementPositionGroupProps } from './component';
import { MovementPositionGroupComponent } from './component';
import { MovementPositionGroupStyleRules } from './styles';

export const MovementPositionGroup = compose<MovementPositionGroupInnerProps, MovementPositionGroupProps>(
    setDisplayName('MovementPositionGroup'),
    withTranslation(),
    withStyles(MovementPositionGroupStyleRules),
    withDisplayPreferences()
)(MovementPositionGroupComponent);
