import { Avatar } from '@mui/material';
import type { Theme } from '@mui/material';
import { styled } from '@mui/styles';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

type TrailerClassKey = 'disabled' | 'disabledTitle' | 'delimiter' | 'trailerIcon' | 'trailerType';

const TrailerStyleRules: StyleRulesCallback<Theme, {}, TrailerClassKey> = (theme: Theme) =>
    createStyles({
        disabled: {
            opacity: 0.38,
        },
        disabledTitle: {
            fontStyle: 'italic',
        },
        delimiter: {
            margin: theme.spacing(0, 0.5),
        },
        trailerIcon: {
            color: theme.functionalItemsColors.assetType.trailer.value,
            backgroundColor: theme.functionalItemsColors.assetType.trailer.contrast,
            border: `1px solid ${theme.palette.text.secondary}`,
        },
        trailerType: {
            fontWeight: theme.typography.fontWeightMedium,
        },
    });

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: 40,
    height: 40,
    alignSelf: 'center',
    backgroundColor: theme.palette.background.default,
    overflow: 'visible',
}));

export { StyledAvatar as Avatar, TrailerStyleRules };
export type { TrailerClassKey };
