import { styled } from '@mui/material';

import { ContainerIcon as _ContainerIcon } from '~/components/Icons';

const ContainerIcon = styled(_ContainerIcon)({
    height: '100%',
    width: '100%',
});

export { ContainerIcon };
