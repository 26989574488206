import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import MuiLink from '@mui/material/Link';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { WithStyles } from '@mui/styles';
import type { FunctionComponent, HTMLAttributes, RefObject } from 'react';
import { forwardRef, useCallback } from 'react';

import fvLogoUrl from '~/assets/fv-logo.svg';
import type { InjectedAppVersionProps } from '~/components/AppVersion';
import type { InjectedUserDataProps } from '~/components/AuthenticationData';
import type { InjectedImpersonatorTokenProps } from '~/components/AuthenticationData/components/ImpersonatorToken';
import { CustomLink } from '~/components/CustomLink';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';

import type { MenuItem } from '../../../../models';

import { OverlayMenuItem } from './components/OverlayMenuItem';
import { UserAvatar } from './components/UserAvatar';
import { UserInfo } from './components/UserInfo';
import type { OverlayMenuStyleRules } from './styles';

const LogoutLink = forwardRef((props: HTMLAttributes<HTMLAnchorElement>, ref: RefObject<HTMLAnchorElement>) => (
    <CustomLink to="/logout" {...props} innerRef={ref} />
));

const EndImpersonationLink = forwardRef(
    (props: HTMLAttributes<HTMLAnchorElement>, ref: RefObject<HTMLAnchorElement>) => (
        <CustomLink to="/end-impersonation" {...props} innerRef={ref} />
    )
);

export interface OverlayMenuProps {
    moduleMenuItems: MenuItem[];
    externalLinksItems: MenuItem[];
    miscellaneousMenuItems: MenuItem[];
    onClose: () => void;
}

export interface OwnProps extends InjectedImpersonatorTokenProps, InjectedUserDataProps {}

export interface StateProps {
    impersonateUserPending: boolean;
}

export interface OverlayMenuInnerProps
    extends OverlayMenuProps,
        StateProps,
        WithStyles<typeof OverlayMenuStyleRules, true>,
        InjectedTranslationProps,
        InjectedAppVersionProps,
        InjectedUserDataProps {}

export const OverlayMenuComponent: FunctionComponent<OverlayMenuInnerProps> = (props) => {
    const {
        t,
        classes,
        moduleMenuItems,
        externalLinksItems,
        miscellaneousMenuItems,
        onClose,
        userName,
        userFullName,
        customerCareUserName,
        appVersion,
        impersonateUserPending,
        theme,
    } = props;

    const renderMenuItem = useCallback(
        (item: MenuItem, _index: number, dataIdPrefix: string) => {
            const dataId = `${dataIdPrefix}:${item.key}`;
            return <OverlayMenuItem menuItem={item} onClose={onClose} dataId={dataId} key={item.key} />;
        },
        [onClose]
    );
    const moduleMenuListItems = moduleMenuItems.map((m, i) => renderMenuItem(m, i, 'module-button'));
    const externalLinksMenuListItems = externalLinksItems.map((m, i) => renderMenuItem(m, i, 'external-button'));
    const miscellaneousMenuListItems = miscellaneousMenuItems.map((m, i) =>
        renderMenuItem(m, i, 'miscellaneous-button')
    );

    const itemsGrouped = [moduleMenuListItems, externalLinksMenuListItems, miscellaneousMenuListItems];

    const customerCareUserNameElement = customerCareUserName && (
        <Typography
            variant="subtitle2"
            gutterBottom
            color="error"
            className={classes.customerCareUsername}
            data-id="customer-care-username"
        >
            {customerCareUserName}
        </Typography>
    );

    return (
        <div className={classes.root} data-id="overlay-menu">
            <div className={classes.header}>
                <div className={classes.logoContainer}>
                    <img className={classes.logo} src={fvLogoUrl} alt="FleetVisor logo" />
                </div>
                <IconButton onClick={onClose} data-id="overlay-close" size="large">
                    <ChevronLeft />
                </IconButton>
            </div>

            <div className={classes.profile}>
                <UserAvatar
                    userName={userName}
                    userFullName={userFullName}
                    className={classes.avatar}
                    data-id="user-avatar"
                />
                <UserInfo />
                {customerCareUserNameElement}
            </div>

            <div className={classes.signOut}>
                <Button color="secondary" component={LogoutLink} data-id="sign-out">
                    {t('sign-out')}
                </Button>
                {isUndefined(customerCareUserName) ? null : (
                    <Button color="secondary" component={EndImpersonationLink} data-id="end-impersonation">
                        {t('end-impersonation')}
                    </Button>
                )}
            </div>

            <div className={classes.linearProgressContainer}>
                {impersonateUserPending ? <LinearProgress data-id="progress-loader" /> : <Divider />}
            </div>

            <div className={classes.menuItems}>
                {itemsGrouped.map((item, idx) => {
                    const ordinal = idx + 1;
                    return (
                        item.length > 0 && (
                            <div key={ordinal}>
                                <List>{item}</List>
                                {ordinal < itemsGrouped.length ? <Divider /> : undefined}
                            </div>
                        )
                    );
                })}
            </div>

            <Divider />

            <div className={classes.footerContainer}>
                <div className={classes.footerContent}>
                    <Typography variant="caption" data-id="version">
                        {t('nth-version', { version: appVersion })}
                    </Typography>

                    <div>
                        <MuiLink
                            href="https://www.astrata.eu/privacy-policy"
                            variant="caption"
                            data-id="privacy-link"
                            color="secondary"
                            underline="hover"
                        >
                            {t('privacy')}
                        </MuiLink>
                    </div>
                </div>
                <div className={classes.footerLogoContainer}>
                    <Tooltip title={t('visit-partner-website', { partnerName: theme.partner.name })}>
                        <MuiLink
                            href={theme.partner.url}
                            className={classes.footerLogoLink}
                            data-id="partner-link"
                            underline="hover"
                        >
                            <img src={theme.partner.logoUrl} alt={theme.partner.name} className={classes.footerLogo} />
                        </MuiLink>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};
