import { Avatar } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { getUserNameInitials } from './getUserNameInitials';
import type { UserAvatarClassKey } from './styles';

export interface UserAvatarProps {
    userName: string;
    userFullName?: string;
    className?: string;
}

export interface UserAvatarInnerProps extends UserAvatarProps, WithStyles<UserAvatarClassKey> {}

export const UserAvatarComponent: React.FunctionComponent<UserAvatarInnerProps> = ({
    classes,
    userName,
    userFullName,
    className,
    ...restProps
}) => (
    <Avatar className={classNames(classes.avatar, className)} {...restProps}>
        {getUserNameInitials(userName, userFullName)}
    </Avatar>
);
