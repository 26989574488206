import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { RetryRetrieveSnackbarInnerProps, RetryRetrieveSnackbarProps } from './component';
import { RetryRetrieveSnackbarComponent } from './component';

export const RetryRetrieveSnackbar = compose<RetryRetrieveSnackbarInnerProps, RetryRetrieveSnackbarProps>(
    setDisplayName('RetryRetrieveSnackbar'),
    withTranslation()
)(RetryRetrieveSnackbarComponent);
