import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ChartLegendItemInnerProps, ChartLegendItemProps } from './component';
import { ChartLegendItemComponent } from './component';
import { ChartLegendItemStyleRules } from './styles';

export const ChartLegendItem = compose<ChartLegendItemInnerProps, ChartLegendItemProps>(
    setDisplayName('ChartLegendItem'),
    withStyles(ChartLegendItemStyleRules)
)(ChartLegendItemComponent);
