import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import type { SectionName } from './constants';

export const CHANGELOGSUBPAGE_PREFERENCES_KEY = 'changelog-subpage-state';

export interface ChangelogSubpageUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {}

export const defaultChangelogSubpageUserPreferences: ChangelogSubpageUserPreferences = {
    sectionOrder: [],
    collapsedSections: [],
};

export const processResponse = (
    preferences: Partial<ChangelogSubpageUserPreferences>
): ChangelogSubpageUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultChangelogSubpageUserPreferences);
};

export const requiredChangelogSubpageUserPreferences = {
    key: CHANGELOGSUBPAGE_PREFERENCES_KEY,
    defaultState: defaultChangelogSubpageUserPreferences,
    processResponse,
};
