import { actionGenerator } from '~/common';

const MODULE_PREFIX = 'ALERT_DEFINITIONS';

const ACTION_KEYS = {
    GET_ACTIVE: `[${MODULE_PREFIX}] GET_ACTIVE`,
    GET_ALERTS: `[${MODULE_PREFIX}] GET_ALERTS`,
    GET_ALERT_SUMMARY: `[${MODULE_PREFIX}] GET_ALERT_SUMMARY`,
    GET_ASSET_GROUPS: `[${MODULE_PREFIX}] GET_ASSET_GROUPS`,
    DELETE_ALERT: `[${MODULE_PREFIX}] DELETE_ALERT`,
} as const;

const ACTIONS = { ...actionGenerator(ACTION_KEYS, MODULE_PREFIX) } as const;

export { ACTIONS, ACTION_KEYS };
