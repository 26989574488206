import { makeStyles } from '@mui/styles';

export type SceneLoaderClassKey = 'loader';

export const useStyles = makeStyles((theme) => ({
    loader: {
        color: theme.palette.primary.main,
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 1,
        margin: '-75px 0 0 -75px',
    },
}));
