const MAX_INT_32 = 999_999_999;

const alphanumeric = /^[a-z0-9]+$/i;
const alphanumericWithSpaceSlashDash = /^[a-z0-9 /-]+$/i;
const phoneWithOptionalPlus = /^(\+)?[0-9]+$/;
const timeRegExp = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
const emailRegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const isAlphanumeric = (value: string) => {
    if (!value) {
        return true;
    }

    return alphanumeric.test(value);
};

const isAlphanumericWithSpaceSlashDash = (value: string) => {
    if (!value) {
        return true;
    }

    return alphanumericWithSpaceSlashDash.test(value);
};

const isPhoneNumber = (value?: string) => {
    if (!value) {
        return false;
    }

    return phoneWithOptionalPlus.test(value);
};

const isEmail = (value?: string) => {
    if (!value) {
        return false;
    }

    return emailRegExp.test(value);
};

const is24HoursClock = (value?: string) => {
    if (!value) {
        return true;
    }

    return timeRegExp.test(value);
};

export { MAX_INT_32, isAlphanumeric, isAlphanumericWithSpaceSlashDash, isPhoneNumber, isEmail, is24HoursClock };
