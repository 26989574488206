import { styled } from '@mui/material';

import { TruckIcon as _TruckIcon } from '~/components/Icons';

const TruckIcon = styled(_TruckIcon)({
    height: '100%',
    width: '100%',
});

export { TruckIcon };
