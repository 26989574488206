import type { CrudActions } from '@fv/components/Dialogs';
import { CRUD_ACTIONS } from '@fv/components/Dialogs';

import type { SingleTFunction } from '~/components/LanguageSelector';
import type { NumericDictionary } from '~/libs/utility';
import { isNil, isUndefined } from '~/libs/utility';
import type {
    Classification,
    ClassificationAbstraction,
    CompanyCard,
    ResolvedAdminVehicleRead,
    ResolvedVehicleSettingsEdit,
    ResolvedVehicleSettingsRead,
    VehicleCategory,
    VehicleType,
} from '~/services/ApiClient';
import {
    AdminVehicleEdit,
    AssetType,
    SettingsBooleanValue,
    SettingsIntegerValue,
    SettingsStringValue,
    SettingsValueSource,
    VehicleSettingsEdit,
    createApiModel,
} from '~/services/ApiClient';
import { formatVehicleCategory, formatVehicleName } from '~/services/Formatters';

import { noneServiceValue } from './constants';
import type { DefaultSettingsValues, FieldValues, MapMulticolumnClassificationProps } from './models';

const modifyVehicleMapper = ({
    vehicle,
    vehicleSettings,
}: {
    vehicle: ResolvedAdminVehicleRead;
    vehicleSettings: ResolvedVehicleSettingsEdit;
}): FieldValues => ({
    id: vehicle.id,
    vin: vehicle.vin ?? '',
    vrn: vehicle.vrn ?? '',
    unitId: vehicle.unitId ?? '',
    alias: vehicleSettings?.alias.value ?? '',
    device: vehicle.device?.id ?? null,
    tag: vehicle.tag ?? '',
    companyCardId: vehicle.companyCardId || null,
    depot: vehicle.depot?.id || null,
    vehicleType: vehicle.vehicleType?.id || null,
    vehicleCategory: vehicle.category?.id,
    alertsAndPanicEnabled: vehicleSettings?.alertsAndPanicEnabled?.value,
    driverCardsEnabled: vehicleSettings?.driverCardsEnabled?.value,
    driverCoachEnabled: vehicleSettings?.driverCoachEnabled?.value,
    driverHoursEnabled: vehicleSettings?.driverHoursEnabled?.value,
    dseEnabled: vehicleSettings?.dseEnabled?.value,
    frequentPositioningEnabled: vehicleSettings?.frequentPositioningEnabled?.value,
    fuelSiteAdviceEnabled: vehicleSettings?.fuelSiteAdviceEnabled?.value,
    kpi2Enabled: vehicleSettings?.kpi2Enabled?.value,
    landmarksEnabled: vehicleSettings?.landmarksEnabled?.value,
    rtdsEnabled: vehicleSettings?.rtdsEnabled?.value,
    textMessagingEnabled: vehicleSettings?.textMessagingEnabled?.value,
    trailersEnabled: vehicleSettings?.trailersEnabled?.value,
    guidedNavigationEnabled: vehicleSettings?.guidedNavigationEnabled?.value,
    tireControlEnabled: vehicleSettings?.tireControlEnabled?.value,
    appStoreEnabled: vehicleSettings?.appStoreEnabled?.value,
    truckIdentificationEnabled: vehicleSettings?.truckIdentificationEnabled?.value,
    trailerControlServiceEnabled: vehicleSettings?.trailerControlServiceEnabled?.value,
    j1939DataServiceEnabled: vehicleSettings?.j1939DataServiceEnabled?.value,
    navigationEtaEnabled: vehicleSettings?.navigationEtaEnabled?.value,
    tachograph: vehicleSettings?.tachograph?.value.id ?? noneServiceValue,
    tachoProtocol: vehicleSettings?.tachoProtocol?.value.id ?? noneServiceValue,
    tachoGeneration: vehicleSettings?.tachoGeneration?.value.id ?? noneServiceValue,
    oneMinuteRuleEnabled: vehicleSettings?.oneMinuteRuleEnabled?.value,
    workflowType: vehicleSettings?.workflowType?.value.id ?? noneServiceValue,
    navigationType: vehicleSettings?.navigationType?.value.id ?? noneServiceValue,
    imageCapturingType: vehicleSettings?.imageCapturingType?.value.id ?? noneServiceValue,
    inhibitorServiceType: vehicleSettings?.inhibitorServiceType?.value.id ?? noneServiceValue,
    tellTaleServiceType: vehicleSettings?.tellTaleServiceType?.value.id ?? noneServiceValue,

    alertsAndPanicEnabledSettingsValueSource: vehicleSettings?.alertsAndPanicEnabled?.source,
    driverCardsEnabledSettingsValueSource: vehicleSettings?.driverCardsEnabled?.source,
    driverCoachEnabledSettingsValueSource: vehicleSettings?.driverCoachEnabled?.source,
    driverHoursEnabledSettingsValueSource: vehicleSettings?.driverHoursEnabled?.source,
    dseEnabledSettingsValueSource: vehicleSettings?.dseEnabled?.source,
    frequentPositioningEnabledSettingsValueSource: vehicleSettings?.frequentPositioningEnabled?.source,
    fuelSiteAdviceEnabledSettingsValueSource: vehicleSettings?.fuelSiteAdviceEnabled?.source,
    kpi2EnabledSettingsValueSource: vehicleSettings?.kpi2Enabled?.source,
    landmarksEnabledSettingsValueSource: vehicleSettings?.landmarksEnabled?.source,
    rtdsEnabledSettingsValueSource: vehicleSettings?.rtdsEnabled?.source,
    textMessagingEnabledSettingsValueSource: vehicleSettings?.textMessagingEnabled?.source,
    trailersEnabledSettingsValueSource: vehicleSettings?.trailersEnabled?.source,
    guidedNavigationEnabledSettingsValueSource: vehicleSettings?.guidedNavigationEnabled?.source,
    tireControlEnabledSettingsValueSource: vehicleSettings?.tireControlEnabled?.source,
    appStoreEnabledSettingsValueSource: vehicleSettings?.appStoreEnabled?.source,
    truckIdentificationEnabledSettingsValueSource: vehicleSettings?.truckIdentificationEnabled?.source,
    trailerControlServiceEnabledSettingsValueSource: vehicleSettings?.trailerControlServiceEnabled?.source,
    j1939DataServiceEnabledSettingsValueSource: vehicleSettings?.j1939DataServiceEnabled?.source,
    navigationEtaEnabledSettingsValueSource: vehicleSettings?.navigationEtaEnabled?.source,
    tachographSettingsValueSource: vehicleSettings?.tachograph?.source,
    tachoProtocolSettingsValueSource: vehicleSettings?.tachoProtocol?.source,
    tachoGenerationSettingsValueSource: vehicleSettings?.tachoGeneration?.source,
    oneMinuteRuleEnabledSettingsValueSource: vehicleSettings?.oneMinuteRuleEnabled?.source,
    workflowTypeSettingsValueSource: vehicleSettings?.workflowType?.source,
    navigationTypeSettingsValueSource: vehicleSettings?.navigationType?.source,
    inhibitorServiceTypeSettingsValueSource: vehicleSettings?.inhibitorServiceType?.source,
    tellTaleServiceTypeSettingsValueSource: vehicleSettings?.tellTaleServiceType?.source,
    imageCapturingTypeSettingsValueSource: vehicleSettings?.imageCapturingType?.source,
    adBlueTankSize: vehicleSettings.adBlueTankSize?.value,
    primaryFuelTankSize: vehicleSettings.primaryFuelTankSize?.value,
    primaryFuelTankType: vehicleSettings.primaryFuelTankType?.value,
    secondaryFuelTankSize: vehicleSettings.secondaryFuelTankSize?.value,
    secondaryFuelTankType: vehicleSettings.secondaryFuelTankType?.value,
    adBlueTankSizeValueSource: vehicleSettings.adBlueTankSize?.source,
    primaryFuelTankSizeValueSource: vehicleSettings.primaryFuelTankSize?.source,
    primaryFuelTankTypeValueSource: vehicleSettings.primaryFuelTankType?.source,
    secondaryFuelTankSizeValueSource: vehicleSettings.secondaryFuelTankSize?.source,
    secondaryFuelTankTypeValueSource: vehicleSettings.secondaryFuelTankType?.source,
});

const cloneVehicleMapper = ({
    vehicle,
    vehicleSettings,
    vehicleCategory,
}: {
    vehicle: ResolvedAdminVehicleRead;
    vehicleSettings: ResolvedVehicleSettingsEdit;
    vehicleCategory: Classification;
}): FieldValues => ({
    vin: '',
    vrn: '',
    unitId: '',
    alias: '',
    tag: vehicle.tag ?? '',
    device: null,
    companyCardId: vehicle.companyCardId,
    depot: vehicle.depot?.id,
    vehicleType: vehicle.vehicleType?.id,
    vehicleCategory: vehicleCategory.id,
    alertsAndPanicEnabled: vehicleSettings?.alertsAndPanicEnabled?.value,
    driverCardsEnabled: vehicleSettings?.driverCardsEnabled?.value,
    driverCoachEnabled: vehicleSettings?.driverCoachEnabled?.value,
    driverHoursEnabled: vehicleSettings?.driverHoursEnabled?.value,
    dseEnabled: vehicleSettings?.dseEnabled?.value,
    frequentPositioningEnabled: vehicleSettings?.frequentPositioningEnabled?.value,
    fuelSiteAdviceEnabled: vehicleSettings?.fuelSiteAdviceEnabled?.value,
    kpi2Enabled: vehicleSettings?.kpi2Enabled?.value,
    landmarksEnabled: vehicleSettings?.landmarksEnabled?.value,
    rtdsEnabled: vehicleSettings?.rtdsEnabled?.value,
    textMessagingEnabled: vehicleSettings?.textMessagingEnabled?.value,
    trailersEnabled: vehicleSettings?.trailersEnabled?.value,
    guidedNavigationEnabled: vehicleSettings?.guidedNavigationEnabled?.value,
    tireControlEnabled: vehicleSettings?.tireControlEnabled?.value,
    appStoreEnabled: vehicleSettings?.appStoreEnabled?.value,
    truckIdentificationEnabled: vehicleSettings?.truckIdentificationEnabled?.value,
    trailerControlServiceEnabled: vehicleSettings?.trailerControlServiceEnabled?.value,
    j1939DataServiceEnabled: vehicleSettings?.j1939DataServiceEnabled?.value,
    navigationEtaEnabled: vehicleSettings?.navigationEtaEnabled?.value,
    tachograph: vehicleSettings?.tachograph?.value.id ?? noneServiceValue,
    tachoProtocol: vehicleSettings?.tachoProtocol?.value.id ?? noneServiceValue,
    tachoGeneration: vehicleSettings?.tachoGeneration?.value.id ?? noneServiceValue,
    oneMinuteRuleEnabled: vehicleSettings?.oneMinuteRuleEnabled?.value,
    workflowType: vehicleSettings?.workflowType?.value.id ?? noneServiceValue,
    navigationType: vehicleSettings?.navigationType?.value.id ?? noneServiceValue,
    imageCapturingType: vehicleSettings?.imageCapturingType?.value.id ?? noneServiceValue,
    inhibitorServiceType: vehicleSettings?.inhibitorServiceType?.value.id ?? noneServiceValue,
    tellTaleServiceType: vehicleSettings?.tellTaleServiceType?.value.id ?? noneServiceValue,

    alertsAndPanicEnabledSettingsValueSource: vehicleSettings?.alertsAndPanicEnabled?.source,
    driverCardsEnabledSettingsValueSource: vehicleSettings?.driverCardsEnabled?.source,
    driverCoachEnabledSettingsValueSource: vehicleSettings?.driverCoachEnabled?.source,
    driverHoursEnabledSettingsValueSource: vehicleSettings?.driverHoursEnabled?.source,
    dseEnabledSettingsValueSource: vehicleSettings?.dseEnabled?.source,
    frequentPositioningEnabledSettingsValueSource: vehicleSettings?.frequentPositioningEnabled?.source,
    fuelSiteAdviceEnabledSettingsValueSource: vehicleSettings?.fuelSiteAdviceEnabled?.source,
    kpi2EnabledSettingsValueSource: vehicleSettings?.kpi2Enabled?.source,
    landmarksEnabledSettingsValueSource: vehicleSettings?.landmarksEnabled?.source,
    rtdsEnabledSettingsValueSource: vehicleSettings?.rtdsEnabled?.source,
    textMessagingEnabledSettingsValueSource: vehicleSettings?.textMessagingEnabled?.source,
    trailersEnabledSettingsValueSource: vehicleSettings?.trailersEnabled?.source,
    guidedNavigationEnabledSettingsValueSource: vehicleSettings?.guidedNavigationEnabled?.source,
    tireControlEnabledSettingsValueSource: vehicleSettings?.tireControlEnabled?.source,
    appStoreEnabledSettingsValueSource: vehicleSettings?.appStoreEnabled?.source,
    truckIdentificationEnabledSettingsValueSource: vehicleSettings?.truckIdentificationEnabled?.source,
    trailerControlServiceEnabledSettingsValueSource: vehicleSettings?.trailerControlServiceEnabled?.source,
    j1939DataServiceEnabledSettingsValueSource: vehicleSettings?.j1939DataServiceEnabled?.source,
    navigationEtaEnabledSettingsValueSource: vehicleSettings?.navigationEtaEnabled?.source,
    tachographSettingsValueSource: vehicleSettings?.tachograph?.source,
    tachoProtocolSettingsValueSource: vehicleSettings?.tachoProtocol?.source,
    tachoGenerationSettingsValueSource: vehicleSettings?.tachoGeneration?.source,
    oneMinuteRuleEnabledSettingsValueSource: vehicleSettings?.oneMinuteRuleEnabled?.source,
    workflowTypeSettingsValueSource: vehicleSettings?.workflowType?.source,
    navigationTypeSettingsValueSource: vehicleSettings?.navigationType?.source,
    inhibitorServiceTypeSettingsValueSource: vehicleSettings?.inhibitorServiceType?.source,
    tellTaleServiceTypeSettingsValueSource: vehicleSettings?.tellTaleServiceType?.source,
    imageCapturingTypeSettingsValueSource: vehicleSettings?.imageCapturingType?.source,
    adBlueTankSize: vehicleSettings.adBlueTankSize?.value,
    primaryFuelTankSize: vehicleSettings.primaryFuelTankSize?.value,
    primaryFuelTankType: vehicleSettings.primaryFuelTankType?.value,
    secondaryFuelTankSize: vehicleSettings.secondaryFuelTankSize?.value,
    secondaryFuelTankType: vehicleSettings.secondaryFuelTankType?.value,
    adBlueTankSizeValueSource: vehicleSettings.adBlueTankSize?.source,
    primaryFuelTankSizeValueSource: vehicleSettings.primaryFuelTankSize?.source,
    primaryFuelTankTypeValueSource: vehicleSettings.primaryFuelTankType?.source,
    secondaryFuelTankSizeValueSource: vehicleSettings.secondaryFuelTankSize?.source,
    secondaryFuelTankTypeValueSource: vehicleSettings.secondaryFuelTankType?.source,
});

const newVehicleMapper = (
    defaultVehicleSettings: ResolvedVehicleSettingsRead,
    defaultVechileCategory: Classification
): FieldValues => ({
    vin: '',
    vrn: '',
    unitId: '',
    alias: '',
    tag: '',
    device: null,
    vehicleCategory: defaultVechileCategory.id,
    alertsAndPanicEnabled: defaultVehicleSettings.alertsAndPanicEnabled,
    driverCardsEnabled: defaultVehicleSettings.driverCardsEnabled,
    driverCoachEnabled: defaultVehicleSettings.driverCoachEnabled,
    driverHoursEnabled: defaultVehicleSettings.driverHoursEnabled,
    dseEnabled: defaultVehicleSettings.dseEnabled,
    frequentPositioningEnabled: defaultVehicleSettings.frequentPositioningEnabled,
    fuelSiteAdviceEnabled: defaultVehicleSettings.fuelSiteAdviceEnabled,
    kpi2Enabled: defaultVehicleSettings.kpi2Enabled,
    landmarksEnabled: defaultVehicleSettings.landmarksEnabled,
    rtdsEnabled: defaultVehicleSettings.rtdsEnabled,
    textMessagingEnabled: defaultVehicleSettings.textMessagingEnabled,
    trailersEnabled: defaultVehicleSettings.trailersEnabled,
    guidedNavigationEnabled: defaultVehicleSettings.guidedNavigationEnabled,
    tireControlEnabled: defaultVehicleSettings.tireControlEnabled,
    appStoreEnabled: defaultVehicleSettings.appStoreEnabled,
    truckIdentificationEnabled: defaultVehicleSettings.truckIdentificationEnabled,
    trailerControlServiceEnabled: defaultVehicleSettings.trailerControlServiceEnabled,
    j1939DataServiceEnabled: defaultVehicleSettings.j1939DataServiceEnabled,
    navigationEtaEnabled: defaultVehicleSettings.navigationEtaEnabled,
    tachograph: defaultVehicleSettings.tachograph.id,
    tachoProtocol: defaultVehicleSettings.tachoProtocol.id,
    tachoGeneration: defaultVehicleSettings.tachoGeneration.id,
    oneMinuteRuleEnabled: defaultVehicleSettings.oneMinuteRuleEnabled,
    workflowType: defaultVehicleSettings.workflowType.id,
    navigationType: defaultVehicleSettings.navigationType.id,
    imageCapturingType: defaultVehicleSettings.imageCapturingType.id,
    inhibitorServiceType: defaultVehicleSettings.inhibitorServiceType.id,
    tellTaleServiceType: defaultVehicleSettings.tellTaleServiceType.id,

    alertsAndPanicEnabledSettingsValueSource: SettingsValueSource.Default,
    driverCardsEnabledSettingsValueSource: SettingsValueSource.Default,
    driverCoachEnabledSettingsValueSource: SettingsValueSource.Default,
    driverHoursEnabledSettingsValueSource: SettingsValueSource.Default,
    dseEnabledSettingsValueSource: SettingsValueSource.Default,
    frequentPositioningEnabledSettingsValueSource: SettingsValueSource.Default,
    fuelSiteAdviceEnabledSettingsValueSource: SettingsValueSource.Default,
    kpi2EnabledSettingsValueSource: SettingsValueSource.Default,
    landmarksEnabledSettingsValueSource: SettingsValueSource.Default,
    rtdsEnabledSettingsValueSource: SettingsValueSource.Default,
    textMessagingEnabledSettingsValueSource: SettingsValueSource.Default,
    trailersEnabledSettingsValueSource: SettingsValueSource.Default,
    guidedNavigationEnabledSettingsValueSource: SettingsValueSource.Default,
    tireControlEnabledSettingsValueSource: SettingsValueSource.Default,
    appStoreEnabledSettingsValueSource: SettingsValueSource.Default,
    truckIdentificationEnabledSettingsValueSource: SettingsValueSource.Default,
    trailerControlServiceEnabledSettingsValueSource: SettingsValueSource.Default,
    j1939DataServiceEnabledSettingsValueSource: SettingsValueSource.Default,
    navigationEtaEnabledSettingsValueSource: SettingsValueSource.Default,
    tachographSettingsValueSource: SettingsValueSource.Default,
    tachoProtocolSettingsValueSource: SettingsValueSource.Default,
    tachoGenerationSettingsValueSource: SettingsValueSource.Default,
    oneMinuteRuleEnabledSettingsValueSource: SettingsValueSource.Default,
    workflowTypeSettingsValueSource: SettingsValueSource.Default,
    navigationTypeSettingsValueSource: SettingsValueSource.Default,
    inhibitorServiceTypeSettingsValueSource: SettingsValueSource.Default,
    tellTaleServiceTypeSettingsValueSource: SettingsValueSource.Default,
    imageCapturingTypeSettingsValueSource: SettingsValueSource.Default,

    adBlueTankSize: defaultVehicleSettings.adBlueTankSize,
    primaryFuelTankSize: defaultVehicleSettings.primaryFuelTankSize,
    primaryFuelTankType: defaultVehicleSettings.primaryFuelTankType.id,
    secondaryFuelTankSize: defaultVehicleSettings.secondaryFuelTankSize,
    secondaryFuelTankType: defaultVehicleSettings.secondaryFuelTankType.id,

    adBlueTankSizeValueSource: SettingsValueSource.Default,
    primaryFuelTankSizeValueSource: SettingsValueSource.Default,
    primaryFuelTankTypeValueSource: SettingsValueSource.Default,
    secondaryFuelTankSizeValueSource: SettingsValueSource.Default,
    secondaryFuelTankTypeValueSource: SettingsValueSource.Default,
});

interface MapVehicleToFormArgs {
    vehicle?: ResolvedAdminVehicleRead;
    vehicleSettings?: ResolvedVehicleSettingsEdit;
    editorState: CrudActions;
    defaultVehicleCategory: VehicleCategory;
    defaultVehicleSettings: ResolvedVehicleSettingsRead;
}
export const mapVehicleToForm = (args: MapVehicleToFormArgs) => {
    const { vehicle, vehicleSettings, editorState, defaultVehicleSettings, defaultVehicleCategory } = args;

    switch (editorState) {
        case CRUD_ACTIONS.EDIT:
            if (!vehicle || !vehicleSettings) {
                return undefined;
            }
            return modifyVehicleMapper({ vehicle, vehicleSettings });
        case CRUD_ACTIONS.CLONE:
            if (!vehicle || !vehicleSettings) {
                return undefined;
            }
            return cloneVehicleMapper({ vehicle, vehicleSettings, vehicleCategory: defaultVehicleCategory });
        case CRUD_ACTIONS.CREATE:
            return newVehicleMapper(defaultVehicleSettings, defaultVehicleCategory);

        default:
            throw Error(`Provided editor state ${editorState} is not supported.`);
    }
};

export const settingsIntegerValueApiModel = (
    value: number = noneServiceValue,
    source: SettingsValueSource = SettingsValueSource.Asset
): SettingsIntegerValue => {
    return createApiModel(SettingsIntegerValue, {
        value,
        source,
    });
};

export const settingsStringValueApiModel = (
    value: string = '',
    source: SettingsValueSource = SettingsValueSource.Asset
): SettingsStringValue => {
    return createApiModel(SettingsStringValue, {
        value,
        source,
    });
};

export const settingsBooleanValueApiModel = (
    value: boolean = false,
    source: SettingsValueSource = SettingsValueSource.Asset
): SettingsBooleanValue => {
    return createApiModel(SettingsBooleanValue, {
        value,
        source,
    });
};
export const mapToAdminVehicleEdit = (field: FieldValues): AdminVehicleEdit => {
    return createApiModel(AdminVehicleEdit, {
        vin: field.vin,
        vrn: field.vrn,
        unitId: field.unitId,
        tag: field.tag,
        category: field.vehicleCategory,
        ...(!isNil(field.companyCardId) && { companyCardId: field.companyCardId }),
        ...(!isNil(field.depot) && { depot: field.depot }),
        ...(!isNil(field.vehicleType) && { vehicleTypeId: field.vehicleType }),
        settings: createApiModel(VehicleSettingsEdit, {
            alias: settingsStringValueApiModel(field.alias, SettingsValueSource.Asset),
            alertsAndPanicEnabled: settingsBooleanValueApiModel(
                field.alertsAndPanicEnabled,
                field.alertsAndPanicEnabledSettingsValueSource
            ),
            driverCardsEnabled: settingsBooleanValueApiModel(
                field.driverCardsEnabled,
                field.driverCardsEnabledSettingsValueSource
            ),
            driverCoachEnabled: settingsBooleanValueApiModel(
                field.driverCoachEnabled,
                field.driverCoachEnabledSettingsValueSource
            ),
            driverHoursEnabled: settingsBooleanValueApiModel(
                field.driverHoursEnabled,
                field.driverHoursEnabledSettingsValueSource
            ),
            dseEnabled: settingsBooleanValueApiModel(field.dseEnabled, field.dseEnabledSettingsValueSource),
            frequentPositioningEnabled: settingsBooleanValueApiModel(
                field.frequentPositioningEnabled,
                field.frequentPositioningEnabledSettingsValueSource
            ),
            fuelSiteAdviceEnabled: settingsBooleanValueApiModel(
                field.fuelSiteAdviceEnabled,
                field.fuelSiteAdviceEnabledSettingsValueSource
            ),
            kpi2Enabled: settingsBooleanValueApiModel(field.kpi2Enabled, field.kpi2EnabledSettingsValueSource),
            landmarksEnabled: settingsBooleanValueApiModel(
                field.landmarksEnabled,
                field.landmarksEnabledSettingsValueSource
            ),
            rtdsEnabled: settingsBooleanValueApiModel(field.rtdsEnabled, field.rtdsEnabledSettingsValueSource),
            textMessagingEnabled: settingsBooleanValueApiModel(
                field.textMessagingEnabled,
                field.textMessagingEnabledSettingsValueSource
            ),
            trailersEnabled: settingsBooleanValueApiModel(
                field.trailersEnabled,
                field.trailersEnabledSettingsValueSource
            ),
            guidedNavigationEnabled: settingsBooleanValueApiModel(
                field.guidedNavigationEnabled,
                field.guidedNavigationEnabledSettingsValueSource
            ),
            tireControlEnabled: settingsBooleanValueApiModel(
                field.tireControlEnabled,
                field.tireControlEnabledSettingsValueSource
            ),
            appStoreEnabled: settingsBooleanValueApiModel(
                field.appStoreEnabled,
                field.appStoreEnabledSettingsValueSource
            ),
            truckIdentificationEnabled: settingsBooleanValueApiModel(
                field.truckIdentificationEnabled,
                field.truckIdentificationEnabledSettingsValueSource
            ),
            trailerControlServiceEnabled: settingsBooleanValueApiModel(
                field.trailerControlServiceEnabled,
                field.trailerControlServiceEnabledSettingsValueSource
            ),
            j1939DataServiceEnabled: settingsBooleanValueApiModel(
                field.j1939DataServiceEnabled,
                field.j1939DataServiceEnabledSettingsValueSource
            ),
            navigationEtaEnabled: settingsBooleanValueApiModel(
                field.navigationEtaEnabled,
                field.navigationEtaEnabledSettingsValueSource
            ),
            tachograph: settingsIntegerValueApiModel(field.tachograph, field.tachographSettingsValueSource),
            tachoProtocol: settingsIntegerValueApiModel(field.tachoProtocol, field.tachoProtocolSettingsValueSource),
            tachoGeneration: settingsIntegerValueApiModel(
                field.tachoGeneration,
                field.tachoGenerationSettingsValueSource
            ),
            oneMinuteRuleEnabled: settingsBooleanValueApiModel(
                field.oneMinuteRuleEnabled,
                field.oneMinuteRuleEnabledSettingsValueSource
            ),
            workflowType: settingsIntegerValueApiModel(field.workflowType, field.workflowTypeSettingsValueSource),
            navigationType: settingsIntegerValueApiModel(field.navigationType, field.navigationTypeSettingsValueSource),
            inhibitorServiceType: settingsIntegerValueApiModel(
                field.inhibitorServiceType,
                field.inhibitorServiceTypeSettingsValueSource
            ),
            tellTaleServiceType: settingsIntegerValueApiModel(
                field.tellTaleServiceType,
                field.tellTaleServiceTypeSettingsValueSource
            ),
            imageCapturingType: settingsIntegerValueApiModel(
                field.imageCapturingType,
                field.imageCapturingTypeSettingsValueSource
            ),
            adBlueTankSize: settingsIntegerValueApiModel(field.adBlueTankSize, field.adBlueTankSizeValueSource),
            primaryFuelTankSize: settingsIntegerValueApiModel(
                field.primaryFuelTankSize,
                field.primaryFuelTankSizeValueSource
            ),
            primaryFuelTankType: settingsIntegerValueApiModel(
                field.primaryFuelTankType,
                field.primaryFuelTankTypeValueSource
            ),
            secondaryFuelTankSize: settingsIntegerValueApiModel(
                field.secondaryFuelTankSize,
                field.secondaryFuelTankSizeValueSource
            ),
            secondaryFuelTankType: settingsIntegerValueApiModel(
                field.secondaryFuelTankType,
                field.secondaryFuelTankTypeValueSource
            ),
        }),
        deviceId: field.device ?? undefined,
    });
};
export const mapClassificationToClassificationAbstraction = (
    values: NumericDictionary<Classification>
): ClassificationAbstraction[] => {
    return Object.values(values).map((it: Classification) => {
        return { id: it.id, key: it.key, displayName: it.displayName ?? '' };
    });
};

export const mapVehicleTypeToClassificationAbstraction = (
    values: NumericDictionary<VehicleType>
): ClassificationAbstraction[] => {
    return Object.values(values).map((it: VehicleType) => {
        return { id: it.id, key: it.id.toString(), displayName: it.name };
    });
};

export const mapVehicleCategoryToClassificationAbstraction = (
    t: SingleTFunction,
    values: NumericDictionary<VehicleCategory>
): ClassificationAbstraction[] => {
    return Object.values(values).map((it: VehicleCategory) => {
        return { id: it.id, key: it.id.toString(), displayName: formatVehicleCategory(t, it) };
    });
};

export const mapCompanyCardToClassificationAbstraction = (
    values: NumericDictionary<CompanyCard>
): ClassificationAbstraction[] => {
    return Object.values(values).map((it: CompanyCard) => {
        return {
            id: it.number as string,
            key: it.number,
            displayName: it.description
                ? `${it.description} [${it.number.padEnd(16, '*')}]`
                : it.number.padEnd(16, '*'),
        };
    });
};

export const mapDevicesToMulticolumnClassificationAbstraction = ({
    devices,
    vehicles,
    vehicleDisplayPreferencesFormat,
    devicesTypes,
}: MapMulticolumnClassificationProps): ClassificationAbstraction[] => {
    const deviceFilteredByVehicle = devices
        .map(({ id, ...rest }) => ({ ...devicesTypes[rest.type], id, ...rest }))
        .filter(({ type, compatibleAssetTypes = [] }) => {
            if (type === 99) {
                return true;
            }

            return compatibleAssetTypes?.includes(AssetType.Vehicle);
        });

    return deviceFilteredByVehicle.map((it) => {
        const vehicle = vehicles.find((ve) => ve.device?.msisdn === it.msisdn);

        const vehicleDisplayName = !isUndefined(vehicle)
            ? formatVehicleName(vehicle, vehicleDisplayPreferencesFormat)
            : '';

        return {
            id: it.id,
            rowValues: [it.msisdn, it.serialNumber, vehicleDisplayName],
            key: it.id.toString(),
            displayName: it.msisdn ?? it.serialNumber ?? vehicleDisplayName,
        };
    });
};

export const defaultSettingsMapper = (vehicleSettings: ResolvedVehicleSettingsRead): DefaultSettingsValues => ({
    alertsAndPanicEnabled: vehicleSettings.alertsAndPanicEnabled,
    driverCardsEnabled: vehicleSettings.driverCardsEnabled,
    driverCoachEnabled: vehicleSettings.driverCoachEnabled,
    driverHoursEnabled: vehicleSettings.driverHoursEnabled,
    dseEnabled: vehicleSettings.dseEnabled,
    frequentPositioningEnabled: vehicleSettings.frequentPositioningEnabled,
    fuelSiteAdviceEnabled: vehicleSettings.fuelSiteAdviceEnabled,
    kpi2Enabled: vehicleSettings.kpi2Enabled,
    landmarksEnabled: vehicleSettings.landmarksEnabled,
    rtdsEnabled: vehicleSettings.rtdsEnabled,
    textMessagingEnabled: vehicleSettings.textMessagingEnabled,
    trailersEnabled: vehicleSettings.trailersEnabled,
    guidedNavigationEnabled: vehicleSettings.guidedNavigationEnabled,
    tireControlEnabled: vehicleSettings.tireControlEnabled,
    appStoreEnabled: vehicleSettings.appStoreEnabled,
    truckIdentificationEnabled: vehicleSettings.truckIdentificationEnabled,
    trailerControlServiceEnabled: vehicleSettings.trailerControlServiceEnabled,
    j1939DataServiceEnabled: vehicleSettings.j1939DataServiceEnabled,
    navigationEtaEnabled: vehicleSettings.navigationEtaEnabled,
    tachograph: vehicleSettings.tachograph.id,
    tachoProtocol: vehicleSettings.tachoProtocol.id,
    tachoGeneration: vehicleSettings.tachoGeneration.id,
    oneMinuteRuleEnabled: vehicleSettings.oneMinuteRuleEnabled,
    workflowType: vehicleSettings.workflowType.id,
    navigationType: vehicleSettings.navigationType.id,
    inhibitorServiceType: vehicleSettings.inhibitorServiceType.id,
    tellTaleServiceType: vehicleSettings.tellTaleServiceType.id,
    imageCapturingType: vehicleSettings.imageCapturingType.id,
    primaryFuelTankSize: vehicleSettings.primaryFuelTankSize,
    secondaryFuelTankSize: vehicleSettings.secondaryFuelTankSize,
    adBlueTankSize: vehicleSettings.adBlueTankSize,
    primaryFuelTankType: vehicleSettings.primaryFuelTankType.id,
    secondaryFuelTankType: vehicleSettings.secondaryFuelTankType.id,
});
