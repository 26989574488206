import type { SingleTFunction } from '~/components/LanguageSelector';
import type { ResolvedMessageBody } from '~/services/ApiClient';
import {
    FieldWorkflowFormElement,
    TextMessageBody,
    UnresolvedWorkflowMessageBody,
    WorkflowMessageBody,
} from '~/services/ApiClient';

import { formatWorkflowFieldValue } from './formatWorkflowFieldValue';

export const formatMessagePrimaryAttribute = (t: SingleTFunction, body: ResolvedMessageBody): string => {
    if (body.value instanceof WorkflowMessageBody) {
        const primaryFieldValue = body.value.fieldValues.find((field) => {
            return field.fieldId === body.workflowFormDefinition?.primaryFieldId;
        });

        const primaryField = body.workflowFormDefinition?.elements.find((el: FieldWorkflowFormElement) => {
            return el.id === body.workflowFormDefinition?.primaryFieldId;
        });

        const primaryWorkflowField =
            primaryField instanceof FieldWorkflowFormElement &&
            formatWorkflowFieldValue(primaryField, t, primaryFieldValue);

        return (
            primaryWorkflowField ||
            body.workflowFormDefinition?.description ||
            body.workflowFormDefinition?.name ||
            `[${body.value.formDefinitionId}]`
        );
    }
    if (body.value instanceof UnresolvedWorkflowMessageBody) {
        return body.value.preview;
    }
    if (body.value instanceof TextMessageBody) {
        return body.value.message;
    }

    throw new Error('unknown message body type');
};
