import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SummarySectionContentClassKey = 'calendarIcon' | 'summaryTimeDetails' | 'summaryContentContainer' | 'flex';

export const SummarySectionContentStyleRules: StyleRulesCallback<Theme, {}, SummarySectionContentClassKey> = (
    theme: Theme
) =>
    createStyles({
        calendarIcon: {
            fontSize: theme.typography.pxToRem(14),
            marginRight: theme.spacing(0.5),
        },
        summaryTimeDetails: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(),
        },
        summaryContentContainer: {
            margin: theme.spacing(0, 2, 1),
        },
        flex: {
            display: 'flex',
        },
    });
