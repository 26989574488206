import { createAction } from 'redux-actions';

import {
    ApiClient,
    ApiException,
    QueryTripsRequestApi,
    ServerResultStatus,
    createApiModel,
} from '~/services/ApiClient';

import { ACTION_TYPE_KEYS, ACTION_TYPE_PREFIXES } from './actionTypes';
import type { QueryTripsByAssetQuery } from './models';

const queryTripList = createAction(ACTION_TYPE_PREFIXES.LIST, async (query: QueryTripsByAssetQuery) => {
    try {
        const parsedQuery = createApiModel(QueryTripsRequestApi, query as QueryTripsRequestApi);
        const { items } = await ApiClient.queryTripsForPeriod(parsedQuery);
        return items;
    } catch (error) {
        if (ApiException.isApiException(error) && error.status === 400) {
            throw ServerResultStatus.BADREQUEST;
        }

        throw ServerResultStatus.SERVERERROR;
    }
});

const resetState = createAction(ACTION_TYPE_KEYS.RESET);

export { queryTripList, resetState };
