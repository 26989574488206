import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';
import { getFuelLevelUnitSystemUtils } from '~/services/Formatters';

export interface FuelLevelFormatterProps {
    t: SingleTFunction;
    unitSystem: DisplayUserPreferencesUnitSystem;
    value?: number;
}

const FuelLevelFormatter: React.FunctionComponent<FuelLevelFormatterProps> = ({ t, unitSystem, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{getFuelLevelUnitSystemUtils(t, unitSystem).formatter(value)}</>;
};
FuelLevelFormatter.displayName = 'FuelLevelFormatter';

export { FuelLevelFormatter };
