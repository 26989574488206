import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { ArrowRightIcon } from '~/components/Icons';

import type { SettingItemHeaderProps } from '../SettingItemHeader';
import { SettingItemHeader } from '../SettingItemHeader';

import type { SummarySettingItemClassKeys } from './styles';

export interface SummarySettingItemProps extends Omit<SettingItemHeaderProps, 'action'> {
    onClick?: () => void;
    actionDataId: string;
}

export interface SummarySettingItemInnerProps
    extends Omit<SummarySettingItemProps, 'classes'>,
        WithStyles<SummarySettingItemClassKeys> {}

export const SummarySettingItemComponent: React.FC<SummarySettingItemInnerProps> = ({
    classes,
    onClick,
    actionDataId,
    ...restProps
}) => {
    const action = <ArrowRightIcon className={classes.action} onClick={onClick} data-id={actionDataId} />;
    return <SettingItemHeader {...restProps} action={action} />;
};
