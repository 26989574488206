import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LabeledIndicatorGroupContainerClassKey = 'root';

export const LabeledIndicatorGroupContainerStyleRules: StyleRulesCallback<
    Theme,
    {},
    LabeledIndicatorGroupContainerClassKey
> = (theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(0.5),
            margin: theme.spacing(2, 1, 1, 1),
        },
    });
