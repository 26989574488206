import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LatestMessagesSectionContentClassKey =
    | 'root'
    | 'content'
    | 'openConversationButton'
    | 'leftButtonAdornment'
    | 'failedToLoadButton'
    | 'failedToLoad'
    | 'italicText'
    | 'paperBackground'
    | 'centerContents';

export const LatestMessagesSectionContentStyleRules: StyleRulesCallback<
    Theme,
    {},
    LatestMessagesSectionContentClassKey
> = (theme: Theme) =>
    createStyles({
        root: { backgroundColor: theme.palette.background.paper },
        content: {
            display: 'flex',
            flexDirection: 'column',
            height: 135,
            backgroundColor: theme.palette.background.default,
            margin: theme.spacing(0, 2),
        },
        leftButtonAdornment: {
            marginRight: theme.spacing(),
        },
        failedToLoadButton: {
            marginTop: theme.spacing(),
            alignSelf: 'center',
        },
        openConversationButton: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        failedToLoad: {
            display: 'flex',
            flexDirection: 'column',
        },
        italicText: {
            fontStyle: 'italic',
        },
        paperBackground: {
            backgroundColor: theme.palette.background.paper,
        },
        centerContents: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });
