import type { NumericDictionary } from '~/libs/utility';
import { keyBy, mapValues } from '~/libs/utility';
import type { AssetReference } from '~/services/ApiClient';

import type { AssetReferenceHash } from '../models';

/// return new hash of items in source, which are not defined in values
export const assetReferenceHashDifference = <T>(
    source: AssetReferenceHash<T>,
    values: AssetReferenceHash<T>
): AssetReferenceHash<T> => {
    return mapValues(source, (assetTypeDict, assetType) => {
        return (
            assetTypeDict &&
            keyBy(
                Object.keys(assetTypeDict)
                    .filter((id) => !values[assetType]?.[id])
                    .map((id) => assetTypeDict[id]),
                'id'
            )
        );
    });
};

/// merge multiple hashes into a new one
export const assetReferenceHashMerge = <T>(...values: AssetReferenceHash<T>[]): AssetReferenceHash<T> => {
    return values.reduce(
        (acc, v) => ({
            ...acc,
            ...mapValues(v, (value, key) => ({ ...value, ...acc[key] })),
        }),
        {}
    );
};

export const assetReferenceHashFlatten = <T>(hash: AssetReferenceHash<T>): T[] => {
    return Object.values(hash)
        .filter(Boolean)
        .reduce<T[]>((acc, dict: NumericDictionary<T>) => {
            acc.push(...Object.values(dict));
            return acc;
        }, []);
};

// returns assetReferences which are not included in hash
export const assetReferenceDifference = <T>(
    assetReferences: AssetReference[],
    hash: AssetReferenceHash<T>
): AssetReference[] => {
    return assetReferences.filter((assetReference) => !hash[assetReference?.type]?.[assetReference.id]);
};
