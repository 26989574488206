import memoize from 'lodash/memoize';

import { debounce, without } from '~/libs/utility';
import type { Disposable } from '~/listeners';
import {
    ApiClient,
    Preferences,
    PreferencesScope,
    SavePreferencesRequest,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import type {
    LocalKeyPreferences,
    PreferencesDictionary,
    SharedKeyPreferences,
    StatusChangedHandler,
} from './contracts';
import { KeyStatus, Status } from './contracts';
import type { RequestSaveKeyPreferencesArgs } from './models';

export class SaveUserPreferencesService {
    private preferences: PreferencesDictionary<LocalKeyPreferences> = {};
    private status: Status = Status.SAVED;
    private statusChangedHandlers: StatusChangedHandler[] = [];

    private debouncedSaveKeyPreferences = memoize((key: string) => {
        return debounce(() => {
            this.saveKeyPreferences(key, true);
        }, 5000);
    });

    public getStatus(): Status {
        return this.status;
    }

    public onStatusChanged(fn: StatusChangedHandler): Disposable {
        this.statusChangedHandlers.push(fn);

        return {
            dispose: () => {
                this.statusChangedHandlers = without(this.statusChangedHandlers, fn);
            },
        };
    }

    public update(preferences: PreferencesDictionary<SharedKeyPreferences>): void {
        Object.keys(preferences).forEach((key) => {
            this.updateKeyPreferences(key, preferences[key]);
        });
    }

    public async saveAll(autoRetry: boolean = true): Promise<void> {
        this.cancelPreferencesDebouncedSave();

        const savePromises = Object.keys(this.preferences)
            .filter(this.keyPreferencesShouldBeSaved)
            .map((key) => this.saveKeyPreferences(key, autoRetry));

        await Promise.all(savePromises);
    }

    public async saveAndClearAll(): Promise<void> {
        await this.saveAll(false);

        this.preferences = {};
        this.setStatus(Status.SAVED);
    }

    private updateKeyPreferences(key: string, keyPreferences: SharedKeyPreferences): void {
        if (this.preferences[key] && this.preferences[key].data === keyPreferences.data) {
            return;
        }

        if (this.preferences[key] && !keyPreferences.toBeSaved) {
            this.debouncedSaveKeyPreferences(key).cancel();
        }

        this.preferences[key] = {
            data: keyPreferences.data,
            status: keyPreferences.toBeSaved ? KeyStatus.TOBESAVED : KeyStatus.SAVED,
        };

        this.updateStatus();

        if (keyPreferences.toBeSaved) {
            this.debouncedSaveKeyPreferences(key)();
        }
    }

    private updateStatus(): void {
        const recalculatedStatus = this.getRecalculatedStatus();
        this.setStatus(recalculatedStatus);
    }

    private getRecalculatedStatus(): Status {
        if (this.someKeyPreferencesHasStatusEqualWith(KeyStatus.SAVING)) {
            return Status.SAVING;
        }

        if (this.someKeyPreferencesHasStatusEqualWith(KeyStatus.FAILEDTOSAVE)) {
            return Status.FAILEDTOSAVE;
        }

        return Status.SAVED;
    }

    private someKeyPreferencesHasStatusEqualWith(status: KeyStatus): boolean {
        return Object.keys(this.preferences).some((key: string) => this.preferences[key].status === status);
    }

    private setStatus(status: Status): void {
        if (this.status === status) {
            return;
        }

        const prevStatus = this.status;
        this.status = status;
        this.statusChangedHandlers.forEach((handler) => {
            handler(status, prevStatus);
        });
    }

    private cancelPreferencesDebouncedSave(): void {
        Object.keys(this.preferences).forEach((key: string) => {
            this.debouncedSaveKeyPreferences(key).cancel();
        });
    }

    private keyPreferencesShouldBeSaved = (key: string): boolean => {
        return (
            this.preferences[key] &&
            [KeyStatus.TOBESAVED, KeyStatus.FAILEDTOSAVE].includes(this.preferences[key].status)
        );
    };

    private async saveKeyPreferences(key: string, autoRetry: boolean): Promise<void> {
        if (!this.preferences[key]) {
            return;
        }

        this.updateKeyPreferencesStatus(this.preferences[key], KeyStatus.SAVING);

        let status: KeyStatus;
        try {
            await this.requestSaveKeyPreferences({ key, value: this.preferences[key].data, autoRetry });
            status = KeyStatus.SAVED;
        } catch {
            status = KeyStatus.FAILEDTOSAVE;
        }

        /**
         * Update preferences status for specified key only
         * if its status is equal with SAVING, to avoid changing
         * the status when it was set by update.
         */
        if (this.preferences[key] && this.preferences[key].status === KeyStatus.SAVING) {
            this.updateKeyPreferencesStatus(this.preferences[key], status);
        }
    }

    private updateKeyPreferencesStatus(keyPreferences: LocalKeyPreferences, status: KeyStatus): void {
        // eslint-disable-next-line no-param-reassign
        keyPreferences.status = status;
        this.updateStatus();
    }

    private requestSaveKeyPreferences({ key, value, autoRetry }: RequestSaveKeyPreferencesArgs): Promise<void> {
        const requestSaveKeyPreferences = () => {
            return ApiClient.savePreferences(
                key,
                createApiModel(SavePreferencesRequest, {
                    item: createApiModel(Preferences, { scope: PreferencesScope.User, value }),
                })
            );
        };

        return autoRetry ? retryableRequest(requestSaveKeyPreferences) : requestSaveKeyPreferences();
    }
}
