import { createAction } from 'redux-actions';

import { ApiClient } from '~/services/ApiClient';

import { ACTIONS, ACTIONS_KEYS } from './actionTypes';
import type { UpdateAdministrationGeozonesArgs } from './models';

const getAdministrationGeozones = createAction(ACTIONS_KEYS.LIST, () => ApiClient.getAdminGeozones());

const updateAdministrationGeozones = createAction<UpdateAdministrationGeozonesArgs>(ACTIONS.UPDATE_GEOZONES);

const getGeozoneAssignments = createAction(ACTIONS_KEYS.GET_GEOZONES_ASSIGNMENTS, (geozoneId: string) =>
    ApiClient.getAssignmentsByGeozone(geozoneId)
);

export { getAdministrationGeozones, updateAdministrationGeozones, getGeozoneAssignments };
