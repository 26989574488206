import type { FuelValue, UnitSystem } from '../models';
import { convertVolumeLiquid } from '../volume';
import { convertWeight } from '../weight';

import { CNG_CLASSIFICATION_VALUE } from './const';

interface ConvertFuelArgs {
    value: FuelValue;
    unitSystem: UnitSystem;
    precision?: number;
}

const convertFuel = (args: ConvertFuelArgs) => {
    const { value, ...restArgs } = args;
    switch (value.type.key) {
        case CNG_CLASSIFICATION_VALUE:
            return convertWeight({ ...restArgs, value: value.amount });
        default:
            return convertVolumeLiquid({ ...restArgs, value: value.amount });
    }
};

export { convertFuel };
export type { ConvertFuelArgs };
