import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Icon, ListItem, ListItemIcon, ListItemText, Link as MuiLink, Typography } from '@mui/material';
import * as React from 'react';

import { CustomNavLink } from '~/components/CustomNavLink';
import { LaunchIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';

import type { MenuItem } from '../../../../../../models';

import { useStyles } from './styles';

const navigationLinkOnClose = (item: MenuItem, onClose: () => void) => () => {
    if (item.onClick) {
        item.onClick();
    }
    onClose();
};

const NavigationLink = (item: MenuItem) =>
    React.forwardRef((props: React.HTMLAttributes<HTMLAnchorElement>, ref: React.RefObject<HTMLAnchorElement>) => {
        if (item.to) {
            return <CustomNavLink to={item.to} {...props} innerRef={ref} />;
        } else {
            return (
                <MuiLink
                    {...props}
                    href={item.href}
                    ref={ref}
                    color="inherit"
                    target={item.openInNewWindow ? '_blank' : undefined}
                    rel={item.openInNewWindow ? 'noopener' : undefined}
                    underline="hover"
                />
            );
        }
    });

export interface OverlayMenuItemProps {
    menuItem: MenuItem;
    parentMenuItem?: MenuItem;
    onClose: () => void;
    dataId: string;
}

export interface OverlayMenuItemInnerProps extends OverlayMenuItemProps, InjectedTranslationProps {}

export const OverlayMenuItemComponent: React.FunctionComponent<OverlayMenuItemInnerProps> = (
    props: OverlayMenuItemInnerProps
) => {
    const classes = useStyles(props);

    const [open, setOpen] = React.useState(false);

    const handleOnClick = React.useCallback(() => {
        setOpen(!open);
    }, [open]);

    const renderNestedMenu = React.useCallback(
        (item: MenuItem) => {
            if (isUndefined(item.nestedItems)) {
                return undefined;
            }

            return (
                <Collapse in={open} timeout="auto">
                    {item.nestedItems.map((nestedItem, _index) => {
                        const nestedDataId = `${props.dataId}:${nestedItem.key}`;
                        return (
                            <OverlayMenuItemComponent
                                menuItem={nestedItem}
                                parentMenuItem={item}
                                onClose={props.onClose}
                                dataId={nestedDataId}
                                t={props.t}
                                i18n={props.i18n}
                                key={nestedItem.key}
                            />
                        );
                    })}
                </Collapse>
            );
        },
        [open, props.dataId, props.i18n, props.onClose, props.t]
    );

    return (
        <>
            <ListItem
                button
                component={NavigationLink(props.menuItem)}
                onClick={
                    isUndefined(props.menuItem.nestedItems)
                        ? navigationLinkOnClose(props.menuItem, props.onClose)
                        : handleOnClick
                }
                data-id={props.dataId}
            >
                <ListItemIcon className={classes.listIcon}>
                    <Icon className={classes.icon}>{props.menuItem.icon}</Icon>
                </ListItemIcon>
                <ListItemText className={props.parentMenuItem ? classes.text : undefined}>
                    <Typography>{props.menuItem.title}</Typography>
                </ListItemText>
                {isUndefined(props.menuItem.nestedItems) ? undefined : open ? <ExpandLess /> : <ExpandMore />}
                {props.menuItem.hasExternalLinkIcon && (
                    <ListItemIcon className={classes.externalListIcon}>
                        <LaunchIcon />
                    </ListItemIcon>
                )}
            </ListItem>
            {renderNestedMenu(props.menuItem)}
        </>
    );
};
