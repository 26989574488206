import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ContainerTemperaturesSectionInnerProps, ContainerTemperaturesSectionOutterProps } from './component';
import { ContainerTemperaturesSectionComponent } from './component';
import { ContainerTemperaturesSectionStyleRules } from './styles';

export const ContainerTemperaturesSection = compose<
    ContainerTemperaturesSectionInnerProps,
    ContainerTemperaturesSectionOutterProps
>(
    setDisplayName('ContainerTemperaturesSection'),
    withStyles(ContainerTemperaturesSectionStyleRules),
    withTranslation()
)(ContainerTemperaturesSectionComponent);
