import { defaultProps } from 'react-recompose';

import { AddressFormatter } from '~/components/Formatters';
import type { Address } from '~/services/ApiClient';
import type { AddressFormatOptions } from '~/services/Formatters';
import { exportFormatterFactory, formatAddress, valueTextFormatterFactory } from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { compareFactory, stringComparer } from '~/services/Sorting';

import type { AddressFormatterProps } from '../Formatters/Address';

import type { CreateColumnOptions, GridColumnDefinition } from './models';

export interface CreateAddressColumnOptions extends CreateColumnOptions {
    formatOptions?: AddressFormatOptions;
}

export const createAddressColumn = <T>(
    name: string,
    title: string,
    groupTitle: string,
    getCellValue: (entry: T) => Address | undefined,
    { formatOptions, ...restOptions }: CreateAddressColumnOptions
): GridColumnDefinition<T> => {
    const defaultFormatterProps: Partial<AddressFormatterProps> = { options: formatOptions };
    const ValueFormatterComponent = defaultProps(defaultFormatterProps)(AddressFormatter);
    return {
        ...restOptions,
        dataType: 'string',
        name,
        title,
        groupTitle,
        getCellValue,
        exportValueFormatter: exportFormatterFactory((v: Address) => formatAddress(v, formatOptions)),
        compare: compareFactory((v: Address) => formatAddress(v, formatOptions), stringComparer),
        valueTextFormatter: valueTextFormatterFactory((v: Address) => formatAddress(v, formatOptions)),
        groupingCriteria: groupingCriteriaFactory((v: Address) => formatAddress(v, formatOptions)),
        valueFormatterComponent: ValueFormatterComponent,
    };
};
