import { Avatar, Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';
import Moment from 'react-moment';

import { DateTimeFormatter, DriverActivityIconFormatter, DriverNameFormatter } from '~/components/Formatters';
import { CoDriverIcon, DriverIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DriverActivityType,
    DriverName,
    DriverSubActivityType,
} from '~/services/ApiClient';
import { formatDriverActivity } from '~/services/Formatters';

import type { StaffHeaderInfoClassKey } from './styles';

export interface StaffHeaderInfoProps {
    driver?: DriverName;
    coDriver?: DriverName;
    driverActivity?: DriverActivityType;
    coDriverActivity?: DriverActivityType;
    driverSubActivity?: DriverSubActivityType;
    coDriverSubActivity?: DriverSubActivityType;
    driverActivityStartDateTime?: Date;
    coDriverActivityStartDateTime?: Date;
    driverDisplayFormat: DisplayUserPreferencesDriverDisplayFormat;
}

export interface StaffHeaderInfoInnerProps
    extends StaffHeaderInfoProps,
        InjectedTranslationProps,
        WithStyles<StaffHeaderInfoClassKey> {}

export const StaffHeaderInfoComponent: React.FC<StaffHeaderInfoInnerProps> = ({
    driver,
    coDriver,
    driverActivity,
    coDriverActivity,
    driverSubActivity,
    coDriverSubActivity,
    driverActivityStartDateTime,
    coDriverActivityStartDateTime,
    driverDisplayFormat,
    classes,
    t,
}) => {
    const formattedDriverName = <DriverNameFormatter value={driver} displayNameFormat={driverDisplayFormat} />;
    const formattedCoDriverName = <DriverNameFormatter value={coDriver} displayNameFormat={driverDisplayFormat} />;

    const driverActivityDurationElement = driverActivityStartDateTime && (
        <span>
            <Moment fromNow>{driverActivityStartDateTime.toUTCString()}</Moment>
        </span>
    );
    const coDriverActivityDurationElement = coDriverActivityStartDateTime && (
        <span>
            <Moment fromNow>{coDriverActivityStartDateTime.toUTCString()}</Moment>
        </span>
    );

    const driverActivityElement = driverActivity && <span>{formatDriverActivity(t, driverActivity)}</span>;
    const driverSubActivityElement = driverSubActivity && <span>{driverSubActivity.name}</span>;
    const driverActivityStartTimestamp = driverActivityStartDateTime && (
        <DateTimeFormatter value={driverActivityStartDateTime} />
    );

    const coDriverActivityElement = coDriverActivity && <span>{formatDriverActivity(t, coDriverActivity)}</span>;
    const coDriverSubActivityElement = coDriverSubActivity && <span>{coDriverSubActivity.name}</span>;
    const coDriverActivityStartTimestamp = coDriverActivityStartDateTime && (
        <DateTimeFormatter value={coDriverActivityStartDateTime} />
    );

    const driverAvatarTooltipTitle = driver && (
        <div>
            <div>{formattedDriverName}</div>
            <div>
                {driverActivityElement}
                {driverActivity && driverSubActivity && `, `}
                {driverSubActivityElement}
            </div>
            <div>{driverActivityDurationElement}</div>
            <div>{driverActivityStartTimestamp}</div>
        </div>
    );
    const coDriverAvatarTooltipTitle = coDriver && (
        <div>
            <div>{formattedCoDriverName}</div>
            <div>
                {coDriverActivityElement}
                {coDriverActivity && coDriverSubActivity && `, `}
                {coDriverSubActivityElement}
            </div>
            <div>{coDriverActivityDurationElement}</div>
            <div>{coDriverActivityStartTimestamp}</div>
        </div>
    );

    const driverAvatar = (
        <Avatar className={classNames(classes.driverAvatar, { [classes.disabled]: !driver })} data-id="driver-avatar">
            <DriverIcon className={classes.driverIcon} />
        </Avatar>
    );
    const driverActivityBadge = driverActivity && (
        <Avatar
            className={classNames(classes.activityAvatar, classes[`activity${driverActivity.key}`])}
            data-id="driver-activity-badge"
        >
            <DriverActivityIconFormatter value={driverActivity.key} iconProps={{ fontSize: 'inherit' }} />
        </Avatar>
    );
    const coDriverActivityBadge = coDriverActivity && (
        <Avatar
            className={classNames(classes.activityAvatar, classes[`activity${coDriverActivity.key}`])}
            data-id="co-driver-activity-badge"
        >
            <DriverActivityIconFormatter value={coDriverActivity.key} iconProps={{ fontSize: 'inherit' }} />
        </Avatar>
    );
    const driverLoggedInElement = driver && (
        <Tooltip title={driverAvatarTooltipTitle ?? ''}>
            <div className={classes.flex}>
                {driverAvatar}
                {driverActivityBadge}
            </div>
        </Tooltip>
    );
    const driverNotLoggedInElement = <Tooltip title={t('not-logged-in')}>{driverAvatar}</Tooltip>;

    const driverElement = (
        <div className={classes.driver}>{driver ? driverLoggedInElement : driverNotLoggedInElement}</div>
    );
    const coDriverElement = coDriver && (
        <Tooltip title={coDriverAvatarTooltipTitle ?? ''}>
            <div className={classes.coDriver}>
                <Avatar className={classes.driverAvatar} data-id="co-driver-avatar">
                    <CoDriverIcon className={classes.driverIcon} />
                </Avatar>
                {coDriverActivityBadge}
            </div>
        </Tooltip>
    );

    return (
        <div className={classes.flex}>
            {driverElement}
            {coDriverElement}
        </div>
    );
};
