import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { OptionsInputInnerProps, OptionsInputProps } from './component';
import { OptionsInputComponent } from './component';
import { OptionsInputStyleRules } from './styles';

export const OptionsInput = compose<OptionsInputInnerProps, OptionsInputProps>(
    setDisplayName('OptionsInput'),
    withStyles(OptionsInputStyleRules),
    withTranslation()
)(OptionsInputComponent);
