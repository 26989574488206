import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileSubpageShell } from '~/common';
import { TachographIcon } from '~/components/Icons';
import { EditorState } from '~/services/ApiClient';

import { SectionName } from '../../constants';

import type { HeaderSectionProps } from './models';
import { useStyles } from './styles';

const HeaderSection: FC<HeaderSectionProps> = (props) => {
    const { entry, openCompanyCardEditDialog } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <ProfileSubpageShell
                icon={<TachographIcon className={classes.icon} />}
                primaryTitle={t(SectionName.HEADER)}
                secondaryTitle={entry.cardNumber || t('company-card')}
            />
            <div className={classes.buttons} data-id="action-buttons-admin-company-card">
                <Tooltip title={t('modify-admin-company-card-button-tooltip')}>
                    <Button
                        color="secondary"
                        data-id="modify-admin-company-card"
                        className={classes.modifyButton}
                        onClick={() => openCompanyCardEditDialog(entry, EditorState.MODIFY)}
                    >
                        <EditIcon className={classes.buttonIcon} />
                        {t('modify-action')}
                    </Button>
                </Tooltip>
            </div>
        </>
    );
};

HeaderSection.displayName = 'HeaderSection';
export default HeaderSection;
