import { createAction } from 'redux-actions';

import { AuthenticationApiClient, retryableRequest } from '~/services/ApiClient';

import type { UsersPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

const retrieveCustomerUsersExecutor = (customerId: number): Promise<UsersPayload> => {
    return retryableRequest(() => AuthenticationApiClient.getUsersForCustomer(customerId));
};

export const retrieveCustomerUsersAction = createAction<Promise<UsersPayload>, number, number>(
    ActionTypePrefixes.CUSTOMERUSERS,
    retrieveCustomerUsersExecutor,
    (customerId: number) => customerId
);
