import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const VEHICLEADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY = 'vehicle-administration-detailspane-state';

export interface VehicleAdministrationDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {}

export const defaultVehicleAdministrationDetailsPaneUserPreferences: VehicleAdministrationDetailsPaneUserPreferences = {
    sectionOrder: [
        SectionName.VEHICLEADMINISTRATIONHEADER,
        SectionName.VEHICLEADMINISTRATIONGENERAL,
        SectionName.VEHICLEADMINISTRATIONTACHOGRAPH,
        SectionName.VEHICLEADMINISTRATIONSERVICES,
        SectionName.VEHICLEADMINISTRATIONFUEL,
    ],
    collapsedSections: [],
};

export const processResponse = (
    preferences: Partial<VehicleAdministrationDetailsPaneUserPreferences>
): VehicleAdministrationDetailsPaneUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(
        preferences,
        defaultVehicleAdministrationDetailsPaneUserPreferences
    );
};

export const requiredVehicleAdministrationDetailsPaneUserPreferences = {
    key: VEHICLEADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY,
    defaultState: defaultVehicleAdministrationDetailsPaneUserPreferences,
};
