import type { FC } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';

import type { EnhancedLeafletEventHandlerFnMap } from '../../models';

const MapEventControls: FC<EnhancedLeafletEventHandlerFnMap> = (props) => {
    const { zoom } = props;

    const map = useMap();

    useMapEvents({
        ...props,
        zoom: () => {
            zoom?.(map.getZoom());
        },
    });

    return null;
};

export { MapEventControls };
