import { logEvent } from '~/services/Analytics';

import { ColumnName } from '../../constants';

const rtdsColumnsToLog = [
    ColumnName.VEHICLEALIAS,
    ColumnName.MSISDN,
    ColumnName.FIRSTNAME,
    ColumnName.LASTNAME,
    ColumnName.NATIONALPHACODE,
    ColumnName.UPLOADEVENTDATE,
    ColumnName.UPLOADEVENTDETAILS,
];

export const logEventWhenEnablingColumns = (columns: ColumnName[]): void => {
    columns.forEach((c) => {
        if (rtdsColumnsToLog.includes(c)) {
            logEvent('rtds', `enable-${c}-column`, `Enabling ${c.replace(/-/g, ' ')} column`);
        }
    });
};
