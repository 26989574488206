import { toMinutes } from 'duration-fns';

import { HOURS_FORMAT, MINUTES_FORMAT } from './consts';
import { DurationFormat, LengthFormat } from './enums';
import type { FormatDurationProps } from './models';

const formatDuration = (props: FormatDurationProps): string => {
    const { value, options } = props;
    const formatOptions = options ?? { format: DurationFormat.HOUR_MINUTE, length: LengthFormat.SHORT };

    const HH = formatOptions.length === LengthFormat.SHORT ? HOURS_FORMAT : ` ${HOURS_FORMAT}`;
    const MM = formatOptions.length === LengthFormat.SHORT ? MINUTES_FORMAT : ` ${MINUTES_FORMAT}`;

    switch (formatOptions.format) {
        case DurationFormat.HOUR_MINUTE:
            return `${value.hours}${HH} ${value.minutes}${MM}`;
        case DurationFormat.HOUR:
            return `${value.hours}${HH}`;
        case DurationFormat.MINUTE:
            return `${toMinutes(value)}${MM}`;
        default:
            throw new Error(`DurationFormat ${formatOptions.format} is not supported.`);
    }
};

export { formatDuration };
