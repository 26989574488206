import type { StoreState } from '~/reducers';
import { menuStoreStateSelector } from '~/selectors';

import type { OverlayMenuStoreState } from './components/OverlayMenu';
import type { MenuRootStoreState } from './reducers';

export const menuRootStateSelector = (s: StoreState): MenuRootStoreState => {
    return menuStoreStateSelector(s).menuRoot;
};

export const overlayMenuStateSelector = (s: StoreState): OverlayMenuStoreState => {
    return menuStoreStateSelector(s).overlayMenu;
};
