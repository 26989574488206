import countries from 'i18n-iso-countries';
import countriesDe from 'i18n-iso-countries/langs/de.json';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesEs from 'i18n-iso-countries/langs/es.json';
import countriesFr from 'i18n-iso-countries/langs/fr.json';
import countriesHu from 'i18n-iso-countries/langs/hu.json';
import countriesIt from 'i18n-iso-countries/langs/it.json';
import countriesNl from 'i18n-iso-countries/langs/nl.json';
import countriesPl from 'i18n-iso-countries/langs/pl.json';
import countriesPt from 'i18n-iso-countries/langs/pt.json';
import type { i18n } from 'i18next';
import originalI18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import classificationsDeDe from '~/locales/classifications.de-de.json';
import classificationsEnGb from '~/locales/classifications.en-gb.json';
import classificationsEsEs from '~/locales/classifications.es-es.json';
import classificationsFrFr from '~/locales/classifications.fr-fr.json';
import classificationsItIt from '~/locales/classifications.it-it.json';
import classificationsNlNl from '~/locales/classifications.nl-nl.json';
import classificationsptBr from '~/locales/classifications.pt-br.json';
import deviceTypesDeDe from '~/locales/deviceTypes.de-de.json';
import deviceTypesEnGb from '~/locales/deviceTypes.en-gb.json';
import deviceTypesHuHu from '~/locales/deviceTypes.hu-hu.json';
import deviceTypesItIt from '~/locales/deviceTypes.it-it.json';
import deviceTypesptBr from '~/locales/deviceTypes.pt-br.json';
import deDe from '~/locales/translation.de-de.json';
import enGb from '~/locales/translation.en-gb.json';
import esEs from '~/locales/translation.es-es.json';
import frFr from '~/locales/translation.fr-fr.json';
import huHu from '~/locales/translation.hu-hu.json';
import itIt from '~/locales/translation.it-it.json';
import nlNl from '~/locales/translation.nl-nl.json';
import plPl from '~/locales/translation.pl-pl.json';
import ptBr from '~/locales/translation.pt-br.json';

let theInstance: i18n;

export function setI18n(instance: i18n): void {
    theInstance = instance;
}

export function getI18n(): i18n {
    return theInstance;
}

export function initI18n(): Promise<void> {
    countries.registerLocale(countriesDe);
    countries.registerLocale(countriesEn);
    countries.registerLocale(countriesEs);
    countries.registerLocale(countriesFr);
    countries.registerLocale(countriesHu);
    countries.registerLocale(countriesNl);
    countries.registerLocale(countriesPl);
    countries.registerLocale(countriesIt);
    countries.registerLocale(countriesPt);

    const resources = {
        'en-GB': {
            translation: enGb,
            country: countries.getNames('en'),
            'device-type': deviceTypesEnGb,
            classifications: classificationsEnGb,
        },
        'nl-NL': {
            translation: nlNl,
            country: countries.getNames('nl'),
            classifications: classificationsNlNl,
        },
        'de-DE': {
            translation: deDe,
            country: countries.getNames('de'),
            'device-type': deviceTypesDeDe,
            classifications: classificationsDeDe,
        },
        'fr-FR': {
            translation: frFr,
            country: countries.getNames('fr'),
            classifications: classificationsFrFr,
        },
        'hu-HU': {
            translation: huHu,
            country: countries.getNames('hu'),
            'device-type': deviceTypesHuHu,
        },
        'es-ES': {
            translation: esEs,
            country: countries.getNames('es'),
            classifications: classificationsEsEs,
        },
        'pl-PL': {
            translation: plPl,
            country: countries.getNames('pl'),
        },
        'it-IT': {
            translation: itIt,
            country: countries.getNames('it'),
            'device-type': deviceTypesItIt,
            classifications: classificationsItIt,
        },
        'pt-BR': {
            'device-type': deviceTypesptBr,
            classifications: classificationsptBr,
            country: countries.getNames('br'),
            translation: ptBr,
        },
    };

    return originalI18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            react: {
                useSuspense: true,
            },
            resources,
            // explicitly set the supported languages to make the language detector work
            supportedLngs: Object.keys(resources),
            fallbackLng: 'en-GB',
            // have a common namespace used around the full app
            ns: ['translation'],
            defaultNS: 'translation',
            debug: false,

            interpolation: {
                escapeValue: false, // not needed for react!!
            },
        })
        .then((_) => setI18n(originalI18n));
}
