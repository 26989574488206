import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import type { FormWidgetDialogClassKey } from './models';

export const useStyles = makeStyles<Theme, {}, FormWidgetDialogClassKey>((theme: Theme) => ({
    form: {
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
    },
    backDrop: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer,
        color: theme.palette.primary.main,
    },
    actionButton: {
        height: '36px',
    },
}));
