import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';

import { withSceneAnalytics } from '~/components/Analytics';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';

import type { ContainerDetailsPaneInnerProps, ContainerDetailsPaneProps } from './component';
import { ContainerDetailsPaneComponent } from './component';
import { DETAILSPANE_CONTAINER_USERPREFERENCES_KEY, requiredContainerDetailsPaneUserPreferences } from './preferences';
import { mapDispatchToProps, mapStateToProps } from './redux';

export { DETAILSPANE_CONTAINER_USERPREFERENCES_KEY, requiredContainerDetailsPaneUserPreferences };

export { ContainerHistorySubpage } from './sections';

export const ContainerDetailsPane = compose<ContainerDetailsPaneInnerProps, ContainerDetailsPaneProps>(
    setDisplayName('ContainerDetailsPane'),
    withSceneAnalytics('containerdetailspane'),
    connect(createSelector(settingDataSelector(SettingsKey.SECURABLES), mapStateToProps), mapDispatchToProps),
    withRouter
)(ContainerDetailsPaneComponent);
