import Flag from '@mui/icons-material/Flag';
import { List, Tooltip } from '@mui/material';
import type { FC } from 'react';
import Moment from 'react-moment';

import { AddressCard } from '~/components/AddressCard';
import { ElapsedTime } from '~/components/ElapsedTime';
import {
    AddressFormatter,
    DateTimeFormatter,
    RelativeTime,
    adaptiveDateTimeFormatterFactory,
} from '~/components/Formatters';
import { ClockIcon } from '~/components/Icons';
import { LabeledIndicator, LabeledIndicatorGroup, LabeledIndicatorGroupContainer } from '~/components/Indicators';
import type { SingleTFunction } from '~/components/LanguageSelector';
import { useTranslation } from '~/components/LanguageSelector';
import { SectionContentMessage } from '~/components/Sections';
import { ReorderableSection } from '~/components/Sections/components/ReorderableSection';
import { TextBadge } from '~/components/TextBadge';
import { useUserPreferences } from '~/components/UserPreferences';
import type { Classification, RouteDestination } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { AddressFormat, formatClassification } from '~/services/Formatters';
import { ColorVariants, mapRouteStatusToColorVariant } from '~/services/Styling';

import type { NavigationSectionUserPreferences } from './preferences';
import { NAVIGATIONSECTION_PREFERENCES_KEY } from './preferences';
import { useStyles } from './styles';

export interface NavigationSectionProps {
    destination?: RouteDestination;
    routeStatus?: Classification;
    navigationEta?: Date;
    driverHoursEta?: Date;
    lastUpdateDatetime?: Date;
    dragHandleElement: JSX.Element;
    toggleCollapsed: () => void;
    isCollapsed: boolean;
}

export interface NavigationSectionInnerProps extends NavigationSectionProps {}

export const NavigationSectionComponent: FC<NavigationSectionInnerProps> = (props) => {
    const {
        destination,
        routeStatus,
        navigationEta,
        driverHoursEta,
        lastUpdateDatetime,
        dragHandleElement,
        isCollapsed,
        toggleCollapsed,
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const [navigationSectionUserPreferences] = useUserPreferences<NavigationSectionUserPreferences>(
        NAVIGATIONSECTION_PREFERENCES_KEY
    );

    const relativeTimeFormat = { days: t('unit-day'), hours: t('unit-hour'), minutes: t('unit-minutes') };

    const AdaptiveDateTimeFormatter = (() => {
        const adaptiveCalendarSpec = (translate: SingleTFunction) => ({
            lastDay: 'L HH:mm',
            sameDay: `[${translate('today').toUpperCase()}] HH:mm`,
            nextDay: `[${translate('tomorrow').toUpperCase()}] HH:mm`,
            lastWeek: 'L HH:mm',
            nextWeek: 'L HH:mm',
            sameElse: 'L HH:mm',
        });
        return adaptiveDateTimeFormatterFactory(adaptiveCalendarSpec);
    })();

    // Address display

    const addressCard = (
        <AddressCard
            address={destination?.address}
            position={destination?.position}
            icon={<Flag color="primary" />}
            noDataMessage={t('no-destination')}
        />
    );

    // Navigation Indicators

    const statusDisplayName = routeStatus?.key
        ? formatClassification(t, ClassificationType.RouteStatus, routeStatus)
        : t('not-available');
    const statusLabel = (
        <LabeledIndicator
            title={t('status')}
            primaryText={statusDisplayName}
            variant={routeStatus?.key ? mapRouteStatusToColorVariant(routeStatus) : ColorVariants.Disabled}
            dataId="navigation-indicators:status"
            key="status"
        />
    );

    const navigationEtaRelativeTime = navigationEta ? (
        <RelativeTime date={navigationEta} format={relativeTimeFormat} />
    ) : undefined;
    const navigationEtaLabelValue = navigationEtaRelativeTime ? (
        <Tooltip title={t('navigation-section-eta-navigation-tooltip')}>
            <div>{navigationEtaRelativeTime}</div>
        </Tooltip>
    ) : (
        t('not-available')
    );
    const navigationEtaExpectedDate = navigationEta ? <AdaptiveDateTimeFormatter value={navigationEta} t={t} /> : null;
    const navigationEtaLabel = (
        <LabeledIndicator
            title={t('eta-navigation-grid')}
            titleKeepCapitalization
            primaryText={navigationEtaLabelValue}
            secondaryText={navigationEtaExpectedDate}
            variant={navigationEta ? ColorVariants.Normal : ColorVariants.Disabled}
            dataId="navigation-indicators:navigation-eta"
            key="navigationEta"
        />
    );

    const driverHoursEtaRelativeTime = driverHoursEta ? (
        <RelativeTime date={driverHoursEta} format={relativeTimeFormat} />
    ) : undefined;
    const driverHoursEtaLabelValue = driverHoursEtaRelativeTime ? (
        <Tooltip title={t('navigation-section-eta-driver-hours-tooltip')}>
            <div>{driverHoursEtaRelativeTime}</div>
        </Tooltip>
    ) : (
        t('not-available')
    );
    const driverHoursEtaExpectedDate = driverHoursEta ? (
        <AdaptiveDateTimeFormatter value={driverHoursEta} t={t} />
    ) : null;
    const driverHoursEtaLabel = (
        <LabeledIndicator
            title={t('eta')}
            primaryText={driverHoursEtaLabelValue}
            secondaryText={driverHoursEtaExpectedDate}
            variant={driverHoursEta ? ColorVariants.Normal : ColorVariants.Disabled}
            dataId="navigation-indicators:driver-hours-eta"
            key="driverHoursEta"
        />
    );

    const navigationIndicators = [statusLabel, navigationEtaLabel, driverHoursEtaLabel];

    // Right header element

    const rightHeader = () => {
        const [preferredEta, preferredEtaRelativeTime] =
            navigationSectionUserPreferences.preferredEtaWhenCollapsed === 'navigation'
                ? [navigationEta, navigationEtaRelativeTime]
                : [driverHoursEta, driverHoursEtaRelativeTime];

        if (!preferredEta && !routeStatus) {
            return undefined;
        }

        const badgeColorVariant = mapRouteStatusToColorVariant(routeStatus);

        let headerRenderContent;
        if (preferredEta) {
            headerRenderContent = (
                <TextBadge
                    text={
                        <>
                            {t('eta')} {preferredEtaRelativeTime}
                        </>
                    }
                    variant={badgeColorVariant}
                    dataId="navigation-section-header:preferred-eta"
                />
            );
        } else {
            headerRenderContent = (
                <TextBadge
                    text={statusDisplayName}
                    variant={badgeColorVariant}
                    dataId="navigation-section-header:status"
                />
            );
        }

        const headerTooltip = (
            <>
                <span>
                    {t('navigation-section-tooltip-destination')}
                    <AddressFormatter value={destination?.address} options={{ format: AddressFormat.SecondLine }} />
                </span>
                <br />
                <span>
                    {t('navigation-section-tooltip-status', { status: statusDisplayName ?? t('not-available') })}
                </span>
                <br />
                <span>
                    {t('navigation-section-tooltip-navigation-eta')}
                    {navigationEtaRelativeTime ?? t('not-available')}
                </span>
                <br />
                <span>
                    {t('navigation-section-tooltip-driver-hours-eta')}
                    {driverHoursEtaRelativeTime ?? t('not-available')}
                </span>
                <br />
                <span>
                    {lastUpdateDatetime && (
                        <>
                            <DateTimeFormatter value={lastUpdateDatetime} />
                            <span> • </span>
                            <Moment date={lastUpdateDatetime} fromNow />
                        </>
                    )}
                </span>
            </>
        );

        return (
            <Tooltip title={headerTooltip}>
                <div>{headerRenderContent}</div>
            </Tooltip>
        );
    };

    const getSectionContent = () => {
        const isNavigationInfoAvailable = destination || routeStatus || navigationEta || driverHoursEta;

        if (!isNavigationInfoAvailable) {
            return <SectionContentMessage dataId="no-navigation-info">{t('no-navigation-info')}</SectionContentMessage>;
        }

        return (
            <>
                <List disablePadding data-id="navigation-destination">
                    {addressCard}
                </List>

                <div className={classes.indicatorsContainer}>
                    <LabeledIndicatorGroupContainer dataId="navigation-indicators-group-container">
                        <LabeledIndicatorGroup dataId="navigation-indicators-group">
                            {navigationIndicators}
                        </LabeledIndicatorGroup>
                    </LabeledIndicatorGroupContainer>
                </div>

                <div className={classes.elapsedTimeContainer}>
                    {lastUpdateDatetime && <ElapsedTime icon={ClockIcon} since={lastUpdateDatetime} />}
                </div>
            </>
        );
    };

    return (
        <ReorderableSection
            title={t('driver-subpage-navigation')}
            rightHeaderElement={rightHeader()}
            dataId="navigation"
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            dragHandleElement={dragHandleElement}
        >
            {getSectionContent()}
        </ReorderableSection>
    );
};
