import { actionGenerator } from '~/common';

const MODULE_PREFIX = 'ASSET_NEAR_BY';

const ACTION_TYPE_PREFIXES = {
    LIST: `[${MODULE_PREFIX}] LIST`,
} as const;

const ACTION_TYPE_KEYS = {
    ...actionGenerator(ACTION_TYPE_PREFIXES, MODULE_PREFIX),
    RESET: `[${MODULE_PREFIX}] RESET` as `[ASSET_NEAR_BY] RESET`,
};

export { ACTION_TYPE_KEYS, ACTION_TYPE_PREFIXES, MODULE_PREFIX };
