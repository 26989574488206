import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { VehicleMarkerIconBadgeInnerProps, VehicleMarkerIconBadgeProps } from './component';
import { VehicleMarkerIconBadgeComponent } from './component';
import { vehicleMarkerIconBadgeStyleRules } from './styles';

export const VehicleMarkerIconBadge = compose<VehicleMarkerIconBadgeInnerProps, VehicleMarkerIconBadgeProps>(
    setDisplayName('VehicleMarkerIconBadge'),
    withStyles(vehicleMarkerIconBadgeStyleRules)
)(VehicleMarkerIconBadgeComponent);
