import type { DriverActivityEntry } from '../../models';

export const filterActivitiesThatAreInInterval = (
    { start, stop }: { start: Date; stop: Date },
    activities: DriverActivityEntry[]
): DriverActivityEntry[] => {
    return activities.filter((activity) => {
        return activity.startDate <= stop && (!activity.stopDate || activity.stopDate > start);
    });
};
