import RefreshIcon from '@mui/icons-material/Refresh';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { AddPlusToIcon, DriverIcon, TruckIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps } from '~/components/SceneList';
import { SceneListViewHeaderFactory } from '~/components/SceneList';
import { TitledIconButton } from '~/components/TitledIconButton';
import type { RtdsSchedulesEntry } from '~/scenes/RtdsSchedules/models';
import type { ResolvedRtdsSchedule } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';

import { filterDataSourceMemoized, getColumnsMemoized } from '../../grid';
import { RTDSSCHEDULES_LIST_USERPREFERENCES_KEY, defaultRtdsSchedulesListUserPreferences } from '../../preferences';

const HeaderComponent = SceneListViewHeaderFactory<RtdsSchedulesEntry>(
    RTDSSCHEDULES_LIST_USERPREFERENCES_KEY,
    defaultRtdsSchedulesListUserPreferences,
    filterDataSourceMemoized,
    'rtds-schedules'
);

export interface RtdsSchedulesListViewHeaderProps
    extends Omit<SceneListViewHeaderProps<RtdsSchedulesEntry>, 'columns' | 'excelSheetTitle' | 'moduleBarActions'> {
    onRefreshData: () => void;
    openScheduleEditor: (assetType: AssetType, isClone: boolean, schedule?: ResolvedRtdsSchedule) => void;
}
export interface RtdsSchedulesListViewHeaderInnerProps
    extends RtdsSchedulesListViewHeaderProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

export const RtdsSchedulesListViewHeaderComponent: FC<RtdsSchedulesListViewHeaderInnerProps> = (props) => {
    const { displayPreferences, t, i18n, onRefreshData, openScheduleEditor, ...restProps } = props;

    const columns = getColumnsMemoized(
        t,
        displayPreferences.vehicleDisplayFormat,
        displayPreferences.driverDisplayFormat
    );

    const scheduleActionButtons = useMemo(() => {
        return (
            <>
                <TitledIconButton
                    title={t('rtds-schedule-create-driver')}
                    placement="bottom-end"
                    color="inherit"
                    onClick={() => openScheduleEditor(AssetType.Driver, false, undefined)}
                    data-id="open-schedule-editor-driver"
                >
                    <AddPlusToIcon>
                        <DriverIcon />
                    </AddPlusToIcon>
                </TitledIconButton>
                <TitledIconButton
                    title={t('rtds-schedule-create-vehicle')}
                    placement="bottom-end"
                    color="inherit"
                    onClick={() => openScheduleEditor(AssetType.Vehicle, false, undefined)}
                    data-id="open-schedule-editor-vehicle"
                >
                    <AddPlusToIcon>
                        <TruckIcon />
                    </AddPlusToIcon>
                </TitledIconButton>
            </>
        );
    }, [openScheduleEditor, t]);

    const scheduleButtons = useMemo(() => {
        return (
            <>
                {scheduleActionButtons}
                <TitledIconButton
                    title={t('refresh')}
                    placement="bottom-end"
                    color="inherit"
                    onClick={onRefreshData}
                    data-id="refresh-data-button"
                >
                    <RefreshIcon />
                </TitledIconButton>
            </>
        );
    }, [onRefreshData, scheduleActionButtons, t]);

    return (
        <HeaderComponent
            {...restProps}
            columns={columns}
            excelSheetTitle={t('rtds-schedules')}
            moduleBarActions={scheduleButtons}
        />
    );
};
