import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const HISTORY_DETAILSPANE_VEHICLE_USERPREFERENCES_KEY = 'history-detailspane-vehicle-state';

export interface HistoryVehicleDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapZoomLevel: number;
}

export const defaultHistoryVehicleDetailsPaneUserPreferences: HistoryVehicleDetailsPaneUserPreferences = {
    miniMapZoomLevel: 12,
    sectionOrder: [SectionName.MINIMAP, SectionName.LOCATION, SectionName.METADATA],
    collapsedSections: [],
};

export const processResponse = (
    preferences: Partial<HistoryVehicleDetailsPaneUserPreferences>
): HistoryVehicleDetailsPaneUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultHistoryVehicleDetailsPaneUserPreferences);
};

export const requiredHistoryVehicleDetailsPaneUserPreferences = {
    key: HISTORY_DETAILSPANE_VEHICLE_USERPREFERENCES_KEY,
    defaultState: defaultHistoryVehicleDetailsPaneUserPreferences,
    processResponse,
};
