import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import { SceneAssetSelectorComponent } from './component';
import type { SceneAssetSelectorInnerProps, SceneAssetSelectorProps } from './models';
import { SceneAssetSelectorStyleRules } from './styles';

export { requiredSceneAssetSelectorUserPreferencesFactory, SceneAssetSelectorViewMode } from './preferences';

export type { SceneAssetSelectorUserPreferences } from './preferences';
export type { SceneAssetSelectorProps } from './models';

export const SceneAssetSelector = compose<SceneAssetSelectorInnerProps, SceneAssetSelectorProps>(
    setDisplayName('SceneAssetSelector'),
    withStyles(SceneAssetSelectorStyleRules, { withTheme: true }),
    withTranslation(),
    React.memo
)(SceneAssetSelectorComponent);
