import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import { isUndefined } from '~/libs/utility';
import type { ResolvedVehicleChangelogChanges, VehicleChangelogChanges } from '~/services/ApiClient';

export const getResolvedVehicleChangelogChanges = (
    settingsStoreState: SettingsStoreState,
    staticDataStoreState: StaticDataStoreState,
    changes?: VehicleChangelogChanges
): ResolvedVehicleChangelogChanges => {
    return {
        category: changes?.category
            ? settingsStoreState.vehicleCategories.data[changes?.category]
            : settingsStoreState.vehicleCategories.data[0],
        vehicleType: changes?.vehicleTypeId ? settingsStoreState.vehicleTypes.data[changes?.vehicleTypeId] : undefined,
        depot: changes?.depot ? staticDataStoreState.depots.data.hash[changes?.depot] : undefined,
        uaid: changes?.uaid,
        vin: changes?.vin,
        vrn: changes?.vrn,
        unitId: changes?.unitId,
        companyCardId: changes?.companyCardId,
        tag: changes?.tag,
        msisdn: changes?.msisdn,
        settings: {
            ...changes?.settings,
            imageCapturingType: !isUndefined(changes?.settings?.imageCapturingType)
                ? settingsStoreState.imageCapturingType.data[changes.settings.imageCapturingType]
                : undefined,
            inhibitorServiceType: !isUndefined(changes?.settings?.inhibitorServiceType)
                ? settingsStoreState.inhibitorServiceType.data[changes.settings.inhibitorServiceType]
                : undefined,
            navigationType: !isUndefined(changes?.settings?.navigationType)
                ? settingsStoreState.navigationType.data[changes.settings.navigationType]
                : undefined,
            tachoProtocol: !isUndefined(changes?.settings?.tachoProtocol)
                ? settingsStoreState.tachoProtocol.data[changes.settings.tachoProtocol]
                : undefined,
            tachograph: !isUndefined(changes?.settings?.tachograph)
                ? settingsStoreState.tachograph.data[changes.settings.tachograph]
                : undefined,
            tachoGeneration: !isUndefined(changes?.settings?.tachoGeneration)
                ? settingsStoreState.tachoGeneration.data[changes.settings.tachoGeneration]
                : undefined,
            tellTaleServiceType: !isUndefined(changes?.settings?.tellTaleServiceType)
                ? settingsStoreState.tellTaleServiceType.data[changes.settings.tellTaleServiceType]
                : undefined,
            workflowType: !isUndefined(changes?.settings?.workflowType)
                ? settingsStoreState.workflowType.data[changes.settings.workflowType]
                : undefined,
            primaryFuelTankType: !isUndefined(changes?.settings?.primaryFuelTankType)
                ? settingsStoreState.fuelTankType.data[changes.settings.primaryFuelTankType]
                : undefined,
            secondaryFuelTankType: !isUndefined(changes?.settings?.secondaryFuelTankType)
                ? settingsStoreState.fuelTankType.data[changes.settings.secondaryFuelTankType]
                : undefined,
        },
    };
};
