import { createAction } from 'redux-actions';

import type { GetAdminCompanyCardsResponse } from '~/services/ApiClient';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import { COMPANYCARD_ACTION_TYPE } from './actionTypes';

export const retrieveAdminCompanyCards = createAction<Promise<GetAdminCompanyCardsResponse>>(
    COMPANYCARD_ACTION_TYPE.GET,
    () => retryableRequest(() => ApiClient.getAdminCompanyCards())
);
