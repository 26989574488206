import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const FolderIconComponent: FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 48 48">
            <path d="M20 8H8c-2.21 0-3.98 1.79-3.98 4L4 36c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V16c0-2.21-1.79-4-4-4H24l-4-4z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </SvgIcon>
    );
};

FolderIconComponent.displayName = 'FolderIcon';
export default FolderIconComponent;
