import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { VehicleEntryRowInnerProps, VehicleEntryRowProps } from './component';
import { VehicleEntryRowComponent } from './component';
import { VehicleEntryRowStyleRules } from './styles';

export const VehicleEntryRow = compose<VehicleEntryRowInnerProps, VehicleEntryRowProps>(
    setDisplayName('VehicleEntryRow'),
    withTranslation(),
    withDisplayPreferences(),
    withStyles(VehicleEntryRowStyleRules)
)(VehicleEntryRowComponent);
