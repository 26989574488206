import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const TruckAttributesOnIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M15.9 6.31H8.17a.51.51 0 0 0-.51.51v6.93a.51.51 0 0 0 .51.51H9.25v.59h5.57v-.59H15.9a.51.51 0 0 0 .51-.51V6.82A.51.51 0 0 0 15.9 6.31Z" />
            <rect x="7.78" y="15.46" width="1" height="2.5" rx="0.2" />
            <path d="M14.28 15.46h-.61a.2.2 0 0 0-.2.2v.71h-.82a.76.76 0 0 0-1.37 0h-.79v-.71a.2.2 0 0 0-.2-.2H9.67a.2.2 0 0 0-.2.2v2.09a.21.21 0 0 0 .2.21h.62a.21.21 0 0 0 .2-.21v-.88h.74a.75.75 0 0 0 .73.61.76.76 0 0 0 .74-.61h.77v.88a.2.2 0 0 0 .2.21h.61a.21.21 0 0 0 .21-.21V15.66A.2.2 0 0 0 14.28 15.46Z" />
            <rect x="15.22" y="15.46" width="1" height="2.5" rx="0.2" />
            <path d="M24 12A12 12 0 1 0 12 24 12 12 0 0 0 24 12ZM2.18 12A9.78 9.78 0 1 1 12 21.73 9.78 9.78 0 0 1 2.18 12Z" />
        </SvgIcon>
    );
};
TruckAttributesOnIcon.displayName = 'TruckAttributesOnIcon';

export { TruckAttributesOnIcon };
