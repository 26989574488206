import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type RetryLoadLatestMessagesClassKey = 'root' | 'message';

export const RetryLoadLatestMessagesStyleRules: StyleRulesCallback<Theme, {}, RetryLoadLatestMessagesClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            textAlign: 'center',
        },
        message: {
            display: 'block',
            fontStyle: 'italic',
            marginBottom: theme.spacing(),
        },
    });
