import type { Dispatch } from 'redux';

import type { JwtStoreState } from '~/components/AuthenticationData';
import { endImpersonationAction } from '~/data/authentication';
import { isUndefined } from '~/libs/utility';
import { reportError } from '~/reporting';
import { saveUserPreferencesService } from '~/services/SaveUserPreferences';

import type { EndImpersonationDispatchProps, EndImpersonationStateProps } from './component';

export function mapStateToProps(jwtStoreState: JwtStoreState): EndImpersonationStateProps {
    return {
        isAuthenticated: jwtStoreState.isAuthenticated,
        isImpersonated: !isUndefined(jwtStoreState.impersonatorJwt),
    };
}

export const mapDispatchToEndImpersonationProps = (dispatch: Dispatch): EndImpersonationDispatchProps => ({
    endImpersonation: async () => {
        await saveUserPreferencesService.saveAndClearAll();
        await dispatch(endImpersonationAction()).catch(reportError);
    },
});
