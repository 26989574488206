import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const UnpinColumnIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
                d="M8 5.4l2 2V4h4v4h-3.4l2 2H14v1.4l2 2V10h4v4h-3.5l2 2H20v1.5l2 2V4c0-1.1-0.9-2-2-2H4.6l2 2 M16
            4h4v4h-4V4z M1.3 1.3 L0 2.5l2 2V20c0 1.1 0.9 2 2
            2h15.5l2 2l1.3-1.3L1.3 1.3z M10 12.6l1.4 1.4H10V12.6z M14 20h-4v-4h3.4l0.6
            0.5V20z M16 20v-1.5 l1.5 1.5H16z"
            />
        </SvgIcon>
    );
};
UnpinColumnIcon.displayName = 'UnpinColumnIcon';

export { UnpinColumnIcon };
