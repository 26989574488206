import type { Reducer } from 'redux';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export interface EtherealKeyState {
    data: object;
}

export interface EtherealState {
    [key: string]: EtherealKeyState;
}

const defaultStoreState: EtherealState = {};

export const etherealReducer: Reducer = (
    state: EtherealState = defaultStoreState,
    action: ActionTypes
): EtherealState => {
    switch (action.type) {
        case ActionTypeKeys.ETHEREAL_UPDATE: {
            const newData = { ...state[action.meta]?.data, ...action.payload.value };
            const newState = {
                ...state,
                [action.meta]: {
                    data: newData,
                },
            };
            return newState;
        }

        default:
            return state;
    }
};
