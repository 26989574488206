import type { Duration } from 'moment';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { compose, defaultProps } from 'react-recompose';

import type { PercentageFormatterProps } from '~/components/Formatters';
import {
    AltitudeFormatter,
    DurationFormatter as BaseDurationFormatter,
    PercentageFormatter as BasePercentageFormatter,
    CompartmentStatusFormatter,
    CountryFormatter,
    DeviceTypeFormatter,
    DirectionFormatter,
    DistanceFormatter,
    DoorStatusFormatter,
    DriverActivityFormatter,
    DriverHoursLoginStatusFormatter,
    FuelLevelFormatter,
    IgnitionStatusFormatter,
    LatitudeFormatter,
    LongitudeFormatter,
    NearestCityFormatter,
    ReeferStatusFormatter,
    SpeedFormatter,
    ViolationType,
    WeightFormatter,
} from '~/components/Formatters';
import { InhibitorStatusFormatter } from '~/components/Formatters/InhibitorStatus';
import type { GridColumnDefinition } from '~/components/Grid';
import {
    classificationFilterEquals,
    createAddressColumn,
    createClassificationColumn,
    createDateTimeColumn,
    createDurationColumn,
    createDurationWithViolationColumn,
    createNumberWithViolationColumn,
    createTemperatureSensorColumn,
    getClassificationOptions,
} from '~/components/Grid';
import { createClassificationsColumn } from '~/components/Grid/createClassificationsColumn';
import { createTemperatureColumn } from '~/components/Grid/createTemperatureColumn';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type {
    ClassificationAbstraction,
    DeviceType,
    DriverActivityType,
    DriverSubActivityType,
    LoginStatus,
    NearestCity,
} from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { degreeToCardinal, durationToDays, exportConvertorFactory } from '~/services/Convertors';
import {
    AddressFormat,
    exportFormatterFactory,
    formatCountry,
    formatDeviceType,
    formatDirection,
    formatDriverActivity,
    formatDriverHoursLoginStatus,
    formatDuration,
    formatIgnitionStatus,
    formatIgnitionStatusTitle,
    formatLatitude,
    formatLongitude,
    formatNearestCity,
    formatPercentage,
    getAltitudeUnitSystemUtils,
    getDistanceUnitSystemUtils,
    getFuelLevelUnitSystemUtils,
    getSpeedUnitSystemUtils,
    getWeightUnitSystemUtils,
    valueTextFormatterFactory,
} from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { memoizeOne } from '~/services/Memoize';
import { compareBooleans, compareFactory, compareNumbers, compareStrings, stringComparer } from '~/services/Sorting';

import { ColumnName } from './constants';
import { getCoDriverActivityValue } from './getCellValue.coDriverActivity';
import { getCoDriverSubActivityValue } from './getCellValue.coDriverSubActivity';
import { getDeviceTypeValue } from './getCellValue.deviceType';
import { getDriverActivityValue } from './getCellValue.driverActivity';
import { getDriverSubActivityValue } from './getCellValue.driverSubActivity';
import {
    getAddressValue,
    getAltitudeValue,
    getCityValue,
    getCountryCodeValue,
    getFuelLevelValue,
    getHeadingValue,
    getIgnitionStatusValue,
    getLastUpdateValue,
    getLatitudeValue,
    getLongitudeValue,
    getNearestCityValue,
    getOdometerValue,
    getPostalCodeValue,
    getSpeedValue,
} from './getCellValue.status';
import {
    getCompartmentN,
    getCompartmentNLastEventValue,
    getCompartmentNReturnTemperatureValue,
    getCompartmentNSetpointTemperatureValue,
    getCompartmentNStatusManufacturerValue,
    getCompartmentNStatusValue,
    getCompartmentNSupplyTemperatureValue,
    getDoorNSensorValue,
    getEBSAxleLoadValue,
    getEBSMileageValue,
    getEBSTimeStampValue,
    getEventTypeValue,
    getReeferStatusValue,
    getTrailerDisplayNameFactory,
    getTrailerLastEventValue,
    getTrailerNumberValue,
    getTrailerUnitIdValue,
    getTrailerWeightValue,
} from './getCellValue.trailer';
import { getInhibitorStatusValue, getMsisdnValue, getVehicleValue } from './getCellValue.vehicle';
import {
    getDoubleMannedAvailableBiWeeklyDrivingTimeValue,
    getDoubleMannedAvailableDailyDrivingTimeValue,
    getDoubleMannedAvailableWeeklyDrivingTimeValue,
} from './getCellValue.vehicleDriverHoursStatus';
import {
    getActivityDurationCoDriverValue,
    getAvailableBiWeeklyDrivingTimeCoDriverValue,
    getAvailableDailyDrivingTimeCoDriverValue,
    getAvailableWeeklyDrivingTimeCoDriverValue,
    getAverageWeeklyLaborCoDriverValue,
    getCoDriverIdValue,
    getCoDriverNameValue,
    getContinuousDrivingCoDriverValue,
    getContinuousLaborLongCoDriverValue,
    getContinuousLaborShortCoDriverValue,
    getContinuousWorkAvailableCoDriverValue,
    getDailyDrivingCoDriverValue,
    getDailyDutyCoDriverValue,
    getDailyRestingCoDriverValue,
    getDriveBreakCoDriverValue,
    getExtendedDrivingCountCoDriverValue,
    getFirstSplitDailyRestCoDriverValue,
    getLoginManualStatusCoDriverValue,
    getLongLaborBreakCoDriverValue,
    getMonthlyDutyCoDriverValue,
    getMonthlyEffectivityPercentageCoDriverValue,
    getNightLaborCoDriverValue,
    getOperationalWeekDurationCoDriverValue,
    getReducedDailyRestCountCoDriverValue,
    getShiftDurationCoDriverValue,
    getShortLaborBreakCoDriverValue,
    getTotalWeeklyRestCompensationCoDriverValue,
    getWeeklyDrivingCoDriverValue,
    getWeeklyDutyCoDriverValue,
    getWeeklyLaborCoDriverValue,
    getWeeklyRestCompensationCoDriverValue,
    getWeeklyRestDueCoDriverValue,
} from './getCellValue.vehicleDriverHoursStatus.coDriver';
import {
    getActivityDurationValue,
    getAvailableBiWeeklyDrivingTimeValue,
    getAvailableDailyDrivingTimeValue,
    getAvailableWeeklyDrivingTimeValue,
    getAverageWeeklyLaborValue,
    getContinuousDrivingValue,
    getContinuousLaborLongValue,
    getContinuousLaborShortValue,
    getContinuousWorkAvailableValue,
    getDailyDrivingValue,
    getDailyDutyValue,
    getDailyRestingValue,
    getDriveBreakValue,
    getDriverIdValue,
    getDriverNameValue,
    getExtendedDrivingCountValue,
    getFirstSplitDailyRestValue,
    getLoginManualStatusValue,
    getLongLaborBreakValue,
    getMonthlyDutyValue,
    getMonthlyEffectivityPercentageValue,
    getNightLaborValue,
    getOperationalWeekDurationValue,
    getReducedDailyRestCountValue,
    getShiftDurationValue,
    getShortLaborBreakValue,
    getTotalWeeklyRestCompensationValue,
    getWeeklyDrivingValue,
    getWeeklyDutyValue,
    getWeeklyLaborValue,
    getWeeklyRestCompensationValue,
    getWeeklyRestDueValue,
} from './getCellValue.vehicleDriverHoursStatus.driver';
import type { CreateNthCompartmentTemperatureSensorNProps, GetColumnsProps, GetCompartmentColumnsProps } from './model';

export const getRowId = (row: MonitoringVehicleEntry): number => row.vehicle.id;

const createNthCompartmentTemperatureSensorN = (
    props: CreateNthCompartmentTemperatureSensorNProps
): GridColumnDefinition<MonitoringVehicleEntry> => {
    const { t, unitSystem, compartmentNumber, sensorNumber } = props;
    return createTemperatureSensorColumn(
        t,
        unitSystem,
        sensorNumber,
        ColumnName[`TRAILERCOMPARTMENT${compartmentNumber}TEMPERATURESENSOR${sensorNumber}`],
        t('nth-compartment-temperature-nth-sensor', { compartmentNumber, sensorNumber }),
        t('trailers'),
        getCompartmentN(compartmentNumber)
    );
};

const createNthDoorSensorColumn = (
    t: SingleTFunction,
    doorNumber: 1 | 2
): GridColumnDefinition<MonitoringVehicleEntry> => {
    const column = createClassificationsColumn(
        t,
        ColumnName[`TRAILERDOOR${doorNumber}SENSOR`],
        t('nth-door-sensor', { doorNumber }),
        t('trailers'),
        getDoorNSensorValue(doorNumber),
        ClassificationType.DoorStatus,
        { valueFormatterComponent: withTranslation()(DoorStatusFormatter) }
    );

    return column;
};

const getCompartmentColumns = (
    props: GetCompartmentColumnsProps
): Array<GridColumnDefinition<MonitoringVehicleEntry>> => {
    const { t, unitSystem, compartmentNumber } = props;
    return [
        createClassificationColumn(
            t,
            ColumnName[`TRAILERCOMPARTMENT${compartmentNumber}STATUS`],
            t('nth-compartment-status', { compartmentNumber }),
            t('trailers'),
            getCompartmentNStatusValue(compartmentNumber),
            ClassificationType.CompartmentStatus,
            { valueFormatterComponent: withTranslation()(CompartmentStatusFormatter) }
        ),
        {
            dataType: 'string',
            name: ColumnName[`TRAILERCOMPARTMENT${compartmentNumber}STATUSMANUFACTURER`],
            title: t('nth-compartment-status-manufacturer', { compartmentNumber }),
            groupTitle: t('trailers'),
            getCellValue: getCompartmentNStatusManufacturerValue(compartmentNumber),
            compare: compareStrings,
        },
        createDateTimeColumn(
            ColumnName[`TRAILERCOMPARTMENT${compartmentNumber}LASTEVENT`],
            t('nth-compartment-last-event', { compartmentNumber }),
            t('trailers'),
            getCompartmentNLastEventValue(compartmentNumber)
        ),
        createTemperatureColumn(
            t,
            unitSystem,
            ColumnName[`TRAILERCOMPARTMENT${compartmentNumber}SUPPLYTEMPERATURE`],
            t('nth-compartment-supply-temperature', { compartmentNumber }),
            t('trailers'),
            getCompartmentNSupplyTemperatureValue(compartmentNumber)
        ),
        createTemperatureColumn(
            t,
            unitSystem,
            ColumnName[`TRAILERCOMPARTMENT${compartmentNumber}RETURNTEMPERATURE`],
            t('nth-compartment-return-temperature', { compartmentNumber }),
            t('trailers'),
            getCompartmentNReturnTemperatureValue(compartmentNumber)
        ),
        createTemperatureColumn(
            t,
            unitSystem,
            ColumnName[`TRAILERCOMPARTMENT${compartmentNumber}SETPOINTTEMPERATURE`],
            t('nth-compartment-setpoint-temperature', { compartmentNumber }),
            t('trailers'),
            getCompartmentNSetpointTemperatureValue(compartmentNumber)
        ),
        createNthCompartmentTemperatureSensorN({ t, unitSystem, compartmentNumber, sensorNumber: 1 }),
        createNthCompartmentTemperatureSensorN({ t, unitSystem, compartmentNumber, sensorNumber: 2 }),
        createNthCompartmentTemperatureSensorN({ t, unitSystem, compartmentNumber, sensorNumber: 3 }),
        createNthCompartmentTemperatureSensorN({ t, unitSystem, compartmentNumber, sensorNumber: 4 }),
    ];
};

export const getColumns = (props: GetColumnsProps): Array<GridColumnDefinition<MonitoringVehicleEntry>> => {
    const {
        t,
        driverDisplayNameFormat,
        vehicleDisplayNameFormat,
        trailerDisplayNameFormat,
        unitSystem,
        canViewTrailers,
        canViewRouting,
        canViewInhibitor,
        deviceTypes,
        reeferStatuses,
        trailerEvents,
        routeStatusTypes,
        inhibitorStatuses,
        driverActivityTypes,
        driverSubActivityTypes,
        canViewDriverStatus,
    } = props;

    const withUnitSystem = defaultProps({ unitSystem });
    const durationExcelCellFormat = `[h]"${t('unit-hour')}" mm"${t('unit-minute')}"`;
    const durationExportValueFormatter = exportFormatterFactory(durationToDays);
    const defaultDurationThreshold = moment.duration();
    const defaultDurationViolationType = ViolationType.ABOVE;

    const defaultPercentageFormatterProps: Partial<PercentageFormatterProps> = { precision: 1 };
    const PercentageFormatter = defaultProps(defaultPercentageFormatterProps)(BasePercentageFormatter);

    const BaseDurationFormatterWithT = BaseDurationFormatter;
    const formatOptionalDurationMemoized = valueTextFormatterFactory((v: Duration) => formatDuration(v));
    const numberToValueTextFormatter = valueTextFormatterFactory((v: number) => v.toString());

    const altitudeUtils = getAltitudeUnitSystemUtils(t, unitSystem);
    const distanceUtils = getDistanceUnitSystemUtils(t, unitSystem);
    const fuelLevelUtils = getFuelLevelUnitSystemUtils(t, unitSystem);
    const speedUtils = getSpeedUnitSystemUtils(t, unitSystem);
    const weightUtils = getWeightUnitSystemUtils(t, unitSystem);

    const deviceTypeClassificationFactory = (value: DeviceType): ClassificationAbstraction => {
        return {
            id: value.id,
            key: value.key,
            displayName: formatDeviceType(t, value.key),
        };
    };

    const driverActivityClassificationFactory = (value: DriverActivityType): ClassificationAbstraction => {
        return {
            id: value.id,
            key: value.key,
            displayName: formatDriverActivity(t, value),
        };
    };

    const driverSubActivityClassificationFactory = (value: DriverSubActivityType): ClassificationAbstraction => {
        return {
            id: value.id,
            key: value.name,
            displayName: value.name,
        };
    };
    const vehicleColumns: Array<GridColumnDefinition<MonitoringVehicleEntry>> = [
        {
            dataType: 'string',
            name: ColumnName.VEHICLE,
            title: t('vehicle'),
            groupTitle: t('general'),
            getCellValue: getVehicleValue(vehicleDisplayNameFormat),
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.MSISDN,
            title: t('msisdn'),
            groupTitle: t('general'),
            getCellValue: getMsisdnValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.DEVICETYPE,
            title: t('device-type'),
            groupTitle: t('general'),
            getCellValue: getDeviceTypeValue,
            exportValueFormatter: exportFormatterFactory((v: string) => formatDeviceType(t, v)),
            valueFormatterComponent: withTranslation()(DeviceTypeFormatter),
            compare: compareFactory((v: string) => formatDeviceType(t, v), stringComparer),
            valueTextFormatter: valueTextFormatterFactory((v: string) => formatDeviceType(t, v)),

            cellFiltering: {
                options: getClassificationOptions(Object.values(deviceTypes), deviceTypeClassificationFactory),
                valueEquals: classificationFilterEquals,
            },
        },
        createDateTimeColumn(ColumnName.LASTUPDATE, t('last-update'), t('general'), getLastUpdateValue),
        {
            dataType: 'number',
            name: ColumnName.SPEED,
            title: t('speed'),
            groupTitle: t('location'),
            getCellValue: getSpeedValue,
            getFilterValue: (v: number) => speedUtils.converter(v, { precision: 0 }),
            exportValueFormatter: exportConvertorFactory((v: number) => speedUtils.converter(v, { precision: 0 })),
            excelCellFormat: `0 "${speedUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(SpeedFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => speedUtils.formatter(v, { precision: 0 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => speedUtils.formatter(v, { precision: 0 })),
        },
        {
            dataType: 'string',
            name: ColumnName.HEADING,
            title: t('heading'),
            groupTitle: t('location'),
            getCellValue: getHeadingValue,
            exportValueFormatter: exportFormatterFactory((v: number) => formatDirection(t, v)),
            valueFormatterComponent: DirectionFormatter,
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory(degreeToCardinal),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatDirection(t, v)),
        },
        createAddressColumn(ColumnName.ADDRESS, t('address'), t('location'), getAddressValue, {
            formatOptions: { format: AddressFormat.FirstLine },
        }),
        {
            dataType: 'string',
            name: ColumnName.CITY,
            title: t('city'),
            groupTitle: t('location'),
            getCellValue: getCityValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.POSTALCODE,
            title: t('postal-code'),
            groupTitle: t('location'),
            getCellValue: getPostalCodeValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.COUNTRY,
            title: t('country'),
            groupTitle: t('location'),
            getCellValue: getCountryCodeValue,
            exportValueFormatter: exportFormatterFactory((v: string) => formatCountry(t, v)),
            valueFormatterComponent: withTranslation()(CountryFormatter),
            compare: compareFactory((v: string) => formatCountry(t, v), stringComparer),
            valueTextFormatter: valueTextFormatterFactory((v: string) => formatCountry(t, v)),
        },
        {
            dataType: 'string',
            name: ColumnName.COUNTRYCODE,
            title: t('country-code'),
            groupTitle: t('location'),
            getCellValue: getCountryCodeValue,
            compare: compareStrings,
        },
        {
            dataType: 'boolean',
            name: ColumnName.IGNITIONSTATUS,
            title: t('ignition-status'),
            groupTitle: t('vehicle-status'),
            getCellValue: getIgnitionStatusValue,
            exportValueFormatter: exportFormatterFactory((v: boolean) => formatIgnitionStatus(t, v)),
            valueFormatterComponent: withTranslation()(IgnitionStatusFormatter),
            compare: compareBooleans,
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatIgnitionStatusTitle(t, v)),
        },
        {
            dataType: 'number',
            name: ColumnName.ODOMETER,
            title: t('odometer'),
            groupTitle: t('vehicle-status'),
            getCellValue: getOdometerValue,
            getFilterValue: (v: number) => distanceUtils.converter(v, { precision: 0 }),
            exportValueFormatter: exportConvertorFactory((v: number) => distanceUtils.converter(v, { precision: 0 })),
            excelCellFormat: `0 "${distanceUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DistanceFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            align: 'right',
        },
        {
            dataType: 'number',
            name: ColumnName.FUELLEVEL,
            title: t('fuel-level'),
            groupTitle: t('vehicle-status'),
            getCellValue: getFuelLevelValue,
            getFilterValue: (v: number) => fuelLevelUtils.converter(v, { precision: 1 }),
            exportValueFormatter: exportConvertorFactory((v: number) => fuelLevelUtils.converter(v, { precision: 1 })),
            excelCellFormat: `0.0 "${fuelLevelUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(FuelLevelFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => fuelLevelUtils.formatter(v, { precision: 1 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => fuelLevelUtils.formatter(v, { precision: 1 })),
            align: 'right',
        },
        {
            dataType: 'string',
            name: ColumnName.NEARESTCITY,
            title: t('nearest-city'),
            groupTitle: t('location'),
            getCellValue: getNearestCityValue,
            exportValueFormatter: exportFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(NearestCityFormatter),
            compare: compareFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v), stringComparer),
            groupingCriteria: groupingCriteriaFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            valueTextFormatter: valueTextFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
        },
        {
            dataType: 'number',
            name: ColumnName.ALTITUDE,
            title: t('altitude'),
            groupTitle: t('location'),
            getCellValue: getAltitudeValue,
            getFilterValue: (v: number) => altitudeUtils.converter(v, { precision: 1 }),
            exportValueFormatter: exportConvertorFactory((v: number) => altitudeUtils.converter(v, { precision: 1 })),
            excelCellFormat: `0.0 "${altitudeUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(AltitudeFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => altitudeUtils.formatter(v, { precision: 1 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => altitudeUtils.formatter(v, { precision: 1 })),
            align: 'right',
        },
        {
            dataType: 'string',
            name: ColumnName.LATITUDE,
            title: t('latitude'),
            groupTitle: t('location'),
            getCellValue: getLatitudeValue,
            exportValueFormatter: exportFormatterFactory((v: number) => formatLatitude(t, v)),
            valueFormatterComponent: withTranslation()(LatitudeFormatter),
            compare: compareNumbers,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLatitude(t, v)),
        },
        {
            dataType: 'string',
            name: ColumnName.LONGITUDE,
            title: t('longitude'),
            groupTitle: t('location'),
            getCellValue: getLongitudeValue,
            exportValueFormatter: exportFormatterFactory((v: number) => formatLongitude(t, v)),
            valueFormatterComponent: withTranslation()(LongitudeFormatter),
            compare: compareNumbers,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLongitude(t, v)),
        },
    ];

    let trailerColumns: Array<GridColumnDefinition<MonitoringVehicleEntry>> = [];

    if (canViewTrailers) {
        trailerColumns = [
            {
                dataType: 'string',
                name: ColumnName.TRAILER,
                title: t('trailer'),
                groupTitle: t('trailers'),
                getCellValue: getTrailerDisplayNameFactory(trailerDisplayNameFormat),
                compare: compareStrings,
            },
            {
                dataType: 'string',
                name: ColumnName.TRAILERID,
                title: t('trailer-number'),
                groupTitle: t('trailers'),
                getCellValue: getTrailerNumberValue,
                valueTextFormatter: numberToValueTextFormatter,
                compare: compareNumbers,
                align: 'right',
            },
            {
                dataType: 'string',
                name: ColumnName.TRAILERUNITID,
                title: t('trailer-unit-id'),
                groupTitle: t('trailers'),
                getCellValue: getTrailerUnitIdValue,
                compare: compareStrings,
                align: 'right',
            },
            createClassificationColumn(
                t,
                ColumnName.TRAILEREVENT,
                t('trailer-event-type'),
                t('trailers'),
                getEventTypeValue,
                ClassificationType.TrailerEventType,
                {
                    classifications: Object.values(trailerEvents),
                }
            ),
            createDateTimeColumn(
                ColumnName.TRAILERLASTEVENT,
                t('trailer-last-event'),
                t('trailers'),
                getTrailerLastEventValue
            ),
            createNthDoorSensorColumn(t, 1),
            createNthDoorSensorColumn(t, 2),
            createClassificationColumn(
                t,
                ColumnName.TRAILERREEFERSTATUS,
                t('reefer-status'),
                t('trailers'),
                getReeferStatusValue,
                ClassificationType.ReeferStatus,
                {
                    valueFormatterComponent: withTranslation()(ReeferStatusFormatter),
                    classifications: Object.values(reeferStatuses),
                }
            ),
            ...getCompartmentColumns({ t, unitSystem, compartmentNumber: 1 }),
            ...getCompartmentColumns({ t, unitSystem, compartmentNumber: 2 }),
            ...getCompartmentColumns({ t, unitSystem, compartmentNumber: 3 }),
            ...getCompartmentColumns({ t, unitSystem, compartmentNumber: 4 }),
        ];
    }
    const ebsColumns: Array<GridColumnDefinition<MonitoringVehicleEntry>> = [];

    ebsColumns.push(
        {
            dataType: 'number',
            name: ColumnName.AXLELOAD,
            title: t('axle-load'),
            groupTitle: t('ebs'),
            getCellValue: getEBSAxleLoadValue,
            getFilterValue: (v: number) => weightUtils.converter(v, { precision: 0 }),
            exportValueFormatter: exportConvertorFactory((v: number) => weightUtils.converter(v, { precision: 0 })),
            excelCellFormat: `0 "${weightUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(WeightFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
            align: 'right',
        },
        {
            dataType: 'number',
            name: ColumnName.TRAILERWEIGHT,
            title: t('trailer-weight'),
            groupTitle: t('ebs'),
            getCellValue: getTrailerWeightValue,
            getFilterValue: (v: number) => weightUtils.converter(v, { precision: 0 }),
            exportValueFormatter: exportConvertorFactory((v: number) => weightUtils.converter(v, { precision: 0 })),
            excelCellFormat: `0 "${weightUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(WeightFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
            align: 'right',
        },
        {
            dataType: 'number',
            name: ColumnName.EBSMILEAGE,
            title: t('ebs-mileage'),
            groupTitle: t('ebs'),
            getCellValue: getEBSMileageValue,
            getFilterValue: (v: number) => distanceUtils.converter(v, { precision: 0 }),
            exportValueFormatter: exportConvertorFactory((v: number) => distanceUtils.converter(v, { precision: 0 })),
            excelCellFormat: `0 "${distanceUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DistanceFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            align: 'right',
        },
        createDateTimeColumn(ColumnName.EBSTIMESTAMP, t('ebs-timestamp'), t('ebs'), getEBSTimeStampValue)
    );

    let routingColumns: GridColumnDefinition<MonitoringVehicleEntry>[] = [];
    if (canViewRouting) {
        routingColumns = [
            createClassificationColumn(
                t,
                ColumnName.ROUTESTATUS,
                t('route-status'),
                t('location'),
                (e) => e.routeStatus?.status,
                ClassificationType.RouteStatus,
                {
                    classifications: Object.values(routeStatusTypes),
                }
            ),
            createDateTimeColumn(
                ColumnName.ROUTEETA,
                t('eta-navigation-grid'),
                t('location'),
                (e) => e.routeStatus?.eta
            ),
            createDateTimeColumn(
                ColumnName.ROUTEDRIVERHOURSETA,
                t('eta-driver-hours-grid'),
                t('location'),
                (e) => e.routeStatus?.driverHoursEta
            ),
            createAddressColumn(
                ColumnName.ROUTEDESTINATION,
                t('route-destination'),
                t('location'),
                (e) => e.routeStatus?.destination?.address,
                {
                    formatOptions: { format: AddressFormat.Full },
                }
            ),
        ];
    }

    let inhibitorColumns: GridColumnDefinition<MonitoringVehicleEntry>[] = [];
    if (canViewInhibitor) {
        inhibitorColumns = [
            createClassificationColumn(
                t,
                ColumnName.INHIBITORSTATUS,
                t('inhibitor-status'),
                t('vehicle-status'),
                getInhibitorStatusValue,
                ClassificationType.InhibitorStatus,
                {
                    valueFormatterComponent: withTranslation()(InhibitorStatusFormatter),
                    classifications: Object.values(inhibitorStatuses),
                }
            ),
        ];
    }

    let driverStatusColumns: GridColumnDefinition<MonitoringVehicleEntry>[] = [];
    if (canViewDriverStatus) {
        driverStatusColumns = [
            {
                dataType: 'string',
                name: ColumnName.DRIVERID,
                title: t('driver-id'),
                groupTitle: t('staff'),
                getCellValue: getDriverIdValue,
                compare: compareNumbers,
                valueTextFormatter: numberToValueTextFormatter,
                align: 'right',
            },
            {
                dataType: 'string',
                name: ColumnName.DRIVERNAME,
                title: t('driver-name'),
                groupTitle: t('staff'),
                getCellValue: getDriverNameValue(driverDisplayNameFormat),
                compare: compareStrings,
            },
            {
                dataType: 'string',
                name: ColumnName.CODRIVERID,
                title: t('co-driver-id'),
                groupTitle: t('staff'),
                getCellValue: getCoDriverIdValue,
                compare: compareNumbers,
                valueTextFormatter: numberToValueTextFormatter,
                align: 'right',
            },
            {
                dataType: 'string',
                name: ColumnName.CODRIVERNAME,
                title: t('co-driver-name'),
                groupTitle: t('staff'),
                getCellValue: getCoDriverNameValue(driverDisplayNameFormat),
                compare: compareStrings,
            },
            {
                dataType: 'string',
                name: ColumnName.DRIVERACTIVITY,
                title: t('driver-activity'),
                groupTitle: t('staff'),
                getCellValue: getDriverActivityValue,
                exportValueFormatter: exportFormatterFactory((v: DriverActivityType) => formatDriverActivity(t, v)),
                valueFormatterComponent: withTranslation()(DriverActivityFormatter),
                compare: compareFactory((v: DriverActivityType) => formatDriverActivity(t, v), stringComparer),
                groupingCriteria: groupingCriteriaFactory((v: DriverActivityType) => formatDriverActivity(t, v)),
                valueTextFormatter: valueTextFormatterFactory((v: DriverActivityType) => formatDriverActivity(t, v)),
                cellFiltering: {
                    options: getClassificationOptions(
                        Object.values(driverActivityTypes),
                        driverActivityClassificationFactory
                    ),
                    valueEquals: classificationFilterEquals,
                },
            },
            {
                dataType: 'string',
                name: ColumnName.DRIVERSUBACTIVITY,
                title: t('driver-sub-activity'),
                groupTitle: t('staff'),
                getCellValue: getDriverSubActivityValue,
                compare: compareStrings,

                cellFiltering: {
                    options: getClassificationOptions(
                        Object.values(driverSubActivityTypes),
                        driverSubActivityClassificationFactory
                    ),
                    valueEquals: classificationFilterEquals,
                },
            },
            {
                dataType: 'string',
                name: ColumnName.CODRIVERSUBACTIVITY,
                title: t('co-driver-sub-activity'),
                groupTitle: t('staff'),
                getCellValue: getCoDriverSubActivityValue,
                compare: compareStrings,

                cellFiltering: {
                    options: getClassificationOptions(
                        Object.values(driverSubActivityTypes),
                        driverSubActivityClassificationFactory
                    ),
                    valueEquals: classificationFilterEquals,
                },
            },
            {
                dataType: 'string',
                name: ColumnName.CODRIVERACTIVITY,
                title: t('co-driver-activity'),
                groupTitle: t('staff'),
                getCellValue: getCoDriverActivityValue,
                exportValueFormatter: exportFormatterFactory((v: DriverActivityType) => formatDriverActivity(t, v)),
                valueFormatterComponent: withTranslation()(DriverActivityFormatter),
                compare: compareFactory((v: DriverActivityType) => formatDriverActivity(t, v), stringComparer),
                groupingCriteria: groupingCriteriaFactory((v: DriverActivityType) => formatDriverActivity(t, v)),
                valueTextFormatter: valueTextFormatterFactory((v: DriverActivityType) => formatDriverActivity(t, v)),

                cellFiltering: {
                    options: getClassificationOptions(
                        Object.values(driverActivityTypes),
                        driverActivityClassificationFactory
                    ),
                    valueEquals: classificationFilterEquals,
                },
            },
            createDurationColumn(
                t,
                ColumnName.CODRIVERAVAILABLEDAILYDRIVINGTIME,
                t('co-driver-available-daily-driving-time'),
                t('co-driver-hours'),
                getAvailableDailyDrivingTimeCoDriverValue,
                {
                    abbreviation: t('co-driver-available-daily-driving-time-abbreviation'),
                    threshold: defaultDurationThreshold,
                    violationType: defaultDurationViolationType,
                }
            ),
            createDurationColumn(
                t,
                ColumnName.CODRIVERAVAILABLEWEEKLYDRIVINGTIME,
                t('co-driver-available-weekly-driving-time'),
                t('co-driver-hours'),
                getAvailableWeeklyDrivingTimeCoDriverValue,
                {
                    abbreviation: t('co-driver-available-weekly-driving-time-abbreviation'),
                    threshold: defaultDurationThreshold,
                    violationType: defaultDurationViolationType,
                }
            ),
            createDurationColumn(
                t,
                ColumnName.CODRIVERAVAILABLEBIWEEKLYDRIVINGTIME,
                t('co-driver-available-bi-weekly-driving-time'),
                t('co-driver-hours'),
                getAvailableBiWeeklyDrivingTimeCoDriverValue,
                {
                    abbreviation: t('co-driver-available-bi-weekly-driving-time-abbreviation'),
                    threshold: defaultDurationThreshold,
                    violationType: defaultDurationViolationType,
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERSHIFTDURATION,
                t('co-driver-shift-duration'),
                t('co-driver-hours'),
                getShiftDurationCoDriverValue,
                {}
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERDAILYDUTY,
                t('co-driver-daily-duty'),
                t('co-driver-hours'),
                getDailyDutyCoDriverValue,
                {}
            ),
            {
                dataType: 'string',
                name: ColumnName.CODRIVERDURATION,
                title: t('co-driver-duration'),
                groupTitle: t('co-driver-hours'),
                align: 'right',
                exportValueFormatter: durationExportValueFormatter,
                excelCellFormat: durationExcelCellFormat,
                valueFormatterComponent: BaseDurationFormatterWithT,
                getCellValue: getActivityDurationCoDriverValue,
                valueTextFormatter: formatOptionalDurationMemoized,
                compare: compareNumbers,
            },
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERCONTINUOUSWORKAVAILABLE,
                t('co-driver-continuous-work-available'),
                t('co-driver-hours'),
                getContinuousWorkAvailableCoDriverValue,
                {
                    abbreviation: t('co-driver-continuous-work-available-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVEROPERATIONALWEEKDURATION,
                t('co-driver-operational-week-duration'),
                t('co-driver-hours'),
                getOperationalWeekDurationCoDriverValue,
                {
                    abbreviation: t('co-driver-operational-week-duration-abbreviation'),
                }
            ),
            {
                dataType: 'string',
                name: ColumnName.CODRIVERLOGINMANUALSTATUS,
                title: t('co-driver-login-manual-status'),
                groupTitle: t('co-driver-hours'),
                valueFormatterComponent: withTranslation()(DriverHoursLoginStatusFormatter),
                getCellValue: getLoginManualStatusCoDriverValue,
                exportValueFormatter: exportFormatterFactory((v: LoginStatus) => formatDriverHoursLoginStatus(t, v)),
                compare: compareFactory((v: LoginStatus) => formatDriverHoursLoginStatus(t, v), stringComparer),
            },
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERCONTINUOUSDRIVING,
                t('co-driver-continuous-driving'),
                t('co-driver-hours'),
                getContinuousDrivingCoDriverValue,
                {
                    abbreviation: t('co-driver-continuous-driving-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.CODRIVERDRIVEBREAK,
                t('co-driver-drive-break'),
                t('co-driver-hours'),
                getDriveBreakCoDriverValue,
                {
                    abbreviation: t('co-driver-drive-break-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERDAILYDRIVING,
                t('co-driver-daily-driving'),
                t('co-driver-hours'),
                getDailyDrivingCoDriverValue,
                {
                    abbreviation: t('co-driver-daily-driving-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERWEEKLYDRIVING,
                t('co-driver-weekly-driving'),
                t('co-driver-hours'),
                getWeeklyDrivingCoDriverValue,
                {
                    abbreviation: t('co-driver-weekly-driving-abbreviation'),
                }
            ),
            createNumberWithViolationColumn(
                t,
                ColumnName.CODRIVEREXTENDEDDRIVINGCOUNT,
                t('co-driver-extended-driving-count'),
                t('co-driver-hours'),
                getExtendedDrivingCountCoDriverValue,
                {
                    abbreviation: t('co-driver-extended-driving-count-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERCONTINUOUSLABORSHORT,
                t('co-driver-continuous-labor-short'),
                t('co-driver-hours'),
                getContinuousLaborShortCoDriverValue,
                {
                    abbreviation: t('co-driver-continuous-labor-short-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.CODRIVERSHORTLABORBREAK,
                t('co-driver-short-labor-break'),
                t('co-driver-hours'),
                getShortLaborBreakCoDriverValue,
                {
                    abbreviation: t('co-driver-short-labor-break-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERCONTINUOUSLABORLONG,
                t('co-driver-continuous-labor-long'),
                t('co-driver-hours'),
                getContinuousLaborLongCoDriverValue,
                {
                    abbreviation: t('co-driver-continuous-labor-long-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.CODRIVERLONGLABORBREAK,
                t('co-driver-long-labor-break'),
                t('co-driver-hours'),
                getLongLaborBreakCoDriverValue,
                {
                    abbreviation: t('co-driver-long-labor-break-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERNIGHTLABOR,
                t('co-driver-night-labor'),
                t('co-driver-hours'),
                getNightLaborCoDriverValue,
                {
                    abbreviation: t('co-driver-night-labor-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERWEEKLYLABOR,
                t('co-driver-weekly-labor'),
                t('co-driver-hours'),
                getWeeklyLaborCoDriverValue,
                {
                    abbreviation: t('co-driver-weekly-labor-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERAVERAGEWEEKLYLABOR,
                t('co-driver-average-weekly-labor'),
                t('co-driver-hours'),
                getAverageWeeklyLaborCoDriverValue,
                {
                    abbreviation: t('co-driver-average-weekly-labor-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERWEEKLYDUTY,
                t('co-driver-weekly-duty'),
                t('co-driver-hours'),
                getWeeklyDutyCoDriverValue,
                {
                    abbreviation: t('co-driver-weekly-duty-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CODRIVERMONTHLYDUTY,
                t('co-driver-monthly-duty'),
                t('co-driver-hours'),
                getMonthlyDutyCoDriverValue,
                {
                    abbreviation: t('co-driver-monthly-duty-abbreviation'),
                }
            ),
            {
                dataType: 'number',
                name: ColumnName.CODRIVERMONTHLYDRIVERDUTY,
                title: t('co-driver-monthly-driver-duty'),
                groupTitle: t('co-driver-hours'),
                align: 'right',
                abbreviation: t('co-driver-monthly-driver-duty-abbreviation'),
                getCellValue: getMonthlyEffectivityPercentageCoDriverValue,
                excelCellFormat: '0.0%',
                valueFormatterComponent: PercentageFormatter,
                compare: compareNumbers,
                exportValueFormatter: exportFormatterFactory((v: number) => formatPercentage(v, 1)),
                valueTextFormatter: valueTextFormatterFactory((v: number) => formatPercentage(v, 1)),
            },
            createDurationColumn(
                t,
                ColumnName.CODRIVERFIRSTSPLITDAILYREST,
                t('co-driver-first-split-daily-rest'),
                t('co-driver-hours'),
                getFirstSplitDailyRestCoDriverValue,
                {
                    abbreviation: t('co-driver-first-split-daily-rest-abbreviation'),
                }
            ),
            createNumberWithViolationColumn(
                t,
                ColumnName.CODRIVERREDUCEDDAILYRESTCOUNT,
                t('co-driver-reduced-daily-rest-count'),
                t('co-driver-hours'),
                getReducedDailyRestCountCoDriverValue,
                {
                    abbreviation: t('co-driver-reduced-daily-rest-count-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.CODRIVERDAILYRESTING,
                t('co-driver-daily-resting'),
                t('co-driver-hours'),
                getDailyRestingCoDriverValue,
                {
                    abbreviation: t('co-driver-daily-resting-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.CODRIVERWEEKLYRESTCOMPENSATION,
                t('co-driver-weekly-rest-compensation'),
                t('co-driver-hours'),
                getWeeklyRestCompensationCoDriverValue,
                {
                    abbreviation: t('co-driver-weekly-rest-compensation-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.CODRIVERTOTALWEEKLYRESTCOMPENSATION,
                t('co-driver-total-weekly-rest-compensation'),
                t('co-driver-hours'),
                getTotalWeeklyRestCompensationCoDriverValue,
                {
                    abbreviation: t('co-driver-total-weekly-rest-compensation-abbreviation'),
                }
            ),
            createDateTimeColumn(
                ColumnName.CODRIVERWEEKLYRESTDUE,
                t('co-driver-weekly-rest-due'),
                t('co-driver-hours'),
                getWeeklyRestDueCoDriverValue,
                {
                    abbreviation: t('co-driver-weekly-rest-due-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.AVAILABLEDAILYDRIVINGTIME,
                t('available-daily-driving-time'),
                t('driver-hours'),
                getAvailableDailyDrivingTimeValue,
                {
                    abbreviation: t('available-daily-driving-time-abbreviation'),
                    threshold: defaultDurationThreshold,
                    violationType: defaultDurationViolationType,
                }
            ),
            createDurationColumn(
                t,
                ColumnName.AVAILABLEWEEKLYDRIVINGTIME,
                t('available-weekly-driving-time'),
                t('driver-hours'),
                getAvailableWeeklyDrivingTimeValue,
                {
                    abbreviation: t('available-weekly-driving-time-abbreviation'),
                    threshold: defaultDurationThreshold,
                    violationType: defaultDurationViolationType,
                }
            ),
            createDurationColumn(
                t,
                ColumnName.AVAILABLEBIWEEKLYDRIVINGTIME,
                t('available-bi-weekly-driving-time'),
                t('driver-hours'),
                getAvailableBiWeeklyDrivingTimeValue,
                {
                    abbreviation: t('available-bi-weekly-driving-time-abbreviation'),
                    threshold: defaultDurationThreshold,
                    violationType: defaultDurationViolationType,
                }
            ),
            createDurationColumn(
                t,
                ColumnName.DOUBLEMANNEDAVAILABLEDAILYDRIVINGTIME,
                t('double-manned-available-daily-driving-time'),
                t('driver-hours'),
                getDoubleMannedAvailableDailyDrivingTimeValue,
                {
                    abbreviation: t('double-manned-available-daily-driving-time-abbreviation'),
                    threshold: defaultDurationThreshold,
                    violationType: defaultDurationViolationType,
                }
            ),
            createDurationColumn(
                t,
                ColumnName.DOUBLEMANNEDAVAILABLEWEEKLYDRIVINGTIME,
                t('double-manned-available-weekly-driving-time'),
                t('driver-hours'),
                getDoubleMannedAvailableWeeklyDrivingTimeValue,
                {
                    abbreviation: t('double-manned-available-weekly-driving-time-abbreviation'),
                    threshold: defaultDurationThreshold,
                    violationType: defaultDurationViolationType,
                }
            ),
            createDurationColumn(
                t,
                ColumnName.DOUBLEMANNEDAVAILABLEBIWEEKLYDRIVINGTIME,
                t('double-manned-available-bi-weekly-driving-time'),
                t('driver-hours'),
                getDoubleMannedAvailableBiWeeklyDrivingTimeValue,
                {
                    abbreviation: t('double-manned-available-bi-weekly-driving-time-abbreviation'),
                    threshold: defaultDurationThreshold,
                    violationType: defaultDurationViolationType,
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.SHIFTDURATION,
                t('shift-duration'),
                t('driver-hours'),
                getShiftDurationValue,
                {}
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.DAILYDUTY,
                t('daily-duty'),
                t('driver-hours'),
                getDailyDutyValue,
                {}
            ),
            {
                dataType: 'string',
                name: ColumnName.DURATION,
                title: t('duration'),
                groupTitle: t('driver-hours'),
                align: 'right',
                exportValueFormatter: durationExportValueFormatter,
                excelCellFormat: durationExcelCellFormat,
                valueFormatterComponent: BaseDurationFormatterWithT,
                getCellValue: getActivityDurationValue,
                valueTextFormatter: formatOptionalDurationMemoized,
                compare: compareNumbers,
            },
            createDurationWithViolationColumn(
                t,
                ColumnName.CONTINUOUSWORKAVAILABLE,
                t('continuous-work-available'),
                t('driver-hours'),
                getContinuousWorkAvailableValue,
                { abbreviation: t('continuous-work-available-abbreviation') }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.OPERATIONALWEEKDURATION,
                t('operational-week-duration'),
                t('driver-hours'),
                getOperationalWeekDurationValue,
                { abbreviation: t('operational-week-duration-abbreviation') }
            ),
            {
                dataType: 'string',
                name: ColumnName.LOGINMANUALSTATUS,
                title: t('login-manual-status'),
                groupTitle: t('driver-hours'),
                valueFormatterComponent: withTranslation()(DriverHoursLoginStatusFormatter),
                getCellValue: getLoginManualStatusValue,
                exportValueFormatter: exportFormatterFactory((v: LoginStatus) => formatDriverHoursLoginStatus(t, v)),
                compare: compareFactory((v: LoginStatus) => formatDriverHoursLoginStatus(t, v), stringComparer),
            },
            createDurationWithViolationColumn(
                t,
                ColumnName.CONTINUOUSDRIVING,
                t('continuous-driving'),
                t('driver-hours'),
                getContinuousDrivingValue,
                { abbreviation: t('continuous-driving-abbreviation') }
            ),
            createDurationColumn(t, ColumnName.DRIVEBREAK, t('drive-break'), t('driver-hours'), getDriveBreakValue, {
                abbreviation: t('drive-break-abbreviation'),
            }),
            createDurationWithViolationColumn(
                t,
                ColumnName.DAILYDRIVING,
                t('daily-driving'),
                t('driver-hours'),
                getDailyDrivingValue,
                {
                    abbreviation: t('daily-driving-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.WEEKLYDRIVING,
                t('weekly-driving'),
                t('driver-hours'),
                getWeeklyDrivingValue,
                {
                    abbreviation: t('weekly-driving-abbreviation'),
                }
            ),
            createNumberWithViolationColumn(
                t,
                ColumnName.EXTENDEDDRIVINGCOUNT,
                t('extended-driving-count'),
                t('driver-hours'),
                getExtendedDrivingCountValue,
                {
                    abbreviation: t('extended-driving-count-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CONTINUOUSLABORSHORT,
                t('continuous-labor-short'),
                t('driver-hours'),
                getContinuousLaborShortValue,
                {
                    abbreviation: t('continuous-labor-short-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.SHORTLABORBREAK,
                t('short-labor-break'),
                t('driver-hours'),
                getShortLaborBreakValue,
                {
                    abbreviation: t('short-labor-break-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.CONTINUOUSLABORLONG,
                t('continuous-labor-long'),
                t('driver-hours'),
                getContinuousLaborLongValue,
                {
                    abbreviation: t('continuous-labor-long-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.LONGLABORBREAK,
                t('long-labor-break'),
                t('driver-hours'),
                getLongLaborBreakValue,
                {
                    abbreviation: t('long-labor-break-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.NIGHTLABOR,
                t('night-labor'),
                t('driver-hours'),
                getNightLaborValue,
                {
                    abbreviation: t('night-labor-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.WEEKLYLABOR,
                t('weekly-labor'),
                t('driver-hours'),
                getWeeklyLaborValue,
                {
                    abbreviation: t('weekly-labor-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.AVERAGEWEEKLYLABOR,
                t('average-weekly-labor'),
                t('driver-hours'),
                getAverageWeeklyLaborValue,
                {
                    abbreviation: t('average-weekly-labor-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.WEEKLYDUTY,
                t('weekly-duty'),
                t('driver-hours'),
                getWeeklyDutyValue,
                {
                    abbreviation: t('weekly-duty-abbreviation'),
                }
            ),
            createDurationWithViolationColumn(
                t,
                ColumnName.MONTHLYDUTY,
                t('monthly-duty'),
                t('driver-hours'),
                getMonthlyDutyValue,
                {
                    abbreviation: t('monthly-duty-abbreviation'),
                }
            ),
            {
                dataType: 'number',
                name: ColumnName.MONTHLYDRIVERDUTY,
                title: t('monthly-driver-duty'),
                groupTitle: t('driver-hours'),
                align: 'right',
                abbreviation: t('monthly-driver-duty-abbreviation'),
                getCellValue: getMonthlyEffectivityPercentageValue,
                excelCellFormat: '0.0%',
                valueFormatterComponent: PercentageFormatter,
                compare: compareNumbers,
                exportValueFormatter: exportFormatterFactory((v: number) => formatPercentage(v, 1)),
                valueTextFormatter: valueTextFormatterFactory((v: number) => formatPercentage(v, 1)),
            },
            createDurationColumn(
                t,
                ColumnName.FIRSTSPLITDAILYREST,
                t('first-split-daily-rest'),
                t('driver-hours'),
                getFirstSplitDailyRestValue,
                {
                    abbreviation: t('first-split-daily-rest-abbreviation'),
                }
            ),
            createNumberWithViolationColumn(
                t,
                ColumnName.REDUCEDDAILYRESTCOUNT,
                t('reduced-daily-rest-count'),
                t('driver-hours'),
                getReducedDailyRestCountValue,
                {
                    abbreviation: t('reduced-daily-rest-count-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.DAILYRESTING,
                t('daily-resting'),
                t('driver-hours'),
                getDailyRestingValue,
                {
                    abbreviation: t('daily-resting-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.WEEKLYRESTCOMPENSATION,
                t('weekly-rest-compensation'),
                t('driver-hours'),
                getWeeklyRestCompensationValue,
                {
                    abbreviation: t('weekly-rest-compensation-abbreviation'),
                }
            ),
            createDurationColumn(
                t,
                ColumnName.TOTALWEEKLYRESTCOMPENSATION,
                t('total-weekly-rest-compensation'),
                t('driver-hours'),
                getTotalWeeklyRestCompensationValue,
                {
                    abbreviation: t('total-weekly-rest-compensation-abbreviation'),
                }
            ),
            createDateTimeColumn(
                ColumnName.WEEKLYRESTDUE,
                t('weekly-rest-due'),
                t('driver-hours'),
                getWeeklyRestDueValue,
                {
                    abbreviation: t('weekly-rest-due-abbreviation'),
                }
            ),
        ];
    }

    const gridColumns = vehicleColumns.concat(
        trailerColumns,
        routingColumns,
        inhibitorColumns,
        driverStatusColumns,
        ebsColumns
    );

    return gridColumns;
};

export const getColumnsMemoized = memoizeOne(getColumns);
