import type { NumericDictionary } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { staticDataStoreStateSelector } from '~/selectors';
import type { Container, Driver, Trailer, Vehicle } from '~/services/ApiClient';

export const vehiclesHashSelector = (state: StoreState): NumericDictionary<Vehicle> => {
    return staticDataStoreStateSelector(state).vehicles.data.hash;
};

export const trailersHashSelector = (state: StoreState): NumericDictionary<Trailer> => {
    return staticDataStoreStateSelector(state).trailers.data.hash;
};

export const costinersHashSelector = (state: StoreState): NumericDictionary<Container> => {
    return staticDataStoreStateSelector(state).containers.data.hash;
};

export const driversHashSelector = (state: StoreState): NumericDictionary<Driver> => {
    return staticDataStoreStateSelector(state).drivers.data.hash;
};
