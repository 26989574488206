import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TimeSeriesChartClassKey = 'tooltipCursor' | 'activeDot' | 'lineSeries' | 'areaSeries';

export const TimeSeriesChartStyleRules: StyleRulesCallback<Theme, {}, TimeSeriesChartClassKey> = () =>
    createStyles({
        tooltipCursor: { strokeDasharray: '6 2', strokeWidth: 2, zIndex: 0 },
        areaSeries: { fillOpacity: 0.26, strokeOpacity: 0.26, strokeWidth: 2 },
        lineSeries: { strokeWidth: 2 },
        activeDot: { r: 6 },
    });
