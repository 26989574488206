import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderTemplate } from '~/components/HeaderTemplate';
import { IconDivider } from '~/components/IconDivider';
import { Search } from '~/components/Search';
import { isEqual } from '~/libs/utility';
import type { StoreState } from '~/reducers';

import { changeSearchQueryAction, showAllSelectedAssetsAction } from '../../data';

import { MapActions } from './components/MapActions';
import type { SceneMapViewHeaderProps } from './models';

const SceneMapViewHeader: FC<SceneMapViewHeaderProps> = (props) => {
    const { mapKey, title, moduleBarActions, filters } = props;

    const dispatch = useDispatch();
    const searchQuery = useSelector((state: StoreState) => state.sceneMap.maps[mapKey]?.searchQuery ?? '', isEqual);

    const handleSearchQuery = (sq?: string) => {
        dispatch(changeSearchQueryAction(mapKey, sq));
    };

    return (
        <HeaderTemplate
            title={title}
            centerEl={
                <Search
                    onSearchQueryChange={(sq) => {
                        handleSearchQuery(sq);
                        dispatch(showAllSelectedAssetsAction());
                    }}
                    clearSearchQuery={handleSearchQuery}
                    defaultSearchQuery={searchQuery}
                />
            }
            actions={
                <>
                    <MapActions />
                    <IconDivider />
                    {moduleBarActions}
                </>
            }
            filters={filters}
        />
    );
};

SceneMapViewHeader.displayName = 'SceneMapViewHeader';
export { SceneMapViewHeader };
