import { GroupingPanel } from '@devexpress/dx-react-grid-material-ui';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { GroupingPaneContainerClassKey } from './styles';

export interface GroupingPaneContainerProps extends GroupingPanel.ContainerProps {}

export interface GroupingPaneContainerInnerProps
    extends GroupingPaneContainerProps,
        WithStyles<GroupingPaneContainerClassKey> {}

export const GroupingPaneContainerComponent: React.FunctionComponent<GroupingPaneContainerInnerProps> = ({
    classes,
    ...restProps
}) => <GroupingPanel.Container className={classes.container} {...restProps} data-id="grouping-container" />;
