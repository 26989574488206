import { memo } from 'react';
import { compose, setDisplayName } from 'react-recompose';

import type { AutoSizerInnerProps, AutoSizerProps } from './component';
import { AutoSizerComponent } from './component';

export const AutoSizer = compose<AutoSizerInnerProps, AutoSizerProps>(
    setDisplayName('AutoSizer'),
    memo
)(AutoSizerComponent);
