import { Checkbox, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { ConfirmationDialog } from '~/components/Dialogs/ConfirmationDialog';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';

import type { ConfirmMarkAllMessagesAsReadDialogClassKey } from './styles';

export interface ConfirmMarkAllMessagesAsReadDialogProps {
    unreadMessagesCount: number;
    onMarkAllMessagesAsRead: (dontWarnMeAnymore: boolean) => void;
    onCancel: () => void;
    open: boolean;
}

export interface ConfirmMarkAllMessagesAsReadDialogInnerProps
    extends ConfirmMarkAllMessagesAsReadDialogProps,
        WithStyles<ConfirmMarkAllMessagesAsReadDialogClassKey>,
        InjectedTranslationProps {}

export interface ConfirmMarkAllMessagesAsReadDialogState {
    dontWarnMeAnymore: boolean;
}

export class ConfirmMarkAllMessagesAsReadDialogComponent extends React.Component<
    ConfirmMarkAllMessagesAsReadDialogInnerProps,
    ConfirmMarkAllMessagesAsReadDialogState
> {
    constructor(props: ConfirmMarkAllMessagesAsReadDialogInnerProps) {
        super(props);
        this.state = { dontWarnMeAnymore: false };
    }

    public render(): React.ReactNode {
        const { classes, t, unreadMessagesCount, open } = this.props;

        const markAllMessagesAsReadDialogText = t('mark-messages-as-read-warning', {
            unreadMessagesCount,
        });

        const markAllMessagesAsReadDialogContent = (
            <>
                <Typography>{markAllMessagesAsReadDialogText}</Typography>
                <div className={classes.checkboxWrapper}>
                    <Checkbox
                        data-id="dont-warn-me-anymore"
                        checked={this.state.dontWarnMeAnymore}
                        onChange={this.handleCheckboxChange}
                    />
                    <Typography variant="subtitle2" color="textPrimary">
                        {t('disable-warning')}
                    </Typography>
                </div>
            </>
        );

        return (
            <ConfirmationDialog
                dataId="mark-all-messages-as-read-dialog"
                title={t('mark-all-messages-as-read')}
                open={open}
                confirmationActionText={t('confirm')}
                onConfirm={this.handleMarkAllMessagesAsRead}
                onCancel={this.props.onCancel}
            >
                {markAllMessagesAsReadDialogContent}
            </ConfirmationDialog>
        );
    }

    private handleCheckboxChange = () => {
        this.setState((s) => ({ dontWarnMeAnymore: !s.dontWarnMeAnymore }));
    };

    private handleMarkAllMessagesAsRead = () => {
        this.props.onMarkAllMessagesAsRead(this.state.dontWarnMeAnymore);
    };
}
