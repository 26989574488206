import { isEmpty, isNil } from '~/libs/utility';
import type { WorkflowFieldValue } from '~/services/ApiClient';
import {
    BooleanWorkflowFieldValue,
    DateTimeWorkflowFieldValue,
    DurationWorkflowFieldValue,
    EnumWorkflowFieldValue,
    NumericWorkflowFieldValue,
    PositionWorkflowFieldValue,
    RawWorkflowFieldValue,
    TextWorkflowFieldValue,
} from '~/services/ApiClient';

export const isFieldEmpty = (field: WorkflowFieldValue | undefined): boolean => {
    if (field instanceof TextWorkflowFieldValue) {
        return isNil(field.value) || isEmpty(field.value);
    } else if (field instanceof EnumWorkflowFieldValue) {
        return isNil(field.optionId);
    } else if (field instanceof PositionWorkflowFieldValue) {
        return isNil(field.value);
    } else if (field instanceof NumericWorkflowFieldValue) {
        return isNil(field.value);
    } else if (field instanceof BooleanWorkflowFieldValue) {
        return isNil(field.value);
    } else if (field instanceof DateTimeWorkflowFieldValue) {
        return isNil(field.value);
    } else if (field instanceof DurationWorkflowFieldValue) {
        return isNil(field.value);
    } else if (field instanceof RawWorkflowFieldValue) {
        return isNil(field.value);
    }
    return true;
};
