import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC, RefAttributes } from 'react';
import { Children, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { ReorderableSectionClassKeys } from './styles';

export interface ReorderableSectionProps extends RefAttributes<HTMLDivElement> {
    title: string;
    dataId: string;
    rightHeaderElement?: JSX.Element;
    dragHandleElement?: JSX.Element;
    headerOverContent?: boolean;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
    hideCollapsing?: boolean;
    noPaddingButton?: boolean;
    emptyMessage?: string;
}

export interface ReorderableSectionInnerProps
    extends ReorderableSectionProps,
        WithStyles<ReorderableSectionClassKeys> {}

export const ReorderableSectionComponent: FC<ReorderableSectionInnerProps> = forwardRef<
    HTMLDivElement,
    ReorderableSectionInnerProps
>(
    (
        {
            dataId,
            classes,
            children,
            dragHandleElement,
            rightHeaderElement,
            title,
            headerOverContent,
            isCollapsed,
            toggleCollapsed,
            hideCollapsing,
            noPaddingButton,
            emptyMessage,
        },
        ref
    ) => {
        const { t } = useTranslation();
        const sectionTitle = (
            <Typography data-id="section-title" className={classes.title} color="textSecondary" variant="subtitle1">
                {title}
            </Typography>
        );

        const CollapseIcon = isCollapsed ? ArrowRight : ArrowDropDown;

        const headerClasses = classNames(classes.header, {
            [classes.headerOverContent]: !isCollapsed && headerOverContent,
            [classes.hideCollapsing]: hideCollapsing,
        });
        const bodyClasses = classNames(classes.body, {
            [classes.noPaddingBottom]: headerOverContent || noPaddingButton,
        });
        const sectionContent = Children.toArray(children).some(Boolean) ? (
            <div className={bodyClasses} data-id="section-content">
                {children}
            </div>
        ) : (
            <Typography variant="body2" className={classes.emptyMessage} data-testid="no-available-data">
                {emptyMessage ?? t('no-available-data')}
            </Typography>
        );

        const rightHeaderSection = (
            <div data-id="section-summary" className={classes.rightHeaderElement}>
                {rightHeaderElement}
            </div>
        );

        const collapseIcon = !hideCollapsing && <CollapseIcon className={classes.collapseIcon} />;

        return (
            <div data-id={`section:${dataId}`} data-testid={`section:${dataId}`} className={classes.section} ref={ref}>
                <div className={headerClasses} onClick={toggleCollapsed} data-id="section-header">
                    {collapseIcon}
                    {(!headerOverContent || isCollapsed) && sectionTitle}
                    {isCollapsed && rightHeaderSection}
                    {dragHandleElement}
                </div>
                {!isCollapsed && sectionContent}
            </div>
        );
    }
);
