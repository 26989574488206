import classNames from 'classnames';
import React from 'react';

import { ColorVariants } from '~/services/Styling';

import { useStyles } from './styles';

export interface TextBadgeProps {
    text: string | JSX.Element;
    variant: ColorVariants;
    dataId?: string;
}

export interface TextBadgeInnerProps extends TextBadgeProps {}

const TextBadge: React.FC<TextBadgeInnerProps> = ({ text, variant, dataId }) => {
    const classes = useStyles();

    const className = classNames(classes.root, {
        [classes.success]: variant === ColorVariants.Success,
        [classes.warning]: variant === ColorVariants.Warning,
        [classes.error]: variant === ColorVariants.Error,
        [classes.disabled]: variant === ColorVariants.Disabled,
    });

    return (
        <span className={className} data-id={dataId}>
            {text}
        </span>
    );
};
TextBadge.displayName = 'TextBadge';

export { TextBadge };
