export const ADMINVEHICLETYPES_USERPREFERENCES_KEY = 'adminvehicletypes-state';

export const ADMINVEHICLETYPES_ASSETSELECTOR_USERPREFERENCES_KEY = 'adminvehicletypes-assetselector-state';

export interface AdminVehicleTypesUserPreferences {
    leftPaneIsOpen: boolean;
}

export const defaultAdminVehicleTypesUserPreferences: AdminVehicleTypesUserPreferences = {
    leftPaneIsOpen: true,
};

export const requiredAdminVehicleTypesUserPreferences = {
    key: ADMINVEHICLETYPES_USERPREFERENCES_KEY,
    defaultState: defaultAdminVehicleTypesUserPreferences,
};
