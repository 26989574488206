import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export type ChangelogChangesTableClassKeys = 'container' | 'table' | 'header' | 'cell';

export const useStyles = makeStyles<Theme, {}, ChangelogChangesTableClassKeys>((theme) => ({
    container: {
        margin: theme.spacing(1, 2, 1, 2),
        width: 'auto',
    },
    table: {
        tableLayout: 'fixed',
    },
    header: {
        fontWeight: 'bold',
    },
    cell: {
        padding: theme.spacing(0, 1),
        fontSize: '12px',
    },
}));
