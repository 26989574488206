import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { DateTimeSettingProps } from './component';

export type DateTimeSettingClassKey = 'toolbarIcon';

export const DateTimeSettingStyleRules: StyleRulesCallback<Theme, DateTimeSettingProps, DateTimeSettingClassKey> = (
    theme: Theme
) =>
    createStyles({
        toolbarIcon: {
            color: theme.palette.primary.contrastText,
        },
    });
