import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withSceneAnalytics } from '~/components/Analytics';
import { protectedComponentHoc } from '~/components/AuthenticationData';
import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';
import { withMenu } from '~/components/Navigation';
import { requiredSceneAssetSelectorUserPreferencesFactory } from '~/components/SceneAssetSelector';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { staticDataStoreStateSelector } from '~/selectors';

import { RtdsSessionsComponent } from './component';
import {
    RTDSSESSIONS_ASSETSELECTOR_USERPREFERENCES_KEY,
    RTDSSESSIONS_USERPREFERENCES_KEY,
    requiredRtdsSessionsUserPreferences,
} from './preferences';
import { mapDispatchProps, mapStateToProps, mergeProps } from './redux';
import { rtdsSessionsStateSelector } from './selectors';
import { RtdsSessionsStyleRules } from './styles';

export * from './storestate';

export const RtdsSessions = compose(
    setDisplayName('RtdsSessions'),
    protectedComponentHoc,
    withMenu,
    withTranslation(),
    withStyles(RtdsSessionsStyleRules),
    ensureSettings([SettingsKey.SECURABLES, SettingsKey.TACHOGRAPH_DATA_BLOCK, SettingsKey.RTDS_SESSION_ERROR_TYPE]),
    ensureUserPreferences([
        requiredSceneAssetSelectorUserPreferencesFactory(RTDSSESSIONS_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredRtdsSessionsUserPreferences,
    ]),
    connect(
        createSelector(
            settingDataSelector(SettingsKey.SECURABLES),
            rtdsSessionsStateSelector,
            staticDataStoreStateSelector,
            keyDataStateSelectorFactory(RTDSSESSIONS_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(RTDSSESSIONS_ASSETSELECTOR_USERPREFERENCES_KEY),
            mapStateToProps
        ),
        mapDispatchProps,
        mergeProps
    ),
    withSceneAnalytics('rtds-sessions')
)(RtdsSessionsComponent);
