import { FormControl, FormHelperText, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers';
import type { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation';
import { isSameDay } from 'date-fns';
import type { Moment } from 'moment';
import moment from 'moment';
import type { MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { RangeKeyDict } from 'react-date-range';
import { useTranslation } from 'react-i18next';

import { endOfDay } from '~/libs/dates';
import { isUndefined } from '~/libs/utility';
import { retrieveCurrentLanguage } from '~/services/Language';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { LOCALES_MAPPER, TIME_PICKER_ERROR_MESSAGES } from './consts';
import type { DateTimeRangePickerProps } from './models';
import {
    StyledButton,
    StyledDateRange,
    StyledIconCalendar,
    StyledIconRow,
    StyledPopover,
    StyledSpan,
    StyledTimePickers,
    StyledTypography,
    TimePickerContainer,
} from './styles';

const DateTimeRangePicker = (props: DateTimeRangePickerProps) => {
    const { value, onChange, minDate, maxDate, maxDays, maxHours = 24, withTimePicker } = props;

    const [open, setOpen] = useState(false);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>(undefined);
    const [locale, setLocale] = useState<object | undefined>(undefined);
    const [focusedRange, setFocusedRange] = useState<[number, number]>([0, 0]);
    const [startTime, setStartTime] = useState<Date>(value.startDate);
    const [endTime, setEndTime] = useState<Date>(endOfDay(value.endDate));

    const [startTempTime, setStartTempTime] = useState<Date>(value.startDate);
    const [endTempTime, setEndTempTime] = useState<Date>(endOfDay(value.endDate));

    const [startTimeError, setStartTimeError] = useState<string>('');
    const [endTimeError, setEndTimeError] = useState<string>('');
    const [generalTimeError, setGeneralTimeError] = useState<string>('');

    const isSameDayCheck = useMemo(() => isSameDay(startTime, endTime), [startTime, endTime]);
    const maxTime = useMemo(() => (isSameDayCheck ? moment(endTime) : undefined), [endTime, isSameDayCheck]);
    const minTime = useMemo(() => (isSameDayCheck ? moment(startTime) : undefined), [startTime, isSameDayCheck]);

    const isWithinMaxHoursLimit = useCallback(
        (firstDate: Date, secondDate: Date) =>
            Math.abs(moment(firstDate).diff(moment(secondDate), 'hours', true)) <= maxHours,
        [maxHours]
    );

    const theme = useTheme();

    const { t } = useTranslation();

    useEffect(() => {
        retrieveCurrentLanguage().then(async (lng) => {
            setLocale(LOCALES_MAPPER[lng.code]);
        });
    }, []);

    const handlePickerChange = useCallback(
        (item: RangeKeyDict) => {
            if (item.selection?.startDate && item.selection?.endDate) {
                const { startDate } = item.selection;

                const adjustedEndDate = moment(item.selection.endDate).endOf('day').toDate();

                setStartTime(startDate);
                setEndTime(adjustedEndDate);

                if (withTimePicker) {
                    setStartTempTime(startDate);
                    setEndTempTime(adjustedEndDate);
                } else {
                    onChange({ startDate, endDate: adjustedEndDate });
                }
            }
        },
        [onChange, withTimePicker]
    );

    useEffect(() => {
        const isValidTimePeriod = isWithinMaxHoursLimit(startTempTime, endTempTime);

        if (!isValidTimePeriod) {
            setGeneralTimeError(t(TIME_PICKER_ERROR_MESSAGES.invalidTime, { maxHours }));
        } else {
            setStartTime(startTempTime);
            setEndTime(endTempTime);
            setGeneralTimeError('');
        }
    }, [isWithinMaxHoursLimit, startTempTime, endTempTime, t, maxHours]);

    const handleChangeStartTime = (time: Moment | null) => {
        if (time) {
            setStartTempTime(time.toDate());
        }
    };

    const handleChangeEndTime = (time: Moment | null) => {
        if (time) {
            setEndTempTime(time.toDate());
        }
    };

    const handleErrorStartTime = (reason: TimeValidationError, _: Date) => {
        if (reason) {
            setStartTimeError(t(TIME_PICKER_ERROR_MESSAGES[reason]));
        } else {
            setStartTimeError('');
        }
    };

    const handleErrorEndTime = (reason: TimeValidationError, _: Date) => {
        if (reason) {
            setEndTimeError(t(TIME_PICKER_ERROR_MESSAGES[reason]));
        } else {
            setEndTimeError('');
        }
    };

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
        setAnchorElement(event.currentTarget);
    };

    const handleClose = (): void => {
        setOpen(false);
        setStartTempTime(value.startDate);
        setEndTempTime(value.endDate);
    };

    const handleFocusedRangeChange = (range: [number, number]) => {
        setFocusedRange(range);
    };

    const handleApply = useCallback((): void => {
        onChange({ startDate: startTime, endDate: endTime });
        setOpen(false);
    }, [endTime, onChange, startTime]);

    const formattedStartDate = moment(value.startDate).format('DD-MM-YYYY');
    const formattedEndDate = moment(value.endDate).format('DD-MM-YYYY');
    const formattedCurrentStartDate = moment(startTime).format('DD-MM-YYYY');
    const formattedCurrentEndDate = moment(endTime).format('DD-MM-YYYY');
    const formattedStartTime = withTimePicker ? moment(startTime).format('HH:mm') : '';
    const formattedEndTime = withTimePicker ? moment(endTime).format('HH:mm') : '';

    const normalizedDates = useMemo(() => {
        let normalizedMinDate = minDate;
        let normalizedMaxDate = maxDate;

        if (!isUndefined(maxDays) && focusedRange[1] === 1) {
            normalizedMinDate = new Date(
                Math.max(
                    minDate?.getTime() ?? Number.MIN_VALUE,
                    moment(startTime)
                        .subtract(maxDays - 1, 'days')
                        .toDate()
                        .getTime()
                )
            );
            normalizedMaxDate = new Date(
                Math.min(
                    maxDate?.getTime() ?? Number.MAX_VALUE,
                    moment(startTime)
                        .add(maxDays - 1, 'days')
                        .toDate()
                        .getTime()
                )
            );
        }

        return { normalizedMinDate, normalizedMaxDate };
    }, [minDate, maxDate, maxDays, focusedRange, startTime]);

    const { normalizedMinDate, normalizedMaxDate } = normalizedDates;

    return (
        <Box>
            <StyledSpan onClick={handleClick} data-testid="date-time-period-selector">
                <StyledIconCalendar />
                {`${formattedStartDate} ${formattedStartTime} - ${formattedEndDate} ${formattedEndTime}`}
                <StyledIconRow />
            </StyledSpan>

            <StyledPopover
                data-testid="date-time-period-picker"
                id="date-time-period-picker"
                onClose={handleClose}
                open={open}
                anchorEl={anchorElement}
                disableRestoreFocus
            >
                <StyledDateRange
                    locale={locale}
                    focusedRange={focusedRange}
                    onChange={handlePickerChange}
                    onRangeFocusChange={handleFocusedRangeChange}
                    showPreview
                    months={2}
                    minDate={normalizedMinDate}
                    maxDate={normalizedMaxDate}
                    ranges={[{ startDate: startTime, endDate: endTime, key: 'selection' }]}
                    direction="horizontal"
                    dateDisplayFormat="dd-MM-yyyy"
                    rangeColors={[theme.palette.secondary.main]}
                    color={theme.palette.secondary.main}
                />
                {withTimePicker && (
                    <TimePickerContainer>
                        <FormControl>
                            <StyledTimePickers>
                                <TimePicker
                                    label={formattedCurrentStartDate}
                                    value={startTempTime}
                                    onChange={handleChangeStartTime}
                                    ampm={false}
                                    inputFormat="HH:mm"
                                    maxTime={maxTime}
                                    onError={handleErrorStartTime}
                                    renderInput={(params) => <TextField {...params} helperText={startTimeError} />}
                                />
                                <StyledTypography variant="body1"> – </StyledTypography>
                                <TimePicker
                                    label={formattedCurrentEndDate}
                                    value={endTempTime}
                                    ampm={false}
                                    onChange={handleChangeEndTime}
                                    inputFormat="HH:mm"
                                    minTime={minTime}
                                    onError={handleErrorEndTime}
                                    renderInput={(params) => <TextField {...params} helperText={endTimeError} />}
                                />
                            </StyledTimePickers>
                            {generalTimeError && <FormHelperText>{generalTimeError}</FormHelperText>}
                        </FormControl>
                        <StyledButton
                            disabled={Boolean(startTimeError) || Boolean(endTimeError) || Boolean(generalTimeError)}
                            onClick={handleApply}
                            color="primary"
                            variant="contained"
                            data-testid="apply"
                        >
                            {t('apply')}
                        </StyledButton>
                    </TimePickerContainer>
                )}
            </StyledPopover>
        </Box>
    );
};

DateTimeRangePicker.displayName = 'DateTimeRangePicker';
export { DateTimeRangePicker };
