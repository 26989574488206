import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { ContainerTemperatureGroupInnerProps, ContainerTemperatureGroupOutterProps } from './component';
import { ContainerTemperatureGroupComponent } from './component';
import { ContainerTemperatureGroupStyleRules } from './styles';

export const ContainerTemperatureGroup = compose<
    ContainerTemperatureGroupInnerProps,
    ContainerTemperatureGroupOutterProps
>(
    setDisplayName('ContainerTemperatureGroup'),
    withStyles(ContainerTemperatureGroupStyleRules),
    withTranslation(),
    withDisplayPreferences()
)(ContainerTemperatureGroupComponent);
