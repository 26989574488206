import { Button, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import illustration from './assets/driver-and-truck.png';
import { useStyles } from './styles';

export interface ReloadPageProps {
    handleReload: () => void;
}

const ReloadPage: FC<ReloadPageProps> = ({ handleReload }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <img src={illustration} className={classes.illustration} alt="Driver and Truck" width={346} height={426} />
            <Typography variant="h2" className={classes.title} data-id="reload-page-title">
                {t('something-went-wrong')}
            </Typography>
            <Typography
                variant="body1"
                color="textSecondary"
                className={classes.description}
                data-id="reload-page-description"
            >
                {t('reload-page')}
            </Typography>
            <Button color="secondary" variant="contained" onClick={handleReload} data-id="reload-page-button">
                {t('reload')}
            </Button>
        </div>
    );
};

ReloadPage.displayName = 'ReloadPage';
export default ReloadPage;
