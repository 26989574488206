import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { wrapDisplayName } from 'react-recompose';
import { useSelector } from 'react-redux';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { Feature } from '~/components/FeatureFlags';
import { featureFlagsSelector } from '~/selectors';
import { themes } from '~/theme';

export const ensureThemeHoc =
    () =>
    <BaseProps extends {}>(
        BaseComponent: React.ComponentType<BaseProps>
    ): React.FC<BaseProps & InjectedDisplayPreferencesProps> => {
        const Hoc = (props: BaseProps & InjectedDisplayPreferencesProps) => {
            const { displayPreferences, ...restProps } = props;
            const { enabledFeatures } = useSelector(featureFlagsSelector);
            const isDriverActivityRedesignEnabled = enabledFeatures.includes(Feature.DriverActivityRedesign);
            const theme = isDriverActivityRedesignEnabled
                ? themes.featureFlaggedTheme
                : themes[displayPreferences.theme] ?? themes.default;

            return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <BaseComponent {...(restProps as unknown as BaseProps)} />
                    </ThemeProvider>
                </StyledEngineProvider>
            );
        };
        Hoc.displayName = wrapDisplayName(BaseComponent, 'ensureTheme');

        return Hoc;
    };
