import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { Button, DialogActions, DialogContent, DialogContentText, Popover } from '@mui/material';
import type { Duration } from 'moment';
import moment from 'moment';
import * as React from 'react';

import { DurationInput, invalidDurationValue } from '~/components/InputFields';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { formatDuration } from '~/services/Formatters';

import type { FilterDurationValue } from '../../../../models';
import type { FilterEditorProps } from '../../models';

import { createFilterValue, getInputValue } from './utility';

export interface DurationEditorProps extends FilterEditorProps {}

export interface DurationEditorInnerProps extends DurationEditorProps, InjectedTranslationProps {}

const dummyFn = () => {
    /* intentionally left blank */
};

export const DurationEditorComponent: React.FC<DurationEditorInnerProps> = ({
    t,
    i18n,
    onChange,
    operation,
    dataId,
    ...restProps
}) => {
    const [filterInputValue, setFilterInputValue] = React.useState<Duration | undefined>();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);
    const [popupInputValue, setPopupInputValue] = React.useState<string | undefined>();

    React.useEffect(() => {
        const inputValue = getInputValue(
            restProps.value ? (JSON.parse(restProps.value) as FilterDurationValue) : undefined
        );
        setFilterInputValue(inputValue);
    }, [restProps.value]);

    const applyFilter = React.useCallback(
        (value: Duration | undefined) => {
            const filterValue = createFilterValue(value);
            if (filterValue) {
                onChange(JSON.stringify(filterValue));
            } else {
                onChange('');
            }
        },
        [onChange]
    );

    const isValid = React.useMemo(() => {
        return popupInputValue !== invalidDurationValue && moment.duration(popupInputValue).isValid();
    }, [popupInputValue]);

    const openPopup = React.useCallback(
        (evt: React.MouseEvent<HTMLElement>) => {
            setPopupInputValue(filterInputValue?.toISOString());
            setAnchorEl(evt.currentTarget);
        },
        [setAnchorEl, setPopupInputValue, filterInputValue]
    );
    const handleFocus = React.useCallback(
        (evt: React.MouseEvent<HTMLElement>) => {
            if (!filterInputValue) {
                openPopup(evt);
            }
        },
        [openPopup, filterInputValue]
    );

    const closePopup = React.useCallback(() => {
        setAnchorEl(undefined);
    }, [setAnchorEl]);

    const applyInputValue = React.useCallback(() => {
        const inputValue = popupInputValue ? moment.duration(popupInputValue) : undefined;
        setFilterInputValue(inputValue);
        applyFilter(inputValue);
        closePopup();
    }, [setFilterInputValue, popupInputValue, closePopup, applyFilter]);

    return (
        <>
            <TableFilterRow.Editor
                {...restProps}
                value={filterInputValue ? formatDuration(filterInputValue) : undefined}
                onChange={dummyFn}
                onFocus={handleFocus}
                onClick={openPopup}
                readOnly
            />
            <Popover
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={closePopup}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                disableRestoreFocus
                data-id="duration-popover"
            >
                <DialogContent>
                    <DialogContentText>{t('enter-duration')}</DialogContentText>
                    <DurationInput
                        defaultValue={popupInputValue}
                        fieldName="duration"
                        dataId="duration"
                        hasError={!isValid}
                        errorText={isValid ? undefined : t('wf-field-error-invalid-value')}
                        autoFocus
                        onBlurChanged={setPopupInputValue}
                    />
                </DialogContent>
                <DialogActions>
                    <Button data-id="cancel" onClick={closePopup}>
                        {t('cancel')}
                    </Button>
                    <Button
                        data-id="apply"
                        variant="contained"
                        color="secondary"
                        disabled={!isValid}
                        onClick={applyInputValue}
                    >
                        {t('apply')}
                    </Button>
                </DialogActions>
            </Popover>
        </>
    );
};
