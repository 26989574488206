import { Grid, Typography } from '@mui/material';
import * as React from 'react';

import type { HeaderProps } from './models';

const maxSizeOfColumn = 12;
const sizeOfMulticolumn = 4;

const Header: React.FC<HeaderProps> = (props) => {
    const { headers, dataId, isMulticolumn, classes } = props;

    return (
        <Grid container data-id={`${dataId}-list-header`} className={classes.headerBox}>
            {headers?.map((e: string, index: number) => (
                <Grid
                    data-id={`${dataId}-list-header-value-${index}`}
                    item
                    xs={isMulticolumn ? sizeOfMulticolumn : maxSizeOfColumn}
                    key={`key-${dataId}-list-header-value-${e}`}
                    className={classes.headerText}
                >
                    <Typography>{e}</Typography>
                </Grid>
            ))}
        </Grid>
    );
};

export default Header;
