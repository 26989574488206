import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';

import { requiredDisplayUserPreferences, withDisplayPreferences } from '~/components/DisplayPreferences';
import { ensureUserPreferences } from '~/components/UserPreferences';

import { ensureThemeHoc } from './component';

export const ensureTheme = (): ComponentEnhancer<unknown, unknown> =>
    compose(ensureUserPreferences([requiredDisplayUserPreferences]), withDisplayPreferences(), ensureThemeHoc());
