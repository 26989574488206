import type {
    ConversationMessagesResponse,
    ConversationNotification,
    ConversationProfile,
    ConversationProfilesResponse,
    ConversationResponse,
    ConversationSummariesResponse,
    ConversationSummary,
    MarkAllMessagesAsReadResponse,
    OptimisticOutgoingConversationMessage,
    TranslateMessageResponse,
    ValidationFailedResponse,
    WorkflowFormDefinitionResponse,
    WorkflowFormDefinitionsResponse,
} from '~/services/ApiClient';
import type {
    Action,
    ActionMeta,
    BaseAction,
    Fulfilled,
    FulfilledMeta,
    Pending,
    PendingMeta,
    Rejected,
    RejectedMeta,
} from '~/types';

export enum ActionTypePrefixes {
    CONVERSATIONS = 'CONVERSATIONS',
    CONVERSATION = 'CONVERSATION',
    CONVERSATIONMESSAGE_SEND = 'CONVERSATIONMESSAGE_SEND',
    CONVERSATION_MARKMESSAGEASREAD = 'CONVERSATION_MARKMESSAGEASREAD',
    CONVERSATION_MARKMESSAGEASUNREAD = 'CONVERSATION_MARKMESSAGEASUNREAD',
    CONVERSATION_MARKALLMESSAGESASREAD = 'CONVERSATION_MARKALLMESSAGESASREAD',
    CONVERSATIONMESSAGES = 'CONVERSATIONMESSAGES',
    CONVERSATIONMESSAGE_TRANSLATE = 'CONVERSATIONMESSAGE_TRANSLATE',
    WORKFLOWFORMDEFINITIONS_LATEST = 'WORKFLOWFORMDEFINITIONS_LATEST',
    WORKFLOWFORMDEFINITION = 'WORKFLOWFORMDEFINITION',
    CONVERSATIONPROFILES = 'CONVERSATIONPROFILES',
    CONVERSATIONPROFILE_CREATE = 'CONVERSATIONPROFILE_CREATE',
    CONVERSATIONPROFILE_UPDATE = 'CONVERSATIONPROFILE_UPDATE',
    CONVERSATIONPROFILE_DELETE = 'CONVERSATIONPROFILE_DELETE',
    CONVERSATION_DELETEMESSAGE = 'CONVERSATION_DELETEMESSAGE',
    CONVERSATION_UNDELETEMESSAGE = 'CONVERSATION_UNDELETEMESSAGE',
}

export enum ActionTypeKeys {
    CONVERSATIONS_PENDING = 'CONVERSATIONS_PENDING',
    CONVERSATIONS_FULFILLED = 'CONVERSATIONS_FULFILLED',
    CONVERSATIONS_REJECTED = 'CONVERSATIONS_REJECTED',
    CONVERSATIONS_UPDATE = 'CONVERSATIONS_UPDATE',
    CONVERSATIONS_APPLY_PENDING = 'CONVERSATIONS_APPLY_PENDING',
    CONVERSATION_PENDING = 'CONVERSATION_PENDING',
    CONVERSATION_FULFILLED = 'CONVERSATION_FULFILLED',
    CONVERSATION_REJECTED = 'CONVERSATION_REJECTED',
    CONVERSATION_UPDATE = 'CONVERSATION_UPDATE',
    CONVERSATION_APPLY_PENDING = 'CONVERSATION_APPLY_PENDING',
    CONVERSATION_CLEAR = 'CONVERSATION_CLEAR',
    CONVERSATION_OPEN = 'CONVERSATION_OPEN',
    CONVERSATION_OPEN_SUCCEEDED = 'CONVERSATION_OPEN_SUCCEEDED',
    CONVERSATION_OPEN_REJECTED = 'CONVERSATION_OPEN_REJECTED',
    CONVERSATIONMESSAGE_SEND_PENDING = 'CONVERSATIONMESSAGE_SEND_PENDING',
    CONVERSATIONMESSAGE_SEND_FULFILLED = 'CONVERSATIONMESSAGE_SEND_FULFILLED',
    CONVERSATIONMESSAGE_SEND_REJECTED = 'CONVERSATIONMESSAGE_SEND_REJECTED',
    CONVERSATIONMESSAGE_DISCARD = 'CONVERSATIONMESSAGE_DISCARD',
    CONVERSATIONMESSAGE_TRANSLATE_PENDING = 'CONVERSATIONMESSAGE_TRANSLATE_PENDING',
    CONVERSATIONMESSAGE_TRANSLATE_FULFILLED = 'CONVERSATIONMESSAGE_TRANSLATE_FULFILLED',
    CONVERSATIONMESSAGE_TRANSLATE_REJECTED = 'CONVERSATIONMESSAGE_TRANSLATE_REJECTED',
    CONVERSATIONMESSAGETEXT_CLEARSENDERROR = 'CONVERSATIONMESSAGETEXT_CLEARSENDERROR',
    CONVERSATIONMESSAGETEXT_CHANGEFOCUS = 'CONVERSATIONMESSAGETEXT_CHANGEFOCUS',
    CONVERSATION_MARKMESSAGEASREAD_PENDING = 'CONVERSATION_MARKMESSAGEASREAD_PENDING',
    CONVERSATION_MARKMESSAGEASREAD_FULFILLED = 'CONVERSATION_MARKMESSAGEASREAD_FULFILLED',
    CONVERSATION_MARKMESSAGEASREAD_REJECTED = 'CONVERSATION_MARKMESSAGEASREAD_REJECTED',
    CONVERSATION_MARKMESSAGEASUNREAD_PENDING = 'CONVERSATION_MARKMESSAGEASUNREAD_PENDING',
    CONVERSATION_MARKMESSAGEASUNREAD_FULFILLED = 'CONVERSATION_MARKMESSAGEASUNREAD_FULFILLED',
    CONVERSATION_MARKMESSAGEASUNREAD_REJECTED = 'CONVERSATION_MARKMESSAGEASUNREAD_REJECTED',
    CONVERSATION_MARKALLMESSAGESASREAD_PENDING = 'CONVERSATION_MARKALLMESSAGESASREAD_PENDING',
    CONVERSATION_MARKALLMESSAGESASREAD_FULFILLED = 'CONVERSATION_MARKALLMESSAGESASREAD_FULFILLED',
    CONVERSATION_MARKALLMESSAGESASREAD_REJECTED = 'CONVERSATION_MARKALLMESSAGESASREAD_REJECTED',
    CONVERSATIONMESSAGES_PENDING = 'CONVERSATIONMESSAGES_PENDING',
    CONVERSATIONMESSAGES_FULFILLED = 'CONVERSATIONMESSAGES_FULFILLED',
    CONVERSATIONMESSAGES_REJECTED = 'CONVERSATIONMESSAGES_REJECTED',
    WORKFLOWFORMDEFINITIONS_LATEST_PENDING = 'WORKFLOWFORMDEFINITIONS_LATEST_PENDING',
    WORKFLOWFORMDEFINITIONS_LATEST_FULFILLED = 'WORKFLOWFORMDEFINITIONS_LATEST_FULFILLED',
    WORKFLOWFORMDEFINITIONS_LATEST_REJECTED = 'WORKFLOWFORMDEFINITIONS_LATEST_REJECTED',
    WORKFLOWFORMDEFINITION_PENDING = 'WORKFLOWFORMDEFINITION_PENDING',
    WORKFLOWFORMDEFINITION_FULFILLED = 'WORKFLOWFORMDEFINITION_FULFILLED',
    WORKFLOWFORMDEFINITION_REJECTED = 'WORKFLOWFORMDEFINITION_REJECTED',
    CONVERSATIONPROFILES_PENDING = 'CONVERSATIONPROFILES_PENDING',
    CONVERSATIONPROFILES_FULFILLED = 'CONVERSATIONPROFILES_FULFILLED',
    CONVERSATIONPROFILES_REJECTED = 'CONVERSATIONPROFILES_REJECTED',
    CONVERSATIONPROFILE_CREATE_PENDING = 'CONVERSATIONPROFILE_CREATE_PENDING',
    CONVERSATIONPROFILE_CREATE_FULFILLED = 'CONVERSATIONPROFILE_CREATE_FULFILLED',
    CONVERSATIONPROFILE_CREATE_REJECTED = 'CONVERSATIONPROFILE_CREATE_REJECTED',
    CONVERSATIONPROFILE_UPDATE_PENDING = 'CONVERSATIONPROFILE_UPDATE_PENDING',
    CONVERSATIONPROFILE_UPDATE_FULFILLED = 'CONVERSATIONPROFILE_UPDATE_FULFILLED',
    CONVERSATIONPROFILE_UPDATE_REJECTED = 'CONVERSATIONPROFILE_UPDATE_REJECTED',
    CONVERSATIONPROFILE_DELETE_PENDING = 'CONVERSATIONPROFILE_DELETE_PENDING',
    CONVERSATIONPROFILE_DELETE_FULFILLED = 'CONVERSATIONPROFILE_DELETE_FULFILLED',
    CONVERSATIONPROFILE_DELETE_REJECTED = 'CONVERSATIONPROFILE_DELETE_REJECTED',
    CONVERSATION_DELETEMESSAGE_PENDING = 'CONVERSATION_DELETEMESSAGE_PENDING',
    CONVERSATION_DELETEMESSAGE_FULFILLED = 'CONVERSATION_DELETEMESSAGE_FULFILLED',
    CONVERSATION_DELETEMESSAGE_REJECTED = 'CONVERSATION_DELETEMESSAGE_REJECTED',
    CONVERSATION_UNDELETEMESSAGE_PENDING = 'CONVERSATION_UNDELETEMESSAGE_PENDING',
    CONVERSATION_UNDELETEMESSAGE_FULFILLED = 'CONVERSATION_UNDELETEMESSAGE_FULFILLED',
    CONVERSATION_UNDELETEMESSAGE_REJECTED = 'CONVERSATION_UNDELETEMESSAGE_REJECTED',
}

export type ConversationsPayload = Readonly<ConversationSummariesResponse>;

export type ConversationPayload = Readonly<ConversationResponse>;

export type ConversationMessagesPayload = Readonly<ConversationMessagesResponse>;

export type WorkflowFormDefinitionsPayload = Readonly<WorkflowFormDefinitionsResponse>;

export type WorkflowFormDefinitionPayload = Readonly<WorkflowFormDefinitionResponse>;

export type MarkAllMessagesAsReadPayload = Readonly<MarkAllMessagesAsReadResponse>;

export type ConversationProfilesPayload = Readonly<ConversationProfilesResponse>;

export interface SendConversationMessageMeta {
    vehicleId: number;
    optimisticMessage: OptimisticOutgoingConversationMessage;
}

export interface MessageActionTypeMeta {
    vehicleId: number;
    messageId: number;
}

export interface TranslateMessageActionPayload extends TranslateMessageResponse {}

export interface DiscardConversationMessageMeta {
    vehicleId: number;
    message: OptimisticOutgoingConversationMessage;
}

export interface ConversationProfileActionTypeMeta {
    item: ConversationProfile;
}

export interface ConversationProfileActionEditTypeMeta {
    item: ConversationProfile;
    id: number;
}

export type ActionTypes =
    | Pending<ActionTypeKeys.CONVERSATIONS_PENDING>
    | Fulfilled<ActionTypeKeys.CONVERSATIONS_FULFILLED, ConversationsPayload>
    | Rejected<ActionTypeKeys.CONVERSATIONS_REJECTED>
    | Action<ActionTypeKeys.CONVERSATIONS_UPDATE, ConversationSummary>
    | ActionMeta<ActionTypeKeys.CONVERSATION_OPEN_SUCCEEDED, undefined, number>
    | ActionMeta<ActionTypeKeys.CONVERSATION_OPEN_REJECTED, undefined, number>
    | BaseAction<ActionTypeKeys.CONVERSATIONS_APPLY_PENDING>
    | PendingMeta<ActionTypeKeys.CONVERSATION_PENDING, number>
    | FulfilledMeta<ActionTypeKeys.CONVERSATION_FULFILLED, ConversationPayload, number>
    | RejectedMeta<ActionTypeKeys.CONVERSATION_REJECTED, undefined, number>
    | ActionMeta<ActionTypeKeys.CONVERSATION_APPLY_PENDING, undefined, number>
    | ActionMeta<ActionTypeKeys.CONVERSATION_CLEAR, undefined, number>
    | ActionMeta<ActionTypeKeys.CONVERSATION_UPDATE, ConversationNotification, number>
    | ActionMeta<ActionTypeKeys.CONVERSATION_OPEN, undefined, number>
    | PendingMeta<ActionTypeKeys.CONVERSATIONMESSAGE_SEND_PENDING, SendConversationMessageMeta>
    | FulfilledMeta<ActionTypeKeys.CONVERSATIONMESSAGE_SEND_FULFILLED, undefined, SendConversationMessageMeta>
    | RejectedMeta<
          ActionTypeKeys.CONVERSATIONMESSAGE_SEND_REJECTED,
          ValidationFailedResponse | unknown,
          SendConversationMessageMeta
      >
    | ActionMeta<ActionTypeKeys.CONVERSATIONMESSAGE_DISCARD, undefined, DiscardConversationMessageMeta>
    | PendingMeta<ActionTypeKeys.CONVERSATIONMESSAGE_TRANSLATE_PENDING, MessageActionTypeMeta>
    | FulfilledMeta<
          ActionTypeKeys.CONVERSATIONMESSAGE_TRANSLATE_FULFILLED,
          TranslateMessageActionPayload,
          MessageActionTypeMeta
      >
    | RejectedMeta<ActionTypeKeys.CONVERSATIONMESSAGE_TRANSLATE_REJECTED, undefined, MessageActionTypeMeta>
    | ActionMeta<ActionTypeKeys.CONVERSATIONMESSAGETEXT_CLEARSENDERROR, undefined, number>
    | ActionMeta<ActionTypeKeys.CONVERSATIONMESSAGETEXT_CHANGEFOCUS, boolean, number>
    | PendingMeta<ActionTypeKeys.CONVERSATION_MARKMESSAGEASREAD_PENDING, MessageActionTypeMeta>
    | FulfilledMeta<ActionTypeKeys.CONVERSATION_MARKMESSAGEASREAD_FULFILLED, undefined, MessageActionTypeMeta>
    | RejectedMeta<ActionTypeKeys.CONVERSATION_MARKMESSAGEASREAD_REJECTED, undefined, MessageActionTypeMeta>
    | PendingMeta<ActionTypeKeys.CONVERSATION_MARKMESSAGEASUNREAD_PENDING, MessageActionTypeMeta>
    | FulfilledMeta<ActionTypeKeys.CONVERSATION_MARKMESSAGEASUNREAD_FULFILLED, undefined, MessageActionTypeMeta>
    | RejectedMeta<ActionTypeKeys.CONVERSATION_MARKMESSAGEASUNREAD_REJECTED, undefined, MessageActionTypeMeta>
    | PendingMeta<ActionTypeKeys.CONVERSATION_MARKALLMESSAGESASREAD_PENDING, number>
    | FulfilledMeta<ActionTypeKeys.CONVERSATION_MARKALLMESSAGESASREAD_FULFILLED, MarkAllMessagesAsReadPayload, number>
    | RejectedMeta<ActionTypeKeys.CONVERSATION_MARKALLMESSAGESASREAD_REJECTED, undefined, number>
    | PendingMeta<ActionTypeKeys.CONVERSATIONMESSAGES_PENDING, number>
    | FulfilledMeta<ActionTypeKeys.CONVERSATIONMESSAGES_FULFILLED, ConversationMessagesPayload, number>
    | RejectedMeta<ActionTypeKeys.CONVERSATIONMESSAGES_REJECTED, undefined, number>
    | Pending<ActionTypeKeys.WORKFLOWFORMDEFINITIONS_LATEST_PENDING>
    | Fulfilled<ActionTypeKeys.WORKFLOWFORMDEFINITIONS_LATEST_FULFILLED, WorkflowFormDefinitionsPayload>
    | Rejected<ActionTypeKeys.WORKFLOWFORMDEFINITIONS_LATEST_REJECTED, undefined>
    | PendingMeta<ActionTypeKeys.WORKFLOWFORMDEFINITION_PENDING, number>
    | FulfilledMeta<ActionTypeKeys.WORKFLOWFORMDEFINITION_FULFILLED, WorkflowFormDefinitionPayload, number>
    | RejectedMeta<ActionTypeKeys.WORKFLOWFORMDEFINITION_REJECTED, undefined, number>
    | Pending<ActionTypeKeys.CONVERSATIONPROFILES_PENDING>
    | Fulfilled<ActionTypeKeys.CONVERSATIONPROFILES_FULFILLED, ConversationProfilesPayload>
    | Rejected<ActionTypeKeys.CONVERSATIONPROFILES_REJECTED, undefined>
    | PendingMeta<ActionTypeKeys.CONVERSATIONPROFILE_CREATE_PENDING, ConversationProfile>
    | FulfilledMeta<
          ActionTypeKeys.CONVERSATIONPROFILE_CREATE_FULFILLED,
          ConversationProfile,
          ConversationProfileActionTypeMeta
      >
    | RejectedMeta<ActionTypeKeys.CONVERSATIONPROFILE_CREATE_REJECTED, undefined, ConversationProfileActionTypeMeta>
    | PendingMeta<ActionTypeKeys.CONVERSATIONPROFILE_UPDATE_PENDING, ConversationProfileActionEditTypeMeta>
    | FulfilledMeta<
          ActionTypeKeys.CONVERSATIONPROFILE_UPDATE_FULFILLED,
          undefined,
          ConversationProfileActionEditTypeMeta
      >
    | RejectedMeta<ActionTypeKeys.CONVERSATIONPROFILE_UPDATE_REJECTED, undefined, ConversationProfileActionEditTypeMeta>
    | PendingMeta<ActionTypeKeys.CONVERSATIONPROFILE_DELETE_PENDING, number>
    | FulfilledMeta<ActionTypeKeys.CONVERSATIONPROFILE_DELETE_FULFILLED, undefined, number>
    | RejectedMeta<ActionTypeKeys.CONVERSATIONPROFILE_DELETE_REJECTED, undefined, number>
    | PendingMeta<ActionTypeKeys.CONVERSATION_DELETEMESSAGE_PENDING, MessageActionTypeMeta>
    | FulfilledMeta<ActionTypeKeys.CONVERSATION_DELETEMESSAGE_REJECTED, undefined, MessageActionTypeMeta>
    | RejectedMeta<ActionTypeKeys.CONVERSATION_DELETEMESSAGE_FULFILLED, undefined, MessageActionTypeMeta>
    | PendingMeta<ActionTypeKeys.CONVERSATION_UNDELETEMESSAGE_PENDING, MessageActionTypeMeta>
    | FulfilledMeta<ActionTypeKeys.CONVERSATION_UNDELETEMESSAGE_REJECTED, undefined, MessageActionTypeMeta>
    | RejectedMeta<ActionTypeKeys.CONVERSATION_UNDELETEMESSAGE_FULFILLED, undefined, MessageActionTypeMeta>;
