import { getValueByPath } from '~/common';
import type { MonitoringTrailerEntry, MonitoringVehicleEntry } from '~/data/monitoring';

const getPopupKey = <T>(entry: T) => {
    const isVehicle = getValueByPath(entry, 'vehicle.id');

    if (isVehicle) {
        const typedEntry = entry as MonitoringVehicleEntry;

        return `vehicle:${typedEntry.vehicle.id}-driver:${
            typedEntry.vehicleDriverHoursStatus?.driverHoursStatus?.driver.id ?? 'none'
        }-codriver:${typedEntry.vehicleDriverHoursStatus?.coDriverHoursStatus?.driver.id ?? 'none'}-trailer:${
            typedEntry.trailerConnection?.trailerReference.id ?? 'none'
        }`;
    }

    const trailerEntry = entry as MonitoringTrailerEntry;
    return `trailer:${trailerEntry.trailer.id}-vehicle:${
        trailerEntry.vehicleConnection?.vehicle?.id ?? 'none'
    }-driver:${
        trailerEntry.vehicleConnection?.vehicleDriverHoursStatus?.driverHoursStatus?.driver.id ?? 'none'
    }-codriver:${trailerEntry.vehicleConnection?.vehicleDriverHoursStatus?.coDriverHoursStatus?.driver.id ?? 'none'}`;
};

export { getPopupKey };
