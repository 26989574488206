import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';
import type { PositionStatus } from '~/services/ApiClient';

import { MiniMapSectionFactoryComponent } from './component';
import type { GetAssetPositionArgs, MiniMapSectionInnerProps, MiniMapSectionProps } from './models';
import { MiniMapSectionStyleRules } from './styles';

export type { MiniMapSectionProps } from './models';

export const MiniMapSectionFactory = <T>(
    getAssetIdentifier: (entry: T) => number,
    getAssetPositions: (args: GetAssetPositionArgs) => Promise<PositionStatus[]>,
    entryEquals: (entryA: T, entryB: T) => boolean
): React.ComponentType<MiniMapSectionProps<T>> =>
    compose<MiniMapSectionInnerProps<T>, MiniMapSectionProps<T>>(
        setDisplayName('MiniMapSection'),
        withTranslation(),
        withStyles(MiniMapSectionStyleRules, { withTheme: true })
    )(MiniMapSectionFactoryComponent(getAssetIdentifier, getAssetPositions, entryEquals));
