import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';

import { protectedComponentStoreStateSelector } from '~/selectors';

import type { AuthenticationStoreState } from '../../reducers';
import { authenticationStoreStateSelector } from '../../selectors';

import type { LogoutDispatchProps, LogoutInnerProps, LogoutProps } from './component';
import { LogoutComponent } from './component';
import { mapDispatchToLogoutProps, mapStateToProps } from './redux';

const stateToPropsMapper = createSelector(
    authenticationStoreStateSelector,
    protectedComponentStoreStateSelector,
    mapStateToProps
);

export const Logout = compose<LogoutInnerProps, LogoutProps>(
    setDisplayName('Logout'),
    connect<AuthenticationStoreState, LogoutDispatchProps, LogoutProps>(stateToPropsMapper, mapDispatchToLogoutProps),
    withRouter
)(LogoutComponent);
