import { Avatar, styled } from '@mui/material';
import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

type EbsSectionContentClassKey = 'captionIcon' | 'delimiter' | 'distanceElement' | 'timeElement' | 'elementAligner';

const EbsSectionContentStyleRules: StyleRulesCallback<Theme, {}, EbsSectionContentClassKey> = (theme: Theme) =>
    createStyles({
        captionIcon: {
            fontSize: theme.typography.pxToRem(14),
            marginRight: theme.spacing(0.5),
        },
        delimiter: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
        distanceElement: {
            paddingBottom: 0,
        },
        timeElement: {
            paddingTop: 0,
        },
        elementAligner: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(),
        },
    });

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: 40,
    height: 40,
    alignSelf: 'center',
    backgroundColor: theme.palette.background.default,
    overflow: 'visible',
}));

export { StyledAvatar as Avatar, EbsSectionContentStyleRules };
export type { EbsSectionContentClassKey };
