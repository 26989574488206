import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { Feature, withFeatureFlagInterceptor } from '~/components/FeatureFlags';
import { withTranslation } from '~/components/LanguageSelector';

import type { ContinuousDrivingInnerProps, ContinuousDrivingProps } from './component';
import { ContinuousDrivingComponent } from './component';
import { ContinuousDrivingClassStyleRules } from './styles';

export const ContinuousDriving = compose<ContinuousDrivingInnerProps, ContinuousDrivingProps>(
    setDisplayName('ContinuousDriving'),
    withFeatureFlagInterceptor(Feature.DriverForecast),
    withTranslation(),
    withStyles(ContinuousDrivingClassStyleRules)
)(ContinuousDrivingComponent);
