import type { SingleTFunction } from '~/components/LanguageSelector';
import type { AssetRecipient, Depot } from '~/services/ApiClient';
import { AssetRecipientType, AssetType } from '~/services/ApiClient';

const getIds = (depot: Depot, assetType: AssetType) => {
    switch (assetType) {
        case AssetType.Vehicle:
            return depot.vehicleIds || [];
        case AssetType.Driver:
            return depot.driverIds || [];
        default:
            throw Error('Unkown asset type');
    }
};

interface GetAssetLengthArgs {
    depot: Depot;
    t: SingleTFunction;
    assetType: AssetType;
}

const getAssetsLength = ({ assetType, depot, t }: GetAssetLengthArgs) => {
    switch (assetType) {
        case AssetType.Vehicle:
            return t('number-of-vehicles', { numberOfVehicles: getIds(depot, assetType).length });
        case AssetType.Driver:
            return t('number-of-drivers', { numberOfDrivers: getIds(depot, assetType).length });
        default:
            return t('unknown');
    }
};

interface GetAssetEnabledArgs {
    depot: Depot;
    assetType: AssetType;
    assetPredicate?: (assetId: number) => boolean;
}

const getAssetsEnabled = ({ assetType, depot, assetPredicate }: GetAssetEnabledArgs) => {
    switch (assetType) {
        case AssetType.Vehicle:
            return assetPredicate ? depot.vehicleIds?.filter(assetPredicate) : depot.vehicleIds;
        case AssetType.Driver:
            return assetPredicate ? depot.driverIds?.filter(assetPredicate) : depot.driverIds;
        default:
            throw Error('Unkown asset type');
    }
};

interface MapDepotOptionsArgs {
    depots: Depot[];
    t: SingleTFunction;
    assetType: AssetType;
    assetPredicate?: (assetId: number) => boolean;
}

export const mapDepotOptions = ({ assetType, depots, t, assetPredicate }: MapDepotOptionsArgs): AssetRecipient[] =>
    depots.map((depot) => {
        const assetsEnabled = getAssetsEnabled({ depot, assetType, assetPredicate });

        return {
            name: depot.name,
            ids: getIds(depot, assetType),
            type: AssetRecipientType.DEPOT,
            enabledAssetIds: assetsEnabled,
            secondaryTextFirst: getAssetsLength({ depot, t, assetType }),
            secondaryTextSecond: t('number-of-assets-eligibles', {
                assetType: t(assetType),
                numberOfAssets: assetsEnabled?.length,
            }),
            secondaryTextThird: undefined,
        };
    });
