import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { StopPositionGroupInnerProps, StopPositionGroupProps } from './component';
import { StopPositionGroupComponent } from './component';
import { StopPositionGroupStyleRules } from './styles';

export const StopPositionGroup = compose<StopPositionGroupInnerProps, StopPositionGroupProps>(
    setDisplayName('StopPositionGroup'),
    withTranslation(),
    withStyles(StopPositionGroupStyleRules)
)(StopPositionGroupComponent);
