import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import { zoomPopupWidth } from '../../styles';

export type ActivitiesBarClassKey = 'root' | 'activitySurface' | 'cursor';

export const ActivitiesBarStyleRules: StyleRulesCallback<Theme, {}, ActivitiesBarClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            width: zoomPopupWidth,
            background: theme.palette.divider,
            height: 40,
            overflow: 'hidden',
            position: 'relative',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        activitySurface: {
            position: 'absolute',
            bottom: 0,
        },
        cursor: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: 40,
            zIndex: 10,
            border: '2px solid red',
        },
    });
