import type { NumericDictionary } from '~/libs/utility';
import { mapValues } from '~/libs/utility';
import type {
    ConversationSummary,
    ResolvedConversationSummary,
    WorkflowFormDefinition,
    WorkflowFormDefinitionStatus,
} from '~/services/ApiClient';

import { resolveConversationMessage } from '../../reducers.resolveConversationMessage';

export const resolveConversationSummary = (
    conversation: ConversationSummary,
    workflowFormDefinitions: NumericDictionary<WorkflowFormDefinition | WorkflowFormDefinitionStatus> | undefined
): ResolvedConversationSummary => {
    return {
        ...conversation,
        lastMessage: conversation.lastMessage
            ? resolveConversationMessage(conversation.lastMessage, workflowFormDefinitions)
            : conversation.lastMessage,
    };
};

export const resolveConversationSummaryDictionary = (
    conversations: NumericDictionary<ConversationSummary>,
    workflowFormDefinitions: NumericDictionary<WorkflowFormDefinition | WorkflowFormDefinitionStatus> | undefined
): NumericDictionary<ResolvedConversationSummary> => {
    return mapValues(conversations, (conversation) => {
        return resolveConversationSummary(conversation, workflowFormDefinitions);
    });
};
