import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { NumericWorkflowFieldInnerProps, NumericWorkflowFieldProps } from './component';
import { NumericWorkflowFieldComponent } from './component';
import { NumericWorkflowFieldStyleRules } from './styles';

export const NumericWorkflowField = compose<NumericWorkflowFieldInnerProps, NumericWorkflowFieldProps>(
    setDisplayName('NumericWorkflowField'),
    withStyles(NumericWorkflowFieldStyleRules),
    withTranslation()
)(NumericWorkflowFieldComponent);
