import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { Action, BaseAction } from '~/types';

export enum ActionTypeKeys {
    TACHOFILES_CLEAR_DATA = 'TACHOFILES_CLEAR_DATA',
    TACHOFILES_CHANGE_DATETIMERANGE = 'TACHOFILES_CHANGE_DATETIMERANGE',
}

export type ActionTypes =
    | BaseAction<ActionTypeKeys.TACHOFILES_CLEAR_DATA>
    | Action<ActionTypeKeys.TACHOFILES_CHANGE_DATETIMERANGE, DateTimeRange>;
