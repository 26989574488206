import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withSceneAnalytics } from '~/components/Analytics';

import type { ZoomPopupInnerProps, ZoomPopupProps } from './component';
import { ZoomPopupComponent } from './component';
import { ZoomPopupStyleRules } from './styles';

export { graphDurationInSeconds } from './constants';
export * from './models';

export const ZoomPopup = compose<ZoomPopupInnerProps, ZoomPopupProps>(
    setDisplayName('ZoomPopup'),
    withStyles(ZoomPopupStyleRules),
    withSceneAnalytics('driveractivitiespopup', { reportDuration: true, reportLoaded: false })
)(ZoomPopupComponent);
