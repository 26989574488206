import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { WorkflowMessageContentComponent } from './component';
import type { WorkflowMessageContentInnerProps, WorkflowMessageContentProps } from './models';
import { WorkflowMessageContentStyleRules } from './styles';

export const WorkflowMessageContent = compose<WorkflowMessageContentInnerProps, WorkflowMessageContentProps>(
    setDisplayName('WorkflowMessageContent'),
    withStyles(WorkflowMessageContentStyleRules)
)(WorkflowMessageContentComponent);
