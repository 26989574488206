import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DaySeparatorClassKey = 'content';

export const DaySeparatorStyleRules: StyleRulesCallback<Theme, {}, DaySeparatorClassKey> = () =>
    createStyles({
        content: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    });
