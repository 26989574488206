const ACTIVITY_TYPES = {
    REST: 'rest',
    WORK: 'work',
    DRIVE: 'drive',
    AVAILABLE: 'available',
} as const;

const ACTIVITY_TYPES_CATEGORIES_ORDER = [
    ACTIVITY_TYPES.AVAILABLE,
    ACTIVITY_TYPES.DRIVE,
    ACTIVITY_TYPES.WORK,
    ACTIVITY_TYPES.REST,
];

export { ACTIVITY_TYPES, ACTIVITY_TYPES_CATEGORIES_ORDER };
