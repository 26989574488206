import React from 'react';
import { useLocation } from 'react-router-dom';

import { AuthTemplate } from '~/components/AuthTemplate';

import { RequestResetPassword } from './components/RequestResetPassword';
import { ResetPasswordForm } from './components/ResetPasswordForm';
import { getTokenFromUrl } from './getTokenFromUrl';

export interface ResetPasswordProps {}

export interface ResetPasswordInnerProps extends ResetPasswordProps {}

export const ResetPasswordComponent: React.FC<ResetPasswordInnerProps> = () => {
    const { search } = useLocation();

    const token = React.useMemo(() => {
        return getTokenFromUrl(search);
    }, [search]);

    const getRenderContent = () => {
        if (token !== '') {
            return <ResetPasswordForm token={token} />;
        } else {
            return <RequestResetPassword />;
        }
    };

    return <AuthTemplate hideLanguageSelector>{getRenderContent()}</AuthTemplate>;
};
