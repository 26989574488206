import { createAction } from 'redux-actions';

import type { Feature } from '../models';

import type { SetFeatureFlagsPayload } from './actionTypes';
import { ActionTypeKeys } from './actionTypes';

export const toggleFeatureAction = createAction<undefined, Feature, Feature>(
    ActionTypeKeys.FEATURES_TOGGLE,
    (_) => undefined,
    (feature) => feature
);

export const resetFeaturesToDefaultValuesAction = createAction(ActionTypeKeys.FEATURES_RESETTODEFAULT);

export const enableAllFeaturesAction = createAction(ActionTypeKeys.FEATURES_ENABLEALL);

export const disableAllFeaturesAction = createAction(ActionTypeKeys.FEATURES_DISABLEALL);

export const setFeatureFlagEnabled = createAction<SetFeatureFlagsPayload, boolean>(
    ActionTypeKeys.FEATURES_SETFEATUREFLAGS,
    (enabled) => ({
        enabled,
    })
);
