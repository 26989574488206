import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { AbortRtdsSessionDialogInnerProps, AbortRtdsSessionDialogProps } from './component';
import { AbortRtdsSessionDialogComponent } from './component';

export const AbortRtdsSessionDialog = compose<AbortRtdsSessionDialogInnerProps, AbortRtdsSessionDialogProps>(
    setDisplayName('AbortRtdsSessionDialog'),
    withTranslation()
)(AbortRtdsSessionDialogComponent);
