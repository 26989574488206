import { createAction } from 'redux-actions';

import type { AdminDriverEdit, GetAdminDriversResponse } from '~/services/ApiClient';
import {
    ApiClient,
    ApiException,
    SaveAdminDriverRequest,
    ServerResultStatus,
    createApiModel,
    retryableRequest,
} from '~/services/ApiClient';

import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

const retrieveAdminDriverExecutor = (): Promise<GetAdminDriversResponse> =>
    retryableRequest(() => ApiClient.getAdminDrivers());

const retrieveDriverAdministrationAction = createAction<Promise<GetAdminDriversResponse>>(
    ActionTypePrefixes.ADMINDRIVERGET,
    retrieveAdminDriverExecutor
);

const updateAdminDriverExecutor = async (item: AdminDriverEdit): Promise<ServerResultStatus> => {
    try {
        await ApiClient.updateAdminDriver(createApiModel(SaveAdminDriverRequest, { item }));
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

const createAdminDriverExecutor = async (item: AdminDriverEdit): Promise<ServerResultStatus> => {
    try {
        await ApiClient.createAdminDriver(createApiModel(SaveAdminDriverRequest, { item }));
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

const deleteAdminDriverExecutor = async (driverId: number): Promise<ServerResultStatus> => {
    try {
        await ApiClient.deleteAdminDriver(driverId);
        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }
        throw ex;
    }
};

const updateAdminDriverAction = createAction(ActionTypePrefixes.ADMINDRIVERUPDATE, updateAdminDriverExecutor);
const createAdminDriverAction = createAction(ActionTypePrefixes.ADMINDRIVERCREATE, createAdminDriverExecutor);
const updateAdminDriverResetAction = createAction(ActionTypeKeys.ADMINDRIVERUPDATE_RESET);
const retrieveAdminDriverChangelogsAction = createAction(ActionTypePrefixes.ADMINDRIVERCHANGELOGS, (driverId: number) =>
    retryableRequest(() => ApiClient.getDriverAdministrationChangelogs(driverId))
);
const deleteAdminDriverAction = createAction(ActionTypePrefixes.ADMINDRIVERDELETE, deleteAdminDriverExecutor);
const deleteAdminDriverResetAction = createAction(ActionTypeKeys.ADMINDRIVERDELETE_RESET);
const clearDataAction = createAction(ActionTypeKeys.ADMINDRIVER_CLEAR_DATA);

export {
    retrieveAdminDriverExecutor,
    retrieveDriverAdministrationAction,
    updateAdminDriverExecutor,
    createAdminDriverExecutor,
    retrieveAdminDriverChangelogsAction,
    updateAdminDriverAction,
    createAdminDriverAction,
    updateAdminDriverResetAction,
    deleteAdminDriverAction,
    deleteAdminDriverResetAction,
    clearDataAction,
};
