import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { SettingsKey, ensureSettings } from '~/components/EnsureSettings';
import { vehicleAdministrationChangelogsSelector } from '~/scenes/VehicleTypesAdministration/selectors';

import type {
    VehicleTypesAdministrationChangelogsSubpageInnerProps,
    VehicleTypesAdministrationChangelogsSubpageProps,
} from './component';
import { VehicleTypesAdministrationChangelogsSubpageComponent } from './component';
import { mapDispatchProps, mapStateToProps } from './redux';

export { vehicleTypeChangelogsReducer } from './reducers';

export type { VehicleTypeChangelogsStoreState } from './reducers';

export const VehicleTypesAdministrationsChangelogSubpage = compose<
    VehicleTypesAdministrationChangelogsSubpageInnerProps,
    VehicleTypesAdministrationChangelogsSubpageProps
>(
    setDisplayName('VehicleTypesAdministrationChangelogSubpage'),
    ensureSettings([SettingsKey.EMISSION_CLASS, SettingsKey.AXLES, SettingsKey.VEHICLE_TYPE_CATEGORIES]),
    connect(createSelector(vehicleAdministrationChangelogsSelector, mapStateToProps), mapDispatchProps),
    withDisplayPreferences()
)(VehicleTypesAdministrationChangelogsSubpageComponent);
