import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: theme.spacing(0.5, 1.5),
        color: theme.palette.text.secondary,
    },
    toggleIcon: {
        marginRight: theme.spacing(0.5),
    },
    content: {},
}));

export { useStyles };
