import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export type ResetPasswordNoticeClassKeys = 'root' | 'link';

export const useStyles = makeStyles<Theme, {}, ResetPasswordNoticeClassKeys>((theme) => ({
    root: {
        margin: theme.spacing(3, 5),
    },
    link: {
        margin: theme.spacing(3, 0, 0),
        display: 'block',
        textAlign: 'center',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));
