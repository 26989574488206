import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SearchHeaderClassKey =
    | 'root'
    | 'selectButtons'
    | 'selectButton'
    | 'searchInput'
    | 'closeIcon'
    | 'searchIcon';

export const SearchHeaderStyleRules: StyleRulesCallback<Theme, {}, SearchHeaderClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(1, 2),
            backgroundColor: theme.palette.background.default,
        },
        selectButtons: {
            marginTop: theme.spacing(),
            alignSelf: 'normal',
        },
        selectButton: {
            marginRight: theme.spacing(2),
            color: theme.palette.secondary.main,
            textTransform: 'none',
            padding: '4px 6px',
        },
        searchInput: {
            height: '32px',
            borderWidth: 1,
            borderRadius: theme.shape.borderRadius,
            borderStyle: 'solid',
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(0.5, 1),
        },
        searchIcon: {
            color: theme.palette.text.secondary,
        },
        closeIcon: {
            color: theme.palette.text.secondary,
            cursor: 'pointer',
        },
    });
