import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { PositionWorkflowFieldInnerProps, PositionWorkflowFieldProps } from './component';
import { PositionWorkflowFieldComponent } from './component';

export const PositionWorkflowField = compose<PositionWorkflowFieldInnerProps, PositionWorkflowFieldProps>(
    setDisplayName('PositionWorkflowField'),
    withTranslation()
)(PositionWorkflowFieldComponent);
