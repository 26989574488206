import type { QueryTachoFilesRequest, QueryTachoFilesResponse } from '~/services/ApiClient';
import type { FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export interface MessageActionTypeMeta {
    messageId: number;
}

export enum ActionTypePrefixes {
    TACHOFILES_QUERY = 'TACHOFILES_QUERY',
}

export enum ActionTypeKeys {
    TACHOFILES_QUERY_PENDING = 'TACHOFILES_QUERY_PENDING',
    TACHOFILES_QUERY_FULFILLED = 'TACHOFILES_QUERY_FULFILLED',
    TACHOFILES_QUERY_REJECTED = 'TACHOFILES_QUERY_REJECTED',
}

export type ActionTypes =
    | PendingMeta<ActionTypeKeys.TACHOFILES_QUERY_PENDING, QueryTachoFilesRequest>
    | FulfilledMeta<
          ActionTypeKeys.TACHOFILES_QUERY_FULFILLED,
          Readonly<QueryTachoFilesResponse>,
          QueryTachoFilesRequest
      >
    | RejectedMeta<ActionTypeKeys.TACHOFILES_QUERY_REJECTED, undefined, QueryTachoFilesRequest>;
