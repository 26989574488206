import type { Classification } from '@fv/translations';

import type { UnitSystem } from '../models';
import { getVolumeLiquidUnit } from '../volume';
import { getWeightUnit } from '../weight';

import { CNG_CLASSIFICATION_VALUE } from './const';

const getFuelUnit = (unitSystem: UnitSystem, fuelType: Classification) => {
    switch (fuelType.key) {
        case CNG_CLASSIFICATION_VALUE:
            return getWeightUnit(unitSystem);
        default:
            return getVolumeLiquidUnit(unitSystem);
    }
};

export { getFuelUnit };
