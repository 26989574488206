import { ApiException } from './generated';
import { ServerResultStatus } from './models';

export const submitServerRequest = async <T>(fn: () => Promise<T>): Promise<ServerResultStatus> => {
    try {
        await fn();

        return ServerResultStatus.OK;
    } catch (ex) {
        if (ApiException.isApiException(ex) && ex.status === 400) {
            return ServerResultStatus.BADREQUEST;
        }

        throw ex;
    }
};
