import { defaultProps } from 'react-recompose';

import type { ChangelogAttributeDefinition } from '~/common/components/ChangelogsSubpage';
import { ClassificationFormatter, ClassificationsFormatter, DateTimeFormatter } from '~/components/Formatters';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { ResolvedRtdsScheduleChangelogChanges } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';

export const getRtdsScheduleChangelogAttributeDefinitions = (
    t: SingleTFunction
): ChangelogAttributeDefinition<ResolvedRtdsScheduleChangelogChanges>[] => {
    return [
        {
            title: t('rtdsschedules-changelogs-definitions-startDate'),
            valueFormatterComponent: DateTimeFormatter,
            getValue: (changelogChanges) => changelogChanges?.startDate,
        },
        {
            title: t('schedule-frequency'),
            valueFormatterComponent: defaultProps({ t, classificationKey: ClassificationType.RtdsScheduleFrequency })(
                ClassificationFormatter
            ),
            getValue: (changelogChanges) => changelogChanges?.frequency,
        },
        {
            title: t('schedule-requested-tacho-data'),
            valueFormatterComponent: defaultProps({ t, classificationKey: ClassificationType.TachographDataBlocks })(
                ClassificationsFormatter
            ),
            getValue: (changelogChanges) => changelogChanges?.tachoBlocks,
        },
        {
            title: t('rtds-schedule-priority'),
            valueFormatterComponent: defaultProps({ t, classificationKey: ClassificationType.RtdsPriority })(
                ClassificationFormatter
            ),
            getValue: (changelogChanges) => changelogChanges?.priority,
        },
        {
            title: t('rtdsschedules-changelogs-definitions-recurrence'),
            getValue: (changelogChanges) => changelogChanges?.recurrence,
        },
        {
            title: t('rtds-frequency-day-custom'),
            getValue: (changelogChanges) => changelogChanges?.day,
        },
        {
            title: t('rtdsschedules-changelogs-definitions-weekday'),
            valueFormatterComponent: defaultProps({ t, classificationKey: ClassificationType.RtdsWeekDayType })(
                ClassificationFormatter
            ),
            getValue: (changelogChanges) => changelogChanges?.weekDay,
        },
        {
            title: t('rtds-schedule-requested-days'),
            getValue: (changelogChanges) => changelogChanges?.requestedDays,
        },
    ];
};
