import { Button, Snackbar } from '@mui/material';
import type { SnackbarProps } from '@mui/material/Snackbar';
import * as React from 'react';
import type { Subtract } from 'utility-types';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

export interface RetrySnackbarProps {
    onRetry: () => void;
    snackbarProps: Subtract<SnackbarProps, { action?: React.ReactNode }>;
}

export interface RetrySnackbarInnerProps extends RetrySnackbarProps, InjectedTranslationProps {}

export const RetrySnackbarComponent: React.FunctionComponent<RetrySnackbarInnerProps> = (props) => {
    const { t, onRetry, snackbarProps } = props;

    const action = (
        <Button color="secondary" onClick={onRetry} variant="text" data-id="retry">
            {t('retry')}
        </Button>
    );

    return <Snackbar {...snackbarProps} action={action} />;
};
