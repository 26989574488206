import { useEffect, useState } from 'react';
import { z } from 'zod';

import { useDeepCompareEffect } from '~/common/hooks';
import type { VehicleStatus } from '~/services/ApiClient';

import type { UseFuelLevelGraphDataArgs } from './models';

const hasValidValues = (value: VehicleStatus) =>
    z
        .object({
            odometer: z.number().positive(),
            fuelLevel: z.number().positive(),
        })
        .safeParse(value).success;

const useFuelLevelGraphData = ({
    vehicleId,
    vehicleStatus,
    initialItems,
    shouldBeUpdated = true,
}: UseFuelLevelGraphDataArgs) => {
    const [graphData, setGraphData] = useState<VehicleStatus[]>([]);

    useDeepCompareEffect(() => {
        setGraphData(initialItems.filter(hasValidValues));
    }, [initialItems]);

    useEffect(() => {
        const updatedValue = vehicleStatus[vehicleId];

        if (
            hasValidValues(updatedValue) &&
            initialItems.length > 0 &&
            shouldBeUpdated &&
            // Check that the values for the graph do not contain elements with the same fuel level.
            !graphData.some(
                (item) => item.odometer === updatedValue.odometer && item.fuelLevel === updatedValue.fuelLevel
            )
        ) {
            setGraphData((prevData) => [...prevData, updatedValue]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleStatus, vehicleId, shouldBeUpdated]);

    return graphData;
};

export { useFuelLevelGraphData };
