import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { protectedComponentStoreStateSelector } from '~/selectors';

import { singleSignOnStoreStateSelector } from '../../../../selectors';

import { AuthenticateComponent } from './component';
import type { AuthenticateInnerProps, AuthenticateProps } from './models';
import { mapDispatchProps, mapStateToProps } from './redux';

export { authenticateReducer } from './reducers';

const stateToPropsMapper = createSelector(
    protectedComponentStoreStateSelector,
    singleSignOnStoreStateSelector,
    mapStateToProps
);

export const Authenticate = compose<AuthenticateInnerProps, AuthenticateProps>(
    setDisplayName('Authenticate'),
    connect(stateToPropsMapper, mapDispatchProps)
)(AuthenticateComponent);
