interface FormatToInputArgs<T> {
    source: T;
    options?: Partial<{ id: string; key: string; displayName: string }>;
}

const formatToSelect = <T>({ source, options }: FormatToInputArgs<T>) => {
    const { id, displayName, key } = { id: 'id', displayName: 'displayName', key: 'key', ...options };

    if (!Array.isArray(source)) {
        const toArray = Object.values(source as { [s: string]: T });

        return toArray.map((values) => ({
            id: values[id],
            displayName: values[displayName],
            key: String(values[key] || values[id]),
        }));
    }

    return source.map((values) => ({
        id: values[id],
        displayName: values[displayName],
        key: String(values[key] || values[id]),
    }));
};

export { formatToSelect };
