import type { Duration } from 'moment';

import type {
    Address,
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
    DriverActivityType,
    LoginStatus,
    NearestCity,
    Position,
} from '~/services/ApiClient';
import { formatDriverName, formatVehicleName } from '~/services/Formatters';
import { parseOptionalDuration } from '~/services/Parsers';

import type { HistoryDriverEntry } from '../../../../models';

export const getAddressValue = (entry: HistoryDriverEntry): Address | undefined => entry.driverEvent?.location?.address;

export const getAltitudeValue = (entry: HistoryDriverEntry): number | undefined =>
    entry.driverEvent?.location?.position?.altitude;

export const getCityValue = (entry: HistoryDriverEntry): string | undefined =>
    entry.driverEvent?.location?.address?.city;

export const getCountryCodeValue = (entry: HistoryDriverEntry): string | undefined =>
    entry.driverEvent?.location?.address?.countryCode;

export const getStartDateValue = (entry: HistoryDriverEntry): Date | undefined => entry.driverEvent?.startDatetime;

export const getLatitudeValue = (entry: HistoryDriverEntry): number | undefined =>
    entry.driverEvent?.location?.position?.latitude;

export const getLongitudeValue = (entry: HistoryDriverEntry): number | undefined =>
    entry.driverEvent?.location?.position?.longitude;

export const getNearestCityValue = (entry: HistoryDriverEntry): NearestCity | undefined =>
    entry.driverEvent?.location?.nearestCity;

export const getStartOdometerValue = (entry: HistoryDriverEntry): number | undefined =>
    entry.driverEvent?.startOdometer;

export const getEndOdometerValue = (entry: HistoryDriverEntry): number | undefined => entry.driverEvent?.endOdometer;

export const getPostalCodeValue = (entry: HistoryDriverEntry): string | undefined =>
    entry.driverEvent?.location?.address?.postalCode;

export const getPositionValue = (entry: HistoryDriverEntry): Position | undefined =>
    entry.driverEvent?.location?.position;

export const getMsisdnValue = (entry: HistoryDriverEntry): string | undefined => entry.driverEvent?.msisdn;

export const getDriverActivityValue = (entry: HistoryDriverEntry): DriverActivityType | undefined =>
    entry.driverEvent?.completedActivity?.activityType;

export const getDriverSubActivityValue = (entry: HistoryDriverEntry): string | undefined => {
    if (entry.driverEvent?.eventType) {
        return entry.driverEvent.eventType.name;
    }
    return entry.driverEvent?.completedActivity?.subActivityType?.name;
};

export const getDurationValue = (entry: HistoryDriverEntry): Duration | undefined =>
    parseOptionalDuration(entry.driverEvent?.completedActivity?.duration, 'hours');

export const getDistanceValue = (entry: HistoryDriverEntry): number | undefined =>
    entry.driverEvent?.completedActivity?.distance;

export const getLoginStatusValue = (entry: HistoryDriverEntry): LoginStatus | undefined =>
    entry.driverEvent?.completedActivity?.loginStatus;

export const getCoDriverNameValue =
    (driverDisplayNameFormat: DisplayUserPreferencesDriverDisplayFormat) =>
    (entry: HistoryDriverEntry): string | undefined => {
        return entry.driverEvent?.coDriver && formatDriverName(entry.driverEvent.coDriver, driverDisplayNameFormat);
    };

export const getCoDriverIdValue = (entry: HistoryDriverEntry): number | undefined => entry.driverEvent?.coDriver?.id;

export const getVehicleDisplayNameFactory =
    (vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat) =>
    (entry: HistoryDriverEntry): string | undefined =>
        entry.driverEvent?.vehicle && formatVehicleName(entry.driverEvent.vehicle, vehicleDisplayNameFormat);

export const getIsCorrectionValue = (entry: HistoryDriverEntry): boolean | undefined => entry.driverEvent?.isCorrection;
