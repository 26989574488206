import Lens from '@mui/icons-material/Lens';
import { ListItem, ListItemIcon } from '@mui/material';
import type { ListItemProps as MuiListItemProps } from '@mui/material/ListItem';
import type { StyledComponentProps, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC, ReactElement, ReactNode, Ref } from 'react';

import type { TimelineItemClassKey } from './styles';

export interface TimelineItemProps {
    children: ReactNode;
    segmentType: TimelineSegmentType;
    avatar?: ReactElement;
    segmentDotSize?: 'small' | 'medium';
    dataId?: string;
    ariaOwns?: string;
    ListItemProps?: Omit<Partial<MuiListItemProps>, 'button'>;
    highlighted?: boolean;
}

export type TimelineSegmentType = 'hidden' | 'begin' | 'continuous' | 'end' | 'point';
export interface TimelineItemOuterProps extends TimelineItemProps, StyledComponentProps<TimelineItemClassKey> {}
export interface TimelineItemForwardRefProps {
    forwardedRef: Ref<HTMLLIElement>;
}
export interface TimelineItemInnerProps
    extends TimelineItemProps,
        WithStyles<TimelineItemClassKey>,
        TimelineItemForwardRefProps {}

export const TimelineItemComponent: FC<TimelineItemInnerProps> = (props) => {
    const {
        children,
        classes,
        avatar,
        segmentType,
        segmentDotSize,
        dataId,
        ariaOwns,
        ListItemProps,
        highlighted,
        forwardedRef,
    } = props;

    const listItemClassName = classNames(classes.root, {
        [classes.highlighted]: highlighted,
    });
    const timelineClassName = classNames(classes.timelineSegment, classes[`${segmentType}Segment`]);

    return (
        <ListItem
            {...ListItemProps}
            disableGutters
            data-id={dataId}
            aria-owns={ariaOwns}
            ref={forwardedRef}
            className={listItemClassName}
        >
            <ListItemIcon className={classes.avatarContainer}>{avatar ?? null}</ListItemIcon>
            <div className={classes.timelineContainer}>
                <div className={timelineClassName} />
                {segmentDotSize && (
                    <div className={classes.timelineIconContainer}>
                        <Lens className={classes[`${segmentDotSize}Icon`]} />
                    </div>
                )}
            </div>
            <div className={classes.content}>{children}</div>
        </ListItem>
    );
};
