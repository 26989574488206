import type { ResolvedTrailersAdministration } from '~/data/trailersAdministration';
import { isUndefined } from '~/libs/utility';

import type { NamedTrailerReference, PojoModel } from '../ApiClient';
import { DisplayUserPreferencesTrailerDisplayFormat } from '../ApiClient';

const composeTrailerName = (alias: string, unitId?: string) => {
    if (alias && unitId) {
        return `${alias} / ${unitId}`;
    }

    return alias || unitId;
};

export const formatTrailerName = (
    trailer: PojoModel<NamedTrailerReference> | ResolvedTrailersAdministration,
    format: DisplayUserPreferencesTrailerDisplayFormat
): string => {
    let trailerName: string | undefined;

    switch (format) {
        case DisplayUserPreferencesTrailerDisplayFormat.Alias:
            trailerName = trailer.alias;
            break;
        case DisplayUserPreferencesTrailerDisplayFormat.AliasUnitid:
            trailerName = composeTrailerName(trailer.alias || `[${trailer.trailerNumber}]`, trailer.unitId);
            break;
        case DisplayUserPreferencesTrailerDisplayFormat.Trailernumber:
            trailerName = isUndefined(trailer.trailerNumber) ? undefined : trailer.trailerNumber.toString();
            break;
        case DisplayUserPreferencesTrailerDisplayFormat.Unitid:
            trailerName = trailer.unitId;
            break;
        default:
            throw new Error(`Unknown trailer format '${format}'`);
    }

    if (!trailerName) {
        trailerName = isUndefined(trailer.trailerNumber) ? `[${trailer.id}]` : `[${trailer.trailerNumber}]`;
    }

    return trailerName;
};
