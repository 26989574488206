import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { RtdsSchedulesListViewHeaderInnerProps, RtdsSchedulesListViewHeaderProps } from './component';
import { RtdsSchedulesListViewHeaderComponent } from './component';

export const RtdsSchedulesListViewHeader = compose<
    RtdsSchedulesListViewHeaderInnerProps,
    RtdsSchedulesListViewHeaderProps
>(
    setDisplayName('RtdsSchedulesListViewHeader'),
    withDisplayPreferences(),
    withTranslation()
)(RtdsSchedulesListViewHeaderComponent);
