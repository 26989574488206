import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        minWidth: theme.spacing(3),
    },
    icon: {
        padding: 0,
    },
    checkbox: {
        padding: 0,
    },
}));

export { useStyles };
