import type { StaticDataStoreState } from '~/common';
import type { SettingsDataType, SettingsKey } from '~/components/EnsureSettings';
import type { TrailerChangelog } from '~/services/ApiClient';

import type { ResolvedTrailerChangelog } from './models';
import { resolveTrailerChangelogChanges } from './reducers.resolveTrailerChangelogChanges';

export const resolveTrailerChangelog = (
    changelog: TrailerChangelog,
    trailerManufacturers: SettingsDataType[SettingsKey.TRAILER_MANUFACTURERS],
    trailerBatteryType: SettingsDataType[SettingsKey.TRAILER_BATTERY_TYPE],
    ebsManufacturer: SettingsDataType[SettingsKey.EBS_MANUFACTURER],
    reeferBatteryType: SettingsDataType[SettingsKey.REEFER_BATTERY_TYPE],
    reeferDataInterface: SettingsDataType[SettingsKey.REEFER_DATA_INTERFACE],
    reeferManufacturers: SettingsDataType[SettingsKey.REEFER_MANUFACTURERS],
    tpmsManufacturer: SettingsDataType[SettingsKey.TPMS_MANUFACTURER],
    trailerOfflineAlarm: SettingsDataType[SettingsKey.TRAILER_OFFLINE_ALARM],
    trailerMonitoring: SettingsDataType[SettingsKey.TRAILER_MONITORING],
    trailerBandwidthLinked: SettingsDataType[SettingsKey.TRAILER_BANDWIDTH_LINKED],
    depots: StaticDataStoreState['depots']['data']['hash']
): ResolvedTrailerChangelog => {
    return {
        ...changelog,
        before: changelog.before
            ? resolveTrailerChangelogChanges(
                  changelog.before,
                  trailerManufacturers,
                  trailerBatteryType,
                  ebsManufacturer,
                  reeferBatteryType,
                  reeferDataInterface,
                  reeferManufacturers,
                  tpmsManufacturer,
                  trailerOfflineAlarm,
                  trailerMonitoring,
                  trailerBandwidthLinked,
                  depots
              )
            : {},
        after: changelog.after
            ? resolveTrailerChangelogChanges(
                  changelog.after,
                  trailerManufacturers,
                  trailerBatteryType,
                  ebsManufacturer,
                  reeferBatteryType,
                  reeferDataInterface,
                  reeferManufacturers,
                  tpmsManufacturer,
                  trailerOfflineAlarm,
                  trailerMonitoring,
                  trailerBandwidthLinked,
                  depots
              )
            : {},
    };
};
