import type { SingleTFunction } from '~/components/LanguageSelector';

import type { ConversationProfile } from '../ApiClient';

const predefinedProfileTranslationKeys = {
    'Text messages only': 'predefined-profile-text-only',
    'All workflows': 'predefined-profile-all-workflows',
    'All workflows and text messages': 'predefined-profile-all-workflows-and-text',
};

export const formatConversationProfileName = (profile: ConversationProfile, t: SingleTFunction): string => {
    return profile.isPredefined && predefinedProfileTranslationKeys[profile.name]
        ? t(predefinedProfileTranslationKeys[profile.name])
        : profile.name;
};
