import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { VehicleSectionInnerProps, VehicleSectionProps } from './component';
import { VehicleSectionComponent } from './component';
import { VehicleSectionStyleRules } from './styles';

export const VehicleSection = compose<VehicleSectionInnerProps, VehicleSectionProps>(
    setDisplayName('VehicleSection'),
    withTranslation(),
    withStyles(VehicleSectionStyleRules),
    withDisplayPreferences()
)(VehicleSectionComponent);
