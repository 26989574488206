import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { WorkflowEditFieldInnerProps, WorkflowEditFieldProps } from './component';
import { WorkflowEditFieldComponent } from './component';

export const WorkflowEditField = compose<WorkflowEditFieldInnerProps, WorkflowEditFieldProps>(
    setDisplayName('WorkflowEditField'),
    withTranslation()
)(WorkflowEditFieldComponent);
