import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { ensureAuthorizationSelector } from '~/components/EnsureAuthorization/selectors';
import { withTranslation } from '~/components/LanguageSelector';
import { languageStateSelector } from '~/selectors';

import type { ConversationWidgetListInnerProps, ConversationWidgetListProps } from './component';
import { ConversationWidgetListComponent } from './component';
import { mapStateToProps } from './redux';
import { ConversationWidgetListStyleRules } from './styles';

export const ConversationWidgetList = compose<ConversationWidgetListInnerProps, ConversationWidgetListProps>(
    setDisplayName('ConversationWidgetList'),
    connect(createSelector(ensureAuthorizationSelector, languageStateSelector, mapStateToProps)),
    withTranslation(),
    withStyles(ConversationWidgetListStyleRules)
)(ConversationWidgetListComponent);
