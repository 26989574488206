import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import { formatPercentage } from '~/services/Formatters';

export interface PercentageFormatterProps {
    value?: number;
    precision?: number;
}

export interface PercentageFormatterInnerProps extends PercentageFormatterProps {}

const PercentageFormatter: React.FunctionComponent<PercentageFormatterInnerProps> = ({ value, precision }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatPercentage(value, precision)}</>;
};
PercentageFormatter.displayName = 'PercentageFormatter';

export { PercentageFormatter };
