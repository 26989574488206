import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ReeferSectionInnerProps, ReeferSectionProps } from './component';
import { ReeferSectionComponent } from './component';
import { ReeferSectionStyleRules } from './styles';

export const ReeferSection = compose<ReeferSectionInnerProps, ReeferSectionProps>(
    setDisplayName('ReeferSection'),
    withStyles(ReeferSectionStyleRules),
    withTranslation()
)(ReeferSectionComponent);
