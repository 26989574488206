import type { GridColumnDefinition } from '~/components/Grid';
import { filterDataSourceBySearchQuery } from '~/components/Grid';
import type { MonitoringDriverEntry } from '~/data/monitoring';
import { memoizeOne } from '~/services/Memoize';

export const filterMonitoringDataSourceMemoized = memoizeOne(
    (
        searchQuery: string | undefined,
        dataSource: MonitoringDriverEntry[],
        columnDefinitions: GridColumnDefinition<MonitoringDriverEntry>[],
        visibleColumns: string[]
    ) => filterDataSourceBySearchQuery(searchQuery, dataSource, columnDefinitions, visibleColumns)
);
