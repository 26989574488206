import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type PositionMiniMapSectionClassKeys = 'positionIcon';

export const PositionMiniMapSectionStyleRules: StyleRulesCallback<Theme, {}, PositionMiniMapSectionClassKeys> = () =>
    createStyles({
        positionIcon: {
            fontSize: 50,
            filter: 'drop-shadow( 2px 0px 2px rgba(0, 0, 0, .4))',
        },
    });
