import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { memoize } from '~/libs/utility';

import type { WorkflowMessageDetailsInnerProps, WorkflowMessageDetailsProps } from './component';
import { WorkflowMessageDetailsComponentFactory } from './component';
import { requiredMessageDetailsUserPreferencesFactory } from './preferences';
import { mapDispatchToPropsFactory, mapStateToProps } from './redux';
import { WorkflowMessageDetailsStyleRules } from './styles';

const messageSelector = (_: unknown, ownProps: WorkflowMessageDetailsProps) => ownProps.message.value;

export const WorkflowMessageDetailsFactory = (
    userPreferencesKey: string
): React.ComponentType<WorkflowMessageDetailsProps> =>
    compose<WorkflowMessageDetailsInnerProps, WorkflowMessageDetailsProps>(
        setDisplayName('WorkflowMessageDetails'),
        ensureUserPreferences([requiredMessageDetailsUserPreferencesFactory(userPreferencesKey)], false),
        ensureSettings([SettingsKey.ATTACHMENT_TYPE]),
        connect(
            createSelector(
                settingDataSelector(SettingsKey.ATTACHMENT_TYPE),
                messageSelector,
                keyDataStateSelectorFactory(userPreferencesKey),
                mapStateToProps
            ),
            mapDispatchToPropsFactory(userPreferencesKey)
        ),
        withTranslation(),
        withStyles(WorkflowMessageDetailsStyleRules)
    )(WorkflowMessageDetailsComponentFactory(userPreferencesKey));

export const workflowMessageDetailsProvider = memoize(WorkflowMessageDetailsFactory);
