import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

const formatCountryInternal = (t: SingleTFunction, country: string) => t(`country:${country}`);

const formatCountryMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((country: string) => formatCountryInternal(t, country))
);

export const formatCountry = (t: SingleTFunction, country: string): string => formatCountryMemoized(t)(country);
