import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { TimelineSectionInnerProps, TimelineSectionProps } from './component';
import { TimelineSectionComponent } from './component';

export type { TimelineItemClassKey } from './components/PositionGroupsContainer';

export const TimelineSection = compose<TimelineSectionInnerProps, TimelineSectionProps>(
    setDisplayName('TimelineSection'),
    withTranslation()
)(TimelineSectionComponent);
