import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';

import { withSceneAnalytics } from '~/components/Analytics';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';

import { TrailerDetailsPaneComponent } from './component';
import type { TrailerDetailsPaneInnerProps, TrailerDetailsPaneProps } from './model';
import { DETAILSPANE_TRAILER_USERPREFERENCES_KEY, requiredTrailerDetailsPaneUserPreferences } from './preferences';
import { mapDispatchToProps, mapStateToProps } from './redux';

export { DETAILSPANE_TRAILER_USERPREFERENCES_KEY, requiredTrailerDetailsPaneUserPreferences };

export { TrailerHistorySubpage } from './sections';
export const TrailerDetailsPane = compose<TrailerDetailsPaneInnerProps, TrailerDetailsPaneProps>(
    setDisplayName('TrailerDetailsPane'),
    withSceneAnalytics('trailerdetailspane'),
    connect(createSelector(settingDataSelector(SettingsKey.SECURABLES), mapStateToProps), mapDispatchToProps),
    withRouter
)(TrailerDetailsPaneComponent);
