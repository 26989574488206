import { DEFAULT_OPTIONS } from './defaultOptions';
import type { ColumnType, CreateColumnArgs, ReturnCreateColumArgs, TypeArgs } from './models';

const createColumn = <T, D extends ColumnType>(args: CreateColumnArgs<T, D>): ReturnCreateColumArgs<T, D> => {
    const defaultOptions = DEFAULT_OPTIONS[args.type](
        ('typeOptions' in args ? args.typeOptions : undefined) as TypeArgs[D]
    ) as ReturnCreateColumArgs<T, D>;
    const { type, typeOptions, ...restArgs } = args;

    return {
        ...defaultOptions,
        ...restArgs,
    };
};

export { createColumn };
