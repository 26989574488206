import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DoorsSectionInnerProps, DoorsSectionProps } from './component';
import { DoorsSectionComponent } from './component';
import { DoorsSectionStyleRules } from './styles';

export const DoorsSection = compose<DoorsSectionInnerProps, DoorsSectionProps>(
    setDisplayName('DoorsSection'),
    withStyles(DoorsSectionStyleRules),
    withTranslation()
)(DoorsSectionComponent);
