import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type { DriverRole, ResolvedVehicle } from '~/services/ApiClient';

import { DriverSubpageHeader } from './components/DriverSubpageHeader';
import type { SectionName } from './constants';
import type { DriverSubpageUserPreferences } from './preferences';
import { DRIVERSUBPAGE_PREFERENCES_KEY } from './preferences';
import type { DriverSubpageReduxProps } from './redux';
import { getSectionsMemoized } from './sections';

export interface DriverSubpageProps {
    defaultActiveRole: DriverRole;
}

export interface DriverSubpageOuterProps extends DriverSubpageProps {
    vehicle: ResolvedVehicle;
}

export interface DriverSubpageInnerProps
    extends InjectedDisplayPreferencesProps,
        DriverSubpageProps,
        DriverSubpageReduxProps {}

export const DetailsPaneComponent = SceneDetailsPaneFactory<
    MonitoringVehicleEntry,
    DriverSubpageUserPreferences,
    SectionName
>(DRIVERSUBPAGE_PREFERENCES_KEY);

export const DriverSubpageComponent: React.FC<DriverSubpageInnerProps> = ({
    entry,
    defaultActiveRole,
    displayPreferences,
}) => {
    const [activeRole, setActiveRole] = React.useState(defaultActiveRole);

    const getSections = React.useCallback(
        (
            userPreferences: DriverSubpageUserPreferences,
            toggleCollapsedState: (sectionName: SectionName) => () => void
        ) => {
            return getSectionsMemoized(userPreferences.collapsedSections, toggleCollapsedState, entry, activeRole);
        },
        [entry, activeRole]
    );

    const Header = React.useCallback(
        (props: { entry: MonitoringVehicleEntry }) => (
            <DriverSubpageHeader
                vehicle={props.entry.vehicle}
                driver={props.entry.vehicleDriverHoursStatus?.driverHoursStatus?.driver}
                coDriver={props.entry.vehicleDriverHoursStatus?.coDriverHoursStatus?.driver}
                driverActivity={props.entry.vehicleDriverHoursStatus?.driverHoursStatus?.activity}
                coDriverActivity={props.entry.vehicleDriverHoursStatus?.coDriverHoursStatus?.activity}
                driverDisplayFormat={displayPreferences.driverDisplayFormat}
                vehicleDisplayFormat={displayPreferences.vehicleDisplayFormat}
                activeDriverRole={activeRole}
                onActiveDriverRoleChange={setActiveRole}
            />
        ),
        [activeRole, setActiveRole, displayPreferences.driverDisplayFormat, displayPreferences.vehicleDisplayFormat]
    );

    return <DetailsPaneComponent dataId="driver-subpage" entry={entry} getSections={getSections} Header={Header} />;
};
