import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { LoadingIndicatorInnerProps, LoadingIndicatorProps } from './component';
import { LoadingIndicatorComponent } from './component';
import { LoadingIndicatorStyleRules } from './styles';

export const LoadingIndicator = compose<LoadingIndicatorInnerProps, LoadingIndicatorProps>(
    setDisplayName('LoadingIndicator'),
    withStyles(LoadingIndicatorStyleRules)
)(LoadingIndicatorComponent);
