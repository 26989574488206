import type { Duration } from 'moment';
import moment from 'moment';

import { isUndefined } from '~/libs/utility';

import type { DurationViolationField, ViolationField } from '../ApiClient';

import { parseOptionalDuration } from './parseOptionalDuration';

export const parseOptionalDurationViolationField = (
    durationViolationField?: DurationViolationField
): ViolationField<Duration> | undefined =>
    isUndefined(durationViolationField)
        ? undefined
        : {
              violationType: durationViolationField.violationType,
              value: moment.duration(durationViolationField.value),
              violation: parseOptionalDuration(durationViolationField.violation),
              threshold: moment.duration(durationViolationField.threshold),
          };
