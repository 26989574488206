import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';
import { dynamicMonitoringStateSelector } from '~/data/monitoring';
import { settingsStoreStateSelector, staticDataStoreStateSelector } from '~/selectors';

import type { ConnectVehicleDialogInnerProps, ConnectVehicleDialogProps } from './component';
import { ConnectVehicleDialogComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export const ConnectVehicleDialog = compose<ConnectVehicleDialogInnerProps, ConnectVehicleDialogProps>(
    setDisplayName('ConnectVehicleDialog'),
    withDisplayPreferences(),
    withTranslation(),
    connect(
        createSelector(
            staticDataStoreStateSelector,
            dynamicMonitoringStateSelector,
            settingsStoreStateSelector,
            mapStateToProps
        ),
        mapDispatchToProps
    )
)(ConnectVehicleDialogComponent);
