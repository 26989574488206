import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory } from '~/components/SceneList';

import { getRowId, rtdsSessionsEntryEquals } from '../../../../entryUtils';
import type { RtdsSessionEntry } from '../../../../models';
import { filterDataSourceMemoized, getColumnsMemoized } from '../../grid';
import { RTDSSESSIONS_LIST_USERPREFERENCES_KEY, defaultRtdsSessionsListUserPreferences } from '../../preferences';

export type RtdsSessionsListViewProps = Omit<
    SceneListViewProps<RtdsSessionEntry>,
    'columns' | 'gridDataId' | 'getCellClassName'
>;

export interface RtdsSessionsListViewInnerProps
    extends RtdsSessionsListViewProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

const ListViewComponent = SceneListViewFactory<RtdsSessionEntry>(
    RTDSSESSIONS_LIST_USERPREFERENCES_KEY,
    defaultRtdsSessionsListUserPreferences,
    getRowId,
    rtdsSessionsEntryEquals,
    filterDataSourceMemoized
);

export const RtdsSessionsListViewComponent: React.FC<RtdsSessionsListViewInnerProps> = ({
    t,
    displayPreferences,
    i18n,
    ...restProps
}) => {
    const columns = getColumnsMemoized(
        t,
        displayPreferences.vehicleDisplayFormat,
        displayPreferences.driverDisplayFormat
    );
    return <ListViewComponent columns={columns} gridDataId="rtds-sessions-grid" {...restProps} />;
};
