import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    avatar: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        width: 40,
        height: 40,
        alignSelf: 'center',
        overflow: 'visible',
        fontSize: 20,
    },
    headerAvatar: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        width: 21,
        height: 21,
        alignSelf: 'center',
        overflow: 'visible',
        fontSize: 20,
    },
}));
