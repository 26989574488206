import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { components } from 'react-select';

import type { DropdownIndicatorProps } from './models';
import { useStyles } from './styles';

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    const classes = useStyles();

    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <ArrowDropDown className={classes.dropdownIcon} />
            </components.DropdownIndicator>
        )
    );
};

DropdownIndicator.displayName = 'DropdownIndicator';
export default DropdownIndicator;
