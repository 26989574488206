import * as React from 'react';

import type { SingleDocumentViewerElement, SingleDocumentViewerProps } from '../../models';
import { DocumentViewerState } from '../../models';

export interface DefaultFileViewerProps extends SingleDocumentViewerProps {}

export interface DefaultFileViewerInnerProps extends DefaultFileViewerProps {}

export interface DefaultFileViewerElement extends SingleDocumentViewerElement {}

const DefaultFileViewer: React.FC<DefaultFileViewerInnerProps> = React.forwardRef<
    DefaultFileViewerElement,
    DefaultFileViewerInnerProps
>(({ data, onStateChange }, _ref) => {
    React.useEffect(() => {
        if (data) {
            onStateChange(DocumentViewerState.NoPreview);
        }
    }, [data, onStateChange]);

    return null;
});
DefaultFileViewer.displayName = 'DefaultFileViewer';

export { DefaultFileViewer };
