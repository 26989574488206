import type { Impersonator } from '~/common';
import type { SingleTFunction } from '~/components/LanguageSelector';

type FormatChangelogChangeAuthorArgs = {
    t: SingleTFunction;
    username?: string;
    impersonator?: Impersonator;
};

export const formatChangelogChangeAuthor = ({ t, username, impersonator }: FormatChangelogChangeAuthorArgs): string => {
    const usernameWithFallback = username ?? `[${t('unknown')}]`;

    if (impersonator) {
        return `${usernameWithFallback} [${impersonator.username}@${impersonator.customerName}]`;
    }

    return usernameWithFallback;
};
