import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type RetryLoadClassKey = 'root' | 'retryIcon';

export const RetryLoadStyleRules: StyleRulesCallback<Theme, {}, RetryLoadClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '50%',
            width: 32,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        retryIcon: {
            fontSize: theme.typography.pxToRem(24),
        },
    });
