import type { Size } from '../models';

const scales = [5, 12.5, 25, 33, 50, 66.7, 75, 100, 125, 150, 200, 300].map((s) => s / 100);
const restScale = 200 / 100;

export const getNextScales = (currentScale: number): { in: number | undefined; out: number | undefined } => {
    const idx = scales.findIndex((scale) => currentScale <= scale);
    if (idx === -1) {
        // out of range, increments of {restScale}
        const offsetFromLast = currentScale - scales[scales.length - 1];
        const offset = offsetFromLast % restScale;
        if (offset === 0) {
            return { in: currentScale + restScale, out: currentScale - restScale };
        }

        return {
            in: currentScale - offset + restScale,
            out: currentScale - offset,
        };
    }

    if (scales[idx] === currentScale) {
        return {
            in: idx + 1 === scales.length ? scales[idx] + restScale : scales[idx + 1],
            out: idx ? scales[idx - 1] : undefined,
        };
    }

    return { in: scales[idx], out: idx ? scales[idx - 1] : undefined };
};

export const getFitToScreenScale = (screenSize: Size, contentSize: Size): number => {
    const scaleWidth = screenSize.width / contentSize.width;
    const scaleHeight = screenSize.height / contentSize.height;

    return Math.min(1, scaleWidth, scaleHeight);
};

export const getFitToScreenWidthScale = (screenSize: Size, contentSize: Size): number => {
    return screenSize.width / contentSize.width;
};
