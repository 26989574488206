import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const ArrowUpBoldCircleIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 22A10 10 0 0 1 2 12A10 10 0 0 1 12 2A10 10 0 0 1 22 12A10 10 0 0 1 12 22M12 7L7 12H10V16H14V12H17L12 7Z" />
        </SvgIcon>
    );
};
ArrowUpBoldCircleIcon.displayName = 'ArrowUpBoldCircleIcon';

export { ArrowUpBoldCircleIcon };
