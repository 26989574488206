import Dashboard from '@mui/icons-material/Dashboard';
import Navigation from '@mui/icons-material/Navigation';
import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';

import type { CourseOverGroundIconProps } from './models';

const CourseOverGroundIcon: FC<CourseOverGroundIconProps> = (props) => {
    const { className, courseOverGround } = props;

    return !isUndefined(courseOverGround) ? (
        <Navigation
            data-id="navigation-icon"
            style={{ transform: `rotate(${courseOverGround}deg)` }}
            className={className}
        />
    ) : (
        <Dashboard data-id="dashboard-icon" className={className} />
    );
};

CourseOverGroundIcon.displayName = 'CourseOverGroundIcon';
export default CourseOverGroundIcon;
