import { isUndefined } from '../utility';

import type { GenericItemTree } from './models';

const getChildrenIds = <T extends GenericItemTree<T>>(source: T[], accumulator: (string | number)[] = []) => {
    const ids = source.reduce((acc: (string | number)[], { id, children }) => {
        if (isUndefined(children) && id) {
            return acc.concat(id);
        }
        if (!isUndefined(children)) {
            return [id].concat(getChildrenIds(children, acc));
        }
        return acc;
    }, []) as (string | number)[];

    return accumulator.concat(ids);
};

export { getChildrenIds };
