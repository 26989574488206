import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatDeviceType } from '~/services/Formatters';

export interface DeviceTypeFormatterProps {
    t: SingleTFunction;
    value?: string;
}

export interface DeviceTypeFormatterInnerProps extends DeviceTypeFormatterProps {}

const DeviceTypeFormatter: React.FunctionComponent<DeviceTypeFormatterInnerProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatDeviceType(t, value)}</>;
};
DeviceTypeFormatter.displayName = 'DeviceTypeFormatter';

export { DeviceTypeFormatter };
