import { groupBy, keyBy } from '~/libs/utility';
import { ApiClient } from '~/services/ApiClient';

import type { ContainerTemperatureGraphEntryModel } from './models';

export const getChartData = async (
    containerId: number,
    startDate: Date,
    stopDate: Date
): Promise<ContainerTemperatureGraphEntryModel[]> => {
    const statuses = (await ApiClient.getContainerStatusTemperatureHistory(containerId, startDate, stopDate)).items;
    const groups = groupBy(statuses, 'dateTime');
    return Object.values(groups).map((group) => ({
        dateTime: group[0].dateTime,
        temperatures: keyBy(group, 'id'),
    }));
};
