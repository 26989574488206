import Refresh from '@mui/icons-material/Refresh';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { AddPlusToIcon, VehicleTypesIcon } from '~/components/Icons';
import { useTranslation } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps } from '~/components/SceneList';
import { SceneListViewHeaderFactory } from '~/components/SceneList';
import { TitledIconButton } from '~/components/TitledIconButton';
import type { ResolvedVehicleType } from '~/services/ApiClient';
import { EditorState } from '~/services/ApiClient';

import { filterDataSourceMemoized, getColumnsMemoized } from '../../grid';
import {
    VEHICLE_TYPES_ADMINISTRATION_USERPREFERENCES_KEY,
    defaultVehicleTypesAdministrationListUserPreferences,
} from '../../preferences';

import type { ReduxProps } from './redux';

const HeaderComponent = SceneListViewHeaderFactory<ResolvedVehicleType>(
    VEHICLE_TYPES_ADMINISTRATION_USERPREFERENCES_KEY,
    defaultVehicleTypesAdministrationListUserPreferences,
    filterDataSourceMemoized,
    'vehicle-types-administration'
);

export interface VehicleTypesAdministrationListViewHeaderProps
    extends Omit<SceneListViewHeaderProps<ResolvedVehicleType>, 'columns' | 'excelSheetTitle' | 'moduleBarActions'> {
    onRefreshData: () => void;
    contentLoading: boolean;
    openEditorDialog: (operationMode: EditorState, entry?: ResolvedVehicleType) => void;
}

export interface VehicleTypesAdministrationListViewHeaderInnerProps
    extends VehicleTypesAdministrationListViewHeaderProps,
        ReduxProps,
        InjectedDisplayPreferencesProps {}

export const VehicleTypesAdministrationListViewHeaderComponent: FC<
    VehicleTypesAdministrationListViewHeaderInnerProps
> = (props) => {
    const {
        displayPreferences,
        dataSource,
        contentLoading,
        vehicleTypeCategories,
        axles,
        emissionClasses,
        onRefreshData,
        openEditorDialog,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const columns = getColumnsMemoized(t, vehicleTypeCategories, axles, emissionClasses, displayPreferences.unitSystem);

    const refreshDataButton = useMemo(() => {
        return (
            <>
                <TitledIconButton
                    title={t('new-vehicle-type')}
                    placement="bottom-end"
                    color="inherit"
                    onClick={() => openEditorDialog(EditorState.NEW)}
                    data-id="open-vehicletype-editor"
                >
                    <AddPlusToIcon>
                        <VehicleTypesIcon />
                    </AddPlusToIcon>
                </TitledIconButton>
                <TitledIconButton
                    title={t('refresh')}
                    placement="bottom-end"
                    color="inherit"
                    onClick={onRefreshData}
                    data-id="refresh-data-button"
                    disabled={restProps.gridActionsDisabled || contentLoading}
                >
                    <Refresh />
                </TitledIconButton>
            </>
        );
    }, [contentLoading, onRefreshData, openEditorDialog, restProps.gridActionsDisabled, t]);

    return (
        <HeaderComponent
            {...restProps}
            dataSource={dataSource}
            columns={columns}
            moduleBarActions={refreshDataButton}
            excelSheetTitle={t('vehicle-types-admin-module')}
            data-id="vehicle-types-administration-header"
        />
    );
};
