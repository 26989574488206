import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import type { FooterProps } from './models';

const Footer = <TId,>(props: FooterProps<TId>) => {
    const { selected, options } = props;
    const { t } = useTranslation();

    return (
        <Box data-testid="total-counter" flexGrow={0} flexShrink={0} paddingX={2} paddingY={1} textAlign="right">
            <Typography variant="caption">
                {t('selected-of-total', { selected: selected.length, total: options.length })}
            </Typography>
        </Box>
    );
};

Footer.displayName = 'Footer';
export { Footer };
