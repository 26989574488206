import { createAction } from 'redux-actions';

import { retrieveLatestIntroCarousel } from '~/services/IntroCarousel';

import type { LatestIntroCarouselPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

const retrieveLatestIntroCarouselExecutor = async (language: string): Promise<LatestIntroCarouselPayload> => {
    return {
        item: await retrieveLatestIntroCarousel(language),
    };
};

export const openIntroCarouselAction = createAction(ActionTypeKeys.INTROCAROUSEL_OPEN);

export const openUnseenCarouselAction = createAction(ActionTypeKeys.INTROCAROUSEL_OPENUNSEEN);

export const closeIntroCarouselAction = createAction(ActionTypeKeys.INTROCAROUSEL_CLOSE);

export const retrieveLatestIntroCarouselAction = createAction(
    ActionTypePrefixes.LATESTINTROCAROUSEL,
    retrieveLatestIntroCarouselExecutor
);
