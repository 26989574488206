import _Button from '@mui/material/Button';
import { styled } from '@mui/styles';

const Button = styled(_Button)({
    '&:hover': { backgroundColor: '#f4f4f4', boxShadow: 'none' },
    '&.Mui-disabled': { backgroundColor: '#fff' },
    backgroundColor: '#fff',
    boxShadow: 'none',
    color: 'black',
    padding: '6px 3px',
});

export { Button };
