import type { ChangelogEntry } from '~/common/components/ChangelogsSubpage/models';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { ResolvedRtdsScheduleChangelogChanges, RtdsScheduleChangelog } from '~/services/ApiClient';

import { getResolvedRtdsScheduleChangelogChanges } from './getResolvedRtdsScheduleChangelogChanges';

export const resolveRtdsScheduleChangelogsEntries = (
    rtdsScheduleChangelogs: RtdsScheduleChangelog[],
    settingsStoreState: SettingsStoreState
): ChangelogEntry<ResolvedRtdsScheduleChangelogChanges>[] => {
    return rtdsScheduleChangelogs.map((rtdsScheduleChangelog) => {
        return {
            action: rtdsScheduleChangelog.action,
            before: getResolvedRtdsScheduleChangelogChanges(settingsStoreState, rtdsScheduleChangelog.before),
            after: getResolvedRtdsScheduleChangelogChanges(settingsStoreState, rtdsScheduleChangelog.after),
            dateTime: rtdsScheduleChangelog.dateTime,
            impersonator: rtdsScheduleChangelog.impersonator,
            username: rtdsScheduleChangelog.username,
            id: rtdsScheduleChangelog.id,
        };
    });
};
