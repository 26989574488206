import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type VehicleMarkerClassKey =
    | 'driverActivityAvailableBackgroundColor'
    | 'driverActivityDriveBackgroundColor'
    | 'driverActivityWorkBackgroundColor'
    | 'driverActivityRestBackgroundColor'
    | 'vehicleBackgroundColor'
    | 'driverActivityAvailableBorderColor'
    | 'driverActivityDriveBorderColor'
    | 'driverActivityWorkBorderColor'
    | 'driverActivityRestBorderColor'
    | 'vehicleBorderColor'
    | 'assetName';

export const VehicleMarkerStyleRules: StyleRulesCallback<Theme, {}, VehicleMarkerClassKey> = (theme: Theme) =>
    createStyles({
        driverActivityAvailableBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.driverActivity.available.value,
            color: theme.functionalItemsColors.driverActivity.available.contrast,
        },
        driverActivityDriveBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.driverActivity.drive.value,
            color: theme.functionalItemsColors.driverActivity.drive.contrast,
        },
        driverActivityWorkBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.driverActivity.work.value,
            color: theme.functionalItemsColors.driverActivity.work.contrast,
        },
        driverActivityRestBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.driverActivity.rest.value,
            color: theme.functionalItemsColors.driverActivity.rest.contrast,
        },
        vehicleBackgroundColor: {
            backgroundColor: theme.functionalItemsColors.assetType.vehicle.value,
            color: theme.functionalItemsColors.assetType.vehicle.contrast,
        },
        driverActivityAvailableBorderColor: {
            borderColor: theme.functionalItemsColors.driverActivity.available.value,
        },
        driverActivityDriveBorderColor: {
            borderColor: theme.functionalItemsColors.driverActivity.drive.value,
        },
        driverActivityWorkBorderColor: {
            borderColor: theme.functionalItemsColors.driverActivity.work.value,
        },
        driverActivityRestBorderColor: {
            borderColor: theme.functionalItemsColors.driverActivity.rest.value,
        },
        vehicleBorderColor: {
            borderColor: theme.functionalItemsColors.assetType.vehicle.value,
        },
        assetName: {
            backgroundColor: theme.functionalItemsColors.assetType.vehicle.contrast,
            borderColor: theme.functionalItemsColors.assetType.vehicle.value,
            color: theme.functionalItemsColors.assetType.vehicle.value,
        },
    });
