import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const DepotIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <title>hyb_depot</title>
            <rect x="14.4" y="13.3" width="0.8" height="0.8" />
            <rect x="14.4" y="14.9" width="0.8" height="0.8" />
            <rect x="14.4" y="11.7" width="0.8" height="0.8" />
            <rect x="12.8" y="11.7" width="0.8" height="0.8" />
            <rect x="14.4" y="16.5" width="0.8" height="0.8" />
            <rect x="12.8" y="16.5" width="0.8" height="0.8" />
            <path d="M20,6H12L10,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Zm0,12.1H12V10.9h4v1.6h4Z" />
            <rect x="12.8" y="14.9" width="0.8" height="0.8" />
            <path d="M16,14.1h.8v.8H16v.8h.8v.8H16v.8h3.2v-4H16Zm1.6,0h.8v.8h-.8Zm0,1.6h.8v.8h-.8Z" />
            <rect x="12.8" y="13.3" width="0.8" height="0.8" />
        </SvgIcon>
    );
};
DepotIcon.displayName = 'DepotIcon';

export { DepotIcon };
