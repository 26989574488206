import { lazy } from 'react';
import { compose } from 'react-recompose';

import { requiredDisplayUserPreferences } from '~/components/DisplayPreferences';
import { withMenu } from '~/components/Navigation';
import { requiredSceneAssetSelectorUserPreferencesFactory } from '~/components/SceneAssetSelector';
import { ensureUserPreferences } from '~/components/UserPreferences';
import { ALERTS_ASSETSELECTOR_USERPREFERENCES_KEY } from '~/data/alerts/constants';
import { requiredDetailsPaneUserPreferences } from '~/scenes/Alerts/components/DetailsPane/preferences';

const AlertsScene = lazy(() => import('./component'));

const AlertViewerScene = compose(
    withMenu,
    ensureUserPreferences([
        requiredSceneAssetSelectorUserPreferencesFactory(ALERTS_ASSETSELECTOR_USERPREFERENCES_KEY),
        requiredDetailsPaneUserPreferences,
        requiredDisplayUserPreferences,
    ])
)(AlertsScene);

export { AlertViewerScene };
