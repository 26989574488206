import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
    disabled: {
        opacity: 0.38,
    },
    driverInfoRow: {
        display: 'flex',
    },
    driver: {
        display: 'block',
        padding: theme.spacing(1, 2),
        alignItems: 'start',
    },
    disabledTitle: {
        fontStyle: 'italic',
    },
    driverInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    driverName: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    activityDetails: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
    delimiter: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
    avatarContainer: {
        position: 'relative',
        height: 40,
    },
    driverActivityIcon: {
        width: 20,
        height: 20,
        fontSize: 14,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        marginRight: theme.spacing(),
        position: 'absolute',
        right: 0,
        bottom: 0,
        border: `1px solid ${theme.palette.common.white}`,
    },
    captionIcon: {
        fontSize: theme.typography.pxToRem(14),
        marginRight: theme.spacing(0.5),
    },
    driverIcon: {
        display: 'inline-flex',
        color: theme.functionalItemsColors.assetType.driver.value,
        backgroundColor: theme.functionalItemsColors.assetType.driver.contrast,
        border: `1px solid ${theme.palette.text.secondary}`,
        marginRight: theme.spacing(2),
        cursor: 'pointer',
    },
    activityAvailable: {
        backgroundColor: theme.functionalItemsColors.driverActivity.available.value,
        color: theme.functionalItemsColors.driverActivity.available.contrast,
    },
    activityWork: {
        backgroundColor: theme.functionalItemsColors.driverActivity.work.value,
        color: theme.functionalItemsColors.driverActivity.work.contrast,
    },
    activityDrive: {
        backgroundColor: theme.functionalItemsColors.driverActivity.drive.value,
        color: theme.functionalItemsColors.driverActivity.drive.contrast,
    },
    activityRest: {
        backgroundColor: theme.functionalItemsColors.driverActivity.rest.value,
        color: theme.functionalItemsColors.driverActivity.rest.contrast,
    },
    role: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    activityDetailsTimestamp: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(),
    },
}));
