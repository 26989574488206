import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import { transparentColorToSolidRgb } from '~/services/Styling';

export type SendWorkflowMessageFormClassKey =
    | 'root'
    | 'workflowDefinitionSelect'
    | 'messageFields'
    | 'sendFab'
    | 'sendFabDisabled'
    | 'sendIcon'
    | 'circularProgress'
    | 'form'
    | 'formMessage'
    | 'workflowDefinitionSelectOption'
    | 'footer';

export const SendWorkflowMessageFormStyleRules: StyleRulesCallback<Theme, {}, SendWorkflowMessageFormClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            height: '100%',
            backgroundColor: theme.palette.common.white,
        },
        workflowDefinitionSelect: {
            margin: theme.spacing(2),
        },
        messageFields: {
            marginBottom: theme.spacing(4),
        },
        sendFab: {
            width: 32,
            height: 32,
            minHeight: 32,
            margin: theme.spacing(0, 2, 1, 0),
            position: 'sticky',
            right: 0,
            bottom: 17,
            float: 'right',
            zIndex: 99,
            '&$sendFabDisabled': {
                backgroundColor: transparentColorToSolidRgb(
                    theme.palette.action.disabledBackground,
                    theme.palette.background.paper
                ),
            },
        },
        sendFabDisabled: {},
        sendIcon: {
            fontSize: theme.typography.pxToRem(14),
        },
        circularProgress: {
            position: 'absolute',
        },
        form: {
            flexGrow: 1,
        },
        formMessage: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            fontStyle: 'italic',
            display: 'flex',
            height: '100%',
            justifyContent: 'space-around',
        },
        workflowDefinitionSelectOption: {
            '&[aria-disabled="true"]': {
                pointerEvents: 'initial',
                cursor: 'default',
            },
            display: 'flex',
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            background: theme.palette.background.default,
            padding: theme.spacing(0.5, 1, 0.5, 0),
        },
    });
