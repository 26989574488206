import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import { graphDurationInSeconds as zoomPopupGraphDurationInSeconds } from './components/ZoomPopup';
import { graphExtraSideMargin, graphWidth, numberOfSecondsInAPixel } from './constants';

export type ActivitiesGraphClassKey = 'root' | 'zoomPopup' | 'cursor' | 'cursorRange' | 'activitiesGraphContainer';

export const cursorWidth = zoomPopupGraphDurationInSeconds / numberOfSecondsInAPixel;

export const ActivitiesGraphStyleRules: StyleRulesCallback<Theme, {}, ActivitiesGraphClassKey> = (theme) =>
    createStyles({
        root: {
            position: 'relative',
            width: graphWidth + graphExtraSideMargin * 2,
            padding: `0 ${graphExtraSideMargin}px`,
            /**
             * Make sure the inner html ignores mouse events
             * to not trigger mouse leave on hover.
             */
            '&>*': {
                pointerEvents: 'none',
            },
            cursor: 'pointer',
        },
        /**
         * Popper is calculating the position top, which is causing
         * the popup to not be positioned correctly if the content updates
         * without manually triggering update. Updating the popper position
         * on each update causes some flickering. For this we set position
         * to absolute and calculate it from bottom.
         */
        zoomPopup: {
            position: 'absolute',
            bottom: theme.spacing(1.5),
            transform: 'translateX(-50%)',
        },
        cursor: {
            width: 1,
            height: 14,
            top: -2,
            zIndex: 10,
            position: 'absolute',
            marginLeft: graphExtraSideMargin,
            backgroundColor: 'red',
        },
        cursorRange: {
            position: 'absolute',
            height: 14,
            width: cursorWidth,
            border: '1px solid #000',
            transform: 'translateX(-50%)',
        },
        activitiesGraphContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginLeft: -theme.spacing(1),
            marginRight: -theme.spacing(1),
        },
    });
