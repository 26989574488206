import { translateClassification } from '@fv/translations';
import type { TFunction } from 'react-i18next';

import { mapEntryToClassification } from '~/common';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { StoreState } from '~/reducers';
import { ClassificationType } from '~/services/ApiClient';
import { stringComparer } from '~/services/Sorting';

const reportSettingsSelector = (state: StoreState) => state.reportSettingsScene;

const editorDialogSelector = (t: TFunction) => (state: StoreState) => ({
    currencies: Object.values(settingDataSelector(SettingsKey.CURRENCIES)(state)),
    reportSettingsItems: reportSettingsSelector(state).items.map((profile) => profile.general.name),
    template: reportSettingsSelector(state).template.item,
    classificationScoringDimensions: mapEntryToClassification({
        values: Object.values(settingDataSelector(SettingsKey.SCORING_DIMENSIONS)(state)),
        displayName: (value) => {
            return translateClassification({
                classification: value,
                classificationKey: ClassificationType.ScoringDimensions,
                t,
            });
        },
    }).sort((a, b) => stringComparer(a.displayName, b.displayName)),
    classificationFuelConsumptionTargets: mapEntryToClassification({
        values: Object.values(settingDataSelector(SettingsKey.FUEL_CONSUMPTION_TARGETS)(state)),
        displayName: (value) => {
            return translateClassification({
                classification: value,
                classificationKey: ClassificationType.FuelConsumptionTarget,
                t,
            });
        },
    }).sort((a, b) => stringComparer(a.displayName, b.displayName)),
});

export { reportSettingsSelector, editorDialogSelector };
