import { formatFuel, formatVolumeLiquid } from '@fv/components';
import type { FC } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useDisplayPreferences } from '~/common/hooks';
import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import { isEqual, isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { vehicleAdministrationStateSelector } from '~/scenes/VehicleAdministration/selectors';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { SectionName } from '../../constants';
import type { CommonSectionProps } from '../../models';

export interface FuelSectionInnerProps extends CommonSectionProps, InjectedTranslationProps {}

export const FuelSectionComponent: FC<FuelSectionInnerProps> = ({
    adminVehicle,
    t,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
}) => {
    const { data: defaultSettings } = useSelector(
        (store: StoreState) => vehicleAdministrationStateSelector(store).adminVehicleDefaultSettings,
        isEqual
    );
    const { unitSystem } = useDisplayPreferences();

    const primaryFuelTankType = adminVehicle.settings?.primaryFuelTankType ?? defaultSettings?.primaryFuelTankType;
    const secondaryFuelTankType =
        adminVehicle.settings?.secondaryFuelTankType ?? defaultSettings?.secondaryFuelTankType;
    const primaryFuelTankSize = adminVehicle.settings?.primaryFuelTankSize ?? defaultSettings?.primaryFuelTankSize;
    const secondaryFuelTankSize =
        adminVehicle.settings?.secondaryFuelTankSize ?? defaultSettings?.secondaryFuelTankSize;
    const adBlueTankSize = adminVehicle.settings?.adBlueTankSize ?? defaultSettings?.adBlueTankSize;

    const keyValueItems: KeyValueItem[] = [
        {
            valueDataId: 'primary-fuel-tank-size',
            label: t('vehicle-administration.primary-fuel-tank-size'),
            value: !isUndefined(primaryFuelTankSize) && primaryFuelTankType && (
                <span>
                    {formatFuel({ value: { amount: primaryFuelTankSize, type: primaryFuelTankType }, unitSystem })}
                </span>
            ),
        },
        {
            valueDataId: 'primary-fuel-tank-type',
            label: t('vehicle-administration.primary-fuel-tank-type'),
            value: primaryFuelTankType && (
                <span>{formatClassification(t, ClassificationType.FuelTankType, primaryFuelTankType)}</span>
            ),
        },
        {
            valueDataId: 'secondary-fuel-tank-size',
            label: t('vehicle-administration.secondary-fuel-tank-size'),
            value: !isUndefined(secondaryFuelTankSize) && secondaryFuelTankType && (
                <span>
                    {formatFuel({ value: { amount: secondaryFuelTankSize, type: secondaryFuelTankType }, unitSystem })}
                </span>
            ),
        },
        {
            valueDataId: 'secondary-fuel-tank-type',
            label: t('vehicle-administration.secondary-fuel-tank-type'),
            value: secondaryFuelTankType && (
                <span>{formatClassification(t, ClassificationType.FuelTankType, secondaryFuelTankType)}</span>
            ),
        },
        {
            valueDataId: 'ad-blue-tank-size',
            label: t('vehicle-administration.ad-blue-tank-size'),
            value: !isUndefined(adBlueTankSize) && (
                <span>{formatVolumeLiquid({ value: adBlueTankSize, unitSystem })}</span>
            ),
        },
    ];

    return (
        <ReorderableSection
            dataId="vehicle-administration-fuel-section"
            title={t(SectionName.VEHICLEADMINISTRATIONFUEL)}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="general-list" />
        </ReorderableSection>
    );
};

FuelSectionComponent.displayName = 'FuelSectionComponent';
