import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type CheckboxWorkflowFieldClassKey = 'label';

export const CheckboxWorkflowFieldStyleRules: StyleRulesCallback<Theme, {}, CheckboxWorkflowFieldClassKey> = () =>
    createStyles({
        label: {
            fontSize: 14,
        },
    });
