import type { NumericDictionary } from '~/libs/utility';
import { mapValues } from '~/libs/utility';
import type { ResolvedConversationSummary } from '~/services/ApiClient';

export const mergeConversationSummaryData = (
    pendingConversationSummaryUpdates: NumericDictionary<ResolvedConversationSummary>,
    nextItems: NumericDictionary<ResolvedConversationSummary>
): NumericDictionary<ResolvedConversationSummary> => ({
    ...pendingConversationSummaryUpdates,
    ...mapValues(nextItems, (nextConversationSummary) => {
        const conversationSummary = pendingConversationSummaryUpdates[nextConversationSummary.vehicleId];
        if (conversationSummary && conversationSummary.revision > nextConversationSummary.revision) {
            return conversationSummary;
        }

        return nextConversationSummary;
    }),
});
