import { useEffect, useState } from 'react';

import { useLanguageCode } from '~/components/LanguageSelector';
import type { Position } from '~/services/ApiClient';
import { Address, createApiModel } from '~/services/ApiClient';
import {
    Point,
    PointType,
    ReverseGeocodeRequest,
    ReverseGeocodeRequestItem,
    ReverseGeocodeResultOptions,
    getMapGatewayClient,
} from '~/services/MapGatewayClient';

export interface ReverseGeocodedAddress {
    address?: Address;
    requestedPosition?: Position;
    pending: boolean;
}

export const useReverseGeocodedAddress = (position?: Position): ReverseGeocodedAddress => {
    const [state, setState] = useState<ReverseGeocodedAddress>({ pending: false });
    const language = useLanguageCode();

    useEffect(() => {
        if (position) {
            setState({ address: undefined, requestedPosition: position, pending: true });

            (async (): Promise<void> => {
                try {
                    const response = await getMapGatewayClient().reverseGeocode(
                        createApiModel(ReverseGeocodeRequest, {
                            item: createApiModel(ReverseGeocodeRequestItem, {
                                position: createApiModel(Point, {
                                    type: PointType.Point,
                                    coordinates: [position.longitude, position.latitude],
                                }),
                            }),
                            options: createApiModel(ReverseGeocodeResultOptions, {
                                includeNearestCity: false,
                            }),
                        })
                    );

                    const address = response.item.location.address
                        ? createApiModel(Address, { ...response.item.location.address } as Address)
                        : undefined;
                    setState((s) => (s.requestedPosition === position ? { ...s, address, pending: false } : s));
                } catch {
                    setState((s) => (s.requestedPosition === position ? { ...s, pending: false } : s));
                }
            })();
        } else {
            setState({ pending: false });
        }
    }, [language, position]);

    return state;
};
