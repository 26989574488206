import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { VehicleCategory } from '~/services/ApiClient';
import { AssetConnectionStatus } from '~/services/ApiClient';
import { formatVehicleCategory } from '~/services/Formatters';

import type { VehicleFilteringClassKey } from './styles';

export interface VehicleFilter {
    vehicleCategory?: VehicleCategory;
    connectionStatus?: AssetConnectionStatus;
}

export interface VehicleFilteringProps {
    filter?: VehicleFilter;
    vehicleCategories: VehicleCategory[];
    onFilterStateChange: (filter: VehicleFilter) => void;
}

export interface VehicleFilteringInnerProps
    extends VehicleFilteringProps,
        InjectedTranslationProps,
        WithStyles<VehicleFilteringClassKey> {}

export const ALL_OPTION = 'all';

export const VehicleFilteringComponent: React.FC<VehicleFilteringInnerProps> = ({
    t,
    vehicleCategories,
    onFilterStateChange,
    classes,
    filter,
}) => {
    const vehicleCategoriesElements = vehicleCategories.map((vehicleCategory: VehicleCategory) => (
        <FormControlLabel
            key={`vehicle-category:${vehicleCategory.id}`}
            value={vehicleCategory.id}
            control={<Radio />}
            label={formatVehicleCategory(t, vehicleCategory)}
            data-id={`vehicle-category-option:${vehicleCategory.id}`}
        />
    ));

    const handleVehicleCategoryChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedVehicleCategory = vehicleCategories.find(
                (vehicleCategory: VehicleCategory) => vehicleCategory.id === +event.target.value
            );

            onFilterStateChange({
                ...filter,
                vehicleCategory: selectedVehicleCategory,
            });
        },
        [onFilterStateChange, filter, vehicleCategories]
    );

    const handleConnectionStatusChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedConnectionStatus =
                event.target.value === ALL_OPTION ? undefined : (event.target.value as AssetConnectionStatus);

            onFilterStateChange({
                ...filter,
                connectionStatus: selectedConnectionStatus,
            });
        },
        [onFilterStateChange, filter]
    );

    return (
        <div className={classes.root}>
            <FormControl variant="standard" component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.label} data-id="vehicle-category-radio-group-title">
                    {t('vehicle-category')}
                </FormLabel>
                <RadioGroup
                    name="vehicle-category"
                    value={isUndefined(filter?.vehicleCategory?.id) ? -1 : filter?.vehicleCategory?.id}
                    onChange={handleVehicleCategoryChange}
                    data-id="vehicle-category-radio-group"
                >
                    <FormControlLabel
                        key={ALL_OPTION}
                        value={-1}
                        control={<Radio />}
                        label={t(ALL_OPTION)}
                        data-id={`vehicle-category-option:${ALL_OPTION}`}
                    />
                    {vehicleCategoriesElements}
                </RadioGroup>
            </FormControl>

            <FormControl variant="standard" component="fieldset">
                <FormLabel component="legend" className={classes.label} data-id="connection-status-radio-group-title">
                    {t('connection-status')}
                </FormLabel>
                <RadioGroup
                    name="connection-status"
                    value={filter?.connectionStatus || ALL_OPTION}
                    onChange={handleConnectionStatusChange}
                    data-id="connection-status-radio-group"
                >
                    <FormControlLabel
                        key={ALL_OPTION}
                        value={ALL_OPTION}
                        control={<Radio />}
                        label={t(ALL_OPTION)}
                        data-id={`connection-status-option:${ALL_OPTION}`}
                    />
                    <FormControlLabel
                        key={AssetConnectionStatus.CONNECTED}
                        value={AssetConnectionStatus.CONNECTED}
                        control={<Radio />}
                        label={t(AssetConnectionStatus.CONNECTED)}
                        data-id={`connection-status-option:${AssetConnectionStatus.CONNECTED}`}
                    />
                    <FormControlLabel
                        key={AssetConnectionStatus.NOT_CONNECTED}
                        value={AssetConnectionStatus.NOT_CONNECTED}
                        control={<Radio />}
                        label={t(AssetConnectionStatus.NOT_CONNECTED)}
                        data-id={`connection-status-option:${AssetConnectionStatus.NOT_CONNECTED}`}
                    />
                </RadioGroup>
            </FormControl>
        </div>
    );
};
