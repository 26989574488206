import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { vehiclesHashSelector } from '~/common';
import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';
import { keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { PredefinedUserPreferencesKeys } from '~/services/PredefinedUserPreferences';

import { CONVERSATION_USERPREFERENCES_KEY } from '../../../../preferences';
import { CONVERSATIONWIDGETS_USERPREFERENCES_KEY } from '../../preferences';

import type { ConversationWidgetInnerProps, ConversationWidgetProps } from './component';
import { ConversationWidgetComponent } from './component';
import { mapDispatchToProps, mapStateToProps, mergeProps } from './redux';
import { conversationStoreStateSelector, translatedMessagesStoreStateSelector, vehicleIdSelector } from './selectors';
import { ConversationWidgetStyleRules } from './styles';

export const ConversationWidget = compose<ConversationWidgetInnerProps, ConversationWidgetProps>(
    setDisplayName('ConversationWidget'),
    ensureSettings([SettingsKey.SECURABLES, SettingsKey.VEHICLE_MESSAGING_CAPABILITIES]),
    connect(
        createSelector(
            vehicleIdSelector,
            vehiclesHashSelector,
            conversationStoreStateSelector,
            translatedMessagesStoreStateSelector,
            settingDataSelector(SettingsKey.SECURABLES),
            settingDataSelector(SettingsKey.VEHICLE_MESSAGING_CAPABILITIES),
            keyDataStateSelectorFactory(PredefinedUserPreferencesKeys.DISPLAY),
            keyDataStateSelectorFactory(CONVERSATIONWIDGETS_USERPREFERENCES_KEY),
            keyDataStateSelectorFactory(CONVERSATION_USERPREFERENCES_KEY),
            mapStateToProps
        ),
        mapDispatchToProps,
        mergeProps
    ),
    withTranslation(),
    withStyles(ConversationWidgetStyleRules)
)(ConversationWidgetComponent);
