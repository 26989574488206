import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TimeSeriesDialogClassKey = 'main';

export const TimeSeriesDialogStyleRules: StyleRulesCallback<Theme, {}, TimeSeriesDialogClassKey> = () =>
    createStyles({
        main: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
        },
    });
