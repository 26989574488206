import { createAction } from 'redux-actions';

import type { InhibitorStatus } from '~/services/ApiClient';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { InhibitorStatusPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const retrieveInhibitorStatusesExecutor = (): Promise<InhibitorStatusPayload> => {
    return retryableRequest(() => ApiClient.getInhibitorStatuses());
};

export const retrieveInhibitorStatusesAction = createAction(
    ActionTypePrefixes.INHIBITORSTATUS,
    retrieveInhibitorStatusesExecutor
);

export const updateInhibitorStatusAction = createAction<InhibitorStatus>(ActionTypeKeys.INHIBITORSTATUS_UPDATE);
