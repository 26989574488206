import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type WithMarkerPopupClassKey = 'popup';

export const WithMarkerPopupStyleRules: StyleRulesCallback<Theme, {}, WithMarkerPopupClassKey> = () =>
    createStyles({
        popup: {
            '@global .leaflet-popup-content-wrapper .leaflet-popup-content ': {
                maxWidth: 350,
                minWidth: 175,
                width: 'auto !important',
            },
        },
    });
