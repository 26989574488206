enum ViolationType {
    ABOVE = 1,
    BELOW = 2,
}

enum DurationFormat {
    HOUR_MINUTE,
    HOUR,
    MINUTE,
}

enum LengthFormat {
    SHORT,
    STANDARD,
}

export { DurationFormat, LengthFormat, ViolationType };
