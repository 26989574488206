import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { UserAvatarInnerProps, UserAvatarProps } from './component';
import { UserAvatarComponent } from './component';
import { UserAvatarStyleRules } from './styles';

export const UserAvatar = compose<UserAvatarInnerProps, UserAvatarProps>(
    setDisplayName('UserAvatar'),
    withStyles(UserAvatarStyleRules, { withTheme: true })
)(UserAvatarComponent);
