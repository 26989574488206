import type { PopperProps } from '@mui/material';
import { Paper, Popper, TextField, Tooltip } from '@mui/material';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import type { FilterOptionsState } from '@mui/material/useAutocomplete';
import { matchSorter } from 'match-sorter';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { getValueByPath } from '~/common';
import { isUndefined } from '~/libs/utility';
import type { ClassificationAbstraction } from '~/services/ApiClient';

import { noneValue } from '../model';
import { TextInput } from '../TextInput';

import { classificationAbstractionToOptionInput } from './dataMappers';
import { Header } from './Header';
import type { AutocompleteDropdownInputInnerProps } from './models';
import type { OptionItem } from './Option';
import { Option } from './Option';

const popperModifiers = [
    { name: 'flip', enabled: false },
    { name: 'preventOverflow', enabled: false },
];

export const AutocompleteDropdownInputComponent: FC<AutocompleteDropdownInputInnerProps> = (props) => {
    const {
        fieldName,
        className,
        values,
        isMulticolumn,
        headers,
        defaultValue,
        hasError,
        classes,
        label,
        dataId,
        disabled,
        disabledReason,
        markValueChange,
        onValueChanged,
        addNoneValue,
        required,
        t,
        errorText,
        readonly,
        minLength,
        isBranded,
        maxLength,
        validationRules,
    } = props;

    const {
        control,
        formState: { errors, dirtyFields },
    } = useFormContext();

    const fieldError = errors[fieldName];
    const isDirtyField = Boolean(getValueByPath(dirtyFields, fieldName));
    const error = !!fieldError || hasError;
    const helperText = hasError && !isUndefined(errorText) ? errorText : fieldError ? fieldError.message : undefined;
    const firstValue = values.filter((e) => e.id === defaultValue)[0];
    const controllerDefaultValue = isUndefined(firstValue) ? undefined ?? defaultValue : firstValue.displayName;

    const options = useMemo(() => {
        const noneDisplayName = t('dropdown-option-none');
        const items: OptionItem[] = values.map((e: ClassificationAbstraction) => {
            const brandedValue = !isUndefined(isBranded) ? isBranded(e) : false;
            return classificationAbstractionToOptionInput(e, brandedValue);
        });

        if (addNoneValue) {
            items.unshift({
                displayName: noneDisplayName,
                id: noneValue,
                key: noneValue,
                values: [noneDisplayName],
            });
        }

        return items;
    }, [values, t, addNoneValue, isBranded]);

    const rules = {
        required: { value: required ?? false, message: t('wf-field-error-required') },
        minLength: minLength && {
            value: minLength,
            message: t('wf-field-error-min-length', { minLength }),
        },
        maxLength,
        validate: (v: string | number) =>
            v === null || options.some((o) => o.id === v) || t('wf-field-error-invalid-value'),
        ...validationRules,
    };

    const getOptionLabel = (option: OptionItem | string) => {
        if (typeof option === 'string' || typeof option === 'number') {
            const val = options.filter((e) => e.id === option)[0];

            return val ? val.displayName : '';
        }

        return option ? option.displayName : '';
    };

    const handleChange = (_: React.ChangeEvent<{}>, value: OptionItem, onChange: (...event: unknown[]) => void) => {
        onChange(value ? value.id : null);

        if (onValueChanged) {
            if (value === null || isUndefined(value)) {
                onValueChanged(null);
            } else {
                onValueChanged(value.id);
            }
        }
    };

    const getOptionSelected = (option: OptionItem, value: string | number) => {
        return option.id === value;
    };

    const filterList = (filterOptions: OptionItem[], { inputValue }: FilterOptionsState<OptionItem>) => {
        if (!inputValue) {
            return matchSorter(filterOptions, inputValue);
        }

        return isMulticolumn
            ? matchSorter(filterOptions, inputValue, {
                  keys: ['values'],
                  threshold: matchSorter.rankings.CONTAINS,
              })
            : matchSorter(filterOptions, inputValue, {
                  keys: ['displayName'],
                  threshold: matchSorter.rankings.CONTAINS,
              });
    };

    const renderInput = (params: AutocompleteRenderInputParams): ReactNode => (
        <TextField
            {...params}
            label={label}
            error={error}
            helperText={helperText}
            variant="outlined"
            InputProps={{
                ...params.InputProps,
                classes: {
                    root: isUndefined(markValueChange) || !markValueChange ? undefined : classes.outlinedInput,
                    ...(markValueChange &&
                        !error &&
                        isDirtyField && {
                            notchedOutline: classes.changedNotchedOutline,
                            focused: classes.focusChangedNotchedOutline,
                        }),
                },
            }}
            required={required}
        />
    );

    // eslint-disable-next-line react/no-unstable-nested-components
    const CustomPaper = (popperProps: PopperProps) => {
        const { width, ...restStyle } = popperProps.style ?? {};
        return (
            <Popper
                {...popperProps}
                style={restStyle}
                placement="bottom-start"
                disablePortal
                modifiers={popperModifiers}
                className={classes.listbox}
            >
                <Paper elevation={8}>
                    {headers && (
                        <Header
                            headers={headers}
                            isMulticolumn={!!isMulticolumn}
                            dataId={dataId}
                            classes={{ headerBox: classes.headerBox, headerText: classes.headerText }}
                        />
                    )}

                    {popperProps.children}
                </Paper>
            </Popper>
        );
    };

    if (readonly) {
        return (
            <TextInput
                fieldName={fieldName}
                dataId={dataId}
                data-testid={dataId}
                label={label}
                required={required}
                disabled={disabled}
                readonly={readonly}
                minLength={minLength}
                maxLength={maxLength}
                className={className}
                hasError={hasError}
                errorText={errorText}
                validationRules={validationRules}
                markValueChange={markValueChange}
            />
        );
    }

    const baseComponent = (
        <Controller
            name={fieldName}
            defaultValue={controllerDefaultValue}
            control={control}
            rules={rules}
            render={({ field }) => (
                <div className={classes.autoCompleteContainer}>
                    <Autocomplete
                        {...field}
                        blurOnSelect="touch"
                        className={className}
                        data-id={dataId}
                        data-testid={dataId}
                        disableClearable={required}
                        disabled={disabled}
                        filterOptions={filterList}
                        getOptionLabel={getOptionLabel}
                        options={options}
                        PopperComponent={CustomPaper}
                        renderInput={renderInput}
                        size="small"
                        renderOption={(liProps, option: OptionItem) => (
                            <li {...liProps}>
                                <Option
                                    isMulticolumn={!!isMulticolumn}
                                    option={option}
                                    classes={{
                                        brandedRow: classes.brandedRow,
                                        optionCellRow: classes.optionCellRow,
                                        optionItemText: classes.optionItemText,
                                    }}
                                />
                            </li>
                        )}
                        onChange={(e, v) => handleChange(e, v, field.onChange)}
                        isOptionEqualToValue={getOptionSelected}
                    />
                </div>
            )}
        />
    );

    return disabledReason && disabled ? (
        <Tooltip
            title={disabledReason}
            data-id={`input-tooltip-${dataId}`}
            data-testid={`input-tooltip-${dataId}`}
            placement="right"
        >
            <span>{baseComponent}</span>
        </Tooltip>
    ) : (
        baseComponent
    );
};
