import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ReeferValueInnerProps, ReeferValueProps } from './component';
import { ReeferValueComponent } from './component';

export const ReeferValue = compose<ReeferValueInnerProps, ReeferValueProps>(
    setDisplayName('ReeferValue'),
    withTranslation()
)(ReeferValueComponent);
