import type { ReactNode } from 'react';
import type { ToastOptions } from 'react-toastify';
import { toast as reactToast } from 'react-toastify';

import type { ToastTypes } from './models';

interface ToastArgs {
    type: ToastTypes;
    message: ReactNode;
    onClose?: ToastOptions['onClose'];
}

const toast = (args: ToastArgs) => {
    const { message, type } = args;

    reactToast(message, { type });
};

const useToast = () => (args: ToastArgs) => {
    toast(args);
};

export { useToast, toast };
