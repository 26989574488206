import { MessageBodyType, MessageDirection } from '~/services/ApiClient';
import type { ResolvedMessage, Securables, VehicleMessagingCapabilities } from '~/services/ApiClient';

export const calculateCanSendTextMessageToVehicle = (
    securables: Securables,
    vehicleMessagingCapabilities: VehicleMessagingCapabilities | undefined
): boolean => {
    return securables.messaging.sendTextMessage && !!vehicleMessagingCapabilities?.textMessagingEnabled;
};

export const calculateCanSendWorkflowMessageToVehicle = (
    securables: Securables,
    vehicleMessagingCapabilities: VehicleMessagingCapabilities | undefined
): boolean => {
    return securables.messaging.sendWorkflowMessage && !!vehicleMessagingCapabilities?.workflowMessagingEnabled;
};

export const isTranslatableMessageType = (message: ResolvedMessage) =>
    message.direction === MessageDirection.Incoming && message.body.type === MessageBodyType.Text;
