import { Box, useTheme } from '@mui/material';
import printJS from 'print-js';
import * as React from 'react';

import { themeSpacingToNumber } from '~/common/utils';
import { AutoSizer } from '~/components/AutoSizer';

import { useRotation, useScaling } from '../../hooks';
import type { SingleDocumentViewerElement, SingleDocumentViewerProps, Size } from '../../models';
import { DocumentViewerState } from '../../models';

import { Image } from './components/Image';

export interface ImageViewerProps extends SingleDocumentViewerProps {}

export interface ImageViewerInnerProps extends ImageViewerProps {}

const contentMargin = 2;

export interface ImageViewerElement extends SingleDocumentViewerElement {}

const ImageViewer: React.FC<ImageViewerInnerProps> = React.forwardRef<ImageViewerElement, ImageViewerInnerProps>(
    ({ data, state, onStateChange, onScalingChange, onRotationChange }, ref) => {
        const theme = useTheme();
        const rotation = useRotation(0);
        const scaling = useScaling();
        const url = React.useMemo(() => data && URL.createObjectURL(data), [data]);
        const isLoaded = state === DocumentViewerState.Success;

        const onImageLoaded = (originalContentSize: Size) => {
            rotation.setOriginalSize(originalContentSize);
        };

        const { setContentSize: setScalingContentSize } = scaling;
        React.useEffect(() => {
            if (rotation.transformedSize) {
                setScalingContentSize(rotation.transformedSize);
                onStateChange(DocumentViewerState.Success);
            }
        }, [rotation.transformedSize, setScalingContentSize, onStateChange]);

        React.useEffect(() => onScalingChange && onScalingChange(scaling), [onScalingChange, scaling]);
        React.useEffect(() => onRotationChange && onRotationChange(rotation), [onRotationChange, rotation]);

        const onPrint = React.useMemo(
            () => (url ? () => printJS({ printable: url, type: 'image' }) : undefined),
            [url]
        );

        const setScreenSizeExcludingMargin = (size: Size) => {
            scaling.setScreenSize({
                width: size.width - themeSpacingToNumber(theme.spacing(contentMargin * 2)),
                height: size.height - themeSpacingToNumber(theme.spacing(contentMargin * 2)),
            });
        };

        React.useImperativeHandle(ref, () => ({ print: onPrint }), [onPrint]);

        return (
            <AutoSizer onResize={setScreenSizeExcludingMargin}>
                {(size: Size) => (
                    <Box display={isLoaded ? 'flex' : 'none'} {...size} overflow="auto">
                        <Box p={contentMargin} margin="auto">
                            <Image src={url} scale={scaling.value} rotation={rotation.value} onLoad={onImageLoaded} />
                        </Box>
                    </Box>
                )}
            </AutoSizer>
        );
    }
);
ImageViewer.displayName = 'ImageViewer';

export { ImageViewer };
