import React from 'react';

import { DetailsPaneHeader } from '~/components/DetailsPaneHeader';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { ResolvedMessage } from '~/services/ApiClient';

import { SectionName } from './constants';
import type { TextMessageDetailsUserPreferences } from './preferences';
import { getSectionsMemoized } from './sections';

export interface TextMessageDetailsProps {
    message: ResolvedMessage;
    showDetailsPaneHeader: boolean;
    onClose?: () => void;
}

export interface TextMessageDetailsReduxProps {
    changeMiniMapZoomLevel: (zoomLevel: number) => void;
}

export interface TextMessageDetailsInnerProps
    extends TextMessageDetailsProps,
        TextMessageDetailsReduxProps,
        InjectedTranslationProps {}

export const TextMessageDetailsComponentFactory: (
    userPreferencesKey: string
) => React.FC<TextMessageDetailsInnerProps> = (userPreferencesKey) => {
    const DetailsPaneComponent = SceneDetailsPaneFactory<
        ResolvedMessage,
        TextMessageDetailsUserPreferences,
        SectionName
    >(userPreferencesKey);

    return ({ t, message, showDetailsPaneHeader, changeMiniMapZoomLevel, onClose }) => {
        const getSections = (
            userPreferences: TextMessageDetailsUserPreferences,
            toggleCollapsedState: (sectionName: SectionName) => () => void
        ) =>
            getSectionsMemoized(
                message,
                userPreferences.collapsedSections,
                toggleCollapsedState,
                t,
                userPreferences.miniMapZoomLevel,
                changeMiniMapZoomLevel
            );

        const header = showDetailsPaneHeader ? (
            <DetailsPaneHeader onClose={onClose} title={t('message-info')} />
        ) : undefined;

        return (
            <DetailsPaneComponent
                entry={message}
                getSections={getSections}
                dataId="text-message-details-sections"
                HeaderReactNode={header}
                alwaysFirstSection={SectionName.MESSAGEINFO}
            />
        );
    };
};
