import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDisplayPreferences } from '~/common/hooks';
import { ReorderableSection, SectionContentMessage } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';

import { EBS } from '../../../EBS';

import type { EbsSectionProps } from './model';

const EbsSectionComponent: FC<EbsSectionProps> = (props) => {
    const { dragHandleElement, entry, isCollapsed, toggleCollapsed } = props;
    const { t } = useTranslation();
    const ebs = entry.trailerConnection?.trailerStatus?.ebs;
    const { unitSystem } = useDisplayPreferences();

    return (
        <ReorderableSection
            dataId="vehicle-monitoring-ebs"
            data-testid="vehicle-monitoring-ebs"
            title={t('ebs')}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
        >
            {!isUndefined(ebs) ? (
                <EBS
                    axleWeight={ebs?.axleLoadSum}
                    mileage={ebs?.milage}
                    trailerWeight={ebs?.trailerWeight}
                    timeStamp={ebs?.dateTime}
                    unitSystem={unitSystem}
                />
            ) : (
                <SectionContentMessage dataId="vehicle-monitoring-ebs-empty">
                    {t('ebs-not-available')}
                </SectionContentMessage>
            )}
        </ReorderableSection>
    );
};

EbsSectionComponent.displayName = 'EbsSectionComponent';

export { EbsSectionComponent };
