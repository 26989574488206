import * as React from 'react';

import { logEvent } from '~/services/Analytics';

import { getCurrentDate } from './getCurrentDate';

export interface SceneAnalyticsOptions {
    reportDuration: boolean;
    reportLoaded: boolean;
}

const DEFAULT_OPTIONS = {
    reportDuration: false,
    reportLoaded: true,
};

export const withSceneAnalytics =
    (scene: string, options?: Partial<SceneAnalyticsOptions>) =>
    <BaseProps extends {}>(BaseComponent: React.ComponentType<BaseProps>): React.ComponentType<BaseProps> => {
        const TheBaseComponent = BaseComponent as React.ComponentType<{}>;
        const { reportLoaded, reportDuration } = { ...DEFAULT_OPTIONS, ...options };

        const Hoc = (props: BaseProps) => {
            React.useEffect(() => {
                if (reportLoaded) {
                    logEvent('Scenes', `loaded-${scene}`, `Loaded scene ${scene}`);
                }

                if (reportDuration) {
                    const start = getCurrentDate();
                    return () => {
                        const duration = Math.round((getCurrentDate().getTime() - start.getTime()) / 1000);
                        logEvent('Scenes', `showed-${scene}`, `Showed scene ${scene}`, { value: duration });
                    };
                }
            }, []);

            return <TheBaseComponent {...props} />;
        };
        Hoc.displayName = `withSceneAnalytics(${BaseComponent.name})`;

        return Hoc;
    };
