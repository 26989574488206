import type { Dispatch } from 'redux';

import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { DriverActivitiesReportStoreState } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import { driverEventResolver } from '~/scenes/History';
import type { Driver, ResolvedDriverEvent } from '~/services/ApiClient';

import type { OwnProps } from './component';
import { getDriverEventsAction, getDriverEventsFromVehicleDriverAction } from './data';

export interface DriverActivityReportReduxProps extends DispatchProps, StateProps {}

export interface DispatchProps {
    getDriverEvents: (vehicleId: number | undefined, driverId: number, startDate: Date, stopDate: Date) => void;
}

export interface StateProps {
    driverEventEntries: ResolvedDriverEvent[];
    loading: boolean;
    rejected: boolean;
    driver: Driver;
}

export const mapStateToProps = (
    { drivers }: StaticDataStoreState,
    { driverActivityTypes, driverSubActivityTypes, driverEventTypes }: SettingsStoreState,
    driverActivitiesReportStoreState: DriverActivitiesReportStoreState,
    { driverId }: OwnProps
): StateProps => {
    const loading =
        drivers.pending ||
        driverActivitiesReportStoreState.driverEvents.pending ||
        driverActivityTypes.pending ||
        driverSubActivityTypes.pending ||
        driverEventTypes.pending;
    const rejected =
        drivers.rejected ||
        driverActivitiesReportStoreState.driverEvents.rejected ||
        driverActivityTypes.rejected ||
        driverSubActivityTypes.rejected ||
        driverEventTypes.rejected;
    const fulfilled =
        drivers.fulfilled &&
        driverActivitiesReportStoreState.driverEvents.fulfilled &&
        driverActivityTypes.fulfilled &&
        driverSubActivityTypes.fulfilled &&
        driverEventTypes.fulfilled;

    return {
        driverEventEntries: fulfilled
            ? driverActivitiesReportStoreState.driverEvents.data.map((driverEvent) =>
                  driverEventResolver(
                      driverEvent,
                      driverActivityTypes.data,
                      driverSubActivityTypes.data,
                      driverEventTypes.data
                  )
              )
            : [],
        loading,
        rejected,
        driver: drivers.data.hash[driverId],
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getDriverEvents: (vehicleId: number | undefined, driverId: number, startDate: Date, stopDate: Date) => {
        if (!isUndefined(vehicleId)) {
            dispatch(getDriverEventsFromVehicleDriverAction(vehicleId, driverId, startDate, stopDate));
        } else {
            dispatch(getDriverEventsAction(driverId, startDate, stopDate));
        }
    },
});
