import type { Dispatch } from 'redux';

import type { NumericDictionary } from '~/libs/utility';
import { reportError } from '~/reporting';
import type { CustomerSecurables } from '~/services/ApiClient';

import type { DispatchProps, StateProps } from './component';
import { retrieveCustomersAction } from './data';
import type { CustomerAutocompleteStoreState } from './reducers';

export const mapStateToProps = (
    customerAutocompleteStoreState: CustomerAutocompleteStoreState,
    customerSecurables: NumericDictionary<CustomerSecurables>
): StateProps => ({
    customers: customerAutocompleteStoreState.customers.data,
    customerSecurables,
    loadingCustomers: customerAutocompleteStoreState.customers.pending,
    retrieveCustomersFulfilled: customerAutocompleteStoreState.customers.fulfilled,
});

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getCustomers: () => {
        dispatch(retrieveCustomersAction()).catch(reportError);
    },
});
