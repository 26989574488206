import { LatLngBounds } from 'leaflet';

export const offset = 0.000000001;

export const ensureBoundsArea = (bounds: LatLngBounds): LatLngBounds => {
    if (bounds.getWest() === bounds.getEast() || bounds.getNorth() === bounds.getSouth()) {
        return new LatLngBounds(
            [bounds.getSouth() - offset, bounds.getWest() - offset],
            [bounds.getNorth() + offset, bounds.getEast() + offset]
        );
    }

    return bounds;
};
