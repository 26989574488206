import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { AssetType } from '~/services/ApiClient';

import type { AssetDataType, AssetHistoryMapInnerProps, AssetHistoryMapProps } from './component';
import { AssetHistoryMapComponentFactory } from './component';

export const AssetHistoryMapFactory = <T extends keyof AssetDataType>(
    assetType: T
): React.ComponentType<AssetHistoryMapProps<AssetDataType[T]>> =>
    compose<AssetHistoryMapInnerProps<AssetDataType[T]>, AssetHistoryMapProps<AssetDataType[T]>>(
        setDisplayName('AssetHistoryMap'),
        withDisplayPreferences()
    )(AssetHistoryMapComponentFactory(assetType));

export const ContainerHistoryMap = AssetHistoryMapFactory(AssetType.Container);
export const TrailerHistoryMap = AssetHistoryMapFactory(AssetType.Trailer);
export const VehicleHistoryMap = AssetHistoryMapFactory(AssetType.Vehicle);
