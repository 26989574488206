import { Tooltip } from '@mui/material';
import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { TextBadge } from '~/components/TextBadge';
import type { ViolationField } from '~/services/ApiClient';
import { ViolationType } from '~/services/ApiClient';
import { ColorVariants } from '~/services/Styling';

export interface ViolationFieldFormatterProps<T> {
    t: SingleTFunction;
    value?: ViolationField<T>;
    fieldName: string;
}

export interface ViolationFieldFormatterInnerProps<T> extends ViolationFieldFormatterProps<T> {}

export const ViolationFieldFormatterComponentFactory = <T,>(
    baseFormatter: (value: T) => string
): React.ComponentType<ViolationFieldFormatterInnerProps<T>> => {
    const formatter = baseFormatter;

    return class extends React.Component<ViolationFieldFormatterInnerProps<T>> {
        public render() {
            const { t, value, fieldName } = this.props;

            if (!value) {
                return null;
            }

            const tooltipText = () => {
                if (value.violationType === ViolationType.None) {
                    return '';
                }
                const params = {
                    value: formatter(value.value),
                    threshold: formatter(value.threshold),
                    violation: value.violation && formatter(value.violation),
                    fieldName,
                };
                return value.violationType === ViolationType.Violation
                    ? t('violation-threshold-hit', params)
                    : t('warning-threshold-hit', params);
            };

            const renderContent = () => {
                let badgeColor;

                switch (value.violationType) {
                    case ViolationType.Violation:
                        badgeColor = ColorVariants.Error;
                        break;
                    case ViolationType.Warning:
                        badgeColor = ColorVariants.Warning;
                        break;
                    default:
                        badgeColor = ColorVariants.Normal;
                }

                return (
                    <TextBadge
                        text={formatter(value.value)}
                        variant={badgeColor}
                        dataId={`violation-field:${value.violationType}`}
                    />
                );
            };

            return (
                <Tooltip title={tooltipText()}>
                    <div>{renderContent()}</div>
                </Tooltip>
            );
        }
    };
};
