import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SendMessageDialogClassKey =
    | 'recipientsListPadding'
    | 'workflowDefinitionSelectMargin'
    | 'messageFieldsMargin'
    | 'sendMessageContainerPadding';

export const SendMessageDialogStyleRules: StyleRulesCallback<Theme, {}, SendMessageDialogClassKey> = (theme: Theme) =>
    createStyles({
        recipientsListPadding: {
            padding: theme.spacing(1, 3),
        },
        workflowDefinitionSelectMargin: {
            margin: theme.spacing(2, 3),
        },
        messageFieldsMargin: {
            margin: theme.spacing(0, 2),
        },
        sendMessageContainerPadding: {
            paddingLeft: theme.spacing(3),
        },
    });
