import type { NumericDictionary } from '~/libs/utility';
import type {
    Driver,
    DriverActivityType,
    DriverEvent,
    DriverEventType,
    DriverSubActivityType,
} from '~/services/ApiClient';

import type { HistoryDriverEntry } from './models';
import { buildHistoryDriverEntry } from './redux.buildHistoryDriverEntry';

export const buildHistoryDriverEntries = (
    driversHash: NumericDictionary<Driver>,
    driverEvents: DriverEvent[],
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>,
    driverEventTypes: NumericDictionary<DriverEventType>
): HistoryDriverEntry[] =>
    driverEvents.map((driverEvent: DriverEvent) =>
        buildHistoryDriverEntry(driversHash, driverEvent, driverActivityTypes, driverSubActivityTypes, driverEventTypes)
    );
