import * as React from 'react';

import type { ResolvedMessage } from '~/services/ApiClient';
import { MessageBodyType } from '~/services/ApiClient';

import { textMessageDetailsProvider } from '../TextMessageDetails';
import { workflowMessageDetailsProvider } from '../WorkflowMessageDetails';

export enum MessageDetailsType {
    Widget = 'widget',
    Pane = 'pane',
}

export interface MessageDetailsProps {
    message: ResolvedMessage;
    type: MessageDetailsType;
    scrollToAttachments?: boolean;
    onClose?: () => void;
}

export interface MessageDetailsInnerProps extends MessageDetailsProps {}

const MessageDetails: React.FC<MessageDetailsInnerProps> = ({ message, type, scrollToAttachments, onClose }) => {
    const userPreferencesKey = `${message.body.type}-message-details-${type}`;
    switch (message.body.type) {
        case MessageBodyType.Workflow: {
            const WorkflowMessageDetails = workflowMessageDetailsProvider(userPreferencesKey);
            return (
                <WorkflowMessageDetails
                    message={message}
                    scrollToAttachments={scrollToAttachments}
                    showDetailsPaneHeader={type === MessageDetailsType.Pane}
                    onClose={onClose}
                />
            );
        }
        case MessageBodyType.Text: {
            const TextMessageDetails = textMessageDetailsProvider(userPreferencesKey);
            return (
                <TextMessageDetails
                    message={message}
                    showDetailsPaneHeader={type === MessageDetailsType.Pane}
                    onClose={onClose}
                />
            );
        }
        default:
            throw new Error('unsupported message body type');
    }
};
MessageDetails.displayName = 'MessageDetails';

export { MessageDetails };
