import moment from 'moment';

import type { NumericDictionary } from '~/libs/utility';
import type { ResolvedRtdsSchedule, TachographDataBlock } from '~/services/ApiClient';
import { RtdsSchedule, RtdsScheduleReference, createApiModel } from '~/services/ApiClient';

import type { FieldValues } from './component';
import { getRecurrenceValue } from './getRecurrenceValue';
import { getSelectedTachoDataIds } from './getSelectedTachoDataIds';
import { MonthlyRadioButtonsTypes } from './models';

export const dataToSubmittedSchedule = (
    data: FieldValues,
    schedule: ResolvedRtdsSchedule,
    tachographDataBlocksSettings: NumericDictionary<TachographDataBlock>
): RtdsSchedule => {
    return createApiModel(RtdsSchedule, {
        id: createApiModel(RtdsScheduleReference, {
            id: schedule.id.id,
        }),
        revision: schedule.revision,
        description: undefined,
        frequency: data.dropdownFrequency,
        priority: data.dropdownPriority,
        recurrence: getRecurrenceValue(data),
        startDate: moment(data.startDate.toISOString()).utc(true).toDate(),
        tachographDataBlocks: getSelectedTachoDataIds(
            schedule.type,
            tachographDataBlocksSettings,
            data.tachoDataBlocks
        ),
        requestedDays: data.lastActivityDays,
        type: schedule.type,
        assetReference: schedule.assetReference,
        day:
            data.radioButtonsFrequencyMonth === MonthlyRadioButtonsTypes.Month
                ? data.dropdownMonthOrdinalType
                : data.frequencyMonthNumDays || 0,
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        weekDay: data.radioButtonsFrequencyMonth === MonthlyRadioButtonsTypes.Day ? undefined : data.dropdownWeekDays,
    });
};
