import { useIntersectionObserver } from '@fv/components/hooks';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC } from 'react';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { LoadingIndicator } from '~/components/LoadingIndicator';
import type { ScrollPosition } from '~/components/ReverseInfiniteScroll';
import { ReverseInfiniteScrollFactory } from '~/components/ReverseInfiniteScroll';
import type { NumericDictionary } from '~/libs/utility';
import type {
    OptimisticOutgoingConversationMessage,
    ResolvedMessage,
    Securables,
    TextMessageBody,
} from '~/services/ApiClient';

import { isTranslatableMessageType } from '../../../../../../utils';
import { ConversationMessage } from '../../../../../ConversationMessage';
import type { PendingMessageStatusUpdate, TranslatedMessageState } from '../../../../reducers';
import type { ListItem } from '../../listItem';

import { DaySeparator } from './components/DaySeparator';
import { NoOlderMessagesToDisplay } from './components/NoOlderMessagesToDisplay';
import { RetryLoad } from './components/RetryLoad';
import { autotranslateHistoryTreshold, infiniteScrollThreshold } from './constants';
import { getListItemId } from './getListItemId';
import type { ConversationWidgetListClassKey } from './styles';

export interface ConversationWidgetListProps {
    items: ListItem[];
    scrollPosition?: ScrollPosition;
    loadingConversation: boolean;
    failedToLoadConversation: boolean;
    loadingOlderMessages: boolean;
    failedToLoadOlderMessages: boolean;
    noOlderMessagesToDisplay: boolean;
    getConversation: () => void;
    loadOlderMessages: () => void;
    onScrollPositionChanged: (scrollPosition: ScrollPosition) => void;
    canUpdateReadStatus: boolean;
    canDeleteMessages: boolean;
    markIncomingMessageAsRead: (messageId: number) => void;
    markIncomingMessageAsUnread: (messageId: number) => void;
    deleteMessage: (messageId: number) => void;
    undeleteMessage: (messageId: number) => void;
    pendingMessagesStatusUpdates: NumericDictionary<PendingMessageStatusUpdate>;
    retrySendMessage: (message: OptimisticOutgoingConversationMessage) => void;
    discardMessage: (message: OptimisticOutgoingConversationMessage) => void;
    copyMessageToDraft: (messageBody: TextMessageBody) => void;
    retrieveWorkflowFormDefinition: (formId: number) => void;
    openMessageDetails: (message: ResolvedMessage, scrollToAttachment: boolean) => void;
    translateConversationMessage: (message: ResolvedMessage, targetLanguage: string) => void;
    translatedConversationMessages: NumericDictionary<TranslatedMessageState>;
    securables?: Securables;
    currentLanguageCode?: string;
}

export interface ConversationWidgetListInnerProps
    extends ConversationWidgetListProps,
        InjectedTranslationProps,
        WithStyles<ConversationWidgetListClassKey> {}

const ReverseInfiniteScroll = ReverseInfiniteScrollFactory<ListItem>();

export const ConversationWidgetListComponent: FC<ConversationWidgetListInnerProps> = ({
    classes,
    items,
    scrollPosition,
    loadingConversation,
    loadingOlderMessages,
    noOlderMessagesToDisplay,
    loadOlderMessages,
    onScrollPositionChanged,
    failedToLoadConversation,
    getConversation,
    failedToLoadOlderMessages,
    canUpdateReadStatus,
    canDeleteMessages,
    pendingMessagesStatusUpdates,
    markIncomingMessageAsRead,
    markIncomingMessageAsUnread,
    retrySendMessage,
    discardMessage,
    deleteMessage,
    undeleteMessage,
    copyMessageToDraft,
    retrieveWorkflowFormDefinition,
    openMessageDetails,
    translateConversationMessage,
    translatedConversationMessages = {},
    securables,
    currentLanguageCode,
    t,
}) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const isAutotranslationAllowed = useMemo(() => securables?.autotranslate.isAllowed, [securables]);

    const { visibleItems, hiddenItems, register } = useIntersectionObserver({
        root: scrollContainerRef,
        threshold: 0.1,
    });

    const requestTranslation = useCallback(
        (message) => {
            translateConversationMessage(message, currentLanguageCode ?? '');
        },
        [currentLanguageCode, translateConversationMessage]
    );

    const requestAutotranslation = useCallback(
        (untranslatedMessages: string[]) => {
            untranslatedMessages.forEach((untranslatedMessageId) => {
                const messageToTranslate = items.find(
                    (item) => item.message.value.id === Number(untranslatedMessageId)
                );

                const messageDate = messageToTranslate?.message?.value?.dateTime?.getTime();

                if (messageDate && Date.now() - messageDate < autotranslateHistoryTreshold) {
                    requestTranslation(messageToTranslate?.message);
                }
            });
        },
        [items, requestTranslation]
    );

    useEffect(() => {
        if (!isAutotranslationAllowed) {
            return;
        }
        requestAutotranslation(visibleItems.filter((visibleItem) => !translatedConversationMessages[visibleItem]));
    }, [
        hiddenItems,
        isAutotranslationAllowed,
        items,
        requestAutotranslation,
        translatedConversationMessages,
        visibleItems,
    ]);

    const overriddenReverseInfiniteScrollClasses = {
        statusIndicator: classNames(classes.statusIndicator, classes.centeredContent),
        itemsContainer: classes.listItemsContainer,
    };

    const getStatusIndicator = useCallback(() => {
        if (noOlderMessagesToDisplay) {
            return <NoOlderMessagesToDisplay />;
        }

        if (loadingConversation || loadingOlderMessages) {
            return <LoadingIndicator />;
        }

        if (failedToLoadConversation) {
            return (
                <RetryLoad
                    title={t('retry-retrieving-conversation')}
                    onClick={getConversation}
                    dataId="retry-load-conversation"
                />
            );
        }

        if (failedToLoadOlderMessages) {
            return (
                <RetryLoad
                    title={t('retry-retrieving-older-messages')}
                    onClick={loadOlderMessages}
                    dataId="retry-load-older-messages"
                />
            );
        }

        return undefined;
    }, [
        failedToLoadConversation,
        failedToLoadOlderMessages,
        getConversation,
        loadOlderMessages,
        loadingConversation,
        loadingOlderMessages,
        noOlderMessagesToDisplay,
        t,
    ]);

    const itemRenderer = useCallback(
        (item: ListItem): JSX.Element => {
            const daySeparatorElement = item.firstMessageOfDay && (
                <div className={classNames(classes.daySeparator, classes.centeredContent)}>
                    <DaySeparator value={item.message.value.dateTime} />
                </div>
            );

            const listItemClasses = classNames(classes.listItem, {
                [classes.groupedListItem]: !item.firstMessageOfGroup,
            });

            return (
                <div key={getListItemId(item)} className={listItemClasses}>
                    {daySeparatorElement}

                    <ConversationMessage
                        ref={
                            isTranslatableMessageType(item.message)
                                ? register(String(item.message.value.id))
                                : undefined
                        }
                        message={item.message}
                        firstMessageOfGroup={item.firstMessageOfGroup}
                        canUpdateReadStatus={canUpdateReadStatus}
                        canDeleteMessages={canDeleteMessages}
                        updatingReadStatus={!!pendingMessagesStatusUpdates[item.message.value.id]}
                        updatingDeleteStatus={
                            !!pendingMessagesStatusUpdates[item.message.value.id]?.expectedStatusDeleted
                        }
                        markIncomingMessageAsRead={markIncomingMessageAsRead}
                        markIncomingMessageAsUnread={markIncomingMessageAsUnread}
                        retrySendMessage={retrySendMessage}
                        discardMessage={discardMessage}
                        deleteMessage={deleteMessage}
                        undeleteMessage={undeleteMessage}
                        copyMessageToDraft={copyMessageToDraft}
                        retrieveWorkflowFormDefinition={retrieveWorkflowFormDefinition}
                        openMessageDetails={openMessageDetails}
                        translatedMessageValue={translatedConversationMessages[item.message.value.id]}
                        requestTranslation={isAutotranslationAllowed ? requestTranslation : undefined}
                    />
                </div>
            );
        },
        [
            classes.daySeparator,
            classes.centeredContent,
            classes.listItem,
            classes.groupedListItem,
            register,
            canUpdateReadStatus,
            canDeleteMessages,
            pendingMessagesStatusUpdates,
            markIncomingMessageAsRead,
            markIncomingMessageAsUnread,
            retrySendMessage,
            discardMessage,
            deleteMessage,
            undeleteMessage,
            copyMessageToDraft,
            retrieveWorkflowFormDefinition,
            openMessageDetails,
            translatedConversationMessages,
            isAutotranslationAllowed,
            requestTranslation,
        ]
    );

    return (
        <ReverseInfiniteScroll
            hasMore={!noOlderMessagesToDisplay}
            dataId="conversation-messages-list"
            scrollPosition={scrollPosition}
            items={items}
            scrollContainerRef={scrollContainerRef}
            loading={loadingConversation || loadingOlderMessages}
            threshold={infiniteScrollThreshold}
            statusIndicator={getStatusIndicator()}
            itemRenderer={itemRenderer}
            getItemId={getListItemId}
            loadMore={loadOlderMessages}
            onScrollPositionChanged={onScrollPositionChanged}
            classes={overriddenReverseInfiniteScrollClasses}
        />
    );
};
