import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export type VehicleTypesAdministrationClassKeys = 'root' | 'messageText' | 'loader' | 'backDrop';

export const useStyles = makeStyles<Theme, {}, VehicleTypesAdministrationClassKeys>((theme) => ({
    root: {
        height: '100%',
    },
    messageText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },
    loader: {
        color: theme.palette.primary.main,
    },
    backDrop: {
        position: 'absolute',
        zIndex: theme.zIndex.drawer,
        color: '#fff',
    },
}));
