import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { RtdsSchedule } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';

import type { RtdsSchedulesEntry } from './models';

export const resolveRtdsSchedulesEntries = (
    rtdsSchedules: RtdsSchedule[],
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): RtdsSchedulesEntry[] => {
    return rtdsSchedules.map((rtdsSchedule) => {
        const vehicle =
            rtdsSchedule.assetReference?.type === AssetType.Vehicle
                ? staticDataStoreState.vehicles.data.hash[rtdsSchedule.assetReference.id]
                : undefined;

        const resolvedRtdsSchedule = {
            ...rtdsSchedule,
            asset:
                rtdsSchedule.assetReference?.type === AssetType.Driver
                    ? staticDataStoreState.drivers.data.hash[rtdsSchedule.assetReference.id]
                    : vehicle,
            deviceType: vehicle?.device && settingsStoreState.deviceTypes.data[vehicle.device.type],
            resolvedDescription: {
                rtdsScheduleType:
                    rtdsSchedule.description &&
                    settingsStoreState.rtdsScheduleType.data[rtdsSchedule.description.rtdsScheduleType],
                weekdayType: rtdsSchedule.description?.weekdayType
                    ? settingsStoreState.rtdsWeekDayType.data[rtdsSchedule.description.weekdayType]
                    : undefined,
                ordinalType: rtdsSchedule.description?.ordinalType
                    ? settingsStoreState.rtdsOrdinalType.data[rtdsSchedule.description.ordinalType]
                    : undefined,
                day: rtdsSchedule.description?.day,
            },
            dataBlocks: rtdsSchedule.tachographDataBlocks.map(
                (dataBlockId) => settingsStoreState.tachographDataBlock.data[dataBlockId]
            ),
            scheduleFrequency: settingsStoreState.rtdsScheduleFrequency.data[rtdsSchedule.frequency],
            schedulePriority: settingsStoreState.rtdsPriority.data[rtdsSchedule.priority],
        };

        return { schedule: resolvedRtdsSchedule };
    });
};
