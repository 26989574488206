import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ActivityDetailsInnerProps, ActivityDetailsProps } from './component';
import { ActivityDetailsComponent } from './component';
import { ActivityDetailsStyleRules } from './styles';

export const ActivityDetails = compose<ActivityDetailsInnerProps, ActivityDetailsProps>(
    setDisplayName('ActivityDetails'),
    withStyles(ActivityDetailsStyleRules)
)(ActivityDetailsComponent);
