import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { BooleanSettingsFieldInnerProps, BooleanSettingsFieldProps } from './component';
import { BooleanSettingsFieldComponent } from './component';
import { BooleanFieldStyleRules } from './styles';

export const BooleanSettingsField = compose<BooleanSettingsFieldInnerProps, BooleanSettingsFieldProps>(
    setDisplayName('BooleanField'),
    withTranslation(),
    withStyles(BooleanFieldStyleRules)
)(BooleanSettingsFieldComponent);
