import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { SearchingBoxComponentInnerProps, SearchingBoxComponentProps } from './component';
import { SearchingBoxComponent } from './component';

export const SearchingBox = compose<SearchingBoxComponentInnerProps, SearchingBoxComponentProps>(
    setDisplayName('SearchingBox'),
    withTranslation()
)(SearchingBoxComponent);
