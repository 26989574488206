import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../../customIconProps';

export const AssetGroupIconComponent: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M18.7 11.09a.16.16 0 0 0 .15-.16v-2a.16.16 0 0 0-.16-.15H18V5.08a.62.62 0 0 0-.62-.62H6.67a.62.62 0 0 0-.62.62V8.77H5.29a.16.16 0 0 0-.16.15v2a.16.16 0 0 0 .16.15h.76v7a.62.62 0 0 0 .62.62.3.3 0 0 0 0 .1v.49a.26.26 0 0 0 .26.26H9a.26.26 0 0 0 .26-.26v-.49a.3.3 0 0 0 0-.1h5.54a.16.16 0 0 0 0 .1v.49a.26.26 0 0 0 .26.26h2.13a.26.26 0 0 0 .26-.26v-.49a.2.2 0 0 0 0-.11.62.62 0 0 0 .55-.61v-7Zm.95 5v.84l.91 0v.44a.19.19 0 0 0 .18.19h1.53a.19.19 0 0 0 .18-.19V17a.53.53 0 0 0 .37-.53V11.33h.52a.12.12 0 0 0 .12-.11V9.74a.13.13 0 0 0-.12-.11h-.52V6.93a.46.46 0 0 0-.45-.46H19.65v2h2.43a.22.22 0 0 1 .2.24V11.7a.22.22 0 0 1-.2.23H19.65v2.56h.55a.19.19 0 0 1 .19.19v1.21a.2.2 0 0 1-.19.19ZM3.74 15.89V14.68a.19.19 0 0 1 .19-.19h.45V11.93H1.93a.21.21 0 0 1-.19-.23V8.75a.22.22 0 0 1 .19-.24H4.38v-2H1.65a.45.45 0 0 0-.44.46v2.7H.67a.12.12 0 0 0-.12.11v1.48a.11.11 0 0 0 .12.11h.54v5.12a.45.45 0 0 0 .44.45v.44a.19.19 0 0 0 .18.19H3.35a.19.19 0 0 0 .18-.19V17l.85 0v-.88H3.93A.2.2 0 0 1 3.74 15.89Zm17.67.19a.19.19 0 0 1-.18-.19v-.7a.18.18 0 0 1 .18-.19h.68a.18.18 0 0 1 .19.19v.7a.19.19 0 0 1-.19.19Zm-19.49 0a.2.2 0 0 1-.19-.19v-.7A.19.19 0 0 1 1.92 15H2.6a.18.18 0 0 1 .19.19v.7a.19.19 0 0 1-.19.19Zm5.13 0H8a.25.25 0 0 1 .26.26v.95a.25.25 0 0 1-.26.26h-1a.25.25 0 0 1-.26-.26v-.95A.25.25 0 0 1 7.05 16.09Zm2.81-.7h4.45a.26.26 0 0 1 .26.26V17.3a.25.25 0 0 1-.26.26H9.86a.25.25 0 0 1-.26-.26V15.65A.26.26 0 0 1 9.86 15.39Zm6.14.7h1a.25.25 0 0 1 .26.26v.95a.25.25 0 0 1-.26.26H16a.25.25 0 0 1-.25-.26v-.95A.25.25 0 0 1 16 16.09ZM7.06 7.25H17a.3.3 0 0 1 .27.32v4a.3.3 0 0 1-.27.32H7.06a.3.3 0 0 1-.27-.32v-4A.3.3 0 0 1 7.06 7.25Z" />
        </SvgIcon>
    );
};
