import type { Theme } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/styles';

import type { TooltipSize } from '../../models';

const sizeMap = {
    small: { spacing: 0.5, fontSize: 12 },
    large: { spacing: 1, fontSize: 14 },
};

interface SizeProps {
    size: TooltipSize;
}

const Container = styled(Paper)<Theme, SizeProps>(({ theme, size }) => {
    const { spacing } = sizeMap[size];
    return {
        padding: theme.spacing(1 * spacing),
    };
});

const Row = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
});

const LabelTypography = styled(Typography)<Theme, SizeProps>(({ theme, size }) => {
    const { spacing, fontSize } = sizeMap[size];
    return {
        margin: theme.spacing(0.5 * spacing),
        marginRight: theme.spacing(2 * spacing),
        flexGrow: 1,
        fontSize,
    };
});

const ValueTypography = styled(Typography)<Theme, SizeProps>(({ theme, size }) => {
    const { spacing, fontSize } = sizeMap[size];
    return {
        margin: theme.spacing(0.5 * spacing),
        fontWeight: theme.typography.fontWeightBold,
        fontSize,
    };
});

export { Container, Row, LabelTypography, ValueTypography };
