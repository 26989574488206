import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import type { ViolationGroupFormatterInnerProps, ViolationGroupFormatterProps } from './component';
import { ViolationGroupFormatterComponent } from './component';
import { ViolationGroupStyleRules } from './styles';

export type { ViolationGroupFormatterProps } from './component';

export const ViolationGroupFormatter = <T>(): React.ComponentType<ViolationGroupFormatterProps<T>> =>
    compose<ViolationGroupFormatterInnerProps<T>, ViolationGroupFormatterProps<T>>(
        setDisplayName('ViolationGroupFormatter'),
        withStyles(ViolationGroupStyleRules)
    )(ViolationGroupFormatterComponent);
