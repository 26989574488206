import type { ChangelogEntry } from '~/common/components/ChangelogsSubpage/models';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { ActionTypes as VehicleTypeActionTypes } from '~/data/vehicletypeadministration';
import { ActionTypeKeys as VehicleTypeActionTypeKeys } from '~/data/vehicletypeadministration';
import type { RetrievableData } from '~/reducers';
import type { ResolvedVehicleTypeChangelogChanges, VehicleTypeChangelog } from '~/services/ApiClient';
import type { BaseAction } from '~/types';

import { resolveVehicleTypeChangelogsEntries } from './reducers.resolveVehicleTypeChangelogsEntries';

export interface VehicleTypeChangelogsStoreState {
    vehicleTypeChangelogsEntries: ChangelogEntry<ResolvedVehicleTypeChangelogChanges>[];
    vehicleTypeChangelogs: RetrievableData<VehicleTypeChangelog[]>;
    sourceVehicleType?: number;
    previousSourceVehicleType?: number;
}

const defaultVehicleTypeChangelogsState: VehicleTypeChangelogsStoreState = {
    vehicleTypeChangelogs: {
        data: [],
        pending: false,
        fulfilled: false,
        rejected: false,
    },
    vehicleTypeChangelogsEntries: [],
    sourceVehicleType: undefined,
    previousSourceVehicleType: undefined,
};

export const vehicleTypeChangelogsReducer = (
    state: VehicleTypeChangelogsStoreState = defaultVehicleTypeChangelogsState,
    action: VehicleTypeActionTypes | BaseAction<''>,
    settingsStoreState: SettingsStoreState
): VehicleTypeChangelogsStoreState => {
    switch (action.type) {
        case VehicleTypeActionTypeKeys.ADMINVEHICLETYPECHANGELOGS_GET_PENDING:
            return {
                ...state,
                vehicleTypeChangelogs: {
                    ...state.vehicleTypeChangelogs,
                    pending: true,
                },
                sourceVehicleType: action.meta,
                previousSourceVehicleType: state.sourceVehicleType ?? action.meta,
            };

        case VehicleTypeActionTypeKeys.ADMINVEHICLETYPECHANGELOGS_GET_REJECTED: {
            if (state.sourceVehicleType !== action.meta) {
                return state;
            }

            return {
                ...defaultVehicleTypeChangelogsState,
                vehicleTypeChangelogs: {
                    ...defaultVehicleTypeChangelogsState.vehicleTypeChangelogs,
                    rejected: true,
                },
            };
        }
        case VehicleTypeActionTypeKeys.ADMINVEHICLETYPECHANGELOGS_GET_FULFILLED: {
            if (state.sourceVehicleType !== action.meta) {
                return state;
            }
            return {
                ...state,
                vehicleTypeChangelogsEntries: resolveVehicleTypeChangelogsEntries(
                    action.payload.items,
                    settingsStoreState
                ),
                vehicleTypeChangelogs: {
                    data: action.payload.items,
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                },
                sourceVehicleType: undefined,
                previousSourceVehicleType: action.meta,
            };
        }

        default:
            return state;
    }
};
