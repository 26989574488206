import withStyles from '@mui/styles/withStyles';
import type * as React from 'react';
import { compose, setDisplayName } from 'react-recompose';
import type { SingleValueProps as ReactSelectSingleValueProps } from 'react-select/src/components/SingleValue';

import type { Option } from '../../models';

import type { SingleValueInnerProps, SingleValueProps } from './component';
import { SingleValueComponentFactory } from './component';
import { SingleValueStyleRules } from './styles';

export const SingleValueFactory: <T>(
    CustomChildComponent?: React.ComponentType<{ option: Option<T> }>
) => React.ComponentType<ReactSelectSingleValueProps<Option<T>>> = <T>(
    CustomChildComponent?: React.ComponentType<{ option: Option<T> }>
) =>
    compose<SingleValueInnerProps<T>, SingleValueProps<T>>(
        setDisplayName('SingleValue'),
        withStyles(SingleValueStyleRules)
    )(SingleValueComponentFactory<T>(CustomChildComponent));
