import { PtvClient } from './generated';

let theInstance: PtvClient | undefined;

export const getPtvClient = (): PtvClient => {
    if (!theInstance) {
        throw new Error('PtvApiClient has not been initialized');
    }

    return theInstance;
};

export const initializePtvClient = (baseUrl: string, xtok: string): void => {
    theInstance = new PtvClient({ xtok }, baseUrl);
};

export * from './generated';
