import Warning from '@mui/icons-material/Warning';
import type { FC } from 'react';

import { PowerOffIcon, ReeferNormalIcon, ShutDownIcon } from '~/components/Icons';
import { ReeferStatusKeys } from '~/services/ApiClient';

import type { IconFormatterProps } from './models';

const IconFormatter: FC<IconFormatterProps> = (props) => {
    const { value, iconProps } = props;

    switch (value) {
        case ReeferStatusKeys.NORMAL:
            return <ReeferNormalIcon {...iconProps} />;
        case ReeferStatusKeys.WARNING:
            return <Warning {...iconProps} />;
        case ReeferStatusKeys.SHUTDOWN:
            return <ShutDownIcon {...iconProps} />;
        case ReeferStatusKeys.OFF:
            return <PowerOffIcon {...iconProps} />;
        default:
            return null;
    }
};

IconFormatter.displayName = 'IconFormatter';
export default IconFormatter;
