import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ReorderableSectionsInnerProps, ReorderableSectionsProps } from './component';
import { ReorderableSectionsComponent } from './component';
import { ReorderableSectionsStyleRules } from './styles';

export type { SectionDefinition } from './sectionDefinition';

export const ReorderableSections = compose<ReorderableSectionsInnerProps, ReorderableSectionsProps>(
    setDisplayName('ReorderableSections'),
    withStyles(ReorderableSectionsStyleRules)
)(ReorderableSectionsComponent);
