import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory } from '~/components/SceneList';
import { getRowId, rtdsSchedulesEntryEquals } from '~/scenes/RtdsSchedules/entryUtils';
import type { RtdsSchedulesEntry } from '~/scenes/RtdsSchedules/models';

import { filterDataSourceMemoized, getColumnsMemoized } from '../../grid';
import { RTDSSCHEDULES_LIST_USERPREFERENCES_KEY, defaultRtdsSchedulesListUserPreferences } from '../../preferences';

export type RtdsSchedulesListViewProps = Omit<
    SceneListViewProps<RtdsSchedulesEntry>,
    'columns' | 'gridDataId' | 'getCellClassName'
>;

export interface RtdsSchedulesListViewInnerProps
    extends RtdsSchedulesListViewProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

const ListViewComponent = SceneListViewFactory<RtdsSchedulesEntry>(
    RTDSSCHEDULES_LIST_USERPREFERENCES_KEY,
    defaultRtdsSchedulesListUserPreferences,
    getRowId,
    rtdsSchedulesEntryEquals,
    filterDataSourceMemoized
);

export const RtdsSchedulesListViewComponent: React.FC<RtdsSchedulesListViewInnerProps> = ({
    t,
    displayPreferences,
    i18n,
    ...restProps
}) => {
    const columns = getColumnsMemoized(
        t,
        displayPreferences.vehicleDisplayFormat,
        displayPreferences.driverDisplayFormat
    );
    return <ListViewComponent columns={columns} gridDataId="rtds-schedules-grid" {...restProps} />;
};
