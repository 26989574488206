import { withTranslation } from 'react-i18next';
import { compose, defaultProps } from 'react-recompose';

import type { PercentageFormatterProps } from '~/components/Formatters';
import {
    AltitudeFormatter,
    PercentageFormatter as BasePercentageFormatter,
    CompartmentStatusFormatter,
    DistanceFormatter,
    DoorStatusFormatter,
    LatitudeFormatter,
    LongitudeFormatter,
    NearestCityFormatter,
    ReeferStatusFormatter,
    TrailerManualConnectionStatusFormatter,
    VoltageFormatter,
    WeightFormatter,
} from '~/components/Formatters';
import type { GridColumnDefinition } from '~/components/Grid';
import {
    createAddressColumn,
    createClassificationColumn,
    createDateTimeColumn,
    createDurationColumn,
    createNumberColumn,
    createTemperatureSensorColumn,
} from '~/components/Grid';
import { createClassificationsColumn } from '~/components/Grid/createClassificationsColumn';
import { createTemperatureColumn } from '~/components/Grid/createTemperatureColumn';
import type { SingleTFunction } from '~/components/LanguageSelector';
import type { MonitoringTrailerEntry } from '~/data/monitoring';
import type { NearestCity } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { exportConvertorFactory } from '~/services/Convertors';
import {
    AddressFormat,
    DurationFormat,
    FormatLength,
    exportFormatterFactory,
    formatBoolean,
    formatLatitude,
    formatLongitude,
    formatNearestCity,
    formatPercentage,
    formatVoltage,
    getAltitudeUnitSystemUtils,
    getDistanceUnitSystemUtils,
    getWeightUnitSystemUtils,
    valueTextFormatterFactory,
} from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { memoizeOne } from '~/services/Memoize';
import { compareFactory, compareNumbers, compareStrings, stringComparer } from '~/services/Sorting';

import { ColumnName } from './constants';
import {
    getAddressValue,
    getAltitudeValue,
    getAxleLoadValue,
    getBatteryLevelTrailerValue,
    getBatteryStatusTrailerValue,
    getBatteryVoltageReeferValue,
    getBatteryVoltageTrailerValue,
    getCityValue,
    getCompartmentN,
    getCompartmentNLastEventValue,
    getCompartmentNMaxBandWithTempValue,
    getCompartmentNMinBandWithTempValue,
    getCompartmentNReturnTemperatureValue,
    getCompartmentNSetpointTemperatureValue,
    getCompartmentNStatusManufacturerValue,
    getCompartmentNStatusValue,
    getCompartmentNSupplyTemperatureValue,
    getCountryCodeValue,
    getDoorNSealCodeValue,
    getDoorNSensorValue,
    getDoorNTimestampValue,
    getEbsMileageValue,
    getEbsTimestampValue,
    getEventTypeValue,
    getFuelLevelReeferValue,
    getHookedValue,
    getLatitudeValue,
    getLongitudeValue,
    getNearestCityValue,
    getPositionDateValue,
    getPostalCodeValue,
    getReeferAlarmsManufacturerValue,
    getReeferAlarmsValue,
    getReeferOperationModeValue,
    getReeferPowerModeValue,
    getReeferSpeedValue,
    getReeferStatusValue,
    getTotalEngineHoursReeferValue,
    getTrailerLastEventValue,
    getTrailerWeightValue,
} from './getCellValue.status';
import {
    getIsManuallyConnectedValue,
    getReeferManufacturerValue,
    getTrailerAliasValue,
    getTrailerDisplayNameFactory,
    getTrailerManufacturerValue,
    getTrailerNumberValue,
    getTrailerUnitIdValue,
    getTrailerVinValue,
    getTrailerVrnValue,
    getVehicleDisplayNameFactory,
} from './getCellValue.trailer';
import type {
    CompartmentColumnsProps,
    CreateNthCompartmentTemperatureSensorNProps,
    CreateNthDoorSensorColumnArgs,
    DoorColumnsProps,
    SectionProps,
} from './models';

export const getRowId = (row: MonitoringTrailerEntry): number => row.trailer.id;

const createNthDoorSensorColumn = (
    args: CreateNthDoorSensorColumnArgs
): GridColumnDefinition<MonitoringTrailerEntry> => {
    const { t, doorNumber, doorStatuses } = args;

    const column = createClassificationsColumn(
        t,
        ColumnName[`DOOR${doorNumber}SENSOR`],
        t('nth-door-sensor', { doorNumber }),
        t('doors'),
        getDoorNSensorValue(doorNumber),
        ClassificationType.DoorStatus,
        {
            valueFormatterComponent: withTranslation()(DoorStatusFormatter),
            classifications: Object.values(doorStatuses),
        }
    );

    return column;
};

const createNthDoorSealCodeColumn = (
    t: SingleTFunction,
    doorNumber: number
): GridColumnDefinition<MonitoringTrailerEntry> => ({
    dataType: 'string',
    name: ColumnName[`DOOR${doorNumber}SEALCODE`],
    title: t('nth-door-sealcode', { doorNumber }),
    groupTitle: t('doors'),
    getCellValue: getDoorNSealCodeValue(doorNumber),
    compare: compareStrings,
    align: 'right',
});

const createNthDoorTimestampColumn = (
    t: SingleTFunction,
    doorNumber: number
): GridColumnDefinition<MonitoringTrailerEntry> =>
    createDateTimeColumn(
        ColumnName[`DOOR${doorNumber}TIMESTAMP`],
        t('nth-door-timestamp', { doorNumber }),
        t('doors'),
        getDoorNTimestampValue(doorNumber)
    );

const getDoorColumns = ({ t, doorNumber, doorStatuses }: DoorColumnsProps) => [
    createNthDoorSensorColumn({ t, doorNumber, doorStatuses }),
    createNthDoorSealCodeColumn(t, doorNumber),
    createNthDoorTimestampColumn(t, doorNumber),
];

const createNthCompartmentTemperatureSensorN = ({
    t,
    unitSystem,
    compartmentNumber,
    sensorNumber,
}: CreateNthCompartmentTemperatureSensorNProps): GridColumnDefinition<MonitoringTrailerEntry> => {
    return createTemperatureSensorColumn(
        t,
        unitSystem,
        sensorNumber,
        ColumnName[`COMPARTMENT${compartmentNumber}TEMPERATURESENSOR${sensorNumber}`],
        t('nth-compartment-temperature-nth-sensor', { compartmentNumber, sensorNumber }),
        t('temperatures'),
        getCompartmentN(compartmentNumber)
    );
};

const getCompartmentColumns = ({
    t,
    unitSystem,
    compartmentNumber,
    compartmentStatuses,
}: CompartmentColumnsProps): Array<GridColumnDefinition<MonitoringTrailerEntry>> => [
    createClassificationColumn(
        t,
        ColumnName[`COMPARTMENT${compartmentNumber}STATUS`],
        t('nth-compartment-status', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNStatusValue(compartmentNumber),
        ClassificationType.CompartmentStatus,
        {
            valueFormatterComponent: withTranslation()(CompartmentStatusFormatter),
            classifications: Object.values(compartmentStatuses),
        }
    ),
    {
        dataType: 'string',
        name: ColumnName[`COMPARTMENT${compartmentNumber}STATUSMANUFACTURER`],
        title: t('nth-compartment-status-manufacturer', { compartmentNumber }),
        groupTitle: t('temperatures'),
        getCellValue: getCompartmentNStatusManufacturerValue(compartmentNumber),
        compare: compareStrings,
    },
    createDateTimeColumn(
        ColumnName[`COMPARTMENT${compartmentNumber}LASTEVENT`],
        t('nth-compartment-last-event', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNLastEventValue(compartmentNumber)
    ),
    createTemperatureColumn(
        t,
        unitSystem,
        ColumnName[`COMPARTMENT${compartmentNumber}SUPPLYTEMPERATURE`],
        t('nth-compartment-supply-temperature', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNSupplyTemperatureValue(compartmentNumber)
    ),
    createTemperatureColumn(
        t,
        unitSystem,
        ColumnName[`COMPARTMENT${compartmentNumber}RETURNTEMPERATURE`],
        t('nth-compartment-return-temperature', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNReturnTemperatureValue(compartmentNumber)
    ),
    createTemperatureColumn(
        t,
        unitSystem,
        ColumnName[`COMPARTMENT${compartmentNumber}SETPOINTTEMPERATURE`],
        t('nth-compartment-setpoint-temperature', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNSetpointTemperatureValue(compartmentNumber)
    ),
    createNthCompartmentTemperatureSensorN({ t, unitSystem, compartmentNumber, sensorNumber: 1 }),
    createNthCompartmentTemperatureSensorN({ t, unitSystem, compartmentNumber, sensorNumber: 2 }),
    createNthCompartmentTemperatureSensorN({ t, unitSystem, compartmentNumber, sensorNumber: 3 }),
    createNthCompartmentTemperatureSensorN({ t, unitSystem, compartmentNumber, sensorNumber: 4 }),
    createTemperatureColumn(
        t,
        unitSystem,
        ColumnName[`COMPARTMENT${compartmentNumber}MINBANDWITHTEMP`],
        t('nth-compartment-min-bandwith-temp', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNMinBandWithTempValue(compartmentNumber)
    ),
    createTemperatureColumn(
        t,
        unitSystem,
        ColumnName[`COMPARTMENT${compartmentNumber}MAXBANDWITHTEMP`],
        t('nth-compartment-max-bandwith-temp', { compartmentNumber }),
        t('temperatures'),
        getCompartmentNMaxBandWithTempValue(compartmentNumber)
    ),
];

export const getColumns = ({
    t,
    trailerDisplayNameFormat,
    vehicleDisplayNameFormat,
    unitSystem,
    trailerEventTypes,
    hookedStatuses,
    doorStatuses,
    batteryStatuses,
    reeferStatuses,
    compartmentStatuses,
}: SectionProps): Array<GridColumnDefinition<MonitoringTrailerEntry>> => {
    const withUnitSystem = defaultProps({ unitSystem });

    const numberToValueTextFormatter = valueTextFormatterFactory((v: number) => v.toString());

    const defaultPercentageFormatterProps: Partial<PercentageFormatterProps> = { precision: 1 };
    const PercentageFormatter = defaultProps(defaultPercentageFormatterProps)(BasePercentageFormatter);

    const altitudeUtils = getAltitudeUnitSystemUtils(t, unitSystem);
    const distanceUtils = getDistanceUnitSystemUtils(t, unitSystem);
    const weightUtils = getWeightUnitSystemUtils(t, unitSystem);

    return [
        {
            dataType: 'string',
            name: ColumnName.TRAILER,
            title: t('trailer'),
            groupTitle: t('general'),
            getCellValue: getTrailerDisplayNameFactory(trailerDisplayNameFormat),
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.TRAILERALIAS,
            title: t('trailer-alias'),
            groupTitle: t('general'),
            getCellValue: getTrailerAliasValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.TRAILERNUMBER,
            title: t('trailer-number'),
            groupTitle: t('general'),
            getCellValue: getTrailerNumberValue,
            valueTextFormatter: numberToValueTextFormatter,
            compare: compareNumbers,
            align: 'right',
        },
        {
            dataType: 'string',
            name: ColumnName.TRAILERVIN,
            title: t('trailer-vin'),
            groupTitle: t('general'),
            getCellValue: getTrailerVinValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.TRAILERVRN,
            title: t('trailer-vrn'),
            groupTitle: t('general'),
            getCellValue: getTrailerVrnValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.TRAILERUNITID,
            title: t('trailer-unit-id'),
            groupTitle: t('general'),
            getCellValue: getTrailerUnitIdValue,
            compare: compareStrings,
            align: 'right',
        },
        {
            dataType: 'string',
            name: ColumnName.VEHICLE,
            title: t('vehicle'),
            groupTitle: t('vehicle'),
            getCellValue: getVehicleDisplayNameFactory(vehicleDisplayNameFormat),
            compare: compareStrings,
        },
        {
            dataType: 'boolean',
            name: ColumnName.ISMANUALLYCONNECTED,
            title: t('is-manually-connected'),
            groupTitle: t('vehicle'),
            getCellValue: getIsManuallyConnectedValue,
            exportValueFormatter: undefined,
            valueFormatterComponent: withTranslation()(TrailerManualConnectionStatusFormatter),
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
            groupingCriteria: groupingCriteriaFactory((v: boolean) => formatBoolean(t, v)),
        },
        createClassificationColumn(
            t,
            ColumnName.EVENTTYPE,
            t('event-type'),
            t('events'),
            getEventTypeValue,
            ClassificationType.TrailerEventType,
            {
                classifications: Object.values(trailerEventTypes),
            }
        ),
        createDateTimeColumn(ColumnName.POSITIONDATE, t('position-date'), t('events'), getPositionDateValue),
        createDateTimeColumn(
            ColumnName.TRAILERLASTEVENT,
            t('trailer-last-event'),
            t('events'),
            getTrailerLastEventValue
        ),
        {
            dataType: 'string',
            name: ColumnName.CITY,
            title: t('city'),
            groupTitle: t('location'),
            getCellValue: getCityValue,
            compare: compareStrings,
        },
        createAddressColumn(ColumnName.ADDRESS, t('address'), t('location'), getAddressValue, {
            formatOptions: { format: AddressFormat.FirstLine },
        }),
        {
            dataType: 'string',
            name: ColumnName.POSTALCODE,
            title: t('postal-code'),
            groupTitle: t('location'),
            getCellValue: getPostalCodeValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.COUNTRYCODE,
            title: t('country-code'),
            groupTitle: t('location'),
            getCellValue: getCountryCodeValue,
            compare: compareStrings,
        },
        {
            dataType: 'string',
            name: ColumnName.NEARESTCITY,
            title: t('nearest-city'),
            groupTitle: t('location'),
            getCellValue: getNearestCityValue,
            exportValueFormatter: exportFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(NearestCityFormatter),
            compare: compareFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v), stringComparer),
            groupingCriteria: groupingCriteriaFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            valueTextFormatter: valueTextFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
        },
        {
            dataType: 'number',
            name: ColumnName.ALTITUDE,
            title: t('altitude'),
            groupTitle: t('location'),
            getCellValue: getAltitudeValue,
            getFilterValue: (v: number) => altitudeUtils.converter(v, { precision: 1 }),
            exportValueFormatter: exportConvertorFactory((v: number) => altitudeUtils.converter(v, { precision: 1 })),
            excelCellFormat: `0.0 "${altitudeUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(AltitudeFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => altitudeUtils.formatter(v, { precision: 1 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => altitudeUtils.formatter(v, { precision: 1 })),
            align: 'right',
        },
        {
            dataType: 'string',
            name: ColumnName.LATITUDE,
            title: t('latitude'),
            groupTitle: t('location'),
            getCellValue: getLatitudeValue,
            exportValueFormatter: exportFormatterFactory((v: number) => formatLatitude(t, v)),
            valueFormatterComponent: withTranslation()(LatitudeFormatter),
            compare: compareNumbers,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLatitude(t, v)),
        },
        {
            dataType: 'string',
            name: ColumnName.LONGITUDE,
            title: t('longitude'),
            groupTitle: t('location'),
            getCellValue: getLongitudeValue,
            exportValueFormatter: exportFormatterFactory((v: number) => formatLongitude(t, v)),
            valueFormatterComponent: withTranslation()(LongitudeFormatter),
            compare: compareStrings,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLongitude(t, v)),
        },
        ...getDoorColumns({ t, doorNumber: 1, doorStatuses }),
        ...getDoorColumns({ t, doorNumber: 2, doorStatuses }),
        {
            dataType: 'number',
            name: ColumnName.BATTERYVOLTAGETRAILER,
            title: t('battery-voltage-trailer'),
            groupTitle: t('battery'),
            getCellValue: getBatteryVoltageTrailerValue,
            excelCellFormat: `0.00 "${t('unit-voltage')}"`,
            valueFormatterComponent: withTranslation()(VoltageFormatter),
            compare: compareNumbers,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatVoltage({ t, value: v })),
            align: 'right',
        },
        {
            dataType: 'number',
            name: ColumnName.BATTERYLEVELTRAILER,
            title: t('battery-level-trailer'),
            groupTitle: t('battery'),
            getCellValue: getBatteryLevelTrailerValue,
            excelCellFormat: '0.0%',
            valueFormatterComponent: PercentageFormatter,
            compare: compareNumbers,
            exportValueFormatter: exportFormatterFactory((v: number) => formatPercentage(v, 1)),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatPercentage(v, 1)),
            align: 'right',
        },
        createClassificationColumn(
            t,
            ColumnName.BATTERYSTATUSTRAILER,
            t('battery-status-trailer'),
            t('battery'),
            getBatteryStatusTrailerValue,
            ClassificationType.TrailerBatteryStatus,
            {
                classifications: Object.values(batteryStatuses),
            }
        ),
        {
            dataType: 'number',
            name: ColumnName.BATTERYVOLTAGEREEFER,
            title: t('battery-voltage-reefer'),
            groupTitle: t('reefer'),
            getCellValue: getBatteryVoltageReeferValue,
            excelCellFormat: `0.00 "${t('unit-voltage')}"`,
            valueFormatterComponent: withTranslation()(VoltageFormatter),
            compare: compareNumbers,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatVoltage({ t, value: v })),
            align: 'right',
        },
        {
            dataType: 'number',
            name: ColumnName.FUELLEVELREEFER,
            title: t('fuel-level-reefer'),
            groupTitle: t('reefer'),
            getCellValue: getFuelLevelReeferValue,
            excelCellFormat: '0.0%',
            valueFormatterComponent: PercentageFormatter,
            compare: compareNumbers,
            exportValueFormatter: exportFormatterFactory((v: number) => formatPercentage(v, 1)),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatPercentage(v, 1)),
            align: 'right',
        },
        createDurationColumn(
            t,
            ColumnName.TOTALENGINEHOURSREEFER,
            t('total-engine-hours-reefer'),
            t('reefer'),
            getTotalEngineHoursReeferValue,
            {
                formatOptions: {
                    durationFormat: DurationFormat.Hour,
                    durationFormatLength: FormatLength.Short,
                },
                excelCellFormat: `[h]"${t('unit-hour')}"`,
            }
        ),
        createClassificationsColumn(
            t,
            ColumnName.REEFERALARMS,
            t('reefer-alarms'),
            t('reefer'),
            getReeferAlarmsValue,
            ClassificationType.ReeferAlarm
        ),
        {
            dataType: 'string',
            name: ColumnName.REEFERALARMSMANUFACTURER,
            title: t('reefer-alarms-manufacturer'),
            groupTitle: t('reefer'),
            getCellValue: getReeferAlarmsManufacturerValue,
            compare: compareStrings,
        },
        createClassificationColumn(
            t,
            ColumnName.REEFEROPERATIONMODE,
            t('reefer-operation-mode'),
            t('reefer'),
            getReeferOperationModeValue,
            ClassificationType.ReeferOperationMode
        ),
        createClassificationColumn(
            t,
            ColumnName.REEFERPOWERMODE,
            t('reefer-power-mode'),
            t('reefer'),
            getReeferPowerModeValue,
            ClassificationType.ReeferPowerMode
        ),
        createClassificationColumn(
            t,
            ColumnName.REEFERSPEED,
            t('reefer-speed'),
            t('reefer'),
            getReeferSpeedValue,
            ClassificationType.ReeferSpeed
        ),
        createClassificationColumn(
            t,
            ColumnName.REEFERSTATUS,
            t('reefer-status'),
            t('reefer'),
            getReeferStatusValue,
            ClassificationType.ReeferStatus,
            {
                valueFormatterComponent: withTranslation()(ReeferStatusFormatter),
                classifications: Object.values(reeferStatuses),
            }
        ),
        createClassificationColumn(
            t,
            ColumnName.HOOKED,
            t('hooked'),
            t('events'),
            getHookedValue,
            ClassificationType.HookedStatus,
            {
                classifications: Object.values(hookedStatuses),
            }
        ),
        createDateTimeColumn(ColumnName.EBSTIMESTAMP, t('ebs-timestamp'), t('ebs'), getEbsTimestampValue),
        {
            dataType: 'number',
            name: ColumnName.EBSMILEAGE,
            title: t('ebs-mileage'),
            groupTitle: t('ebs'),
            getCellValue: getEbsMileageValue,
            getFilterValue: (v: number) => distanceUtils.converter(v, { precision: 0 }),
            exportValueFormatter: exportConvertorFactory((v: number) => distanceUtils.converter(v, { precision: 0 })),
            excelCellFormat: `0 "${distanceUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(DistanceFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => distanceUtils.formatter(v, { precision: 0 })),
            align: 'right',
        },
        {
            dataType: 'number',
            name: ColumnName.AXLELOAD,
            title: t('axle-load'),
            groupTitle: t('ebs'),
            getCellValue: getAxleLoadValue,
            getFilterValue: (v: number) => weightUtils.converter(v, { precision: 0 }),
            exportValueFormatter: exportConvertorFactory((v: number) => weightUtils.converter(v, { precision: 0 })),
            excelCellFormat: `0 "${weightUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(WeightFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
            align: 'right',
        },
        createClassificationColumn(
            t,
            ColumnName.TRAILERMANUFACTURER,
            t('trailer-manufacturer'),
            t('general'),
            getTrailerManufacturerValue,
            ClassificationType.TrailerManufacturer
        ),
        createClassificationColumn(
            t,
            ColumnName.REEFERMANUFACTURER,
            t('reefer-manufacturer'),
            t('reefer'),
            getReeferManufacturerValue,
            ClassificationType.ReeferManufacturer
        ),
        ...getCompartmentColumns({ t, unitSystem, compartmentNumber: 1, compartmentStatuses }),
        ...getCompartmentColumns({ t, unitSystem, compartmentNumber: 2, compartmentStatuses }),
        ...getCompartmentColumns({ t, unitSystem, compartmentNumber: 3, compartmentStatuses }),
        ...getCompartmentColumns({ t, unitSystem, compartmentNumber: 4, compartmentStatuses }),
        createNumberColumn(ColumnName.TRAILERWEIGHT, t('trailer-weight'), t('ebs'), getTrailerWeightValue, {
            getFilterValue: (v: number) => weightUtils.converter(v, { precision: 0 }),
            exportValueFormatter: exportConvertorFactory((v: number) => weightUtils.converter(v, { precision: 0 })),
            excelCellFormat: `0 "${weightUtils.unit}"`,
            valueFormatterComponent: compose(withTranslation(), withUnitSystem)(WeightFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => weightUtils.formatter(v, { precision: 0 })),
            align: 'right',
        }),
    ];
};

export const getColumnsMemoized = memoizeOne(getColumns);
