export enum SceneRoutes {
    MONITORING = '/monitoring',
    SETTINGS_USER = '/settings/user',
    SETTINGS_CUSTOMER = '/settings/customer',
    SETTINGS_REPORTS = '/settings/report',
    HISTORY = '/history',
    HISTORY_VEHICLE = '/history/historic-positions',
    HISTORY_TRAILER = '/history/trailer-events',
    TRIP_REPORT = '/history/trip-report',
    DRIVER_ACTIVITIES_REPORT = '/history/driver-activities-report',
    HISTORY_DRIVER = '/history/driver-activities',
    ASSET_NEAR_BY = '/history/asset-near-by',
    COMMUNICATION = '/communication',
    TACHOFILES = '/tacho-files',
    TACHOLINCSTATUS = '/tacholinc-status',
    RTDSSESSIONS = '/rtds-sessions',
    RTDSSCHEDULES = '/rtds-schedules',
    ADMIN_VEHICLES = '/administration/vehicles',
    ADMIN_DRIVERS = '/administration/drivers',
    IMPERSONATION = '/impersonation',
    UNAUTHORIZED = '/unauthorized',
    ADMIN_VEHICLE_TYPES = '/administration/vehicle-types',
    ADMIN_TRAILERS = '/administration/trailers',
    ADMIN_DEVICES = '/administration/devices',
    ADMIN_COMPANY_CARD = '/administration/company-card',
    ADMIN_GROUPS = '/administration/groups',
    USER_ROLES_PERMISSIONS = '/administration/user-roles-permissions',
    ADMIN_USERS = '/administration/users',
    ALERTS = '/alerts',
    ALERT_DEFINITIONS = '/administration/alert-definitions',
    GEOZONE_ADMINISTRATION = '/administration/geozone',
}

const CHANGELOG_PATH = 'changelogs';

const ADMIN_DEVICES_PATHS = [
    SceneRoutes.ADMIN_DEVICES,
    `${SceneRoutes.ADMIN_DEVICES}/:deviceId([0-9]+)`,
    `${SceneRoutes.ADMIN_DEVICES}/:deviceId([0-9]+)/:subpage(${CHANGELOG_PATH})`,
];

const ADMIN_VEHICLE_TYPES_PATHS = [
    SceneRoutes.ADMIN_VEHICLE_TYPES,
    `${SceneRoutes.ADMIN_VEHICLE_TYPES}/:vehicleTypeId([0-9]+)`,
    `${SceneRoutes.ADMIN_VEHICLE_TYPES}/:vehicleTypeId([0-9]+)/:subpage(${CHANGELOG_PATH})`,
];

const ADMIN_COMPANY_CARD_PATHS = [
    SceneRoutes.ADMIN_COMPANY_CARD,
    `${SceneRoutes.ADMIN_COMPANY_CARD}/:companyCardId([0-9]+)`,
];

const RTDS_SCHEDULES_PATHS = [
    SceneRoutes.RTDSSCHEDULES,
    `${SceneRoutes.RTDSSCHEDULES}/:scheduleId([0-9]+)`,
    `${SceneRoutes.RTDSSCHEDULES}/:scheduleId([0-9]+)/:subpage(${CHANGELOG_PATH})`,
];

const ALERT_DEFINITIONS_PATHS = [SceneRoutes.ALERT_DEFINITIONS, `${SceneRoutes.ALERT_DEFINITIONS}/:id`];

export {
    ADMIN_DEVICES_PATHS,
    CHANGELOG_PATH,
    ADMIN_VEHICLE_TYPES_PATHS,
    RTDS_SCHEDULES_PATHS,
    ADMIN_COMPANY_CARD_PATHS,
    ALERT_DEFINITIONS_PATHS,
};
