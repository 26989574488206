import type { Identity } from '~/data/authentication';
import type { Action } from '~/types';

export enum ActionTypeKeys {
    SAVEIDENTITY = 'SAVEIDENTITY',
}

export interface SaveIdentityPayload {
    identity?: Identity;
}

export type ActionTypes = Action<ActionTypeKeys.SAVEIDENTITY, SaveIdentityPayload>;
