import { createAction } from 'redux-actions';

import type { ContainerStatus } from '~/services/ApiClient';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { ContainerStatusPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const retrieveContainerStatusExecutor = (): Promise<ContainerStatusPayload> => {
    return retryableRequest(() => ApiClient.getContainerStatus());
};

export const retrieveContainerStatusAction = createAction(
    ActionTypePrefixes.CONTAINERSTATUS,
    retrieveContainerStatusExecutor
);

export const updateContainerStatusAction = createAction<ContainerStatus>(ActionTypeKeys.CONTAINERSTATUS_UPDATE);
