import { Tooltip } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetAvatar } from '~/components/AssetAvatar';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import { IconFormatter } from './components/Icon';
import type { ReeferStatusFormatterProps } from './models';
import { useStyles } from './styles';

const ReeferStatusFormatter: FC<ReeferStatusFormatterProps> = (props) => {
    const { value, withAvatar, headerAvatar } = props;

    const { t } = useTranslation();
    const classes = useStyles();

    if (!value) {
        return null;
    }

    const title = formatClassification(t, ClassificationType.ReeferStatus, value);

    if (withAvatar) {
        return (
            <AssetAvatar
                icon={<IconFormatter value={value.key} />}
                classes={headerAvatar ? { avatar: classes.headerAvatar } : { avatar: classes.avatar }}
                tooltip={title}
            />
        );
    }

    return (
        <Tooltip title={title}>
            <span data-id={`reefer-status:${value.key}`}>
                <IconFormatter value={value.key} />
            </span>
        </Tooltip>
    );
};

ReeferStatusFormatter.displayName = 'ReeferStatusFormatter';
export default ReeferStatusFormatter;
