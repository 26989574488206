import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type NotAuthorizedPageSnackbarClassKeys = 'content' | 'link';

export const useStyles = makeStyles<Theme, {}, NotAuthorizedPageSnackbarClassKeys>((theme) => ({
    content: {
        backgroundColor: theme.palette.warning2.main,
        fontWeight: 'bold',
        color: theme.palette.warning2.contrastText,
    },
    link: { color: theme.palette.warning2.contrastText },
}));
