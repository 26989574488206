import { ConversationUpdate, createApiModel } from '~/services/ApiClient';

import { mergeDeletedMessages } from './mergeDeletedMessages';
import { mergeUpdatedMessages } from './mergeUpdatedMessages';

export const mergeConversationUpdates = (
    pendingConversationUpdate: ConversationUpdate,
    nextConversationUpdate: ConversationUpdate
): ConversationUpdate =>
    createApiModel(ConversationUpdate, {
        ...(nextConversationUpdate.revision > pendingConversationUpdate.revision
            ? nextConversationUpdate
            : pendingConversationUpdate),
        updatedMessages: mergeUpdatedMessages(pendingConversationUpdate, nextConversationUpdate),
        deletedMessages: mergeDeletedMessages(pendingConversationUpdate, nextConversationUpdate),
    });
