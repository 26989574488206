import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { isUndefined } from '~/libs/utility';
import { formatDirection } from '~/services/Formatters';

export interface DirectionFormatterProps {
    value?: number;
    undefinedValueMessage?: string;
}

export interface DirectionFormatterInnerProps extends DirectionFormatterProps {}

const DirectionFormatter: React.FunctionComponent<DirectionFormatterInnerProps> = ({
    value,
    undefinedValueMessage,
}) => {
    const { t } = useTranslation();
    return (
        <div data-testid="direction-formatter-component">
            {isUndefined(value) ? undefinedValueMessage : formatDirection(t, value)}
        </div>
    );
};
DirectionFormatter.displayName = 'DirectionFormatter';

export { DirectionFormatter };
