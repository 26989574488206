import * as storage from 'localforage';
import { combineReducers } from 'redux';
import type { PersistConfig } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import type { PersistPartial } from 'redux-persist/es/persistReducer';

import { loginReducer } from './components/Login';
import type { LoginStoreState } from './components/Login/reducers';
import type { SingleSignOnStoreState } from './components/SingleSignOn';
import { singleSignOnReducer } from './components/SingleSignOn';
import type { AuthenticationStoreState } from './reducers';
import { authenticationReducer as reducer } from './reducers';

export interface AuthenticationSceneStoreState {
    authentication: AuthenticationStoreState & PersistPartial;
    login: LoginStoreState & PersistPartial;
    singleSignOn: SingleSignOnStoreState;
}

const config: PersistConfig<AuthenticationStoreState> = {
    key: 'authentication',
    storage,
    whitelist: ['loginType'],
};

const authenticationReducer = persistReducer(config, reducer);

export const authenticationSceneReducer = combineReducers({
    authentication: authenticationReducer,
    login: loginReducer,
    singleSignOn: singleSignOnReducer,
});
