import type { MonitoringTrailerEntry } from '~/data/monitoring';
import type { Address, Classification, NearestCity, ResolvedCompartmentStatus } from '~/services/ApiClient';
import { parseOptionalDuration } from '~/services/Parsers';

export const getEventTypeValue = (entry: MonitoringTrailerEntry): Classification | undefined =>
    entry.status?.lastEvent.trailerEventType;

export const getPositionDateValue = (entry: MonitoringTrailerEntry): Date | undefined =>
    entry.status?.location?.dateTime;

export const getTrailerLastEventValue = (entry: MonitoringTrailerEntry): Date | undefined =>
    entry.status?.lastEvent.dateTime;

export const getCityValue = (entry: MonitoringTrailerEntry): string | undefined =>
    entry.status?.location?.address?.city;

export const getAddressValue = (entry: MonitoringTrailerEntry): Address | undefined => entry.status?.location?.address;

export const getPostalCodeValue = (entry: MonitoringTrailerEntry): string | undefined =>
    entry.status?.location?.address?.postalCode;

export const getCountryCodeValue = (entry: MonitoringTrailerEntry): string | undefined =>
    entry.status?.location?.address?.countryCode;

export const getNearestCityValue = (entry: MonitoringTrailerEntry): NearestCity | undefined =>
    entry.status?.location?.nearestCity;

export const getAltitudeValue = (entry: MonitoringTrailerEntry): number | undefined =>
    entry.status?.location?.position.altitude;

export const getLatitudeValue = (entry: MonitoringTrailerEntry): number | undefined =>
    entry.status?.location?.position.latitude;

export const getLongitudeValue = (entry: MonitoringTrailerEntry): number | undefined =>
    entry.status?.location?.position.longitude;

export const getDoorNSensorValue = (doorNumber: number) => {
    return (entry: MonitoringTrailerEntry): Classification[] | undefined => entry.status?.doors[doorNumber]?.status;
};

export const getDoorNSealCodeValue = (doorNumber: number) => {
    return (entry: MonitoringTrailerEntry): string | undefined => entry.status?.doors[doorNumber]?.sealCode;
};

export const getDoorNTimestampValue = (doorNumber: number) => {
    return (entry: MonitoringTrailerEntry): Date | undefined => entry.status?.doors[doorNumber]?.dateTime;
};

export const getBatteryVoltageTrailerValue = (entry: MonitoringTrailerEntry): number | undefined =>
    entry.status?.battery?.voltage;

export const getBatteryLevelTrailerValue = (entry: MonitoringTrailerEntry): number | undefined =>
    entry.status?.battery?.level;

export const getBatteryStatusTrailerValue = (entry: MonitoringTrailerEntry): Classification | undefined =>
    entry.status?.battery?.status;

export const getBatteryVoltageReeferValue = (entry: MonitoringTrailerEntry): number | undefined =>
    entry.status?.reefer?.batteryVoltage;

export const getFuelLevelReeferValue = (entry: MonitoringTrailerEntry): number | undefined =>
    entry.status?.reefer?.fuelLevel;

export const getTotalEngineHoursReeferValue = (entry: MonitoringTrailerEntry): moment.Duration | undefined =>
    parseOptionalDuration(entry.status?.reefer?.totalAmountOfEngineHours, 'hours');

export const getReeferAlarmsValue = (entry: MonitoringTrailerEntry): Classification[] | undefined =>
    entry.status?.reefer?.alarms;

export const getReeferAlarmsManufacturerValue = (entry: MonitoringTrailerEntry): string | undefined =>
    entry.status?.reefer?.alarmManufacturer;

export const getReeferOperationModeValue = (entry: MonitoringTrailerEntry): Classification | undefined =>
    entry.status?.reefer?.operationMode;

export const getReeferPowerModeValue = (entry: MonitoringTrailerEntry): Classification | undefined =>
    entry.status?.reefer?.powerMode;

export const getReeferSpeedValue = (entry: MonitoringTrailerEntry): Classification | undefined =>
    entry.status?.reefer?.speed;

export const getReeferStatusValue = (entry: MonitoringTrailerEntry): Classification | undefined =>
    entry.status?.reefer?.status;

export const getHookedValue = (entry: MonitoringTrailerEntry): Classification | undefined => entry.status?.hooked;

export const getEbsTimestampValue = (entry: MonitoringTrailerEntry): Date | undefined => entry.status?.ebs?.dateTime;

export const getEbsMileageValue = (entry: MonitoringTrailerEntry): number | undefined => entry.status?.ebs?.milage;

export const getAxleLoadValue = (entry: MonitoringTrailerEntry): number | undefined => entry.status?.ebs?.axleLoadSum;
export const getTrailerWeightValue = (entry: MonitoringTrailerEntry): number | undefined =>
    entry.status?.ebs?.trailerWeight;
export const getCompartmentNStatusValue = (compartmentNumber: number) => {
    return (entry: MonitoringTrailerEntry): Classification | undefined =>
        entry.status?.compartments[compartmentNumber]?.status;
};

export const getCompartmentNStatusManufacturerValue = (compartmentNumber: number) => {
    return (entry: MonitoringTrailerEntry): string | undefined =>
        entry.status?.compartments[compartmentNumber]?.statusManufacturer;
};

export const getCompartmentNLastEventValue = (compartmentNumber: number) => {
    return (entry: MonitoringTrailerEntry): Date | undefined => entry.status?.compartments[compartmentNumber]?.dateTime;
};

export const getCompartmentNSupplyTemperatureValue = (compartmentNumber: number) => {
    return (entry: MonitoringTrailerEntry): number | undefined =>
        entry.status?.compartments[compartmentNumber]?.supplyTemperature;
};

export const getCompartmentNReturnTemperatureValue = (compartmentNumber: number) => {
    return (entry: MonitoringTrailerEntry): number | undefined =>
        entry.status?.compartments[compartmentNumber]?.returnTemperature;
};

export const getCompartmentNSetpointTemperatureValue = (compartmentNumber: number) => {
    return (entry: MonitoringTrailerEntry): number | undefined =>
        entry.status?.compartments[compartmentNumber]?.setpointTemperature;
};

export const getCompartmentN = (compartmentNumber: number) => {
    return (entry: MonitoringTrailerEntry): ResolvedCompartmentStatus | undefined =>
        entry.status?.compartments[compartmentNumber];
};

export const getCompartmentNMinBandWithTempValue = (compartmentNumber: number) => {
    return (entry: MonitoringTrailerEntry): number | undefined =>
        entry.status?.compartments[compartmentNumber]?.minTemperature;
};

export const getCompartmentNMaxBandWithTempValue = (compartmentNumber: number) => {
    return (entry: MonitoringTrailerEntry): number | undefined =>
        entry.status?.compartments[compartmentNumber]?.maxTemperature;
};
