import type { MonitoringContainerEntry } from '~/data/monitoring';

import { MarkerIcon } from '../../../../../MarkerIcon';

import { ContainerIcon } from './styles';

export interface ContainerMarkerIconProps {
    entry: MonitoringContainerEntry;
}

export interface ContainerMarkerIconInnerProps extends ContainerMarkerIconProps {}

const ContainerMarkerIcon: React.FC<ContainerMarkerIconInnerProps> = () => (
    <MarkerIcon
        AssetIcon={ContainerIcon}
        driverActivity={undefined}
        courseOverGround={undefined}
        speedOverGround={undefined}
    />
);
ContainerMarkerIcon.displayName = 'ContainerMarkerIcon';

export { ContainerMarkerIcon };
