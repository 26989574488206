import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import type { CountryFormatterInnerProps, CountryFormatterProps } from './component';
import { CountryFormatterComponent } from './component';
import { CountryFormatterStyleRules } from './styles';

export const CountryFormatter = compose<CountryFormatterInnerProps, CountryFormatterProps>(
    setDisplayName('CountryFormatter'),
    withStyles(CountryFormatterStyleRules)
)(CountryFormatterComponent);
