const viewportDownscaleFactor = 0.85;

export const minimumScreenResolutionReference = {
    conventionalName: 'Full-HD',
    width: 1920,
    height: 1080,
};

export const minimunScreenResolution = {
    width: minimumScreenResolutionReference.width * viewportDownscaleFactor,
    height: minimumScreenResolutionReference.height * viewportDownscaleFactor,
};
