import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ServicesSectionInnerProps, ServicesSectionProps } from './component';
import { ServicesSectionComponent } from './component';

export const ServicesSection = compose<ServicesSectionInnerProps, ServicesSectionProps>(
    setDisplayName('ServicesSection'),
    withTranslation()
)(ServicesSectionComponent);
