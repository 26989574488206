import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { Chip, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import type { AutocompleteChangeReason, AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import type { ChangeEvent, FC, HTMLAttributes } from 'react';
import { useCallback } from 'react';

import { nullFilterValue } from '~/components/Grid/constants';
import { isNil, isObject, isUndefined } from '~/libs/utility';
import type { Classification, ClassificationAbstraction } from '~/services/ApiClient';
import { formatBoolean, formatDate } from '~/services/Formatters';

import type { FilterOptionsCellInnerProps, Options } from './models';
import { buildAndSortOptions } from './utils';

const castValueAsClassification = (value: unknown): ClassificationAbstraction | undefined => {
    const classification = isObject(value) ? (value as ClassificationAbstraction) : undefined;
    return classification;
};

export const FilterOptionsCellComponent: FC<FilterOptionsCellInnerProps> = (props) => {
    const {
        columnName,
        options,
        t,
        valueTextFormatter,
        renderOptions,
        classes,
        i18n,
        hasSelectAll = true,
        ...rest
    } = props;

    const selectedOptions: unknown[] = rest.filter?.value ? JSON.parse(rest.filter.value) : [];

    const allSelected = options.length === selectedOptions.length;

    const formatValue = useCallback(
        (inputValue: unknown): string => {
            const value = inputValue === nullFilterValue ? undefined : inputValue;

            if (isNil(value)) {
                return t('unknown');
            }

            if (typeof value === 'string' && value === 'select-all') {
                return t(value);
            }

            const classification = castValueAsClassification(value);

            if (classification) {
                return classification?.displayName;
            }

            if (valueTextFormatter) {
                const formatted = valueTextFormatter(value);
                if (formatted) {
                    return formatted;
                }
            } else if (typeof value === 'string') {
                return value;
            } else if (typeof value === 'boolean') {
                formatBoolean(t, value);
            } else if (typeof value === 'number') {
                return value.toString();
            } else if (value instanceof Date) {
                formatDate(value);
            }

            return (value as object)?.toString() ?? '';
        },
        [valueTextFormatter, t]
    );

    const formatId = useCallback((inputValue: unknown): string => {
        const value = inputValue === nullFilterValue ? undefined : inputValue;

        if (isNil(value)) {
            return 'unknown';
        }

        const classification = castValueAsClassification(value);
        return classification ? classification.id.toString() : (value as object)?.toString();
    }, []);

    const renderOption = useCallback(
        (liProps: HTMLAttributes<HTMLLIElement>, option: unknown, { selected }) => {
            const idFormatted = formatId(option);
            const selectAllProps = option === 'select-all' ? { checked: allSelected } : {};

            return (
                <li {...liProps}>
                    <Checkbox
                        className={classes.checkBox}
                        data-id={`option:${idFormatted}`}
                        size="small"
                        checked={selected}
                        {...selectAllProps}
                    />
                    {!isUndefined(renderOptions) && idFormatted !== 'unknown' ? (
                        <div className={classes.renderOptionMargin}>{renderOptions(option)}</div>
                    ) : null}
                    {formatValue(option)}
                </li>
            );
        },
        [classes.checkBox, classes.renderOptionMargin, formatId, formatValue, renderOptions, allSelected]
    );

    const handleChange = useCallback(
        (_: ChangeEvent, selectedValues: Options, reason: AutocompleteChangeReason) => {
            const selectAllChecked = selectedValues.find((option) => option === 'select-all');

            if (
                !['selectOption', 'removeOption'].includes(reason) ||
                (selectAllChecked && allSelected) ||
                selectedValues.length === 0
            ) {
                rest.onFilter(null);
                return;
            }

            const updatedFilterValue = selectAllChecked && !allSelected ? options : selectedValues;
            rest.onFilter({
                columnName,
                value: JSON.stringify(updatedFilterValue),
            });
        },
        [options, columnName, rest, allSelected]
    );

    const optionsToRender = buildAndSortOptions({ options, hasSelectAll, formatValue });

    const isOptionSelected = useCallback((option: unknown, value: unknown): boolean => {
        const optionClassification = castValueAsClassification(option);
        const valueClassification = castValueAsClassification(value);

        if (optionClassification && valueClassification) {
            return optionClassification.id === valueClassification.id;
        }

        return option === value;
    }, []);

    const autocompleteClasses = {
        input: classes.input,
        inputRoot: classes.inputRoot,
        hasPopupIcon: classes.hasPopupIcon,
        endAdornment: classes.endAdornment,
        popper: classes.popper,
    };

    return (
        <TableFilterRow.Cell {...rest}>
            <Autocomplete
                classes={autocompleteClasses}
                disableClearable
                disableCloseOnSelect
                multiple
                disablePortal
                options={optionsToRender}
                value={selectedOptions}
                isOptionEqualToValue={isOptionSelected}
                getOptionLabel={formatValue}
                onChange={handleChange}
                renderOption={renderOption}
                // Rendering only 1 selected(the first in the selected items list) item and add the number of the rest selected
                renderTags={(values: Classification[] | string[], getTagProps) => {
                    return (
                        !isUndefined(values[0]) && (
                            <>
                                <Chip
                                    label={(values[0] as Classification)?.displayName ?? formatValue(values[0])}
                                    {...getTagProps({ index: 0 })}
                                />
                                {values.length > 1 && <Typography>+ {values.length - 1}</Typography>}
                            </>
                        )
                    );
                }}
                renderInput={(params: AutocompleteRenderInputParams) => <TextField variant="standard" {...params} />}
            />
        </TableFilterRow.Cell>
    );
};
