import type { MonitoringTrailerEntry } from '~/data/monitoring';
import type {
    Classification,
    DisplayUserPreferencesTrailerDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
} from '~/services/ApiClient';
import { formatTrailerName, formatVehicleName } from '~/services/Formatters';

export const getTrailerDisplayNameFactory =
    (trailerDisplayNameFormat: DisplayUserPreferencesTrailerDisplayFormat) =>
    (entry: MonitoringTrailerEntry): string => {
        return formatTrailerName(entry.trailer, trailerDisplayNameFormat);
    };

export const getTrailerAliasValue = (entry: MonitoringTrailerEntry): string | undefined => entry.trailer.alias;

export const getTrailerNumberValue = (entry: MonitoringTrailerEntry): number | undefined => entry.trailer.trailerNumber;

export const getTrailerVinValue = (entry: MonitoringTrailerEntry): string | undefined => entry.trailer.vin;

export const getTrailerVrnValue = (entry: MonitoringTrailerEntry): string | undefined => entry.trailer.vrn;

export const getTrailerUnitIdValue = (entry: MonitoringTrailerEntry): string | undefined => entry.trailer.unitId;

export const getTrailerManufacturerValue = (entry: MonitoringTrailerEntry): Classification | undefined =>
    entry.trailer.trailerManufacturer;

export const getReeferManufacturerValue = (entry: MonitoringTrailerEntry): Classification | undefined =>
    entry.trailer.reeferManufacturer;

export const getVehicleDisplayNameFactory =
    (vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat) =>
    (entry: MonitoringTrailerEntry): string | undefined =>
        entry.vehicleConnection &&
        formatVehicleName(entry.vehicleConnection.vehicleReference, vehicleDisplayNameFormat);

export const getIsManuallyConnectedValue = (entry: MonitoringTrailerEntry): boolean | undefined =>
    entry.vehicleConnection?.isManuallyConnected;
