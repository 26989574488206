import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';
import type {
    ResolvedVehicleSettingsEdit,
    ResolvedVehicleSettingsRead,
    VehicleSettingsEdit,
    VehicleSettingsRead,
} from '~/services/ApiClient';
import { SettingsValueSource } from '~/services/ApiClient';

export const resolveVehicleSettings = (
    vehicleSettings: VehicleSettingsRead,
    settingsStoreState: SettingsStoreState
): ResolvedVehicleSettingsRead => {
    const tachograph = settingsStoreState.tachograph.data[vehicleSettings.tachograph];
    const tachoProtocol = settingsStoreState.tachoProtocol.data[vehicleSettings.tachoProtocol];
    const tachoGeneration = settingsStoreState.tachoGeneration.data[vehicleSettings.tachoGeneration];
    const workflowType = settingsStoreState.workflowType.data[vehicleSettings.workflowType];
    const navigationType = settingsStoreState.navigationType.data[vehicleSettings.navigationType];
    const imageCapturingType = settingsStoreState.imageCapturingType.data[vehicleSettings.imageCapturingType];
    const inhibitorServiceType = settingsStoreState.inhibitorServiceType.data[vehicleSettings.inhibitorServiceType];
    const tellTaleServiceType = settingsStoreState.tellTaleServiceType.data[vehicleSettings.tellTaleServiceType];
    const primaryFuelTankType = settingsStoreState.fuelTankType.data[vehicleSettings.primaryFuelTankType];
    const secondaryFuelTankType = settingsStoreState.fuelTankType.data[vehicleSettings.secondaryFuelTankType];

    return {
        ...vehicleSettings,
        tachograph,
        tachoProtocol,
        tachoGeneration,
        workflowType,
        navigationType,
        imageCapturingType,
        inhibitorServiceType,
        tellTaleServiceType,
        primaryFuelTankType,
        secondaryFuelTankType,
    };
};

export const resolveVehicleSettingsEdit = (
    vehicleSettings: VehicleSettingsEdit,
    settingsStoreState: SettingsStoreState
): ResolvedVehicleSettingsEdit => {
    const tachograph = settingsStoreState.tachograph.data[vehicleSettings.tachograph.value];
    const tachoProtocol = settingsStoreState.tachoProtocol.data[vehicleSettings.tachoProtocol.value];
    const tachoGeneration = settingsStoreState.tachoGeneration.data[vehicleSettings.tachoGeneration.value];
    const workflowType = settingsStoreState.workflowType.data[vehicleSettings.workflowType?.value ?? 0];
    const navigationType = settingsStoreState.navigationType.data[vehicleSettings.navigationType?.value ?? 0];
    const imageCapturingType =
        settingsStoreState.imageCapturingType.data[vehicleSettings.imageCapturingType?.value ?? 0];
    const inhibitorServiceType =
        settingsStoreState.inhibitorServiceType.data[vehicleSettings.inhibitorServiceType?.value ?? 0];
    const tellTaleServiceType =
        settingsStoreState.tellTaleServiceType.data[vehicleSettings.tellTaleServiceType?.value ?? 0];

    return {
        ...vehicleSettings,
        tachograph: {
            value: tachograph,
            source: vehicleSettings.tachograph.source,
        },
        tachoProtocol: {
            value: tachoProtocol,
            source: vehicleSettings.tachoProtocol.source,
        },
        tachoGeneration: {
            value: tachoGeneration,
            source: vehicleSettings.tachoGeneration.source,
        },
        workflowType: {
            value: workflowType,
            source: vehicleSettings.workflowType?.source ?? SettingsValueSource.Asset,
        },
        navigationType: {
            value: navigationType,
            source: vehicleSettings.navigationType?.source ?? SettingsValueSource.Asset,
        },
        imageCapturingType: {
            value: imageCapturingType,
            source: vehicleSettings.imageCapturingType?.source ?? SettingsValueSource.Asset,
        },
        inhibitorServiceType: {
            value: inhibitorServiceType,
            source: vehicleSettings.inhibitorServiceType?.source ?? SettingsValueSource.Asset,
        },
        tellTaleServiceType: {
            value: tellTaleServiceType,
            source: vehicleSettings.tellTaleServiceType?.source ?? SettingsValueSource.Asset,
        },
    };
};
