import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { TemperatureSensorFormatterComponent } from './component';
import type { TemperatureSensorFormatterInnerProps, TemperatureSensorFormatterProps } from './models';
import { TemperatureSensorStyleRules } from './styles';

export const TemperatureSensorFormatter = compose<
    TemperatureSensorFormatterInnerProps,
    TemperatureSensorFormatterProps
>(
    setDisplayName('TemperatureSensorFormatter'),
    withStyles(TemperatureSensorStyleRules)
)(TemperatureSensorFormatterComponent);
