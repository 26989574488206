import type { StoreState } from '~/reducers';

import { conversationWidgetsStoreStateSelector } from '../../../../selectors';
import type { ConversationWidgetStoreState, TranslatedMessagesState } from '../../reducers';

import type { ConversationWidgetProps } from './component';

export const conversationStoreStateSelector = (
    s: StoreState,
    { vehicleId }: ConversationWidgetProps
): ConversationWidgetStoreState | undefined => {
    return conversationWidgetsStoreStateSelector(s).conversations[vehicleId];
};

export const translatedMessagesStoreStateSelector = (s: StoreState): TranslatedMessagesState | undefined => {
    return conversationWidgetsStoreStateSelector(s).translatedMessages;
};

export const vehicleIdSelector = (_: unknown, { vehicleId }: ConversationWidgetProps): number | undefined => vehicleId;
