import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { Subtract } from 'utility-types';

import { protectedComponentDecodedTokenStateSelector } from '../../selectors';

import type { UserDataStateProps } from './redux';
import { mapStateToProps as userDataMapStateToProps } from './redux';

export interface InjectedUserDataProps extends UserDataStateProps {}

export const withUserData = <WrappedProps extends InjectedUserDataProps>(): ComponentEnhancer<
    WrappedProps,
    Subtract<WrappedProps, InjectedUserDataProps>
> =>
    compose<WrappedProps, Subtract<WrappedProps, InjectedUserDataProps>>(
        connect(createSelector(protectedComponentDecodedTokenStateSelector, userDataMapStateToProps))
    );
