import type { Dispatch } from 'redux';

import { openUnseenCarouselAction } from '~/components/IntroCarousel';
import { impersonateUserAction } from '~/data/authentication';
import { reportError } from '~/reporting';

import type { OverlayMenuStoreState } from '../../reducers';

import type { DispatchProps, OwnProps, StateProps } from './component';

export const mapStateToProps = (state: OverlayMenuStoreState): StateProps => {
    return {
        impersonateUserPending: state.impersonateUserPending,
        impersonateUserFulfilled: state.impersonateUserFulfilled,
        impersonateUserFailed: state.impersonateUserFailed,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    openUnseenCarousel: () => {
        dispatch(openUnseenCarouselAction());
    },
    selectCustomer: (name: string) => {
        if (ownProps.impersonatorToken) {
            dispatch(impersonateUserAction(name, undefined)).catch(reportError);
        }
    },
    selectUser: (name: string) => {
        if (ownProps.impersonatorToken) {
            dispatch(impersonateUserAction(ownProps.customerName, name)).catch(reportError);
        }
    },
});
