import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

const formatDriverSubpageListItemLabelInternal = (t: SingleTFunction, key: string) =>
    t(`driver-subpage-${key.toLocaleLowerCase()}`);

const formatDriverSubpageListItemLabelMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((key: string) => formatDriverSubpageListItemLabelInternal(t, key))
);

export const formatDriverSubpageListItemLabel = (t: SingleTFunction, key: string): string =>
    formatDriverSubpageListItemLabelMemoized(t)(key);
