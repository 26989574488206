import type { ConversationMessagesResponse, ConversationNotification } from '~/services/ApiClient';
import type { ActionMeta, FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export type ConversationMessagesPayload = Readonly<ConversationMessagesResponse>;

export enum ActionTypePrefixes {
    LATESTMESSAGES = 'LATESTMESSAGES',
}

export enum ActionTypeKeys {
    LATESTMESSAGES_PENDING = 'LATESTMESSAGES_PENDING',
    LATESTMESSAGES_FULFILLED = 'LATESTMESSAGES_FULFILLED',
    LATESTMESSAGES_REJECTED = 'LATESTMESSAGES_REJECTED',
    LATESTMESSAGES_UPDATE = 'LATESTMESSAGES_UPDATE',
    LATESTMESSAGES_APPLY_PENDING = 'LATESTMESSAGES_APPLY_PENDING',
    LATESTMESSAGES_CLEAR = 'LATESTMESSAGES_CLEAR',
}

export type ActionTypes =
    | PendingMeta<ActionTypeKeys.LATESTMESSAGES_PENDING, number>
    | FulfilledMeta<ActionTypeKeys.LATESTMESSAGES_FULFILLED, ConversationMessagesPayload, number>
    | RejectedMeta<ActionTypeKeys.LATESTMESSAGES_REJECTED, undefined, number>
    | ActionMeta<ActionTypeKeys.LATESTMESSAGES_UPDATE, ConversationNotification, number>
    | ActionMeta<ActionTypeKeys.LATESTMESSAGES_APPLY_PENDING, undefined, number>
    | ActionMeta<ActionTypeKeys.LATESTMESSAGES_CLEAR, undefined, number>;
