import { Popper } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import { v1 as uuidv1 } from 'uuid';

import { isUndefined } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

import type { DriverActivityEntry } from '../../models';

import { ActivitiesBar } from './components/ActivitiesBar';
import { Ticks } from './components/Ticks';
import { ZoomPopup } from './components/ZoomPopup';
import { getCursorInterval } from './getCursorInterval';
import { getCursorRelativeToGraph } from './getCursorRelativeToGraph';
import type { ActivitiesGraphClassKey } from './styles';

export interface ActivitiesGraphProps {
    activities?: DriverActivityEntry[];
    loading: boolean;
    failedToLoad: boolean;
    openReportDialog?: () => void;
}

export interface ActivitiesGraphStateProps {
    currentTime: Date;
}

export interface ActivitiesGraphInnerProps
    extends ActivitiesGraphProps,
        ActivitiesGraphStateProps,
        WithStyles<ActivitiesGraphClassKey> {}

export const ActivitiesGraphComponent: React.FC<ActivitiesGraphInnerProps> = ({
    loading,
    failedToLoad,
    activities,
    currentTime,
    classes,
    openReportDialog,
}) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [cursor, setCursor] = React.useState<number | undefined>();
    const popupId = React.useMemo(uuidv1, []);

    const handleMouseMove = React.useCallback(
        (e: React.MouseEvent) => {
            if (containerRef.current) {
                const containerBoundingClientRect = containerRef.current.getBoundingClientRect();
                setCursor(e.pageX - containerBoundingClientRect.left);
            }
        },
        [containerRef, setCursor]
    );

    const handleMouseOut = React.useCallback(() => {
        setCursor(undefined);
    }, [setCursor]);

    const handleMouseClick = React.useCallback(() => {
        if (openReportDialog) {
            openReportDialog();
        }
    }, [openReportDialog]);

    const getCursorIntervalMemoized = React.useMemo(() => memoizeOne(getCursorInterval), []);
    const cursorRelativeToGraph = !isUndefined(cursor) ? getCursorRelativeToGraph(cursor) : undefined;

    const cursorElement: JSX.Element | null =
        activities?.length && !isUndefined(cursorRelativeToGraph) ? (
            <div className={classes.cursor} style={{ left: cursorRelativeToGraph }}>
                <div className={classes.cursorRange} />
            </div>
        ) : null;

    const zoomPopupElement: JSX.Element | null =
        activities?.length && !isUndefined(cursorRelativeToGraph) && containerRef.current ? (
            <Popper anchorEl={containerRef.current} open placement="top" id={popupId}>
                <ZoomPopup
                    className={classes.zoomPopup}
                    cursor={getCursorIntervalMemoized(currentTime, cursorRelativeToGraph)}
                    activityEntries={activities}
                    currentTime={currentTime}
                />
            </Popper>
        ) : null;

    return (
        <div className={classes.activitiesGraphContainer}>
            <div
                ref={containerRef}
                className={classes.root}
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseOut}
                onClick={handleMouseClick}
                data-id="activities-graph-container"
                aria-owns={zoomPopupElement ? popupId : undefined}
            >
                {cursorElement}
                {zoomPopupElement}
                <ActivitiesBar
                    activities={activities}
                    loading={loading}
                    failedToLoad={failedToLoad}
                    currentTime={currentTime}
                />
                <Ticks currentTime={currentTime} />
            </div>
        </div>
    );
};
