import type { StaticDataStoreState } from '~/common';
import { getValueFromHash } from '~/common';
import type { Driver, TachoLincStatus, Vehicle } from '~/services/ApiClient';

import type { ResolvedTachoLincStatus } from './models';

export const resolveTachoLincStatus = (
    status: TachoLincStatus,
    staticDataState: StaticDataStoreState
): ResolvedTachoLincStatus => {
    const assignedVehicle: Vehicle | undefined = status.vehicleId
        ? getValueFromHash(staticDataState.vehicles.data.hash, status.vehicleId)
        : undefined;
    const assignedDriver: Driver | undefined = status.driverId
        ? getValueFromHash(staticDataState.drivers.data.hash, status.driverId)
        : undefined;

    return {
        ...status,
        assignedVehicle,
        assignedDriver,
    };
};
