import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const TachographIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M20.82 7H3.18A1.12 1.12 0 0 0 2.06 8.11v7.78A1.12 1.12 0 0 0 3.18 17H20.82a1.12 1.12 0 0 0 1.12-1.12V8.11A1.12 1.12 0 0 0 20.82 7Zm-9.31 8.36c0 .24-.1.44-.22.44h-7c-.12 0-.22-.2-.22-.44v-.62c0-.24.1-.44.22-.44h7c.12 0 .22.2.22.44Zm-7.19-2.3v-.57a.22.22 0 0 1 .22-.22H5.1a.22.22 0 0 1 .23.22v.57a.22.22 0 0 1-.23.22H4.54A.22.22 0 0 1 4.32 13.05Zm2 0v-.57a.22.22 0 0 1 .22-.22h.57a.22.22 0 0 1 .22.22v.57a.22.22 0 0 1-.22.22H6.52A.22.22 0 0 1 6.3 13.05Zm2 0v-.57a.22.22 0 0 1 .22-.22h.56a.22.22 0 0 1 .23.22v.57a.22.22 0 0 1-.23.22H8.51A.22.22 0 0 1 8.29 13.05Zm2 0v-.57a.22.22 0 0 1 .23-.22h.56a.22.22 0 0 1 .22.22v.57a.22.22 0 0 1-.22.22H10.5A.22.22 0 0 1 10.27 13.05ZM11.51 11a.52.52 0 0 1-.53.53H4.59A.52.52 0 0 1 4.06 11V9.52A.53.53 0 0 1 4.59 9H11a.53.53 0 0 1 .53.53ZM20 15.35c0 .24-.09.44-.22.44h-7c-.12 0-.22-.2-.22-.44v-.62c0-.24.1-.44.22-.44h7c.13 0 .22.2.22.44Z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </SvgIcon>
    );
};
TachographIcon.displayName = 'TachographIcon';

export { TachographIcon };
