import { isEqual, isNil } from '~/libs/utility';

import type { SourcesProps } from '../models';

import { getValueByPath } from './getValueByPath';

const entryEquals = <T extends object>({ a, b, paths }: SourcesProps<T>) => {
    if (isNil(a) && isNil(b)) {
        return true;
    }

    if (isNil(a) || isNil(b)) {
        return false;
    }

    const aObject = paths.reduce((acc: { [key: string]: unknown }, path) => {
        const value = getValueByPath(a, path);
        if (!isNil(value)) {
            acc[path] = value;
        }

        return acc;
        // acc[]
        // return { ...acc, ...(!isNil(value) && { [path]: value }) };
    }, {});

    const bObject = paths.reduce((acc: { [key: string]: unknown }, path) => {
        const value = getValueByPath(b, path);
        if (!isNil(value)) {
            acc[path] = value;
        }

        return acc;
        // return { ...acc, ...(!isNil(value) && { [path]: value }) };
    }, {});

    return isEqual(aObject, bObject);
};

export { entryEquals };
