import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ConfirmMarkAllMessagesAsReadDialogClassKey = 'root' | 'checkboxWrapper';

export const ConfirmMarkAllMessagesAsReadDialogStyleRules: StyleRulesCallback<
    Theme,
    {},
    ConfirmMarkAllMessagesAsReadDialogClassKey
> = () =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
        },
        checkboxWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
    });
