import { Marker } from '@fv/components';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC } from 'react';
import { useMemo } from 'react';
import { withProps } from 'react-recompose';

import type { MonitoringVehicleEntry } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesVehicleDisplayFormat } from '~/services/ApiClient';

import { VehicleMarkerIcon } from './components/VehicleMarkerIcon';
import { VehicleMarkerIconBadge } from './components/VehicleMarkerIconBadge';
import { VehicleMarkerNameFormatter } from './components/VehicleMarkerNameFormatter';
import type { VehicleMarkerClassKey } from './styles';

export interface VehicleMarkerProps {
    entry: MonitoringVehicleEntry;
    vehicleDisplayNameFormat?: DisplayUserPreferencesVehicleDisplayFormat;
    selected: boolean;
    updateAnimationVisible: boolean;
    animationDuration: number;
}

export interface VehicleMarkerInnerProps extends VehicleMarkerProps, WithStyles<VehicleMarkerClassKey> {}

export const VehicleMarkerComponent: FC<VehicleMarkerInnerProps> = (props) => {
    const { entry, vehicleDisplayNameFormat, classes, ...rest } = props;

    const NameFormatterComponent = useMemo(
        () => vehicleDisplayNameFormat && withProps({ vehicleDisplayNameFormat })(VehicleMarkerNameFormatter),
        [vehicleDisplayNameFormat]
    );

    const overriddenMarkerClasses = useMemo(() => {
        const driverActivity = entry.vehicleDriverHoursStatus?.driverHoursStatus?.activity?.key;

        return {
            iconBox: classNames({
                [classes[`driverActivity${driverActivity}BackgroundColor`]]: !isUndefined(driverActivity),
                [classes.vehicleBackgroundColor]: isUndefined(driverActivity),
            }),
            highlight: classNames({
                [classes[`driverActivity${driverActivity}BorderColor`]]: !isUndefined(driverActivity),
                [classes.vehicleBorderColor]: isUndefined(driverActivity),
            }),
            selected: classNames({
                [classes[`driverActivity${driverActivity}BorderColor`]]: !isUndefined(driverActivity),
                [classes.vehicleBorderColor]: isUndefined(driverActivity),
            }),
            assetName: classes.assetName,
        };
    }, [classes, entry.vehicleDriverHoursStatus]);

    return (
        <Marker<MonitoringVehicleEntry>
            {...rest}
            entry={entry}
            Icon={VehicleMarkerIcon}
            IconBadge={VehicleMarkerIconBadge}
            NameFormatter={NameFormatterComponent}
            classes={overriddenMarkerClasses}
            dataId={`vehicle-marker:${entry.vehicle.id}`}
        />
    );
};
