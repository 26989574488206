import { Divider } from '@mui/material';
import type { FC } from 'react';
import { useMemo } from 'react';

import {
    DriverActivitiesReportChart,
    DriverActivitiesReportPerspective,
} from '~/components/DriverActivitiesReportChart';
import { AssetType } from '~/services/ApiClient';

import type { HistoryDriverEntry } from '../../models';

export interface DriverEventsGraphPanelProps {
    selectedAssetType: AssetType;
    dataSource: HistoryDriverEntry[];
    selectHighlightedRow?: (segmentId?: number) => void;
    selectRow?: (segmentId: number) => void;
}

const DriverEventsGraphPanel: FC<DriverEventsGraphPanelProps> = (props) => {
    const { dataSource, selectedAssetType, selectHighlightedRow, selectRow } = props;

    const driverEvents = useMemo(() => dataSource.map((x) => x.driverEvent), [dataSource]);

    const perspective = (() => {
        switch (selectedAssetType) {
            case AssetType.Driver:
                return DriverActivitiesReportPerspective.Driver;
            case AssetType.Vehicle:
                return DriverActivitiesReportPerspective.Vehicle;
            default:
                return undefined;
        }
    })();

    if (!perspective) {
        return null;
    }

    return (
        <>
            <DriverActivitiesReportChart
                data-id="driver-activity-report"
                perspective={perspective}
                resolvedDriverEvents={driverEvents}
                selectHighlightedRow={selectHighlightedRow}
                selectRow={selectRow}
            />

            <Divider />
        </>
    );
};

DriverEventsGraphPanel.displayName = 'DriverEventsGraphPanel';
export { DriverEventsGraphPanel };
