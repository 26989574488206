import { Paper, Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { DateTimeFormatter, TemperatureFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { DisplayUserPreferencesUnitSystem, ResolvedContainerTemperatureStatus } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

import type { ContainerTemperatureSensorClassKey } from './styles';

export interface ContainerTemperatureSensorProps {
    sensorOrder: number;
    temperatureSensor: ResolvedContainerTemperatureStatus;
    unitSystem: DisplayUserPreferencesUnitSystem;
}

export interface ContainerTemperatureSensorInnerProps
    extends ContainerTemperatureSensorProps,
        WithStyles<ContainerTemperatureSensorClassKey>,
        InjectedTranslationProps {}

export const ContainerTemperatureSensorComponent: React.FC<ContainerTemperatureSensorInnerProps> = ({
    temperatureSensor,
    sensorOrder,
    classes,
    unitSystem,
    t,
}) => {
    const sensorTooltipContent = (
        <div>
            <DateTimeFormatter value={temperatureSensor.dateTime} />
        </div>
    );

    return (
        <Tooltip title={sensorTooltipContent}>
            <Paper elevation={3} className={classes.root} data-id={`temperature-sensor:${temperatureSensor.id}`}>
                <Typography variant="subtitle1" className={classes.primaryText}>
                    <span className={classes.value} data-id="value">
                        <TemperatureFormatter unitSystem={unitSystem} t={t} value={temperatureSensor.value} />
                    </span>
                </Typography>

                <Typography variant="caption" className={classes.secondaryText} color="textSecondary">
                    <span className={classes.value} data-id="name">
                        {`${formatClassification(
                            t,
                            ClassificationType.ContainerTemperatureType,
                            temperatureSensor.type
                        )} ${sensorOrder}`}
                    </span>
                </Typography>
            </Paper>
        </Tooltip>
    );
};
