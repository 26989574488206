import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type RecipientsListClassKey =
    | 'secondaryIdentifier'
    | 'avatar'
    | 'groupName'
    | 'flex'
    | 'chipsIcon'
    | 'chipCloseIcon'
    | 'flexColumn'
    | 'firstLine'
    | 'secondLine'
    | 'listItemDivider'
    | 'option'
    | 'optionPadding'
    | 'input'
    | 'chipsValue'
    | 'delimiter'
    | 'secondaryTextPlaceholder'
    | 'rootDiv';

export const maxInputRows = 2;

export const RecipientsListStyleRules: StyleRulesCallback<Theme, {}, RecipientsListClassKey> = (theme: Theme) =>
    createStyles({
        secondaryIdentifier: {
            opacity: 0.54,
        },
        input: {
            '&::placeholder': {
                color: theme.palette.text.secondary,
                fontStyle: 'italic',
                fontFamily: theme.typography.fontFamily,
                opacity: 1,
            },
        },
        avatar: {
            color: theme.functionalItemsColors.assetType.vehicle.value,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.text.secondary}`,
            marginRight: theme.spacing(),
        },
        groupName: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(2),
            position: 'relative',
        },
        flex: {
            display: 'flex',
        },
        chipsIcon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(0.5),
            display: 'flex',
            alignItems: 'center',
            '& > *': {
                width: 12,
                height: 12,
            },
        },
        chipCloseIcon: {
            width: 12,
            height: 12,
        },
        flexColumn: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        firstLine: {
            fontSize: 12,
        },
        secondLine: {
            fontSize: 12,
        },
        option: {
            padding: theme.spacing(0, 0, 0, 2),
            '&[aria-disabled="true"]': {
                pointerEvents: 'initial',
                cursor: 'default',
            },
        },
        listItemDivider: {
            bottom: 0,
            left: 64,
            right: 0,
            position: 'absolute',
        },
        optionPadding: {
            padding: theme.spacing(1, 0),
        },
        chipsValue: {
            width: 60,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        delimiter: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
        secondaryTextPlaceholder: {
            height: 20,
        },
        rootDiv: {
            margin: theme.spacing(1, 2),
        },
    });
