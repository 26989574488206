import moment from 'moment';

import type { NumericDictionary } from '~/libs/utility';
import type { AssetType, TachographDataBlock } from '~/services/ApiClient';
import { AssetReference, RtdsSchedule, RtdsScheduleReference, createApiModel } from '~/services/ApiClient';

import type { FieldValues } from './component';
import { getRecurrenceValue } from './getRecurrenceValue';
import { getSelectedTachoDataIds } from './getSelectedTachoDataIds';
import { MonthlyRadioButtonsTypes } from './models';

export const mapRecipientIdsToSubmittedSchedules = (
    data: FieldValues,
    recipientIds: number[],
    assetType: AssetType,
    tachographDataBlocksSettings: NumericDictionary<TachographDataBlock>
): RtdsSchedule[] => {
    const submittedSchedules = recipientIds.map((recipientId) => {
        return createApiModel(RtdsSchedule, {
            id: createApiModel(RtdsScheduleReference, {
                id: 0,
            }),
            revision: 0,
            description: undefined,
            frequency: data.dropdownFrequency,
            priority: data.dropdownPriority,
            recurrence: getRecurrenceValue(data),
            startDate: moment(data.startDate.toISOString()).utc(true).toDate(),
            tachographDataBlocks: getSelectedTachoDataIds(
                assetType,
                tachographDataBlocksSettings,
                data.tachoDataBlocks
            ),
            requestedDays: data.lastActivityDays,
            type: assetType,
            assetReference: createApiModel(AssetReference, {
                id: recipientId,
                type: assetType,
            }),
            day:
                data.radioButtonsFrequencyMonth === MonthlyRadioButtonsTypes.Month
                    ? data.dropdownMonthOrdinalType
                    : data.frequencyMonthNumDays || 0,
            timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
            weekDay:
                data.radioButtonsFrequencyMonth === MonthlyRadioButtonsTypes.Day ? undefined : data.dropdownWeekDays,
        });
    });

    return submittedSchedules;
};
