import { createAction } from 'redux-actions';

import type { MapViewport } from '~/common';
import type { RequiredAndNonNullable } from '~/types';

import { ActionTypeKeys } from './actionTypes';

export const changeViewportAction = createAction(
    ActionTypeKeys.HISTORYMAP_CHANGEVIEWPORT,
    (viewport: RequiredAndNonNullable<MapViewport>) => viewport
);

export const panToPositionAction = createAction(ActionTypeKeys.HISTORYMAP_PANTOPOSITION);

export const panToPositionGroupAction = createAction(ActionTypeKeys.HISTORYMAP_PANTOPOSITIONGROUP);

export const flyToPositionGroupAction = createAction(ActionTypeKeys.HISTORYMAP_FLYTOPOSITIONGROUP);
