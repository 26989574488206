import { TextField } from '@mui/material';
import type { InputBaseComponentProps } from '@mui/material/InputBase';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import type { ControlProps as ReactSelectControlProps } from 'react-select';

import type { Option } from '../../models';

import type { ControlClassKey } from './styles';

export interface ControlProps extends ReactSelectControlProps<Option, false> {}

export interface ControlInnerProps extends ControlProps, WithStyles<ControlClassKey> {}

const inputComponent = ({ inputRef, ...props }: InputBaseComponentProps) => (
    <div ref={inputRef as React.Ref<HTMLDivElement>} {...(props as React.HTMLAttributes<HTMLDivElement>)} />
);

export const ControlComponent: React.FC<ControlInnerProps> = React.forwardRef(
    ({ classes, children, innerProps, innerRef }, ref: React.Ref<HTMLInputElement>) => {
        const inputProps = {
            inputComponent,
            inputProps: {
                className: classes.input,
                ref: innerRef,
                children,
                ...innerProps,
            },
        };

        return <TextField ref={ref} fullWidth margin="dense" variant="outlined" InputProps={inputProps} />;
    }
);
