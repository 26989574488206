import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { VehicleMarkerInnerProps, VehicleMarkerProps } from './component';
import { VehicleMarkerComponent } from './component';
import { VehicleMarkerStyleRules } from './styles';

export type { VehicleMarkerProps } from './component';

export const VehicleMarker = compose<VehicleMarkerInnerProps, VehicleMarkerProps>(
    setDisplayName('VehicleMarker'),
    withStyles(VehicleMarkerStyleRules)
)(VehicleMarkerComponent);
