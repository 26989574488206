import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { resetPasswordSceneStateSelector } from '~/selectors';

import { ResetPasswordFormComponent } from './component';
import type { ResetPasswordFormInnerProps, ResetPasswordFormProps } from './models';
import { mapDispatchToProps, mapStateToProps } from './redux';

export const ResetPasswordForm = compose<ResetPasswordFormInnerProps, ResetPasswordFormProps>(
    setDisplayName('ResetPasswordForm'),
    connect(createSelector(resetPasswordSceneStateSelector, mapStateToProps), mapDispatchToProps)
)(ResetPasswordFormComponent);
