import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { CommonSectionProps } from '../../models';

import type { GeneralSectionInnerProps } from './component';
import { GeneralSectionComponent } from './component';

export const GeneralSection = compose<GeneralSectionInnerProps, CommonSectionProps>(
    setDisplayName('GeneralSection'),
    withTranslation()
)(GeneralSectionComponent);
