import * as React from 'react';
import { wrapDisplayName } from 'react-recompose';

import { SceneLoader } from '~/components/SceneLoader';
import type { NonEmptyArray } from '~/types';

import type { UserPreferencesState } from '../../reducers';

export interface RequiredUserPreferences {
    key: string;
    defaultState: object;
    processResponse?: (preferences: unknown) => unknown;
}

export interface StateProps {
    userPreferencesState: UserPreferencesState;
}

export interface DispatchProps {
    initializeUserPreferencesState: (
        key: string,
        value: object,
        processResponse?: (preferences: unknown) => unknown
    ) => void;
}

export interface UserPreferencesInnerProps extends StateProps, DispatchProps {}

export const ensureUserPreferencesHoc =
    (requiredUserPreferences: NonEmptyArray<RequiredUserPreferences>, showLoaderWhileRetrievingForFirstTime: boolean) =>
    <WrappedProps extends object>(
        WrappedComponent: React.ComponentType<WrappedProps>
    ): React.ComponentType<WrappedProps & UserPreferencesInnerProps> => {
        return class extends React.Component<WrappedProps & UserPreferencesInnerProps> {
            public static displayName = wrapDisplayName(WrappedComponent, 'EnsureUserPreferences');

            public componentDidMount() {
                this.initializeUserPreferencesState();
            }

            public render() {
                const { userPreferencesState, initializeUserPreferencesState, ...restProps } = this.props;

                if (this.retrievingUserPreferencesForFirstTime()) {
                    return showLoaderWhileRetrievingForFirstTime ? <SceneLoader /> : null;
                }

                return <WrappedComponent {...(restProps as WrappedProps)} />;
            }

            private initializeUserPreferencesState(): void {
                const { userPreferencesState, initializeUserPreferencesState } = this.props;

                requiredUserPreferences.forEach(({ key, defaultState, processResponse }) => {
                    if (!userPreferencesState[key]) {
                        initializeUserPreferencesState(key, defaultState, processResponse);
                    }
                });
            }

            private retrievingUserPreferencesForFirstTime(): boolean {
                return requiredUserPreferences.some(this.requiredUserPreferencesAreBeingRetrieved);
            }

            private requiredUserPreferencesAreBeingRetrieved = ({ key }: RequiredUserPreferences): boolean => {
                const userPreferencesKeyState = this.props.userPreferencesState[key];
                return (
                    !userPreferencesKeyState || !(userPreferencesKeyState.fulfilled || userPreferencesKeyState.rejected)
                );
            };
        };
    };
