import type { HistoryStoreState, TimelineSectionStoreState } from '~/data/monitoring';
import type { StoreState } from '~/reducers';

import { historySubpageStateSelector } from '../Vehicles/selectors';

export const timelineSectionStateSelector = (s: StoreState): TimelineSectionStoreState =>
    historySubpageStateSelector(s).timelineSection;

export const historyStateSelector = (s: StoreState): HistoryStoreState =>
    historySubpageStateSelector(s).historyStoreState;
