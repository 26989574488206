import type { JwtDecodedToken } from '~/data/authentication';
import { isNil } from '~/libs/utility';

const isCCuser = (value?: JwtDecodedToken) => {
    if (isNil(value)) {
        return false;
    }

    return true;
};

export { isCCuser };
