import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { getPressureUnitSystemUtils } from '~/services/Formatters';

export interface PressureFormatterProps {
    t: SingleTFunction;
    value?: number;
}

export interface PressureFormatterInnerProps extends PressureFormatterProps {}

const PressureFormatter: React.FunctionComponent<PressureFormatterInnerProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{getPressureUnitSystemUtils(t).formatter(value)}</>;
};
PressureFormatter.displayName = 'PressureFormatter';

export { PressureFormatter };
