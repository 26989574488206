import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type {
    ConfirmMarkAllMessagesAsReadDialogInnerProps,
    ConfirmMarkAllMessagesAsReadDialogProps,
} from './component';
import { ConfirmMarkAllMessagesAsReadDialogComponent } from './component';
import { ConfirmMarkAllMessagesAsReadDialogStyleRules } from './styles';

export const ConfirmMarkAllMessagesAsReadDialog = compose<
    ConfirmMarkAllMessagesAsReadDialogInnerProps,
    ConfirmMarkAllMessagesAsReadDialogProps
>(
    setDisplayName('ConfirmMarkAllMessagesAsReadDialog'),
    withStyles(ConfirmMarkAllMessagesAsReadDialogStyleRules),
    withTranslation()
)(ConfirmMarkAllMessagesAsReadDialogComponent);
