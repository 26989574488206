import type { ActionTypes as AuthenticationActionTypes } from '~/data/authentication';
import type { IntroCarousel } from '~/services/IntroCarousel';

import type { ActionTypes } from './data';
import { ActionTypeKeys } from './data';

export interface IntroCarouselStoreState {
    manuallyOpened: boolean;
    latestIntroCarousel?: IntroCarousel;
    openUnseenCarousel: boolean;
    seenLatestCarousel: boolean;
}

const initialStoreState: IntroCarouselStoreState = {
    manuallyOpened: false,
    openUnseenCarousel: false,
    seenLatestCarousel: false,
};

export function introCarouselReducer(
    state: IntroCarouselStoreState = initialStoreState,
    action: ActionTypes | AuthenticationActionTypes
): IntroCarouselStoreState {
    switch (action.type) {
        case ActionTypeKeys.INTROCAROUSEL_OPENUNSEEN:
            return { ...state, openUnseenCarousel: true };
        case ActionTypeKeys.INTROCAROUSEL_OPEN:
            return { ...state, manuallyOpened: true };
        case ActionTypeKeys.INTROCAROUSEL_CLOSE:
            return { ...state, manuallyOpened: false, seenLatestCarousel: true };
        case ActionTypeKeys.LATESTINTROCAROUSEL_FULFILLED:
            return { ...state, latestIntroCarousel: action.payload.item, seenLatestCarousel: false };
        default:
            return state;
    }
}
