import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailsPaneHeader } from '~/components/DetailsPaneHeader';
import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { ResolvedAdminCompanyCard } from '~/data/companycardAdministration';

import { SectionName } from './constants';
import type { CompanyCardAdministrationDetailsPaneProps } from './models';
import { COMPANY_CARD_ADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY } from './preferences';
import { getSectionsMemoized } from './sections';

const DetailsPaneComponent = SceneDetailsPaneFactory<
    ResolvedAdminCompanyCard,
    SceneDetailsPaneUserPreferences<SectionName>,
    SectionName
>(COMPANY_CARD_ADMINISTRATION_DETAILSPANE_USERPREFERENCES_KEY);

const CompanyCardAdministrationDetailsPane: FC<CompanyCardAdministrationDetailsPaneProps> = (props) => {
    const { entry, onClose, openCompanyCardEditDialog } = props;

    const { t } = useTranslation();
    const header = useMemo(
        () => <DetailsPaneHeader onClose={onClose} title={t('company-card-admin-module')} />,
        [onClose, t]
    );

    const getSections = useCallback(
        (
            userPreferences: SceneDetailsPaneUserPreferences<SectionName>,
            toggleCollapsedState: (sectionName: SectionName) => () => void
        ) =>
            getSectionsMemoized({
                entry,
                collapsedSections: userPreferences.collapsedSections,
                toggleCollapsedState,
                openCompanyCardEditDialog,
            }),
        [entry, openCompanyCardEditDialog]
    );

    return (
        <DetailsPaneComponent
            entry={entry}
            getSections={getSections}
            dataId="admin-company-card-details-pane"
            HeaderReactNode={header}
            alwaysFirstSection={SectionName.HEADER}
        />
    );
};

CompanyCardAdministrationDetailsPane.displayName = 'CompanyCardAdministrationDetailsPaneComponent';
export default CompanyCardAdministrationDetailsPane;
