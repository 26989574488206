import type { CommunicationGridUserPreferences, CommunicationUserPreferences } from './models';

const COMMUNICATION_GRID_USERPREFERENCES_KEY = 'communication-grid';
const COMMUNICATION_USERPREFERENCES_KEY = 'communication-state';

const defaultCommunicationGridUserPreferences: CommunicationGridUserPreferences = { defaultQueryPeriod: 'P1D' };

const requiredCommunicationGridUserPreferences = {
    key: COMMUNICATION_GRID_USERPREFERENCES_KEY,
    defaultState: defaultCommunicationGridUserPreferences,
};

const defaultCommunicationUserPreferences: CommunicationUserPreferences = {
    messageProfileId: undefined,
    leftPaneIsOpen: true,
};

const requiredCommunicationUserPreferences = {
    key: COMMUNICATION_USERPREFERENCES_KEY,
    defaultState: defaultCommunicationUserPreferences,
};

export {
    COMMUNICATION_GRID_USERPREFERENCES_KEY,
    COMMUNICATION_USERPREFERENCES_KEY,
    requiredCommunicationGridUserPreferences,
    requiredCommunicationUserPreferences,
};
