import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DragHandleInnerProps, DragHandleProps } from './component';
import { DragHandleComponent } from './component';

export const DragHandle = compose<DragHandleInnerProps, DragHandleProps>(
    setDisplayName('DragHandle'),
    withTranslation()
)(DragHandleComponent);
