import type { ColorContrast, ColorContrastOptions, FixedArray, Theme, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import logoUrl from '~/assets/astrata-logo-black.png';
import { merge } from '~/libs/utility';

import {
    androidGreen,
    baraRed,
    blueMartina,
    energos,
    lavenderRose,
    mediterraneanSea,
    radianYellow,
    sunflower,
} from './colors';

export const createAppTheme = (
    customThemeOptions?: Pick<ThemeOptions, 'menuBar' | 'palette' | 'functionalItemsColors' | 'partner'>
) => {
    const baseThemeOptions: ThemeOptions = {
        menuBar: {
            background: '#212121',
            iconColor: '#DBDBDB',
            activeIconColor: '#FFFFFF',
        },
        partner: {
            logoUrl,
            name: 'Astrata',
            url: 'https://www.astrata.eu/',
            analyticsSolutionName: 'Astrata Dash',
        },
        functionalItemsColors: {
            assetType: {
                vehicle: { value: '#001689', contrast: '#FFFFFF' },
                trailer: { value: '#001689', contrast: '#FFFFFF' },
                driver: { value: '#001689', contrast: '#FFFFFF' },
                user: { value: '#9C27B0', contrast: '#FFFFFF' },
                container: { value: '#001689', contrast: '#FFFFFF' },
                group: { value: '#001689', contrast: '#FFFFFF' },
            },
            graph: [
                { value: sunflower[500] },
                { value: energos[500] },
                { value: blueMartina[500] },
                { value: lavenderRose[500] },
                { value: baraRed[500] },
                { value: radianYellow[500] },
                { value: androidGreen[500] },
                { value: mediterraneanSea[500] },
            ],
            ignition: {
                on: { value: '#4CAF50', contrast: '#FFFFFF' },
                off: { value: '#9E9E9E', contrast: '#FFFFFF' },
            },
            driverActivity: {
                drive: { value: '#B15DFF', contrast: '#FFFFFF' },
                work: { value: '#72DEFF', contrast: '#FFFFFF' },
                available: { value: '#FFCF44', contrast: '#FFFFFF' },
                rest: { value: '#1EB980', contrast: '#FFFFFF' },
                subtotal: { value: '#f39500', contrast: '#FFFFFF' },
            },
            temperature: {
                setpoint: { value: '#FFC107', contrast: '#FFFFFF' },
                supply: { value: '#3F51B5', contrast: '#FFFFFF' },
                return: { value: '#FF5722', contrast: '#FFFFFF' },
                sensor1: { value: '#8BC34A', contrast: '#FFFFFF' },
                sensor2: { value: '#FF9800', contrast: '#FFFFFF' },
                sensor3: { value: '#00BCD4', contrast: '#FFFFFF' },
                sensor4: { value: '#795548', contrast: '#FFFFFF' },
                high: { value: '#EA2027', contrast: '#FFFFFF' },
                low: { value: '#0652DD', contrast: '#FFFFFF' },
            },
            cluster: { value: '#000000', contrast: '#FFFFFF' },
        },
        palette: {
            background: { default: '#fafafa' },
            error: { light: '#FF4F30', main: '#CC0000', dark: '#930000', contrastText: '#FFFFFF' },
            primary: { light: '#96EF76', main: '#63BC47', dark: '#2D8B14', contrastText: '#FFFFFF' },
            secondary: { light: '#C759B5', main: '#942685', dark: '#630058', contrastText: '#FFFFFF' },
            info: { light: '#4D72C5', main: '#004794', dark: '#002165', contrastText: '#FFFFFF' },
            success: { light: '#9CE156', main: '#69AF22', dark: '#357F00' },
            warning: { light: '#FFFF72', main: '#FFEB3B', dark: '#C8B900' },
            warning2: { light: '#FFC947', main: '#FF9800', dark: '#C66900' },
            action: { selectedBackground: 'rgba(0, 0, 0, 0.38)' },
            grid: { selected: '#F6EDF5' },
        },
        components: {
            MuiTableRow: { styleOverrides: { root: { height: '32px' } } },
            MuiCssBaseline: {
                styleOverrides: {
                    body: { fontSize: '14px', lineHeight: 1.43, letterSpacing: '0.01071em' },
                    scrollbarWidth: 'thin',
                    scrollbarColor: ['#cdcdcd', '#f0f0f0'],
                    msScrollbarBaseColor: ['#cdcdcd', '#f0f0f0'],
                    '&::-webkit-scrollbar': { width: 7.5, height: 7.5 },
                    '&::-webkit-scrollbar-track': { backgroundColor: '#f0f0f0' },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#cdcdcd',
                        borderRight: '1px solid white',
                        borderBottom: '1px solid white',
                        '&:hover': { backgroundColor: '#a6a6a6' },
                        '&:active': { backgroundColor: '#606060' },
                    },
                },
            },
            MuiSwitch: { defaultProps: { color: 'secondary' } },
            MuiRadio: { defaultProps: { color: 'secondary' } },
            MuiCheckbox: { defaultProps: { color: 'secondary' } },
            MuiChip: { styleOverrides: { root: { height: '24px' } } },
            MuiIconButton: { styleOverrides: { root: { '&.Mui-disabled': { color: 'inherit', opacity: 0.26 } } } },
            MuiTypography: { styleOverrides: { root: { overflowWrap: 'break-word' } } },
            MuiTooltip: { styleOverrides: { tooltip: { overflowWrap: 'break-word' } } },
            MuiInputBase: {
                defaultProps: {
                    color: 'primary',
                    size: 'small',
                    inputProps: {
                        // Force required: false to not show the html5 required helpers from the browser
                        required: false,
                    },
                },
                styleOverrides: {
                    root: {
                        minHeight: '40px',
                    },
                    inputSizeSmall: { fontSize: 14 },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        // Prevent wrapping of buttons over multiple lines
                        whiteSpace: 'nowrap',
                        minWidth: 'auto',
                    },
                },
            },
            MuiInputLabel: { styleOverrides: { sizeSmall: { fontSize: 14 } } },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'initial',
                        '&.Mui-selected': {
                            backgroundColor: '#00000014',
                            '&.Mui-focusVisible': { background: '#00000014' },
                        },
                        '&.Mui-selected:hover': { backgroundColor: '#00000014' },
                    },
                },
            },
            MuiSelect: { defaultProps: { size: 'small' } },
            MuiTableCell: { styleOverrides: { root: { position: 'relative' } } },
        },
    };

    const mergedThemeOptions = merge(baseThemeOptions, customThemeOptions);
    const theme = createTheme(mergedThemeOptions);
    const augmentColorContrast = (colorContrastOptions: ColorContrastOptions): ColorContrast => ({
        value: colorContrastOptions.value,
        contrast: colorContrastOptions.contrast ?? theme.palette.getContrastText(colorContrastOptions.value),
    });

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return <Theme>{
        ...theme,
        palette: {
            ...theme.palette,
            info: theme.palette.augmentColor({ color: mergedThemeOptions.palette!.info! }),
            success: theme.palette.augmentColor({ color: mergedThemeOptions.palette!.success! }),
            warning: theme.palette.augmentColor({ color: mergedThemeOptions.palette!.warning! }),
            warning2: theme.palette.augmentColor({ color: mergedThemeOptions.palette!.warning2! }),
        },
        functionalItemsColors: {
            assetType: {
                driver: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.driver!),
                trailer: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.trailer!),
                vehicle: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.vehicle!),
                user: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.user!),
                container: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.container!),
                group: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.assetType!.group!),
            },
            graph: mergedThemeOptions.functionalItemsColors!.graph!.map(augmentColorContrast) as FixedArray<
                ColorContrast,
                8
            >,
            cluster: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.cluster!),
            driverActivity: {
                available: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.driverActivity!.available!),
                drive: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.driverActivity!.drive!),
                rest: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.driverActivity!.rest!),
                work: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.driverActivity!.work!),
                subtotal: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.driverActivity!.subtotal!),
            },
            ignition: {
                off: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.ignition!.off!),
                on: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.ignition!.on!),
            },
            temperature: {
                sensor1: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.sensor1!),
                sensor2: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.sensor2!),
                sensor3: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.sensor3!),
                sensor4: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.sensor4!),
                setpoint: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.setpoint!),
                supply: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.supply!),
                return: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.return!),
                high: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.high!),
                low: augmentColorContrast(mergedThemeOptions.functionalItemsColors!.temperature!.low!),
            },
        },
        custom: {
            virtualList: { height: 300 },
            dialogContent: { minHeight: 500, minWidth: 500 },
            chart: { minHeight: 400 },
            loader: { minHeight: 400 },
            inputContainer: { gap: 10, display: 'grid', gridTemplateColumns: '400px 42px' },
            menu: { width: 65 },
            header: { height: 56 },
            subtitleFontSize: '15px',
            listMaxHeight: '40vh',
            font: {
                fontSize: '14px',
                fontFamily: 'Roboto, Arial, sans-serif',
                fontWeight: 500,
            },
        },
    };
};
