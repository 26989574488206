import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';

import type { SpeedLimitsSectionInnerProps, SpeedLimitsSectionProps } from './component';
import { SpeedLimitsSectionComponent } from './component';

export const SpeedLimitsSection = compose<SpeedLimitsSectionInnerProps, SpeedLimitsSectionProps>(
    setDisplayName('SpeedLimitsSection'),
    withDisplayPreferences()
)(SpeedLimitsSectionComponent);
