import { createAction } from 'redux-actions';

import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { DevicesPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

export const retrieveDevicesExecutor = (): Promise<DevicesPayload> => {
    return retryableRequest(() => ApiClient.getDevices());
};

export const retrieveDevicesAction = createAction(ActionTypePrefixes.DEVICES, retrieveDevicesExecutor);
