import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { TableFixedColumnsCellInnerProps, TableFixedColumnsCellProps } from './component';
import { TableFixedColumnsCellComponent } from './component';
import { TableFixedColumnsCellStyleRules } from './styles';

export const TableFixedColumnsCell = compose<TableFixedColumnsCellInnerProps, TableFixedColumnsCellProps>(
    setDisplayName('TableFixedColumnsCell'),
    withStyles(TableFixedColumnsCellStyleRules)
)(TableFixedColumnsCellComponent);
