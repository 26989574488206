import { Virtuoso } from 'react-virtuoso';

import { useTree } from '../../context';

import { ItemContent } from './components';

const List = <T,>() => {
    const [{ items, checkedKeys, expandedKeys, readOnly = false, dataTestId }, { onCheck, onCollapse, onContextMenu }] =
        useTree<T>();

    return (
        <Virtuoso
            data-testid="tree-list"
            style={{ height: '90vh' }}
            data={items}
            // eslint-disable-next-line react/no-unstable-nested-components
            itemContent={(_: number, props) => (
                <ItemContent
                    {...props}
                    onCheck={onCheck}
                    onCollapse={onCollapse}
                    onContextMenu={onContextMenu}
                    checkedKeys={checkedKeys}
                    expandedKeys={expandedKeys}
                    readOnly={readOnly}
                    dataTestId={dataTestId}
                />
            )}
        />
    );
};

List.displayName = 'List';
export { List };
