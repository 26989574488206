import type { Dispatch } from 'redux';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { MessageDirection, WorkflowFormDefinition } from '~/services/ApiClient';

import { retrieveLatestWorkflowFormDefinitionsAction } from '../../data';
import type { ConversationsRootStoreState } from '../../reducers';

import type { SendWorkflowMessageFormOuterProps } from './component';

export interface SendWorkflowMessageFormStateProps {
    outgoingWorkflowFormDefinitions: WorkflowFormDefinition[];
    shouldFetchWorkflowFormDefinitions: boolean;
}

export const mapStateToProps = (
    conversationsRootState: ConversationsRootStoreState
): SendWorkflowMessageFormStateProps => {
    return {
        outgoingWorkflowFormDefinitions: Object.values(conversationsRootState.workflowFormDefinitions.data).filter(
            (it) => it instanceof WorkflowFormDefinition && it.direction === MessageDirection.Outgoing
        ),
        shouldFetchWorkflowFormDefinitions:
            !conversationsRootState.workflowFormDefinitions.fulfilled &&
            !conversationsRootState.workflowFormDefinitions.pending &&
            !conversationsRootState.workflowFormDefinitions.rejected,
    };
};

export interface SendWorkflowMessageFormPropsReduxOwnProps
    extends SendWorkflowMessageFormOuterProps,
        InjectedTranslationProps {}

export interface SendWorkflowMessageFormDispatchProps {
    retrieveLatestWorkflowFormDefinitions: () => void;
}

export const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: SendWorkflowMessageFormPropsReduxOwnProps
): SendWorkflowMessageFormDispatchProps => {
    return {
        retrieveLatestWorkflowFormDefinitions: () => {
            dispatch(retrieveLatestWorkflowFormDefinitionsAction(ownProps.i18n.language));
        },
    };
};
