import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withTranslation } from '~/components/LanguageSelector';
import { timeSeriesDialogStateSelector } from '~/selectors';

import type { TimeSeriesDialogInnerProps, TimeSeriesDialogProps } from './component';
import { TimeSeriesDialogComponent } from './component';
import { mapDispatchProps, mapStateToProps } from './redux';
import { TimeSeriesDialogStyleRules } from './styles';

export * from './models';
export { timeSeriesDialogReducer } from './reducers';
export type { TimeSeriesDialogStoreState } from './reducers';

export const TimeSeriesDialogFactory = <T>(): React.ComponentType<TimeSeriesDialogProps<T>> =>
    compose<TimeSeriesDialogInnerProps<T>, TimeSeriesDialogProps<T>>(
        setDisplayName('TimeSeriesDialog'),
        connect(createSelector(timeSeriesDialogStateSelector, mapStateToProps), mapDispatchProps),
        withTranslation(),
        withStyles(TimeSeriesDialogStyleRules)
    )(TimeSeriesDialogComponent);
