import type { ApiException } from './generated';
import { Client } from './generated';

export function resolveWhenStatusCodeIn<T>(...codes: number[]): (err: ApiException) => Promise<T> {
    return (err) => {
        if (codes && codes.includes(err.status)) {
            return Promise.resolve<T>(err.result);
        }

        return Promise.reject<T>(err);
    };
}

export const ApiClient = new Client('/api');
export const AuthenticationApiClient = new Client('/api');

export * from './createApiModel';
export * from './generated';
export * from './models';
export * from './optimisticOutgoingConversationMessage';
export * from './retryableRequest';
export * from './submitServerRequest';
