import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';
import { settingsStoreStateSelector } from '~/selectors';

import { sendMessageDialogStoreStateSelector } from '../../selectors';

import type { SendMessageDialogInnerProps, SendMessageDialogProps } from './component';
import { SendMessageDialogComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { SendMessageDialogStyleRules } from './styles';

export type { SendMessageDialogStoreState } from './reducers';

export { sendMessageDialogReducer } from './reducers';

export const SendMessageDialog = compose<SendMessageDialogInnerProps, SendMessageDialogProps>(
    setDisplayName('SendMessageDialog'),
    withTranslation(),
    connect(
        createSelector(sendMessageDialogStoreStateSelector, settingsStoreStateSelector, mapStateToProps),
        mapDispatchToProps
    ),
    withDisplayPreferences(),
    withStyles(SendMessageDialogStyleRules)
)(SendMessageDialogComponent);
