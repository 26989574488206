import type { MopinionWindow, Pastease } from './models';

export const getWindow = (): MopinionWindow => {
    return window as unknown as MopinionWindow;
};

export const install = (): Promise<Pastease> => {
    const scriptId = 'fv-mopinion';

    return new Promise<Pastease>((resolve, reject) => {
        const { head } = document;
        const script = document.createElement('script');
        script.id = scriptId;
        script.type = 'text/javascript';
        script.src = `//deploy.mopinion.com/js/pastease.js`;

        head.insertBefore(script, head.firstChild);

        const i = window.setInterval(() => {
            try {
                const mopinionWindow = getWindow();
                if (mopinionWindow.Pastease) {
                    clearInterval(i);
                    resolve(mopinionWindow.Pastease);
                }
            } catch (e) {
                clearInterval(i);
                reject(e);
            }
        }, 50);
    });
};
