import moment from 'moment';
import { compose, defaultProps } from 'react-recompose';

import { getValueByPath } from '~/common';
import {
    AltitudeFormatter,
    BooleanFormatter,
    DriverActivityFormatter,
    DriverHoursLoginStatusFormatter,
    LatitudeFormatter,
    LongitudeFormatter,
    NearestCityFormatter,
    PercentageFormatter,
} from '~/components/Formatters';
import type { GridColumnDefinition } from '~/components/Grid';
import {
    createDateTimeColumn,
    createDurationColumn,
    createDurationWithViolationColumn,
    createNumberColumn,
    createNumberWithViolationColumn,
    createStringColumn,
} from '~/components/Grid';
import type { SingleTFunction } from '~/components/LanguageSelector';
import { withTranslation } from '~/components/LanguageSelector';
import type { MonitoringDriverEntry } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesUnitSystem,
    DisplayUserPreferencesVehicleDisplayFormat,
    DriverActivityType,
    LoginStatus,
    NearestCity,
} from '~/services/ApiClient';
import { exportConvertorFactory } from '~/services/Convertors';
import {
    exportFormatterFactory,
    formatBoolean,
    formatDriverActivity,
    formatDriverHoursLoginStatus,
    formatDriverName,
    formatLatitude,
    formatLongitude,
    formatNearestCity,
    formatPercentage,
    formatVehicleName,
    getAltitudeUnitSystemUtils,
    valueTextFormatterFactory,
} from '~/services/Formatters';
import { groupingCriteriaFactory } from '~/services/GroupingCriteria';
import { memoizeOne } from '~/services/Memoize';
import { parseOptionalDuration, parseOptionalDurationViolationField } from '~/services/Parsers';
import { compareFactory, compareNumbers, compareStrings, stringComparer } from '~/services/Sorting';

import { Abbreviation, ColumnGroup, ColumnName } from './consts';

export const getColumns = (
    t: SingleTFunction,
    unitSystem: DisplayUserPreferencesUnitSystem,
    driverDisplayNameFormat: DisplayUserPreferencesDriverDisplayFormat,
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat
): GridColumnDefinition<MonitoringDriverEntry>[] => {
    const withT = defaultProps({ t });
    const withUnitSystem = defaultProps({ unitSystem });
    const altitudeUtils = getAltitudeUnitSystemUtils(t, unitSystem);

    const formatCodriver = (entry: MonitoringDriverEntry): string => {
        return !isUndefined(entry.driver?.coDriver)
            ? formatDriverName(getValueByPath(entry, 'driver.coDriver'), driverDisplayNameFormat)
            : '';
    };

    const formatVehicle = (entry: MonitoringDriverEntry): string => {
        return !isUndefined(entry.driver?.vehicle)
            ? formatVehicleName(getValueByPath(entry, 'driver.vehicle'), vehicleDisplayNameFormat)
            : '';
    };

    const formatDriver = (entry: MonitoringDriverEntry): string => {
        return !isUndefined(entry.driver?.driver)
            ? formatDriverName(getValueByPath(entry, 'driver.driver'), driverDisplayNameFormat)
            : '';
    };

    return [
        {
            dataType: 'string',
            name: ColumnName.DRIVER_ACTIVITY,
            title: t(ColumnName.DRIVER_ACTIVITY),
            groupTitle: t(ColumnGroup.STAFF),
            getCellValue: (entry) => getValueByPath(entry, 'driver.activity'),
            exportValueFormatter: exportFormatterFactory((v: DriverActivityType) => formatDriverActivity(t, v)),
            valueFormatterComponent: withT(DriverActivityFormatter),
            compare: compareFactory((v: DriverActivityType) => formatDriverActivity(t, v), stringComparer),
            valueTextFormatter: valueTextFormatterFactory((v: DriverActivityType) => formatDriverActivity(t, v)),
            align: 'left',
        },
        {
            dataType: 'string',
            name: ColumnName.SUBACTIVITY,
            title: t(ColumnName.SUBACTIVITY),
            groupTitle: t(ColumnGroup.STAFF),
            getCellValue: (entry) => getValueByPath(entry, 'driver.subactivity.name'),
            compare: compareStrings,
            align: 'left',
        },
        createStringColumn(ColumnName.DRIVER, t(ColumnName.DRIVER), t(ColumnGroup.STAFF), formatDriver),
        createNumberColumn(ColumnName.DRIVER_ID, t(ColumnName.DRIVER_ID), t(ColumnGroup.STAFF), (entry) =>
            getValueByPath(entry, 'driver.driver.id')
        ),
        createStringColumn(ColumnName.VEHICLE, t(ColumnName.VEHICLE), t(ColumnGroup.GENERAL), formatVehicle),
        createStringColumn(ColumnName.CODRIVER_NAME, t(ColumnName.CODRIVER_NAME), t(ColumnGroup.STAFF), formatCodriver),
        createNumberColumn(ColumnName.CODRIVER_ID, t(ColumnName.CODRIVER_ID), t(ColumnGroup.STAFF), (entry) =>
            getValueByPath(entry, 'driver.coDriver.id')
        ),
        createDateTimeColumn(ColumnName.LAST_EVENT, t(ColumnName.LAST_EVENT), t(ColumnGroup.GENERAL), (entry) =>
            getValueByPath(entry, 'driver.lastEvent')
        ),
        createDurationColumn(
            t,
            ColumnName.DURATION,
            t(ColumnName.DURATION),
            t(ColumnGroup.GENERAL),
            (entry) => moment.duration(getValueByPath(entry, 'driver.duration')),
            { align: 'left' }
        ),
        {
            dataType: 'string',
            name: ColumnName.CARD_STATUS,
            title: t(ColumnName.CARD_STATUS),
            groupTitle: t(ColumnGroup.GENERAL),
            valueFormatterComponent: withTranslation()(DriverHoursLoginStatusFormatter),
            getCellValue: (entry) => getValueByPath(entry, 'status.cardStatus'),
            exportValueFormatter: exportFormatterFactory((v: LoginStatus) => formatDriverHoursLoginStatus(t, v)),
            compare: compareFactory((v: LoginStatus) => formatDriverHoursLoginStatus(t, v), stringComparer),
        },
        createDateTimeColumn(
            ColumnName.POSITION_DATETIME,
            t(ColumnName.POSITION_DATETIME),
            t(ColumnGroup.LOCATION),
            (entry) => getValueByPath(entry, 'driver.location.dateTime')
        ),
        {
            dataType: 'string',
            name: ColumnName.POI,
            title: t(ColumnName.POI),
            groupTitle: t(ColumnGroup.LOCATION),
            getCellValue: (entry) => getValueByPath(entry, 'driver.location.nearestCity'),
            exportValueFormatter: exportFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            valueFormatterComponent: compose(withT, withUnitSystem)(NearestCityFormatter),
            compare: compareFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v), stringComparer),
            groupingCriteria: groupingCriteriaFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
            valueTextFormatter: valueTextFormatterFactory((v: NearestCity) => formatNearestCity(t, unitSystem, v)),
        },
        {
            dataType: 'number',
            name: ColumnName.ALTITUDE,
            title: t(ColumnName.ALTITUDE),
            groupTitle: t(ColumnGroup.LOCATION),
            getCellValue: (entry) => getValueByPath(entry, 'driver.location.position.altitude'),
            getFilterValue: (v: number) => altitudeUtils.converter(v, { precision: 1 }),
            exportValueFormatter: exportConvertorFactory((v: number) => altitudeUtils.converter(v, { precision: 1 })),
            excelCellFormat: `0.0 "${altitudeUtils.unit}"`,
            valueFormatterComponent: compose(withT, withUnitSystem)(AltitudeFormatter),
            compare: compareNumbers,
            groupingCriteria: groupingCriteriaFactory((v: number) => altitudeUtils.formatter(v, { precision: 1 })),
            valueTextFormatter: valueTextFormatterFactory((v: number) => altitudeUtils.formatter(v, { precision: 1 })),
            align: 'left',
        },
        {
            dataType: 'string',
            name: ColumnName.LATITUDE,
            title: t(ColumnName.LATITUDE),
            groupTitle: t(ColumnGroup.LOCATION),
            getCellValue: (entry) => getValueByPath(entry, 'driver.location.position.latitude'),
            exportValueFormatter: exportFormatterFactory((v: number) => formatLatitude(t, v)),
            valueFormatterComponent: withT(LatitudeFormatter),
            compare: compareNumbers,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLatitude(t, v)),
            align: 'left',
        },
        {
            dataType: 'string',
            name: ColumnName.LONGITUDE,
            title: t(ColumnName.LONGITUDE),
            groupTitle: t(ColumnGroup.LOCATION),
            getCellValue: (entry) => getValueByPath(entry, 'driver.location.position.longitude'),
            exportValueFormatter: exportFormatterFactory((v: number) => formatLongitude(t, v)),
            valueFormatterComponent: withT(LongitudeFormatter),
            compare: compareNumbers,
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatLongitude(t, v)),
            align: 'left',
        },
        createStringColumn(ColumnName.CITY, t(ColumnName.CITY), t(ColumnGroup.LOCATION), (entry) =>
            getValueByPath(entry, 'driver.location.address.city')
        ),
        createStringColumn(ColumnName.POSTAL_CODE, t(ColumnName.POSTAL_CODE), t(ColumnGroup.LOCATION), (entry) =>
            getValueByPath(entry, 'driver.location.address.postalCode')
        ),
        createStringColumn(ColumnName.STREET, t(ColumnName.STREET), t(ColumnGroup.LOCATION), (entry) =>
            getValueByPath(entry, 'driver.location.address.street')
        ),
        createStringColumn(ColumnName.HOUSE_NUMBER, t(ColumnName.HOUSE_NUMBER), t(ColumnGroup.LOCATION), (entry) =>
            getValueByPath(entry, 'driver.location.address.houseNumber')
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.SHIFT_DURATION,
            t(ColumnName.SHIFT_DURATION),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.shiftDuration')),
            { align: 'left' }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.CONTINUOUS_DRIVING,
            t(ColumnName.CONTINUOUS_DRIVING),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.continuousDriving')),
            { align: 'left', abbreviation: t(Abbreviation.CONTINUOUS_DRIVING) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.DRIVE_BREAK,
            t(ColumnName.DRIVE_BREAK),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.driveBreak')),
            { align: 'left', abbreviation: t(Abbreviation.DRIVE_BREAK) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.SPLIT_DAILY_REST,
            t(ColumnName.SPLIT_DAILY_REST),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.splitDailyRest')),
            { align: 'left', abbreviation: t(Abbreviation.SPLIT_DAILY_REST) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.CONTINUOUS_WORK_WAIT,
            t(ColumnName.CONTINUOUS_WORK_WAIT),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.continuousWorkWait')),
            { align: 'left', abbreviation: t(Abbreviation.CONTINUOUS_WORK_WAIT) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.CONTINUOUS_LABOUR_SHORT,
            t(ColumnName.CONTINUOUS_LABOUR_SHORT),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.continuousLabourShort')),
            { align: 'left', abbreviation: t(Abbreviation.CONTINUOUS_LABOUR_SHORT) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.SHORT_LABOUR_BREAK,
            t(ColumnName.SHORT_LABOUR_BREAK),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.shortLabourBreak')),
            { align: 'left', abbreviation: t(Abbreviation.SHORT_LABOUR_BREAK) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.CONTINUOUS_LABOUR_LONG,
            t(ColumnName.CONTINUOUS_LABOUR_LONG),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.continuousLabourLong')),
            { align: 'left', abbreviation: t(Abbreviation.CONTINUOUS_LABOUR_LONG) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.LONG_LABOUR_BREAK,
            t(ColumnName.LONG_LABOUR_BREAK),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.longLabourBreak')),
            { align: 'left', abbreviation: t(Abbreviation.LONG_LABOUR_BREAK) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.DAILY_DRIVING,
            t(ColumnName.DAILY_DRIVING),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.dailyDriving')),
            { align: 'left', abbreviation: t(Abbreviation.DAILY_DRIVING) }
        ),
        createDurationColumn(
            t,
            ColumnName.AVAILABLE_DAILY_DRIVING,
            t(ColumnName.AVAILABLE_DAILY_DRIVING),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDuration(getValueByPath(entry, 'status.availableDailyDriving')),
            { align: 'left', abbreviation: t(Abbreviation.AVAILABLE_DAILY_DRIVING) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.DAILY_DUTY,
            t(ColumnName.DAILY_DUTY),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.dailyDuty')),
            { align: 'left', abbreviation: t(Abbreviation.DAILY_DUTY) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.NIGHT_LABOUR,
            t(ColumnName.NIGHT_LABOUR),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.nightLabour')),
            { align: 'left', abbreviation: t(Abbreviation.NIGHT_LABOUR) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.OPERATION_WEEK_DURATION,
            t(ColumnName.OPERATION_WEEK_DURATION),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.operationalWeekDuration')),
            { align: 'left', abbreviation: t(Abbreviation.OPERATION_WEEK_DURATION) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.WEEKLY_DRIVING,
            t(ColumnName.WEEKLY_DRIVING),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.weeklyDriving')),
            { align: 'left', abbreviation: t(Abbreviation.WEEKLY_DRIVING) }
        ),
        createDurationColumn(
            t,
            ColumnName.AVAILABLE_WEEKLY_DRIVING,
            t(ColumnName.AVAILABLE_WEEKLY_DRIVING),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDuration(getValueByPath(entry, 'status.availableWeeklyDriving')),
            { align: 'left', abbreviation: t(Abbreviation.AVAILABLE_WEEKLY_DRIVING) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.WEEKLY_DUTY,
            t(ColumnName.WEEKLY_DUTY),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.weeklyDuty')),
            { align: 'left', abbreviation: t(Abbreviation.WEEKLY_DUTY) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.WEEKLY_LABOUR,
            t(ColumnName.WEEKLY_LABOUR),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.weeklyLabour')),
            { align: 'left', abbreviation: t(Abbreviation.WEEKLY_LABOUR) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.AVERAGE_WEEKLY_LABOUR,
            t(ColumnName.AVERAGE_WEEKLY_LABOUR),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.averageWeeklyLabour')),
            { align: 'left', abbreviation: t(Abbreviation.AVERAGE_WEEKLY_LABOUR) }
        ),
        createNumberWithViolationColumn(
            t,
            ColumnName.REDUCED_DAILY_REST_COUNT,
            t(ColumnName.REDUCED_DAILY_REST_COUNT),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => getValueByPath(entry, 'driver.reducedDailyRestCount'),
            { abbreviation: t(Abbreviation.REDUCED_DAILY_REST_COUNT) }
        ),
        createNumberWithViolationColumn(
            t,
            ColumnName.EXTENDED_DAILY_DRIVING_COUNT,
            t(ColumnName.EXTENDED_DAILY_DRIVING_COUNT),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => getValueByPath(entry, 'driver.extendedDailyDrivingCount'),
            { abbreviation: t(Abbreviation.EXTENDED_DAILY_DRIVING_COUNT) }
        ),
        createDurationColumn(
            t,
            ColumnName.AVAILABLE_TWO_WEEKLY_DRIVING,
            t(ColumnName.AVAILABLE_TWO_WEEKLY_DRIVING),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDuration(getValueByPath(entry, 'status.availableBiWeeklyDriving')),
            { align: 'left', abbreviation: t(Abbreviation.AVAILABLE_TWO_WEEKLY_DRIVING) }
        ),
        createDurationWithViolationColumn(
            t,
            ColumnName.MONTHLY_DUTY,
            t(ColumnName.MONTHLY_DUTY),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => parseOptionalDurationViolationField(getValueByPath(entry, 'driver.monthlyDuty')),
            { align: 'left', abbreviation: t(Abbreviation.MONTHLY_DUTY) }
        ),
        {
            dataType: 'number',
            name: ColumnName.MONTHLY_DRIVE_OVER_DUTY,
            title: t(ColumnName.MONTHLY_DRIVE_OVER_DUTY),
            groupTitle: t(ColumnGroup.DRIVER_HOURS),
            align: 'right',
            abbreviation: t(Abbreviation.MONTHLY_DRIVE_OVER_DUTY),
            getCellValue: (entry) => getValueByPath(entry, 'driver.monthlyEfficiency'),
            excelCellFormat: '0.0%',
            valueFormatterComponent: PercentageFormatter,
            compare: compareNumbers,
            exportValueFormatter: exportFormatterFactory((v: number) => formatPercentage(v, 1)),
            valueTextFormatter: valueTextFormatterFactory((v: number) => formatPercentage(v, 1)),
        },
        {
            dataType: 'boolean',
            name: ColumnName.REGULAR_WEEKLY_REST_REQUIRED,
            title: t(ColumnName.REGULAR_WEEKLY_REST_REQUIRED),
            groupTitle: t(ColumnGroup.DRIVER_HOURS),
            getCellValue: (entry) => getValueByPath(entry, 'driver.regularWeeklyRestRequired'),
            exportValueFormatter: exportFormatterFactory((v: boolean) => formatBoolean(t, v)),
            valueFormatterComponent: withT(BooleanFormatter),
            valueTextFormatter: valueTextFormatterFactory((v: boolean) => formatBoolean(t, v)),
            compare: compareFactory((v: boolean) => formatBoolean(t, v), stringComparer),
            groupingCriteria: groupingCriteriaFactory((v: boolean) => formatBoolean(t, v)),
            abbreviation: t(Abbreviation.REGULAR_WEEKLY_REST_REQUIRED),
        },
        createDurationColumn(
            t,
            ColumnName.WEEKLY_REST_COMPENSATION,
            t(ColumnName.WEEKLY_REST_COMPENSATION),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => moment.duration(getValueByPath(entry, 'driver.weeklyRestCompensation')),
            { align: 'left', abbreviation: t(Abbreviation.WEEKLY_REST_COMPENSATION) }
        ),
        createDurationColumn(
            t,
            ColumnName.TOTAL_WEEKLY_REST_COMPENSATION,
            t(ColumnName.TOTAL_WEEKLY_REST_COMPENSATION),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => moment.duration(getValueByPath(entry, 'driver.totalWeeklyRestCompensation')),
            { align: 'left', abbreviation: t(Abbreviation.TOTAL_WEEKLY_REST_COMPENSATION) }
        ),
        createDurationColumn(
            t,
            ColumnName.WEEKLY_REST_VIOLATION,
            t(ColumnName.WEEKLY_REST_VIOLATION),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => moment.duration(getValueByPath(entry, 'status.weeklyRestViolation')),
            { align: 'left', abbreviation: t(Abbreviation.WEEKLY_REST_VIOLATION) }
        ),
        createDurationColumn(
            t,
            ColumnName.WEEKLY_REST_COMPENSATION_ON_VIOLATION,
            t(ColumnName.WEEKLY_REST_COMPENSATION_ON_VIOLATION),
            t(ColumnGroup.DRIVER_HOURS),
            (entry) => moment.duration(getValueByPath(entry, 'status.weeklyRestCompensationOnViolation')),
            { align: 'left', abbreviation: t(Abbreviation.WEEKLY_REST_COMPENSATION_ON_VIOLATION) }
        ),
    ];
};

export const getRowId = (row: MonitoringDriverEntry): number => getValueByPath(row, 'driver.driver.id');

export const getColumnsMemoized = memoizeOne(getColumns);
