import { Avatar } from '@mui/material';
import type { FC } from 'react';

import { DriverActivityIconFormatter } from '~/components/Formatters';
import { DriverIcon } from '~/components/Icons';

import type { DriverAvatarProps } from './models';
import { useStyles } from './styles';

const DriverAvatar: FC<DriverAvatarProps> = (props) => {
    const { activity } = props;
    const classes = useStyles();

    return (
        <div data-id="driver-avatar" className={classes.driver}>
            <Avatar className={classes.driverAvatar}>
                <DriverIcon />
            </Avatar>
            {activity && (
                <Avatar data-id="activity-badge" className={classes.activityAvatar}>
                    <DriverActivityIconFormatter value={activity.key} iconProps={{ fontSize: 'inherit' }} />
                </Avatar>
            )}
        </div>
    );
};

DriverAvatar.displayName = 'DriverAvatar';
export default DriverAvatar;
