import { escapeRegExp, isNil } from '~/libs/utility';

import type { ColumnSelectorGroup } from './models';

export const filterColumnGroups = (
    searchQuery: string | undefined,
    columnGroups: ColumnSelectorGroup[]
): ColumnSelectorGroup[] => {
    if (isNil(searchQuery)) {
        return columnGroups;
    }

    const searchQueryRegex = new RegExp(escapeRegExp(searchQuery), 'i');

    return columnGroups
        .filter((g) => g.columns.some((c) => c.title.match(searchQueryRegex)))
        .map((g) => {
            return { ...g, columns: g.columns.filter((c) => c.title.match(searchQueryRegex)) };
        });
};

export const getColumnNamesFromGroups = (columnGroups: ColumnSelectorGroup[]): string[] => {
    const columns: string[] = columnGroups.reduce<string[]>((prev, current) => {
        return prev.concat(current.columns.map((it) => it.name));
    }, []);

    return columns;
};
