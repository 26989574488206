import type { Dispatch } from 'redux';

import type { RetrievableData } from '~/reducers';
import { reportError } from '~/reporting';
import type { PositionStatus } from '~/services/ApiClient';

import { retrievePositionStatusesAction } from './data';

export interface PositionGroupStateProps {
    loading: boolean;
    rejected: boolean;
    items: PositionStatus[];
}

export interface PositionGroupDispatchProps {
    getIndividualPositions: (
        retrievePositions: (startDate: Date, stopDate: Date) => Promise<PositionStatus[]>,
        positionGroupId: number,
        startDate: Date,
        stopDate: Date
    ) => void;
}

export interface PositionGroupRedux extends PositionGroupStateProps, PositionGroupDispatchProps {}

export const mapStateToProps = (data: RetrievableData<PositionStatus[]>): PositionGroupStateProps => {
    return {
        loading: data.pending,
        rejected: data.rejected,
        items: data.data,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): PositionGroupDispatchProps => ({
    getIndividualPositions: (
        retrievePositions: (startDate: Date, stopDate: Date) => Promise<PositionStatus[]>,
        positionGroupId: number,
        startDate: Date,
        stopDate: Date
    ) => {
        dispatch(retrievePositionStatusesAction(retrievePositions, positionGroupId, startDate, stopDate)).catch(
            reportError
        );
    },
});
