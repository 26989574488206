import type { WithStyles } from '@mui/styles';
import { divIcon } from 'leaflet';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { ConversationMiniMapPinIcon } from '~/components/Icons';
import type { DeepPojoModel, Position } from '~/services/ApiClient';
import { renderToStaticThemedMarkup } from '~/services/React';

import { MiniMapSectionFactory } from '../MiniMapSection';

import type { PositionMiniMapSectionStyleRules } from './styles';

export interface PositionMiniMapSectionProps {
    position?: Position | DeepPojoModel<Position>;
    dragHandleElement: JSX.Element;
    zoomLevel: number;
    changeZoomLevel: (zoomLevel: number) => void;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
    lastLocation?: Position | DeepPojoModel<Position>;
}

export interface PositionMiniMapSectionInnerProps
    extends PositionMiniMapSectionProps,
        WithStyles<typeof PositionMiniMapSectionStyleRules, true> {}

const PositionMiniMapSectionComponent: FC<PositionMiniMapSectionProps> = (props: PositionMiniMapSectionInnerProps) => {
    const {
        position,
        dragHandleElement,
        zoomLevel,
        changeZoomLevel,
        isCollapsed,
        toggleCollapsed,
        theme,
        classes,
        lastLocation,
    } = props;

    const getMarkerIcon = useCallback(() => {
        return divIcon({
            html: renderToStaticThemedMarkup(
                theme,
                <ConversationMiniMapPinIcon fontSize="inherit" data-id="position-marker" />
            ),
            iconSize: [46, 56],
            iconAnchor: [23, 50],
            className: classes.positionIcon,
        });
    }, [classes.positionIcon, theme]);

    const MiniMapSection = useMemo(() => {
        return MiniMapSectionFactory(
            () => 0,
            () => Promise.resolve([]),
            () => true
        );
    }, []);

    return (
        <MiniMapSection
            isCollapsed={isCollapsed}
            dragHandleElement={dragHandleElement}
            entry={undefined}
            zoomLevel={zoomLevel}
            changeZoomLevel={changeZoomLevel}
            toggleCollapsed={toggleCollapsed}
            getMarkerIcon={getMarkerIcon}
            lastLocation={position ?? lastLocation}
        />
    );
};

export { PositionMiniMapSectionComponent };
