import type { StaticDataStoreState } from '~/common';
import { AssetType } from '~/services/ApiClient';

import { ACTION_TYPE_KEYS } from './actionsTypes';
import type { ActionTypes, AssetNearBySceneState } from './models';
import { getAssetNearByListResolver } from './utils';

const INITIAL_STATE: AssetNearBySceneState = {
    error: false,
    items: [],
    pending: false,
};

const assetNearByPositionsReducer = (
    state = INITIAL_STATE,
    action: ActionTypes,
    staticData: StaticDataStoreState
): AssetNearBySceneState => {
    const { drivers, vehicles, trailers } = staticData;

    const staticDataMap = {
        [AssetType.Vehicle]: vehicles.data.hash,
        [AssetType.Trailer]: trailers.data.hash,
        [AssetType.Driver]: drivers.data.hash,
    };

    switch (action.type) {
        case ACTION_TYPE_KEYS.LIST_PENDING:
            return { ...state, pending: true };
        case ACTION_TYPE_KEYS.LIST_FULFILLED: {
            return {
                ...state,
                items: getAssetNearByListResolver(action.payload, staticDataMap[action.meta]),
                pending: false,
            };
        }

        case ACTION_TYPE_KEYS.LIST_REJECTED:
            return { ...state, error: true, pending: false };

        case ACTION_TYPE_KEYS.RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export { assetNearByPositionsReducer };
