import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatVoltage } from '~/services/Formatters';

export interface VoltageFormatterProps {
    t: SingleTFunction;
    value?: number;
    precision?: number;
}

export interface VoltageFormatterInnerProps extends VoltageFormatterProps {}

const VoltageFormatter: React.FunctionComponent<VoltageFormatterInnerProps> = ({ t, value, precision }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatVoltage({ t, value, precision })}</>;
};
VoltageFormatter.displayName = 'VoltageFormatter';

export { VoltageFormatter };
