import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { HeaderInfoInnerProps, HeaderInfoProps } from './component';
import { HeaderInfoComponent } from './component';
import { HeaderInfoStyleRules } from './styles';

export const HeaderInfo = compose<HeaderInfoInnerProps, HeaderInfoProps>(
    setDisplayName('HeaderInfo'),
    withStyles(HeaderInfoStyleRules),
    withTranslation()
)(HeaderInfoComponent);
