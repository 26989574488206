import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import { formatDateTime } from '~/services/Formatters';

export interface DateTimeFormatterProps {
    value?: Date;
}

export interface DateTimeFormatterInnerProps extends DateTimeFormatterProps {}

const DateTimeFormatter: React.FunctionComponent<DateTimeFormatterInnerProps> = ({ value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatDateTime(value)}</>;
};
DateTimeFormatter.displayName = 'DateTimeFormatter';

export { DateTimeFormatter };
