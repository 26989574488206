import type { NumericDictionary } from '~/libs/utility';
import type {
    ConversationUpdate,
    ResolvedConversationUpdate,
    WorkflowFormDefinition,
    WorkflowFormDefinitionStatus,
} from '~/services/ApiClient';

import { resolveConversationMessage } from './reducers.resolveConversationMessage';

export const resolveConversationUpdate = (
    conversationUpdate: ConversationUpdate,
    workflowFormDefinitions: NumericDictionary<WorkflowFormDefinition | WorkflowFormDefinitionStatus> | undefined
): ResolvedConversationUpdate => ({
    ...conversationUpdate,
    updatedMessages: conversationUpdate.updatedMessages.map((message) =>
        resolveConversationMessage(message, workflowFormDefinitions)
    ),
});
