import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type VehicleMarkerIconBadgeClassKey = 'ignitionStatus' | 'ignitionStatusOn' | 'ignitionStatusOff';

export const vehicleMarkerIconBadgeStyleRules: StyleRulesCallback<Theme, {}, VehicleMarkerIconBadgeClassKey> = (
    theme: Theme
) => {
    return createStyles({
        ignitionStatus: {
            height: 12,
            width: 12,
            position: 'relative',
            right: -4,
            borderRadius: '50%',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: theme.palette.common.white,
            boxShadow: `
                0px 1px 5px 0px ${theme.palette.grey[400]},
                0px 2px 2px 0px ${theme.palette.grey[500]},
                0px 3px 1px -2px ${theme.palette.grey[600]}
            `,
        },
        ignitionStatusOn: {
            backgroundColor: theme.functionalItemsColors.ignition.on.value,
        },
        ignitionStatusOff: {
            backgroundColor: theme.functionalItemsColors.ignition.off.value,
        },
    });
};
