import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { InlineBadgeInnerProps, InlineBadgeProps } from './component';
import { InlineBadgeComponent } from './component';
import { InlineBadgeStyleRules } from './styles';

export const InlineBadge = compose<InlineBadgeInnerProps, InlineBadgeProps>(
    setDisplayName('InlineBadge'),
    withStyles(InlineBadgeStyleRules)
)(InlineBadgeComponent);
