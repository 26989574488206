import _ExpandMore from '@mui/icons-material/ChevronRight';
import _ExpandLess from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

interface ContainerProps {
    isRoot: boolean;
}
const Container = styled('div')<ContainerProps>(({ theme, isRoot }) => ({
    paddingLeft: isRoot ? 0 : theme.spacing(3),
}));
const TreeRowContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(),
}));

interface TreeRowItemProps {
    order: number;
    flexGrow?: number;
}
const TreeRowItem = styled('div')<TreeRowItemProps>(({ order, flexGrow }) => ({ order, flexGrow }));

interface ToggleExpandActionProps {
    isDisable: boolean;
}
const ExpandMore = styled(_ExpandMore, {
    shouldForwardProp: (propName) => propName !== 'isDisable',
})<ToggleExpandActionProps>(({ theme, isDisable }) => ({
    color: isDisable ? theme.palette.text.disabled : 'unset',
}));

const ExpandLess = styled(_ExpandLess, {
    shouldForwardProp: (propName) => propName !== 'isDisable',
})<ToggleExpandActionProps>(({ theme, isDisable }) => ({
    color: isDisable ? theme.palette.text.disabled : 'unset',
}));

export { TreeRowItem, TreeRowContainer, Container, ExpandMore, ExpandLess };
