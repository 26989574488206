import _List from '@mui/material/List';
import { styled } from '@mui/styles';

const List = styled(_List)(({ theme }) => ({
    padding: `0 ${theme.spacing()}`,
    '& li:nth-child(odd)': {
        backgroundColor: theme.palette.background.default,
    },
}));

export { List };
