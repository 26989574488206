import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withUserData } from '~/components/AuthenticationData';
import { withTranslation } from '~/components/LanguageSelector';
import { ensureUserPreferences } from '~/components/UserPreferences';

import { requiredConversationUserPreferences } from '../../preferences';
import { conversationsRootStoreStateSelector } from '../../selectors';

import type { SendWorkflowMessageFormInnerProps, SendWorkflowMessageFormOuterProps } from './component';
import { SendWorkflowMessageFormComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { SendWorkflowMessageFormStyleRules } from './styles';

export const SendWorkflowMessageForm = compose<SendWorkflowMessageFormInnerProps, SendWorkflowMessageFormOuterProps>(
    setDisplayName('SendWorkflowMessageFormSubpage'),
    withTranslation(),
    withUserData(),
    withStyles(SendWorkflowMessageFormStyleRules),
    ensureUserPreferences([requiredConversationUserPreferences]),
    connect(createSelector(conversationsRootStoreStateSelector, mapStateToProps), mapDispatchToProps)
)(SendWorkflowMessageFormComponent);
