import type * as i18next from 'i18next';
import React from 'react';
import { wrapDisplayName } from 'react-recompose';
import type { Subtract } from 'utility-types';

import { useTranslation } from '../../hooks';
import type { SingleTFunction } from '../../models';

export interface InjectedTranslationProps {
    i18n: i18next.i18n;
    t: SingleTFunction;
}

export interface InjectedWithTranslationProps extends InjectedTranslationProps {}

export const withTranslationHoc = <P extends InjectedWithTranslationProps>(
    WrappedComponent: React.ComponentType<P>
): React.ComponentType<Subtract<P, InjectedWithTranslationProps>> => {
    type HocProps = Subtract<P, InjectedWithTranslationProps>;

    const WithTranslation = (props: HocProps) => {
        const translation = useTranslation();
        return <WrappedComponent {...(props as unknown as P)} {...translation} />;
    };
    WithTranslation.displayName = wrapDisplayName(WrappedComponent, 'withTranslation');
    return WithTranslation;
};
