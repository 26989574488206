import type { NumericDictionary } from '~/libs/utility';
import type { WorkflowFormDefinition } from '~/services/ApiClient';
import { WorkflowFormDefinitionStatus } from '~/services/ApiClient';

import { resolveConversationMessageBody } from '../../reducers.resolveConversationMessageBody';

import type { ConversationWidgetStoreState } from './reducers';

export const updateMissingWorkflowFormDefinitions = (
    conversationStoreState: ConversationWidgetStoreState,
    workflowFormDefinitions: NumericDictionary<WorkflowFormDefinition | WorkflowFormDefinitionStatus>
): ConversationWidgetStoreState => {
    return {
        ...conversationStoreState,
        resolvedConversation: conversationStoreState.resolvedConversation
            ? {
                  ...conversationStoreState.resolvedConversation,
                  messages: conversationStoreState.resolvedConversation.messages.map((message) => {
                      let msgToReturn = message;
                      if (
                          message.body.workflowFormDefinitionStatus === WorkflowFormDefinitionStatus.REQUIRED ||
                          message.body.workflowFormDefinitionStatus === WorkflowFormDefinitionStatus.PENDING
                      ) {
                          msgToReturn = {
                              ...message,
                              body: resolveConversationMessageBody(message.body.value, workflowFormDefinitions),
                          };
                      }
                      if (
                          message.replyToMessage &&
                          (message.replyToMessage.body.workflowFormDefinitionStatus ===
                              WorkflowFormDefinitionStatus.REQUIRED ||
                              message.replyToMessage.body.workflowFormDefinitionStatus ===
                                  WorkflowFormDefinitionStatus.PENDING)
                      ) {
                          msgToReturn = {
                              ...msgToReturn,
                              replyToMessage: {
                                  ...message.replyToMessage,
                                  body: resolveConversationMessageBody(
                                      message.replyToMessage.body.value,
                                      workflowFormDefinitions
                                  ),
                              },
                          };
                      }
                      return msgToReturn;
                  }),
              }
            : undefined,
    };
};
