import type { LocationState } from 'history';
import * as React from 'react';
import type { RouteComponentProps, StaticContext } from 'react-router';

import type { LogoutReason } from '~/components/AuthenticationData';

export interface EndImpersonationStateProps {
    isAuthenticated: boolean;
    isImpersonated: boolean;
}

export interface EndImpersonationLocationState {
    from?: LocationState;
    reason?: LogoutReason;
}

export interface EndImpersonationDispatchProps {
    endImpersonation: () => Promise<void>;
}

export interface EndImpersonationProps
    extends RouteComponentProps<{}, StaticContext, EndImpersonationLocationState | undefined> {}

export interface EndImpersonationInnerProps
    extends EndImpersonationProps,
        EndImpersonationStateProps,
        EndImpersonationDispatchProps {}

export class EndImpersonationComponent extends React.Component<EndImpersonationInnerProps> {
    public async componentDidMount(): Promise<void> {
        if (this.props.isAuthenticated && this.props.isImpersonated) {
            await this.props.endImpersonation();
        }

        this.redirectToHomepage();
    }

    public render() {
        return null;
    }

    private redirectToHomepage = () => {
        this.props.history.push('/', this.props.location.state);
    };
}
