import Warning from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { WarningIconFormatterProps } from './models';

const WarningIconFormatter: FC<WarningIconFormatterProps> = (props) => {
    const { value } = props;
    const { t } = useTranslation();

    return value ? (
        <Tooltip title={t('warning-icon-enabled')}>
            <span data-id="warning-icon-enabled">
                <Warning />
            </span>
        </Tooltip>
    ) : null;
};

WarningIconFormatter.displayName = 'WarningIconFormatter';
export default WarningIconFormatter;
