import type { Dispatch } from 'redux';

import { impersonateUserAction } from '~/data/authentication';
import { reportError } from '~/reporting';
import type { Securables } from '~/services/ApiClient';

import type { DispatchProps, StateProps } from './component';

export const mapStateToProps = (securables: Securables): StateProps => ({
    impersonateSecurable: securables.impersonation.impersonate,
});

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    impersonateUser: (customer: string, user: string) => {
        dispatch(impersonateUserAction(customer, user)).catch(reportError);
    },
});
