import { isNil } from '~/libs/utility';

import { isArray, isPrimitive } from './checkTypes';

const countDirtyFields = (value: unknown): number => {
    if (isPrimitive(value) && value) {
        return 1;
    }

    if (isNil(value) || !value) {
        return 0;
    }

    const toIterate = isArray(value) ? (value as unknown[]) : (Object.values(value as object) as unknown[]);

    return toIterate.reduce((acc: number, item) => {
        if (isPrimitive(item) && item) {
            return acc + 1;
        }

        return acc + countDirtyFields(item);
    }, 0) as number;
};

export { countDirtyFields };
