import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ContinuousDrivingClassKey = 'capitalize';

export const ContinuousDrivingClassStyleRules: StyleRulesCallback<Theme, {}, ContinuousDrivingClassKey> = () =>
    createStyles({
        capitalize: {
            textTransform: 'uppercase',
        },
    });
