import { isUndefined } from '~/libs/utility';
import type { StoreState } from '~/reducers';
import { userPreferencesStateSelector } from '~/selectors';

export const keyDataStateSelectorFactory =
    <T>(key: string) =>
    (s: StoreState): T => {
        const value = userPreferencesStateSelector(s)[key];

        if (isUndefined(value)) {
            throw new Error(`No key with value ${key} in user preferences`);
        }

        return value.actualData as T;
    };

export const defaultSettingsChangedSelectorFactory =
    (defaultSettingKeys?: string[]) =>
    (s: StoreState): boolean => {
        const state = userPreferencesStateSelector(s);

        return (defaultSettingKeys || Object.keys(state)).some((key) => {
            if (!state[key]) {
                return false;
            }
            return state[key].actualData !== state[key].defaultData;
        });
    };
