import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { CommonSectionProps } from '../../models';

import type { TachographSectionInnerProps } from './component';
import { TachographSectionComponent } from './component';

export const TachographSection = compose<TachographSectionInnerProps, CommonSectionProps>(
    setDisplayName('TachographSection'),
    withTranslation()
)(TachographSectionComponent);
