import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatLongitude } from '~/services/Formatters';

export interface LongitudeFormatterProps {
    t: SingleTFunction;
    value?: number;
}

export interface LongitudeFormatterInnerProps extends LongitudeFormatterProps {}

const LongitudeFormatter: React.FunctionComponent<LongitudeFormatterInnerProps> = ({ t, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatLongitude(t, value)}</>;
};
LongitudeFormatter.displayName = 'LongitudeFormatter';

export { LongitudeFormatter };
