import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';
import { kilogramsToPounds } from '~/services/Convertors';
import { memoizeOne } from '~/services/Memoize';

import type { UnitSystemConverterOptions, UnitSystemFormatterOptions, UnitSystemUtils } from './unitSystemUtils';

const getImperialUtils = (t: SingleTFunction) => {
    const unit = t('unit-lb');
    const defaultPrecision = 0;

    return {
        unit,
        formatter: (v?: number, options?: UnitSystemFormatterOptions) =>
            !isUndefined(v) ? `${kilogramsToPounds(v).toFixed(options?.precision ?? defaultPrecision)} ${unit}` : '',
        converter: (v?: number, options?: UnitSystemConverterOptions) =>
            !isUndefined(v) ? Number(kilogramsToPounds(v).toFixed(options?.precision ?? defaultPrecision)) : undefined,
    };
};

const getMetricUtils = (t: SingleTFunction) => {
    const unit = t('unit-kg');
    const defaultPrecision = 0;

    return {
        unit,
        formatter: (v?: number, options?: UnitSystemFormatterOptions) =>
            !isUndefined(v) ? `${v.toFixed(options?.precision ?? defaultPrecision)} ${unit}` : '',
        converter: (v?: number, options?: UnitSystemFormatterOptions) =>
            !isUndefined(v) ? Number(v.toFixed(options?.precision ?? defaultPrecision)) : undefined,
    };
};

export const getWeightUnitSystemUtils = memoizeOne(
    (t: SingleTFunction, unitSystem: DisplayUserPreferencesUnitSystem): UnitSystemUtils => {
        switch (unitSystem) {
            case DisplayUserPreferencesUnitSystem.Imperial:
                return getImperialUtils(t);
            case DisplayUserPreferencesUnitSystem.Metric:
                return getMetricUtils(t);
            default:
                throw new Error('Unknown unit system.');
        }
    }
);
