import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { TemperaturesSectionInnerProps, TemperaturesSectionProps } from './component';
import { TemperaturesSectionComponent } from './component';
import { TemperaturesSectionStyleRules } from './styles';

export const TemperaturesSection = compose<TemperaturesSectionInnerProps, TemperaturesSectionProps>(
    setDisplayName('TemperaturesSection'),
    withStyles(TemperaturesSectionStyleRules),
    withTranslation()
)(TemperaturesSectionComponent);
