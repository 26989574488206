import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { DriverAvatarInnerProps, DriverAvatarProps } from './component';
import { DriverAvatarComponent } from './component';
import { DriverAvatarStyleRules } from './styles';

export const DriverAvatar = compose<DriverAvatarInnerProps, DriverAvatarProps>(
    setDisplayName('DriverAvatar'),
    withStyles(DriverAvatarStyleRules)
)(DriverAvatarComponent);

export type { DriverAvatarClassKey } from './styles';
