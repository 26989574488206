import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { Subtract } from 'utility-types';

import { impersonatorTokenStateSelector } from '../../selectors';

import type { ImpersonatorTokenStateProps } from './redux';
import { mapStateToProps } from './redux';

export interface InjectedImpersonatorTokenProps extends ImpersonatorTokenStateProps {}

export const withImpersonatorToken = <WrappedProps extends InjectedImpersonatorTokenProps>(): ComponentEnhancer<
    WrappedProps,
    Subtract<WrappedProps, InjectedImpersonatorTokenProps>
> =>
    compose<WrappedProps, Subtract<WrappedProps, InjectedImpersonatorTokenProps>>(
        connect(createSelector(impersonatorTokenStateSelector, mapStateToProps))
    );
