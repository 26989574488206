import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import { themeSpacingToNumber } from '~/common/utils';

export type TimelineItemClassKey =
    | 'root'
    | 'highlighted'
    | 'avatarContainer'
    | 'smallIcon'
    | 'mediumIcon'
    | 'timelineContainer'
    | 'timelineIconContainer'
    | 'timelineSegment'
    | 'content'
    | 'beginSegment'
    | 'endSegment'
    | 'continuousSegment'
    | 'pointSegment'
    | 'hiddenSegment';

export const TimelineItemStyleRules: StyleRulesCallback<Theme, {}, TimelineItemClassKey> = (theme: Theme) => {
    const timelineWidth = 10;
    const firstHeight = themeSpacingToNumber(theme.spacing(3));
    const timelineRadius = timelineWidth / 2;
    return createStyles({
        root: {
            alignItems: 'flex-start',
            padding: 0,
            borderColor: alpha(theme.palette.divider, 0.08),
            borderStyle: 'solid',
            borderWidth: '1px 0',
        },
        highlighted: {
            '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity),
                pointerEvents: 'none',
            },
        },
        avatarContainer: {
            minWidth: 'initial',
            width: 32,
            margin: theme.spacing(1.5, 2),
            justifyContent: 'center',
        },
        content: {
            width: '100%',
            margin: theme.spacing(1, 2),
        },
        timelineContainer: {
            minWidth: timelineWidth,
            alignSelf: 'stretch',
            position: 'relative',
            marginTop: -1,
            marginBottom: -1,
        },

        timelineSegment: {
            backgroundColor: theme.palette.primary.main,
            width: timelineWidth,
            position: 'absolute',
        },
        hiddenSegment: {
            display: 'none',
        },
        beginSegment: {
            top: firstHeight - timelineRadius,
            bottom: 0,
            borderTopLeftRadius: timelineRadius,
            borderTopRightRadius: timelineRadius,
        },
        continuousSegment: {
            top: 0,
            bottom: 0,
        },
        endSegment: {
            top: 0,
            height: firstHeight + timelineRadius,
            borderBottomLeftRadius: timelineRadius,
            borderBottomRightRadius: timelineRadius,
        },
        pointSegment: {
            top: firstHeight - timelineRadius,
            height: timelineWidth,
            borderRadius: timelineRadius,
        },

        timelineIconContainer: {
            position: 'absolute',
            zIndex: 1,
            top: firstHeight,
            height: 0,
            width: timelineWidth,
            display: 'flex',
            color: theme.palette.primary.contrastText,
            alignItems: 'center',
            justifyContent: 'center',
        },
        mediumIcon: {
            fontSize: 8,
        },
        smallIcon: {
            fontSize: 6,
        },
    });
};
