import GpsFixed from '@mui/icons-material/GpsFixed';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import LocalParking from '@mui/icons-material/LocalParking';
import type { SvgIconProps } from '@mui/material';
import { Avatar, CircularProgress, Typography } from '@mui/material';
import type { ClassNameMap, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { ComponentType, FC, MouseEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { MovementPositionGroup } from '~/components/MovementPositionGroup';
import { StopPositionGroup } from '~/components/StopPositionGroup';
import { orderBy } from '~/libs/utility';
import { logEvent } from '~/services/Analytics';
import type { Position, PositionGroup, PositionStatus } from '~/services/ApiClient';
import { PositionGroupType } from '~/services/ApiClient';

import { IndividualPosition } from '../IndividualPosition';
import type { TimelineItemClassKey, TimelineSegmentType } from '../TimelineItem';
import { TimelineItem, splitTimelineSegmentType } from '../TimelineItem';

import type { PositionGroupRedux } from './redux';
import type { PositionGroupClassKey } from './styles';

export interface PositionGroupProps {
    positionGroup: PositionGroup;
    retrievePositions: (startDate: Date, stopDate: Date) => Promise<PositionStatus[]>;
    segmentType: TimelineSegmentType;
    startDate: Date;
    stopDate: Date;
    AssetIcon: ComponentType<SvgIconProps>;
    highlighted: boolean;
    stopNumber?: number;
    highlightPositionGroup: (positionGroup?: PositionGroup) => void;
    highlightPosition: (position?: Position) => void;
    flyToPositionGroup: () => void;
    panToPositionGroup: () => void;
    panToPosition: () => void;
    resetScrollIntoView: () => void;
    scrollIntoView?: boolean;
    timelineClasses?: Partial<ClassNameMap<TimelineItemClassKey>>;
}

export interface PositionGroupInnerProps
    extends PositionGroupProps,
        InjectedTranslationProps,
        WithStyles<PositionGroupClassKey>,
        PositionGroupRedux {}

export const PositionGroupComponent: FC<PositionGroupInnerProps> = (props) => {
    const {
        classes,
        segmentType,
        positionGroup,
        startDate,
        stopDate,
        stopNumber,
        getIndividualPositions,
        retrievePositions,
        loading,
        rejected,
        items,
        AssetIcon,
        t,
        highlightPositionGroup,
        highlightPosition,
        panToPositionGroup,
        flyToPositionGroup,
        panToPosition,
        scrollIntoView,
        highlighted,
        resetScrollIntoView,
        timelineClasses,
    } = props;

    const [expanded, setExpanded] = useState(false);
    const itemRef = useRef<HTMLLIElement>(null);

    const CollapseIcon = expanded ? KeyboardArrowUp : KeyboardArrowDown;

    const positionIsStop = positionGroup.type === PositionGroupType.Stop;

    const itemRootClass = classNames({ [classes.parkItem]: positionIsStop });
    const itemClasses = {
        ...timelineClasses,
        root: classNames(timelineClasses?.root, itemRootClass, classes.clickableItem),
    };
    const dotSize = positionIsStop ? 'medium' : positionGroup.current ? 'small' : undefined;
    const splitSegmentTypes = splitTimelineSegmentType(segmentType);

    useEffect(() => {
        if (itemRef.current && scrollIntoView) {
            itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            resetScrollIntoView();
        }
    }, [scrollIntoView, resetScrollIntoView]);

    useEffect(() => {
        if (expanded) {
            getIndividualPositions(retrievePositions, positionGroup.id, startDate, stopDate);
        }
    }, [expanded, getIndividualPositions, retrievePositions, positionGroup.id, startDate, stopDate]);

    const renderPositions = useCallback(() => {
        if (!expanded) {
            return undefined;
        }

        const positionItemClasses = {
            ...timelineClasses,
            root: classNames(timelineClasses?.root, itemRootClass, classes.positionTimelineItem),
        };
        const clickablePositionItemClasses = {
            root: classNames(classes.clickableItem, itemRootClass, classes.positionTimelineItem),
        };

        if (loading) {
            return (
                <TimelineItem segmentType={splitSegmentTypes.stop} classes={positionItemClasses}>
                    <div className={classes.loaderContainer} data-id="loading-data">
                        <CircularProgress size={32} />
                    </div>
                </TimelineItem>
            );
        }

        if (rejected) {
            return (
                <TimelineItem segmentType={splitSegmentTypes.stop} classes={positionItemClasses}>
                    <Typography variant="caption" className={classes.messageText} data-id="loading-failed">
                        {t('failed-to-retrieve-data')}
                    </Typography>
                </TimelineItem>
            );
        }

        if (!items.length) {
            return (
                <TimelineItem segmentType={splitSegmentTypes.stop} classes={positionItemClasses}>
                    <Typography variant="caption" className={classes.messageText} data-id="no-data">
                        {t('no-position-data-available-for-group')}
                    </Typography>
                </TimelineItem>
            );
        }

        const lastItemIndex = items.length - 1;
        const orderedItems = orderBy(items, 'dateTime', 'desc');

        return orderedItems.map((item, index) => {
            const key = index;
            const dataId = `position-group:${positionGroup.id}|individual-position-index:${index}`;
            const positionSegmentType = index === lastItemIndex ? splitSegmentTypes.stop : splitSegmentTypes.middle;
            return (
                <IndividualPosition
                    key={key}
                    segmentType={positionSegmentType}
                    positionStatus={item}
                    classes={clickablePositionItemClasses}
                    dataId={dataId}
                    panToPosition={panToPosition}
                    highlightPosition={highlightPosition}
                    timelineClasses={timelineClasses}
                />
            );
        });
    }, [
        expanded,
        itemRootClass,
        classes.positionTimelineItem,
        classes.clickableItem,
        classes.loaderContainer,
        classes.messageText,
        loading,
        rejected,
        items,
        splitSegmentTypes.stop,
        splitSegmentTypes.middle,
        t,
        positionGroup.id,
        panToPosition,
        highlightPosition,
        timelineClasses,
    ]);

    const toggle = useCallback(
        (event: MouseEvent<SVGSVGElement>) => {
            event.stopPropagation();
            setExpanded(!expanded);
            if (!expanded) {
                logEvent('timeline', 'expand-position-group', `Expand position group of type '${positionGroup.type}'`);
            }
        },
        [expanded, positionGroup.type]
    );

    const dataId = expanded ? 'expand-group-button' : 'collapse-group-button';

    const currentAvatar = positionIsStop ? classes.avatar : classes.avatarSmaller;

    const stopIcon = stopNumber ?? <LocalParking fontSize="inherit" />;

    const avatar = positionGroup.current ? (
        <Avatar className={currentAvatar} data-id="avatar:current">
            <GpsFixed fontSize="inherit" />
        </Avatar>
    ) : positionIsStop ? (
        <Avatar className={classes.parkAvatar} data-id="avatar:stop">
            {stopIcon}
        </Avatar>
    ) : undefined;

    const renderContent = useCallback(
        (item: PositionGroup) => {
            const iconWithAction = <CollapseIcon onClick={toggle} className={classes.toggleButton} data-id={dataId} />;
            switch (item.type) {
                case PositionGroupType.Movement: {
                    return (
                        <MovementPositionGroup
                            AssetIcon={AssetIcon}
                            positionGroup={item}
                            toggleElement={iconWithAction}
                        />
                    );
                }

                case PositionGroupType.Stop: {
                    return <StopPositionGroup positionGroup={item} toggleElement={iconWithAction} />;
                }

                default:
                    throw new Error(`Unknown positionGroupType: ${item.type}`);
            }
        },
        [toggle, classes, dataId, AssetIcon, CollapseIcon]
    );

    const mouseEnterHandler = useCallback(() => {
        highlightPositionGroup(positionGroup);
    }, [highlightPositionGroup, positionGroup]);

    const clickHandler = useCallback(() => {
        if (positionGroup.type === PositionGroupType.Movement) {
            flyToPositionGroup();
        } else {
            panToPositionGroup();
        }
    }, [panToPositionGroup, flyToPositionGroup, positionGroup]);

    const positionsMouseLeaveHandler = useCallback(() => {
        highlightPosition(undefined);
    }, [highlightPosition]);

    const positionGroupSegmentType = expanded ? splitSegmentTypes.start : segmentType;
    const isHighlighted = highlighted ? 'highlighted' : 'normal';

    return (
        <div onMouseEnter={mouseEnterHandler}>
            <TimelineItem
                ref={itemRef}
                segmentType={positionGroupSegmentType}
                avatar={avatar}
                segmentDotSize={dotSize}
                classes={itemClasses}
                dataId={`position-group:${positionGroup.id}:${positionGroup.type}:${isHighlighted}`}
                ariaOwns={`underlying-positions-content:${positionGroup.id}`}
                highlighted={highlighted}
                ListItemProps={{ onClick: clickHandler }}
            >
                {renderContent(positionGroup)}
            </TimelineItem>
            <div data-id={`underlying-positions-content:${positionGroup.id}`} onMouseLeave={positionsMouseLeaveHandler}>
                {renderPositions()}
            </div>
        </div>
    );
};
