import type { Dispatch } from 'react';
import { useEffect } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';

export interface UseNestedRouteParamsArgs<T> {
    path: string;
    defaultParams: T;
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;
}

const useNestedRouteParams = <Params extends { [K in keyof Params]?: string }>({
    path,
    defaultParams,
    exact = true,
    strict,
    sensitive,
}: UseNestedRouteParamsArgs<Params>): [Params, Dispatch<Params>] => {
    const parentMatch = useRouteMatch();
    const basePath = parentMatch.url.slice(-1) === '/' ? parentMatch.url.slice(0, -1) : parentMatch.url;
    const pathPattern = `${basePath}${path}`;
    const routeMatch = useRouteMatch<Params>({ path: [pathPattern, basePath], exact, sensitive, strict });
    const history = useHistory();

    useEffect(() => {
        if (routeMatch?.path !== pathPattern) {
            if (routeMatch?.isExact) {
                history.replace(generatePath(pathPattern, defaultParams));
            } else {
                history.push('/not-found');
            }
        }
    }, [routeMatch, history, pathPattern, defaultParams]);

    return [
        routeMatch?.path === pathPattern ? routeMatch.params : defaultParams,
        (params: Params) => history.push(generatePath(pathPattern, params)),
    ];
};

export { useNestedRouteParams };
