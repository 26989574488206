import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { LabeledIndicatorGroupComponent } from './component';
import type { LabeledIndicatorGroupInnerProps, LabeledIndicatorGroupProps } from './models';
import { LabeledIndicatorGroupStyleRules } from './styles';

export const LabeledIndicatorGroup = compose<LabeledIndicatorGroupInnerProps, LabeledIndicatorGroupProps>(
    setDisplayName('LabeledIndicatorGroup'),
    withStyles(LabeledIndicatorGroupStyleRules)
)(LabeledIndicatorGroupComponent);
