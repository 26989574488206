import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CompassIcon } from '~/components/Icons';
import { isUndefined } from '~/libs/utility';
import type { Position } from '~/services/ApiClient';
import { copyCoordinatesToClipboard } from '~/services/Clipboard';

import { useStyles } from './styles';

export interface LocationSectionActionButtonsProps {
    position?: Position;
}

export interface LocationSectionActionButtonsInnerProps extends LocationSectionActionButtonsProps {}

const LocationSectionActionButtons: React.FC<LocationSectionActionButtonsInnerProps> = ({ position }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const copyCoordinates = () => {
        if (!isUndefined(position)) {
            copyCoordinatesToClipboard(position);
        }
    };

    return (
        <Button
            color="secondary"
            data-id="copy-coordinates"
            className={classes.copyCoordinatesButton}
            disabled={isUndefined(position)}
            onClick={copyCoordinates}
        >
            <CompassIcon className={classes.iconMargin} />
            {t('copy-coordinates')}
        </Button>
    );
};
LocationSectionActionButtons.displayName = 'LocationSectionActionButtons';

export { LocationSectionActionButtons };
