import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SceneDetailsPaneHeaderClassKey = 'root' | 'avatar' | 'secondaryIdentifier' | 'action';

export const SceneDetailsPaneHeaderStyleRules: StyleRulesCallback<Theme, {}, SceneDetailsPaneHeaderClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            height: 80,
            flexDirection: 'row',
            position: 'relative',
            paddingRight: theme.spacing(),
        },
        avatar: {
            width: 40,
            height: 40,
            alignSelf: 'center',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            overflow: 'visible',
        },
        secondaryIdentifier: {
            opacity: 0.54,
        },
        action: {
            marginRight: theme.spacing(),
        },
    });
