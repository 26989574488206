import { Tooltip } from '@mui/material';
import { add, sub } from 'date-fns';
import * as durationFns from 'duration-fns';
import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
    DateTimeFormatter,
    DurationFnsFormatter,
    DurationFormatter,
    adaptiveDateTimeFormatterFactory,
} from '~/components/Formatters';
import { LabeledIndicator, LabeledIndicatorGroup } from '~/components/Indicators';
import { isUndefined } from '~/libs/utility';
import { DurationViolationField, ViolationType, createApiModel } from '~/services/ApiClient';
import { parseOptionalDuration, parseOptionalDurationViolationField } from '~/services/Parsers';
import { mapViolationTypeToColorVariant } from '~/services/Styling';

import type { ContinuousDrivingProps } from './models';
import { useStyles } from './styles';

const ContinuousDrivingSection: FC<ContinuousDrivingProps> = (props) => {
    const { continuousDriving, duration, activityStartDateTime, driveBreak } = props.driverStatus;
    const { t } = useTranslation();
    const classes = useStyles();

    const continuousDriveValue = parseOptionalDurationViolationField(continuousDriving as DurationViolationField);
    const driveBreakValue = parseOptionalDuration(driveBreak);
    const dateTime: Date = !isUndefined(duration)
        ? add(activityStartDateTime as Date, durationFns.parse(duration))
        : (activityStartDateTime as Date);
    const durationViolationField = createApiModel(DurationViolationField, {
        threshold: 'PT1H6M',
        value: 'PT20M',
        violationType: ViolationType.Violation,
        violation: 'PT1H6M',
    });
    const remainingValue = parseOptionalDurationViolationField(durationViolationField);
    const driveBreakDueValue = durationFns.parse('PT20H');

    const formattedBreakDueValueDuration = !driveBreakDueValue ? undefined : driveBreakDueValue.milliseconds === 0 ? (
        t('none')
    ) : (
        <DurationFnsFormatter value={driveBreakDueValue} />
    );

    const violationDate = sub(dateTime, durationFns.parse(durationViolationField.violation ?? ''));

    const AdaptiveDateTimeFormatter = adaptiveDateTimeFormatterFactory();

    const remainingViolated = remainingValue?.violationType === ViolationType.Violation;

    return (
        <LabeledIndicatorGroup title={t('continuous-driving')} dataId="continuous-driving-group">
            {[
                <LabeledIndicator
                    key={t('accumulated')}
                    title={t('accumulated')}
                    primaryText={
                        continuousDriveValue?.value ? (
                            <DurationFormatter value={continuousDriveValue?.value} />
                        ) : (
                            t('not-available')
                        )
                    }
                    secondaryText={t('since-last-block')}
                    variant={mapViolationTypeToColorVariant(continuousDriveValue?.violationType)}
                    dataId={`accumulated-time-indicator${
                        continuousDriveValue?.violationType ? `:${continuousDriveValue?.violationType}` : ''
                    }`}
                />,
                <LabeledIndicator
                    key={t('remaining')}
                    title={remainingViolated ? t('overrun') : t('remaining')}
                    primaryText={
                        !remainingValue
                            ? undefined
                            : remainingViolated
                              ? remainingValue?.violation && <DurationFormatter value={remainingValue?.violation} />
                              : <DurationFormatter value={remainingValue?.value} /> ?? t('not-available')
                    }
                    secondaryText={
                        remainingViolated ? (
                            <Tooltip
                                title={<DateTimeFormatter value={violationDate} />}
                                data-id="secondary-text-tooltip"
                            >
                                <span>
                                    <Trans i18nKey="since-violation">
                                        <span className={classes.capitalize}>
                                            <AdaptiveDateTimeFormatter value={violationDate} t={t} />
                                        </span>
                                    </Trans>
                                </span>
                            </Tooltip>
                        ) : (
                            t('before-break').toLowerCase()
                        )
                    }
                    variant={mapViolationTypeToColorVariant(remainingValue?.violationType)}
                    dataId={`remaining-time-indicator${
                        remainingValue?.violationType ? `:${remainingValue?.violationType}` : ''
                    }`}
                />,
                <LabeledIndicator
                    key={t('break-due')}
                    title={t('break-due')}
                    primaryText={formattedBreakDueValueDuration ?? t('not-available')}
                    secondaryText={
                        formattedBreakDueValueDuration ? (
                            <Trans i18nKey="taken-break">
                                {!driveBreakValue || driveBreakValue.asMilliseconds() === 0 ? (
                                    <span>{t('none')}</span>
                                ) : (
                                    <DurationFormatter value={driveBreakValue} />
                                )}
                            </Trans>
                        ) : (
                            ''
                        )
                    }
                    dataId="break-due-indicator"
                />,
            ]}
        </LabeledIndicatorGroup>
    );
};

ContinuousDrivingSection.displayName = 'ContinuousDrivingSection';
export default ContinuousDrivingSection;
