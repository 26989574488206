import moment from 'moment';
import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { DistanceFormatter, FuelLevelFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import type { PositionGroup } from '~/services/ApiClient';

import { HeaderInfo } from './HeaderInfo';
import { SummarySectionContent } from './SummarySectionContent';

export interface SummarySectionProps {
    positionGroups: PositionGroup[];
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

export interface SummarySectionInnerProps
    extends SummarySectionProps,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps {}

export const SummarySectionComponent: React.FC<SummarySectionInnerProps> = ({
    positionGroups,
    t,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
    displayPreferences,
}) => {
    let primaryValueDistance: string | React.ReactNode = t('not-available');
    let secondaryValueDistance;
    let primaryValueFuel: string | React.ReactNode = t('not-available');
    let secondaryValueFuel;

    let startDate;
    let stopDate;
    let fuelSum;
    let distanceOdometerSum;
    let distanceGpsSum;

    const isThereFuelData = positionGroups
        .map((p) => {
            return p.fuelUsed;
        })
        .find((fuelValue) => {
            return fuelValue !== undefined;
        });

    if (positionGroups.length) {
        if (isThereFuelData !== undefined) {
            fuelSum = positionGroups.reduce((prev, cur) => {
                return cur.fuelUsed !== undefined ? prev + cur.fuelUsed : prev;
            }, 0);
            if (fuelSum !== undefined && !Number.isNaN(fuelSum)) {
                if (fuelSum === 0) {
                    primaryValueFuel = t('no-fuel-used');
                } else {
                    primaryValueFuel = (
                        <FuelLevelFormatter t={t} unitSystem={displayPreferences.unitSystem} value={fuelSum} />
                    );
                }
                secondaryValueFuel = t('vehicle-data');
            }
        }

        distanceOdometerSum = positionGroups.reduce((prev, cur) => {
            return cur.odometerDistance !== undefined ? prev + cur.odometerDistance : undefined;
        }, 0);
        if (distanceOdometerSum === undefined || Number.isNaN(distanceOdometerSum)) {
            distanceGpsSum = positionGroups.reduce((prev, cur) => {
                return prev + cur.gpsDistance;
            }, 0);
            if (distanceGpsSum === 0) {
                primaryValueDistance = t('no-movement');
            } else {
                primaryValueDistance = (
                    <DistanceFormatter
                        t={t}
                        unitSystem={displayPreferences.unitSystem}
                        value={distanceGpsSum}
                        autoFormat
                    />
                );
            }
            secondaryValueDistance = t('gps-data');
        } else {
            if (distanceOdometerSum === 0) {
                primaryValueDistance = t('no-movement');
            } else {
                primaryValueDistance = (
                    <DistanceFormatter
                        t={t}
                        unitSystem={displayPreferences.unitSystem}
                        value={distanceOdometerSum}
                        autoFormat
                    />
                );
            }
            secondaryValueDistance = t('odometer');
        }

        const firstPositionGroup = positionGroups[0];
        const lastPositionGroup = positionGroups[positionGroups.length - 1];
        startDate = firstPositionGroup.start?.dateTime
            ? firstPositionGroup.start?.dateTime
            : firstPositionGroup.stop?.dateTime
              ? moment(firstPositionGroup.stop?.dateTime)
                    .add(-moment.duration(firstPositionGroup.duration))
                    .toDate()
              : undefined;
        stopDate = lastPositionGroup.stop?.dateTime
            ? lastPositionGroup.stop?.dateTime
            : lastPositionGroup.start?.dateTime
              ? moment(lastPositionGroup.start?.dateTime)
                    .add(moment.duration(lastPositionGroup.duration))
                    .toDate()
              : undefined;
    }
    const dateSpan = { start: startDate, stop: stopDate };
    const header = <HeaderInfo fuel={primaryValueFuel} distance={primaryValueDistance} />;
    return (
        <ReorderableSection
            title={t('summary')}
            dataId="summary"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            rightHeaderElement={header}
        >
            <SummarySectionContent
                dateSpan={dateSpan}
                primaryValueDistance={primaryValueDistance}
                secondaryValueDistance={secondaryValueDistance}
                primaryValueFuel={primaryValueFuel}
                secondaryValueFuel={secondaryValueFuel}
            />
        </ReorderableSection>
    );
};
