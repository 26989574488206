import { ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { ElapsedTime } from '~/components/ElapsedTime';
import { DoorStatusFormatter } from '~/components/Formatters';
import { ClockIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SensitiveData } from '~/components/SensitiveData';
import type { ResolvedDoorStatus } from '~/services/ApiClient';
import { formatDoorStatus } from '~/services/Formatters';

import type { DoorClassKeys } from './styles';

export interface DoorProps {
    status: ResolvedDoorStatus;
}

export interface DoorInnerProps extends DoorProps, InjectedTranslationProps, WithStyles<DoorClassKeys> {}

export const DoorComponent: React.FC<DoorInnerProps> = ({ status, classes, t }) => {
    const doorNumberElement = (
        <Typography data-id="door-label" className={classes.doorNumber}>
            {t('nth-door', { doorNumber: status.doorNumber })}
        </Typography>
    );

    const doorStatus = formatDoorStatus(t, status.status);

    const doorAvatar = <DoorStatusFormatter t={t} value={status.status} withAvatar />;

    const sealCode = status.sealCode && <SensitiveData value={status.sealCode} />;

    return (
        <div data-id={`door-summary-${status.doorNumber}`}>
            <ListItem className={classes.doorElement}>
                <ListItemAvatar>{doorAvatar}</ListItemAvatar>
                <ListItemText primary={doorNumberElement} />
                <div>
                    <div data-id="door-status" className={classes.doorStatus}>
                        {doorStatus}
                    </div>
                    <Typography variant="caption" color="textSecondary">
                        {sealCode}
                    </Typography>
                </div>
            </ListItem>
            <ListItem className={classes.doorTimeElement}>
                <ElapsedTime icon={ClockIcon} since={status.dateTime} />
            </ListItem>
        </div>
    );
};
