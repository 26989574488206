import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { ResolvedContainerTemperatureStatus } from '~/services/ApiClient';

import { ContainerTemperatureSensor } from '../TemperatureSensor';

import type { ContainerTemperatureGroupClassKey } from './styles';

export interface ContainerTemperatureGroupOutterProps {
    temperatures: ResolvedContainerTemperatureStatus[];
    onClick: () => void;
}
export interface ContainerTemperatureGroupInnerProps
    extends InjectedTranslationProps,
        ContainerTemperatureGroupOutterProps,
        InjectedDisplayPreferencesProps,
        WithStyles<ContainerTemperatureGroupClassKey> {}

export const ContainerTemperatureGroupComponent: React.FC<ContainerTemperatureGroupInnerProps> = ({
    temperatures,
    displayPreferences,
    onClick,
    classes,
}) => {
    const sensorElements = temperatures.map((temperatureSensor, index) => (
        <div key={temperatureSensor.id} className={classes.temperatureSensor} onClick={onClick}>
            <ContainerTemperatureSensor
                temperatureSensor={temperatureSensor}
                sensorOrder={index + 1}
                unitSystem={displayPreferences.unitSystem}
            />
        </div>
    ));
    return <div className={classes.temperatureGroupContainer}>{sensorElements}</div>;
};
