import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { ClassificationFormatter, TrailerNameFormatter } from '~/components/Formatters';
import { TrailerIcon } from '~/components/Icons/TrailerIcon';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { MonitoringTrailerEntry } from '~/data/monitoring';
import { ClassificationType } from '~/services/ApiClient';

import type { TrailerEntryRowClassKey } from './styles';

export interface TrailerEntryRowProps {
    entry: MonitoringTrailerEntry;
}

export interface TrailerEntryRowInnerProps
    extends TrailerEntryRowProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps,
        WithStyles<TrailerEntryRowClassKey> {}

export const TrailerEntryRowComponent: React.FC<TrailerEntryRowInnerProps> = ({
    classes,
    entry,
    t,
    displayPreferences,
}) => {
    const { trailer } = entry;

    const delimiter = <span className={classes.delimiter}>•</span>;

    const trailerType = <span data-id="trailer-type">{t(trailer?.reeferManufacturer ? 'reefer' : 'trailer')}</span>;

    const manufacturer = trailer?.trailerManufacturer && (
        <>
            {delimiter}
            <span data-id="trailer-manufacturer">
                <ClassificationFormatter
                    value={trailer.trailerManufacturer}
                    t={t}
                    classificationKey={ClassificationType.TrailerManufacturer}
                />
            </span>
        </>
    );

    const vehicleIsConnected = (
        <>
            {delimiter}
            <span data-id="vehicle-connection">{t(entry.vehicleConnection ? 'connected' : 'not-connected')}</span>
        </>
    );

    const secondary = (
        <>
            {trailerType}
            {manufacturer}
            {vehicleIsConnected}
        </>
    );

    const formattedTrailerName = (
        <TrailerNameFormatter value={trailer} trailerDisplayNameFormat={displayPreferences.trailerDisplayFormat} />
    );

    const trailerName = (
        <Typography data-id="trailer-name" variant="body2" className={classes.primaryText}>
            {formattedTrailerName}
        </Typography>
    );
    return (
        <ListItem component="div" className={classes.root}>
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    <TrailerIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={trailerName} secondary={secondary} className={classes.content} />
        </ListItem>
    );
};
