import type { Action } from 'redux';

import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings';

import type { TachoFilesStoreState } from './reducers';
import { tachoFilesReducer } from './reducers';

export interface TachoFilesSceneStoreState {
    tachoFilesRoot: TachoFilesStoreState;
}

export const tachoFilesSceneReducer = (
    state: TachoFilesSceneStoreState | undefined,
    action: Action,
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): TachoFilesSceneStoreState => {
    const tachoFilesRoot = tachoFilesReducer(state?.tachoFilesRoot, action, staticDataStoreState, settingsStoreState);
    return { tachoFilesRoot };
};
