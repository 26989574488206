import { CircularProgress } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import type { ButtonProgressClassKey } from './styles';

export interface ButtonProgressProps {
    showProgress: boolean;
}

export interface ButtonProgressInnerProps extends ButtonProgressProps, WithStyles<ButtonProgressClassKey> {}

export const ButtonProgressComponent: React.FunctionComponent<ButtonProgressInnerProps> = ({
    showProgress,
    classes,
}) => {
    return showProgress ? <CircularProgress size={24} className={classes.buttonProgress} /> : null;
};
