import type { HistoryTrailerEntry } from './models';

export const HistoryTrailerEquals = (rowA?: HistoryTrailerEntry, rowB?: HistoryTrailerEntry): boolean => {
    if (!rowA || !rowB) {
        return false;
    }

    const trailerEquals = rowA.trailerEvent.trailerEventId === rowB.trailerEvent.trailerEventId;

    return trailerEquals;
};
