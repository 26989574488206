import type { FC } from 'react';
import { createRef, useEffect, useState } from 'react';

import { isNil } from '~/libs/utility';

import { MAX_HEIGHT, MIN_HEIGHT } from './consts';
import type { DividerProps, SplitViewProps, TopPaneProps } from './models';
import { useStyles } from './styles';

const TopPane: FC<TopPaneProps> = (props) => {
    const { children, classStyle, topHeigth, setTopHeigth } = props;

    const topRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (topRef.current) {
            if (!topHeigth) {
                setTopHeigth(topRef.current.clientHeight);
                return;
            }

            topRef.current.style.height = `${topHeigth}px`;
        }
    }, [topRef, topHeigth, setTopHeigth]);

    return (
        <div ref={topRef} className={classStyle}>
            {children}
        </div>
    );
};

const Divider: FC<DividerProps> = (props) => {
    const { onMouseDown, onTouchStart, onMouseUp, classes } = props;

    return (
        <div
            className={classes.dividerHitbox}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            onTouchEnd={onMouseUp}
        >
            <div className={classes.divider} />
        </div>
    );
};

const SplitView: FC<SplitViewProps> = (props) => {
    const { children } = props;

    const [topHeigth, setTopHeigth] = useState<number>(MAX_HEIGHT);
    const [separatorYPosition, setSeparatorYPosition] = useState<undefined | number>(undefined);
    const [dragging, setDragging] = useState(false);
    const splitPaneRef = createRef<HTMLDivElement>();
    const classes = useStyles();

    const onMouseDown = (event: React.MouseEvent) => {
        setSeparatorYPosition(event.clientY);
        setDragging(true);
    };

    const onTouchStart = (event: React.TouchEvent) => {
        setSeparatorYPosition(event.touches[0].clientY);
        setDragging(true);
    };

    const onMove = (clientY: number) => {
        if (dragging && topHeigth && separatorYPosition) {
            const newTopHeigth = topHeigth + clientY - separatorYPosition;
            setSeparatorYPosition(clientY);

            if (newTopHeigth < MIN_HEIGHT || newTopHeigth > MAX_HEIGHT) {
                return;
            }

            if (splitPaneRef.current) {
                const splitPaneheigth = splitPaneRef.current.clientHeight;

                if (newTopHeigth > splitPaneheigth - MIN_HEIGHT) {
                    setTopHeigth(splitPaneheigth - MIN_HEIGHT);
                    return;
                }
            }

            setTopHeigth(newTopHeigth);
        }
    };

    const onMouseMove = (e: MouseEvent) => {
        e.preventDefault();
        onMove(e.clientY);
    };

    const onTouchMove = (e: TouchEvent) => {
        onMove(e.touches[0].clientY);
    };

    const onMouseUp = () => {
        setDragging(false);
    };

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('mouseup', onMouseUp);

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('touchmove', onTouchMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    });

    if (isNil(children.top)) {
        return children.bottom;
    }

    return (
        <div className={classes.root} ref={splitPaneRef}>
            <TopPane classStyle={classes.topPane} topHeigth={topHeigth} setTopHeigth={setTopHeigth}>
                {children.top}
            </TopPane>
            <Divider classes={classes} onMouseDown={onMouseDown} onTouchStart={onTouchStart} onMouseUp={onMouseUp} />
            {children.bottom}
        </div>
    );
};
SplitView.displayName = 'SplitView';

export { Divider, TopPane, SplitView };
