import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import type { SingleValueProps as ReactSelectSingleValueProps } from 'react-select/src/components/SingleValue';

import type { Option } from '../../models';

import type { SingleValueClassKey } from './styles';

export interface SingleValueProps<T> extends ReactSelectSingleValueProps<Option<T>> {}

export interface SingleValueInnerProps<T> extends SingleValueProps<T>, WithStyles<SingleValueClassKey> {}

export const SingleValueComponentFactory: <T>(
    CustomChildComponent?: React.ComponentType<{ option: Option<T> }>
) => React.FC<SingleValueInnerProps<T>> =
    (CustomChildComponent) =>
    ({ classes, children, getValue }) => {
        const value = getValue();
        const option = value instanceof Array ? value[0] : value;

        if (!option) {
            return null;
        }

        return (
            <div className={classes.root} data-id="value-container">
                {CustomChildComponent ? (
                    <CustomChildComponent option={option}>{children}</CustomChildComponent>
                ) : (
                    children
                )}
            </div>
        );
    };
