import type { ChangelogEntry } from '~/common/components/ChangelogsSubpage/models';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { ActionTypes as RtdsSchedulesActionTypes } from '~/data/rtdsschedules';
import { ActionTypeKeys as RtdsSchedulesActionTypeKeys } from '~/data/rtdsschedules';
import type { RetrievableData } from '~/reducers';
import type { ResolvedRtdsScheduleChangelogChanges, RtdsScheduleChangelog } from '~/services/ApiClient';
import type { BaseAction } from '~/types';

import { resolveRtdsScheduleChangelogsEntries } from './reducers.resolveRtdsScheduleChangelogsEntries';

export interface RtdsScheduleChangelogsStoreState {
    rtdsSchedulesChangelogsEntries: ChangelogEntry<ResolvedRtdsScheduleChangelogChanges>[];
    rtdsSchedulesChangelogs: RetrievableData<RtdsScheduleChangelog[]>;
    pendingChangelogs?: number;
    previousChangelog?: number;
}

const defaultrtdsScheduleChangelogsState: RtdsScheduleChangelogsStoreState = {
    rtdsSchedulesChangelogs: {
        data: [],
        pending: false,
        fulfilled: false,
        rejected: false,
    },
    rtdsSchedulesChangelogsEntries: [],
    pendingChangelogs: undefined,
};

export const rtdsScheduleChangelogsReducer = (
    state: RtdsScheduleChangelogsStoreState = defaultrtdsScheduleChangelogsState,
    action: RtdsSchedulesActionTypes | BaseAction<''>,
    settingsStoreState: SettingsStoreState
): RtdsScheduleChangelogsStoreState => {
    switch (action.type) {
        case RtdsSchedulesActionTypeKeys.RTDSSCHEDULESCHANGELOGS_GET_PENDING:
            return {
                ...state,
                rtdsSchedulesChangelogs: {
                    ...state.rtdsSchedulesChangelogs,
                    pending: true,
                },
                pendingChangelogs: action.meta,
                previousChangelog: state.pendingChangelogs ?? action.meta,
            };

        case RtdsSchedulesActionTypeKeys.RTDSSCHEDULESCHANGELOGS_GET_REJECTED: {
            if (state.pendingChangelogs !== action.meta) {
                return state;
            }

            return {
                ...state,
                rtdsSchedulesChangelogsEntries: [],
                rtdsSchedulesChangelogs: {
                    data: [],
                    pending: false,
                    fulfilled: false,
                    rejected: true,
                },
                pendingChangelogs: undefined,
            };
        }
        case RtdsSchedulesActionTypeKeys.RTDSSCHEDULESCHANGELOGS_GET_FULFILLED: {
            if (state.pendingChangelogs !== action.meta) {
                return state;
            }
            return {
                ...state,
                rtdsSchedulesChangelogsEntries: resolveRtdsScheduleChangelogsEntries(
                    action.payload.items,
                    settingsStoreState
                ),
                rtdsSchedulesChangelogs: {
                    data: action.payload.items,
                    fulfilled: true,
                    pending: false,
                    rejected: false,
                },
                pendingChangelogs: undefined,
                previousChangelog: action.meta,
            };
        }

        default:
            return state;
    }
};
