import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import type { LoginStatus } from '~/services/ApiClient';
import { formatDriverHoursLoginStatus } from '~/services/Formatters';

export interface DriverHoursLoginStatusFormatterProps {
    t: SingleTFunction;
    value?: LoginStatus;
}

export interface DriverHoursLoginStatusFormatterInnerProps extends DriverHoursLoginStatusFormatterProps {}

const DriverHoursLoginStatusFormatter: React.FC<DriverHoursLoginStatusFormatterInnerProps> = ({ t, value }) => {
    if (value) {
        return <>{formatDriverHoursLoginStatus(t, value)}</>;
    }

    return null;
};
DriverHoursLoginStatusFormatter.displayName = 'DriverHoursLoginStatusFormatter';

export { DriverHoursLoginStatusFormatter };
