import type { Driver, Trailer, Vehicle } from '~/common';
import { getValueFromHash } from '~/common';
import type { NumericDictionary } from '~/libs/utility';
import { uuidv4 } from '~/libs/utility';

import type { QueryAssetsNearbyPositionsResolved, QueryAssetsNearbyPositionsResponse } from './models';

const getAssetNearByListResolver = (
    source: QueryAssetsNearbyPositionsResponse[],
    staticData: NumericDictionary<Driver | Trailer | Vehicle>
): QueryAssetsNearbyPositionsResolved[] =>
    source.flatMap(({ assetReference, positions }) => {
        const asset = getValueFromHash(staticData, assetReference.id);
        return asset
            ? positions.map((position) => ({
                  ...position,
                  id: uuidv4(),
                  asset,
              }))
            : [];
    });

export { getAssetNearByListResolver };
