import { Button, CircularProgress, Typography } from '@mui/material';
import type { PropsWithChildren, ReactElement } from 'react';
import { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { countDirtyFields } from '~/common';
import { CloseIcon } from '~/components/Icons';
import { TitledIconButton } from '~/components/TitledIconButton';
import { reportError } from '~/reporting';
import { ServerResultStatus } from '~/services/ApiClient';

import { ConfirmationDialog } from '../ConfirmationDialog';
import type { TopBannerProps } from '../TopBanner';
import { BannerType, TopBanner } from '../TopBanner';
import { WidgetDialog } from '../WidgetDialog';

import type { FormWidgetDialogProps } from './models';
import { useStyles } from './styles';

const FormWidgetDialog = <T extends FieldValues>(props: PropsWithChildren<FormWidgetDialogProps<T>>): ReactElement => {
    const {
        title,
        submitText,
        onClose,
        resolver,
        onSubmitSuccess,
        initialFormState,
        open,
        testId,
        submitForm,
        children,
    } = props;

    const { t } = useTranslation();
    const classes = useStyles(props);
    const formId = 'form-dialog';
    const [topBanner, setTopBanner] = useState<Omit<TopBannerProps, 'onDismiss'> | undefined>();
    const [openDiscardChangesDialog, setOpenDiscardChangesDialog] = useState<boolean>(false);
    const [serverResultStatus, setServerResultStatus] = useState<ServerResultStatus | undefined>();
    const form = useForm<T>({ defaultValues: initialFormState, resolver });
    const { isValid, isSubmitted } = form.formState;
    const dirtyFieldsCount = countDirtyFields(form.formState.dirtyFields);

    useEffect(() => {
        // Update default values when initialFormState changes
        form.reset(initialFormState);
    }, [form, initialFormState]);

    useEffect(() => {
        if (!serverResultStatus) {
            if (isSubmitted && !isValid) {
                setTopBanner({
                    title: t('topbanner-message-validation-errors'),
                    dataId: 'form-dialog-banner-form-validation-errors',
                    type: BannerType.Error,
                });
            } else if (dirtyFieldsCount > 0) {
                setTopBanner({
                    title: t('topbanner-message-fields-changed', {
                        fieldCount: dirtyFieldsCount,
                    }),
                    dataId: 'form-dialog-banner-fields-changed',
                    type: BannerType.Warning,
                });
            } else {
                setTopBanner(undefined);
            }
        }
    }, [dirtyFieldsCount, isSubmitted, isValid, t, serverResultStatus]);

    const closeForm = () => {
        onClose?.();
        setServerResultStatus(undefined);
    };

    const cancelHandler = () => {
        if (dirtyFieldsCount) {
            setOpenDiscardChangesDialog(true);
        } else {
            closeForm();
        }
    };

    const submitHandler = async (data: T): Promise<void> => {
        setServerResultStatus(ServerResultStatus.PENDING);
        setTopBanner(undefined);

        let result = ServerResultStatus.SERVERERROR;
        try {
            result = await submitForm(data);
        } catch (ex) {
            reportError(ex);
        }

        setServerResultStatus(result);

        switch (result) {
            case ServerResultStatus.OK:
            case ServerResultStatus.ACCEPTED:
                onSubmitSuccess?.();
                closeForm();
                break;

            case ServerResultStatus.BADREQUEST:
                setTopBanner({
                    title: t('server-message-bad-request'),
                    dataId: 'form-dialog-banner-server-warning',
                    type: BannerType.Warning,
                });
                break;

            case ServerResultStatus.SERVERERROR:
                setTopBanner({
                    title: t('server-message-internal-error'),
                    dataId: 'form-dialog-banner-server-error',
                    type: BannerType.Error,
                });
                break;

            default:
                setTopBanner(undefined);
                break;
        }
    };

    const headerIcon = (
        <TitledIconButton
            title={t('close')}
            disabled={serverResultStatus === ServerResultStatus.PENDING}
            placement="bottom-end"
            color="inherit"
            onClick={cancelHandler}
            data-id="dialog-header-icon"
        >
            <CloseIcon />
        </TitledIconButton>
    );

    const actionButtons = (
        <>
            <Button
                color="secondary"
                disabled={serverResultStatus === ServerResultStatus.PENDING}
                onClick={cancelHandler}
                data-id="cancel"
            >
                {t('cancel')}
            </Button>
            <Button
                data-testid="save"
                disabled={dirtyFieldsCount === 0 || serverResultStatus === ServerResultStatus.PENDING}
                variant="contained"
                color="secondary"
                type="submit"
                form={formId}
                className={classes.actionButton}
            >
                {serverResultStatus === ServerResultStatus.PENDING && (
                    <CircularProgress size={30} className={classes.backDrop} />
                )}
                {submitText ?? t('save')}
            </Button>
        </>
    );

    return (
        <>
            <ConfirmationDialog
                dataId="confirm-close-form-dialog"
                title={t('asset-editor-close-confirmation-title')}
                open={openDiscardChangesDialog}
                confirmationActionText={t('discard')}
                onConfirm={closeForm}
                onCancel={() => setOpenDiscardChangesDialog(false)}
            >
                <Typography>{t('asset-editor-close-confirmation-prompt')}</Typography>
            </ConfirmationDialog>

            <WidgetDialog
                title={title}
                testId={testId}
                open={open}
                dialogActions={actionButtons}
                headerActions={[headerIcon]}
            >
                <FormProvider {...form}>
                    {topBanner && <TopBanner {...topBanner} onDismiss={() => setTopBanner(undefined)} />}
                    <form onSubmit={form.handleSubmit(submitHandler)} id={formId} className={classes.form}>
                        {children}
                    </form>
                </FormProvider>
            </WidgetDialog>
        </>
    );
};

FormWidgetDialog.displayName = 'FormWidgetDialog';
export { FormWidgetDialog };
