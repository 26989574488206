import type { SvgIconProps } from '@mui/material';
import type { ClassNameMap } from '@mui/styles';
import memoizeOne from 'memoize-one';
import * as React from 'react';

import type { SectionDefinition } from '~/components/Sections';
import type { PositionGroup, PositionStatus } from '~/services/ApiClient';

import { SummarySection } from './components/SummarySection';
import { TimelineSection } from './components/TimelineSection';
import type { TimelineItemClassKey } from './components/TimelineSection/components/PositionGroupsContainer/components/TimelineItem/styles';
import { SectionName } from './constants';
import type { HistorySubpageUserPreferences } from './preferences';

export const getSections = (
    preferences: HistorySubpageUserPreferences,
    toggleCollapsedState: (sectionName: SectionName) => () => void,
    AssetIcon: React.ComponentType<SvgIconProps>,
    positionGroups: PositionGroup[],
    stopDate: Date,
    startDate: Date,
    retrievePositions: (startDate: Date, stopDate: Date) => Promise<PositionStatus[]>,
    timelineClasses?: Partial<ClassNameMap<TimelineItemClassKey>>
): SectionDefinition[] => {
    return [
        {
            name: SectionName.TIMELINE,
            content: (dragHandleElement: JSX.Element) => (
                <TimelineSection
                    AssetIcon={AssetIcon}
                    isCollapsed={preferences.collapsedSections.includes(SectionName.TIMELINE)}
                    toggleCollapsed={toggleCollapsedState(SectionName.TIMELINE)}
                    dragHandleElement={dragHandleElement}
                    stopDate={stopDate}
                    startDate={startDate}
                    positionGroups={positionGroups}
                    retrievePositions={retrievePositions}
                    timelineClasses={timelineClasses}
                />
            ),
        },
        {
            name: SectionName.SUMMARY,
            content: (dragHandleElement: JSX.Element) => (
                <SummarySection
                    positionGroups={positionGroups}
                    isCollapsed={preferences.collapsedSections.includes(SectionName.SUMMARY)}
                    toggleCollapsed={toggleCollapsedState(SectionName.SUMMARY)}
                    dragHandleElement={dragHandleElement}
                />
            ),
        },
    ];
};

export const getUnauthorizedSections = (includeSummarySection: boolean): SectionName[] => {
    const result = [];

    if (!includeSummarySection) {
        result.push(SectionName.SUMMARY);
    }

    return result;
};

export const getSectionsMemoized = memoizeOne(getSections);
