import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import type { Container, PojoModel } from '~/services/ApiClient';
import { formatContainerName } from '~/services/Formatters';

export interface ContainerNameFormatterProps {
    value?: PojoModel<Container>;
}

export interface ContainerNameFormatterInnerProps extends ContainerNameFormatterProps {}

const ContainerNameFormatter: React.FunctionComponent<ContainerNameFormatterInnerProps> = ({ value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatContainerName(value)}</>;
};
ContainerNameFormatter.displayName = 'ContainerNameFormatter';

export { ContainerNameFormatter };
