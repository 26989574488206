import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type AnimatedTableRowClassKey = 'updateAnimation' | 'highlighted';

export const animationDuration = 750;

export const AnimatedTableRowStyleRules: StyleRulesCallback<Theme, {}, AnimatedTableRowClassKey> = (theme: Theme) =>
    createStyles({
        updateAnimation: {
            animation: `$fadeIn ${animationDuration}ms`,
            animationIterationCount: 1,
            backgroundColor: theme.palette.background.paper,
        },
        '@keyframes fadeIn': {
            '0%': {
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        },
        highlighted: {
            backgroundColor: theme.palette.action.hover,
        },
    });
