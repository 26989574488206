import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ActivitySurfaceInnerProps, ActivitySurfaceProps } from './component';
import { ActivitySurfaceComponent } from './component';
import { ActivitySurfaceStyleRules } from './styles';

export const ActivitySurface = compose<ActivitySurfaceInnerProps, ActivitySurfaceProps>(
    setDisplayName('ActivitySurface'),
    withStyles(ActivitySurfaceStyleRules)
)(ActivitySurfaceComponent);
