import { Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { VehicleNameFormatter, adaptiveDateTimeFormatterFactory } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import type { DisplayUserPreferencesVehicleDisplayFormat } from '~/services/ApiClient';
import { IncomingMessage, IncomingMessageStatus } from '~/services/ApiClient';
import { formatDateTime } from '~/services/Formatters';

import type { ConversationSummaryEntry } from '../../../../../../conversationSummaryEntry';

import type { ConversationSummaryFirstLineClassKey } from './styles';

export interface ConversationSummaryFirstLineProps {
    conversationSummaryEntry: ConversationSummaryEntry;
    vehicleDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat;
}

export interface ConversationSummaryFirstLineInnerProps
    extends ConversationSummaryFirstLineProps,
        InjectedTranslationProps,
        WithStyles<ConversationSummaryFirstLineClassKey> {}

export const ConversationSummaryFirstLineComponent: React.FC<ConversationSummaryFirstLineInnerProps> = ({
    t,
    classes,
    conversationSummaryEntry,
    vehicleDisplayFormat,
}) => {
    const { conversationSummary } = conversationSummaryEntry;

    const vehicleDisplayNameClassName = classNames(classes.vehicleDisplayName, {
        [classes.vehicleDisplayNameHighlighted]:
            conversationSummary.lastMessage?.value instanceof IncomingMessage &&
            conversationSummary.lastMessage.value.status === IncomingMessageStatus.Received,
    });

    const AdaptiveDateTimeFormatter = adaptiveDateTimeFormatterFactory();
    const timestamp = conversationSummary.lastMessage && (
        <Typography className={classes.timestamp} variant="caption" color="textSecondary" data-id="timestamp">
            <AdaptiveDateTimeFormatter t={t} value={conversationSummary.lastMessage.value.dateTime} />
        </Typography>
    );

    const fullTimeStamp =
        conversationSummary.lastMessage && formatDateTime(conversationSummary.lastMessage.value.dateTime);

    return (
        <div className={classes.root}>
            <Typography
                className={vehicleDisplayNameClassName}
                variant="caption"
                color="textPrimary"
                data-id="vehicle-display-name"
            >
                <TitledTruncatedText>
                    <VehicleNameFormatter
                        value={conversationSummaryEntry.vehicle}
                        vehicleDisplayNameFormat={vehicleDisplayFormat}
                    />
                </TitledTruncatedText>
            </Typography>
            <Tooltip title={fullTimeStamp ?? ''}>
                <span>{timestamp}</span>
            </Tooltip>
        </div>
    );
};
