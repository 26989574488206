import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

import type { VehicleCategory } from '../ApiClient';

const formatVehicleCategoryInternal = (t: SingleTFunction, value: VehicleCategory) =>
    t(`vehicle-category-${value.key.toLocaleLowerCase()}`);

const formatVehicleCategoryMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((value: VehicleCategory) => formatVehicleCategoryInternal(t, value))
);

export const formatVehicleCategory = (t: SingleTFunction, value: VehicleCategory): string =>
    formatVehicleCategoryMemoized(t)(value);
