import type { LocationDescriptor } from 'history';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import type { InjectedFeatureFlagsProps } from '~/components/FeatureFlags';
import { useTranslation } from '~/components/LanguageSelector';

import type { MenuItem } from '../../models';
import { getMiscellaneousMenuItems, getModuleMenuItems } from '../../moduleMenuItems';

export interface LandingPageProps {}

export interface LandingPageInnerProps extends LandingPageProps, InjectedFeatureFlagsProps {}

const getFirstLocationDescriptor = (items: MenuItem[]): LocationDescriptor | undefined => {
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.to) {
            return item.to;
        }
        if (item.nestedItems) {
            const nestedLocationDescriptor = getFirstLocationDescriptor(item.nestedItems);
            if (nestedLocationDescriptor) {
                return nestedLocationDescriptor;
            }
        }
    }

    return undefined;
};

export const LandingPageComponent: React.FC<LandingPageInnerProps> = ({ featureFlags }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const securables = useSelector(settingDataSelector(SettingsKey.SECURABLES));

    const target =
        getFirstLocationDescriptor(getModuleMenuItems({ t, securables, featureFlags })) ??
        getFirstLocationDescriptor(getMiscellaneousMenuItems({ t, securables, dispatch, featureFlags }));

    if (!target) {
        throw new Error('No module to navigate to');
    }

    return <Redirect to={target} />;
};
