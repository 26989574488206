import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type * as i18next from 'i18next';
import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import { ViolationType } from '~/services/ApiClient';

import type { ViolationGroupClassKey } from './styles';

export interface ViolationGroupFormatterProps<T> extends i18next.WithT {
    value?: T;
    getViolationType: ((value: T) => ViolationType) | undefined;
}

export interface ViolationGroupFormatterInnerProps<T>
    extends ViolationGroupFormatterProps<T>,
        WithStyles<ViolationGroupClassKey> {}

export const ViolationGroupFormatterComponent = <T,>(props: ViolationGroupFormatterInnerProps<T>): JSX.Element => {
    const { t, classes, value, getViolationType } = props;

    let displayValue = t('violation-group-no-data');
    let dataId = 'violation-group-no-data';
    let className = classes.root;

    if (!isUndefined(value)) {
        displayValue = t('violation-group-normal');
        dataId = 'violation-group-normal';

        if (!isUndefined(getViolationType)) {
            const violationType = getViolationType(value);
            if (violationType === ViolationType.Warning) {
                displayValue = t('violation-group-warnings');
                dataId = 'violation-group-warnings';
                className = classNames(classes.root, [classes.warning]);
            } else if (violationType === ViolationType.Violation) {
                displayValue = t('violation-group-violations');
                dataId = 'violation-group-violations';
                className = classNames(classes.root, [classes.violation]);
            }
        }
    }

    return (
        <span className={className} data-id={dataId}>
            {displayValue}
        </span>
    );
};
