import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { Classification } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';

import { formatClassification } from './formatClassification';

export const formatOrdinalTypeClassification = (
    t: SingleTFunction,
    classification: Classification | undefined
): string => {
    if (!isUndefined(classification)) {
        return formatClassification(t, ClassificationType.RtdsOrdinalType, classification);
    }

    return '';
};
