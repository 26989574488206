import type { AssetReference, ResolvedConversationSummary } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';

export const filterConversationSummaryForSelectedVehicle = (
    conversationSummaryList: ResolvedConversationSummary[],
    selectedAssets: AssetReference[] | undefined
): ResolvedConversationSummary[] => {
    const selectedVehicles = selectedAssets?.filter((asset) => asset.type === AssetType.Vehicle);
    if (selectedVehicles) {
        const selectedVehicleIds = selectedVehicles.map((selectedVehicle) => selectedVehicle.id);
        if (selectedVehicleIds.length > 0) {
            return conversationSummaryList.filter((it) => selectedVehicleIds.includes(it.vehicleId));
        } else {
            return [];
        }
    }

    return conversationSummaryList;
};
