import { createAction } from 'redux-actions';

import type { RouteStatus } from '~/services/ApiClient';
import { ApiClient, AssetType, retryableRequest } from '~/services/ApiClient';

import type { RouteStatusesPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const retrieveVehicleRouteStatusesExecutor = (): Promise<RouteStatusesPayload> => {
    return retryableRequest(() => ApiClient.getRouteStatuses(AssetType.Vehicle));
};

export const retrieveVehicleRouteStatusesAction = createAction(
    ActionTypePrefixes.ROUTESTATUS,
    retrieveVehicleRouteStatusesExecutor,
    () => AssetType.Vehicle
);

export const updateRouteStatusAction = createAction<RouteStatus>(ActionTypeKeys.ROUTESTATUS_UPDATE);
