import { useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import type { TooltipPayload } from 'recharts';
import { Area, Brush, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { findLastIndex, isUndefined } from '~/libs/utility';
import type { ChartPeriod } from '~/services/ApiClient';
import { formatDateTime, formatTime } from '~/services/Formatters';
import { calculateTicks } from '~/services/Graphs';
import { flattenStyleRules } from '~/services/React';
import { defaultComparer } from '~/services/Sorting';
import { formattedComparer } from '~/services/Sorting/comparers';

import type { ChartState, TimeSeriesChartDefinition } from '../../../../models';

import { ChartTooltip } from './components/ChartTooltip';
import { mapDataEntries } from './mapDataEntries';
import type { DataEntry } from './models';
import { TimeSeriesChartStyleRules } from './styles';

export interface TimeSeriesChartProps<T> {
    data: T[];
    chart: TimeSeriesChartDefinition<T>;
    chartState: ChartState;
    viewPeriod: ChartPeriod;
    onViewPeriodChange: (viewPeriod: ChartPeriod) => void;
}

export interface TimeSeriesChartInnerProps<T> extends TimeSeriesChartProps<T> {}

const TimeSeriesChart = <T,>(props: TimeSeriesChartInnerProps<T>): JSX.Element => {
    const { data, chart, chartState, viewPeriod, onViewPeriodChange: onViewPeriodChanged } = props;

    const sortedData = useMemo(() => mapDataEntries(chart, data), [data, chart]);

    const dataIndexes = useMemo(() => {
        const predicate = (d: DataEntry) => {
            return d.dt >= viewPeriod.startDate.getTime() && d.dt <= viewPeriod.stopDate.getTime();
        };

        const first = sortedData.findIndex(predicate);
        if (first === -1) {
            return undefined;
        }

        const last = findLastIndex(sortedData, predicate);

        return {
            startIndex: first,
            endIndex: last,
        };
    }, [sortedData, viewPeriod]);

    const setDataIndexes = useCallback(
        (indexes: { startIndex: number; endIndex: number }) => {
            onViewPeriodChanged({
                startDate: new Date(sortedData[indexes.startIndex].dt),
                stopDate: new Date(sortedData[indexes.endIndex].dt),
            });
        },
        [sortedData, onViewPeriodChanged]
    );

    const ticks = useMemo(() => {
        const start = dataIndexes ? sortedData[dataIndexes.startIndex] : sortedData[0];
        const stop = dataIndexes ? sortedData[dataIndexes.endIndex] : sortedData[sortedData.length - 1];

        const startDate = new Date(start.dt);
        const stopDate = new Date(stop.dt);

        return calculateTicks({ stop: stopDate, start: startDate, maxTickCount: 8 }).ticks.map((d) => d.getTime());
    }, [sortedData, dataIndexes]);

    const theme = useTheme();
    const styles = useMemo(
        () => flattenStyleRules({ styleRulesCallback: TimeSeriesChartStyleRules, theme, props: {} }),
        [theme]
    );

    const allSeries = useMemo(() => {
        return chart.series.map((s) => {
            switch (s.type) {
                case 'line':
                    return {
                        name: s.title,
                        dataKey: s.name,
                        el: (
                            <Line
                                type="monotone"
                                isAnimationActive={false}
                                name={s.title}
                                key={s.name}
                                dataKey={s.name}
                                unit={` ${s.unit}`}
                                dot={false}
                                activeDot={{ style: styles.activeDot }}
                                legendType="square"
                                stroke={s.color}
                                strokeWidth={styles.lineSeries.strokeWidth}
                                strokeOpacity={styles.lineSeries.strokeOpacity}
                            />
                        ),
                    };
                case 'area':
                    return {
                        name: s.title,
                        dataKey: s.name,
                        el: (
                            <Area
                                isAnimationActive={false}
                                name={s.title}
                                key={s.name}
                                dataKey={s.name}
                                legendType="none"
                                dot={false}
                                activeDot={false}
                                unit={` ${s.unit}`}
                                stroke={s.color}
                                strokeWidth={styles.areaSeries.strokeWidth}
                                strokeOpacity={styles.areaSeries.strokeOpacity}
                                fill={s.color}
                                fillOpacity={styles.areaSeries.fillOpacity}
                            />
                        ),
                    };
                default:
                    throw new Error('unknown series type');
            }
        });
    }, [styles, chart]);

    const selectedSeries = useMemo(() => {
        return allSeries.filter((s) => chartState.visibleSeries.includes(s.dataKey)).map((s) => s.el);
    }, [allSeries, chartState]);

    const tooltipLabelFormatter = useCallback((timestamp: number) => {
        return formatDateTime(new Date(timestamp));
    }, []);

    const valueFormatter = useCallback((value: number) => {
        return value.toFixed(1);
    }, []);

    const timeTickFormatter = useCallback((timestamp: number) => {
        return formatTime(new Date(timestamp));
    }, []);

    const tooltipFormatter = useCallback(
        (value: string | number | Array<string | number>) => {
            if (typeof value === 'number') {
                return valueFormatter(value);
            }

            if (Array.isArray(value)) {
                return value.map((v) => (isUndefined(v) && typeof v === 'number' ? valueFormatter(v) : v)).join(' - ');
            }

            throw new Error('Unable to format tooltip');
        },
        [valueFormatter]
    );

    const tooltipItemSorter = useMemo(() => {
        const seriesOrderByName: Record<string, number> = allSeries.reduce((acc, current, idx) => {
            acc[current.name] = idx;
            return acc;
        }, {});

        const idxLookup = (item: TooltipPayload) => !isUndefined(item.name) && seriesOrderByName[item.name];
        return formattedComparer(idxLookup, defaultComparer);
    }, [allSeries]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={sortedData}>
                <CartesianGrid />
                <Tooltip
                    isAnimationActive={false}
                    labelFormatter={tooltipLabelFormatter}
                    formatter={tooltipFormatter}
                    content={<ChartTooltip />}
                    cursor={{ style: styles.tooltipCursor }}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore is not properly typed from the library itself, should be (payload: T, payload: T) => number
                    itemSorter={tooltipItemSorter}
                />
                <Brush
                    dataKey="dt"
                    tickFormatter={timeTickFormatter}
                    onChange={setDataIndexes}
                    startIndex={dataIndexes?.startIndex}
                    endIndex={dataIndexes?.endIndex}
                />
                <YAxis
                    type="number"
                    domain={['dataMin - 1', 'dataMax + 1']}
                    tickFormatter={valueFormatter}
                    tickLine={false}
                    scale="linear"
                    unit={` ${chart.series[0].unit}`}
                />
                <XAxis
                    dataKey="dt"
                    domain={['dataMin', 'dataMax']}
                    allowDataOverflow
                    tickCount={5}
                    ticks={ticks}
                    type="number"
                    tickFormatter={timeTickFormatter}
                    scale="time"
                />
                {selectedSeries}
            </ComposedChart>
        </ResponsiveContainer>
    );
};
TimeSeriesChart.displayName = 'TimeSeriesChart';

export { TimeSeriesChart };
