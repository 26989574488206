import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { LeftPaneInnerProps, LeftPaneOuterProps } from './component';
import { LeftPaneComponent } from './component';
import { LeftPaneStyleRules } from './styles';

export const LeftPane = compose<LeftPaneInnerProps, LeftPaneOuterProps>(
    setDisplayName('LeftPane'),
    withTranslation(),
    withStyles(LeftPaneStyleRules)
)(LeftPaneComponent);
