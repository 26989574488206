import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { RestSectionInnerProps, RestSectionProps } from './component';
import { RestSectionComponent } from './component';

export const RestSection = compose<RestSectionInnerProps, RestSectionProps>(
    setDisplayName('RestSection'),
    withTranslation()
)(RestSectionComponent);
