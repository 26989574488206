import { Typography } from '@mui/material';
import type { FC } from 'react';

import { WeightFormatter } from '~/components/Formatters';
import { EbsIcon } from '~/components/Icons';
import { ReorderableSection, SectionContentMessage } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';

import { EbsSectionContent } from './components/EbsSectionContent';
import type { EbsSectionInnerProps } from './models';

const EbsSectionComponent: FC<EbsSectionInnerProps> = ({
    t,
    classes,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
    displayPreferences,
    ebs,
}) => {
    const axleWeight = ebs?.axleLoadSum;

    const collapsedHeader = !isUndefined(axleWeight) ? (
        <div className={classes.collapsedHeaderWrapper}>
            <Typography variant="caption" color="textSecondary">
                <EbsIcon fontSize="inherit" />
            </Typography>
            <Typography variant="body2" data-id="weight" className={classes.weight}>
                <WeightFormatter unitSystem={displayPreferences.unitSystem} value={axleWeight} precision={0} />
            </Typography>
        </div>
    ) : undefined;

    return (
        <ReorderableSection
            dataId="ebs"
            title={t('ebs')}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            rightHeaderElement={collapsedHeader}
        >
            {!isUndefined(ebs) ? (
                <EbsSectionContent ebs={ebs} />
            ) : (
                <SectionContentMessage dataId="no-ebs-message">{t('ebs-not-available')}</SectionContentMessage>
            )}
        </ReorderableSection>
    );
};

export { EbsSectionComponent };
