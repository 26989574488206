import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { TicksInnerProps, TicksProps } from './component';
import { TicksComponent } from './component';
import { TicksStyleRules } from './styles';

export const Ticks = compose<TicksInnerProps, TicksProps>(
    setDisplayName('Ticks'),
    withStyles(TicksStyleRules)
)(TicksComponent);
