import { InputAdornment, TextField, Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import { KEY_0, KEY_9, KEY_C, KEY_DECIMAL, KEY_NUMPAD0, KEY_NUMPAD9, KEY_PERIOD, KEY_V } from 'keycode-js';
import * as React from 'react';
import type { ControllerRenderProps, FieldErrors } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getValueByPath } from '~/common';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNaN, isNil, isUndefined } from '~/libs/utility';

import type { BaseProps } from '../model';

import { ALLOWED_KEYS } from './consts';
import type { NumericInputClassKey } from './styles';

export interface NumericInputProps extends BaseProps<number | null> {
    minValue?: number;
    maxValue?: number;
    adornment?: string;
    leftAlignment?: boolean;
    supportsDecimals?: boolean;
    decimalPrecision?: number;
    requiredText?: string;
}

export interface NumericInputInnerProps
    extends NumericInputProps,
        InjectedTranslationProps,
        WithStyles<NumericInputClassKey> {}

export const NumericInputComponent: React.FC<NumericInputInnerProps> = (props) => {
    const {
        label,
        dataId,
        minValue,
        maxValue,
        required,
        requiredText,
        adornment,
        leftAlignment,
        supportsDecimals,
        decimalPrecision,
        fieldName,
        classes,
        className,
        defaultValue,
        disabled,
        onValueChanged,
        markValueChange,
        hasError,
        errorText,
        validationRules,
        disabledReason,
        onBlurChanged,
    } = props;

    const { t } = useTranslation();
    const supportsDecimalsWithFallback = supportsDecimals ?? false;
    const decimalPrecisionWithFallback = decimalPrecision ?? 2;
    const { control, formState } = useFormContext();
    const fieldError = getValueByPath<unknown, FieldErrors | undefined>(formState.errors, fieldName);
    const error = !!fieldError || hasError;
    const isDirtyField = Boolean(getValueByPath(formState.dirtyFields, fieldName));
    const requiredMessage = requiredText ?? t('wf-field-error-required');
    const helperText = hasError && !isUndefined(errorText) ? errorText : fieldError ? fieldError.message : undefined;
    const rules = React.useMemo(
        () => ({
            required: { value: required ?? false, message: requiredMessage },
            min: !isUndefined(minValue)
                ? {
                      value: minValue,
                      message: isUndefined(maxValue)
                          ? t('wf-field-error-min-value', { minValue })
                          : t('wf-field-error-range', { maxValue, minValue }),
                  }
                : undefined,
            max: !isUndefined(maxValue)
                ? {
                      value: maxValue,
                      message: isUndefined(minValue)
                          ? t('wf-field-error-max-value', { maxValue })
                          : t('wf-field-error-range', { maxValue, minValue }),
                  }
                : undefined,
            ...validationRules,
        }),
        [maxValue, minValue, required, requiredMessage, validationRules, t]
    );

    const onKeyDownHandler = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const keyDown = event.keyCode;

            /** Check if the pressed key is a number. */
            const isNumberKey = keyDown >= KEY_0 && keyDown <= KEY_9;
            /** Check if the pressed key is a key on the numeric keypad. */
            const isNumpadKey = keyDown >= KEY_NUMPAD0 && keyDown <= KEY_NUMPAD9;
            /** Check if the pressed key is a decimal point (if supported). */
            const isDecimalKey = supportsDecimalsWithFallback && (keyDown === KEY_PERIOD || keyDown === KEY_DECIMAL);
            /** Check if the pressed key is Ctrl+C (Cmd+C) / Ctrl+V (Cmd+V) combination. */
            const isCmdCtrlKey = (event.metaKey || event.ctrlKey) && (keyDown === KEY_C || keyDown === KEY_V);

            /** If the pressed key is not in the allowedKeys array and satisfies none of the above conditions, prevent the input action. */
            if (!ALLOWED_KEYS.includes(keyDown) && !isNumberKey && !isNumpadKey && !isDecimalKey && !isCmdCtrlKey) {
                event.preventDefault();
            }
        },
        [supportsDecimalsWithFallback]
    );

    const notifyChange = React.useCallback(
        (value: string, callbackFn?: (v: number | undefined) => void) => {
            if (!callbackFn) {
                return;
            }

            const rawNumberValue = parseFloat(value);
            const convertedNumberValue = supportsDecimalsWithFallback
                ? Number(rawNumberValue.toFixed(decimalPrecisionWithFallback))
                : Math.trunc(rawNumberValue);

            if (isNil(convertedNumberValue) || isNaN(convertedNumberValue)) {
                callbackFn(undefined);
                return;
            }
            callbackFn(convertedNumberValue);
        },
        [decimalPrecisionWithFallback, supportsDecimalsWithFallback]
    );

    const onChangeHandler = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: ControllerRenderProps) => {
            notifyChange(event.currentTarget.value, (value) => {
                field.onChange(value ?? null);
                if (onValueChanged) {
                    onValueChanged(value ?? null);
                }
            });
        },
        [onValueChanged, notifyChange]
    );

    const onBlurHandler = React.useCallback(
        (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, field: ControllerRenderProps) => {
            notifyChange(event.currentTarget.value, (value) => {
                if (onBlurChanged) {
                    onBlurChanged(value ?? null);
                } else {
                    field.onBlur();
                }
            });
        },
        [onBlurChanged, notifyChange]
    );

    const baseComponent = (
        <Controller
            name={fieldName}
            defaultValue={defaultValue}
            control={control}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            rules={rules}
            render={({ field }) => (
                <TextField
                    {...field}
                    value={isNil(field.value) ? null : field.value}
                    type="number"
                    fullWidth
                    data-id={dataId}
                    data-testid={dataId}
                    className={className}
                    label={label}
                    size="small"
                    InputProps={{
                        classes: {
                            input: !leftAlignment ? classes.centerAlignment : classes.leftAlignment,
                            root: isUndefined(markValueChange) || !markValueChange ? undefined : classes.outlinedInput,
                            ...(markValueChange &&
                                !error &&
                                isDirtyField && {
                                    notchedOutline: classes.changedNotchedOutline,
                                    focused: classes.focusChangedNotchedOutline,
                                }),
                        },
                        endAdornment: adornment && <InputAdornment position="end">{adornment}</InputAdornment>,
                    }}
                    variant="outlined"
                    onChange={(e) => onChangeHandler(e, field)}
                    onBlur={(e) => onBlurHandler(e, field)}
                    onKeyDown={onKeyDownHandler}
                    required={required}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                />
            )}
        />
    );

    return disabledReason && disabled ? (
        <Tooltip title={disabledReason} data-id={`input-tooltip-${dataId}`} placement="right">
            <span>{baseComponent}</span>
        </Tooltip>
    ) : (
        baseComponent
    );
};
