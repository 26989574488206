import type { Position } from '~/services/ApiClient';

export interface FieldValueDictionary {
    [fieldId: number]: string | number | boolean | Date | Position | null;
}

export enum SendingMessageDisabledReason {
    NotAllowed = 1,
    VehicleMesagingServiceDisabled = 2,
    NotSupportedByDevice = 3,
}
