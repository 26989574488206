import type { DisplayUserPreferencesTrailerDisplayFormat } from '~/services/ApiClient';
import { formatTrailerName } from '~/services/Formatters';

import type { HistoryTrailerEntry } from '../../../../models';

export const getTrailerDisplayNameFactory =
    (trailerDisplayNameFormat: DisplayUserPreferencesTrailerDisplayFormat) =>
    (entry: HistoryTrailerEntry): string | undefined => {
        return formatTrailerName(entry.trailer ?? entry.trailerEvent.trailer, trailerDisplayNameFormat);
    };
