import type { ComponentEnhancer } from 'react-recompose';
import { compose, withProps } from 'react-recompose';
import type { Subtract } from 'utility-types';

export interface InjectedAppVersionProps {
    appVersion: string;
}

export const withAppVersion = <WrappedProps extends InjectedAppVersionProps>(): ComponentEnhancer<
    WrappedProps,
    Subtract<WrappedProps, InjectedAppVersionProps>
> =>
    compose<WrappedProps, Subtract<WrappedProps, InjectedAppVersionProps>>(
        withProps({ appVersion: process.env.REACT_APP_VERSION })
    );
