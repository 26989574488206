import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const DefrostIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} width="24" height="24" viewBox="0 0 216.34 200">
            {/* tslint:disable:max-line-length */}
            <path d="M203.13 94.34H175.27l19.07-19.07a13.21 13.21 0 0 0-18.68-18.68L137.91 94.34H120.74V78.43l37.75-37.75A13.21 13.21 0 0 0 139.81 22L120.74 41.07V13.21a13.21 13.21 0 1 0-26.41 0V41.07L75.26 22A13.21 13.21 0 0 0 56.58 40.68L94.33 78.43V95.6H78.42L40.67 57.85A13.21 13.21 0 0 0 22 76.53L41.06 95.6H13.2A13.22 13.22 0 0 0 0 108.18H216.33c0-.22 0-.42 0-.63A13.2 13.2 0 0 0 203.13 94.34Z" />
            <path d="M130.81 177.36a22.64 22.64 0 0 1-45.28 0c0-12.51 22.64-54.09 22.64-54.09S130.81 164.85 130.81 177.36Z" />
            <path d="M183.14 161.13a15.85 15.85 0 1 1-31.7 0c0-8.75 15.85-37.86 15.85-37.86S183.14 152.38 183.14 161.13Z" />
            <path d="M33.2 161.13a15.85 15.85 0 0 0 31.7 0c0-8.75-15.85-37.86-15.85-37.86S33.2 152.38 33.2 161.13Z" />
        </SvgIcon>
    );
};
DefrostIcon.displayName = 'DefrostIcon';

export { DefrostIcon };
