import * as React from 'react';

import type { SectionDefinition } from '~/components/Sections';
import type { AssetType, ResolvedRtdsSchedule } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import type { RtdsSchedulesEntry } from '../../models';

import { RtdsScheduleDetails } from './components/RtdsScheduleDetails';
import { SectionName } from './constants';

export const getSections = (
    entry: RtdsSchedulesEntry,
    openScheduleEditor: (assetType: AssetType, isClone: boolean, schedule?: ResolvedRtdsSchedule) => void
): SectionDefinition[] => {
    return [
        {
            name: SectionName.RTDSSCHEDULEDETAILS,
            content: () => <RtdsScheduleDetails entry={entry} openScheduleEditor={openScheduleEditor} />,
        },
    ];
};

export const getSectionsMemoized = memoizeOne(getSections);
