import type { ServerResultStatus } from '~/services/ApiClient';
import type { BaseAction, Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    REQUESTPASSWORDRESET = 'REQUESTPASSWORDRESET',
    RESETPASSWORD = 'RESETPASSWORD',
    VALIDATERESETPASSWORD = 'VALIDATERESETPASSWORD',
}

export enum ActionTypeKeys {
    REQUESTPASSWORDRESET_PENDING = 'REQUESTPASSWORDRESET_PENDING',
    REQUESTPASSWORDRESET_FULFILLED = 'REQUESTPASSWORDRESET_FULFILLED',
    REQUESTPASSWORDRESET_REJECTED = 'REQUESTPASSWORDRESET_REJECTED',
    RESETPASSWORD_PENDING = 'RESETPASSWORD_PENDING',
    RESETPASSWORD_FULFILLED = 'RESETPASSWORD_FULFILLED',
    RESETPASSWORD_REJECTED = 'RESETPASSWORD_REJECTED',
    VALIDATERESETPASSWORD_PENDING = 'VALIDATERESETPASSWORD_PENDING',
    VALIDATERESETPASSWORD_FULFILLED = 'VALIDATERESETPASSWORD_FULFILLED',
    VALIDATERESETPASSWORD_REJECTED = 'VALIDATERESETPASSWORD_REJECTED',
    RESETPASSWORD_CLEAR_DATA = 'RESETPASSWORD_CLEAR_DATA',
}

export type ActionTypes =
    | Pending<ActionTypeKeys.REQUESTPASSWORDRESET_PENDING>
    | Fulfilled<ActionTypeKeys.REQUESTPASSWORDRESET_FULFILLED, ServerResultStatus>
    | Rejected<ActionTypeKeys.REQUESTPASSWORDRESET_REJECTED>
    | Pending<ActionTypeKeys.RESETPASSWORD_PENDING>
    | Fulfilled<ActionTypeKeys.RESETPASSWORD_FULFILLED, ServerResultStatus>
    | Rejected<ActionTypeKeys.RESETPASSWORD_REJECTED>
    | Pending<ActionTypeKeys.VALIDATERESETPASSWORD_PENDING>
    | Fulfilled<ActionTypeKeys.VALIDATERESETPASSWORD_FULFILLED, ServerResultStatus>
    | Rejected<ActionTypeKeys.VALIDATERESETPASSWORD_REJECTED>
    | BaseAction<ActionTypeKeys.RESETPASSWORD_CLEAR_DATA>;
