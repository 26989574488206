import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ScheduleEditorFormClassKey =
    | 'root'
    | 'body'
    | 'element'
    | 'elementNumericRecurrence'
    | 'elementEnumerationRecurrence'
    | 'elementRecurrenceMonthlyText'
    | 'elementRecurrenceMonthlyRadioButtonText'
    | 'checkboxContainer'
    | 'recurrenceDailyWeeklyContainer'
    | 'recurrenceMonthlyContainer'
    | 'recurrenceItem'
    | 'itemCheckbox'
    | 'recipientsListPadding'
    | 'radioFieldContainer'
    | 'recurrenceMonthlyFieldsContainer'
    | 'radioButtonsMarginBottom'
    | 'lastActivities';
export const ScheduleEditorFormStyleRules: StyleRulesCallback<Theme, {}, ScheduleEditorFormClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%',
            backgroundColor: theme.palette.common.white,
            fontSize: theme.typography.fontSize,
        },
        body: {
            backgroundColor: theme.palette.common.white,
            transform: 'translateX(+124px)',
            width: 750,
            marginTop: theme.spacing(4),
            marginRight: theme.spacing(35),
        },
        element: {
            width: 750,
            marginBottom: theme.spacing(3),
        },
        elementNumericRecurrence: {
            width: 70,
            marginTop: theme.spacing(-1),
        },
        elementEnumerationRecurrence: { width: 120, marginTop: theme.spacing(-1) },
        elementRecurrenceMonthlyText: {
            width: 70,
            display: 'inline-block',
            verticalAlign: 'middle',
            marginBottom: theme.spacing(3),
            marginRight: theme.spacing(2),
        },
        elementRecurrenceMonthlyRadioButtonText: {
            width: 60,
        },
        checkboxContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        recurrenceDailyWeeklyContainer: { display: 'flex', marginBottom: theme.spacing(3) },
        recurrenceMonthlyContainer: { display: 'flex', marginBottom: theme.spacing(1) },
        recurrenceMonthlyFieldsContainer: {
            display: 'flex',
            marginLeft: theme.spacing(-5.5),
            marginBottom: theme.spacing(2.5),
        },
        recurrenceItem: { marginRight: theme.spacing() },
        itemCheckbox: { flex: '1 0 50%' },
        recipientsListPadding: {
            padding: theme.spacing(1, 3),
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
            fontFamily: theme.typography.fontFamily,
            opacity: 1,
        },
        radioFieldContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        radioButtonsMarginBottom: { marginBottom: theme.spacing(1) },
        lastActivities: {
            width: 140,
            marginLeft: theme.spacing(-10),
        },
    });
