import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip } from '@mui/material';
import type { FC } from 'react';

import { useTranslation } from '~/components/LanguageSelector';

import type { CheckIconFormatterProps } from './models';

const CheckIconFormatter: FC<CheckIconFormatterProps> = (props) => {
    const { value } = props;
    const { t } = useTranslation();

    return (
        <Tooltip title={t(value ? 'check-icon-enabled' : 'check-icon-disabled')}>
            <span data-id={Boolean(value)}>{value ? <CheckIcon /> : <HighlightOffIcon />}</span>
        </Tooltip>
    );
};

CheckIconFormatter.displayName = 'CheckIconFormatter';
export default CheckIconFormatter;
