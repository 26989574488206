import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { PositionStatusContentInnerProps, PositionStatusContentProps } from './component';
import { PositionStatusContentComponent } from './component';
import { PositionStatusContentStyleRules } from './styles';

export const PositionStatusContent = compose<PositionStatusContentInnerProps, PositionStatusContentProps>(
    setDisplayName('PositionStatusContent'),
    withTranslation(),
    withStyles(PositionStatusContentStyleRules)
)(PositionStatusContentComponent);
