import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';

import type { ResolvedRtdsSessionError } from '../ApiClient';
import { ClassificationType } from '../ApiClient';

import { formatClassification, formatClassifications } from '.';

export const formatRtdsSessionError = (t: SingleTFunction, error: ResolvedRtdsSessionError | undefined): string => {
    if (isUndefined(error) || isUndefined(error?.rtdsSessionErrorType)) {
        return '';
    }
    if (error?.rtdsSessionErrorType?.id === 700 && !isUndefined(error?.tachographDataBlocks)) {
        const formattedTachoDataBlocks = formatClassifications(
            t,
            ClassificationType.TachographDataBlocks,
            error?.tachographDataBlocks
        );
        return `${t(`rtds-sessions-error-missing-data`)}${formattedTachoDataBlocks}`;
    }
    return formatClassification(t, ClassificationType.RtdsSessionErrorType, error?.rtdsSessionErrorType);
};
