import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const VehicleTypesIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M18.78,7.2h-1V2.07a.87.87,0,0,0-.86-.86H2.13a.87.87,0,0,0-.87.86V7.2h-1A.22.22,0,0,0,0,7.42v2.8a.21.21,0,0,0,.21.21H1.26v9.73a.87.87,0,0,0,.87.86h0a.32.32,0,0,0,0,.14v.68a.36.36,0,0,0,.35.36h3a.36.36,0,0,0,.36-.36v-.68a.32.32,0,0,0,0-.14H8.53V19.44h-2a.35.35,0,0,1-.35-.35v-2.3a.36.36,0,0,1,.35-.36h2V15.05l1.21-3.47H2.67a.41.41,0,0,1-.37-.45V5.54a.41.41,0,0,1,.37-.45h13.7a.42.42,0,0,1,.38.45V9H19V7.42A.21.21,0,0,0,18.78,7.2ZM2.65,17.4H4a.36.36,0,0,1,.36.36v1.33a.36.36,0,0,1-.36.35H2.65a.36.36,0,0,1-.36-.35V17.76A.36.36,0,0,1,2.65,17.4Z" />
            <path d="M22.34,10.82A1.2,1.2,0,0,0,21.21,10H12.42a1.21,1.21,0,0,0-1.13.81L9.63,15.6V22a.8.8,0,0,0,.8.8h.8A.79.79,0,0,0,12,22v-.8H21.6V22a.8.8,0,0,0,.8.8h.8A.8.8,0,0,0,24,22V15.6Zm-9.92,8a1.2,1.2,0,1,1,1.2-1.19A1.19,1.19,0,0,1,12.42,18.79Zm8.79,0A1.2,1.2,0,1,1,22.4,17.6,1.19,1.19,0,0,1,21.21,18.79Zm-10-4,1.19-3.59h8.79L22.4,14.8Z" />
        </SvgIcon>
    );
};
VehicleTypesIcon.displayName = 'VehicleTypesIcon';

export { VehicleTypesIcon };
