import * as React from 'react';

import { DefaultHeader } from './components/DefaultHeader';
import { SearchHeader } from './components/SearchHeader';

export interface SearchingBoxComponentProps {
    dataIdPrefix: string;
    searchingItemsName: string;
    searchEnabled: boolean;
    openSearch: () => void;
    closeSearch: () => void;
    expandAll?: () => void;
    collapseAll?: () => void;
    deselectAll?: () => void;
    selectAll?: () => void;
    selectAllMatchedItems?: () => void;
    deselectAllMatchedItems?: () => void;
    changeSearchQuery: (searchQuery: string) => void;
    defaultSearchQuery?: string;
    partiallySelected: boolean;
    selectedAll: boolean;
    partiallyExpanded: boolean;
    expandedAll: boolean;
    hideBulkSelectActions?: boolean;
}

export interface SearchingBoxComponentInnerProps extends SearchingBoxComponentProps {}

export class SearchingBoxComponent extends React.Component<SearchingBoxComponentInnerProps> {
    public render(): JSX.Element {
        const {
            dataIdPrefix,
            searchingItemsName,
            searchEnabled,
            expandAll,
            collapseAll,
            deselectAll,
            selectAll,
            partiallySelected,
            selectedAll,
            partiallyExpanded,
            expandedAll,
            openSearch,
            closeSearch,
            changeSearchQuery,
            defaultSearchQuery,
            selectAllMatchedItems,
            deselectAllMatchedItems,
            hideBulkSelectActions,
        } = this.props;

        return (
            <div data-id={`${this.props.dataIdPrefix}-header`}>
                {searchEnabled ? (
                    <SearchHeader
                        dataIdPrefix={dataIdPrefix}
                        changeSearchQuery={changeSearchQuery}
                        defaultSearchQuery={defaultSearchQuery}
                        closeSearch={closeSearch}
                        selectAllMatchedItems={selectAllMatchedItems}
                        deselectAllMatchedItems={deselectAllMatchedItems}
                        hideSelectAllButtons={hideBulkSelectActions}
                    />
                ) : (
                    <DefaultHeader
                        dataIdPrefix={dataIdPrefix}
                        searchingItemsName={searchingItemsName}
                        openSearch={openSearch}
                        expandAll={expandAll}
                        collapseAll={collapseAll}
                        deselectAll={deselectAll}
                        selectAll={selectAll}
                        partiallySelected={partiallySelected}
                        selectedAll={selectedAll}
                        partiallyExpanded={partiallyExpanded}
                        expandedAll={expandedAll}
                        hideSelectAllCheckbox={hideBulkSelectActions}
                    />
                )}
            </div>
        );
    }
}
