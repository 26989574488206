import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { NumericInputSettingItemInnerProps, NumericInputSettingItemProps } from './component';
import { NumericInputSettingItemComponent } from './component';

export const NumericInputSettingItem = compose<NumericInputSettingItemInnerProps, NumericInputSettingItemProps>(
    setDisplayName('NumericInputSettingItem'),
    withTranslation()
)(NumericInputSettingItemComponent);
