import type { ComponentType } from 'react';
import { compose, setDisplayName } from 'react-recompose';

import type { ViolationFieldFormatterInnerProps, ViolationFieldFormatterProps } from './component';
import { ViolationFieldFormatterComponentFactory } from './component';

export type { ViolationFieldFormatterProps } from './component';

export const ViolationFieldFormatterFactory: <T>(
    formatter: (value: T) => string
) => ComponentType<ViolationFieldFormatterProps<T>> = <T>(formatter: (value: T) => string) =>
    compose<ViolationFieldFormatterInnerProps<T>, ViolationFieldFormatterProps<T>>(
        setDisplayName('ViolationFieldFormatter')
    )(ViolationFieldFormatterComponentFactory<T>(formatter));
