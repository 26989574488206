import { getValueFromHash } from '~/common';
import type { NumericDictionary } from '~/libs/utility';
import { isUndefined, keyBy } from '~/libs/utility';
import type {
    Classification,
    CompartmentStatus,
    Container,
    ContainerStatus,
    DeviceType,
    DoorStatus,
    DriverActivityType,
    DriverHoursStatus,
    DriverSubActivityType,
    InhibitorStatus,
    LastTrailerEvent,
    ResolvedContainer,
    ResolvedContainerStatus,
    ResolvedDriverHoursStatus,
    ResolvedInhibitorStatus,
    ResolvedLastTrailerEvent,
    ResolvedRouteStatus,
    ResolvedTrailerConnection,
    ResolvedTrailerStatus,
    ResolvedVehicleConnection,
    ResolvedVehicleDriverHoursStatus,
    RouteStatus,
    Trailer,
    TrailerConnection,
    TrailerStatus,
    Vehicle,
    VehicleCategory,
    VehicleConnection,
    VehicleDriverHoursStatus,
    VehicleType,
} from '~/services/ApiClient';
import {
    compartmentStatusResolver,
    doorStatusResolver,
    namedVehicleReferenceResolver,
    reeferStatusResolver,
    trailerBatteryStatusResolver,
    trailerResolver,
    vehicleResolver,
} from '~/services/ModelResolvers';

import type { DriverStatusResolverProps, ResolvedDriverStatus } from './models';

export const driverHoursStatusResolver = (
    driverHoursStatus: DriverHoursStatus | undefined,
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>
): ResolvedDriverHoursStatus | undefined => {
    if (!driverHoursStatus) {
        return undefined;
    }

    return {
        ...driverHoursStatus,
        activity: !isUndefined(driverHoursStatus?.activity)
            ? driverActivityTypes[driverHoursStatus.activity]
            : undefined,
        subActivity: !isUndefined(driverHoursStatus?.subActivity)
            ? driverSubActivityTypes[driverHoursStatus.subActivity]
            : undefined,
    };
};

export const driverStatusResolver = (props: DriverStatusResolverProps): ResolvedDriverStatus | undefined => {
    const { driverActivityTypes, driverStatus, driverSubActivityTypes, driversHash, vehicleCategories, vehicleTypes } =
        props;

    if (!driverStatus) {
        return;
    }
    return {
        ...driverStatus,
        activity: getValueFromHash(driverActivityTypes, driverStatus.activity),
        subactivity: getValueFromHash(driverSubActivityTypes, driverStatus.subactivity),
        coDriverActivity: getValueFromHash(driverActivityTypes, driverStatus.coDriverActivity),
        coDriverSubActivity: getValueFromHash(driverSubActivityTypes, driverStatus.coDriverSubActivity),
        driver: getValueFromHash(driversHash, driverStatus.driver),
        coDriver: getValueFromHash(driversHash, driverStatus.coDriver),
        vehicle: !isUndefined(driverStatus.vehicle)
            ? namedVehicleReferenceResolver({
                  namedVehicleReference: driverStatus.vehicle,
                  categories: vehicleCategories,
                  vehicleTypes,
              })
            : undefined,
    };
};

export const containerResolver = (containerData: Container): ResolvedContainer => ({
    ...containerData,
});

export const containerStatusResolver = (
    restProps: ContainerStatus,
    containerEventType: NumericDictionary<Classification>,
    containerTemperatureType: NumericDictionary<Classification>
): ResolvedContainerStatus => ({
    ...restProps,
    lastEvent: {
        dateTime: restProps.lastEvent.dateTime,
        eventType: containerEventType[restProps.lastEvent.eventType],
    },
    temperatures: restProps.temperatures.map(({ type, ...t }) => ({
        ...t,
        type: containerTemperatureType[type],
    })),
});

export const inhibitorStatusResolver = (
    restProps: InhibitorStatus,
    inhibitorStatuses: NumericDictionary<Classification>
): ResolvedInhibitorStatus => ({
    ...restProps,
    inhibitorStatus: inhibitorStatuses[restProps.status],
});

export const lastEventResolver = (
    lastEventData: LastTrailerEvent,
    lastEvent: NumericDictionary<Classification>
): ResolvedLastTrailerEvent => ({
    ...lastEventData,
    trailerEventType: lastEvent[lastEventData.trailerEventType],
});

export const routeStatusResolver = (
    routeStatus: RouteStatus,
    routeStatusTypes: NumericDictionary<Classification>
): ResolvedRouteStatus => {
    return {
        ...routeStatus,
        status: routeStatusTypes[routeStatus.status],
    };
};

export const trailerStatusResolver = (
    restProps: TrailerStatus,
    reeferStatus: NumericDictionary<Classification>,
    reeferAlarm: NumericDictionary<Classification>,
    reeferOperationMode: NumericDictionary<Classification>,
    reeferPowerMode: NumericDictionary<Classification>,
    reeferSpeed: NumericDictionary<Classification>,
    hookedStatus: NumericDictionary<Classification>,
    compartmentStatus: NumericDictionary<Classification>,
    doorStatus: NumericDictionary<Classification>,
    trailerBatteryStatus: NumericDictionary<Classification>,
    trailerEventType: NumericDictionary<Classification>
): ResolvedTrailerStatus => ({
    ...restProps,
    compartments: keyBy(
        restProps.compartments.map((compartment: CompartmentStatus) =>
            compartmentStatusResolver(compartment, compartmentStatus)
        ),
        'compartmentNumber'
    ),
    doors: keyBy(
        restProps.doors.map((door: DoorStatus) => doorStatusResolver(door, doorStatus)),
        'doorNumber'
    ),
    reefer:
        restProps.reefer &&
        reeferStatusResolver(
            restProps.reefer,
            reeferStatus,
            reeferAlarm,
            reeferOperationMode,
            reeferPowerMode,
            reeferSpeed
        ),
    hooked: !isUndefined(restProps.hooked) ? hookedStatus[restProps.hooked] : undefined,
    battery: restProps.battery ? trailerBatteryStatusResolver(restProps.battery, trailerBatteryStatus) : undefined,
    lastEvent: lastEventResolver(restProps.lastEvent, trailerEventType),
});

export const trailerConnectionResolver = (
    trailerConnection: TrailerConnection,
    trailer: Trailer | undefined,
    trailerStatus: TrailerStatus | undefined,
    trailerManufacturers: NumericDictionary<Classification>,
    reeferManufacturers: NumericDictionary<Classification>,
    reeferStatus: NumericDictionary<Classification>,
    reeferAlarm: NumericDictionary<Classification>,
    reeferOperationMode: NumericDictionary<Classification>,
    reeferPowerMode: NumericDictionary<Classification>,
    reeferSpeed: NumericDictionary<Classification>,
    hookedStatus: NumericDictionary<Classification>,
    compartmentStatus: NumericDictionary<Classification>,
    doorStatus: NumericDictionary<Classification>,
    trailerBatteryStatus: NumericDictionary<Classification>,
    trailerEventType: NumericDictionary<Classification>
): ResolvedTrailerConnection => {
    const resolvedTrailerConnection: ResolvedTrailerConnection = {
        trailerReference: trailerConnection.trailer,
        isManuallyConnected: trailerConnection.isManuallyConnected,
    };

    if (trailer) {
        resolvedTrailerConnection.trailer = trailerResolver(trailer, trailerManufacturers, reeferManufacturers);
        resolvedTrailerConnection.trailerStatus =
            trailerStatus &&
            trailerStatusResolver(
                trailerStatus,
                reeferStatus,
                reeferAlarm,
                reeferOperationMode,
                reeferPowerMode,
                reeferSpeed,
                hookedStatus,
                compartmentStatus,
                doorStatus,
                trailerBatteryStatus,
                trailerEventType
            );
    }

    return resolvedTrailerConnection;
};

export const vehicleDriverHoursStatusResolver = (
    vehicleDriverHoursStatus: VehicleDriverHoursStatus,
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>
): ResolvedVehicleDriverHoursStatus => ({
    ...vehicleDriverHoursStatus,
    driverHoursStatus: driverHoursStatusResolver(
        vehicleDriverHoursStatus.driverHoursStatus,
        driverActivityTypes,
        driverSubActivityTypes
    ),
    coDriverHoursStatus: driverHoursStatusResolver(
        vehicleDriverHoursStatus.coDriverHoursStatus,
        driverActivityTypes,
        driverSubActivityTypes
    ),
});

export const vehicleConnectionResolver = (
    vehicleConnection: VehicleConnection,
    vehicleDriverHoursStatus: NumericDictionary<VehicleDriverHoursStatus>,
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>,
    vehiclesHash: NumericDictionary<Vehicle>,
    deviceTypes: NumericDictionary<DeviceType>,
    vehicleCategories: NumericDictionary<VehicleCategory>,
    vehicleTypes: NumericDictionary<VehicleType>
): ResolvedVehicleConnection => {
    const vehicleDriverHourStatus = vehicleDriverHoursStatus[vehicleConnection.vehicle.id];

    return {
        vehicle: vehiclesHash[vehicleConnection.vehicle.id]
            ? vehicleResolver(vehiclesHash[vehicleConnection.vehicle.id], deviceTypes, vehicleCategories, vehicleTypes)
            : undefined,
        vehicleReference: vehicleConnection.vehicle,
        isManuallyConnected: !!vehicleConnection.trailerConnection?.isManuallyConnected,
        canManuallyDisconnect: vehicleConnection.canManuallyDisconnect,
        vehicleDriverHoursStatus: vehicleDriverHourStatus
            ? vehicleDriverHoursStatusResolver(vehicleDriverHourStatus, driverActivityTypes, driverSubActivityTypes)
            : undefined,
    };
};
