import { createAction } from 'redux-actions';

import type { PreferencesScope } from '~/services/ApiClient';
import {
    ApiClient,
    QueryPreferencesRequest,
    QueryPreferencesResponse,
    createApiModel,
    resolveWhenStatusCodeIn,
    retryableRequest,
} from '~/services/ApiClient';
import { predefinedUserPreferences } from '~/services/PredefinedUserPreferences';

import type { UserPreferencesPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const retrieveUserPreferencesExecutor = async (
    keys: string[]
): Promise<QueryPreferencesResponse | undefined> => {
    const predefinedPrefKeys = keys.filter((it) => Object.keys(predefinedUserPreferences).includes(it));
    const notPredefinedPrefKeys = keys.filter((it) => !Object.keys(predefinedUserPreferences).includes(it));

    let response = createApiModel(QueryPreferencesResponse, { items: [] });

    if (notPredefinedPrefKeys.length > 0) {
        response = await retryableRequest(() =>
            ApiClient.queryPreferences(createApiModel(QueryPreferencesRequest, { keys: notPredefinedPrefKeys }))
        );
    }

    await Promise.all(
        predefinedPrefKeys.map(async (predefinedPreKey) => {
            const rs: QueryPreferencesResponse = await retryableRequest(() =>
                predefinedUserPreferences[predefinedPreKey].get()
            );

            if (rs?.items) {
                response.items.push(...rs.items);
            }
        })
    );

    return response;
};

export const deleteUserPreferencesExecutor = (key: string): Promise<void> => {
    return retryableRequest(() => ApiClient.deletePreferences(key).catch(resolveWhenStatusCodeIn(404)));
};

export const deleteAllPreferencesExecutor = (scope: PreferencesScope): Promise<void> => {
    return retryableRequest(() => ApiClient.deleteAllPreferences(scope));
};

export const retrieveUserPreferencesAction = createAction<Promise<UserPreferencesPayload>, string[], string[]>(
    ActionTypePrefixes.USERPREFERENCES_LOAD,
    retrieveUserPreferencesExecutor,
    (keys: string[]) => keys
);

export const initializeUserPreferencesStateAction = createAction(
    ActionTypeKeys.USERPREFERENCES_INITIALIZESTATE,
    (key: string, value: object, processResponse?: (preferences: object) => object) => ({
        defaultUserPreferences: {
            key,
            value,
        },
        processResponse,
    }),
    (key: string) => key
);

export const updateUserPreferencesAction = createAction(
    ActionTypeKeys.USERPREFERENCES_UPDATE,
    (key: string, value: object) => ({ key, value }),
    (key: string) => key
);

export const deleteUserPreferencesAction = createAction<Promise<unknown>, string, string>(
    ActionTypePrefixes.USERPREFERENCES_DELETE,
    deleteUserPreferencesExecutor,
    (key: string) => key
);

export const deleteAllPreferencesAction = createAction<Promise<unknown>, PreferencesScope>(
    ActionTypePrefixes.USERPREFERENCES_DELETE_ALL,
    deleteAllPreferencesExecutor,
    (scope: PreferencesScope) => scope
);

export const promoteUserPreferencesToCustomerDefaultAction = createAction(
    ActionTypePrefixes.USERPREFERENCES_PROMOTETOCUSTOMERDEFAULT,
    () => retryableRequest(() => ApiClient.promotePreferencesToCustomerDefault())
);
