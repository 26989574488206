import { createAction } from 'redux-actions';

import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { ContainersPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

const retrieveContainersExecutor = (): Promise<ContainersPayload> => retryableRequest(() => ApiClient.getContainers());

export const retrieveContainersAction = createAction<Promise<ContainersPayload>>(
    ActionTypePrefixes.CONTAINERS,
    retrieveContainersExecutor
);
