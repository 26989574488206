import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { GridRootInnerProps, GridRootProps } from './component';
import { GridRootComponent } from './component';
import { GridRootStyleRules } from './styles';

export const GridRoot = compose<GridRootInnerProps, GridRootProps>(
    setDisplayName('GridRoot'),
    withStyles(GridRootStyleRules)
)(GridRootComponent);
