import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type WidgetsClassKeys = 'root' | 'widgets' | 'conversationWidgets' | 'messageText';

export const WidgetsStyleRules: StyleRulesCallback<Theme, {}, WidgetsClassKeys> = (theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
        },
        widgets: {
            marginRight: 54,
            display: 'flex',
        },
        conversationWidgets: {
            marginRight: theme.spacing(2),
        },
        messageText: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
        },
    });
