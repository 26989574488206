import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatDateTime } from '~/services/Formatters/formatDateTime';

import type { ResolvedRtdsSchedule } from '../ApiClient';

import { formatDate } from './formatDate';
import { formatDateTimeHourMinute } from './formatDateTimeHourMinute';
import { formatOrdinalTypeClassification } from './formatOrdinalTypeClassification';
import { formatWeekDayTypeClassification } from './formatWeekDayTypeClassification';

export const formatRtdscheduleDescription = (t: SingleTFunction, schedule: ResolvedRtdsSchedule): string => {
    const startDateFormatted = t('schedules-description-starting-at', { date: formatDate(schedule.startDate) });
    switch (schedule.resolvedDescription?.rtdsScheduleType?.id) {
        case 1:
            return t('schedules-description-at', { startDate: formatDateTime(schedule.startDate) });
        case 2:
            return `${t('schedules-description-every-day-at', {
                timeOfDate: formatDateTimeHourMinute(schedule.startDate),
            })}${startDateFormatted}`;
        case 3:
            return `${t('schedules-description-every-days-at', {
                recurrence: schedule.recurrence,
                timeOfDate: formatDateTimeHourMinute(schedule.startDate),
            })}${startDateFormatted}`;
        case 4:
            return `${t('schedules-description-every-week-at', {
                weekDay: formatWeekDayTypeClassification(t, schedule.resolvedDescription.weekdayType),
                timeOfDate: formatDateTimeHourMinute(schedule.startDate),
            })}${startDateFormatted}`;
        case 5:
            return `${t('schedules-description-every-weeks-on', {
                recurrence: schedule.recurrence,
                weekDay: formatWeekDayTypeClassification(t, schedule.resolvedDescription.weekdayType),
                timeOfDate: formatDateTimeHourMinute(schedule.startDate),
            })}${startDateFormatted}`;
        case 6:
            return `${t('schedules-description-every-ordinaltype-weekday', {
                ordinalType: formatOrdinalTypeClassification(t, schedule.resolvedDescription.ordinalType),
                weekDay: formatWeekDayTypeClassification(t, schedule.resolvedDescription.weekdayType),
            })}${t('schedules-description-every-month-at', {
                timeOfDate: formatDateTimeHourMinute(schedule.startDate),
            })}${startDateFormatted}`;
        case 7:
            return `${t('schedules-description-every-ordinaltype-weekday', {
                ordinalType: formatOrdinalTypeClassification(t, schedule.resolvedDescription.ordinalType),
                weekDay: formatWeekDayTypeClassification(t, schedule.resolvedDescription.weekdayType),
            })}${t('schedules-description-every-months-at', {
                recurrence: schedule.recurrence,
                timeOfDate: formatDateTimeHourMinute(schedule.startDate),
            })}${startDateFormatted}`;
        case 8:
            if (!isUndefined(schedule.resolvedDescription.day)) {
                return `${t('schedules-description-every-month-on-day', {
                    day: schedule.resolvedDescription.day,
                    timeOfDate: formatDateTimeHourMinute(schedule.startDate),
                })}${startDateFormatted}`;
            }
            break;
        case 9:
            if (!isUndefined(schedule.resolvedDescription.day)) {
                return `${t('schedules-description-every-months-on-day', {
                    recurrence: schedule.recurrence,
                    day: schedule.resolvedDescription.day,
                    timeOfDate: formatDateTimeHourMinute(schedule.startDate),
                })}${startDateFormatted}`;
            }
            break;
        default:
            return '';
    }
    return '';
};
