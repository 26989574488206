import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import type { ChangeEvent, FC, FocusEvent } from 'react';
import { useCallback, useMemo } from 'react';
import type { ControllerRenderProps, FieldErrors, RegisterOptions } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getValueByPath } from '~/common';
import { isUndefined } from '~/libs/utility';

import { isAlphanumeric } from '../helpers';

import type { TextInputProps } from './models';
import { useStyles } from './styles';

const TextInput: FC<TextInputProps> = (props) => {
    const {
        alphanumeric,
        dataId,
        label,
        defaultValue,
        disabled,
        className,
        fieldName,
        required,
        requiredText,
        readonly = false,
        minLength,
        maxLength,
        onValueChanged,
        onBlurChanged,
        markValueChange,
        hasError,
        errorText,
        validationRules,
        variant,
        type,
        disabledReason,
        InputProps,
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const { control, formState } = useFormContext();
    const fieldError = getValueByPath<FieldErrors, FieldErrors | undefined>(formState.errors, fieldName);
    const error = !!fieldError || hasError;
    const isDirtyField = Boolean(getValueByPath(formState.dirtyFields, fieldName));
    const requiredMessage: string = requiredText ?? t('wf-field-error-required');
    const helperText = hasError && !isUndefined(errorText) ? errorText : fieldError ? fieldError.message : undefined;

    const rules = useMemo<RegisterOptions>(
        () => ({
            required: { value: required ?? false, message: requiredMessage },
            minLength: minLength && {
                value: minLength,
                message: t('wf-field-error-min-length', {
                    minLength,
                }),
            },
            maxLength,
            ...(typeof validationRules?.validate === 'function'
                ? { ...validationRules }
                : {
                      validate: {
                          ...validationRules?.validate,
                          ...(alphanumeric && {
                              'only-alphanumeric': (value: string) =>
                                  isAlphanumeric(value) || t('input-field-alphanumeric-error'),
                          }),
                      },
                  }),
        }),
        [alphanumeric, maxLength, minLength, required, requiredMessage, t, validationRules]
    );

    const notifyChange = useCallback(
        (value: string, callbackFn?: (v: string) => void) => {
            if (!callbackFn) {
                return;
            }
            let endValue = value;

            const maxLengthReached = maxLength && value.length > maxLength;
            if (maxLengthReached) {
                endValue = value.substring(0, maxLength);
            }
            callbackFn(endValue);
        },
        [maxLength]
    );

    const onChangeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: ControllerRenderProps) => {
            notifyChange(event.currentTarget.value, (value) => {
                field.onChange(value);
                if (onValueChanged) {
                    onValueChanged(value);
                }
            });
        },
        [onValueChanged, notifyChange]
    );

    const onBlurHandler = useCallback(
        (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>, field: ControllerRenderProps) => {
            notifyChange(event.currentTarget.value, (value) => {
                if (onBlurChanged) {
                    onBlurChanged(value);
                } else {
                    field.onBlur();
                }
            });
        },
        [onBlurChanged, notifyChange]
    );
    const textFieldClasses = useMemo(
        () => ({
            input: classes.leftAlignment,
            ...(isUndefined(variant) && {
                root: isUndefined(markValueChange) ? undefined : classes.outlinedInput,
                ...(markValueChange &&
                    !error &&
                    isDirtyField && {
                        notchedOutline: classes.changedNotchedOutline,
                        focused: classes.focusChangedNotchedOutline,
                    }),
            }),
        }),
        [
            classes.changedNotchedOutline,
            classes.focusChangedNotchedOutline,
            classes.leftAlignment,
            classes.outlinedInput,
            error,
            isDirtyField,
            markValueChange,
            variant,
        ]
    );

    const baseComponent = (
        <Controller
            name={fieldName}
            defaultValue={defaultValue}
            control={control}
            rules={rules}
            render={({ field }) => (
                <TextField
                    {...field}
                    fullWidth
                    className={className}
                    data-id={dataId}
                    data-testid={dataId}
                    label={label}
                    size="small"
                    disabled={disabled}
                    InputProps={{
                        ...InputProps,
                        classes: textFieldClasses,
                        readOnly: readonly ?? false,
                    }}
                    inputProps={{ maxLength, required: false }} // Required: false to avoid the browser showing the message to force the user to type something.
                    variant={variant || 'outlined'}
                    error={error}
                    helperText={helperText}
                    required={required}
                    onChange={(event) => onChangeHandler(event, field)}
                    type={type}
                    onBlur={(event) => onBlurHandler(event, field)}
                />
            )}
        />
    );

    return disabledReason && disabled ? (
        <Tooltip
            data-id={`input-tooltip-${dataId}`}
            data-testid={`input-tooltip-${dataId}`}
            placement="right"
            title={disabledReason}
        >
            <span>{baseComponent}</span>
        </Tooltip>
    ) : (
        baseComponent
    );
};

TextInput.displayName = 'TextInput';
export { TextInput };
