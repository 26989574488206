import type { UnitSystem } from '@fv/converters';
import { convertVolumeLiquid, getVolumeLiquidUnit } from '@fv/converters';

interface FormatVolumeLiquidArgs {
    value: number;
    unitSystem: UnitSystem;
    precision?: number;
}

const formatVolumeLiquid = (args: FormatVolumeLiquidArgs) => {
    return `${convertVolumeLiquid(args).toFixed(args.precision)} ${getVolumeLiquidUnit(args.unitSystem)}`;
};

export type { FormatVolumeLiquidArgs };
export { formatVolumeLiquid };
