import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { IconFormatterInnerProps, IconFormatterProps } from './component';
import { IconFormatterComponent } from './component';
import { SecondaryDoorStatusStyleRules } from './styles';

export const IconFormatter = compose<IconFormatterInnerProps, IconFormatterProps>(
    setDisplayName('IconFormatter'),
    withStyles(SecondaryDoorStatusStyleRules)
)(IconFormatterComponent);
