import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DurationEditorInnerProps, DurationEditorProps } from './component';
import { DurationEditorComponent } from './component';

export const DurationEditor = compose<DurationEditorInnerProps, DurationEditorProps>(
    setDisplayName('DurationEditor'),
    withTranslation()
)(DurationEditorComponent);
