import { Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isNil } from '~/libs/utility';
import type { ResolvedMessageBody, WorkflowFieldValue, WorkflowFormElement } from '~/services/ApiClient';
import {
    BooleanFieldWorkflowFormElement,
    FieldWorkflowFormElement,
    LabelWorkflowFormElement,
    NewlineWorkflowFormElement,
    WorkflowMessageBody,
} from '~/services/ApiClient';

import { WorkflowEditField } from './components/WorkflowEditField';
import { WorkflowReadOnlyField } from './components/WorkflowReadOnlyField';
import { isFieldEmpty } from './isFieldEmpty';
import type { ConversationWorkflowBodyClassKey } from './styles';

export interface ConversationWorkflowBodyProps {
    messageBody: ResolvedMessageBody;
    readOnly: boolean;
    disabled?: boolean;
    showEmptyFields?: boolean;
}

export interface ConversationWorkflowBodyInnerProps
    extends ConversationWorkflowBodyProps,
        WithStyles<ConversationWorkflowBodyClassKey>,
        InjectedTranslationProps {}

export const createElementGroups = (elements?: WorkflowFormElement[]): WorkflowFormElement[][] => {
    const wfElementGroups: WorkflowFormElement[][] = [];

    if (!elements) {
        return wfElementGroups;
    }

    let firstInGroup = true;
    let currentGroup: WorkflowFormElement[] = [];

    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];

        if (element instanceof NewlineWorkflowFormElement) {
            firstInGroup = true;
        } else {
            if (firstInGroup) {
                currentGroup = [];
                wfElementGroups.push(currentGroup);
                firstInGroup = false;
            }
            currentGroup.push(element);
        }
    }
    return wfElementGroups;
};

export const ConversationWorkflowBodyComponent: FC<ConversationWorkflowBodyInnerProps> = (props) => {
    const { classes, messageBody, readOnly, disabled, showEmptyFields, t } = props;

    const wfElementGroups = useMemo(() => {
        return createElementGroups(messageBody.workflowFormDefinition?.elements);
    }, [messageBody.workflowFormDefinition]);

    if (!(messageBody.value instanceof WorkflowMessageBody)) {
        return null;
    }

    const workflowBody: WorkflowMessageBody = messageBody.value;

    const showField = (value: WorkflowFieldValue | undefined): boolean => {
        return showEmptyFields || !isFieldEmpty(value);
    };

    const renderField = (field: FieldWorkflowFormElement, index: number, label?: string, dataId?: string) => {
        const fieldValue = workflowBody.fieldValues?.find((v) => field.id === v.fieldId);
        return readOnly ? (
            showField(fieldValue) ? (
                <div data-id={dataId} className={classes.element} key={field.id}>
                    <WorkflowReadOnlyField
                        value={fieldValue}
                        field={field}
                        label={label}
                        dataId={`wf-input:${index}`}
                    />
                </div>
            ) : null
        ) : (
            <div data-id={dataId} className={classes.element} key={field.id}>
                <WorkflowEditField
                    value={fieldValue}
                    field={field}
                    label={label}
                    disabled={disabled}
                    dataId={`wf-input:${index}`}
                />
            </div>
        );
    };

    const renderBody = wfElementGroups.map((group, idxGroup) => {
        const alreadyRendered: Array<WorkflowFormElement> = [];
        const renderGroupContainer = group.map((element, idx, elements) => {
            const jsonElement = element.toJSON();
            const previousElement = elements[idx - 1];
            const nextElement = elements[idx + 1];
            const key = idx;

            if (alreadyRendered.includes(element)) {
                return undefined;
            }

            if (element instanceof LabelWorkflowFormElement) {
                if (nextElement instanceof FieldWorkflowFormElement) {
                    alreadyRendered.push(element, nextElement);
                    return renderField(nextElement, idx, element.text, `wf-field:${key}`);
                } else {
                    alreadyRendered.push(element);
                    return (
                        <Typography
                            key={`${jsonElement.type}:${key}`}
                            variant="subtitle1"
                            data-id={`wf-label:${key}`}
                            className={classes.element}
                        >
                            {element.text}
                        </Typography>
                    );
                }
            } else if (element instanceof FieldWorkflowFormElement) {
                if (
                    // Special case for CheckBox element when:
                    // 1. it does not have previous element (CheckBox is first element in a row) and
                    // 2. next element is Label and
                    // 3. there is no other element in a row
                    // => Link that CheckBox with hat Label
                    element instanceof BooleanFieldWorkflowFormElement &&
                    !previousElement &&
                    nextElement instanceof LabelWorkflowFormElement &&
                    !elements[idx + 2]
                ) {
                    alreadyRendered.push(element, nextElement);
                    return renderField(element, idx, nextElement.text, `wf-field:${key}`);
                } else {
                    alreadyRendered.push(element);
                    return renderField(element, idx, element.name, `wf-field:${key}`);
                }
            } else {
                return undefined;
            }
        });
        const groupContainsNoElement = renderGroupContainer.every((x) => isNil(x));
        return (
            !groupContainsNoElement && (
                <div data-id={`workflow-group:${idxGroup}`} className={classes.group}>
                    {renderGroupContainer}
                </div>
            )
        );
    });

    const isBodyEmpty = renderBody.every((x) => x === false);

    return (
        <div data-id={`workflow-body:${messageBody.workflowFormDefinition?.id}`} className={classes.body}>
            {renderBody}
            {isBodyEmpty && (
                <Typography
                    variant="caption"
                    color="textSecondary"
                    data-id="turn-off-empty-fields-label"
                    align="center"
                    display="block"
                >
                    {t('workflow-body-no-fields-to-show')}
                </Typography>
            )}
        </div>
    );
};
