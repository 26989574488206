import { gtag, install } from './gtag';

export const configParameters = {
    custom_map: {
        dimension1: 'username',
        dimension2: 'customerName',
        dimension3: 'cid',
        dimension4: 'actor',
        dimension5: 'eventTrigger',
    },
};

let theMeasurementId: string;
let lastScreenName: string | undefined;
let lastLocation: string | undefined;

export interface UserProperties {
    username: string;
    customerName: string;
    cid: number;
    actor: string;
}

export interface EventProperties {
    value?: number;
    eventTrigger?: string;
}

export const initializeGtag = (measurementId: string): void => {
    theMeasurementId = measurementId;
    lastScreenName = undefined;
    lastLocation = undefined;

    install(theMeasurementId, {
        ...configParameters,
        send_page_view: false,
    });
};

export const setUserProperties = (newUserProperties: Partial<UserProperties>): void => {
    gtag('set', 'user_properties', newUserProperties);
};

export const setUserId = (userId?: string): void => {
    gtag('set', { user_id: userId });
};

export const setScreen = (screenName: string): void => {
    if (screenName === lastScreenName && window.location.href === lastLocation) {
        return;
    }

    lastScreenName = screenName;
    lastLocation = window.location.href;

    gtag('config', theMeasurementId, {
        ...configParameters,
        page_path: `/${screenName}`,
        page_location: window.location.href,
        page_title: document.title,
    });
};

export const logEvent = (
    category: string,
    eventName: string,
    label?: string,
    eventParameters?: EventProperties
): void => {
    gtag('event', eventName, {
        event_category: category,
        event_label: label,
        ...eventParameters,
    });
};
