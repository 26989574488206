import * as React from 'react';
import type { Size } from 'react-virtualized';
import type { DetectElementResize } from 'react-virtualized/dist/es/vendor/detectElementResize';
import createDetectElementResize from 'react-virtualized/dist/es/vendor/detectElementResize';

function defaultOnResize() {
    // do nothing
}

export interface AutoSizerProps {
    className?: string;
    defaultHeight?: number;
    defaultWidth?: number;
    disableHeight?: boolean;
    disableWidth?: boolean;
    nonce?: string;
    style?: object;
    onResize?: (info: Partial<Size>) => void;
    children: (info: Partial<Size>) => React.ReactNode;
}

export interface AutoSizerInnerProps extends AutoSizerProps {}

type State = {
    height: number;
    width: number;
};

// This component is copied from Autosizer component(react-virtualized library) with only change
// regarding the width and height which are now fetched with this method: getBoundingClientRect()
export class AutoSizerComponent extends React.Component<AutoSizerInnerProps, State> {
    static defaultProps = {
        onResize: defaultOnResize,
        disableHeight: false,
        disableWidth: false,
        style: {},
    };

    parentNode: HTMLElement | undefined;
    autoSizer: HTMLElement | undefined;
    window: Window; // uses any instead of Window because Flow doesn't have window type
    detectElementResize: DetectElementResize;

    constructor(props: AutoSizerInnerProps) {
        super(props);
        this.onResize = this.onResize.bind(this);
        this.setRef = this.setRef.bind(this);
        this.state = {
            height: this.props.defaultHeight || 0,
            width: this.props.defaultWidth || 0,
        };
    }

    componentDidMount() {
        const { nonce } = this.props;
        if (
            this.autoSizer &&
            this.autoSizer.parentNode &&
            this.autoSizer.parentNode.ownerDocument &&
            this.autoSizer.parentNode.ownerDocument.defaultView &&
            this.autoSizer.parentNode instanceof this.autoSizer.parentNode.ownerDocument.defaultView.HTMLElement
        ) {
            // Delay access of parentNode until mount.
            // This handles edge-cases where the component has already been unmounted before its ref has been set,
            // As well as libraries like react-lite which have a slightly different lifecycle.
            this.parentNode = this.autoSizer.parentNode;
            this.window = this.autoSizer.parentNode.ownerDocument.defaultView;

            // Defer requiring resize handler in order to support server-side rendering.
            // See issue #41
            this.detectElementResize = createDetectElementResize(nonce, this.window);
            this.detectElementResize.addResizeListener(this.parentNode, this.onResize);

            this.onResize();
        }
    }

    componentWillUnmount() {
        if (this.detectElementResize && this.parentNode) {
            this.detectElementResize.removeResizeListener(this.parentNode, this.onResize);
        }
    }

    render() {
        const { children, className, disableHeight, disableWidth, style } = this.props;
        const { height, width } = this.state;

        // Outer div should not force width/height since that may prevent containers from shrinking.
        // Inner component should overflow and use calculated width/height.
        // See issue #68 for more information.
        const outerStyle: Partial<React.CSSProperties> = { overflow: 'visible' };
        const childParams: Size = { height: 0, width: 0 };

        if (!disableHeight) {
            outerStyle.height = 0;
            childParams.height = height;
        }

        if (!disableWidth) {
            outerStyle.width = 0;
            childParams.width = width;
        }

        /**
     * TODO: Avoid rendering children before the initial measurements have been collected.
     * At best this would just be wasting cycles.
     * Add this check into version 10 though as it could break too many ref callbacks in version 9.
     * Note that if default width/height props were provided this would still work with SSR.
    if (
      height !== 0 &&
      width !== 0
    ) {
      child = children({ height, width })
    }
    */

        return (
            <div
                className={className}
                ref={this.setRef}
                style={{
                    ...outerStyle,
                    ...style,
                }}
            >
                {children(childParams)}
            </div>
        );
    }

    onResize = () => {
        const { disableHeight, disableWidth, onResize } = this.props;

        if (this.parentNode) {
            // Guard against AutoSizer component being removed from the DOM immediately after being added.
            // This can result in invalid style values which can result in NaN values if we don't handle them.
            // See issue #150 for more context.

            const height = this.parentNode.getBoundingClientRect().height || 0;
            const width = this.parentNode.getBoundingClientRect().width || 0;

            const win = this.window || window;
            const style = win.getComputedStyle(this.parentNode) || {};
            const paddingLeft = parseInt(style.paddingLeft, 10) || 0;
            const paddingRight = parseInt(style.paddingRight, 10) || 0;
            const paddingTop = parseInt(style.paddingTop, 10) || 0;
            const paddingBottom = parseInt(style.paddingBottom, 10) || 0;

            const newHeight = height - paddingTop - paddingBottom;
            const newWidth = width - paddingLeft - paddingRight;

            if (
                (!disableHeight && this.state.height !== newHeight) ||
                (!disableWidth && this.state.width !== newWidth)
            ) {
                this.setState({
                    height: height - paddingTop - paddingBottom,
                    width: width - paddingLeft - paddingRight,
                });

                if (onResize) {
                    onResize({ height, width });
                }
            }
        }
    };

    setRef = (autoSizer: HTMLElement | null) => {
        this.autoSizer = autoSizer || undefined;
    };
}
