import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const ExportIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M4,6H2V20a2,2,0,0,0,2,2H18V20H4Z" />
            <path d="M20.4,2H7.6A1.6,1.6,0,0,0,6,3.6V16.4A1.6,1.6,0,0,0,7.6,18H20.4A1.6,1.6,0,0,0,22,16.4V3.6A1.6,1.6,0,0,0,20.4,2ZM10.8,16.4H7.6V13.2h3.2Zm0-4.8H7.6V8.4h3.2Zm0-4.8H7.6V3.6h3.2Zm4.8,9.6H12.4V13.2h3.2Zm0-4.8H12.4V8.4h3.2Zm0-4.8H12.4V3.6h3.2Zm4.8,9.6H17.2V13.2h3.2Zm0-4.8H17.2V8.4h3.2Zm0-4.8H17.2V3.6h3.2Z" />
        </SvgIcon>
    );
};
ExportIcon.displayName = 'ExportIcon';

export { ExportIcon };
