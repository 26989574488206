import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type FilterCellClassKey = 'filtered';

export const useStyles = makeStyles<Theme, {}, FilterCellClassKey>((theme) => ({
    filtered: {
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            pointerEvents: 'none',
            backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.disabledOpacity),
        },
    },
}));
