import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const CoDriverIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M-3 0h24v24H-3V0z" fill="none" />
            <g>
                <path
                    d="M9 12c2.2 0 4-1.8 4-4s-1.8-4-4-4S5 5.8 5 8S6.8 12 9 12z M9 5.9c1.2 0 2.1 0.9 2.1 2.1s-0.9 2.1-2.1 2.1S6.9 9.2 6.9 8
                        S7.8 5.9 9 5.9z"
                />
                <path
                    d="M20.6 11c-0.9-0.6-2-0.9-3.1-1h-0.1h-0.8c-0.3 0-0.5 0.1-0.8 0.1c-1.9 0.3-3.6 1.5-4.7 3.1C10.3 13.1 9.6 13 9 13
                        c-2.7 0-8 1.3-8 4v3h9.7c0.6 1.4 1.7 2.5 3 3.2c0.9 0.5 1.8 0.7 2.8 0.8c0 0 0.1 0 0.1 0h0.8c0.3 0 0.5-0.1 0.8-0.1
                        c2.1-0.4 4-1.7 5-3.6c0.6-1.1 0.9-2.3 0.8-3.6C23.9 14.3 22.6 12.2 20.6 11z M15.2 12.1c2.7-1 5.7 0.5 6.6 3.2h-9.8
                        C12.6 13.8 13.8 12.6 15.2 12.1z M2.9 17c0-0.6 3.1-2.1 6.1-2.1c0.4 0 0.8 0 1.3 0.1c-0.1 0.5-0.2 1-0.3 1.5c0 0 0 0.1 0 0.1v0.8
                        c0 0.2 0.1 0.4 0.1 0.7H2.9V17z M11.8 17c0.3 0 0.6 0 0.9 0.1c1 0.3 1.9 1 2.5 1.9c0.6 0.9 0.9 2 0.9 3.1c0 0 0 0 0 0
                        C13.6 21.7 11.7 19.5 11.8 17z M17 17.9c-0.5 0-0.9-0.4-0.9-0.9s0.4-0.9 0.9-0.9c0.5 0 0.9 0.4 0.9 0.9
                        C17.9 17.5 17.5 17.9 17 17.9z M17.8 22.1c0-0.4 0.1-0.8 0.2-1.1c0.2-0.9 0.5-1.7 1.1-2.4c0.6-0.9 1.6-1.4 2.7-1.6
                        c0.2 0 0.3 0 0.5 0C22.2 19.6 20.4 21.7 17.8 22.1z"
                />
            </g>
        </SvgIcon>
    );
};
CoDriverIcon.displayName = 'CoDriverIcon';

export { CoDriverIcon };
