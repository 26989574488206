import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type {
    DisconnectVehicleFromTrailerDialogInnerProps,
    DisconnectVehicleFromTrailerDialogProps,
} from './component';
import { DisconnectVehicleFromTrailerDialogComponent } from './component';

export const DisconnectVehicleFromTrailerDialog = compose<
    DisconnectVehicleFromTrailerDialogInnerProps,
    DisconnectVehicleFromTrailerDialogProps
>(
    setDisplayName('DisconnectVehicleFromTrailerDialog'),
    withTranslation()
)(DisconnectVehicleFromTrailerDialogComponent);
