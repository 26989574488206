import { de, enGB, es, fr, hu, it, nl, pl, ptBR } from 'date-fns/locale';

const getLocale = (language: string) => {
    const LOCALES_MAPPER = {
        'en-GB': enGB,
        'nl-NL': nl,
        'de-DE': de,
        'fr-FR': fr,
        'hu-HU': hu,
        'es-ES': es,
        'pl-PL': pl,
        'it-IT': it,
        'pt-BR': ptBR,
    };

    return LOCALES_MAPPER[language];
};

export { getLocale };
