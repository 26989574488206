import type { TFunction } from 'i18next';

import type { MonitoringVehicleEntry } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';
import type {
    DisplayUserPreferencesDriverDisplayFormat,
    DisplayUserPreferencesVehicleDisplayFormat,
} from '~/services/ApiClient';
import { AssetConnectionStatus } from '~/services/ApiClient';

import type { VehicleFilter } from './components/VehicleFiltering';
import { getVehicleSearchText } from './getVehicleSearchText';

export const filterVehicles = (
    entries: MonitoringVehicleEntry[],
    vehicleNameDisplayFormat: DisplayUserPreferencesVehicleDisplayFormat,
    driverDisplayFormat: DisplayUserPreferencesDriverDisplayFormat,
    t: TFunction,
    searchQuery?: string,
    filter?: VehicleFilter
): MonitoringVehicleEntry[] => {
    if ((!filter || Object.values(filter).every((v) => isUndefined(v))) && !searchQuery) {
        return entries;
    }

    const searchQueryLowerCase = searchQuery?.toLowerCase();
    return entries.filter((entry: MonitoringVehicleEntry) => {
        const filterByVehicleCategoryCondition = !filter?.vehicleCategory
            ? true
            : entry.vehicle.category?.id === filter.vehicleCategory.id;

        const filterBySearchQuery = searchQueryLowerCase
            ? getVehicleSearchText(entry, vehicleNameDisplayFormat, driverDisplayFormat, t).includes(
                  searchQueryLowerCase
              )
            : true;

        const filterByConnectionStatusCondition = !filter?.connectionStatus
            ? true
            : filter.connectionStatus === AssetConnectionStatus.CONNECTED
              ? !isUndefined(entry.trailerConnection)
              : isUndefined(entry.trailerConnection);

        return filterByVehicleCategoryCondition && filterBySearchQuery && filterByConnectionStatusCondition;
    });
};
