import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../../customIconProps';

export const AssetDepotIconComponent: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M22 8 12 3 2 8V21H4V13H20v8h2ZM8 9v2H4V9Zm6 0v2H10V9Zm6 0v2H16V9Z" />
        </SvgIcon>
    );
};
