import type { PopperProps } from '@mui/material';
import { ListItem, ListItemIcon, ListItemText, MenuList, Link as MuiLink, Paper, Popper, Tooltip } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import type { History } from 'history';
import * as React from 'react';

import { CustomNavLink } from '~/components/CustomNavLink';

import type { ContextMenuClassKey } from './style';

const NavigationLink = (item: ContextMenuItem) =>
    React.forwardRef((props: React.HTMLAttributes<HTMLAnchorElement>, ref: React.RefObject<HTMLAnchorElement>) => {
        if (item.to) {
            return <CustomNavLink to={item.to} {...props} innerRef={ref} />;
        } else {
            return <MuiLink {...props} ref={ref} color="inherit" underline="none" />;
        }
    });

export interface ContextMenuPosition {
    clientX: number;
    clientY: number;
}

export interface ContextMenuProps {
    position?: ContextMenuPosition;
    onClose: () => void;
    menuItems: ContextMenuItem[];
    dataId?: string;
}

export interface ContextMenuItem {
    key: string;
    title: string;
    icon: React.ReactNode;
    to?: History.LocationDescriptor;
    onClick?: () => void;
    disabled?: boolean;
    disabledReason?: string;
    hidden?: boolean;
}

export interface ContextMenuInnerProps extends ContextMenuProps, WithStyles<ContextMenuClassKey> {}

export const ContextMenuComponent: React.FC<ContextMenuInnerProps> = ({
    onClose,
    position,
    menuItems,
    dataId,
    classes,
}) => {
    const [closingObj, setClosingObj] = React.useState<PopperProps['anchorEl']>(undefined);

    const referenceObject: PopperProps['anchorEl'] = React.useMemo(() => {
        return (
            position && {
                clientHeight: 0,
                clientWidth: 0,
                getBoundingClientRect: () => new DOMRect(position.clientX, position.clientY),
            }
        );
    }, [position]);

    const awayCallback = React.useCallback(() => {
        setClosingObj(referenceObject);
    }, [setClosingObj, referenceObject]);

    React.useEffect(() => {
        if (closingObj) {
            if (closingObj === referenceObject) {
                onClose();
            }
            setClosingObj(undefined);
        }
    }, [referenceObject, onClose, closingObj, setClosingObj]);

    const open = !!referenceObject;
    React.useEffect(() => {
        if (!open) {
            return undefined;
        }

        document.addEventListener('contextmenu', awayCallback);
        document.addEventListener('click', awayCallback);

        return () => {
            document.removeEventListener('contextmenu', awayCallback);
            document.removeEventListener('click', awayCallback);
        };
    }, [awayCallback, open]);

    const content = menuItems.map((item) => {
        const component = (
            <ListItem
                button
                component={NavigationLink(item)}
                disabled={item.disabled}
                onClick={() => {
                    if (item.onClick) {
                        item.onClick();
                    }
                    onClose();
                }}
                key={item.key}
                data-id={`${item.key}`}
            >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
            </ListItem>
        );

        const renderedNode =
            item.disabled && item.disabledReason ? (
                <Tooltip
                    title={item.disabledReason}
                    placement="right"
                    data-id={`list-item-tooltip:${item.key}`}
                    key={item.key}
                    className={classes.tooltip}
                >
                    <span data-id={`tooltip-span:${item.key}`}>{component}</span>
                </Tooltip>
            ) : (
                component
            );

        return !item.hidden && renderedNode;
    });

    return (
        <Popper anchorEl={referenceObject} open={open} popperOptions={{ placement: 'bottom-start' }}>
            <Paper elevation={8}>
                <MenuList dense data-id={dataId}>
                    {content}
                </MenuList>
            </Paper>
        </Popper>
    );
};
