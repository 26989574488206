import { Message } from '~/services/ApiClient/generated';

export class OptimisticOutgoingConversationMessage extends Message {
    public correlationId: string;
    public status: OptimisticOutgoingConversationMessageStatus;
    public author: string;

    constructor() {
        super();
        // eslint-disable-next-line no-underscore-dangle
        this._discriminator = 'OptimisticOutgoingConversationMessage';
    }
}

export enum OptimisticOutgoingConversationMessageStatus {
    Sending = 'sending',
    Pending = 'pending',
    ValidationFailure = 'validation-failure',
    GeneralFailure = 'general-failure',
}
