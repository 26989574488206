import withStyles from '@mui/styles/withStyles';
import type * as React from 'react';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { keyDataStateSelectorFactory } from '~/components/UserPreferences';

import { SceneDetailsPaneFactoryComponent } from './component';
import type { SceneDetailsPaneInnerProps, SceneDetailsPaneProps } from './models';
import type { SceneDetailsPaneUserPreferences } from './preferences.sceneDetailsPane';
import { mapDispatchToPropsFactory, mapStateToPropsFactory, mergePropsFactory } from './redux';
import { SceneDetailsPaneClassStyleRules } from './styles';

export { SceneDetailsPaneHeader } from './components/SceneDetailsPaneHeader';
export { SceneDetailsSubpageHeader } from './components/SceneDetailsSubpageHeader';
export { DetailsPaneContext, withDetailsPaneContext } from './context';
export { processSceneDetailsPanePreferencesResponse } from './preferences.sceneDetailsPane';

export type { SceneDetailsPaneUserPreferences } from './preferences.sceneDetailsPane';
export type { DetailsPaneUserPreferences } from './preferences.detailsPane';
export type { DetailsPaneContextProps, InjectedDetailsPaneContextProps } from './context';
export type { DetailsPaneSectionsProps } from './context';
export { SectionList } from './components/SectionList';

export {
    defaultDetailsPaneUserPreferences,
    DETAILSPANE_USERPREFERENCES_KEY,
    requiredDetailsPaneUserPreferences,
} from './preferences.detailsPane';

export const SceneDetailsPaneFactory = <E, P extends SceneDetailsPaneUserPreferences<S>, S extends string>(
    preferencesKey: string
): React.ComponentType<SceneDetailsPaneProps<E, P, S>> =>
    compose<SceneDetailsPaneInnerProps<E, P, S>, SceneDetailsPaneProps<E, P, S>>(
        setDisplayName('SceneDetailsPane'),
        connect(
            createSelector(keyDataStateSelectorFactory(preferencesKey), mapStateToPropsFactory<P, S>()),
            mapDispatchToPropsFactory<S>(preferencesKey),
            mergePropsFactory<P, S>()
        ),
        withStyles(SceneDetailsPaneClassStyleRules)
    )(SceneDetailsPaneFactoryComponent<E, P, S>());
