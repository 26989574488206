import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withImpersonatorToken, withUserData } from '~/components/AuthenticationData';

import { overlayMenuStateSelector } from '../../../../selectors';

import type { StateProps, UserInfoInnerProps, UserInfoProps } from './component';
import { UserInfoComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export const UserInfo = compose<UserInfoInnerProps, UserInfoProps>(
    setDisplayName('UserInfo'),
    withUserData(),
    withImpersonatorToken(),
    connect<StateProps>(createSelector(overlayMenuStateSelector, mapStateToProps), mapDispatchToProps)
)(UserInfoComponent);
