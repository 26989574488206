import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';

import { subscriptionProviderHoc } from './component';

export * from './context';

export type { ComponentIdentifier, InjectedSubscriptionProviderProps } from './component';

export const withSubscriptionProvider = (): ComponentEnhancer<unknown, unknown> => compose(subscriptionProviderHoc);
