import type { ResolvedMessageBody } from '~/services/ApiClient';
import { UnresolvedWorkflowMessageBody, WorkflowMessageBody } from '~/services/ApiClient';

export const formatMessageFormName = (body: ResolvedMessageBody): string | undefined => {
    if (body.value instanceof WorkflowMessageBody) {
        return (
            body.workflowFormDefinition?.description ||
            body.workflowFormDefinition?.name ||
            `[${body.value.formDefinitionId}]`
        );
    }
    if (body.value instanceof UnresolvedWorkflowMessageBody) {
        return body.value.preview;
    }

    return undefined;
};
