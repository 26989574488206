import { graphExtraSideMargin, graphWidth } from './constants';

export const getCursorRelativeToGraph = (cursor: number): number => {
    if (cursor < graphExtraSideMargin) {
        return 0;
    }

    if (cursor > graphExtraSideMargin + graphWidth) {
        return graphWidth;
    }

    return cursor - graphExtraSideMargin;
};
