import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import type { FilterDefaultCellProps } from './component';

export type FilterDefaultCellClassKey = 'root' | 'input';

export const useStyles = makeStyles<Theme, FilterDefaultCellProps, FilterDefaultCellClassKey>(() => ({
    root: {},
    input: (props) => ({
        textAlign: props.align,
    }),
}));
