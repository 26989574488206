import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type {
    CompletedActivity,
    DriverActivityType,
    DriverSubActivityType,
    ResolvedCompletedActivity,
} from '~/services/ApiClient';

export const completedActivityResolver = (
    completedActivity: CompletedActivity | undefined,
    driverActivityTypes: NumericDictionary<DriverActivityType>,
    driverSubActivityTypes: NumericDictionary<DriverSubActivityType>
): ResolvedCompletedActivity | undefined => {
    if (!completedActivity) {
        return undefined;
    }
    return {
        ...completedActivity,
        activityType: driverActivityTypes[completedActivity.activityType],
        subActivityType: !isUndefined(completedActivity.subActivityType)
            ? driverSubActivityTypes[completedActivity.subActivityType]
            : undefined,
    };
};
