import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';

export type InlineBadgeClassKey = 'root';

export const InlineBadgeStyleRules: StyleRulesCallback<Theme, {}, InlineBadgeClassKey> = (theme: Theme) => {
    return {
        root: {
            fontSize: 11,
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            borderRadius: '16px',
            padding: '0 5px',
            lineHeight: '15px',
            minWidth: '16px',
            height: '16px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Roboto',
        },
    };
};
