import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type HistorySubpageClassKey = 'messageText' | 'loaderContainer';

export const HistorySubpageStyleRules: StyleRulesCallback<Theme, {}, HistorySubpageClassKey> = (theme: Theme) =>
    createStyles({
        messageText: {
            display: 'flex',
            justifyContent: 'center',
            fontStyle: 'italic',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
        },
        loaderContainer: {
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(2),
        },
    });
