import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(),
        background: theme.palette.background.default,
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    avatarIcons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
    driverName: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        fontWeight: theme.typography.fontWeightMedium,
    },
    vehicleInfo: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(3),
    },
    placeHolderElement: {
        display: 'flex',
        width: 50,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
}));
