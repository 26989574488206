import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import type { ImageProps } from './component';

export type ImageClassKey = 'image';

export const useStyles = makeStyles<Theme, ImageProps, ImageClassKey>(() => ({
    image: (props: ImageProps) => ({
        '-webkit-transform': `rotate(${props.rotation}deg) scale(${props.scale})`,
        '-ms-transform': `rotate(${props.rotation}deg) scale(${props.scale})`,
        transform: `rotate(${props.rotation}deg) scale(${props.scale})`,
    }),
}));
