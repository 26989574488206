import { Avatar, ListItem, Typography } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';

import { useDisplayPreferences } from '~/common/hooks';
import { ElapsedTime } from '~/components/ElapsedTime';
import { DriverActivityIconFormatter, DriverNameFormatter } from '~/components/Formatters';
import { ClockIcon, CoDriverIcon, DriverIcon } from '~/components/Icons';
import { AssetSubpage, MonitoringPerspective, MonitoringViewMode } from '~/data/monitoring';
import { DriverActivityGraph } from '~/scenes/Monitoring/components/DetailsPane/components/Vehicles/components/DriverActivityGraph';
import { MONITORING_PATH_STRUCTURE } from '~/scenes/Monitoring/consts';
import type { DriverActivityType, DriverName } from '~/services/ApiClient';
import { DriverRole } from '~/services/ApiClient';
import { formatDriverActivity } from '~/services/Formatters';

import type { DriverSummaryProps } from './models';
import { useStyles } from './styles';

const DriverSummary: FC<DriverSummaryProps> = (props) => {
    const {
        activity,
        coDriverActivity,
        activityStartDateTime,
        coDriverActivityStartDateTime,
        subactivity,
        coDriverSubActivity,
        driver,
        coDriver,
    } = props.driverStatus;
    const { role } = props;
    const classes = useStyles();
    const displayPreferences = useDisplayPreferences();
    const { t } = useTranslation();
    const history = useHistory();

    const driverData = {
        driver: role === DriverRole.DRIVER ? driver : coDriver,
        activity: role === DriverRole.DRIVER ? activity : coDriverActivity,
        activityStartDateTime: role === DriverRole.DRIVER ? activityStartDateTime : coDriverActivityStartDateTime,
        subactivity: role === DriverRole.DRIVER ? subactivity : coDriverSubActivity,
        icon: role === DriverRole.DRIVER ? <DriverIcon /> : <CoDriverIcon />,
        subpage: role === DriverRole.DRIVER ? AssetSubpage.DRIVER : AssetSubpage.CODRIVER,
    };

    const subactivityElement = driverData.driver && driverData.subactivity && (
        <span data-id="driver-sub-activity">{driverData.subactivity.name}</span>
    );

    const activityElement =
        driverData.driver && driverData.activity ? (
            <Typography variant="body2" color="textSecondary">
                <span>{formatDriverActivity(t, driverData.activity as DriverActivityType)}</span>
                {subactivityElement && <>,&nbsp;</>}
                {subactivityElement}
            </Typography>
        ) : (
            driverData.driver && (
                <Typography variant="body2" color="textSecondary" data-id="driver-activity">
                    -
                </Typography>
            )
        );

    const openDriverSubpage = () => {
        history.push(
            generatePath(MONITORING_PATH_STRUCTURE, {
                viewMode: MonitoringViewMode.LIST,
                perspective: MonitoringPerspective.DRIVER,
                id: driver?.id,
                subpage: driverData.subpage,
            })
        );
    };

    return (
        <ListItem
            className={classNames(classes.driver, { [classes.disabled]: !driverData.driver })}
            data-id="driver-summary"
        >
            <div className={classes.driverInfoRow}>
                <div className={classes.avatarContainer}>
                    <Avatar className={classes.driverIcon} onClick={openDriverSubpage} data-id="driver-avatar">
                        {driverData.icon}
                    </Avatar>
                    {driverData.activity && (
                        <Avatar
                            className={classNames(
                                classes.driverActivityIcon,
                                classes[`activity${driverData.activity.key}`]
                            )}
                        >
                            <DriverActivityIconFormatter
                                value={driverData.activity.key}
                                iconProps={{ fontSize: 'inherit' }}
                            />
                        </Avatar>
                    )}
                </div>

                <div>
                    <Typography
                        data-id="driver-name"
                        className={classNames(classes.driverName, { [classes.disabledTitle]: !driverData.driver })}
                        onClick={openDriverSubpage}
                    >
                        {driverData.driver ? (
                            <DriverNameFormatter
                                value={driverData.driver as DriverName}
                                displayNameFormat={displayPreferences.driverDisplayFormat}
                            />
                        ) : (
                            t('not-logged-in')
                        )}
                    </Typography>
                    <div className={classes.driverInfo}>
                        <Typography variant="body2" color="textSecondary" className={classes.role}>
                            {t(`${role}`)}
                        </Typography>
                        {activityElement && (
                            <Typography variant="body2" color="textSecondary" className={classes.delimiter}>
                                •
                            </Typography>
                        )}
                        {activityElement}
                    </div>
                </div>
            </div>
            {driverData.driver && driverData.activity && driverData.activityStartDateTime ? (
                <div className={classes.activityDetailsTimestamp}>
                    <ElapsedTime icon={ClockIcon} since={driverData.activityStartDateTime as Date} />
                </div>
            ) : (
                <div data-id="no-data">{t('not-available')}</div>
            )}
            {driverData.driver && (
                <DriverActivityGraph driverId={driverData.driver.id} componentId={`${role}-activity-graph`} />
            )}
        </ListItem>
    );
};

DriverSummary.displayName = 'DriverSummary';
export default DriverSummary;
