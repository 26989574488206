/* eslint-disable @typescript-eslint/no-var-requires */
import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/it';

moment.updateLocale('de', require('~/locales/momentDuration.de-de.json'));
moment.updateLocale('en-gb', require('~/locales/momentDuration.en-gb.json'));
moment.updateLocale('es', require('~/locales/momentDuration.es-es.json'));
moment.updateLocale('fr', require('~/locales/momentDuration.fr-fr.json'));
moment.updateLocale('hu', require('~/locales/momentDuration.hu-hu.json'));
moment.updateLocale('nl', require('~/locales/momentDuration.nl-nl.json'));
moment.updateLocale('pl', require('~/locales/momentDuration.pl-pl.json'));
moment.updateLocale('it', require('~/locales/momentDuration.it-it.json'));
moment.updateLocale('pt-br', require('~/locales/momentDuration.pt-br.json'));

const toMomentLocale = (lng: string): string => {
    const language = lng.toLowerCase();

    if (!moment.locales().includes(language)) {
        return language.split('-')[0];
    }

    return language;
};

export const setMomentLocale = (language?: string): void => {
    if (!language) {
        return;
    }

    const locale = toMomentLocale(language);

    if (locale === moment.locale()) {
        return;
    }

    moment.locale(locale);
};
