import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { Subtract } from 'utility-types';

import { keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { PredefinedUserPreferencesKeys } from '~/services/PredefinedUserPreferences';

import type { StateProps } from './redux';
import { mapStateToProps } from './redux';

export { defaultDisplayUserPreferences, requiredDisplayUserPreferences } from './preferences';

export type { DisplayUserPreferences } from './preferences';

export interface InjectedDisplayPreferencesProps extends StateProps {}

export const withDisplayPreferences = <WrappedProps extends InjectedDisplayPreferencesProps>(): ComponentEnhancer<
    WrappedProps,
    Subtract<WrappedProps, InjectedDisplayPreferencesProps>
> =>
    compose<WrappedProps, Subtract<WrappedProps, InjectedDisplayPreferencesProps>>(
        connect(createSelector(keyDataStateSelectorFactory(PredefinedUserPreferencesKeys.DISPLAY), mapStateToProps))
    );
