import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { RtdsSessionContextMenuInnerProps, RtdsSessionContextMenuProps } from './component';
import { RtdsSessionContextMenuComponent } from './component';

export const RtdsSessionContextMenu = compose<RtdsSessionContextMenuInnerProps, RtdsSessionContextMenuProps>(
    setDisplayName('RtdsSessionContextMenu'),
    withTranslation()
)(RtdsSessionContextMenuComponent);
