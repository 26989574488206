import { createAction } from 'redux-actions';

import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { VehiclesPayload } from './actionTypes';
import { ActionTypePrefixes } from './actionTypes';

export const retrieveVehiclesExecutor = (): Promise<VehiclesPayload> => {
    return retryableRequest(() => ApiClient.getVehicles());
};

export const retrieveVehiclesAction = createAction(ActionTypePrefixes.VEHICLES, retrieveVehiclesExecutor);
