import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { Duration } from 'moment';
import * as React from 'react';

import { isUndefined } from '~/libs/utility';
import type { DurationFormatOptions } from '~/services/Formatters';
import { formatDuration } from '~/services/Formatters';

import type { DurationClassKey } from './styles';
import type { ViolationType } from './violations';
import { isViolation } from './violations';

export interface DurationFormatterProps {
    value?: Duration;
    options?: DurationFormatOptions;
    threshold?: Duration;
    violationType?: ViolationType;
}

export interface DurationFormatterInnerProps extends DurationFormatterProps, WithStyles<DurationClassKey> {}

export const DurationFormatterComponent: React.FC<DurationFormatterInnerProps> = ({
    classes,
    value,
    options,
    threshold,
    violationType,
}) => {
    if (isUndefined(value)) {
        return null;
    }

    const formattedDuration = formatDuration(value, options);

    const className = classNames(classes.root, {
        [classes.violation]:
            !isUndefined(threshold) && !isUndefined(violationType) && isViolation(value, threshold, violationType),
    });

    return <span className={className}>{formattedDuration}</span>;
};
