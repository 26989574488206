import Notifications from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';

import { alertsCountSelector } from '~/data/alerts';

const AlertsIconCounterComponent = () => {
    const { count } = useSelector(alertsCountSelector);

    if (count === 0) {
        return <Notifications />;
    }

    return (
        <Badge badgeContent={count} color="primary">
            <Notifications />
        </Badge>
    );
};

export default AlertsIconCounterComponent;
