import type { JwtDecodedToken } from '~/data/authentication';
import { isUndefined } from '~/libs/utility';

export interface UserDataStateProps {
    cid: number;
    customerName: string;
    userName: string;
    userFullName?: string;
    customerCareUserName?: string;
    userIsImpersonated: boolean;
}

export const mapStateToProps = (state: JwtDecodedToken | undefined): UserDataStateProps => {
    if (isUndefined(state)) {
        throw new Error('JwtDecodedToken state is undefined');
    }

    if (isUndefined(state.act)) {
        return {
            cid: state.data.cid,
            customerName: state.data.customerName,
            userName: state.data.username,
            userFullName: state.data.fullName,
            customerCareUserName: undefined,
            userIsImpersonated: false,
        };
    }

    const customerCareUserNameEndIndex = state.act.sub.indexOf('@');
    const customerCareUserName =
        customerCareUserNameEndIndex !== -1 ? state.act.sub.substring(0, customerCareUserNameEndIndex) : undefined;
    return {
        cid: state.data.cid,
        customerName: state.data.customerName,
        userName: state.data.username,
        userFullName: state.data.fullName,
        customerCareUserName,
        userIsImpersonated: true,
    };
};
