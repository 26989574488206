import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type VehicleHeaderClassKey = 'badge';

export const VehicleHeaderStyleRules: StyleRulesCallback<Theme, {}, VehicleHeaderClassKey> = () =>
    createStyles({
        badge: {
            right: -6,
        },
    });
