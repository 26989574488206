import { isUndefined } from '~/libs/utility';

export const baseComparer =
    <T>(comparer: (a: T, b: T) => number) =>
    (a?: T, b?: T): number => {
        if (isUndefined(a) && isUndefined(b)) {
            return 0;
        }

        if (isUndefined(a)) {
            return -1;
        }

        if (isUndefined(b)) {
            return 1;
        }

        return comparer(a, b);
    };

export const defaultComparer = <T>(a: T, b: T): number => (a === b ? 0 : a < b ? -1 : 1);

export const stringComparer = new Intl.Collator([], { sensitivity: 'base' }).compare;

export const formattedComparer =
    <T, C>(format: (a: T) => C, comparer: (a: C, b: C) => number) =>
    (a: T, b: T): number => {
        return comparer(format(a), format(b));
    };
