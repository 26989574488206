import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DutySectionInnerProps, DutySectionProps } from './component';
import { DutySectionComponent } from './component';

export const DutySection = compose<DutySectionInnerProps, DutySectionProps>(
    setDisplayName('DutySection'),
    withTranslation()
)(DutySectionComponent);
