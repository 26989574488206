import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { RefreshSceneBarInnerProps, RefreshSceneBarProps } from './component';
import { RefreshSceneBarComponent } from './component';

export * from './models';

export const RefreshSceneBar = compose<RefreshSceneBarInnerProps, RefreshSceneBarProps>(
    setDisplayName('RefreshSceneBar'),
    withTranslation()
)(RefreshSceneBarComponent);
