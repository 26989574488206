import type { StaticDataStoreState } from '~/common';
import { getValueFromHash } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';
import { isUndefined } from '~/libs/utility';
import type { AdminVehicleRead, ResolvedAdminVehicleRead } from '~/services/ApiClient';

import { resolveAdminDevice } from './reducers.resolveAdminDevice';
import { resolveVehicleSettings } from './reducers.resolveVehicleSettings';

export const resolveAdminVehicles = (
    adminVehicles: AdminVehicleRead[],
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): ResolvedAdminVehicleRead[] => {
    const resolvedAdminVehicles: ResolvedAdminVehicleRead[] = adminVehicles.map((vehicle) => {
        const category = settingsStoreState.vehicleCategories.data[vehicle.category];
        const vehicleType = getValueFromHash(settingsStoreState.vehicleTypes.data, vehicle.vehicleTypeId);
        const device = vehicle.device && resolveAdminDevice(vehicle.device, settingsStoreState);
        const deviceType = getValueFromHash(settingsStoreState.deviceTypes.data, vehicle.device?.type);
        const depot = getValueFromHash(staticDataStoreState.depots.data.hash, vehicle.depot);
        const settings = vehicle.settings && resolveVehicleSettings(vehicle.settings, settingsStoreState);
        return {
            id: isUndefined(vehicle) ? 0 : vehicle.id,
            uaid: vehicle.uaid,
            companyCardId: vehicle.companyCardId,
            tag: vehicle.tag,
            unitId: vehicle.unitId,
            vehicleTypeId: vehicle.vehicleTypeId,
            vin: vehicle.vin,
            vrn: vehicle.vrn,
            category,
            device,
            vehicleType,
            deviceType,
            depot,
            settings,
        };
    });
    return resolvedAdminVehicles;
};
