import { CircularProgress, Divider, Fab, Tooltip, Typography } from '@mui/material';
import type { StyledComponentProps } from '@mui/styles';
import { KEY_ESCAPE, KEY_RETURN } from 'keycode-js';
import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { ConfirmationDialog } from '~/components/Dialogs';
import { SendIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { useUserPreferences } from '~/components/UserPreferences';
import { isUndefined } from '~/libs/utility';
import {
    OptimisticOutgoingConversationMessageStatus,
    SendMessageOptions,
    TextMessageBody,
    createApiModel,
} from '~/services/ApiClient';
import { getConfig } from '~/services/Config';

import type { ConversationUserPreferences } from '../../preferences';
import { CONVERSATION_USERPREFERENCES_KEY } from '../../preferences';
import { SendMessageFooter } from '../SendMessageFooter';

import type { SendTextMessageFormClassKey } from './styles';
import { useStyles } from './styles';

export interface SendTextMessageFormProps extends StyledComponentProps<SendTextMessageFormClassKey> {
    onClose: () => void;
    onSend: (body: TextMessageBody, options: SendMessageOptions) => void;
    onSendOptionsWarning: (messageText?: string, canDismiss?: boolean) => void;
    shouldClose: boolean;
    disabledSendFab?: React.ReactNode;
    onCloseRejected: () => void;
    messageStatus?: OptimisticOutgoingConversationMessageStatus;
    messageText?: string;
    defaultMessageText?: string;
    onMessageChange?: (messageText: string) => void;
    disabled?: boolean;
    maxInputRows?: number;
    disabledTyping?: boolean;
}

export interface SendTextMessageFormInnerProps
    extends SendTextMessageFormProps,
        SendTextMessageFormForwardRefProps,
        InjectedTranslationProps {}

export interface SendTextMessageFormElement {
    focus: () => void;
}

export interface SendTextMessageFormForwardRefProps {
    forwardedRef: React.Ref<SendTextMessageFormElement>;
}

export const SendTextMessageFormComponent: React.FC<SendTextMessageFormInnerProps> = (props) => {
    const {
        onClose,
        onSend,
        onSendOptionsWarning,
        disabledSendFab,
        messageText,
        shouldClose,
        onMessageChange,
        onCloseRejected,
        defaultMessageText,
        disabled,
        maxInputRows,
        t,
        forwardedRef,
        messageStatus,
    } = props;
    const maxConversationMessageLength = React.useMemo(() => getConfig().maxConversationMessageLength, []);
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const classes = useStyles(props);

    const [conversationUserPreferences, setConversationUserPreferences] =
        useUserPreferences<ConversationUserPreferences>(CONVERSATION_USERPREFERENCES_KEY);

    const [localText, setLocalText] = React.useState(defaultMessageText ?? '');

    const currentMessageText = isUndefined(messageText) ? localText : messageText;

    const [sendMessageOptions, setSendMessageOptions] = React.useState(
        createApiModel(SendMessageOptions, { ttl: conversationUserPreferences.ttl })
    );

    React.useImperativeHandle(forwardedRef, () => ({
        focus: (): void => {
            if (textAreaRef.current) {
                textAreaRef.current.focus();
            }
        },
    }));

    const handleChangeMessage = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setLocalText(e.target.value);
            if (onMessageChange) {
                onMessageChange(e.target.value);
            }
        },
        [onMessageChange]
    );

    const showConfirmationOnClose = currentMessageText !== '';

    React.useEffect(() => {
        if (
            (shouldClose && !showConfirmationOnClose) ||
            messageStatus === OptimisticOutgoingConversationMessageStatus.Pending
        ) {
            onClose();
        }
    }, [shouldClose, showConfirmationOnClose, onClose, messageStatus]);

    const closeConfirmDialog = React.useMemo(() => {
        return (
            <ConfirmationDialog
                dataId="confirm-close-send-text-form"
                title={t('discard-draft')}
                open={shouldClose && showConfirmationOnClose}
                confirmationActionText={t('discard')}
                onConfirm={onClose}
                onCancel={onCloseRejected}
            >
                <Typography>{t('confirm-discard-draft')}</Typography>
            </ConfirmationDialog>
        );
    }, [t, showConfirmationOnClose, shouldClose, onClose, onCloseRejected]);

    const sendOptionsAreDefault = React.useCallback(
        (sendOptions: SendMessageOptions) =>
            conversationUserPreferences.ttl === sendOptions.ttl && !sendOptions.sendAfter,
        [conversationUserPreferences]
    );

    const resetSendOptions = React.useCallback(
        (keepLastTTL: boolean) => {
            setSendMessageOptions(
                createApiModel(SendMessageOptions, {
                    ttl: keepLastTTL ? sendMessageOptions.ttl : conversationUserPreferences.ttl,
                })
            );
        },
        [conversationUserPreferences.ttl, sendMessageOptions.ttl]
    );

    const handleSendMessage = React.useCallback(() => {
        if (!disabled && currentMessageText) {
            onSend(createApiModel(TextMessageBody, { message: currentMessageText }), sendMessageOptions);

            const rememberThisTTL =
                !sendOptionsAreDefault(sendMessageOptions) && conversationUserPreferences.rememberLastTtl;

            if (rememberThisTTL) {
                setConversationUserPreferences({ ttl: sendMessageOptions.ttl });
            }

            resetSendOptions(rememberThisTTL);
        }
    }, [
        disabled,
        currentMessageText,
        onSend,
        sendMessageOptions,
        resetSendOptions,
        sendOptionsAreDefault,
        conversationUserPreferences.rememberLastTtl,
        setConversationUserPreferences,
    ]);

    const handleTextareaKeyDown = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement> & React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === KEY_ESCAPE && onClose) {
                e.preventDefault();
                onClose();
            }

            if (e.keyCode === KEY_RETURN && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
            }
        },
        [onClose, handleSendMessage]
    );
    const isSending = messageStatus === OptimisticOutgoingConversationMessageStatus.Sending;

    const sendTextMessageFab = (
        <Fab
            color="secondary"
            className={classes.sendFab}
            onClick={handleSendMessage}
            disabled={disabled || !currentMessageText.trim() || isSending}
            data-id="send-message"
        >
            {isSending && <CircularProgress size="100%" className={classes.circularProgress} />}
            <SendIcon className={classes.sendIcon} />
        </Fab>
    );

    const tooltippedSendTextMessageFab = <Tooltip title={t('send-message')}>{sendTextMessageFab}</Tooltip>;

    const summary = (
        <Typography color="textSecondary" className={classes.characterCount}>
            <span data-id="message-length">{currentMessageText.length}</span>
            {' / '}
            <span data-id="max-message-length">{maxConversationMessageLength}</span>
        </Typography>
    );

    return (
        <>
            {closeConfirmDialog}
            <div className={classes.root}>
                <div className={classes.sendMessageContainer}>
                    <TextareaAutosize
                        ref={textAreaRef}
                        maxRows={maxInputRows}
                        className={classes.input}
                        value={messageText}
                        placeholder={!props.disabledTyping ? t('type-a-message') : t('click-clipboard-select-form')}
                        onChange={handleChangeMessage}
                        onKeyDown={handleTextareaKeyDown}
                        data-id="message-text"
                        maxLength={maxConversationMessageLength}
                        disabled={props.disabledTyping}
                    />
                    {messageText ? tooltippedSendTextMessageFab : disabledSendFab ?? sendTextMessageFab}
                </div>
                <Divider />
                <div className={classes.characterCountContainer}>
                    <SendMessageFooter
                        options={sendMessageOptions}
                        preferences={conversationUserPreferences}
                        onOptionsChange={setSendMessageOptions}
                        summary={summary}
                        onWarning={onSendOptionsWarning}
                    />
                </div>
            </div>
        </>
    );
};
