import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type VehicleFilteringClassKey = 'root' | 'formControl' | 'label';

export const VehicleFilteringStyleRules: StyleRulesCallback<Theme, {}, VehicleFilteringClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        formControl: {
            marginBottom: theme.spacing(2),
        },
        label: {
            marginBottom: theme.spacing(2),
            textTransform: 'uppercase',
        },
    });
