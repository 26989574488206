import { MapViewMode } from './mapViewMode';

export const MAP_USERPREFERENCES_KEY = 'map-state';

export interface MapUserPreferences {
    viewMode: MapViewMode;
    followSelectedAsset: boolean;
    markerClusterRadius: number;
    truckAttributesOverlayEnabled: boolean;
    trafficIncidentsOverlayEnabled: boolean;
}

export const defaultMapUserPreferences: MapUserPreferences = {
    viewMode: MapViewMode.MAP,
    followSelectedAsset: true,
    markerClusterRadius: 65,
    truckAttributesOverlayEnabled: false,
    trafficIncidentsOverlayEnabled: false,
};

export const requiredMapUserPreferences = {
    key: MAP_USERPREFERENCES_KEY,
    defaultState: defaultMapUserPreferences,
};
