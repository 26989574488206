import { createAction } from 'redux-actions';

import type { VehicleDriverHoursStatus } from '~/services/ApiClient';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { DriverHoursByVehiclePayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const retrieveDriverHoursByVehicleExecutor = (): Promise<DriverHoursByVehiclePayload> => {
    return retryableRequest(() => ApiClient.getDriverHoursByVehicle());
};

export const retrieveDriverHoursByVehicle = createAction(
    ActionTypePrefixes.DRIVERHOURSBYVEHICLE,
    retrieveDriverHoursByVehicleExecutor
);

export const updateDriverHoursByVehicle = createAction<VehicleDriverHoursStatus>(
    ActionTypeKeys.DRIVERHOURSBYVEHICLE_UPDATE
);
