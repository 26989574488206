import type { Duration } from 'moment';

export enum ViolationType {
    ABOVE = 1,
    BELOW = 2,
}

export const isViolation = (duration: Duration, threshold: Duration, violationType: ViolationType): boolean => {
    if (violationType === ViolationType.ABOVE) {
        return duration.subtract(threshold).asMilliseconds() < 0;
    }

    if (violationType === ViolationType.BELOW) {
        return duration.subtract(threshold).asMilliseconds() > 0;
    }

    return false;
};
