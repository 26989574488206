import { isEmpty, isNil } from '~/libs/utility';

const buildDictionary = (values: (string | number)[] | undefined) => {
    if (isNil(values) || isEmpty(values)) {
        return {};
    }

    return values.reduce((acc: { [key: string]: string | number }, item) => {
        acc[item.toString()] = item;

        return acc;
    }, {});
};

export { buildDictionary };
