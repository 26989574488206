import type { TrailerStatus } from '~/services/ApiClient';
import type { Action, Fulfilled, Pending, Rejected } from '~/types';

export enum ActionTypePrefixes {
    TRAILERSTATUS = 'TRAILERSTATUS',
}

export enum ActionTypeKeys {
    TRAILERSTATUS_PENDING = 'TRAILERSTATUS_PENDING',
    TRAILERSTATUS_FULFILLED = 'TRAILERSTATUS_FULFILLED',
    TRAILERSTATUS_REJECTED = 'TRAILERSTATUS_REJECTED',
    TRAILERSTATUS_UPDATE = 'TRAILERSTATUS_UPDATE',
}

export interface TrailerStatusPayload {
    readonly items: TrailerStatus[];
}

export type ActionTypes =
    | Pending<ActionTypeKeys.TRAILERSTATUS_PENDING>
    | Fulfilled<ActionTypeKeys.TRAILERSTATUS_FULFILLED, TrailerStatusPayload>
    | Rejected<ActionTypeKeys.TRAILERSTATUS_REJECTED>
    | Action<ActionTypeKeys.TRAILERSTATUS_UPDATE, TrailerStatus>;
