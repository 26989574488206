import moment from 'moment';

import { DurationFormatter, ViolationType } from '~/components/Formatters';
import { isUndefined } from '~/libs/utility';

const commonDurationFormatter = (fieldName: string | undefined) => {
    return !isUndefined(fieldName) ? (
        <DurationFormatter
            threshold={moment.duration()}
            violationType={ViolationType.ABOVE}
            value={moment.duration(fieldName)}
        />
    ) : undefined;
};

export { commonDurationFormatter };
