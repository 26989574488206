import type { NumericDictionary } from '~/libs/utility';
import { get, isUndefined } from '~/libs/utility';

const getValueByPath = <T, U = unknown>(obj: T, path: string, defaultValue?: U) => get(obj, path, defaultValue) as U;

const getValueFromHash = <T>(
    hash: NumericDictionary<T> | undefined,
    key: number | string | undefined
): T | undefined => {
    return isUndefined(key) || !hash ? undefined : hash[key];
};

export { getValueByPath, getValueFromHash };
