import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { TachoFilesListViewHeaderInnerProps, TachoFilesListViewHeaderProps } from './component';
import { TachoFilesListViewHeaderComponent } from './component';

export const TachoFileListViewHeader = compose<TachoFilesListViewHeaderInnerProps, TachoFilesListViewHeaderProps>(
    setDisplayName('TachoFileListViewHeader'),
    withDisplayPreferences(),
    withTranslation()
)(TachoFilesListViewHeaderComponent);
