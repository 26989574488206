import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { Classification, ClassificationType } from '~/services/ApiClient';
import { formatClassification } from '~/services/Formatters';

export interface ClassificationFormatterProps {
    t: SingleTFunction;
    classificationKey: ClassificationType;
    value?: Classification;
}

export interface ClassificationFormatterInnerProps extends ClassificationFormatterProps {}

const ClassificationFormatter: React.FunctionComponent<ClassificationFormatterInnerProps> = ({
    t,
    classificationKey,
    value,
}) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{formatClassification(t, classificationKey, value)}</>;
};
ClassificationFormatter.displayName = 'ClassificationFormatter';

export { ClassificationFormatter };
