import { createAction } from 'redux-actions';

import type { DriverStatus } from '~/services/ApiClient';
import { ApiClient, retryableRequest } from '~/services/ApiClient';

import type { DriverStatusPayload } from './actionTypes';
import { ActionTypeKeys, ActionTypePrefixes } from './actionTypes';

export const applyPendingUpdatesAction = createAction(ActionTypeKeys.MONITORING_APPLY_PENDING);

export const clearDataAction = createAction(ActionTypeKeys.MONITORING_CLEAR_DATA);

export const retrieveDriverStatusExecutor = (): Promise<DriverStatusPayload> =>
    retryableRequest(() => ApiClient.getDriverStatuses());

export const retrieveDriverStatusAction = createAction(
    ActionTypePrefixes.MONITORING_DRIVERSTATUS,
    retrieveDriverStatusExecutor
);

export const updateDriverStatusAction = createAction<DriverStatus>(ActionTypeKeys.MONITORING_DRIVERSTATUS_UPDATE);
