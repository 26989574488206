import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import type { FC, SyntheticEvent } from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';

export interface ConfirmationDialogProps {
    dataId: string;
    title: string;
    open: boolean;
    confirmationActionText?: string;
    onConfirm: () => void;
    onCancel: () => void;
    isDisabled?: boolean;
}

export interface ConfirmationDialogInnerProps extends ConfirmationDialogProps, InjectedTranslationProps {}

/**
 * @deprecated This component is deprecated in favor of `@fv/components/Dialogs/ConfirmDialog`
 */
export const ConfirmationDialogComponent: FC<ConfirmationDialogInnerProps> = ({
    t,
    dataId,
    title,
    open,
    confirmationActionText,
    onConfirm,
    onCancel,
    children,
    isDisabled,
}) => {
    const handleClick = (event: SyntheticEvent) => {
        event.stopPropagation();
    };

    return (
        <Dialog open={open} onClick={handleClick}>
            <div data-id={dataId}>
                <DialogTitle data-id="title">{title}</DialogTitle>
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="secondary" data-id="cancel">
                        {t('cancel')}
                    </Button>
                    <Button onClick={onConfirm} color="secondary" data-id="confirm" disabled={isDisabled}>
                        {confirmationActionText || t('ok')}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};
