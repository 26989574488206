import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';

export interface SelectionDetailsProps {
    entryType: string;
    numberOfSelectedEntries: number;
    numberOfMatchingEntries?: number;
    numberOfEntries: number;
}

export interface SelectionDetailsInnerProps extends SelectionDetailsProps, InjectedTranslationProps {}

export const SelectionDetailsComponent: React.FC<SelectionDetailsInnerProps> = ({
    t,
    entryType,
    numberOfSelectedEntries,
    numberOfMatchingEntries,
    numberOfEntries,
}) => {
    const content = isUndefined(numberOfMatchingEntries)
        ? numberOfSelectedEntries
            ? t('nof-selected-entries-out-of-a-total-of', { numberOfSelectedEntries, numberOfEntries, entryType })
            : t('nof-entries', { numberOfEntries, entryType })
        : numberOfSelectedEntries
          ? t('nof-selected-entries-out-of-a-total-of-matching-items', {
                numberOfSelectedEntries,
                numberOfMatchingEntries,
                numberOfEntries,
                entryType,
            })
          : t('nof-matching-entries', { numberOfMatchingEntries, numberOfEntries, entryType });

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{content}</>;
};
