import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { userPreferencesStateSelector } from '~/selectors';

import type {
    DispatchProps,
    HandleUserPreferencesStateUpdateInnerProps,
    HandleUserPreferencesStateUpdateProps,
    StateProps,
} from './component';
import { HandleUserPreferencesStateUpdateComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export const HandleUserPreferencesStateUpdate = compose<
    HandleUserPreferencesStateUpdateInnerProps,
    HandleUserPreferencesStateUpdateProps
>(
    setDisplayName('HandleUserPreferencesStateUpdate'),
    connect<StateProps, DispatchProps>(
        createSelector(userPreferencesStateSelector, mapStateToProps),
        mapDispatchToProps
    )
)(HandleUserPreferencesStateUpdateComponent);
