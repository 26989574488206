import type { Dispatch } from 'redux';

import type { SynchronizationClockDispatchProps } from './component';
import { updateSynchronizationClockAction } from './data';

export const mapDispatchToProps = (dispatch: Dispatch): SynchronizationClockDispatchProps => {
    return {
        updateSynchronizationClock: () => {
            dispatch(updateSynchronizationClockAction(new Date().getTime()));
        },
    };
};
