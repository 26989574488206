import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type VirtualTableCellClassKey = 'cell';

export const VirtualTableCellStyleRules: StyleRulesCallback<Theme, {}, VirtualTableCellClassKey> = () =>
    createStyles({
        cell: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    });
