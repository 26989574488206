import type { StaticDataStoreState } from '~/common';

import { ACTION_TYPE_KEYS } from './actionsTypes';
import type { ActionTypes, DriverActivitiesReportSceneState } from './models';
import { ViewMode } from './models';
import { driverActivitiesGraphResolver, driverActivitiesListResolver } from './utils';

const INITIAL_STATE: DriverActivitiesReportSceneState = {
    error: false,
    items: [],
    pending: false,
};

const driverActivitiesReportReducer = (
    state = INITIAL_STATE,
    action: ActionTypes,
    staticData: StaticDataStoreState
): DriverActivitiesReportSceneState => {
    const { drivers, vehicles } = staticData;

    switch (action.type) {
        case ACTION_TYPE_KEYS.LIST_PENDING:
            return { ...state, pending: true };
        case ACTION_TYPE_KEYS.LIST_FULFILLED: {
            const commonData = {
                source: action.payload,
                drivers: drivers.data.hash,
                vehicles: vehicles.data.hash,
            };

            return {
                ...state,
                items:
                    action.meta.viewMode === ViewMode.LIST
                        ? driverActivitiesListResolver(commonData)
                        : driverActivitiesGraphResolver(commonData, action.meta.assetType),
                pending: false,
            };
        }

        case ACTION_TYPE_KEYS.LIST_REJECTED:
            return { ...state, error: true, pending: false };

        case ACTION_TYPE_KEYS.RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export { driverActivitiesReportReducer };
