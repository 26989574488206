import type { AssetGroup, AssetReference, Depot } from '~/services/ApiClient';

import type { AssetReferenceHash } from '../models';

import { getAssetGroupById, getSingleAssetGroupAssetIds } from './assetgroups';
import { getDepotById, getSingleDepotAssetIds } from './depots';
import { assetReferenceHashFlatten } from './hashutils';

export const getAssetGroupChildAssetIds = (
    assetGroups: AssetGroup[],
    assetGroupId: number,
    assetIds: AssetReferenceHash<AssetReference>
): AssetReference[] => {
    const assetGroup = getAssetGroupById(assetGroups, assetGroupId);

    if (assetGroup === null) {
        return [];
    }

    const hash = getSingleAssetGroupAssetIds(assetGroup, assetIds);
    return assetReferenceHashFlatten(hash);
};

export const getDepotChildAssetIds = (
    depots: Depot[],
    depotId: number,
    assetIds: AssetReferenceHash<AssetReference>
): AssetReference[] => {
    const depot = getDepotById(depots, depotId);

    if (depot === null) {
        return [];
    }

    return assetReferenceHashFlatten(getSingleDepotAssetIds(depot, assetIds));
};
