import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

const formatDeviceTypeInternal = (t: SingleTFunction, key: string) => t(`device-type:${key.toLocaleLowerCase()}`);

const formatDeviceTypeMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((key: string) => formatDeviceTypeInternal(t, key))
);

export const formatDeviceType = (t: SingleTFunction, key: string): string => formatDeviceTypeMemoized(t)(key);
