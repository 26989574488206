import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type MiniSettingsPanelClassKeys = 'miniSettingsPanel' | 'settingWrapper' | 'setting';

export const MiniSettingsPanelStyleRules: StyleRulesCallback<Theme, {}, MiniSettingsPanelClassKeys> = (theme: Theme) =>
    createStyles({
        miniSettingsPanel: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 0,
            marginTop: 'auto',
            width: '100%',
            padding: theme.spacing(1, 2),
            backgroundColor: theme.palette.background.default,
        },
        settingWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            margin: theme.spacing(0, 2),
        },
        setting: {
            width: '200px',
        },
    });
