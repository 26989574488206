import type { FC } from 'react';
import { memo } from 'react';

import { PositionMiniMapSection } from '~/common';
import { entryEquals } from '~/common/utils';

import type { MiniMapSectionProps } from '../../../models';

const MiniMapComponent: FC<MiniMapSectionProps> = (props) => {
    const { dragHandleElement, driverStatus, toggleCollapsed, changeZoomLevel, zoomLevel, isCollapsed, lastLocation } =
        props;

    return (
        <PositionMiniMapSection
            position={driverStatus.location?.position}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            dragHandleElement={dragHandleElement}
            changeZoomLevel={changeZoomLevel}
            zoomLevel={zoomLevel}
            lastLocation={lastLocation}
        />
    );
};

MiniMapComponent.displayName = 'MiniMap';
export default memo(MiniMapComponent, (a, b) =>
    entryEquals<MiniMapSectionProps>({ a, b, paths: ['driverStatus', 'zoomLevel', 'isCollapsed'] })
);
