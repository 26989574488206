import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) =>
    createStyles({
        messageContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
        spinner: { marginRight: theme.spacing(2) },
        message: { fontStyle: 'italic', fontWeight: 'normal' },
    })
);
