import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { DeviceType, ResolvedVehicle, Vehicle, VehicleCategory, VehicleType } from '~/services/ApiClient';

export const vehicleResolver = (
    vehicle: Vehicle,
    deviceTypes: NumericDictionary<DeviceType>,
    categories: NumericDictionary<VehicleCategory>,
    vehicleTypes: NumericDictionary<VehicleType>
): ResolvedVehicle => ({
    ...vehicle,
    deviceType: vehicle?.device && deviceTypes[vehicle.device.type],
    category: categories[vehicle?.category],
    vehicleType: !isUndefined(vehicle?.vehicleTypeId) ? vehicleTypes[vehicle.vehicleTypeId] : undefined,
});
