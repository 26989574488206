import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export type ValidationBoxClassKeys = 'root' | 'error' | 'warning';

export const useStyles = makeStyles<Theme, {}, ValidationBoxClassKeys>((theme: Theme) => ({
    root: {
        padding: theme.spacing(3, 5),
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
}));
