import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';
import { litersToGallons } from '~/services/Convertors';
import { memoizeOne } from '~/services/Memoize';

import type { UnitSystemConverterOptions, UnitSystemFormatterOptions, UnitSystemUtils } from './unitSystemUtils';

const getImperialUtils = (t: SingleTFunction) => {
    const unit = t('unit-gal');
    const defaultPrecision = 1;

    return {
        unit,
        formatter: (fuelLevel?: number, options?: UnitSystemFormatterOptions) =>
            !isUndefined(fuelLevel)
                ? `${litersToGallons(fuelLevel).toFixed(options?.precision ?? defaultPrecision)} ${unit}`
                : '',
        converter: (fuelLevel?: number, options?: UnitSystemConverterOptions) =>
            !isUndefined(fuelLevel)
                ? Number(litersToGallons(fuelLevel).toFixed(options?.precision ?? defaultPrecision))
                : undefined,
    };
};

const getMetricUtils = (t: SingleTFunction) => {
    const unit = t('unit-l');
    const defaultPrecision = 1;

    return {
        unit,
        formatter: (fuelLevel?: number, options?: UnitSystemFormatterOptions) =>
            !isUndefined(fuelLevel) ? `${fuelLevel.toFixed(options?.precision ?? defaultPrecision)} ${unit}` : '',
        converter: (fuelLevel?: number, options?: UnitSystemConverterOptions) =>
            !isUndefined(fuelLevel) ? Number(fuelLevel.toFixed(options?.precision ?? defaultPrecision)) : undefined,
    };
};

export const getFuelLevelUnitSystemUtils = memoizeOne(
    (t: SingleTFunction, unitSystem: DisplayUserPreferencesUnitSystem): UnitSystemUtils => {
        switch (unitSystem) {
            case DisplayUserPreferencesUnitSystem.Imperial:
                return getImperialUtils(t);
            case DisplayUserPreferencesUnitSystem.Metric:
                return getMetricUtils(t);
            default:
                throw new Error('Unknown unit system.');
        }
    }
);
