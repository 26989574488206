import { Typography } from '@mui/material';
import * as React from 'react';

import { useStyles } from './styles';

export interface SectionContentMessageProps {
    children: React.ReactNode;
    dataId?: string;
}

export const SectionContentMessageComponent: React.FC<SectionContentMessageProps> = ({ children, dataId }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} data-id={dataId}>
            <Typography variant="caption" className={classes.message}>
                {children}
            </Typography>
        </div>
    );
};
