import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const ArrowDownBoldCircleIcon: React.FC<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2A10 10 0 0 1 22 12A10 10 0 0 1 12 22A10 10 0 0 1 2 12A10 10 0 0 1 12 2M12 17L17 12H14V8H10V12H7L12 17Z" />
        </SvgIcon>
    );
};
ArrowDownBoldCircleIcon.displayName = 'ArrowDownBoldCircleIcon';

export { ArrowDownBoldCircleIcon };
