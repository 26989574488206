import { compose, setDisplayName, withProps } from 'react-recompose';
import type { Omit } from 'utility-types';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { withTranslation } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps, SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory, SceneListViewHeaderFactory } from '~/components/SceneList';
import type { MonitoringContainerEntry } from '~/data/monitoring';

import { monitoringContainerEntryEquals } from '../../../../utils';

import { filterMonitoringDataSourceMemoized } from './filter';
import { getColumnsMemoized, getRowId } from './grid';
import { MONITORING_LIST_CONTAINERS_USERPREFERENCES_KEY, defaultContainersListUserPreferences } from './preferences';

export * from './preferences';

export type ContainerListViewProps = Omit<SceneListViewProps<MonitoringContainerEntry>, 'columns' | 'gridDataId'>;

const ContainerListViewComponent = SceneListViewFactory<MonitoringContainerEntry>(
    MONITORING_LIST_CONTAINERS_USERPREFERENCES_KEY,
    defaultContainersListUserPreferences,
    getRowId,
    monitoringContainerEntryEquals,
    filterMonitoringDataSourceMemoized
);

export type ContainerListViewHeaderProps = Omit<
    SceneListViewHeaderProps<MonitoringContainerEntry>,
    'columns' | 'excelSheetTitle'
>;

const ContainerListViewHeaderComponent = SceneListViewHeaderFactory<MonitoringContainerEntry>(
    MONITORING_LIST_CONTAINERS_USERPREFERENCES_KEY,
    defaultContainersListUserPreferences,
    filterMonitoringDataSourceMemoized,
    'monitoring'
);

export const ContainerListView = compose<SceneListViewProps<MonitoringContainerEntry>, ContainerListViewProps>(
    setDisplayName('ContainerListView'),
    withDisplayPreferences(),
    withTranslation(),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps) => ({
        columns: getColumnsMemoized(ownProps.t, ownProps.displayPreferences.unitSystem),
        gridDataId: 'monitoring-grid-container',
    }))
)(ContainerListViewComponent);

export const ContainerListViewHeader = compose<
    SceneListViewHeaderProps<MonitoringContainerEntry>,
    ContainerListViewHeaderProps
>(
    setDisplayName('ContainerListViewHeader'),
    withDisplayPreferences(),
    withTranslation(),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps) => ({
        columns: getColumnsMemoized(ownProps.t, ownProps.displayPreferences.unitSystem),
        excelSheetTitle: ownProps.t('monitoring'),
    }))
)(ContainerListViewHeaderComponent);
