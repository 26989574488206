import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { BooleanInputInnerProps, BooleanInputProps } from './component';
import { BooleanInputComponent } from './component';
import { BooleanInputStyleRules } from './styles';

export type { BooleanInputProps } from './component';

export const BooleanInput = compose<BooleanInputInnerProps, BooleanInputProps>(
    setDisplayName('BooleanInput'),
    withTranslation(),
    withStyles(BooleanInputStyleRules)
)(BooleanInputComponent);
