import type { Duration } from 'moment';
import moment from 'moment';
import * as React from 'react';

import { DateTimeFormatter, DurationFormatter } from '~/components/Formatters';
import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import type { ResolvedMessage } from '~/services/ApiClient';
import { OutgoingMessage } from '~/services/ApiClient';

import { SectionName } from '../WorkflowMessageDetails/constants';

export interface MetadataSectionProps {
    dragHandleElement: JSX.Element;
    toggleCollapsed: () => void;
    isCollapsed: boolean;
    message: ResolvedMessage;
}

export interface MetadataSectionInnerProps extends MetadataSectionProps, InjectedTranslationProps {}

export const MetadataSectionComponent: React.FC<MetadataSectionInnerProps> = ({
    t,
    dragHandleElement,
    toggleCollapsed,
    isCollapsed,
    message,
}) => {
    const keyValueItems: KeyValueItem[] = React.useMemo(() => {
        let outgoingMessageTtl: Duration | undefined;
        if (message.value instanceof OutgoingMessage) {
            outgoingMessageTtl = moment.duration(message.value.ttl);
        }
        return [
            {
                valueDataId: 'message-id',
                label: t('message-id'),
                value: <span>{message.value.id}</span>,
            },
            {
                valueDataId: 'gateway-message-id',
                label: t('gateway-message-id'),
                value: <span>{message.value.gatewayMsgId}</span>,
            },
            {
                valueDataId: 'form',
                label: t('form'),
                value: <span>{message.value.form}</span>,
            },
            {
                valueDataId: 'created',
                label: t('created'),
                value: <DateTimeFormatter value={message.value.dateTime} />,
            },
            {
                valueDataId: 'transmitted',
                label: t('transmitted'),
                value: <DateTimeFormatter value={message.value.transmittedDateTime} />,
            },
            {
                valueDataId: 'ttl',
                label: t('ttl'),
                value: <DurationFormatter value={outgoingMessageTtl} />,
            },
            {
                valueDataId: 'msisdn',
                label: t('msisdn'),
                value: <span>{message.value.msisdn}</span>,
            },
        ];
    }, [message.value, t]);

    return (
        <ReorderableSection
            title={t('metadata')}
            dataId={SectionName.METADATA}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            dragHandleElement={dragHandleElement}
        >
            <KeyValueList list={keyValueItems} listDataId="metadata-list" />
        </ReorderableSection>
    );
};
