import * as React from 'react';

import { AssetAvatar } from '~/components/AssetAvatar';
import { ContainerIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsPaneHeader } from '~/components/SceneDetailsPane';
import type { MonitoringContainerEntry } from '~/data/monitoring';
import { formatContainerName } from '~/services/Formatters';

export interface ContainerHeaderProps {
    entry: MonitoringContainerEntry;
}

export interface ContainerHeaderInnerProps extends ContainerHeaderProps, InjectedTranslationProps {}

export const ContainerHeaderComponent: React.FC<ContainerHeaderInnerProps> = ({ entry, t }) => {
    const { container } = entry;

    const containerAvatar = <AssetAvatar icon={<ContainerIcon />} />;
    const containerSecondaryIdentifier = t('container');
    return (
        <SceneDetailsPaneHeader
            avatar={containerAvatar}
            primaryIdentifier={formatContainerName(container)}
            secondaryIdentifier={containerSecondaryIdentifier}
        />
    );
};
