import moment from 'moment';

import type { SingleTFunction } from '~/components/LanguageSelector';

export const placeholderChar = '-';
export const invalidDurationValue = 'invalid';
const HoursMinutesDurationRegex = /^(0[0-9]|1[0-9]|2[0-3]|[0-9])?:([0-5]?[0-9])?$/;

export const displayDurationString = (dayToken: string, duration?: moment.Duration): string => {
    if (!duration) {
        return placeholderChar;
    }

    const days = !Number.isNaN(duration.days()) ? duration.days().toString() : placeholderChar;

    const hours = !Number.isNaN(duration.hours())
        ? duration.hours().toString().padStart(2, '0')
        : `${placeholderChar}${placeholderChar}`;

    const minutes = !Number.isNaN(duration.minutes())
        ? duration.minutes().toString().padStart(2, '0')
        : `${placeholderChar}${placeholderChar}`;

    return `${days}${dayToken} ${hours}:${minutes}`;
};

const isEmptyDurationValue = (dayToken: string, value?: string): boolean => {
    const emptyMask = displayDurationString(dayToken, moment.duration(NaN));
    return !value || value.trim() === emptyMask;
};

export const convertFromISOValue = (value: string | undefined, dayToken: string): string | undefined => {
    if (isEmptyDurationValue(dayToken, value) || !value || value === invalidDurationValue) {
        return undefined;
    }

    const format = value.includes('M') || value.includes('H') ? `d[${dayToken}] hh:mm` : `d[${dayToken}]`;

    return moment.duration(value).format(format, { trim: false, forceLength: true });
};

const fullFormatDuration = (dayToken: string): RegExp => {
    return new RegExp(`(^[0-9]([${dayToken}]|[\\s]){1,2}(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$)`);
};

const daysDuration = (dayToken: string): RegExp => {
    return new RegExp(`^[0-9]${dayToken}?$`);
};

export const everyFormatPossible = (dayToken: string): RegExp => {
    return new RegExp(
        `${fullFormatDuration(dayToken).flags}|${HoursMinutesDurationRegex.flags}|${daysDuration(dayToken).flags}`
    );
};

export const parseFullTime = (dayToken: string, value?: string): moment.Duration | undefined => {
    if (isEmptyDurationValue(dayToken, value)) {
        return undefined;
    }
    let days = NaN;
    let hours = NaN;
    let minutes = NaN;

    if (!value || value.includes(placeholderChar)) {
        return moment.duration({ days, hours, minutes });
    }

    const parts = value.split(' ');

    if (parts[0]) {
        const daysPart = parts[0].replace(dayToken, '').trim();
        days = parseInt(daysPart, 10);
    }

    if (parts[1]) {
        const hhmm = parts[1].split(':');

        if (hhmm[0]) {
            const hoursPart = hhmm[0].trim();
            hours = parseInt(hoursPart, 10);
            hours = hours > 23 ? NaN : hours;
        }

        if (hhmm[1]) {
            const minutesPart = hhmm[1].trim();
            minutes = parseInt(minutesPart, 10);
            minutes = minutes > 59 ? NaN : minutes;
        }
    }

    return moment.duration({ days, hours, minutes });
};

export const validateDurationValue = (
    value: string | undefined,
    dayToken: string,
    t: SingleTFunction,
    isRequired: boolean,
    minValue?: moment.Duration,
    maxValue?: moment.Duration
): string | undefined => {
    let durationValue: moment.Duration | undefined;

    if (!value) {
        if (isRequired) {
            return t('wf-field-error-required');
        } else {
            return undefined;
        }
    } else if (value === invalidDurationValue) {
        return t('wf-field-error-invalid-value');
    } else {
        durationValue = moment.duration(value);
    }

    if (durationValue) {
        if (
            (minValue && durationValue.asSeconds() < minValue.asSeconds()) ||
            (maxValue && durationValue.asSeconds() > maxValue.asSeconds())
        ) {
            return t('wf-field-error-range', {
                minValue: displayDurationString(dayToken, minValue),
                maxValue: displayDurationString(dayToken, maxValue),
            });
        }
    }

    return undefined;
};

export const validateDurationFormats = (value: string, dayToken: string): boolean => {
    return everyFormatPossible(dayToken).exec(value) !== null;
};

export const parseHoursMinutes = (value: string): moment.Duration => {
    const splitValue = value.trim().split(':');

    const hours = splitValue[0] === '' ? '00' : splitValue[0];
    const minutes = splitValue[1] === '' ? '00' : splitValue[1];

    return moment.duration({ days: 0, hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) });
};

export const daysToFullDateTimeMoment = (value: string): moment.Duration => {
    return moment.duration({ days: parseInt(value, 10), hours: 0, minutes: 0 });
};

export const daysToFullDateString = (value: string, dayToken: string): string => {
    if (value.includes(`${dayToken}`)) {
        const values = value.split(`${dayToken}`);
        return `${values[0]}${dayToken} 00:00`;
    }
    return `${value}${dayToken} 00:00`;
};

export const fullFormatToString = (value: string, dayToken: string): string => {
    let values;

    if (value.includes(`${dayToken} `)) {
        values = value.split(`${dayToken} `);
    } else if (value.includes(`${dayToken}`)) {
        values = value.split(`${dayToken}`);
    } else {
        values = value.split(' ');
    }
    return `${values[0]}${dayToken} ${values[1]}`;
};

export const hoursMinutesToFullDateString = (value: string, dayToken: string): string => {
    const splitValue = value.trim().split(':');
    let hours = splitValue[0] === '' ? '00' : splitValue[0];
    if (hours.length === 1) {
        hours = `0${hours}`;
    }
    let minutes = splitValue[1] === '' ? '00' : splitValue[1];
    if (minutes.length === 1) {
        minutes = `0${minutes}`;
    }
    return `0${dayToken} ${hours}:${minutes}`;
};
export const formatsToGenericFormat = (value: string, dayToken: string): moment.Duration | undefined => {
    const fullFormat = fullFormatDuration(dayToken);
    const daysFormat = daysDuration(dayToken);

    if (!validateDurationFormats(value, dayToken)) {
        return undefined;
    }

    return fullFormat.exec(value)
        ? parseFullTime(dayToken, fullFormatToString(value, dayToken))
        : HoursMinutesDurationRegex.exec(value)
          ? parseHoursMinutes(value)
          : daysFormat.exec(value)
            ? daysToFullDateTimeMoment(value)
            : undefined;
};

export const emptyDurationInput = (dayToken: string): string => {
    return `0${dayToken} 00:00`;
};

export const formatsToGenericFormatString = (
    value: string,
    dayToken: string,
    required?: boolean
): string | undefined => {
    if (required && value === '') {
        return emptyDurationInput(dayToken);
    }
    if (!required && value === '') {
        return undefined;
    }

    const fullFormat = fullFormatDuration(dayToken);
    const daysFormat = daysDuration(dayToken);
    if (!validateDurationFormats(value, dayToken)) {
        return undefined;
    }

    if (fullFormat.test(value)) {
        return fullFormatToString(value, dayToken);
    } else if (HoursMinutesDurationRegex.test(value)) {
        return hoursMinutesToFullDateString(value, dayToken);
    } else if (daysFormat.test(value)) {
        return daysToFullDateString(value, dayToken);
    }
    return undefined;
};

export const formatToValidValue = (value: string, dayToken: string): moment.Duration | undefined => {
    const fullFormat = fullFormatDuration(dayToken);
    const daysFormat = daysDuration(dayToken);

    if (!validateDurationFormats(value, dayToken)) {
        return undefined;
    }

    return fullFormat.exec(value)
        ? parseFullTime(dayToken, fullFormatToString(value, dayToken))
        : HoursMinutesDurationRegex.exec(value)
          ? parseHoursMinutes(value)
          : daysFormat.exec(value)
            ? daysToFullDateTimeMoment(value)
            : undefined;
};
