import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { PositionDialogProps } from './component';

export type PositionDialogClassKey = 'markerIcon' | 'wrapper' | 'mapContainer' | 'positionIcon';

export const PositionDialogStyleRules: StyleRulesCallback<Theme, PositionDialogProps, PositionDialogClassKey> = (
    theme: Theme
) =>
    createStyles({
        markerIcon: { cursor: 'pointer' },
        wrapper: { height: theme.custom.dialogContent.minHeight },
        mapContainer: { width: '100%', height: '100%' },
        positionIcon: {
            fontSize: 50,
            filter: 'drop-shadow( 2px 0px 2px rgba(0, 0, 0, .4))',
            cursor: 'grab',
        },
    });
