import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type DeleteVehicleTypeDialogClassKeys = 'messageContainer' | 'spinner' | 'message';

export const useStyles = makeStyles<Theme, {}, DeleteVehicleTypeDialogClassKeys>((theme) => ({
    messageContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    spinner: {
        marginRight: theme.spacing(2),
    },
    message: {
        fontStyle: 'italic',
        fontWeight: 'normal',
    },
}));
