import makeStyles from '@mui/styles/makeStyles';

import { themeSpacingToNumber } from '~/common/utils';
import { widgetDialogDimensions } from '~/components/Dialogs';

export const useStyles = makeStyles((theme) => {
    const leftPaneWidth = widgetDialogDimensions.width * 0.2;
    const formWidth = widgetDialogDimensions.width * 0.8;

    const baseInputWidthPx = 748;
    const baseInputRightMargin = themeSpacingToNumber(theme.spacing(2));

    const inputWidthForRow = (numberOfInputs: number) =>
        (baseInputWidthPx - (numberOfInputs - 1) * baseInputRightMargin) / numberOfInputs;

    return {
        root: {
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.common.white,
            fontSize: theme.typography.fontSize,
        },
        body: { overflow: 'hidden', display: 'flex' },
        closeIcon: { color: 'inherit' },
        leftPane: {
            borderRight: `1px solid ${theme.palette.divider}`,
            width: leftPaneWidth,
            display: 'inline',
            float: 'left',
            overflow: 'hidden',
        },
        vehicleTypeIcon: { color: theme.functionalItemsColors.assetType.vehicle.value },
        sectionsListItem: { '&:hover': { cursor: 'pointer' } },
        form: {
            backgroundColor: theme.palette.common.white,
            display: 'inline',
            width: formWidth,
            overflowY: 'scroll',
            overflowX: 'hidden',
            marginLeft: theme.spacing(2),
        },
        sectionTitle: {
            flexGrow: 1,
            lineHeight: '24px',
            fontWeight: theme.typography.fontWeightMedium,
            paddingTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
        baseInput: { marginBottom: theme.spacing(3), marginRight: baseInputRightMargin },
        textInput: { width: inputWidthForRow(2) },
        dimensionsNumberInput: { width: inputWidthForRow(4) },
        selectInput: { width: inputWidthForRow(2) },
        speedNumberInput: { width: inputWidthForRow(3) },
        fieldContainer: { display: 'flex', marginBottom: theme.spacing(3) },
        actionButton: { height: '100%' },
        backdrop: { position: 'absolute', zIndex: theme.zIndex.drawer, color: theme.palette.primary.main },
        select: { display: 'flex' },
    };
});
