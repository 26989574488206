import { Divider } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';
import type {
    DraggableProvided,
    DraggableStateSnapshot,
    DropResult,
    DroppableProvided,
    DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { every, isNil } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

import { buildOrderedSections } from './buildOrderedSections';
import { DragHandle } from './components/DragHandle';
import type { SectionDefinition } from './sectionDefinition';
import type { ReorderableSectionsClassKey } from './styles';

export interface ReorderableSectionsProps {
    sectionDefinitions: SectionDefinition[];
    sectionOrder: string[];
    hiddenSections?: string[];
    changeSectionOrder: (sectionOrder: string[]) => void;
    droppableId: string;
    alwaysFirstSection?: string;
}

export interface ReorderableSectionsInnerProps
    extends ReorderableSectionsProps,
        WithStyles<ReorderableSectionsClassKey> {}

export class ReorderableSectionsComponent extends React.Component<ReorderableSectionsInnerProps> {
    private buildOrderedSectionsMemoized = memoizeOne(buildOrderedSections);

    public render(): React.ReactNode {
        const {
            classes,
            sectionDefinitions,
            sectionOrder = [],
            droppableId,
            hiddenSections,
            alwaysFirstSection,
        } = this.props;

        const droppableFn = (droppableProvided: DroppableProvided, droppableStateSnapshot: DroppableStateSnapshot) => {
            const filteredSectionDefinitions = sectionDefinitions.filter((e) => e.name !== alwaysFirstSection);
            const filteredSectionOrder = sectionOrder.filter((e) => e !== alwaysFirstSection);

            const orderedSections = this.buildOrderedSectionsMemoized(filteredSectionDefinitions, filteredSectionOrder);

            const firstSectionDraggableId = `${droppableId}:${orderedSections[0] && orderedSections[0].name}`;
            const firstSectionIsDragged = droppableStateSnapshot.draggingFromThisWith === firstSectionDraggableId;

            const draggableSections = orderedSections.map((section, index) => {
                const isSectionHidden = hiddenSections?.includes(section.name);
                const draggableId = `${droppableId}:${section.name}`;

                const draggableFn = (
                    draggableProvided: DraggableProvided,
                    draggableStateSnapshot: DraggableStateSnapshot
                ) => {
                    const dragHandleElement = <DragHandle dragHandleProps={draggableProvided.dragHandleProps} />;
                    const draggableClassName = classNames(classes.draggable, {
                        [classes.dragged]: draggableStateSnapshot.isDragging,
                        [classes.hiddenSection]: isSectionHidden,
                    });

                    const shownFirstWhenFirstIsDragged =
                        index === 1 && firstSectionIsDragged && every(draggableProvided.draggableProps.style, isNil);

                    const dividerClassName = classNames({
                        /**
                         * Not rendering the Divider is causing the sections(3..n) to move to top by 1px while
                         * dragging first section over the second one. To avoid this use visibility: hidden;
                         */
                        [classes.hidden]: index === 0 || shownFirstWhenFirstIsDragged,
                    });

                    return (
                        <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            className={draggableClassName}
                        >
                            {!draggableStateSnapshot.isDragging && <Divider className={dividerClassName} />}
                            {isSectionHidden ? dragHandleElement : section.content(dragHandleElement)}
                        </div>
                    );
                };

                return (
                    <Draggable key={draggableId} draggableId={draggableId} index={index}>
                        {draggableFn}
                    </Draggable>
                );
            });

            return (
                <div
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    className={classes.droppable}
                >
                    {draggableSections}
                    {droppableProvided.placeholder}
                </div>
            );
        };

        const firstSection = sectionDefinitions.find((x) => x.name === alwaysFirstSection)?.content();
        return (
            <DragDropContext onDragEnd={this.handleDragEnd}>
                {firstSection}
                {firstSection && sectionDefinitions.length > 1 && <Divider />}
                <Droppable droppableId={droppableId}>{droppableFn}</Droppable>
            </DragDropContext>
        );
    }

    private handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const nextSectionOrder = [...this.props.sectionOrder.filter((x) => x !== this.props.alwaysFirstSection)];
        const [movedSection] = nextSectionOrder.splice(result.source.index, 1);
        nextSectionOrder.splice(result.destination.index, 0, movedSection);

        this.props.changeSectionOrder(nextSectionOrder);
    };
}
