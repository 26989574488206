import type { Action } from 'redux';

import type { StaticDataStoreState } from '~/common';
import type { SettingsStoreState } from '~/components/EnsureSettings/reducers';

import type { VehicleAdministrationChangelogsStoreState } from './components/DetailsPane/components/VehicleAdministrationChangelogsSubpage';
import { vehicleChangelogsReducer } from './components/DetailsPane/components/VehicleAdministrationChangelogsSubpage/reducers';
import type { AdminVehicleStoreState } from './reducers';
import { adminVehicleReducer } from './reducers';

export interface AdminVehicleSceneStoreState {
    adminVehicleStoreState: AdminVehicleStoreState;
    vehicleAdministrationChangelogsRoot: VehicleAdministrationChangelogsStoreState;
}

export const adminVehicleSceneReducer = (
    state: AdminVehicleSceneStoreState | undefined,
    action: Action,
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): AdminVehicleSceneStoreState => {
    const vehicleAdministration = adminVehicleReducer(
        state?.adminVehicleStoreState,
        action,
        staticDataStoreState,
        settingsStoreState
    );

    const vehicleAdministrationChangelogsRoot = vehicleChangelogsReducer(
        state?.vehicleAdministrationChangelogsRoot,
        action,
        staticDataStoreState,
        settingsStoreState
    );

    return { adminVehicleStoreState: vehicleAdministration, vehicleAdministrationChangelogsRoot };
};
