import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withAutoSizer } from '~/components/AutoSizer';

import type { ConversationsSummaryWidgetListInnerProps, ConversationsSummaryWidgetListProps } from './component';
import { ConversationsSummaryWidgetListComponent } from './component';
import { ConversationsSummaryWidgetListStyleRules } from './styles';

export const ConversationsSummaryWidgetList = compose<
    ConversationsSummaryWidgetListInnerProps,
    ConversationsSummaryWidgetListProps
>(
    setDisplayName('ConversationsSummaryWidgetList'),
    withAutoSizer,
    withStyles(ConversationsSummaryWidgetListStyleRules)
)(ConversationsSummaryWidgetListComponent);
