import { Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import React from 'react';

import { FileTypeIconFormatter, TimeFormatter, adaptiveDateTimeFormatterFactory } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { ResolvedAttachment } from '~/services/ApiClient';
import { formatBytes } from '~/services/Formatters';

import type { AttachmentComponentClassKey } from './styles';

export interface AttachmentComponentOuterProps {
    attachment: ResolvedAttachment;
    onClick: () => void;
}

export interface AttachmentComponentInnerProps
    extends AttachmentComponentOuterProps,
        InjectedTranslationProps,
        WithStyles<AttachmentComponentClassKey> {}

export const AttachmentComponent: React.FC<AttachmentComponentInnerProps> = ({ classes, t, onClick, attachment }) => {
    const fileTooltipContent = (
        <div>
            {t('file-name', { fileName: attachment.filename })}
            <br />
            {t('file-size', { fileSize: formatBytes(attachment.size) })}
        </div>
    );

    const fileTypeAndName = (
        <Tooltip className={classes.fileTypeAndName} title={fileTooltipContent}>
            <span>
                <Typography
                    variant="caption"
                    className={classes.fileType}
                    color="textSecondary"
                    data-id="message-attachment-file-type"
                >
                    <FileTypeIconFormatter value={attachment.type.key} iconProps={{ fontSize: 'small' }} />
                </Typography>
                <Typography
                    variant="caption"
                    className={classes.filename}
                    color="textPrimary"
                    data-id="message-attachment-file-name"
                >
                    {attachment.filename}
                </Typography>
            </span>
        </Tooltip>
    );

    const AdaptiveDateTimeFormatter = adaptiveDateTimeFormatterFactory();
    const fileDateTime = (
        <Tooltip title={<TimeFormatter value={attachment.dateTime} />}>
            <Typography
                variant="caption"
                className={classes.fileDateTime}
                color="textSecondary"
                data-id="message-attachment-timestamp"
            >
                <AdaptiveDateTimeFormatter value={attachment.dateTime} t={t} />
            </Typography>
        </Tooltip>
    );

    return (
        <div className={classes.root} data-id={`message-attachment:${attachment.id}`} onClick={onClick}>
            {fileTypeAndName}

            {fileDateTime}
        </div>
    );
};
