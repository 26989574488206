import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type LoginFormClassKey = 'form' | 'fields' | 'resetPassword';

export const LoginFormStyleRules: StyleRulesCallback<Theme, {}, LoginFormClassKey> = (theme: Theme) =>
    createStyles({
        form: {
            margin: theme.spacing(3, 0),
        },
        fields: {
            margin: theme.spacing(3, 5),
        },
        resetPassword: {
            margin: theme.spacing(3, 0, 0),
            display: 'block',
            textAlign: 'center',
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    });
