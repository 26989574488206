import { ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';

import { ClassificationFormatter, ReeferStatusFormatter } from '~/components/Formatters';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { ResolvedReeferStatus } from '~/services/ApiClient';
import { ClassificationType } from '~/services/ApiClient';

import { ReeferValue } from '../ReeferValue';

import type { FuelInformationClassKeys } from './styles';

export interface FuelInformationProps {
    reefer: ResolvedReeferStatus;
}

export interface FuelInformationInnerProps
    extends FuelInformationProps,
        InjectedTranslationProps,
        WithStyles<FuelInformationClassKeys> {}

export const FuelInformationComponent: React.FC<FuelInformationInnerProps> = ({ reefer, classes, t }) => {
    const notAvailable = t('not-available');

    const hours = (
        <span className={classes.reeferHours} data-id="reefer-status-hours">
            {reefer.totalAmountOfEngineHours
                ? t('reefer-run-hours', { hours: reefer.totalAmountOfEngineHours })
                : notAvailable}
        </span>
    );

    const title = (
        <span data-id="reefer-status-label">
            <ClassificationFormatter t={t} classificationKey={ClassificationType.ReeferStatus} value={reefer.status} />
        </span>
    );

    const powerMode = (
        <span data-id="reefer-status-power">
            <ClassificationFormatter
                t={t}
                classificationKey={ClassificationType.ReeferPowerMode}
                value={reefer.powerMode}
            />
        </span>
    );

    return (
        <div data-id="reefer-summary">
            <ListItem className={classes.reeferElement}>
                <ListItemAvatar>
                    <span data-status={reefer.status?.key} data-id="reefer-status-avatar">
                        <ReeferStatusFormatter value={reefer.status} withAvatar />
                    </span>
                </ListItemAvatar>

                <ListItemText primary={title} secondary={hours} />

                <div>
                    <div data-id="reefer-status-value" className={classes.reeferStatus}>
                        <ReeferValue reefer={reefer} />
                    </div>
                    <Typography variant="caption" color="textPrimary">
                        {powerMode}
                    </Typography>
                </div>
            </ListItem>
        </div>
    );
};
