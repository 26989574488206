import type { RetryableFn } from 'promise-retry';

import { ApiException } from './generated';

export const retryableErrorResponseFactory =
    <T>(fn: () => Promise<T>) =>
    async (retry: RetryableFn<T>): Promise<T> => {
        try {
            return await fn();
        } catch (error) {
            if (ApiException.isApiException(error) && error.status < 500) {
                throw error;
            }

            if (error instanceof DOMException && error.name === 'AbortError') {
                throw error;
            }

            return retry(error);
        }
    };
