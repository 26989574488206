import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import type { MonitoringVehicleEntry } from '~/data/monitoring';
import { isUndefined } from '~/libs/utility';

import type { VehicleMarkerIconBadgeClassKey } from './styles';

export interface VehicleMarkerIconBadgeProps {
    entry: MonitoringVehicleEntry;
}

export interface VehicleMarkerIconBadgeInnerProps
    extends VehicleMarkerIconBadgeProps,
        WithStyles<VehicleMarkerIconBadgeClassKey> {}

export const VehicleMarkerIconBadgeComponent: React.FC<VehicleMarkerIconBadgeInnerProps> = ({ entry, classes }) => {
    if (isUndefined(entry.vehicleStatus?.ignitionStatus)) {
        return null;
    }

    const ignitionStatusDataId = `ignition-status:${entry.vehicleStatus?.ignitionStatus ? 'on' : 'off'}`;
    const ignitionStatusClasses = classNames(classes.ignitionStatus, {
        [classes.ignitionStatusOn]: entry.vehicleStatus?.ignitionStatus,
        [classes.ignitionStatusOff]: !entry.vehicleStatus?.ignitionStatus,
    });

    return <div className={ignitionStatusClasses} data-id={ignitionStatusDataId} />;
};
