import * as React from 'react';

import { LocationSection } from '~/common';
import { PositionMiniMapSection } from '~/common/components/PositionMiniMapSection';
import type { SectionDefinition } from '~/components/Sections';
import { memoizeOne } from '~/services/Memoize';

import type { HistoryDriverEntry } from '../../../../models';
import { LocationSectionActionButtons } from '../LocationSectionActionButtons';

import { DriverEventFieldsSection, DriverEventSource } from './components/DriverEventFieldsSection';
import { SectionName } from './constants';

export const getSections = (
    entry: HistoryDriverEntry,
    collapsedSections: SectionName[],
    toggleCollapsedState: (sectionName: SectionName) => () => void,
    miniMapZoomLevel: number,
    changeMiniMapZoomLevel: (zoomLevel: number) => void
): SectionDefinition[] => {
    const locationSectionActionButtons = (
        <LocationSectionActionButtons position={entry.driverEvent.location?.position} />
    );

    const sections: SectionDefinition[] = [
        {
            name: SectionName.LOCATION,
            content: (dragHandleElement: JSX.Element) => (
                <LocationSection
                    position={entry.driverEvent.location?.position}
                    address={entry.driverEvent.location?.address}
                    datetime={entry.driverEvent.startDatetime}
                    odometer={entry.driverEvent?.startOdometer}
                    isCollapsed={collapsedSections.includes(SectionName.LOCATION)}
                    toggleCollapsed={toggleCollapsedState(SectionName.LOCATION)}
                    dragHandleElement={dragHandleElement}
                    actionButtons={locationSectionActionButtons}
                />
            ),
        },
        {
            name: SectionName.MINIMAP,
            content: (dragHandleElement: JSX.Element) => (
                <PositionMiniMapSection
                    position={entry.driverEvent.location?.position}
                    isCollapsed={collapsedSections.includes(SectionName.MINIMAP)}
                    dragHandleElement={dragHandleElement}
                    zoomLevel={miniMapZoomLevel}
                    changeZoomLevel={changeMiniMapZoomLevel}
                    toggleCollapsed={toggleCollapsedState(SectionName.MINIMAP)}
                />
            ),
        },
    ];

    if (entry.driverEvent.eventType) {
        sections.push({
            name: SectionName.ACTIVITYFIELDS,
            content: (dragHandleElement: JSX.Element) => (
                <DriverEventFieldsSection
                    isCollapsed={collapsedSections.includes(SectionName.ACTIVITYFIELDS)}
                    dragHandleElement={dragHandleElement}
                    toggleCollapsed={toggleCollapsedState(SectionName.ACTIVITYFIELDS)}
                    driverEventId={entry.driverEvent.eventId}
                    driverEventTypeId={entry.driverEvent.eventType?.id}
                    driverEventSource={DriverEventSource.DriverEvent}
                />
            ),
        });
    } else if (entry.driverEvent.completedActivity?.subActivityType) {
        sections.push({
            name: SectionName.ACTIVITYFIELDS,
            content: (dragHandleElement: JSX.Element) => (
                <DriverEventFieldsSection
                    isCollapsed={collapsedSections.includes(SectionName.ACTIVITYFIELDS)}
                    dragHandleElement={dragHandleElement}
                    toggleCollapsed={toggleCollapsedState(SectionName.ACTIVITYFIELDS)}
                    driverEventId={entry.driverEvent.eventId}
                    driverEventTypeId={entry.driverEvent.completedActivity?.subActivityType?.id}
                    driverEventSource={DriverEventSource.DriverSubActivity}
                />
            ),
        });
    }

    return sections;
};

export const getSectionsMemoized = memoizeOne(getSections);
