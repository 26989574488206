import type { ClassNameMap, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme, { classes?: ClassNameMap<string>; animationDuration: number }>((theme) => ({
    root: {
        whiteSpace: 'nowrap',
    },
    icon: {
        display: 'inline-block',
        position: 'relative',
    },
    iconBox: {
        zIndex: 3,
        height: 24,
        width: 24,
        padding: '3px 3px 3px 3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.common.white,
        boxShadow: `
                2px 2px 5px 0px rgba(0, 0, 0, 0.35);
            `,
    },
    iconBadge: {
        position: 'absolute',
        zIndex: 4,
        right: 0,
        bottom: 0,
    },
    '@keyframes assetCirclePulsate': {
        '0%': {
            transform: 'scale(0.2, 0.2)',
            opacity: 0.0,
        },
        '50%': {
            opacity: 0.5,
        },
        '100%': {
            transform: 'scale(1.2, 1.2)',
            opacity: 0.0,
        },
    },
    highlight: {
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 2,
        width: 36,
        height: 36,
        top: -6,
        left: -6,
        borderWidth: 6,
        borderStyle: 'solid',
        borderRadius: '50%',
        opacity: 0,
        animation: ({ animationDuration }) => `assetCirclePulsate ${animationDuration}ms ease-out`,
        animationIterationCount: 1,
    },
    selected: {
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 1,
        width: 48,
        height: 48,
        top: -12,
        left: -12,
        borderWidth: 12,
        borderStyle: 'solid',
        borderRadius: '50%',
        opacity: 0.3,
    },
    assetName: {
        marginLeft: '7px',
        height: '24px',
        textAlign: 'center',
        position: 'relative',
        zIndex: 3,
        display: 'inline-block',
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: '12px',
        lineHeight: '24px',
        fontSize: 14,
        fontWeight: 700,
        whiteSpace: 'nowrap',
        textShadow: `
                -1px 0 ${theme.palette.common.white},
                0 1px ${theme.palette.common.white},
                1px 0 ${theme.palette.common.white},
                0 -1px ${theme.palette.common.white}
            `,
    },
}));
