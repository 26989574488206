import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

const formatCardinalInternal = (t: SingleTFunction, cardinal: string) => t(`cardinal-${cardinal.toLowerCase()}`);

const formatCardinalMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((cardinal: string) => formatCardinalInternal(t, cardinal))
);

export const formatCardinal = (t: SingleTFunction, cardinal: string): string => formatCardinalMemoized(t)(cardinal);
