import type { ComponentEnhancer } from 'react-recompose';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withFeatureFlags } from '~/components/FeatureFlags';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { COMMUNICATION_USERPREFERENCES_KEY, requiredCommunicationUserPreferences } from '~/data/communication';

import { CONVERSATION_USERPREFERENCES_KEY, requiredConversationUserPreferences } from '../../preferences';
import { conversationsRootStoreStateSelector } from '../../selectors';

import type { DispatchProps, StateProps } from './component';
import { ensureUserConversationProfileHoc } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export type { InjectedUserMessagingProfilesProps } from './component';

export const ensureUserMessagingProfiles = (
    showLoaderWhileLoadingProfiles: boolean = true
): ComponentEnhancer<unknown, unknown> =>
    compose(
        setDisplayName('EnsureUserConversationProfile'),
        ensureUserPreferences([requiredConversationUserPreferences, requiredCommunicationUserPreferences], false),
        withFeatureFlags(),
        connect<StateProps, DispatchProps>(
            createSelector(
                conversationsRootStoreStateSelector,
                keyDataStateSelectorFactory(CONVERSATION_USERPREFERENCES_KEY),
                keyDataStateSelectorFactory(COMMUNICATION_USERPREFERENCES_KEY),
                mapStateToProps
            ),
            mapDispatchToProps
        ),
        ensureUserConversationProfileHoc(showLoaderWhileLoadingProfiles)
    );
