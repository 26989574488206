import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import { themeSpacingToNumber } from '~/common/utils';

export type ColumnGroupClassKey = 'listItem' | 'listItemIcon' | 'groupCheckbox' | 'columnCheckbox' | 'toggleButton';

export const ColumnGroupStyleRules: StyleRulesCallback<Theme, {}, ColumnGroupClassKey> = (theme: Theme) =>
    createStyles({
        listItem: {
            padding: theme.spacing(0, 1),
        },
        listItemIcon: {
            minWidth: 'inherit',
        },
        groupCheckbox: {
            padding: theme.spacing(),
        },
        columnCheckbox: {
            padding: theme.spacing(),
            marginLeft: 24 + 40 - themeSpacingToNumber(theme.spacing()), // expand button + checkbox - own padding
        },
        toggleButton: {
            cursor: 'pointer',
        },
    });
