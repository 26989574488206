import { Avatar } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { DriverActivityIconFormatter } from '~/components/Formatters';
import type { DriverActivityType, DriverName } from '~/services/ApiClient';

import type { DriverAvatarClassKey } from './styles';

export interface DriverAvatarProps {
    dataId: string;
    icon: JSX.Element;
    order: number;
    selected: boolean;
    driverActivity?: DriverActivityType;
    driver?: DriverName;
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export interface DriverAvatarInnerProps extends DriverAvatarProps, WithStyles<DriverAvatarClassKey> {}

export const DriverAvatarComponent: React.FC<DriverAvatarInnerProps> = ({
    dataId,
    icon,
    order,
    driverActivity,
    driver,
    selected,
    onClick,
    classes,
}) => {
    const driverActivityBadge = driverActivity && (
        <Avatar
            data-id="activity-badge"
            className={classNames(classes.activityAvatar, classes[`activity${driverActivity.key}`], {
                [classes.activityAvatarSelected]: selected,
            })}
        >
            <DriverActivityIconFormatter value={driverActivity.key} iconProps={{ fontSize: 'inherit' }} />
        </Avatar>
    );

    const driverElementClasses = classNames(
        classes.driver,
        { [classes.disabled]: !driver },
        { [classes.disabledSelected]: !driver && selected }
    );

    return (
        <div data-id={dataId} className={driverElementClasses} onClick={onClick} style={{ order }}>
            <Avatar
                className={classNames(classes.driverAvatar, {
                    [classes.driverAvatarSelected]: selected,
                })}
            >
                {icon}
            </Avatar>
            {driverActivityBadge}
        </div>
    );
};
