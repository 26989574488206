import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type IndividualPositionClassKey = 'root';

export const IndividualPositionStyleRules: StyleRulesCallback<Theme, {}, IndividualPositionClassKey> = () =>
    createStyles({
        root: {},
    });
