import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { RetrySnackbarInnerProps, RetrySnackbarProps } from './component';
import { RetrySnackbarComponent } from './component';

export type { RetrySnackbarProps } from './component';

export const RetrySnackbar = compose<RetrySnackbarInnerProps, RetrySnackbarProps>(
    setDisplayName('RetrySnackbar'),
    withTranslation()
)(RetrySnackbarComponent);
