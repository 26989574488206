import { Paper, Tooltip, Typography } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { TemperatureFormatter } from '~/components/Formatters';
import { ArrowDownBoldCircleIcon, ArrowUpBoldCircleIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesUnitSystem, TemperatureSensorStatus } from '~/services/ApiClient';
import { getTemperatureUnitSystemUtils } from '~/services/Formatters';

import type { TemperatureSensorClassKey } from './styles';

export interface TemperatureSensorProps {
    temperatureSensor: TemperatureSensorStatus;
    minTemperature?: number;
    maxTemperature?: number;
    unitSystem: DisplayUserPreferencesUnitSystem;
    dataId?: string;
}

export interface TemperatureSensorInnerProps
    extends TemperatureSensorProps,
        WithStyles<TemperatureSensorClassKey>,
        InjectedTranslationProps {}

export const TemperatureSensorComponent: React.FC<TemperatureSensorInnerProps> = ({
    temperatureSensor,
    minTemperature,
    maxTemperature,
    classes,
    dataId,
    unitSystem,
    t,
}) => {
    const { sensorNumber, value } = temperatureSensor;

    const temperatureTooLow = !isUndefined(minTemperature) && value < minTemperature;
    const temperatureTooHigh = !isUndefined(maxTemperature) && value > maxTemperature;

    const warningArrow = temperatureTooLow ? (
        <ArrowDownBoldCircleIcon className={classes.icon} />
    ) : temperatureTooHigh ? (
        <ArrowUpBoldCircleIcon className={classes.icon} />
    ) : undefined;

    const paperClassName = classNames(classes.root, {
        [classes.temperatureTooLow]: temperatureTooLow,
        [classes.temperatureTooHigh]: temperatureTooHigh,
    });

    const formatedMinTemperatureValue =
        !isUndefined(minTemperature) && getTemperatureUnitSystemUtils(t, unitSystem).formatter(minTemperature);
    const formatedMaxTemperatureValue =
        !isUndefined(maxTemperature) && getTemperatureUnitSystemUtils(t, unitSystem).formatter(maxTemperature);

    const bandwithTooltip =
        minTemperature && maxTemperature
            ? t('bandwitdh-span', {
                  minTemperature: formatedMinTemperatureValue,
                  maxTemperature: formatedMaxTemperatureValue,
              })
            : t('no-bandwidth');

    const finalDataId = temperatureTooLow ? `${dataId}:low` : temperatureTooHigh ? `${dataId}:high` : dataId;

    return (
        <Tooltip title={bandwithTooltip}>
            <Paper elevation={3} className={paperClassName} data-id={finalDataId}>
                <Typography variant="subtitle1" className={classes.primaryText}>
                    {warningArrow}
                    <span className={classes.value} data-id="value">
                        <TemperatureFormatter unitSystem={unitSystem} t={t} value={value} />
                    </span>
                </Typography>

                <Typography variant="caption" className={classes.secondaryText} color="textSecondary">
                    {t('nth-sensor', { sensorNumber })}
                </Typography>
            </Paper>
        </Tooltip>
    );
};
