import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { EmptyStateMessageInnerProps, EmptyStateMessageProps } from './component';
import { EmptyStateMessageComponent } from './component';
import { EmptyStateMessageStyleRules } from './styles';

export const EmptyStateMessage = compose<EmptyStateMessageInnerProps, EmptyStateMessageProps>(
    setDisplayName('EmptyStateMessage'),
    withStyles(EmptyStateMessageStyleRules)
)(EmptyStateMessageComponent);
