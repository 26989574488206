import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withSceneAnalytics, withScreenAnalytics } from '~/components/Analytics';
import { protectedComponentHoc, withUserData } from '~/components/AuthenticationData';
import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { withMenu } from '~/components/Navigation';

import type { ImpersonationInnerProps, ImpersonationProps } from './component';
import { ImpersonationComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export const Impersonation = compose<ImpersonationInnerProps, ImpersonationProps>(
    setDisplayName('Impersonation'),
    protectedComponentHoc,
    withMenu,
    withUserData(),
    ensureSettings([SettingsKey.SECURABLES]),
    connect(createSelector(settingDataSelector(SettingsKey.SECURABLES), mapStateToProps), mapDispatchToProps),
    withSceneAnalytics('impersonation'),
    withScreenAnalytics('impersonation')
)(ImpersonationComponent);
