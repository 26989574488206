import { Marker, MarkerWithPopup } from '@fv/components';
import GpsFixed from '@mui/icons-material/GpsFixed';
import LocalParking from '@mui/icons-material/LocalParking';
import { Avatar } from '@mui/material';
import type { StyledComponentProps, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback } from 'react';

import { StopPositionGroup } from '~/components/StopPositionGroup';
import type { PositionGroup } from '~/services/ApiClient';
import { PositionGroupType } from '~/services/ApiClient';

import * as utility from '../../utility';

import type { PositionGroupMarkerClassKey, PositionGroupMarkerStyleRules } from './styles';

export interface PositionGroupMarkerProps {
    positionGroup: PositionGroup;
    assetName: string;
    selected: boolean;
    dataId?: string;
    stopNumber?: number;
    highlightPositionGroup: (positionGroup?: PositionGroup) => void;
    scrollToPositionGroup: () => void;
}

export interface PositionGroupMarkerOuterProps
    extends PositionGroupMarkerProps,
        StyledComponentProps<PositionGroupMarkerClassKey> {}

export interface PositionGroupMarkerInnerProps
    extends PositionGroupMarkerProps,
        WithStyles<typeof PositionGroupMarkerStyleRules, true> {}

export const PositionGroupMarkerComponent: FC<PositionGroupMarkerInnerProps> = (props) => {
    const {
        positionGroup,
        classes,
        assetName,
        selected,
        dataId,
        stopNumber,
        highlightPositionGroup,
        scrollToPositionGroup,
    } = props;

    // eslint-disable-next-line react/jsx-no-useless-fragment
    const PositionGroupMarkerLabel = useCallback((_: { entry: PositionGroup }) => <>{assetName}</>, [assetName]);

    const PositionGroupMarkerIcon = useCallback(
        (p: { entry: PositionGroup }) => {
            const stopIcon = stopNumber ?? <LocalParking fontSize="inherit" />;
            const positionGroupEntry = p.entry;
            const positionIsStop = positionGroupEntry.type === PositionGroupType.Stop;
            return positionGroupEntry.current ? (
                <GpsFixed fontSize="inherit" data-id="current-position-icon" />
            ) : positionIsStop ? (
                <Avatar className={classes.parkAvatar} data-id="avatar:stop">
                    {stopIcon}
                </Avatar>
            ) : null;
        },
        [classes.parkAvatar, stopNumber]
    );

    const handleMouseOver = () => {
        highlightPositionGroup(positionGroup);
    };

    const position = utility.getPositionGroupLatLong(positionGroup);
    if (!position) {
        return null;
    }

    const positionIsStop = positionGroup.type === PositionGroupType.Stop;
    const size = positionIsStop ? 24 : 20;

    const icon = PositionGroupMarkerIcon({ entry: positionGroup });
    const avatarClassName = positionGroup.current ? classes.popupCurrentAvatar : classes.popupParkAvatar;
    const avatarDataId = positionGroup.current ? 'avatar:current' : positionIsStop ? 'avatar:stop' : null;
    const popupContent = (
        <div className={classes.box} data-id={`stop-popup:${positionGroup.id}`}>
            <Avatar className={avatarClassName} data-id={avatarDataId}>
                {icon}
            </Avatar>

            <StopPositionGroup positionGroup={positionGroup} />
        </div>
    );

    return (
        <MarkerWithPopup
            eventHandlers={{ click: scrollToPositionGroup, mouseover: handleMouseOver }}
            position={position}
            popupContent={popupContent}
            iconOptions={{ iconSize: [size, size], iconAnchor: [size / 2, size / 2], className: classes.markerIconDiv }}
            popupKey=""
        >
            <Marker
                entry={positionGroup}
                selected={selected}
                NameFormatter={positionGroup.current && PositionGroupMarkerLabel}
                Icon={PositionGroupMarkerIcon}
                dataId={dataId}
                classes={{
                    iconBox: classNames({
                        [classes.smallMarkerIconBox]: !positionIsStop,
                        [classes.currentAvatar]: positionGroup.current,
                        [classes.parkAvatar]: positionIsStop && !positionGroup.current,
                    }),
                    selected: classNames({
                        [classes.currentAvatarSelected]: positionGroup.current,
                        [classes.parkAvatarSelected]: positionIsStop && !positionGroup.current,
                        [classes.smallAvatarSelected]: !positionIsStop,
                    }),
                    assetName: classes.assetName,
                }}
                updateAnimationVisible={false}
                animationDuration={2000}
            />
        </MarkerWithPopup>
    );
};
