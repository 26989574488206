import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withTranslation } from '~/components/LanguageSelector';
import { dynamicHistoryStateSelector } from '~/scenes/History/selectors';

import { DriverEventFieldsSectionComponent } from './component';
import type { DriverEventFieldsSectionInnerProps, DriverEventFieldsSectionProps } from './models';
import { mapDispatchToProps, mapStateToProps } from './redux';

export * from './models';

export const DriverEventFieldsSection = compose<DriverEventFieldsSectionInnerProps, DriverEventFieldsSectionProps>(
    setDisplayName('DriverEventFieldsSection'),
    withTranslation(),
    connect(createSelector(dynamicHistoryStateSelector, mapStateToProps), mapDispatchToProps)
)(DriverEventFieldsSectionComponent);
