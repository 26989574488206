import type { Address, NearestCity, Position } from '~/services/ApiClient';

import type { HistoryVehicleEntry } from '../../../../models';

export const getAddressValue = (entry: HistoryVehicleEntry): Address | undefined =>
    entry.vehicleEvent?.location?.address;

export const getAltitudeValue = (entry: HistoryVehicleEntry): number | undefined =>
    entry.vehicleEvent?.location?.position?.altitude;

export const getCityValue = (entry: HistoryVehicleEntry): string | undefined =>
    entry.vehicleEvent?.location?.address?.city;

export const getHeadingValue = (entry: HistoryVehicleEntry): number | undefined => entry.vehicleEvent?.courseOverGround;

export const getCountryCodeValue = (entry: HistoryVehicleEntry): string | undefined =>
    entry.vehicleEvent?.location?.address?.countryCode;

export const getStatusDateValue = (entry: HistoryVehicleEntry): Date | undefined => entry.vehicleEvent?.datetime;

export const getFuelLevelValue = (entry: HistoryVehicleEntry): number | undefined => entry.vehicleEvent?.fuelLevel;

export const getTotalFuelUsageValue = (entry: HistoryVehicleEntry): number | undefined =>
    entry.vehicleEvent?.totalFuelUsed;

export const getIgnitionStatusValue = (entry: HistoryVehicleEntry): boolean | undefined =>
    entry.vehicleEvent?.ignitionStatus;

export const getLatitudeValue = (entry: HistoryVehicleEntry): number | undefined =>
    entry.vehicleEvent?.location?.position?.latitude;

export const getLongitudeValue = (entry: HistoryVehicleEntry): number | undefined =>
    entry.vehicleEvent?.location?.position?.longitude;

export const getNearestCityValue = (entry: HistoryVehicleEntry): NearestCity | undefined =>
    entry.vehicleEvent?.location?.nearestCity;

export const getOdometerValue = (entry: HistoryVehicleEntry): number | undefined => entry.vehicleEvent?.odometer;

export const getPostalCodeValue = (entry: HistoryVehicleEntry): string | undefined =>
    entry.vehicleEvent?.location?.address?.postalCode;

export const getPositionValue = (entry: HistoryVehicleEntry): Position | undefined =>
    entry.vehicleEvent?.location?.position;

export const getSpeedValue = (entry: HistoryVehicleEntry): number | undefined => entry.vehicleEvent?.speedOverGround;

export const getMsisdnValue = (entry: HistoryVehicleEntry): string | undefined => entry.vehicleEvent?.msisdn;
