import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';

import { protectedComponentStoreStateSelector } from '~/selectors';

import type { EndImpersonationInnerProps, EndImpersonationProps } from './component';
import { EndImpersonationComponent } from './component';
import { mapDispatchToEndImpersonationProps, mapStateToProps } from './redux';

const stateToPropsMapper = createSelector(protectedComponentStoreStateSelector, mapStateToProps);

export const EndImpersonation = compose<EndImpersonationInnerProps, EndImpersonationProps>(
    setDisplayName('EndImpersonation'),
    connect(stateToPropsMapper, mapDispatchToEndImpersonationProps),
    withRouter
)(EndImpersonationComponent);
