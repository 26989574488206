import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Typography } from '@mui/material';
import type { FC } from 'react';

import { isUndefined } from '~/libs/utility';

import { useStyles } from './styles';

export interface TotalCounterFooterProps extends Omit<Table.RowProps, 'children'> {
    message: string;
    totalCountElements?: number;
}

const TotalCounterFooter: FC<TotalCounterFooterProps> = (props) => {
    const { message, totalCountElements, ...rowProps } = props;

    const classes = useStyles();
    const printedMessage = !isUndefined(totalCountElements) ? `${message}: ${totalCountElements}` : message;

    return (
        <Table.NoDataRow {...rowProps}>
            <td>
                <Typography className={classes.totalCounterFooter} data-id="total-counter-footer">
                    {printedMessage}
                </Typography>
            </td>
        </Table.NoDataRow>
    );
};

TotalCounterFooter.displayName = 'TotalCounterFooter';
export { TotalCounterFooter };
