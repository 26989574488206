/* eslint-disable no-underscore-dangle */
import type { Coords, LatLng, LeafletMouseEvent, Map, Point, TileLayerOptions } from 'leaflet';
import { DomEvent, TileLayer, latLng } from 'leaflet';
import type { Request, Response } from 'superagent';
import superagent from 'superagent';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const L = require('leaflet');

export interface PixelBoundingBoxAttributes {
    right: number;
    left: number;
    top: number;
    bottom: number;
}

export interface FeatureDetails {
    latLng: LatLng;
    attributes: RawFeatureAttribute[];
    id: string;
    themeId: string;
    pixelBoundingBox: PixelBoundingBoxAttributes;
    referenceCoordinate: Point;
    referencePixelPoint: Point;
}

export interface RawFeatureAttribute {
    key: string;
    value: string;
}

export interface RunRequestQResponseProps {
    image: string;
    zoom: number;
    features?: FeatureDetails[];
}

export interface TileProps {
    _map?: Map;
    _layers?: FeatureDetails[];
    tileImage?: HTMLImageElement;
}

L.TileLayer.XServer = TileLayer.extend({
    _isrsLayer: false,
    maxConcurrentRequests: 8,
    requestQueue: [],
    activeRequests: [],
    queueId: 0,
    initialize(url: string, options: TileLayerOptions) {
        this._isrsLayer = url.indexOf('/renderMap') !== -1;

        if (!this._isrsLayer && url.indexOf('contentType=JSON') === -1) {
            throw new Error('TileLayer.XServer cannot be intatiated directly without contentType=JSON');
        }

        L.TileLayer.prototype.initialize.call(this, url, options);
    },
    onAdd(map: Map) {
        this._resetQueue();

        TileLayer.prototype.onAdd.call(this, map);
    },
    onRemove(map: Map) {
        this._resetQueue();

        TileLayer.prototype.onRemove.call(this, map);
    },
    _setView(center: LatLng, zoom: number, noPrune: boolean, noUpdate: boolean) {
        let tileZoom: number | undefined = Math.round(zoom);
        if (
            (this.options.maxZoom !== undefined && tileZoom > this.options.maxZoom) ||
            (this.options.minZoom !== undefined && tileZoom < this.options.minZoom)
        ) {
            tileZoom = undefined;
        }

        const tileZoomChanged = this.options.updateWhenZooming && tileZoom !== this._tileZoom;

        if (tileZoomChanged) {
            this._resetQueue();
        }

        L.TileLayer.prototype._setView.call(this, center, zoom, noPrune, noUpdate);
    },
    redraw() {
        this._resetQueue();

        TileLayer.prototype.redraw.call(this);
    },
    _resetQueue() {
        this.requestQueue = [];
        this.queueId += 1;

        this.activeRequests.forEach((activeRequest: Request) => {
            activeRequest.abort();
        });

        this.activeRequests = [];
    },
    async runRequestQ(
        url: string,
        request: Request,
        handleSuccess: (err: Error | undefined, resp: object | undefined) => void,
        force: boolean
    ) {
        if (!force && this.activeRequests.length >= this.maxConcurrentRequests) {
            this.requestQueue.push({
                url,
                request,
                handleSuccess,
            });
            return;
        }

        const { queueId } = this;

        const req = superagent.get(url);
        req.end((err: Error, resp: Response) => {
            this.activeRequests.splice(this.activeRequests.indexOf(request), 1);
            if (this.queueId === queueId && this.requestQueue.length) {
                const pendingRequest = this.requestQueue.shift();
                this.runRequestQ(pendingRequest.url, pendingRequest.request, pendingRequest.handleSuccess, true);
            }

            handleSuccess(err, resp);
        });

        this.activeRequests.push(req);
    },
    findElement(e: MouseEvent, container: HTMLElement): { [id: string]: FeatureDetails } | undefined {
        if (!container) {
            return undefined;
        }

        const result = {};

        const tiles = Array.prototype.slice.call(container.getElementsByTagName('img'));
        let i;
        let len;
        let tile;

        for (i = 0, len = tiles.length; i < len; i++) {
            tile = tiles[i];
            const mp = DomEvent.getMousePosition(e, tile);

            for (let j = tile._layers.length - 1; j >= 0; j--) {
                const layer = tile._layers[j];
                const width = Math.abs(layer.pixelBoundingBox.right - layer.pixelBoundingBox.left);
                const height = Math.abs(layer.pixelBoundingBox.top - layer.pixelBoundingBox.bottom);
                if (
                    layer.referencePixelPoint.x - width / 2 <= mp.x &&
                    layer.referencePixelPoint.x + width / 2 >= mp.x &&
                    layer.referencePixelPoint.y - height / 2 <= mp.y &&
                    layer.referencePixelPoint.y + height / 2 >= mp.y
                ) {
                    if (!result[layer.id]) {
                        result[layer.id] = layer;
                    }
                }
            }
        }

        if (Object.keys(result).length > 0) {
            return result;
        }

        return undefined;
    },
    _onMouseMove(e: Event) {
        if (!this._map || this._map.dragging._draggable._moving || this._map._animatingZoom) {
            return;
        }

        const found = this.findElement(e, this._container);

        if (found) {
            e.preventDefault();

            this._map._container.style.cursor = 'pointer';

            e.stopPropagation();
        } else {
            this._map._container.style.cursor = '';
        }
    },
    _onMouseDown(e: Event) {
        const found = this.findElement(e, this._container);
        if (found) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

        return true;
    },
    _onClick(e: Event) {
        const found = this.findElement(e, this._container);
        if (found) {
            e.preventDefault();

            this._openPopup(found);

            e.stopPropagation();
            return false;
        }

        return true;
    },
    _onMapClick(e: LeafletMouseEvent) {
        const found = this.findElement(e.originalEvent, this._container);
        if (found) {
            this._openPopup(found);

            return false;
        }

        return true;
    },
    buildDescriptionText(found: FeatureDetails[]) {
        let description = '';
        let isFirstLayer = true;

        found.forEach((layer: FeatureDetails) => {
            if (isFirstLayer) {
                isFirstLayer = false;
            } else {
                description += '<br>';
            }

            layer.attributes.forEach((attribute: RawFeatureAttribute) => {
                description = description.concat(
                    `${attribute.key.replace(/[A-Z]/g, ' $&')}: ${attribute.value.replace('_', ' ')}<br>`
                );
            });
        });

        return description.toLowerCase();
    },
    pixToLatLng(tileKey: Coords, point: Point) {
        const earthHalfCircum = Math.PI;
        const earthCircum = earthHalfCircum * 2.0;
        const arc = earthCircum / 2 ** tileKey.z;
        const x = -earthHalfCircum + (tileKey.x + point.x / 256.0) * arc;
        const y = earthHalfCircum - (tileKey.y + point.y / 256.0) * arc;

        return latLng((360 / Math.PI) * (Math.atan(Math.exp(y)) - Math.PI / 4), (180.0 / Math.PI) * x);
    },
    createTile(coords: Coords, done: () => void) {
        const tile: TileProps & HTMLImageElement = document.createElement('img');

        L.DomEvent.on(tile, 'load', L.bind(this._tileOnLoad, this, done, tile));
        L.DomEvent.on(tile, 'error', L.bind(this._tileOnError, this, done, tile));

        if (this.options.crossOrigin) {
            tile.crossOrigin = '';
        }

        /*
         Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
         http://www.w3.org/TR/WCAG20-TECHS/H67
        */
        tile.alt = '';

        /*
         Set role="presentation" to force screen readers to ignore this
         https://www.w3.org/TR/wai-aria/roles#textalternativecomputation
        */
        tile.setAttribute('role', 'presentation');

        const tileUrl = this.getTileUrl(coords);
        let request = {};

        if (this._isrsLayer) {
            // Modify/extend this object for customization, for example the stored profile
            request = {
                mapSection: {
                    $type: 'MapSectionByTileKey',
                    zoomLevel: coords.z,
                    x: coords.x,
                    y: coords.y,
                },
                imageOptions: {
                    width: 256,
                    height: 256,
                },
                resultFields: {
                    image: true,
                },
            };

            if (this.options.requestExtension) {
                request = L.extend(request, this.options.requestExtension);
            }
        }

        tile._map = this._map;
        tile._layers = [];

        this.runRequestQ(
            tileUrl,
            request,
            L.bind((error: Error, response: Response) => {
                if (!this._map) {
                    return;
                }

                if (error) {
                    tile.src = '';
                    return;
                }

                const resp: RunRequestQResponseProps = JSON.parse(response.text);

                const prefixMap = {
                    iVBOR: 'data:image/png;base64,',
                    R0lGO: 'data:image/gif;base64,',
                    '/9j/4': 'data:image/jpeg;base64,',
                    Qk02U: 'data:image/bmp;base64,',
                };

                const rawImage = resp.image;
                tile.src = prefixMap[rawImage.substr(0, 5)] + rawImage;

                if (resp.features) {
                    const objectInfos = resp.features;

                    objectInfos.forEach((objectInfo: FeatureDetails) => {
                        // eslint-disable-next-line no-param-reassign
                        objectInfo.latLng = this.pixToLatLng(coords, objectInfo.referencePixelPoint);
                        if (tile._layers) {
                            tile._layers.push(objectInfo);
                        }
                    });
                }
            }, this)
        );

        return tile;
    },
});

export const createXServerTileLayer = (url: string, options: TileLayerOptions): TileLayer => {
    return new L.TileLayer.XServer(url, options);
};
