import moment from 'moment';
import type { Dispatch } from 'redux';

import type { StaticDataStoreState } from '~/common';
import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { SceneAssetSelectorUserPreferences } from '~/components/SceneAssetSelector';
import { queryTachoFilesAction } from '~/data/tacho';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import { isUndefined } from '~/libs/utility';
import type { RetrievableHashedData } from '~/reducers';
import { reportError } from '~/reporting';
import type { AssetReference, Driver, Securables, TachoDataSettings, Vehicle } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { changeDateTimeRangeAction, clearDataAction } from './data';
import type { TachoFileEntry } from './models';
import type { TachoFilesUserPreferences } from './preferences';
import { TACHOFILES_USERPREFERENCES_KEY } from './preferences';
import type { TachoFilesStoreState } from './reducers';

export interface TachoFilesReduxProps
    extends Omit<DispatchProps, 'changeLeftPaneVisibility'>,
        StateProps,
        MergedProps {}

export interface MergedProps {
    toggleLeftPaneVisibility: () => void;
}

export interface DispatchProps {
    changeDateTimeRange: (value: DateTimeRange) => void;
    clearData: () => void;
    getTachoFiles: (
        startDateTime: Date,
        stopDateTime: Date,
        vehicleIds: number[] | undefined,
        driverIds: number[] | undefined
    ) => void;
    changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => void;
}

export interface StateProps {
    canViewTachographFiles: boolean;
    canViewDriverCardFiles: boolean;
    authorized: boolean;
    rmsEnabled: boolean;
    dateTimeRange: DateTimeRange;
    dateTimeThreshold: { minDate: Date; maxDate: Date };
    tachoFilesEntries: TachoFileEntry[];
    selectedVehicleIds?: number[];
    selectedDriverIds?: number[];
    contentLoading: boolean;
    leftPaneIsOpen: boolean;
    loading: boolean;
    rejected: boolean;
}

const getDefaultDateTimeRange = memoizeOne(() => {
    const endDate = moment().endOf('day').toDate();
    const startDate = moment().startOf('day').subtract(moment.duration(1, 'days')).toDate();
    return { startDate, endDate };
});

const getSelectedAuthorizedVehicles = memoizeOne(
    (vehicles: RetrievableHashedData<Vehicle>, selectedAssetIds?: AssetReference[]) => {
        let authorizedVehicleIds: number[] | undefined;

        if (vehicles.fulfilled && vehicles.data?.hash) {
            const selectedVehiclesIds = selectedAssetIds
                ?.filter((selectedAsset) => selectedAsset.type === AssetType.Vehicle)
                .map((selectedAsset) => selectedAsset.id);
            authorizedVehicleIds = selectedVehiclesIds?.filter((vehicleId) => {
                const authorizedVehicle = vehicles.data.hash[vehicleId];
                return !isUndefined(authorizedVehicle);
            });
        }

        return authorizedVehicleIds;
    }
);

const getSelectedAuthorizedDrivers = memoizeOne(
    (drivers: RetrievableHashedData<Driver>, selectedAssetIds?: AssetReference[]) => {
        let authorizedDriverIds: number[] | undefined;

        if (drivers.fulfilled && drivers.data?.hash) {
            const selectedDriversIds = selectedAssetIds
                ?.filter((selectedAsset) => selectedAsset.type === AssetType.Driver)
                .map((selectedAsset) => selectedAsset.id);

            authorizedDriverIds = selectedDriversIds?.filter((driverId) => {
                const authorizedDriver = drivers.data.hash[driverId];
                return !isUndefined(authorizedDriver);
            });
        }

        return authorizedDriverIds;
    }
);

export const mapStateToProps = (
    securables: Securables,
    tachoDataSettings: TachoDataSettings,
    tachoFilesStoreState: TachoFilesStoreState,
    staticDataStoreState: StaticDataStoreState,
    tachoFilesUserPreferences: TachoFilesUserPreferences,
    tachoFilesAssetSelectorUserPreferences: SceneAssetSelectorUserPreferences
): StateProps => {
    const { vehicles, drivers } = staticDataStoreState;
    const { tachoFiles, tachoFilesEntries } = tachoFilesStoreState;
    const {
        tachoFile: { tachographFiles, driverCardFiles },
    } = securables;

    return {
        canViewTachographFiles: tachographFiles,
        canViewDriverCardFiles: driverCardFiles,
        authorized: securables.tachoFile.driverCardFiles || securables.tachoFile.tachographFiles,
        rmsEnabled: securables.tachoFile.rms && tachoDataSettings.rmsEnabled,
        dateTimeRange: tachoFilesStoreState.dateTimeRange ?? getDefaultDateTimeRange(),
        dateTimeThreshold: {
            minDate: moment().startOf('date').subtract(364, 'days').toDate(),
            maxDate: moment().endOf('date').toDate(),
        },
        loading: !vehicles.fulfilled || !drivers.fulfilled || !tachoFiles.fulfilled,
        rejected: vehicles.rejected || drivers.rejected || tachoFiles.rejected,
        tachoFilesEntries,
        contentLoading: tachoFiles.pending,
        leftPaneIsOpen: tachoFilesUserPreferences.leftPaneIsOpen,
        selectedVehicleIds: tachographFiles
            ? getSelectedAuthorizedVehicles(vehicles, tachoFilesAssetSelectorUserPreferences.selectedAssetIds)
            : undefined,
        selectedDriverIds: driverCardFiles
            ? getSelectedAuthorizedDrivers(drivers, tachoFilesAssetSelectorUserPreferences.selectedAssetIds)
            : undefined,
    };
};

export const mapDispatchProps = (dispatch: Dispatch): DispatchProps => ({
    changeDateTimeRange: (value: DateTimeRange) => {
        dispatch(changeDateTimeRangeAction(value));
    },
    clearData: () => {
        dispatch(clearDataAction());
    },
    getTachoFiles: (
        startDateTime: Date,
        stopDateTime: Date,
        vehicleIds: number[] | undefined,
        driverIds: number[] | undefined
    ) => {
        dispatch(queryTachoFilesAction(startDateTime, stopDateTime, vehicleIds, driverIds)).catch(reportError);
    },
    changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => {
        dispatch(updateUserPreferencesAction(TACHOFILES_USERPREFERENCES_KEY, { leftPaneIsOpen }));
    },
});

const toggleLeftPaneVisibilityMemoized = memoizeOne(
    (changeLeftPaneVisibility: (leftPaneIsOpen: boolean) => void, leftPaneIsOpen: boolean) => () => {
        changeLeftPaneVisibility(!leftPaneIsOpen);
    }
);

export const mergeProps = (
    { leftPaneIsOpen, selectedDriverIds, selectedVehicleIds, ...restStateProps }: StateProps,
    { changeLeftPaneVisibility, ...restDispatchProps }: DispatchProps,
    { ...restOwnProps }: object
): TachoFilesReduxProps => {
    const leftPaneForceOpen =
        selectedVehicleIds?.length === 0 && selectedDriverIds?.length === 0 ? false : leftPaneIsOpen;

    return {
        ...restStateProps,
        ...restDispatchProps,
        ...restOwnProps,
        toggleLeftPaneVisibility: toggleLeftPaneVisibilityMemoized(changeLeftPaneVisibility, leftPaneForceOpen),
        leftPaneIsOpen: leftPaneForceOpen ? true : leftPaneIsOpen,
        selectedDriverIds,
        selectedVehicleIds,
    };
};
