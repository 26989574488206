import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { SensitiveDataInnerProps, SensitiveDataProps } from './component';
import { SensitiveDataComponent } from './component';
import { SensitiveDataStyleRules } from './styles';

export const SensitiveData = compose<SensitiveDataInnerProps, SensitiveDataProps>(
    setDisplayName('SensitiveData'),
    withStyles(SensitiveDataStyleRules)
)(SensitiveDataComponent);
