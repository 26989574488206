import { withTranslation } from 'react-i18next';
import { compose, setDisplayName, withProps } from 'react-recompose';

import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { withDisplayPreferences } from '~/components/DisplayPreferences';
import type { GridHeaderProps, GridListProps } from '~/components/Grid';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { SceneListViewHeaderProps, SceneListViewProps } from '~/components/SceneList';
import { SceneListViewFactory, SceneListViewHeaderFactory } from '~/components/SceneList';
import type { MonitoringDriverEntry } from '~/data/monitoring';

import { monitoringDriverEntryEquals } from '../../../../utils';

import { MONITORING_LIST_DRIVERS_USERPREFERENCES_KEY } from './consts';
import { filterMonitoringDataSourceMemoized } from './filter';
import { getColumnsMemoized, getRowId } from './grid';
import { defaultDriversListUserPreferences } from './preferences';

const DriverListViewComponent = SceneListViewFactory<MonitoringDriverEntry>(
    MONITORING_LIST_DRIVERS_USERPREFERENCES_KEY,
    defaultDriversListUserPreferences,
    getRowId,
    monitoringDriverEntryEquals,
    filterMonitoringDataSourceMemoized
);

const DriverListViewHeaderComponent = SceneListViewHeaderFactory<MonitoringDriverEntry>(
    MONITORING_LIST_DRIVERS_USERPREFERENCES_KEY,
    defaultDriversListUserPreferences,
    filterMonitoringDataSourceMemoized,
    'monitoring'
);

export const DriverListView = compose<SceneListViewProps<MonitoringDriverEntry>, GridListProps<MonitoringDriverEntry>>(
    setDisplayName('DriverListView'),
    withDisplayPreferences(),
    withTranslation(),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps) => ({
        columns: getColumnsMemoized(
            ownProps.t,
            ownProps.displayPreferences.unitSystem,
            ownProps.displayPreferences.driverDisplayFormat,
            ownProps.displayPreferences.vehicleDisplayFormat
        ),
        gridDataId: 'monitoring-grid-container',
    }))
)(DriverListViewComponent);

export const DriverListViewHeader = compose<
    SceneListViewHeaderProps<MonitoringDriverEntry>,
    GridHeaderProps<MonitoringDriverEntry>
>(
    setDisplayName('DriverListViewHeader'),
    withDisplayPreferences(),
    withTranslation(),
    withProps((ownProps: InjectedDisplayPreferencesProps & InjectedTranslationProps) => ({
        columns: getColumnsMemoized(
            ownProps.t,
            ownProps.displayPreferences.unitSystem,
            ownProps.displayPreferences.driverDisplayFormat,
            ownProps.displayPreferences.vehicleDisplayFormat
        ),
        excelSheetTitle: ownProps.t('monitoring'),
    }))
)(DriverListViewHeaderComponent);
