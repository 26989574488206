import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withTranslation } from '~/components/LanguageSelector';

import { loginStoreStateSelector } from '../../../../selectors';

import type {
    CustomerCareLoginFormDispatchProps,
    CustomerCareLoginFormInnerProps,
    CustomerCareLoginFormProps,
    CustomerCareLoginFormStateProps,
} from './component';
import { CustomerCareLoginFormComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { LoginFormStyleRules } from './styles';

const stateToPropsMapper = createSelector(loginStoreStateSelector, mapStateToProps);

export const CustomerCareLoginForm = compose<CustomerCareLoginFormInnerProps, CustomerCareLoginFormProps>(
    setDisplayName('CustomerCareLoginForm'),
    withStyles(LoginFormStyleRules, { withTheme: true }),
    withTranslation(),
    connect<CustomerCareLoginFormStateProps, CustomerCareLoginFormDispatchProps, CustomerCareLoginFormProps>(
        stateToPropsMapper,
        mapDispatchToProps
    )
)(CustomerCareLoginFormComponent);
