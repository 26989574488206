import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const TruckAttributesOffIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <rect x="7.78" y="15.46" width="0.99" height="2.5" rx="0.2" />
            <path d="M16.24 15.66a.2.2 0 0 0-.21-.2H15.8L3.31 3 2 4.23l.47.47A11.85 11.85 0 0 0 0 12 12 12 0 0 0 12 24a11.85 11.85 0 0 0 7.3-2.51l.47.47L21 20.69l-4.8-4.8ZM12 21.73A9.75 9.75 0 0 1 4.05 6.24L7.66 9.85v3.9a.51.51 0 0 0 .51.51H9.25v.59h3.41l.81.81h0v.71h-.82a.76.76 0 0 0-1.37 0h-.79v-.71a.2.2 0 0 0-.2-.2H9.67a.2.2 0 0 0-.2.2v2.09a.21.21 0 0 0 .2.21h.62a.21.21 0 0 0 .2-.21v-.88h.74a.75.75 0 0 0 .73.61.76.76 0 0 0 .74-.61h.77v.88a.2.2 0 0 0 .2.21h.61a.21.21 0 0 0 .21-.21V16.68l.73.73v.34a.21.21 0 0 0 .2.21h.34l1.92 1.91A9.68 9.68 0 0 1 12 21.73Z" />
            <path d="M12 0A11.92 11.92 0 0 0 5.09 2.2L6.65 3.76A9.77 9.77 0 0 1 20.16 17.27l1.64 1.64A12 12 0 0 0 12 0Z" />
            <path d="M16.41 6.82a.51.51 0 0 0-.51-.51H9.2l7.21 7.21Z" />
        </SvgIcon>
    );
};
TruckAttributesOffIcon.displayName = 'TruckAttributesOffIcon';

export { TruckAttributesOffIcon };
