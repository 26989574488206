import * as React from 'react';

import { VehicleNameFormatter } from '~/components/Formatters';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import type { DisplayUserPreferencesVehicleDisplayFormat } from '~/services/ApiClient';

export interface VehicleMarkerNameFormatterProps {
    entry: MonitoringVehicleEntry;
    vehicleDisplayNameFormat: DisplayUserPreferencesVehicleDisplayFormat;
}

export interface VehicleMarkerNameFormatterInnerProps extends VehicleMarkerNameFormatterProps {}

const VehicleMarkerNameFormatter: React.FC<VehicleMarkerNameFormatterInnerProps> = ({
    entry,
    vehicleDisplayNameFormat,
}) => {
    return <VehicleNameFormatter value={entry.vehicle} vehicleDisplayNameFormat={vehicleDisplayNameFormat} />;
};
VehicleMarkerNameFormatter.displayName = 'VehicleMarkerNameFormatter';

export { VehicleMarkerNameFormatter };
