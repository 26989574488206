import type * as React from 'react';
import { compose, setDisplayName } from 'react-recompose';
import type { OptionProps as ReactSelectOptionProps } from 'react-select/src/components/Option';

import type { Option } from '../../models';

import type { OptionInnerProps, OptionProps } from './component';
import { OptionComponentFactory } from './component';

export const OptionFactory: <T>(
    CustomChildComponent?: React.ComponentType<{ option: Option<T> }>
) => React.ComponentType<ReactSelectOptionProps<Option<T>, false>> = <T>(
    CustomChildComponent?: React.ComponentType<{ option: Option<T> }>
) =>
    compose<OptionInnerProps<T>, OptionProps<T>>(setDisplayName('Option'))(
        OptionComponentFactory<T>(CustomChildComponent)
    );
