import { uniq } from '~/libs/utility';
import type { DriverActivitiesUpdate } from '~/services/ApiClient';

import { filterDeletedDriverActivities } from './reducers.filterDeletedDriverActivities';

export const mergeDeletedDriverActivities = (
    pendingDriverActivitiesUpdates: DriverActivitiesUpdate,
    nextDriverActivityUpdate: DriverActivitiesUpdate
): number[] =>
    uniq([
        ...filterDeletedDriverActivities(pendingDriverActivitiesUpdates, nextDriverActivityUpdate),
        ...filterDeletedDriverActivities(nextDriverActivityUpdate, pendingDriverActivitiesUpdates),
    ]);
