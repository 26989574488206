import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import { formatLanguage } from '~/services/Formatters';

import { SectionName } from '../../constants';

import type { CompanySectionProps } from './models';

const CompanySection: FC<CompanySectionProps> = (props) => {
    const { adminCompanyCard, dragHandleElement, isCollapsed, toggleCollapsed } = props;
    const { t } = useTranslation();

    const keyValueItems: KeyValueItem[] = [
        {
            valueDataId: 'company-card-name',
            label: t('company-card-name'),
            value: adminCompanyCard.name ? <span> {adminCompanyCard.name}</span> : undefined,
        },
        {
            valueDataId: 'company-card-address',
            label: t('company-card-address'),
            value: adminCompanyCard.address ? <span> {adminCompanyCard.address}</span> : undefined,
        },
        {
            valueDataId: 'company-card-preferred-language',
            label: t('company-card-preferred-language'),
            value: adminCompanyCard.preferredLanguage ? (
                <span> {formatLanguage(t, adminCompanyCard.preferredLanguage)}</span>
            ) : undefined,
        },
    ];
    return (
        <ReorderableSection
            dataId="company-card-administration-company-section"
            title={t(SectionName.COMPANY)}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="company-list" />
        </ReorderableSection>
    );
};
CompanySection.displayName = 'CompanySection';
export default CompanySection;
