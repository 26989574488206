import { createAction } from 'redux-actions';

import type { MapViewport } from '~/common';
import { logEvent } from '~/services/Analytics';
import type { RequiredAndNonNullable } from '~/types';

import { ActionTypeKeys } from './actionTypes';

export const changeViewportAction = createAction(
    ActionTypeKeys.SCENEMAP_CHANGEVIEWPORT,
    (viewport: RequiredAndNonNullable<MapViewport>) => viewport
);

export const showHistoryMapAction = createAction(
    ActionTypeKeys.SCENEMAP_HISTORY,
    (showHistoryMap: boolean) => showHistoryMap
);

export const showAllSelectedAssetsAction = createAction(ActionTypeKeys.SCENEMAP_SHOWALLSELECTEDASSETS);

export const panToAssetAction = createAction(
    ActionTypeKeys.SCENEMAP_PANTOASSET,
    (category: string, eventName: string, label: string, eventTrigger: string) => {
        logEvent(category, eventName, label, { eventTrigger });
        return undefined;
    }
);

export const changeSearchQueryAction = createAction<string | undefined, string, string, string | undefined>(
    ActionTypeKeys.SCENEMAP_CHANGESEARCHQUERY,
    (_: string, searchQuery: string | undefined) => {
        if (searchQuery) {
            logEvent('map-view', 'search', `Search for '${searchQuery}'`);
        }
        return searchQuery;
    },
    (key: string) => key
);
