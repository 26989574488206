import type { StoreState } from '~/reducers';
import { rtdsSchedulesSceneStateSelector } from '~/selectors';

import type { RtdsScheduleChangelogsStoreState } from './components/DetailsPane/components/RtdsScheduleChangelogsSubpage';
import type { RtdsSchedulesEntry } from './models';
import type { DynamicRtdsScheduleStoreState } from './reducers';

export const dynamicRtdsSchedulesStateSelector = (s: StoreState): DynamicRtdsScheduleStoreState => {
    return rtdsSchedulesSceneStateSelector(s).rtdsSchedulesRoot.dynamicRtdsSchedules;
};

export const rtdsScheduleEntriesSelector = (s: StoreState): RtdsSchedulesEntry[] => {
    return dynamicRtdsSchedulesStateSelector(s).rtdsSchedulesEntries;
};

export const rtdsScheduleChangelogsSelector = (s: StoreState): RtdsScheduleChangelogsStoreState => {
    return rtdsSchedulesSceneStateSelector(s).rtdsScheduleChangelogsRoot;
};
