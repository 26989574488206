import { actionGenerator } from '~/common';

const MODULE_PREFIX = 'GEOZONES_ADMINISTRATION';
const ACTIONS_KEYS = {
    LIST: `[${MODULE_PREFIX}] LIST`,
    GET_GEOZONES_ASSIGNMENTS: `[${MODULE_PREFIX}] GET_GEOZONES_ASSIGNMENTS`,
} as const;

const ACTIONS = {
    ...actionGenerator(ACTIONS_KEYS, MODULE_PREFIX),
    UPDATE_GEOZONES: `[${MODULE_PREFIX}] UPDATE_GEOZONES`,
    DELETE_GEOZONES: `[${MODULE_PREFIX}] DELETE_GEOZONES`,
} as const;

export { ACTIONS, ACTIONS_KEYS };
