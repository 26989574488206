import type { SingleTFunction } from '~/components/LanguageSelector';
import type { Classification, ClassificationType } from '~/services/ApiClient';

import { formatClassification } from './formatClassification';

export const formatClassifications = (
    t: SingleTFunction,
    classificationKey: ClassificationType,
    classifications: Classification[]
): string => {
    return classifications
        .map((classification) => {
            return formatClassification(t, classificationKey, classification);
        })
        .join(', ');
};
