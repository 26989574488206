import { SvgIcon } from '@mui/material';
import * as React from 'react';

import type { CustomIconInnerProps } from '../customIconProps';

const TrafficInfoOnIcon: React.FunctionComponent<CustomIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="none" d="M0 0H24V24H0ZM0 0H24V24H0ZM0 0H24V24H0ZM0 0H24V24H0Z" />
            <path
                d="M9 11.57l1.29-3.89h7.78l1.3 3.89m-1.3 4.32a1.3 1.3 0 0 1-1.3-1.3h0a1.3 1.3 0 1 1 1.3 1.3h0m-7.78 0A1.3 1.3 0 0 1 9 14.59H9a1.3 1.3 0 1 1 1.29
            1.3h0m9-8.64a1.31 1.31 0 0 0-1.23-.87H10.27A1.31 1.31 0 0 0 9 7.25L7.25 12.43v6.92a.86.86 0 0 0 .86.86H9a.87.87 0 0 0 .86-.86v-.87h8.64v.87a.87.87 0 0
            0 .87.86h.86a.86.86 0 0 0 .86-.86V12.43L19.28 7.25M15 4.65a1.32 1.32 0 0 0-1.23-.86H6a1.31 1.31
            0 0 0-1.23.86L2.93 9.84v6.91a.86.86 0 0 0 .86.87h.86a.87.87 0 0 0 .87-.87V13.22A1.3 1.3 0 0 1 6 10.7H6L6.62 9h-2L6 5.09H15.1Z"
            />
        </SvgIcon>
    );
};
TrafficInfoOnIcon.displayName = 'TrafficInfoOnIcon';

export { TrafficInfoOnIcon };
