import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { FlagIcon } from '~/components/Icons';

import type { Language, LanguageMenuProps, LanguageSelectorInnerProps } from './models';

const LanguageSelectorComponent: FC<LanguageSelectorInnerProps> = (props) => {
    const { availableLanguages, changeLanguage, retrieveLanguages, currentLanguage } = props;
    const [languageMenu, setLanguageMenu] = useState<LanguageMenuProps>({ open: false });

    useEffect(() => {
        retrieveLanguages();
    }, [retrieveLanguages]);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        setLanguageMenu({ open: true, anchorEl: e.currentTarget });
    };

    const handleClose = (): void => {
        setLanguageMenu({ open: false });
    };

    const handleChangeLanguage = (language: Language) => {
        changeLanguage(language);
        setLanguageMenu({ open: false });
    };

    if (!currentLanguage || !availableLanguages.length) {
        return null;
    }

    return (
        <div>
            <Button
                aria-owns={languageMenu.open ? 'language-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                data-id="language-selector"
            >
                <ListItemIcon>
                    <FlagIcon code={currentLanguage.flag} />
                </ListItemIcon>
                <ListItemText primary={currentLanguage.name} />
            </Button>
            <Menu id="language-menu" open={languageMenu.open} anchorEl={languageMenu.anchorEl} onClose={handleClose}>
                {availableLanguages.map((item) => (
                    <MenuItem
                        key={item.code}
                        selected={item.code === currentLanguage.code}
                        onClick={() => handleChangeLanguage(item)}
                        data-id={`language-menu:${item.code}`}
                    >
                        <ListItemIcon>
                            <FlagIcon code={item.flag} />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

LanguageSelectorComponent.displayName = 'LanguageSelector';
export default LanguageSelectorComponent;
