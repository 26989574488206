export enum ColumnName {
    UAID = 'uaid',
    VIN = 'vin',
    VRN = 'vrn',
    UNITID = 'unit-id',
    ALIAS = 'alias',
    MSISDN = 'msisdn',
    DEVICETYPE = 'device-type',
    VEHICLECATEGORY = 'vehicle-category',
    NETWORKID = 'network-id',
    TAG = 'tag',
    DEPOT = 'depot',
    VEHICLETYPE = 'vehicle-type',
    BILLINGGROUP = 'billing-group',
    BILLINGSTATUS = 'billing-status',
    COMPANYCARDID = 'company-card-id',
    TACHOGRAPH = 'tachograph',
    TACHOPROTOCOL = 'tacho-protocol',
    TACHOGENERATION = 'tacho-generation',
    ONEMINUTERULEENABLED = 'one-minute-rule-enabled',
    ALERTSANDPANIC = 'alerts-and-panic',
    DRIVERCARDS = 'driver-cards',
    DRIVERCOACH = 'driver-coach',
    DRIVERHOURS = 'driver-hours',
    DSE = 'dse',
    FREQUENTPOSITIONING = 'frequent-positioning',
    FUELSITEADVICE = 'fuel-site-advice',
    KPI2 = 'kpi2',
    LANDMARKS = 'landmarks',
    RTDS = 'rtds',
    TEXTMESSAGING = 'text-messaging',
    TRAILERS = 'trailers',
    WORKFLOW = 'workflow',
    GUIDEDNAVIGATION = 'guided-navigation',
    NAVIGATION = 'navigation',
    IMAGECAPTURING = 'image-capturing',
    TIRECONTROL = 'tire-control',
    APPSTORE = 'app-store',
    TRUCKIDENTIFICATION = 'truck-identification',
    TRAILERCONTROLSERVICE = 'trailer-control-service',
    STARTINHIBITOR = 'start-inhibitor',
    TELLTALESSERVICE = 'tell-tales-service',
    J1939DATASERVICE = 'j1939-data-service',
    NAVIGATIONETA = 'navigation-eta',
    PRIMARY_FUEL_TANK_SIZE = 'primary-fuel-tank-size',
    SECONDARY_FUEL_TANK_SIZE = 'secondary-fuel-tank-size',
    AD_BLUE_TANK_SIZE = 'ad-blue-tank-size',
    PRIMARY_FUEL_TANK_TYPE = 'primary-fuel-tank-type',
    SECONDARY_FUEL_TANK_TYPE = 'secondary-fuel-tank-type',
}
