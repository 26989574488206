import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DaySeparatorInnerProps, DaySeparatorProps } from './component';
import { DaySeparatorComponent } from './component';
import { DaySeparatorStyleRules } from './styles';

export const DaySeparator = compose<DaySeparatorInnerProps, DaySeparatorProps>(
    setDisplayName('DaySeparator'),
    withTranslation(),
    withStyles(DaySeparatorStyleRules)
)(DaySeparatorComponent);
