import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDisplayPreferences } from '~/common/hooks';
import { AssetAvatar } from '~/components/AssetAvatar';
import { DriverActivityIconFormatter, VehicleNameFormatter } from '~/components/Formatters';
import { TruckIcon } from '~/components/Icons';
import type { ResolvedVehicle, VehicleCategory } from '~/services/ApiClient';
import { formatVehicleCategory } from '~/services/Formatters';

import type { VehicleProps } from './models';
import { useStyles } from './styles';

const VehicleComponent: FC<VehicleProps> = (props) => {
    const { vehicle, activity } = props.driverStatus;
    const classes = useStyles();
    const displayPreferences = useDisplayPreferences();
    const { t } = useTranslation();

    return (
        <ListItem className={classNames({ [classes.disabled]: !vehicle })}>
            <ListItemAvatar>
                <AssetAvatar
                    icon={<TruckIcon />}
                    badge={
                        activity && (
                            <Avatar
                                className={classNames(classes.activityAvatar, classes[`activity${activity.key}`])}
                                data-id="driver-activity-badge"
                            >
                                <DriverActivityIconFormatter value={activity.key} iconProps={{ fontSize: 'inherit' }} />
                            </Avatar>
                        )
                    }
                    classes={{ avatar: classes.avatar, badge: classes.badge }}
                />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography data-id="vehicle-name" className={classNames({ [classes.disabledTitle]: !vehicle })}>
                        {vehicle ? (
                            <VehicleNameFormatter
                                value={vehicle as ResolvedVehicle}
                                vehicleDisplayNameFormat={displayPreferences.vehicleDisplayFormat}
                            />
                        ) : (
                            t('not-connected')
                        )}
                    </Typography>
                }
                secondary={
                    <div className={classes.line}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            className={classes.vehicleCategory}
                            data-id="vehicle-category"
                        >
                            {vehicle?.category
                                ? formatVehicleCategory(t, vehicle.category as VehicleCategory)
                                : t('vehicle')}
                        </Typography>
                        {vehicle?.vehicleType ? (
                            <>
                                <Typography variant="body2" color="textSecondary" className={classes.delimiter}>
                                    •
                                </Typography>
                                <Typography variant="body2" color="textSecondary" data-id="vehicle-type">
                                    {vehicle.vehicleType.name}
                                </Typography>
                            </>
                        ) : null}
                    </div>
                }
            />
        </ListItem>
    );
};

VehicleComponent.displayName = 'Vehicle';
export default VehicleComponent;
