import { useEffect, useMemo } from 'react';

import { debounce } from './utility';

export interface UseDebounceArgs<T extends (...params: Parameters<T>) => ReturnType<T>> {
    func: T;
    delay: number;
}

const useDebounce = <T extends (...params: Parameters<T>) => ReturnType<T>>({ func, delay }: UseDebounceArgs<T>) => {
    const debounced = useMemo(() => debounce(func, delay), [delay, func]);

    useEffect(() => {
        return () => {
            debounced.cancel();
        };
    }, [debounced]);

    return debounced;
};

export { useDebounce };
