import { Avatar, Typography } from '@mui/material';
import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CoDriverIcon, DriverIcon, TrailerIcon } from '~/components/Icons';
import { TitledTruncatedText } from '~/components/TitledTruncatedText';
import type { DriverName, NamedTrailerReference } from '~/services/ApiClient';
import { formatDriverName, formatTrailerName } from '~/services/Formatters';

import type { VehicleInfoBoxInnerProps } from './models';

const VehicleInfoBox: FC<VehicleInfoBoxInnerProps> = (props) => {
    const { entry, driverDisplayNameFormat, trailerDisplayFormat, classes } = props;

    const { vehicleDriverHoursStatus, trailerConnection } = entry;
    const { t } = useTranslation();
    const driverIcon = <DriverIcon className={classes.icon} />;
    const coDriverIcon = <CoDriverIcon className={classes.icon} />;
    const trailerIcon = <TrailerIcon className={classes.icon} />;
    const driver = vehicleDriverHoursStatus?.driverHoursStatus?.driver;
    const coDriver = vehicleDriverHoursStatus?.coDriverHoursStatus?.driver;
    const trailer = trailerConnection?.trailerReference;
    const trailerAuthorized = !!trailerConnection?.trailer;

    const displayDriverInfo = (d: DriverName, dataId: string, icon: JSX.Element) => {
        const formattedName = formatDriverName(d, driverDisplayNameFormat);
        return (
            <div className={classes.infoBoxWrapper}>
                <Avatar className={classes.driverAvatar}>{icon}</Avatar>

                <div className={classes.infoText}>
                    <Typography variant="body2" data-id={dataId}>
                        <TitledTruncatedText>{formattedName}</TitledTruncatedText>
                    </Typography>
                </div>
            </div>
        );
    };

    const displayTrailerInfo = (
        authorized: boolean,
        trailerReference: NamedTrailerReference,
        dataId: string,
        icon: JSX.Element
    ) => {
        const formattedTrailerName = formatTrailerName(trailerReference, trailerDisplayFormat);

        const trailerInfoClasses = classNames(classes.infoBoxWrapper, {
            [classes.notAuthorized]: !authorized,
        });
        return (
            <div className={trailerInfoClasses}>
                <Avatar className={classes.trailerAvatar}>{icon}</Avatar>
                <div className={classes.infoText}>
                    <Typography variant="body2" data-id={dataId}>
                        <TitledTruncatedText>{formattedTrailerName}</TitledTruncatedText>
                    </Typography>
                </div>
            </div>
        );
    };

    const displayNoDriver = () => {
        const translatedTitle = t('not-logged-in');
        return (
            <div className={classNames(classes.notLoggedIn, classes.infoBoxWrapper)}>
                <Avatar className={classes.driverAvatar}>{driverIcon}</Avatar>

                <div className={classes.infoText}>
                    <Typography variant="body2" data-id="driver">
                        <TitledTruncatedText>{translatedTitle}</TitledTruncatedText>
                    </Typography>
                </div>
            </div>
        );
    };

    return (
        <div data-id={`info-box:${entry.vehicle.id}`}>
            {driver ? displayDriverInfo(driver, 'driver', driverIcon) : displayNoDriver()}
            {coDriver ? displayDriverInfo(coDriver, 'co-driver', coDriverIcon) : null}
            {trailer ? displayTrailerInfo(trailerAuthorized, trailer, 'trailer', trailerIcon) : null}
        </div>
    );
};

VehicleInfoBox.displayName = 'VehicleInfoBox';
export { VehicleInfoBox };
