import { ACTIONS } from './actionTypes';
import type { ActionTypes, AlertDefinitionsState } from './models';

const initialState: AlertDefinitionsState = {
    pending: false,
    error: false,
    items: [],
    item: undefined,
};

export const alertDefinitionsSceneReducer = (state = initialState, action: ActionTypes): AlertDefinitionsState => {
    switch (action.type) {
        case ACTIONS.GET_ALERTS_PENDING:
            return { ...state, pending: true, error: false };
        case ACTIONS.GET_ALERTS_FULFILLED:
            return {
                ...state,
                items: action.payload?.items || [],
                pending: false,
                error: false,
            };
        case ACTIONS.GET_ALERTS_REJECTED:
            return { ...state, error: true, pending: false };
        case ACTIONS.GET_ALERT_SUMMARY_PENDING:
            return { ...state, pending: true, error: false };
        case ACTIONS.GET_ALERT_SUMMARY_FULFILLED:
            return {
                ...state,
                item: action.payload.item,
                pending: false,
                error: false,
            };
        case ACTIONS.GET_ALERT_SUMMARY_REJECTED:
            return { ...state, error: true, pending: false };
        case ACTIONS.DELETE_ALERT_PENDING:
            return { ...state, error: false, pending: true };
        case ACTIONS.DELETE_ALERT_FULFILLED:
            return { ...state, error: false, pending: false };
        case ACTIONS.DELETE_ALERT_REJECTED:
            return { ...state, error: true, pending: true };
        default:
            return state;
    }
};
