import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { BannerType } from './consts';

export type ClassKeys = 'root' | 'messageContainer' | 'message' | 'button' | 'icon';

export const useStyles = makeStyles<Theme, { type: BannerType }, ClassKeys>((theme) => ({
    root: {
        background: ({ type }) =>
            type === BannerType.Warning ? theme.palette.warning.light : theme.palette.error.light,
        padding: theme.spacing(1, 1, 0.5, 1),
        text: theme.palette.text.secondary,
        display: 'flex',
        flexFlow: 'column',
        fontSize: 12,
    },
    messageContainer: {
        display: 'flex',
    },
    message: {
        fontStyle: 'italic',
        letterSpacing: 'normal',
        marginLeft: theme.spacing(),
        textAlign: 'left',
    },
    button: {
        alignSelf: 'flex-end',
        fontSize: 12,
        padding: 0,
        color: ({ type }) => (type === BannerType.Warning ? theme.palette.warning.dark : theme.palette.error.dark),
        '&:hover': {
            backgroundColor: ({ type }) =>
                type === BannerType.Warning ? theme.palette.warning.light : theme.palette.error.light,
        },
    },
    icon: {
        color: theme.palette.text.secondary,
    },
}));
