import * as React from 'react';

import { AssetAvatar } from '~/components/AssetAvatar';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { TrailerIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsPaneHeader } from '~/components/SceneDetailsPane';
import type { MonitoringTrailerEntry } from '~/data/monitoring';
import { ClassificationType } from '~/services/ApiClient';
import { formatClassification, formatTrailerName } from '~/services/Formatters';

export interface TrailerHeaderProps {
    entry: MonitoringTrailerEntry;
}

export interface TrailerHeaderInnerProps
    extends TrailerHeaderProps,
        InjectedDisplayPreferencesProps,
        InjectedTranslationProps {}

export const TrailerHeaderComponent: React.FC<TrailerHeaderInnerProps> = ({ entry, t, displayPreferences }) => {
    const { trailer } = entry;

    const vehicleAvatar = <AssetAvatar icon={<TrailerIcon />} />;
    const trailerType = trailer.reeferManufacturer ? t('reefer') : t('trailer');
    const secondaryIdentifier = `${trailerType} - ${formatClassification(
        t,
        ClassificationType.TrailerManufacturer,
        trailer.trailerManufacturer
    )}`;

    return (
        <SceneDetailsPaneHeader
            avatar={vehicleAvatar}
            primaryIdentifier={formatTrailerName(trailer, displayPreferences.trailerDisplayFormat)}
            secondaryIdentifier={secondaryIdentifier}
        />
    );
};
