export interface GridGroupedRow {
    groupedBy: string;
    compoundKey: string;
    key: string;
    value: unknown;
}

export const createGroupedGridGetRowId =
    <T extends object>(getRowId: (row: T) => number | string) =>
    (row: GridGroupedRow | T): string | number => {
        if ('groupedBy' in row) {
            return `${row.groupedBy}-${row.compoundKey}`;
        }

        return getRowId(row);
    };
