import { Divider } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import React from 'react';

import { DetailsPaneHeader } from '~/components/DetailsPaneHeader';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { BooleanSettingItem } from '~/components/MiniSettingsPanelComponent';
import { BooleanFieldType, MiniSettingsPanel, SettingItemType } from '~/components/MiniSettingsPanelComponent';
import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { ResolvedAttachment, ResolvedMessage } from '~/services/ApiClient';

import { SectionName } from './constants';
import type { MessageDetailsUserPreferences } from './preferences';
import { getSectionsMemoized } from './sections';
import type { WorkflowMessageDetailsClassKey } from './styles';

export interface WorkflowMessageDetailsProps {
    message: ResolvedMessage;
    scrollToAttachments?: boolean;
    showDetailsPaneHeader: boolean;
    onClose?: () => void;
}

export interface WorkflowMessageDetailsStateProps {
    messageResolvedAttachments: ResolvedAttachment[];
}
export interface WorkflowMessageDetailsDispatchProps {
    changeMiniMapZoomLevel: (zoomLevel: number) => void;
}

export interface WorkflowMessageDetailsInnerProps
    extends WorkflowMessageDetailsProps,
        WorkflowMessageDetailsStateProps,
        WorkflowMessageDetailsDispatchProps,
        InjectedTranslationProps,
        WithStyles<WorkflowMessageDetailsClassKey> {}

export const WorkflowMessageDetailsComponentFactory = (
    userPreferencesKey: string
): React.ComponentType<WorkflowMessageDetailsInnerProps> => {
    const DetailsPaneComponent = SceneDetailsPaneFactory<ResolvedMessage, MessageDetailsUserPreferences, SectionName>(
        userPreferencesKey
    );

    const WorkflowMessageDetailsComponent: React.FC<WorkflowMessageDetailsInnerProps> = ({
        classes,
        t,
        message,
        scrollToAttachments,
        showDetailsPaneHeader,
        messageResolvedAttachments,
        onClose,
        changeMiniMapZoomLevel,
    }) => {
        const [showEmptyFields, setShowEmptyFields] = React.useState(true);
        const attachmentRef = React.useRef<HTMLDivElement>(null);
        React.useEffect(() => {
            window.setTimeout(() => {
                if (attachmentRef.current && scrollToAttachments) {
                    attachmentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 0);
        }, [scrollToAttachments]);

        const getSections = (
            userPreferences: MessageDetailsUserPreferences,
            toggleCollapsedState: (sectionName: SectionName) => () => void
        ) =>
            getSectionsMemoized(
                userPreferences.collapsedSections,
                toggleCollapsedState,
                t,
                message,
                messageResolvedAttachments,
                attachmentRef,
                showEmptyFields,
                userPreferences.miniMapZoomLevel,
                changeMiniMapZoomLevel
            );

        const header = showDetailsPaneHeader && <DetailsPaneHeader onClose={onClose} title={t('message-info')} />;

        const showEmptyFieldsSetting: BooleanSettingItem = {
            key: 'show-empty-fields',
            onValueChange: () => {
                setShowEmptyFields((prevState) => !prevState);
            },
            settingType: SettingItemType.Boolean,
            title: t('workflow-setting-show-empty-fields'),
            type: BooleanFieldType.ToggleSwitch,
            value: showEmptyFields,
        };

        return (
            <div className={classes.root}>
                <div className={classes.detailsPaneWrapper}>
                    <DetailsPaneComponent
                        entry={message}
                        getSections={getSections}
                        dataId="wf-message-details-sections"
                        HeaderReactNode={header}
                        alwaysFirstSection={SectionName.MESSAGEINFO}
                    />
                </div>
                <Divider />
                <MiniSettingsPanel settings={[showEmptyFieldsSetting]} />
            </div>
        );
    };

    return WorkflowMessageDetailsComponent;
};
