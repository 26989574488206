import type { FC } from 'react';

import { DriverRole } from '~/services/ApiClient';

import { DriverHeader } from './components/DriverHeader';
import type { StaffHeaderProps } from './models';
import { useStyles } from './styles';

const StaffHeader: FC<StaffHeaderProps> = (props) => {
    const { driver, activity, subactivity, lastEvent } = props.driverStatus;
    const classes = useStyles();

    return (
        <div className={classes.flex}>
            <DriverHeader
                role={DriverRole.DRIVER}
                driver={driver}
                activity={activity}
                lastEvent={lastEvent}
                subactivity={subactivity}
            />
        </div>
    );
};

StaffHeader.displayName = 'StaffHeader';
export { StaffHeader };
