import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { AddressPopupProps } from './component';

export type AddressPopupClassKey = 'paper' | 'avatar' | 'closeButton';

export const AddressPopupStyleRules: StyleRulesCallback<Theme, AddressPopupProps, AddressPopupClassKey> = (
    theme: Theme
) =>
    createStyles({
        paper: {
            width: theme.spacing(48),
            alignSelf: 'flex-end',
            display: 'flex',
            padding: theme.spacing(0.75),
            position: 'relative',
            bottom: theme.spacing(11),
            margin: '0 auto',
            zIndex: 1000,
        },
        avatar: {
            alignSelf: 'flex-start',
            width: theme.spacing(7.5),
            height: theme.spacing(7.5),
            backgroundColor: theme.palette.background.default,
            marginRight: theme.spacing(1),
        },
        closeButton: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: 14,
            padding: 0,
            marginLeft: 'auto',
        },
    });
