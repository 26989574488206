import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { GridColumnDefinition } from '../../models';

import type { TableGroupRowContentInnerProps, TableGroupRowContentProps } from './component';
import { createTableGroupRowContent as createTableGroupRow } from './component';
import { TableGroupRowContentStyleRules } from './styles';

export const createTableGroupRowContent = <T>(
    columnDefinitions: Array<GridColumnDefinition<T>>
): React.ComponentType<TableGroupRowContentProps> =>
    compose<TableGroupRowContentInnerProps, TableGroupRowContentProps>(
        setDisplayName('TableGroupRowContent'),
        withStyles(TableGroupRowContentStyleRules)
    )(createTableGroupRow<T>(columnDefinitions));
