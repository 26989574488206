import type { StoreState } from '~/reducers';
import { vehicleTypesAdministrationSceneStateSelector as vehicleTypeAdministrationSceneStateSelector } from '~/selectors';

import type { VehicleTypeChangelogsStoreState } from './components/DetailsPane/components/VehicleTypesAdministrationChangelogsSubpage';
import type { AdminVehicleTypesStoreState } from './reducers';

export const vehicleTypeAdministrationStateSelector = (s: StoreState): AdminVehicleTypesStoreState =>
    vehicleTypeAdministrationSceneStateSelector(s).adminVehicleTypeStoreState;

export const vehicleAdministrationChangelogsSelector = (s: StoreState): VehicleTypeChangelogsStoreState => {
    return vehicleTypeAdministrationSceneStateSelector(s).vehicleTypeAdministrationChangelogsRoot;
};
