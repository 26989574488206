import { isUndefined } from '~/libs/utility';
import { DriverCardFileMetadata, TachographFileMetadata } from '~/services/ApiClient';

import type { ResolveTachoFilesEntriesArgs, TachoFileEntry } from './models';

export const resolveTachoFilesEntries = ({
    staticDataStoreState,
    tachoFiles,
    settingsStoreState,
}: ResolveTachoFilesEntriesArgs): TachoFileEntry[] => {
    return tachoFiles.map((tachoFile) => {
        const sendVehicleId = tachoFile.sendVehicle?.vehicle?.id;
        const sendVehicle = {
            ...tachoFile.sendVehicle,
            vehicle: isUndefined(sendVehicleId) ? undefined : staticDataStoreState.vehicles.data.hash[sendVehicleId],
        };

        const resolvedTachoFile = {
            ...tachoFile,
            metadata: {
                value: tachoFile.metadata,
                vehicle:
                    tachoFile.metadata instanceof TachographFileMetadata
                        ? staticDataStoreState.vehicles.data.hash[tachoFile.metadata.vehicle.id]
                        : undefined,
                driver:
                    tachoFile.metadata instanceof DriverCardFileMetadata
                        ? staticDataStoreState.drivers.data.hash[tachoFile.metadata.driver.id]
                        : undefined,
                dataBlocks:
                    tachoFile.metadata instanceof TachographFileMetadata
                        ? tachoFile.metadata.dataBlocks.map(
                              (dataBlockId) => settingsStoreState.tachographDataBlock.data[dataBlockId]
                          )
                        : undefined,
            },
            sendVehicle,
        };

        return { file: resolvedTachoFile };
    });
};
