import type { StoreState } from '~/reducers';
import { settingsStoreStateSelector } from '~/selectors';

import type { SettingsDataType, SettingsDetails } from './settings';

export const settingDataSelector =
    <K extends keyof SettingsDetails>(key: K) =>
    (state: StoreState): SettingsDataType[K] => {
        return settingsStoreStateSelector(state)[key].data as SettingsDataType[K];
    };
