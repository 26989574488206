import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DocumentViewerShellInnerProps, DocumentViewerShellProps } from './component';
import { DocumentViewerShellComponent } from './component';

export type { DocumentViewerShellProps } from './component';
export const DocumentViewerShell = compose<DocumentViewerShellInnerProps, DocumentViewerShellProps>(
    setDisplayName('DocumentViewerShell'),
    withTranslation()
)(DocumentViewerShellComponent);
