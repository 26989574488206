import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { withTranslation } from '~/components/LanguageSelector';
import { ensureUserMessagingProfiles } from '~/modules/Communication';

import type { LatestMessagesSectionInnerProps, LatestMessagesSectionProps, StateProps } from './component';
import { LatestMessagesSectionComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { deletedMessagesStateSelector, latestMessagesStateSelector } from './selectors';

export { latestMessagesSectionReducer } from './reducers';

export const LatestMessagesSection = compose<LatestMessagesSectionInnerProps, LatestMessagesSectionProps>(
    setDisplayName('LatestMessagesSectionComponent'),
    withTranslation(),
    ensureUserMessagingProfiles(),
    connect<StateProps>(
        createSelector(
            settingDataSelector(SettingsKey.SECURABLES),
            latestMessagesStateSelector,
            deletedMessagesStateSelector,
            mapStateToProps
        ),
        mapDispatchToProps
    )
)(LatestMessagesSectionComponent);
