import type { ActionTypes, Language } from '~/data/language';
import { ActionTypeKeys } from '~/data/language';
import type { Dictionary } from '~/libs/utility';
import { keyBy } from '~/libs/utility';

const initialState: LanguageStoreState = {
    availableLanguages: {},
};

export interface LanguageStoreState {
    availableLanguages: Dictionary<Language>;
    currentLanguage?: Language;
}

export function languageReducer(state: LanguageStoreState = initialState, action: ActionTypes): LanguageStoreState {
    switch (action.type) {
        case ActionTypeKeys.CURRENTLANGUAGE_FULFILLED:
        case ActionTypeKeys.CHANGELANGUAGE_FULFILLED:
            return { ...state, currentLanguage: action.payload.language };
        case ActionTypeKeys.LANGUAGES_FULFILLED:
            return { ...state, availableLanguages: keyBy(action.payload.languages, 'code') };
        default:
            return state;
    }
}

export function selectLanguages(state: LanguageStoreState): Language[] {
    return Object.keys(state.availableLanguages).map((k) => state.availableLanguages[k]);
}
