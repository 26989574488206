import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';

import { SectionName } from './consts';

export const DRIVERSUBPAGE_PREFERENCES_KEY = 'driver-status-detailspane-driver-subpage';

export interface DriverPerspectiveSubpageUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {}

export const defaultDriverPerspectiveSubpageUserPreferences: DriverPerspectiveSubpageUserPreferences = {
    sectionOrder: [
        SectionName.DAILY_OVERVIEW,
        SectionName.GENERAL,
        SectionName.DRIVE,
        SectionName.LABOR,
        SectionName.DUTY,
        SectionName.REST,
    ],
    collapsedSections: [],
};

export const requiredDriverPerspectiveSubpageUserPreferences = {
    key: DRIVERSUBPAGE_PREFERENCES_KEY,
    defaultState: defaultDriverPerspectiveSubpageUserPreferences,
};
