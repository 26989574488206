import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';

import type { DimensionsSectionInnerProps, DimensionsSectionProps } from './component';
import { DimensionsSectionComponent } from './component';

export const DimensionsSection = compose<DimensionsSectionInnerProps, DimensionsSectionProps>(
    setDisplayName('DimensionsSection'),
    withDisplayPreferences()
)(DimensionsSectionComponent);
