import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    leftAlignment: { textAlign: 'left' },
    rightAlignment: { textAlign: 'right' },
    centerAlignment: { textAlign: 'center' },
    inputAdornedStart: { paddingLeft: theme.spacing(1) },
    inputAdornedEnd: { paddingRight: theme.spacing(1) },
    messageIconInfo: {
        fontSize: 24,
        color: theme.palette.text.secondary,
        cursor: 'pointer',
    },
    root: {
        position: 'relative',
        paddingBottom: '56.25%', // 16:9
    },
    content: { position: 'absolute', width: '100%', height: '100%' },
    handle: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
        padding: theme.spacing(1, 2),
        color: theme.palette.text.secondary,
        '& svg:last-child': {
            margin: theme.spacing(0, 0.5),
        },
    },
    invalidValue: { color: theme.palette.error.main },
    readOnlyRoot: { borderRadius: 4 },
    readOnlyUnderline: {
        '&:before': { border: 'none' },
        '&:after': { border: 'none' },
        '&:hover:before': { border: 'none' },
        '&:disabled:before': { border: 'none' },
    },
    readOnlyUnderlineDisabled: { '&:before': { borderBottom: 0 } },
}));

export { useStyles };
