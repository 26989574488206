export const valueTextFormatterFactory =
    <T>(formatter: (v: T) => string) =>
    (v?: T): string | undefined => {
        if (v === undefined) {
            return undefined;
        }

        return formatter(v);
    };

export const valueTextFormatterIncludingUndefinedFactory =
    <T>(formatter: (v: T | undefined) => string) =>
    (v?: T): string => {
        return formatter(v);
    };
