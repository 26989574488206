import { Typography } from '@mui/material';
import React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { logEvent } from '~/services/Analytics';
import type { ResolvedAttachment, ResolvedMessage } from '~/services/ApiClient';

import { AttachmentViewer } from '../../../AttachmentViewer';

import { Attachment } from './components/Attachment';

export interface AttachmentsSectionContentProps {
    attachments: ResolvedAttachment[];
    message: ResolvedMessage;
}

export interface AttachmentsSectionContentInnerProps extends AttachmentsSectionContentProps, InjectedTranslationProps {}

export const AttachmentsSectionContentComponent: React.FC<AttachmentsSectionContentInnerProps> = ({
    t,
    attachments,
    message,
}) => {
    const [openedAttachment, setOpenedAttachment] = React.useState<ResolvedAttachment | undefined>();

    const documentViewer = React.useMemo(() => {
        return (
            openedAttachment && (
                <AttachmentViewer
                    onClose={() => setOpenedAttachment(undefined)}
                    attachments={attachments}
                    defaultAttachment={openedAttachment}
                    message={message}
                />
            )
        );
    }, [attachments, openedAttachment, setOpenedAttachment, message]);

    if (!attachments.length) {
        return (
            <Typography variant="caption" color="textSecondary" data-id="no-attachments" align="center" display="block">
                {t('no-attachments-message')}
            </Typography>
        );
    }

    return (
        <>
            <div>
                {attachments.map((attachment) => (
                    <Attachment
                        key={attachment.id}
                        attachment={attachment}
                        onClick={() => {
                            logEvent('messaging', 'open-attachment', 'Open workflow attachment');
                            setOpenedAttachment(attachment);
                        }}
                    />
                ))}
            </div>
            {documentViewer}
        </>
    );
};
