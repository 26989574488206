import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { ModuleBarActionsInnerProps, ModuleBarActionsProps } from './component';
import { ModuleBarActionsComponent } from './component';
import { ModuleViewBarActionsStyleRules } from './styles';

export const ModuleBarActions = compose<ModuleBarActionsInnerProps, ModuleBarActionsProps>(
    setDisplayName('ModuleBarActions'),
    withTranslation(),
    withStyles(ModuleViewBarActionsStyleRules)
)(ModuleBarActionsComponent);
