import type { FC } from 'react';

import { ElapsedTime } from '~/components/ElapsedTime';
import { ClockIcon } from '~/components/Icons';
import { isUndefined } from '~/libs/utility';

import { Container } from '../styles';

import type { TimeProps } from './model';

const Time: FC<TimeProps> = (props) => {
    const { time, notAvailable } = props;
    return (
        <Container data-testid="time-component">
            {isUndefined(time) ? (
                <span data-testid="not-available">{notAvailable}</span>
            ) : (
                <ElapsedTime icon={ClockIcon} since={time} />
            )}
        </Container>
    );
};

Time.displayName = 'Time';

export { Time };
