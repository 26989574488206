import Box from '@mui/material/Box';
import type { WithStyles } from '@mui/styles';
import type { FC } from 'react';

import { FlagIcon } from '~/components/Icons';
import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import { formatCountry } from '~/services/Formatters';

import type { CountryFormatterClassKey } from './styles';

export interface CountryFormatterProps {
    t: SingleTFunction;
    value?: string;
}

export interface CountryFormatterInnerProps extends CountryFormatterProps, WithStyles<CountryFormatterClassKey> {}

export const CountryFormatterComponent: FC<CountryFormatterInnerProps> = ({ t, value, classes }) => {
    if (isUndefined(value)) {
        return null;
    }

    return (
        <Box display="flex" alignItems="center">
            <FlagIcon code={value} className={classes.flag} />
            {formatCountry(t, value)}
        </Box>
    );
};
