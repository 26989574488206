import type { Option } from '~/components/RemoteAutocomplete';
import type { NumericDictionary } from '~/libs/utility';
import type { User } from '~/services/ApiClient';
import { compareFactory, stringComparer } from '~/services/Sorting';

import { getUserOption } from './getUserOption';

const userComparer = compareFactory((u: User) => u.username, stringComparer);

export const mapUsersToUserOptions = (usersHash: NumericDictionary<User>): Array<Option<undefined>> => {
    const userArray: User[] = Object.keys(usersHash).map((key) => usersHash[key]);

    return userArray.sort(userComparer).map((user: User) => getUserOption(user.username, !user.isEnabled));
};
