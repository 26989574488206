import type { FC } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';

import type { EventControlsProps } from './models';

const MapEventControls: FC<EventControlsProps> = (props) => {
    const { onChangeViewport } = props;
    const map = useMap();

    const setViewport = () => {
        const zoom = map.getZoom();
        const { lat, lng } = map.getCenter();

        onChangeViewport({ center: [lat, lng], zoom });
    };

    useMapEvents({ moveend: setViewport });
    return null;
};

MapEventControls.displayName = 'MapEventControls';
export default MapEventControls;
