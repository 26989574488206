import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';
import { getSpeedUnitSystemUtils } from '~/services/Formatters';

export interface SpeedFormatterProps {
    t: SingleTFunction;
    unitSystem: DisplayUserPreferencesUnitSystem;
    value?: number;
    undefinedValueMessage?: string;
}

export interface SpeedFormatterInnerProps extends SpeedFormatterProps {}

const SpeedFormatter: React.FunctionComponent<SpeedFormatterInnerProps> = ({
    t,
    unitSystem,
    value,
    undefinedValueMessage,
}) => {
    if (isUndefined(value)) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{undefinedValueMessage ?? ''}</>;
    }

    return <>{getSpeedUnitSystemUtils(t, unitSystem).formatter(value)}</>;
};
SpeedFormatter.displayName = 'SpeedFormatter';

export { SpeedFormatter };
