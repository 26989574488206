const CRUD_ACTIONS = {
    CREATE: 'create',
    DELETE: 'delete',
    EDIT: 'edit',
    CLONE: 'clone',
} as const;

const SERVER_STATUS = {
    PENDING: 'Pending',
    BADREQUEST: 'BadRequest',
    SERVERERROR: 'ServerError',
    OK: 'Ok',
    ACCEPTED: 'Accepted',
};

export { SERVER_STATUS, CRUD_ACTIONS };
