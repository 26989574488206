import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
    disabled: {
        opacity: 0.38,
    },
    activityAvatar: {
        width: 18,
        height: 18,
        fontSize: 14,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        alignSelf: 'flex-end',
        marginLeft: theme.spacing(-1.5),
    },
    activityAvailable: {
        backgroundColor: theme.functionalItemsColors.driverActivity.available.value,
        color: theme.functionalItemsColors.driverActivity.available.contrast,
    },
    activityWork: {
        backgroundColor: theme.functionalItemsColors.driverActivity.work.value,
        color: theme.functionalItemsColors.driverActivity.work.contrast,
    },
    activityDrive: {
        backgroundColor: theme.functionalItemsColors.driverActivity.drive.value,
        color: theme.functionalItemsColors.driverActivity.drive.contrast,
    },
    activityRest: {
        backgroundColor: theme.functionalItemsColors.driverActivity.rest.value,
        color: theme.functionalItemsColors.driverActivity.rest.contrast,
    },
    avatar: {
        color: theme.functionalItemsColors.assetType.vehicle.value,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.text.secondary}`,
        width: 40,
        height: 40,
        alignSelf: 'center',
        overflow: 'visible',
    },
    badge: {
        right: 10,
    },
    disabledTitle: {
        fontStyle: 'italic',
    },
    line: {
        display: 'flex',
    },
    vehicleCategory: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    delimiter: {
        margin: theme.spacing(0, 0.5),
    },
}));
