type GenericObject = { [key: string]: string };

type Filled<T extends GenericObject> = {
    [Property in keyof T as
        | `${Property & string}_PENDING`
        | `${Property & string}_FULFILLED`
        | `${Property & string}_REJECTED`]: string;
};

const actionGenerator = <T extends GenericObject, K extends string>(actionsPrefixes: T, modulePrefix: K) => {
    const arrayKeys = Object.keys(actionsPrefixes) as string[];

    return arrayKeys.reduce(
        (acc, key) => ({
            ...acc,
            [`${key}_PENDING`]: `[${modulePrefix}] ${key}_PENDING`,
            [`${key}_FULFILLED`]: `[${modulePrefix}] ${key}_FULFILLED`,
            [`${key}_REJECTED`]: `[${modulePrefix}] ${key}_REJECTED`,
        }),
        {} as { [Key in keyof Filled<T>]: `[${typeof modulePrefix}] ${Key & string}` }
    );
};

export { actionGenerator };
