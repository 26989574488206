import { createSelector } from 'reselect';

import type { StoreState } from '~/reducers';

export const communicationStateSelector = (state: StoreState) => state.communicationScene;

export const dynamicCommunicationStateSelector = createSelector(
    [communicationStateSelector],
    ({ dynamicCommunication }) => dynamicCommunication
);

export const communicationEntriesSelector = createSelector(
    [dynamicCommunicationStateSelector],
    ({ communicationEntries }) => communicationEntries
);

export const communicationDateTimeRangeStateSelector = createSelector(
    [communicationStateSelector],
    ({ dateTimeRange }) => dateTimeRange
);
