import { getRawCellValue } from './getRawCellValue';
import type { GridColumnDefinition } from './models';

export const dataSourceExportProcessor = <T>(
    dataSource: T[],
    columnDefinitions: Array<GridColumnDefinition<T>>
): Array<Array<string | number | Date | undefined>> => {
    return dataSource.map((entry) => {
        return columnDefinitions.map((column) => {
            const { exportValueFormatter } = column;
            if (exportValueFormatter) {
                return exportValueFormatter(getRawCellValue(column, entry));
            }
            return getRawCellValue(column, entry);
        });
    });
};
