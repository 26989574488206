import makeStyles from '@mui/styles/makeStyles';

import { themeSpacingToNumber } from '~/common/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    illustration: {
        height: '45vh',
        objectFit: 'contain',
        marginBottom: `${(themeSpacingToNumber(theme.spacing(11.5)) * 100) / document.documentElement.clientHeight}vh`,
    },
    title: {
        textAlign: 'center',
        marginBottom: `${(themeSpacingToNumber(theme.spacing(4)) * 100) / document.documentElement.clientHeight}vh`,
    },
    description: {
        textAlign: 'center',
        marginBottom: `${(themeSpacingToNumber(theme.spacing(9)) * 100) / document.documentElement.clientHeight}vh`,
    },
    '@media (max-width: 960px)': {
        title: {
            lineHeight: '24px',
            fontSize: 24,
        },
        description: {
            lineHeight: '16px',
            fontSize: 16,
        },
    },
    '@media (min-width: 961px)': {
        title: {
            lineHeight: '72px',
        },
        description: {
            lineHeight: '19px',
        },
    },
}));

export { useStyles };
