import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DefaultHeaderClassKey = 'root' | 'toggleExpandAllButton' | 'checkbox' | 'itemsCounter' | 'searchIcon';

export const DefaultHeaderStyleRules: StyleRulesCallback<Theme, {}, DefaultHeaderClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            height: 24,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(),
            margin: theme.spacing(1.5, 0),
        },
        toggleExpandAllButton: {
            width: 24,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        checkbox: {
            width: 40,
            '&:hover': {
                backgroundColor: 'inherit',
            },
        },
        itemsCounter: {
            flexGrow: 1,
            marginLeft: theme.spacing(),
        },
        searchIcon: {
            margin: theme.spacing(),
            color: theme.palette.text.secondary,
            cursor: 'pointer',
        },
    });
