import type { BaseAction, FulfilledMeta, PendingMeta, RejectedMeta } from '~/types';

export enum ActionTypePrefixes {
    TIMESERIESDIALOG = 'TIMESERIESDIALOG',
}

export enum ActionTypeKeys {
    TIMESERIESDIALOG_PENDING = 'TIMESERIESDIALOG_PENDING',
    TIMESERIESDIALOG_FULFILLED = 'TIMESERIESDIALOG_FULFILLED',
    TIMESERIESDIALOG_REJECTED = 'TIMESERIESDIALOG_REJECTED',
    TIMESERIESDIALOG_CLEAR = 'TIMESERIESDIALOG_CLEAR',
}

export interface TimeSeriesDialogMeta {
    startDate: Date;
    stopDate: Date;
}

export type ActionTypes =
    | PendingMeta<ActionTypeKeys.TIMESERIESDIALOG_PENDING, TimeSeriesDialogMeta>
    | FulfilledMeta<ActionTypeKeys.TIMESERIESDIALOG_FULFILLED, unknown[], TimeSeriesDialogMeta>
    | RejectedMeta<ActionTypeKeys.TIMESERIESDIALOG_REJECTED, undefined, TimeSeriesDialogMeta>
    | BaseAction<ActionTypeKeys.TIMESERIESDIALOG_CLEAR>;
