import type { FC } from 'react';

import { ContainerNameFormatter } from '~/components/Formatters';
import type { MonitoringContainerEntry } from '~/data/monitoring';

export interface ContainerMarkerNameFormatterProps {
    entry: MonitoringContainerEntry;
}

export interface ContainerMarkerNameFormatterInnerProps extends ContainerMarkerNameFormatterProps {}

const ContainerMarkerNameFormatter: FC<ContainerMarkerNameFormatterInnerProps> = ({ entry }) => {
    return <ContainerNameFormatter value={entry.container} />;
};
ContainerMarkerNameFormatter.displayName = 'ContainerMarkerNameFormatter';

export { ContainerMarkerNameFormatter };
