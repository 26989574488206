import withStyles from '@mui/styles/withStyles';
import withTheme from '@mui/styles/withTheme';
import type * as React from 'react';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import type { GridColumnDefinition, GridRowId } from '~/components/Grid';
import { withTranslation } from '~/components/LanguageSelector';
import { ensureUserPreferences, keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { sceneListStateSelector } from '~/selectors';

import type { SceneListUserPreferences } from '../../preferences';
import { processResponseFactory } from '../../preferences';

import { SceneListViewComponentFactory } from './component';
import type { SceneListViewInnerProps, SceneListViewProps } from './models';
import { mapDispatchToPropsFactory, mapStateToPropsFactory } from './redux';
import { SceneListViewStyleRules } from './styles';

export type { GroupSummaryItemCustom } from './models';
export type { SceneListViewProps } from './models';
export const SceneListViewFactory = <T extends object>(
    preferencesKey: string,
    defaultListUserPreferencesState: SceneListUserPreferences,
    getRowId: (row: T) => GridRowId,
    entryEquals: (rowA?: T, rowB?: T) => boolean,
    filterDataSourceMemoized: (
        searchQuery: string | undefined,
        dataSource: T[],
        columnDefinitions: Array<GridColumnDefinition<T>>,
        visibleColumns: string[]
    ) => T[]
): React.ComponentType<SceneListViewProps<T>> =>
    compose<SceneListViewInnerProps<T>, SceneListViewProps<T>>(
        setDisplayName('SceneListView'),
        ensureUserPreferences([
            {
                key: preferencesKey,
                defaultState: defaultListUserPreferencesState,
                processResponse: processResponseFactory(defaultListUserPreferencesState),
            },
        ]),
        withTranslation(),
        withStyles(SceneListViewStyleRules),
        withTheme,
        connect(
            createSelector(
                keyDataStateSelectorFactory(preferencesKey),
                sceneListStateSelector,
                mapStateToPropsFactory(preferencesKey)
            ),
            mapDispatchToPropsFactory(preferencesKey)
        )
    )(SceneListViewComponentFactory<T>(getRowId, entryEquals, filterDataSourceMemoized));
