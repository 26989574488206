import * as React from 'react';

import { DetailsPaneHeader } from '~/components/DetailsPaneHeader';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import { formatVehicleName } from '~/services/Formatters';

import type { HistoryVehicleEntry } from '../../../../models';

import type { SectionName } from './constants';
import type { HistoryVehicleDetailsPaneUserPreferences } from './preferences';
import { HISTORY_DETAILSPANE_VEHICLE_USERPREFERENCES_KEY } from './preferences';
import { getSectionsMemoized } from './sections';

export interface VehicleDetailsPaneProps {
    entry: HistoryVehicleEntry;
    handleClose: () => void;
}

export interface DispatchProps {
    changeMiniMapZoomLevel: (zoomLevel: number) => void;
}

export interface VehicleDetailsPaneInnerProps
    extends VehicleDetailsPaneProps,
        InjectedDisplayPreferencesProps,
        DispatchProps,
        InjectedTranslationProps {}

const DetailsPaneComponent = SceneDetailsPaneFactory<
    HistoryVehicleEntry,
    HistoryVehicleDetailsPaneUserPreferences,
    SectionName
>(HISTORY_DETAILSPANE_VEHICLE_USERPREFERENCES_KEY);

export const VehicleDetailsPaneComponent: React.FC<VehicleDetailsPaneInnerProps> = ({
    entry,
    changeMiniMapZoomLevel,
    handleClose,
    displayPreferences,
    t,
}) => {
    const getSections = (
        userPreferences: HistoryVehicleDetailsPaneUserPreferences,
        toggleCollapsedState: (sectionName: SectionName) => () => void
    ) =>
        getSectionsMemoized(
            entry,
            userPreferences.collapsedSections,
            toggleCollapsedState,
            userPreferences.miniMapZoomLevel,
            changeMiniMapZoomLevel
        );

    const vehicleName = entry.vehicle
        ? formatVehicleName(entry.vehicle, displayPreferences.vehicleDisplayFormat)
        : undefined;
    const header = <DetailsPaneHeader onClose={handleClose} title={t('position-report-of', { vehicleName })} />;

    return (
        <DetailsPaneComponent
            entry={entry}
            getSections={getSections}
            dataId="vehicle-details-pane"
            HeaderReactNode={header}
        />
    );
};
