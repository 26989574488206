import { MapGatewayClient } from './generated';

let mapGatewayClientInstance: MapGatewayClient | undefined;

const getMapGatewayClient = (): MapGatewayClient => {
    if (!mapGatewayClientInstance) {
        throw new Error('MapGatewayClient has not been initialized');
    }

    return mapGatewayClientInstance;
};
const setMapGatewayClientToken = (jwt?: string) => {
    if (!mapGatewayClientInstance) {
        throw new Error('MapGatewayClient has not been initialized');
    }
    mapGatewayClientInstance.setJwt(jwt);
};

const initializeMapGatewayClient = (baseUrl: string): void => {
    mapGatewayClientInstance = new MapGatewayClient(baseUrl);
};

export * from './generated';
export { getMapGatewayClient, initializeMapGatewayClient, setMapGatewayClientToken };
