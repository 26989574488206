import type { SvgIconProps } from '@mui/material';
import type { ClassNameMap } from '@mui/styles';
import * as React from 'react';

import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { ReorderableSection } from '~/components/Sections';
import type { PositionGroup, PositionStatus } from '~/services/ApiClient';

import type { TimelineItemClassKey } from './components/PositionGroupsContainer';
import { PositionGroupsContainer } from './components/PositionGroupsContainer';

export interface TimelineSectionProps {
    AssetIcon: React.ComponentType<SvgIconProps>;
    dragHandleElement: JSX.Element;
    isCollapsed: boolean;
    toggleCollapsed: () => void;
    stopDate: Date;
    startDate: Date;
    positionGroups: PositionGroup[];
    retrievePositions: (startDate: Date, stopDate: Date) => Promise<PositionStatus[]>;
    timelineClasses?: Partial<ClassNameMap<TimelineItemClassKey>>;
}

export interface TimelineSectionInnerProps extends TimelineSectionProps, InjectedTranslationProps {}

export const TimelineSectionComponent: React.FC<TimelineSectionInnerProps> = ({
    AssetIcon,
    t,
    positionGroups,
    retrievePositions,
    stopDate,
    startDate,
    dragHandleElement,
    isCollapsed,
    toggleCollapsed,
    timelineClasses,
}) => {
    return (
        <ReorderableSection
            title={t('timeline')}
            dataId="timeline"
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
        >
            <PositionGroupsContainer
                positionGroups={positionGroups}
                retrievePositions={retrievePositions}
                stopDate={stopDate}
                startDate={startDate}
                AssetIcon={AssetIcon}
                timelineClasses={timelineClasses}
            />
        </ReorderableSection>
    );
};
