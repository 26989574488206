import * as React from 'react';
import type { Subtract } from 'utility-types';

import type { CustomIconProps } from '~/components/Icons';
import { AssetDepotIcon, TruckIcon } from '~/components/Icons';
import { MessageDirection } from '~/services/ApiClient';

export interface IconFormatterProps {
    value?: MessageDirection;
    iconProps?: Subtract<CustomIconProps, { ref?: unknown }>;
}

export interface IconFormatterInnerProps extends IconFormatterProps {}

const IconFormatter: React.FunctionComponent<IconFormatterInnerProps> = ({ value, iconProps }) => {
    switch (value) {
        case MessageDirection.Incoming: {
            return <TruckIcon {...iconProps} />;
        }

        case MessageDirection.Outgoing: {
            return <AssetDepotIcon {...iconProps} />;
        }

        default: {
            return null;
        }
    }
};
IconFormatter.displayName = 'IconFormatter';

export { IconFormatter };
