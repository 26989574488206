import type { StaticDataStoreState } from '~/common';
import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import type { ActionTypes as RtdsSessionsActionTypes } from '~/data/rtdssessions';
import { ActionTypeKeys as RtdsSessionsActionTypeKeys } from '~/data/rtdssessions';
import type { RetrievableData } from '~/reducers';
import type { QueryRtdsSessionsRequest, RtdsSession } from '~/services/ApiClient';

import type { ActionTypes as RtdsSessionsItemsActionTypes } from './data';
import { ActionTypeKeys as RtdsSessionsItemsActionTypeKeys } from './data';
import type { RtdsSessionEntry } from './models';
import { resolveRtdsSessionsEntries } from './reducers.resolveRtdsSessionsEntries';

export interface DynamicRtdsSessionsStoreState {
    rtdsSessionsEntries: RtdsSessionEntry[];
    rtdsSessions: RetrievableData<RtdsSession[]>;
}
export interface RtdsSessionsStoreState {
    dynamicRtdsSessions: DynamicRtdsSessionsStoreState;
    dateTimeRange?: DateTimeRange;
    latestRequestParams?: QueryRtdsSessionsRequest;
}

export const defaultStoreState: RtdsSessionsStoreState = {
    dynamicRtdsSessions: {
        rtdsSessionsEntries: [],
        rtdsSessions: {
            data: [],
            pending: false,
            fulfilled: false,
            rejected: false,
        },
    },
};

type ActionTypes = RtdsSessionsActionTypes | RtdsSessionsItemsActionTypes;

export const rtdsSessionsReducer = (
    state: RtdsSessionsStoreState = defaultStoreState,
    action: ActionTypes,
    staticDataStoreState: StaticDataStoreState,
    settingsStoreState: SettingsStoreState
): RtdsSessionsStoreState => {
    switch (action.type) {
        case RtdsSessionsItemsActionTypeKeys.RTDSSESSIONS_CLEAR_DATA:
            return {
                ...defaultStoreState,
                dateTimeRange: state.dateTimeRange,
            };
        case RtdsSessionsItemsActionTypeKeys.RTDSSESSIONS_CHANGE_DATETIMERANGE:
            return {
                ...state,
                dateTimeRange: action.payload,
            };
        case RtdsSessionsActionTypeKeys.RTDSSESSIONS_QUERY_PENDING:
            return {
                ...state,
                latestRequestParams: action.meta,
                dynamicRtdsSessions: {
                    ...state.dynamicRtdsSessions,
                    rtdsSessions: {
                        ...state.dynamicRtdsSessions.rtdsSessions,
                        pending: true,
                        rejected: false,
                        fulfilled: false,
                    },
                },
            };
        case RtdsSessionsActionTypeKeys.RTDSSESSIONS_QUERY_REJECTED: {
            const isLatestResponse = state.latestRequestParams === action.meta;
            if (!isLatestResponse) {
                return state;
            }
            return {
                ...state,
                dynamicRtdsSessions: {
                    ...state.dynamicRtdsSessions,
                    rtdsSessions: {
                        data: [],
                        pending: false,
                        fulfilled: false,
                        rejected: true,
                    },
                },
            };
        }
        case RtdsSessionsActionTypeKeys.RTDSSESSIONS_QUERY_FULFILLED: {
            const isLatestResponse = state.latestRequestParams === action.meta;
            if (!isLatestResponse) {
                return state;
            }

            const resolvedRtdsSessionsEntries = resolveRtdsSessionsEntries(
                action.payload.items,
                staticDataStoreState,
                settingsStoreState
            );

            return {
                ...state,
                dynamicRtdsSessions: {
                    ...state.dynamicRtdsSessions,
                    rtdsSessionsEntries: resolvedRtdsSessionsEntries,
                    rtdsSessions: {
                        data: action.payload.items,
                        fulfilled: true,
                        pending: false,
                        rejected: false,
                    },
                },
            };
        }
        default:
            return state;
    }
};
