import { getI18n } from '~/i18n';

export interface Language {
    code: string;
    flag: string;
    name: string;
}

const allLanguages = [
    { code: 'de-DE', flag: 'de', name: 'Deutsch' },
    { code: 'en-GB', flag: 'gb', name: 'English' },
    { code: 'es-ES', flag: 'es', name: 'Español' },
    { code: 'fr-FR', flag: 'fr', name: 'Français' },
    { code: 'hu-HU', flag: 'hu', name: 'Magyar' },
    { code: 'nl-NL', flag: 'nl', name: 'Nederlands' },
    { code: 'pl-PL', flag: 'pl', name: 'Polski' },
    { code: 'it-IT', flag: 'it', name: 'Italiano' },
    { code: 'pt-BR', flag: 'br', name: 'Português' },
];

export async function retrieveAllLanguages(): Promise<Language[]> {
    return allLanguages;
}

export async function retrieveCurrentLanguage(): Promise<Language> {
    const i18n = getI18n();
    const specific = allLanguages.find((l) => l.code === i18n.language);

    if (specific) {
        return specific;
    } else {
        if (!i18n.options.fallbackLng || !i18n.options.fallbackLng.length) {
            throw new Error('No fallbackLng configured');
        }

        const fallback = i18n.options.fallbackLng[0];
        const fallbackLanguage = allLanguages.find((l) => l.code === fallback);

        if (!fallbackLanguage) {
            throw new Error('FallbackLng could not be found');
        }

        return fallbackLanguage;
    }
}

export async function changeLanguage(language: Language): Promise<void> {
    if (allLanguages.findIndex((l) => l.code === language.code) < 0) {
        throw new Error('Unknown language');
    }

    const i18n = getI18n();
    i18n.changeLanguage(language.code);
}
