import { divIcon } from 'leaflet';
import type { FC } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import MarkerClusterGroup from 'react-leaflet-cluster';

import { ClusterGroup } from '../ClusterGroup';

import { MAP_KEY } from './constants';
import type { IconCreateFunction, MarkerClusterProps } from './models';

const MarkerCluster: FC<MarkerClusterProps> = (props) => {
    const { mapKey, markerClusterRadius, children } = props;

    const iconCreateFunction: IconCreateFunction = (cluster) => {
        const { lat, lng } = cluster.getLatLng();

        return divIcon({
            className: '',
            html: renderToStaticMarkup(
                <ClusterGroup
                    count={cluster.getChildCount()}
                    dataId={`cluster${MAP_KEY[mapKey]}-marker:${lat}${lng}`}
                />
            ),
            iconSize: [24, 24],
            iconAnchor: [12, 12],
        });
    };

    return (
        <MarkerClusterGroup
            animate={false}
            animateAddingMarkers={false}
            chunkDelay={500}
            chunkedLoading
            chunkInterval={1000}
            iconCreateFunction={iconCreateFunction}
            maxClusterRadius={markerClusterRadius}
            removeOutsideVisibleBounds
            spiderfyDistanceMultiplier={4}
        >
            {children}
        </MarkerClusterGroup>
    );
};

MarkerCluster.displayName = 'MarkerCluster';
export { MarkerCluster };
