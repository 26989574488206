import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { Classification, DeviceType, Trailer, TrailerEvent } from '~/services/ApiClient';

import type { HistoryTrailerEntry } from './models';
import { trailerEventResolver } from './redux.trailerEventResolver';

export const buildHistoryTrailerEntry = (
    trailersHash: NumericDictionary<Trailer>,
    trailerEvent: TrailerEvent,
    deviceTypes: NumericDictionary<DeviceType>,
    reeferStatus: NumericDictionary<Classification>,
    reeferAlarm: NumericDictionary<Classification>,
    reeferOperationMode: NumericDictionary<Classification>,
    reeferPowerMode: NumericDictionary<Classification>,
    reeferSpeed: NumericDictionary<Classification>,
    hookedStatus: NumericDictionary<Classification>,
    compartmentStatus: NumericDictionary<Classification>,
    doorStatus: NumericDictionary<Classification>,
    trailerBatteryStatus: NumericDictionary<Classification>,
    trailerEventType: NumericDictionary<Classification>
): HistoryTrailerEntry => {
    const trailer = !isUndefined(trailerEvent.trailer.id) ? trailersHash[trailerEvent.trailer.id] : undefined;

    return {
        trailer,
        trailerEvent: trailerEventResolver(
            trailerEvent,
            deviceTypes,
            reeferStatus,
            reeferAlarm,
            reeferOperationMode,
            reeferPowerMode,
            reeferSpeed,
            hookedStatus,
            compartmentStatus,
            doorStatus,
            trailerBatteryStatus,
            trailerEventType
        ),
    };
};
