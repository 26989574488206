import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        gridTemplateColumns: '1fr 100px 1fr',
        minHeight: theme.custom.dialogContent.minHeight,
    },
    buttonContainer: { gap: theme.spacing() },
}));

export { useStyles };
