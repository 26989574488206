import * as React from 'react';

import { formatDateTime } from '~/services/Formatters';

export interface DateTimeSpanFormatterProps {
    value: { start?: Date; stop?: Date };
}

export interface DateTimeSpanFormatterInnerProps extends DateTimeSpanFormatterProps {}

const DateTimeSpanFormatter: React.FunctionComponent<DateTimeSpanFormatterInnerProps> = ({ value }) => {
    const formattedDate1 = value.start && formatDateTime(value.start);
    const formattedDate2 = value.stop && formatDateTime(value.stop);
    return (
        <>
            {formattedDate1}
            {' - '}
            {formattedDate2}
        </>
    );
};
DateTimeSpanFormatter.displayName = 'DateTimeSpanFormatter';

export { DateTimeSpanFormatter };
