import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { TemperatureSensorGroupFormatterInnerProps, TemperatureSensorGroupFormatterProps } from './component';
import { TemperatureSensorGroupFormatterComponent } from './component';
import { TemperatureSensorGroupStyleRules } from './styles';

export const TemperatureSensorGroupFormatter = compose<
    TemperatureSensorGroupFormatterInnerProps,
    TemperatureSensorGroupFormatterProps
>(
    setDisplayName('TemperatureSensorGroupFormatter'),
    withStyles(TemperatureSensorGroupStyleRules)
)(TemperatureSensorGroupFormatterComponent);
