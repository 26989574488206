import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ButtonProgressInnerProps, ButtonProgressProps } from './component';
import { ButtonProgressComponent } from './component';
import { ButtonProgressStyleRules } from './styles';

export const ButtonProgress = compose<ButtonProgressInnerProps, ButtonProgressProps>(
    setDisplayName('ButtonProgress'),
    withStyles(ButtonProgressStyleRules, { withTheme: false })
)(ButtonProgressComponent);
