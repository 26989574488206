import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { ToggleSettingItemInnerProps, ToggleSettingItemProps } from './component';
import { ToggleSettingItemComponent } from './component';
import { ToggleSettingItemStyleRules } from './styles';

export const ToggleSettingItem = compose<ToggleSettingItemInnerProps, ToggleSettingItemProps>(
    setDisplayName('ToggleSettingItem'),
    withStyles(ToggleSettingItemStyleRules)
)(ToggleSettingItemComponent);
