import type { Dispatch } from 'redux';

import { panToAssetAction } from '~/components/SceneMap';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import type { Securables } from '~/services/ApiClient';

import type { DispatchProps, StateProps } from './component';
import { DETAILSPANE_CONTAINER_USERPREFERENCES_KEY } from './preferences';

export const mapStateToProps = (securables: Securables): StateProps => ({ canViewMap: securables.monitoring.map });

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    changeMiniMapZoomLevel: (zoomLevel: number) => {
        dispatch(
            updateUserPreferencesAction(DETAILSPANE_CONTAINER_USERPREFERENCES_KEY, { miniMapZoomLevel: zoomLevel })
        );
    },
    panToContainer: () => {
        dispatch(
            panToAssetAction(
                'mapping',
                'locate-from-container-details',
                'Locate on map from container details',
                'location-section'
            )
        );
    },
});
