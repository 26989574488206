import type { NumericDictionary } from '~/libs/utility';
import { keyBy, mapValues } from '~/libs/utility';
import type { AssetGroup, AssetReference } from '~/services/ApiClient';

import type { AssetReferenceHash } from '../models';

import { assetTypeIdsMap } from './createUnassignedCategories';
import { assetReferenceHashMerge } from './hashutils';

export const getAssetGroupIds = (assetGroups?: AssetGroup[]): number[] => {
    if (assetGroups === undefined) {
        return [];
    }

    return assetGroups.reduce((a, b: AssetGroup) => [...a, b.id, ...getAssetGroupIds(b.assetGroups)], []);
};

export const getAssetGroupById = (assetGroups: AssetGroup[] | undefined, assetGroupId: number): AssetGroup | null => {
    if (assetGroups === undefined) {
        return null;
    }

    for (let i = 0; i < assetGroups.length; i++) {
        const assetGroup = assetGroups[i];
        if (assetGroup.id === assetGroupId) {
            return assetGroup;
        }

        const assetGroupById = getAssetGroupById(assetGroup.assetGroups, assetGroupId);

        if (assetGroupById) {
            return assetGroupById;
        }
    }

    return null;
};

export const getOwnAssetGroupAssetIds = (
    assetGroup: AssetGroup,
    assetIds: AssetReferenceHash<AssetReference>
): AssetReferenceHash<AssetReference> => {
    return mapValues(assetIds, (assetTypeDict, assetType) => {
        const ids = assetGroup[assetTypeIdsMap[assetType]] as unknown as number[] | undefined;
        if (!ids?.length) {
            return {};
        }
        return keyBy(ids.map((id) => assetTypeDict?.[id]).filter(Boolean), 'id') as NumericDictionary<AssetReference>;
    });
};

export const getSingleAssetGroupAssetIds = (
    assetGroup: AssetGroup,
    assetIds: AssetReferenceHash<AssetReference>
): AssetReferenceHash<AssetReference> => {
    const own = getOwnAssetGroupAssetIds(assetGroup, assetIds);
    const children = assetGroup.assetGroups?.map((ag) => getSingleAssetGroupAssetIds(ag, assetIds)) ?? [];
    return assetReferenceHashMerge(own, ...children);
};

export const getAssetGroupAssetIds = (
    assetGroups: AssetGroup[] | undefined,
    assetIds: AssetReferenceHash<AssetReference>
): AssetReferenceHash<AssetReference> => {
    if (assetGroups === undefined) {
        return {};
    }

    const assets = assetGroups.map((ag) => getSingleAssetGroupAssetIds(ag, assetIds));
    return assetReferenceHashMerge(...assets);
};
