import { orderBy } from '~/libs/utility';
import type { ResolvedConversation, ResolvedMessage } from '~/services/ApiClient';

export const pushOptimisticMessageToConversation = (
    conversation: ResolvedConversation,
    optimisticMessage: ResolvedMessage
): ResolvedConversation => ({
    ...conversation,
    messages: orderBy([optimisticMessage, ...conversation.messages], ['value.dateTime', 'value.id'], ['desc', 'desc']),
});
