import type { NumericDictionary } from '~/libs/utility';
import { keyBy, remove } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import {
    ConversationProfile,
    WorkflowFormDefinition,
    WorkflowFormDefinitionStatus,
    createApiModel,
} from '~/services/ApiClient';

import type { ActionTypes as ActionTypesConversation } from './data';
import { ActionTypeKeys } from './data';

export interface ConversationsRootStoreState {
    workflowFormDefinitions: RetrievableData<NumericDictionary<WorkflowFormDefinition | WorkflowFormDefinitionStatus>>;
    profiles: RetrievableData<ConversationProfile[]>;
    maxConversationProfileCount: number;
}

const defaultStoreState: ConversationsRootStoreState = {
    workflowFormDefinitions: { data: {}, pending: false, fulfilled: false, rejected: false },
    profiles: { data: [], pending: false, fulfilled: false, rejected: false },
    maxConversationProfileCount: 0,
};

type ActionTypes = ActionTypesConversation;

export const conversationsRootReducer = (
    state: ConversationsRootStoreState = defaultStoreState,
    action: ActionTypes
): ConversationsRootStoreState => {
    switch (action.type) {
        case ActionTypeKeys.WORKFLOWFORMDEFINITIONS_LATEST_PENDING: {
            return {
                ...state,
                workflowFormDefinitions: {
                    ...state.workflowFormDefinitions,
                    pending: true,
                },
            };
        }

        case ActionTypeKeys.WORKFLOWFORMDEFINITIONS_LATEST_REJECTED: {
            return {
                ...state,
                workflowFormDefinitions: {
                    data: {},
                    pending: false,
                    rejected: true,
                    fulfilled: false,
                },
            };
        }

        case ActionTypeKeys.WORKFLOWFORMDEFINITIONS_LATEST_FULFILLED: {
            const workflowFormDefinitionsDictionary = keyBy(action.payload.items, 'id');

            return {
                ...state,
                workflowFormDefinitions: {
                    data: workflowFormDefinitionsDictionary,
                    pending: false,
                    rejected: false,
                    fulfilled: true,
                },
            };
        }

        case ActionTypeKeys.WORKFLOWFORMDEFINITION_PENDING: {
            if (!state.workflowFormDefinitions.fulfilled) {
                return state;
            }

            if (state.workflowFormDefinitions.data[action.meta] instanceof WorkflowFormDefinition) {
                return state;
            }

            const workflowFormDefinitionsDictionary = {
                ...state.workflowFormDefinitions.data,
                [action.meta]: WorkflowFormDefinitionStatus.PENDING,
            };

            return {
                ...state,
                workflowFormDefinitions: {
                    ...state.workflowFormDefinitions,
                    data: workflowFormDefinitionsDictionary,
                },
            };
        }

        case ActionTypeKeys.WORKFLOWFORMDEFINITION_REJECTED: {
            if (!state.workflowFormDefinitions.fulfilled) {
                return state;
            }

            if (state.workflowFormDefinitions.data[action.meta] instanceof WorkflowFormDefinition) {
                return state;
            }

            const workflowFormDefinitionsDictionary = {
                ...state.workflowFormDefinitions.data,
                [action.meta]: WorkflowFormDefinitionStatus.REJECTED,
            };

            return {
                ...state,
                workflowFormDefinitions: {
                    ...state.workflowFormDefinitions,
                    data: workflowFormDefinitionsDictionary,
                },
            };
        }

        case ActionTypeKeys.WORKFLOWFORMDEFINITION_FULFILLED: {
            if (!state.workflowFormDefinitions.fulfilled) {
                return state;
            }

            if (state.workflowFormDefinitions.data[action.meta] instanceof WorkflowFormDefinition) {
                return state;
            }

            const workflowFormDefinitionsDictionary = {
                ...state.workflowFormDefinitions.data,
                [action.meta]: action.payload.item,
            };

            return {
                ...state,
                workflowFormDefinitions: {
                    ...state.workflowFormDefinitions,
                    data: workflowFormDefinitionsDictionary,
                },
            };
        }

        case ActionTypeKeys.CONVERSATIONPROFILES_PENDING:
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    pending: true,
                },
            };
        case ActionTypeKeys.CONVERSATIONPROFILES_FULFILLED:
            return {
                ...state,
                profiles: {
                    data: action.payload.items,
                    pending: false,
                    rejected: false,
                    fulfilled: true,
                },
                maxConversationProfileCount: action.payload.metadata.maxConversationProfileCount,
            };

        case ActionTypeKeys.CONVERSATIONPROFILES_REJECTED:
            return {
                ...state,
                profiles: {
                    data: [],
                    pending: false,
                    rejected: true,
                    fulfilled: false,
                },
            };

        case ActionTypeKeys.CONVERSATIONPROFILE_CREATE_FULFILLED: {
            const newProfile = { ...action.meta.item, id: action.payload.id };
            const profileAdded = createApiModel(ConversationProfile, newProfile);
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    data: [...state.profiles.data, profileAdded],
                },
            };
        }

        case ActionTypeKeys.CONVERSATIONPROFILE_UPDATE_FULFILLED:
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    data: state.profiles.data.map((profile) => {
                        if (profile.id !== action.meta.item.id) {
                            return profile;
                        }
                        return action.meta.item;
                    }),
                },
            };

        case ActionTypeKeys.CONVERSATIONPROFILE_DELETE_FULFILLED:
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    data: remove(state.profiles.data, (profile) => {
                        return profile.id !== action.meta;
                    }),
                },
            };

        default:
            return state;
    }
};
