import { GridSortingDirection } from '~/components/Grid';
import type { SceneListUserPreferences } from '~/components/SceneList';

import { ColumnName } from './constants';

export const MONITORING_LIST_CONTAINERS_USERPREFERENCES_KEY = 'monitoring-grid-container-state';

export const defaultContainersListUserPreferences: SceneListUserPreferences = {
    columnOrder: [
        ColumnName.CONTAINER,
        ColumnName.ADDRESS,
        ColumnName.POSTALCODE,
        ColumnName.CITY,
        ColumnName.COUNTRYCODE,
        ColumnName.NEARESTCITY,
        ColumnName.ALTITUDE,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
        ColumnName.ODOMETER,
        ColumnName.EVENTTYPE,
        ColumnName.EVENTDATETIME,
        ColumnName.TEMPERATURE,
        ColumnName.BARRELFILLLEVEL,
        ColumnName.BARRELPRESSURE,
        ColumnName.HEATINGSYSTEMON,
        ColumnName.HEATINGSYSTEMTANKLEVEL,
        ColumnName.PRODUCTDENSITY,
    ],
    visibleColumns: [
        ColumnName.CONTAINER,
        /* ColumnName.EVENTTYPE, not visible until we do not have all event types */
        ColumnName.EVENTDATETIME,
        ColumnName.BARRELFILLLEVEL,
        ColumnName.BARRELPRESSURE,
        ColumnName.HEATINGSYSTEMON,
        ColumnName.HEATINGSYSTEMTANKLEVEL,
        ColumnName.PRODUCTDENSITY,
        ColumnName.ODOMETER,
        ColumnName.TEMPERATURE,
        ColumnName.CITY,
        ColumnName.ADDRESS,
        ColumnName.POSTALCODE,
        ColumnName.COUNTRYCODE,
        ColumnName.NEARESTCITY,
        ColumnName.ALTITUDE,
        ColumnName.LATITUDE,
        ColumnName.LONGITUDE,
    ],
    columnWidths: [
        { columnName: ColumnName.CONTAINER, width: 150 },
        { columnName: ColumnName.EVENTTYPE, width: 150 },
        { columnName: ColumnName.EVENTDATETIME, width: 150 },
        { columnName: ColumnName.BARRELFILLLEVEL, width: 75 },
        { columnName: ColumnName.BARRELPRESSURE, width: 75 },
        { columnName: ColumnName.HEATINGSYSTEMON, width: 75 },
        { columnName: ColumnName.HEATINGSYSTEMTANKLEVEL, width: 75 },
        { columnName: ColumnName.PRODUCTDENSITY, width: 75 },
        { columnName: ColumnName.ODOMETER, width: 100 },
        { columnName: ColumnName.TEMPERATURE, width: 75 },
        { columnName: ColumnName.CITY, width: 100 },
        { columnName: ColumnName.ADDRESS, width: 100 },
        { columnName: ColumnName.POSTALCODE, width: 100 },
        { columnName: ColumnName.COUNTRYCODE, width: 100 },
        { columnName: ColumnName.NEARESTCITY, width: 100 },
        { columnName: ColumnName.ALTITUDE, width: 75 },
        { columnName: ColumnName.LATITUDE, width: 100 },
        { columnName: ColumnName.LONGITUDE, width: 100 },
    ],
    sorting: [{ columnName: ColumnName.CONTAINER, direction: GridSortingDirection.ASC }],
    groupBy: [],
    groupingEnabled: false,
    pinEnabled: true,
    filters: [],
    filteringEnabled: false,
};
