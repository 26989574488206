import type { Duration } from 'moment';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PercentageFormatter, ViolationFieldFormatterFactory } from '~/components/Formatters';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import { isUndefined } from '~/libs/utility';
import type { DurationViolationField } from '~/services/ApiClient';
import { formatDriverSubpageListItemLabel, formatDuration } from '~/services/Formatters';
import { parseOptionalDurationViolationField } from '~/services/Parsers';

import { SectionName } from '../../../consts';
import type { SectionProps } from '../models';

import { useStyles } from './styles';

const DutySection: FC<SectionProps> = (props) => {
    const { dragHandleElement, toggleCollapsed, isCollapsed } = props;
    const { dailyDuty, weeklyDuty, monthlyDuty, monthlyEfficiency } = props.driverStatus;
    const classes = useStyles();
    const { t } = useTranslation();

    const ViolationDurationFormatter = useMemo(
        () => ViolationFieldFormatterFactory((v: Duration) => formatDuration(v)),
        []
    );

    return (
        <ReorderableSection
            title={t(`driver-subpage-${SectionName.DUTY}`)}
            dataId={SectionName.DUTY}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
            dragHandleElement={dragHandleElement}
        >
            <KeyValueList
                list={[
                    {
                        valueDataId: 'daily-duty',
                        label: formatDriverSubpageListItemLabel(t, 'duty-daily-duty'),
                        value: dailyDuty && (
                            <ViolationDurationFormatter
                                t={t}
                                fieldName={formatDriverSubpageListItemLabel(t, 'duty-daily-duty')}
                                value={parseOptionalDurationViolationField(dailyDuty as DurationViolationField)}
                            />
                        ),
                    },
                    {
                        valueDataId: 'weekly-duty',
                        label: formatDriverSubpageListItemLabel(t, 'duty-weekly-duty'),
                        value: weeklyDuty && (
                            <ViolationDurationFormatter
                                t={t}
                                fieldName={formatDriverSubpageListItemLabel(t, 'duty-weekly-duty')}
                                value={parseOptionalDurationViolationField(weeklyDuty as DurationViolationField)}
                            />
                        ),
                    },
                    {
                        valueDataId: 'monthly-dut',
                        label: formatDriverSubpageListItemLabel(t, 'duty-monthly-duty'),
                        value: monthlyDuty && (
                            <ViolationDurationFormatter
                                t={t}
                                fieldName={formatDriverSubpageListItemLabel(t, 'duty-monthly-duty')}
                                value={parseOptionalDurationViolationField(monthlyDuty as DurationViolationField)}
                            />
                        ),
                    },
                    {
                        valueDataId: 'monthly-driver-duty',
                        label: formatDriverSubpageListItemLabel(t, 'duty-monthly-driver-duty'),
                        value: !isUndefined(monthlyEfficiency) ? (
                            <span className={classes.simpleValue}>
                                <PercentageFormatter value={monthlyEfficiency} precision={2} />
                            </span>
                        ) : undefined,
                    },
                ]}
                listDataId="property-list"
            />
        </ReorderableSection>
    );
};

DutySection.displayName = 'DutySection';
export default DutySection;
