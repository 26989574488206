import { SearchingBox } from '@fv/components/Searching';
import type { FC } from 'react';

import { useTree } from '../../context';

const Header: FC = () => {
    const [
        { searchEnabled, partiallySelected, searchingItemsName, selectedAll, expandedAll, hideSelectAll },
        { openSearch, changeSearchQuery, closeSearch, selectAll, onCollapse },
    ] = useTree();

    return (
        <SearchingBox
            hideBulkSelectActions={hideSelectAll}
            closeSearch={closeSearch}
            changeSearchQuery={changeSearchQuery}
            expandedAll={expandedAll}
            openSearch={openSearch}
            onToggleSelectAll={selectAll}
            partiallySelected={partiallySelected}
            searchEnabled={searchEnabled}
            selectedAll={selectedAll}
            searchingItemsName={searchingItemsName}
            handleCollapse={onCollapse}
        />
    );
};

Header.displayName = 'Header';
export { Header };
