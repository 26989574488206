import { isUndefined } from '~/libs/utility';
import type { RetrievableHashedData } from '~/reducers';
import type { AssetReference, Driver } from '~/services/ApiClient';
import { AssetType } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

export const getSelectedAuthorizedDrivers = memoizeOne(
    (drivers: RetrievableHashedData<Driver>, selectedAssetIds?: AssetReference[]) => {
        let authorizedDriverIds: number[] | undefined;

        if (drivers.fulfilled && drivers.data?.hash) {
            const selectedDriversIds = selectedAssetIds
                ?.filter((selectedAsset) => selectedAsset.type === AssetType.Driver)
                .map((selectedAsset) => selectedAsset.id);

            authorizedDriverIds = selectedDriversIds?.filter((driverId) => {
                const authorizedDriver = drivers.data.hash[driverId];
                return !isUndefined(authorizedDriver);
            });
        }

        return authorizedDriverIds;
    }
);
