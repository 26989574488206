import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { SettingsKey, ensureSettings, settingDataSelector } from '~/components/EnsureSettings';
import { customerAutocompleteStateSelector } from '~/selectors';

import type { CustomerRemoteAutocompleteInnerProps, CustomerRemoteAutocompleteProps } from './component';
import { CustomerRemoteAutocompleteComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export type { CustomerRemoteAutocompleteProps } from './component';
export type { CustomerAutocompleteStoreState } from './reducers';
export { customerAutocompleteReducer } from './reducers';

export const CustomerRemoteAutocomplete = compose<
    CustomerRemoteAutocompleteInnerProps,
    CustomerRemoteAutocompleteProps
>(
    setDisplayName('CustomerRemoteAutocomplete'),
    ensureSettings([SettingsKey.CUSTOMER_SECURABLES], false),
    connect(
        createSelector(
            customerAutocompleteStateSelector,
            settingDataSelector(SettingsKey.CUSTOMER_SECURABLES),
            mapStateToProps
        ),
        mapDispatchToProps
    )
)(CustomerRemoteAutocompleteComponent);
