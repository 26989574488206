import { useTranslation } from 'react-i18next';

import type { KeyValueItem } from '~/components/KeyValueList';
import { KeyValueList } from '~/components/KeyValueList';
import { ReorderableSection } from '~/components/Sections';
import { isEmpty } from '~/libs/utility';
import { getVehicleAssetGroups, getVehicleDepot } from '~/services/Vehicles';

import type { VehicleDetailsSectionProps } from './models';

const VehicleDetailsSection: React.FunctionComponent<VehicleDetailsSectionProps> = (props) => {
    const { vehicle, assetGroups, depots, dragHandleElement, isCollapsed, toggleCollapsed } = props;

    const { t } = useTranslation();
    const vehicleGroups = getVehicleAssetGroups(vehicle.id, assetGroups);
    const vehicleDepot = getVehicleDepot(vehicle.id, depots);

    const keyValueItems: KeyValueItem[] = [
        {
            valueDataId: 'alias',
            label: t('alias'),
            value: vehicle.alias,
        },
        {
            valueDataId: 'vin',
            label: t('vin'),
            value: vehicle.vin,
        },
        {
            valueDataId: 'vrn',
            label: t('vrn'),
            value: vehicle.vrn,
        },
        {
            valueDataId: 'unit-id',
            label: t('unit-id'),
            value: vehicle.unitId,
        },
        {
            valueDataId: 'vehicle-type',
            label: t('vehicle-type'),
            value: vehicle.vehicleType?.description ?? vehicle.vehicleType?.name,
        },
        {
            valueDataId: 'groups',
            label: t('groups'),
            value: !isEmpty(vehicleGroups) ? vehicleGroups.join(', ') : t('unassigned'),
        },
        {
            valueDataId: 'depot',
            label: t('depot'),
            value: vehicleDepot,
        },
    ];

    return (
        <ReorderableSection
            dataId="vehicle-details"
            title={t('vehicle-details')}
            dragHandleElement={dragHandleElement}
            isCollapsed={isCollapsed}
            toggleCollapsed={toggleCollapsed}
        >
            <KeyValueList list={keyValueItems} listDataId="general-list" />
        </ReorderableSection>
    );
};

VehicleDetailsSection.displayName = 'VehicleDetailsSection';
export { VehicleDetailsSection };
