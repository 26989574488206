import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';
import { getAltitudeUnitSystemUtils } from '~/services/Formatters';

export interface AltitudeFormatterProps {
    t: SingleTFunction;
    unitSystem: DisplayUserPreferencesUnitSystem;
    value?: number;
}

export interface AltitudeFormatterInnerProps extends AltitudeFormatterProps {}

const AltitudeFormatter: React.FunctionComponent<AltitudeFormatterInnerProps> = ({ t, unitSystem, value }) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{getAltitudeUnitSystemUtils(t, unitSystem).formatter(value)}</>;
};
AltitudeFormatter.displayName = 'AltitudeFormatter';

export { AltitudeFormatter };
