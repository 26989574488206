import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type RtdsScheduleDetailsClassKey = 'item' | 'actionButtons' | 'iconMargin';

export const useStyles = makeStyles<Theme, {}, RtdsScheduleDetailsClassKey>((theme) => ({
    item: {
        paddingTop: theme.spacing(1.75),
        paddingBottom: 0,
        display: 'block',
    },
    actionButtons: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-around',
    },
    iconMargin: {
        marginRight: theme.spacing(),
    },
    modifyScheduleButton: {
        marginLeft: theme.spacing(),
    },
}));
