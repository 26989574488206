import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type MarkAllMessagesAsReadButtonClassKey = 'root' | 'disabled';

export const spinnerSize = 14;

export const MarkAllMessagesAsReadButtonStyleRules: StyleRulesCallback<
    Theme,
    {},
    MarkAllMessagesAsReadButtonClassKey
> = () =>
    createStyles({
        root: {
            width: 24,
            height: 24,
            padding: 0,
            fontSize: 14,
            cursor: 'pointer',
        },
        disabled: {
            cursor: 'default',
        },
    });
