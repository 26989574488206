import type { ActionTypes as AuthenticationActionTypes, LoginTypes } from '~/data/authentication';
import { ActionTypeKeys as AuthenticationActionTypeKeys } from '~/data/authentication';

export interface AuthenticationStoreState {
    loginType?: LoginTypes;
}

const defaultStoreState: AuthenticationStoreState = {};

export const authenticationReducer = (
    state: AuthenticationStoreState = defaultStoreState,
    action: AuthenticationActionTypes
): AuthenticationStoreState => {
    switch (action.type) {
        case AuthenticationActionTypeKeys.LOGOUT_FULFILLED:
        case AuthenticationActionTypeKeys.VERIFYTOKEN_REJECTED:
            return { ...state, loginType: undefined };
        case AuthenticationActionTypeKeys.AUTHENTICATION_FULFILLED:
            return {
                ...state,
                loginType: action.meta,
            };
        default:
            return state;
    }
};
