import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { DRIVERSTATUS_DETAILSPANE_USERPREFERENCES_KEY } from '~/data/monitoring';

import { SectionName } from './consts';

export const defaultDriverStatusDetailsPaneUserPreferences: DriverStatusDetailsPaneUserPreferences = {
    sectionOrder: [
        SectionName.DRIVERSTATUS_HEADER,
        SectionName.DRIVERSTATUS_MINIMAP,
        SectionName.DRIVERSTATUS_LOCATION,
        SectionName.DRIVERSTATUS_STAFF,
        SectionName.DRIVERSTATUS_VEHICLE,
    ],
    collapsedSections: [],
    miniMapZoomLevel: 12,
};

export interface DriverStatusDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapZoomLevel: number;
}

export const requiredDriverStatusDetailsPaneUserPreferences = {
    key: DRIVERSTATUS_DETAILSPANE_USERPREFERENCES_KEY,
    defaultState: defaultDriverStatusDetailsPaneUserPreferences,
};
