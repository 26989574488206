import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'typeface-roboto';
import 'url-polyfill';

import { App } from '~/App';
import { initializeAppInsights } from '~/applicationInsights';
import { setFeatureFlagEnabled } from '~/components/FeatureFlags';
import { ensureCurrentLanguage } from '~/components/LanguageSelector';
import { PersistStore } from '~/components/PersistStore';
import { initI18n } from '~/i18n';
import { setMomentLocale } from '~/moment';
import { unregister } from '~/registerServiceWorker';
import { reportError } from '~/reporting';
import { languageStateSelector, protectedComponentStoreStateSelector } from '~/selectors';
import { initializeGtag } from '~/services/Analytics';
import { ApiClient, AuthenticationApiClient } from '~/services/ApiClient';
import { getConfig, loadConfig } from '~/services/Config';
import { initializeMopinion, setMopinionData } from '~/services/Mopinion';
import { initializePtvClient } from '~/services/PtvClient';
import { observeState } from '~/services/Redux';
import { saveUserPreferencesService } from '~/services/SaveUserPreferences';
import { NotificationsClient } from '~/services/SignalR';
import { configureStore } from '~/store';

import { ThemeProvider } from './providers/themes';
import { initializeMapGatewayClient, setMapGatewayClientToken } from './services/MapGatewayClient';

const store = configureStore();

observeState({
    store,
    stateSelector: protectedComponentStoreStateSelector,
    onChange: (protectedComponent) => {
        // Set JWT
        AuthenticationApiClient.setJwt(protectedComponent.impersonatorJwt ?? protectedComponent.jwt);
        ApiClient.setJwt(protectedComponent.jwt);
        NotificationsClient.setJwt(protectedComponent.jwt);
        setMapGatewayClientToken(protectedComponent.jwt);
        // Change mopinion
        setMopinionData({
            username: protectedComponent.decodedJwt?.data.username,
            customerName: protectedComponent.decodedJwt?.data.customerName,
        });
    },
});

observeState({
    store,
    stateSelector: languageStateSelector,
    onChange: (language) => {
        // Change moment locale
        setMomentLocale(language.currentLanguage?.code);

        // Change mopinion
        setMopinionData({
            languageCode: language.currentLanguage?.code,
        });

        // Change html lang attribute
        document.documentElement.lang = language.currentLanguage?.code ?? 'en';
    },
});

window.addEventListener('beforeunload', () => {
    saveUserPreferencesService.saveAndClearAll().catch(reportError);
});

window.addEventListener('unload', () => {
    NotificationsClient.close().catch(reportError);
});

Promise.all([loadConfig(), initI18n()]).then(() => {
    const history = createBrowserHistory();
    initializeAppInsights(history);

    const config = getConfig();
    initializePtvClient(config.ptvUrl, config.ptvAuthorizationToken);
    initializeMapGatewayClient(config.mapGatewayClientUrl);
    initializeGtag(config.analyticsMeasurementId);
    initializeMopinion(config.mopinionFormId);
    store.dispatch(setFeatureFlagEnabled(config.featureFlagsEnabled));

    const DecoratedApp = ensureCurrentLanguage(App);
    const root = createRoot(document.getElementById('root') as HTMLElement);

    root.render(
        <Provider store={store}>
            <ThemeProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <PersistStore reduxStore={store}>
                        <Router history={history}>
                            <DecoratedApp />
                        </Router>
                    </PersistStore>
                </LocalizationProvider>
            </ThemeProvider>
        </Provider>
    );

    unregister();
});
