import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { DateTimeInputInnerProps, DateTimeInputProps } from './component';
import { DateTimeInputComponent } from './component';
import { DateTimeInputStyleRules } from './styles';

export const DateTimeInput = compose<DateTimeInputInnerProps, DateTimeInputProps>(
    setDisplayName('DateTimeInput'),
    withStyles(DateTimeInputStyleRules),
    withTranslation()
)(DateTimeInputComponent);
