import { createSelector } from 'reselect';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { featureFlagsSelector } from '~/selectors';

const ensureAuthorizationSelector = createSelector(
    [featureFlagsSelector, settingDataSelector(SettingsKey.SECURABLES)],
    ({ enabledFeatures }, securables) => ({ enabledFeatures, securables })
);

export { ensureAuthorizationSelector };
