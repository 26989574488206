import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/system';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { Feature } from '~/components/FeatureFlags';
import { featureFlagsSelector } from '~/selectors';

import { themes } from '../theme';

const ThemeProvider: FC = ({ children }) => {
    const { enabledFeatures } = useSelector(featureFlagsSelector);
    const isDriverActivityRedesignEnabled = enabledFeatures.includes(Feature.DriverActivityRedesign);
    const theme = isDriverActivityRedesignEnabled ? themes.featureFlaggedTheme : themes.default;

    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MuiThemeProvider>
        </StyledEngineProvider>
    );
};

ThemeProvider.displayName = 'ThemeProvider';
export { ThemeProvider };
