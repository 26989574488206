import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import type { AssetSelectorComponentProps, AssetSelectorInnerProps } from './component';
import { AssetSelectorComponent } from './component';

export const AssetSelector = compose<AssetSelectorInnerProps, AssetSelectorComponentProps>(
    setDisplayName('AssetSelector'),
    withDisplayPreferences(),
    withTranslation()
)(AssetSelectorComponent);
