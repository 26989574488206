import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type WorkflowMessageDetailsClassKey = 'root' | 'header' | 'attachmentsTitle' | 'detailsPaneWrapper';

export const WorkflowMessageDetailsStyleRules: StyleRulesCallback<Theme, {}, WorkflowMessageDetailsClassKey> = (
    theme: Theme
) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100%',
        },
        header: {
            padding: theme.spacing(2),
            textAlign: 'right',
        },
        attachmentsTitle: {
            flexGrow: 1,
            lineHeight: '24px',
            margin: theme.spacing(0, 2),
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.common.white,
        },
        detailsPaneWrapper: {
            flex: '1 1',
            overflow: 'auto',
        },
    });
