import type { FC } from 'react';

import type { PageLoaderProps } from './models';
import { Backdrop, CircularProgress, MessageContainer } from './styles';

const PageLoader: FC<PageLoaderProps> = (props) => {
    const { isLoading, isEmpty, children, emptyMessage, isInitialLoad } = props;

    if (!isLoading && isEmpty) {
        return <MessageContainer data-testid="no-selection">{emptyMessage}</MessageContainer>;
    }

    return (
        <>
            <Backdrop open={isLoading && !isInitialLoad} data-testid="back-drop">
                <CircularProgress size={120} data-testid="backdrop-loader" />
            </Backdrop>

            {children}
        </>
    );
};

PageLoader.displayName = 'PageLoader';
export { PageLoader };
