import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import type { StyledComponentProps } from '@mui/styles';
import * as React from 'react';

import type { GridFilterOperation } from '../../../../models';
import { FilterIcon } from '../../../FilterIcon';
import type { FilterEditorProps } from '../../models';

import type { FilterDefaultCellClassKey } from './styles';
import { useStyles } from './styles';

export interface FilterDefaultCellProps
    extends TableFilterRow.CellProps,
        StyledComponentProps<FilterDefaultCellClassKey> {
    availableFilterOperations: GridFilterOperation[];
    editorComponent?: React.ComponentType<FilterEditorProps>;
    align?: 'left' | 'right' | 'center';
    dataId?: string;
}

export interface FilterDefaultCellInnerProps extends FilterDefaultCellProps {}

const DefaultEditor = ({ operation, ...props }: FilterEditorProps) => <TableFilterRow.Editor {...props} />;

const FilterDefaultCell: React.FC<FilterDefaultCellInnerProps> = (props) => {
    const {
        availableFilterOperations,
        onFilter,
        editorComponent,
        align: _alignment,
        classes: _classes,
        dataId,
        ...restProps
    } = props;

    const editorRef = React.useRef<HTMLElement>(null);
    const [filterOperation, setFilterOperation] = React.useState<string>(availableFilterOperations[0]);
    const [filterInputValue, setFilterInputValue] = React.useState<string | undefined>();

    const classes = useStyles(props);

    const EditorComponent = editorComponent ?? DefaultEditor;

    React.useEffect(() => {
        const operation = restProps.filter?.operation;
        if (operation) {
            setFilterOperation(operation);
        }
        setFilterInputValue(restProps.filter?.value);
    }, [restProps.filter, setFilterOperation, setFilterInputValue, availableFilterOperations]);

    const applyFilter = React.useCallback(
        (operation: string, value: string | undefined) => {
            if (value) {
                onFilter({ columnName: restProps.column.name, operation, value });
            } else {
                onFilter(null);
            }
        },
        [onFilter, restProps.column]
    );

    const applyInputValue = React.useCallback(
        (value: string) => {
            setFilterInputValue(value);
            applyFilter(filterOperation, value);
        },
        [setFilterInputValue, filterOperation, applyFilter]
    );

    const applyFilterOperation = React.useCallback(
        (operation: string) => {
            setFilterOperation(operation);
            applyFilter(operation, filterInputValue);
            window.setTimeout(() => {
                if (editorRef.current) {
                    editorRef.current.focus();
                }
            }, 0);
        },
        [setFilterOperation, filterInputValue, applyFilter]
    );

    return (
        <TableFilterRow.Cell {...restProps} onFilter={onFilter} className={classes.root}>
            <TableFilterRow.FilterSelector
                toggleButtonComponent={TableFilterRow.ToggleButton}
                availableValues={availableFilterOperations}
                disabled={!restProps.filteringEnabled}
                getMessage={restProps.getMessage}
                iconComponent={FilterIcon}
                value={filterOperation}
                onChange={applyFilterOperation}
            />
            <EditorComponent
                dataId={dataId}
                inputRef={editorRef}
                disabled={!restProps.filteringEnabled}
                getMessage={restProps.getMessage}
                operation={filterOperation as GridFilterOperation}
                value={filterInputValue}
                onChange={applyInputValue}
                classes={{ input: classes.input }}
            />
        </TableFilterRow.Cell>
    );
};
FilterDefaultCell.displayName = 'FilterDefaultCell';

export { FilterDefaultCell };
