import type { NumericDictionary } from '~/libs/utility';
import type { Classification, DeviceType, Trailer, TrailerEvent } from '~/services/ApiClient';

import type { HistoryTrailerEntry } from './models';
import { buildHistoryTrailerEntry } from './redux.buildHistoryTrailerEntry';

export const buildHistoryTrailerEntries = (
    trailersHash: NumericDictionary<Trailer>,
    trailerEvents: TrailerEvent[],
    deviceTypes: NumericDictionary<DeviceType>,
    reeferStatus: NumericDictionary<Classification>,
    reeferAlarm: NumericDictionary<Classification>,
    reeferOperationMode: NumericDictionary<Classification>,
    reeferPowerMode: NumericDictionary<Classification>,
    reeferSpeed: NumericDictionary<Classification>,
    hookedStatus: NumericDictionary<Classification>,
    compartmentStatus: NumericDictionary<Classification>,
    doorStatus: NumericDictionary<Classification>,
    trailerBatteryStatus: NumericDictionary<Classification>,
    trailerEventType: NumericDictionary<Classification>
): HistoryTrailerEntry[] =>
    trailerEvents.map((trailerEvent: TrailerEvent) =>
        buildHistoryTrailerEntry(
            trailersHash,
            trailerEvent,
            deviceTypes,
            reeferStatus,
            reeferAlarm,
            reeferOperationMode,
            reeferPowerMode,
            reeferSpeed,
            hookedStatus,
            compartmentStatus,
            doorStatus,
            trailerBatteryStatus,
            trailerEventType
        )
    );
