import { toLat } from 'mt-geo';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { memoize } from '~/libs/utility';
import { memoizeOne } from '~/services/Memoize';

import { formatCardinal } from './formatCardinal';

const formatLatitudeInternal = (t: SingleTFunction, latitude: number) => {
    const latString = toLat(latitude);
    const latCardinal = latString.slice(-1);
    return `${latString.substring(0, latString.length - 1)} ${formatCardinal(t, latCardinal)}`;
};

const formatLatitudeNoCardinalTranslationInternal = (latitude: number) => {
    const latString = toLat(latitude);
    const latCardinal = latString.slice(-1);
    return `${latString.substring(0, latString.length - 1)} ${latCardinal}`;
};

const formatLatitudeMemoized = memoizeOne((t: SingleTFunction) =>
    memoize((latitude: number) => formatLatitudeInternal(t, latitude))
);

const formatLatitudeNoCardinalTranslationMemoized = memoizeOne(() =>
    memoize((latitude: number) => formatLatitudeNoCardinalTranslationInternal(latitude))
);

export const formatLatitude = (t: SingleTFunction, latitude: number): string => formatLatitudeMemoized(t)(latitude);

export const formatLatitudeNoCardinalTranslation = (latitude: number): string =>
    formatLatitudeNoCardinalTranslationMemoized()(latitude);
