import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type ButtonProgressClassKey = 'buttonProgress';

export const ButtonProgressStyleRules: StyleRulesCallback<Theme, {}, ButtonProgressClassKey> = () =>
    createStyles({
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
            width: '100%',
        },
    });
