import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import type { IconDividerInnerProps, IconDividerProps } from './component';
import { IconDividerComponent } from './component';
import { IconDividerStyleRules } from './styles';

export const IconDivider = compose<IconDividerInnerProps, IconDividerProps>(
    setDisplayName('IconDivider'),
    withStyles(IconDividerStyleRules)
)(IconDividerComponent);
