import * as React from 'react';
import type { RouteComponentProps } from 'react-router';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { Subtract } from 'utility-types';

export interface CustomLinkProps extends Subtract<LinkProps, { replace?: boolean; location?: unknown }> {}

export interface CustomLinkInnerProps extends RouteComponentProps, CustomLinkProps {}

export const CustomLinkComponent: React.FunctionComponent<CustomLinkInnerProps> = ({
    staticContext,
    match,
    location,
    history,
    to,
    ...restProps
}) => {
    const shouldUseReplace = history.location.pathname === to;

    return <Link {...restProps} to={to} replace={shouldUseReplace} />;
};
