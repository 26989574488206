import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type IgnitionStatusBadgeClassKey =
    | 'avatar'
    | 'ignitionStatus'
    | 'ignitionStatusIcon'
    | 'ignitionStatusIconOff'
    | 'ignitionStatusIconOn';

export const IgnitionStatusBadgeStyleRules: StyleRulesCallback<Theme, {}, IgnitionStatusBadgeClassKey> = (
    theme: Theme
) =>
    createStyles({
        avatar: {
            backgroundColor: theme.functionalItemsColors.assetType.vehicle.value,
            color: theme.functionalItemsColors.assetType.vehicle.contrast,
        },
        ignitionStatus: {
            width: 16,
            height: 16,
            borderRadius: '50%',
            padding: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.common.white,
        },
        ignitionStatusIcon: {
            height: '100%',
            width: '100%',
        },
        ignitionStatusIconOff: {
            color: theme.functionalItemsColors.ignition.off.value,
        },
        ignitionStatusIconOn: {
            color: theme.functionalItemsColors.ignition.on.value,
        },
    });
