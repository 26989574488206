import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { RetryActionInnerProps, RetryActionProps } from './component';
import { RetryActionComponent } from './component';

export { Status } from './component';
export type { RetryActionProps } from './component';

export const RetryAction = compose<RetryActionInnerProps, RetryActionProps>(
    setDisplayName('RetryAction'),
    withTranslation()
)(RetryActionComponent);
