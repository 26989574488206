import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { resetPasswordSceneStateSelector } from '~/selectors';

import type { RequestResetPasswordInnerProps, RequestResetPasswordProps } from './component';
import { RequestResetPasswordComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';

export const RequestResetPassword = compose<RequestResetPasswordInnerProps, RequestResetPasswordProps>(
    setDisplayName('RequestResetPassword'),
    connect(createSelector(resetPasswordSceneStateSelector, mapStateToProps), mapDispatchToProps)
)(RequestResetPasswordComponent);
