import { withTranslation } from 'react-i18next';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';

import type { TachoFilesListViewInnerProps, TachoFilesListViewProps } from './component';
import { TachoFilesListViewComponent } from './component';

export const TachoFilesListView = compose<TachoFilesListViewInnerProps, TachoFilesListViewProps>(
    setDisplayName('TachoFilesListView'),
    withDisplayPreferences(),
    withTranslation()
)(TachoFilesListViewComponent);
