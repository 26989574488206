import * as React from 'react';

import type { SingleTFunction } from '~/components/LanguageSelector';
import { isUndefined } from '~/libs/utility';
import type { DisplayUserPreferencesUnitSystem } from '~/services/ApiClient';
import { getTemperatureUnitSystemUtils } from '~/services/Formatters';

export interface TemperatureFormatterProps {
    t: SingleTFunction;
    unitSystem: DisplayUserPreferencesUnitSystem;
    value?: number;
    precision?: number;
}

export interface TemperatureFormatterInnerProps extends TemperatureFormatterProps {}

const TemperatureFormatter: React.FunctionComponent<TemperatureFormatterInnerProps> = ({
    t,
    unitSystem,
    value,
    precision,
}) => {
    if (isUndefined(value)) {
        return null;
    }

    return <>{getTemperatureUnitSystemUtils(t, unitSystem).formatter(value, { precision })}</>;
};
TemperatureFormatter.displayName = 'TemperatureFormatter';

export { TemperatureFormatter };
