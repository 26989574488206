import { withStyles } from '@mui/styles';
import { compose, setDisplayName } from 'react-recompose';

import { withTranslation } from '~/components/LanguageSelector';

import type { EnumerationWorkflowFieldInnerProps, EnumerationWorkflowFieldProps } from './component';
import { EnumerationWorkflowFieldComponent } from './component';
import { EnumerationWorkflowFieldStyleRules } from './styles';

export const EnumerationWorkflowField = compose<EnumerationWorkflowFieldInnerProps, EnumerationWorkflowFieldProps>(
    setDisplayName('EnumerationWorkflowField'),
    withStyles(EnumerationWorkflowFieldStyleRules),
    withTranslation()
)(EnumerationWorkflowFieldComponent);
