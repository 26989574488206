import { styled } from '@mui/material';

const SubtypeListContainer = styled('ul')({
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1rem',
    listStyle: 'none',
});

export { SubtypeListContainer };
