import type { GridColumnInfo, GridFilter, GridSorting } from '~/components/Grid';
import { difference, without } from '~/libs/utility';

export interface SceneListUserPreferences {
    columnOrder: string[];
    visibleColumns: string[];
    columnWidths: GridColumnInfo[];
    sorting: GridSorting[];
    groupBy: string[];
    groupingEnabled: boolean;
    pinEnabled: boolean;
    filteringEnabled: boolean;
    filters: GridFilter[];
}

export const processResponseFactory =
    (defaultSceneListUserPreferences: SceneListUserPreferences) =>
    (preferences: Partial<SceneListUserPreferences>): SceneListUserPreferences => {
        const processedPreferences = {
            ...defaultSceneListUserPreferences,
            ...preferences,
        };

        const missingColumns = difference(
            defaultSceneListUserPreferences.columnOrder,
            processedPreferences.columnOrder
        );
        if (missingColumns.length) {
            const missingAndDefaultVisibleColumns = missingColumns.filter((column) =>
                defaultSceneListUserPreferences.visibleColumns.includes(column)
            );

            processedPreferences.columnOrder = [...processedPreferences.columnOrder, ...missingColumns];
            processedPreferences.visibleColumns = [
                ...processedPreferences.visibleColumns,
                ...missingAndDefaultVisibleColumns,
            ];
            processedPreferences.columnWidths = [
                ...processedPreferences.columnWidths,
                ...defaultSceneListUserPreferences.columnWidths.filter(({ columnName }) =>
                    missingColumns.includes(columnName)
                ),
            ];
        }

        const obsoleteColumns = difference(
            processedPreferences.columnOrder,
            defaultSceneListUserPreferences.columnOrder
        );
        if (obsoleteColumns.length) {
            processedPreferences.columnOrder = without(processedPreferences.columnOrder, ...obsoleteColumns);
            processedPreferences.visibleColumns = without(processedPreferences.visibleColumns, ...obsoleteColumns);
            processedPreferences.columnWidths = processedPreferences.columnWidths.filter(
                ({ columnName }) => !obsoleteColumns.includes(columnName)
            );
            processedPreferences.groupBy = without(processedPreferences.groupBy, ...obsoleteColumns);
            processedPreferences.sorting = processedPreferences.sorting.filter(
                ({ columnName }) => !obsoleteColumns.includes(columnName)
            );
            processedPreferences.filters = processedPreferences.filters.filter(
                ({ columnName }) => !obsoleteColumns.includes(columnName)
            );
        }

        return processedPreferences;
    };
