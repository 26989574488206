import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import * as React from 'react';
import type { Subtract } from 'utility-types';

export interface AvailableIconProps extends Subtract<SvgIconProps, { viewBox?: string }> {}

export interface AvailableIconInnerProps extends AvailableIconProps {}

const AvailableIcon: React.SFC<AvailableIconInnerProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM5,5H17.59L5,17.59ZM19,19H6.41L19,6.41Z" />
        </SvgIcon>
    );
};
AvailableIcon.displayName = 'AvailableIcon';

export { AvailableIcon };
