import { composeWithDevToolsLogOnlyInProduction } from '@redux-devtools/extension';
import type { AnyAction, Store } from 'redux';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import promise from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';

import { cancelableActionMiddleware } from '~/common';
import type { StoreState } from '~/reducers';
import { rootReducer } from '~/reducers';

export function configureStore(): Store<StoreState, AnyAction> {
    const composeEnhancers = composeWithDevToolsLogOnlyInProduction({ trace: true, traceLimit: 25 });

    return createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunkMiddleware, cancelableActionMiddleware, promise))
    );
}
