import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type SingleValueClassKey = 'root';

export const SingleValueStyleRules: StyleRulesCallback<Theme, {}, SingleValueClassKey> = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
            paddingLeft: theme.spacing(),
        },
    });
