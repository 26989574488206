import type { NumericDictionary } from '~/libs/utility';
import { isUndefined } from '~/libs/utility';
import type { Classification, ResolvedTrailerBatteryStatus, TrailerBatteryStatus } from '~/services/ApiClient';

export const trailerBatteryStatusResolver = (
    { init, toJSON, ...batteryStatusData }: TrailerBatteryStatus,
    batteryStatus: NumericDictionary<Classification>
): ResolvedTrailerBatteryStatus => ({
    ...batteryStatusData,
    status: !isUndefined(batteryStatusData.status) ? batteryStatus[batteryStatusData.status] : undefined,
});
