import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { withFeatureFlags } from '~/components/FeatureFlags';
import { withTranslation } from '~/components/LanguageSelector';

import { loginStoreStateSelector } from '../../../../selectors';

import type {
    UserLoginFormDispatchProps,
    UserLoginFormInnerProps,
    UserLoginFormProps,
    UserLoginFormStateProps,
} from './component';
import { UserLoginFormComponent } from './component';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { LoginFormStyleRules } from './styles';

const stateToPropsMapper = createSelector(loginStoreStateSelector, mapStateToProps);

export const UserLoginForm = compose<UserLoginFormInnerProps, UserLoginFormProps>(
    setDisplayName('UserLoginForm'),
    withStyles(LoginFormStyleRules, { withTheme: true }),
    withTranslation(),
    withFeatureFlags(),
    connect<UserLoginFormStateProps, UserLoginFormDispatchProps, UserLoginFormProps>(
        stateToPropsMapper,
        mapDispatchToProps
    )
)(UserLoginFormComponent);
