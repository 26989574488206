import { Button, CircularProgress, FormControl, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PasswordScore, PasswordStrengthIndicator } from '~/common';
import { ButtonProgress } from '~/components/ButtonProgress';
import { TextInput } from '~/components/InputFields';
import { useTranslation } from '~/components/LanguageSelector';
import { Severity, ValidationBox } from '~/components/ValidationBox';
import { ServerResultStatus } from '~/services/ApiClient';

import { minPasswordLength } from './constants';
import type { FieldValues, ResetPasswordFormInnerProps } from './models';
import { useStyles } from './styles';

export const ResetPasswordFormComponent: FC<ResetPasswordFormInnerProps> = (props) => {
    const { resetPassword, token, validateSecretToken, verifyTokenServerResultStatus, lastServerResultStatus } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const [newPasswordScore, setNewPasswordScore] = useState(PasswordScore.TooShort);
    const form = useForm<FieldValues>({ mode: 'all', defaultValues: { newPassword: '', repeatNewPassword: '' } });
    const newPasswordValue = form.watch('newPassword');
    const showResultOk = lastServerResultStatus === ServerResultStatus.OK;
    const resultBadRequest =
        lastServerResultStatus === ServerResultStatus.BADREQUEST ||
        verifyTokenServerResultStatus === ServerResultStatus.BADREQUEST;
    const lastServerResultPending = lastServerResultStatus === ServerResultStatus.PENDING;
    const tokenIsValid = verifyTokenServerResultStatus === ServerResultStatus.OK;
    const newPasswordConformsToPolicy = newPasswordScore !== PasswordScore.TooShort;
    const disableSubmitButton = !form.formState.isValid || lastServerResultPending || !newPasswordConformsToPolicy;

    useEffect(() => {
        validateSecretToken(token);
    }, [token, validateSecretToken]);

    const onSubmit = (data: FieldValues) => {
        resetPassword(token, data.newPassword);
    };

    const getTitleMessage = () => {
        if (resultBadRequest) {
            return t('link-no-valid-title');
        } else if (showResultOk) {
            return t('reset-password-form-ok-response-title');
        } else {
            return t('enter-new-password-title');
        }
    };

    const getDescriptionMessage = () => {
        if (resultBadRequest) {
            return (
                <Trans data-id="description-translation" i18nKey="link-no-valid-description">
                    <Link to="/reset-password" className={classes.link} data-id="reset-password-link">
                        {t('here')}
                    </Link>
                </Trans>
            );
        } else if (showResultOk) {
            return (
                <Trans data-id="description-translation" i18nKey="reset-password-form-ok-response-description">
                    <Link to="/" className={classes.link} data-id="login-link">
                        {t('here')}
                    </Link>
                </Trans>
            );
        } else {
            return (
                <>
                    {t('enter-new-password-description')}
                    <br />
                    <br />
                    {t('password-policy-paragraph')}
                    <ul>
                        <li>{t('password-policy-rule-min-length', { minimumLength: minPasswordLength })}</li>
                    </ul>
                </>
            );
        }
    };

    const repeatNewPasswordRule = {
        validate: {
            'password-match': (value: string) => {
                if (value !== form.getValues('newPassword') && value !== '') {
                    return t('passwords-no-match');
                } else {
                    return undefined;
                }
            },
        },
    };

    const resetPasswordForm = tokenIsValid && !showResultOk && !resultBadRequest && (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
                method="post"
                data-id="reset-password-token-form"
                id="reset-password-token-form"
            >
                <TextInput
                    className={classes.formField}
                    fieldName="newPassword"
                    dataId="new-password"
                    label={t('new-password')}
                    required
                    variant="standard"
                    type="password"
                    markValueChange={false}
                    onValueChanged={() => {
                        if (form.formState.touchedFields.repeatNewPassword) {
                            form.trigger('repeatNewPassword');
                        }
                    }}
                />

                <PasswordStrengthIndicator
                    password={newPasswordValue}
                    minLength={minPasswordLength}
                    onChangeScore={setNewPasswordScore}
                />

                <TextInput
                    className={classes.formField}
                    fieldName="repeatNewPassword"
                    dataId="repeat-new-password"
                    label={t('repeat-new-password')}
                    required
                    validationRules={repeatNewPasswordRule}
                    variant="standard"
                    type="password"
                    markValueChange={false}
                />

                <FormControl variant="standard" className={classes.button} fullWidth>
                    <Button
                        disabled={disableSubmitButton}
                        color="secondary"
                        data-id="reset-password"
                        type="submit"
                        variant="contained"
                        fullWidth
                    >
                        {t('reset-password-form-button')}
                    </Button>
                    <ButtonProgress showProgress={lastServerResultPending} />
                </FormControl>
            </form>
        </FormProvider>
    );

    if (verifyTokenServerResultStatus === ServerResultStatus.PENDING) {
        return <CircularProgress data-id="loader" className={classes.loader} size={48} />;
    }

    return (
        <>
            {lastServerResultStatus === ServerResultStatus.SERVERERROR && (
                <ValidationBox message={t('not-able-to-process-request')} severity={Severity.Error} />
            )}

            <div className={classes.root}>
                <Typography variant="h5" data-id="title" gutterBottom>
                    {getTitleMessage()}
                </Typography>
                <br />
                <Typography variant="body2" data-id="description">
                    {getDescriptionMessage()}
                </Typography>

                {resetPasswordForm}
            </div>
        </>
    );
};
