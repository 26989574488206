import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

type MiniMapSectionClassKeys =
    | 'root'
    | 'content'
    | 'handle'
    | 'map'
    | 'mapContainer'
    | 'noLocationContainer'
    | 'noLocationImage';

const MiniMapSectionStyleRules: StyleRulesCallback<Theme, {}, MiniMapSectionClassKeys> = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            paddingBottom: '56.25%', // 16:9
        },
        content: {
            position: 'absolute',
            width: '100%',
            height: '100%',
        },
        handle: {
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1,
            padding: theme.spacing(1, 2),
            color: theme.palette.text.secondary,
            '& svg:last-child': {
                margin: theme.spacing(0, 0.5),
            },
        },
        mapContainer: {
            width: '100%',
            height: '100%',
        },
        map: {
            zIndex: 0,
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        noLocationContainer: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
        },
        noLocationImage: {
            width: theme.spacing(11),
            height: theme.spacing(11),
            marginBottom: theme.spacing(),
        },
    });

export type { MiniMapSectionClassKeys };
export { MiniMapSectionStyleRules };
