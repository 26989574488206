import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type DoorStatusClassKey = 'flex' | 'avatar' | 'badge';

export const DoorStatusStyleRules: StyleRulesCallback<Theme, {}, DoorStatusClassKey> = (theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex',
        },
        avatar: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.default,
            width: 40,
            height: 40,
            alignSelf: 'center',
            overflow: 'visible',
            fontSize: 20,
        },
        badge: {
            right: 10,
            width: 22,
            height: 22,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.info.main,
            borderColor: theme.palette.info.contrastText,
            alignItems: 'center',
            borderRadius: '50%',
            justifyContent: 'center',
            borderWidth: 1,
            fontSize: 14,
            display: 'flex',
            overflow: 'hidden',
        },
    });
