import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import type { ChartLegendItemProps } from './component';

export type ChartLegendItemClassKey = 'checkbox';

export const ChartLegendItemStyleRules: StyleRulesCallback<Theme, ChartLegendItemProps, ChartLegendItemClassKey> = () =>
    createStyles({
        checkbox: (props) => {
            switch (props.series.type) {
                case 'line':
                    return {
                        color: props.series.color,
                        '&:hover': {
                            backgroundColor: alpha(props.series.color, 0.08),
                        },
                    };
                case 'area':
                    return {
                        color: alpha(props.series.color, 0.26),
                        '&:hover': {
                            backgroundColor: alpha(props.series.color, 0.08),
                        },
                    };
                default:
                    throw new Error('unknown series type');
            }
        },
    });
