import withStyles from '@mui/styles/withStyles';
import { compose, setDisplayName } from 'react-recompose';

import { withDisplayPreferences } from '~/components/DisplayPreferences';
import { withTranslation } from '~/components/LanguageSelector';

import { EbsSectionComponent } from './component';
import type { EbsSectionInnerProps, EbsSectionProps } from './models';
import { EbsSectionStyleRules } from './styles';

export const EbsSection = compose<EbsSectionInnerProps, EbsSectionProps>(
    setDisplayName('EbsSection'),
    withDisplayPreferences(),
    withTranslation(),
    withStyles(EbsSectionStyleRules)
)(EbsSectionComponent);
